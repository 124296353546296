import { useEffect, useRef } from 'react'
import { WebView, WebViewMessageEvent } from 'react-native-webview'
import { useLinkTo, useNavigation } from '@react-navigation/native'
import { RegionType } from '@vatom/sdk/core'
import { useAnalytics, useSDK } from '@vatom/sdk/react'
import { VatomModelType } from '@vatom/vatomNew/plugin'
import { VatomWallet } from '@vatom/wallet-sdk'
// import { type RequestPayload, OutgoingMessagePayload } from '@vatom/view-sdk'
import EventEmitter from 'events'
import { getSnapshot } from 'mobx-state-tree'
import { z } from 'zod'

import { AppNavigation, AppRoutes, TabRoutes } from '../../../../navigators'

const RNEvents = new EventEmitter()

export const registerHandler = <T extends MessengerKeys>(
  action: T,
  callback: MessengerAction[T]['action']
) => {
  RNEvents.on(action, callback)
  return () => RNEvents.off(action, callback)
}

export const useClientListener = <T extends MessengerKeys>(
  action: T,
  callback: MessengerAction[T]['action']
) => {
  useEffect(() => {
    const unsubscribe = registerHandler(action, callback)
    return () => {
      unsubscribe()
    }
  }, [action, callback])
}

/**
 * Will emit the event to the child when the dependencies are
 * @param action
 * @param deps array of dependencies that will trigger the subscription
 * @param callback
 */
export const useSubscriptionToDependencies = <T extends MessengerKeys>(
  action: T,
  callback: MessengerAction[T]['action'],
  deps: unknown[]
) => {
  useClientListener(action, callback)

  useEffect(() => {
    RNEvents.emit(action, { eventType: action }, deps)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, ...deps])
}

function onMessageFromVatomViewSDK(message: string | WebViewMessage) {
  const event = decodeWebViewMessage(message)
  console.log('LOG: > onMessageFromVatomViewSDK > event:', event)
  if (event?.eventType) {
    RNEvents.emit(event.eventType, event)
  }
}

type Deps = {
  navigation: AppNavigation
  trackEvent: (name: string, ...args: unknown[]) => void
  token: VatomModelType
  dismiss: () => void
  linkTo: ReturnType<typeof useLinkTo>
  sdk: ReturnType<typeof useSDK>
  getToken: typeof getTokenFromSDK
  sentToChildren: (type: string, payload: Record<string, unknown>) => void
}

function getTokenFromSDK(
  { id, objectDefinitionId }: { id?: string; objectDefinitionId?: string },
  { sdk }: { sdk: ReturnType<typeof useSDK> }
) {
  if (!id && !objectDefinitionId) {
    return undefined
  }
  const region = sdk.dataPool.region(RegionType.inventory)

  if (!objectDefinitionId && id) {
    // Look for token by id
    return region.tokens.find(t => t.id === id)
  }
  if (!id && objectDefinitionId) {
    // Look for token by objectDefinitionId
    return region.tokens.find(t => t.studioInfo?.objectDefinitionId === objectDefinitionId)
  }
}

function getTokenChildrenFromSDK(
  token: VatomModelType,
  { sdk }: { sdk: ReturnType<typeof useSDK> }
) {
  if ('getChildren' in token) {
    if (typeof token.getChildren === 'function') {
      return token.getChildren() ?? []
    }
  }
  const inventoryRegion = sdk.dataPool.regions.find(r => r.id === 'inventory')
  if (!inventoryRegion) {
    return []
  }
  const tokens = inventoryRegion?.tokens
  const children = tokens.filter(c => c.parentId === token.id)
  return children
}

function getUserFromSDK({ sdk }: { sdk: ReturnType<typeof useSDK> }) {
  return sdk.dataPool.user.userInfo
}

type WebViewMessage =
  | WebViewMessageEvent
  | (Event & {
      data: string
    })

const EventMessageSchema = z.object({
  type: z.string(),
  payload: z.optional(z.record(z.string(), z.unknown()))
})

function getEventData(event: string | WebViewMessage) {
  if ('string' === typeof event) {
    return event
  }
  if ('data' in event) {
    return event.data
  }
}

function decodeWebViewMessage(message: string | WebViewMessage) {
  try {
    const messageData = getEventData(message)
    if ('string' !== typeof messageData) {
      return
    }
    const eventFromMessage = JSON.parse(messageData)
    const parsedEvent = EventMessageSchema.safeParse(eventFromMessage)
    if (!parsedEvent.success) {
      throw parsedEvent.error
    }
    return {
      eventType: parsedEvent.data.type,
      eventData: parsedEvent.data.payload
    } as HandleEventsProps
  } catch (error) {
    console.info('decodeWebViewMessage.INFO: unparsable message event', error, message)
    return
  }
}

const getInjectableJSMessage = (message: string): string => {
  const safeString = JSON.stringify(message)
  const date = Date.now()
  return `(function() {
      document.dispatchEvent(new MessageEvent('message', {
        data: ${safeString},
        triggeredAt: ${date}
      }));
    })();true;`
}

const useViewSDKHandlerDependencies = ({
  webViewRef,
  deps
}: {
  webViewRef: React.MutableRefObject<WebView | HTMLIFrameElement | undefined>
  deps: Omit<Deps, 'sentToChildren'>
}) => {
  const sentToChildren = (type: string, payload: Record<string, unknown>) => {
    console.log('LOG: > sentToChildren > type:', type, payload)
    try {
      const msg = JSON.stringify({ type, payload })

      if (webViewRef.current && 'injectJavaScript' in webViewRef.current) {
        // Native
        webViewRef.current?.injectJavaScript(getInjectableJSMessage(msg))
      } else if (webViewRef.current && 'contentWindow' in webViewRef.current) {
        // Web
        webViewRef.current?.contentWindow?.postMessage(msg, '*')
      } else {
        console.warn('MessageHandler.sentToChildren: web view instance not found')
      }
    } catch (error) {
      console.error('MessageHandler.sentToChildren', error)
    }
  }

  const runCallbackWithDependencies = (cb: any) => (event: HandleEventsProps) => {
    if (!event) {
      return
    }
    return cb(event, { ...deps, sentToChildren })
  }

  return runCallbackWithDependencies
}

// TODO: change name to useViewSDKHandler
export const useViewSDKHandler = ({ token }: { token: VatomModelType }) => {
  const navigation = useNavigation()
  const analytics = useAnalytics()
  const linkTo = useLinkTo()
  const sdk = useSDK()

  const webViewRef = useRef<WebView | HTMLIFrameElement>()

  const createCallback = useViewSDKHandlerDependencies({
    webViewRef,
    deps: {
      navigation: navigation,
      trackEvent: analytics.track,
      token,
      dismiss: () => {
        // TODO: implement
      },
      linkTo,
      sdk,
      getToken: getTokenFromSDK
      // You could add mores dependencies to be required by the callbacks
    }
  })

  // Listeners
  useClientListener('viewer.view.ready', createCallback(MessengerActions.onWebViewReady))

  useClientListener('vatom.view.close', createCallback(MessengerActions.onDismiss))

  useClientListener('ui.scanner.show', createCallback(MessengerActions.onScannerShow))
  useClientListener('viewer.scanner.show', createCallback(MessengerActions.onScannerShow))

  useClientListener('viewer.vatom.delete', createCallback(MessengerActions.onDeleteToken))
  useClientListener('viewer.delete-vatom', createCallback(MessengerActions.onDeleteToken))

  useClientListener('viewer.analytics.push', createCallback(MessengerActions.onAnalyticsPush))
  useClientListener(
    'viewer.analytics.track',
    createCallback(MessengerActions.onAnalyticsTrackEvent)
  )

  useClientListener('viewer.vatom.show', createCallback(MessengerActions.onVatomShow))
  useClientListener('ui.vatom.show', createCallback(MessengerActions.onVatomShow))

  useClientListener('viewer.ar.show', createCallback(MessengerActions.onArShow))

  // Subscriptions to dependencies
  const tokenSnapshot = token ? getSnapshot(token) : undefined
  useSubscriptionToDependencies(
    'vatom.token.update',
    createCallback(MessengerActions.onVatomTokenUpdate),
    [tokenSnapshot]
  )

  return {
    webViewRef,
    messageHandler: onMessageFromVatomViewSDK
  }
}

const ViewerKeys = {
  'viewer.view.ready': 'viewer.view.ready',
  'ui.scanner.show': 'ui.scanner.show',
  'viewer.scanner.show': 'viewer.scanner.show',
  'viewer.vatom.delete': 'viewer.vatom.delete',
  'viewer.delete-vatom': 'viewer.delete-vatom',
  'viewer.analytics.push': 'viewer.analytics.push',
  'viewer.vatom.show': 'viewer.vatom.show',
  'ui.vatom.show': 'ui.vatom.show',
  'viewer.ar.show': 'viewer.ar.show',
  'viewer.analytics.track': 'viewer.analytics.track'
} as const

const MessageKeys = {
  ...ViewerKeys,
  'vatom.view.close': 'vatom.view.close',
  'vatom.token.update': 'vatom.token.update'
} as const

type MessengerKeys = keyof typeof MessageKeys

type MessengerAction = {
  [Property in MessengerKeys]: {
    action: (a: HandleEventsProps, d: Deps) => void
  }
}

type EventDataPayload = {
  'viewer.analytics.track': {
    name: string
    data?: Record<string, unknown>
  }
  'viewer.analytics.push': Record<string, unknown> & {
    event: string
    userId: string
  }
  ['viewer.vatom.delete']: {
    name: string
    id?: string
    'this.id'?: string
  }
  ['viewer.vatom.show']: {
    name: string
    id: string
  }
}

type HandleEventsProps<T extends keyof EventDataPayload = keyof EventDataPayload> = {
  eventType: T
  eventData?: EventDataPayload[T]
}

type MessengerActionsKeys = keyof typeof MessengerActions
type MessengerActionsCallbackType = typeof MessengerActions[MessengerActionsKeys]

const MessengerActions = {
  onDismiss: (args: HandleEventsProps, { dismiss }: Deps) => {
    dismiss()
    return args.eventData
  },
  onScannerShow: (_: HandleEventsProps, { trackEvent, token }: Deps) => {
    trackEvent('scannerShow', {}, token)
    throw new Error('Bridge message not implemented.')
  },
  onDeleteToken: async (
    { eventData }: HandleEventsProps<'viewer.vatom.delete'>,
    { trackEvent, token, navigation }: Deps
  ) => {
    // Face is requesting we delete a specific vatom
    if (!eventData) {
      return
    }
    const vatomId = eventData['this.id'] || eventData.id
    await token.performAction('Delete')

    // If this vatom is being deleted, return to home
    if (vatomId === token.id) navigation.navigate(AppRoutes.home)
  },
  onAnalyticsPush: (
    { eventData }: HandleEventsProps<'viewer.analytics.push'>,
    { trackEvent, token }: Deps
  ) => {
    if (!eventData) {
      return
    }
    // Face is requesting we send analytics
    if (eventData?.event) {
      trackEvent(eventData.event as string, eventData, token)
    }
  },
  onAnalyticsTrackEvent: (
    { eventData }: HandleEventsProps<'viewer.analytics.track'>,
    { trackEvent }: Deps
  ) => {
    if (!eventData) {
      throw new Error(`MessengerActions.onAnalyticsTrackEvent: EventData not found`)
    }
    if (!eventData.name) {
      throw new Error(`MessengerActions.onAnalyticsTrackEvent: Name not found in eventData`)
    }
    trackEvent(eventData.name as string, eventData?.data)
  },
  onVatomShow: (
    { eventData }: HandleEventsProps<'viewer.vatom.show'>,
    { navigation, getToken, sdk }: Deps
  ) => {
    if (!eventData) {
      throw new Error(`MessengerActions.onVatomShow: EventData not found`)
    }
    const { id: tokenId } = eventData
    const childToken = getToken(eventData, { sdk })
    if (childToken) {
      if (childToken?.studioInfo?.businessId) {
        // TODO: verify if this is working for all types of tokens
        navigation.navigate(AppRoutes.NFTDetail_Business, {
          business: childToken?.studioInfo?.businessId,
          tokenId
        })
      } else {
        navigation.navigate(AppRoutes.NFTDetail, { tokenId })
      }
    } else {
      throw new Error(`MessengerActions.onVatomShow: Token not found`)
    }
  },
  onArShow: (_: HandleEventsProps, { token, navigation }: Deps) => {
    if (token?.studioInfo?.businessId) {
      navigation.navigate(AppRoutes.BusinessProxy, {
        business: token?.studioInfo?.businessId,
        screen: TabRoutes.MapAr
      })
    } else {
      navigation.navigate(AppRoutes.MapAR)
    }
  },
  onWebViewReady: (args: HandleEventsProps, deps: Deps) => {
    const user = getUserFromSDK({ sdk: deps.sdk })
    const tokenChildren = getTokenChildrenFromSDK(deps.token, { sdk: deps.sdk })
    // Send response event to "subscribers"
    deps.sentToChildren(args.eventType, {
      token: deps.token,
      userData: user,
      children: tokenChildren
    })
  },
  onVatomTokenUpdate: (args: HandleEventsProps, deps: Deps) => {
    // Send response event to "subscribers"
    deps.sentToChildren(args.eventType, {
      token: deps.token
    })
  }
}

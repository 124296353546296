import { QueryFunctionContext, useQueries, useQuery } from '@tanstack/react-query'
import { getRootStore } from '@vatom/sdk/react'

import { DeviceContactsWithUserId, PublicProfileOptions, UserData } from './types'

export const publicProfileQueryKeys = {
  publicProfile: [{ scope: 'public-profile' }] as const,
  getPublicProfile: (userId: string) =>
    [{ ...publicProfileQueryKeys.publicProfile[0], userId }] as const
}

export const publicProfileHost = 'https://users.vatom.com/'

export const fetchUserProfile = async ({
  queryKey: [{ userId }]
}: QueryFunctionContext<ReturnType<typeof publicProfileQueryKeys['getPublicProfile']>>) => {
  const apiInstance = getRootStore().service.users

  const response = await apiInstance?.get<UserData>(`/${userId}`)
  return response.data
}

export const useGetPublicProfile = <T = UserData>(
  userId?: string,
  options?: PublicProfileOptions<T>
) => {
  const query = useQuery({
    queryKey: publicProfileQueryKeys.getPublicProfile(userId ?? ''),
    queryFn: fetchUserProfile,
    enabled: !!userId && options?.enabled !== false,
    ...options
  })

  return query
}

export const useGetPublicProfiles = <T = UserData[]>(
  userIds: string[],
  select?: (users: UserData) => T
) => {
  const queries = useQueries({
    queries: userIds.map(userId => ({
      queryKey: publicProfileQueryKeys.getPublicProfile(userId),
      queryFn: fetchUserProfile,
      select,
      enabled: !!userId
    }))
  })

  return queries
}

export const useGetPublicProfilesWithDevice = <T = UserData[]>(
  deviceContacts: DeviceContactsWithUserId[],
  select?: (users: UserData) => T
) => {
  const queries = useQueries({
    queries: deviceContacts.map(contact => ({
      queryKey: publicProfileQueryKeys.getPublicProfile(contact.userId),
      queryFn: fetchUserProfile,
      select: (user: UserData) => {
        const updatedUser = {
          ...user,
          name: user?.name ?? contact.deviceContact.value
        }
        return select?.(updatedUser) ?? updatedUser
      },
      enabled: !!contact.userId
    }))
  })

  return queries
}

import { useIsFocused, useNavigation } from '@react-navigation/native'
import { Channels, useNotificationsStore } from '@vatom/sdk/react'

import { AppRoutes } from '../navigators'

import { useBusinessSelector, useInventoryByBusiness } from './useBusinessSelector'

export const useAutoOpen = () => {
  const isFocused = useIsFocused()

  const navigation = useNavigation()
  const { business } = useBusinessSelector()

  const autoOpen = business?.pageConfig?.features?.vatom?.autoOpen ?? []
  const autoOpenChannel = useNotificationsStore.getState().channels[Channels.autoOpen]

  const hasPendingAutoOpens = autoOpen.some(id => {
    return !autoOpenChannel?.find(obj => obj.objectDefinitionId === id)
  })

  const isDeferrer = !isFocused || !hasPendingAutoOpens
  const businessInventory = useInventoryByBusiness({
    defer: isDeferrer
  })

  if (!businessInventory?.tokens.length) {
    return
  }

  const tokenToOpen = businessInventory?.tokens?.find(token => {
    const result =
      token.studioInfo?.objectDefinitionId &&
      autoOpen.includes(token.studioInfo?.objectDefinitionId) &&
      autoOpenChannel.find(obj => obj.id === token.id) === undefined
    return !!result
  })
  if (!tokenToOpen || !tokenToOpen.studioInfo?.objectDefinitionId) {
    return
  }

  useNotificationsStore.getState().addNotification(Channels.autoOpen, {
    id: tokenToOpen.id,
    objectDefinitionId: tokenToOpen.studioInfo?.objectDefinitionId
  })

  navigation.navigate(AppRoutes.NFTDetail_Business, {
    tokenId: tokenToOpen.id,
    business: business?.id as string
  })
}

import React from 'react'
import { Text, theme } from '@vatom/wombo'
import { Box, Divider, Input } from 'native-base'

interface IPriceDetails {
  royalties: number
  marketplace: number
  sellPrice: string
  setSellPrice: (t: string) => void
}

export const PriceDetails = ({
  royalties,
  marketplace,
  sellPrice,
  setSellPrice
}: IPriceDetails) => {
  return (
    <Box marginBottom="22px">
      <Text
        fontSize={13}
        fontWeight="600"
        textAlign="left"
        marginBottom="4px"
        marginTop="27px"
        _light={{
          color: theme.colors.textLightMode[900]
        }}
        _dark={{
          color: theme.colors.textDarkMode[100]
        }}
      >
        Price
      </Text>
      <Input
        accessibilityHint=""
        accessibilityLabel=""
        value={sellPrice}
        onChangeText={text => setSellPrice(text)}
        clearButtonMode="always"
        keyboardType="decimal-pad"
        leftElement={
          <Box flexDirection="row" alignItems="center" justifyContent="flex-start">
            <Text
              marginX="12px"
              _light={{
                color: theme.colors.textLightMode[300]
              }}
              _dark={{
                color: theme.colors.textDarkMode[300]
              }}
            >
              $
            </Text>
            <Divider
              backgroundColor="extraLightText"
              orientation="vertical"
              height="28px"
              padding={0}
              margin={0}
              _light={{
                backgroundColor: theme.colors.grayCool[300]
              }}
              _dark={{
                backgroundColor: theme.colors.grayDarkMode[500]
              }}
            />
          </Box>
        }
        size="md"
        fontSize={15}
        width="100%"
        height="38px"
        margin={0}
        paddingLeft={2.5}
        _light={{
          backgroundColor: theme.colors.white,
          borderColor: theme.colors.grayCool[300],
          placeholderTextColor: theme.colors.textLightMode[100]
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[800],
          borderColor: theme.colors.grayDarkMode[500],
          placeholderTextColor: theme.colors.textDarkMode[600]
        }}
      />
      <Text
        _light={{
          color: theme.colors.textLightMode[300]
        }}
        _dark={{
          color: theme.colors.textDarkMode[300]
        }}
        marginTop="20px"
        marginBottom="16px"
        fontWeight="400"
        fontSize="13px"
      >
        The proceeds from the sale of this item will be distributed as follows:
      </Text>

      <Box marginBottom="8px" flexDirection="row" justifyContent="space-between" width="100%">
        <Text
          _light={{
            color: theme.colors.textLightMode[900]
          }}
          _dark={{
            color: theme.colors.textDarkMode[100]
          }}
          fontSize="13px"
          fontWeight="400"
        >
          You (Seller):
        </Text>
        <Text
          _light={{
            color: theme.colors.textLightMode[900]
          }}
          _dark={{
            color: theme.colors.textDarkMode[100]
          }}
          fontSize="13px"
          fontWeight="400"
        >
          $
          {sellPrice !== ''
            ? parseFloat(
                String(((100 - marketplace - royalties) / 100) * parseInt(sellPrice))
              ).toFixed(2)
            : '0.00'}{' '}
          (minus fees)
        </Text>
      </Box>
      <Box marginBottom="8px" flexDirection="row" justifyContent="space-between" width="100%">
        <Text
          _light={{
            color: theme.colors.textLightMode[900]
          }}
          _dark={{
            color: theme.colors.textDarkMode[100]
          }}
          fontSize="13px"
          fontWeight="400"
        >
          Creator Royalties:
        </Text>
        <Text
          _light={{
            color: theme.colors.textLightMode[900]
          }}
          _dark={{
            color: theme.colors.textDarkMode[100]
          }}
          fontSize="13px"
          fontWeight="400"
        >
          $
          {sellPrice !== ''
            ? parseFloat(String((parseInt(sellPrice) * royalties) / 100)).toFixed(2)
            : '0.00'}
        </Text>
      </Box>
      <Box marginBottom="8px" flexDirection="row" justifyContent="space-between" width="100%">
        <Text
          _light={{
            color: theme.colors.textLightMode[900]
          }}
          _dark={{
            color: theme.colors.textDarkMode[100]
          }}
          fontSize="13px"
          fontWeight="400"
        >
          Marketplace:
        </Text>
        <Text
          _light={{
            color: theme.colors.textLightMode[900]
          }}
          _dark={{
            color: theme.colors.textDarkMode[100]
          }}
          fontSize="13px"
          fontWeight="400"
        >
          $
          {sellPrice !== ''
            ? parseFloat(String((parseInt(sellPrice) * marketplace) / 100)).toFixed(2)
            : '0.00'}
        </Text>
      </Box>
    </Box>
  )
}

export default PriceDetails

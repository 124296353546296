import React, { useEffect } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { View } from 'native-base'

import theme from '../theme'

import { Loader } from './Loader'

export type LoaderViewProps = {
  size?: number
  blocking?: boolean
  style?: StyleProp<ViewStyle>
  loaderColor?: string
}

export const LoaderView = ({ style, loaderColor, size = 50, blocking = true }: LoaderViewProps) => {
  return (
    <View
      position="absolute"
      top={0}
      left={0}
      w="100%"
      h="100%"
      _dark={{
        bg: blocking ? theme.colors.grayCool[900] : 'transparent'
      }}
      _light={{
        bg: blocking ? theme.colors.white : 'transparent'
      }}
      zIndex={10000}
      alignItems="center"
      justifyContent="center"
      style={style}
    >
      <Loader size={size} color={loaderColor} />
    </View>
  )
}

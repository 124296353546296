import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useGetSpace, useMatrixUser } from '@vatom/sdk/react'
import { IEvent } from 'matrix-js-sdk'
import moment from 'moment-timezone'

import { useBusinessSelector } from '../../../../../../hooks/useBusinessSelector'
import { AppNavigation, navigateToConnectScreen, RoomRoutes } from '../../../../../../navigators'
import { useMessagePollVotes } from '../../../../queries'
import { useCommunitiesTheme } from '../../../../themes'
import { SketchType } from '../../../../types'
import { useCommunitySpace } from '../../../../useCommunitySpace'
import CheckIcon from '../../../AddModals/assets/check_icon.svg'
import ArrowRightIcon from '../../../modal-components/ArrowRightIcon'
import { useMessageContext } from '../../MessageContext'
import EndsInNote from '../EndsInNote'

import Sketch from './Sketch'

type QuestionsProps = {
  sketches: {
    content: SketchType[]
  }
  spaceId: string
  isThread: boolean
  additionalInfo: any
}

const Sketches = (props: QuestionsProps) => {
  const { business } = useBusinessSelector()
  const businessId = business?.id ?? ''
  const { messageEventID } = useMessageContext()
  const { community } = useCommunitySpace()
  const { sketches, spaceId, isThread, additionalInfo } = props
  const space = useGetSpace(spaceId, {
    enabled: false
  })
  const roomId = space.data?.matrixRoomId ?? ''
  const { data: matrixUser } = useMatrixUser()
  const userId = matrixUser?.user_id
  const navigation = useNavigation<AppNavigation>()
  const communitiesTheme = useCommunitiesTheme()
  const sketchesLength = sketches?.content?.length ?? 0

  const { startTime, endTime } = additionalInfo

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useMessagePollVotes(
    roomId,
    messageEventID,
    'sketch.vote'
  )
  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage()
  }

  const userSketchVotes =
    data?.pages.flatMap(group => {
      return group?.chunk?.filter((pollVote: IEvent) => pollVote.sender === userId)
    }) ?? []

  if (!messageEventID) {
    return null
  }

  return (
    <View style={{ marginTop: 12, marginBottom: 16 }}>
      {isThread ? (
        <View
          style={{
            height: 1,
            width: '150%',
            backgroundColor: communitiesTheme.messageList.border,
            position: 'absolute',
            flex: 1,
            left: -20
          }}
        />
      ) : null}

      {!isThread ? (
        <TouchableOpacity
          accessibilityRole="button"
          style={{
            ...styles.showQuestionsButton,
            backgroundColor: communitiesTheme.sketches.backgroundColor
          }}
          onPress={() => {
            navigateToConnectScreen(navigation, RoomRoutes.RoomThread, {
              spaceId,
              business: businessId,
              community,
              messageId: messageEventID
            })
          }}
        >
          <View>
            <Text
              key="question-number"
              style={{
                fontSize: 15,
                lineHeight: 20,
                fontWeight: '600',
                color: communitiesTheme.sketches.titleColor
              }}
            >
              {sketchesLength} {sketchesLength === 1 ? 'Sketch' : 'Sketches'}
            </Text>
            {userSketchVotes.length > 0 ? (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <CheckIcon fill="#868E96" width={10} height={10} />
                <Text style={{ fontSize: 11, lineHeight: 15, color: '#868E96' }}>
                  {' '}
                  You voted on {moment(userSketchVotes[0].origin_server_ts).format('MMM D')}
                </Text>
              </View>
            ) : null}
          </View>

          <ArrowRightIcon style={{ marginLeft: 12 }} />
        </TouchableOpacity>
      ) : (
        <View style={styles.detailsContainer}>
          <Text
            key="question-number"
            style={{
              ...styles.questionsNumber,
              color: communitiesTheme.sketches.titleColor,
              paddingVertical: 14
            }}
          >
            {sketchesLength} {sketchesLength === 1 ? 'Sketch' : 'Sketches'}
          </Text>
        </View>
      )}

      {isThread
        ? sketches?.content?.map(sketch => (
            <Sketch
              key={sketch.id}
              sketch={sketch}
              roomId={roomId}
              additionalInfo={additionalInfo}
              spaceId={spaceId}
            />
          ))
        : null}
      <EndsInNote startTime={startTime} endTime={endTime} beforeText="Ends in" />
    </View>
  )
}

export default Sketches

const styles = StyleSheet.create({
  detailsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  questionsNumber: {
    fontSize: 17,
    lineHeight: 24,
    fontWeight: '700'
  },
  numberAnswered: {
    fontSize: 11,
    lineHeight: 15,
    color: '#868E96'
  },
  showQuestionsButton: {
    padding: 16,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

import React from 'react'
import { useWindowDimensions } from 'react-native'
import WebView from 'react-native-webview'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { useAppConfig, useSDK } from '@vatom/sdk/react'
import { theme } from '@vatom/wombo'
import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import { Box, Flex, Image } from 'native-base'

import { HeaderButtonClose, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useMaxWidth, WebWrapper } from '../../components/WebWrapper'
import logger from '../../logger'
import { headerMaxHeight } from '../Home/partials/DesktopHeader'
import { VatomMenu } from '../Home/partials/VatomMenu'

const vatomLogoImage = require('../Home/assets/vatom-logo.xl.png')

export const EditProfile = observer(() => {
  const { height } = useWindowDimensions()
  const sdk = useSDK()
  const maxMinWidth = useMaxWidth()

  const navigation = useNavigation()
  const config = useAppConfig()

  const goBack = debounce(async () => {
    sdk.dataPool.user.fetchMe()
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }
    window.close()
  }, 250)
  logger.info(`${config.data?.api.oidc}/profile?back=true&layout=embedded`)

  const width = '100%'
  const maxWidth = '100%'
  const minWidth = maxMinWidth

  const Header = () => (
    <Box
      flex={1}
      flexDirection={'row'}
      alignItems={'center'}
      paddingY={3}
      paddingX={5}
      backgroundColor={theme.colors.white}
      borderBottomColor={theme.colors.trueGray[50]}
      borderBottomWidth={1}
      maxHeight={headerMaxHeight}
      width={width}
    >
      <Box mr={10}>
        <Image
          accessibilityIgnoresInvertColors
          source={{ uri: vatomLogoImage }}
          style={{ width: 42, height: 36 }}
        />
      </Box>
      <Box flex={1}></Box>
      <Box flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'} marginLeft={5}>
        <VatomMenu />
      </Box>
    </Box>
  )

  return (
    <Flex bgColor={theme.colors.white} flex={1} alignItems="center" alignContent="center">
      <Header />
      <ScreenWrapper
        statusBarBackgroundColor={theme.colors.white}
        viewContainerStyle={{ width: '100%' }}
      >
        <WebWrapper
          style={{
            height: '100%'
          }}
        >
          <Box position="absolute" top={2} left="16px" zIndex={999}>
            <ScreenHeaderButton onPress={goBack}>
              <HeaderButtonClose color={theme.colors.black} />
            </ScreenHeaderButton>
          </Box>
          <WebView
            originWhitelist={['*']}
            source={{ uri: `${config.data?.api.oidc}/profile?back=true&layout=embedded` }}
            style={{ flex: 1, width, height, maxWidth, minWidth }}
            scalesPageToFit={false}
          />
        </WebWrapper>
      </ScreenWrapper>
    </Flex>
  )
})
export default EditProfile

import { useRef } from 'react'
import { RectButton, Swipeable, SwipeableProps } from 'react-native-gesture-handler'
import { Text, theme } from '@vatom/wombo'
import { Box, Center, useColorModeValue, View } from 'native-base'

import { DeleteIcon, EditIcon } from '../../../components/Icons'

export const ItemSwipeable = ({
  children,
  simultaneousHandlers,
  onDelete,
  onEdit,
  onPress
}: React.PropsWithChildren<{
  simultaneousHandlers: SwipeableProps['simultaneousHandlers']
  onDelete?: () => void
  onEdit?: () => void
  onPress?: () => void
}>) => {
  const swipeableRef = useRef(null)
  const editIconColor = '#fff'
  const editBackgroundColor = useColorModeValue(
    theme.colors.grayCool[500],
    theme.colors.grayDarkMode[800]
  )
  const deleteBackgroundColor = useColorModeValue(
    theme.colors.systemColorsLight.red,
    theme.colors.systemColorsDark.red
  )

  const renderRightActions = () => {
    return (
      <>
        {onDelete ? (
          <RectButton
            style={{
              flex: 1,
              backgroundColor: deleteBackgroundColor,
              minWidth: 54,
              maxWidth: 72,
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onPress={() => {
              if (swipeableRef.current) {
                /* @ts-ignore */
                swipeableRef.current.close()
              }
              onDelete()
            }}
          >
            <Box
              style={{
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <Center size={8}>
                <DeleteIcon size={4} fill={'#fff'} />
              </Center>
              <Text
                fontSize={13}
                lineHeight={18}
                fontFamily={'Inter-Regular'}
                marginTop={1}
                color={editIconColor}
              >
                Delete
              </Text>
            </Box>
          </RectButton>
        ) : null}

        {onEdit ? (
          <RectButton
            style={{
              flex: 1,
              backgroundColor: editBackgroundColor,
              minWidth: 54,
              maxWidth: 72,
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onPress={() => {
              if (swipeableRef.current) {
                /* @ts-ignore */
                swipeableRef.current.close()
              }
              onEdit()
            }}
          >
            <Box
              style={{
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <Center size={8}>
                <EditIcon size={4} fill={editIconColor} />
              </Center>
              <Text
                fontSize={13}
                lineHeight={18}
                fontFamily={'Inter-Regular'}
                marginTop={1}
                color={editIconColor}
              >
                Edit
              </Text>
            </Box>
          </RectButton>
        ) : null}
      </>
    )
  }

  return (
    <Swipeable
      ref={swipeableRef}
      renderRightActions={renderRightActions}
      simultaneousHandlers={simultaneousHandlers}
      containerStyle={{ flexGrow: 1, marginHorizontal: -16 }}
    >
      <RectButton onPress={onPress}>
        <View style={{ marginHorizontal: 16 }}>{children}</View>
      </RectButton>
    </Swipeable>
  )
}

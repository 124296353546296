import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  ethNetworksChains,
  moralis,
  solNetworksChains,
  TEthereumChains,
  TSolanaChains,
  UserIdentity
} from '@vatom/sdk/core'
import { allSettled, isFulfilled, isRejected, useSDK } from '@vatom/sdk/react'
import { ethers } from 'ethers'

import { ItemCoins } from '../screens/CoinDetail/partials/TransactionItem'
import { getChainScanId } from '../screens/ConfirmationWeb3/TransactionLink'

import { Coin } from './useLoyalty'

// const moralisServerUrl = 'https://parser.api.vatominc.com/server'
// const moralisAppId = 'KPwA7E3j_p5Pvme1XRhkF'

type balance = {
  balance: string
}

const getChainInfo = (chain: TSolanaChains | TEthereumChains) => {
  const logos = {
    eth: 'https://w7.pngwing.com/pngs/268/1013/png-transparent-ethereum-eth-hd-logo.png',
    polygon: 'https://logowik.com/content/uploads/images/polygon-matic-icon3725.logowik.com.webp',
    bsc: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png',
    devnet: 'https://cryptologos.cc/logos/solana-sol-logo.png',
    mainnet: 'https://cryptologos.cc/logos/solana-sol-logo.png'
  }
  const names = {
    eth: 'Ethereum',
    polygon: 'Polygon',
    bsc: 'Binance',
    devnet: 'Solana testnet',
    mainnet: 'Solana',
    goerli: 'Goerli'
  }

  return {
    name: names[chain as keyof typeof names] || names.eth,
    plural_name: names[chain as keyof typeof names] || names.eth,
    logo: logos[chain as keyof typeof logos] || logos.eth
  }
}

export const fetchEthereumBalances = async (address: string) => {
  await moralis.default.User.currentAsync()
  const web3Api = moralis.default.Web3API

  try {
    const allSettledResult = await allSettled(
      ethNetworksChains.map(async chain => {
        const chainBalance: balance = await web3Api.account.getNativeBalance({
          address: address,
          chain
        })

        // crate a big number with wei value
        const balanceInWei = ethers.toBigInt(chainBalance.balance)

        // chage wei to ether
        const etherBalance = ethers.formatEther(balanceInWei)

        //round valueto from "0.0003595599083115" to 0.000359559
        const roundedValue = Math.round(parseFloat(etherBalance) * 1000000) / 1000000
        const id = address + '_' + chain

        const point = {
          id: id,
          // this is the value og vatom businessId, this allows us to use the same config
          businessId: 'nQwtevgfOa',
          points: roundedValue,
          enabled: true,
          userId: address,
          symbol: chain.toUpperCase(),
          type: 'eth',
          address: address,
          chain: chain,
          isWeb3: true,
          ...getChainInfo(chain)
        }

        return point
      })
    )
    const balances = allSettledResult.filter(isFulfilled).map(p => p.value)
    const errors = allSettledResult.filter(isRejected).map(p => p.reason)
    if (errors.length > 0) {
      console.error('fetchEthereumBalances.error', errors)
    }
    return balances
  } catch (error) {
    console.error('fetchEthereumBalances.error', error)
    return []
  }
}

export const roundFloatValue = (floatNumber: string) => {
  return Math.round(parseFloat(floatNumber) * 1000000) / 1000000
}

export const fetchSolanaBalances = async (address: string) => {
  try {
    await moralis.default.User.currentAsync()

    const balances = await Promise.all(
      // fetch all solana balances
      solNetworksChains.map(async chain => {
        const res = await (moralis.default as any).SolanaAPI?.account?.balance({
          address: address,
          network: chain
        })

        const id = address + '_' + chain

        return {
          id: id,
          // this is the value og vatom businessId, this allows us to use the same config
          businessId: 'nQwtevgfOa',
          points: roundFloatValue(res?.solana),
          enabled: true,
          userId: address,
          symbol: chain.toUpperCase(),
          type: 'sol',
          address: address,
          chain: chain,
          isWeb3: true,
          ...getChainInfo(chain)
        }
      })
    )

    return balances
  } catch (error) {
    console.error('fetchSolanaBalances.error', error)
    return []
  }
}

export const fetchUserBalances = async (identities: UserIdentity[]) => {
  try {
    const promises = identities
      .filter(i => !i.custodial)
      .filter(i => i.type === 'sol')
      .map(async identity => {
        if (identity.type === 'eth') {
          return await fetchEthereumBalances(identity.value)
        } else if (identity.type === 'sol') {
          return await fetchSolanaBalances(identity.value)
        }
      })

    const results = await allSettled(promises)

    const fulfilledValues = results.filter(isFulfilled).map(p => p.value)
    const rejectedReasons = results.filter(isRejected).map(p => p.reason)

    rejectedReasons.forEach(error => {
      console.error(error)
    })

    return fulfilledValues.flat().filter(v => v !== null) as Coin[]
  } catch (e) {
    console.error('fetchUserBalances.error: ', e)
    throw e
  }
}
export type FungibleTokenOptions<T = Coin[]> = Omit<
  UseQueryOptions<Coin[], unknown, T, ['fungibleTokens', 'balance']>,
  'queryKey' | 'queryFn'
>

export const useFungibleTokens = <T = Coin[]>(options: FungibleTokenOptions<T> = {}) => {
  const sdk = useSDK()
  const identities = sdk?.dataPool.user.identities

  const query = useQuery({
    queryKey: ['fungibleTokens', 'balance'],
    queryFn: () => fetchUserBalances(identities),
    enabled: !!sdk && !!identities,

    staleTime: 1000 * 60 * 2,
    ...options
  })

  return query
}

export const useFungibleToken = (id: string) => {
  const { data: fungibleToken } = useFungibleTokens({
    select: tokens => tokens.find(token => token.id === id)
  })

  return fungibleToken ? (fungibleToken as unknown as Coin) : null
}

export const fetchEthTransactions = async (
  chain?: TEthereumChains | TSolanaChains,
  address?: string
) => {
  try {
    if (!address || !chain) {
      return [] as ItemCoins[]
    }

    const web3Api = moralis.default.Web3API

    const chainTransactions = await web3Api.account.getTransactions({
      address: address,
      chain: chain as TEthereumChains
    })

    const transactions = (chainTransactions.result || []).map((tx: any) => {
      const date = new Date(tx.block_timestamp)
      const value = ethers.formatEther(tx.value)

      const scanUrl = getChainScanId(chain as TEthereumChains, tx.hash)
      return {
        type: 'crypto',
        id: tx.hash,
        timeAgo: date.toLocaleDateString(),
        operationType: tx.from_address === address ? 'send' : 'receive',
        from: tx.from_address,
        to: tx.to_address,
        amount: value,
        scanUrl: scanUrl
      }
    })

    return transactions as ItemCoins[]
  } catch (error) {
    console.error('fetchEthTransactions.error', error)
    return [] as ItemCoins[]
  }
}

// export const fetchSolTransactions = async (address: string, chain: TSolanaChains) => {
//   try {
//     const res = await (moralis.default as any).SolanaAPI?.account?.transactions({
//       address: address,
//       network: chain
//     })

//     return res
//   } catch (error) {
//     console.error('fetchSolTransactions.error', error)
//     return []
//   }
// }

export const useEthTransactions = (chain?: TEthereumChains | TSolanaChains, address?: string) => {
  const query = useQuery({
    queryKey: ['eth', 'transactions', address, chain],
    queryFn: () => fetchEthTransactions(chain, address)
  })
  return query
}

// export const useSolTransactions = (address: string, chain: string) => {
//   const query = useQuery({
//     queryKey: ['sol', 'transactions', address, chain],
//     queryFn: () => fetchSolTransactions(address, chain as TSolanaChains),
//     enabled: !!address && !!chain
//   })
//   return query
// }

export const useWeb3Transactions = (chain?: TEthereumChains | TSolanaChains, address?: string) => {
  const { data, ...rest } = useEthTransactions(chain, address)
  // const { data: solTransactions } = useSolTransactions(
  //   fungibleToken.address || '',
  //   fungibleToken.id
  // )

  // return {
  //   ethTransactions
  //   // solTransactions
  // }
  return { data: (data || []) as ItemCoins[], ...rest }
}

import React, { useRef } from 'react'
import Animated from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { translate } from '@vatom/utils'
import { theme, Toast } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import {
  Box,
  Center,
  HStack,
  Input,
  Pressable,
  Text,
  useColorModeValue,
  VStack,
  WarningIcon
} from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import { ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'

export const BlockChainNetworkAdd = observer(() => {
  const { pageTheme } = useBusinessTheme()
  const scrollRef = useRef<Animated.ScrollView | null>(null)
  const insets = useSafeAreaInsets()

  // TODO: update value
  const isSaveEnabled = false

  const headerIconColor = useColorModeValue(
    theme.colors.textLightMode[900],
    theme.colors.textDarkMode[100]
  )

  const onPressAdd = useThrottledCallback(
    () => {
      console.log('LOG: BlockchainNetwork > Add New!!')
      Toast({
        title: 'Add new network not implemented yet!',
        status: 'warning',
        duration: 1500
      })
    },
    300,
    {
      leading: true
    }
  )

  return (
    <ScreenWrapper
      screenBackgroundColor={pageTheme.background}
      statusBarBackgroundColor={pageTheme.background}
      viewContainerStyle={{
        width: '100%',
        backgroundColor: pageTheme.background
      }}
      unsafe
    >
      <ScreenHeader
        headerLeftType={'back'}
        headerTextColor={headerIconColor}
        headerProps={{
          paddingTop: insets.top,
          paddingBottom: 1,
          overflow: 'hidden'
        }}
        centerContainerProps={{
          flex: 1,
          paddingX: 2
        }}
        leftContainerProps={{
          flex: 0,
          _web: {
            flexGrow: 0,
            flexShrink: 1,
            flexBasis: 'auto'
          }
        }}
        rightContainerProps={{
          flex: 0,
          _web: {
            flexGrow: 0,
            flexShrink: 1,
            flexBasis: 'auto'
          }
        }}
        headerRight={() => <ScreenHeaderButton />}
      >
        <Title preset="h5" variant="semibold" numberOfLines={1}>
          Add Custom Network
        </Title>
      </ScreenHeader>

      <Animated.ScrollView
        ref={scrollRef}
        style={{
          paddingHorizontal: 16,
          marginTop: 12
        }}
        contentContainerStyle={{
          paddingBottom: 60 // headerHeight
        }}
      >
        <VStack flexGrow={1} marginTop={0}>
          <Box marginBottom={3}>
            <Text>Network Name</Text>
            <Input placeholder="Add a network name" marginTop={1} />
          </Box>
          <Box marginBottom={3}>
            <Text>RPC URL</Text>
            <Input placeholder="Add a RPC url" marginTop={1} />
          </Box>
          <Box marginBottom={3}>
            <Text>Chain ID</Text>
            <Input placeholder="Add a chain ID" marginTop={1} />
          </Box>
          <Box marginBottom={3}>
            <Text>Currency Symbol</Text>
            <Input placeholder="Add currency symbol" marginTop={1} />
          </Box>
          <Box marginBottom={3}>
            <Text>Block explorer URL (optional)</Text>
            <Input placeholder="" marginTop={1} />
          </Box>
          <Box>
            <Disclaimer />
          </Box>
        </VStack>

        <Box marginTop={4} marginX={0}>
          <Pressable
            accessibilityRole="button"
            onPress={onPressAdd}
            flexGrow={1}
            paddingY={3}
            paddingX={2}
            borderRadius={4}
            _light={{
              backgroundColor: theme.colors.systemColorsLight.orange
            }}
            _dark={{
              backgroundColor: theme.colors.systemColorsDark.orange
            }}
            _disabled={{
              _light: {
                backgroundColor: theme.colors.grayCool[400]
              },
              _dark: {
                backgroundColor: theme.colors.grayDarkMode[600]
              }
            }}
            disabled={!isSaveEnabled}
          >
            <HStack alignItems={'center'} justifyContent={'center'}>
              <Text
                fontSize={16}
                lineHeight={22}
                fontFamily={'Inter-Regular'}
                letterSpacing={0.2}
                _light={{
                  color: isSaveEnabled ? '#fff' : theme.colors.grayCool[200]
                }}
                _dark={{
                  color: isSaveEnabled ? '#fff' : theme.colors.textDarkMode[600]
                }}
              >
                Add new network
              </Text>
            </HStack>
          </Pressable>
        </Box>
      </Animated.ScrollView>
    </ScreenWrapper>
  )
})

function Disclaimer() {
  return (
    <HStack>
      <Center marginRight={2}>
        <WarningIcon />
      </Center>
      <Text
        flex={1}
        fontSize={13}
        fontFamily={'Inter-Light'}
        _light={{
          color: theme.colors.textLightMode[600]
        }}
        _dark={{
          color: theme.colors.textDarkMode[300]
        }}
      >
        Some networks may be used for malicious purposes. Only add networks that you trust.
      </Text>
    </HStack>
  )
}

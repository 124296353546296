import React, { useMemo } from 'react'
import { FlatList, Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import {
  Contact,
  ContactSources,
  Destination,
  DestinationTypes,
  useGetContacts
} from '@vatom/sdk/react'
import { Avatar, PressableOpacity, Text, theme } from '@vatom/wombo'
import { View } from 'native-base'

import { AppRoutes } from '../../../navigators'
import { isValidEthereumAddress } from '../../../utils/isValidAddress'

import { SendStorage } from './Constants'
import { useSender } from './SendProvider'
import { ResultListItemProps } from './Types'

export default function RecentList() {
  const { token, isShare, contractAddress, owner } = useSender()

  const contacts = useGetContacts(
    {
      type: DestinationTypes.Id,
      value: ''
    },
    {
      tokenType: token.getTokenType(),
      sources: [ContactSources.recents, ContactSources.tokenhistory, ContactSources.devicecontacts]
    }
  )

  const isSelfCustodialTransaction = contractAddress && owner
  const data = useMemo(() => {
    if (!isSelfCustodialTransaction) {
      return contacts.data
    }
    // TODO: REVIEW: isCustodial not found
    return contacts.data.filter(d => d.identities?.some(i => !i.isCustodial && i.type === 'eth'))
  }, [contacts.data, isSelfCustodialTransaction])

  if (!token) return null

  return data.length !== 0 ? (
    <View>
      <Text
        fontFamily="Inter-Bold"
        marginBottom="10px"
        fontSize="22px"
        fontWeight="700"
        color="black"
        _light={{
          color: theme.colors.textLightMode[900]
        }}
        _dark={{
          color: theme.colors.textDarkMode[100]
        }}
        tx="common.recent"
      />
      <FlatList
        data={data}
        keyExtractor={_item => `recent-item-${_item.id}`}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }) => (
          <RecentItem
            contractAddress={contractAddress}
            owner={owner}
            item={item}
            token={token}
            isShare={isShare}
          />
        )}
      />
    </View>
  ) : null
}

const RecentItem = (props: ResultListItemProps) => {
  const navigation = useNavigation()
  const { token, item, isShare, contractAddress, owner } = props

  const handleOnPressItem = () => {
    if (!token) return
    try {
      const props: {
        tokenId: string
        userToSend: Contact | undefined
        destination: Destination | undefined
        isShare: boolean
      } = { tokenId: token.id, isShare, userToSend: undefined, destination: undefined }

      if (item && 'identities' in item) {
        props.userToSend = item
      }

      if (item && 'value' in item) {
        props.destination = item
      }

      if (contractAddress && owner) {
        const toUserId =
          props?.userToSend && !isValidEthereumAddress(props?.userToSend.id)
            ? props?.userToSend.id
            : undefined

        const toAddress =
          props.destination && isValidEthereumAddress(props.destination?.validatedValue ?? '')
            ? props.destination.validatedValue
            : undefined

        if (toAddress) {
          navigation.navigate(AppRoutes.sendNFTConfirmation, {
            tokenId: token.id,
            owner,
            contractAddress,
            toAddress
          })
        } else if (toUserId) {
          navigation.navigate(AppRoutes.sendNFTConfirmation, {
            tokenId: token.id,
            owner,
            contractAddress,
            toUserId
          })
        }
        // setOpen(false)
        return
      }

      if (Platform.OS === 'web') {
        localStorage.setItem(SendStorage.Destination, JSON.stringify(props.destination))
        localStorage.setItem(SendStorage.UserToSend, JSON.stringify(props.userToSend))

        navigation.navigate(AppRoutes.sendConfirmation, {
          tokenId: props.tokenId,
          isShare: props.isShare
        })
      } else {
        navigation.navigate(AppRoutes.sendConfirmation, props)
      }
    } catch (error) {
      console.warn('SendNFT.RecentList: error', error)
    } finally {
      // setOpen(false)
    }
  }

  return item ? (
    <PressableOpacity marginRight="10px" onPress={handleOnPressItem}>
      {item.id ? (
        <Avatar size={62} url={item.avatarUrl} name={item.name} />
      ) : (
        <Avatar size={62} name={item.value} />
      )}
      <Text noOfLines={2} w={62} textAlign={'center'}>
        {item?.name || item?.value || ''}
      </Text>
    </PressableOpacity>
  ) : null
}

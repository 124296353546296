import { useContext } from 'react'

import { UserContext } from '../components/UserProvider'
import { UserProviderTypes } from '../types'

export function useUser<CampaignUserInfo = unknown, UserInfo = unknown>() {
  const context = useContext<UserProviderTypes<CampaignUserInfo, UserInfo>>(
    UserContext as unknown as React.Context<UserProviderTypes<CampaignUserInfo, UserInfo>>
  )
  if (!context) {
    throw new Error('useMyContext must be used under MyContextProvider')
  }
  return context
}

import React from 'react'
import { Entypo } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { Avatar, Text } from '@vatom/wombo'
import { Box, Image, Pressable } from 'native-base'

import { AppRoutes } from '../../../navigators'

export const Header = () => {
  const navigation = useNavigation()
  // const [assets /*, error*/] = useAssets([require('../assets/profile.png')])

  return (
    <Box bgColor="brand.500">
      <Box flexDir="row" justifyContent="space-between" px={5} py={2}>
        <Pressable
          accessibilityRole="button"
          w={10}
          alignItems="flex-start"
          onPress={() => navigation.navigate(AppRoutes.home)}
        >
          <Entypo name="chevron-small-left" size={40} color="white" />
        </Pressable>
        <Text
          color="white"
          fontFamily="Inter-SemiBold"
          fontSize={17}
          fontWeight="600"
          alignSelf="center"
          tx="common.settings"
        />
        <Pressable w={10} alignItems="flex-end" accessibilityRole="button">
          <Text color="white" fontSize={15} tx="common.done" />
        </Pressable>
      </Box>
      <Box h={180}>
        <Image
          source={require('../assets/profile-bg.png')}
          h="100%"
          w="100%"
          accessibilityIgnoresInvertColors={true}
          alt="Profile BG"
          position="absolute"
          justifyContent="center"
          flex={1}
        />
        <Avatar size={44} url="" />
      </Box>
    </Box>
  )
}

export default Header

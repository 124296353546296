import { IdentityType } from '@vatom/sdk/core'
import {
  AddressBalance,
  AllBalances,
  defaultNetworks,
  useAddressesBalance,
  useAllTokenBalances,
  useIdentities
} from '@vatom/sdk/react'
import { Network, Utils } from 'alchemy-sdk'

import { Coin } from '../../hooks/useLoyalty'
import { useWalletStore } from '../BlockChainWallets/WalletStore'

const BalancesToCoinsSelector = (
  balances: AllBalances,
  isSelfCustodial: boolean,
  isWatching: boolean
): Array<Coin> => {
  return balances.map(b => {
    const { address, network, tokenMetadata, tokenBalance } = b
    // const roundedValue = Math.round(parseFloat(tokenBalance.tokenBalance) * 1000000) / 1000000
    // id = 'xxxx_eth-mainnet_eth'
    const id = address + '_' + network + '_' + tokenMetadata?.symbol
    //   hex string to number

    const coin: Coin = {
      id: id,
      businessId: 'nQwtevgfOa',
      name: tokenMetadata?.symbol ?? '',
      plural_name: tokenMetadata?.symbol ?? '',
      logo: tokenMetadata?.logo ?? '',
      // @ts-ignore
      points: Utils.formatUnits(tokenBalance.tokenBalance ?? 0, tokenMetadata?.decimals ?? 18),
      type: 'eth',
      chain: [Network.ETH_MAINNET, Network.ETH_SEPOLIA].includes(network) ? 'eth' : 'polygon',
      enabled: true,
      symbol: tokenMetadata?.symbol ?? '',
      address: address,
      userId: '',
      isWeb3: false,
      isSelfCustodial,
      isWatching,
      contractAddress: tokenBalance.contractAddress,
      alchemyNetwork: network
    }

    return coin
  })
}

const DefaultTokenToCoinSelector = (
  res: AddressBalance,
  isSelfCustodial: boolean,
  isWatching: boolean
): Coin => {
  const { address, network, symbol, balance } = res
  const id = address + '_' + network
  //   hex string to number

  const coin: Coin = {
    id: id,
    businessId: 'nQwtevgfOa',
    name: symbol ?? '',
    plural_name: symbol ?? '',
    logo: '',
    // @ts-ignore
    points: Utils.formatUnits(balance, 18),
    type: 'eth',
    chain: [Network.ETH_MAINNET, Network.ETH_SEPOLIA].includes(network) ? 'eth' : 'polygon',
    enabled: true,
    symbol,
    address: address,
    userId: '',
    isWeb3: false,
    isSelfCustodial,
    isWatching,
    alchemyNetwork: network
  }

  return coin
}

export const useSelfCustodialFungibleTokens = () => {
  const selfCustodialAddresses = useWalletStore(walletState =>
    walletState.wallets.map(wallet => wallet.address)
  )

  const addressesBalancesQueries = useAddressesBalance(
    {
      addresses: selfCustodialAddresses,
      networks: defaultNetworks
    },
    {
      select: res => DefaultTokenToCoinSelector(res, true, false)
    }
  )

  const { isLoading, data, isRefetching } = useAllTokenBalances(
    {
      addresses: selfCustodialAddresses,
      networks: defaultNetworks
    },
    {
      select: AllBalances => BalancesToCoinsSelector(AllBalances, true, false)
    }
  )

  return {
    coins: [...(addressesBalancesQueries.map(r => r.data) as Coin[]), ...(data ?? [])],
    isLoading,
    isRefetching
  }
}

export const useWatchingFungibleTokens = () => {
  const { identities } = useIdentities()
  const selfCustodialAddresses = useWalletStore(walletState =>
    walletState.wallets.map(wallet => wallet.address.toLowerCase())
  )
  const watchingAddresses = identities
    .filter(
      identity =>
        !identity.custodial &&
        identity.type === IdentityType.Eth &&
        !selfCustodialAddresses.includes(identity.value)
    )
    .map(address => address.value)

  const addressesBalancesQueries = useAddressesBalance(
    {
      addresses: watchingAddresses,
      networks: defaultNetworks
    },
    {
      select: res => DefaultTokenToCoinSelector(res, false, true)
    }
  )

  const { isLoading, data, isRefetching } = useAllTokenBalances(
    {
      addresses: watchingAddresses,
      networks: defaultNetworks
    },
    {
      select: AllBalances => BalancesToCoinsSelector(AllBalances, false, true)
    }
  )

  return {
    coins: [...(addressesBalancesQueries.map(r => r.data) as Coin[]), ...(data ?? [])],
    isLoading,
    isRefetching
  }
}

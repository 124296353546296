import { useCallback, useMemo } from 'react'
import { Platform, TouchableOpacity } from 'react-native'
import { useConfig } from '@vatom/sdk/react'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { Avatar, theme } from '@vatom/wombo'
import { Box, Center, Image, useColorModeValue } from 'native-base'

// import DefaultAvatar from '../../../assets/buildings-sharp-light.svg'
import { More, VatomLogo } from '../../../components/Icons'
import { useBusinessSelector } from '../../../hooks/useBusinessSelector'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../../hooks/useIsDesktop'
import { useDrawerStore } from '../../../navigators/DrawerNavigator/drawerStore'

import { useHeaderDesktopContext } from './DesktopHeader'

const vatomLogoImage = require('../assets/vatom-logo.xl.png')

const VatomAvatar = () => {
  const isWeb = Platform.OS === 'web'
  if (isWeb) {
    return (
      <Image
        source={{ uri: vatomLogoImage }}
        style={{ width: 28, height: 24 }}
        alt="Vatom"
        accessibilityIgnoresInvertColors
      />
    )
  }
  return <VatomLogo size={8} />
}

const BusinessAvatar = ({ image, name }: { image?: string; name: string }) => {
  const isDesktop = useIsDesktop()

  const avatarBorder = useColorModeValue(
    theme.colors.grayCool[200],
    isDesktop ? theme.colors.grayCool[200] : theme.colors.grayDarkMode[700]
  )
  // Uncomment to show default avatar instead of initials when no image is available
  // const avatarFill = useColorModeValue(
  //   theme.colors.textLightMode[300],
  //   isDesktop ? theme.colors.textDarkMode[300] : theme.colors.textDarkMode[300]
  // )

  // if (!image) {
  //   return (
  //     <Center
  //       size={12}
  //       borderRadius={'full'}
  //       borderWidth={1}
  //       _light={{
  //         backgroundColor: theme.colors.grayCool[50]
  //       }}
  //       _dark={{
  //         backgroundColor: theme.colors.grayDarkMode[700]
  //       }}
  //     >
  //       <DefaultAvatar fill={avatarFill} width={20} height={20} />
  //     </Center>
  //   )
  // }

  return (
    <Avatar
      source={image ? { uri: image } : undefined}
      size={12}
      borderWidth={1}
      borderColor={avatarBorder}
      nbAvatarSize={'100%'}
      name={name}
    />
  )
}

const BusinessIcon = ({ color }: { color?: string }) => {
  const { business, businessIdentifier } = useBusinessSelector()

  const renderIcon = useMemo(() => {
    // Placeholder
    if (businessIdentifier && !business) {
      return <Box size={12} borderRadius={999} backgroundColor={theme.colors.grayCool[200]} />
    }
    // Business
    if (business) {
      return <BusinessAvatar image={business.logoSrc} name={business.displayName} />
    }
    // Default
    if (!businessIdentifier && !business) {
      return <VatomAvatar />
    }

    return null
  }, [business, businessIdentifier])

  const isDesktop = useIsDesktop()
  if (isDesktop) {
    return (
      <Center
        size={52}
        borderColor={color}
        borderWidth={1}
        borderRadius={999}
        overflow={'hidden'}
        _light={{
          borderColor: theme.colors.grayCool[200]
        }}
        _dark={{
          borderColor: theme.colors.grayDarkMode[500]
        }}
        style={{
          borderColor: color
        }}
      >
        {renderIcon}
      </Center>
    )
  }
  return renderIcon
}

export const DrawerSelectorButton = () => {
  const isDesktop = useIsDesktop()
  const { pageTheme } = useBusinessTheme()
  // const { config: sdkConfig, isEmbedded } = useVatomWalletSdkStore()
  const config = useConfig()
  const { toggleDrawer } = useHeaderDesktopContext()
  const { openDrawer } = useDrawerStore()

  // const themedColor = !isDesktop
  //   ? brandTheme?.primaryText ?? pageTheme.active
  //   : theme.colors.grayCool[700]
  const themedColor = !isDesktop ? pageTheme.active : theme.colors.grayCool[700]

  const onPressDrawer = useCallback(() => {
    if (isDesktop) {
      toggleDrawer()
      return
    }
    openDrawer()
  }, [isDesktop, openDrawer, toggleDrawer])

  // if (isEmbedded && sdkConfig.hideDrawer) {
  if (config?.features?.hideDrawer) {
    return null
  }

  return (
    <TouchableOpacity accessibilityRole="button" onPress={onPressDrawer}>
      <Box flexDirection={'row'} alignItems={'center'} pointerEvents="auto">
        <Box marginRight={1}>{<More size={18} fill={themedColor} />}</Box>
        <BusinessIcon />
      </Box>
    </TouchableOpacity>
  )
}

import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react'
import { ViewProps } from 'react-native'
import { TokenType, ViewMode } from '@vatom/sdk/core'

import logger from '../logger'

import ErrorFace from './Faces/ErrorFace'
import ImageFace from './Faces/ImageFace'
import ThreeDFace from './Faces/ThreeDFace'
import VideoFace from './Faces/VideoFace'
import { FaceHandler } from './Face'

export const placeHolderImage = require('./Faces/placeholder.png')

export const placeHolderImageOrange =
  'https://resources.varius.io/nQwtevgfOa/1a5e0bc4-239a-4886-87be-a4967d8d202c.png'

export interface FaceProps extends ViewProps {
  token: TokenType
  errorMessage?: string
  viewMode?: ViewMode
  onMessage?: (name: string, payload: any) => Promise<void>
}

export const CoreFaces: React.FC<FaceProps>[] = [
  ErrorFace,
  ImageFace,
  // Loader,
  VideoFace,
  ThreeDFace
]

export type FaceType = React.JSXElementConstructor<
  React.ReactNode & {
    token: TokenType
    viewMode: ViewMode
    onMessage?: (name: string, payload: any) => Promise<void>
    ref?: React.MutableRefObject<FaceHandler | undefined>
    modified?: Date
  }
>
export type FaceMap = Record<string, FaceType>

/**
 * Create a context we can use to
 * - Provide access to our stores from our root component
 * - Consume stores in our screens (or other components, though it's
 *   preferable to just connect screens)
 */
const FaceContext = createContext<FaceMap>({} as FaceMap)
/**
 * The provider our root component will use to expose the root store
 */
export const FaceContextProvider = FaceContext.Provider
/**
 * A hook that screens can use to gain access to our stores, with
 * `const { someStore, someOtherStore } = useStore()`,
 * or less likely: `const Face = useStore()`
 */
export const useFaces = () => useContext(FaceContext)

export interface FaceProviderProps {
  children?: React.ReactNode
  faces?: React.FC<FaceProps>[]
}

export const FaceProvider = ({ children, faces }: FaceProviderProps) => {
  const faceStore = useRef<FaceMap>({} as FaceMap)

  const setFaces = useCallback(() => {
    const configFaces = faces ?? []
    configFaces.forEach(face => {
      const name = face.name || 'WebFace' // This is a big hack because wrapping the face in forwardRef obscures the name

      logger.info('[FaceProvider] setFace', face, name)
      if (!faceStore.current[name]) faceStore.current[name] = face
    })
    logger.info('\x1b[33m%s\x1b[0m', '[FaceProvider] faces registered', faceStore.current)
  }, [faceStore, faces])

  // Kick off initial async loading actions, like loading fonts and faceStore
  useEffect(() => {
    setFaces()
  }, [setFaces])

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color.
  // In iOS: application:didFinishLaunchingWithOptions:
  // In Android: https://stackoverflow.com/a/45838109/204044
  // You can replace with your own loading component if you wish.
  if (!faceStore) return null

  return <FaceContextProvider value={faceStore.current}>{children}</FaceContextProvider>
}

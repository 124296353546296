import { WebWrapper } from '../../components/WebWrapper'

import { type DmListProps, DmListScreen as DmList } from './DmList'

export function DmListScreen(props: DmListProps) {
  return (
    <WebWrapper flex={1} flexShrink={0}>
      <DmList {...props} />
    </WebWrapper>
  )
}

export const walletApiHost = 'https://id.vatom.com/api'

export const walletQueryKeys = {
  ethNonce: [{ scope: 'eth-nonce' }] as const,
  getEthNonce: (appId: string, vatomToken: string, address: string) =>
    [{ ...walletQueryKeys.ethNonce[0], appId, vatomToken, address }] as const
}

export const fetchNonce = async ({
  appId,
  vatomToken,
  address
}: {
  appId: string
  vatomToken: string
  address: string
}) => {
  const res = await fetch(`${walletApiHost}/eth/nonce?address=${address}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${vatomToken}`,
      'App-Id': appId,
      'Content-Type': 'application/json'
    })
  })

  const data = (await res.json()) as EthNonce
  return data
}

type EthNonce = {
  value: string
}

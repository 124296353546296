import React from 'react'
import { Platform } from 'react-native'
import { Box, useBreakpointValue, useTheme } from 'native-base'

export function useMaxWidth() {
  const isWeb = Platform.OS === 'web'
  const theme = useTheme()
  return useBreakpointValue({
    base: isWeb ? 'inherit' : '100%',
    md: theme.breakpoints.sm,
    lg: theme.breakpoints.md
  })
}

type WebWrapperProps = React.PropsWithChildren & React.ComponentProps<typeof Box>

const WebWrapper = React.forwardRef<typeof Box, WebWrapperProps>((props, ref) => {
  const { children, backgroundColor = 'transparent', ...rest } = props

  const maxWidth = useMaxWidth()
  const marginHorizontal = useBreakpointValue({
    base: '0',
    md: 'auto'
  })
  return (
    <Box
      ref={ref}
      maxWidth={maxWidth}
      width={'100%'}
      marginX={marginHorizontal}
      backgroundColor={backgroundColor}
      minHeight={'auto'}
      {...rest}
    >
      {children}
    </Box>
  )
})

export { WebWrapper }

import { useCallback, useEffect, useRef, useState } from 'react'
import { Platform, StatusBarStyle } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useAppState } from '@react-native-community/hooks'
import { useFocusEffect, useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { useQuery } from '@tanstack/react-query'
import { BVatomPluginType } from '@vatom/BVatom/plugin'
import { SessionSource, SessionType } from '@vatom/sdk/core'
import {
  useBusinessSearch,
  useConfigState,
  useInitialQsStore,
  useIsAuthed,
  useSDK
} from '@vatom/sdk/react'
import { theme } from '@vatom/wombo'
import * as AuthSession from 'expo-auth-session'
import * as Linking from 'expo-linking'
import * as WebBrowser from 'expo-web-browser'

import DeepLinkManager from '../../components/DeepLinkManager'
import logger from '../../logger'
import { useStore } from '../../models'
import { AppRoutes, AppStackScreenProps } from '../../navigators'
import { isElectron } from '../../utils/isElectron'
import { useWalletSdkAuth } from '../../utils/WalletSdk/hooks'

type CurrentRoute = AppStackScreenProps<
  typeof AppRoutes.businessConnect | typeof AppRoutes.connect
>['route']

export const useAuth = (code?: string) => {
  const navigation = useNavigation()
  const {
    accessToken: walletSdkAccessToken,
    isLoading: walletSDKIsLoading,
    isEmbedded
  } = useWalletSdkAuth()
  const sdk = useSDK()
  const isAuthed = useIsAuthed()
  const store = useStore()
  const [isLoading, setIsLoading] = useState(true)
  const [tab, setTab] = useState<{ style: StatusBarStyle; back: string }>({
    style: 'light-content',
    back: theme.colors.brand[500]
  })
  const appState = useAppState()

  const { data: config, isLoading: isLoadingConfig } = useConfigState()

  const {
    clientId,
    scopes,
    useProxy,
    discoveryUrl,
    redirectUri: oRedirectUri
  } = config.authentication

  // discovery service
  // https://id.vatom.com/.well-known/openid-configuration
  const discovery = AuthSession.useAutoDiscovery(discoveryUrl)

  const route = useRoute<CurrentRoute>()
  const linkingUrl = store.linking.linkingUrl ?? ''
  const urlParams = new URLSearchParams(linkingUrl.split('?')[1])
  const pathWithBusinessId = linkingUrl?.split('/b/')?.[1]
  const businessIdOrName = config.isBusinessLocked
    ? config.businessId
    : route.params?.business || pathWithBusinessId?.split('/')?.[0]?.split('?')?.[0]

  const redirectUri = Platform.OS === 'web' ? `${window.location.origin}/callback` : oRedirectUri
  const context = urlParams.get('context') ?? undefined
  const urlLoginParam = urlParams.get('login') ?? undefined
  const shouldUseDefaultId = context && !businessIdOrName
  const business = useBusinessSearch({
    business: shouldUseDefaultId ? 'ym3t3mPi0g' : businessIdOrName,
    context,
    join: context && !linkingUrl.includes('acquire') ? true : false
  })

  const isFocused = useIsFocused()

  // requested hardcoded business id if context is not empty
  // we don't want to redirect the user to /b/ym3t3mPi0g if there is context. Only in root.
  const businessId = shouldUseDefaultId ? 'ym3t3mPi0g' : business?.data?.[0]?.id
  const extraParams = businessId
    ? {
        ...(shouldUseDefaultId
          ? { join: 'true', context: context, 'business-id': businessId }
          : {
              'business-id': businessId
            })
      }
    : undefined

  // Create and load an auth request
  WebBrowser.maybeCompleteAuthSession()
  const [request, result, promptAsync] = AuthSession.useAuthRequest(
    {
      clientId,
      redirectUri,
      // id_token will return a JWT token
      responseType: AuthSession.ResponseType.Code,
      prompt: AuthSession.Prompt.Consent,
      extraParams,

      // prompt: AuthSession.Prompt.Login,
      // responseType: AuthSession.ResponseType.Token,
      scopes
    },
    discovery
  )

  // console.log('request', request, discovery)
  // console.log('redirectUri', redirectUri)
  // console.log('discovery', discovery)
  const gotoDeeplinkOrHome = useCallback(() => {
    const linkingUrl = store.linking.linkingUrl ?? ''
    logger.info('[Connect.gotoDeeplinkOrHome] linkingUrl', linkingUrl)

    if (store.linking.linkingUrl) {
      const linkingUrl = store.linking.linkingUrl
      if (linkingUrl.indexOf(AppRoutes.connect) === -1) {
        logger.info('[Connect.gotoDeeplinkOrHome] linkTo', { linkingUrl, businessIdOrName })

        const url = new URL(linkingUrl)
        const dpUrl = Platform.OS === 'ios' ? `${url.hostname}:/${url.pathname}` : linkingUrl
        Linking.openURL(dpUrl).then(store.linking.clearLinkingUrl)

        // store.linking.clearLinkingUrl()
        return
      }
    }

    navigation.navigate(AppRoutes.home)
  }, [businessIdOrName, navigation, store.linking.clearLinkingUrl, store.linking.linkingUrl])

  useEffect(() => {
    logger.info('[Connect.useEffect] isLoggedIn', sdk.dataPool.sessionStore.isLoggedIn)
    if (isAuthed && sdk.dataPool.sessionStore.isLoggedIn) {
      gotoDeeplinkOrHome()
    }
  }, [gotoDeeplinkOrHome, navigation, sdk.dataPool.sessionStore.isLoggedIn, isAuthed])

  const setSessionAndUser = useCallback(
    async (tokenResponse: AuthSession.TokenResponse) => {
      logger.info(`[Connect setSessionAndUser]`, sdk.vatomIncApi)

      if (!discovery) return null

      const { accessToken, expiresIn, idToken, issuedAt, refreshToken, tokenType, scope } =
        tokenResponse
      const redirectContext = await AsyncStorage.getItem('REDIRECT_CONTEXT')

      // Add token to the axios services
      sdk.service.setToken(accessToken)
      const date = new Date()
      sdk.dataPool.sessionStore.add({
        type: SessionType.JWT,
        value: {
          idToken,
          accessToken,
          refreshToken,
          expiresAt: new Date(
            new Date(date).setSeconds(date.getSeconds() + (expiresIn || 0))
          ).getTime()
        },
        source: SessionSource.Vatom
      })

      const onError = (e: any) => {
        setIsLoading(false)
      }

      // Perform token enchange
      await sdk.vatomIncApi.performTokenExchange(accessToken).catch(onError)
      //
      if (accessToken && expiresIn && issuedAt && refreshToken && tokenType && scope) {
        await sdk.dataPool.user.fetchIdentities().catch(onError)
        const user = await sdk.dataPool.user.fetchMe().catch(onError)

        // DEBUG: Test Sentry exception and crash reporting...
        // if (user?.sub && user?.email) {
        //   Sentry.instance.captureException(new Error('Test Capture Exception'))
        //   Sentry.instance.nativeCrash()
        // }

        // if (context || redirectContext) {
        //   // deleting the url if there is context so we don't redirect the user inside the folder upon login.
        //   if (shouldUseDefaultId || (redirectContext && !businessIdOrName)) {
        //     store.linking.clearLinkingUrl()
        //   }
        // }
        if (user?.deferred_deeplink) {
          try {
            const urlObject = new URL(user.deferred_deeplink)
            const url =
              Platform.OS === 'ios'
                ? `${urlObject.hostname}:/${urlObject.pathname}`
                : user.deferred_deeplink

            const canOpen = await Linking.canOpenURL(url)
            if (canOpen) {
              await Linking.openURL(url)
              await sdk.dataPool.user.patchMe({ deferred_deeplink: null })
            } else {
              throw new Error(`Cannot open URL: ${url}`)
            }
          } catch (error) {
            logger.error(error)
            gotoDeeplinkOrHome()
          }
        } else {
          gotoDeeplinkOrHome()
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      discovery,
      navigation,
      sdk.service,
      sdk.dataPool.sessionStore,
      sdk.dataPool.user,
      sdk.vatomIncApi,
      gotoDeeplinkOrHome
    ]
  )

  const authorizeCode = useCallback(
    async (stateCode: string) => {
      let codeVerifier = request?.codeVerifier ?? ''
      if (Platform.OS === 'web') {
        try {
          const storedCodeVerifier = localStorage.getItem('LOGIN_CODE_VERIFIER')
          localStorage.removeItem('LOGIN_CODE_VERIFIER')
          codeVerifier = storedCodeVerifier ?? codeVerifier
        } catch (error) {
          //
        }
      }
      if (discovery) {
        try {
          const authorizedState = await AuthSession.exchangeCodeAsync(
            {
              clientId,
              code: stateCode,
              redirectUri,
              extraParams: {
                code_verifier: codeVerifier
              }
            },
            discovery
          )

          // logger.info('[Connect.handleVatomLogin] authorizedState', authorizedState)
          // logger.info('[Connect.handleVatomLogin] setSessionAndUser?', !!setSessionAndUser)
          setSessionAndUser(authorizedState)
        } catch (error) {
          if (Platform.OS === 'web') {
            navigation.navigate(AppRoutes.connect)
          }
        }
      }
    },
    [clientId, discovery, navigation, redirectUri, request?.codeVerifier, setSessionAndUser]
  )

  const handleVatomLogin = useCallback(async () => {
    try {
      const qs = linkingUrl.split('?')[1]
      useInitialQsStore.setState({ initialQS: qs })
      if (isElectron()) {
        console.warn('TODO: Implement ELECTRON AUTH')
      } else if (Platform.OS === 'web') {
        if (discovery && request) {
          const url = await request.makeAuthUrlAsync(discovery)
          window.location.replace(url)
          localStorage.setItem('LOGIN_CODE_VERIFIER', request?.codeVerifier ?? '')

          return
        }
        // const url = makeAuthUrlAsync
        // redirect to url
      } else {
        const response = await promptAsync({
          // @ts-expect-error not really sure why we need this but I'm afraid it's important that would cause an issue upon removal
          useProxy,
          createTask: false,
          showInRecents: true,
          // NOTE: There's a missing type on the WebBrowser.openAuthSession
          // With ASWebAuthenticationSession, setting .prefersEphemeralWebBrowserSession to true prior to
          // calling .start() will force the user to enter credentials in the browser session. While not
          // the same as logging out, this will allow a new user to login with different credentials when
          // launching the next session.
          // https://stackoverflow.com/questions/47207914/sfauthenticationsession-aswebauthenticationsession-and-logging-out
          // @ts-ignore
          preferEphemeralSession: true,
          windowFeatures: {
            createTask: false,
            preferEphemeralSession: true
          }
        })

        if (discovery && response.type === 'success') {
          authorizeCode(response.params.code)
        } else {
          AuthSession.dismiss()
          store.linking.clearLinkingUrl()
        }
      }
    } catch (error) {
      logger.error('[Connect.handleVatomLogin] error', error, store.linking)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizeCode, discovery, promptAsync, request, store.linking, useProxy])

  useEffect(() => {
    const skipConnectLoginParam =
      Boolean(businessId && urlLoginParam === '1') || urlLoginParam === undefined
    const shouldSkipConnect =
      businessId &&
      !isAuthed &&
      isFocused &&
      appState === 'active' &&
      (Platform.OS === 'web'
        ? !window.location.href.includes('/callback')
        : !!request &&
          !business.isLoading &&
          request.extraParams?.['business-id'] === businessId) &&
      !isEmbedded &&
      skipConnectLoginParam &&
      !isLoadingConfig &&
      config

    if (shouldSkipConnect) {
      handleVatomLogin()
    }

    if (context && urlLoginParam === '0') {
      const url = new URL(linkingUrl)
      const bVatomPlugin = sdk.dataPool.getPlugin('BVatomPlugin') as BVatomPluginType
      if (DeepLinkManager.isDeepLink(url)) {
        DeepLinkManager.handleDeepLink(url, bVatomPlugin.api!, navigation)
        return
      }
    } else {
      setIsLoading(false)
    }
  }, [
    appState,
    business.isLoading,
    businessId,
    config,
    context,
    handleVatomLogin,
    isAuthed,
    isEmbedded,
    isFocused,
    isLoadingConfig,
    linkingUrl,
    navigation,
    promptAsync,
    request,
    result,
    sdk.dataPool,
    urlLoginParam
  ])

  useQuery({
    queryKey: ['doAuthorize', code],
    queryFn: async ({ queryKey }) => {
      const [_, code] = queryKey
      if (discovery && code) {
        authorizeCode(code)
      }
      return true
    },
    enabled: !!discovery && !!code && !!request?.codeVerifier,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false
  })

  useFocusEffect(
    useCallback(() => {
      setTab({
        style: 'light-content',
        back: theme.colors.brand[500]
      })

      return () =>
        setTab({
          style: 'dark-content',
          back: theme.colors.white
        })
    }, [])
  )

  const isInitialized = useRef(false)
  useEffect(() => {
    if (isInitialized.current === true) return
    if (walletSdkAccessToken) {
      isInitialized.current = true
      setSessionAndUser(walletSdkAccessToken)
    } else if (!walletSDKIsLoading && !walletSdkAccessToken && !businessId) {
      setIsLoading(false)
    }
  }, [businessId, setSessionAndUser, walletSDKIsLoading, walletSdkAccessToken])

  const loading =
    (isEmbedded && !!walletSdkAccessToken) || config.isBusinessLocked
      ? true
      : Boolean(!isEmbedded && businessIdOrName && !business.error && isLoading)

  return {
    isLoading: loading,
    walletSDKIsLoading,
    tab,
    handleVatomLogin,
    setSessionAndUser,
    isEmbedded
  }
}

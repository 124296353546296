/* eslint-disable @typescript-eslint/no-explicit-any */
import { groupBy } from 'lodash'
import { toJS } from 'mobx'
import { IAnyType, types } from 'mobx-state-tree'

import logger from '../../../logger'
// import { nanoid } from 'nanoid'
import { Token, TokenType, TokenTypes } from '../../../models'
import { isPlugin, Plugin, PluginableProps } from '../../BasePlugin'
import { NftFilterBy } from '../../NftFilter'
import { WalletPreferenceSortBy } from '../../WalletPreference'
import { CompositeRegionStore, CompositeRegionStoreType } from '../CompositeRegionStore'
import { Region, RegionTypes } from '../RegionStore'

export interface CompositeRegion extends CompositeRegionStoreType {
  regions: Region[]
  addRegion(region: Region): Region
  filteredTokens: TokenType[]
  sortedTokens: TokenType[]
  walletTokens: TokenType[]
}

export const composePluginableRegionStore = (props: PluginableProps) => {
  const target = '[createPluginableRegionStore]'
  const anyPlugins = props.plugins ?? ([] as Plugin[])
  // const anyTypes = anyPlugins.map(plugin => plugin) as IAnyType[]
  logger.info(target, 'anyPlugins.length', anyPlugins.length)
  const plugins = anyPlugins.reduce((typedPlugins, plugin) => {
    // const placeholder = plugin.create({ name: plugin.name })
    logger.info(
      target,
      'plugins.reduce isPlugin(plugin)',
      isPlugin(plugin),
      plugin.name
      // placeholder.availableRegions
    )
    if (isPlugin(plugin)) {
      logger.info(
        target,
        'plugins.reduce',
        plugin.name,
        plugin.availableRegions.map(region => region.name)
      )
      return [...typedPlugins, plugin as Plugin]
    }
    return typedPlugins
  }, [] as Plugin[])

  const availableRegionTypes = plugins.reduce(
    (concatenatedRegions, plugin) => [...concatenatedRegions, ...plugin.availableRegions],
    [] as IAnyType[]
  )

  const availableTokenTypes = plugins.reduce(
    (concatenatedTokens, plugin) => [...concatenatedTokens, ...plugin.availableTokens],
    [] as IAnyType[]
  )

  logger.info(target, 'props.plugins', anyPlugins.length)
  logger.info(target, 'plugins', plugins.length)
  logger.info(target, 'availableRegionTypes', availableRegionTypes.length)
  logger.info(target, 'availableTokenTypes', availableTokenTypes.length)

  return types
    .compose(
      CompositeRegionStore,
      types.model({
        _tokens: types.optional(
          types.map(
            types.reference(
              types.union(
                {
                  eager: false,
                  dispatcher: (snapshot: any): IAnyType => {
                    logger.info(
                      target,
                      'snapshot',
                      toJS(snapshot).id,
                      'token names',
                      availableTokenTypes.map(plugin => plugin.name)
                    )
                    if (!availableTokenTypes.length) {
                      return Token
                    }
                    const discriminatedToken = availableTokenTypes.filter(
                      token => token.name === snapshot.className || token.name === snapshot.name
                    )
                    logger.info(target, 'availableTokenTypes', availableTokenTypes.length)
                    if (!discriminatedToken || discriminatedToken.length === 0) {
                      throw new Error('[createPluginableRegionStore] Unable to discrimate Region')
                    }
                    logger.info('[createPluginableRegionStore] snapshot', typeof discriminatedToken)
                    return discriminatedToken[0] as IAnyType
                  }
                },
                ...availableTokenTypes
              )
            )
          ),
          {}
        )
      }),
      types.model({
        regions: types.optional(
          types.array(
            // types.late(() =>
            types.safeReference(
              types.union(
                {
                  eager: false,
                  dispatcher: (snapshot: any): IAnyType => {
                    logger.info(
                      target,
                      'snapshot',
                      toJS(snapshot).id,
                      'plugin names',
                      availableRegionTypes.map(plugin => plugin.name)
                    )
                    if (!availableRegionTypes.length) {
                      return CompositeRegionStore
                    }
                    const discriminatedRegion = availableRegionTypes.filter(
                      region => region.name === snapshot.name || region.name === snapshot.className
                    )
                    logger.info(target, 'availableRegionTypes', availableRegionTypes.length)
                    if (!discriminatedRegion || discriminatedRegion.length === 0) {
                      throw new Error('[createPluginableRegionStore] Unable to discrimate Region')
                    }
                    logger.info(
                      '[createPluginableRegionStore] snapshot',
                      typeof discriminatedRegion
                    )
                    return discriminatedRegion[0] as IAnyType
                  }
                },
                ...availableRegionTypes
              )
            )
            // )
          ),
          []
        )
      })
    )
    .actions(self => ({
      async load() {
        await Promise.all(
          self.regions.map(async (region: Region) => {
            // region.clear()
            await region.load()
          })
        )
      },
      async reload() {
        await Promise.all(self.regions.map(r => r.reload()))
      },
      async fetchPage(args?: any) {
        await Promise.all(
          self.regions.map(async (region: Region) => {
            await region?.fetchPage(args)
          })
        )
      },
      copyFromStash({ limit }: { limit?: number }) {
        logger.info('[CompositeRegion.copyFromStash] limit', limit)
        self.regions.forEach(region => {
          // logger.info('CompositeRegion.copyFromStash region.name', { ...region })
          if (region.className.includes('Vatom')) {
            region.copyFromStash({ limit })
          }
        })
      },
      addRegion(region: Region) {
        // See if region already exists and return it
        const existingRegion = self.regions.find($ => $.stateKey === region.stateKey)
        if (existingRegion) {
          logger.info('[CompositeRegion.addRegion] existingRegion', existingRegion.id)
          return existingRegion
        }

        self.regions.push(region)
        return region
      },

      // Fetches or creates a region (in this cases its an aggregate of all the regions)
      region(id: RegionTypes, descriptor?: string): CompositeRegion {
        logger.info(
          '[createPluginableRegionStore] Looking for composite region: ',
          self.regions.length,
          id,
          descriptor
        )
        let region = self.regions.find(r => r.matches(id, descriptor))
        if (region) {
          logger.info('[createPluginableRegionStore] Found composite region: ', id, descriptor)
          return region
        }

        // region = CompositeRegionStore.create({ id, descriptor, _id: nanoid() })
        region = CompositeRegionStore.create({
          id,
          descriptor,
          _id: descriptor ? `CompositeRegion-${id}-${descriptor}` : `CompositeRegion-${id}`
        })
        logger.info('[createPluginableRegionStore] Created composite region: ', id, descriptor)
        self.regions.push(region)
        // self.emit('region.added', getSnapshot(region))

        return region
      },

      removeRegion(region: CompositeRegionStoreType) {
        for (let i = 0; i < self.regions.length; i++) {
          if (self.regions[i] === region) {
            self.regions.splice(i, 1)
            return true
          }
        }
        return false
      }
    }))
    .views(self => ({
      get tokens(): TokenType[] {
        logger.info('[CompositeRegion.tokens]', self.regions.length)
        return self.regions.reduce((agg: TokenType[], region) => {
          // logger.info('[CompositeRegion.tokens]', agg.length, region.get().length)
          return agg.concat(region?.tokens ?? [])
        }, [])
      },

      get sortedTokens() {
        logger.info('[CompositeRegion.sortedTokens]', self.rootStore.walletPreference.sortBy)
        if (self.rootStore.walletPreference.sortBy === WalletPreferenceSortBy.Descending) {
          return this.tokens
            .sort((a, b) => {
              if (a.metadata.name.toUpperCase() < b.metadata.name.toUpperCase()) {
                return 1
              }

              if (a.metadata.name.toUpperCase() > b.metadata.name.toUpperCase()) {
                return -1
              }
              return 0
            })
            .slice()
        } else if (self.rootStore.walletPreference.sortBy === WalletPreferenceSortBy.Ascending) {
          return this.tokens
            .sort((a, b) => {
              if (a.metadata.name.toUpperCase() < b.metadata.name.toUpperCase()) {
                return -1
              }

              if (a.metadata.name.toUpperCase() > b.metadata.name.toUpperCase()) {
                return 1
              }
              return 0
            })
            .slice()
        } else if (self.rootStore.walletPreference.sortBy === WalletPreferenceSortBy.Oldest) {
          return this.tokens
            .sort((a, b) => {
              if (new Date(a.modified || a.created || 0) < new Date(b.modified || b.created || 0)) {
                return -1
              }

              if (new Date(a.modified || a.created || 0) > new Date(b.modified || b.created || 0)) {
                return 1
              }
              return 0
            })
            .slice()
        } else if (self.rootStore.walletPreference.sortBy === WalletPreferenceSortBy.Newest) {
          return this.tokens
            .sort((a, b) => {
              if (new Date(a.modified || a.created || 0) < new Date(b.modified || b.created || 0)) {
                return 1
              }

              if (new Date(a.modified || a.created || 0) > new Date(b.modified || b.created || 0)) {
                return -1
              }
              return 0
            })
            .slice()
        }

        return this.tokens
      },
      get ARTokens() {
        const geoRegions = self.rootStore.dataPool?.regions.filter(r => r.id === 'geopos') ?? []
        // There might be duplicates if the same token is in multiple regions so we group them by id.
        const allGeoRegionTokens = geoRegions.map(r => r.tokens).flat()
        const tokenGroupsById = groupBy(allGeoRegionTokens, 'id')

        const droppedTokens = Object.values(tokenGroupsById).reduce((agg, tokenGroup) => {
          // There might be a desync of the state of a given token.
          // i.e: The user picks -> optimistic update kicks in -> user scrolls map before pickup finishes ->  2 geo regions with 1 token dropped and the other not dropped
          // So we assume that if at least one of the token dupe is not dropped , then the token is not dropped.
          const atLeastOneNotDropped = tokenGroup.some(
            // @ts-ignore
            token => !token.properties?.dropped
          )

          if (atLeastOneNotDropped) {
            return agg
          }
          return agg.concat(tokenGroup[0]) as TokenType[]
        }, [] as TokenType[])

        return droppedTokens
      },
      get filteredTokens() {
        logger.info('[CompositeRegion.filteredTokens]', self.regions.length)
        const toTitleCase = (str: string) => {
          return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
          })
        }

        let tokens = !self.rootStore.walletPreference.showDroppedSmartNft
          ? this.sortedTokens.filter(v => {
              if (v.type === 'vatom-new') {
                // @ts-expect-error
                return v.state?.['map-ready-v1']?.location === undefined
              }

              return (v.type === 'vatom' && v.position === undefined) || v.type !== 'vatom'
            })
          : this.sortedTokens

        if (
          self.rootStore.nftFilter.filterBy !== NftFilterBy.All &&
          self.rootStore.nftFilter.filterBy !== NftFilterBy.Wallet &&
          self.rootStore.nftFilter.filterBy !== NftFilterBy.SmartNft &&
          self.rootStore.nftFilter.filterBy !== NftFilterBy.Custodial
        ) {
          tokens = tokens.filter((token: TokenType) => {
            let category = ''
            const categoryName = token.metadata?.attributes?.find(
              $ => $.trait_type === 'category'
            )?.value
            if (categoryName) {
              category = toTitleCase(categoryName.toString())!
            }
            return self.rootStore.nftFilter.filterBy === toTitleCase(category)
          })
        } else if (self.rootStore.nftFilter.filterBy === NftFilterBy.SmartNft) {
          tokens = tokens.filter((token: TokenType) => token.type === 'vatom')
        } else if (self.rootStore.nftFilter.filterBy === NftFilterBy.Wallet) {
          tokens = tokens.filter(t => {
            return (
              t.blockchainInfo?.owner.toLowerCase() ===
              self.rootStore.nftFilter.walletAddress.toLowerCase()
            )
          })
        } else if (self.rootStore.nftFilter.filterBy === NftFilterBy.Custodial) {
          tokens = tokens.filter(t => {
            return t.getTokenType() === TokenTypes.VatomMintedCustodial
          })
        }

        tokens = tokens.filter(v => v.owner !== '.' && v.parentId === '.')

        return tokens
      },
      get walletTokens() {
        logger.info('[CompositeRegion.walletTokens]', self.regions.length)
        return this.filteredTokens
      },
      // async getItem(id: string, waitUntilStable = true): Promise<DataObject | null> {
      //   for (const region of this.regions) {
      //     const item = await region.getItem(id, waitUntilStable)
      //     if (item) return item as DataObject
      //   }
      //   return null
      // },
      get stateKey() {
        return self.regions
          .reduce(
            (agg, region) => {
              return agg.concat(region.stateKey)
            },
            ['composite']
          )
          .join(':')
      },
      get isLoading() {
        const isLoading = self.regions.reduce(
          (sumIsLoading, region) => sumIsLoading || region?.isLoading,
          false
        )
        logger.info('[ComposedRegion.isLoading]', self.id, isLoading)
        return isLoading || self.regions.length === 0
      }
    }))
    .actions(self => {
      const afterCreate = () => {
        logger.info('[ComposedRegion.afterCreate] regions.clear()')
        // self.regions.map(region => region.clear())
        // self.regions.clear()
      }
      const beforeDestroy = () => {
        // teardown
      }
      return { afterCreate, beforeDestroy }
    })
}

import * as Font from 'expo-font'

export const initFonts = async () => {
  // Refer to ./assets/fonts/custom-fonts.md for instructions.
  // ...
  // Welcome back! Just uncomment this and replace/append with your font file names!
  // ⬇
  // await Font.loadAsync({
  //   Montserrat: require("./Montserrat-Regular.ttf"),
  //   "Montserrat-Regular": require("./Montserrat-Regular.ttf"),
  // })
  await Font.loadAsync({
    'Inter-VariableFont': require('./Inter-VariableFont_slnt,wght.ttf'),
    'Inter-Black': require('./Inter-Black.ttf'),
    'Inter-Bold': require('./Inter-Bold.ttf'),
    'Inter-ExtraBold': require('./Inter-ExtraBold.ttf'),
    'Inter-ExtraLight': require('./Inter-ExtraLight.ttf'),
    'Inter-Light': require('./Inter-Light.ttf'),
    'Inter-Medium': require('./Inter-Medium.ttf'),
    Inter: require('./Inter-VariableFont_slnt,wght.ttf'),
    'Inter-Regular': require('./Inter-Regular.ttf'),
    'Inter-SemiBold': require('./Inter-SemiBold.ttf'),
    'Inter-Thin': require('./Inter-Thin.ttf'),
    // AudioWide
    Audiowide: require('./audiowide/Regular.ttf'),
    // Outfit
    Outfit: require('./outfit/Outfit-VariableFont_wght.ttf'),
    'Outfit-Thin': require('./outfit/Outfit-Thin.ttf'),
    'Outfit-ExtraLight': require('./outfit/Outfit-ExtraLight.ttf'),
    'Outfit-Light': require('./outfit/Outfit-Light.ttf'),
    'Outfit-Regular': require('./outfit/Outfit-Regular.ttf'),
    'Outfit-Medium': require('./outfit/Outfit-Medium.ttf'),
    'Outfit-SemiBold': require('./outfit/Outfit-SemiBold.ttf'),
    'Outfit-Bold': require('./outfit/Outfit-Bold.ttf'),
    'Outfit-ExtraBold': require('./outfit/Outfit-ExtraBold.ttf'),
    'Outfit-Black': require('./outfit/Outfit-Black.ttf')
  })
}

import { useMemo } from 'react'
import { BusinessData } from '@vatom/sdk/react'
import { theme } from '@vatom/wombo'

import { useBusinessSelector } from '../../../hooks/useBusinessSelector'
import { useBusinessThemeConfig } from '../../../hooks/useBusinessTheme'

const useCoinTheme = ({ business }: { business?: BusinessData }) => {
  const { businessIdentifier } = useBusinessSelector()

  const { brandTheme, pageTheme, isDark } = useBusinessThemeConfig({
    pageConfig: business?.pageConfig,
    brandConfig: business?.brandConfig
  })
  const businessId = business?.id || businessIdentifier

  const coinTheme = useMemo(() => {
    if (businessId) {
      return {
        primary: brandTheme?.primary ?? pageTheme.colorHeader,
        background: brandTheme?.background ?? pageTheme.background,
        primaryText: brandTheme?.primaryText ?? pageTheme.primaryText,
        textColor: brandTheme?.text ?? pageTheme.text,
        isDark
      }
    }
    // Default Vatom
    return {
      primary: brandTheme?.primary ?? pageTheme.colorHeader,
      background: theme.colors.white,
      primaryText: brandTheme?.primaryText ?? pageTheme.text,
      textColor: theme.colors.textLightMode[600],
      isDark
    }
  }, [
    brandTheme?.background,
    brandTheme?.primary,
    brandTheme?.primaryText,
    brandTheme?.text,
    businessId,
    isDark,
    pageTheme.background,
    pageTheme.colorHeader,
    pageTheme.primaryText,
    pageTheme.text
  ])

  return coinTheme
}

export { useCoinTheme }

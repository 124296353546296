import React, { createContext, PropsWithChildren, useState } from 'react'

import { useMessage } from '../hooks/useMessage'
import { UserProviderTypes, UserType } from '../types'

export const UserContext = createContext<UserProviderTypes>({} as UserProviderTypes)

export const UserProvider = (props: PropsWithChildren) => {
  const [user, setUser] = useState<UserType>()
  const [campaignUserInfo, setCampaignUserInfo] = useState()
  const [userBusinessInfo, setUserBusinessInfo] = useState()
  const { sendToParent } = useMessage()

  useMessage('GET_USER', (_, payload) => {
    if (payload) {
      setUser(payload)
    }
  })

  useMessage('GET_USER_INFO', (_, payload) => {
    if (payload) {
      setUserBusinessInfo(payload)
    }
  })

  useMessage('GET_CAMPAIGN_USER_INFO', (_, payload) => {
    setCampaignUserInfo(payload)
  })

  function updateUserCampaignInfo(payload: any): void {
    sendToParent({ type: 'UPDATE_CAMPAIGN_USER_INFO', payload: payload })
  }
  function getUserBusinessInfo(): void {
    sendToParent({ type: 'GET_USER_INFO' })
  }

  return (
    <UserContext.Provider
      value={{
        user,
        campaignUserInfo,
        updateUserCampaignInfo,
        userBusinessInfo,
        getUserBusinessInfo
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useInRouterContext, useLocation } from 'react-router-dom'

import { useMessage } from '../hooks/useMessage'
import { useWindowScroll } from '../hooks/useWindowScroll'
import { ExperienceTypes } from '../types'

import { BusinessNavigationProvider } from './BusinessNavigationProvider'
import { BusinessProvider } from './BusinessProvider'
import { UserProvider } from './UserProvider'

declare global {
  interface Window {
    ReactNativeWebView: any
  }
}

export const ExperienceContext = createContext<ExperienceTypes>({} as ExperienceTypes)
export const useExperience = () => useContext(ExperienceContext)

export const ExperienceProvider = (props: PropsWithChildren) => {
  const inRouterContext = useInRouterContext()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = inRouterContext ? useLocation() : null
  const [windowScroll, windowScrollTo] = useWindowScroll()
  const { sendToParent } = useMessage()
  const [layoutHidden, setLayoutHidden] = useState(false)

  useEffect(() => {
    sendToParent({ type: 'WEB_VIEW_READY', payload: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location) {
      sendToParent({ type: 'HISTORY_CHANGE', payload: location })
    }
  }, [sendToParent, location])

  useEffect(() => {
    if (windowScroll) {
      sendToParent({ type: 'WINDOW_SCROLL', payload: windowScroll })
    }
  }, [sendToParent, windowScroll])

  useMessage('GET_LAYOUT_HIDDEN', (_, payload) => {
    if (payload) {
      setLayoutHidden(payload)
    }
  })

  const sendAnalyticsEvent = (type: string, payload: any) => {
    sendToParent({ type: 'SEND_ANALYTICS', payload: { type, payload } })
  }

  const postHeaderMessage = (msg: string) => {
    sendToParent({ type: 'POST_HEADER_MESSAGE', payload: { title: msg } })
  }

  const navigateToExternalBrowser = (url: string) => {
    sendAnalyticsEvent('openURL', { url })
    sendToParent({
      type: 'NAVIGATE_TO_EXTERNAL_BROWSER',
      payload: {
        url: url
      }
    })
  }

  const navigateToWallet = () => {
    sendToParent({ type: 'NAVIGATE_TO_WALLET' })
  }

  const navigateToNFTDetail = (tokenId: string, businessIdOrName: string) => {
    sendToParent({
      type: 'NAVIGATE_TO_TOKEN',
      payload: {
        tokenId,
        businessIdOrName
      }
    })
  }

  const sendEmail = (email: string) => {
    const url = 'mailto:' + email
    sendAnalyticsEvent('openURL', { url })

    sendToParent({
      type: 'NAVIGATE_TO_EXTERNAL_BROWSER',
      payload: { url: url }
    })
  }

  const openSpace = (alias: string) => {
    sendToParent({ type: 'OPEN_SPACE', payload: { alias: alias } })
  }

  const callSpace = (alias: string) => {
    sendToParent({ type: 'CALL_SPACE', payload: { alias: alias } })
  }

  return (
    <ExperienceContext.Provider
      value={{
        analytics: { event: sendAnalyticsEvent },
        postHeaderMessage,
        navigateToExternalBrowser,
        sendEmail,
        openSpace,
        callSpace,
        navigateToWallet,
        navigateToNFTDetail,
        layoutHidden
      }}
    >
      <UserProvider>
        <BusinessProvider>
          <BusinessNavigationProvider>{props.children}</BusinessNavigationProvider>
        </BusinessProvider>
      </UserProvider>
    </ExperienceContext.Provider>
  )
}

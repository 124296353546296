/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { NftModel, NftModelType } from "./NftModel"
import { NftModelSelector } from "./NftModel.base"
import { TwitterProfileModel, TwitterProfileModelType } from "./TwitterProfileModel"
import { TwitterProfileModelSelector } from "./TwitterProfileModel.base"
import { RootStoreType } from "./index"


/**
 * MintEventBase
 * auto generated base class for the model MintEventModel.
 */
export const MintEventModelBase = ModelBase
  .named('MintEvent')
  .props({
    __typename: types.optional(types.literal("MintEvent"), "MintEvent"),
    createdAt: types.union(types.undefined, types.frozen()),
    walletAddress: types.union(types.undefined, types.string),
    profile: types.union(types.undefined, types.null, types.late((): any => TwitterProfileModel)),
    feedEventId: types.union(types.undefined, types.string),
    metadataAddress: types.union(types.undefined, types.frozen()),
    nft: types.union(types.undefined, types.null, types.late((): any => NftModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class MintEventModelSelector extends QueryBuilder {
  get createdAt() { return this.__attr(`createdAt`) }
  get walletAddress() { return this.__attr(`walletAddress`) }
  get feedEventId() { return this.__attr(`feedEventId`) }
  get metadataAddress() { return this.__attr(`metadataAddress`) }
  profile(builder: string | TwitterProfileModelSelector | ((selector: TwitterProfileModelSelector) => TwitterProfileModelSelector) | undefined) { return this.__child(`profile`, TwitterProfileModelSelector, builder) }
  nft(builder: string | NftModelSelector | ((selector: NftModelSelector) => NftModelSelector) | undefined) { return this.__child(`nft`, NftModelSelector, builder) }
}
export function selectFromMintEvent() {
  return new MintEventModelSelector()
}

export const mintEventModelPrimitives = selectFromMintEvent().createdAt.walletAddress.feedEventId.metadataAddress

import { useCallback, useMemo, useState } from 'react'
import Animated, {
  Easing,
  interpolate,
  useAnimatedRef,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StackActions } from '@react-navigation/native'
import { useConfig } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Input } from 'native-base'

import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../../hooks/useIsDesktop'
import { useLastVisitedRouteMutation } from '../../../hooks/useLastVisitedRoute'
import { AppRoutes } from '../../../navigators'
import { useDrawerStore } from '../drawerStore'

import { DrawerTabScreenProp, useDrawerContentContext } from './DrawerContent'
import { BusinessItem, DrawerItem, vatomBusinessAllImage } from './DrawerItem'
import { ProfileButton } from './ProfileButton'

const filterHeight = 50

type FilterDrawerItemsProps = {
  filter: string
  setFilter: (filter: string) => void
}
const FilterDrawerItems = ({ filter, setFilter }: FilterDrawerItemsProps) => {
  return (
    <Box height={filterHeight} paddingX={4} _web={{ marginTop: 2 }} _android={{ paddingTop: 2 }}>
      <Input
        placeholder={translate('common.search')}
        accessibilityHint="Filter business"
        accessibilityLabel="Filter business"
        defaultValue={filter}
        onChangeText={setFilter}
        clearButtonMode="always"
        fontSize={15}
        _light={{
          backgroundColor: theme.colors.white,
          borderColor: theme.colors.grayCool[300],
          placeholderTextColor: theme.colors.textLightMode[100]
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[800],
          borderColor: theme.colors.grayDarkMode[500],
          placeholderTextColor: theme.colors.textDarkMode[600]
        }}
      />
    </Box>
  )
}

type BusinessesListProps = DrawerTabScreenProp<'BusinessList'>

const BusinessesList = observer(({ navigation }: BusinessesListProps) => {
  const insets = useSafeAreaInsets()
  const scrollRef = useAnimatedRef<Animated.ScrollView>()
  const { selectBusiness, businesses, selectedBusinessId, appNavigation, parentBusiness } =
    useDrawerContentContext()
  const config = useConfig()
  const { closeDrawer } = useDrawerStore()
  const { isDark } = useBusinessTheme()

  const isDesktop = useIsDesktop()

  const initialOffSet = !isDesktop ? filterHeight : 0
  const lastContentOffset = useSharedValue(initialOffSet)
  const lastVisitedTimeMutation = useLastVisitedRouteMutation()

  const onSelectBusiness = useCallback(
    (businessId: string | null, hasCommunities?: boolean) => {
      if (businessId === null) {
        selectBusiness('')
        closeDrawer()
        lastVisitedTimeMutation('')

        // Remove filter for business and show all from vatom
        appNavigation.dispatch(StackActions.replace(AppRoutes.home))
      } else if (businessId && hasCommunities) {
        // Navigate to communities list or business
        selectBusiness(businessId)
        navigation.navigate('CommunitiesList')
      } else if (businessId) {
        closeDrawer()

        appNavigation.dispatch(
          StackActions.replace(AppRoutes.BusinessProxy, { business: businessId })
        )
      }
    },
    [appNavigation, closeDrawer, lastVisitedTimeMutation, navigation, selectBusiness]
  )

  const [filterString, setFilterString] = useState('')

  const businessesFiltered = useMemo(() => {
    if (filterString === '') {
      return businesses
    }
    return businesses?.filter(b =>
      b.displayName?.toLowerCase().includes(filterString?.toLowerCase())
    )
  }, [businesses, filterString])

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: event => {
      lastContentOffset.value = event.contentOffset.y
    }
  })

  const filterBarStyle = useAnimatedStyle(() => {
    const filterHeightThreshold = interpolate(lastContentOffset.value, [0, filterHeight], [0, 1])
    const showFilter = filterHeightThreshold < 1
    if (showFilter) {
      return {
        opacity: 1,
        transform: [
          {
            translateY: withTiming(0, {
              duration: 250,
              easing: Easing.inOut(Easing.ease)
            })
          }
        ]
      }
    } else {
      return {
        opacity: 0,
        transform: [
          {
            translateY: withTiming(-filterHeight, {
              duration: 250,
              easing: Easing.inOut(Easing.ease)
            })
          }
        ]
      }
    }
  })

  return (
    <>
      {!isDesktop && (
        <Animated.View
          style={[
            {
              position: 'absolute',
              left: 0,
              right: 0,
              zIndex: 2
            },
            filterBarStyle
          ]}
        >
          <FilterDrawerItems filter={filterString} setFilter={setFilterString} />
        </Animated.View>
      )}
      <Animated.ScrollView
        ref={scrollRef}
        contentOffset={{ x: 0, y: initialOffSet }}
        // snapToOffsets={[filterHeight, 0]}
        // snapToStart={false}
        // snapToEnd={true}
        scrollEventThrottle={32}
        contentContainerStyle={{
          zIndex: 1,
          paddingTop: initialOffSet,
          overflow: 'hidden',
          paddingBottom: isDesktop ? undefined : 90 + insets.bottom // ProfileButton + inset
        }}
        onScroll={scrollHandler}
        style={{ backgroundColor: isDark ? theme.colors.grayCool[900] : theme.colors.white }}
      >
        <DrawerItem
          onPress={() => onSelectBusiness(null)}
          name={translate('common.all')}
          image={config.isBusinessLocked ? parentBusiness?.logoSrc : vatomBusinessAllImage}
        />
        {businessesFiltered?.map(b => {
          const isActive = b.id === selectedBusinessId
          return (
            <BusinessItem
              key={b.id}
              businessId={b.id}
              onPress={onSelectBusiness}
              name={b.displayName}
              image={b.logoSrc}
              containerProps={
                isActive
                  ? {
                      backgroundColor: isDark ? theme.colors.brand[500] : 'rgba(50,50,50,0.07)'
                    }
                  : {}
              }
            />
          )
        })}
      </Animated.ScrollView>
      {!isDesktop && <ProfileButton onPress={() => navigation.navigate('ProfileDrawer')} />}
    </>
  )
})

export { BusinessesList }

import { BoundingBoxModel, Sentry, TokenType } from '@vatom/sdk/core'
import { geoDiscoveryQueryKeys, SDKQueryClient } from '@vatom/sdk/react'
import { Instance, SnapshotOut, types } from 'mobx-state-tree'

import logger from '../logger'
import { BVatomSnapshot, BVatomToken, BVatomTokenType } from '../models/BVatomToken'
import { ActionPayload } from '../modules/Vatom/model/Action'
import { Change, VatomPayload } from '../modules/Vatom/model/BVatom'
import { FacePayload } from '../modules/Vatom/model/Face'

import { VatomRegionStore } from './BVatomRegion'

const className = 'VatomGeoPosRegionStore'

export const VatomGeoPosRegionStore = VatomRegionStore.named(className)
  .props({
    className,
    _tokens: types.optional(types.map(BVatomToken), {})
  })
  .views(self => ({
    /** Our state key is the current user's ID */
    get stateKey() {
      return `geopos:VatomNet:${self._id}`
    },
    get vatoms(): BVatomTokenType[] {
      return self.tokens as BVatomTokenType[]
    },
    get isLoading() {
      console.log('self._isLoading vatomGeo: ', self._isLoading)
      return self._isLoading
    },
    get decodedDescriptor() {
      if (self.descriptor) {
        return JSON.parse(self.descriptor) as SnapshotOut<typeof BoundingBoxModel>
      }
      return null
    },
    get ARTokens(): TokenType[] {
      return self.tokens
    }
  }))
  .actions(self => ({
    getItem(id: string) {
      return self.vatoms.find(t => t.id === id)
    },
    updateAction(change: Change) {
      const tokens = self.vatoms.filter(t => (t.properties.template = change.template))
      for (const token of tokens) {
        token.updateAction(change)
      }
    },
    updateFace(change: Change) {
      const tokens = self.vatoms.filter(t => (t.properties.template = change.template))
      for (const token of tokens) {
        token.updateFace(change)
      }
    }
  }))
  .actions(self => ({
    async fetchPage(args?: any) {
      return
    },
    async load(): Promise<string[]> {
      // Start the webscoket server if it is not running
      self.startSockets().then(() => {
        // Send region command to the WebSocket
        this.sendRegionCommand()
      })

      self.setIsLoading(true)
      logger.info('[VatomGeoPosRegion.load] loading...')

      logger.info('[VatomGeoPosRegion.load] self.decodedDescriptor', self.decodedDescriptor)
      const limit = 100
      type GeoDiscoverPayload = {
        filter: 'all'
        limit: number
        next_token?: string
        top_right?: {
          lat: number
          lon: number
        }
        bottom_left?: {
          lat: number
          lon: number
        }
      }

      const payload: GeoDiscoverPayload = {
        ...self.decodedDescriptor,
        filter: 'all',
        limit,
        next_token: undefined
      }

      const loadedIDs: string[] = []

      let count = 0
      let nextToken = 'initial'
      // Fetch data
      while (count < limit && !!nextToken) {
        if (nextToken !== 'initial') payload.next_token = nextToken

        const response = await self.api.client.request(
          'POST',
          '/v1/vatom/geodiscover',
          payload,
          true
        )

        // Create list of new objects
        const newVatoms: BVatomSnapshot[] = []
        // Add vatoms to the list
        for (const v of response.vatoms as VatomPayload[]) {
          const actions = response.actions.filter(($: ActionPayload) =>
            $.name.includes(v['vAtom::vAtomType'].template)
          )
          const faces = response.faces.filter(
            ($: FacePayload) => $.template === v['vAtom::vAtomType'].template
          )
          try {
            // logger.info('[VatomGeoPosRegion.load().vatoms] faces', v.id)
            newVatoms.push(self.mapObject(v.id, v, faces, actions))
          } catch (error) {
            logger.error('[VatomGeoPosRegion.load]', error)
          }
          loadedIDs.push(v.id)
        }

        self.addTokens(newVatoms)

        nextToken = response.next_token
        count += response.vatoms.length
      }

      self.setIsLoading(false)
      return loadedIDs
    },
    /** Sends the region command up the websocket to enable region monitoring */
    sendRegionCommand() {
      // Stop if WebSocket is not connected
      console.log('sendRegionCommand', self.socket.isOpen)
      if (!self.socket.isOpen || !self.decodedDescriptor) return

      // Convert our coordinates into the ones needed by the command
      const topLeft = {
        lat: Math.max(self.decodedDescriptor.top_right.lat, self.decodedDescriptor.bottom_left.lat),
        lon: Math.min(self.decodedDescriptor.top_right.lon, self.decodedDescriptor.bottom_left.lon)
      }
      const bottomRight = {
        lat: Math.min(self.decodedDescriptor.top_right.lat, self.decodedDescriptor.bottom_left.lat),
        lon: Math.max(self.decodedDescriptor.top_right.lon, self.decodedDescriptor.bottom_left.lon)
      }

      // Create command payload
      const cmd = {
        id: '1',
        version: '1',
        type: 'command',
        cmd: 'monitor',
        payload: {
          top_left: topLeft,
          bottom_right: bottomRight
        }
      }

      // Send it up
      logger.info('Sending WS command: ' + JSON.stringify(cmd))
      self.socket.sendMessage(cmd)
    }
  }))
  .actions(self => {
    const onWebSocketOpen = async (msg: any) => {
      // Full refresh this region, in case any messages were missed
      self.load()
      // Send region command again
      self.sendRegionCommand()
    }

    return { onWebSocketOpen }
  })
  .actions(self => {
    // Contrive a super() concept
    const superProcessMessage = self.processMessage
    return {
      async processMessage(msg: any) {
        logger.info('[VatomGeoPosRegion.processMessage] start', msg)
        // Call super
        superProcessMessage(msg)
        // Check for map add event
        if (msg.msg_type === 'map' && msg.payload.op === 'add') {
          try {
            // A vatom was added to the map. Fetch vatom, add components to data pool
            const response = await self.api.client.request(
              'POST',
              '/v1/user/vatom/get',
              { ids: [msg.payload.vatom_id] },
              true
            )

            // Add vatom to new objects list
            const newVatoms: BVatomSnapshot[] = []
            // Add vatoms to the list
            for (const v of response.vatoms as VatomPayload[]) {
              const actions = response.actions.filter(($: ActionPayload) =>
                $.name.includes(v['vAtom::vAtomType'].template)
              )
              const faces = response.faces.filter(
                ($: FacePayload) => $.template === v['vAtom::vAtomType'].template
              )
              newVatoms.push(self.mapObject(v.id, v, faces, actions))
            }

            // Add new objects
            logger.info('In GeoPos Region: adding new objects', newVatoms.length)
            self.addTokens(newVatoms)
            SDKQueryClient.invalidateQueries(geoDiscoveryQueryKeys.geoDiscoveryGroups)

            return
          } catch (error) {
            logger.error('[VatomGeoPosRegion.processMessage]', error)
            return
          }
        } else if (msg.msg_type === 'map' && msg.payload.op === 'remove') {
          // A vatom was removed from the map. Undrop it
          self.preemptiveChange(msg.payload.vatom_id, '/properties/dropped', false)
          SDKQueryClient.invalidateQueries(geoDiscoveryQueryKeys.geoDiscoveryGroups)

          return
        } else if (msg.msg_type === 'state_update') {
          // Get vatom ID
          const vatomID = msg.payload && msg.payload.id
          if (!vatomID) {
            throw new Error(`Got websocket message with no vatom ID in it.`)
          }

          // Check if this is a newly dropped vatom
          const dropped =
            msg.payload.new_object &&
            msg.payload.new_object['vAtom::vAtomType'] &&
            msg.payload.new_object['vAtom::vAtomType'].dropped
          if (!dropped) {
            return
          }

          // Check if we already have this vatom
          if (self.getItem(vatomID)) {
            return
          }

          // A new vatom was dropped! Pause WebSocket message processing
          self.pauseMessages()

          // Fetch vatom payload
          const response = await self.api.client
            .request('POST', '/v1/user/vatom/get', { ids: [vatomID] }, true)
            .catch((err: Error) => {
              logger.error('[VatomGeoPosRegion.processMessage]', err)
              if (err instanceof Error) Sentry.captureError(err)
              return {
                error: err,
                vatoms: [],
                faces: [],
                actions: []
              }
            })

          // Add vatom to new objects list
          const newVatoms: BVatomSnapshot[] = []
          // Add vatoms to the list
          for (const v of response.vatoms as VatomPayload[]) {
            const actions = response.actions.filter(($: ActionPayload) =>
              $.name.includes(v['vAtom::vAtomType'].template)
            )
            const faces = response.faces.filter(
              ($: FacePayload) => $.template === v['vAtom::vAtomType'].template
            )
            newVatoms.push(self.mapObject(v.id, v, faces, actions))
          }

          // Add new objects
          logger.info('In GeoPos Region: adding new objects', newVatoms.length)
          self.addTokens(newVatoms)
          SDKQueryClient.invalidateQueries(geoDiscoveryQueryKeys.geoDiscoveryGroups)

          self.resumeMessages()
        }

        // We only handle inventory update messages after this.
        if (msg.msg_type !== 'inventory') {
          return
        }
        logger.info('In Inventory Region: processMessage', JSON.stringify(msg, null, 4))
        // Get vatom ID
        const vatomID = msg.payload && msg.payload.id
        if (!vatomID) {
          return logger.warn(
            `[DataPool > BVWebSocketRegion] Got websocket message with no vatom ID in it: `,
            JSON.stringify(msg, null, 4)
          )
        }
        // Check if this is an incoming or outgoing vatom
        const currentBVUserId = self.api.store.userID
        if (
          msg.payload.old_owner === currentBVUserId &&
          msg.payload.new_owner !== currentBVUserId
        ) {
          // Vatom is no longer owned by us
          self.removeTokens([vatomID])
        } else if (
          msg.payload.old_owner !== currentBVUserId &&
          msg.payload.new_owner === currentBVUserId
        ) {
          try {
            // Vatom is now our inventory! Fetch vatom payload
            const response = await self.api.client.request(
              'POST',
              '/v1/user/vatom/get',
              { ids: [vatomID] },
              true
            )

            // Add faces to new objects list
            if (!response.vatoms || response.vatoms.length === 0) {
              Sentry.captureMessage(`No vatom found in API response: ${vatomID}`)
            }

            if (!response.faces || response.faces.length === 0) {
              Sentry.captureMessage(`No faces found in API response: ${vatomID}`)
            }

            if (response.vatoms.length === 0) return

            // Add vatom to new objects list
            const newVatoms: BVatomSnapshot[] = []
            // Add vatoms to the list
            for (const v of response.vatoms as VatomPayload[]) {
              const actions = response.actions.filter(($: ActionPayload) =>
                $.name.includes(v['vAtom::vAtomType'].template)
              )
              const faces = response.faces.filter(
                ($: FacePayload) => $.template === v['vAtom::vAtomType'].template
              )
              newVatoms.push(self.mapObject(v.id, v, faces, actions))
            }

            // Add new objects
            logger.info('In Inventory Region: adding new objects', newVatoms.length)
            self.addTokens(newVatoms)
            SDKQueryClient.invalidateQueries(geoDiscoveryQueryKeys.geoDiscoveryGroups)
          } catch (err) {
            console.error('geo pos processMessage err: ', err)
            if (err instanceof Error) Sentry.captureError(err)
            // throw err
          }
        } else {
          // Logic error, old owner and new owner cannot be the same
          // logger.warn(`[DataPool > BVWebSocketRegion] Logic error in WebSocket message, old_owner and new_owner shouldn't be the same: ${vatomRef.id}`)
        }
      }
    }
  })

export type VatomGeoPosRegionStoreType = Instance<typeof VatomGeoPosRegionStore>
export type VatomGeoPosRegion = VatomGeoPosRegionStoreType
export type VatomGeoPosRegionSnapshot = SnapshotOut<typeof VatomGeoPosRegionStore>

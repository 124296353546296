import { Circle, Path } from 'react-native-svg'
import { Icon } from 'native-base'

export const NotificationOffIcon = ({
  size,
  fill,
  ...iconProps
}: React.ComponentProps<typeof Icon>) => (
  <Icon viewBox="0 0 32 32" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M16.015 24.75c-.704 0-1.25-.547-1.25-1.25h-1.25c0 1.406 1.093 2.5 2.5 2.5 1.367 0 2.5-1.094 2.5-2.5h-1.25c0 .703-.586 1.25-1.25 1.25zm8.164-5.664c-1.094-1.016-1.914-2.11-1.914-5.82 0-3.086-2.5-5.625-5.625-5.938v-.703c0-.313-.313-.625-.625-.625a.642.642 0 00-.625.625v.703c-3.165.313-5.664 2.852-5.664 5.938 0 3.71-.82 4.804-1.915 5.82a1.878 1.878 0 00-.43 2.031c.274.703.977 1.133 1.759 1.133h13.75c.742 0 1.445-.43 1.718-1.133a1.878 1.878 0 00-.43-2.031zM22.889 21H9.14c-.585 0-.859-.625-.468-1.016 1.367-1.289 2.305-2.734 2.305-6.718 0-2.618 2.265-4.766 5.039-4.766 2.734 0 5 2.148 5 4.766 0 3.984.937 5.43 2.304 6.718.39.391.117 1.016-.43 1.016z"
    />
  </Icon>
)

export const NotificationOnIcon = ({
  size,
  fill,
  ...iconProps
}: React.ComponentProps<typeof Icon>) => (
  <Icon viewBox="0 0 32 32" size={size} {...iconProps}>
    <Path
      fill={fill}
      fillRule="evenodd"
      d="M22.269 13.71a23.63 23.63 0 01-.004-.444c0-1.53-.615-2.926-1.615-3.982-.256.44-.401.934-.406 1.455.39.587.649 1.258.737 1.977a3.96 3.96 0 001.288.994zm-3.424-4.361a5.126 5.126 0 00-2.83-.849c-2.774 0-5.04 2.148-5.04 4.766 0 3.984-.937 5.43-2.304 6.718-.39.391-.117 1.016.469 1.016h13.75c.546 0 .82-.625.43-1.016-1.19-1.121-2.055-2.362-2.26-5.29.402.232.84.423 1.306.566.25 2.167.946 3.021 1.813 3.826.547.547.703 1.328.43 2.031-.274.703-.977 1.133-1.72 1.133H9.14c-.78 0-1.484-.43-1.757-1.133a1.878 1.878 0 01.43-2.031c1.093-1.016 1.914-2.11 1.914-5.82 0-3.086 2.5-5.625 5.664-5.938v-.703c0-.313.273-.625.625-.625.312 0 .625.313.625.625v.703a6.442 6.442 0 012.785.953c-.243.332-.439.69-.58 1.068zM14.765 23.5c0 .703.546 1.25 1.25 1.25.664 0 1.25-.547 1.25-1.25h1.25c0 1.406-1.133 2.5-2.5 2.5a2.468 2.468 0 01-2.5-2.5h1.25z"
      clipRule="evenodd"
    />
    <Path
      fill={fill}
      fillRule="evenodd"
      d="M23 15a4 4 0 100-8 4 4 0 000 8zm0 1a5 5 0 100-10 5 5 0 000 10z"
      clipRule="evenodd"
    />
    <Circle cx="23" cy="11" r="3" fill={fill} />
  </Icon>
)

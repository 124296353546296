import React from 'react'
import { View } from 'react-native'
import { Contact, DeviceContact, useGetInvitePeople } from '@vatom/sdk/react'
import { observer } from 'mobx-react-lite'
import { Box, FlatList } from 'native-base'

import { ItemFollowPeople } from './ItemFollowPeople'
import ItemInvitePeople from './ItemInvitePeople'

type SectionPeopleProps = {
  containerProps?: React.ComponentProps<typeof Box>
}

export const SectionPeople = observer(({ containerProps }: SectionPeopleProps) => {
  const invitePeople = useGetInvitePeople()

  if (!invitePeople.length) return null

  return (
    <Box {...containerProps}>
      <FlatList
        keyExtractor={item => {
          const contact = item as unknown as Contact
          return `device-contact-${contact?.value}`
        }}
        data={invitePeople}
        renderItem={({ item }) => {
          if (!item) {
            return null
          }

          // Vatom Device Contacts
          if ('id' in item) {
            const contact = item as unknown as Contact
            return <ItemFollowPeople item={contact} />
          }

          // Non Vatom Device Contacts
          const deviceContact = item as unknown as DeviceContact
          return <ItemInvitePeople item={deviceContact} />
        }}
        showsHorizontalScrollIndicator={false}
        ListFooterComponent={<View style={{ height: 90 }}></View>}
      />
    </Box>
  )
})

export default SectionPeople

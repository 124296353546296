import React from 'react'
import { EvilIcons } from '@expo/vector-icons'
import { Text, theme } from '@vatom/wombo'
import { Box, Icon, Pressable } from 'native-base'

export interface IActionItem {
  onPress: () => void
  title: string
}

export const ActionItem = ({ onPress, title }: IActionItem) => {
  return (
    <Box
      borderColor="gray.100"
      bgColor="white"
      _dark={{
        backgroundColor: theme.colors.grayCool[900],
        borderColor: '#FFFFFF4D'
      }}
      borderBottomWidth={1}
      h={44}
      px={4}
      py={3}
    >
      <Pressable
        justifyContent="space-between"
        flexDir="row"
        accessibilityRole="button"
        onPress={onPress}
      >
        <Text
          fontSize={15}
          fontWeight="400"
          color="black"
          _dark={{
            color: theme.colors.textDarkMode[200]
          }}
        >
          {title}
        </Text>
        <Pressable accessibilityRole="button">
          <Icon
            as={EvilIcons}
            name="chevron-right"
            size="25px"
            _dark={{
              color: theme.colors.textDarkMode[200]
            }}
          />
        </Pressable>
      </Pressable>
    </Box>
  )
}

export default ActionItem

import React from 'react'
import { Text, TouchableWithoutFeedback, View } from 'react-native'

import { useCommunitiesTheme } from '../../../../themes'

type AddButtonProps = {
  text: string
  onPress: () => void
}

export const AddButton = ({ text, onPress }: AddButtonProps) => {
  const communitiesTheme = useCommunitiesTheme()

  return (
    <TouchableWithoutFeedback accessibilityRole="button" onPress={onPress}>
      <View
        style={{
          paddingHorizontal: 20,
          paddingVertical: 11,
          borderWidth: 1,
          borderRadius: 3,
          borderColor: communitiesTheme.addButton.border
        }}
      >
        <Text
          style={{
            fontSize: 13,
            lineHeight: 18,
            fontWeight: '500',
            color: communitiesTheme.addButton.text
          }}
        >
          {text}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  )
}

export const PlaceholderTitle = ({ text }: { text: string }) => {
  const communitiesTheme = useCommunitiesTheme()

  return (
    <Text
      style={{
        fontSize: 16,
        lineHeight: 22,
        fontWeight: '600',
        marginBottom: 10,
        color: communitiesTheme.name === 'dark' ? '#ECECED' : '#11141E'
      }}
    >
      {text}
    </Text>
  )
}

export const PlaceholderDescription = ({ text }: { text: string }) => (
  <Text
    style={{
      fontSize: 13,
      lineHeight: 18,
      fontWeight: '400',
      color: '#868E96',
      marginBottom: 20,
      textAlign: 'center'
    }}
  >
    {text}
  </Text>
)

/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { WalletModel, WalletModelType } from "./WalletModel"
import { WalletModelSelector } from "./WalletModel.base"
import { RootStoreType } from "./index"


/**
 * GraphConnectionBase
 * auto generated base class for the model GraphConnectionModel.
 */
export const GraphConnectionModelBase = ModelBase
  .named('GraphConnection')
  .props({
    __typename: types.optional(types.literal("GraphConnection"), "GraphConnection"),
    address: types.union(types.undefined, types.string),
    from: types.union(types.undefined, types.late((): any => WalletModel)),
    to: types.union(types.undefined, types.late((): any => WalletModel)),
    connectedAt: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class GraphConnectionModelSelector extends QueryBuilder {
  get address() { return this.__attr(`address`) }
  get connectedAt() { return this.__attr(`connectedAt`) }
  from(builder: string | WalletModelSelector | ((selector: WalletModelSelector) => WalletModelSelector) | undefined) { return this.__child(`from`, WalletModelSelector, builder) }
  to(builder: string | WalletModelSelector | ((selector: WalletModelSelector) => WalletModelSelector) | undefined) { return this.__child(`to`, WalletModelSelector, builder) }
}
export function selectFromGraphConnection() {
  return new GraphConnectionModelSelector()
}

export const graphConnectionModelPrimitives = selectFromGraphConnection().address.connectedAt

import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const DeleteIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 14 16" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M4.5 12.5C4.5 12.7812 4.25 13 4 13C3.71875 13 3.5 12.7812 3.5 12.5V5.5C3.5 5.25 3.71875 5 4 5C4.25 5 4.5 5.25 4.5 5.5V12.5ZM7.5 12.5C7.5 12.7812 7.25 13 7 13C6.71875 13 6.5 12.7812 6.5 12.5V5.5C6.5 5.25 6.71875 5 7 5C7.25 5 7.5 5.25 7.5 5.5V12.5ZM10.5 12.5C10.5 12.7812 10.25 13 10 13C9.71875 13 9.5 12.7812 9.5 12.5V5.5C9.5 5.25 9.71875 5 10 5C10.25 5 10.5 5.25 10.5 5.5V12.5ZM9.6875 0.71875L10.5 2H13.5C13.75 2 14 2.25 14 2.5C14 2.78125 13.75 3 13.5 3H13V13.5C13 14.9062 11.875 16 10.5 16H3.5C2.09375 16 1 14.9062 1 13.5V3H0.5C0.21875 3 0 2.78125 0 2.5C0 2.25 0.21875 2 0.5 2H3.46875L4.28125 0.71875C4.53125 0.28125 5.03125 0 5.53125 0H8.4375C8.9375 0 9.4375 0.28125 9.6875 0.71875ZM4.625 2H9.34375L8.84375 1.25C8.75 1.09375 8.59375 1 8.4375 1H5.53125C5.375 1 5.21875 1.09375 5.125 1.25L4.625 2ZM2 13.5C2 14.3438 2.65625 15 3.5 15H10.5C11.3125 15 12 14.3438 12 13.5V3H2V13.5Z"
    />
  </Icon>
)

import { Center, Image } from 'native-base'

function ProfileMetamask() {
  return (
    <Center>
      <Image
        marginTop={5}
        alt="profile"
        accessibilityIgnoresInvertColors
        height="110px"
        justifyContent="flex-start"
        resizeMode="cover"
        width="110px"
        source={require('../assets/profile-metamask.png')}
      />
    </Center>
  )
}

export { ProfileMetamask }

import { useUser } from 'libs/sdk/react/src/hooks'

import { converToContactSelector, useGetPublicProfiles } from '../../profile'
import { useGetUserFollowers, useGetUserFollowing } from '../../relationships'
import { ContactSources, Destination } from '../types'

export const useGetFollowingPeople = (destination?: Destination) => {
  const user = useUser()
  const relFollowing = useGetUserFollowing(user?.sub ?? '', {
    select: relContact => relContact.map(contact => contact.value)
  })
  const publicProfiles = useGetPublicProfiles(relFollowing?.data ?? [], user =>
    converToContactSelector(user, ContactSources.following, destination)
  )

  return publicProfiles
}

export const useGetFollowerPeople = (destination?: Destination) => {
  const user = useUser()
  const relFollowing = useGetUserFollowers(user?.sub ?? '', {
    select: relContact => relContact.map(contact => contact.value)
  })
  const publicProfiles = useGetPublicProfiles(relFollowing?.data ?? [], user =>
    converToContactSelector(user, ContactSources.followers, destination)
  )

  return publicProfiles
}

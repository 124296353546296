import { Circle } from 'react-native-svg'
import { Icon } from 'native-base'

type MoreIconProps = {
  size: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
}

export const More = ({ size, fill }: MoreIconProps) => (
  <Icon viewBox="0 0 4 18" size={size}>
    <Circle cx="2" cy="2" r="2" fill={fill} />
    <Circle cx="2" cy="9" r="2" fill={fill} />
    <Circle cx="2" cy="16" r="2" fill={fill} />
  </Icon>
)

import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import { getRepliesData } from '../../../helpers'
import { useMessageReactions, useMessageThreadReplies, usePlugins } from '../../../queries'
import { useCommunitiesTheme } from '../../../themes'

import ReactionsIcon from './assets/reactions_icon.svg'
import RepliesIcon from './assets/replies_icon.svg'
import PostReputationCounter from './PostReputationCounter'

const ThreadRootInfo = (props: any) => {
  const { matrixMessage, roomId } = props

  const { reputation } = matrixMessage

  const { data: plugins, isSuccess } = usePlugins()

  const firstPlugin = isSuccess ? plugins[0] : null

  const { data: reactions } = useMessageReactions(roomId, matrixMessage.eventId)
  const { data: repliesData } = useMessageThreadReplies(roomId, matrixMessage.eventId)
  const threadReplies = getRepliesData(repliesData)

  const reactionTypes = ['like', 'dislike']
  const emojies =
    reactions?.filter(reaction => !reactionTypes.some(type => type === reaction.key)) ?? []

  const communitiesTheme = useCommunitiesTheme()

  const threadRepliesCount = threadReplies?.length

  return (
    <View style={[styles.container, { borderTopColor: communitiesTheme.threadRootInfo.border }]}>
      <View style={styles.infoContainer}>
        <RepliesIcon style={{ marginRight: 3 }} fill={communitiesTheme.text} />
        <Text style={[{ ...styles.text, marginRight: 20 }, { color: communitiesTheme.text }]}>
          <Text style={{ fontWeight: '600' }}>{threadRepliesCount}</Text>{' '}
          {threadRepliesCount === 1 ? 'Reply' : 'Replies'}
        </Text>
      </View>

      <View style={styles.infoContainer}>
        <ReactionsIcon style={{ marginRight: 3 }} fill={communitiesTheme.text} />
        <Text style={[styles.text, { color: communitiesTheme.text }]}>
          <Text style={{ fontWeight: '600' }}>{emojies.length}</Text> Reactions
        </Text>
      </View>

      {firstPlugin?.id === 'reputation' ? (
        <View style={{ marginTop: -12, marginLeft: 'auto' }}>
          <PostReputationCounter
            value={''}
            reputation={reputation}
            isReply={!('eventType' in matrixMessage)}
          />
        </View>
      ) : null}
    </View>
  )
}

export default ThreadRootInfo

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 8,
    width: '100%',
    borderTopWidth: 1
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  text: {
    fontSize: 13,
    lineHeight: 18,
    fontWeight: '400'
  }
})

import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'

import { useMessage } from '../../../queries'
import { useStoreContext } from '../../MSTContextProvider'
import { PollChoice } from '../stores'

import ModalPollChoice from './ModalPollChoice'

const ModalPollChoicesList = observer(
  ({ basePollChoices }: { basePollChoices: SnapshotOut<typeof PollChoice>[] }) => {
    const store = useStoreContext()

    React.useEffect(() => {
      store.initPollChoices(basePollChoices)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return store.pollChoices.map((pollChoice, index) => {
      return <ModalPollChoice key={`poll-choice-${index}`} input={pollChoice} />
    })
  }
)

const ModalPollChoicesHolder = ({ roomId, messageId }: { roomId: string; messageId?: string }) => {
  let basePollChoices = [
    { index: 1, value: '' },
    { index: 2, value: '' }
  ]

  const { data: matrixMessage } = useMessage(roomId, messageId)

  if (matrixMessage) {
    const messageInputs = JSON.parse(matrixMessage.content.body)
    const messagePollChoices = messageInputs.elements.find(
      ({ type }: { type: string }) => type === 'choices'
    )
    if (messagePollChoices) {
      basePollChoices = messagePollChoices.content
    }
  }

  return <ModalPollChoicesList basePollChoices={basePollChoices} />
}

export default ModalPollChoicesHolder

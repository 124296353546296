import logger from '../../logger'
import { getMaxListenerCancel } from '../firestore'
import { mixerCreate } from '../functions'

import Mixer, { MixerState } from './Mixer'
/** Wait until the specified condition returns trueish, and then return the result of the condition. Usage: `let result = await waitFor(cb => myPossiblyNullFunction())` */
export async function waitFor(code: any, timeout = 15000) {
  // Run loop
  const startedAt = Date.now()
  while (Date.now() - startedAt < timeout) {
    // Run it
    const out = await code()
    if (out) return out

    // Wait a bit
    await new Promise(cb => setTimeout(cb, 500))
  }

  // Timed out
  throw new Error('Timed out.')
}

/** A map of arrays. */
class ArrayMap<K, T> {
  private map = new Map<K, T[]>()

  /** Adds an item under a key. */
  add(key: K, item: T) {
    if (this.map.has(key)) this.map.get(key)!.push(item)
    else this.map.set(key, [item])
  }

  /*
   * Removes an item under a key.
   * If there are multiple instances (by reference) each one will be removed.
   */
  remove(key: K, item: T) {
    if (!this.map.has(key)) return
    const newArray = this.map.get(key)!.filter(c => c !== item)

    if (newArray.length > 0) this.map.set(key, newArray)
    else this.map.delete(key)
  }

  getForKey = (key: K) => this.map.get(key) ?? []
}

/** Design pattern to listen for and dispatch events by a string "type". */
export class EventEmitter {
  private cbs = new ArrayMap<string, (args: any) => any>()

  /** Add a callback function for an event type. */
  on = (type: string, cb: (args: any) => any) => {
    this.cbs.add(type, cb)
  }

  /** Remove a callback function for an event type. */
  off = (type: string, cb: (args: any) => any) => this.cbs.remove(type, cb)

  /** Execute all callbacks for an event type. */
  emit = (type: string, ...args: any) => {
    this.cbs.getForKey(type).forEach(cb => cb.apply(this, args))
  }

  /** Add a callback function for an event type - to be fired only once. */
  once(type: string, cb: (args: any) => any) {
    const onceCb = (...args: any) => {
      cb.apply(this, args)
      this.off(type, onceCb)
    }
    this.on(type, onceCb)
  }

  trigger(...args: any) {
    // eslint-disable-next-line prefer-spread
    return this.emit.apply(this, args)
  }

  /**
   * Returns a promise which is resolved when the next occurrence of a given
   * event type is emitted.
   */
  until = (type: string) => new Promise(resolve => this.once(type, resolve))
}

interface MixerInfo {
  id: string
  distance: number
  x: number
  y: number
  z: number
}

/**
 * This class manages the audio connection to the mixers.
 *
 * @event updated Triggered when the list of active mixers changes, or any properties within them.
 */
export default class Mixers extends EventEmitter {
  /** Mixer connections */
  active: Mixer[] = []

  /** All mixers described in the database */
  all: MixerInfo[] = []

  /** True if first load has happened */
  loaded = false

  /** @type {Mixer} The primary mixer, if any. This is the mixer we "hear". */
  primaryMixer?: Mixer

  /** Last user position */
  lastPosition = { x: 0, y: 0, z: 0 }

  /** Server ID */
  serverID = ''

  /** Dimension ID */
  dimensionID = 'default'

  /** Microphone input track */
  micInputStream?: MediaStream
  mixerListenerCancel: any
  lastMixerRequest?: number
  updateIsRunning: any
  isShutDown: any
  inputAudioTrack: any

  userId: string
  firebaseApp?: any

  /** Constructor */
  constructor(serverID: string, userId: string, firebaseApp?: any) {
    super()

    // Store vars
    this.serverID = serverID
    this.userId = userId
    this.firebaseApp = firebaseApp
  }

  /** Muted state */
  _muted = true
  get muted() {
    return this._muted
  }

  set muted(v) {
    // Pass muted state to individual mixers as well
    this._muted = v
    for (const mixer of this.active) mixer.muted = this.muted

    // If unmuting, ensure input device is available
    // if (!v) AudioManager.checkInputDeviceAvailability().catch(err => {

    //     // Failed, display error in console
    //     logger.error('Unable to set an input device.', err)

    //     // Display error to the user
    //     let supportsAudioCapture = !!navigator.mediaDevices?.getUserMedia
    //     Toast.show({
    //         text: supportsAudioCapture ? "There was a problem accessing your microphone." : "This browser doesn't support microphone access.",
    //         duration: 15000,
    //         buttonText: "Try again",
    //         buttonAction: e => {

    //             // Unmute again. Since this was triggered by the mouse click on the toast alert it is a 'secure' event, so
    //             // we may have better luck this time.
    //             this.muted = false
    //             HUD.singleton.forceUpdate()

    //         }
    //     })

    //     // Mute again
    //     this.muted = true
    //     // HUD.singleton.forceUpdate()

    // })

    // Log analytics
    // Analytics.track('userLog', { event: v ? 'user+micOff' : 'user+micOn', action: v ? 'micOff' : 'micOn' })
  }

  /** If true, will attempt to connect to mixers */
  mixerConnectionNeeded = false

  /** Called on start */
  async start() {
    // Reset current Mixer if any
    this.all = []
    this.loaded = false

    // Listen for mixer information
    if (this.mixerListenerCancel) this.mixerListenerCancel()
    this.mixerListenerCancel = getMaxListenerCancel(
      this.serverID,
      this.dimensionID,
      this.onMixersUpdated.bind(this),
      this.firebaseApp
    )
  }

  /** @private Called when the mixer data in the database changes */
  onMixersUpdated(snap: any) {
    // Store it
    this.all = snap.docs.map((d: any) => Object.assign(d.data(), { id: d.id }))
    this.loaded = true
    this.emit('updated')

    // Update active mixer configs
    for (const activeMixer of this.active)
      activeMixer.config = this.all.find(m => m.id == activeMixer.id) || activeMixer.config

    // Update mixer distances
    this.updateMixerDistances()
  }

  /** @private Update mixer distance values in the config */
  updateMixerDistances() {
    // Stop if no distance value has been given yet
    if (!this.lastPosition) return

    // Calculate mixer distances
    for (const mixerInfo of this.all) {
      mixerInfo.distance = Math.sqrt(
        Math.pow(mixerInfo.x - this.lastPosition.x, 2) +
          Math.pow(mixerInfo.y - this.lastPosition.y, 2) +
          Math.pow(mixerInfo.z - this.lastPosition.z, 2)
      )
    }
  }

  /** Get the closest mixer to a point */
  closestMixerInfoToPoint(x: number, y: number, z: number) {
    // Go through each one
    let foundDistance = 0
    let foundMixer = null
    for (const mixerInfo of this.all) {
      // Get distance
      const distance = Math.sqrt(
        Math.pow(mixerInfo.x - x, 2) + Math.pow(mixerInfo.y - y, 2) + Math.pow(mixerInfo.z - z, 2)
      )

      // If not found yet, use this one
      if (!foundMixer || foundDistance > distance) {
        foundMixer = mixerInfo
        foundDistance = distance
      }
    }

    // Done
    return foundMixer
  }

  /** Set the avatar's position. Called by Map3D. */
  setPosition(x: number, y: number, z: number) {
    // Store it
    this.lastPosition.x = x
    this.lastPosition.y = y
    this.lastPosition.z = z
    // this.lastQuaternion = orientationQuaternion

    // Send to mixers
    for (const mixer of this.active) mixer.setPosition(x, y, z)

    // Update mixer distances
    this.updateMixerDistances()
  }

  /** Instantly shut down all mixers. Note, this may cause them to be reconnected if rendering is still ongoing. */
  async shutdown() {
    // Is shut down
    this.isShutDown = true

    // Stop timers
    clearInterval(this.mixerUpdateTimer)

    // Stop listeners
    this.mixerListenerCancel?.()

    // Shutdown all mixers
    await Promise.all(
      this.active.map(async mixer => {
        return await mixer.close()
      })
    )
    this.active = []
  }

  /**
   * Create a new mixer in this space.
   *
   * @param {Vector3} position The position this mixer should be created at.
   * @returns {boolean} True if the mixer was created, false if someone else has requested a mixer already within the last few seconds.
   **/
  async create(position: any) {
    // Normalize position. Mixers are created on a 10x10x10 grid, so the x, y, z values should be multiples of 10.
    const gridSize = 10
    const config: any = {
      x: Math.floor((position.x || 0) / gridSize) * gridSize,
      y: Math.floor((position.y || 0) / gridSize) * gridSize,
      z: Math.floor((position.z || 0) / gridSize) * gridSize
    }

    // Create zone ID
    config.zone = `mixer:${this.serverID}:${this.dimensionID}:${config.x}:${config.y}:${config.z}`
    // Check if a mixer at this location exists already
    if (this.all.find(m => m.x == config.x && m.y == config.y && m.z == config.z))
      throw new Error('Cannot request a new mixer, one already exists at this location.')

    // Check if it's too soon to request another mixer
    if (this.lastMixerRequest && Date.now() - this.lastMixerRequest < 15000) return false //throw new Error("Cannot request a mixer, one has been requested too recently.")
    this.lastMixerRequest = Date.now()
    try {
      // Call cloud function to create the mixer
      const result = await mixerCreate(this.serverID, this.dimensionID, config, this.firebaseApp)

      logger.info('[Mixers] Created mixer', this.serverID, this.dimensionID, result)
      // Return result
      return !result?.data?.requestedTooSoon
    } catch (err) {
      this.lastMixerRequest = undefined
      throw err
    }
  }

  /** Run the update loop every 2 seconds. */
  mixerUpdateTimer = setInterval(e => this.updateNow(), 2000)

  /** Run an iteration of the update loop immediately */
  async updateNow() {
    if (this.isShutDown) return
    // Run only one at a time
    if (this.updateIsRunning) return
    this.updateIsRunning = true

    // Run it
    try {
      // Run the loop
      await this._updateLoop()
    } catch (err) {
      // Update failed
      logger.warn(`[Mixers] Error during update loop: `, err)
    }

    // Done
    this.updateIsRunning = false
  }

  async _updateLoop() {
    // Stop if not loaded yet
    if (!this.loaded) return

    // Stop if shut down
    if (this.isShutDown) return

    // Stop if not loaded
    // if (!Server.hasJoined)
    //     return

    // Stop if switching spaces
    // if (this.isSwitchingSpaces)
    //     return

    // Stop if the start or shutdown screen is visible
    // if (StartScreen.isVisible || ShutdownScreen.isVisible)
    //     return

    // Stop if we haven't received a position yet
    // if (!this.lastPosition)
    //     return

    // If there are no mixers on this server, check what to do
    if (this.all.length == 0) {
      // Stop if we don't need to request a mixer right now
      if (!this.mixerConnectionNeeded) return // logger.debug(`[Mixers] Skipping mixer request since we don't need a mixer connection right now.`)

      // Request a new mixer
      const success = await this.create(this.lastPosition)
      if (!success)
        return logger.debug(`[Mixers] Unable to request a mixer, one has been requested recently.`)

      // Wait for mixers to be updated
      logger.debug(`[Mixers] Requested new mixer, waiting for database update...`)
      await waitFor((e: any) => this.all.length > 0, 15000)
    }

    // NOTE: After this point, there is guaranteed to be at least one mixer document in `this.all`.

    // Update mixer distances
    this.updateMixerDistances()

    // Get our primary mixer, which is the closest one
    this.all.sort((a: any, b: any) => a.distance - b.distance)
    const primaryMixerInfo = this.all[0]

    // Make a list of all mixers we want to be connected to
    // TODO: This should account for attenuation zones and so on
    const desiredMixers = [primaryMixerInfo]

    // Add mixers that are within the default listening range as well. This helps when overlapping between two mixers.
    const AudibleRange = 10 * 1.25
    for (const mixerInfo of this.all)
      if (mixerInfo.distance < AudibleRange)
        if (!desiredMixers.find(m => m.id == mixerInfo.id)) desiredMixers.push(mixerInfo)

    // Add the closest mixers to a certain radius around the user. This helps with being on the boundary of two mixers that are far away from each other
    const ConnectDistance = 4
    for (let x = -ConnectDistance; x <= ConnectDistance; x += ConnectDistance) {
      for (let y = -ConnectDistance; y <= ConnectDistance; y += ConnectDistance) {
        for (let z = -ConnectDistance; z <= ConnectDistance; z += ConnectDistance) {
          // Get closest mixer to this point
          const mixer = this.closestMixerInfoToPoint(
            this.lastPosition.x + x,
            this.lastPosition.y + y,
            this.lastPosition.z + z
          )

          // Add it if necessary
          if (mixer && !desiredMixers.includes(mixer)) desiredMixers.push(mixer)
        }
      }
    }

    // Go through each one and connect
    for (const mixerInfo of desiredMixers) {
      // Stop if mixer exists already
      let mixer = this.active.find(m => m.id == mixerInfo.id)
      if (mixer) continue

      // Mixer does not exist, create it
      // logger.debug(`[Mixers] Connecting to mixer: id=${mixerInfo.id} position=${mixerInfo.x},${mixerInfo.y},${mixerInfo.z} distance=${mixerInfo.distance}`)
      mixer = new Mixer(this.firebaseApp)
      mixer.mixers = this
      mixer.config = mixerInfo
      mixer.muted = this.muted
      mixer.userId = this.userId
      mixer.inputAudioTrack = this.inputAudioTrack
      mixer.setPosition(this.lastPosition.x, this.lastPosition.y, this.lastPosition.z)
      this.active.push(mixer)

      // Connect to it
      mixer.connect()
      this.emit('updated')
    }

    // Find primary mixer. This should in theory never fail.
    const primaryMixer = this.active.find(m => m.id == primaryMixerInfo.id)
    if (!primaryMixer) throw new Error("Logic error: Couldn't find primary mixer.")

    // Check if primary mixer is over capacity
    if (primaryMixer.state != MixerState.Connected && primaryMixer.isOverCapacity) {
      // Request a new mixer
      logger.debug(`[Mixers] Primary mixer is over capacity, requesting a new mixer...`)
      const success = await this.create(this.lastPosition)
      if (!success)
        logger.debug(`[Mixers] Unable to request a mixer, one has been requested recently.`)

      // Cannot continue until we have a good mixer
      return
    }

    // Stop if primary mixer is not connected
    if (primaryMixer.state != MixerState.Connected || !primaryMixer.audioOutputStream) return // logger.debug(`[Mixers] Skipping mixer cleanup since primary mixer is not connected yet`)

    // Make sure primary mixer is set as primary and all others are not. This will also switch the audio we hear to this mixer.
    if (!primaryMixer.isPrimary) {
      // Go through all mixers
      logger.debug(
        `[Mixers] Making mixer the primary: id=${primaryMixer.id} position=${primaryMixer.config.x},${primaryMixer.config.y},${primaryMixer.config.z} distance=${primaryMixer.config.distance}`
      )
      for (const mixer of this.active) {
        // Check if primary
        if (mixer == primaryMixer) {
          // Make primary
          mixer.isPrimary = true

          logger.debug(`[Mixers] Setting primary mixer`, mixer.id)

          // Store it
          this.primaryMixer = mixer

          // Play this mixer's audio
          // logger.debug(`[Mixers] Playing mixer output stream: `, mixer.audioOutputStream)
          mixer.audioOutputStream && this.playAudioStream(mixer.audioOutputStream)
        } else {
          // Not primary
          mixer.isPrimary = false

          // Stop this mixer's audio
          mixer.audioOutputStream && this.stopAudioStream(mixer.audioOutputStream)
        }
      }

      // Updated primary mixer
      this.emit('updated')
    }

    // Disconnect unnecessary mixers
    for (const mixer of this.active) {
      // Check if it's in our list
      if (desiredMixers.find(m => m.id == mixer.id)) continue

      // This mixer is unnecessary, remove it
      logger.debug(
        `[Mixers] Remove unneeded mixer: id=${mixer.id} position=${mixer.config.x},${mixer.config.y},${mixer.config.z} distance=${mixer.config.distance}`
      )
      this.active = this.active.filter(m => m != mixer)
      mixer.close()
      this.emit('updated')
    }
  }

  // Currently playing audio streams
  audioPlayers: HTMLAudioElement[] = []

  // Play an audio stream
  playAudioStream(stream: MediaStream) {
    // Stop if not on Web ... on Native the WebRTC streams are played automatically
    if (typeof window == 'undefined' || !window.Audio) return

    // Check if already playing
    if (this.audioPlayers.find(player => player.srcObject == stream)) return

    // Start playing it
    const audio = new Audio()
    audio.srcObject = stream
    audio.play()

    // Store it
    this.audioPlayers.push(audio)
  }

  // Stop an audio stream
  stopAudioStream(stream: MediaStream) {
    // Find player
    const playerIdx = this.audioPlayers.findIndex(player => player.srcObject == stream)
    if (playerIdx == -1) return

    // Stop and remove it
    const player = this.audioPlayers[playerIdx]
    player.srcObject = null
    this.audioPlayers.splice(playerIdx, 1)
  }
}

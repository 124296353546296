import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const PayoutIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 12 17" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M11.4062 4.4375C11.7812 4.8125 12 5.3125 12 5.84375V14.5C12 15.625 11.0938 16.5 10 16.5H2C0.875 16.5 0 15.625 0 14.5V2.5C0 1.40625 0.875 0.5 2 0.5H6.65625C7.1875 0.5 7.6875 0.71875 8.0625 1.09375L11.4062 4.4375ZM7 1.59375V5C7 5.28125 7.21875 5.5 7.5 5.5H10.9062C10.875 5.375 10.8125 5.25 10.6875 5.125L7.375 1.8125C7.25 1.6875 7.125 1.625 7 1.59375ZM11 14.5V6.5H7.5C6.65625 6.5 6 5.84375 6 5V1.5H2C1.4375 1.5 1 1.96875 1 2.5V14.5C1 15.0625 1.4375 15.5 2 15.5H10C10.5312 15.5 11 15.0625 11 14.5ZM2.5 3.5C2.21875 3.5 2 3.28125 2 3C2 2.75 2.21875 2.5 2.5 2.5H4.5C4.75 2.5 5 2.75 5 3C5 3.28125 4.75 3.5 4.5 3.5H2.5ZM5 5C5 5.28125 4.75 5.5 4.5 5.5H2.5C2.21875 5.5 2 5.28125 2 5C2 4.75 2.21875 4.5 2.5 4.5H4.5C4.75 4.5 5 4.75 5 5ZM6.3125 9.53125C5.625 9.4375 5.0625 9.59375 5 9.90625C4.96875 10.0625 4.9375 10.2188 5.90625 10.4688L6.09375 10.5312C6.9375 10.75 8.1875 11.0625 7.96875 12.3125C7.84375 12.9688 7.28125 13.375 6.46875 13.4688C6.46875 13.5 6.5 13.5 6.5 13.5V14C6.5 14.2812 6.25 14.5 6 14.5C5.71875 14.5 5.5 14.2812 5.5 14V13.5V13.4688C5.15625 13.4062 4.8125 13.3125 4.5 13.2188L4.34375 13.1562C4.0625 13.0625 3.9375 12.7812 4 12.5312C4.09375 12.2812 4.375 12.125 4.625 12.2188L4.78125 12.25C5.0625 12.3438 5.40625 12.4375 5.65625 12.5C6.34375 12.5938 6.90625 12.4375 6.96875 12.125C7.03125 11.875 6.875 11.75 5.875 11.5L5.65625 11.4375C5.03125 11.2812 3.78125 10.9688 4 9.71875C4.125 9.0625 4.6875 8.65625 5.5 8.53125C5.5 8.53125 5.5 8.53125 5.5 8.5V8C5.5 7.75 5.71875 7.5 6 7.5C6.25 7.5 6.5 7.75 6.5 8V8.5C6.5 8.53125 6.46875 8.53125 6.46875 8.5625C6.6875 8.59375 6.9375 8.65625 7.25 8.75C7.53125 8.84375 7.65625 9.125 7.59375 9.375C7.5 9.625 7.21875 9.78125 6.96875 9.71875C6.75 9.625 6.5 9.5625 6.3125 9.53125Z"
    />
  </Icon>
)

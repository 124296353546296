import React from 'react'
import { PressableOpacity, Text } from '@vatom/wombo'
import { Box, HStack } from 'native-base'

import EraseIcon from '../../assets/erase.svg'

type NumberProps = React.PropsWithChildren & {
  onPress: () => void
  onLongPress?: () => void
  color?: string
}
const Number = ({ children, onPress, onLongPress, color }: NumberProps) => (
  <PressableOpacity
    flex={1}
    alignItems={'center'}
    justifyContent={'center'}
    onPress={onPress}
    onLongPress={onLongPress}
    hitSlop={10}
  >
    <Text
      fontSize={32}
      lineHeight={32}
      fontFamily={'Inter-Regular'}
      fontWeight={400}
      color={color ?? '#11141E'}
    >
      {children}
    </Text>
  </PressableOpacity>
)

export type NumberPadProps = {
  onPress: (number: string) => void
  onPressErase: () => void
  onLongPressErase?: () => void
  color?: string
}

export const NumberPad = ({ onPress, onPressErase, onLongPressErase, color }: NumberPadProps) => {
  return (
    <Box py={28} _web={{ padding: 0 }}>
      <HStack mb={28}>
        <Number color={color} onPress={() => onPress('1')}>
          1
        </Number>
        <Number color={color} onPress={() => onPress('2')}>
          2
        </Number>
        <Number color={color} onPress={() => onPress('3')}>
          3
        </Number>
      </HStack>
      <HStack mb={28}>
        <Number color={color} onPress={() => onPress('4')}>
          4
        </Number>
        <Number color={color} onPress={() => onPress('5')}>
          5
        </Number>
        <Number color={color} onPress={() => onPress('6')}>
          6
        </Number>
      </HStack>
      <HStack mb={28}>
        <Number color={color} onPress={() => onPress('7')}>
          7
        </Number>
        <Number color={color} onPress={() => onPress('8')}>
          8
        </Number>
        <Number color={color} onPress={() => onPress('9')}>
          9
        </Number>
      </HStack>
      <HStack
        mb={28}
        _web={{
          marginBottom: 14
        }}
      >
        <Number color={color} onPress={() => onPress('.')}>
          .
        </Number>
        <Number color={color} onPress={() => onPress('0')}>
          0
        </Number>
        <Number color={color} onPress={onPressErase} onLongPress={onLongPressErase}>
          <Box>
            <EraseIcon fill={color ?? '#11141E'} />
          </Box>
        </Number>
      </HStack>
    </Box>
  )
}

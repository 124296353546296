import { useThree } from '@react-three/fiber'
import { CubeTexture, CubeTextureLoader, RGBAFormat } from 'three'

let envMap: CubeTexture
const getEnvMap = () => {
  if (envMap) return envMap

  const cubeMapURLs = [
    require('libs/sdk/react/src/faces/Faces/ThreeDFace/envmap_px.jpg'),
    require('libs/sdk/react/src/faces/Faces/ThreeDFace/envmap_nx.jpg'),
    require('libs/sdk/react/src/faces/Faces/ThreeDFace/envmap_py.jpg'),
    require('libs/sdk/react/src/faces/Faces/ThreeDFace/envmap_ny.jpg'),
    require('libs/sdk/react/src/faces/Faces/ThreeDFace/envmap_pz.jpg'),
    require('libs/sdk/react/src/faces/Faces/ThreeDFace/envmap_nz.jpg')
  ]

  const loader = new CubeTextureLoader()
  // The CubeTextureLoader load method takes an array of urls representing all 6 sides of the cube.
  return loader.load(cubeMapURLs)
}

export function SkyBox() {
  // highlight-start
  const { scene } = useThree()

  // The CubeTextureLoader load method takes an array of urls representing all 6 sides of the cube.
  const texture = getEnvMap()
  texture.format = RGBAFormat
  // Set the scene background property to the resulting texture.
  if (!scene.environment) scene.environment = texture

  return null
}

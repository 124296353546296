import { deg } from 'react-native-linear-gradient-degree'
import { PressableOpacity, theme } from '@vatom/wombo'
import { Box } from 'native-base'

import { FAB } from '../../../components/FAB'
import { ScanQrCode } from '../../../components/Icons'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'

type FabScanQrProps = {
  enabled: boolean
  onPress: () => void
} & React.ComponentProps<typeof Box>

// TODO: change styles with business custom theme
const FabScanQr = ({ enabled, onPress, ...boxProps }: FabScanQrProps) => {
  // const { isEmbedded, config } = useVatomWalletSdkStore()
  // const isEmbedded = useVatomWalletSdkStore(state => state.isEmbedded)

  // const scannerEnabled = useVatomWalletSdkStore(state => !!state.config.scanner?.enabled)
  const { brandTheme } = useBusinessTheme()

  // const showFabScanQr =
  //   isEmbedded && scannerConfig?.enabled !== null && scannerConfig?.enabled !== undefined
  //     ? scannerConfig?.enabled
  //     : true
  console.log('CONSOLE.LOG: FabScanQr.tsx: enabled: ', enabled)

  if (!enabled) return null

  const gradientDeg = deg(240)
  const startDeg = [gradientDeg.start.x, gradientDeg.start.y]
  const endDeg = [gradientDeg.end.x, gradientDeg.end.y]

  const styles = brandTheme?.primary
    ? {
        backgroundColor: brandTheme.primary,
        text: brandTheme.primaryText ?? '#fff'
      }
    : {
        backgroundColor: {
          linearGradient: {
            colors: [theme.colors.brand[500], '#FF7A00'],
            start: startDeg,
            end: endDeg
          }
        },
        text: '#fff'
      }

  return (
    <FAB enabled={enabled}>
      <PressableOpacity onPress={onPress}>
        <Box
          borderRadius={999}
          size={60}
          backgroundColor={styles.backgroundColor}
          alignItems={'center'}
          justifyContent={'center'}
          {...boxProps}
        >
          <ScanQrCode fill={styles.text} size={7} />
        </Box>
      </PressableOpacity>
    </FAB>
  )
}

export { FabScanQr }

import { applySnapshot, getRoot, Instance, SnapshotOut, types } from 'mobx-state-tree'

import { RootSDKStoreType } from '../RootSdk'

export type SpaceType = Instance<typeof Space>
export type SpaceSnapshot = SnapshotOut<typeof Space>

export const SpaceCompatibility = types.model('SpaceCompatibility').props({
  mobile: types.maybe(types.boolean)
})

export enum SpaceAccessLevel {
  Anonymous = 'anonymous',
  Guest = 'guest',
  User = 'user'
}

export const Space = types
  .model('Space')
  .props({
    id: types.identifier,
    url: types.string,
    displayName: types.string,
    subTitle: types.maybe(types.string),
    description: types.string,
    createdAt: types.string,
    createdById: types.string,
    updatedAt: types.string,
    businessId: types.string,
    businessLogo: types.maybe(types.string),
    addressable: types.boolean,
    alias: types.maybe(types.union(types.string, types.null)),
    cover: types.maybe(types.string),
    accessLevel: types.enumeration<SpaceAccessLevel>(Object.values(SpaceAccessLevel)),
    dashboardAnalyticsUrl: types.maybe(types.string),
    detailedDashboardAnalyticsUrl: types.maybe(types.string),
    startedAt: types.maybe(types.union(types.string, types.null)),
    stoppedAt: types.maybe(types.union(types.string, types.null)),
    provisionId: types.maybe(types.union(types.string, types.null)),
    isAuthorized: types.maybe(types.boolean),
    code: types.maybe(types.union(types.string, types.null)),
    // entryCoordinates?: any
    // browserCompatibility?: any
    attendeeCount: types.maybe(types.number),
    maxAttendees: types.maybe(types.number),
    path: types.maybe(types.union(types.string, types.null)),
    compatibility: types.maybe(SpaceCompatibility),
    matrixRoomId: types.maybe(types.union(types.string, types.null)),
    communityId: types.maybe(types.union(types.string, types.null))
  })
  .actions(self => ({
    setAttendeeCount(attendeeCount: number) {
      self.attendeeCount = attendeeCount
    }
  }))

export const SpaceStore = types
  .model('SpaceStore')
  .props({
    _spaces: types.optional(types.map(Space), {})
  })
  // .extend(withRootSDKStore)
  .views(self => ({
    get rootStore(): any {
      // @TODO: Circular dependency:
      return getRoot<RootSDKStoreType>(self) as any
    }
  }))
  .actions(self => ({
    /** Load remote config from cached copy */
    addUpdate(space: SpaceSnapshot) {
      if (self._spaces.has(space.id)) {
        const currentSpace = self._spaces.get(space.id)
        applySnapshot(currentSpace!, space)
      } else {
        self._spaces.put(space)
      }
    },
    remove(id: string) {
      if (self._spaces.has(id)) {
        self._spaces.delete(id)
      }
    },
    // Implement a stale-while-revalidate style cache using mobx
    async reload(id: string) {
      if (self._spaces.has(id)) {
        const space = await self.rootStore.vatomIncApi.getSpace(id)
        const currentSpace = self._spaces.get(id)
        // Set attendeeCount
        space.attendeeCount = currentSpace?.attendeeCount
        this.addUpdate(space)
      }
    },
    // Implement a stale-while-revalidate style cache using mobx
    async reloadAll() {
      Array.from<string>(self._spaces.keys()).map(id => this.reload(id))
    }
  }))
  .views(self => ({
    get items() {
      const spaces = Array.from<SpaceType>(self._spaces.values())
      return spaces
    },
    get(id: string) {
      return self._spaces.get(id)
    }
  }))

export type SpaceStoreType = Instance<typeof SpaceStore>
export type SpaceStoreSnapshot = SnapshotOut<typeof SpaceStore>

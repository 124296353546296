import { useRef } from 'react'

import { WebWrapper } from '../../../components/WebWrapper'

import Transfer, { ITransferProps } from './Transfer'

export default (props: ITransferProps) => {
  const ref = useRef(null)

  return (
    <WebWrapper justifyContent={'center'} flexGrow={1}>
      <Transfer ref={ref} {...props} />
    </WebWrapper>
  )
}

import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { BusinessFields, BusinessTerms } from '@vatom/sdk/core'

import { getRootStore } from '../../../SDKProvider'

export const termsAndConditionQueryKeys = {
  forms: [{ scope: 'forms' }] as const,
  terms: [{ scope: 'terms' }] as const,
  getForms: ({ businessId, campaignId }: { businessId?: string; campaignId?: string }) =>
    [{ ...termsAndConditionQueryKeys.forms[0], businessId, campaignId }] as const,
  getTerms: (businessId?: string) =>
    [{ ...termsAndConditionQueryKeys.terms[0], businessId }] as const
}

const fetchTerms = async ({
  queryKey: [{ businessId }]
}: QueryFunctionContext<ReturnType<typeof termsAndConditionQueryKeys['getTerms']>>) => {
  const apiInstance = getRootStore().service.oidc
  const response = await apiInstance?.get(`api/me/terms?business-id=${businessId}`)
  return response?.data
}

export const useBusinessTerms = <TData = BusinessTerms[]>(
  businessId?: string,
  select?: (terms: BusinessTerms[]) => TData,
  onSuccess?: (data: TData) => void
) => {
  const result = useQuery({
    queryKey: termsAndConditionQueryKeys.getTerms(businessId),
    queryFn: fetchTerms,
    enabled: !!businessId,
    initialData: [],
    staleTime: 0,
    select,
    onSuccess,
    refetchOnWindowFocus: true
  })

  return result
}

const fetchBusinessForm = async ({
  queryKey: [{ businessId, campaignId }]
}: QueryFunctionContext<ReturnType<typeof termsAndConditionQueryKeys['getForms']>>) => {
  const apiInstance = getRootStore().service.oidc

  const formQ = new URLSearchParams({
    flow: 'popup',
    'business-id': businessId ?? '',
    'campaign-id': campaignId ?? ''
  })

  const response = await apiInstance?.get(`api/me/info/form?${formQ.toString()}`)
  return response?.data || []
}

export const useBusinessForms = <TData = BusinessFields[]>(
  businessId?: string,
  campaignId?: string,
  select?: (fields: BusinessFields[]) => TData,
  onSuccess?: (data: TData) => void
) => {
  const result = useQuery({
    queryKey: termsAndConditionQueryKeys.getForms({
      businessId,
      campaignId
    }),
    queryFn: fetchBusinessForm,
    enabled: !!businessId && !!campaignId,
    initialData: [],
    staleTime: 0,
    select,
    onSuccess,
    refetchOnWindowFocus: true
  })

  return result
}

export const promptSelector = (terms: BusinessTerms[]) => terms?.filter(u => u.prompt) ?? []

export const valuesSelector = (fields: BusinessFields[]) => {
  const values = fields.reduce<Record<string, BusinessFields['value']>>((acc, field) => {
    acc[field.name] = field.value
    return acc
  }, {})

  return { fields, values }
}

import { useRef } from 'react'

import { WebWrapper } from '../../components/WebWrapper'

import CoinConfirmation, { CoinConfirmationProps } from './CoinConfirmation'

export default (props: CoinConfirmationProps) => {
  const ref = useRef(null)
  return (
    <WebWrapper justifyContent={'center'}>
      <CoinConfirmation ref={ref} {...props} />
    </WebWrapper>
  )
}

import React from 'react'
import { FlatList } from 'react-native'
import { useGetSpace } from '@vatom/sdk/react'
import { IEvent } from 'matrix-js-sdk'

import { getRepliesData } from '../../../helpers'
import { useMessageThreadReplies } from '../../../queries'
import { useCommunitiesTheme } from '../../../themes'
import ChatBubble from '../../ChatBubble/ChatBubble'
import ThreadChatBubble from '../../ChatBubble/ThreadChatBuble'

const ModalThreadReplies = ({ message, spaceId }: { message: IEvent; spaceId: string }) => {
  const space = useGetSpace(spaceId, {
    enabled: false
  })
  const roomId = space.data?.matrixRoomId ?? ''
  const communitiesTheme = useCommunitiesTheme()
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useMessageThreadReplies(
    roomId,
    message.event_id
  )

  const threadReplies = getRepliesData(data)
  threadReplies.sort((a, b) => a.timestamp - b.timestamp)

  return (
    <FlatList
      data={threadReplies}
      renderItem={({ item }) => (
        <ThreadChatBubble spaceId={spaceId} key={item.eventId} threadMessage={item} />
      )}
      keyExtractor={item => item.eventId}
      contentContainerStyle={{
        flexGrow: 1,
        paddingBottom: 160,
        backgroundColor: communitiesTheme.background
      }}
      ListHeaderComponent={<ChatBubble spaceId={spaceId} matrixMessage={message} isThread />}
      onEndReachedThreshold={0.7}
      onEndReached={() => (hasNextPage && !isFetchingNextPage ? fetchNextPage() : null)}
      removeClippedSubviews
      maxToRenderPerBatch={5}
      initialNumToRender={5}
    />
  )
}

export default ModalThreadReplies

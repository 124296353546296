import React from 'react'
import { Box, VStack } from 'native-base'

export const ActiveIcon = ({
  children,
  isActive
}: React.PropsWithChildren & { isActive: boolean }) => (
  <VStack>
    {isActive && (
      <Box
        position={'absolute'}
        bg={'#DD4267'}
        borderColor={'#fff'}
        borderWidth={1}
        borderRadius={999}
        mt={0}
        ml={-1}
        zIndex={1}
        alignSelf="flex-start"
        width={2.5}
        height={2.5}
      />
    )}
    {children}
  </VStack>
)

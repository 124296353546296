import { useCallback } from 'react'
import { useIsFocused } from '@react-navigation/native'
import { useQueries, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'
import { TEthereumChains, TSolanaChains } from '@vatom/sdk/core'
import { useAccessToken, useIsAuthed, useSDK } from '@vatom/sdk/react'
import { Network } from 'alchemy-sdk'
import { AxiosInstance } from 'axios'
import { orderBy } from 'lodash'

import { ItemPoints } from '../screens/CoinDetail/partials/TransactionItem'
import { convertToPointsItem } from '../screens/CoinDetail/utils/convertTransactions'

// TODO: move to libs/sdk/react/store/server/features/loyalty

export const loyaltyApi = 'https://loyalty.api.vatominc.com'

type CoinTransaction = {
  id: string
  credit: string
  debit: string
  detail: string
  source: string
  link: string | null
  image: string | null
  creditType: string
  debitType: string
  points: number
  tokenId: string | null
  createdAt: string
}

export type Coin = {
  id: string
  businessId: string
  name: string
  plural_name: string
  logo: string
  points: number
  enabled: boolean
  userId: string
  symbol: string | null
  type: string | null
  address?: string
  isWeb3: boolean
  chain?: TSolanaChains | TEthereumChains
  isWatching?: boolean
  isSelfCustodial?: boolean
  alchemyNetwork?: Network
  contractAddress?: string
}

const transformNameToId = (name?: string) => name?.toLowerCase().replace(/ /g, '-').trim()

const fetchCoinsBusiness = async ({
  apiInstance,
  userId,
  businessId
}: {
  apiInstance: AxiosInstance
  userId: string | null
  businessId: string
}) => {
  if (!apiInstance || !userId || !businessId) {
    throw new Error('fetchCoinsBusiness missing params')
  }
  const response = await apiInstance.get(`/u/${userId}/coins?businessId=${businessId}`)
  const data = response.data
  return {
    ...data,
    id: transformNameToId(data?.name)
  } as Coin
}

const fetchCoins = async ({
  apiInstance,
  userId
}: {
  apiInstance: AxiosInstance
  userId: string | null
}) => {
  if (!apiInstance || !userId) {
    throw new Error('fetchCoins missing params')
  }
  const response = await apiInstance.get(`/u/${userId}/coins`)
  const coins = (response.data?.items as Coin[]) ?? []
  const coinsWithId = coins.map(coin => {
    const id = coin.id ?? transformNameToId(coin?.name)
    return { ...coin, id }
  })
  return coinsWithId as Coin[]
}

const fetchCoinTransactions = async ({
  apiInstance,
  userId
}: {
  apiInstance: AxiosInstance
  userId: string | null
}) => {
  if (!apiInstance || !userId) {
    throw new Error('fetchCoinTransactions missing params')
  }
  const response = await apiInstance.get(`/u/${userId}/coins/txns`)
  const transactions = (response.data?.items as CoinTransaction[]) ?? []
  return transactions
}

export type LoyaltyCoinOptions<T = Coin[]> = Omit<
  UseQueryOptions<Coin[], unknown, T, ['loyalty', 'coins', string | null]>,
  'queryKey' | 'queryFn'
>

export function useLoyaltyCoins(options: LoyaltyCoinOptions = {}) {
  const sdk = useSDK()
  const userId = sdk?.dataPool.user.userInfo?.sub ?? null
  const apiInstance = sdk.service.loyalty

  const queryCoins = useQuery({
    queryKey: ['loyalty', 'coins', userId],
    queryFn: () => fetchCoins({ apiInstance, userId }),
    enabled: !!userId,
    ...options
  })
  return queryCoins
}

export function useLoyaltyTransactions() {
  const sdk = useSDK()
  // const accessToken = useAccessToken()
  const userId = sdk?.dataPool.user.userInfo?.sub ?? null
  const apiInstance = sdk.service.loyalty

  const queryTransactions = useQuery({
    queryKey: ['loyalty', 'transactions', userId],
    queryFn: () => fetchCoinTransactions({ apiInstance, userId }),
    enabled: !!userId
  })

  return queryTransactions
}

type QueriesAcc = { queryKey: string[]; queryFn: () => Promise<ItemPoints> }

export function useLoyaltyTransactionPoints({
  businessId,
  order = ['timeAgo'],
  sort = 'desc'
}: {
  businessId?: string
  order?: string[]
  sort?: 'asc' | 'desc'
}) {
  const sdk = useSDK()
  const userId = sdk?.dataPool.user.userInfo?.sub ?? ''

  const { data } = useLoyaltyTransactions()

  const filteredData = !businessId
    ? data
    : data?.filter(transaction => transaction.tokenId === businessId)

  const queriesPoints = filteredData?.reduce((acc, transaction) => {
    const { id } = transaction

    const queryKey = ['loyalty', 'points', id]
    const queryFn = async () => {
      return await convertToPointsItem(transaction, { userId })
    }
    return [
      ...acc,
      {
        queryKey,
        queryFn
      }
    ]
  }, [] as QueriesAcc[])

  const queryPoints = useQueries({
    queries: queriesPoints ?? []
  })

  const isError = queryPoints.some(query => query.isError)
  const isLoading = queryPoints.some(query => query.isLoading)

  const dataPoints = queryPoints.map(query => query.data)
  const dataOrdered = orderBy(dataPoints, order, sort)
  return { isLoading, isError, data: dataOrdered as ItemPoints[] }
}

export function useLoyaltyBusiness({ businessId }: { businessId: string }) {
  const sdk = useSDK()
  const userId = sdk?.dataPool.user.userInfo?.sub ?? null
  const apiInstance = sdk.service.loyalty

  const queryBusinessCoins = useQuery({
    queryKey: ['loyalty', 'business', businessId, 'coins', userId],
    queryFn: async () => await fetchCoinsBusiness({ apiInstance, userId, businessId }),
    enabled: !!businessId && !!userId
  })
  return queryBusinessCoins
}

export const useLoyalty = () => {
  const sdk = useSDK()
  const accessToken = useAccessToken()
  const myself = sdk?.dataPool.user.userInfo?.sub ?? null

  const loyaltyCoins = useLoyaltyCoins()
  const loyaltyTransactions = useLoyaltyTransactions()

  const getCoinById = (id: string) => {
    return loyaltyCoins.data?.find(coin => coin.id === id) ?? null
  }

  const transferCoin = async ({
    userId,
    businessId,
    points,
    detail = '',
    source
  }: {
    userId: string
    businessId: string
    points: number
    detail?: string
    source: string
  }) => {
    // TODO: set link to user profile
    const LINK_TEMP = `https://wallet.vatom.com/p/${myself}`

    const payload = {
      userId,
      businessId: businessId,
      points,
      detail,
      source, // app-transfer
      link: LINK_TEMP // link to user profile
    }
    // TODO: change fetch for axios instance
    const response = await fetch(`${loyaltyApi}/u/${myself}/coins/transfer`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(payload)
    })
    const data = await response.json()

    await loyaltyCoins.refetch()
    await loyaltyTransactions.refetch()

    return {
      success: response.status === 200,
      data
    }
  }

  return {
    coins: loyaltyCoins,
    transactions: loyaltyTransactions,
    points: useLoyaltyTransactionPoints,
    getCoinById: getCoinById,
    transferCoin
  }
}

import React, { forwardRef, useEffect, useRef } from 'react'
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming
} from 'react-native-reanimated'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { Coin } from '@vatom/sdk/core'
import { useBusiness } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { PressableOpacity, Text, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Center, HStack, VStack } from 'native-base'

import { HeaderButtonClose, ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useFungibleToken } from '../../hooks/useFungibleTokens'
import { useIsDesktop } from '../../hooks/useIsDesktop'
import { useLoyalty } from '../../hooks/useLoyalty'
import logger from '../../logger'
import { useCoinTheme } from '../CoinDetail/hooks/useCoinTheme'
import { CoinAvatar } from '../CoinDetail/partials'
import { formatAmount } from '../CoinDetail/utils/formatString'
import { CoinNavigatorParamList, CoinStackRoutes } from '../CoinNavigation/CoinNavigation'
import { useCoinStore } from '../CoinNavigation/useCoinStore'

import SwapIcon from './assets/swap.svg'
import { ButtonNext } from './partials/Buttons'
import { NumberPad } from './partials/NumberPad'
import { useNumberPad } from './partials/NumberPad/useNumberPad'
import { TransferAmount, TransferAmountInput } from './partials/TransferAmount'

const coinExchangeAmountMock = '0.00000'

export type CoinTransferProps = {
  navigation: NavigationProp<CoinNavigatorParamList>
}

const isLoyalty = true

const CoinTransfer = observer(
  forwardRef(({ navigation: coinNavigation }: CoinTransferProps, ref) => {
    const navigation = useNavigation()
    const isDesktop = useIsDesktop()

    const coinId = useCoinStore(state => state.coinId)
    const { setAmount: setAmountToTransfer } = useCoinStore()

    const fungibleToken = useFungibleToken(coinId)
    const { getCoinById } = useLoyalty()

    // Get data for coin
    const coin = getCoinById(coinId) || fungibleToken

    // Get Theme for header background from business
    const { data: business } = useBusiness({ business: coin?.businessId })
    const styles = useCoinTheme({ business })

    const inputRef = useRef<any>(null)

    const [swap, setSwap] = React.useState(false)

    const { amount, setAmount, onPressNumber, onPressErase, onLongPressErase } = useNumberPad()

    // const networkImage = business?.logoSrc
    // const networkName = business?.displayName
    const coinImage = coin?.logo
    const coinName = coin?.name ?? ''
    const coinBalance = coin?.points ?? 0

    useEffect(() => {
      // Focus input
      inputRef.current?.focus()
    }, [amount])

    const swapRotation = useSharedValue(0)
    const animatedSwapStyles = useAnimatedStyle(() => {
      return {
        transform: [{ rotateZ: `${swapRotation.value}deg` }]
      }
    })

    const onClose = () => {
      navigation.goBack()
    }

    const swapCurrencies = () => {
      logger.info('swapCurrencies')
      setSwap(!swap)
    }

    const onPressSwap = () => {
      logger.info('onPressSwap')
      swapCurrencies()
      // Animate swap icon
      swapRotation.value = withRepeat(withTiming(180), 1, false, () => {
        swapRotation.value = 0
      })
    }

    const onPressNext = () => {
      logger.info('onPressNext')
      setAmountToTransfer(parseFloat(amount))
      coinNavigation.navigate(CoinStackRoutes.CoinRecipients)
    }

    const isAvailable = () => {
      // No amount, always available
      if (amount === '') return true
      // No balance, not available
      if (coinBalance === 0) return false

      // TODO: check if amount is available depending on the coin
      const simpleAmountAvailability = parseFloat(amount) <= coinBalance
      return simpleAmountAvailability
    }

    const isNextDisabled = () => {
      let isDisabled = false
      if (amount === '' || amount === '0' || !isAvailable()) {
        isDisabled = true
      }
      return isDisabled
    }

    const CoinLogos = (
      <Box
        px={4}
        position={'relative'}
        zIndex={1}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {coinImage && (
          <CoinAvatar
            // Coin
            source={coinImage}
            size={100}
            name={coinName ?? ''}
            containerProps={{
              zIndex: 0
            }}
          />
        )}
        {/* {networkImage && (
              <CoinAvatar
                // Network
                source={networkImage}
                size={32}
                name={networkImage && networkName ? networkName : ''}
                containerProps={{ zIndex: 1, position: 'absolute', bottom: 0, right: 0 }}
              />
            )} */}
      </Box>
    )

    const onSubmitEditing = () => {
      if (!isNextDisabled()) {
        onPressNext()
      }
    }

    const TransferAmountLayout = ({ prefix = '' }: { prefix?: string }) => {
      if (isDesktop) {
        return (
          <TransferAmountInput
            ref={inputRef}
            defaultValue={amount || ''}
            onChangeText={setAmount}
            color={styles.textColor}
            onSubmitEditing={onSubmitEditing}
          />
        )
      }
      return <TransferAmount color={styles.textColor}>{`${prefix} ${amount || 0}`}</TransferAmount>
    }

    const TransferContent = (
      <Center flex={1}>
        {!isLoyalty && (
          // Swap Button
          <PressableOpacity onPress={onPressSwap} mt={5}>
            <Animated.View style={animatedSwapStyles}>
              <Box
                width={8}
                height={8}
                backgroundColor={theme.colors.trueGray[300]}
                borderRadius={999}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <SwapIcon width={16} height={16} fill={styles.textColor} />
              </Box>
            </Animated.View>
          </PressableOpacity>
        )}
        <Box
          flexDirection={(!swap && coinName?.length > 5) || isDesktop ? 'column' : 'row'}
          mx={2}
          minWidth={200}
          maxWidth={'100%'}
          minHeight={16}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {swap ? (
            <TransferAmountLayout prefix={'$'} />
          ) : (
            <>
              <TransferAmountLayout />
              <Text fontSize={32} color={styles.textColor}>
                {coinName?.toUpperCase()}
              </Text>
            </>
          )}
        </Box>

        <Box flexDirection={!swap ? 'column' : 'column-reverse'}>
          {!isLoyalty && (
            <Text fontSize={13} lineHeight={18} fontWeight={400} color={styles.textColor} mt={2}>
              {coinExchangeAmountMock} {coinName?.toUpperCase()}
            </Text>
          )}
          <Text
            fontSize={13}
            lineHeight={18}
            fontWeight={400}
            color={isAvailable() ? styles.textColor : theme.colors.red[500]}
            mt={2}
            tx="coinsScreen.available"
            txOptions={{
              amount: formatAmount(coinBalance)
            }}
          />
        </Box>
      </Center>
    )

    const DesktopLayout = (
      <Box
        ref={ref}
        backgroundColor={styles.background}
        borderRadius={10}
        padding={6}
        shadow={'modal'}
        _web={{
          minHeight: '50vh'
        }}
      >
        <ScreenHeader
          headerLeftType="none"
          headerRight={() => (
            <ScreenHeaderButton onPress={() => navigation.goBack()}>
              <HeaderButtonClose color={styles.textColor} />
            </ScreenHeaderButton>
          )}
        />
        <VStack alignItems={'center'} flex={1}>
          <Box marginTop={4}>{CoinLogos}</Box>
          <Box marginTop={4}>{TransferContent}</Box>
        </VStack>
        <HStack alignSelf={'flex-end'} alignItems={'center'}>
          <ButtonNext
            minWidth={70}
            maxHeight={10} // 40px
            backgroundColor={styles.primary}
            alignSelf={'flex-end'}
            disabled={isNextDisabled()}
            onPress={onPressNext}
          >
            {translate('common.next')}
          </ButtonNext>
        </HStack>
      </Box>
    )

    if (isDesktop) {
      return DesktopLayout
    }

    return (
      <ScreenWrapper
        screenBackgroundColor={styles.background}
        statusBarBackgroundColor={styles.primary}
      >
        <ScreenHeader
          headerLeftPress={onClose}
          headerTextColor={styles.primaryText}
          backdropProps={{
            backgroundColor: styles.primary
          }}
        />

        {CoinLogos}

        {TransferContent}

        <VStack flex={1} justifyContent={'flex-end'}>
          <NumberPad
            color={styles.textColor}
            onPress={onPressNumber}
            onPressErase={onPressErase}
            onLongPressErase={onLongPressErase}
          />
        </VStack>

        <ButtonNext
          m={4}
          backgroundColor={styles.primary}
          disabled={isNextDisabled()}
          onPress={onPressNext}
        >
          {translate('common.next')}
        </ButtonNext>
      </ScreenWrapper>
    )
  })
)

export default CoinTransfer

import React from 'react'
import { Alert, Platform } from 'react-native'
import { ImageLibraryOptions, launchImageLibrary } from 'react-native-image-picker'
import * as FileSystem from 'expo-file-system'
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator'
import { observer } from 'mobx-react-lite'
import { IMSTArray } from 'mobx-state-tree'

import { allSettled } from '../../../helpers'
import { useStoreContext } from '../../MSTContextProvider'
import { Media } from '../stores'

const ModalImagePicker = observer((props: any) => {
  const { imagePicker, setImagePicker } = props
  const store = useStoreContext()

  const photoType = Platform.OS === 'web' ? 'photo' : 'mixed'

  const launchNativeImageLibrary = () => {
    setImagePicker({ show: false, type: '' })
    const options = {
      includeBase64: false,
      selectionLimit: 10,
      mediaType: photoType
    } as ImageLibraryOptions

    launchImageLibrary(options, async response => {
      if (response.didCancel) {
        console.log('User cancelled image picker')
      } else if (response.errorCode) {
        console.log('ImagePicker Error: ', response.errorMessage)
      } else {
        if (!response.assets) {
          return
        }

        const allSettledResult = await allSettled(
          response.assets.map(async item => {
            const { uri, fileSize, fileName, width, height } = item
            const sieInMb = (fileSize ?? 0) / 1000 / 1000

            if (!uri || !width || !height) {
              throw Object.assign(new Error('Something went wrong'), {
                description: `${fileName} can not be uploaded.`
              })
            }

            const manipResult = await manipulateAsync(uri, [{ resize: { width, height } }], {
              format: SaveFormat.JPEG
            })

            if (!manipResult) {
              throw Object.assign(new Error('Something went wrong'), {
                description: `${fileName} can not be uploaded.`
              })
            }

            const base64Data =
              Platform.OS === 'web'
                ? manipResult.base64
                : await FileSystem.readAsStringAsync(manipResult.uri, {
                    encoding: 'base64'
                  })

            if (sieInMb <= 10 && base64Data) {
              return { type: 'image', base64: base64Data, uri, sieInMb, fileName }
            } else {
              throw Object.assign(new Error('File too large'), {
                description: `${fileName} can not be uploaded. Files can not be over 10MB`
              })
            }
          })
        )
        const isFulfilled = <T,>(p: PromiseSettledResult<T>): p is PromiseFulfilledResult<T> =>
          p.status === 'fulfilled'
        const isRejected = <T,>(p: PromiseSettledResult<T>): p is PromiseRejectedResult =>
          p.status === 'rejected'

        const imgDataArray = allSettledResult
          .filter(isFulfilled)
          .map(res => res.value) as IMSTArray<typeof Media>
        // const rejected = allSettledResult.filter(isRejected).map(res => {
        //   const { message, description } = res.reason
        //   return Alert.alert(message, description)
        // })

        let errorMessage = ''
        const rejected = allSettledResult.filter(isRejected).map(res => {
          const { description } = res.reason
          errorMessage += `\n${description}\n`
        })
        if (rejected.length > 0) {
          Alert.alert('Something went wrong', errorMessage)
        }
        store.setMedia(imgDataArray)
      }
    })
  }

  if (imagePicker.show) {
    launchNativeImageLibrary()
  }

  return null
})

export default ModalImagePicker

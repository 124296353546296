import { useCallback } from 'react'
import { useFocusEffect } from '@react-navigation/native'

import { useScrollStore } from './useScrollStore'

/**
 *
 * @param key if used with a key, it will save the scroll position for that key. Otherwise it will scroll to the top of the page.
 *
 */
export const useWindowScrollRestoration = (key?: string) => {
  const onWindowScroll = useCallback(() => {
    if (key && window.scrollY) {
      useScrollStore.getState().setLastKnownPosition(key, window.scrollY)
    }
  }, [key])

  const subscribeOnScroll = useCallback(() => {
    window.addEventListener('scroll', onWindowScroll)
    return () => {
      window.removeEventListener('scroll', onWindowScroll)
    }
  }, [onWindowScroll])

  const handleScroll = useCallback(() => {
    const scrollTarget = key ? useScrollStore.getState().lastKnownPositions[key] : 0
    const timeout = setTimeout(() => {
      window.scrollTo({
        top: key && scrollTarget > 42 ? scrollTarget : 0,
        behavior: 'instant'
      })
    }, 100)

    return () => {
      clearTimeout(timeout)
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      })
    }
  }, [key])

  useFocusEffect(handleScroll)
  useFocusEffect(subscribeOnScroll)
}

import React from 'react'
import { Linking, StyleSheet } from 'react-native'
import Markdown from 'react-native-markdown-display'
import { BusinessTerms } from '@vatom/sdk/core'
import { theme } from '@vatom/wombo'
import { Box, Checkbox, Text, useColorMode } from 'native-base'

export function TermsItem(props: { BusinessTerms: BusinessTerms; checkedErr: boolean }) {
  const { BusinessTerms, checkedErr } = props
  const { name, markdown, url } = BusinessTerms
  // const { terms } = useWebView()

  const { colorMode } = useColorMode()

  const styles = React.useMemo(() => {
    if (colorMode === 'dark') {
      return StyleSheet.create({
        body: {
          maxWidth: '100%',
          color: theme.colors.textDarkMode[100]
        },
        link: {
          color: theme.colors.systemColorsDark['blue']
        }
      })
    }

    return StyleSheet.create({
      body: {
        maxWidth: '100%',
        color: theme.colors.textLightMode[900]
      },
      link: {
        color: theme.colors.systemColorsLight['blue']
      }
    })
  }, [colorMode])

  return (
    <Box
      flexDirection="row"
      alignItems="center"
      // mb={terms?.length !== 1 ? '0' : '3'}
    >
      <Box marginRight={3}>
        <Checkbox accessibilityHint={name} value={name} accessibilityLabel={name} />
      </Box>

      {markdown ? (
        <Box marginRight={2} maxW="90%">
          <Markdown style={styles}>{markdown}</Markdown>
          <Text
            _light={{
              color: theme.colors.systemColorsLight['red']
            }}
            _dark={{
              color: theme.colors.systemColorsDark['red']
            }}
            opacity={checkedErr ? 1 : 0}
            mt="-2"
          >
            This is required
          </Text>
        </Box>
      ) : (
        <Box>
          <Text
            color={theme.colors.black}
            _light={{
              color: theme.colors.textLightMode[900]
            }}
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
            w="80%"
          >
            By clicking this checkbox in I agree to the{' '}
            <Text
              onPress={() => Linking.openURL(url)}
              _light={{
                color: theme.colors.systemColorsLight['blue']
              }}
              _dark={{
                color: theme.colors.systemColorsDark['blue']
              }}
              underline
            >
              legal terms and policies
            </Text>
          </Text>

          <Text
            _light={{
              color: theme.colors.systemColorsLight['red']
            }}
            _dark={{
              color: theme.colors.systemColorsDark['red']
            }}
            opacity={checkedErr ? 1 : 0}
          >
            This is required
          </Text>
        </Box>
      )}
    </Box>
  )
}

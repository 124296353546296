import React, { useRef } from 'react'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { translate } from '@vatom/utils'
import { GradientButton, SafeAreaView, Status, Text, Toast } from '@vatom/wombo'
import { Box, Image } from 'native-base'

import { useBusinessSelector } from '../../hooks/useBusinessSelector'
import { AppRoutes, WalletRoutes } from '../../navigators'
import PrimaryText from '../ActionSheets/partials/PrimaryText'
import SecondaryText from '../ActionSheets/partials/SecondaryText'
import { CoinNavigatorParamList } from '../CoinNavigation/CoinNavigation'
import { useCoinStore } from '../CoinNavigation/useCoinStore'

import TransactionLink from './TransactionLink'

export type CoinTransferProps = {
  navigation: NavigationProp<CoinNavigatorParamList>
}

export const ConfirmationWeb3 = () => {
  const navigation = useNavigation()
  const { web3Confirmation } = useCoinStore.getState()
  const displayToast = useRef(false)

  const { businessIdentifier } = useBusinessSelector()

  const onDone = () => {
    if (businessIdentifier) {
      navigation.navigate(AppRoutes.BusinessProxy, {
        business: businessIdentifier,
        screen: WalletRoutes.Coins
      })
    } else {
      navigation.navigate(AppRoutes.home, {
        screen: WalletRoutes.Coins
      })
    }
  }

  React.useEffect(() => {
    if (displayToast.current === false) {
      Toast({ title: 'Your NFT has been sent', status: Status.success })
      displayToast.current = true
    }
  }, [])

  return (
    <SafeAreaView flex={1} backgroundColor="white">
      <Box flex={1} justifyContent="center" alignItems="center">
        <Image
          accessibilityIgnoresInvertColors={true}
          source={require('./assets/request-sent.png')}
          height="174px"
          width="203px"
          marginBottom="20px"
          alt="request-sent"
        />
        {/* <PrimaryText text="Your request has been sent!" /> */}
        <PrimaryText text={translate('web3.requestSend')} />
        <SecondaryText
          isShare={false}
          text={translate('web3.requestSendDescription')}
          hasLearnMore={true}
        />
        <TransactionLink hash={web3Confirmation?.hash} chain={web3Confirmation?.chain} />
        <GradientButton onPress={onDone} h="38px" w="73px" justifyContent="center">
          <Text alignSelf="center" fontFamily="Inter-Medium" color="white" tx="common.done" />
        </GradientButton>
      </Box>
    </SafeAreaView>
  )
}

export default ConfirmationWeb3

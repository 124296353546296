import { useRef } from 'react'
import { Alert, Platform } from 'react-native'
import Animated from 'react-native-reanimated'
import { useNavigation } from '@react-navigation/native'
import { useDeleteAddressFromIdentities, useIdentities } from '@vatom/sdk/react'
import { LoaderView, Text, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Center, HStack, Pressable, useColorModeValue, VStack } from 'native-base'
import converter from 'number-to-words'

import { deleteKey } from '../../../../../modules/web3-wallet'
import { BlockchainWalletIcon, ImportIcon, PlusIcon } from '../../../components/Icons'
import { Title } from '../../../components/Title'
import { useEncryptAndStoreDerivedAddress } from '../../../hooks/useEncryptAndStore'
import { AppRoutes } from '../../../navigators'
import { useRetrieveWalletBackups } from '../../RestoreFromCloud/screens/list'
import { useSelectedBackup } from '../../RestoreFromCloud/store'
import { groupedWalletsSelector, useWalletStore } from '../WalletStore'

import { Divider } from './Divider'
import { ItemSwipeable } from './ItemSwipeable'
import { RightArrow } from './RightArrow'
import { SubTitleETH } from './SubTitleETH'
import { WalletAddress } from './WalletAddress'

const ActionButton = ({
  title,
  onPress,
  CustomIcon
}: {
  title: string
  onPress: () => void
  CustomIcon?: React.ReactElement
}) => {
  return (
    <Pressable
      accessibilityRole="button"
      onPress={onPress}
      flexGrow={1}
      paddingY={3}
      paddingX={2}
      borderRadius={4}
      _light={{
        backgroundColor: theme.colors.grayCool[200]
      }}
      _dark={{
        backgroundColor: theme.colors.grayDarkMode[700]
      }}
    >
      <HStack alignItems={'center'} justifyContent={'center'}>
        <Center size={4}>{CustomIcon}</Center>
        <Text
          fontSize={13}
          lineHeight={18}
          fontFamily={'Inter-Regular'}
          letterSpacing={0.2}
          _light={{
            color: theme.colors.textLightMode[600]
          }}
          _dark={{
            color: theme.colors.textDarkMode[300]
          }}
          marginLeft={CustomIcon ? 1 : undefined}
        >
          {title}
        </Text>
      </HStack>
    </Pressable>
  )
}

const AddNewWalletButtons = () => {
  const iconColor = useColorModeValue(
    theme.colors.textLightMode[600],
    theme.colors.textDarkMode[300]
  )
  const navigation = useNavigation()
  return (
    <HStack marginY={3} justifyContent={'space-between'}>
      <ActionButton
        title={'Add New Wallet'}
        CustomIcon={<PlusIcon size={4} fill={iconColor} />}
        onPress={() => navigation.navigate(AppRoutes.NewWalletStart)}
      />
      <Box size={2} />
      <ActionButton
        title={'Import Wallet'}
        CustomIcon={<ImportIcon size={4} fill={iconColor} />}
        onPress={() => navigation.navigate(AppRoutes.ImportWalletStart)}
      />
    </HStack>
  )
}

const HeaderAddWallet = ({ name, onPressAdd }: { name: string; onPressAdd?: () => void }) => {
  const iconColor = useColorModeValue(
    theme.colors.textLightMode[600],
    theme.colors.textDarkMode[300]
  )
  return (
    <HStack
      alignItems={'center'}
      style={{
        height: 40
      }}
    >
      <Center size={8}>
        <BlockchainWalletIcon width={17} height={18} fill={iconColor} />
      </Center>
      <Text
        flexGrow={1}
        fontSize={11}
        lineHeight={15}
        fontFamily={'Inter-SemiBold'}
        _light={{
          color: theme.colors.textLightMode[600]
        }}
        _dark={{
          color: theme.colors.textDarkMode[300]
        }}
      >
        {name}
      </Text>
      {onPressAdd ? (
        <Pressable accessibilityRole="button" onPress={onPressAdd} alignItems={'center'}>
          <Text
            fontSize={15}
            lineHeight={20}
            _light={{
              color: theme.colors.systemColorsLight.blue
            }}
            _dark={{
              color: theme.colors.systemColorsDark.blue
            }}
          >
            Add Address
          </Text>
        </Pressable>
      ) : null}
    </HStack>
  )
}

export const SelfCustodialWallets = observer(() => {
  const scrollRef = useRef<Animated.ScrollView | null>(null)
  const navigation = useNavigation()
  const walletBackups = useRetrieveWalletBackups()
  const deleteAddressToIdentitiesMutation = useDeleteAddressFromIdentities()
  const { identities } = useIdentities()
  const groupedWallets = useWalletStore(groupedWalletsSelector)

  const deriveNewAddressMutation = useEncryptAndStoreDerivedAddress()

  const deleteAddress = async (address: string) => {
    // messaging and function needs to change once the delete of identities are done
    Alert.alert(
      'Delete Private Keys',
      'Are you sure you want to delete this keys? We will not be able to retrieve this once you proceed.',
      [
        {
          text: 'Cancel',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel'
        },
        {
          text: 'OK',
          onPress: async () => {
            const addressesFromWallet = useWalletStore
              .getState()
              .wallets.filter(obj => obj.wallet === address)

            addressesFromWallet.forEach(addressFromWallet => {
              const identity = identities.find(
                i => i.value.toLowerCase() === addressFromWallet.address.toLowerCase()
              )
              if (identity) {
                deleteAddressToIdentitiesMutation.mutate({
                  type: identity.type,
                  value: identity.value
                })
              }
            })

            await deleteKey(address)
            useWalletStore.getState().removeWallet(address)
            const walletBackup = walletBackups.data?.find(backup => backup.labl === address)

            if (walletBackup) {
              Alert.alert(
                'Backup Found',
                "We found a cloud backup for this address. Do you also want to delete this? You'll be redirected to another screen to enter the password you use for backing it up.",
                [
                  { text: 'Cancel', style: 'cancel' },
                  {
                    text: 'Proceed',
                    onPress: () => {
                      useSelectedBackup
                        .getState()
                        .setSelectedBackup(walletBackup.acct, walletBackup.labl)
                      navigation.navigate(AppRoutes.DeleteFromCloud)
                    }
                  }
                ]
              )
            }
          }
        }
      ]
    )
  }

  const editAddress = (address: string) => {
    navigation.navigate(AppRoutes.EditAddressName, { address })
  }

  if (!groupedWallets.length && Platform.OS !== 'ios') {
    return null
  }

  if (deriveNewAddressMutation.isLoading) {
    return <LoaderView />
  }

  return (
    <VStack flexGrow={1}>
      <Title preset="h3" variant="bold">
        Self-Custodial Wallets
      </Title>

      {!groupedWallets.length ? (
        <>
          <Text
            fontSize={16}
            lineHeight={22}
            fontFamily={'Inter-SemiBold'}
            letterSpacing={0.4}
            _light={{
              color: theme.colors.textLightMode[600]
            }}
            _dark={{
              color: theme.colors.textDarkMode[300]
            }}
            marginLeft={2}
          >
            No Added Wallets Yet
          </Text>

          <AddNewWalletButtons />
        </>
      ) : (
        <>
          <SubTitleETH marginTop={3} marginBottom={3} />
          {groupedWallets.map((storedAddresses, storedAddressesIndex) => (
            <>
              <HeaderAddWallet
                name={`Wallet ${converter.toWords(storedAddressesIndex + 1)}`}
                onPressAdd={
                  storedAddresses.length < 4
                    ? () => {
                        deriveNewAddressMutation.mutate({
                          address: storedAddresses[0].address,
                          n: storedAddresses.length
                        })
                      }
                    : undefined
                }
              />
              <VStack flexGrow={1} marginY={3} alignItems={'center'}>
                {storedAddresses.map(storedAddress => (
                  <>
                    {/* Wallet Addresses */}
                    <HStack
                      key={`eth-sel-custodial-${storedAddress.address}`}
                      flexGrow={1}
                      marginY={2}
                      alignItems={'center'}
                    >
                      <ItemSwipeable
                        simultaneousHandlers={scrollRef}
                        onDelete={
                          storedAddress.n === 0
                            ? () => deleteAddress(storedAddress.address)
                            : undefined
                        }
                        onEdit={() => editAddress(storedAddress.address)}
                        onPress={() => {
                          useWalletStore.getState().setSelectedAddress(storedAddress.address)
                          navigation.navigate(AppRoutes.WalletAddressManagement, {
                            address: storedAddress.address
                          })
                        }}
                      >
                        <WalletAddress
                          title={storedAddress.name ?? `Address ${storedAddress.n + 1}`}
                          address={storedAddress.address}
                          RightComponent={<RightArrow />}
                        />
                      </ItemSwipeable>
                    </HStack>
                  </>
                ))}
              </VStack>

              <Divider marginX={1} />
            </>
          ))}
          {groupedWallets.length < 3 ? <AddNewWalletButtons /> : null}
        </>
      )}
    </VStack>
  )
})

import React from 'react'
import { ViewStyle } from 'react-native'
import { useAssets } from 'expo-asset'
import { Avatar as NBAvatar, Center, ICenterProps, Text, View } from 'native-base'
import { ColorType } from 'native-base/lib/typescript/components/types'

import theme from '../theme'

interface AvatarProps extends ICenterProps {
  /**
   * Box border color
   */
  borderColor?: ColorType
  /**
   * Box border width
   */
  borderWidth?: string | number
  /**
   * Badge color
   */
  badgeColor?: ColorType
  /**
   * Avatar Source image
   */
  url?: string
  /**
   * Avatar size
   */
  size?: number | string
  /**
   * Name
   */
  name?: string

  /**
   * Name size: sx, sm, md, lg, xl, 2xl
   */
  fontSize?: string

  /**
   * Avatar size
   */
  nbAvatarSize?: number | string
  source?: React.ComponentProps<typeof NBAvatar>['source']

  badgeStyles?: ViewStyle
}

const getFirstTwoLetters = (name: string | undefined, alt?: string): string | undefined => {
  if (name) {
    const nameArray = name.split(' ')
    const firstName = nameArray.shift()
    const first = firstName?.split('')[0]
    const secondName = nameArray.find(name => name !== '')

    if (secondName) {
      const second = secondName.split('')[0]
      return `${first?.toUpperCase()} ${second?.toUpperCase()}`
    }

    return `${first?.toUpperCase()}`
  } else if (alt) {
    return `${alt.slice(0, 3)}`
  }

  return
}

export const Avatar = ({
  source,
  url,
  badgeColor,
  borderColor = 'brand.500',
  borderWidth = 1,
  size = 24,
  nbAvatarSize = '95%',
  fontSize = 'md',
  name,
  badgeStyles
}: AvatarProps) => {
  const [assets /*, error*/] = useAssets([require('./assets/default_avatar.png')])

  // https://github.com/expo/expo/issues/18328
  // const avatarSource: any = url ? { uri: url } : name ? undefined : assets?.[0]
  const ogSource = url ? { uri: url } : source
  const avatarSource: any = ogSource ? ogSource : name ? undefined : assets?.[0]

  return (
    <Center
      borderColor={borderColor}
      borderRadius="full"
      borderWidth={borderWidth}
      h={size}
      w={size}
      // padding="1px"
    >
      {avatarSource ? (
        <NBAvatar
          size={nbAvatarSize}
          source={avatarSource}
          borderWidth={0}
          _light={{
            backgroundColor: theme.colors.grayCool[50]
          }}
          _dark={{
            backgroundColor: theme.colors.grayDarkMode[700]
          }}
        />
      ) : (
        <View>
          <Text fontSize={fontSize}>{getFirstTwoLetters(name)}</Text>
        </View>
      )}
      {badgeColor && (
        <View
          position="absolute"
          right={0}
          bottom={0}
          backgroundColor={badgeColor}
          borderWidth="2px"
          // borderColor="white"
          // margin="1px"
          h="12px"
          w="12px"
          borderRadius="full"
          _light={{
            borderColor: 'white'
          }}
          _dark={{
            borderColor: theme.colors.grayDarkMode[900]
          }}
          style={badgeStyles}
        />
      )}
    </Center>
  )
}

export default Avatar

import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Platform } from 'react-native'
import RNCallKeep from 'react-native-callkeep'
import InCallManager from 'react-native-incall-manager'
import { useNavigation } from '@react-navigation/native'
import { useQuery } from '@tanstack/react-query'
import { PublicProfileSnapshot, Space, SpaceType } from '@vatom/sdk/core'
import { observer } from 'mobx-react-lite'
import * as uuid from 'uuid'
import { create } from 'zustand'

import { useAccessToken, useFirebase, useUser } from '../hooks'
import logger from '../logger'
import { useSDK } from '../store'

import { getUsersCollection } from './firestore'
import SpaceSessionManager, { ConnectionStatus } from './SpaceSessionManager'

export { ConnectionStatus } from './SpaceSessionManager'

const initialSpaceStore = {
  connectionStatus: 0,
  numberUsers: 0,
  space: null,
  activeSession: null
}

const SpaceStore = create<{
  connectionStatus: number
  numberUsers: number
  space: SpaceType | null
  setConnectionStatus: (status: number) => void
  setNumberUsers: (status: number) => void
  setSpace: (space: SpaceType) => void
  removeSpace: () => void
  activeSession: SpaceSessionManager | null
  setActiveSession: (session: SpaceSessionManager) => void
  removeActiveSession: () => void
}>(set => ({
  ...initialSpaceStore,
  setConnectionStatus: (status: number) => set(state => ({ ...state, connectionStatus: status })),
  setNumberUsers: (users: number) => set(state => ({ ...state, numberUsers: users })),
  setSpace: (space: SpaceType) => set(state => ({ ...state, space })),
  removeSpace: () => set(state => ({ ...state, space: null })),
  setActiveSession: (session: SpaceSessionManager) =>
    set(state => ({ ...state, activeSession: session })),
  removeActiveSession: () => set(state => ({ ...state, activeSession: null }))
}))

const initialCallStore = {
  callId: '',
  isMuted: true,
  inSpeaker: false
}

const CallStore = create<{
  callId: string
  isMuted: boolean
  inSpeaker: boolean
  setCallId: (id: string) => void
  removeCallId: () => void
  setIsMuted: (mute: boolean) => void
  setInSpeaker: (speaker: boolean) => void
}>(set => ({
  ...initialCallStore,
  setCallId: (id: string) => set(state => ({ ...state, callId: id })),
  removeCallId: () => set(state => ({ ...state, callId: '' })),
  setIsMuted: (mute: boolean) => set(state => ({ ...state, isMuted: mute })),
  setInSpeaker: (speaker: boolean) => set(state => ({ ...state, inSpeaker: speaker }))
}))

const connectionText = {
  [ConnectionStatus.Connecting]: 'Connecting',
  [ConnectionStatus.Connected]: 'Connected',
  [ConnectionStatus.Error]: 'Connection failed',
  [ConnectionStatus.Ended]: 'Call ended'
}

const options = {
  ios: {
    imageName: 'IconMask',
    appName: 'Vatom',
    supportsVideo: false
  },
  android: {
    alertTitle: 'Permissions required',
    alertDescription: 'This application needs to access your phone accounts',
    cancelButton: 'Cancel',
    okButton: 'ok',
    imageName: 'phone_account_icon',
    // additionalPermissions: [PermissionsAndroid.PERMISSIONS.example],
    additionalPermissions: [],
    // Required to get audio in background when using Android 11
    foregroundService: {
      channelId: 'com.vatom', // TODO: change this
      channelName: 'Space Audio', // TODO: change this
      notificationTitle: 'Vatom is running on background' // TODO: change this
      // notificationIcon: '' // TODO: change this
    }
  }
}
if (Platform.OS !== 'web') {
  RNCallKeep.setup(options)
}
if (Platform.OS === 'android') {
  RNCallKeep.setAvailable(true) // for android
}

const setMuteCall = (muted: boolean) => {
  try {
    const session = SpaceStore.getState().activeSession
    if (session) {
      session.muted = muted
      SpaceStore.getState().setActiveSession(session)
      CallStore.getState().setIsMuted(muted)
    }
  } catch (error) {
    console.log('LOG: > setMuteCall > error:', error)
  }
}
const setSpeakerCall = async (speaker: boolean) => {
  try {
    if (speaker) {
      CallStore.getState().setInSpeaker(true)
      InCallManager.setForceSpeakerphoneOn(true)
    } else {
      InCallManager.setForceSpeakerphoneOn(false)
      CallStore.getState().setInSpeaker(false)
    }
  } catch (error) {
    console.log('LOG: > setSpeakerCall > error:', error)
  }
}

const handleSpeakerChange = async (output: 'receiver' | 'speaker') => {
  if (output !== 'receiver' && output !== 'speaker') {
    return
  }
  const inSpeaker = CallStore.getState().inSpeaker
  if (output === 'receiver' && inSpeaker === false) {
    return
  }
  if (output === 'receiver' && inSpeaker === true) {
    CallStore.getState().setInSpeaker(false)
    return
  }
  if (output === 'speaker' && inSpeaker === true) {
    return
  }
  if (output === 'speaker' && inSpeaker === false) {
    CallStore.getState().setInSpeaker(true)
    return
  }
}

const setEndCall = async () => {
  try {
    const session = SpaceStore.getState().activeSession
    SpaceStore.getState().removeSpace()
    CallStore.setState(initialCallStore)
    if (session === null) {
      return
    }

    await session.end()
    SpaceStore.getState().removeActiveSession()
  } catch (error) {
    console.log('LOG: > setEndCall > error:', error)
    SpaceStore.setState(initialSpaceStore)
  }
}

const useCallKeep = () => {
  const navigation = useNavigation()
  const didLoadWithEvents = useCallback((args?: any) => {
    // console.log('LOG: > didLoadWithEvents:', args)
  }, [])
  const didDisplayIncomingCall = useCallback((args?: any) => {
    // console.log('LOG: > didDisplayIncomingCall:', args)
  }, [])
  const answerCall = useCallback((args?: any) => {
    // console.log('LOG: > answerCall:', args)
  }, [])
  const didPerformDTMFAction = useCallback((args?: any) => {
    // console.log('LOG: > didPerformDTMFAction:', args)
  }, [])
  const didReceiveStartCallAction = useCallback((args?: any) => {
    // console.log('LOG: > didReceiveStartCallAction:', args)
  }, [])
  const didPerformSetMutedCallAction = useCallback(
    (args?: { callUUID: string; muted: boolean }) => {
      const { muted } = args || {}
      if (typeof muted === 'boolean') {
        setMuteCall(muted)
      }
    },
    []
  )
  const didToggleHoldCallAction = useCallback((args?: any) => {
    // console.log('LOG: > didToggleHoldCallAction:', args)
  }, [])
  const endCall = useCallback(
    async (args?: { callUUID: string }) => {
      await setEndCall()
      if (navigation?.canGoBack()) {
        navigation?.goBack()
      }
      if (Platform.OS === 'android') {
        RNCallKeep.backToForeground() // for android
      }
    },
    [navigation]
  )
  const didChangeAudioRoute = useCallback(async (args?: { output?: string; reason?: number }) => {
    console.log('LOG: > didChangeAudioRoute:', args)
    const { output } = args || {}
    // {"output": "Receiver", "reason": 3}
    // {"output": "Speaker", "reason": 4}
    if (output) {
      handleSpeakerChange(output.toLowerCase() as 'receiver' | 'speaker')
    }
  }, [])
  const didActivateAudioSession = useCallback((args?: any) => {
    // console.log('LOG: > didActivateAudioSession:', args)
  }, [])

  useEffect(() => {
    RNCallKeep.addEventListener('didLoadWithEvents', didLoadWithEvents)
    RNCallKeep.addEventListener('didDisplayIncomingCall', didDisplayIncomingCall)
    RNCallKeep.addEventListener('answerCall', answerCall)
    RNCallKeep.addEventListener('didPerformDTMFAction', didPerformDTMFAction) //numpad
    RNCallKeep.addEventListener('didReceiveStartCallAction', didReceiveStartCallAction)
    RNCallKeep.addEventListener('didPerformSetMutedCallAction', didPerformSetMutedCallAction)
    RNCallKeep.addEventListener('didToggleHoldCallAction', didToggleHoldCallAction)
    RNCallKeep.addEventListener('didChangeAudioRoute', didChangeAudioRoute)
    RNCallKeep.addEventListener('endCall', endCall)
    RNCallKeep.addEventListener('didActivateAudioSession', didActivateAudioSession)
    return () => {
      RNCallKeep.removeEventListener('didLoadWithEvents')
      RNCallKeep.removeEventListener('didDisplayIncomingCall')
      RNCallKeep.removeEventListener('answerCall')
      RNCallKeep.removeEventListener('didPerformDTMFAction')
      RNCallKeep.removeEventListener('didReceiveStartCallAction')
      RNCallKeep.removeEventListener('didPerformSetMutedCallAction')
      RNCallKeep.removeEventListener('didToggleHoldCallAction')
      RNCallKeep.removeEventListener('didChangeAudioRoute')
      RNCallKeep.removeEventListener('endCall')
      RNCallKeep.removeEventListener('didActivateAudioSession')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

const getNewUuid = () => uuid.v4().toLowerCase()

const callKeep = Platform.OS !== 'web' ? useCallKeep : null

export const SpaceMonitor = () => {
  callKeep?.()
  const sessionRef = useRef(SpaceStore.getState().activeSession)

  useEffect(() => {
    SpaceStore.subscribe(state => (sessionRef.current = state.activeSession))
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      monitorSession(sessionRef.current)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return null
}

const monitorSession = (session: SpaceSessionManager | null) => {
  if (session === null) {
    return
  }

  session.update()

  if (session.status !== SpaceStore.getState().connectionStatus) {
    SpaceStore.getState().setConnectionStatus(session.status)
  }
  if (session.listUsers().length !== SpaceStore.getState().numberUsers) {
    SpaceStore.getState().setNumberUsers(session.listUsers().length)
  }
}

const initializeCallkeep = (space: SpaceType) => {
  const callId = getNewUuid()
  const contactNumber = space.displayName.toLowerCase()
  const contactDisplay = `@${space.alias}`

  RNCallKeep.startCall(callId, contactNumber, contactDisplay)
  RNCallKeep.setCurrentCallActive(callId)
  CallStore.getState().setCallId(callId)
  RNCallKeep.answerIncomingCall(callId)
}

export const useSpace = () => {
  const user = useUser()
  const sdk = useSDK()
  const accessToken = useAccessToken()
  const firebase = useFirebase()
  const firebaseApp = firebase.data

  const { connectionStatus, space, numberUsers } = SpaceStore()
  const { isMuted, inSpeaker } = CallStore()

  const sessionRef = useRef(SpaceStore.getState().activeSession)

  useEffect(() => {
    const dispose = SpaceStore.subscribe(state => (sessionRef.current = state.activeSession))
    return () => {
      dispose()
    }
  }, [])

  const getHasCurrentSession = useCallback(() => {
    return Boolean(SpaceStore.getState().activeSession !== null)
  }, [])

  const endCall = useCallback(async () => {
    try {
      if (Platform.OS !== 'web') {
        InCallManager.stop()

        const callId = CallStore.getState().callId
        if (callId) {
          RNCallKeep.endCall(callId)
        }
      }
      // End cal and restore states
      if (Platform.OS === 'web') {
        await setEndCall()
      }
    } catch (error) {
      logger.warn(`[Space] Call failed to END: ${(error as Error).message}`)
    }
  }, [])

  const setOnMute = useCallback((muted: boolean) => {
    if (Platform.OS === 'web') {
      setMuteCall(muted)
      return
    }
    const callId = CallStore.getState().callId
    RNCallKeep.setMutedCall(callId, muted)
  }, [])

  const startCall = useCallback(
    async (
      space: SpaceType,
      {
        onSuccess,
        onError
      }: {
        onSuccess?: () => void
        onError?: (e: any) => void
      }
    ) => {
      try {
        if (!space) {
          throw new Error(`Space not provided.`)
        }
        if (!firebaseApp) {
          throw new Error(`Firebase app not initialized.`)
        }
        if (!user) {
          throw new Error(`User not provided.`)
        }
        if (!accessToken) {
          throw new Error(`Access token not found, please try login again.`)
        }
        console.log('LOG: > useSpace > firebaseApp:', firebaseApp)
        logger.info(`[Space] Starting call to space: id=${space.id} user=${user.sub}`)
        // Set Space
        SpaceStore.getState().setSpace(space)

        const session = new SpaceSessionManager(space, user.sub, accessToken, firebaseApp)
        SpaceStore.getState().setActiveSession(session)

        if (Platform.OS !== 'web') {
          InCallManager.start({ media: 'audio', auto: false })
          InCallManager.setKeepScreenOn(false)
          initializeCallkeep(space)
        }
        await session.start()
        SpaceStore.getState().setActiveSession(session)
        // Initialize call muted
        setOnMute(true)

        onSuccess?.()
      } catch (error) {
        onError?.(error)
        SpaceStore.getState().setConnectionStatus(ConnectionStatus.Error)
        logger.warn(`[Space] Call failed to START: ${(error as Error).message}`)
        await endCall()
      }
    },
    [accessToken, endCall, firebaseApp, setOnMute, user]
  )

  const getCallBackgroundColor = useCallback((status: number) => {
    if (status === ConnectionStatus.Connecting) return '#2a4357'
    if (status === ConnectionStatus.Connected) return '#365e31'
    if (status === ConnectionStatus.Error) return '#522522'
    if (status === ConnectionStatus.Ended) return '#3b3b3b'
    // return 'white'
    return 'rgba(0,0,0,0.5)'
  }, [])

  const callBackgroundColor = useMemo(
    () => getCallBackgroundColor(connectionStatus),
    [connectionStatus, getCallBackgroundColor]
  )

  const inCall = useMemo(() => {
    return connectionStatus === ConnectionStatus.Connected
  }, [connectionStatus])

  // TODO: initialize RN Callkit call when is connected?

  const connectionStatusText = useMemo(() => {
    return connectionText[connectionStatus as ConnectionStatus]
  }, [connectionStatus])

  const toggleMute = useCallback(() => {
    if (sessionRef.current === null) return

    try {
      setOnMute(!sessionRef.current.muted)
    } catch (error) {
      console.log('LOG: > toggleMute > error:', error)
    }
  }, [setOnMute])

  const toggleSpeaker = useCallback(async () => {
    if (Platform.OS === 'web') return
    if (sessionRef.current === null) return

    const speaker = CallStore.getState().inSpeaker
    await setSpeakerCall(!speaker)
  }, [])

  // Helpers
  const addSpaceWithAlias = useCallback(
    async (value: string): Promise<SpaceType | null> => {
      if (value.length === 0) alert('Please enter a name')

      let space_alias = value
      // Ensure value starts with an @
      if (!space_alias.startsWith('@')) space_alias = '@' + space_alias

      // Fetch space ID from space name
      const id = space_alias.substring(1)

      try {
        // Attempt to read space ID from SaaS platform
        const space = await sdk.vatomIncApi.getSpace(id)
        sdk.spaces.addUpdate(space)
        return space
      } catch (err) {
        console.log('LOG: > err:', err)
        return null
      }
    },
    [sdk]
  )

  const getSpaces = useCallback(() => {
    return sdk?.spaces?.items?.filter?.(s => !!s) ?? []
  }, [sdk])

  const findSpaceById = useCallback(
    (id: string) => {
      return sdk.spaces.get(id)
    },
    [sdk]
  )

  const removeSpace = useCallback(
    async (spaceId: string) => {
      spaceId && sdk.spaces.remove(spaceId)
    },
    [sdk]
  )

  const reloadSpaces = useCallback(() => {
    sdk.spaces.reloadAll()
  }, [sdk])

  return {
    startCall,
    endCall,
    getCallBackgroundColor,
    callBackgroundColor,
    connectionStatusText,
    connectionStatus,
    numberUsers,
    getSpaces,
    findSpaceById,
    toggleMute,
    toggleSpeaker,
    inCall,
    isMuted,
    inSpeaker,
    space,
    getHasCurrentSession,
    // Extras fns for spaces
    addSpaceWithAlias,
    removeSpace,
    reloadSpaces
  }
}

export const useSpaceAttendees = () => {
  const { space } = useSpace()
  const sdk = useSDK()
  // useSpacePresenceWebsocketSubscription(space?.id)

  //  const enabled = !!firebase.data && isEnabled && getFireBaseIsReady(firebase)

  const fetchPresence = useCallback(async () => {
    if (!space) return
    const now = new Date()
    const presenceDate = new Date(now.setMinutes(now.getMinutes() - 1)).getTime()
    const usersCollection = getUsersCollection(space.id, presenceDate)

    const users = Platform.OS === 'web' ? await usersCollection : await usersCollection.get()

    const attendees: PublicProfileSnapshot[] = []
    if (users) {
      /* @ts-ignore */
      for (const userDoc of users.docs) {
        const userId = userDoc.id.split(':')[1]

        const user = await sdk.vatomIncApi.getPublicProfile(userId)

        if (user) {
          attendees.push(user)
        }
      }
    }
    return attendees
  }, [sdk.vatomIncApi, space])

  return useQuery({
    queryKey: ['space-presence'],
    queryFn: fetchPresence,
    refetchInterval: 5000
  })
}

import React from 'react'
import { ProfileSnapshot, UserSnapshot } from '@vatom/sdk/core'
import { Avatar, Text, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Image, Pressable } from 'native-base'

interface TopProfileUserProps {
  onQrPress: () => void
  user?: UserSnapshot
  profile?: ProfileSnapshot | null
}

export const TopProfileUser = observer(({ onQrPress, user, profile }: TopProfileUserProps) => {
  return (
    <>
      <Image
        alt="photo_bg"
        accessibilityIgnoresInvertColors
        position="absolute"
        source={require('../assets/photo-bg.png')}
        width="100%"
      />
      {/* <Header /> */}
      <Box w="100%" height="200px" alignItems="center" justifyContent="center">
        <Image
          alt="profile_bg"
          accessibilityIgnoresInvertColors
          position="absolute"
          alignItems="center"
          alignSelf="stretch"
          height="100%"
          width="100%"
          source={require('../assets/profile-bg.png')}
        />

        <Avatar size="110px" url={user?.picture} />
        <Text
          fontFamily="Inter-Bold"
          color="black"
          fontSize="17px"
          marginBottom="4px"
          marginTop="7px"
          zIndex={9999}
        >
          {user?.name}
        </Text>
        <Box flexDirection="row" alignItems="center">
          {profile?.preferred_username && (
            <Box marginRight={2}>
              <Text
                color={theme.colors.lightText}
                fontFamily="Inter-Regular"
                fontSize={13}
                lineHeight={18}
              >
                {`@${profile?.preferred_username}`}
              </Text>
            </Box>
          )}
          <Pressable accessibilityRole="button" onPress={() => onQrPress()}>
            <Image
              alt="qr"
              accessibilityIgnoresInvertColors
              width={5}
              height={5}
              resizeMode="contain"
              source={require('../assets/qr.png')}
            />
          </Pressable>
        </Box>
      </Box>
    </>
  )
})

export default TopProfileUser

import React from 'react'
import { TouchableWithoutFeedback, View } from 'react-native'

const ModalDecorationWrapper = ({
  children,
  handler
}: {
  children: React.ReactNode
  handler?: () => void
}) => {
  return handler ? (
    <TouchableWithoutFeedback accessibilityRole="button" onPress={() => handler()}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingVertical: 12
        }}
      >
        {children}
      </View>
    </TouchableWithoutFeedback>
  ) : (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 12
      }}
    >
      {children}
    </View>
  )
}

export default ModalDecorationWrapper

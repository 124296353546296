import { useQuery } from '@tanstack/react-query'
import { useSDK, useUser } from '@vatom/sdk/react'
import { AxiosInstance } from 'axios'

import { useBusinessSelector } from './useBusinessSelector'

type Points = {
  [key: string]: string
  total: string
}

const fetchPoints = async ({
  apiInstance,
  userId,
  campaignId
}: {
  apiInstance: AxiosInstance
  userId: string
  campaignId: string
}) => {
  try {
    const response = await apiInstance.get(`/u/${userId}/campaign/${campaignId}/points`)
    return response.data
  } catch (error) {
    let errorMessage = ''
    if (typeof error === 'string') {
      errorMessage = `fetchPoints: error ${error}`
    } else if (error instanceof Error) {
      errorMessage = `fetchPoints: error ${error.message}`
    }

    throw new Error(errorMessage)
  }
}

const useBusinessPoints = () => {
  const sdk = useSDK()
  const user = useUser()
  const { business } = useBusinessSelector()
  const apiInstance = sdk.service.points

  const query = useQuery<Points>({
    queryKey: ['business-points', business?.id, business?.defaultCampaignId],
    queryFn: async () =>
      await fetchPoints({
        apiInstance,
        userId: user?.sub ?? '',
        campaignId: business?.defaultCampaignId ?? ''
      }),
    enabled: !!(business?.id && business?.defaultCampaignId)
  })

  const getPoints = () => query.refetch

  return {
    points: query.data,
    getPoints
  }
}

export { useBusinessPoints }

import React from 'react'
import { Platform } from 'react-native'
import Animated from 'react-native-reanimated'
import { observer } from 'mobx-react-lite'
import { Box } from 'native-base'

import { useIsDesktop } from '../../hooks/useIsDesktop'
import { useLayoutScrollHandler } from '../../hooks/useLayoutScrollHandler'

import SpacesSection from './partials/SectionSpaces'

export const Spaces = observer(() => {
  const { scrollHandler, scrollEventThrottle, getHeaderHeight, getFooterHeight } =
    useLayoutScrollHandler()
  const isDesktop = useIsDesktop()
  if (Platform.OS === 'web') {
    return (
      <Box paddingTop={!isDesktop ? getHeaderHeight() : 0}>
        <SpacesSection />
      </Box>
    )
  }
  return (
    <Animated.ScrollView
      keyboardShouldPersistTaps="handled"
      onScroll={scrollHandler}
      scrollEventThrottle={scrollEventThrottle}
      contentContainerStyle={{
        paddingTop: getHeaderHeight(),
        paddingBottom: getFooterHeight()
      }}
    >
      <SpacesSection />
    </Animated.ScrollView>
  )
})

export default Spaces

import React from 'react'
import { Ionicons } from '@expo/vector-icons'
import { Text, theme } from '@vatom/wombo'
import { Box, Image } from 'native-base'

type IRoomHeadingProps = {
  coverImage?: string
  displayName: string
  subTitle?: string
  trophy?: number
}

export const RoomHeading = ({ coverImage, displayName, subTitle, trophy }: IRoomHeadingProps) => {
  return (
    <Box pr={'16px'} flexDir="row" justifyContent="space-between" mb={'10px'} alignItems={'center'}>
      <Box flexDir="row" alignItems={'center'}>
        <Image
          accessibilityIgnoresInvertColors={true}
          alt="Business Header"
          h={'32px'}
          w={'32px'}
          mr={'5px'}
          borderRadius={'54px'}
          source={coverImage ? { uri: coverImage } : require('../assets/defaultSpace.jpg')}
        />
        <Box>
          <Text
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
            _light={{
              color: theme.colors.textLightMode[900]
            }}
            fontFamily="Inter"
            fontSize={15}
            fontWeight={700}
            lineHeight={'20px'}
          >
            {displayName}
          </Text>
          {subTitle ? (
            <Text
              _dark={{
                color: theme.colors.textDarkMode[100]
              }}
              _light={{
                color: theme.colors.textLightMode[900]
              }}
              fontFamily="Inter"
              fontSize={11}
              fontWeight={400}
              lineHeight={'15px'}
              letterSpacing={0}
            >
              {subTitle}
            </Text>
          ) : null}
        </Box>
      </Box>
      {trophy ? (
        <Box alignItems={'center'}>
          <Box
            borderColor={theme.colors.grayCool[300]}
            borderRadius={34}
            borderWidth={1}
            w={'64px'}
            flexDir="row"
            h={'24px'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Ionicons name="trophy" size={16} color={theme.colors.brand[500]} />
            <Text
              color="black"
              fontFamily="Inter"
              fontSize={11}
              fontWeight={500}
              lineHeight={'15px'}
              letterSpacing={0}
            >
              {trophy}
            </Text>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

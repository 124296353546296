import { useNavigation } from '@react-navigation/native'
import { PressableOpacity, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { useDebouncedCallback } from 'use-debounce'

import { Filters } from '../../../components/Icons'
import { useBusinessSelector } from '../../../hooks/useBusinessSelector'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { AppRoutes } from '../../../navigators'

export const WalletHeaderRight = observer(() => {
  const { businessIdentifier } = useBusinessSelector()
  const navigation = useNavigation()
  const { isDark } = useBusinessTheme()
  const openFilters = () => {
    navigation.navigate(AppRoutes.nftFilters)
  }

  const openFilterHandler = useDebouncedCallback(openFilters, 50, {
    leading: true,
    trailing: false
  })

  const showFilters = !businessIdentifier

  const FilterComponent = () =>
    showFilters ? (
      <PressableOpacity accessibilityRole="button" ml={1} padding={2} onPress={openFilterHandler}>
        <Filters size={8} fill={isDark ? theme.colors.textDarkMode[200] : theme.colors.darkText} />
      </PressableOpacity>
    ) : null

  return <FilterComponent />
})

import { RelationshipContact } from './types'

export const relationshipsQueryKeys = {
  contacts: [{ scope: 'relationship-contacts' }] as const,
  followers: [{ scope: 'relationship-followers' }] as const,
  userFollowing: [{ scope: 'relationship-user-following' }] as const,
  userFollowers: [{ scope: 'relationship-user-followers' }] as const,
  getContacts: (contacts: RelationshipContact[], vatomToken: string) =>
    [{ ...relationshipsQueryKeys.contacts[0], vatomToken, contacts }] as const,
  getFollowing: (vatomToken: string) =>
    [{ ...relationshipsQueryKeys.followers[0], vatomToken }] as const,
  getUserFollowing: (userId: string, vatomToken: string) =>
    [{ ...relationshipsQueryKeys.userFollowing[0], vatomToken, userId }] as const,
  getUserFollowers: (userId: string, vatomToken: string) =>
    [{ ...relationshipsQueryKeys.userFollowers[0], vatomToken, userId }] as const
}

import { Instance, SnapshotOut, types } from 'mobx-state-tree'

import { ApiAxiosStore } from './api/ApiAxiosStore'

export const ServiceStore = types
  .compose(ApiAxiosStore, types.model('service-placeholder'))
  .named('ServiceStore')

export type ServiceStoreType = Instance<typeof ServiceStore>
export type ServiceStoreSnapshot = SnapshotOut<typeof ServiceStore>

import React from 'react'
import { Platform, StyleSheet } from 'react-native'

import ReactNativeMap, {
  LatLng,
  MapEvent,
  Marker,
  PROVIDER_DEFAULT,
  PROVIDER_GOOGLE,
  Region
} from '../../react-native-maps'

interface IMapView {
  markerLocation: LatLng | undefined
  location: Region
  widthOfMap: number
  setWidthOfMap: (width: number) => void
  setMarkerLocation: (latLng: LatLng) => void
}

const styles = StyleSheet.create({
  container: {
    height: '80%',
    width: '100%'
  },
  map: {
    ...StyleSheet.absoluteFillObject
  }
})

export const MapView = ({
  location,
  markerLocation,
  setWidthOfMap,
  widthOfMap,
  setMarkerLocation
}: IMapView) => {
  const onMapPress = (mapData: MapEvent) => {
    setMarkerLocation({ latitude: mapData.latLng.lat(), longitude: mapData.latLng.lng() })
  }

  return (
    <ReactNativeMap
      provider={Platform.select({
        ios: PROVIDER_DEFAULT,
        web: PROVIDER_GOOGLE,
        android: PROVIDER_GOOGLE
      })}
      onMapReady={() => {
        setWidthOfMap(widthOfMap - 1)
      }}
      cacheEnabled={true}
      style={styles.map}
      region={location}
      zoomEnabled={true}
      zoomControlEnabled={true}
      showsUserLocation={true}
      showsMyLocationButton={true}
      onPress={onMapPress}
    >
      {markerLocation && <Marker coordinate={markerLocation} />}
    </ReactNativeMap>
  )
}
export default MapView

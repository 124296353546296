/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
import React, { useCallback, useMemo, useRef } from 'react'
import { StyleSheet, View } from 'react-native'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import { runOnJS, useSharedValue, withTiming } from 'react-native-reanimated'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { type IEvent, useDmReactions } from '@vatom/sdk/react'
import { theme } from '@vatom/wombo'
import { Image } from 'expo-image'
import { Box, HStack, Pressable, Text } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import { Title } from '../../../../components/Title'
import { getWidthRatio } from '../../hooks/useImageSize'
import { getImageDataFromEvent, getReplyToEventId, getThumbnailUrl } from '../helpers'

import { MessageElementLayout, useMessageContext } from './Message'
import { ReplyPreview } from './ReplyPreview'

const ITEM_BORDER_RADIUS = 12
export const ItemPositionStyles = StyleSheet.create({
  // Item
  item: {
    flexShrink: 1
  },
  // Item Left
  itemLeftContainer: {
    left: 0,
    alignContent: 'flex-start'
  },
  itemLeft: {
    alignSelf: 'flex-start',
    borderTopLeftRadius: ITEM_BORDER_RADIUS,
    borderTopRightRadius: ITEM_BORDER_RADIUS,
    borderBottomLeftRadius: ITEM_BORDER_RADIUS,
    borderBottomRightRadius: ITEM_BORDER_RADIUS
  },
  itemLeft__LAST: {
    borderBottomLeftRadius: 0
  },
  // Item Right
  itemRightContainer: {
    right: 0,
    alignContent: 'flex-end'
  },
  itemRight: {
    alignSelf: 'flex-end',
    borderTopLeftRadius: ITEM_BORDER_RADIUS,
    borderTopRightRadius: ITEM_BORDER_RADIUS,
    borderBottomLeftRadius: ITEM_BORDER_RADIUS,
    borderBottomRightRadius: ITEM_BORDER_RADIUS
  },
  itemRight__LAST: {
    borderBottomRightRadius: 0
  }
})

export const LayoutStyles = {
  left: {
    container: ItemPositionStyles.itemLeftContainer,
    item: {
      ...ItemPositionStyles.item,
      ...ItemPositionStyles.itemLeft
    },
    item__LAST: ItemPositionStyles.itemLeft__LAST,
    item__LIGHT: {
      borderWidth: 1,
      borderColor: theme.colors.grayCool[100],
      backgroundColor: '#fff'
    },
    item__DARK: {
      borderWidth: 1,
      borderColor: theme.colors.grayDarkMode[800],
      backgroundColor: theme.colors.grayDarkMode[900] //'transparent'
    }
  },
  right: {
    container: ItemPositionStyles.itemRightContainer,
    item: {
      ...ItemPositionStyles.item,
      ...ItemPositionStyles.itemRight
    },
    item__LAST: ItemPositionStyles.itemRight__LAST,
    item__LIGHT: {
      borderWidth: 1,
      borderColor: theme.colors.grayCool[100],
      backgroundColor: theme.colors.grayCool[100]
    },
    item__DARK: {
      borderWidth: 1,
      borderColor: theme.colors.grayDarkMode[800],
      backgroundColor: theme.colors.grayDarkMode[800]
    }
  }
}

// #####################
// #  Layout
// #####################

/**
 * @param {boolean} onlyDirection uses layout styles without backgrounds or borders
 */
export const MessageLayout = React.memo(function ({
  children,
  isLast = false,
  onlyDirection = false,
  containerProps,
  style
}: React.PropsWithChildren<{
  isLast?: boolean
  onlyDirection?: boolean
  containerProps?: React.ComponentProps<typeof Box>
  style?: React.ComponentProps<typeof Box>['style']
}>) {
  const { layoutDirection } = useMessageContext()
  const layoutStyle = LayoutStyles[layoutDirection]

  return (
    <Box
      _light={onlyDirection ? {} : layoutStyle.item__LIGHT}
      _dark={onlyDirection ? {} : layoutStyle.item__DARK}
      style={[
        layoutStyle.item,
        isLast ? layoutStyle.item__LAST : {},
        {
          // marginBottom: hasReactions ? 8 : undefined
        },
        style
      ]}
      {...containerProps}
    >
      {children}
    </Box>
  )
})

// #####################
// #  Text
// #####################

export function checkOnlyEmojiAmount(text: string) {
  const regexpOnlyEmoji = /\p{Emoji_Presentation}+$/gu
  if (regexpOnlyEmoji.test(text) === false) {
    return 0
  }
  const emojisRegex =
    /\p{RI}\p{RI}|\p{Emoji}(\p{EMod}|\uFE0F\u20E3?|[\u{E0020}-\u{E007E}]+\u{E007F})?(\u200D(\p{RI}\p{RI}|\p{Emoji}(\p{EMod}|\uFE0F\u20E3?|[\u{E0020}-\u{E007E}]+\u{E007F})?))*/gu

  return text.match(emojisRegex)?.length ?? 0
}

const SingleText = React.memo(function ({
  children,
  ...textProps
}: React.PropsWithChildren<React.ComponentProps<typeof Text>>) {
  // TODO: add read more?
  return <Text {...textProps}>{children}</Text>
})

export const MessageText = React.memo(function ({
  event,
  style,
  ...textProps
}: { event: IEvent } & React.ComponentProps<typeof Text>) {
  // TODO: handle format custom html
  return (
    <SingleText
      style={[
        {
          paddingVertical: 6,
          paddingHorizontal: 20
        },
        style
      ]}
      {...textProps}
    >
      {event.content?.body}
    </SingleText>
  )
})

export const MessageEmoji = React.memo(function ({
  event,
  style,
  ...textProps
}: { event: IEvent } & React.ComponentProps<typeof Text>) {
  return (
    <SingleText
      style={[
        {
          paddingVertical: 0,
          paddingHorizontal: 0
        },
        style
      ]}
      fontSize={48}
      lineHeight={54}
      {...textProps}
    >
      {event.content?.body}
    </SingleText>
  )
})

// #####################
// #  Reactions
// #####################
export const MessageReactions = React.memo(function () {
  const { events, layoutDirection, roomId, onPressReactions } = useMessageContext()

  const eventId = useMemo(() => events?.[0].event_id ?? '', [events])
  const { data: reactions } = useDmReactions({ roomId, eventId })

  const reactionsGrouped = useMemo(() => {
    const grouped: string[] = []
    if (!reactions || reactions?.length === 0) {
      return grouped
    }
    reactions.filter(a => a)
    reactions.forEach(reaction => {
      if (!grouped.includes(reaction.key)) {
        grouped.push(reaction.key)
      }
    })
    return grouped
  }, [reactions])

  const handlePressReaction = useThrottledCallback(
    () => {
      onPressReactions && onPressReactions(eventId)
    },
    300,
    { leading: true }
  )

  if (!reactions || reactionsGrouped?.length === 0) {
    return null
  }

  return (
    <Pressable
      onPress={handlePressReaction}
      accessibilityRole="button"
      _light={{
        backgroundColor: theme.colors.grayCool[100],
        borderColor: theme.colors.grayCool[300]
      }}
      _dark={{
        backgroundColor: theme.colors.grayDarkMode[700],
        borderColor: theme.colors.grayDarkMode[900]
      }}
      borderWidth={0.5}
      position={'absolute'}
      bottom={0}
      left={layoutDirection === 'left' ? undefined : 0}
      right={layoutDirection === 'right' ? undefined : 0}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      style={{
        marginBottom: -16,
        margin: 'auto',
        width: 'auto',
        minWidth: 26, // 28
        height: 26, // 28
        padding: 3, // 4
        borderRadius: 24
      }}
    >
      {reactionsGrouped.map((reaction, index) => (
        <Text
          key={`${reaction}-${index}`}
          style={{
            marginTop: 0,
            marginRight: reactions?.length > 1 ? 4 : 0
          }}
        >
          {reaction}
        </Text>
      ))}
      {reactions && reactions?.length > 1 && (
        <Text
          _light={{
            color: theme.colors.textLightMode[300]
          }}
          _dark={{
            color: theme.colors.textDarkMode[300]
          }}
          fontSize={12}
          marginRight={1}
        >
          {reactions.length}
        </Text>
      )}
    </Pressable>
  )
})

// #####################
// #  Image
// #####################

const IMAGE_SINGLE_WIDTH = 260
const IMAGE_SINGLE_MAX_HEIGHT = 360
const IMAGE_GROUP_WIDTH = 280
const IMAGE_GROUP_GAP = 5

type SingleImageProps = {
  alt?: string
  isLocal: boolean
  uri?: string
  url: string
  width: number
  height: number
  style: React.ComponentProps<typeof Image>['style']
  imageProps?: React.ComponentProps<typeof Image>
}
const SingleImage = React.memo(function ({
  isLocal,
  uri,
  url,
  width,
  height,
  alt,
  style,
  imageProps
}: SingleImageProps) {
  const thumbnailUrl = isLocal
    ? uri
    : getThumbnailUrl(url, {
        width,
        height
      })

  const renderStatusIcon = useMemo(() => {
    if (isLocal) {
      return <MaterialCommunityIcons name="progress-clock" size={12} color={'#fff'} />
    }
    if (!isLocal && url) {
      return <MaterialCommunityIcons name="check" size={12} color={'#fff'} />
    }
    // if (delivered) {
    //   return <MaterialCommunityIcons name="check-all" size={24} color="white" />
    // }
    return null
  }, [isLocal, url])

  return (
    <>
      {/* <Box
        position={'absolute'}
        alignItems={'center'}
        justifyContent={'center'}
        opacity={0.7}
        style={{
          bottom: 8,
          right: 7,
          borderRadius: 50,
          width: 18,
          height: 18,
          backgroundColor: 'rgba(0,0,0,0.5)'
        }}
      >
        {renderStatusIcon}
      </Box> */}
      <Image
        alt={alt}
        cachePolicy={'memory-disk'}
        source={{
          uri: thumbnailUrl,
          width: width,
          height: height
        }}
        style={[
          {
            borderRadius: ITEM_BORDER_RADIUS,
            width: width,
            height: height,
            maxHeight: IMAGE_SINGLE_MAX_HEIGHT
          },
          style
        ]}
        contentFit="cover"
        {...imageProps}
      />
    </>
  )
})

export const MessageImageCaption = React.memo(function () {
  const { events } = useMessageContext()
  const caption = useMemo(() => {
    const captionEvent = events.findLast(event => {
      return 'string' === typeof event.content?.info?.caption && event.content?.info?.caption !== ''
    })
    return captionEvent?.content?.info?.caption
  }, [events])

  if (!caption) {
    return null
  }
  return (
    <SingleText
      style={{
        paddingHorizontal: 8,
        paddingVertical: 8
      }}
    >
      {caption}
    </SingleText>
  )
})

export const MessageImage = React.memo(function ({ index = 0 }: { index?: number }) {
  const { events } = useMessageContext()
  const event = useMemo(() => events[index] ?? events[0], [events, index])

  const image = getImageDataFromEvent(event)
  const imageSize = getWidthRatio(image.width, image.height, IMAGE_SINGLE_WIDTH)
  return (
    <SingleImage
      width={imageSize.width}
      height={imageSize.height}
      url={image.url}
      uri={image.uri}
      isLocal={Boolean(image.isLocal)}
      alt={image.alt}
      style={{
        marginVertical: 2,
        marginHorizontal: 2
      }}
    />
  )
})

const maxPerGroup = 4
const maxRow = 2
const imageMaxWith = IMAGE_GROUP_WIDTH / maxRow - IMAGE_GROUP_GAP

export const MessageImageGroup = React.memo(function () {
  const { events } = useMessageContext()

  const renderImageGroup = useMemo(
    () =>
      events.slice(0, maxPerGroup).map((event, index, self) => {
        const image = getImageDataFromEvent(event)
        const imageSize = getWidthRatio(image.width, image.height, imageMaxWith)
        const isLast = index === self.length - 1
        const isLastRow = self.length - index <= maxRow
        return (
          <SingleImage
            key={`${events[index].unsigned.transaction_id ?? events[index].event_id}`}
            width={imageSize.width}
            height={imageSize.height}
            url={image.url}
            uri={image.uri}
            isLocal={Boolean(image.isLocal)}
            alt={image.alt}
            style={{
              borderRadius: ITEM_BORDER_RADIUS,
              width: imageSize.width,
              height: imageSize.width,
              marginBottom: isLastRow ? 0 : IMAGE_GROUP_GAP
            }}
            imageProps={{
              blurRadius: isLast && events.length > maxPerGroup ? 4 : 0
            }}
          />
        )
      }),
    [events]
  )

  const ViewMoreOverlay = useCallback(
    () => (
      <Box
        position={'absolute'}
        right={0}
        bottom={0}
        zIndex={2}
        width={imageMaxWith}
        height={imageMaxWith}
        alignItems={'center'}
        justifyContent={'center'}
        _light={{
          backgroundColor: 'rgba(0,0,0,.4)'
        }}
        _dark={{
          backgroundColor: 'rgba(255,255,255,.3)'
        }}
        style={{
          borderRadius: ITEM_BORDER_RADIUS
        }}
      >
        <Title
          preset="h3"
          variant="light"
          _light={{
            color: '#fff'
          }}
          _dark={{
            color: '#000'
          }}
        >{`+${events.length - 3}`}</Title>
      </Box>
    ),
    [events]
  )

  return (
    <Box
      maxWidth={IMAGE_GROUP_WIDTH + IMAGE_GROUP_GAP}
      style={{
        paddingVertical: IMAGE_GROUP_GAP,
        paddingHorizontal: IMAGE_GROUP_GAP
      }}
    >
      <Box
        overflow={'hidden'}
        flexWrap={'wrap'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        {events.length > maxPerGroup && <ViewMoreOverlay />}
        {renderImageGroup}
      </Box>
    </Box>
  )
})

export const MessagePressActions = React.memo(function ({
  children,
  index = 0
}: React.PropsWithChildren<{ index?: number }>) {
  const viewRef = useRef<View>()
  const { onPress, onLongPress, events, onPressReply, messageOffsetX } = useMessageContext()
  const currentEvent = events[index] ?? events[0]

  const swipeDirection = useSharedValue(0)

  const handleReply = useCallback(() => {
    onPressReply && onPressReply(currentEvent?.event_id)
  }, [currentEvent?.event_id, onPressReply])

  const handlePress = useCallback(() => {
    onPress && onPress(events, currentEvent?.event_id)
  }, [currentEvent?.event_id, events, onPress])

  const handleLongPress = useCallback(async () => {
    // Measure element
    const elementLayout: MessageElementLayout = await new Promise(resolve => {
      viewRef.current?.measureInWindow((x, y, width, height) => {
        resolve({
          pageX: Math.floor(x),
          pageY: Math.floor(y),
          width: Math.floor(width),
          height: Math.floor(height)
        })
      })
    })

    onLongPress && onLongPress(events, currentEvent?.event_id ?? '', elementLayout)
  }, [currentEvent?.event_id, events, onLongPress])

  const swipeReplyThreshold = 150
  const swipeReply = Gesture.Pan()
    .onUpdate(e => {
      // e.translationX is the distance of the swipe
      // isSwipeRight is true if the swipe is to the right
      const isSwipeRight = e.translationX > 0
      // direction 1 is right, -1 is left
      swipeDirection.value = isSwipeRight ? 1 : -1

      if (isSwipeRight) {
        if (Math.abs(e.translationX) > swipeReplyThreshold) {
          messageOffsetX.value = swipeReplyThreshold
        } else {
          messageOffsetX.value = e.translationX
        }
      }
    })
    .onEnd(e => {
      console.log('LOG: onEnd > e:', swipeDirection.value)
      if (swipeDirection.value === 1) {
        // Swipe right
        if (Math.abs(e.translationX) > 100 || Math.abs(e.velocityX) > 1000) {
          // Trigger reply to message
          runOnJS(handleReply)()
        }
      }
      // reset offset
      messageOffsetX.value = withTiming(0, { duration: 100 })
    })
    .onFinalize(e => {
      // reset direction
      swipeDirection.value = 0
    })

  const longPress = Gesture.LongPress().onStart(e => {
    runOnJS(handleLongPress)()
  })

  const tap = Gesture.Tap()
    .requireExternalGestureToFail(swipeReply)
    .onEnd((e, success) => {
      if (success) {
        runOnJS(handlePress)()
      }
    })

  const pressGestures = Gesture.Exclusive(tap, longPress)
  const gestures = Gesture.Exclusive(swipeReply, pressGestures)

  return (
    <GestureDetector gesture={gestures}>
      <View
        ref={ref => {
          if (!viewRef.current && ref) {
            viewRef.current = ref
          }
        }}
      >
        {children}
      </View>
    </GestureDetector>
  )
})

export const MessageReplyPreview = React.memo(function () {
  const { events, roomId } = useMessageContext()

  const replyEventId = getReplyToEventId(events[0]) ?? ''

  if (!replyEventId) {
    return null
  }

  // TODO: add on press to scroll to message replyEventId
  return (
    <Box padding={1} paddingBottom={0}>
      <ReplyPreview
        roomId={roomId}
        eventId={replyEventId}
        containerProps={{
          overflow: 'hidden',
          borderRadius: 8,
          _light: {
            backgroundColor: 'rgba(0,0,0,.05)'
          },
          _dark: {
            backgroundColor: 'rgba(0,0,0,.1)'
          }
        }}
      />
    </Box>
  )
})

// const MessageReply = React.memo(function () {
//   const { events, roomId, layoutDirection } = useMessageContext()
//   const { data: matrixUser } = useMatrixUser()
//   const userId = matrixUser?.user_id ?? ''

//   const eventId = useMemo(() => events[0].event_id ?? '', [events])
//   const replyEventId = getReplyToEventId(events[0]) ?? ''

//   const { data: event } = useRoomEvent(
//     {
//       roomId,
//       eventId: replyEventId
//     },
//     {
//       enabled: !!eventId && eventId !== ''
//     }
//   )

//   const senderId = useMemo(() => event?.sender ?? '', [event])
//   const { data: userData } = useMatrixUserProfile(senderId, {
//     enabled: senderId !== ''
//   })
//   // const userTitle = userId ?? senderId // change text

//   const iconColor = useColorModeValue(
//     theme.colors.textLightMode[300],
//     theme.colors.textLightMode[300]
//   )
//   const layoutContainerBorderColor = useColorModeValue(
//     theme.colors.grayCool[100],
//     theme.colors.grayDarkMode[800]
//   )

//   if (!event) {
//     return null
//   }

//   return (
//     <Box>
//       <HStack alignItems={'center'} marginBottom={1} justifyContent={'flex-end'}>
//         <FontAwesome5 name="reply" size={12} color={iconColor} />
//         <Text
//           marginLeft={1}
//           fontSize={'10'}
//           lineHeight={'14'}
//           textAlign={'right'}
//           _light={{
//             color: theme.colors.textLightMode[300]
//           }}
//           _dark={{
//             color: theme.colors.textDarkMode[600]
//           }}
//         >
//           You replied to {userData?.displayname}
//         </Text>
//       </HStack>
//       {/* TODO: custom message reply to handle images and other types of messages, like truncate text, audio, etc */}
//       {event && (
//         <MessageBlock
//           layoutDirection={layoutDirection}
//           roomId={roomId}
//           events={[event]}
//           hideTime={true}
//           hideReactions={true}
//           hideReplyPreview={true}
//           layoutContainerStyle={{
//             borderWidth: 1,
//             borderColor: layoutContainerBorderColor,
//             backgroundColor: 'transparent'
//           }}
//           containerStyle={[
//             {
//               opacity: 0.7
//             }
//           ]}
//         />
//       )}
//     </Box>
//   )
// })

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Platform, ScrollView } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { BottomSheetModal, BottomSheetScrollView, BottomSheetView } from '@gorhom/bottom-sheet'
import { useNavigation } from '@react-navigation/native'
import { BusinessFields, BusinessTerms } from '@vatom/sdk/core'
import {
  promptSelector,
  useBusiness,
  useBusinessForms,
  useBusinessTerms,
  useSDK,
  valuesSelector
} from '@vatom/sdk/react'
import { theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Button, Checkbox, Pressable, Text, useColorModeValue } from 'native-base'

import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { AppNavigation, AppRoutes } from '../../navigators'
import { BottomSheetCustomBackdrop } from '../BottomSheetCustomBackdrop'
import { useMaxWidth } from '../WebWrapper'

import Field from './Field'
import { TermsItem } from './Term'
import { TermsAndConditionsProps } from './Types'

const isSafari = Platform.select({
  web: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
  native: false
})

const isWeb = Platform.OS === 'web'

const PlatformBottomSheetContent = isWeb ? ScrollView : BottomSheetScrollView

export const TermsAndConditions = observer((props: TermsAndConditionsProps) => {
  const business = useBusiness({ businessId: props.businessId })
  const businessId = business?.data?.id
  const campaignId = business?.data?.defaultCampaignId ?? undefined

  const businessTerms = useBusinessTerms(businessId, promptSelector)
  const businessFields = useBusinessForms(businessId, campaignId, valuesSelector)

  const fieldsRequired = businessFields.data?.fields?.filter(f => f.required)

  const isLoading = businessTerms.isLoading || businessFields.isLoading

  const isOpen = !isLoading && (businessTerms.data?.length > 0 || fieldsRequired.length > 0)

  if (!business?.data) return null

  const refetchAll = () => {
    businessTerms.refetch()
    businessFields.refetch()
  }

  return isOpen ? (
    <BusinessTermsAndConditions
      initialValues={businessFields.data.values}
      businessFields={businessFields.data.fields}
      businessTerms={businessTerms.data}
      businessId={businessId}
      campaignId={campaignId}
      refetchAll={refetchAll}
    />
  ) : null
})

export type FieldsValidationsErrors = {
  [key: BusinessFields['name']]: {
    message: string
  }
}

type TFieldsValues = Record<string, BusinessFields['value']>

type TermsAndConditionsActionSheetProps = {
  initialValues: TFieldsValues
  businessFields: BusinessFields[]
  businessTerms: BusinessTerms[]
  businessId?: string
  campaignId?: string
  refetchAll: () => void
}

const BusinessTermsAndConditions = (props: TermsAndConditionsActionSheetProps) => {
  const { initialValues, businessId, businessFields, businessTerms, campaignId, refetchAll } = props
  const sdk = useSDK()
  const insets = useSafeAreaInsets()

  const { brandTheme } = useBusinessTheme()

  const [fieldsErrors, setFieldsErrors] = useState<FieldsValidationsErrors>({})
  const [termsError, setTermsError] = useState<string[]>([])

  const [termsDecisions, setTermsDecisions] = useState<string[]>([])
  const [fieldValues, setFieldValues] = useState<TFieldsValues>(initialValues)
  const [submitting, setSubmitting] = useState(false)
  const navigation = useNavigation<AppNavigation>()

  const bottomSheetRef = useRef<BottomSheetModal>(null)

  const onChangeField = (name: string, value: string) =>
    setFieldValues(v => ({ ...v, [name]: value }))

  const cancel = useCallback(() => {
    navigation.navigate(AppRoutes.home)
  }, [navigation])

  const decideTerms = useCallback(async () => {
    try {
      setSubmitting(true)
      setFieldsErrors({})

      const missingRequiredTerms = businessTerms.reduce<string[]>((acc, term) => {
        if (term.required && !termsDecisions.includes(term.name)) {
          acc.push(term.name)
          return acc
        }
        return acc
      }, [])
      setTermsError(missingRequiredTerms)

      const formRes = await sdk.vatomIncApi.decideBusinessForms(fieldValues, campaignId, businessId)
      if (formRes?.status === 400) {
        if (formRes.data.errors) {
          setFieldsErrors(formRes.data.errors)
          return
        }
      } else {
        setFieldsErrors({})
      }

      if (missingRequiredTerms.length === 0) {
        for (const term of businessTerms) {
          const t = termsDecisions.find(t => t === term.name)
          await sdk.vatomIncApi.decideBusinessTerms(term.name, !!t ?? false)
        }
      }

      refetchAll()
      setSubmitting(false)
    } catch (error) {
      setFieldsErrors({
        [businessFields[0].name]: {
          message: 'Unknown server error'
        }
      })
    } finally {
      setSubmitting(false)
    }
  }, [
    businessFields,
    businessId,
    businessTerms,
    campaignId,
    fieldValues,
    refetchAll,
    sdk.vatomIncApi,
    termsDecisions
  ])

  const maxWidth = useMaxWidth()
  const snapPoints = useMemo(() => (isWeb ? ['75%', '100%'] : ['75%', '90%']), [])

  useEffect(() => {
    bottomSheetRef.current?.present()
  }, [])

  const bottomSheetStyles = useColorModeValue(
    {
      container: {
        backgroundColor: theme.colors.white
      },
      indicator: {
        backgroundColor: theme.colors.grayCool[400]
      }
    },
    {
      container: {
        backgroundColor: theme.colors.grayDarkMode[900]
      },
      indicator: {
        backgroundColor: theme.colors.grayDarkMode[100]
      }
    }
  )

  return (
    <BottomSheetModal
      ref={bottomSheetRef}
      index={isSafari ? 1 : 0}
      snapPoints={snapPoints}
      enableDynamicSizing={true}
      enableOverDrag={false}
      enableContentPanningGesture={false}
      keyboardBehavior="interactive"
      keyboardBlurBehavior="none"
      backdropComponent={props => (
        <BottomSheetCustomBackdrop {...props} style={{ zIndex: 99 }} onPress={cancel} />
      )}
      onDismiss={cancel}
      handleIndicatorStyle={{
        backgroundColor: bottomSheetStyles.indicator.backgroundColor
      }}
      handleStyle={{
        marginTop: 12,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        backgroundColor: bottomSheetStyles.container.backgroundColor
      }}
      backgroundStyle={{
        backgroundColor: bottomSheetStyles.container.backgroundColor
      }}
      containerStyle={{
        zIndex: 999,
        backgroundColor: 'transparent'
      }}
      style={{
        maxWidth: isWeb ? maxWidth : undefined,
        backgroundColor: 'transparent',
        marginHorizontal: isWeb ? 'auto' : 0,
        marginBottom: isWeb ? 0 : insets.bottom
      }}
    >
      <BottomSheetView>
        <PlatformBottomSheetContent
          style={{ flexGrow: 1, paddingHorizontal: 16 }}
          contentContainerStyle={{
            paddingBottom: 16
          }}
        >
          <Text
            w="100%"
            fontFamily="Inter-SemiBold"
            fontSize={25}
            fontWeight="600"
            textAlign="left"
            mb={17}
            _light={{
              color: brandTheme?.text ?? theme.colors.textLightMode[900]
            }}
            _dark={{
              color: brandTheme?.text ?? theme.colors.textDarkMode[100]
            }}
          >
            Complete your profile
          </Text>
          <Text
            w="100%"
            fontFamily="Inter-Regular"
            fontSize={16}
            textAlign="left"
            _light={{
              color: theme.colors.textLightMode[600]
            }}
            _dark={{
              color: theme.colors.textDarkMode[300]
            }}
          >
            You are almost done! To secure your account please provide additional information before
            continuing.
          </Text>
          <Box>
            {businessFields?.map((field, key) => {
              return (
                <Field
                  key={key}
                  field={field}
                  error={fieldsErrors[field.name]}
                  value={fieldValues[field.name] || ''}
                  onChange={onChangeField}
                />
              )
            })}
          </Box>
          <Checkbox.Group
            mt={5}
            accessibilityLabel="accept terms"
            onChange={setTermsDecisions}
            accessibilityHint="accept terms"
          >
            {businessTerms?.map((terms, key) => (
              <TermsItem
                key={key}
                BusinessTerms={terms}
                checkedErr={termsError.includes(terms.name)}
              />
            ))}
          </Checkbox.Group>

          <Box
            flexDirection="row"
            marginY={2}
            style={{
              backgroundColor: bottomSheetStyles.container.backgroundColor
            }}
          >
            <Pressable
              accessibilityRole="button"
              onPress={() => cancel()}
              justifyContent="center"
              flex={1}
              borderWidth={1}
              rounded="sm"
              mr={1}
              _light={{
                borderColor: theme.colors.textLightMode[300]
              }}
              _dark={{
                borderColor: theme.colors.textDarkMode[600]
              }}
            >
              <Text fontFamily="Inter-Regular" alignSelf="center" py={2} fontSize="18px">
                Cancel
              </Text>
            </Pressable>
            <Button
              accessibilityRole="button"
              flex={1}
              py={4}
              rounded="sm"
              onPress={decideTerms}
              justifyContent="center"
              disabled={submitting}
              opacity={submitting ? 0.5 : 1}
              backgroundColor={brandTheme?.primary ?? theme.colors.brand[500]}
            >
              <Text
                w="100%"
                textAlign="center"
                fontFamily="Inter-Regular"
                alignSelf="center"
                fontSize="18px"
                color="white"
                _light={{
                  color: brandTheme?.primaryText ?? 'white'
                }}
                _dark={{
                  color: brandTheme?.primaryText ?? theme.colors.textDarkMode[100]
                }}
              >
                Accept
              </Text>
            </Button>
          </Box>
        </PlatformBottomSheetContent>
      </BottomSheetView>
    </BottomSheetModal>
  )
}

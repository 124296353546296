import React from 'react'
import { Platform, Text, TouchableWithoutFeedback, View } from 'react-native'
import DateTimePicker from '@react-native-community/datetimepicker'
import moment from 'moment'

import { useCommunitiesTheme } from '../../../../themes'

export const DateTimePickerComponent = (props: any) => {
  const { value, onChange, offset } = props
  const communitiesTheme = useCommunitiesTheme()

  return (
    <DateTimePicker
      testID="dateTimePickerr"
      value={value}
      mode={'time'}
      display="spinner"
      onChange={(event: any, date: Date | undefined) => onChange(date)}
      is24Hour={true}
      timeZoneOffsetInMinutes={offset ?? null}
      textColor={communitiesTheme.sketch.color}
    />
  )
}

export const RNDateTimePickerComponent = (props: any) => {
  const { value, onChange, offset } = props
  const communitiesTheme = useCommunitiesTheme()
  const displayOptions = Platform.OS === 'ios' ? 'inline' : 'default'

  return (
    <DateTimePicker
      themeVariant={communitiesTheme.name === 'dark' ? 'dark' : 'light'}
      testID="dateTimePicker"
      value={value}
      mode={'date'}
      display={displayOptions}
      onChange={(event: any, date: Date | undefined) => onChange(date)}
      minimumDate={new Date()}
      timeZoneOffsetInMinutes={offset ?? null}
    />
  )
}

export const ModalRow = (props: any) => {
  const { children, style, onClick } = props
  const communitiesTheme = useCommunitiesTheme()

  const content = (
    <View
      style={{
        paddingHorizontal: 16,
        borderBottomColor: communitiesTheme.threadRootInfo.border,
        borderBottomWidth: 1,
        paddingVertical: 8,
        ...style
      }}
    >
      {children}
    </View>
  )

  return onClick ? (
    <TouchableWithoutFeedback accessibilityRole="button" onPress={onClick}>
      {content}
    </TouchableWithoutFeedback>
  ) : (
    content
  )
}

export const ModalRowText = (props: any) => {
  const { text } = props
  const communitiesTheme = useCommunitiesTheme()

  return (
    <Text style={{ fontSize: 15, lineHeight: 20, color: communitiesTheme.labelTitleColor }}>
      {text}
    </Text>
  )
}

export const SelectedValueText = (props: any) => {
  const { value } = props
  const communitiesTheme = useCommunitiesTheme()

  return (
    <Text
      style={{
        fontSize: 15,
        lineHeight: 20,
        color: communitiesTheme.labelIconColor
      }}
    >
      {value}
    </Text>
  )
}

export const getDisplayDate = (value: number, timeZoneOffset: number, currentOffset: number) => {
  return value
    ? moment(new Date(value + (timeZoneOffset - currentOffset) * 60 * 1000)).format(
        'MMM DD, YYYY - h:mmA'
      )
    : null
}

import { useCallback, useState } from 'react'
import { Keyboard } from 'react-native'
import {
  Contact,
  ContactSources,
  Filters,
  useGetContacts,
  useLocationFromIp,
  UserData,
  validateDestination
} from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { PressableOpacity, Text } from '@vatom/wombo'
import { Box } from 'native-base'
import { useDebounce } from 'use-debounce'

import { CameraScannerModalUser } from '../../../components/CameraScannerModal'
import { useIsDesktop } from '../../../hooks/useIsDesktop'

import RecentList from './RecentList'
import ResultsList from './ResultsList'
import Search from './Search'

export type ChoseRecipientsProps = {
  handleOnPress: (item: Contact) => void
  searchMode: boolean
  setSearchMode: (b: boolean) => void
}

export const ChoseRecipients = ({
  setSearchMode,
  searchMode,
  handleOnPress
}: ChoseRecipientsProps) => {
  const locationFromIp = useLocationFromIp()
  const [searchString, setSearchString] = useState('')
  const [isScannerOpen, setIsScannerOpen] = useState(false)
  const [lastSearchString] = useDebounce(searchString, 150)
  const isDesktop = useIsDesktop()

  const destination = validateDestination(
    lastSearchString,
    true,
    locationFromIp?.data?.country_code
  )
  const filters: Filters = {
    tokenType: undefined,
    sources: [
      ContactSources.userdirectory,
      ContactSources.tokenhistory,
      ContactSources.devicecontacts
    ]
  }
  const contacts = useGetContacts(destination, filters)

  const onDismiss = () => {
    setSearchMode(false)
    setSearchString('')
    Keyboard.dismiss()
  }

  const searchOnFocus = () => {
    !searchMode && setSearchMode(true)
  }

  const searchOnBlur = () => {
    searchMode && setSearchMode(false)
  }

  const onProcessLink = useCallback(
    (user: UserData) => {
      if (!user) return
      setIsScannerOpen(false)
      setTimeout(() => {
        const contact = user as unknown as Contact
        handleOnPress(contact)
      }, 400)
    },
    [handleOnPress]
  )

  return (
    <>
      {!searchMode && <RecentList handleOnPress={handleOnPress} />}
      <Box flexDirection={'row'} justifyItems={'center'} mt={searchMode ? 0 : 4}>
        <Box flex={1}>
          <Search
            setIScannerOpen={setIsScannerOpen}
            onFocus={searchOnFocus}
            onBlur={searchOnBlur}
            searchMode={searchMode}
            setSearchString={setSearchString}
            searchString={searchString}
            searchPlaceholder={translate('common.chooseRecipientPlaceholder')}
          />
        </Box>
        {!isDesktop && searchMode && (
          <PressableOpacity onPress={onDismiss} justifyContent={'center'} px={2} ml={2}>
            <Text
              fontFamily={'Inter-Regular'}
              fontSize={15}
              lineHeight={20}
              fontWeight={400}
              tx="common.cancel"
            />
          </PressableOpacity>
        )}
      </Box>
      <CameraScannerModalUser
        isOpen={isScannerOpen}
        onDismiss={() => setIsScannerOpen(false)}
        onSuccess={onProcessLink}
      />
      <ResultsList
        data={contacts.data}
        isLoading={contacts.isLoading}
        searchString={searchString}
        onPress={handleOnPress}
        lastSearchString={lastSearchString}
        searchMode={searchMode}
      />
    </>
  )
}

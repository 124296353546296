import { TouchableOpacity } from 'react-native-gesture-handler'
import { FontAwesome5 } from '@expo/vector-icons'
import { translate } from '@vatom/utils'
import { Status, theme, Toast } from '@vatom/wombo'
import * as Clipboard from 'expo-clipboard'
import { Box, HStack, Icon, Text, VStack } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import { useBusinessTheme } from '../../../hooks/useBusinessTheme'

import { Address } from './Address'

export const WalletAddress = ({
  title,
  address,
  picture,
  RightComponent
}: {
  title: string
  address: string
  picture?: string
  RightComponent?: React.ReactElement
}) => {
  const { pageTheme } = useBusinessTheme()
  const copyToClipboard = useThrottledCallback(
    async (copiedText: string) => {
      await Clipboard.setStringAsync(copiedText)

      Toast({
        title: translate('common.copiedToClipboard', { text: copiedText }),
        placement: 'top',
        status: Status.success
      })
    },
    300,
    { leading: true }
  )

  return (
    <HStack
      flexGrow={1}
      alignItems={'center'}
      style={{
        height: 64
      }}
      backgroundColor={pageTheme.background}
    >
      {/* TODO: use picture */}
      {picture && <Box size={8} backgroundColor={'#f99'} marginRight={2} />}
      <VStack flexGrow={1}>
        <Text
          fontFamily={'Inter-SemiBold'}
          fontSize={15}
          lineHeight={20}
          _light={{
            color: theme.colors.textLightMode[600]
          }}
          _dark={{
            color: theme.colors.textDarkMode[200]
          }}
        >
          {title}
        </Text>

        <HStack maxWidth={'100%'}>
          <Address address={address} />
          <TouchableOpacity accessibilityRole="button" onPress={() => copyToClipboard(address)}>
            <Box marginLeft={3} justifyContent={'center'}>
              <Icon
                as={FontAwesome5}
                size="15px"
                _light={{
                  color: theme.colors.textLightMode[300]
                }}
                _dark={{
                  color: theme.colors.textDarkMode[600]
                }}
                name="copy"
              />
            </Box>
          </TouchableOpacity>
        </HStack>
      </VStack>

      {RightComponent}
    </HStack>
  )
}

import { useMutation } from '@tanstack/react-query'
import { BVatomPluginType } from '@vatom/BVatom/plugin'
import { RegionType } from '@vatom/sdk/core'
import { useSDK } from '@vatom/sdk/react'

import Alert from '../Alert'

export const useRedeemMutation = () => {
  const sdk = useSDK()
  return useMutation({
    mutationFn: async (payload: { vatomId: string; requestorId: string }) => {
      const plugin = sdk.dataPool.getPlugin('BVatomPlugin') as BVatomPluginType
      const region = plugin.region(RegionType.inventory)
      const vatomToken = region.tokens.find(tk => tk.id === payload.vatomId)

      if (!vatomToken) {
        throw new Error('Vatom not found')
      }

      try {
        const res = await vatomToken.performAction('Redeem', {
          'new.owner.id': payload.requestorId
        })

        return res
      } catch (error: any) {
        Alert.showError(error)
        throw new Error(error)
      }
    }
  })
}

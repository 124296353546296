import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import RNMapView, { Region } from 'react-native-maps'
import { useIsFocused } from '@react-navigation/native'
import { BVatomTokenType } from '@vatom/BVatom/plugin'
import { RegionType, TokenType } from '@vatom/sdk/core'
import {
  MapBoundingBox,
  useConfig,
  useGeoDiscoveryGroups,
  useLocationPermission,
  useSDK
} from '@vatom/sdk/react'
import * as Location from 'expo-location'
import { LocationAccuracy } from 'expo-location'
import { CountryCode } from 'libphonenumber-js'
import { useThrottledCallback } from 'use-debounce'

import { useModalQuery } from '../../../components/PromptModal/PromptModal'
import { useBusinessSelector } from '../../../hooks/useBusinessSelector'
import { getBoundingBoxFromRegion } from '../../../utils/map'

import {
  createCustomMarkers,
  createGroupMarkers,
  createSingleMarkers,
  createUserLocationMarkers,
  createVatomMarkers
} from './createMarkers'
import { useMapActions } from './useMapActions'

const locationOptions: Location.LocationOptions = {
  accuracy: LocationAccuracy.Balanced,
  distanceInterval: 5
}

// Map zoom 0-20
const PRECISION_MIN = 2
// const PRECISION_MAX = 14
const PRECISION_ZOOM_SUBTRACT = 6
const PRECISION_DEFAULT = 7

function getPrecision(latitudeDelta: number) {
  const zoomLevel = Math.round(Math.log(360 / latitudeDelta) / Math.LN2)
  // The object here is the zoomLeveL: precision. Some zoomLevel needed specific precision in order to look good in the map
  const zoomPrecision = { 9: 5, 8: 4, 7: 4, 6: 3, 5: 3 } as Record<number, number>
  const zoomPrecisionMagic = Number(zoomLevel - PRECISION_ZOOM_SUBTRACT)
  const precision = Math.max(zoomPrecision[zoomLevel] ?? zoomPrecisionMagic, PRECISION_MIN)
  return precision
}

export const fetchLocationFromIp = async () => {
  const response = await fetch(
    'https://api.ipstack.com/check?access_key=f6c1392b737ae773c32e839ccd03e87f&fields=country_code,latitude,longitude'
  )
  return (await response.json()) as {
    latitude: number
    longitude: number
    country_code: CountryCode
  }
}

export const useMaps = ({
  mapRef
}: {
  mapRef?: MutableRefObject<RNMapView> | MutableRefObject<google.maps.Map>
} = {}) => {
  const sdk = useSDK()
  const pickingRef = useRef(false)
  const [lastPicked, setLastPicked] = useState<string>()
  const removeLastPickedTimeout = useRef<NodeJS.Timeout>()
  const dimensions = useWindowDimensions()
  const focused = useIsFocused()
  const [selectedToken, setSelectedToken] = useState<TokenType>()
  const lastRegion = useRef<Region>()
  const config = useConfig()
  const { business } = useBusinessSelector()
  const [mapBoundingBox, setMapBoundingBox] = useState<MapBoundingBox | undefined>()
  const [precision, setPrecision] = useState(PRECISION_DEFAULT)
  const [openModal, setOpenModal] = useState(false)
  const [openPostModal, setOpenPostModal] = useState(false)
  const [openLocPermModal, setOpenLocPermModal] = useState(true)

  // const { location } = useCurrentLocation({
  //   locationOptions: locationOptions
  // })

  const {
    permission
    // isLoading: permissionIsLoading,
    // isFetching: permissionIsFetching
  } = useLocationPermission()

  // const requestedEnableLocation = useRef(false)

  const fetchFallBackLocation = async (): Promise<Location.LocationObject> => {
    const { latitude, longitude } = await fetchLocationFromIp()

    return {
      coords: {
        latitude,
        longitude,
        accuracy: null,
        altitude: null,
        altitudeAccuracy: null,
        speed: null,
        heading: null
      },
      mocked: false,
      timestamp: Date.now()
    }
  }

  const fetchLocationWithoutPermission = (permissionStatus?: string) => {
    if (openLocPermModal && Platform.OS !== 'web') {
      setOpenLocPermModal(false)
      throw new Error('Should show prompt error')
    } else if ((permissionStatus !== undefined && Platform.OS !== 'web') || Platform.OS === 'web') {
      return fetchFallBackLocation()
    }
  }

  const defaultTimeout = 2000

  const fetchLocation = async (locationOptions?: Location.LocationOptions, timeout?: number) => {
    console.log('fetchLocation')

    const timeOutPromise = new Promise<Location.LocationObject>((_, reject) => {
      setTimeout(() => {
        reject(new Error('Location request timed out'))
      }, timeout ?? defaultTimeout)
    })
    const locationPromise = Location.getCurrentPositionAsync(locationOptions)
    const lastKnownLocationPromise = Location.getLastKnownPositionAsync()
    try {
      const result = await Promise.race([timeOutPromise, locationPromise])
      return result
    } catch (error) {
      console.error('Error: fetchlocation, using last known position', error)
      const result = await lastKnownLocationPromise
      if (result) {
        return result
      } else {
        throw error
      }
    }
  }

  const mapLocation = useModalQuery({
    modalQueryKey: ['map-location', permission?.status, locationOptions] as const,
    modalQueryFn: async ({ queryKey }) => {
      const [, permissionStatus, _locationOptions] = queryKey
      const locationOptions = _locationOptions as Location.LocationOptions

      if (permissionStatus === 'granted') {
        return fetchLocation(locationOptions)
      }

      if (permissionStatus === 'undetermined' && permission?.canAskAgain) {
        const newStatus = await Location.requestForegroundPermissionsAsync()

        if (newStatus.status === 'granted') {
          return fetchLocation(locationOptions)
        }
      }

      return fetchLocationWithoutPermission(permissionStatus as string)
    },
    onSuccessFn: data => {
      // // do something else needed
      // if (data?.some) {
      //   // setSomething()
      // }
    },
    queryOptions: {}, // custom query options
    promptError: {
      title: 'Location Permission',
      message:
        'For a better experience, Please enable location permission to see the nearby tokens',
      onCloseError: () => {
        fetchFallBackLocation()
        setOpenLocPermModal(false)
      }
    }
    //  LoadingComponent: () => (
    //    <Box>
    //      <Text>Loading...</Text>
    //    </Box>
    //  ) // Not required
  })

  const location = mapLocation.data as Location.LocationObject

  const { handleCenter, onGroupMarkerPress, onCenterChanged } = useMapActions({
    location,
    selectedToken,
    focused,
    setMapBoundingBox,
    setPrecision,
    //@ts-ignore
    mapRef
  })

  const onSelectToken = useThrottledCallback((token: TokenType) => {
    setOpenModal(true)
    setSelectedToken(token)
  }, 300)

  const onCloseModal = useThrottledCallback(() => {
    setOpenModal(false)
    setSelectedToken(undefined)
  }, 300)

  const onPostModal = useThrottledCallback(() => {
    setOpenModal(false)
    setOpenPostModal(true)
  }, 300)

  const onClosePostModal = useThrottledCallback(() => {
    setOpenPostModal(false)
    setSelectedToken(undefined)
    onPickUp()
  }, 300)

  const onPickUp = useThrottledCallback(() => {
    setLastPicked(selectedToken?.id)
    removeLastPickedTimeout.current = setTimeout(() => {
      setLastPicked(undefined)
    }, 5000)

    onCloseModal()
  }, 300)

  const onRegionChange = useThrottledCallback(
    (mapRegion: Region) => {
      if (pickingRef.current) return
      const boundingBox = mapRegion ? getBoundingBoxFromRegion(mapRegion) : null
      if (boundingBox && !selectedToken && focused) {
        setMapBoundingBox(boundingBox)
        lastRegion.current = mapRegion
        sdk.dataPool.region(RegionType.geopos, JSON.stringify(boundingBox))
        const precision = getPrecision(mapRegion.latitudeDelta) ?? PRECISION_DEFAULT
        setPrecision(precision)
      }
    },
    1000,
    { trailing: true, leading: false }
  )

  useEffect(() => {
    return () => {
      if (removeLastPickedTimeout.current) {
        clearTimeout(removeLastPickedTimeout.current)
      }
    }
  }, [])

  const initialRegion = useMemo(() => {
    if (!location?.coords) return
    const ASPECT_RATIO = dimensions.width / dimensions.height
    const LATITUDE_DELTA = 0.015
    const LONGITUDE_DELTA = LATITUDE_DELTA * ASPECT_RATIO

    return {
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
      latitudeDelta: LATITUDE_DELTA,
      longitudeDelta: LONGITUDE_DELTA
    }
  }, [dimensions.height, dimensions.width, location])

  const initialCenter = useMemo(() => {
    if (!location?.coords) return

    return {
      latitude: location.coords.latitude,
      longitude: location.coords.longitude
    }
  }, [location])

  const disableInitialRegion = useRef(false)

  useEffect(() => {
    if (!focused) return
    // We only create the initial map region once per mount (driven by user location).
    // That way next time the user navigates back to the map, it will not reset back to the initial region but it will use the last bounding box the user was looking at.
    // (unless the map unmounts and resets)
    if (initialRegion && !disableInitialRegion.current) {
      const boundingBox = initialRegion ? getBoundingBoxFromRegion(initialRegion) : null

      if (boundingBox) {
        onRegionChange(initialRegion)
        disableInitialRegion.current = true
      }
    } else if (lastRegion.current) {
      onRegionChange(lastRegion.current)
    }
  }, [initialRegion, focused, onRegionChange])

  const regions = sdk.dataPool.regions.filter(r => r.id === RegionType.geopos)

  const tokens = useMemo(() => {
    const tokenMap = regions.reduce<Map<string, TokenType>>((acc, region) => {
      const tokens = region.ARTokens ?? []
      tokens.forEach(token => {
        if (token.type !== 'vatom-new') acc.set(token.id, token)
      })
      return acc
    }, new Map())

    return Array.from(tokenMap.values())
  }, [regions])

  const joysIds = useMemo(() => ['QkxH4IbOIl', 'Cox1qh6ggb'], [])
  const tokensGeoMap = useMemo(() => {
    if (business && business?.id && !joysIds.includes(business?.id)) {
      return []
    }
    const lastNotLoadingRegion = regions.filter(region => !region.isLoading).pop()
    const tokenItems = new Map<string, TokenType>()

    if (lastNotLoadingRegion) {
      lastNotLoadingRegion.tokens.forEach(token => {
        if (token.type === 'vatom-new') {
          tokenItems.set(token.id, token)
        }
      })

      return Array.from(tokenItems.values())
    }

    return Array.from(tokenItems.values())
  }, [business, joysIds, regions])

  const filteredTokens = useMemo(() => {
    // remove duplicates
    const filtered = !business?.id
      ? tokens
      : tokens.filter(
          token =>
            token.studioInfo?.businessId === business.id ||
            (business.id === 'Cox1qh6ggb'
              ? (token as BVatomTokenType).payload['vAtom::vAtomType'].publisher_fqdn ===
                'mx.joyapp'
              : false) // hack for joy's tokens
        )
    return filtered
  }, [business?.id, tokens])

  const discoverGroups = useGeoDiscoveryGroups(
    {
      filter: 'all',
      precision,
      ...mapBoundingBox
    },
    {
      select: result => ({
        groups: result.payload?.groups?.filter(group => group.count > 1) ?? [],
        singles: result.payload?.groups?.filter(group => group.count === 1) ?? []
      })
    }
  )

  const onMarkerClick = useCallback(
    (item: TokenType) => {
      if (!item.position) throw new Error('Token has no coordinates')
      if (config.features.maps?.pickup?.enabled !== false) onSelectToken(item)
    },
    [config.features.maps?.pickup?.enabled, onSelectToken]
  )

  const markers = useMemo(() => {
    const extraMarkers = Platform.OS === 'web' ? createUserLocationMarkers(location) : []
    return !business?.id && precision <= 12
      ? [
          ...createGroupMarkers(discoverGroups?.data?.groups ?? [], onGroupMarkerPress),
          ...createSingleMarkers(
            discoverGroups?.data?.singles ?? [],
            filteredTokens,
            onMarkerClick
          ),
          ...createVatomMarkers(tokensGeoMap, onMarkerClick),
          ...extraMarkers
        ]
      : [
          ...createCustomMarkers(filteredTokens, onMarkerClick),
          ...extraMarkers,
          ...createVatomMarkers(tokensGeoMap, onMarkerClick)
        ]
  }, [
    location,
    business?.id,
    precision,
    discoverGroups?.data?.groups,
    discoverGroups?.data?.singles,
    onGroupMarkerPress,
    filteredTokens,
    onMarkerClick,
    tokensGeoMap
  ])

  return {
    location,
    selectedToken,
    onPickUp,
    onCloseModal,
    openModal,
    onRegionChange,
    markers,
    handleCenter,

    // only used for native
    initialRegion,

    // only used for web
    initialCenter,
    onCenterChanged,
    openPostModal,
    onPostModal,
    onClosePostModal,
    refetchLocation: mapLocation.refetch,
    renderModalQueryComponent: mapLocation.renderModalQueryComponent,
    setOpenModal
  }
}

import React from 'react'
import { GradientButton, Text } from '@vatom/wombo'
import * as Clipboard from 'expo-clipboard'
import { Box, Image, Pressable } from 'native-base'

export interface IActionItem {
  onPress: () => void
  title: string
}

export const Content = () => {
  const copiedText = '0xb06f5a13196FC4021f917059Ac57c8e8'

  return (
    <Box bgColor="white">
      <Text
        color="black"
        fontFamily="Inter-Regular"
        fontSize={13}
        fontWeight="400"
        mx={4}
        textAlign="center"
      >
        Vatom wallet supports multiple wallet addresses across chains. Create an account with Vatom
        to access all your tokens across your wallets under a single login.
      </Text>
      <GradientButton
        my={6}
        mx={4}
        colorScheme="brand"
        variant="solid"
        hasImage
        imageHeight="16px"
        imageWidth="18.5px"
        imgSource={require('../assets/logo-white.png')}
        imgAlt="Vatom Logo"
      >
        <Text fontWeight="semibold" fontSize="16px" color="white">
          Sign In with Vatom
        </Text>
      </GradientButton>
      <Box
        bgColor="gray.50"
        borderBottomWidth={1}
        borderColor="gray.100"
        borderTopWidth={1}
        px={4}
        py={3}
      >
        <Text color="black" fontFamily="Inter-Medium" fontSize={12} fontWeight="500" mb={2}>
          Ethereum Address:
        </Text>
        <Box flexDir="row" justifyContent="space-between">
          <Text color="black" fontFamily="Inter-Regular" fontSize={15} fontWeight="400">
            0xb06f5a13196FC4021f917059Ac57c8e8
          </Text>
          <Pressable
            accessibilityRole="button"
            onPress={async () => Clipboard.setStringAsync(copiedText)}
          >
            <Image
              h={5}
              resizeMode="contain"
              alt="Copy"
              accessibilityIgnoresInvertColors={true}
              source={require('../assets/Copy.png')}
            />
          </Pressable>
        </Box>
      </Box>
    </Box>
  )
}

export default Content

import { Platform } from 'react-native'
import { STAGE } from 'react-native-dotenv'
import { Instance, SnapshotOut, types } from 'mobx-state-tree'

import logger from '../../logger'
import { Heap } from '../Heap'
import { withRootSDKStore } from '../RootSdk/extensions/withRootSDKStore'
import { instance as Sentry } from '../Sentry'
import { withServiceApiStore } from '../Services/extensions/withServiceStore'
import { SessionSource, SessionType } from '../SessionInfo'

import { IdentityType, UserIdentityModel, UserIdentitySnapshot } from './UserIdentityModel'
import { User, UserModel, UserSnapshot } from './UserModel'

export const UserStore = types
  .model('UserStore')
  .props({
    identities: types.optional(types.array(UserIdentityModel), []),
    user: types.maybe(UserModel)
  })
  .extend(withRootSDKStore)
  .extend(withServiceApiStore)
  .views(self => ({
    get userInfo() {
      return self.user
    }
  }))
  .actions(self => ({
    setIdentities(data: UserIdentitySnapshot[]) {
      logger.info('[UserStore] setIdentities', data.length, self.identities.length)

      // User is already logged in
      // if (self.identities.length > 0) return

      const newIdentities: UserIdentitySnapshot[] = data
        .filter($ => {
          return $.type && Object.values(IdentityType).includes($.type) && UserIdentityModel.is($)
        })
        .sort((a, b) => {
          if (a.custodial) {
            return -1
          }
          if (b.custodial) {
            return 1
          }
          if (a.preferred && !b.preferred) return -1

          if (b.preferred && !a.preferred) return 1
          // a must be equal to b
          return 0
        })
        .map(item => UserIdentityModel.create(item))

      self.identities.replace(newIdentities)

      self.rootStore.dataPool.sessionStore.addAll(
        SessionSource.Vatom,
        SessionType.Eth,
        newIdentities.filter(i => !i.custodial && i.type === 'eth').map(i => i.value)
      )

      self.rootStore.dataPool.sessionStore.addAll(
        SessionSource.Vatom,
        SessionType.Sol,
        newIdentities.filter(i => !i.custodial && i.type === 'sol').map(i => i.value)
      )
    },
    setUser(data: Record<string, any>) {
      // logger.info('[UserStore.setUser]', UserModel.is(data), data)
      if (UserModel.is(data)) {
        self.user = data as User
      } else {
        logger.warn('Unable to map user data', data)
      }
    },
    async fetchIdentities() {
      try {
        const response = await self.api.auth.get('/me/identities')
        this.setIdentities(response.data)
      } catch (error) {
        logger.error('[UserStore.fetchIdentities]', error)
      }
    },
    trackUser() {
      if (self.user && self.user?.sub) {
        const userId = self.user?.sub
        if (self.user?.email) {
          Sentry.configureScope(scope => {
            scope.setTag('platform', Platform.OS)
            scope.setTag('stage', STAGE)
            scope.setUser({
              id: userId,
              email: self.user?.email
            })
          })
        }

        Heap.identify(userId)
      }
    },
    async fetchMe(): Promise<UserSnapshot | undefined> {
      try {
        const response = await self.api.auth.get('/me')
        // logger.info('fetchMe', response)
        this.setUser(response.data)

        self.rootStore.dataPool.sessionStore.add({
          type: SessionType.UserId,
          value: response.data.sub,
          source: SessionSource.Vatom
        })

        this.trackUser()

        return response.data
      } catch (error) {
        logger.error('[UserStore.fetchMe]', error)
      }
      return undefined
    },
    async patchMe(body: Partial<UserSnapshot>) {
      const response = await self.api.auth.patch('/me', body)
      logger.info('[PATCH USER]: ', response)
      await this.fetchMe()
    },
    logout() {
      logger.info('[UserStore.logout] !!!!!!!!!!!!!!!!!!!!')
      self.user = undefined
      self.identities.clear()
    },
    clearCache() {
      self.rootStore.dataPool.clearCache()
    },
    afterCreate() {
      this.trackUser()
    }
  }))

export type UserStoreType = Instance<typeof UserStore>
export type UserStoreSnapshot = SnapshotOut<typeof UserStore>

import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { useWeb3 } from '@vatom/sdk/react'
import { GradientButton, SafeAreaView, Status, Text, Toast } from '@vatom/wombo'
import { Box, Image } from 'native-base'

import { AppRoutes, AppStackScreenProps } from '../../navigators'
import PrimaryText from '../ActionSheets/partials/PrimaryText'
import ScreenHeader from '../ActionSheets/partials/ScreenHeader'
import SecondaryText from '../ActionSheets/partials/SecondaryText'
import TransactionLink from '../ActionSheets/partials/TransactionLink'

type SendToChainConfirmationProps = AppStackScreenProps<typeof AppRoutes.sendToChainConfirmation>

export const SendToChainConfirmation = ({ route, navigation }: SendToChainConfirmationProps) => {
  const nav = useNavigation()
  const web3 = useWeb3(null)

  // const { walletAddress } = route.params
  React.useEffect(() => {
    Toast({ title: 'Your NFT has been sent', status: Status.success })
    // Toast({ title: 'Your transaction failed!', status: 'error' })
  }, [])
  return (
    <SafeAreaView marginX="16px" flex={1} backgroundColor="white">
      <ScreenHeader route={route} navigation={navigation} />
      <Box flex={1} justifyContent="center" alignItems="center">
        <Image
          accessibilityIgnoresInvertColors={true}
          source={require('./assets/request-sent.png')}
          height="174px"
          width="203px"
          marginBottom="20px"
          alt="request-sent"
        />
        <PrimaryText text="Your request has been sent!" />
        <SecondaryText
          isShare={false}
          text=" It can take up to 20 minutes based on the network congestion. Here is the transaction id
          for your request. Click on the link below for complete details."
          hasLearnMore={true}
        />
        <TransactionLink hash={web3.Web3Confirmation?.hash} chain={web3.Web3Confirmation?.chain} />
        <GradientButton
          onPress={() => {
            nav.navigate(AppRoutes.home)
          }}
          h="38px"
          w="73px"
          justifyContent="center"
        >
          <Text alignSelf="center" fontFamily="Inter-Medium" color="white" tx="common.done" />
        </GradientButton>
      </Box>
    </SafeAreaView>
  )
}

export default SendToChainConfirmation

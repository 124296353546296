
import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const AngleDownIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 12 7" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M11.25 1.8125L6.5 6.28125C6.34375 6.4375 6.15625 6.5 6 6.5C5.8125 6.5 5.625 6.4375 5.46875 6.3125L0.71875 1.8125C0.40625 1.53125 0.40625 1.0625 0.6875 0.75C0.96875 0.4375 1.4375 0.4375 1.75 0.71875L6 4.71875L10.2188 0.71875C10.5312 0.4375 11 0.4375 11.2812 0.75C11.5625 1.0625 11.5625 1.53125 11.25 1.8125Z"
    />
  </Icon>
)

import { useMemo } from 'react'
import { useCurrentLocation as useLocation, useCurrentLocationProps } from '@vatom/sdk/react'

import { useBusinessSelector } from './useBusinessSelector'

const useCurrentLocation = (props?: Omit<useCurrentLocationProps, 'extraData'>) => {
  const { business } = useBusinessSelector()

  const extraData = useMemo(() => {
    if (business) {
      return {
        businessId: business?.id,
        campaignId: business?.defaultCampaignId
      }
    }
    return {}
  }, [business])
  return useLocation({ ...props, extraData })
}

export { useCurrentLocation }

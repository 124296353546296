import { useCallback } from 'react'
import { Sentry, SessionTokenSnapshot } from '@vatom/sdk/core'
import * as AuthSession from 'expo-auth-session'
import { TokenTypeHint } from 'expo-auth-session'
import { getSnapshot } from 'mobx-state-tree'

import logger from '../logger'
import { SDKQueryClient, SDKQueryClientPersister } from '../store'
import { useSDK } from '../store/SDKProvider'

import { CookieManager } from './cookies'
import { useConfig } from './useConfig'

export const useLogout = () => {
  const sdk = useSDK()
  const session = sdk.dataPool.sessionStore.vatomIncSessionToken
  const config = useConfig()
  const { discoveryUrl } = config.authentication
  const discovery = AuthSession.useAutoDiscovery(discoveryUrl)

  return useCallback(async () => {
    // Log out of the OIDC server
    if (session && discovery) {
      try {
        const jwtSession = session as SessionTokenSnapshot
        // const token = jwtSession.idToken ?? ''
        const token = jwtSession.accessToken
        const { clientId } = config.authentication
        logger.info('[useLogout] revoke', discovery?.revocationEndpoint)
        const isTokenRevoked = await AuthSession.revokeAsync(
          { clientId, token, tokenTypeHint: TokenTypeHint.AccessToken },
          discovery
        )
        logger.info('[useLogout] isTokenRevoked', isTokenRevoked)
        // clear a specific cookie by its name (IOS ONLY)
        // const clearByName = await CookieManager.clearByName(discovery?.authorizationEndpoint, 'cookie_name')
        // logger.info('CookieManager.clearByName', clearByName)
        // https://stackoverflow.com/questions/47207914/sfauthenticationsession-aswebauthenticationsession-and-logging-out
        const clearAll = await CookieManager.clearAll()
        logger.info('[useLogout] CookieManager.clearAll', clearAll)
        // Remove session cookies (ANDROID ONLY)
        // Session cookies are cookies with no expires set. Android typically does not
        // remove these, it is up to the developer to decide when to remove them.
        // The return value is true if any session cookies were removed.
        // iOS handles removal of session cookies automatically on app open.
        const removeSessionCookies = await CookieManager.removeSessionCookies()
        logger.info('[useLogout] CookieManager.removeSessionCookies', removeSessionCookies)
        Sentry.instance.setUser(null)
      } catch (error) {
        logger.error('[useLogout]', error)
      }

      // Log out of the user store
      sdk.dataPool.user.logout()

      // Remove token from the axios services
      sdk.service.setToken(undefined)
      SDKQueryClient.clear()
      SDKQueryClientPersister.removeClient()
      AuthSession.dismiss()
    }
  }, [session, discovery, sdk.dataPool.user, sdk.service, config.authentication])
}

import React from 'react'
import { useConfig } from '@vatom/sdk/react'
import { theme } from '@vatom/wombo'
import { Box, Pressable, Text } from 'native-base'

import QRCodeSVG from '../../../assets/qr-code.min.svg'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'

function ProfileUserBasicInfo({
  name,
  username,
  onPressQrCode
}: {
  name: string
  username?: string
  onPressQrCode: () => void
}) {
  const { isDark } = useBusinessTheme()
  const config = useConfig()

  return (
    <Box>
      <Text
        fontFamily="Inter-Bold"
        color={theme.colors.black}
        fontSize={17}
        lineHeight={24}
        marginTop={2}
        _dark={{ color: theme.colors.textDarkMode[100] }}
      >
        {name}
      </Text>
      <Box flexDirection="row" justifyContent="center" alignItems="center" marginTop={1}>
        {username && config.showUsername && (
          <Box marginRight={2}>
            <Text
              _light={{
                color: theme.colors.lightText
              }}
              _dark={{
                color: theme.colors.grayDarkMode[200]
              }}
              fontFamily="Inter-Regular"
              fontSize={13}
              lineHeight={18}
            >
              {`@${username}`}
            </Text>
          </Box>
        )}
        {config.showUserQRCode && (
          <Pressable accessibilityRole="button" onPress={onPressQrCode}>
            <QRCodeSVG width={20} height={20} fill={isDark ? '#83858A' : theme.colors.bodyText} />
          </Pressable>
        )}
      </Box>
    </Box>
  )
}

export { ProfileUserBasicInfo }

import { useCallback, useEffect, useRef, useState } from 'react'
import { BackHandler } from 'react-native'
import {
  createNavigationContainerRef,
  NavigationAction,
  NavigationContainerRefWithCurrent,
  NavigationState,
  PartialState
} from '@react-navigation/native'

import logger from '../logger'

import { AppStackParamList } from './AppNavigator'

/* eslint-disable */
export const RootNavigation = {
  navigate(_name: string, _params?: any) {},
  goBack() {},
  resetRoot(_state?: PartialState<NavigationState> | NavigationState) {},
  getRootState(): NavigationState {
    return {} as any
  },
  dispatch(_action: NavigationAction) {}
}
/* eslint-enable */

export type navRefType = NavigationContainerRefWithCurrent<AppStackParamList>
export const navigationRef = createNavigationContainerRef<AppStackParamList>()

/**
 * Gets the current screen from any navigation state.
 */
export function getActiveRouteName(state: NavigationState | PartialState<NavigationState>): string {
  if (state.index !== undefined || state?.routes.length === 1) {
    const route = state.routes[state.index || 0]

    // Found the active route -- return the name
    if (!route.state) return route.name

    // Recursive call to deal with nested routers
    return getActiveRouteName(route.state)
  }
  return ''
}

/**
 * Gets the current route info from any navigation state.
 */
export function getActiveRoute(state: NavigationState | PartialState<NavigationState>) {
  if (state.index !== undefined || state?.routes.length === 1) {
    const route = state.routes[state.index || 0]

    // Found the active route -- return the name
    if (!route.state) return route

    // Recursive call to deal with nested routers
    return getActiveRoute(route.state)
  }
}

/**
 * Hook that handles Android back button presses and forwards those on to
 * the navigation or allows exiting the app.
 */
export function useBackButtonHandler(
  canExit: (routeName: string) => boolean,
  canGoBack: (routeName: string) => boolean
) {
  const canExitRef = useRef(canExit)
  const canGoBackRef = useRef(canGoBack)

  useEffect(() => {
    canGoBackRef.current = canGoBack
  }, [canGoBack])

  useEffect(() => {
    canExitRef.current = canExit
  }, [canExit])

  useEffect(() => {
    // We'll fire this when the back button is pressed on Android.
    const onBackPress = () => {
      if (!navigationRef.isReady()) {
        return false
      }

      // grab the current route
      const routeName = getActiveRouteName(navigationRef.getRootState())

      console.log(
        'onBackPress: ',
        canGoBackRef.current(routeName),
        navigationRef.canGoBack(),
        canExitRef.current(routeName)
      )

      // are we allowed to exit?
      if (canExitRef.current(routeName)) {
        // exit and let the system know we've handled the event
        BackHandler.exitApp()
        return true
      }

      // we can't exit, so let's turn this into a back action
      if (canGoBackRef.current(routeName) && navigationRef.canGoBack()) {
        navigationRef.goBack()
        return true
      }

      return true
    }

    // Subscribe when we come to life
    const subscription = BackHandler.addEventListener('hardwareBackPress', onBackPress)

    // Unsubscribe when we're done
    return () => subscription.remove()
  }, [])
}

/**
 * Custom hook for persisting navigation state.
 */
export function useNavigationPersistence(storage: any, persistenceKey: string) {
  const [initialNavigationState, setInitialNavigationState] = useState()

  // This feature is particularly useful in development mode.
  // It is selectively enabled in development mode with
  // the following approach. If you'd like to use navigation persistence
  // in production, remove the __DEV__ and set the state to false
  // DEV ONLY:
  // const [isRestored, setIsRestored] = useState(!__DEV__)
  // PRODUCTION:
  const [isRestored, setIsRestored] = useState(false)

  const routeNameRef = useRef<string | undefined>()

  const onNavigationStateChange = (state?: NavigationState) => {
    logger.info('[useNavigationPersistence.onNavigationStateChange]', state)
    const previousRouteName = routeNameRef.current
    const currentRouteName = state ? getActiveRouteName(state) : ''

    if (previousRouteName !== currentRouteName) {
      // track screens.
      // __DEV__ && logger.tron.log(currentRouteName)
      __DEV__ && logger.info('[useNavigationPersistence] currentRouteName', currentRouteName)
    }

    // Save the current route name for later comparision
    routeNameRef.current = currentRouteName

    // Persist state to storage
    storage.save(persistenceKey, state)
  }

  const restoreState = useCallback(async () => {
    try {
      const state = await storage.load(persistenceKey)
      if (state) setInitialNavigationState(state)
    } finally {
      setIsRestored(true)
    }
  }, [storage, persistenceKey])

  useEffect(() => {
    if (!isRestored) restoreState()
  }, [isRestored, restoreState])

  return { onNavigationStateChange, restoreState, isRestored, initialNavigationState }
}

/**
 * use this to navigate to navigate without the navigation
 * prop. If you have access to the navigation prop, do not use this.
 * More info: https://reactnavigation.org/docs/navigating-without-navigation-prop/
 */
export function navigate(name: any, params?: any) {
  if (navigationRef.isReady()) {
    // @ts-expect-error not sure the correct fix for this
    navigationRef.navigate(name as never, params as never)
  }
}

export function goBack() {
  if (navigationRef.isReady() && navigationRef.canGoBack()) {
    navigationRef.goBack()
  }
}

export function resetRoot(params = { index: 0, routes: [] }) {
  if (navigationRef.isReady()) {
    navigationRef.resetRoot(params)
  }
}

export const findKeyInParams = (objectWithParams: any, key: string): string | null => {
  if (!objectWithParams?.params) {
    return null
  }
  const foundKey = (objectWithParams?.params[key] as string) || null
  if (!foundKey && objectWithParams?.params?.params) {
    return findKeyInParams(objectWithParams?.params, key)
  }
  return foundKey
}

const defaultIgnoreKeys = ['initial', 'path', 'params', 'screen', 'state']

export function getAllParamsFromRoutes(
  state: NavigationState,
  ignoreKeys: string[] = defaultIgnoreKeys
) {
  const allParams = []
  const activeIndex = state.index ?? 0
  const activeRoute = state.routes[activeIndex]
  if (activeRoute.params !== undefined) {
    allParams.push(activeRoute.params)
    // @ts-ignore
    if (typeof activeRoute.params?.params === 'object') {
      // @ts-ignore
      allParams.push(activeRoute.params.params)
    }
  }

  if (activeRoute?.state !== undefined) {
    // @ts-ignore
    const moreParams = getAllParamsFromRoutes(activeRoute.state)
    allParams.push(moreParams)
  }

  const reducedParams: Record<string, any> = allParams.reduce((params, acc) => {
    const neededParams = {} as Record<string, any>
    Object.entries(params).forEach(([key, value]) => {
      if (!ignoreKeys.includes(key)) {
        neededParams[key] = value
      }
    })
    return {
      ...acc,
      ...neededParams
    }
  }, {})

  return reducedParams
}

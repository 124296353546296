import { useFrame } from '@react-three/fiber'
import { useControls } from 'leva'

export const useCameraPanel = () => {
  const [_, setCameraInfo] = useControls('Camera Info', () => {
    return {
      cameraPosition: {
        value: [0, 0, 0],
        editable: false
      },
      cameraRotation: {
        value: [0, 0, 0],
        editable: false
      }
    }
  })

  useFrame(({ scene, camera }) => {
    if (!camera.position) {
      return
    }
    setCameraInfo({
      cameraPosition: [camera.position.x, camera.position.y, camera.position.z],
      cameraRotation: [camera.rotation.x, camera.rotation.y, camera.rotation.z]
    })
  })
}

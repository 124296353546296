import React, { useEffect } from 'react'
import { useFocusEffect } from '@react-navigation/native'

const originalStyles = {
  overflow: document.body.style.overflow,
  position: document.body.style.position,
  width: document.body.style.width,
  height: document.body.style.height
}

const unlock = () => {
  document.body.style.overflow = originalStyles.overflow
  document.body.style.position = originalStyles.position
  document.body.style.width = originalStyles.width
  document.body.style.height = originalStyles.height
  document.ontouchmove = null
}

const lock = () => {
  document.body.style.overflow = 'hidden'
  document.body.style.position = 'fixed'
  document.body.style.width = '100%'
  document.body.style.height = '100dvh'
  document.ontouchmove = e => e.preventDefault()
}

export function useScrollLock() {
  useEffect(() => {
    lock()
    return () => {
      unlock()
    }
  }, [])

  useFocusEffect(
    React.useCallback(() => {
      lock()
      return () => {
        unlock()
      }
    }, [])
  )
}

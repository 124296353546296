import React, { useCallback, useMemo, useState } from 'react'
import { Ionicons } from '@expo/vector-icons'
import { IdentityType } from '@vatom/sdk/core'
import { useConfig, useIdentities } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { PressableOpacity } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, HStack, Icon } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import { useModalState } from '../../../hooks/useModalState'

import { QRCodeModal } from './QRCodeModal'
import { WalletAddress } from './WalletAddress'

export const CustodialWallets = observer(() => {
  const { identities } = useIdentities()
  const config = useConfig()

  const { isVisible, closeModal, openModal } = useModalState()
  const [QRCodeInfo, setQRCodeInfo] = useState({
    link: '',
    title: ''
  })

  const showEthIdentity = config.features.allowedChains.includes('eth')
  const showSolIdentity = config.features.allowedChains.includes('sol')
  const showCasperIdentity = config.features.allowedChains.includes('cspr')

  const ethWallet = useMemo(() => {
    return identities.find(ident => ident.type === IdentityType.Eth && ident.custodial) ?? null
  }, [identities])

  const solanaWallet = useMemo(() => {
    return identities.find(ident => ident.type === IdentityType.Solana && ident.custodial) ?? null
  }, [identities])

  const casperWallet = useMemo(() => {
    return identities.find(ident => ident.type === IdentityType.Casper && ident.custodial) ?? null
  }, [identities])

  const onPressQRCode = useThrottledCallback(
    (walletAddressLink: string, title: string) => {
      setQRCodeInfo({
        link: walletAddressLink,
        title
      })
      openModal()
    },
    300,
    { leading: true }
  )

  const CustodialRight = useCallback(
    ({ qrLink, title }: { qrLink: string; title: string }) => (
      <PressableOpacity
        alignSelf={'center'}
        accessibilityRole="button"
        onPress={() => onPressQRCode(qrLink, title)}
        alignItems={'center'}
        justifyContent={'center'}
        size={8}
      >
        <Icon as={Ionicons} size={6} name="md-qr-code-outline" alignSelf="center" />
      </PressableOpacity>
    ),
    [onPressQRCode]
  )

  return (
    <Box marginTop={2}>
      <QRCodeModal
        isOpen={isVisible}
        onDismiss={closeModal}
        link={QRCodeInfo.link}
        title={QRCodeInfo.title}
      />
      {showEthIdentity && ethWallet && (
        <HStack flexGrow={1} marginY={2} alignItems={'center'}>
          <WalletAddress
            title={translate('common.ethereumAddress')}
            address={ethWallet.value}
            RightComponent={
              <CustodialRight
                qrLink={`ethereum:${ethWallet.value}`}
                title={translate('common.ethereumAddress')}
              />
            }
          />
        </HStack>
      )}
      {showSolIdentity && solanaWallet && (
        <HStack flexGrow={1} marginY={2} alignItems={'center'}>
          <WalletAddress
            title={translate('common.solanaAddress')}
            address={solanaWallet.value}
            RightComponent={
              <CustodialRight
                qrLink={`solana:${solanaWallet.value}`}
                title={translate('common.solanaAddress')}
              />
            }
          />
        </HStack>
      )}
      {showCasperIdentity && casperWallet && (
        <HStack flexGrow={1} marginY={2} alignItems={'center'}>
          <WalletAddress
            title={`Casper Address`}
            address={casperWallet.value}
            // picture={'url'}
            RightComponent={
              <CustodialRight qrLink={`casper:${casperWallet.value}`} title={`Casper Address`} />
            }
          />
        </HStack>
      )}
    </Box>
  )
})

import React from 'react'

import LeftArrowIcon from '../../../../../assets/arrow-icons/gallery-slider-arrow-left.svg'
import RightArrowIcon from '../../../../../assets/arrow-icons/gallery-slider-arrow-right.svg'
import { useCommunitiesTheme } from '../../../../../themes'

import './GallerySliderForWeb.css'

const SliderArrow = (props?: any) => {
  const { className, style, onClick, direction, placement } = props
  const communitiesTheme = useCommunitiesTheme()

  const buttonDimension = placement === 'feed' ? 30 : 40
  const arrowDimension = placement === 'feed' ? 8 : 14

  return (
    <button
      className={className}
      style={{
        ...style,
        background: communitiesTheme.modalToolBar.messageComponent.background,
        right: direction === 'left' ? 'auto' : 30,
        left: direction === 'left' ? 30 : 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: buttonDimension,
        height: buttonDimension,
        zIndex: 999,
        borderRadius: 80
      }}
      onClick={onClick}
    >
      {direction === 'left' ? (
        <LeftArrowIcon
          fill={communitiesTheme.xButtonColor}
          style={{ width: arrowDimension, height: arrowDimension }}
        />
      ) : (
        <RightArrowIcon
          fill={communitiesTheme.xButtonColor}
          style={{ width: arrowDimension, height: arrowDimension }}
        />
      )}
    </button>
  )
}

export default SliderArrow

import React, { useMemo, useState } from 'react'
import { Platform, View } from 'react-native'
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedStyle,
  useSharedValue
} from 'react-native-reanimated'
import { useGetSpace } from '@vatom/sdk/react'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { observer } from 'mobx-react-lite'
import { Box } from 'native-base'

import { withBusinessSelector } from '../../../../hooks/useBusinessSelector'
import { useLayoutScrollHandler } from '../../../../hooks/useLayoutScrollHandler'
import { AppRoutes, AppStackScreenProps } from '../../../../navigators'
import FABRoom from '../../components/FAB'
import { getRoomVisibility } from '../../components/home-components/RoomItem'
import IsUserJoinedWrapper from '../../components/IsUserJoinedWrapper'
import MessageList from '../../components/MessageList'
import RoomHeader from '../../components/RoomHeader'
import { RoomProvider } from '../../components/RoomProvider'
import { useCommunitiesTheme } from '../../themes'
import { RoomType } from '../../types'

const isWeb = Platform.OS === 'web'

type RoomPageProps = AppStackScreenProps<typeof AppRoutes.CommunitiesRoom | typeof AppRoutes.Room>

const RoomView = observer((props: RoomPageProps) => {
  const { roomTab, spaceId, business } = props.route.params

  const communitiesTheme = useCommunitiesTheme()
  const { config } = useVatomWalletSdkStore()
  const space = useGetSpace(spaceId)
  const matrixRoomId = space.data?.matrixRoomId

  const shouldHideBackButton = config?.screensConfig?.[props.route.name].hideBackButton

  const newRoom = useMemo(
    () =>
      space.data
        ? {
            room_id: matrixRoomId,
            avatar_url: space.data.cover,
            name: space.data.displayName,
            join_rule: getRoomVisibility(space.data.additionalAuth),
            num_joined_members: space.data.members,
            space_id: space.data.id,
            business_id: business
          }
        : null,
    [business, matrixRoomId, space.data]
  ) as RoomType

  const { lastContentOffset } = useLayoutScrollHandler()

  const [filter, setFilter] = useState(roomTab ?? 'all')

  const headerHeight = useSharedValue(0)

  const animatedStyle = useAnimatedStyle(() => {
    const top = interpolate(
      lastContentOffset.value,
      [0, headerHeight.value],
      [headerHeight.value, 0],
      Extrapolation.CLAMP
    )
    return {
      paddingTop: isWeb ? top : undefined,
      paddingBottom: isWeb ? 70 : undefined,
      marginBottom: isWeb ? -70 : undefined
    }
  }, [])

  if (!space.data) {
    // TODO: Add UI Feedback if not room was found
    console.log('Room not found')
    return null
  }

  return (
    <RoomProvider value={newRoom}>
      <View
        style={{
          backgroundColor: communitiesTheme.background,
          height: '100%',
          flex: 1,
          flexShrink: 0
        }}
      >
        <Box
          style={{
            transform: [{ translateY: 0 }]
          }}
          _web={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 99
          }}
        >
          <RoomHeader
            hideBackButton={shouldHideBackButton}
            filter={filter}
            setFilter={setFilter}
            headerHeight={headerHeight}
          />
        </Box>
        <Animated.View
          style={[
            {
              backgroundColor: communitiesTheme.background,
              transform: [{ translateY: 0 }]
            },
            animatedStyle
          ]}
        >
          <IsUserJoinedWrapper roomId={matrixRoomId}>
            {matrixRoomId ? (
              <MessageList roomId={matrixRoomId} filter={filter} spaceId={spaceId} />
            ) : null}
          </IsUserJoinedWrapper>
        </Animated.View>
        <FABRoom spaceId={spaceId} />
      </View>
    </RoomProvider>
  )
})

const RoomViewWithBusinessSelector = withBusinessSelector(RoomView)
export default RoomViewWithBusinessSelector

import React from 'react'
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useMatrixUser } from '@vatom/sdk/react'
import axios from 'axios'

import { matrixServerUrl } from '../../../constants'
import { useMessageReactions } from '../../../queries'
import { useCommunitiesTheme } from '../../../themes'
import { CommunitiesReactionType } from '../../../types'
import { useMessageContext } from '../MessageContext'

import AddEmojiReactionIcon from './assets/add_emoji_reaction_icon.svg'
import EmojiPickerComponent from './EmojiPicker'

type EmojiLineProps = {
  align: string
  isThread: boolean
  roomId: string
}

type GroupedEmojies = {
  key: string
  data: Array<CommunitiesReactionType>
}

const EmojiLine = (props: EmojiLineProps) => {
  const { messageEventID } = useMessageContext()
  const { align, isThread, roomId } = props
  const { data: matrixUser } = useMatrixUser()
  const userId = matrixUser?.user_id

  const { data: reactions } = useMessageReactions(roomId, messageEventID)

  const communitiesTheme = useCommunitiesTheme()

  const reactionTypes = ['like', 'dislike']

  const allReactions = reactions

  const emojies =
    allReactions?.filter(reaction => !reactionTypes.some(type => type === reaction.key)) ?? []

  const result: Array<GroupedEmojies> = Object.values(
    emojies.reduce((acc: any, curr) => {
      const key = curr.key
      acc[key] ??= { key, data: [] }
      acc[key].data.push(curr)
      return acc
    }, {})
  )

  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const handlePick = async (emoji: string) => {
    const dateNow = Date.now()
    await axios.put(
      `${matrixServerUrl}/_matrix/client/r0/rooms/${roomId}/send/m.reaction/emoji.${dateNow}?access_token=${matrixUser?.access_token}`,
      {
        'm.relates_to': {
          rel_type: 'm.annotation',
          event_id: messageEventID,
          key: emoji
        }
      }
    )
  }

  const removeEvent = async (event: any) => {
    const dateNow = Date.now()
    const { eventId } = event
    await axios.put(
      `${matrixServerUrl}/_matrix/client/r0/rooms/${roomId}/redact/${eventId}/${messageEventID}.emoji.${dateNow}?access_token=${matrixUser?.access_token}`,
      {}
    )
  }

  return (
    <ScrollView
      horizontal={true}
      style={{
        marginTop: 8,
        marginLeft: isThread ? 0 : align === 'indent' ? 55 : 0,
        flexDirection: 'row'
      }}
    >
      <TouchableOpacity
        accessibilityRole="button"
        onPress={() => {
          setIsOpen(true)
        }}
        key="emoji-holder"
      >
        <View
          style={[styles.emojiHolder, { backgroundColor: communitiesTheme.emojiLine.background }]}
        >
          <AddEmojiReactionIcon fill={communitiesTheme.emojiLine.icon} />
        </View>
      </TouchableOpacity>

      <EmojiPickerComponent isOpen={isOpen} setIsOpen={setIsOpen} handlePick={handlePick} />

      <View style={{ flexDirection: 'row' }} key="emoji-list">
        {result.map((info, index) => {
          const { key, data } = info
          const userReactedStyle = {
            borderWidth: 1,
            borderColor: communitiesTheme.accentColor
          }
          const hasUserReacted = data.find(
            (reaction: CommunitiesReactionType) => reaction.sender === userId
          )

          return (
            <TouchableOpacity
              accessibilityRole="button"
              key={index}
              style={[
                {
                  ...styles.emojiHolder,
                  ...(hasUserReacted ? userReactedStyle : {})
                },
                { backgroundColor: communitiesTheme.emojiLine.background }
              ]}
              onPress={async () =>
                hasUserReacted ? await removeEvent(hasUserReacted) : await handlePick(key)
              }
            >
              <Text style={{ fontSize: 15, marginRight: 4 }}>{key}</Text>
              <Text style={{ fontSize: 13, color: '#868E96' }}>{data.length}</Text>
            </TouchableOpacity>
          )
        })}
      </View>
    </ScrollView>
  )
}

export default EmojiLine

const styles = StyleSheet.create({
  emojiHolder: {
    marginRight: 4,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingVertical: 4,
    paddingHorizontal: 8
  }
})

import React, { forwardRef } from 'react'
import { theme } from '@vatom/wombo'
import { TextArea } from 'native-base'

type CommentInputProps = React.ComponentProps<typeof TextArea> & {
  comment: string
  setComment: (comment: string) => void
}

const CommentInput = forwardRef(
  ({ comment, setComment, ...otherProps }: CommentInputProps, ref) => {
    return (
      <TextArea
        ref={ref}
        accessibilityHint="Comment input field"
        accessibilityLabel="Comment"
        placeholder="Comment"
        autoCapitalize="sentences"
        value={comment}
        onChangeText={setComment}
        multiline={true}
        maxLength={64}
        height={120}
        padding={3}
        borderWidth={1}
        borderRadius={4}
        fontSize={15}
        lineHeight={20}
        fontFamily={'Inter-Regular'}
        _light={{
          borderColor: theme.colors.grayCool[300]
        }}
        _dark={{
          borderColor: theme.colors.grayDarkMode[500]
        }}
        {...otherProps}
      />
    )
  }
)

const CommentInputMemo = React.memo(CommentInput)

export { CommentInputMemo as CommentInput }

import React from 'react'
import { Image } from 'expo-image'

import NoUserAvatarWeb from '../assets/no-user-avatar-web.svg'
import { useMemberData } from '../queries'
import { useCommunitiesTheme } from '../themes'

type UserAvatarProps = {
  memberId: string
  style: object
  width: number
  height: number
}

const UserAvatar = (props: UserAvatarProps) => {
  const { memberId, style, width, height } = props
  const data = useMemberData(memberId)

  const communitiesTheme = useCommunitiesTheme()
  const imageStyle = {
    borderColor: communitiesTheme.replyMessageScreen.background,
    ...style
  }

  return data?.picture ? (
    <Image
      accessibilityIgnoresInvertColors
      key={memberId}
      source={data?.picture}
      contentFit="cover"
      cachePolicy="memory"
      style={{
        width: width,
        height: height,
        borderColor: communitiesTheme.replyMessageScreen.background,
        ...style
      }}
    />
  ) : (
    <NoUserAvatarWeb width={width} height={height} style={imageStyle} />
  )
}

export default UserAvatar

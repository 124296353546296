import React, { useEffect, useState } from 'react'
import { IdentitySnapshot } from '@vatom/sdk/core'
import { translate } from '@vatom/utils'
import { Text, theme } from '@vatom/wombo'
import { Box, Pressable } from 'native-base'

import ProcessButton from '../../ActionSheets/partials/ProcessButton'
import SelectAddressOld from '../../ActionSheets/partials/SelectAddressOld'

interface IMyAddress {
  setSelectedAddress: (address: string) => void
  selectedAddress: string
  identities: IdentitySnapshot[]
  onMint: () => void
  onSelectCustomAddress: () => void
  isLoading: boolean
}

export const MyAddress = ({
  setSelectedAddress,
  selectedAddress,
  identities,
  onMint,
  onSelectCustomAddress,
  isLoading
}: IMyAddress) => {
  const [isMintDisabled, setIsMintDisabled] = useState(true)

  // There does not seem to be an easy way to check if the address is valid
  useEffect(() => {
    // if (!/^(0x)?[0-9a-f]{40}$/i.test(selectedAddress)) {
    //   setIsMintDisabled(true)
    // } else if (/^(0x)?[0-9a-fA-F]{40}$/.test(selectedAddress)) {
    //   setIsMintDisabled(false)
    // }
    setIsMintDisabled(!selectedAddress)
  }, [selectedAddress])

  return (
    <Box flexGrow={1}>
      <Box flex={1}>
        <SelectAddressOld
          setSelectedAddress={setSelectedAddress}
          selectedAddress={selectedAddress}
          hasManage={true}
          title={translate('mintNft.myAddresses')}
          identities={identities}
        />
      </Box>
      <Box alignItems={'flex-end'} paddingBottom={5}>
        <Pressable
          onPress={onSelectCustomAddress}
          accessibilityRole="button"
          paddingY={4}
          marginY={3}
        >
          <Text
            fontFamily="Inter-Regular"
            fontSize="13px"
            lineHeight="18px"
            _dark={{
              color: theme.colors.systemColorsDark.blue
            }}
            _light={{
              color: theme.colors.systemColorsLight.blue
            }}
            textAlign="center"
            tx="mintNft.mintNoListed"
          />
        </Pressable>

        <ProcessButton
          label={translate('mintNft.mint')}
          isDisabled={isMintDisabled || isLoading}
          onPress={onMint}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  )
}
export default MyAddress

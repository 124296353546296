import React from 'react'
import { Avatar, Text, theme } from '@vatom/wombo'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import { Box, Button, Image, Link } from 'native-base'

import ArrowRight from '../assets/arrow-right-long.svg'
import BuyIcon from '../assets/buy.svg'
import SellIcon from '../assets/sell.svg'
import SwapIcon from '../assets/swap.svg'

const config = {
  thresholds: [
    { l: 's', r: 1 },
    { l: 'm', r: 1 },
    { l: 'mm', r: 59, d: 'minute' },
    { l: 'h', r: 1 },
    { l: 'hh', r: 23, d: 'hour' },
    { l: 'd', r: 1 },
    { l: 'dd', r: 29, d: 'day' },
    { l: 'M', r: 1 },
    { l: 'MM', r: 11, d: 'month' },
    { l: 'y', r: 1 },
    { l: 'yy', d: 'year' }
  ]
}
dayjs.extend(relativeTime, config)
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years'
  }
})

function getDate(time: string) {
  const date = dayjs(time)

  const weeksPassed = dayjs().diff(date, 'week')

  if (weeksPassed < 4) {
    return `${weeksPassed}w`
  } else {
    return date.fromNow(true)
  }
}

enum transactionTypes {
  crypto = 'crypto',
  points = 'points'
}
type TransactionType = keyof typeof transactionTypes

// type CoinsOperationType = 'buy' | 'sell' | 'swap'
type CoinsOperationType = 'send' | 'receive'
enum pointsOperationTypes {
  reward = 'reward',
  redeem = 'redeem',
  giftIn = 'giftIn',
  giftOut = 'giftOut'
}
type PointsOperationType = keyof typeof pointsOperationTypes

type Item = {
  id: string
  type: TransactionType
  timeAgo: string
}
type ItemCoins = Item & {
  type: 'crypto'
  operationType: CoinsOperationType
  from: string
  to: string
  amount: string
  scanUrl: string
}
type ItemPoints = Item & {
  type: 'points'
  operationType: PointsOperationType
  title: string
  description: string
  amount: string
  image: string
}

type ItemType = ItemCoins | ItemPoints

const OperationType = ({ type }: { type: CoinsOperationType }) => {
  switch (type) {
    case 'receive':
      return <BuyIcon height={30} width={30} fill={'#34C759'} />
    case 'send':
      return <SellIcon height={30} width={30} fill={'#DD4267'} />
    // case 'swap':
    //   return <SwapIcon height={30} width={30} fill={'#2EA7FF'} />
    default:
      return null
  }
}

const TransactionCryptoIcon = ({ type }: { type: CoinsOperationType }) => (
  <Box
    width={44}
    height={44}
    alignItems={'center'}
    justifyContent={'center'}
    borderWidth={1}
    borderColor={theme.colors.gray[900]}
    borderRadius={999}
  >
    <OperationType type={type} />
  </Box>
)

const TextCoin = ({ children }: React.PropsWithChildren) => (
  <Text
    fontSize={13}
    lineHeight={18}
    fontFamily={'Inter-Regular'}
    fontWeight="400"
    color={theme.colors.bodyText}
  >
    {children}
  </Text>
)

const TransactionCrypto = ({
  operation,
  from,
  to,
  timeAgo,
  amount,
  scanUrl
}: {
  operation: CoinsOperationType
  from: string
  to: string
  timeAgo: string
  amount: string
  scanUrl: string
}) => (
  <TransactionItem>
    <TransactionCryptoIcon type={operation} />
    <Box flex={1} px={2}>
      <Box flexDirection={'row'} alignItems={'center'}>
        <Text
          fontSize={16}
          lineHeight={22}
          fontFamily={'Inter-Regular'}
          fontWeight="600"
          color={theme.colors.darkText}
          textTransform={'capitalize'}
        >
          {operation}
        </Text>

        <Link href={scanUrl} flexDirection="row" isExternal marginLeft={1}>
          <Button padding={0} bgColor="transparent">
            <Image
              accessibilityIgnoresInvertColors={true}
              size="12px"
              source={require('./assets/external-link.png')}
              alt="external-link"
            />
          </Button>
        </Link>
      </Box>

      <Box mt={1} flexDirection={'row'} alignItems={'center'}>
        <TextCoin>{'...' + from?.slice(-10)}</TextCoin>
        <Box mx={1.5}>
          <ArrowRight width={13} height={8} fill={theme.colors.bodyText} />
        </Box>

        <TextCoin>{'...' + to?.slice(-10)}</TextCoin>
      </Box>
    </Box>

    <Box
      flexDirection={'row'}
      alignSelf={'stretch'}
      justifyContent={'space-between'}
      textAlign={'right'}
    >
      <Text
        fontSize={12}
        lineHeight={16}
        fontFamily={'Inter-Regular'}
        fontWeight="500"
        color={theme.colors.lightText}
      >
        {timeAgo + ', '}
      </Text>

      <Text
        fontSize={12}
        lineHeight={16}
        fontFamily={'Inter-Regular'}
        fontWeight="500"
        color={theme.colors.lightText}
        marginLeft={1}
      >
        {amount}
      </Text>
    </Box>
  </TransactionItem>
)

const PointContent = ({
  operation,
  title,
  description
}: {
  operation: PointsOperationType
  title: string
  description: string
}) => {
  let TitleElement = null
  switch (operation) {
    case pointsOperationTypes.reward:
      TitleElement = () => (
        <Text
          fontSize={15}
          lineHeight={20}
          fontFamily={'Inter-Regular'}
          fontWeight="400"
          _light={{
            color: theme.colors.textLightMode[900]
          }}
          _dark={{
            color: theme.colors.textDarkMode[300]
          }}
        >
          {title}
        </Text>
      )
      break

    case pointsOperationTypes.redeem:
      TitleElement = () => (
        <>
          <Text
            fontSize={15}
            lineHeight={20}
            fontFamily={'Inter-Regular'}
            fontWeight="600"
            _light={{
              color: theme.colors.textLightMode[900]
            }}
            _dark={{
              color: theme.colors.textDarkMode[300]
            }}
          >
            {title}
          </Text>
          {description === '' && (
            <Text
              fontSize={13}
              lineHeight={18}
              fontFamily={'Inter-Regular'}
              fontWeight="400"
              _light={{
                color: theme.colors.lightBlue[600]
              }}
              _dark={{
                color: theme.colors.textDarkMode[100]
              }}
            >
              description
            </Text>
          )}
        </>
      )
      break
    case pointsOperationTypes.giftIn:
    case pointsOperationTypes.giftOut:
      TitleElement = () => (
        <>
          <Text
            fontSize={15}
            lineHeight={20}
            fontFamily={'Inter-Regular'}
            fontWeight="600"
            _light={{
              color: theme.colors.lightBlue[600]
            }}
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
          >
            {title}
          </Text>
          {description !== '' && (
            <Text
              fontSize={13}
              lineHeight={18}
              fontFamily={'Inter-Regular'}
              fontWeight="400"
              _light={{
                color: theme.colors.textLightMode[600]
              }}
              _dark={{
                color: theme.colors.textDarkMode[300]
              }}
            >
              description
            </Text>
          )}
        </>
      )
      break

    default:
      break
  }
  return TitleElement && TitleElement()
}

type OperationSettings = Record<
  'default' | 'positive' | 'negative',
  {
    color: {
      light: string
      dark: string
    }
    symbol: string
  }
>
const operationSettings: OperationSettings = {
  default: {
    color: {
      light: theme.colors.textLightMode[300],
      dark: theme.colors.textDarkMode[900]
    },
    symbol: ''
  },
  positive: {
    color: {
      light: theme.colors.systemColorsLight.green,
      dark: theme.colors.systemColorsDark.green
    },
    symbol: '+'
  },
  negative: {
    color: {
      light: theme.colors.systemColorsLight.red,
      dark: theme.colors.systemColorsDark.red
    },
    symbol: '-'
  }
}

const TransactionPoint = ({
  operation,
  title,
  description,
  amount,
  timeAgo,
  image
}: {
  operation: PointsOperationType
  title: string
  description: string
  amount: string
  timeAgo: string
  image: string
}) => {
  let currentSettings = operationSettings.default
  if (operation === pointsOperationTypes.reward || operation === pointsOperationTypes.giftIn) {
    currentSettings = operationSettings.positive
  } else if (
    operation === pointsOperationTypes.redeem ||
    operation === pointsOperationTypes.giftOut
  ) {
    currentSettings = operationSettings.negative
  }

  return (
    <TransactionItem>
      <Avatar size={44} borderWidth={image.length === 0 ? 1 : 0} url={image} name={title} />
      <Box flex={1} px={2} justifyContent={'center'}>
        <PointContent operation={operation} title={title} description={description} />
      </Box>
      <Box
        flexDirection={'column'}
        alignSelf={'stretch'}
        justifyContent={'space-between'}
        alignItems={'flex-end'}
      >
        <Text
          fontSize={16}
          lineHeight={22}
          fontFamily={'Inter-Regular'}
          fontWeight="600"
          _light={{
            color: currentSettings.color.light
          }}
          _dark={{
            color: currentSettings.color.dark
          }}
        >
          {`${currentSettings.symbol}${amount}`}
        </Text>
        <Text
          fontSize={12}
          lineHeight={16}
          fontFamily={'Inter-Regular'}
          fontWeight="500"
          _light={{
            color: theme.colors.textLightMode[300]
          }}
          _dark={{
            color: theme.colors.textDarkMode[600]
          }}
        >
          {getDate(timeAgo)}
        </Text>
      </Box>
    </TransactionItem>
  )
}

const TransactionItem = ({ children }: React.PropsWithChildren) => (
  <Box flexDirection="row" py={3} px={4} marginY={1}>
    {children}
  </Box>
)

export { pointsOperationTypes, TransactionCrypto, TransactionPoint, transactionTypes }
export type {
  CoinsOperationType,
  ItemCoins,
  ItemPoints,
  ItemType,
  PointsOperationType,
  TransactionType
}

/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { MintStatsModel, MintStatsModelType } from "./MintStatsModel"
import { MintStatsModelSelector } from "./MintStatsModel.base"
import { RootStoreType } from "./index"


/**
 * AuctionHouseBase
 * auto generated base class for the model AuctionHouseModel.
 */
export const AuctionHouseModelBase = ModelBase
  .named('AuctionHouse')
  .props({
    __typename: types.optional(types.literal("AuctionHouse"), "AuctionHouse"),
    stats: types.union(types.undefined, types.null, types.late((): any => MintStatsModel)),
    address: types.union(types.undefined, types.string),
    treasuryMint: types.union(types.undefined, types.string),
    auctionHouseTreasury: types.union(types.undefined, types.string),
    treasuryWithdrawalDestination: types.union(types.undefined, types.string),
    feeWithdrawalDestination: types.union(types.undefined, types.string),
    authority: types.union(types.undefined, types.string),
    creator: types.union(types.undefined, types.string),
    bump: types.union(types.undefined, types.integer),
    treasuryBump: types.union(types.undefined, types.integer),
    feePayerBump: types.union(types.undefined, types.integer),
    sellerFeeBasisPoints: types.union(types.undefined, types.integer),
    requiresSignOff: types.union(types.undefined, types.boolean),
    canChangeSalePrice: types.union(types.undefined, types.boolean),
    auctionHouseFeeAccount: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class AuctionHouseModelSelector extends QueryBuilder {
  get address() { return this.__attr(`address`) }
  get treasuryMint() { return this.__attr(`treasuryMint`) }
  get auctionHouseTreasury() { return this.__attr(`auctionHouseTreasury`) }
  get treasuryWithdrawalDestination() { return this.__attr(`treasuryWithdrawalDestination`) }
  get feeWithdrawalDestination() { return this.__attr(`feeWithdrawalDestination`) }
  get authority() { return this.__attr(`authority`) }
  get creator() { return this.__attr(`creator`) }
  get bump() { return this.__attr(`bump`) }
  get treasuryBump() { return this.__attr(`treasuryBump`) }
  get feePayerBump() { return this.__attr(`feePayerBump`) }
  get sellerFeeBasisPoints() { return this.__attr(`sellerFeeBasisPoints`) }
  get requiresSignOff() { return this.__attr(`requiresSignOff`) }
  get canChangeSalePrice() { return this.__attr(`canChangeSalePrice`) }
  get auctionHouseFeeAccount() { return this.__attr(`auctionHouseFeeAccount`) }
  stats(builder: string | MintStatsModelSelector | ((selector: MintStatsModelSelector) => MintStatsModelSelector) | undefined) { return this.__child(`stats`, MintStatsModelSelector, builder) }
}
export function selectFromAuctionHouse() {
  return new AuctionHouseModelSelector()
}

export const auctionHouseModelPrimitives = selectFromAuctionHouse().address.treasuryMint.auctionHouseTreasury.treasuryWithdrawalDestination.feeWithdrawalDestination.authority.creator.bump.treasuryBump.feePayerBump.sellerFeeBasisPoints.requiresSignOff.canChangeSalePrice.auctionHouseFeeAccount

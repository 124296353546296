import { useCallback, useEffect, useMemo, useState } from 'react'
import { Platform } from 'react-native'
import { useLinkTo } from '@react-navigation/native'
import { Sentry } from '@vatom/sdk/core'
import {
  SDKQueryClient,
  SDKQueryClientPersister,
  useConfig,
  useIsAuthed,
  useSDK
} from '@vatom/sdk/react'
// import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import * as AuthSession from 'expo-auth-session'

import { useStore } from '../../models'
import { navigationRef } from '../../navigators'
import { useTabNavConfig } from '../../screens/Home/hooks/useTabNavConfig'

// import { AppNavigation, AppRoutes } from '../../navigators'
import SDKHandlers from './SDKHandlers'
import { VatomWallet } from '.'

export const useWalletSdkBusiness = () => {
  const isEmbedded = VatomWallet.isEmbedded()
  const businessId = VatomWallet.getBusinessId()

  return { isEmbedded, businessId }
}

// export const useWalletSdkConfig = () => {
//   const isEmbedded = useVatomWalletSdkStore.getState().isEmbedded
//   const pageConfig = useVatomWalletSdkStore.getState().config?.pageConfig ?? {}
//   const SDKNavigationHidden = useVatomWalletSdkStore.getState().config?.hideNavigation
//   const SDKDrawerHidden = useVatomWalletSdkStore.getState().config?.hideDrawer
//   const hideTokenActions = useVatomWalletSdkStore.getState().config?.hideTokenActions
//   const tabConfigs = useVatomWalletSdkStore.getState().config?.visibleTabs ?? []
//   const scannerConfig = useVatomWalletSdkStore.getState().config?.scanner ?? { enabled: false }
//   const mapStyles = useVatomWalletSdkStore.getState().config?.mapStyle
//   const SDKdisableArPickup = useVatomWalletSdkStore.getState().config?.disableArPickup
//   const SDKMaxDistanceForPickup =
//     useVatomWalletSdkStore.getState().config?.pageConfig?.features?.maxDistanceForPickup

//   // const isEmbedded = VatomWallet.isEmbedded()
//   // const pageConfig = isEmbedded ? VatomWallet.getSDKPageConfig() : {}
//   // const SDKNavigationHidden = VatomWallet.SDKNavigationHidden()
//   // const SDKDrawerHidden = VatomWallet.SDKDrawerHidden()
//   // const hideTokenActions = VatomWallet.tokenActionsDisabled()
//   // const tabConfigs = isEmbedded ? VatomWallet.getTabConfigs() : []
//   // const scannerConfig = isEmbedded ? VatomWallet.getScannerConfig() : ({} as { enabled: boolean })
//   // const mapStyles = VatomWallet.getMapStyles()
//   // const SDKdisableArPickup = VatomWallet.SDKdisableArPickup()
//   // const SDKMaxDistanceForPickup = VatomWallet.SDKMaxDistanceForPickup()

//   return {
//     isEmbedded,
//     pageConfig,
//     SDKNavigationHidden,
//     SDKDrawerHidden,
//     hideTokenActions,
//     tabConfigs,
//     scannerConfig,
//     mapStyles,
//     SDKdisableArPickup,
//     SDKMaxDistanceForPickup
//   }
// }

const isWeb = Platform.OS === 'web'

export const useSDKHandlers = () => {
  const sdk = useSDK()
  const linkTo = useLinkTo()
  // const navigation = useNavigation()
  const navigation = navigationRef

  const businessTab = useTabNavConfig()

  const isLoggedIn = useIsAuthed()
  const store = useStore()
  const config = useConfig()

  const { clientId, scopes, discoveryUrl, useProxy, redirectUri } = config.authentication

  const getDiscovery = useCallback(async () => {
    return await AuthSession.resolveDiscoveryAsync(discoveryUrl)
  }, [discoveryUrl])

  const session = sdk.dataPool.sessionStore.vatomIncSessionToken

  const postLogoutRedirectUri = isWeb
    ? `${window.location.origin}/logout-callback`
    : 'com.vatom://logout-callback'

  const authRequest = useMemo(() => {
    return new AuthSession.AuthRequest({
      clientId,
      redirectUri,
      // id_token will return a JWT token
      responseType: AuthSession.ResponseType.Code,
      prompt: AuthSession.Prompt.Consent,
      // prompt: AuthSession.Prompt.Login,
      // responseType: AuthSession.ResponseType.Token,
      scopes,
      extraParams: {
        post_logout_redirect_uri: postLogoutRedirectUri,
        id_token_hint: session?.idToken ?? ''
      }
    })
  }, [clientId, postLogoutRedirectUri, redirectUri, scopes, session?.idToken])

  const clearSdkAuth = useCallback(() => {
    sdk.dataPool.user.logout()
    sdk.dataPool.user.clearCache()
    sdk.service.setToken(undefined)
    sdk.dataPool.sessionStore.clear()
  }, [sdk.dataPool.sessionStore, sdk.dataPool.user, sdk.service])

  const logoutOrDisconnect = useCallback(async () => {
    console.log('[ProfileUser.logoutOrDisconnect] isLoggedIn', isLoggedIn)
    try {
      if (isLoggedIn) {
        const discovery = await getDiscovery()
        if (Platform.OS === 'android') {
          await authRequest.promptAsync(discovery, {
            windowFeatures: {
              useProxy,
              createTask: false
            }
          })
        }
        const requestUrl = await authRequest.makeAuthUrlAsync({
          authorizationEndpoint: encodeURI(`https://id.vatom.com/session/end`)
        })
        if (isWeb && requestUrl) {
          window.location.replace(requestUrl)
        }
      }
    } catch (error) {
      console.error('[SDKHandlers.logoutOrDisconnect] error', error)
      const logError = JSON.stringify(error, null, 2) ?? error
      Sentry.captureError(new Error(`SDKHandlers.logoutOrDisconnect: ${logError}`))
    } finally {
      if (!isWeb) {
        // Log out of the user store
        AuthSession.dismiss()
        store.linking.clearLinkingUrl()
        clearSdkAuth()
        SDKQueryClient.clear()
        SDKQueryClientPersister.removeClient()

        if (Platform.OS === 'web') {
          localStorage.clear()
        }
      }
    }
  }, [isLoggedIn, getDiscovery, authRequest, useProxy, store.linking, clearSdkAuth])

  // const validateTokens = useCallback(() => {
  //   if (isEmbedded) {
  //     const tokenSessionStorage = sessionStorage.getItem('VATOM_ACCESS_TOKEN') || 'false'
  //     const currentToken = localStorage.getItem('VATOM_ACCESS_TOKEN_CURRENT') || 'true'
  //     const isNewToken = currentToken !== tokenSessionStorage

  //     if (!isLoggedIn) {
  //       localStorage.setItem('VATOM_ACCESS_TOKEN_CURRENT', tokenSessionStorage)
  //       return
  //     }

  //     if (isNewToken && isLoggedIn) {
  //       localStorage.setItem('VATOM_ACCESS_TOKEN_CURRENT', tokenSessionStorage)
  //       logoutOrDisconnect()
  //       return
  //     }
  //   }
  // }, [isEmbedded, isLoggedIn, logoutOrDisconnect])

  // useEffect(() => {
  //   validateTokens()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    SDKHandlers.setLinkTo(linkTo)
  }, [linkTo])

  useMemo(() => {
    if (sdk) {
      if (config.features.screensConfig?.Wallet?.inventoryFilter) {
        sdk.nftFilter.setFilterBy(config.features.screensConfig?.Wallet?.inventoryFilter)
      }

      const businessTabsConfig = {
        tabsToRender: businessTab.tabsToRender
      }
      SDKHandlers.setRootStore({ sdk, navigation, businessTabsConfig, logoutOrDisconnect })
    }
  }, [
    businessTab.tabsToRender,
    config.features.screensConfig?.Wallet?.inventoryFilter,
    logoutOrDisconnect,
    navigation,
    sdk
  ])
}

export const useWalletSdkAuth = () => {
  const isEmbedded = VatomWallet.isEmbedded()
  const [tokenResponse, setTokenResponse] = useState<AuthSession.TokenResponse | undefined>(
    undefined
  )
  const [isLoading, setIsLoading] = useState(isEmbedded)

  useEffect(() => {
    const embeddedLogin = async (e?: unknown) => {
      setIsLoading(true)
      try {
        const token = await VatomWallet.getVatomAccessToken()
        if (token) {
          setTokenResponse(token)
        }
        setIsLoading(false)
      } catch (error) {
        console.log('useWalletSdkAuth Error: ', error)
      } finally {
        if (e) {
          setIsLoading(false)
        }
      }
    }
    if (Platform.OS === 'web') {
      window.addEventListener('vatomwallet:loaded', embeddedLogin)
    }

    embeddedLogin()
    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('vatomwallet:loaded', embeddedLogin)
      }
    }
  }, [])

  return {
    accessToken: tokenResponse,
    isLoading,
    isEmbedded
  }
}

import React from 'react'
import DateTimePicker from 'react-datetime-picker'
import {
  Animated,
  Dimensions,
  Modal,
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
  View
} from 'react-native'
import { observer } from 'mobx-react-lite'

import CloseIcon from '../../../../assets/header-icons/close-icon.svg'
import { getCombinedDateTimestamp, getTimeDifference } from '../../../../helpers'
import { useCommunitiesTheme } from '../../../../themes'
import ArrowRightIcon from '../../../modal-components/ArrowRightIcon'
import ModalText from '../../../modal-components/ModalText'
import {
  closeAnimation,
  ModalAnimatedWrapper,
  ModalGreyBackground,
  ModalHeaderWrapper,
  ModalSaveButton,
  ModalTitle,
  openAnimation
} from '../../../modalHelpers'
import { useStoreContext } from '../../../MSTContextProvider'
import PollLengthIcon from '../../assets/poll_length_icon.svg'
import { DecorationData } from '../ModalDecorations'
import ModalDecorationWrapper from '../ModalDecorationWrapper'
import ModalError from '../ModalError'

import { ModalRow, ModalRowText, SelectedValueText } from './components'

import './DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'

const ModalPollLength = observer(({ decorationData }: { decorationData: DecorationData }) => {
  const store = useStoreContext()
  const [isModalVisible, setIsModalVisible] = React.useState(false)

  React.useEffect(() => {
    store.addAdditionalInputs(decorationData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dateInput = store.additionalInputs.find(input => input.type === decorationData.type)

  const [date, setDate] = React.useState(new Date())
  const [time, setTime] = React.useState(new Date())
  const [showError, setShowError] = React.useState(false)
  const [errorData, setErrorData] = React.useState({
    errorTitle: '',
    errorDescription: ''
  })

  const fadeAnim = React.useRef(new Animated.Value(0)).current
  const slideAnim = React.useRef(new Animated.Value(1000)).current
  const animationDuration = 300

  const communitiesTheme = useCommunitiesTheme()

  const openModal = () => {
    setIsModalVisible(true)
    openAnimation(fadeAnim, slideAnim, animationDuration).start()
  }

  const closeModal = () => {
    closeAnimation(fadeAnim, slideAnim, animationDuration).start(() => {
      setIsModalVisible(false)
    })
  }

  const handleDone = () => {
    const timestamp = getCombinedDateTimestamp(date, time)

    if (dateInput) {
      dateInput.setValue(timestamp)
    } else {
      handleError()
    }
    closeModal()
  }

  const onDateChange = (date?: Date) => {
    if (date) {
      setDate(date)
    }
  }

  const onTimeChange = (date?: Date) => {
    if (date) {
      setTime(date)
    }
  }

  const handleError = () => {
    setShowError(true)
    setErrorData({
      errorTitle: 'Error',
      errorDescription: 'You cannot select a smaller date than the current one'
    })
  }

  const { height } = Dimensions.get('window')

  return (
    <>
      <ModalError
        showError={showError}
        errorTitle={errorData.errorTitle}
        errorDescription={errorData.errorDescription}
        setShowError={setShowError}
      />

      <ModalDecorationWrapper handler={() => openModal()}>
        <View style={styles.contentHolder}>
          <PollLengthIcon
            width="20"
            style={{ marginRight: 12 }}
            fill={communitiesTheme.labelIconColor}
          />
          <ModalText text={decorationData.placeholder} />
        </View>
        <View style={styles.contentHolder}>
          <SelectedValueText
            value={dateInput?.value ? getTimeDifference(dateInput.value as number, true) : null}
          />
          <ArrowRightIcon style={{ marginLeft: 12 }} />
        </View>
      </ModalDecorationWrapper>

      <Modal visible={isModalVisible && !showError} transparent={true} animationType="none">
        <ModalGreyBackground fadeAnim={fadeAnim} handler={closeModal} />

        <ModalAnimatedWrapper slideAnim={slideAnim} style={{ height: height - 50, paddingTop: 19 }}>
          <ModalHeaderWrapper>
            <TouchableWithoutFeedback accessibilityRole="button" onPress={() => closeModal()}>
              <View>
                <CloseIcon fill={communitiesTheme.confirmationModal.title} width={20} height={20} />
              </View>
            </TouchableWithoutFeedback>

            <ModalTitle placeholder={decorationData.placeholder} />

            <ModalSaveButton handler={handleDone} text="Done" />
          </ModalHeaderWrapper>

          <ScrollView style={{ paddingVertical: 16 }}>
            <ModalRow
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <ModalRowText text="Select End Date" />
              <DateTimePicker
                onChange={value => {
                  onDateChange(value as Date)
                  onTimeChange(value as Date)
                }}
                value={date}
                isCalendarOpen
                isClockOpen
                minDate={new Date()}
                className={communitiesTheme.name}
              />
            </ModalRow>
          </ScrollView>
        </ModalAnimatedWrapper>
      </Modal>
    </>
  )
})

export default ModalPollLength

const styles = StyleSheet.create({
  text: {
    color: '#3F4A55',
    fontSize: 15,
    lineHeight: 20
  },
  contentHolder: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  animatedContainer: {
    position: 'absolute',
    bottom: 0,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingTop: 19,
    width: '100%',
    zIndex: 2,
    flex: 1
  },
  row: {
    paddingVertical: 14,
    borderBottomWidth: 1,
    paddingHorizontal: 16
  },
  flexRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  rowText: {
    fontSize: 15,
    lineHeight: 20
  }
})

import { useCallback, useEffect, useMemo, useState } from 'react'
import { Linking, Platform } from 'react-native'
import { CommonActions, useNavigation } from '@react-navigation/native'
import {
  AnalyticsPayload,
  BusinessType,
  IPostMessage,
  MessageTypes,
  navigateTap,
  PostHeaderMessagePayload,
  PublicTokenType,
  SpacePayload,
  tabRoute,
  UserType,
  useUser
} from '@vatom/experience-sdk'
import { BusinessSnapshot } from '@vatom/sdk/core'
import {
  useAccessToken,
  useBusinessInventory,
  useCampaignUserInfo,
  useConfig,
  useImperativeCampaignPoints,
  useInitialQsStore,
  useSDK,
  useSpace,
  useUserInfo
} from '@vatom/sdk/react'
import { Status, Toast } from '@vatom/wombo'
import { match } from 'path-to-regexp'

import { useBusinessAnalytics } from '../../../../hooks/useBusinessAnalytics'
import { useBusinessSelector } from '../../../../hooks/useBusinessSelector'
import { useLayoutScrollHandler } from '../../../../hooks/useLayoutScrollHandler'
import { AppRoutes, TabRoutes } from '../../../../navigators'
import { useAquireMutation } from '../../../Acquire'
import { extractTokenIdFromAcquireLink } from '../../../FindToken'
import { useTabNavConfig } from '../../../Home/hooks/useTabNavConfig'
import Share, { ShareOptions } from '../../Share'
import { MessageNativeEvent } from '../types'

import { useSiteHeader } from './useSiteHeader'

type WebBusinessProviderProps = {
  sendMessage: <T extends MessageTypes>(message: IPostMessage<T>) => void
}

export const useWebBusinessProviderBase = ({ sendMessage }: WebBusinessProviderProps) => {
  const [isBackButtonSubscribed, setIsBackButtonSubscribed] = useState(false)
  const [history, setHistory] = useState<MessageNativeEvent<MessageTypes> | null>(null)
  const whiteList = useConfig({
    select: state => state.experienceSDKApiWhiteList ?? []
  })
  const navigation = useNavigation()
  const aquireMutation = useAquireMutation()

  const { onAnalyticsBusiness } = useBusinessAnalytics()

  const { business } = useBusinessSelector()

  const { tokens: businessTokens, isLoading: areTokensLoading } = useBusinessInventory(business?.id)

  const user = useUser()
  const accessToken = useAccessToken()
  const { campaignUserInfo, fetchCampaignUserInfo } = useCampaignUserInfo(business)
  const { userInfo, fetchUserInfo } = useUserInfo(business)
  const sdk = useSDK()

  const updateUserCampaignInfo = useCallback(
    async (payload: any) => {
      if (accessToken && business) {
        await sdk.vatomIncApi.updateUserCampaignInfo(
          payload,
          business as unknown as BusinessSnapshot,
          accessToken
        )
        await fetchCampaignUserInfo()
      }
    },
    [accessToken, business, fetchCampaignUserInfo, sdk.vatomIncApi]
  )

  const onShare = async (options: ShareOptions) => {
    const response = await Share.open(options)
    return response
  }

  const onAnalytics = (p: AnalyticsPayload) => {
    const { type, payload } = p
    const businessId = business?.id ?? ''
    const campaignId = business?.defaultCampaignId ?? ''
    onAnalyticsBusiness(type, { businessId, campaignId, ...payload })
  }

  const { addSpaceWithAlias } = useSpace()
  const tabNavConfig = useTabNavConfig()

  const getPoints = useImperativeCampaignPoints()
  const { shouldHideElements } = useLayoutScrollHandler()

  const openSace = useCallback(
    async (alias: string) => {
      try {
        const res = await addSpaceWithAlias(alias)

        if (res) {
          navigation.navigate(AppRoutes.Metaverse, { spaceId: res.id, type: 'metaverse' })
          return res
        }
      } catch (error) {
        console.error(error)
        return false
      }
    },
    [addSpaceWithAlias, navigation]
  )

  const tokens = useMemo(() => {
    return businessTokens.map(token => ({
      tokenId: token.id,
      metadata: token.metadata,
      studioInfo: token.studioInfo,
      position: token.position
    })) as PublicTokenType[]
  }, [businessTokens])

  const sendTokens = useCallback(() => {
    if (!areTokensLoading) {
      sendMessage({
        type: 'GET_BUSINESS_TOKENS',
        payload: tokens
      })
    }
  }, [areTokensLoading, sendMessage, tokens])

  const onWebViewReady = useCallback(() => {
    sendMessage({
      type: 'GET_USER',
      payload: user as unknown as UserType
    })
    sendMessage({
      type: 'GET_BUSINESS',
      payload: business as unknown as BusinessType
    })

    sendMessage({ type: 'GET_CAMPAIGN_USER_INFO', payload: campaignUserInfo })

    sendMessage({ type: 'GET_PLATAFORM_OS_TYPE', payload: Platform.OS })
    sendMessage({ type: 'GET_USER_INFO', payload: userInfo })

    sendMessage({
      type: 'GET_BUSINESS_TABS',
      payload: tabNavConfig.tabsToRender as tabRoute[]
    })

    useInitialQsStore.setState({ initialQS: '' })
  }, [business, campaignUserInfo, sendMessage, tabNavConfig.tabsToRender, user, userInfo])

  useEffect(() => {
    sendMessage({ type: 'GET_CAMPAIGN_USER_INFO', payload: campaignUserInfo })
  }, [campaignUserInfo, sendMessage])

  useEffect(() => {
    sendTokens()
  }, [sendMessage, sendTokens])

  useEffect(() => {
    sendMessage({ type: 'GET_LAYOUT_HIDDEN', payload: shouldHideElements.value })
  }, [sendMessage, shouldHideElements.value])

  const onUserInfoCampaignUpdate = useCallback(
    async (msg: IPostMessage<'UPDATE_CAMPAIGN_USER_INFO'>) => {
      await updateUserCampaignInfo(msg.payload)
    },
    [updateUserCampaignInfo]
  )

  const onClaimToken = async (options: {
    businessId: string
    objectDefinitionId?: string
    campaignId?: string
    claimLink?: string
    sync?: boolean
  }) => {
    const { businessId, objectDefinitionId, campaignId, claimLink, sync } = options

    if (!businessId || !campaignId || !objectDefinitionId) return
    Toast({
      id: 'aquiring',
      title: `Aquiring...`,
      placement: 'top',
      status: Status.success
    })

    const shareToken = Buffer.from(
      JSON.stringify({
        objectDefinitionId,
        campaignId
      })
    ).toString('base64')

    const finalClaimLink =
      claimLink ?? `https://${businessId}.share.vatominc.com/emit/${shareToken}?json=true`
    if (finalClaimLink.includes('share.vatominc.com/emit/')) {
      const res = await fetch(finalClaimLink)
      const body = (await res.json()) as { acquireLink: string }
      const acquireLink = !sync ? body.acquireLink : `${body.acquireLink}&sync=true`
      const aquireUrl = new URL(acquireLink)
      const acquireParams = new URLSearchParams(aquireUrl.search)
      type MutationParams = Omit<
        Parameters<typeof aquireMutation.mutateAsync>[0],
        'id' | 'businessId'
      >
      const mutationParams = Object.fromEntries(acquireParams.entries()) as MutationParams
      const tokenId = extractTokenIdFromAcquireLink(aquireUrl.pathname)
      if (tokenId) {
        aquireMutation.mutate({
          id: tokenId,
          businessId: businessId,
          ...mutationParams
        })
      } else {
        console.error('Token id not found')
      }
    } else {
      console.info('Performing standard claim')
      Linking.openURL(finalClaimLink)
    }
  }

  useEffect(() => {
    return () => {
      useSiteHeader.setState({ title: '' })
    }
  }, [])

  const messageHandler = useCallback(
    (message: MessageNativeEvent<MessageTypes>) => {
      const { type } = message.data
      if (!type) {
        return
      }

      switch (type) {
        case 'WEB_VIEW_READY':
          onWebViewReady()
          break
        case 'WINDOW_SCROLL':
          // handleOnScroll(message.data.payload)
          break
        case 'HISTORY_CHANGE':
          console.log('HISTORY_CHANGE: ', message)
          setHistory(message)

          break
        case 'GET_CAMPAIGN_USER_INFO':
          sendMessage({ type: 'GET_CAMPAIGN_USER_INFO', payload: campaignUserInfo })
          break
        case 'GET_USER_INFO':
          fetchUserInfo().then(res => {
            sendMessage({ type: 'GET_USER_INFO', payload: res })
          })
          break
        case 'UPDATE_CAMPAIGN_USER_INFO': {
          onUserInfoCampaignUpdate(message.data as IPostMessage<'UPDATE_CAMPAIGN_USER_INFO'>)
          break
        }
        case 'POST_HEADER_MESSAGE': {
          const headerPayload = message.data.payload as PostHeaderMessagePayload
          const headerTitle = headerPayload.title ?? ''
          if (headerTitle !== '' && typeof headerTitle === 'string') {
            useSiteHeader.setState({ title: headerTitle })
          }
          break
        }
        case 'NAVIGATE_TO_TOKEN': {
          navigation.navigate(AppRoutes.NFTDetail_Business, {
            business: message.data.payload.businessIdOrName,
            tokenId: message.data.payload.tokenId
          })
          break
        }
        case 'NAVIGATE_TO_EXTERNAL_BROWSER': {
          Linking.openURL(message.data.payload.url)
          break
        }
        case 'GET_PLATAFORM_OS_TYPE': {
          sendMessage({ type: 'GET_PLATAFORM_OS_TYPE', payload: Platform.OS })
          break
        }
        case 'SEND_ANALYTICS': {
          const analyticsPayload = message.data.payload as AnalyticsPayload
          onAnalytics(analyticsPayload)
          break
        }
        case 'SHARE': {
          onShare(message.data.payload as IPostMessage<'SHARE'>)
            .then(response => {
              sendMessage({ type: 'SHARE_RESPONSE', payload: response })
            })
            .catch(error => {
              sendMessage({ type: 'SHARE_ERROR', payload: error })
            })
          break
        }
        case 'NATIVE_GO_BACK': {
          if (navigation.canGoBack()) {
            navigation.goBack()
          } else {
            navigation.navigate(AppRoutes.home)
          }
          break
        }
        case 'SUBSCRIBE_GO_BACK': {
          // Disabling for now. It crashes the app and is not being used
          // setIsBackButtonSubscribed(true)
          break
        }
        case 'UNSUBSCRIBE_GO_BACK': {
          // Disabling for now. It crashes the app and is not being used
          // setIsBackButtonSubscribed(false)
          break
        }
        case 'GET_BUSINESS_TOKENS': {
          sendTokens()
          break
        }
        case 'OPEN_SPACE': {
          const space = message.data.payload as SpacePayload
          openSace(space.alias)
          break
        }
        case 'CALL_SPACE': {
          const space = message.data.payload as SpacePayload
          openSace(space.alias)
          break
        }
        case 'NAVIGATE_TO_WALLET': {
          navigation.navigate(AppRoutes.BusinessProxy, {
            business: business?.id as string,
            screen: TabRoutes.Wallet
          })
          break
        }
        case 'NAVIGATE_TO_USER_PROFILE': {
          console.log('NAVIGATE_TO_USER_PROFILE: ', message.data.payload)
          navigation.navigate(AppRoutes.profileOtherUser_Business, {
            business: business?.id as string,
            userId: message.data.payload.userId,
            showSharedSpaces: true
          })
          break
        }
        case 'GET_POINTS': {
          const campaignId = message.data.payload.campaignId ?? business?.defaultCampaignId
          getPoints(campaignId)
            .then(res => {
              sendMessage({
                type: 'GET_POINTS_SUCCESS',
                payload: {
                  campaignId,
                  data: res
                }
              })
            })
            .catch(error => {
              sendMessage({
                type: 'GET_POINTS_ERROR',
                payload: {
                  campaignId,
                  error
                }
              })
            })
          break
        }

        case 'NAVIGATE_TO_CONNECT_TAB': {
          const msg = message.data.payload as navigateTap

          if (business?.id && msg.communityId && msg.spaceId) {
            navigation.navigate(AppRoutes.CommunitiesRoom, {
              business: business.id,
              spaceId: msg.spaceId,
              community: msg.communityId
            })
          } else if (business?.id && msg.spaceId) {
            navigation.navigate(AppRoutes.Room, {
              business: business.id,
              spaceId: msg.spaceId
            })
          } else if (business?.id) {
            navigation.dispatch(
              CommonActions.navigate({
                name: AppRoutes.BusinessProxy,
                params: {
                  business: business?.id,
                  screen: msg.tab
                }
              })
            )
          }

          break
        }

        case 'CLAIM_TOKEN': {
          // const { payload } = message.data as IPostMessage<'CLAIM_TOKEN'>
          const { payload } = message.data as IPostMessage<'CLAIM_TOKEN'>
          onClaimToken(payload)
          break
        }

        case 'SCAN_QR_CODE': {
          navigation.navigate(AppRoutes.CameraScreen)
          break
        }
        case 'FETCH_VATOM_API': {
          const { payload } = message.data as IPostMessage<'FETCH_VATOM_API'>
          const { url, method, headers, body, id } = payload
          const accessToken = sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken
          const isAllowed = isWhitelisted(url, method, whiteList)
          if (!isAllowed) {
            console.error('Not allowed to fetch this URL')
            sendMessage({
              type: 'FETCH_VATOM_API_ERROR',
              payload: { error: 'Not allowed to fetch this URL', id }
            })
            return
          }

          fetch(url, {
            method,
            headers: {
              ...headers,
              Authorization: `Bearer ${accessToken}`
            },
            body
          })
            .then(async res => {
              const data = await res.json()
              sendMessage({
                type: 'FETCH_VATOM_API_SUCCESS',
                payload: { data, id }
              })
            })
            .catch(error => {
              sendMessage({
                type: 'FETCH_VATOM_API_ERROR',
                payload: { error: String(error), id }
              })
            })
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return {
    history,
    isBackButtonSubscribed,
    setIsBackButtonSubscribed,
    messageHandler
  }
}

const isWhitelisted = (
  url: string,
  method: string,
  whiteList: Array<{ method: string; url: string }>
) => {
  const incommingUrl = new URL(url)

  for (const entry of whiteList) {
    const whiteListUrl = new URL(entry.url)
    const matcher = match(whiteListUrl.pathname, {
      decode: decodeURIComponent
    })

    const originMatched = whiteListUrl.origin === incommingUrl.origin
    const pathMatched = matcher(incommingUrl.pathname)
    const methodMatched = method === entry.method

    const isAllowed = originMatched && pathMatched && methodMatched

    if (isAllowed) {
      return true
    }
  }
  return false
}

import * as React from 'react'
import Svg, { ClipPath, Defs, G, LinearGradient, Path, Rect, Stop } from 'react-native-svg'

export const SolanaIcon = () => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <G clipPath="url(#clip0_16337_58216)">
      <Path
        d="M3.89866 17.3106C4.04349 17.1662 4.24263 17.082 4.45385 17.082H23.608C23.958 17.082 24.133 17.5031 23.8856 17.7497L20.1018 21.5214C19.957 21.6658 19.7578 21.75 19.5466 21.75H0.392495C0.0424828 21.75 -0.132523 21.3289 0.114899 21.0823L3.89866 17.3106Z"
        fill="url(#paint0_linear_16337_58216)"
      />
      <Path
        d="M3.89866 3.22859C4.04952 3.08422 4.24867 3 4.45385 3H23.608C23.958 3 24.133 3.42108 23.8856 3.66771L20.1018 7.43936C19.957 7.58373 19.7578 7.66795 19.5466 7.66795H0.392495C0.0424828 7.66795 -0.132523 7.24687 0.114899 7.00024L3.89866 3.22859Z"
        fill="url(#paint1_linear_16337_58216)"
      />
      <Path
        d="M20.1018 10.2244C19.957 10.0801 19.7578 9.99585 19.5466 9.99585H0.392495C0.0424828 9.99585 -0.132523 10.4169 0.114899 10.6636L3.89866 14.4352C4.04349 14.5796 4.24263 14.6638 4.45385 14.6638H23.608C23.958 14.6638 24.133 14.2427 23.8856 13.9961L20.1018 10.2244Z"
        fill="url(#paint2_linear_16337_58216)"
      />
    </G>
    <Defs>
      <LinearGradient
        id="paint0_linear_16337_58216"
        x1={21.7782}
        y1={0.746893}
        x2={8.58863}
        y2={26.0913}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#00FFA3" />
        <Stop offset={1} stopColor="#DC1FFF" />
      </LinearGradient>
      <LinearGradient
        id="paint1_linear_16337_58216"
        x1={15.9819}
        y1={-2.26957}
        x2={2.79231}
        y2={23.0748}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#00FFA3" />
        <Stop offset={1} stopColor="#DC1FFF" />
      </LinearGradient>
      <LinearGradient
        id="paint2_linear_16337_58216"
        x1={18.8616}
        y1={-0.770991}
        x2={5.67202}
        y2={24.5734}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#00FFA3" />
        <Stop offset={1} stopColor="#DC1FFF" />
      </LinearGradient>
      <ClipPath id="clip0_16337_58216">
        <Rect width={24} height={18.75} fill="white" transform="translate(0 3)" />
      </ClipPath>
    </Defs>
  </Svg>
)

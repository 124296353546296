import { create } from 'zustand'

type WalletDetails = {
  seeds: string
  address: string
  n?: number
  setWalletDetails: (address: string, seeds: string, n?: number) => void
}

export const useWalletDetails = create<WalletDetails>(set => ({
  seeds: '',
  address: '',
  setWalletDetails: (address: string, seeds: string, n?: number) =>
    set(() => {
      return {
        address,
        seeds,
        n
      }
    })
}))

import AsyncStorage from '@react-native-async-storage/async-storage'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type FirstTimeStore = {
  wasOpened: string[]
  setWasOpened: (id: string) => void
  getIsFirstTime: (id: string) => boolean
}
const useFirstTimeStore = create(
  persist<FirstTimeStore>(
    (set, get) => ({
      wasOpened: [],
      setWasOpened: (id: string) =>
        set(state => {
          if (!state.wasOpened.includes(id)) {
            state.wasOpened.push(id)
          }
          return state
        }),
      getIsFirstTime: (id: string) => {
        const isFirstTime = !get().wasOpened.includes(id)
        return isFirstTime
      }
    }),
    {
      name: 'firstTime-storage',
      getStorage: () => AsyncStorage,
      version: 1
    }
  )
)

export { useFirstTimeStore }

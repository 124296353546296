import { Text, theme } from '@vatom/wombo'
import { Center, HStack } from 'native-base'

import { SolanaIcon } from '../../../components/Icons'

export const SubTitleSolana = (props: React.ComponentProps<typeof HStack>) => {
  return (
    <HStack alignItems={'center'} {...props}>
      <Center size={8}>
        <SolanaIcon />
      </Center>
      <Text
        fontSize={16}
        lineHeight={22}
        fontFamily={'Inter-SemiBold'}
        letterSpacing={0.4}
        _light={{
          color: theme.colors.textLightMode[600]
        }}
        _dark={{
          color: theme.colors.textDarkMode[300]
        }}
        marginLeft={2}
      >
        Solana Wallets
      </Text>
    </HStack>
  )
}

import React, { useCallback, useEffect } from 'react'
import { Platform } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { useInitialQsStore } from '@vatom/sdk/react'
import { observer } from 'mobx-react-lite'
import { View } from 'native-base'

import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { useLayoutScrollHandler } from '../../../hooks/useLayoutScrollHandler'
import { useWebView } from '../WebBusinessProvider'
import { ThemeInterface } from '../WebBusinessProvider/types'

import WebViewBase from './WebViewBase'

type WebViewProps = {
  shouldRenderHeader?: boolean
  link: string
}

export default observer((props: WebViewProps) => {
  const { link = '' } = props
  const { setWebviewRef, host, onMessage, setIsBackButtonSubscribed } = useWebView()
  const { getHeaderHeight, getFooterHeight } = useLayoutScrollHandler()
  const { brandTheme, pageTheme } = useBusinessTheme()

  const businessTheme = {
    background: brandTheme?.background ?? pageTheme.background
  }

  const onBack = useCallback(() => {
    return () => {
      setIsBackButtonSubscribed(false)
    }
  }, [setIsBackButtonSubscribed])

  useFocusEffect(onBack)

  const baseUri = link.startsWith('https') ? link : `${host}${link}`
  // const baseUri = 'http://wallet.localhost:3001/'
  // console.log('launching experience', uri)
  const qs = useInitialQsStore(state => state.qsForExperienceSDK)
  const uri = `${baseUri}?${qs}`

  // console.log('[Webview] uri:', uri)

  const headerHeight = getHeaderHeight()
  const footerHeight = getFooterHeight()
  const isWeb = Platform.OS === 'web'

  return (
    <View
      style={{
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: businessTheme.background,
        justifyContent: Platform.OS === 'web' ? 'center' : undefined,
        alignItems: Platform.OS === 'web' ? 'center' : undefined,
        paddingTop: isWeb ? headerHeight : 0
      }}
    >
      <WebViewBase
        businessTheme={businessTheme as ThemeInterface}
        setWebviewRef={setWebviewRef}
        onMessage={onMessage}
        uri={uri}
        containerStyle={{
          backgroundColor: businessTheme.background,
          paddingBottom: footerHeight
        }}
        injectedJavaScript={`var body = document.getElementsByTagName("body"); body[0].style.paddingTop = "${headerHeight}px"; body[0].style.backgroundColor = "${businessTheme.background}";`}
      />
    </View>
  )
})

import React, { useState } from 'react'
import {
  Contact,
  useRelFollowContact,
  useRelGetFollowing,
  useRelUnfollowContact,
  useUser
} from '@vatom/sdk/react'
import { Avatar, Text } from '@vatom/wombo'
import { Box, Pressable } from 'native-base'

export interface IItemProps {
  item: Contact
}

export const ItemFollowPeople = ({ item }: IItemProps) => {
  const user = useUser()
  const followMutation = useRelFollowContact()
  const unfollowMutation = useRelUnfollowContact()
  const [pendingUnfollow, setPendingUnfollow] = useState(false)

  const following = useRelGetFollowing({
    select: contacts => {
      return contacts.find(c => c.value === item.id)
    },
    onSuccess: contact => {
      if (contact && pendingUnfollow) {
        setPendingUnfollow(false)
        unfollowMutation.mutate(contact)
      }
    }
  })

  const followContact = () => {
    followMutation.mutate({
      type: 'user',
      value: item.id
    })
  }

  const unfollowContact = async () => {
    unfollowMutation.mutate(following.data)
    if (!following.data?.key) {
      setPendingUnfollow(true)
    }
  }

  const FollowButton = () => {
    if (following.data) {
      return (
        <Pressable
          accessibilityRole="button"
          alignItems="center"
          justifyContent="center"
          onPress={unfollowContact}
          borderColor="extraLightText"
          bgColor="extraLightText"
          borderWidth={1}
          w="80px"
          h="32px"
        >
          <Text textTransform="capitalize" fontSize={12} color="black" tx="common.following" />
        </Pressable>
      )
    }

    return (
      <Pressable
        accessibilityRole="button"
        alignItems="center"
        justifyContent="center"
        onPress={followContact}
        borderColor="extraLightText"
        borderWidth={1}
        w="80px"
        h="32px"
        borderRadius="3px"
      >
        <Text textTransform="capitalize" fontSize={12} color="black" tx="common.follow" />
      </Pressable>
    )
  }

  return (
    <Box
      alignItems="center"
      borderBottomWidth={1}
      borderColor="gray.50"
      flexDir="row"
      h={62}
      mx={4}
      py={3}
    >
      <Avatar size={44} url={item.avatarUrl} />
      <Box ml={3} flex={1}>
        <Text
          color="darkText"
          fontFamily="Inter-Bold"
          fontSize={15}
          ellipsizeMode="tail"
          w="95%"
          noOfLines={1}
        >
          {item.name ?? item.id}
        </Text>

        <Text
          fontFamily="Inter-Bold"
          fontSize={11}
          fontWeight={400}
          lineHeight={15}
          ellipsizeMode="tail"
          w="95%"
          noOfLines={1}
        >
          {item.preferred_username ? `@${item.preferred_username}` : item.value}
        </Text>
      </Box>
      {user?.sub !== item.value ? <FollowButton /> : null}
    </Box>
  )
}

export default ItemFollowPeople

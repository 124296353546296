import { create } from 'zustand'

type ReplyStore = {
  eventId: string | undefined
  setReplyEventId: (eventId: string) => void
  clearReplyEventId: () => void
}
export const useReplyStore = create<ReplyStore>(set => ({
  eventId: undefined,
  setReplyEventId: (eventId: string) => set(() => ({ eventId: eventId })),
  clearReplyEventId: () => set(() => ({ eventId: undefined }))
}))

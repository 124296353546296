/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { BidModel, BidModelType } from './BidModel'
import { BidModelSelector } from './BidModel.base'
import { ConnectionCountsModel, ConnectionCountsModelType } from './ConnectionCountsModel'
import { ConnectionCountsModelSelector } from './ConnectionCountsModel.base'
import { TwitterProfileModel, TwitterProfileModelType } from './TwitterProfileModel'
import { TwitterProfileModelSelector } from './TwitterProfileModel.base'
import { WalletNftCountModel, WalletNftCountModelType } from './WalletNftCountModel'
import { WalletNftCountModelSelector } from './WalletNftCountModel.base'
import { RootStoreType } from './index'

/**
 * WalletBase
 * auto generated base class for the model WalletModel.
 */
export const WalletModelBase = ModelBase.named('Wallet')
  .props({
    __typename: types.optional(types.literal('Wallet'), 'Wallet'),
    address: types.union(types.undefined, types.frozen()),
    twitterHandle: types.union(types.undefined, types.null, types.string),
    bids: types.union(types.undefined, types.array(types.late((): any => BidModel))),
    profile: types.union(
      types.undefined,
      types.null,
      types.late((): any => TwitterProfileModel)
    ),
    connectionCounts: types.union(
      types.undefined,
      types.late((): any => ConnectionCountsModel)
    ),
    nftCounts: types.union(
      types.undefined,
      types.late((): any => WalletNftCountModel)
    )
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class WalletModelSelector extends QueryBuilder {
  get address() {
    return this.__attr(`address`)
  }
  get twitterHandle() {
    return this.__attr(`twitterHandle`)
  }
  bids(
    builder:
      | string
      | BidModelSelector
      | ((selector: BidModelSelector) => BidModelSelector)
      | undefined
  ) {
    return this.__child(`bids`, BidModelSelector, builder)
  }
  profile(
    builder:
      | string
      | TwitterProfileModelSelector
      | ((selector: TwitterProfileModelSelector) => TwitterProfileModelSelector)
      | undefined
  ) {
    return this.__child(`profile`, TwitterProfileModelSelector, builder)
  }
  connectionCounts(
    builder:
      | string
      | ConnectionCountsModelSelector
      | ((selector: ConnectionCountsModelSelector) => ConnectionCountsModelSelector)
      | undefined
  ) {
    return this.__child(`connectionCounts`, ConnectionCountsModelSelector, builder)
  }
  nftCounts(
    builder:
      | string
      | WalletNftCountModelSelector
      | ((selector: WalletNftCountModelSelector) => WalletNftCountModelSelector)
      | undefined,
    args?: { creators?: any[] }
  ) {
    return this.__child(
      `nftCounts(creators: ${JSON.stringify(args ? args['creators'] : '')})`,
      WalletNftCountModelSelector,
      builder
    )
  }
}
export function selectFromWallet() {
  return new WalletModelSelector()
}

export const walletModelPrimitives = selectFromWallet().address.twitterHandle

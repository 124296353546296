// import AsyncStorage from '@react-native-async-storage/async-storage'
import { applySnapshot, getSnapshot, Instance, SnapshotOut, types } from 'mobx-state-tree'

export enum WalletPreferenceSortBy {
  Newest = 'newest',
  Oldest = 'oldest',
  Ascending = 'ascending',
  Descending = 'descending'
}

// const WalletPreferenceStoreKey = 'WalletPreferenceStore'

export const WalletPreferenceStore = types
  .model('WalletPreferenceStore')
  .props({
    sortBy: types.optional(
      types.enumeration(Object.values(WalletPreferenceSortBy)),
      WalletPreferenceSortBy.Newest
    ),
    showTitles: types.optional(types.boolean, true),
    showEditions: types.optional(types.boolean, true),
    showBadges: types.optional(types.boolean, true),
    showDroppedSmartNft: types.optional(types.boolean, false)
  })
  .actions(self => ({
    setSortBy(sortBy: WalletPreferenceSortBy) {
      self.sortBy = sortBy
      // self.persist()
    },
    setShowTitles(show: boolean) {
      self.showTitles = show
      // self.persist()
    },
    setShowEditions(show: boolean) {
      self.showEditions = show
      // self.persist()
    },
    setShowBadges(show: boolean) {
      self.showBadges = show
      // self.persist()
    },
    setShowDroppedSmartNft(show: boolean) {
      self.showDroppedSmartNft = show
      // self.persist()
    }
  }))

export type WalletPreference = Instance<typeof WalletPreferenceStore>
export type WalletPreferenceSnaphot = SnapshotOut<typeof WalletPreferenceStore>

import React from 'react'
import { Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { translate } from '@vatom/utils'
import { theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, ScrollView, View } from 'native-base'

import { HeaderButtonBack, ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { AppRoutes, AppStackScreenProps } from '../../navigators'

import PeopleSectionFollowers from './partials/SectionPeopleFollowers'
import PeopleSectionFollowing from './partials/SectionPeopleFollowing'

// We don't want scrollviews on web they contain in position absolute
const MaybeScrollView = Platform.OS === 'web' ? View : ScrollView

export type FollowListProp = AppStackScreenProps<typeof AppRoutes.followList>

export const FollowList = observer(({ route }: FollowListProp) => {
  const navigation = useNavigation()
  const isFollowers = route.params.type === 'followers'
  const { brandTheme, pageTheme } = useBusinessTheme()
  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate(AppRoutes.profileUser)
    }
  }
  return (
    <ScreenWrapper
      screenBackgroundColor={brandTheme?.background ?? pageTheme.background}
      statusBarBackgroundColor={brandTheme?.background ?? pageTheme.background}
    >
      <ScreenHeader
        headerTextColor={theme.colors.white}
        headerProps={{
          zIndex: 0,
          backgroundColor: 'transparent'
        }}
        headerLeft={() => (
          <ScreenHeaderButton onPress={goBack}>
            <HeaderButtonBack color={theme.colors.black} />
          </ScreenHeaderButton>
        )}
        headerRight={() => <ScreenHeaderButton />}
      >
        <Box
          style={{
            alignSelf: 'stretch',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Title fontWeight="600" fontSize="17px" lineHeight="24px" color={pageTheme.active}>
            {translate(isFollowers ? 'common.followers' : 'common.following')}
          </Title>
        </Box>
      </ScreenHeader>

      <MaybeScrollView>
        {isFollowers ? (
          <PeopleSectionFollowers
            userId={route.params.userId}
            containerProps={{
              marginTop: 10
            }}
          />
        ) : (
          <PeopleSectionFollowing
            userId={route.params.userId}
            containerProps={{
              marginTop: 10
            }}
          />
        )}
      </MaybeScrollView>
    </ScreenWrapper>
  )
})

export default FollowList

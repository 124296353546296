import React from 'react'
import { View } from 'react-native'
import Animated from 'react-native-reanimated'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import {
  Community,
  SpaceInfo,
  useBusinessCommunities,
  useGetBusinessSpaces
} from '@vatom/sdk/react'
import { LoaderView } from '@vatom/wombo'

import { useBusinessSelector, withBusinessSelector } from '../../../hooks/useBusinessSelector'
import { useLayoutScrollHandler } from '../../../hooks/useLayoutScrollHandler'
import { useScrollContentSize } from '../../../hooks/useScrollContentSize'
import { AppRoutes, CommunitiesParamList, CommunitiesRoutes } from '../../../navigators'
import Header from '../components/Header'
import CategoryRoom from '../components/home-components/CategoryItem'
// import CategoryRoom from './components/CategoryItem'
import RoomItem from '../components/home-components/RoomItem'
import { useCommunitiesTheme } from '../themes'
import { useCommunitySpace } from '../useCommunitySpace'

const communitySelector = (communities: Community[]) => {
  return communities.filter(c => !c.isDefault)
}

export const CommunitiesHome = (
  props: NativeStackScreenProps<
    CommunitiesParamList,
    typeof CommunitiesRoutes.CommunitiesHome | typeof CommunitiesRoutes.Communities
  >
) => {
  const { navigation, route } = props
  const { getHeaderHeight, getFooterHeight, scrollEventThrottle, scrollHandler } =
    useLayoutScrollHandler()
  const { onContentSizeChange, scrollEnabled } = useScrollContentSize()

  const { business } = useBusinessSelector()
  const businessId = business?.id ?? ''

  const { getCommunityName, community: communityFromCtx } = useCommunitySpace()

  const community = route.params?.community ?? communityFromCtx

  const communityName = getCommunityName(community ?? businessId)
  const communitiesTheme = useCommunitiesTheme()

  const renderCommunity = ({ item }: { item: Community }) => {
    return <CategoryRoom key={item.id} community={item} />
  }

  const communities = useBusinessCommunities(businessId, {
    select: communitySelector,
    enabled: !community && !!businessId
  })

  const spaces = useGetBusinessSpaces(businessId, community ?? '', {
    select: spaces => {
      return spaces.items.filter(space => space.matrixRoomId) ?? []
    }
  })

  const shouldRenderSpaces = community || communities.data?.length === 0

  const renderSpaces = ({ item }: { item: SpaceInfo }) => {
    const space = item as SpaceInfo
    return (
      <RoomItem
        key={`room-${space.matrixRoomId}`}
        room={item}
        onPress={() => {
          // @ts-expect-error this need some love
          navigation.navigate(community ? AppRoutes.CommunitiesRoom : AppRoutes.Room, {
            spaceId: space.id,
            business: businessId,
            community: community
          })
        }}
      />
    )
  }

  return (
    <View
      style={{
        backgroundColor: communitiesTheme.background,
        height: '100%',
        flex: 1,
        flexShrink: 0
      }}
    >
      <Header title={communityName || ''} />
      {spaces.isLoading || communities.isLoading ? <LoaderView blocking={false} /> : null}

      {shouldRenderSpaces ? (
        <Animated.FlatList
          data={spaces.data}
          renderItem={renderSpaces}
          initialNumToRender={20}
          onScroll={scrollHandler}
          scrollEventThrottle={scrollEventThrottle}
          onContentSizeChange={onContentSizeChange}
          scrollEnabled={scrollEnabled}
          removeClippedSubviews
          contentContainerStyle={{
            paddingTop: getHeaderHeight(),
            paddingBottom: getFooterHeight()
          }}
        />
      ) : (
        <Animated.FlatList
          data={communities.data}
          renderItem={renderCommunity}
          initialNumToRender={20}
          onScroll={scrollHandler}
          scrollEventThrottle={scrollEventThrottle}
          onContentSizeChange={onContentSizeChange}
          scrollEnabled={scrollEnabled}
          removeClippedSubviews
          contentContainerStyle={{
            paddingTop: getHeaderHeight(),
            paddingBottom: getFooterHeight()
          }}
        />
      )}
    </View>
  )
}

export const CommunitiesHomeWithBusiness = withBusinessSelector(CommunitiesHome)
export default CommunitiesHome

import { useCallback, useEffect, useMemo } from 'react'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { throttle } from 'lodash'

type UseModalCloseHandlerOptions = {
  dismissEscape?: boolean
  onDismiss?: () => void
}

const useModalCloseHandler = (
  modalRef: React.RefObject<unknown>,
  options?: UseModalCloseHandlerOptions
) => {
  const { dismissEscape = true, onDismiss } = options || {}
  const navigation = useNavigation()
  const isFocused = useIsFocused()

  const dismissCallback = useCallback(() => {
    if (onDismiss) {
      onDismiss()
      return
    } else {
      navigation.goBack()
      return
    }
  }, [navigation, onDismiss])

  const dismissDebounce = useMemo(() => throttle(dismissCallback, 300), [dismissCallback])

  const handleClickedOutside = useCallback(
    (event: MouseEvent) => {
      if (isFocused && modalRef.current && event.target) {
        // @ts-ignore
        if (!modalRef.current?.contains(event.target)) {
          event.stopPropagation()
          dismissDebounce()
        }
      }
    },
    [dismissDebounce, isFocused, modalRef]
  )
  const handleEscPress = useCallback(
    (event: KeyboardEvent) => {
      if (dismissEscape) {
        if (isFocused && modalRef.current && event.key === 'Escape') {
          event.stopPropagation()
          dismissDebounce()
        }
      }
    },
    [dismissDebounce, dismissEscape, isFocused, modalRef]
  )

  useEffect(() => {
    return () => {
      dismissDebounce.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.addEventListener('pointerdown', handleClickedOutside)
    return () => {
      document.removeEventListener('pointerdown', handleClickedOutside)
    }
  }, [handleClickedOutside])

  useEffect(() => {
    document.addEventListener('keydown', handleEscPress)
    return () => {
      document.removeEventListener('keydown', handleEscPress)
    }
  }, [handleEscPress])
}

// const ExampleComponent = () => {
//   const myRef = useRef(null)
//   useModalCloseHandler({
//     ref: myRef
//   })

//   return (
//     <Box ref={myRef}>
//       <Text>asd</Text>
//     </Box>
//   )
// }

export { useModalCloseHandler }

import { StyleSheet } from 'react-native'
import Markdown from 'react-native-markdown-display'
import { Box, StyledProps } from 'native-base'

import { fieldPropsInterfaceItem } from './Types'

type props = fieldPropsInterfaceItem & StyledProps

export const Subtitle = (props: props) => {
  if (!props.field.subtitle) return null
  return (
    <Box {...props}>
      <Markdown style={styles}>{props?.field?.subtitle}</Markdown>
    </Box>
  )
}
const styles = StyleSheet.create({
  body: {
    color: '#868E96',
    fontSize: 13
  }
})

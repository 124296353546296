import React, { useEffect, useRef, useState } from 'react'
import { Platform, useWindowDimensions } from 'react-native'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import type { BVatomTokenType } from '@vatom/BVatom/plugin'
import { Audio, AVPlaybackStatus, ResizeMode, Video } from 'expo-av'
import { Box } from 'native-base'

import { FaceProps } from '../../FaceProvider'
import ImageFace from '../ImageFace'
import LoaderView from '../LoaderView'

import { RenderCover } from './Cover'

export * from './Cover'

function VideoFace(props: FaceProps) {
  const { token, viewMode: VM, onMessage, ...rest } = props
  const viewMode = VM || 'icon'
  const vatom = token
  const face = (vatom as BVatomTokenType).getFace(viewMode)
  const [isLoading, setIsLoading] = useState(true)
  const coverImage = token?.resources.find(r => r.name === 'CoverImage')
  const animation_url = token?.metadata.animation_url
  const { height } = useWindowDimensions()
  const ref = useRef<Video>(null)
  const isLooping =
    face?.properties?.parsedConfig.loop || token.studioInfo?.businessId === 'nQwtevgfOa'

  const playVideo = () => {
    ref.current?.playAsync()
  }

  // const pauseVideo = () => {
  //   ref.current?.pauseAsync()
  // }

  useEffect(() => {
    const enableAudio = async () => {
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: false,
        playsInSilentModeIOS: true,
        staysActiveInBackground: false,
        shouldDuckAndroid: false
      })
    }

    enableAudio()
  }, [])

  const onPlayVideo = () => {
    playVideo()
  }

  const onCoverClick = (shouldStart: boolean) => {
    onPlayVideo()
  }

  // Handler video´s states
  const onStatusChange = (videoStatus: AVPlaybackStatus) => {
    if (!videoStatus.isLoaded) {
      return
    }
    if (!videoStatus.durationMillis) {
      return
    }

    if (coverImage) {
      return
    }

    if (videoStatus.positionMillis === videoStatus.durationMillis) {
      ref.current?.setPositionAsync(0)
    }
  }

  if (viewMode === 'icon') {
    return <ImageFace token={token} viewMode={viewMode} {...rest} />
  }

  const onLoadVideo = () => {
    setIsLoading(false)
    onPlayVideo()
  }

  if (!animation_url) return null
  return (
    <>
      {Platform.OS !== 'web' && isLoading ? <LoaderView /> : null}

      {isLoading && coverImage?.url ? (
        <RenderCover url={coverImage?.url} onCoverClick={onCoverClick} />
      ) : null}

      <Box
        // @ts-expect-error 900px maxwidth is for web. Anyone who adds a new props here should remove this comment first
        style={{
          maxWidth: Platform.OS === 'web' ? '900px' : '100%',
          // maxWidth: '900px',
          maxHeight: height,
          width: '100%',
          height: '100%'
        }}
      >
        <Video
          ref={ref}
          resizeMode={ResizeMode.CONTAIN}
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            zIndex: 9
          }}
          onPlaybackStatusUpdate={onStatusChange}
          useNativeControls={true}
          onLoad={onLoadVideo}
          isLooping={isLooping}
          source={{ uri: animation_url }}
          shouldPlay={true}
        />
      </Box>
    </>
  )
}

Object.defineProperty(VideoFace, 'name', {
  writable: true,
  value: 'VideoFace'
})

export default VideoFace

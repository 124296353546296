import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import {
  FollowersResponse,
  FollowingResponse,
  useRelFollowContact,
  useRelGetFollowing,
  useRelUnfollowContact,
  useUser
} from '@vatom/sdk/react'
import { Avatar, PressableOpacity, Text } from '@vatom/wombo'
import { Box, Pressable } from 'native-base'

import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { AppRoutes } from '../../../navigators'
import { color } from '../../../theme'

export interface IItemProps {
  // image: ImageSourcePropType
  // name: string
  // usertag: string
  item: FollowersResponse | FollowingResponse
}

export const ItemFollowPeople = ({ item }: IItemProps) => {
  const { pageTheme } = useBusinessTheme()
  const user = useUser()
  const [pendingUnfollow, setPendingUnfollow] = useState(false)
  const navigation = useNavigation()
  const followMutation = useRelFollowContact()
  const unfollowMutation = useRelUnfollowContact()

  const following = useRelGetFollowing({
    select: contacts => {
      return contacts.find(c => c.value === item.value)
    },
    onSuccess: contact => {
      if (contact && pendingUnfollow) {
        setPendingUnfollow(false)
        unfollowMutation.mutate(contact)
      }
    }
  })

  const followContact = () => {
    followMutation.mutate({
      type: 'user',
      value: item.value
    })
  }

  const unfollowContact = async () => {
    unfollowMutation.mutate(following.data)
    if (!following.data?.key) {
      setPendingUnfollow(true)
    }
  }

  const openProfile = () => {
    if (item.value !== user?.sub) {
      navigation.navigate(AppRoutes.profileOtherUser, {
        userId: item.value
      })
    } else {
      navigation.navigate(AppRoutes.profileUser)
    }
  }

  const FollowButton = () => {
    return (
      <Pressable
        accessibilityRole="button"
        alignItems="center"
        justifyContent="center"
        onPress={following.data ? unfollowContact : followContact}
        bgColor={
          following.data
            ? pageTheme.buttonFollowing.backgroundColor
            : pageTheme?.buttonFollow.backgroundColor
        }
        borderColor={
          following.data ? pageTheme.buttonFollowing.border : pageTheme?.buttonFollow.border
        }
        borderWidth={1}
        w="80px"
        h="32px"
      >
        <Text
          fontSize={12}
          color={following.data ? pageTheme.buttonFollowing.text : pageTheme?.buttonFollow.text}
          textTransform="capitalize"
          tx={following.data ? 'common.following' : 'common.follow'}
        />
      </Pressable>
    )
  }

  return (
    <PressableOpacity onPress={openProfile}>
      <Box
        alignItems="center"
        borderBottomWidth={1}
        borderColor="gray.50"
        flexDir="row"
        h={62}
        mx={4}
        py={3}
      >
        <Avatar size={44} url={item.user?.picture} />
        <Box ml={3} flex={1}>
          <Text color={pageTheme.text} fontFamily="Inter-Bold" fontSize={15}>
            {item.type !== 'user' ? item.value : item.user?.name}
          </Text>
          {item.user?.preferred_username ? (
            <Text
              color={color.textLight}
              fontFamily="Inter-Bold"
              fontSize={11}
              fontWeight={400}
              lineHeight={15}
            >
              @{item.user.preferred_username}
            </Text>
          ) : null}
        </Box>
        {user?.sub !== item.value ? <FollowButton /> : null}
      </Box>
    </PressableOpacity>
  )
}

export default ItemFollowPeople

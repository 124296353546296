import { type IEvent, MsgType } from '@vatom/sdk/react'
import moment from 'moment'

function eventsHaveDifferentTime(event: IEvent, otherEvent: IEvent) {
  const eventTime = moment(event.origin_server_ts).format('YYYY-MM-DD_hh:mm a')
  return eventTime !== moment(otherEvent.origin_server_ts).format('YYYY-MM-DD_hh:mm a')
}
function eventsHaveDifferentType(event: IEvent, otherEvent: IEvent) {
  return event?.content?.msgtype !== otherEvent?.content?.msgtype
}
function eventIsMsgType(event: IEvent, type: MsgType) {
  return event?.content?.msgtype === type
}
function eventImageHasCaption(event: IEvent) {
  const hasCaption =
    'string' === typeof event.content?.info?.caption && event.content?.info?.caption !== ''
  return hasCaption
}

type IsImageGroupOptions = {
  breakOnCaption?: boolean
  breakOnTime?: boolean
}
// If it found 3 or more image events (without a caption)
function isImageGroup(messages: IEvent[], currentIndex: number, options?: IsImageGroupOptions) {
  // Check for more than 3 next events
  const messagesToCheck = messages.slice(currentIndex, messages.length - 1)
  if (messagesToCheck.length < 4 && options?.breakOnTime === false) {
    return {
      breakIndex: currentIndex
    }
  }
  // From the next event until breaks
  const messagesToCheckSize = messagesToCheck.length
  let foundBreakIndex = null
  for (let index = 0; index < messagesToCheckSize; index++) {
    // Break on Different type
    if (!eventIsMsgType(messagesToCheck[index], MsgType.Image)) {
      foundBreakIndex = index
      break
    }
    // Option: Break on caption
    if (options?.breakOnCaption === true && eventImageHasCaption(messagesToCheck[index])) {
      foundBreakIndex = index
      break
    }
    // Option: Break on different time
    if (
      options?.breakOnTime === true &&
      eventsHaveDifferentTime(messages[currentIndex], messagesToCheck[index])
    ) {
      foundBreakIndex = index
      break
    }
  }
  return {
    breakIndex: foundBreakIndex === null ? currentIndex : foundBreakIndex + currentIndex
  }
}

function isTextGroup(messages: IEvent[], currentIndex: number) {
  const messagesToCheck = messages.slice(currentIndex, messages.length - 1) ?? []
  const messagesToCheckSize = messagesToCheck.length
  let foundBreakIndex = null
  // From the next event until breaks
  for (let index = 0; index < messagesToCheckSize; index++) {
    if (
      eventsHaveDifferentType(messages[currentIndex], messagesToCheck[index]) ||
      eventsHaveDifferentTime(messages[currentIndex], messagesToCheck[index])
    ) {
      foundBreakIndex = index
      break
    }
  }
  return {
    breakIndex: foundBreakIndex === null ? currentIndex : foundBreakIndex + currentIndex
  }
}

// ######################
// # Message UI Grouping
// ######################

export type ParsedMessages = IEvent[][]
/**
 * All parsed messages are a group of events. it can have only one
 *
 * @param messages
 * @param partialParsedMessages
 * @param currentIndex
 * @returns
 */
export function groupMessages(
  messages: IEvent[],
  partialParsedMessages: ParsedMessages = [],
  currentIndex = 0
) {
  let nextIndex = null
  const messagesSize = messages.length
  for (let index = currentIndex; index < messagesSize; index++) {
    // TEXT
    if (
      messages[index]?.content.msgtype === MsgType.Text &&
      messages[index + 1]?.content.msgtype === MsgType.Text
    ) {
      // Check for TEXT group
      const { breakIndex } = isTextGroup(messages, index)
      if (breakIndex !== index) {
        nextIndex = breakIndex
        // push from index to breakIndex in group
        const newGroup = messages.slice(index, breakIndex)
        newGroup.sort((a, b) => a.origin_server_ts - b.origin_server_ts)
        partialParsedMessages.push(newGroup)
        break
      }
    }
    // IMAGES
    if (
      messages[index]?.content.msgtype === MsgType.Image &&
      messages[index + 1]?.content.msgtype === MsgType.Image
    ) {
      // Check for IMAGE group
      const { breakIndex } = isImageGroup(messages, index, {
        breakOnTime: true
      })
      if (breakIndex !== index) {
        nextIndex = breakIndex
        // push from index to breakIndex in group
        const newGroup = messages.slice(index, breakIndex)
        newGroup.sort((a, b) => a.origin_server_ts - b.origin_server_ts)
        partialParsedMessages.push(newGroup)
        break
      }
    }

    // No specific group  detected > push and continue
    partialParsedMessages.push([messages[index]])
    continue
  }

  if (nextIndex && nextIndex < messagesSize) {
    return groupMessages(messages, partialParsedMessages, nextIndex)
  }
  return partialParsedMessages
}

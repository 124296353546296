/**
 * Use this function to get the aspect ratio of a given width and height
 * @param w
 * @param h
 * @param aspectRatio default is 1.6 = 16/10 (is the legacy of 16/9)
 */
export const getAspectRatio = (w: number, h: number, aspectRatio = 1.6) => {
  let dimensions = {
    width: w,
    height: h
  }
  const isBiggerThanAspectRatio = h / w > aspectRatio
  if (!isBiggerThanAspectRatio) {
    const width = h / aspectRatio
    dimensions = { width, height: h }
  } else {
    const height = w * aspectRatio
    dimensions = { width: w, height }
  }

  return {
    width: Math.floor(dimensions.width),
    height: Math.floor(dimensions.height)
  }
}

export const useAspectRatio = (w: number, h: number, aspectRatio = 1.6) => {
  return getAspectRatio(w, h, aspectRatio)
}

import React, { useMemo, useState } from 'react'
import { Linking, Platform, useWindowDimensions, View } from 'react-native'
import { translate } from '@vatom/utils'
import { Button, theme } from '@vatom/wombo'
import * as Location from 'expo-location'
import { LocationAccuracy } from 'expo-location'
import { CountryCode } from 'libphonenumber-js'
import { Box, VStack } from 'native-base'

import { useModalQuery } from '../../../components/PromptModal/PromptModal'
import { Title } from '../../../components/Title'
import { LatLng } from '../react-native-maps'

import { MapView } from './MapView'

const isWeb = Platform.OS === 'web'

type CurrentLocation = {
  latitude: number
  longitude: number
  latitudeDelta: number
  longitudeDelta: number
}

const fetchLocationFromIp = async () => {
  const response = await fetch(
    'https://api.ipstack.com/check?access_key=f6c1392b737ae773c32e839ccd03e87f&fields=country_code,latitude,longitude'
  )
  return (await response.json()) as {
    latitude: number
    longitude: number
    country_code: CountryCode
  }
}

const getCurrentLocation = async () => {
  try {
    const currentPermission = await Location.getForegroundPermissionsAsync()
    if (currentPermission.granted === false && currentPermission.status === 'denied') {
      throw new Error('Location permission required')
    }
    if (currentPermission.status !== 'granted') {
      const requestPermission = await Location.requestForegroundPermissionsAsync()
      if (requestPermission.status !== 'granted') {
        throw new Error('Location permission required')
      }
    }

    const fetchLocation = async () => {
      const timeOutPromise = new Promise<Location.LocationObject>((_, reject) => {
        setTimeout(() => {
          reject(new Error('Location request timed out'))
        }, 2000)
      })
      const locationPromise = Location.getCurrentPositionAsync({
        accuracy: LocationAccuracy.Balanced,
        distanceInterval: 5
      })
      const lastKnownLocationPromise = Location.getLastKnownPositionAsync()
      try {
        const result = await Promise.race([timeOutPromise, locationPromise])
        return result
      } catch (error) {
        console.error('Error: fetchlocation, using last known position', error)
        const result = await lastKnownLocationPromise
        if (result) {
          return result
        } else {
          throw error
        }
      }
    }

    const location = await fetchLocation()

    return {
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
      latitudeDelta: 0.015,
      longitudeDelta: 0.0121
    }
  } catch (error) {
    if (Platform.OS !== 'web') {
      throw error
    } else {
      const { latitude, longitude } = await fetchLocationFromIp()

      return {
        latitude,
        longitude,
        latitudeDelta: 0.015,
        longitudeDelta: 0.0121
      }
    }
  }
}

type Map = {
  onDrop: (lat: number, lon: number) => void
  isLoading: boolean
  isOpen: boolean
}

export const Map = ({ onDrop, isLoading, isOpen }: Map) => {
  const { width } = useWindowDimensions()
  const [widthOfMap, setWidthOfMap] = useState(width)
  const [markerLocation, setMarkerLocation] = useState<LatLng>()

  const onOpenSetting = async () => {
    const servicesEnabled = await Location.hasServicesEnabledAsync()

    if (!servicesEnabled) {
      Linking.openURL('App-Prefs:Privacy&path=LOCATION')
      return
    }

    Linking.openSettings()
  }

  const {
    isLoadingOrFetching,
    isError: isErrorPermission,
    renderModalQueryComponent,
    data: location
  } = useModalQuery({
    modalQueryKey: ['drop-location'],
    modalQueryFn: getCurrentLocation,
    onSuccessFn: location => {
      const l = location as CurrentLocation
      if (l) {
        setMarkerLocation(() => ({
          latitude: l.latitude,
          longitude: l.longitude
        }))
      }
    },
    promptError: {
      title: 'Location Permission',
      message: translate('errors.deniedLocation'),
      onCloseError: () => console.log('Prompt Error was closed'),
      onOpenSetting: onOpenSetting
    }
  })

  const renderMap = useMemo(
    () =>
      isOpen &&
      location && (
        <MapView
          setMarkerLocation={setMarkerLocation}
          widthOfMap={widthOfMap}
          setWidthOfMap={setWidthOfMap}
          location={location as CurrentLocation}
          markerLocation={markerLocation}
        />
      ),
    [isOpen, location, markerLocation, widthOfMap]
  )

  return (
    <VStack flexGrow={1}>
      {renderModalQueryComponent}
      <VStack
        flexGrow={1}
        marginBottom={4}
        style={{
          aspectRatio: isWeb ? 1 : undefined
        }}
      >
        {renderMap}
      </VStack>
      <VStack
        justifyContent={'flex-end'}
        flex={0}
        paddingBottom={4}
        _web={{
          flex: 1
        }}
      >
        <Button
          onPress={() => onDrop(markerLocation!.latitude, markerLocation!.longitude)}
          disabled={isLoading || isLoadingOrFetching || isErrorPermission}
          opacity={isLoading || isLoadingOrFetching || isErrorPermission ? 0.5 : 1}
          _light={{
            backgroundColor: theme.colors.systemColorsLight.orange
          }}
          _dark={{
            backgroundColor: theme.colors.systemColorsDark.orange
          }}
        >
          <Title preset="h5" variant="semibold" paddingY={1} color="white">
            {translate('common.drop')}
          </Title>
        </Button>
      </VStack>
    </VStack>
  )
}
export default Map

import * as React from 'react'
import { Image, Pressable, View } from 'native-base'

interface FooterProps {
  onCommentPress?: () => void
  onHeartPress?: () => void
  onSharePress?: () => void
}

export const Footer = ({ onCommentPress, onHeartPress, onSharePress }: FooterProps) => {
  return (
    <View flexDirection="row">
      <Pressable key="heart" accessibilityRole="button" onPress={() => onHeartPress?.()}>
        <Image
          height="21px"
          width="24px"
          mr="16px"
          my="16px"
          resizeMode="contain"
          tintColor="lightText"
          source={require('./assets/heart.png')}
          accessibilityIgnoresInvertColors
          alt="heart"
        />
      </Pressable>
      <Pressable key="comment" accessibilityRole="button" onPress={() => onCommentPress?.()}>
        <Image
          height="21px"
          width="24px"
          mr="16px"
          my="16px"
          resizeMode="contain"
          tintColor="lightText"
          source={require('./assets/comment.png')}
          accessibilityIgnoresInvertColors
          alt="comment"
        />
      </Pressable>
      <Pressable key="share" accessibilityRole="button" onPress={() => onSharePress?.()}>
        <Image
          height="21px"
          width="24px"
          mr="16px"
          my="16px"
          resizeMode="contain"
          tintColor="lightText"
          source={require('./assets/share.png')}
          accessibilityIgnoresInvertColors
          alt="share"
        />
      </Pressable>
    </View>
  )
}

export default Footer

import React from 'react'
import { Text } from '@vatom/wombo'
import { Pressable } from 'native-base'

interface ClickableCategoryFieldProps {
  title: string
  value: string
  onPress: () => void
}

export const ClickableCategoryField = ({ value, title, onPress }: ClickableCategoryFieldProps) => {
  return (
    <Pressable
      alignItems="center"
      h="55px"
      flexDir="row"
      justifyContent="space-between"
      accessibilityRole="button"
      onPress={() => onPress()}
    >
      <Text fontFamily="Inter-Regular" fontSize="15px" lineHeight="20px">
        {title}
      </Text>
      <Text>{value}</Text>
    </Pressable>
  )
}

export default ClickableCategoryField

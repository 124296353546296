import React, { useCallback, useMemo, useState } from 'react'
import {
  getBusinessIdsInRoomsSelector,
  useGetMatrixFullStateSync,
  useMatrixUser
} from '@vatom/sdk/react'
import { LoaderView, theme } from '@vatom/wombo'
import { Box } from 'native-base'

import { AddSpace } from './AddSpace'
import { EmptySpace } from './EmptySpace'
import { JustAddedRooms } from './JustAddedRooms'
import { LastVisitedRooms } from './LastVisitedRooms'
import { LoadingSpace } from './LoadingSpace'
import { MySpaces, SectionHeader } from './MySpaces'
import SectionHeading from './SectionHeading'
import { SpaceActionsModal } from './SpaceActionsModal'

export type SpaceRoom = {
  matrixRoomId: string
  spaceId: string
}

const defaultSpaceRoom = {
  matrixRoomId: '',
  spaceId: ''
}
export const SectionSpaces = () => {
  const [selectedSpaceRoom, setSelectedSpaceRoom] = useState<SpaceRoom>(defaultSpaceRoom)
  const [openActions, setOpenActions] = useState(false)
  const matrixUser = useMatrixUser()
  const { data: businessIdsOfRooms, isLoading } = useGetMatrixFullStateSync({
    select: matrixData => {
      return getBusinessIdsInRoomsSelector(matrixData, matrixUser)
    }
  })

  const handleSetSelectedSpace = useCallback(
    (spaceData: SpaceRoom) => {
      setSelectedSpaceRoom(spaceData)
      setOpenActions(true)
    },
    [setSelectedSpaceRoom]
  )

  const onDismissModal = useCallback(() => {
    setSelectedSpaceRoom(defaultSpaceRoom)
    setOpenActions(false)
  }, [setOpenActions])

  const renderSpaces = useMemo(
    () =>
      businessIdsOfRooms?.length
        ? businessIdsOfRooms.map(businessId => (
            <React.Fragment key={`space-${businessId}`}>
              <Box
                paddingY={3}
                paddingLeft={4}
                _dark={{
                  backgroundColor: theme.colors.grayCool[900]
                }}
                _light={{
                  backgroundColor: theme.colors.white
                }}
              >
                <SectionHeader businessId={businessId} />
                <MySpaces onSelectSpaceRoom={handleSetSelectedSpace} businessId={businessId} />
              </Box>
              <Box
                _dark={{
                  backgroundColor: theme.colors.grayCool[900]
                }}
                _light={{
                  backgroundColor: theme.colors.white
                }}
                height="2px"
              />
            </React.Fragment>
          ))
        : null,
    [businessIdsOfRooms, handleSetSelectedSpace]
  )

  const renderEmptyOrLoading = useMemo(
    () => (!isLoading && !businessIdsOfRooms?.length ? <EmptySpace /> : <LoadingSpace />),
    [isLoading, businessIdsOfRooms]
  )

  const showSpaces = businessIdsOfRooms && businessIdsOfRooms.length

  return (
    <>
      <SpaceActionsModal
        showModal={openActions}
        onDismiss={onDismissModal}
        spaceRoom={selectedSpaceRoom}
      />
      <AddSpace />
      <Box
        _dark={{
          backgroundColor: theme.colors.grayCool[800]
        }}
        _light={{
          backgroundColor: theme.colors.coolGray[100]
        }}
      >
        {!showSpaces && isLoading ? (
          <LoaderView blocking={false} style={{ marginTop: -60 }} />
        ) : null}
        <Box
          mt="8px"
          py={3}
          _dark={{
            backgroundColor: theme.colors.grayCool[900]
          }}
          _light={{
            backgroundColor: theme.colors.white
          }}
        >
          <SectionHeading title="Recently Visited" viewAll={false} />
          <Box marginTop={1} paddingY={1} paddingLeft={4}>
            {renderSpaces ? (
              <LastVisitedRooms onSelectSpaceRoom={handleSetSelectedSpace} />
            ) : (
              renderEmptyOrLoading
            )}
          </Box>
        </Box>

        <Box
          mt="8px"
          py={3}
          _dark={{
            backgroundColor: theme.colors.grayCool[900]
          }}
          _light={{
            backgroundColor: theme.colors.white
          }}
        >
          <SectionHeading title="Just Added" viewAll={false} />
          <Box marginTop={1} paddingY={1} paddingLeft={4}>
            {!isLoading ? (
              <JustAddedRooms onSelectSpaceRoom={handleSetSelectedSpace} />
            ) : (
              renderEmptyOrLoading
            )}
          </Box>
        </Box>

        <Box
          mt="8px"
          py={3}
          _dark={{
            backgroundColor: theme.colors.grayCool[900]
          }}
          _light={{
            backgroundColor: theme.colors.white
          }}
        >
          <SectionHeading title="My Spaces" viewAll={false} />
          {renderSpaces ? (
            renderSpaces
          ) : (
            <Box marginTop={1} paddingY={1} paddingLeft={4}>
              {renderEmptyOrLoading}
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default SectionSpaces

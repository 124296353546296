import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import Animated from 'react-native-reanimated'
import {
  Contact,
  ContactSources,
  DestinationTypes,
  useGetContacts,
  useRefreshOnFocus
} from '@vatom/sdk/react'
import { observer } from 'mobx-react-lite'
import { Box, FlatList } from 'native-base'

import { useLayoutScrollHandler } from '../../../hooks/useLayoutScrollHandler'

import { PeopleActionsModal } from './ContactActionsModal'
import ItemPeople from './ItemPeople'
import ZeroState from './ZeroState'

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList)

type SectionPeopleProps = {
  containerProps?: React.ComponentProps<typeof Box>
}

export const SectionPeople = observer(({ containerProps }: SectionPeopleProps) => {
  const [selectedSpaceId, setSelectedSpaceId] = useState('')
  const [openActions, setOpenActions] = useState(false)
  const { scrollHandler, scrollEventThrottle, getHeaderHeight, getFooterHeight } =
    useLayoutScrollHandler()

  useEffect(() => {
    if (selectedSpaceId) {
      setOpenActions(true)
    }
  }, [selectedSpaceId])

  const contacts = useGetContacts(
    { type: DestinationTypes.Id, value: '' },
    {
      sources: [
        ContactSources.recents,
        ContactSources.tokenhistory,
        ContactSources.following,
        ContactSources.followers
      ]
    }
  )

  useRefreshOnFocus(contacts.refetch)

  if (contacts.isLoading) return null
  if (contacts.data?.length === 0) return <ZeroState />

  return (
    <>
      <PeopleActionsModal
        showModal={openActions}
        setShowModal={setOpenActions}
        spaceId={selectedSpaceId}
      />
      <Box {...containerProps}>
        <AnimatedFlatList
          keyExtractor={item => {
            // Had to do this because AnimatedFlatlist doesn't infer the types of the passed data
            const contact = item as Contact
            return `contact-${contact.id}`
          }}
          data={contacts.data}
          renderItem={({ item }) => {
            // Had to do this because AnimatedFlatlist doesn't infer the types of the passed data
            const contact = item as Contact
            return <ItemPeople contact={contact} setSelectedSpaceId={setSelectedSpaceId} />
          }}
          showsHorizontalScrollIndicator={false}
          ListFooterComponent={<View style={{ height: 90 }}></View>}
          onScroll={scrollHandler}
          scrollEventThrottle={scrollEventThrottle}
          contentContainerStyle={{
            paddingTop: getHeaderHeight(),
            paddingBottom: getFooterHeight()
          }}
        />
      </Box>
    </>
  )
})

export default SectionPeople

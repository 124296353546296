import { useMemo } from 'react'

import { useScreenSize } from './useScreenSize'

const useScrollContentSize = () => {
  // TODO: this is only for Y axis. Can be re used for X axis as well
  const { windowSize, screenSize, setScreenSize } = useScreenSize()
  const onContentSizeChange = (contentWidth: number, contentHeight: number) => {
    setScreenSize({
      width: contentWidth,
      height: contentHeight
    })
  }
  const scrollEnabled = useMemo(
    () => screenSize.height > windowSize.height,
    [screenSize.height, windowSize.height]
  )
  return {
    onContentSizeChange,
    scrollEnabled
  }
}

export { useScrollContentSize }

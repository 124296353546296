import { useNavigation } from '@react-navigation/native'
import {
  useGetContactsPermission,
  useIsContactsAvailable,
  useRequestContactsPermission
} from '@vatom/sdk/react'
import { PressableOpacity, Text } from '@vatom/wombo'
import * as Contacts from 'expo-contacts'
import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'

import { AppRoutes } from '../../../navigators'

export const NavHeaderRight = observer(() => {
  const navigation = useNavigation()
  const contactPermission = useGetContactsPermission()
  const contactsPermissionMutation = useRequestContactsPermission(permission => {
    if (permission?.status === Contacts.PermissionStatus.GRANTED) {
      navigation.navigate(AppRoutes.invitePeople)
    }
  })
  const isAvailable = useIsContactsAvailable()

  const onPress = debounce(() => {
    if (contactPermission.data?.status !== Contacts.PermissionStatus.GRANTED) {
      contactsPermissionMutation.mutate()
    } else {
      navigation.navigate(AppRoutes.invitePeople)
    }
  }, 50)
  if (isAvailable.data) {
    return (
      <PressableOpacity accessibilityRole="button" ml={1} padding={2} onPress={onPress}>
        <Text tx="common.invite" />
      </PressableOpacity>
    )
  }

  return null
})

import { ClipPath, Defs, G, LinearGradient, Path, Stop } from 'react-native-svg'
import { Icon } from 'native-base'

type VatomLogoIconProps = {
  size: React.ComponentProps<typeof Icon>['size']
}

export const VatomLogo = ({ size }: VatomLogoIconProps) => (
  <Icon viewBox="0 0 127 109" size={size} fill="currentColor">
    <G clipPath="url(#clip0_150_284)">
      <Path
        d="M63.31 44.606c9.081 0 16.442-7.355 16.442-16.43 0-9.073-7.36-16.429-16.441-16.429-9.08 0-16.442 7.356-16.442 16.43s7.361 16.43 16.442 16.43z"
        fill="url(#paint0_linear_150_284)"
      />
      <Path
        d="M119.109 1.538c-6.665-3.283-14.733-.543-18.018 6.121L78.123 54.256l-23.198 47.059a13.415 13.415 0 00-1.36 5.071 28.585 28.585 0 0010.162 1.857c10.616 0 19.886-5.78 24.874-14.38l1.723-3.496 34.91-70.82c3.285-6.662.543-14.725-6.125-18.009z"
        fill="url(#paint1_linear_150_284)"
      />
      <Path
        d="M66.067 89.5c3.058 4.618 7.2 6.414 12.288 6.414a14.958 14.958 0 0012.702-7.042l-.358.724-1.85 3.752c-3.486 6.01-8.92 10.736-15.431 13.304a30.513 30.513 0 01-4.939 1.481 28.892 28.892 0 01-5.263.482c-4.247 0-8.278-.921-11.91-2.573a29.106 29.106 0 01-13.117-11.925l-1.681-3.41L1.395 19.491C-1.905 12.796.85 4.694 7.55 1.395c6.7-3.3 14.808-.546 18.11 6.15 0 0 37.426 78.049 40.405 81.954h.002z"
        fill="url(#paint2_linear_150_284)"
      />
    </G>
    <Defs>
      <LinearGradient
        id="paint0_linear_150_284"
        x1={63.2513}
        y1={7.80716}
        x2={67.6345}
        y2={36.3554}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#F90" />
        <Stop offset={1} stopColor="#FB4F0E" />
      </LinearGradient>
      <LinearGradient
        id="paint1_linear_150_284"
        x1={19.7891}
        y1={171.731}
        x2={121.935}
        y2={4.19286}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#FB4E0E" />
        <Stop offset={0.959783} stopColor="#FFE431" />
      </LinearGradient>
      <LinearGradient
        id="paint2_linear_150_284"
        x1={-0.00000536063}
        y1={-14.7181}
        x2={-0.381855}
        y2={57.9318}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#F90" />
        <Stop offset={1} stopColor="#FB4F0E" />
      </LinearGradient>
      <ClipPath id="clip0_150_284">
        <Path fill="#fff" d="M0 0H127V109H0z" />
      </ClipPath>
    </Defs>
  </Icon>
)

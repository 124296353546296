import { Color, getColor, TextContent } from '@vatom/BVatom/plugin'
import { TokenType } from '@vatom/sdk/core'
import { Button, theme } from '@vatom/wombo'
import { Box, HStack, Image, Modal, Text } from 'native-base'

export const ViewIncomingVatomModal = ({
  token,
  onViewPress,
  onCancel,
  isOpen,
  buttonColor,
  title,
  descriprion
}: {
  token?: TokenType
  onViewPress: () => void
  onCancel: () => void
  isOpen: boolean
  buttonColor?: Color
  title?: TextContent
  descriprion?: string
}) => {
  return (
    <Modal
      useRNModal={true}
      isOpen={isOpen}
      alignItems="center"
      justifyContent="center"
      animationPreset="slide"
    >
      <Modal.Content
        p={4}
        borderRadius="8px"
        backgroundColor="white"
        // make it grow vertically to fit the content
        height="auto"
        width="320px"
      >
        <Box px="16px" flex={1}>
          <Box marginY="16px" height="100px" alignItems="center" justifyContent="center">
            {token?.metadata.image && (
              <Image
                resizeMode="stretch"
                accessibilityIgnoresInvertColors
                source={{ uri: token.displayImage ?? token.metadata.image }}
                height="100px"
                width="100px"
                alt="modalData.image"
                borderRadius="8px"
              />
            )}
          </Box>
          <Text
            ellipsizeMode="tail"
            fontSize="17px"
            marginBottom="8px"
            fontFamily="Inter-Bold"
            textAlign="center"
            noOfLines={1}
          >
            {title?.text ?? 'Congratulations!'}
          </Text>
          {/* <Text textAlign="center" fontSize={12}>
            You found a {token?.metadata.name} + 20 points! You can view all your collectibles in
            your iHeart Digital Wallet.
          </Text> */}
          <Text textAlign="center" fontSize={12}>
            {descriprion ?? `You found a ${token?.metadata.name}!\nDo you want to view it?`}
          </Text>
        </Box>
        {/* <Divider height="1px" backgroundColor="light.300" /> */}
        <HStack height="44px" space={2} marginTop="4" justifyContent="center">
          <Button onPress={onCancel} backgroundColor="white" variant="outline" w="50%">
            <Text fontFamily="Inter-Regular" fontSize="15px">
              Return to AR
            </Text>
          </Button>
          <Button
            onPress={onViewPress}
            _dark={{
              backgroundColor: buttonColor
                ? getColor(buttonColor)
                : theme.colors.systemColorsDark.orange
            }}
            _light={{
              backgroundColor: buttonColor
                ? getColor(buttonColor)
                : theme.colors.systemColorsLight.orange
            }}
            variant="outline"
            w="50%"
          >
            <Text fontFamily="Inter-SemiBold" fontSize="15px" color="white">
              View My Token
            </Text>
          </Button>
        </HStack>
      </Modal.Content>
    </Modal>
  )
}

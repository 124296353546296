import React, { useCallback } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { BottomSheetView } from '@gorhom/bottom-sheet'
import { useNavigation } from '@react-navigation/native'
import { useMutation } from '@tanstack/react-query'
import { TokenType } from '@vatom/sdk/core'
import { useAnalytics } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { Status, Text, theme, Toast } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Divider, Pressable, VStack } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import Alert from '../../../components/Alert'
import { useBusinessSelector } from '../../../hooks/useBusinessSelector'
import { AppRoutes, TabRoutes } from '../../../navigators'
import ProcessButton from '../../ActionSheets/partials/ProcessButton'

export const DeleteSheetModal = observer(
  ({ token, onDismiss }: { token: TokenType; onDismiss: () => void }) => {
    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const analytics = useAnalytics()
    const { business } = useBusinessSelector()

    const goBack = useCallback(() => {
      if (business?.id) {
        navigation.navigate(AppRoutes.BusinessProxy, {
          business: business?.id,
          screen: TabRoutes.Wallet
        })
        return
      } else {
        navigation.navigate(AppRoutes.home, {
          screen: 'Wallet'
        })
        return
      }
    }, [business?.id, navigation])

    const deleteMutation = useMutation({
      mutationFn: async () => {
        if (token.type === 'vatom-new') {
          await token.performAction('Delete')
        } else {
          await token.performAction('Delete')
          await analytics.event('performAction', {}, token)
        }
        Toast({
          title: translate('deleteNft.success'),
          placement: 'top',
          status: Status.success
        })
      },
      onError(error) {
        //
        Alert.showError(error)
      },
      onSuccess() {
        goBack()
      }
    })

    const deleteAction = useThrottledCallback(() => {
      deleteMutation.mutate()
    }, 300)

    return (
      <BottomSheetView
        style={{
          paddingTop: 16,
          paddingBottom: insets.bottom > 0 ? insets.bottom : 16
        }}
      >
        <VStack>
          <VStack w="100%" paddingX="16px">
            <Text textAlign="center" tx="deleteNft.shouldDelete" />
          </VStack>

          <Divider backgroundColor="gray.900" height="0px" mt={4} />
          <Box width={'100%'} px={4}>
            <ProcessButton
              label={translate('common.delete')}
              isDisabled={deleteMutation.isLoading}
              onPress={deleteAction}
              isLoading={deleteMutation.isLoading}
            />
          </Box>

          <Divider backgroundColor="gray.900" height="0px" mt={4} />

          <Box width={'100%'} px={4}>
            <Pressable
              accessibilityRole="button"
              colorScheme="gray"
              w="100%"
              justifyContent="center"
              onPress={onDismiss}
              disabled={deleteMutation.isLoading}
              rounded={'sm'}
              p={2}
              _light={{
                backgroundColor: theme.colors.grayCool[600]
              }}
              _dark={{
                backgroundColor: theme.colors.grayDarkMode[700]
              }}
            >
              <Text
                color="white"
                fontFamily="Inter-SemiBold"
                alignSelf="center"
                py={2}
                fontSize="18px"
                tx="common.cancel"
              />
            </Pressable>
          </Box>
        </VStack>
      </BottomSheetView>
    )
  }
)

import { QueryClient } from '@tanstack/react-query'
import { EventType, KNOWN_SAFE_ROOM_VERSION } from 'matrix-js-sdk'

import { IEvent, MatrixData, matrixQueryKeys } from '../matrix'

import { createTxnId } from './helpers'
import { dmQueryKeys } from './queries'

export type Member = {
  userId: string
  displayname: string
  avatar_url?: string
}

export const LOCAL_ROOM_ID_PREFIX = 'local+'

export function createLocalDmRoom(userId: string, invitees: Member[], queryClient: QueryClient) {
  if (!userId) {
    throw new Error('createLocalDmRoom: userId is required')
  }
  if (!invitees || invitees.length === 0) {
    throw new Error('createLocalDmRoom: invitees is required')
  }

  const localRoomId = LOCAL_ROOM_ID_PREFIX + createTxnId()
  const events: Omit<IEvent, 'unsigned'>[] = []

  events.push({
    event_id: `~${localRoomId}:${createTxnId()}`,
    type: EventType.RoomCreate,
    content: {
      creator: userId,
      room_version: KNOWN_SAFE_ROOM_VERSION
    },
    state_key: '',
    sender: userId,
    room_id: localRoomId,
    origin_server_ts: Date.now()
  })

  events.push({
    event_id: `~${localRoomId}:${createTxnId()}`,
    type: EventType.RoomMember,
    content: {
      displayname: userId,
      membership: 'join'
    },
    state_key: userId,
    sender: userId,
    room_id: localRoomId,
    origin_server_ts: Date.now()
  })

  const membersEvents: Omit<IEvent, 'unsigned' | 'origin_server_ts'>[] = []
  invitees.forEach((target: Member) => {
    membersEvents.push({
      event_id: `~${localRoomId}:${createTxnId()}`,
      type: EventType.RoomMember,
      content: {
        displayname: target.displayname,
        avatar_url: target?.avatar_url ?? undefined,
        membership: 'invite',
        is_direct: true
      },
      state_key: target.userId,
      sender: userId,
      room_id: localRoomId
    })
    membersEvents.push({
      event_id: `~${localRoomId}:${createTxnId()}`,
      type: EventType.RoomMember,
      content: {
        displayname: target.displayname,
        avatar_url: target?.avatar_url ?? undefined,
        membership: 'join'
      },
      state_key: target.userId,
      sender: target.userId,
      room_id: localRoomId
    })
  })

  const currentState = queryClient.getQueryState(matrixQueryKeys.getMatrixFullStateSync(''))
    ?.data as MatrixData | null
  if (currentState) {
    currentState.rooms.join[localRoomId] = {
      timeline: {
        // @ts-ignore
        events: [...events, ...membersEvents]
      },
      account_data: {
        events: []
      },
      ephemeral: {
        events: []
      },
      unread_notifications: {
        notification_count: 0,
        highlight_count: 0
      }
    }

    queryClient.setQueryData(matrixQueryKeys.getMatrixFullStateSync(''), currentState)
    // Add state to dm-room-members
    const roomMembers = {
      chunk: membersEvents
    }
    queryClient.setQueryData(dmQueryKeys.getDmRoomMembers(localRoomId), roomMembers)
  }

  return localRoomId
}

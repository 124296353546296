import { useMemo } from 'react'

import { useBusinessSelector } from '../../hooks/useBusinessSelector'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'

export const lightTheme = (brandColors: any) => {
  const { primary, primaryText } = brandColors ?? {}

  return {
    name: 'light',
    accentColor: primary?.light ?? '#fc500eff',
    accentColorText: primaryText?.light ?? '#ffffffff',
    header: {
      background: '#E9ECEF',
      icons: '#11141E'
    },
    xButtonColor: '#11141E',
    toolbarCloseBackground: '#3F4A55',
    background: '#FFFFFF',
    roomTitle: '#000',
    text: '#868E96',
    roomItem: {
      name: '#11141E',
      sub: '#868E96'
    },
    category: {
      name: '#11141E',
      borderColor: '#DEE2E6'
    },
    roomAvatarBorder: '#FFFFFF',
    roomTags: {
      text: '#868E96',
      background: '#F8F9FA'
    },
    roomFilters: {
      filter: '#868E96',
      activeFilter: '#3F4A55',
      border: '#DEE2E6',
      activeBorder: '#3F4A55'
    },
    dayBanner: {
      border: '#DEE2E6'
    },
    messageList: {
      border: '#DEE2E6'
    },
    chatBubbleHeader: {
      userName: '#3F4A55',
      text: '#868E96'
    },
    chatBubbleContent: {
      text: '#3F4A55'
    },
    emojiLine: {
      background: '#F8F9FA',
      icon: '#ADB5BD'
    },
    replyHeaderTitle: '#11141E',
    threadRootInfo: {
      border: '#E9ECEF'
    },
    modalToolBar: {
      background: '#FFFFFF',
      border: '#E9ECEF',
      messageComponent: {
        background: '#F8F9FA',
        text: '#CED4DA'
      }
    },
    input: {
      text: '#3F4A55',
      placeholder: '#CED4DA',
      border: '#DEE2E6',
      icon: '#ADB5BD'
    },
    popUp: {
      icon: '#868E96',
      title: '#3F4A55'
    },
    membersPage: {
      title: '#11141E',
      sub: '#868E96'
    },
    memberItem: {
      name: '#11141E',
      tag: '#868E96'
    },
    memberActionButtons: {
      border: '#CED4DA',
      text: '#FFFFFF',
      backgroundColor: '#FF8000'
    },
    memberActionButtons__OUTLINE: {
      border: '#CED4DA',
      text: '#FFFFFF',
      backgroundColor: 'transparent'
    },
    memberStat: {
      background: '#F8F9FA',
      middleBorder: '#DEE2E6',
      border: '#FFFFFF',
      value: '#11141E',
      title: '#868E96'
    },
    memberInfo: {
      name: '#11141E',
      tag: '#868E96',
      description: '#3F4A55',
      linkIcon: '#3F4A55',
      linkColor: '#2EA7FF',
      reputationBadge: {
        background: '#FFFFFF',
        text: '#3F4A55',
        border: '#ced4da33'
      }
    },
    replyMessageScreen: {
      background: '#FFF',
      mainTextColor: '#3F4A55',
      lightTextColor: '#868E96',
      extraLightTextColor: '#CED4D4',
      reputationBadge: {
        background: '#F8F9FA',
        titleColor: '#3F4A55',
        linkColor: '#3798E4',
        counterColor: '#FFF',
        activeButtonBackground: '#7220F9',
        buttonTextColor: '#868E96',
        greenCounterBackground: '#F3FCF5',
        redCounterBackground: '#rf2c9d2',
        counterBorder: '#DEE2E6',
        reverse: '#000',
        lightColor: '#D9D9DB'
      },
      inactiveSendButtonBackground: '#DEE2E6',
      inactiveSendButtonColor: '#FFF'
    },
    questionBadge: {
      backgroudColor: '#F8F9FA',
      textColor: '#3F4A55',
      smallTextColor: '#868E96',
      borderColor: '#E9ECEF'
    },
    score: {
      borderColor: '#E9ECEF',
      borderColorActive: '#3F4A55',
      textColor: '#3F4A55',
      lineColor: '#F1F3F5',
      lineFill: '#3F4A55'
    },
    sketches: {
      backgroundColor: '#F8F9FA',
      titleColor: '#3F4A55',
      subTitleColor: '#868E96',
      borderLeft: '#CED4DA',
      borderLeftActive: '#3F4A55'
    },
    voteIcon: {
      fillColor: '#868E96',
      backgroundColor: '#F1F3F5',
      activeBackgroundColor: '#FF8000'
    },
    labelTitleColor: '#3F4A55',
    labelIconColor: '#868E96',
    labelSwich: '#FF8000',
    labelTrackColor: '#CED4DA',
    labelThumbColor: '#FFFFFF',
    labelBorder: '#E9ECEF',
    stars: {
      starColor: '#CED4DA',
      starColorActive: '#F5B700',
      progressStarColor: '#E9ECEF'
    },
    toolbar: {
      backgroundColor: '#FFFFFF',
      closeButtonBackground: '#3F4A55',
      closeIconColor: '#FFFFFF',
      textColor: '#868E96'
    },
    confirmationModal: {
      title: '#11141E',
      description: '#3F4A55',
      buttonBorder: '#CED4DA'
    },
    scoringLabelEnded: {
      color: '#DD4267',
      backgroundColor: `rgba(221, 66, 103, 0.10)`
    },
    scoringLabelStarts: {
      color: '#34C759',
      backgroundColor: `rgba(52, 199, 89, 0.10)`
    },
    sketch: {
      color: '#3F4A55'
    },
    postHiddenTag: {
      title: '#11141E',
      description: '#3F4A55',
      background: '#F1F3F5',
      icon: '#868E96'
    },
    carousel: {
      dot: '#DEE2E6',
      activeDot: '#29313A'
    },
    addButton: {
      border: '#CED4DA',
      text: '#3F4A55'
    },
    sketchVoteButton: {
      border: '#CED4DA',
      line: '#DEE2E6'
    },
    pollChoice: {
      border: '#11141e14',
      text: '#3F4A55',
      placeholder: '#CED4DA',
      activeBorder: '#3F4A55',
      fillBar: '#11141e0a'
    },
    pollAddDeleteButton: {
      icon: '#868E96',
      border: '#DEE2E6'
    }
  }
}

export const darkTheme = (brandColors: any) => {
  const { primary, primaryText } = brandColors ?? {}

  return {
    name: 'dark',
    accentColor: primary?.dark ?? '#d6661cff',
    accentColorText: primaryText?.dark ?? '#ffffffff',
    header: {
      background: '#2E3039',
      icons: '#FFFFFF'
    },
    xButtonColor: '#FFF',
    toolbarCloseBackground: '#83858A',
    background: '#11141E',
    roomTitle: '#FFF',
    text: '#707278',
    roomItem: {
      name: '#D9D9DB',
      sub: '#707278'
    },
    category: {
      name: '#B3B4B7',
      borderColor: '#2E3039'
    },
    roomAvatarBorder: '#000000',
    roomTags: {
      text: '#D9D9DB',
      background: '#d9d9db1a'
    },
    roomFilters: {
      filter: '#707278',
      activeFilter: '#B3B4B7',
      border: '#373A42',
      activeBorder: '#FFFFFF'
    },
    dayBanner: {
      border: '#67696F'
    },
    messageList: {
      border: '#2E3039'
    },
    chatBubbleHeader: {
      userName: '#B3B4B7',
      text: '#707278'
    },
    chatBubbleContent: {
      text: '#B3B4B7'
    },
    emojiLine: {
      background: '#1B1D27',
      icon: '#54565D'
    },
    replyHeaderTitle: '#D9D9DB',
    threadRootInfo: {
      border: '#373A42'
    },
    modalToolBar: {
      background: '#1B1D27',
      border: '#2E3039',
      messageComponent: {
        background: '#242730',
        text: '#54565D'
      }
    },
    input: {
      text: '#B3B4B7',
      placeholder: '#3F4A55',
      border: '#41434B',
      icon: '#707278'
    },
    popUp: {
      icon: '#B3B4B7',
      title: '#D9D9DB'
    },
    membersPage: {
      title: '#D9D9DB',
      sub: '#707278'
    },
    memberItem: {
      name: '#D9D9DB',
      tag: '#707278'
    },
    memberActionButtons: {
      border: '#ffffff4d',
      text: '#B3B4B7',
      backgroundColor: '#6100FF'
    },
    memberActionButtons__OUTLINE: {
      border: '#ffffff4d',
      text: '#B3B4B7',
      backgroundColor: 'transparent'
    },
    memberStat: {
      background: '#1B1D27',
      middleBorder: '#2E3039',
      border: '#373A42',
      value: '#D9D9DB',
      title: '#B3B4B7'
    },
    memberInfo: {
      name: '#D9D9DB',
      tag: '#B3B4B7',
      description: '#F8F9FA',
      linkIcon: '#F8F9FA',
      linkColor: '#3798E4',
      reputationBadge: {
        background: '#1B1D27',
        text: '#B3B4B7',
        border: '#242730'
      }
    },
    replyMessageScreen: {
      background: '#1B1D27',
      mainTextColor: '#B3B4B7',
      lightTextColor: '#707278',
      extraLightTextColor: '#3F4A55',
      reputationBadge: {
        background: '#242730',
        titleColor: '#B3B4B7',
        linkColor: '#3798E4',
        counterColor: '#41434B',
        activeButtonBackground: '#7220F9',
        buttonTextColor: '#FFFFFF',
        greenCounterBackground: '#284439',
        redCounterBackground: '#8b4858',
        counterBorder: '#707278',
        reverse: '#FFF',
        lightColor: '#11141E'
      },
      inactiveSendButtonBackground: '#373A42',
      inactiveSendButtonColor: '#707278'
    },
    questionBadge: {
      backgroudColor: '#1B1D27',
      textColor: '#B3B4B7',
      smallTextColor: '#707278',
      borderColor: 'rgba(255, 255, 255, 0.16)'
    },
    score: {
      borderColor: '#2E3039',
      borderColorActive: '#83858A',
      textColor: '#B3B4B7',
      lineColor: '#242730',
      lineFill: '#83858A'
    },
    sketches: {
      backgroundColor: '#1B1D27',
      titleColor: '#B3B4B7',
      subTitleColor: '#707278',
      borderLeft: '#41434B',
      borderLeftActive: '#83858A'
    },
    voteIcon: {
      fillColor: '#B3B4B7',
      backgroundColor: '#2E3039',
      activeBackgroundColor: '#EB8117'
    },
    labelTitleColor: '#C6C7C9',
    labelIconColor: '#83858A',
    labelSwich: '#EB8117',
    labelTrackColor: '#41434B',
    labelThumbColor: '#D9D9DB',
    labelBorder: '#373A42',
    stars: {
      starColor: '#3F4A55',
      starColorActive: '#CDAC4B',
      progressStarColor: '#2E3039'
    },
    toolbar: {
      backgroundColor: '#11141E',
      closeButtonBackground: '#B3B4B7',
      closeIconColor: '#1B1D27',
      textColor: '#707278'
    },
    confirmationModal: {
      title: '#ECECED',
      description: '#B3B4B7',
      buttonBorder: '#ffffff4d'
    },
    scoringLabelEnded: {
      color: '#C84D6B',
      backgroundColor: `rgba(221, 66, 103, 0.16)`
    },
    scoringLabelStarts: {
      color: '#389D52',
      backgroundColor: `rgba(52, 199, 89, 0.10)`
    },
    sketch: {
      color: '#FFFFFF'
    },
    postHiddenTag: {
      title: '#ECECED',
      description: '#C6C7C9',
      background: '#2E3039',
      icon: '#ECECED'
    },
    carousel: {
      dot: '#29313A',
      activeDot: '#DEE2E6'
    },
    addButton: {
      border: '#ffffff4d',
      text: '#C6C7C9'
    },
    sketchVoteButton: {
      border: '#41434B',
      line: '#41434B'
    },
    pollChoice: {
      border: '#ffffff29',
      text: '#C6C7C9',
      placeholder: '#54565D',
      activeBorder: '#83858A',
      fillBar: '#ffffff14'
    },
    pollAddDeleteButton: {
      icon: '#83858A',
      border: '#373A42'
    }
  }
}

export const useCommunitiesTheme = () => {
  const { isDark } = useBusinessTheme()
  const { business } = useBusinessSelector()
  const brandColors = business?.brandConfig?.colors

  return useMemo(
    () => (isDark ? darkTheme(brandColors) : lightTheme(brandColors)),
    [brandColors, isDark]
  )
}

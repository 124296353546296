import React from 'react'
import { FlatList, View } from 'react-native'
import { RichEditor } from 'react-native-pell-rich-editor'
import { useQueries } from '@tanstack/react-query'
import { PublicProfileSnapshot } from '@vatom/sdk/core'
import { observer } from 'mobx-react-lite'

import { fetchMemberData, useMembers } from '../../../queries'
import { useCommunitiesTheme } from '../../../themes'
import MemberItem from '../../MemberItem'
import { useStoreContext } from '../../MSTContextProvider'

const sanitizeMentions = (htmlString: string) => {
  // Regular expression to match the entire mention block
  const mentionBlockRegex = /<mention id="[^"]+" class="mention">(.*?)<\/mention>/g

  // Function to extract the innermost mention
  const extractInnermostMention = (mentionBlock: string) => {
    const mentionRegex = /<mention id="([^"]+)" class="mention">([^<]+)<\/mention>/
    const match = mentionRegex.exec(mentionBlock)
    return match ? match[0] : mentionBlock
  }

  // Replace each mention block with its innermost mention
  return htmlString.replace(mentionBlockRegex, match => {
    return extractInnermostMention(match)
  })
}

const Members = ({
  roomId,
  searchString,
  onPress
}: {
  roomId: string
  searchString?: string
  onPress: (memberId: string, tag?: string) => void
}) => {
  const communitiesTheme = useCommunitiesTheme()
  const trueSearchString = searchString ? searchString.replace('@', '').toLowerCase() : ''
  const { data: roomMembers } = useMembers(roomId)
  const members = useQueries({
    queries:
      roomMembers?.map(member => {
        const userId = member.id.split('@').pop()?.split(':')[0]
        return {
          queryKey: ['member-data', userId],
          queryFn: () => fetchMemberData(userId),
          select: (data: { data: PublicProfileSnapshot }) => {
            const { id, name, picture, preferred_username } = data.data
            return {
              id: member.id,
              name,
              picture,
              tag: preferred_username,
              userID: id
            }
          }
        }
      }) ?? []
  })

  let filtered = members.map(member => {
    return member.data
  })
  filtered = filtered.filter(member => member?.tag?.toLowerCase().includes(trueSearchString)) ?? []

  // const [lastSearchString] = useDebounce(trueSearchString, 250)

  // const destination = validateDestination(lastSearchString)
  // const algoliaContacts = useGetAlgoliaContacts(destination)
  // console.log(algoliaContacts)

  return filtered.length > 0 ? (
    <View
      style={{
        bottom: 0,
        width: '100%',
        backgroundColor: communitiesTheme.background,
        borderTopWidth: 1,
        borderTopColor: '#FFFFFF',
        height: 300
      }}
      pointerEvents="box-none"
    >
      <FlatList
        data={filtered}
        renderItem={({ item }) =>
          item ? <MemberItem onPress={() => onPress(item.id, item.tag)} member={item} /> : null
        }
        keyExtractor={(item, index) => `${item?.id}-${index}`}
      />
    </View>
  ) : null
}

const ModalMentions = observer(
  React.forwardRef<RichEditor, { roomID: string }>((props, ref) => {
    const { roomID } = props
    const [mentions, setMentions] = React.useState<string[]>([])
    const [searchString, setSearchString] = React.useState('')
    const [wasClicked, setWasClicked] = React.useState(false)

    const store = useStoreContext()

    const storeInput = store.inputs.find(sinput => sinput.type === 'text')
    const inputValue = (storeInput?.value ?? '') as string

    React.useEffect(() => {
      const simpleDescription = inputValue
        .replace(/<\/div>/gi, ' ')
        .replace(/<br\s*\/?>/gi, ' ')
        .replaceAll(/<\/?[^>]+(>|$)/gi, '')
        .replace(/&nbsp;/g, ' ')
      const mentionRegex = /\B@\s*(\w+)/g
      let match
      const currentMentions = []

      while ((match = mentionRegex.exec(simpleDescription)) !== null) {
        currentMentions.push(match[0])
      }

      const prevMentionsSet = new Set(mentions)

      const diff =
        mentions.length > 0
          ? currentMentions.find(mention => !prevMentionsSet.has(mention)) ?? ''
          : ''
      setSearchString(diff)
      store.setIsMentionsOpen(diff.length > 0 && !wasClicked)
      setMentions(currentMentions)
      setWasClicked(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue])

    const onPress = (memberId: string, tag?: string) => {
      const regex = new RegExp(`${searchString}(?=\\s|$|\\W)`, 'g')
      const replacedString = inputValue.replace(
        regex,
        `<mention id="${memberId}" class="mention">@${tag}</mention>&nbsp;`
      )

      const result = sanitizeMentions(replacedString)

      // @ts-expect-error we know current exist so I'm not sure how to fix this
      ref?.current?.command(`$.execCommand('selectAll', false)`)
      // @ts-expect-error we know current exist so I'm not sure how to fix this
      ref?.current?.command(`$.execCommand('insertHTML', false, '${result}')`)
      setSearchString('')
      setWasClicked(true)
    }

    return searchString && store.isMentionsOpen ? (
      <Members roomId={roomID} searchString={searchString} onPress={onPress} />
    ) : null
  })
)

export default ModalMentions

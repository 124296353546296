import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useMatrixUser } from '@vatom/sdk/react'
import { IEvent } from 'matrix-js-sdk'

import { useMessagePollVotes } from '../../../../queries'
import { useCommunitiesTheme } from '../../../../themes'
import PollElement from '../PollElement'
import TextParagraph from '../TextParagraph'

type PollProps = {
  question: any
  spaceId: string
  eventID: string
  isLocked: boolean
  roomID: string
}

const PollComponent = (props: PollProps) => {
  const { question, spaceId, eventID, isLocked, roomID } = props
  const communitiesTheme = useCommunitiesTheme()
  const { data: matrixUser } = useMatrixUser()
  const userId = matrixUser?.user_id

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useMessagePollVotes(
    roomID,
    eventID,
    'question.vote'
  )
  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage()
  }

  const questionVotes = data?.pages.flatMap(group => {
    const questionVotes: IEvent[] = group?.chunk?.filter(
      (pollVote: IEvent) => pollVote.content.body.questionIndex === question.id
    )
    return questionVotes?.map(pollVote => ({
      index: pollVote.content.body.index,
      sender: pollVote.sender,
      eventId: pollVote.event_id
    }))
  })

  if (!questionVotes) {
    return null
  }

  const hasUserVoted = !!questionVotes.find(vote => vote.sender === userId)

  const { borderLeft, borderLeftActive } = communitiesTheme.sketches

  return (
    <View
      style={[
        { ...styles.container, borderLeftColor: hasUserVoted ? borderLeftActive : borderLeft },
        { backgroundColor: communitiesTheme.sketches.backgroundColor }
      ]}
    >
      <Text style={[styles.questionNumber, { color: communitiesTheme.sketches.subTitleColor }]}>
        {question.id + 1}
      </Text>
      <Text style={[styles.title, { color: communitiesTheme.sketches.titleColor }]}>
        {question.title}
      </Text>
      <View style={{ marginBottom: 16 }}>
        <TextParagraph spaceId={spaceId} text={question.text} />
      </View>
      {question.pollChoices?.map((choice: any, index: number) => {
        return (
          <PollElement
            key={index}
            choice={choice}
            pollVotes={questionVotes}
            messageId={eventID}
            roomId={spaceId}
            questionId={question.id}
            isLocked={isLocked}
          />
        )
      })}
      {question.responseAnchor ? (
        <Text style={styles.anchor}>{question.responseAnchor}</Text>
      ) : null}
    </View>
  )
}

export default PollComponent

const styles = StyleSheet.create({
  container: {
    paddingVertical: 20,
    paddingHorizontal: 16,
    marginTop: 8,
    borderRadius: 4,
    borderLeftWidth: 2
  },
  title: {
    fontSize: 16,
    lineHeight: 22,
    fontWeight: '600',
    marginBottom: 4
  },
  anchor: {
    fontSize: 11,
    lineHeight: 15,
    color: '#868E96',
    marginTop: 8
  },
  questionNumber: {
    fontSize: 13,
    lineHeight: 18,
    fontWeight: '500',
    position: 'absolute',
    right: 10,
    top: 10
  }
})

import React, { useMemo, useState } from 'react'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { CompositeRegion, RegionType, TokenType } from '@vatom/sdk/core'
import { useSDK, useThrottle } from '@vatom/sdk/react'
import { LocationAccuracy, LocationObject } from 'expo-location'
import { observer } from 'mobx-react-lite'
import { useThrottledCallback } from 'use-debounce'

import ARViewerWeb from '../../components/ARViewerWeb'
import { useBusinessSelector } from '../../hooks/useBusinessSelector'
import { useCurrentLocation } from '../../hooks/useCurrentLocation'
import { AppNavigation } from '../../navigators'
import { getBoundingBoxFromRegion } from '../../utils/map'
import PickUpModal from '../Maps/partials/PickUpModal'

/** Response for useTokensInRadius() */
interface TokensInRadiusResponse {
  /** The current location */
  location?: LocationObject
  /** The tokens in the radius */
  tokens: TokenType[]
  /** True if the region is still loading */
  isLoading: boolean
  /** The region that was used to fetch the tokens */
  region?: CompositeRegion
}

/** React hook to fetch all tokens within a radius around the user */
export const useTokensInRadius = (radiusInMeters: number): TokensInRadiusResponse => {
  // Get the SDK
  const sdk = useSDK()
  const { business } = useBusinessSelector()

  // Get the current location
  const { location } = useCurrentLocation({
    locationOptions: {
      accuracy: LocationAccuracy.Balanced,
      distanceInterval: 50
    }
  })

  // Get region to monitor
  const region: CompositeRegion | undefined = useMemo(() => {
    if (!location?.coords) return
    const LATITUDE_DELTA = Math.abs(radiusInMeters / 111111.1)
    const LONGITUDE_DELTA = Math.abs(
      radiusInMeters / (111111.1 * Math.cos(location.coords.latitude))
    )

    const regionInfo = {
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
      latitudeDelta: LATITUDE_DELTA,
      longitudeDelta: LONGITUDE_DELTA
    }

    const boundingBox = getBoundingBoxFromRegion(regionInfo)
    if (boundingBox) {
      return sdk.dataPool.region(RegionType.geopos, JSON.stringify({ ...boundingBox }))
    }
  }, [location, radiusInMeters, sdk.dataPool])

  const filteredTokens = !business?.id
    ? region?.ARTokens
    : region?.ARTokens.filter(token => token.studioInfo?.businessId === business.id)

  // TODO: How do we monitor the region for changes? Ie if a new vatom gets dropped while AR is open?

  // Get the tokens
  return {
    location,
    region,
    tokens: filteredTokens ?? [],
    isLoading: !region || region?.isLoading
  }
}

const AR = observer(props => {
  const sdk = useSDK()
  const { location, tokens, isLoading } = useTokensInRadius(500)
  const focused = useIsFocused()
  // Selected token for the confirmation dialog
  const [tokenForPickupConfirmation, setTokenForPickupConfirmation] = useState<TokenType>()
  const [openModal, setOpenModal] = useState(false)

  const onSelectToken = useThrottledCallback((token: TokenType) => {
    setOpenModal(true)
    setTokenForPickupConfirmation(token)
  }, 300)

  const onCloseModal = useThrottledCallback(() => {
    setOpenModal(false)
    setTokenForPickupConfirmation(undefined)
  }, 300)

  // // Stop if still loading the region
  // if (isLoading) return <LoaderView />

  // // Render UI
  return (
    <>
      {/* Token AR */}
      {focused && (
        <ARViewerWeb sdk={sdk} tokens={tokens} onTokenSelect={token => onSelectToken(token)} />
      )}

      {/* Pickup confirmation dialog */}

      <PickUpModal
        userLocation={location?.coords}
        token={tokenForPickupConfirmation}
        onPickUp={onCloseModal}
        onCancel={onCloseModal}
        isOpen={openModal}
      />
    </>
  )
})

export { AR }

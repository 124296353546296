import React from 'react'
import { Box, Text } from 'native-base'

import Boolean from './Boolean'
import CountryAndRegionField from './CountryAndRegionField'
import DateField from './DateField'
import EnumField from './EnumField'
import MultiBoolField from './MultiBoolField'
import StringField from './StringField'
import TelField from './TelField'
import { fieldPropsInterface, fieldsInterface } from './Types'
import UnknownField from './UnknownField'

export const FIELDS: fieldsInterface = {
  boolean: Boolean,
  countryAndRegion: CountryAndRegionField,
  date: DateField,
  email: StringField,
  // @ts-expect-error in order for this to get fixed, the EnumField Platform.select need to fill all types of platforms
  enum: EnumField,
  multibool: MultiBoolField,
  number: TelField,
  string: StringField,
  tel: TelField
}

export default function Field(props: fieldPropsInterface) {
  const Field = FIELDS[props.field.type as keyof typeof FIELDS] ?? UnknownField

  return (
    <Box>
      <Field {...props} />

      {props.field.name === 'jingleball_age' ? (
        <Text fontWeight="400" fontStyle="italic" fontSize="13px" color="#868E96" marginTop="1rem">
          {atob(
            'VGhlIGZvbGxvd2luZyBxdWVzdGlvbnMgd2lsbCBoZWxwIFBmaXplciBwcm92aWRlIHlvdSB3aXRoIHRoZSBsYXRlc3QgbmV3cyByZWdhcmRpbmcgQ09WSUQtMTkgYW5kIGEgQ09WSUQtMTkgdmFjY2luZSBvcHRpb24u'
          )}
        </Text>
      ) : null}
    </Box>
  )
}

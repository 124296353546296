import React, { useEffect, useRef } from 'react'
import { StyleSheet } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { PreCreatedVatom, useCrateStore } from '@vatom/sdk/react'
import { theme } from '@vatom/wombo'
import AnimatedLottieView from 'lottie-react-native'
import { FlatList, Image, Text, View } from 'native-base'

import { useIsDesktop } from '../../hooks/useIsDesktop'
import LottieAnimation from '../LottieAnimation'

import animationData from './confetti-lottie.json'

const minheight = 400

const RewardsCard = () => {
  const translateY = useSharedValue(0)
  const { shouldShowCrateRewards, crateNotification, shouldShowConfetti } = useCrateStore()
  const isDesktop = useIsDesktop()
  const animationRef = useRef<AnimatedLottieView>(null)

  useEffect(() => {
    const timing = 500

    if (shouldShowCrateRewards) {
      translateY.value = withTiming(-(minheight + 50), { duration: timing })

      if (crateNotification?.config.showConfetti) {
        setTimeout(() => {
          animationRef.current?.play()
        }, timing)
      }
    } else {
      translateY.value = withTiming(0, { duration: timing })
    }
  }, [translateY, shouldShowCrateRewards, crateNotification])

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: translateY.value }]
    }
  })

  if (!crateNotification) return null

  const truncatedData =
    crateNotification.tokens.length >= 4
      ? crateNotification.tokens.slice(0, 4)
      : crateNotification.tokens

  const showFooter = crateNotification.tokens.length >= 4

  const renderItem = ({ item }: { item: PreCreatedVatom }) => (
    <View style={styles.itemContainer}>
      <Image
        alt={item.name}
        source={{ uri: item.icon }}
        w={100}
        h={100}
        borderRadius={8}
        resizeMode="cover"
        accessibilityIgnoresInvertColors
      />
      <Text
        color={'#3F4A55'}
        fontSize={13}
        isTruncated
        maxW={20}
        noOfLines={2}
        mt={2}
        textAlign="center"
      >
        {item.name}
      </Text>
    </View>
  )

  const renderFooter = () => (
    <View style={styles.itemContainer}>
      <Text style={styles.footerText}>and more...</Text>
    </View>
  )
  console.log('CRATE.crateNotification', crateNotification)
  console.log(
    'CRATE.crateNotification.crateNotification?.config.showConfetti ',
    crateNotification?.config.showConfetti
  )

  return (
    <Animated.View
      style={[
        {
          maxWidth: 500,
          position: 'absolute',
          bottom: -minheight,
          left: 0,
          right: 0,
          backgroundColor: theme.colors.white,
          padding: 10,
          zIndex: 999,
          borderRadius: 20,
          alignItems: 'center',
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 4
          },
          shadowOpacity: 0.25,
          shadowRadius: 4,
          elevation: 5,
          margin: isDesktop ? 'auto' : undefined,
          marginHorizontal: !isDesktop ? 30 : undefined
        },
        animatedStyle
      ]}
    >
      <View width="100%" h="100%" position="absolute" top="-50%" zIndex="100">
        {crateNotification?.config.showConfetti && shouldShowConfetti ? (
          <LottieAnimation
            source={animationData}
            ref={animationRef}
            loop={false}
            autoPlay={false}
            options={{
              loop: false,
              autoplay: false,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
          />
        ) : null}
      </View>

      <Text color="#11141E" fontFamily="Inter-SemiBold" fontSize="22" fontWeight="600" my="3">
        Congratulations!
      </Text>
      <Text color="#3F4A55" fontSize="15" marginBottom="7">
        You received...
      </Text>
      <View w="100%" alignItems="center">
        <FlatList
          data={truncatedData}
          numColumns={2}
          renderItem={renderItem}
          keyExtractor={(item, index) => index.toString()}
          ListFooterComponent={showFooter ? renderFooter : null}
        />
      </View>
    </Animated.View>
  )
}

export default RewardsCard

const styles = StyleSheet.create({
  itemContainer: {
    alignItems: 'center',
    marginBottom: 10,
    marginLeft: 10,
    padding: 8
  },

  footerText: {
    textAlign: 'center',
    fontStyle: 'italic'
  }
})

// const crateNotification = {
//   tokens: [
//     {
//       icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQU9q64eUpt6dKYf_R4fC8UyJ5zA-iVFAmhlA&usqp=CAU',
//       name: 'Lakeside Lakeside Lakeside Lakeside'
//     },
//     {
//       icon: 'https://resources.vatominc.com/rUBK9GTeKK/286dc6c0-e928-11ed-985e-6d7b9b780f7c.png?1683059424953',
//       name: 'Lakeside'
//     }
//   ],
//   config: {
//     showConfetti: true,
//     toastAppearanceTime: 8,
//     video: {
//       ref: '',
//       type: 'string'
//     }
//   }
// }

import React, { useCallback, useState } from 'react'
import { Modal } from 'react-native'
import { useGetPublicProfile, UserData } from '@vatom/sdk/react'
import { Box } from 'native-base'

import logger from '../logger'
import { CameraScanner } from '../screens/Camera/partials/CameraScanner'

import { useMaxWidth } from './/WebWrapper'
import Alert from './Alert'

type CameraScannerModalProps = {
  isOpen: boolean
  onDismiss: () => void
  processLink: (link: string) => void
}

export const CameraScannerModal = (props: CameraScannerModalProps) => {
  const maxWidth = useMaxWidth()
  return (
    <Modal
      visible={props.isOpen}
      onDismiss={props.onDismiss}
      transparent
      style={{
        maxWidth: maxWidth
      }}
    >
      <Box left={0} right={0} position={'absolute'} bottom={0} top={0} safeAreaTop>
        <CameraScanner
          onScanner={data => {
            data.data && props.processLink(data.data)
          }}
          onClose={props.onDismiss}
        />
      </Box>
    </Modal>
  )
}
type CameraScannerModalUserProps = Omit<CameraScannerModalProps, 'processLink'> & {
  onSuccess: (contact: UserData) => void
}
export const CameraScannerModalUser = (props: CameraScannerModalUserProps) => {
  const { onSuccess, ...rest } = props
  const [contactId, setContactId] = useState<string>()

  useGetPublicProfile(contactId, {
    enabled: !!contactId,
    onSuccess: contact => {
      if (contactId) {
        logger.info('contact', contact)
        if (contact && contact.id) {
          onSuccess(contact)
          return
        }

        Alert.fire({
          title: 'Contact not found',
          text: 'Please try again'
        })
      }
    }
  })

  const processLink = useCallback((link: string) => {
    // https://wallet.vatom.com/receive/0e37v9l
    const id = link.split('/').pop()
    if (id) setContactId(id)
  }, [])

  return <CameraScannerModal processLink={processLink} {...rest} />
}

import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { useMutation } from '@tanstack/react-query'
import { translate } from '@vatom/utils'
import { GradientButton, PressableOpacity, Text, theme } from '@vatom/wombo'
import { Box, Link, VStack } from 'native-base'

import { getWallet } from '../../../../../modules/web3-wallet'
import CloseIcon from '../../../assets/close.svg'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { AppRoutes } from '../../../navigators'
import NewWalletImg from '../assets/new-wallet.svg'
import { useWalletDetails } from '../store'

export const Start = () => {
  const { isDark } = useBusinessTheme()
  const navigation = useNavigation()

  const createWalletMutation = useMutation({
    mutationFn: async () => {
      return await getWallet()
    },
    onSuccess: data => {
      useWalletDetails.getState().setWalletDetails(data.address, data.words)
      navigation.navigate(AppRoutes.NewWalletBackup)
    }
  })

  return (
    <Box flex={1} safeArea={true}>
      <VStack height="100%" w="100%" flex={1} px="16px">
        <Box alignItems="flex-start">
          <PressableOpacity
            hitSlop={{
              top: 20,
              left: 20,
              bottom: 20,
              right: 20
            }}
            accessibilityRole="button"
            onPress={() => navigation.goBack()}
          >
            <CloseIcon
              height={40}
              width={40}
              fill={!isDark ? theme.colors.darkText : theme.colors.white}
            />
          </PressableOpacity>
        </Box>
        <Box flex={1} justifyContent="center" alignItems="center" height="100%">
          <Text
            marginBottom="20px"
            color={!isDark ? theme.colors.darkText : theme.colors.white}
            fontFamily="Inter-Bold"
            fontSize="34px"
            textAlign="center"
            lineHeight="41px"
            tx="newWalletScreen.startTitle"
          />

          <NewWalletImg height={250} width={260} />

          <Text
            w="100%"
            fontFamily="Inter-Regular"
            fontSize="15px"
            textAlign="center"
            mt={10}
            color={!isDark ? theme.colors.darkText : theme.colors.white}
          >
            {translate('newWalletScreen.startDescription')}{' '}
            <Link
              href="#"
              isExternal={true}
              _text={{
                color: 'blue.400',
                fontSize: '15px'
              }}
              mt={-0.5}
              _web={{
                mb: -2
              }}
            >
              {translate('common.learnMore')}
            </Link>
          </Text>
        </Box>
        <Box justifyContent="center" width="100%" pb={20}>
          <GradientButton
            onPress={() => {
              if (useWalletDetails.getState().seeds === '') {
                createWalletMutation.mutate()
              } else {
                navigation.navigate(AppRoutes.NewWalletBackup)
              }
            }}
            h="52px"
            w="100%"
            py="15px"
            px="30px"
            disabled={createWalletMutation.isLoading}
            alignItems="center"
          >
            <Text alignSelf="center" fontFamily="Inter" color="white" fontSize={16} lineHeight={22}>
              {createWalletMutation.isLoading ? 'Creating your wallet now' : 'Continue'}
            </Text>
          </GradientButton>
        </Box>
      </VStack>
    </Box>
  )
}

export default Start

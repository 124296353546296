import React, { forwardRef } from 'react'
import { Keyboard, Platform, TextInput } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { useAddRecentContact, useBusiness, useWeb3 } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { Avatar, Status, Text, theme, Toast } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Center, HStack, KeyboardAvoidingView, VStack } from 'native-base'

import { HeaderButtonClose, ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import { useBusinessSelector } from '../../hooks/useBusinessSelector'
import { useFungibleToken } from '../../hooks/useFungibleTokens'
import { useIsDesktop } from '../../hooks/useIsDesktop'
import { useLoyalty } from '../../hooks/useLoyalty'
import { useUserById } from '../../hooks/useUserById'
import logger from '../../logger'
import { AppNavigation, AppRoutes, WalletRoutes } from '../../navigators'
import { useCoinTheme } from '../CoinDetail/hooks/useCoinTheme'
import { CoinAvatar } from '../CoinDetail/partials'
import { CoinNavigatorParamList } from '../CoinNavigation/CoinNavigation'
import { useCoinNavigation } from '../CoinNavigation/useCoinNavegation'
import { useCoinStore } from '../CoinNavigation/useCoinStore'
import { ButtonBack, ButtonNext } from '../CoinTransfer/partials/Buttons'

import Arrow from './assets/long-arrow.svg'
import { CommentInput } from './components/CommentInput'
import UserIdentities from './components/UserIdentities'
import { useTransferState } from './hooks/useTransferState'

export type CoinConfirmationProps = {
  navigation: NavigationProp<CoinNavigatorParamList>
}

export const CoinConfirmation = observer(
  forwardRef((props: CoinConfirmationProps, ref) => {
    const navigation = useNavigation<AppNavigation>()
    const insets = useSafeAreaInsets()
    const isDesktop = useIsDesktop()
    const { onBack, onClose } = useCoinNavigation()

    const { businessIdentifier } = useBusinessSelector()
    const { coinId, contact, toUserId, amount, businessId, source } = useCoinStore.getState()
    const { detail, setDetail, setWeb3Confirmation } = useCoinStore()
    const { transferState, startTransfer, completeTransfer, failTransfer } = useTransferState()
    const { getCoinById, transferCoin } = useLoyalty()

    const fungibleToken = useFungibleToken(coinId)

    const coin = getCoinById(coinId) || fungibleToken

    const { data: business } = useBusiness({ business: businessId })
    const addRecentContactMutation = useAddRecentContact()
    const [web3address, setWeb3address] = React.useState<string>('')

    const inputRef = React.createRef<TextInput>()

    const styles = useCoinTheme({ business })

    const coinImage = coin?.logo
    const coinName = coin?.name

    const { data: user } = useUserById(toUserId)
    const userName = contact?.name || user?.name
    const userImage = contact?.avatarUrl ?? user?.picture
    const userImageShowBorder = userImage ? 0 : 1
    const web3 = useWeb3(coin)

    const onPressSend = async () => {
      try {
        Keyboard.dismiss()
        startTransfer()
        const transferPayload = {
          coinId: coinId,
          businessId: businessId,
          userId: toUserId,
          points: amount,
          detail: detail,
          source: source
        }
        const response = await transferCoin(transferPayload)
        if (!response.success) {
          throw new Error('CoinConfirmation.TransferCoin: Error sending coin')
        }

        if (contact) addRecentContactMutation.mutate(contact)

        completeTransfer()
        // await coins.refetch()
        Toast({
          title: translate('coinsScreen.sendSuccess', { coinName }),
          placement: 'top',
          status: Status.success,
          withMargin: true
        })
        if (businessIdentifier) {
          navigation.navigate(AppRoutes.BusinessProxy, {
            business: businessIdentifier,
            screen: WalletRoutes.Coins
          })
        } else {
          navigation.navigate(AppRoutes.home, {
            screen: WalletRoutes.Coins
          })
        }
      } catch (error) {
        logger.error(error)
        failTransfer()
        Toast({
          title: translate('errors.default'),
          placement: 'top',
          status: Status.error,
          withMargin: true
        })
      }
    }

    const onSendFungibleToken = async () => {
      try {
        if (!coin) {
          // throw new Error('Coin transfer failed')
          throw new Error(translate('coinsScreen.coinTransferFailed'))
        }

        if (!web3.isConnected) {
          await web3.open()
          return
        }

        Keyboard.dismiss()

        startTransfer()
        const response = await web3.sendFungibleToken(web3address, amount, coin)

        if (response) {
          if (contact) addRecentContactMutation.mutate(contact)

          completeTransfer()

          setWeb3Confirmation({
            hash: response,
            to: web3address,
            chain: coin.chain
          })

          props.navigation.navigate('CoinConfirmationWeb3')
        }
      } catch (error: any) {
        console.log('onSendFungibleToken.error: ', error.message)
        failTransfer()
        // get error message
        const errorMessage = error.message
        Toast({
          title: errorMessage,
          placement: 'top',
          status: Status.error,
          withMargin: true
        })
      }
    }

    const SendContent = (
      <Box flex={1} pt={4}>
        <Center>
          <Text
            _light={{
              color: theme.colors.textLightMode[600]
            }}
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
            fontSize={34}
            lineHeight={41}
            fontFamily={'Inter-Regular'}
            fontWeight={'700'}
          >
            {`${amount} ${coin?.name}`}
          </Text>
          <Text
            mt={2}
            _light={{
              color: theme.colors.textLightMode[300]
            }}
            _dark={{
              color: theme.colors.textDarkMode[300]
            }}
            tx="coinsScreen.sendCoins"
            txOptions={{
              amount,
              coinName: coin?.name,
              user: userName
            }}
          />
        </Center>
        <Box flexDirection={'row'} justifyContent={'center'} alignItems={'center'} mt={5}>
          <Center>
            <Box>
              <CoinAvatar
                // Coin
                source={coinImage || ''}
                size={100}
                name={coinName || ''}
                containerProps={{ zIndex: 0 }}
              />
              {/* <CoinAvatar
                    // Network
                    source={networkImage}
                    size={32}
                    name={''}
                    containerProps={{ zIndex: 1, position: 'absolute', bottom: 0, right: 0 }}
                  /> */}
            </Box>
          </Center>
          <Center
            width={16}
            borderRadius={999}
            height={16}
            mx={4}
            shadow={1}
            borderWidth={1}
            _light={{
              borderColor: theme.colors.grayCool[200],
              backgroundColor: theme.colors.white
            }}
            _dark={{
              borderColor: theme.colors.grayDarkMode[900],
              backgroundColor: theme.colors.grayDarkMode[700]
            }}
          >
            <Arrow width={24} height={16} fill={theme.colors.lightText} />
          </Center>
          <Avatar size={100} borderWidth={userImageShowBorder} url={userImage} name={userName} />
        </Box>

        {user && coin?.isWeb3 ? (
          <UserIdentities user={user} coin={coin} setWeb3address={setWeb3address} />
        ) : null}

        <Box mt={8} px={4}>
          <CommentInput
            ref={inputRef}
            comment={detail}
            setComment={setDetail}
            autoCompleteType={false}
          />
        </Box>
      </Box>
    )

    const DesktopLayout = (
      <Box
        ref={ref}
        backgroundColor={styles.background}
        borderRadius={10}
        padding={6}
        shadow={'modal'}
        _web={{
          minHeight: '50vh'
        }}
      >
        <ScreenHeader
          headerLeftType="none"
          headerProps={{
            minHeight: 8, // 32px
            paddingX: 0
          }}
          headerRight={() => (
            <ScreenHeaderButton onPress={onClose}>
              <HeaderButtonClose color={styles.textColor} />
            </ScreenHeaderButton>
          )}
        />

        <VStack flex={1} alignItems={'stretch'} width="100%" marginY={8}>
          {SendContent}
        </VStack>

        <HStack
          width={'100%'}
          alignSelf={'flex-end'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <ButtonBack onPress={onBack}>{translate('common.back')}</ButtonBack>
          <Box>
            {coin?.isWeb3 ? (
              <ButtonNext
                m={4}
                backgroundColor={styles.primary}
                onPress={onSendFungibleToken}
                disabled={
                  web3address === '' || transferState.isOnGoing || transferState.isCompleted
                }
              >
                {translate(web3.isConnected ? 'common.send' : 'common.connect')}
              </ButtonNext>
            ) : (
              <ButtonNext
                m={4}
                backgroundColor={styles.primary}
                onPress={onPressSend}
                disabled={transferState.isOnGoing || transferState.isCompleted}
              >
                {translate('common.send')}
              </ButtonNext>
            )}
          </Box>
        </HStack>
      </Box>
    )

    if (isDesktop) {
      return DesktopLayout
    }

    return (
      <KeyboardAvoidingView
        paddingBottom={insets.bottom}
        flex={1}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        <ScreenWrapper
          screenBackgroundColor={styles.background}
          statusBarBackgroundColor={styles.primary}
        >
          <ScreenHeader headerLeftPress={onClose} headerTextColor={styles.textColor}>
            <Title
              preset="h5"
              variant="semibold"
              color={styles.textColor}
              textAlign={'center'}
              tx="common.send"
            />
          </ScreenHeader>

          {SendContent}

          <Box>
            {coin?.isWeb3 ? (
              <ButtonNext
                m={4}
                backgroundColor={styles.primary}
                onPress={onSendFungibleToken}
                disabled={
                  web3address === '' || transferState.isOnGoing || transferState.isCompleted
                }
              >
                {translate(web3.isConnected ? 'common.send' : 'common.connect')}
              </ButtonNext>
            ) : (
              <ButtonNext
                m={4}
                backgroundColor={styles.primary}
                onPress={onPressSend}
                disabled={transferState.isOnGoing || transferState.isCompleted}
              >
                {translate('common.send')}
              </ButtonNext>
            )}
          </Box>
        </ScreenWrapper>
      </KeyboardAvoidingView>
    )
  })
)

export default CoinConfirmation

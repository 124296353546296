import React from 'react'

import { WebWrapper } from '../../components/WebWrapper'

import { MintSuccess, MintSuccessProps } from './MintSuccess'

export default (props: MintSuccessProps) => {
  return (
    <WebWrapper justifyContent={'center'} flex={1} flexShrink={0}>
      <MintSuccess {...props} />
    </WebWrapper>
  )
}

import React, { useCallback, useMemo } from 'react'
import { WalletPreferenceSortBy } from '@vatom/sdk/core'
import { useSDK } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { SwitchBase, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, HStack, Select, useColorModeValue, VStack } from 'native-base'

import { AngleDownIcon } from '../../components/Icons'
import { ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'

export const Settings = () => {
  const { pageTheme } = useBusinessTheme()

  const headerBorderColor = useColorModeValue(
    theme.colors.grayCool[300],
    theme.colors.grayDarkMode[700]
  )
  const headerIconColor = useColorModeValue(
    theme.colors.textLightMode[900],
    theme.colors.textDarkMode[100]
  )

  return (
    <ScreenWrapper
      screenBackgroundColor={pageTheme.background}
      statusBarBackgroundColor={pageTheme.background}
      viewContainerStyle={{
        width: '100%',
        backgroundColor: pageTheme.background
      }}
      // unsafe
    >
      <ScreenHeader
        headerLeftType={'back'}
        headerTextColor={headerIconColor}
        headerProps={{
          borderBottomColor: headerBorderColor,
          borderBottomWidth: 1,
          overflow: 'hidden'
        }}
        centerContainerProps={{
          flex: 1,
          paddingX: 2
        }}
        leftContainerProps={{
          flex: 0,
          _web: {
            flexGrow: 0,
            flexShrink: 1,
            flexBasis: 'auto'
          }
        }}
        rightContainerProps={{
          flex: 0,
          _web: {
            flexGrow: 0,
            flexShrink: 1,
            flexBasis: 'auto'
          }
        }}
        headerRight={() => <ScreenHeaderButton />}
      >
        <Title preset="h5" variant="semibold" numberOfLines={1}>
          {translate('common.settings')}
        </Title>
      </ScreenHeader>
      <HStack paddingX={4} marginTop={3}>
        <VStack flexGrow={1}>
          <Title preset="h5">{translate('common.walletPreferences')}</Title>
          <WalletPreferences />
        </VStack>
      </HStack>
    </ScreenWrapper>
  )
}

const WalletPreferences = observer(() => {
  const sdk = useSDK()

  const sortByOptions = [
    {
      key: WalletPreferenceSortBy.Newest,
      label: translate('common.newest')
    },
    {
      key: WalletPreferenceSortBy.Oldest,
      label: translate('common.oldest')
    },
    {
      key: WalletPreferenceSortBy.Ascending,
      label: 'A - Z'
    },
    {
      key: WalletPreferenceSortBy.Descending,
      label: 'Z - A'
    }
  ]

  const onSortByItemPress = useCallback(
    (sortBy: WalletPreferenceSortBy) => {
      sdk.walletPreference.setSortBy(sortBy)
    },
    [sdk.walletPreference]
  )

  const onToggleTitle = useCallback(() => {
    sdk.walletPreference.setShowTitles(!sdk.walletPreference.showTitles)
  }, [sdk.walletPreference])

  const onToggleEdition = useCallback(() => {
    sdk.walletPreference.setShowEditions(!sdk.walletPreference.showEditions)
  }, [sdk.walletPreference])

  const onToggleBadge = useCallback(() => {
    sdk.walletPreference.setShowBadges(!sdk.walletPreference.showBadges)
  }, [sdk.walletPreference])

  const onToggleDroppedNft = useCallback(() => {
    sdk.walletPreference.setShowDroppedSmartNft(!sdk.walletPreference.showDroppedSmartNft)
  }, [sdk.walletPreference])

  const switchOptions = useMemo(
    () => [
      {
        id: 'title',
        label: translate('common.showTitles'),
        getValue: () => sdk.walletPreference.showTitles,
        onToggle: onToggleTitle
      },
      {
        id: 'editions',
        label: translate('common.showEditions'),
        getValue: () => sdk.walletPreference.showEditions,
        onToggle: onToggleEdition
      },
      {
        id: 'badges',
        label: translate('common.showBadges'),
        getValue: () => sdk.walletPreference.showBadges,
        onToggle: onToggleBadge
      },
      {
        id: 'dropped',
        label: translate('common.showDropped'),
        getValue: () => sdk.walletPreference.showDroppedSmartNft,
        onToggle: onToggleDroppedNft
      }
    ],
    [
      onToggleTitle,
      onToggleEdition,
      onToggleBadge,
      onToggleDroppedNft,
      sdk.walletPreference.showTitles,
      sdk.walletPreference.showEditions,
      sdk.walletPreference.showBadges,
      sdk.walletPreference.showDroppedSmartNft
    ]
  )

  const selectIconColor = useColorModeValue(
    theme.colors.textLightMode[900],
    theme.colors.textDarkMode[100]
  )

  return (
    <Box marginTop={2}>
      <HStack flexGrow={1} marginY={2} alignItems={'center'}>
        <Title
          preset="h6"
          style={{
            flex: 1
          }}
          _light={{
            color: theme.colors.textLightMode[600]
          }}
          _dark={{
            color: theme.colors.textDarkMode[200]
          }}
        >
          {translate('common.sortBy')}
        </Title>
        {/* eslint-disable-next-line react-native-a11y/has-accessibility-hint */}
        <Select
          minWidth={120}
          dropdownIcon={
            <Box marginRight={2}>
              <AngleDownIcon size={4} fill={selectIconColor} />
            </Box>
          }
          _actionSheetContent={{
            _light: {
              backgroundColor: theme.colors.grayCool[50]
            },
            _dark: {
              backgroundColor: theme.colors.grayDarkMode[900]
            }
          }}
          _selectedItem={{
            borderRadius: 24,
            _light: {
              backgroundColor: theme.colors.systemColorsLight.orange
            },
            _dark: {
              backgroundColor: theme.colors.systemColorsDark.orange
            }
          }}
          selectedValue={sdk.walletPreference.sortBy}
          accessibilityLabel={translate('common.sortBy')}
          // placeholder="Choose an option"
          onValueChange={itemSelected => onSortByItemPress(itemSelected as WalletPreferenceSortBy)}
        >
          {sortByOptions.map(option => (
            <Select.Item
              label={option.label}
              value={option.key}
              _light={{
                backgroundColor: theme.colors.grayCool[50]
              }}
              _dark={{
                backgroundColor: theme.colors.grayDarkMode[900]
              }}
            />
          ))}
        </Select>
      </HStack>
      <Divider />

      {switchOptions.map((option, index) => (
        <>
          <HStack flexGrow={1} marginY={2} alignItems={'center'}>
            <Title
              preset="h6"
              style={{
                flex: 1
              }}
              _light={{
                color: theme.colors.textLightMode[600]
              }}
              _dark={{
                color: theme.colors.textDarkMode[200]
              }}
            >
              {option.label}
            </Title>
            <SwitchBase value={option.getValue()} onToggle={option.onToggle} />
          </HStack>
          <Divider />
        </>
      ))}
      <Box flexGrow={1}></Box>
    </Box>
  )
})

function Divider() {
  return (
    <Box
      width={'100%'}
      style={{
        height: 1
      }}
      _light={{
        backgroundColor: theme.colors.grayCool[100]
      }}
      _dark={{
        backgroundColor: theme.colors.grayDarkMode[800]
      }}
    />
  )
}

import React from 'react'
import { isMobile } from 'react-device-detect'
import { Platform } from 'react-native'
import { LoaderView } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'

import { AppRoutes, AppStackScreenProps } from '../../navigators'

import { AudioSpace } from './AudioSpace'
import SpaceWebview from './SpaceWebview'
import { useSpaceFromNavParams } from './useSpaceFromNavParams'

type SpaceProps = AppStackScreenProps<typeof AppRoutes.Metaverse>

export const Space = observer(({ route }: SpaceProps) => {
  const { type } = route.params
  const audio = type === 'metaverse' ? false : true
  const { space, isLoading } = useSpaceFromNavParams(route.params)

  // if (isLoading || !space || shouldRedirectToRoom) {
  if (isLoading || !space) {
    return <LoaderView />
  }

  if (((isMobile || Platform.OS !== 'web') && !space?.compatibility?.mobile && !audio) || audio) {
    return <AudioSpace space={space}></AudioSpace>
  }

  return <SpaceWebview space={space}></SpaceWebview>
})

export default Space

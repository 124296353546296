import React, { useCallback, useMemo } from 'react'
import { Modal, Pressable, StyleSheet, useWindowDimensions, View } from 'react-native'
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Ionicons } from '@expo/vector-icons'
import {
  type IEvent,
  createTxnId,
  MessageReaction,
  useDmReactions,
  useMatrixUser,
  useRedactMessageReaction,
  useSendMessageReaction
} from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { theme, Toast } from '@vatom/wombo'
import { BlurTint, BlurView } from 'expo-blur'
import * as Clipboard from 'expo-clipboard'
import { Box, Text, useColorModeValue } from 'native-base'
import EmojiPicker from 'rn-emoji-keyboard'
import { EmojiType } from 'rn-emoji-keyboard/lib/typescript/src/types'
import { useThrottledCallback } from 'use-debounce'
import { create } from 'zustand'

import { CopyIcon, ReplyIcon } from '../../../components/Icons'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { useModalState } from '../../../hooks/useModalState'

import { MessageBlock, MessageElementLayout } from './Message/Message'

type MessageSelectedStore = {
  isVisible: boolean
  events: IEvent[] | null
  elementLayout: MessageElementLayout | null
  openModal: (events: IEvent[], elementLayout: MessageElementLayout) => void
  closeModal: () => void
}
const useMessageSelectedStore = create<MessageSelectedStore>(set => ({
  isVisible: false,
  events: null,
  elementLayout: null,
  openModal: (events, elementLayout) =>
    set(state => ({
      ...state,
      isVisible: true,
      events,
      elementLayout
    })),
  closeModal: () =>
    set(state => ({ ...state, isVisible: false, events: null, elementLayout: null }))
}))

export const useMessageSelectedModal = () => useMessageSelectedStore.getState()

export const MessageSelectedModal = React.memo(
  ({ roomId, onPressReply }: { roomId: string; onPressReply: (eventId: string) => void }) => {
    const { data: matrixUser } = useMatrixUser()
    const userId = useMemo(() => matrixUser?.user_id ?? '', [matrixUser])

    const { pageTheme } = useBusinessTheme()
    const insets = useSafeAreaInsets()
    const { height: windowHeight } = useWindowDimensions()
    const { elementLayout, events, isVisible, closeModal } = useMessageSelectedStore()

    const eventId = useMemo(() => events?.[0].event_id ?? '', [events])
    const { data: reactions } = useDmReactions({ roomId, eventId })

    // NOTE: only allow 1 reaction per user
    const userReaction = useMemo(() => {
      return reactions && reactions.find(reaction => reaction.sender === userId)
    }, [reactions, userId])

    const sendReactionMutation = useSendMessageReaction()
    const redactionMessageReactionMutation = useRedactMessageReaction()

    const emojiKeyboardModal = useModalState()
    const emojiPickerTheme = useEmojiKeyboardTheme()

    const hasMultipleEvents = useMemo(() => events && events?.length > 1, [events])
    // TODO: has more than one events? don't allow reactions, other types of options for those messages
    // TODO: build message options instead of using boilerplate
    const messageOptions = MESSAGE_OPTIONS
    const messageOptionsHeight = MESSAGE_OPTIONS.length * MESSAGE_OPTION_HEIGHT

    const floatingPositionOffset = useMemo(() => {
      const positionBottomOffset = insets.bottom ?? 30
      const positionTopOffset = insets.top + REACTIONS_HEIGHT + 16
      const limitTopOffset = insets.top + REACTIONS_HEIGHT

      if (!elementLayout?.pageY || !elementLayout?.height) {
        return undefined
      }

      const { pageY, height } = elementLayout
      // Close to top
      if (elementLayout.pageY < limitTopOffset) {
        return positionTopOffset
      }
      const spaceTaken = pageY + height + messageOptionsHeight
      const windowSpace = Math.floor(windowHeight - insets.bottom)

      // Close to bottom
      if (spaceTaken >= windowSpace) {
        return windowSpace - (height + messageOptionsHeight + positionBottomOffset)
      }
      return pageY
    }, [elementLayout, insets, messageOptionsHeight, windowHeight])

    const boxShadow = useColorModeValue(styles.boxShadowLight, styles.boxShadowDark)
    const blurTint: BlurTint = useColorModeValue('light', 'dark')

    const onDismiss = useThrottledCallback(() => closeModal(), 300)

    const onPressReaction = useCallback(
      async (reaction: string) => {
        if (!events) {
          return
        }
        // An user reaction exist, redacted it
        if (userReaction) {
          await redactionMessageReactionMutation.mutateAsync({
            roomId,
            eventId: userReaction.eventId,
            parentEventId: eventId
          })
        }
        // Send new reaction
        sendReactionMutation.mutate({
          roomId,
          eventId: eventId,
          reactionKey: reaction,
          transactionId: createTxnId()
        })
        closeModal()
      },
      [
        events,
        userReaction,
        sendReactionMutation,
        roomId,
        eventId,
        closeModal,
        redactionMessageReactionMutation
      ]
    )
    const onPressMore = useCallback(() => {
      emojiKeyboardModal.openModal()
    }, [emojiKeyboardModal])

    const handleKeyboardEmojiPick = useCallback(
      (emoji: EmojiType) => {
        emojiKeyboardModal.closeModal()
        if (emoji.emoji) {
          onPressReaction(emoji.emoji)
        }
      },
      [emojiKeyboardModal, onPressReaction]
    )

    const layoutDirection = useMemo(
      () => (events?.[0]?.sender === userId ? 'right' : 'left'),
      [events, userId]
    )

    const onPressMessageOption = useCallback(
      async (option: MessageOptionId) => {
        console.log('LOG: > onPressMessageOption:', option)

        if (option === 'reply') {
          onPressReply(eventId)
          closeModal()
          return
        }

        if (option === 'copy') {
          // onPressReply(eventId)
          // TODO: handle others events to copy like images?
          const toCopy = events?.[0].content.body ?? ''
          await Clipboard.setStringAsync(toCopy)

          closeModal()
          return
        }

        Toast({
          title: `Message ${option} not implemented yet!`,
          status: 'warning',
          duration: 1500
        })
        closeModal()
      },
      [closeModal, eventId, events, onPressReply]
    )

    const handlePressMessageOption = useThrottledCallback(onPressMessageOption, 300)

    return (
      <Modal visible={isVisible} onDismiss={onDismiss} transparent onShow={() => null}>
        {elementLayout && events && floatingPositionOffset && (
          <Animated.View
            style={{
              flex: 1
            }}
            entering={FadeIn}
            exiting={FadeOut}
          >
            <Pressable
              accessibilityRole="button"
              onPress={() => {
                onDismiss()
              }}
              style={{
                flex: 1
              }}
            >
              <BlurView tint={blurTint} style={StyleSheet.absoluteFillObject} intensity={50} />
              <View
                style={{
                  paddingHorizontal: 16,
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  marginTop: floatingPositionOffset
                }}
              >
                {!hasMultipleEvents && (
                  <>
                    <Reactions
                      layoutDirection={layoutDirection}
                      onPressReaction={onPressReaction}
                      onPressMore={onPressMore}
                      currentReaction={userReaction}
                    />
                    <EmojiPicker
                      hideHeader={true}
                      enableSearchBar={true}
                      categoryPosition="bottom"
                      enableRecentlyUsed={true}
                      theme={emojiPickerTheme}
                      enableCategoryChangeAnimation={false}
                      enableSearchAnimation={false}
                      onEmojiSelected={handleKeyboardEmojiPick}
                      open={emojiKeyboardModal.isVisible}
                      onClose={emojiKeyboardModal.closeModal}
                      styles={{
                        container: {
                          marginHorizontal: 8
                        },
                        knob: {
                          height: 4
                        }
                      }}
                    />
                  </>
                )}
                <View
                  style={{
                    width: 'auto',
                    zIndex: 4,
                    alignSelf: layoutDirection === 'left' ? 'flex-start' : 'flex-end',
                    ...boxShadow
                  }}
                >
                  <MessageBlock
                    layoutDirection={layoutDirection}
                    roomId={roomId}
                    events={events}
                    hideTime={true}
                    hideReactions={true}
                    hideReplyPreview={true}
                    containerStyle={[
                      {
                        zIndex: 9
                      }
                    ]}
                  />
                </View>

                <MessageOptions
                  roomId={roomId}
                  layoutDirection={layoutDirection}
                  onPress={handlePressMessageOption}
                  offsetTop={elementLayout?.height ?? 0}
                  options={messageOptions}
                />
              </View>
            </Pressable>
          </Animated.View>
        )}
      </Modal>
    )
  }
)

const useEmojiKeyboardTheme = () => {
  return useColorModeValue(
    {
      backdrop: '#00000055',
      knob: '#fff',
      container: theme.colors.grayCool[50],

      header: '#00000099',
      skinTonesContainer: '#e3dbcd',
      category: {
        icon: '#000000',
        iconActive: theme.colors.systemColorsLight.blue,
        container: '#e3dbcd',
        containerActive: '#ffffff'
      },
      search: {
        text: '#000000cc',
        placeholder: '#00000055',
        icon: '#00000055',
        background: '#00000011'
      },
      emoji: '#e3dbcd'
    },
    {
      backdrop: '#00000055',
      knob: theme.colors.textDarkMode[100],
      container: theme.colors.grayDarkMode[900],

      header: '#00000099',
      skinTonesContainer: '#e3dbcd',
      category: {
        icon: theme.colors.textDarkMode[100],
        iconActive: theme.colors.systemColorsDark.blue,
        container: '#e3dbcd',
        containerActive: theme.colors.grayDarkMode[800]
      },
      search: {
        text: theme.colors.textDarkMode[100],
        placeholder: theme.colors.textDarkMode[300],
        icon: theme.colors.textDarkMode[300],
        background: theme.colors.grayDarkMode[700]
      },
      emoji: '#e3dbcd'
    }
  )
}

type MessageOptionId = 'copy' | 'reply'
type MessageOption = {
  id: MessageOptionId
  title: string
  Icon: any
  // FIXME: fix any type
}

// TODO: translate options
const MESSAGE_OPTIONS: MessageOption[] = [
  {
    id: 'reply',
    title: 'Reply',
    Icon: ReplyIcon
  },
  {
    id: 'copy',
    title: 'Copy',
    Icon: CopyIcon
  }
]

const MESSAGE_OPTION_HEIGHT = 48

const MessageOptions = React.memo(
  ({
    roomId,
    layoutDirection,
    onPress,
    options,
    offsetTop
  }: {
    roomId: string
    layoutDirection: 'right' | 'left'
    onPress: (option: MessageOptionId) => void
    options: MessageOption[]
    offsetTop: number
  }) => {
    const boxShadow = useColorModeValue(styles.boxShadowLight, styles.boxShadowDark)

    const iconColor = useColorModeValue(
      theme.colors.textLightMode[900],
      theme.colors.textDarkMode[100]
    )
    const borderColor = useColorModeValue(
      theme.colors.grayCool[200],
      theme.colors.grayDarkMode[800]
    )

    const listHeight = options.length * MESSAGE_OPTION_HEIGHT
    console.log('LOG: > listHeight:', listHeight)

    return (
      <Box
        position={'absolute'}
        zIndex={13}
        left={layoutDirection === 'left' ? 0 : undefined}
        right={layoutDirection === 'right' ? 0 : undefined}
        marginX={4}
        justifyContent={'center'}
        _light={{
          backgroundColor: theme.colors.grayCool[50]
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[900]
        }}
        style={[
          {
            top: 12,
            marginTop: Math.floor(offsetTop),
            borderRadius: 8,
            width: 180
          },
          boxShadow
        ]}
      >
        {options.map(({ id, title, Icon }, index, self) => (
          <Pressable
            key={`message-option-${id}`}
            accessibilityRole="button"
            onPress={() => onPress(id)}
            style={{
              paddingHorizontal: 16,
              justifyContent: 'center',
              height: MESSAGE_OPTION_HEIGHT,
              borderBottomColor: borderColor,
              borderBottomWidth: self.length > 0 && index !== self.length - 1 ? 1 : 0
            }}
          >
            <Box flexDirection={'row'} alignItems={'center'}>
              <Icon fill={iconColor} size={18} />
              <Text
                marginLeft={2}
                fontFamily={'Inter'}
                fontSize={'15'}
                lineHeight={'15'}
                _light={{
                  color: theme.colors.textLightMode[600]
                }}
                _dark={{
                  color: theme.colors.textDarkMode[100]
                }}
              >
                {title}
              </Text>
            </Box>
          </Pressable>
        ))}
      </Box>
    )
  }
)

const REACTIONS_HEIGHT = 56
const REACTIONS_ELEMENTS = ['❤️', '😂', '😮', '😢', '😠', '👍']
const Reactions = React.memo(
  ({
    layoutDirection,
    onPressReaction,
    onPressMore,
    currentReaction,
    reactionsElements = REACTIONS_ELEMENTS
  }: {
    layoutDirection: 'right' | 'left'
    onPressReaction: (reaction: string) => void
    onPressMore: () => void
    currentReaction?: MessageReaction
    reactionsElements?: string[]
  }) => {
    const boxShadow = useColorModeValue(styles.boxShadowLight, styles.boxShadowDark)

    const optionsIconColor = useColorModeValue(
      theme.colors.textLightMode[300],
      theme.colors.textDarkMode[100]
    )
    const optionsBackgroundColor = useColorModeValue(
      theme.colors.grayCool[200],
      theme.colors.grayDarkMode[700]
    )
    const selectedStyle = useColorModeValue(
      {
        backgroundColor: theme.colors.grayCool[300]
      },
      {
        backgroundColor: theme.colors.grayDarkMode[600]
      }
    )

    const handlePressReaction = useThrottledCallback(onPressReaction, 300, { leading: true })
    const handlePressMore = useThrottledCallback(onPressMore, 300, { leading: true })

    return (
      <Box
        position={'absolute'}
        zIndex={0}
        left={layoutDirection === 'left' ? 0 : undefined}
        right={layoutDirection === 'right' ? 0 : undefined}
        top={-12}
        marginX={4}
        padding={3}
        justifyContent={'center'}
        _light={{
          backgroundColor: theme.colors.grayCool[50]
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[900]
        }}
        style={[
          {
            marginTop: -REACTIONS_HEIGHT,
            height: REACTIONS_HEIGHT,
            borderRadius: 60
          },
          boxShadow
        ]}
      >
        <Box flexDirection={'row'} alignItems={'center'} justifyItems={'center'}>
          {reactionsElements.map((reaction, index) => {
            const isSelected = reaction === currentReaction?.key
            return (
              <Pressable
                key={`message-reaction-${index}`}
                accessibilityRole="button"
                style={[
                  {
                    borderRadius: 60,
                    marginRight: 6,
                    width: 44,
                    height: 44,
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center'
                  },
                  isSelected ? selectedStyle : {}
                ]}
                onPressOut={() => handlePressReaction(reaction)}
              >
                <Text
                  style={{
                    marginLeft: -1,
                    width: 32,
                    height: 32,
                    fontSize: 32,
                    lineHeight: 38,
                    letterSpacing: 0,
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center'
                  }}
                >
                  {reaction}
                </Text>
              </Pressable>
            )
          })}
          <Pressable
            accessibilityRole="button"
            style={{
              borderRadius: 40,
              width: 32,
              height: 32,
              backgroundColor: optionsBackgroundColor,
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onPress={handlePressMore}
          >
            <Ionicons name="ellipsis-horizontal-sharp" color={optionsIconColor} size={22} />
          </Pressable>
        </Box>
      </Box>
    )
  }
)

const styles = StyleSheet.create({
  boxShadowLight: {
    // Shadow
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.1,
    shadowRadius: 14,

    elevation: 9
  },
  boxShadowDark: {
    // Shadow
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.3,
    shadowRadius: 14,

    elevation: 9
  }
})

import React from 'react'
import { Box, Image, ITextAreaProps, Pressable, TextArea } from 'native-base'

import { icons } from '../InputField/icons'

export type TextAreaFieldProps = ITextAreaProps & {
  hasDelete?: boolean
  setValue: (s: string) => void
}

export const TextAreaField = ({ setValue, hasDelete, ...props }: TextAreaFieldProps) => {
  const [ShowDelete, setShowDelete] = React.useState<boolean>(false)
  const [inputText, setInputText] = React.useState<string>(props.defaultValue || '')

  const clearInputField = () => {
    setInputText('')
    setValue('')
  }

  return (
    <Box justifyContent="center">
      <TextArea
        value={inputText}
        autoCompleteType={true}
        onFocus={() => setShowDelete(true)}
        onBlur={() => setShowDelete(false)}
        onChangeText={text => {
          setInputText(text)
          setValue(text)
        }}
        {...props}
      />
      {hasDelete === true && ShowDelete && inputText.length !== 0 && (
        <Pressable
          onPress={clearInputField}
          pb="10px"
          accessibilityRole="button"
          position="absolute"
          alignSelf="flex-end"
        >
          <Image
            alt="delete"
            accessibilityIgnoresInvertColors
            size="16px"
            position="absolute"
            source={icons.delete}
            alignSelf="flex-end"
          />
        </Pressable>
      )}
    </Box>
  )
}

export default TextAreaField

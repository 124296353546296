import { useQueryClient } from '@tanstack/react-query'
import {
  MatrixData,
  SpaceInfo,
  spacesQueryKeys,
  useMatrixPollSync,
  useMatrixUser
} from '@vatom/sdk/react'
import { IEvent } from 'matrix-js-sdk'
import { observer } from 'mobx-react-lite'

import { addObjectIfUnique, getFilteredPagesData, getFilters } from './helpers'

export const SyncContainer = observer(() => {
  const queryClient = useQueryClient()
  const matrixUser = useMatrixUser()

  useMatrixPollSync({
    onSuccess: response => {
      try {
        const clientSync = (response: MatrixData) => {
          const sync = response
          // console.log('LOG: > clientSync > sync:', sync)

          if (!sync) {
            return
          }

          const { rooms } = sync

          if (!rooms) {
            return
          }

          if ('leave' in rooms) {
            for (const key in rooms.leave) {
              const newEvents = rooms.leave[key].timeline.events
              // console.log('LOG: > clientSync > newEvents:', newEvents)
              newEvents.map((event: any) => {
                if (event.type === 'm.room.member') {
                  if (event.content.membership === 'leave') {
                    queryClient.invalidateQueries(['matrix-members', key])
                    return null
                  }
                }
              })
            }
          }

          if ('join' in rooms) {
            for (const key in rooms.join) {
              // Get the business and it's plugins
              const spacesQueries = queryClient.getQueriesData<{
                items: SpaceInfo[]
              }>(spacesQueryKeys.businessSpaces)

              const spaces = spacesQueries
                .map(q => {
                  const [_, queryData] = q
                  return queryData?.items
                })
                .flat()

              const businessId = spaces.find(space => space?.matrixRoomId === key)?.businessId
              const businessPlugins = queryClient.getQueryData<{ data: any }>([
                'business-plugins',
                businessId
              ])?.data

              const firstPlugin = businessPlugins ? businessPlugins[0] : null
              const newEvents = rooms.join[key].timeline.events
              const userJoinEvents: any = {}

              newEvents.forEach((event: IEvent) => {
                if (event.content.membership === 'join') {
                  userJoinEvents[event.sender] = event // Overwrite with latest join event for the user
                }
              })

              // Now userJoinEvents contains the last join event for each user
              const lastJoinEvents = Object.values(userJoinEvents)
              if (lastJoinEvents.length > 0) {
                lastJoinEvents.map((event: any) => {
                  queryClient.invalidateQueries(['matrix-members', key])
                })
              }

              newEvents.map(event => {
                if (event.type.includes('v.room') || event.type.includes('m.room.message')) {
                  if ('m.relates_to' in event.content) {
                    // const voteTypes = ['poll.vote', 'score.vote', 'question.vote', 'sketch.vote']
                    const voteTypes = ['poll.vote', 'score.vote', 'question.vote']
                    const { event_id: parentID, rel_type: relType } = event.content['m.relates_to']

                    if (relType === 'm.thread') {
                      queryClient.setQueryData(
                        ['message-thread-replies', key, parentID],
                        (oldData: any) => {
                          if (!oldData) {
                            return null
                          }

                          return {
                            ...oldData,
                            pages: [
                              {
                                ...oldData.pages[0],
                                chunk: addObjectIfUnique(oldData.pages[0].chunk, event)
                              },
                              ...oldData.pages.slice(1)
                            ]
                          }
                        }
                      )
                      return null
                    }

                    if (voteTypes.some(type => type === relType)) {
                      queryClient.setQueryData(
                        ['message-poll-votes', key, parentID, relType],
                        (oldData: any) => {
                          if (!oldData) {
                            return
                          }

                          return {
                            ...oldData,
                            pages: [
                              {
                                ...oldData.pages[0],
                                chunk: addObjectIfUnique(oldData.pages[0].chunk, event)
                              },
                              ...oldData.pages.slice(1)
                            ]
                          }
                        }
                      )
                      return null
                    }

                    if (relType === 'm.replace') {
                      queryClient.setQueryData(['message', key, parentID], (old: any) => ({
                        ...old,
                        content: event.content['m.new_content']
                      }))
                      return null
                    }

                    if (relType === 'v.hide') {
                      queryClient.invalidateQueries(['message-hide', key, parentID])
                      return null
                    }
                  } else {
                    if (!firstPlugin) {
                      return null
                    }

                    const messageFacade = firstPlugin.descriptor.facades.message.map(
                      (msg: { id: any }) => msg.id
                    )
                    if (messageFacade.some((msg: any) => msg === event.type)) {
                      const filters = getFilters('all', firstPlugin.descriptor.facades.message)
                      queryClient.setQueryData(['message', key, event.event_id], () => event)
                      queryClient.setQueryData(['messages', key, filters], (oldData: any) => {
                        if (!oldData) {
                          return null
                        }

                        return {
                          ...oldData,
                          pages: [
                            {
                              ...oldData.pages[0],
                              chunk: addObjectIfUnique(oldData.pages[0].chunk, event)
                            },
                            ...oldData.pages.slice(1)
                          ]
                        }
                      })

                      const currentFilter = getFilters(
                        event.type,
                        firstPlugin.descriptor.facades.message
                      )
                      queryClient.setQueryData(['messages', key, currentFilter], (oldData: any) => {
                        if (!oldData) {
                          return null
                        }

                        return {
                          ...oldData,
                          pages: [
                            {
                              ...oldData.pages[0],
                              chunk: addObjectIfUnique(oldData.pages[0].chunk, event)
                            },
                            ...oldData.pages.slice(1)
                          ]
                        }
                      })
                      return null
                    }
                  }
                }

                if (event.type === 'm.reaction') {
                  const reactionParent = event?.content['m.relates_to']?.event_id
                  queryClient.setQueryData(
                    ['message-reactions', key, reactionParent],
                    (oldData: any) => {
                      if (!oldData) {
                        return null
                      }

                      return {
                        ...oldData,
                        data: {
                          ...oldData.data,
                          chunk: addObjectIfUnique(oldData.data.chunk, event)
                        }
                      }
                    }
                  )
                  return null
                }
                if (event.type === 'm.room.redaction') {
                  if (!event.unsigned.transaction_id) {
                    return null
                  }

                  const redactionParent = event.unsigned?.transaction_id.split('.')[0]
                  const relType = event.unsigned?.transaction_id?.split('.')[1]

                  if (relType === 'emoji') {
                    // queryClient.invalidateQueries(['message-reactions', key, redactionParent])
                    queryClient.setQueryData(
                      ['message-reactions', key, redactionParent],
                      (oldData: any) => {
                        const newEvents = oldData.data.chunk.filter(
                          (currentEvent: IEvent) => currentEvent.event_id !== event.redacts
                        )

                        return {
                          ...oldData,
                          data: {
                            ...oldData.data,
                            chunk: newEvents
                          }
                        }
                      }
                    )
                  }

                  // const voteTypes = ['poll', 'score', 'question', 'sketch']
                  const voteTypes = ['poll', 'score', 'question']

                  if (voteTypes.some(type => type === relType)) {
                    const voteType = voteTypes.find(type => type === relType)
                    queryClient.setQueryData(
                      ['message-poll-votes', key, redactionParent, voteType + '.vote'],
                      (oldData: any) => {
                        const newPages = getFilteredPagesData(oldData, event)

                        return {
                          ...oldData,
                          pages: newPages
                        }
                      }
                    )
                    return null
                  }

                  const messageTypes: string[] = firstPlugin.descriptor.facades.message.map(
                    ({ id }: { id: string }) => id
                  )
                  const match = event.unsigned?.transaction_id.match(/\.-(.*?)-\./)
                  if (match) {
                    if (messageTypes.some(type => match[1] === type)) {
                      const filters = getFilters('all', firstPlugin.descriptor.facades.message)
                      queryClient.setQueryData(['messages', key, filters], (oldData: any) => {
                        const newPages = getFilteredPagesData(oldData, event)

                        return {
                          ...oldData,
                          pages: newPages
                        }
                      })

                      const currentFilter = getFilters(
                        match[1],
                        firstPlugin.descriptor.facades.message
                      )
                      queryClient.setQueryData(['messages', key, currentFilter], (oldData: any) => {
                        const newPages = getFilteredPagesData(oldData, event)

                        return {
                          ...oldData,
                          pages: newPages
                        }
                      })
                    }
                  }

                  if (relType === 'reply') {
                    queryClient.setQueryData(
                      ['message-thread-replies', key, redactionParent],
                      (oldData: any) => {
                        const newPages = getFilteredPagesData(oldData, event)

                        return {
                          ...oldData,
                          pages: newPages
                        }
                      }
                    )
                  }

                  return null
                }

                // if (event.type === 'v.room.message.reputation') {
                //   handleMessageReputation(event, roomWithEvents)
                //   return
                // }
                // if (event.type === 'v.room.reply.stake') {
                //   handleReplyStake(event, roomWithEvents)
                //   return
                // }
                // if (event.type === 'v.room.user.reputation') {
                //   handleUserReputation(event, roomWithEvents)
                //   return
                // }

                return null
              })
            }
          }
        }
        console.log('matrix sync poll event: ', response)
        // console.log('LOG: > newEvents.forEach > directEvents:', directEvents)
        clientSync(response)
        queryClient.invalidateQueries(['matrix-community-sync', matrixUser.data?.access_token])
      } catch (error) {
        console.log('Something went wrong in clientSync: ', error)
      }
    },
    onError: error => {
      console.error('error', error)
      queryClient.invalidateQueries(['matrix-community-sync', matrixUser.data?.access_token])
    },
    enabled: false
  })
  return null
})

import React from 'react'
import { Marker } from '@react-google-maps/api'
import { TokenType } from '@vatom/sdk/core'
import { observer } from 'mobx-react-lite'

type CustomMarkerProps = {
  item: TokenType
  onMarkerClick: (item: TokenType) => void
}

export const CustomMarker = observer((props: CustomMarkerProps) => {
  const { item, onMarkerClick } = props
  const keyMarkerAlt = `marker_${item.id}`

  if (!item.position) return null

  return (
    <Marker
      icon={{
        url: item.displayImage ?? item.metadata.image,
        size: {
          width: 60,
          height: 60,
          equals: () => true
        },
        scaledSize: {
          width: 50,
          height: 50,
          equals: () => true
        }
      }}
      key={keyMarkerAlt}
      title={item.metadata.name}
      position={{
        lat: item.position!.coordinates[1],
        lng: item.position!.coordinates[0]
      }}
      onClick={() => {
        onMarkerClick(item)
      }}
    />
  )
})

import React, { useCallback } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { BottomSheetView } from '@gorhom/bottom-sheet'
import { useNavigation } from '@react-navigation/native'
import { useMutation } from '@tanstack/react-query'
import { TokenType } from '@vatom/sdk/core'
import { Status, Text, theme, Toast } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Divider, Pressable, VStack } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import Alert from '../../../components/Alert'
import { useBusinessSelector } from '../../../hooks/useBusinessSelector'
import { AppRoutes, TabRoutes } from '../../../navigators'
import ProcessButton from '../../ActionSheets/partials/ProcessButton'

export const UnSellSheetModal = observer(
  ({ token, onDismiss }: { token: TokenType; onDismiss: () => void }) => {
    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const { business } = useBusinessSelector()

    const unSellMutation = useMutation({
      mutationKey: ['token-unsell', token?.id],
      mutationFn: async () => {
        await token.performAction('varius.action:varius.io:remove-from-sale-v1').then($ => {
          Toast({
            title: `Your NFT was successfully de-listed.`,
            placement: 'top',
            status: Status.success
          })
        })
      },
      onError(error) {
        Alert.showError(error)
      },
      onSuccess() {
        goBack()
      }
    })

    const goBack = useCallback(() => {
      if (business?.id) {
        navigation.navigate(AppRoutes.BusinessProxy, {
          business: business?.id,
          screen: TabRoutes.Wallet
        })
        return
      } else {
        navigation.navigate(AppRoutes.home, {
          screen: 'Wallet'
        })
        return
      }
    }, [business?.id, navigation])

    const unSellAction = useThrottledCallback(
      async () => {
        unSellMutation.mutate()
      },
      300,
      {
        leading: true
      }
    )

    return (
      <BottomSheetView
        style={{
          paddingTop: 16,
          paddingBottom: insets.bottom > 0 ? insets.bottom : 16
        }}
      >
        <VStack paddingX={4}>
          <VStack w="100%">
            <Text textAlign="center">Are you sure you want to remove this vatom from Sale?</Text>
          </VStack>

          <Divider backgroundColor="gray.900" height="0px" mt={4} />
          <ProcessButton
            label="Remove"
            isDisabled={unSellMutation.isLoading}
            onPress={unSellAction}
            isLoading={unSellMutation.isLoading}
          />
          <Divider backgroundColor="gray.900" height="0px" mt={4} />

          <Pressable
            accessibilityRole="button"
            onPress={onDismiss}
            disabled={unSellMutation.isLoading}
            colorScheme="gray"
            px={4}
            w="100%"
            justifyContent="center"
            rounded={'sm'}
            p={2}
            _light={{
              backgroundColor: theme.colors.grayCool[600]
            }}
            _dark={{
              backgroundColor: theme.colors.grayDarkMode[700]
            }}
          >
            <Text
              color="white"
              fontFamily="Inter-SemiBold"
              alignSelf="center"
              py={2}
              fontSize="18px"
              tx="common.cancel"
            />
          </Pressable>
        </VStack>
      </BottomSheetView>
    )
  }
)

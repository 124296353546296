import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { useGetSpace, useMatrixUser } from '@vatom/sdk/react'
import axios from 'axios'

import { useBusinessSelector } from '../../../../hooks/useBusinessSelector'
import { AppNavigation, navigateToConnectScreen, RoomRoutes } from '../../../../navigators'
import MessageActionCloneIcon from '../../assets/message-action-sheet-icons/clone-icon.svg'
import MessageActionDeleteIcon from '../../assets/message-action-sheet-icons/delete-icon.svg'
import MessageActionEditIcon from '../../assets/message-action-sheet-icons/edit-icon.svg'
import MessageActionFlagIcon from '../../assets/message-action-sheet-icons/flag-icon.svg'
import MessageActionHideIcon from '../../assets/message-action-sheet-icons/hidden-icon.svg'
import MessageActionReactIcon from '../../assets/message-action-sheet-icons/react-icon.svg'
import MessageActionReplyIcon from '../../assets/message-action-sheet-icons/reply-icon.svg'
import { matrixServerUrl } from '../../constants'
import { transformedMessageData, transformedReplyData } from '../../helpers'
import { useMessage, useMessageHide, usePlugins, useUserPowerLevel } from '../../queries'
import { useCommunitiesTheme } from '../../themes'
import { useCommunitySpace } from '../../useCommunitySpace'
import { getSendMessageUrl } from '../AddModals/add-modals-helpers'
import EmojiPickerComponent from '../ChatBubble/chat-components/EmojiPicker'
import ModalOption from '../modal-components/ModalOption'

import PopModalWrapper from './PopModalWrapper'

type MessagesPopUpModalProps = {
  messageId: string
  isModalVisible: boolean
  setIsModalVisible: (arg0: boolean) => void
  setIsConfirmationVisible: (arg0: boolean) => void
  isThread: boolean
  isReplyMessage?: boolean
  spaceId: string
}

const MessagesPopUpModal = (props: MessagesPopUpModalProps) => {
  const { business } = useBusinessSelector()
  const businessId = business?.id ?? ''
  const {
    messageId,
    isThread,
    isReplyMessage,
    isModalVisible,
    setIsModalVisible,
    setIsConfirmationVisible,
    spaceId
  } = props

  const space = useGetSpace(spaceId, {
    enabled: false
  })
  const roomId = space.data?.matrixRoomId ?? ''
  const { community } = useCommunitySpace()

  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const { data: matrixUser } = useMatrixUser()
  const navigation = useNavigation<AppNavigation>()
  const communitiesTheme = useCommunitiesTheme()

  const { data: message } = useMessage(roomId, messageId)
  const { data: plugins } = usePlugins()
  const pluginData = plugins ? plugins[0] : null
  const { data: messageHidden } = useMessageHide(roomId, messageId)

  const userPowerLevel = useUserPowerLevel(roomId)

  if (!message) {
    return null
  }

  const matrixMessage = isReplyMessage
    ? transformedReplyData(message)
    : transformedMessageData(message)

  const options = !isReplyMessage
    ? pluginData?.descriptor.facades.message.find(
        (type: any) => type.id === matrixMessage?.eventType
      ).actions ?? []
    : ['react', 'flag']

  const handleReply = () => {
    setIsModalVisible(false)

    navigateToConnectScreen(navigation, RoomRoutes.RoomReply, {
      spaceId,
      business: businessId,
      community,
      messageId: messageId
    })
  }

  const handleReact = () => {
    setIsOpen(true)
  }

  const handleFlag = () => {
    console.log('flag')
  }

  const handleHide = async () => {
    const hideUrl = getSendMessageUrl('v.room.hide', roomId, matrixUser?.access_token)
    const isHidden =
      messageHidden?.chunk.length === 0 ? true : !messageHidden?.chunk[0]?.content.isHidden
    await axios.post(hideUrl, {
      'm.relates_to': {
        rel_type: 'v.hide',
        event_id: messageId
      },
      isHidden: isHidden
    })
  }

  const handleEdit = () => {
    setIsModalVisible(false)

    if (isReplyMessage) {
      navigateToConnectScreen(navigation, RoomRoutes.RoomReply, {
        spaceId,
        business: businessId,
        community,
        messageId: messageId,
        method: 'edit'
      })
    } else {
      navigateToConnectScreen(navigation, RoomRoutes.RoomNewMessage, {
        messageType: matrixMessage.eventType,
        spaceId,
        business: businessId,
        community,
        messageId: messageId,
        method: 'edit'
      })
    }
  }

  const handleClone = () => {
    setIsModalVisible(false)
    navigateToConnectScreen(navigation, RoomRoutes.RoomNewMessage, {
      messageType: matrixMessage.eventType,
      spaceId,
      business: businessId,
      community,
      messageId: messageId,
      method: 'clone'
    })
  }

  const handlePick = async (emoji: string) => {
    setIsOpen(false)
    setIsModalVisible(false)
    const dateNow = Date.now()
    await axios.put(
      `${matrixServerUrl}/_matrix/client/r0/rooms/${roomId}/send/m.reaction/emoji.${dateNow}?access_token=${matrixUser?.access_token}`,
      {
        'm.relates_to': {
          rel_type: 'm.annotation',
          event_id: messageId,
          key: emoji
        }
      }
    )
  }

  const cloneMessageTypes = ['v.room.score', 'v.room.poll', 'v.room.sketch']

  return (
    <>
      <EmojiPickerComponent isOpen={isOpen} setIsOpen={setIsOpen} handlePick={handlePick} />

      {options.length > 0 && !isOpen ? (
        <PopModalWrapper isModalVisibl={isModalVisible} setIsModalVisible={setIsModalVisible}>
          {options.map((option: string, index: number) => {
            switch (option) {
              case 'reply':
                if (isThread || isReplyMessage) {
                  return null
                }
                return (
                  <ModalOption key={index} handler={handleReply} title="Reply">
                    <MessageActionReplyIcon fill={communitiesTheme.labelIconColor} />
                  </ModalOption>
                )
              case 'react':
                return (
                  <ModalOption key={index} handler={handleReact} title="React">
                    <MessageActionReactIcon fill={communitiesTheme.labelIconColor} />
                  </ModalOption>
                )
              case 'flag':
                return (
                  <ModalOption key={index} handler={handleFlag} title="Flag">
                    <MessageActionFlagIcon fill={communitiesTheme.labelIconColor} />
                  </ModalOption>
                )
              default:
                return null
            }
          })}

          {matrixUser?.user_id === matrixMessage.sender || userPowerLevel >= 90 ? (
            <ModalOption
              key="hide"
              handler={() => (messageHidden ? handleHide() : null)}
              title={messageHidden?.chunk[0]?.content.isHidden ? 'Unhide' : 'Hide'}
            >
              <MessageActionHideIcon fill={communitiesTheme.labelIconColor} />
            </ModalOption>
          ) : null}

          {matrixUser?.user_id === matrixMessage.sender || userPowerLevel >= 90 ? (
            <ModalOption key="edit" handler={handleEdit} title="Edit">
              <MessageActionEditIcon fill={communitiesTheme.labelIconColor} />
            </ModalOption>
          ) : null}

          {matrixUser?.user_id === matrixMessage.sender || userPowerLevel >= 90 ? (
            cloneMessageTypes.some(type => type === matrixMessage.eventType) ? (
              <ModalOption key="clone" handler={handleClone} title="Clone">
                <MessageActionCloneIcon
                  fill={communitiesTheme.labelIconColor}
                  height={16}
                  width={16}
                />
              </ModalOption>
            ) : null
          ) : null}

          {userPowerLevel >= 90 ? (
            <ModalOption
              key="delete"
              handler={() => setIsConfirmationVisible(true)}
              title="Delete"
              titleStyle={{ color: '#DD4267' }}
            >
              <MessageActionDeleteIcon />
            </ModalOption>
          ) : null}
        </PopModalWrapper>
      ) : null}
    </>
  )
}

export default MessagesPopUpModal

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FontAwesome5 } from '@expo/vector-icons'
import { PublicProfileSnapshot } from '@vatom/sdk/core'
import { useSpace, useSpaceAttendees } from '@vatom/sdk/react'
import { PressableOpacity } from '@vatom/wombo'
import { Box, Text } from 'native-base'

import PeopleIcon from '../../assets/people.svg'

import AttendeesModal from './AttendeesModal'
import { CallControlType } from './EndCallButton'

export function PeopleButton(props: CallControlType) {
  const size = '58px'
  const { space } = useSpace()
  const [modalAttendees, setModalAttendees] = useState(false)
  const spaceAttendees = useSpaceAttendees()
  const guests = spaceAttendees.data ?? []

  if (!space) {
    return null
  }

  if (props.secondary) {
    return (
      <>
        {space ? (
          <AttendeesModal open={modalAttendees} setOpen={setModalAttendees} space={space} />
        ) : null}
        <PressableOpacity onPress={() => setModalAttendees(true)} disabled={guests.length === 0}>
          <Box
            flexDirection="row"
            background="#F1F3F5"
            alignItems="center"
            p="2"
            borderRadius="50px"
            justifyContent="center"
            w="70px"
            {...props}
          >
            <FontAwesome5 name="user-alt" size={15} color="#3F4A55" />
            <Text
              ml="2"
              style={{
                color: '#262626',
                fontFamily: 'Inter-SemiBold'
              }}
            >
              {guests.length}
            </Text>
          </Box>
        </PressableOpacity>
      </>
    )
  }

  return (
    <>
      {space ? (
        <AttendeesModal open={modalAttendees} setOpen={setModalAttendees} space={space} />
      ) : null}

      <PressableOpacity
        disabled={guests.length === 0}
        onPress={() => setModalAttendees(true)}
        background={guests.length === 0 ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.7)'}
        h={size}
        w={size}
        borderRadius={30}
        justifyContent="center"
        alignItems="center"
        {...props}
      >
        <PeopleIcon />
      </PressableOpacity>
    </>
  )
}

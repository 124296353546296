import React from 'react'
import { theme } from '@vatom/wombo'
import { Button, useColorModeValue } from 'native-base'

import CloseIcon from '../../../assets/close.svg'
import { AppRoutes, AppStackScreenProps } from '../../../navigators'

type ScreenHeaderProps = AppStackScreenProps<
  typeof AppRoutes.sendConfirmation | typeof AppRoutes.sendToChainConfirmation
>

export const ScreenHeader = ({ navigation }: ScreenHeaderProps) => {
  const iconColor = useColorModeValue(theme.colors.grayDarkMode[900], theme.colors.white)
  return (
    <Button
      padding={2}
      onPress={() => navigation.goBack()}
      bgColor="transparent"
      alignSelf="flex-start"
    >
      <CloseIcon height={40} width={40} fill={iconColor} />
    </Button>
  )
}

export default ScreenHeader

import React, { useRef } from 'react'
import { Alert } from 'react-native'
import Animated from 'react-native-reanimated'
import { useNavigation } from '@react-navigation/native'
import { IdentityType } from '@vatom/sdk/core'
import { useDeleteAddressFromIdentities, useIdentities } from '@vatom/sdk/react'
import { observer } from 'mobx-react-lite'
import { HStack, VStack } from 'native-base'

import { Title } from '../../../components/Title'
import { AppRoutes } from '../../../navigators'
import { groupedWalletsSelector, useWalletStore } from '../WalletStore'

import { Divider } from './Divider'
import { ItemSwipeable } from './ItemSwipeable'
import { RightArrow } from './RightArrow'
import { SubTitleETH } from './SubTitleETH'
import { SubTitleSolana } from './SubtitleSolana'
import { WalletAddress } from './WalletAddress'

export const WatchingWallets = observer(() => {
  const scrollRef = useRef<Animated.ScrollView | null>(null)
  const navigation = useNavigation()
  const deleteAddressToIdentitiesMutation = useDeleteAddressFromIdentities()
  const groupedWallets = useWalletStore(groupedWalletsSelector)
  const storedAddresses =
    groupedWallets.flatMap(arr => arr.map(obj => obj.address.toLowerCase())) ?? []
  const { identities } = useIdentities()
  const ethWallets = identities.filter(
    identity =>
      !identity.custodial &&
      identity.type === IdentityType.Eth &&
      !storedAddresses.includes(identity.value)
  )
  const solanaWallets = identities.filter(
    identity =>
      !identity.custodial &&
      identity.type === IdentityType.Solana &&
      !storedAddresses.includes(identity.value)
  )
  // const iconColor = useColorModeValue(
  //   theme.colors.textLightMode[600],
  //   theme.colors.textDarkMode[300]
  // )

  const deleteAddress = async (address: string, type: string) => {
    // messaging and function needs to change once the delete of identities are done
    Alert.alert(
      'Delete Private Keys',
      'Are you sure you want to delete this keys? We will not be able to retrieve this once you proceed.',
      [
        {
          text: 'Cancel',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel'
        },
        {
          text: 'OK',
          onPress: () => {
            deleteAddressToIdentitiesMutation.mutate({
              type,
              value: address
            })
          }
        }
      ]
    )
  }

  const editAddress = (address: string) => {
    navigation.navigate(AppRoutes.EditAddressName, { address })
  }

  if (!ethWallets.length && !solanaWallets.length) {
    return null
  }

  return (
    <VStack flexGrow={1}>
      <Title preset="h3" variant="bold">
        Watching
      </Title>

      {ethWallets.length ? (
        <>
          <SubTitleETH marginTop={3} />

          {/* Wallet Addresses */}
          <VStack flexGrow={1} marginY={3} alignItems={'center'}>
            {ethWallets.map((wallet, index) => (
              <HStack
                key={`eth-watching-${wallet.value}`}
                flexGrow={1}
                marginY={2}
                alignItems={'center'}
              >
                <ItemSwipeable
                  onDelete={() => deleteAddress(wallet.value, wallet.type)}
                  simultaneousHandlers={scrollRef}
                  onEdit={() => editAddress(wallet.value)}
                  onPress={() => {
                    useWalletStore.getState().setSelectedAddress(wallet.value)
                    navigation.navigate(AppRoutes.WalletAddressManagement, {
                      address: wallet.value
                    })
                  }}
                >
                  <WalletAddress
                    title={wallet.name || `Address ${index + 1}`}
                    address={wallet.value}
                    RightComponent={<RightArrow />}
                  />
                </ItemSwipeable>
              </HStack>
            ))}
          </VStack>
        </>
      ) : null}

      <Divider marginX={1} />

      {solanaWallets.length ? (
        <>
          <SubTitleSolana marginTop={3} />

          {/* Wallet Addresses */}
          <VStack flexGrow={1} marginY={3} alignItems={'center'}>
            {solanaWallets.map((wallet, index) => (
              <HStack
                key={`solana-watching-${wallet.value}`}
                flexGrow={1}
                marginY={2}
                alignItems={'center'}
              >
                <ItemSwipeable
                  onDelete={() => deleteAddress(wallet.value, wallet.type)}
                  simultaneousHandlers={scrollRef}
                  onEdit={() => editAddress(wallet.value)}
                  onPress={() => {
                    useWalletStore.getState().setSelectedAddress(wallet.value)
                    navigation.navigate(AppRoutes.WalletAddressManagement, {
                      address: wallet.value
                    })
                  }}
                >
                  <WalletAddress
                    title={wallet.name ?? `Address ${index + 1}`}
                    address={wallet.value}
                    RightComponent={<RightArrow />}
                  />
                </ItemSwipeable>
              </HStack>
            ))}
          </VStack>
        </>
      ) : null}
    </VStack>
  )
})

import { useQuery } from '@tanstack/react-query'

import { useSDK } from '../../../SDKProvider'

import { fetchContacts, fetchFollowing, fetchUserFollowers, fetchUserFollowing } from './api'
import { relationshipsQueryKeys } from './queryKeys'
import {
  DeviceContactResponse,
  FollowersResponse,
  FollowingResponse,
  RelationshipContact,
  RelationshipFollowingOptions,
  RelationshipOptions,
  UserFollowersOptions,
  UserFollowingOptions
} from './types'

export const useRelGetContacts = <T = DeviceContactResponse[]>(
  contacts: RelationshipContact[],
  options?: RelationshipOptions<T>
) => {
  const sdk = useSDK()
  const result = useQuery({
    queryKey: relationshipsQueryKeys.getContacts(
      contacts,
      sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken ?? ''
    ),
    queryFn: fetchContacts,
    enabled: !!sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken && !!contacts.length,
    ...options
  })
  return result
}

export const useGetUserFollowing = <T = FollowingResponse[]>(
  userId: string,
  options?: UserFollowingOptions<T>
) => {
  const sdk = useSDK()
  const result = useQuery({
    queryKey: relationshipsQueryKeys.getUserFollowing(
      userId,
      sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken ?? ''
    ),
    queryFn: fetchUserFollowing,
    enabled: !!sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken && !!userId,
    ...options
  })
  return result
}

export const useGetUserFollowers = <T = FollowersResponse[]>(
  userId: string,
  options?: UserFollowersOptions<T>
) => {
  const sdk = useSDK()
  const result = useQuery({
    queryKey: relationshipsQueryKeys.getUserFollowers(
      userId,
      sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken ?? ''
    ),
    queryFn: fetchUserFollowers,
    enabled: !!sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken && !!userId,
    ...options
  })
  return result
}

export const useRelGetFollowing = <T = RelationshipContact[]>(
  options?: RelationshipFollowingOptions<T>
) => {
  const sdk = useSDK()
  const result = useQuery({
    queryKey: relationshipsQueryKeys.getFollowing(
      sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken ?? ''
    ),
    queryFn: fetchFollowing,
    enabled: !!sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken,
    ...options
  })
  return result
}

import React, { PropsWithChildren } from 'react'
import { IdentitySnapshot, TokenType, TokenTypes } from '@vatom/sdk/core'
import { Contact, Destination } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { GradientButton, Text } from '@vatom/wombo'

import SendVatomBasic from './SendBVatomBasic'
import SendVatomInvitation from './SendBVatomInvitation'
import SendNft from './SendNft'

export * from './SendBVatomBasic'
export * from './SendNft'

interface BtnProps
  extends PropsWithChildren<{
    onPress: () => void
    smart?: boolean
    disabled: boolean
    isShare?: boolean
  }> {}

export const SendBtn = ({ onPress, smart, disabled, isShare = false, children }: BtnProps) => {
  return (
    <GradientButton
      onPress={onPress}
      justifyContent="center"
      disabled={disabled}
      opacity={disabled ? 0.5 : 1}
    >
      <Text color="white" fontFamily="Inter-SemiBold" alignSelf="center" py={2} fontSize="18px">
        {children
          ? children
          : `${isShare ? translate('common.share') : translate('common.send')} ${
              smart ? translate('common.smartNft') : 'NFT'
            }`}
      </Text>
    </GradientButton>
  )
}

export interface senderInterface {
  userProfile?: Contact
  token: TokenType
  invitation?: boolean
  destination: Destination | IdentitySnapshot | undefined
  isShare: boolean
}

export const Sender = (props: senderInterface) => {
  const { token, invitation } = props
  const type = token.getTokenType()

  if (invitation) {
    return <SendVatomInvitation {...props} />
  }

  if (
    type === TokenTypes.VatomBasic ||
    type === TokenTypes.VatomUnMinted ||
    type === TokenTypes.VatomMintedCustodial ||
    type === TokenTypes.VatomNew
  ) {
    return <SendVatomBasic {...props} />
  }

  if (
    type === TokenTypes.Solana ||
    type === TokenTypes.Erc721 ||
    type === TokenTypes.VatomMintedNonCustodial
  ) {
    return <SendNft {...props} />
  }

  return (
    <GradientButton
      onPress={() => alert(`not yet implemented ${type}`)}
      w="100%"
      justifyContent="center"
    >
      <Text color="white" fontFamily="Inter-SemiBold" alignSelf="center" py={2} fontSize="18px">
        Send NFT
      </Text>
    </GradientButton>
  )
}

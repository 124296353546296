import React from 'react'
import { Modal, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useMatrixUser } from '@vatom/sdk/react'
import axios from 'axios'

import { matrixServerUrl } from '../../../constants'
import { useMessage } from '../../../queries'
import { useCommunitiesTheme } from '../../../themes'
import ModalWrapper from '../../AddModals/modal-components/ModalWrapper'

import ExclamationCircleRed from './assets/exclamation-circle-red.svg'

const DeleteConfirmation = (props: any) => {
  const {
    roomId,
    isConfirmationVisible,
    setIsConfirmationVisible,
    isReplyMessage,
    messageId,
    setIsModalVisible
  } = props
  const { data: matrixUser } = useMatrixUser()
  const { data: matrixMessage } = useMessage(roomId, messageId)
  const communitiesTheme = useCommunitiesTheme()

  const handleDelete = async () => {
    setIsModalVisible(false)
    setIsConfirmationVisible(false)
    const dateNow = Date.now()

    const relType = isReplyMessage ? 'reply' : `-${matrixMessage.type}-`
    const parentId = isReplyMessage
      ? matrixMessage.content['m.relates_to']?.event_id
      : matrixMessage.event_id

    await axios.put(
      `${matrixServerUrl}/_matrix/client/r0/rooms/${roomId}/redact/${messageId}/${parentId}.${relType}.${dateNow}?access_token=${matrixUser?.access_token}`,
      {}
    )
  }

  return (
    <Modal visible={isConfirmationVisible} animationType="fade" transparent={true}>
      <ModalWrapper>
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <TouchableOpacity
            accessibilityRole="button"
            style={styles.greyBackground}
            onPress={() => setIsConfirmationVisible(false)}
          />

          <View style={{ ...styles.errorHolder, backgroundColor: communitiesTheme.background }}>
            <ExclamationCircleRed style={{ marginBottom: 36 }} />
            <Text style={{ ...styles.title, color: communitiesTheme.confirmationModal.title }}>
              Delete Post
            </Text>
            <Text
              style={{
                ...styles.description,
                color: communitiesTheme.confirmationModal.description
              }}
            >
              Are you sure you want to delete this post?
            </Text>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <TouchableOpacity
                accessibilityRole="button"
                style={{
                  ...styles.button,
                  ...styles.withBorder,
                  marginRight: 8,
                  backgroundColor: communitiesTheme.background,
                  borderColor: communitiesTheme.confirmationModal.buttonBorder
                }}
                onPress={() => setIsConfirmationVisible(false)}
                key="cancel"
              >
                <Text
                  style={{
                    ...styles.buttonText,
                    color: communitiesTheme.confirmationModal.description
                  }}
                >
                  Keep
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                accessibilityRole="button"
                style={{
                  ...styles.button,
                  backgroundColor: communitiesTheme.accentColor,
                  marginLeft: 8
                }}
                onPress={() => handleDelete()}
                key="add"
              >
                <Text style={{ ...styles.buttonText, color: communitiesTheme.accentColorText }}>
                  Delete
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </ModalWrapper>
    </Modal>
  )
}

export default DeleteConfirmation

const styles = StyleSheet.create({
  greyBackground: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: `rgba(0, 0, 0, 0.5)`,
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  errorHolder: {
    backgroundColor: 'white',
    paddingHorizontal: 16,
    paddingVertical: 32,
    maxWidth: 270,
    width: '100%',
    borderRadius: 8,
    alignItems: 'center'
  },
  title: {
    fontSize: 17,
    lineHeight: 24,
    fontWeight: '700',
    textAlign: 'center'
  },
  description: {
    fontSize: 13,
    lineHeight: 18,
    color: '#3F4A55',
    textAlign: 'center',
    marginTop: 8
  },
  button: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 3,
    marginTop: 16,
    flex: 1
  },
  withBorder: {
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: '#CED4DA'
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 11,
    fontWeight: '500',
    lineHeight: 15
  },
  input: {
    marginTop: 10,
    color: '#3F4A55',
    paddingHorizontal: 12,
    paddingVertical: 10,
    borderWidth: 1,
    borderColor: '#DEE2E6',
    borderRadius: 4,
    fontSize: 15,
    lineHeight: 20
  }
})

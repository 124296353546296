import { Box } from 'native-base'

export const HeaderBackdropSize = {
  height: 100
}
const borderRadius = 20

type HeaderBackdropProps = Omit<React.ComponentProps<typeof Box>, 'height'> & {
  height?: number
}

export const HeaderBackdrop = ({
  backgroundColor,
  height = HeaderBackdropSize.height,
  ...boxProps
}: HeaderBackdropProps) => {
  return (
    <Box
      backgroundColor={backgroundColor}
      position={'relative'}
      zIndex={0}
      height={height}
      borderBottomRightRadius={borderRadius}
      borderBottomLeftRadius={borderRadius}
      {...boxProps}
    />
  )
}

import React from 'react'
import { FontAwesome5, Ionicons } from '@expo/vector-icons'
import { PressableOpacity, Text, theme } from '@vatom/wombo'
import { Box, HStack, Icon } from 'native-base'

interface EditableComponentProps {
  label: string
  value: string
  showCopy?: boolean
  showQr?: boolean
  onCopyPress?: () => void
  onQrPress?: () => void
  valueFontSize: string
}

export const EditableComponent = ({
  valueFontSize,
  onCopyPress,
  onQrPress,
  showCopy,
  showQr,
  label,
  value
}: EditableComponentProps) => {
  return (
    <Box marginX="16px" marginY="12px">
      <Text
        _dark={{
          color: theme.colors.textDarkMode[200]
        }}
        color="lightText"
        fontFamily="Inter-Medium"
        fontSize="12px"
        marginBottom="6px"
      >
        {label}
      </Text>
      <Box flexDirection="row" justifyContent="space-between">
        <Text
          _dark={{
            color: '#83858A'
          }}
          ellipsizeMode="tail"
          noOfLines={1}
          fontFamily="Inter"
          fontSize={valueFontSize}
          pr={5}
          w="90%"
          color="normalText"
        >
          {value}
        </Text>
        <HStack>
          {showCopy && (
            <PressableOpacity
              marginRight="12px"
              accessibilityRole="button"
              onPress={() => onCopyPress && onCopyPress()}
            >
              <Icon
                as={FontAwesome5}
                size="15px"
                _dark={{
                  color: '#83858A'
                }}
                color="lightText"
                name="copy"
                alignSelf="center"
              />
            </PressableOpacity>
          )}
          {showQr && (
            <PressableOpacity accessibilityRole="button" onPress={() => onQrPress && onQrPress()}>
              <Icon
                as={Ionicons}
                size="15px"
                // color="trueGray.500"
                name="md-qr-code-outline"
                alignSelf="center"
              />
            </PressableOpacity>
          )}
        </HStack>
      </Box>
    </Box>
  )
}

export default EditableComponent

import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import Ended from '../../../assets/ban.svg'
import Starts from '../../../assets/date_time.svg'
import { usePlugins } from '../../../queries'
import { useCommunitiesTheme } from '../../../themes'

import StartsInNote from './StartsInNote'

type ScoringTimeInfoLabelProps = {
  parsedBody: any
  eventType: string
  isThread: boolean
}

const ScoringTimeInfoLabel = (props: ScoringTimeInfoLabelProps) => {
  const { parsedBody, eventType, isThread } = props

  const communitiesTheme = useCommunitiesTheme()

  const { data: messageFacadeName } = usePlugins({
    select: (data: any) =>
      data.data[0].descriptor.facades.message.find((msg: any) => msg.id === eventType)?.name
  })

  const [currentDateTimestamp, setCurrentDateTimestamp] = React.useState(new Date().getTime())

  const { startTime, endTime, pollLength } = parsedBody

  const trueEndTime = endTime ?? pollLength

  const willBegin = startTime && currentDateTimestamp < startTime
  const hasEnded = trueEndTime && currentDateTimestamp > trueEndTime

  React.useEffect(() => {
    if (hasEnded) {
      return
    }
    const intervalId = setInterval(() => {
      setCurrentDateTimestamp(new Date().getTime())
    }, 10000)

    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let messageName
  const hasEndedDescription =
    eventType === 'v.room.sketch'
      ? 'Sketch has ended and scoring is no longer available.'
      : 'Vote has ended and voting is no longer available.'
  const willBeginDescription =
    eventType === 'v.room.sketch'
      ? 'Sketch Scoring has not begun yet. Please check back later'
      : 'Voting has not begun yet. Please check back later'

  if (eventType !== 'm.room.message') {
    messageName = messageFacadeName
  }

  const { scoringLabelStarts, scoringLabelEnded } = communitiesTheme

  return (
    <>
      {willBegin ? (
        <View
          style={{
            ...styles(isThread).container,
            backgroundColor: scoringLabelStarts.backgroundColor
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <Starts />
            <Text
              style={{
                ...styles(isThread).text,
                color: scoringLabelStarts.color
              }}
            >
              <StartsInNote startTime={startTime} beforeText={`${messageName} begins in`} />
            </Text>
          </View>
          {isThread ? (
            <Text
              style={{ ...styles().description, color: communitiesTheme.postHiddenTag.description }}
            >
              {willBeginDescription}
            </Text>
          ) : null}
        </View>
      ) : null}

      {hasEnded ? (
        <View
          style={{
            ...styles(isThread).container,
            backgroundColor: scoringLabelEnded.backgroundColor
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <Ended />
            <Text
              style={{
                ...styles(isThread).text,
                color: scoringLabelEnded.color
              }}
            >
              {messageName} has ended
            </Text>
          </View>
          {isThread ? (
            <Text
              style={{ ...styles().description, color: communitiesTheme.postHiddenTag.description }}
            >
              {hasEndedDescription}
            </Text>
          ) : null}
        </View>
      ) : null}
    </>
  )
}

export default ScoringTimeInfoLabel

const styles = (isThread?: boolean) =>
  StyleSheet.create({
    container: {
      borderRadius: 4,
      paddingHorizontal: isThread ? 16 : 9,
      paddingVertical: isThread ? 12 : 5,
      alignSelf: 'flex-start',
      marginBottom: 12,
      width: isThread ? '100%' : 'auto'
    },
    text: {
      fontSize: 13,
      fontWeight: isThread ? '600' : '400',
      marginLeft: isThread ? 8 : 4,
      lineHeight: 18
    },
    description: {
      fontSize: 11,
      lineHeight: 15,
      color: '#3F4A55',
      marginTop: 4
    }
  })

import React from 'react'

const useNumberPad = () => {
  const [amount, setAmount] = React.useState('')

  const onPressNumber = (digit: string) => {
    if (digit === '0' && amount === '0') {
      return
    }
    if (digit === '.' && amount.length === 0) {
      setAmount('0.')
      return
    }
    if (digit === '.' && amount.includes('.')) {
      return
    }
    setAmount(amount.concat(digit))
  }
  const onPressErase = () => {
    if (amount.length === 0) {
      return
    }
    setAmount(amount.slice(0, -1))
  }
  const onLongPressErase = () => {
    setAmount('')
  }

  return { amount, setAmount, onPressNumber, onPressErase, onLongPressErase }
}

export { useNumberPad }

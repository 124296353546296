import React from 'react'
import { View } from 'react-native'
import { useGetUserFollowing, useUser } from '@vatom/sdk/react'
import { observer } from 'mobx-react-lite'
import { Box, FlatList } from 'native-base'

import ItemFollowPeople from './ItemFollowPeople'

type SectionPeopleProps = {
  containerProps?: React.ComponentProps<typeof Box>
  userId: string
}

const FollowingList = ({ userId }: { userId: string }) => {
  const user = useUser()
  const followingUsers = useGetUserFollowing(userId === 'me' ? user?.sub ?? '' : userId, {
    select: relContact => relContact.filter(contact => contact.type === 'user')
  })

  return (
    <FlatList
      keyExtractor={key => `${key?.value}`}
      data={followingUsers.data}
      renderItem={({ item }) => (item ? <ItemFollowPeople item={item} /> : null)}
      showsHorizontalScrollIndicator={false}
      ListFooterComponent={<View style={{ height: 90 }}></View>}
    />
  )
}

export const SectionPeople = observer(({ containerProps, userId }: SectionPeopleProps) => {
  return (
    <Box {...containerProps}>
      <FollowingList userId={userId} />
    </Box>
  )
})

export default SectionPeople

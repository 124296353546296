/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import {
  FlatList,
  Platform,
  Pressable,
  SectionList,
  TextInput,
  TouchableOpacity,
  // Vibration,
  View,
  ViewToken
} from 'react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import Animated, {
  FadeIn,
  FadeOut,
  FadeOutLeft,
  useAnimatedStyle,
  withSpring
} from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { AntDesign } from '@expo/vector-icons'
import { BottomSheetModal, BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { CommonActions, useNavigation } from '@react-navigation/native'
import { useQuery } from '@tanstack/react-query'
import {
  type IEvent,
  createTxnId,
  getMatrixUser,
  getVatomUserFromMatrixUserId,
  isLocalRoom,
  LOCAL_ROOM_ID_PREFIX,
  MsgType,
  useCreateLocalRoom,
  useDirectMessages,
  useDmRoomBetweenUsers,
  useGetDmRoomBetweenUsers,
  useGetPublicProfile,
  useMatrixUser,
  useMatrixUserProfile,
  useReadMarkerMutation,
  useRoomMembers,
  useSDK,
  useSendFirstMessageMutation,
  useSendImageMessage,
  useSendTextMessage,
  useUserIsTypingMutation,
  useUsersPresence,
  useUserTyping
} from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { Avatar, Loader, theme, Toast } from '@vatom/wombo'
// import * as Haptics from 'expo-haptics'
import moment from 'moment'
import { Box, Center, KeyboardAvoidingView, Modal, Text, useColorModeValue } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import { BottomSheetCustomBackdrop } from '../../components/BottomSheetCustomBackdrop'
import {
  SendAudioIcon,
  SendFromWalletIcon,
  // SendGifIcon,
  SendImageIcon,
  SendMessageIcon
} from '../../components/Icons'
import { ArrowRightIcon } from '../../components/Icons/ArrowRightIcon'
import { PhoneIcon } from '../../components/Icons/PhoneIcon'
import { VideoIcon } from '../../components/Icons/VideoIcon'
import { HeaderButtonClose, ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import { useMaxWidth } from '../../components/WebWrapper'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { useKeyboardState } from '../../hooks/useKeyboardState'
import { AppStackScreenProps } from '../../navigators'
import { mxcToHttp } from '../Communities/helpers'

import { getImageDataFromEvent } from './components/helpers'
import { ImagePickerModal, ImageToSend, useImagePickerModal } from './components/ImagePicker'
import { ImagesViewerModal, ImagesViewerRef } from './components/ImagesViewer'
import { MessageBlock } from './components/Message'
import { MessageElementLayout } from './components/Message/Message'
import { ReplyPreview } from './components/Message/ReplyPreview'
import { MessageSelectedModal, useMessageSelectedModal } from './components/MessageSelectedModal'
import { ReactionDetailsModal, useReactionDetailsModal } from './components/ReactionDetailsModal'
import { useReplyStore } from './hooks/useReplyStore'
import { groupMessages, ParsedMessages } from './messageUIParser'

const isWeb = Platform.OS === 'web'

const AnimatedBox = Animated.createAnimatedComponent(Box)

const FOOTER_BOTTOM_OFFSET = 6
const FOOTER_HEIGHT = 50

const UNKNOWN_LOCAL_ROOM = `${LOCAL_ROOM_ID_PREFIX}-unknown`

const useRoomIdResolver = (roomParam: string) => {
  const { vatomIncApi } = useSDK()
  const { createLocalRoom } = useCreateLocalRoom()

  const isRoomId = roomParam.startsWith('!')
  const isLocalRoom = roomParam.startsWith(LOCAL_ROOM_ID_PREFIX)
  const vatomUserIds = roomParam.split(',').flat()

  const { data: foundRoomForUsers, isLoading, isError } = useDmRoomBetweenUsers(vatomUserIds)

  return useQuery({
    queryKey: ['dm-roomId-resolver', roomParam],
    queryFn: async () => {
      if (isLocalRoom) {
        return roomParam
      }
      if (isRoomId) {
        return roomParam
      }
      // Check for user ids for existing room
      if (foundRoomForUsers) {
        return foundRoomForUsers
      }
      // No dm room exist for user, create local room for it
      const membersRequests = vatomUserIds.map(async vatomUser => {
        const userProfile = await vatomIncApi.getPublicProfile(vatomUser)
        return {
          userId: getMatrixUser(vatomUser),
          displayname: userProfile?.name ?? '',
          avatar_url: userProfile?.picture ?? ''
        }
      })
      const members = await Promise.all(membersRequests)

      const localRoomId = createLocalRoom({ members })
      return localRoomId
    },
    enabled: isLoading === false && isError === false
  })
}

const DmScreen = React.memo(function (props: AppStackScreenProps<'DmScreen'>) {
  const mounted = useRef(false)
  const { roomId: roomParam } = props.route.params

  const { data: roomId, isLoading, isStale, isError } = useRoomIdResolver(roomParam)

  const bottomSheetModalRef = useRef<BottomSheetModal>(null)

  const insets = useSafeAreaInsets()
  const { pageTheme } = useBusinessTheme()

  const keyboardRef = useRef<typeof KeyboardAvoidingView>()

  const footerInputRef = useRef<TextInput>(null)

  const { setReplyEventId, clearReplyEventId } = useReplyStore()

  // const { data: matrixUser } = useMatrixUser()
  // const userId = matrixUser?.user_id

  // const { data: roomState } = useRoomState(roomId, {
  // select: data => {
  //   return data.filter(state => state.type === 'm.room.member' && state.state_key === userId)
  // }
  // })

  useLayoutEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    }

    return () => {
      clearReplyEventId()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPressUsersDetails = useThrottledCallback(
    () => {
      bottomSheetModalRef.current?.present()
    },
    300,
    {
      leading: true
    }
  )

  const onPressReply = useCallback(
    (replyEventId: string) => {
      console.log('LOG: > Content > onPressReply > eventId:', replyEventId)
      setReplyEventId(replyEventId)
    },
    [setReplyEventId]
  )

  return (
    <ScreenWrapper
      screenBackgroundColor={pageTheme.background}
      statusBarBackgroundColor={pageTheme.background}
      viewContainerStyle={{
        width: '100%',
        backgroundColor: pageTheme.background
      }}
      unsafe
    >
      {!roomId && (
        <View
          style={{
            flex: 1,
            paddingTop: insets.top,
            paddingBottom: insets.bottom
          }}
        >
          <View>
            {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
            <Header roomId={`${UNKNOWN_LOCAL_ROOM}`} onPressUsersDetails={() => {}} />
          </View>
          <Center flexGrow={1}>
            {(isLoading || isStale) && !isError && (
              <Box>
                <Center>
                  <Loader />
                </Center>
                <Text>Loading</Text>
              </Box>
            )}
            {isError && !isLoading && (
              <Box>
                <Text>ERROR</Text>
              </Box>
            )}
          </Center>
        </View>
      )}

      {roomId && !isLoading && !isError && (
        <GestureHandlerRootView
          style={{
            flex: 1
          }}
        >
          <BottomSheetModalProvider>
            <View
              style={{
                flex: 1,
                paddingTop: insets.top,
                paddingBottom: insets.bottom
              }}
            >
              <View>
                <Header roomId={roomId} onPressUsersDetails={onPressUsersDetails} />
              </View>
              <KeyboardAvoidingView
                ref={keyboardRef}
                style={{
                  flex: 1
                }}
                behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                keyboardVerticalOffset={Platform.OS === 'ios' ? FOOTER_BOTTOM_OFFSET : 0}
              >
                <Content roomId={roomId} onPressReply={onPressReply} />

                <View>
                  <Footer ref={footerInputRef} roomId={roomId} />
                </View>
              </KeyboardAvoidingView>
              <UsersInfoModal roomId={roomId} ref={bottomSheetModalRef} />
            </View>
          </BottomSheetModalProvider>
        </GestureHandlerRootView>
      )}
    </ScreenWrapper>
  )
})

type SendMessage =
  | {
      type: MsgType.Text
      content?: unknown
    }
  | {
      type: MsgType.Image
      content: {
        images: ImageToSend[]
        caption?: string
      }
    }

const inputLineHeight = 20
const MAX_NUM_OF_LINES = 5

const Footer = React.memo(
  React.forwardRef<TextInput, { roomId: string }>(function ({ roomId }, forwardedRef) {
    const navigation = useNavigation()
    const { pageTheme } = useBusinessTheme()

    const isLocal = isLocalRoom(roomId)
    const isLocalCreating = useRef(false)
    const { clearReplyEventId, eventId: replyEventId } = useReplyStore()

    // This will create a room before sending the first message
    const sendFirstMessage = useSendFirstMessageMutation()

    const sendTextMessage = useSendTextMessage()
    const sendImageMessage = useSendImageMessage()

    const typingMutation = useUserIsTypingMutation()

    const { isKeyboardHidden } = useKeyboardState()
    const { imagePickerVisible, closeImagePicker, pickImage } = useImagePickerModal()
    const [messageValue, setMessageValue] = useState('')
    const hasMessageValue = useMemo(() => messageValue.length > 0, [messageValue])

    const [inputContentSizeHeight, setInputContentSizeHeight] = useState(inputLineHeight)

    useEffect(() => {
      if (forwardedRef && 'current' in forwardedRef) {
        if (replyEventId && replyEventId !== '') {
          forwardedRef.current?.focus()
        }
      }
    }, [forwardedRef, replyEventId])

    const isMultiline = useMemo(() => {
      return inputContentSizeHeight !== inputLineHeight && inputContentSizeHeight > inputLineHeight
    }, [inputContentSizeHeight])

    const footerAnimatedStyles = useAnimatedStyle(() => {
      return {
        paddingTop: isKeyboardHidden ? 0 : 2
      }
    }, [isKeyboardHidden])

    const inputAnimatedStyles = useAnimatedStyle(() => {
      return {
        paddingHorizontal: 16,
        paddingVertical: 5,
        borderRadius: withSpring(isMultiline ? 8 : 40, {
          duration: 150,
          dampingRatio: 1.5,
          stiffness: 100,
          overshootClamping: false,
          restDisplacementThreshold: 0.01,
          restSpeedThreshold: 2
        })
      }
    }, [isMultiline])

    const placeholderTextColor = useColorModeValue(
      theme.colors.textLightMode[300],
      theme.colors.textDarkMode[600]
    )
    const inputTextColor = useColorModeValue(
      theme.colors.textLightMode[900],
      theme.colors.textDarkMode[300]
    )
    const iconColor = useColorModeValue(
      theme.colors.textLightMode[300],
      theme.colors.textDarkMode[600]
    )
    const sendIconColor = useColorModeValue(
      theme.colors.systemColorsLight.orange,
      theme.colors.systemColorsDark.orange
    )

    const renderIcons = useMemo(
      () =>
        isKeyboardHidden ? (
          <Animated.View
            style={{
              flexDirection: 'row'
            }}
            entering={FadeIn.duration(300)}
            exiting={FadeOutLeft.duration(300)}
          >
            <Pressable
              accessibilityRole="button"
              onPress={() => {
                pickImage()
              }}
              style={{
                width: 40,
                height: 40,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <SendImageIcon size={4} fill={iconColor} />
            </Pressable>
            {/* <Pressable
          accessibilityRole="button"
          onPress={() => console.log('LOG: Icon Press!!')}
          style={{
            width: 40,
            height: 40,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <SendGifIcon size={4} fill={iconColor} />
        </Pressable> */}
            <Pressable
              accessibilityRole="button"
              onPress={() => {
                console.log('LOG: Send from Wallet Press!!')
                Toast({
                  title: 'Send from Wallet not implemented yet!',
                  status: 'warning',
                  duration: 1500
                })
              }}
              style={{
                width: 40,
                height: 40,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <SendFromWalletIcon size={4} fill={iconColor} />
            </Pressable>
          </Animated.View>
        ) : (
          <Animated.View entering={FadeIn.duration(300)} exiting={FadeOutLeft.duration(300)}>
            <Pressable
              accessibilityRole="button"
              onPress={() => {
                console.log('LOG: Chat Options Press!!')
                Toast({
                  title: 'Chat Options not implemented yet!',
                  status: 'warning',
                  duration: 1500
                })
              }}
              style={{
                width: 40,
                height: 40,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <ArrowRightIcon size={4} fill={iconColor} />
            </Pressable>
          </Animated.View>
        ),
      [iconColor, isKeyboardHidden, pickImage]
    )

    const sendIsTyping = useThrottledCallback(
      ({ isTyping, timeout }: { isTyping: boolean; timeout?: number }) => {
        if (isLocal) {
          return
        }
        typingMutation.mutate({
          roomId,
          isTyping,
          timeout
        })
      },
      1500,
      {
        leading: true
      }
    )

    const handleChangeText = useCallback(
      (text: string) => {
        setMessageValue(() => text)
        sendIsTyping({ isTyping: true, timeout: 500 })
      },
      [sendIsTyping]
    )

    const onMessageSent = useCallback(() => {
      // Clear reply
      clearReplyEventId()
      // Send typing
      sendIsTyping({ isTyping: false })
      // Clear message value
      setMessageValue(() => '')
      // TODO: Scroll to new message
    }, [sendIsTyping, clearReplyEventId])

    const createRoomForMessage = useCallback(async () => {
      // TODO: Set loading state for room creation
      const newRoomId = await sendFirstMessage.mutateAsync({ localRoomId: roomId })
      return newRoomId
    }, [roomId, sendFirstMessage])

    const onSendMessage = useThrottledCallback(
      async ({ type, content }: SendMessage) => {
        let roomIdForMessage = roomId
        // isLocal Room send the first message
        if (isLocal && isLocalCreating.current === true) {
          return
        }
        if (isLocal) {
          roomIdForMessage = await createRoomForMessage()
          isLocalCreating.current = true
        }

        // TODO: handle other types of messages
        switch (type) {
          case MsgType.Image:
            handleSendImages(roomIdForMessage, content)
            break
          case MsgType.Text:
          default:
            handleSendMessage(roomIdForMessage)
            break
        }

        if (isLocal) {
          // Replace route params with new roomId
          const navState = navigation.getState()
          const updatedRoutes = navState.routes
          updatedRoutes[navState.index] = {
            ...updatedRoutes[navState.index],
            params: {
              roomId: roomIdForMessage
            }
          }

          navigation.dispatch(
            CommonActions.reset({
              ...navState,
              routes: updatedRoutes
            })
          )
        }
      },
      600,
      {
        leading: true
      }
    )

    const handleSendMessage = useCallback(
      (roomIdForMessage: string) => {
        onMessageSent()
        //TODO: verify if more than a trim is needed
        const messageSanitized = messageValue.trim()
        const txnId = createTxnId()
        sendTextMessage.mutate({
          roomId: roomIdForMessage,
          message: messageSanitized,
          replyTo: replyEventId,
          transactionId: txnId
        })
      },
      [messageValue, replyEventId, onMessageSent, sendTextMessage]
    )

    const handleSendImages = useCallback(
      async (
        roomIdForMessage: string,
        { images, caption }: { images: ImageToSend[]; caption?: string }
      ) => {
        onMessageSent()

        images.forEach((image, index) => {
          const isLast = index === images.length - 1
          const txnId = createTxnId()
          sendImageMessage.mutate({
            roomId: roomIdForMessage,
            image,
            caption: isLast ? caption : undefined,
            replyTo: replyEventId,
            transactionId: txnId
          })
        })
      },
      [onMessageSent, replyEventId, sendImageMessage]
    )
    const handleClearReply = useThrottledCallback(
      () => {
        console.log('LOG: > handleClearReply:', handleClearReply)
        clearReplyEventId()
      },
      300,
      {
        leading: true
      }
    )

    // // TODO: use current message value for ImagePickerModal to use as caption
    return (
      <>
        {isLocal && sendFirstMessage.isLoading && !sendFirstMessage.isError && (
          <Modal
            isOpen={true}
            position={'absolute'}
            top={0}
            right={0}
            bottom={0}
            left={0}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Box
              margin={10}
              paddingX={10}
              paddingY={6}
              alignItems={'center'}
              justifyContent={'center'}
              borderRadius={16}
              _light={{
                backgroundColor: theme.colors.grayCool[50]
              }}
              _dark={{
                backgroundColor: theme.colors.grayDarkMode[900]
              }}
              style={{
                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 0
                },
                shadowOpacity: 0.1,
                shadowRadius: 20,

                elevation: 10
              }}
            >
              <Loader size={48} />
              <Title
                preset="h3"
                variant="light"
                marginTop={3}
                textAlign={'center'}
                _light={{
                  color: theme.colors.textLightMode[900]
                }}
                _dark={{
                  color: theme.colors.textDarkMode[100]
                }}
              >
                {/* TODO: translate this */}
                Creating Room
              </Title>
            </Box>
          </Modal>
        )}
        <FooterReply roomId={roomId} eventId={replyEventId} onPressClose={handleClearReply} />
        <AnimatedBox
          position={'relative'}
          zIndex={2}
          flexDirection={'row'}
          paddingX={3}
          alignItems={'center'}
          _android={{}}
          backgroundColor={pageTheme.background}
          style={[
            {
              minHeight: FOOTER_HEIGHT
            },
            footerAnimatedStyles
          ]}
        >
          {imagePickerVisible && (
            <ImagePickerModal
              onSend={(images, caption) =>
                onSendMessage({
                  type: MsgType.Image,
                  content: {
                    images,
                    caption
                  }
                })
              }
              showModal={imagePickerVisible}
              onDismiss={closeImagePicker}
              defaultCaption={messageValue}
            />
          )}
          {renderIcons}
          <AnimatedBox
            flex={1}
            flexShrink={0}
            alignItems={'center'}
            justifyContent={'center'}
            minWidth={175}
            _light={{
              backgroundColor: theme.colors.grayCool[100]
            }}
            _dark={{
              backgroundColor: theme.colors.grayDarkMode[700]
            }}
            style={inputAnimatedStyles}
          >
            <TextInput
              accessibilityHint={'input'}
              accessibilityLabel={translate('dms.message') ?? ''}
              ref={forwardedRef}
              multiline={true}
              value={messageValue}
              onChangeText={handleChangeText}
              onContentSizeChange={({ nativeEvent: { contentSize } }) => {
                setInputContentSizeHeight(() => Math.floor(contentSize.height))
              }}
              enablesReturnKeyAutomatically={true}
              placeholder={translate('dms.message')}
              placeholderTextColor={placeholderTextColor}
              style={{
                width: '100%',
                borderWidth: 0,
                backgroundColor: 'transparent',
                color: inputTextColor,
                fontSize: 15,
                lineHeight: inputLineHeight,
                fontFamily: 'Inter',
                paddingBottom: 5,
                maxHeight: inputLineHeight * MAX_NUM_OF_LINES,
                minHeight: inputLineHeight
              }}
            />
          </AnimatedBox>
          <Box
            flex={0}
            alignItems={'center'}
            marginLeft={3}
            _web={{
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto'
            }}
          >
            {hasMessageValue ? (
              <Animated.View entering={FadeIn.duration(300)} exiting={FadeOutLeft.duration(300)}>
                <Pressable
                  accessibilityRole="button"
                  onPress={() => onSendMessage({ type: MsgType.Text })}
                  style={{
                    backgroundColor: sendIconColor,
                    borderRadius: 99,
                    width: 40,
                    height: 40,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <SendMessageIcon width={16} height={20} fill={'white'} />
                </Pressable>
              </Animated.View>
            ) : (
              <Animated.View entering={FadeIn.duration(300)} exiting={FadeOutLeft.duration(300)}>
                <Pressable
                  accessibilityRole="button"
                  onPress={() => {
                    console.log('LOG: Audio Press!!')
                    Toast({
                      title: 'Audio message not implemented yet!',
                      status: 'warning',
                      duration: 1500
                    })
                  }}
                  style={{
                    width: 40,
                    height: 40,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <SendAudioIcon width={14} height={20} fill={iconColor} />
                </Pressable>
              </Animated.View>
            )}
          </Box>
        </AnimatedBox>
      </>
    )
  })
)

const FooterReply = React.memo(function ({
  roomId,
  eventId,
  onPressClose
}: {
  roomId: string
  eventId?: string
  onPressClose: () => void
}) {
  const iconColor = useColorModeValue(
    theme.colors.textLightMode[900],
    theme.colors.textDarkMode[100]
  )

  const handleOnPress = useThrottledCallback(onPressClose, 300)

  if (!eventId) {
    return null
  }

  return (
    <Box
      flexDirection={'row'}
      alignItems={'center'}
      borderTopWidth={1}
      _light={{
        backgroundColor: theme.colors.grayCool[100],
        borderTopColor: theme.colors.grayCool[50]
      }}
      _dark={{
        backgroundColor: theme.colors.grayDarkMode[900],
        borderTopColor: theme.colors.grayDarkMode[800]
      }}
    >
      <ReplyPreview
        roomId={roomId}
        eventId={eventId}
        containerProps={{
          flex: 1
        }}
      />
      <Box paddingX={2}>
        <TouchableOpacity accessibilityRole="button" onPress={handleOnPress}>
          <AntDesign name="closecircleo" size={24} color={iconColor} />
        </TouchableOpacity>
      </Box>
    </Box>
  )
})

const DateSeparator = React.memo(function ({ date }: { date: string }) {
  const formattedDate = moment(date, 'ddd MMM DD YYYY').format('dddd, MMMM DD')

  return (
    <Box flexDirection={'row'} alignItems={'center'}>
      <Box
        flex={1}
        _light={{
          backgroundColor: theme.colors.grayCool[300]
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[400]
        }}
        style={{
          height: 1
        }}
      />
      <Text
        flex={2}
        flexShrink={0}
        paddingY={2}
        fontSize={11}
        lineHeight={15}
        fontFamily={'Inter'}
        textAlign={'center'}
        _light={{
          color: theme.colors.textLightMode[300]
        }}
        _dark={{
          color: theme.colors.grayDarkMode[200]
        }}
      >
        {formattedDate}
      </Text>
      <Box
        flex={1}
        _light={{
          backgroundColor: theme.colors.grayCool[300]
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[400]
        }}
        style={{
          height: 1
        }}
      />
    </Box>
  )
})

type Accumulator = {
  [key: string]: { data: ParsedMessages; date: string; key: string }
}

const Content = React.memo(function ({
  roomId,
  onPressReply
}: {
  roomId: string
  onPressReply: (replyEventId: string) => void
}) {
  const { data: matrixUser } = useMatrixUser()
  const userId = matrixUser?.user_id

  const { data: roomMembers } = useRoomMembers(roomId, {
    enabled: !!userId,
    select(data): IEvent[] {
      return data?.chunk.filter(event => event.state_key !== userId)
    }
  })

  const getUserName = useCallback(
    (userId: string) => {
      const userData = roomMembers?.find(user => user.sender === userId)
      return userData?.content.displayname
    },
    [roomMembers]
  )

  const setReadMarkerMutation = useReadMarkerMutation()

  // const { data: roomData } = useRoom(roomId)
  // console.log('LOG: DmScreen > Content > roomData:', roomData, roomId)

  const imagesViewerRef = useRef<ImagesViewerRef>(null)
  const messageSelectedModal = useMessageSelectedModal()
  const reactionDetailsModalRef = useRef<BottomSheetModal>(null)
  const { setReactionDetailModalEventId } = useReactionDetailsModal()

  const sectionListRef = useRef<SectionList>(null)
  const flatListRef = useRef<FlatList>(null)

  const {
    data: queryMessages,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isFetching
  } = useDirectMessages(
    { roomId },
    {
      select(data) {
        const flatMessagePages =
          data?.pages.flatMap(group => {
            return group?.chunk?.filter((event: IEvent) => !('redacted_because' in event)) ?? []
          }) ?? []
        console.log('LOG: > select > flatMessagePages:', flatMessagePages)

        return {
          ...data,
          pages: groupMessages(flatMessagePages) ?? flatMessagePages
        }
      }
    }
  )

  // NOTE: The last message needs to be the first to display
  const sectionData = useMemo(() => {
    if (!queryMessages) {
      return []
    }
    if (queryMessages?.pages.length === 0) {
      return []
    }

    return Object.values(
      queryMessages.pages.reduce((acc: Accumulator, messageGroup: IEvent[]) => {
        const firstInGroup = messageGroup[0]
        const dateFormat = new Date(firstInGroup.origin_server_ts)
        const date = dateFormat.toDateString()
        acc[date] ??= { date, data: [], key: firstInGroup.origin_server_ts.toString() }
        acc[date].data.push(messageGroup)
        return acc
      }, {})
    )
  }, [queryMessages])

  const unreadMessagesAmount = 0
  // TODO: fix this. there was a change on the list of messages, use groups
  // const unreadMessagesAmount = useMemo(() => {
  //   const eventId = roomData?.account_data?.events[0]?.content?.event_id
  //   console.log('LOG: > unreadMessagesAmount > eventId:', eventId)
  //   if (queryMessages.length === 0) {
  //     return 0
  //   } else {
  //     const foundIndex = queryMessages.findIndex(msg => msg.event_id === eventId)
  //     console.log('LOG: > foundIndex:', foundIndex, queryMessages.length)
  //     // if (!foundIndex) {
  //     // return 0
  //     // }
  //     return queryMessages.length - foundIndex
  //   }
  // }, [queryMessages, roomData?.account_data?.events])
  // console.log('LOG: > unreadMessagesAmount:', unreadMessagesAmount)

  // const unreadMessages = useMemo(() => {
  //   if (unreadMessagesAmount === 0) {
  //     return []
  //   }
  //   const size = queryMessagesMapped.length
  //   return queryMessagesMapped.splice(size - unreadMessagesAmount, unreadMessagesAmount)
  // }, [queryMessagesMapped, unreadMessagesAmount])
  // console.log('LOG: > unreadMessages > unreadMessages:', unreadMessages)

  // TODO: this should be trigger when the items are on view?
  // const setReadMarker = useCallback(
  //   (eventId?: string) => {
  //     console.log('LOG: setReadMarker > eventId:', eventId)

  //     if (queryMessagesMapped.length > 0 && unreadMessagesAmount > 0) {
  //       const eventIdToMark =
  //         eventId ?? queryMessagesMapped[queryMessagesMapped.length - 1].event_id

  //       setReadMarkerMutation.mutate({
  //         roomId,
  //         eventId: queryMessagesMapped[queryMessagesMapped.length - 1].event_id
  //       })
  //     }
  //   },
  //   [queryMessagesMapped, roomId, setReadMarkerMutation, unreadMessagesAmount]
  // )

  const onPressImageMessage = useCallback(
    (events: IEvent[], eventId?: string) => {
      // Open Viewer with image gallery
      const images = events.map(event => {
        const imageData = getImageDataFromEvent(event)
        return { ...imageData, url: mxcToHttp(imageData.url) }
      })
      const userName =
        events[0].sender === userId ? translate('dms.you') : getUserName(events[0].sender)
      imagesViewerRef.current?.setData(images, userName)
      imagesViewerRef.current?.open()
    },
    [getUserName, userId]
  )

  const onPressMessage = useThrottledCallback(
    (events: IEvent[], eventId?: string) => {
      console.log('LOG: > Message > onPressMessage:', eventId)
      if (events[0].content.msgtype === MsgType.Image) {
        onPressImageMessage(events, eventId)
        return
      }

      // Group Press
      if (!eventId) {
        // Only images for now
        if (events[0].content.msgtype === MsgType.Image) {
          onPressImageMessage(events, eventId)
          return
        }
      }
    },
    300,
    {
      leading: true
    }
  )
  const onLongPressMessage = useThrottledCallback(
    (events: IEvent[], eventId: string, elementLayout: MessageElementLayout) => {
      const event = events.find(event => event.event_id === eventId)
      console.log('LOG: > Message > onLongPressMessage:', eventId, event)
      // Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light)
      if (event) {
        messageSelectedModal.openModal([event], elementLayout)
        return
      }
      messageSelectedModal.openModal(events, elementLayout)
      return
    },
    300,
    {
      leading: true
    }
  )

  const onPressReactions = useCallback(
    (eventId: string) => {
      if (eventId) {
        // Set event to reaction details modal info
        setReactionDetailModalEventId(eventId)
        // Open modal
        reactionDetailsModalRef.current?.present()
        return
      }
    },
    [setReactionDetailModalEventId]
  )

  const handleItemsInView = useThrottledCallback(
    useCallback(
      (viewableItems: ViewToken[]) => {
        console.log(
          'LOG: > handleItemsInView > viewableItems:',
          viewableItems.length,
          viewableItems[viewableItems.length - 1].item
        )
        if (viewableItems.length === 0 || unreadMessagesAmount === 0) {
          return
        }
        // TODO: Set read markers for last item in view if there are unread messages
        const lastItem: IEvent = viewableItems[viewableItems.length - 1]?.item
        if (lastItem) {
          // setReadMarker(lastItem.event_id)
        }
      },
      [unreadMessagesAmount]
    ),
    2000,
    {
      trailing: true
    }
  )

  // TODO: REVIEW THIS
  const getOffsetUnread = useCallback(
    (currentIndex?: number) => {
      const sectionDataSize = sectionData.length
      if (sectionDataSize === 0) {
        return {
          key: '',
          sectionIndex: 0,
          itemIndex: 0
        }
      }
      const lastDataIndex = sectionDataSize === 0 ? sectionDataSize : sectionDataSize - 1
      const toCheckSectionIndex = (currentIndex ?? sectionDataSize) - 1
      if (toCheckSectionIndex < 0) {
        // Get the last one
        return {
          key: sectionData[lastDataIndex]?.key,
          sectionIndex: lastDataIndex,
          itemIndex: 0
        }
      }
      const lastSectionDataSize = sectionData[toCheckSectionIndex].data.length
      if (lastSectionDataSize < unreadMessagesAmount) {
        // If the unread messages are less that the size of the section, check the previous
        return getOffsetUnread(toCheckSectionIndex)
      }
      const itemIndex = lastSectionDataSize - unreadMessagesAmount
      return {
        key: sectionData[toCheckSectionIndex]?.key,
        sectionIndex: toCheckSectionIndex,
        itemIndex: itemIndex > 0 ? itemIndex - 1 : 0
      }
    },
    [sectionData, unreadMessagesAmount]
  )

  const unreadOffset = useMemo(() => getOffsetUnread(), [getOffsetUnread])

  return (
    <>
      <ImagesViewerModal ref={imagesViewerRef} roomId={roomId} />
      <MessageSelectedModal roomId={roomId} onPressReply={onPressReply} />
      <ReactionDetailsModal ref={reactionDetailsModalRef} roomId={roomId} />
      <SectionList
        ref={sectionListRef}
        scrollEventThrottle={32}
        onContentSizeChange={() => {
          // if (unreadOffset.key !== '') {
          // sectionListRef.current?.scrollToLocation({
          //   sectionIndex: unreadOffset.sectionIndex,
          //   itemIndex: unreadOffset.itemIndex,
          //   animated: false,
          //   viewPosition: 1
          // })
          // }
        }}
        onScrollToIndexFailed={() => ({
          index: 0
        })}
        onEndReached={() => {
          if (hasNextPage) {
            !isFetchingNextPage && fetchNextPage()
          }
        }}
        // TODO: check if this works as expected and re render once the message are read
        extraData={
          {
            // unreadMessagesAmount
          }
        }
        onViewableItemsChanged={({ viewableItems, changed }) => {
          // handleItemsInView(viewableItems)
        }}
        sections={sectionData}
        stickySectionHeadersEnabled={false}
        SectionSeparatorComponent={({ section: { date }, ...data }) =>
          data?.leadingItem && <DateSeparator date={date} />
        }
        renderItem={({ section, item: messageGroup, index }) => {
          const groupKey =
            messageGroup
              ?.map((item: IEvent) => item?.unsigned?.transaction_id ?? item.event_id)
              .join(':') ?? `${roomId}-${index}`

          return (
            <React.Fragment key={groupKey}>
              {/* {section.key === unreadOffset.key &&
                unreadOffset.itemIndex === index &&
                unreadMessagesAmount > 0 && <UnreadMarker />} */}
              <MessageBlock
                layoutDirection={messageGroup[0].sender === userId ? 'right' : 'left'}
                roomId={roomId}
                events={messageGroup}
                onPress={onPressMessage}
                onLongPress={onLongPressMessage}
                onPressReactions={onPressReactions}
                onPressReply={onPressReply}
              />
            </React.Fragment>
          )
        }}
        style={{
          flex: 1
        }}
        // NOTE: Inverted needed to start the list at the last received message
        inverted
        contentContainerStyle={{
          justifyContent: 'flex-end',
          paddingHorizontal: 16,
          marginBottom: 8
        }}
      />
    </>
  )
})

const UnreadMarker = React.memo(function () {
  return (
    <Box alignItems={'center'} marginBottom={2}>
      <Box
        style={{
          position: 'absolute',
          zIndex: 0,
          top: 12,
          left: 0,
          right: 0,
          height: 1,
          backgroundColor: theme.colors.grayDarkMode[700]
        }}
      ></Box>
      <Box
        borderRadius={10}
        paddingY={1}
        paddingX={3}
        shadow={4}
        _light={{
          backgroundColor: theme.colors.grayCool[100]
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[900]
        }}
      >
        <Text fontSize={12} fontFamily={'Inter-Light'}>
          Unread messages
        </Text>
      </Box>
    </Box>
  )
})

const Header = React.memo(function ({
  roomId,
  onPressUsersDetails
}: {
  roomId: string
  onPressUsersDetails: () => void
}) {
  const { data: matrixUser } = useMatrixUser()
  const userId = matrixUser?.user_id

  const isLocal = isLocalRoom(roomId)

  const { data: roomMembers, isError } = useRoomMembers(roomId, {
    enabled: !!userId,
    select(data): IEvent[] {
      return data?.chunk.filter(event => event.state_key !== userId)
    }
  })

  // TODO: handle multiple users
  const otherUserId = roomMembers ? (roomMembers[0].state_key as string) : ''
  const { data: userData } = useMatrixUserProfile(otherUserId, {
    enabled: otherUserId !== ''
  })

  const { data: usersPresence } = useUsersPresence([otherUserId])
  const { data: usersTyping } = useUserTyping(roomId)
  const isUserTyping = useMemo(() => {
    return usersTyping?.includes(otherUserId) ?? false
  }, [otherUserId, usersTyping])

  const isUserOnline = useMemo(() => {
    const userPresence = usersPresence ? usersPresence[otherUserId] : undefined
    return userPresence ? userPresence?.currently_active === true : false
  }, [otherUserId, usersPresence])

  const headerBorderColor = useColorModeValue(
    theme.colors.grayCool[300],
    theme.colors.grayDarkMode[700]
  )
  const headerIconColor = useColorModeValue(
    theme.colors.textLightMode[900],
    theme.colors.textDarkMode[100]
  )

  const headerRightIcons = useMemo(
    () => (
      <Box flexDirection="row" alignItems={'stretch'}>
        <Pressable
          accessibilityRole="button"
          onPress={() => {
            console.log('LOG: Phone Press!!')
            Toast({
              title: 'Phone not implemented yet!',
              status: 'warning',
              duration: 1500
            })
          }}
          style={{
            width: 32,
            height: 32,
            marginRight: 8
          }}
        >
          <PhoneIcon size={8} fill={headerIconColor} />
        </Pressable>
        <Pressable
          accessibilityRole="button"
          onPress={() => {
            console.log('LOG: Video Press!!')
            Toast({
              title: 'Video not implemented yet!',
              status: 'warning',
              duration: 1500
            })
          }}
          style={{
            width: 32,
            height: 32
          }}
        >
          <VideoIcon size={8} fill={headerIconColor} />
        </Pressable>
      </Box>
    ),
    [headerIconColor]
  )

  const renderUserState = useMemo(() => {
    if (!isUserOnline && !isUserTyping) {
      return null
    }
    if (isUserTyping) {
      return (
        <Animated.View entering={FadeIn.duration(300)} exiting={FadeOut.duration(300)}>
          <Text
            fontSize={11}
            lineHeight={15}
            fontFamily={'Inter'}
            _light={{
              color: theme.colors.textLightMode[300]
            }}
            _dark={{
              color: theme.colors.textDarkMode[300]
            }}
          >
            {`${translate('dms.isTyping')}...`}
          </Text>
        </Animated.View>
      )
    } else if (isUserOnline) {
      return (
        <Animated.View entering={FadeIn.duration(300)} exiting={FadeOut.duration(300)}>
          <Text
            fontSize={11}
            lineHeight={15}
            fontFamily={'Inter'}
            _light={{
              color: theme.colors.textLightMode[300]
            }}
            _dark={{
              color: theme.colors.textDarkMode[300]
            }}
          >
            {translate('dms.userActiveNow')}
          </Text>
        </Animated.View>
      )
    }
    return null
  }, [isUserOnline, isUserTyping])

  return (
    <ScreenHeader
      headerLeftType={'back'}
      headerTextColor={headerIconColor}
      headerProps={{
        borderBottomColor: headerBorderColor,
        borderBottomWidth: 1,
        overflow: 'hidden'
      }}
      centerContainerProps={{
        flex: 1,
        paddingX: 2
      }}
      leftContainerProps={{
        flex: 0,
        _web: {
          flexGrow: 0,
          flexShrink: 1,
          flexBasis: 'auto'
        }
      }}
      rightContainerProps={{
        flex: 0,
        _web: {
          flexGrow: 0,
          flexShrink: 1,
          flexBasis: 'auto'
        }
      }}
      headerRight={() => (isLocal ? null : headerRightIcons)}
    >
      <Pressable
        accessibilityRole="button"
        onPress={() => {
          onPressUsersDetails()
        }}
        style={{
          width: '100%',
          flexDirection: 'row'
        }}
      >
        <Avatar
          size={36}
          url={userData?.avatar_url}
          name={userData?.displayname}
          borderWidth={userData?.avatar_url ? 0 : 1}
          borderColor={headerBorderColor}
          badgeStyles={{
            right: 3,
            bottom: 3
          }}
        />
        <Box flexDirection={'column'} marginLeft={2} flex={1} justifyContent={'center'}>
          <Title preset="h5" variant="semibold" numberOfLines={1}>
            {userData?.displayname}
          </Title>
          {renderUserState}
        </Box>
      </Pressable>
    </ScreenHeader>
  )
})

const UsersInfoModal = React.memo(
  React.forwardRef<BottomSheetModal, { roomId: string }>(function ({ roomId }, forwardedRef) {
    const maxWidth = useMaxWidth()
    const { data: matrixUser } = useMatrixUser()
    const userId = matrixUser?.user_id

    const navigation = useNavigation()

    const { data: roomMembers, isError } = useRoomMembers(roomId, {
      enabled: !!userId,
      select(data): IEvent[] {
        return data?.chunk.filter(event => event.state_key !== userId)
      }
    })

    // TODO: handle multiple users
    const otherUserId = roomMembers ? (roomMembers[0].state_key as string) : ''
    const { data: userData } = useMatrixUserProfile(otherUserId, {
      enabled: otherUserId !== ''
    })
    const vatomUserId = useMemo(
      () => (otherUserId ? getVatomUserFromMatrixUserId(otherUserId) : ''),
      [otherUserId]
    )
    // const usersDataVatom = useGetPublicProfiles([vatomUserId])
    const { data: userDataVatom } = useGetPublicProfile(vatomUserId)
    const userPicture = userData?.avatar_url ?? userDataVatom?.picture

    const infoContentTitle = useMemo(() => {
      if (roomMembers?.length && roomMembers?.length > 1) {
        return translate('dms.groupInfo')
      }
      return translate('dms.contactInfo')
    }, [roomMembers])

    const headerIconColor = useColorModeValue(
      theme.colors.textLightMode[900],
      theme.colors.textDarkMode[100]
    )
    const bottomSheetStyles = useColorModeValue(
      {
        container: {
          backgroundColor: theme.colors.white
        },
        indicator: {
          backgroundColor: theme.colors.grayCool[400]
        }
      },
      {
        container: {
          backgroundColor: theme.colors.grayDarkMode[900]
        },
        indicator: {
          backgroundColor: theme.colors.grayDarkMode[100]
        }
      }
    )

    const onPressClose = useThrottledCallback(
      () => {
        // @ts-ignore
        forwardedRef?.current?.dismiss()
      },
      300,
      { leading: true }
    )

    return (
      <BottomSheetModal
        ref={forwardedRef}
        index={0}
        snapPoints={['94%']}
        enablePanDownToClose={true}
        enableDismissOnClose={true}
        handleIndicatorStyle={{
          backgroundColor: bottomSheetStyles.indicator.backgroundColor
        }}
        handleStyle={{
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          backgroundColor: bottomSheetStyles.container.backgroundColor
        }}
        backgroundStyle={{
          backgroundColor: bottomSheetStyles.container.backgroundColor
        }}
        containerStyle={{
          backgroundColor: 'transparent'
        }}
        style={{
          maxWidth: isWeb ? maxWidth : undefined,
          backgroundColor: 'transparent',
          marginHorizontal: isWeb ? 'auto' : 4
        }}
        backdropComponent={BottomSheetCustomBackdrop}
      >
        <Box
          flexDirection={'row'}
          marginX={3}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <ScreenHeaderButton onPress={onPressClose}>
            <HeaderButtonClose color={headerIconColor} />
          </ScreenHeaderButton>
          <Title preset="h5" variant="semibold">
            {infoContentTitle}
          </Title>
          <ScreenHeaderButton></ScreenHeaderButton>
        </Box>
        <Box flex={1} marginTop={3}>
          <Box alignItems={'center'}>
            <Box marginY={3}>
              <Avatar
                size={88}
                url={userPicture}
                name={userData?.displayname}
                borderColor={headerIconColor}
                borderWidth={1}
                badgeStyles={{
                  right: 3,
                  bottom: 3
                }}
              />
            </Box>
            <Title preset="h4" variant="bold" marginTop={2}>
              {userData?.displayname}
            </Title>
            {userDataVatom?.preferred_username && (
              <Title preset="h6" variant="light" marginTop={2}>
                {`@${userDataVatom?.preferred_username}`}
              </Title>
            )}
          </Box>
        </Box>
      </BottomSheetModal>
    )
  })
)

export { DmScreen }

import { useCallback, useEffect } from 'react'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useConfig, useIsAuthed, useSDK } from '@vatom/sdk/react'
import { LoaderView } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'

import { AppNavigation, AppRoutes, AppStackScreenProps } from '../../navigators'

type AcquirePubVariationProps = AppStackScreenProps<
  | typeof AppRoutes.AcquirePubVariation
  | typeof AppRoutes.AcquirePubVariation1
  | typeof AppRoutes.AcquirePubVariation2
>

export const AcquirePubVariation = observer(({ route }: AcquirePubVariationProps) => {
  const focused = useIsFocused()
  const isAuthed = useIsAuthed()
  const navigation = useNavigation<AppNavigation>()
  const sdk = useSDK()
  const config = useConfig()

  const onAcquire = useCallback(async () => {
    if (route.params.ar) {
      const msg = route.params.msg ?? 'Acquiring...'
      navigation.navigate(AppRoutes.TokenAR, {
        tokenId: route.params.tokenId,
        msg
      })
      return
    }

    try {
      await sdk.service.userVatom({
        method: 'post',
        url: '/action/AcquirePubVariation',
        data: {
          'this.id': route.params.tokenId
        },
        headers: {
          'App-Id': config.appID,
          Authorization: `bearer ${sdk.dataPool.sessionStore?.vatomNetworkSessionToken?.accessToken}`
        }
      })
      navigation.navigate(AppRoutes.home)
    } catch (error) {
      navigation.navigate(AppRoutes.home)
    }
  }, [
    config.appID,
    navigation,
    route.params.ar,
    route.params.msg,
    route.params.tokenId,
    sdk.dataPool.sessionStore?.vatomNetworkSessionToken?.accessToken,
    sdk.service
  ])

  useEffect(() => {
    if (isAuthed) {
      onAcquire()
    }
  }, [isAuthed, onAcquire])

  if (!focused) {
    return null
  }

  return <LoaderView />
})

export default AcquirePubVariation

import { useCallback, useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { BusinessSnapshot, SessionSource, SessionType } from '@vatom/sdk/core'

import logger from '../logger'
import { refreshToken } from '../providers/BVatomRefreshTokenProvider'
import { BusinessData, useSDK } from '../store'

export const useUser = () => {
  const sdk = useSDK()
  return sdk.dataPool.user.userInfo
}

export const useUserProfile = (userId: string) => {
  const sdk = useSDK()

  const userProfileQuery = useQuery(
    ['user', 'profile', userId],
    async () => await sdk.vatomIncApi.getPublicProfile(userId),
    { enabled: !!userId }
  )
  return userProfileQuery
}

export const useAccount = () => {
  const sdk = useSDK()
  const default_business_id = sdk.dataPool.user.userInfo?.default_business_id

  const { data, refetch, error } = useQuery(
    ['user_account', default_business_id],
    async ({ queryKey }) => {
      if (!queryKey[1]) throw new Error('[useAccount]: no business id available')
      return await sdk.vatomIncApi.getAccount(queryKey[1])
    },
    {
      enabled: !!default_business_id,
      refetchOnWindowFocus: false
    }
  )

  return {
    account: data,
    refetchAccount: refetch
  }
}

export const useNetworkUserId = () => {
  const sdk = useSDK()
  const session = sdk.dataPool.sessionStore.getFirstByTypeAndSource(
    SessionType.UserId,
    SessionSource.VatomNetwork
  )

  return session?.value as string
}

export const useAccessToken = () => {
  const sdk = useSDK()
  const session = sdk.dataPool.sessionStore.vatomIncSessionToken
  const isExpired = session && sdk.dataPool.sessionStore.isVatomExpired
  if (isExpired) {
    console.error('Access token is expired', session)
  }
  return !session || isExpired ? null : session?.accessToken
  // return session?.accessToken ?? null
}

export const useSessionExpiresAt = () => {
  const sdk = useSDK()
  return sdk.dataPool.sessionStore.vatomIncSessionToken?.expiresAt ?? 0
}

export const useCampaignUserInfo = (b?: BusinessData) => {
  const accessToken = useAccessToken()
  const businessId = b?.id
  const sdk = useSDK()

  const defaultCampaignId = b?.defaultCampaignId

  const fetchCampaignUserInfo = async () => {
    if (!accessToken || !businessId || !defaultCampaignId) {
      throw new Error('Missing required params')
    }
    try {
      const url = `/api/me/info?business-id=${businessId}&campaign-id=${defaultCampaignId}`
      const res = await sdk.service.oidc.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      return res.data
    } catch (e) {
      logger.error(e)
      throw new Error('Failed to fetch campaign user info')
    }
  }

  const {
    data: campaignUserInfo,
    isLoading,
    error,
    refetch
  } = useQuery(
    ['campaignUserInfo', accessToken, businessId, defaultCampaignId],
    fetchCampaignUserInfo,
    {
      enabled: !!accessToken && !!businessId && !!defaultCampaignId,
      refetchOnWindowFocus: false
    }
  )

  return {
    campaignUserInfo,
    isLoading,
    error,
    fetchCampaignUserInfo: refetch
  }
}

export const useUserInfo = (b?: BusinessData) => {
  const accessToken = useAccessToken()
  const businessId = b?.id
  const sdk = useSDK()

  const [isLoading, setLoading] = useState(true)
  // Each microsite has its own type for userInfo
  const [userInfo, setUserInfo] = useState<unknown>()
  const [error, setError] = useState<unknown>()

  const fetchUserInfo = useCallback(async () => {
    if (!accessToken || !businessId) {
      return
    }
    try {
      const url = `/api/me/info?business-id=${businessId}`
      const res = await sdk.service.oidc.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })

      setUserInfo(res.data)
      return res.data
    } catch (e) {
      logger.error(e)
      setError(e)
    }
  }, [accessToken, businessId, sdk.service.oidc])

  useEffect(() => {
    fetchUserInfo().then(() => {
      setLoading(false)
    })
  }, [fetchUserInfo])

  return {
    userInfo,
    isLoading,
    error,
    fetchUserInfo
  }
}

import React, { createContext, useContext } from 'react'
import { Instance } from 'mobx-state-tree'

import { AddMessageModalStore } from './AddModals/stores'

const dataStore = AddMessageModalStore.create()
export const CreateMessageContext = createContext<Instance<typeof AddMessageModalStore>>(dataStore)

export const CreateMessageProvider = ({
  dataStore,
  children
}: {
  dataStore: Instance<typeof AddMessageModalStore>
  children: React.ReactNode
}) => {
  return <CreateMessageContext.Provider value={dataStore}>{children}</CreateMessageContext.Provider>
}

export const useStoreContext = () => useContext(CreateMessageContext)

import React, { useCallback, useEffect } from 'react'

import { NumberPad as NumberPadBase, NumberPadProps } from './NumberPad'

const numbersKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.']

export const NumberPad = (props: NumberPadProps) => {
  const handleKeyDown = (event: KeyboardEvent) => {
    const eventKey = event.key.toLowerCase()
    if (eventKey === 'backspace') {
      event.preventDefault()
      props.onPressErase()
    }
    if (numbersKeys.find(number => number === eventKey)) {
      event.preventDefault()
      props.onPress(eventKey)
    }
  }
  const handleKeyDownMemo = useCallback(handleKeyDown, [props])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDownMemo)
    return () => {
      window.removeEventListener('keydown', handleKeyDownMemo)
    }
  }, [handleKeyDownMemo])
  return <NumberPadBase {...props} />
}

import React from 'react'
import { Box, Text } from 'native-base'

function ProfileUserBio({
  text,
  ...boxProps
}: {
  text: string
} & React.ComponentProps<typeof Box>) {
  return (
    <Box
      alignItems={'center'}
      justifyContent={'center'}
      marginX={8}
      _web={{
        maxWidth: 330
      }}
      {...boxProps}
    >
      <Text fontFamily={'Inter-Regular'} fontSize={13} lineHeight={18} textAlign={'center'}>
        {text}
      </Text>
    </Box>
  )
}
export { ProfileUserBio }

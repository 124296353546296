import React from 'react'
import { UserSnapshot } from '@vatom/sdk/core'
import { useAccount, useBusiness, useConfig } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { observer } from 'mobx-react-lite'
import { Box } from 'native-base'

import WalletPreference from './WalletPreferrence'
import { LinkingComponent, LinkingType } from './'

interface LinkingDataItemProps {
  type: LinkingType
  label: string
  href?: string
  onPress?: () => void
}

interface IVatomProfileProps {
  user?: UserSnapshot
  logoutOrDisconnect: () => void
  openWalletQrCode: (walletAddress: string) => void
  background: string
}

export const VatomProfile = observer(
  ({ user, logoutOrDisconnect, openWalletQrCode, background }: IVatomProfileProps) => {
    const config = useConfig()
    const linkingData: Array<LinkingDataItemProps> = [
      {
        type: LinkingType.externalLink,
        label: translate('common.support'),
        href: config.supportURL
      },
      {
        type: LinkingType.externalLink,
        label: translate('common.termsOfService'),
        href: config.termsOfUseURL
      },
      {
        type: LinkingType.externalLink,
        label: translate('common.privacyPolicy'),
        href: config.privacyPolicyURL
      }
    ]
    const { data: business } = useBusiness(
      {
        businessId: user?.default_business_id || ''
      },
      {},
      false
    )
    const { account } = useAccount()

    const sellerAccountStatus = account?.sellerAccount.status
      ? translate(`common.${account?.sellerAccount.status}`)
      : ''

    return (
      <Box>
        {config.showBusinessProfile
          ? business?.displayName && (
              <LinkingComponent
                type={LinkingType.forward}
                label={`${translate('profileScreen.businessProfile')} - ${sellerAccountStatus}`}
                href={`${config.studioServer}/${business.id}/settings/payouts`}
              />
            )
          : null}

        <WalletPreference />
        {linkingData.map(({ label, href, type }, index) => (
          <LinkingComponent key={`Linking${index}`} type={type} label={label} href={href} />
        ))}
      </Box>
    )
  }
)

export default VatomProfile

import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { useConfig } from 'libs/sdk/react/src/hooks'

import { useSDK } from '../../../SDKProvider'

import { GeoDiscoveryGroups, GeoDiscoveryGroupsOptions, GeoDiscoveryGroupsRequest } from './types'

export const geoDiscoveryGroupsHost = 'https://api.vi.vatom.network'

export const geoDiscoveryQueryKeys = {
  geoDiscoveryGroups: [{ scope: 'geo-discovery-groups' }] as const,
  getGeoDiscoveryGroups: (vatomToken: string, appId: string, payload: GeoDiscoveryGroupsRequest) =>
    [{ ...geoDiscoveryQueryKeys.geoDiscoveryGroups[0], appId, vatomToken, payload }] as const
}

export const useGeoDiscoveryGroups = <T = GeoDiscoveryGroups>(
  payload: GeoDiscoveryGroupsRequest,
  options?: GeoDiscoveryGroupsOptions<T>
) => {
  const sdk = useSDK()
  const config = useConfig()
  const accessToken = sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken ?? ''
  const appId = config.appID
  const query = useQuery({
    queryKey: geoDiscoveryQueryKeys.getGeoDiscoveryGroups(accessToken, appId, payload),
    queryFn: fetchGeoDiscoveryGroups,
    enabled:
      !!accessToken &&
      !!appId &&
      !!payload.bottom_left &&
      !!payload.top_right &&
      payload.precision < 12 &&
      payload.precision > 0,
    ...options,
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: true
  })

  return query
}

const fetchGeoDiscoveryGroups = async ({
  queryKey: [{ vatomToken, appId, payload }]
}: QueryFunctionContext<ReturnType<typeof geoDiscoveryQueryKeys['getGeoDiscoveryGroups']>>) => {
  const res = await fetch(`${geoDiscoveryGroupsHost}/v1/vatom/geodiscovergroups`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${vatomToken}`,
      'App-Id': appId
    }),
    body: JSON.stringify(payload)
  })

  const data = await res.json()
  return data
}

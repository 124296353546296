/* eslint-disable unicorn/filename-case */
import { useCallback, useRef } from 'react'
import { IPostMessage, MessageTypes } from '@vatom/experience-sdk'

import { useWebViewRefStore } from '../../../../hooks/useWebViewRef'

import { useWebBusinessProviderBase } from './base'

export const useWebBusinessProvider = () => {
  const webViewRef = useRef<HTMLIFrameElement | null>(null)

  const sendMessage = <T extends MessageTypes>(message: IPostMessage<T>) => {
    if (!webViewRef.current?.contentWindow) {
      return
    }

    const date = Date.now()
    const msg = {
      data: JSON.stringify(message),
      triggeredAt: date
    }

    webViewRef.current.contentWindow.postMessage(msg, '*')
  }

  const { messageHandler, history, isBackButtonSubscribed, setIsBackButtonSubscribed } =
    useWebBusinessProviderBase({
      sendMessage
    })

  const setRef = (ref: HTMLIFrameElement | null) => {
    webViewRef.current = ref

    // @ts-expect-error webview and iframe has different type
    if (ref) useWebViewRefStore.getState().setRef(ref)
  }

  // Receives and strinigies the payload
  const onMessage = useCallback(
    (event: any) => {
      try {
        const data = JSON.parse(event.data)
        messageHandler({
          ...event.currentTarget.navigation,
          data
        })
      } catch (error) {
        return
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return {
    onMessage,
    setRef,
    webViewRef,
    sendMessage,
    history,
    isBackButtonSubscribed,
    setIsBackButtonSubscribed
  }
}

import React, { ComponentProps, useEffect, useState } from 'react'
import moment from 'moment'
import { Text } from 'native-base'

// TODO: add locale es
moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 'Just now',
    ss: 'Just now',
    // s: 'a few seconds',
    // ss: '%d seconds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    // d: '1 day',
    d: '1d',
    dd: '%dd',
    w: '1w',
    ww: '%dw',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years'
  }
})

export const getTimeAgo = (time: any) => moment(moment(time)).fromNow()

type TimeAgoProps = {
  time: number | Date
  interval?: number
  intervalEnable?: boolean
  componentProps?: ComponentProps<typeof Text>
}
function TimeAgo({
  time,
  interval = 60000, // 60 sec
  intervalEnable = true,
  componentProps
}: TimeAgoProps) {
  const [, setTime] = useState(Date.now())

  useEffect(() => {
    if (intervalEnable) {
      const i = setInterval(() => setTime(Date.now()), interval)
      return () => {
        clearInterval(i)
      }
    }
  }, [interval, intervalEnable])

  return <Text {...componentProps}>{getTimeAgo(time)}</Text>
}

export { TimeAgo }

import React, { ReactNode, useState } from 'react'
import {
  Alert as NbAlert,
  Box,
  CloseIcon,
  Collapse,
  HStack,
  IAlertProps,
  IconButton,
  Text,
  VStack
} from 'native-base'

interface AlertProps extends IAlertProps {
  status?: string
  message?: string | ReactNode
  title?: string
  hideStatusIcon?: boolean
  closeIcon?: JSX.Element
}

export const Alert = ({
  status = 'success',
  message = 'Create a Vatom account to view NFTs across wallets',
  title,
  hideStatusIcon,
  closeIcon,
  ...props
}: AlertProps) => {
  const [showAlert, setShowAlert] = useState(true)

  return (
    <Box w="100%" alignItems="center">
      <Collapse isOpen={showAlert}>
        <NbAlert w="100%" status={status} {...props}>
          <VStack space={1} flexShrink={1} w="100%">
            <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
              <HStack flexShrink={1} space={2} alignItems="center">
                {hideStatusIcon ? null : <NbAlert.Icon />}

                {title ? (
                  <Text fontSize="md" fontWeight="semibold">
                    {title}
                  </Text>
                ) : (
                  <Box>{message}</Box>
                )}
              </HStack>
              <IconButton
                variant="unstyled"
                icon={closeIcon ? closeIcon : <CloseIcon size="3" />}
                onPress={() => setShowAlert(false)}
              />
            </HStack>
            {title && <Box pl="6">{message}</Box>}
          </VStack>
        </NbAlert>
      </Collapse>
    </Box>
  )
}

export default Alert

// eslint-disable-next-line unicorn/filename-case
import { useCallback, useEffect, useRef } from 'react'
import { BVatomTokenType } from '@vatom/BVatom/plugin'
import { TokenType } from '@vatom/sdk/core'
import { FaceHandler, useAnalytics } from '@vatom/sdk/react'

import logger from '../../../logger'

export interface IUseTokenInit {
  token?: TokenType
  faceRef?: React.MutableRefObject<FaceHandler | undefined>
}

export const useTokenInit = ({ token, faceRef }: IUseTokenInit) => {
  const analytics = useAnalytics()
  const openTime = useRef<Date>(new Date())

  // this is a generic behavior/action that applies to most studio vatoms. allows for certain generic outcomes, such as generating a share link, grabbing a coupon code, etc.
  // it is also used to trigger the user registration process if user is unregistered
  const handleLifecycle = useCallback(async () => {
    if (token?.type !== 'vatom') return

    logger.info('[NFTDetail] handleLifecycle')

    const vatom = token as BVatomTokenType
    const hasLifecycle = vatom.actions.find(a => a === 'Initialize')

    if (hasLifecycle) {
      const isInitialized = vatom.private['lifecycle-v1']?.initialized
      const supportsRefresh = vatom.private['lifecycle-v1']?.supportsRefresh
      if (!isInitialized || supportsRefresh) {
        const isGuestUser = false //TODO

        try {
          const res = await vatom.performAction('Initialize', {
            is_guest_user: isGuestUser
          })
          // Log analytics
          analytics.event(
            'performAction',
            {
              actionUri: 'Initialize'
            },
            token
          )

          if (faceRef) {
            faceRef.current?.sendRequest?.('core.vatom.init', res.output)
          } else {
            console.error('No Face')
          }
        } catch (e) {
          logger.error(`Error performing initialize action: ${e}`)
        }
      }
    }
  }, [analytics, faceRef, token])

  useEffect(() => {
    if (!token) return

    analytics.event('openVatom', {}, token)

    handleLifecycle()
    const currentOpenTime = openTime.current
    return () => {
      analytics.event(
        'closeVatom',
        {
          eventValue: currentOpenTime ? new Date().getTime() - currentOpenTime.getTime() : 0
        },
        token
      )
    }
  }, [analytics, token, handleLifecycle])

  return {}
}

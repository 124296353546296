import { Instance, types } from 'mobx-state-tree'

export const LatLon = types.model('LatLon', {
  lat: types.optional(types.number, 0),
  lon: types.optional(types.number, 0)
})

export const BoundingBoxModel = types.model('BoundingBox').props({
  top_right: types.optional(LatLon, {}),
  bottom_left: types.optional(LatLon, {})
})

export type BoundingBoxType = Instance<typeof BoundingBoxModel>

import React, { useCallback } from 'react'
import { FlatList } from 'react-native'
import Animated from 'react-native-reanimated'
import { useBusiness } from '@vatom/sdk/react'
import { Button, Text, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Image } from 'native-base'

import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../../hooks/useIsDesktop'
import { useLayoutScrollHandler } from '../../../hooks/useLayoutScrollHandler'
import { Coin, useLoyaltyCoins } from '../../../hooks/useLoyalty'
import { useScrollContentSize } from '../../../hooks/useScrollContentSize'
import { useCoinStore } from '../../CoinNavigation/useCoinStore'
import { CoinItemProps, CoinListItem } from '../components/CoinListItem'

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList)

type CoinListBusinessItemProps = Omit<CoinItemProps, 'subTitle'> & {
  businessId: string
  isSelected: boolean
  address?: string
  isWatching?: boolean
}
const CoinListBusinessItem = ({
  businessId,
  id,
  title,
  imageURL,
  balance,
  onPress,
  isSelected,
  address,
  isWatching
}: CoinListBusinessItemProps) => {
  const isDesktop = useIsDesktop()
  const { data: business } = useBusiness({
    businessId
  })
  const { isDark } = useBusinessTheme()
  const subTitle = business?.displayName ?? ''
  const containerPropsSelected =
    isDesktop && isSelected
      ? {
          backgroundColor: isDark ? theme.colors.grayCool[700] : theme.colors.grayCool[100]
        }
      : {}
  return (
    <CoinListItem
      id={id}
      title={title}
      subTitle={address ? shortenEthAddress(address) : subTitle}
      // networkURL={getBusiness(item.businessId)?.logoSrc ?? ''}
      imageURL={imageURL}
      balance={balance}
      onPress={onPress}
      isWatching={isWatching}
      containerProps={{
        _web: {
          paddingX: isDesktop ? 3 : 0
        },
        ...containerPropsSelected
      }}
    />
  )
}

type CoinListProps = {
  data: ReturnType<typeof useLoyaltyCoins>['data']
  isLoading: boolean
  containerProps?: React.ComponentProps<typeof Box>
  onPressCoin: (coinId: Coin) => void
  onRefresh?: () => void
  isRefreshing?: boolean
}

const CoinsList = observer(
  ({ data, isLoading, onPressCoin, onRefresh, isRefreshing, containerProps }: CoinListProps) => {
    const currentCoinId = useCoinStore(state => state.coinId)
    const { scrollHandler, scrollEventThrottle, getHeaderHeight, getFooterHeight } =
      useLayoutScrollHandler()
    const isDesktop = useIsDesktop()

    const { onContentSizeChange, scrollEnabled } = useScrollContentSize()

    const renderCoinsItem = useCallback(
      ({ item }: { item: any }) => {
        if (!item) return null
        return (
          <CoinListBusinessItem
            businessId={item.businessId}
            id={item.id}
            title={item.plural_name}
            imageURL={item.logo}
            balance={`${item.points}`}
            onPress={() => onPressCoin(item)}
            isSelected={Boolean(item.id === currentCoinId)}
            address={item.address}
            isWatching={item.isWatching}
          />
        )
      },
      [currentCoinId, onPressCoin]
    )

    // const ListLoadingComponent = () => (
    //   <Box marginTop={4}>
    //     <LoaderView size={4} blocking={false} />
    //   </Box>
    // )

    const headerHeight = isDesktop ? 0 : getHeaderHeight()
    const footerHeight = isDesktop ? 0 : getFooterHeight()

    return (
      <Box flex={1} {...containerProps}>
        <AnimatedFlatList
          data={data}
          ListEmptyComponent={!isLoading ? EmptyCoins : null}
          keyExtractor={(item: any, index) => `coin-${item?.id || index}`}
          renderItem={renderCoinsItem}
          onRefresh={onRefresh}
          refreshing={isRefreshing}
          onContentSizeChange={onContentSizeChange}
          scrollEnabled={scrollEnabled}
          onScroll={scrollHandler}
          scrollEventThrottle={scrollEventThrottle}
          contentContainerStyle={{
            paddingTop: headerHeight,
            paddingBottom: footerHeight
          }}
        />
        {/* {isLoading ? (
          <ListLoadingComponent />
        ) : (
          <FlatList
            data={data}
            ListEmptyComponent={EmptyCoins}
            keyExtractor={item => item.id}
            renderItem={renderCoinsItem}
            // onRefresh={onRefresh}
            // refreshing={isRefreshing}
            contentContainerStyle={{}}
          />
        )} */}
      </Box>
    )
  }
)

const EmptyCoins = () => {
  return (
    <Box flex={1} justifyContent="center" alignItems="center">
      <Image
        accessibilityIgnoresInvertColors={true}
        alt="claim"
        h={250}
        w={250}
        resizeMode="contain"
        source={require('../assets/empty_coins.png')}
      />
      <Text
        color="black"
        fontFamily="Inter-SemiBold"
        fontSize={16}
        fontWeight="600"
        mb={2.5}
        mt={10}
        textAlign="center"
        tx="coinsScreen.noCoins"
      />
      <Text
        color="gray.500"
        fontFamily="Inter-Regular"
        fontSize={13}
        fontWeight="400"
        mb={10}
        textAlign="center"
        tx="coinsScreen.notEnrolled"
      />
    </Box>
  )
}

export { CoinsList }

function shortenEthAddress(address: string, displayLength = 8) {
  if (!address) {
    return ''
  }

  // Make sure the address is valid (length greater than 8)
  if (address.length <= displayLength) {
    return address
  }

  // Get the first characters
  // const firstCharacters = address.slice(0, displayLength / 2)

  // Get the last characters
  const lastCharacters = address.slice(-displayLength)

  // Build the shortened address with ellipsis in the middle
  const shortenedAddress = `...${lastCharacters}`

  return shortenedAddress
}

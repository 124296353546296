import React from 'react'
import { ImageStyle, Platform, StyleProp, ViewProps } from 'react-native'
import { AntDesign, Ionicons } from '@expo/vector-icons'
import { ListingStatus, TokenType, TokenTypes } from '@vatom/sdk/core'
import { HStack, Image, View } from 'native-base'

import logger from '../../logger'

const ImageMapMarked = require('./map-marked-alt.png')

export interface BadgeInterface extends ViewProps {
  token: TokenType
  style?: StyleProp<ImageStyle>
  iconSize?: number
}

export interface BadgeProps extends ViewProps {
  tokenType: TokenTypes
  isActive?: boolean
  style?: StyleProp<ImageStyle>
  iconSize?: number
}

export function TokenBadge(props: BadgeProps) {
  const { tokenType, isActive, ...rest } = props
  switch (tokenType) {
    case TokenTypes.VatomBasic:
    case TokenTypes.VatomMintedCustodial:
    case TokenTypes.VatomMintedNonCustodial:
    case TokenTypes.VatomUnMinted:
      return (
        <Image
          source={require('./smart-vatom.png')}
          alt={tokenType}
          accessibilityIgnoresInvertColors
          tintColor={isActive ? undefined : 'gray.100'}
          {...rest}
        />
      )
    case TokenTypes.Solana:
      return (
        <Image
          source={require('./solana.png')}
          alt="Solana"
          accessibilityIgnoresInvertColors
          tintColor={isActive ? undefined : 'gray.100'}
          {...rest}
        />
      )
    case TokenTypes.Erc721:
      return (
        <Image
          source={require('./nft.png')}
          alt="Erc721"
          accessibilityIgnoresInvertColors
          tintColor={isActive ? undefined : 'gray.100'}
          {...rest}
        />
      )

    default:
      logger.info('No badge found')
      return <View />
  }
}

export function TokenBadges(props: BadgeInterface) {
  const { token, iconSize, ...rest } = props
  const isWeb = Platform.OS === 'web'

  const tokenType = token.getTokenType()
  // Had to spit out the individual properties because the object seems to get serialized at this point
  const badges: JSX.Element[] = []

  badges.push(
    <TokenBadge
      key="token-badge"
      tokenType={tokenType === 'vatom-new' ? TokenTypes.VatomBasic : tokenType}
      isActive={tokenType !== TokenTypes.VatomMintedNonCustodial}
      {...rest}
    />
  )

  // Only show the chain for vatoms
  if (token.blockchainInfo && token.type === 'vatom') {
    const name = token.blockchainInfo.network
    const badge = token.getNetworkIcon(name)
    badges.push(
      <Image
        key="BadgeBlockchain"
        source={badge}
        alt={name}
        accessibilityIgnoresInvertColors
        {...rest}
        opacity={token.isMinted ? 1 : 0.3}
      />
    )
  }

  if (token.commerceInfo?.status === ListingStatus.Active) {
    badges.push(
      <Ionicons
        key="BadgeCommerceInfoStatusActive"
        name="pricetag-outline"
        size={iconSize ? iconSize : 16}
        opacity={token.isMinted ? 1 : 0.3}
        style={{ marginRight: 13 }}
      />
    )
  } else if (token.commerceInfo?.status === ListingStatus.Pending) {
    badges.push(
      <AntDesign
        key="BadgeCommerceInfoStatusPending"
        name="clockcircleo"
        size={iconSize ? iconSize : 16}
        opacity={token.isMinted ? 1 : 0.3}
        style={{ marginRight: 13 }}
      />
    )
  }

  if (token.position) {
    badges.push(
      <Image
        key="BadgeTokenPosition"
        source={ImageMapMarked}
        alt="dropped"
        accessibilityIgnoresInvertColors
        {...rest}
      />
    )
  }

  return <HStack>{badges}</HStack>
}

import React from 'react'
import { SafeAreaView } from '@vatom/wombo'
import { Box } from 'native-base'

import { color } from '../../theme'

import ActionItem from './partials/ActionItem'
import Content from './partials/Content'
import Footer from './partials/Footer'
import Header from './partials/Header'

export const SettingsMetamask = () => {
  return (
    <Box bgColor="gray.50" flex={1}>
      <SafeAreaView style={{ backgroundColor: color.primary }} />
      <Box bgColor="gray.50" flex={1}>
        <Header />
        <Content />
        <ActionItem onPress={() => true} title="Add email or phone number" />
        <Box m={1} />
        <ActionItem onPress={() => true} title="Feedback" />
        <ActionItem onPress={() => true} title="Terms of use" />
        <ActionItem onPress={() => true} title="Privacy Policy" />
        <Footer />
      </Box>
      <SafeAreaView />
    </Box>
  )
}

export default SettingsMetamask

import { useCallback, useMemo } from 'react'
import { Platform } from 'react-native'
import { InfiniteData, MutateOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  EventType,
  IEvent,
  matrixQueryKeys,
  MsgType,
  useMatrixUser,
  useUploadMediaMutation
} from '@vatom/sdk/react'
import axios from 'axios'
import { manipulateAsync, SaveFormat, SaveOptions } from 'expo-image-manipulator'

import { createLocalDmRoom, Member } from './actions'
import { areMatrixUsers, createTxnId, getMatrixUsers } from './helpers'
import {
  dmQueryKeys,
  FetchReactions,
  matrixServerUrl,
  MessageData,
  RoomMembers,
  useAccountDataEvents,
  useGetDmRoomBetweenUsers
} from './queries'

const isWeb = Platform.OS === 'web'

export const TEMP_EVENT_PREFIX = 'tempEventId_'

export const useJoinMutation = () => {
  const queryClient = useQueryClient()
  const matrixUser = useMatrixUser()
  const setDmRoomMutation = useSetDmRoomMutation()
  const queryKey = matrixQueryKeys.getMatrixFullStateSync(matrixUser.data?.access_token)
  return useMutation({
    mutationFn: async ({ roomId, userId }: { roomId: string; userId: string }) => {
      return await axios
        .post(
          `${matrixServerUrl}/_matrix/client/v3/rooms/${roomId}/join?access_token=${matrixUser.data?.access_token}`
        )
        .then(({ data }) => data)
    },
    onSuccess: async (data, { roomId, userId }) => {
      await setDmRoomMutation.mutateAsync({ roomId, userId })
      // TODO: this should be a partial invalidation?
      queryClient.invalidateQueries({ queryKey })
    }
  })
}

// ************************
//      SET ROOM AS DM
// *************************
export const useSetDmRoomMutation = () => {
  const userAccountDataMutation = useUserAccountDataMutation()

  const { data: accountDataEvents } = useAccountDataEvents()
  const directEvent = accountDataEvents?.find(event => event.type === EventType.Direct)
  const mDirectEvent = useMemo(() => directEvent?.content ?? {}, [directEvent])

  return useMutation({
    mutationFn: async ({ roomId, userId }: { roomId: string; userId: string }) => {
      const dmRoomMap = new Map(Object.entries(mDirectEvent ?? {}))

      let modified = false
      for (const userIdInRoom of dmRoomMap.keys()) {
        const roomList = dmRoomMap.get(userIdInRoom) || []

        if (userIdInRoom !== userId) {
          const indexOfRoom = roomList.indexOf(roomId)
          if (indexOfRoom > -1) {
            roomList.splice(indexOfRoom, 1)
            modified = true
          }
        }
      }

      const roomList = dmRoomMap.get(userId) || []
      if (roomList.indexOf(roomId) === -1) {
        roomList.push(roomId)
        modified = true
      }
      dmRoomMap.set(userId, roomList)

      if (!modified) return

      await userAccountDataMutation.mutateAsync({
        eventType: EventType.Direct,
        payload: Object.fromEntries(dmRoomMap)
      })
    }
  })
}

// ************************
//      CREATE ROOM AS DM
// *************************

export const useCreatePrivateRoom = () => {
  const { data: matrixUser } = useMatrixUser()
  const setDmRoomMutation = useSetDmRoomMutation()

  const { getDmRoomBetweenUsers } = useGetDmRoomBetweenUsers()

  const privateRoomPayload = {
    preset: 'private_chat',
    is_direct: true
  }

  const mutation = useMutation({
    mutationFn: async (userIds: string[]) => {
      if (!userIds || userIds.length === 0) {
        throw new Error('Required userIds')
      }
      const invitees = areMatrixUsers(userIds) ? userIds : getMatrixUsers(userIds)

      // If there is a room for all users involved. cancel create
      const foundRoomForUser = getDmRoomBetweenUsers(userIds)
      if (foundRoomForUser) {
        throw new Error('Found room for user')
      }

      const payload = {
        ...privateRoomPayload,
        invite: invitees
      }
      const res = await axios
        .post(
          `${matrixServerUrl}/_matrix/client/v3/createRoom?access_token=${matrixUser?.access_token}`,
          payload
        )
        .then(({ data }) => data)
      // return res.room_id as string
      const roomId = res.room_id as string
      if (!roomId) {
        throw new Error('Unable to create room for user')
      }

      const invitations = await Promise.all(
        invitees.map(
          async inviteeId => await setDmRoomMutation.mutateAsync({ roomId, userId: inviteeId })
        )
      )
      return roomId
    },
    onMutate(variables) {
      //
    },
    onError(error, variables, context) {
      console.error('useCreatePrivateRoom', error)
    }
  })
  return mutation
}

// ************************
//      DM IMAGE
// *************************

async function imageToJpg(
  image: { uri: string; width: number; height: number },
  saveOptions: SaveOptions = {
    format: SaveFormat.JPEG
  }
) {
  return await manipulateAsync(
    image.uri,
    [{ resize: { width: image.width, height: image.height } }],
    saveOptions
  )
}

// ************************
//    SEND IMAGE MESSAGE
// *************************

type ImageToSend = {
  uri: string
  width: number
  height: number
  fileName?: string | null
  fileSize?: number | null
}
type SendImageMessageResponse = {
  url: string
  height: number
  width: number
  mimeType: string
  blurhash: string
  name: string
}
type SendImageMutation = {
  roomId: string
  image: ImageToSend
  caption?: string
  transactionId: string
  replyTo?: string // eventId
}

export function useSendImageMessage() {
  const queryClient = useQueryClient()
  const { data: matrixUser } = useMatrixUser()
  const matrixUserId = useMemo(() => matrixUser?.user_id ?? '', [matrixUser])
  const uploadMediaMutation = useUploadMediaMutation()
  const sendDirectMessage = useSendDirectMessage()

  return useMutation({
    mutationFn: async ({ roomId, image, caption, transactionId }: SendImageMutation) => {
      // Transform images to jpg
      const imageJPG = await imageToJpg(image)
      // Generate blurhash
      const blurhash = ''

      const imageToUpload = isWeb ? (imageJPG.base64 as string) : imageJPG.uri
      const uploaded = await uploadMediaMutation.mutateAsync(imageToUpload)
      if (!uploaded?.content_uri) {
        throw new Error('useSendImage.uploadMedia: Missing content_uri on response')
      }
      const media = {
        url: uploaded.content_uri,
        height: imageJPG?.height,
        width: imageJPG?.width,
        mimeType: 'image/jpeg',
        blurhash: blurhash ?? '',
        name: image?.fileName ?? ''
      }

      // INFO: payload for images https://spec.matrix.org/v1.9/client-server-api/#mimage
      const messagePayload = {
        roomId,
        transactionId: transactionId,
        message: media?.name ?? '',
        messageType: MsgType.Image,
        extraData: {
          url: media.url,
          info: {
            caption,
            w: media.width,
            h: media.height,
            blurhash: media.blurhash
          }
        }
      }
      // Send Direct Message
      const message = await sendDirectMessage.mutateAsync(messagePayload)

      return { media, message }
    },
    onMutate: async variables => {
      console.log('LOG: > useSendImageMessage > onMutate > variables:', variables)
      // Cancel any outgoing refetch
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(dmQueryKeys.getDmMessages(variables.roomId))

      const tempEventId = `${TEMP_EVENT_PREFIX}${variables.transactionId}`

      const extraDataTemp = {
        url: '',
        uri: variables.image.uri,
        info: {
          caption: variables.caption,
          w: variables.image.width,
          h: variables.image.height,
          blurhash: ''
        }
      }

      // Snapshot the previous value
      const previousData = queryClient.getQueryData(dmQueryKeys.getDmMessages(variables.roomId))

      const replyPayload = variables.replyTo
        ? {
            'm.relates_to': {
              'm.in_reply_to': {
                event_id: variables.replyTo
              }
            }
          }
        : {}

      const tempPayload: IEvent = {
        event_id: tempEventId,
        room_id: variables.roomId,
        content: {
          body: variables.image.fileName ?? '',
          msgtype: MsgType.Image,
          ...(extraDataTemp ?? {}),
          ...replyPayload
        },
        sender: matrixUserId,
        type: 'm.room.message',
        origin_server_ts: new Date().getTime(),
        unsigned: {
          transaction_id: variables.transactionId
        }
      }
      // Optimistically update to the new value
      queryClient.setQueryData<InfiniteData<MessageData> | undefined>(
        dmQueryKeys.getDmMessages(variables.roomId),
        currentData => {
          if (!currentData) {
            return currentData
          }
          // Mutate current data to update pages
          const otherPages = currentData.pages.splice(1, currentData.pages.length)

          const newData = {
            ...currentData,
            pages: [
              {
                ...currentData.pages[0],
                chunk: [tempPayload, ...currentData.pages[0].chunk]
              },
              ...otherPages
            ]
          }

          return newData
        }
      )
      const imageUri = variables.image.uri
      // Return a context object with the snapshot value
      return { previousData, tempEventId, imageUri }
    },
    onSuccess: async ({ media, message }, { roomId, transactionId }, context) => {
      if (!message?.event_id) {
        return
      }
      // Update message data new new event id
      queryClient.setQueryData<InfiniteData<MessageData> | undefined>(
        dmQueryKeys.getDmMessages(roomId),
        currentData => {
          if (!currentData) {
            return currentData
          }
          if (!currentData.pages[0]?.chunk) {
            return currentData
          }

          const firstPageChunk = currentData.pages[0]?.chunk ?? []
          // Find the event to update
          const eventToUpdateIndex = firstPageChunk.findIndex(event => {
            return event.unsigned.transaction_id === transactionId
          })
          if (eventToUpdateIndex === -1) {
            return currentData
          }

          const updatedEvent = {
            ...firstPageChunk[eventToUpdateIndex],
            event_id: message.event_id,
            content: {
              ...firstPageChunk[eventToUpdateIndex].content,
              uri: context?.imageUri,
              url: media.url
            }
          }
          // Mutate previous data to add the updated event
          firstPageChunk[eventToUpdateIndex] = updatedEvent

          const otherPages = currentData.pages.splice(1, currentData.pages.length)
          const newData = {
            ...currentData,
            pages: [
              {
                ...currentData.pages[0],
                chunk: firstPageChunk
              },
              ...otherPages
            ]
          }
          return newData
        }
      )
    },
    onSettled(data, error, variables, context) {
      // invalidate the query
      // queryClient.invalidateQueries(dmQueryKeys.getDmMessages(variables.roomId))
    },
    onError(error, variables, context) {
      console.error('useSendMessage', error)
      queryClient.setQueryData(dmQueryKeys.getDmMessages(variables.roomId), context?.previousData)
    }
  })
}

// ************************
//    SEND MESSAGE
// *************************

type SendMessageResponse = {
  ['event_id']: string
}

type SendMessageMutation = {
  roomId: string
  transactionId: string
  message: string
  messageType?: MsgType
  extraData?: Record<string, unknown>
  replyTo?: string // eventId
}

function useSendDirectMessage() {
  const { data: matrixUser } = useMatrixUser()

  const eventType = 'm.room.message'
  const mutation = useMutation({
    meta: {},
    mutationFn: async ({
      roomId,
      transactionId,
      message,
      messageType = MsgType.Text,
      extraData,
      replyTo
    }: SendMessageMutation): Promise<SendMessageResponse> => {
      const replyPayload = replyTo
        ? {
            'm.relates_to': {
              'm.in_reply_to': {
                event_id: replyTo
              }
            }
          }
        : {}
      const payload = {
        body: message,
        msgtype: messageType,
        ...extraData,
        ...replyPayload
      }
      return await axios
        .put(
          `${matrixServerUrl}/_matrix/client/v3/rooms/${roomId}/send/${eventType}/${transactionId}?access_token=${matrixUser?.access_token}`,
          payload
        )
        .then(({ data }) => data)
    }
  })
  return mutation
}

// ************************
//    SEND TEXT MESSAGE
// *************************

export function useSendTextMessage() {
  const queryClient = useQueryClient()
  const { data: matrixUser } = useMatrixUser()
  const matrixUserId = useMemo(() => matrixUser?.user_id ?? '', [matrixUser])
  const sendDirectMessage = useSendDirectMessage()

  return useMutation({
    meta: {},
    mutationFn: async ({
      roomId,
      transactionId,
      message,
      messageType = MsgType.Text,
      extraData,
      replyTo
    }: SendMessageMutation): Promise<SendMessageResponse> => {
      return await sendDirectMessage.mutateAsync({
        roomId,
        transactionId,
        message,
        messageType,
        extraData,
        replyTo
      })
    },
    onMutate: async variables => {
      // Cancel any outgoing refetch
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(dmQueryKeys.getDmMessages(variables.roomId))

      const tempEventId = `tempEventId_${variables.transactionId}`

      // Snapshot the previous value
      const previousData = queryClient.getQueryData(dmQueryKeys.getDmMessages(variables.roomId))

      const replyPayload = variables.replyTo
        ? {
            'm.relates_to': {
              'm.in_reply_to': {
                event_id: variables.replyTo
              }
            }
          }
        : {}

      const tempPayload: IEvent = {
        event_id: tempEventId,
        room_id: variables.roomId,
        content: {
          body: variables.message,
          msgtype: variables.messageType ?? MsgType.Text,
          ...(variables.extraData ?? {}),
          ...replyPayload
        },
        sender: matrixUserId,
        type: 'm.room.message',
        origin_server_ts: new Date().getTime(),
        unsigned: {
          transaction_id: variables.transactionId
        }
      }
      // Optimistically update to the new value
      queryClient.setQueryData<InfiniteData<MessageData> | undefined>(
        dmQueryKeys.getDmMessages(variables.roomId),
        currentData => {
          if (!currentData) {
            return currentData
          }
          // Mutate current data to update pages
          const otherPages = currentData.pages.splice(1, currentData.pages.length)

          const newData = {
            ...currentData,
            pages: [
              {
                ...currentData.pages[0],
                chunk: [tempPayload, ...currentData.pages[0].chunk]
              },
              ...otherPages
            ]
          }
          console.log('LOG: > useSendTextMessage > onMutate newData:', newData)
          return newData
          // currentData.pages[0].chunk.splice(0, 0, tempPayload)
          // return currentData
        }
      )
      // Return a context object with the snapshot value
      return { previousData, tempEventId }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (error, data, context) => {
      console.error('useSendTextMessage', error)
      queryClient.setQueryData(dmQueryKeys.getDmMessages(data.roomId), context?.previousData)
    },
    onSuccess(data, { roomId, transactionId }) {
      queryClient.setQueryData<InfiniteData<MessageData> | undefined>(
        dmQueryKeys.getDmMessages(roomId),
        currentData => {
          if (!currentData) {
            return currentData
          }

          if (!currentData.pages[0]?.chunk) {
            return currentData
          }
          const firstPageChunk = currentData.pages[0]?.chunk ?? []
          // Find the event to update
          const eventToUpdateIndex = firstPageChunk.findIndex(event => {
            return event.unsigned.transaction_id === transactionId
          })
          if (eventToUpdateIndex === -1) {
            return currentData
          }

          const updatedEvent = {
            ...firstPageChunk[eventToUpdateIndex],
            event_id: data.event_id
          }
          // Mutate previous data to add the updated event
          firstPageChunk[eventToUpdateIndex] = updatedEvent

          const otherPages = currentData.pages.splice(1, currentData.pages.length)
          const newData = {
            ...currentData,
            pages: [
              {
                ...currentData.pages[0],
                chunk: firstPageChunk
              },
              ...otherPages
            ]
          }
          return newData
        }
      )
    },
    onSettled: (data, error, variables) => {
      // invalidate the query
      // queryClient.invalidateQueries(dmQueryKeys.getDmMessages(variables.roomId))
    }
  })
}

export const useReadMarkerMutation = () => {
  const queryClient = useQueryClient()
  const { data: matrixUser } = useMatrixUser()
  const queryKeySync = matrixQueryKeys.getMatrixFullStateSync(matrixUser?.access_token)
  const mutation = useMutation({
    mutationFn: async ({ roomId, eventId }: { roomId: string; eventId: string }) => {
      const payload = {
        [EventType.FullyRead]: eventId
      }

      return await axios
        .post(
          `${matrixServerUrl}/_matrix/client/v3/rooms/${roomId}/read_markers?access_token=${matrixUser?.access_token}`,
          payload
        )
        .then(({ data }) => data)
    },
    // onMutate: async data => {},
    // onError: (err, data, context) => {},
    onSuccess(data, variables, context) {
      // console.log('LOG: > onSuccess > context:', context)
      // console.log('LOG: > onSuccess > data:', data)
      // TODO: fix this
      // queryClient.invalidateQueries(queryKeySync)
    }
    // onSettled: (data, error, variables) => {}
  })

  return mutation
}

export const useUserAccountDataMutation = () => {
  const queryClient = useQueryClient()
  const { data: matrixUser } = useMatrixUser()
  const userId = matrixUser?.user_id ?? ''

  return useMutation({
    mutationFn: async ({
      eventType,
      payload
    }: {
      eventType: EventType
      payload: Record<string, string[]>
    }) => {
      return await axios
        .put(
          `${matrixServerUrl}/_matrix/client/v3/user/${userId}/account_data/${eventType}?access_token=${matrixUser?.access_token}`,
          payload
        )
        .then(({ data }) => data)
    },
    onSuccess: (data, variables) => {
      const queryKey = dmQueryKeys.getDmUserAccountData(userId, variables.eventType)
      queryClient.invalidateQueries({ queryKey })
    }
  })
}

/**
 * Typing Notification
 * @example https://spec.matrix.org/v1.9/client-server-api/#typing-notifications
 * @returns
 */
export const useUserIsTypingMutation = () => {
  const { data: matrixUser } = useMatrixUser()
  const userId = matrixUser?.user_id ?? ''
  return useMutation({
    mutationFn: async ({
      roomId,
      isTyping,
      timeout
    }: {
      roomId: string
      isTyping: boolean
      timeout?: number
    }) => {
      const payload = {
        timeout: timeout ?? 1000,
        typing: isTyping
      }
      return await axios
        .put(
          `${matrixServerUrl}/_matrix/client/v3/rooms/${roomId}/typing/${userId}?access_token=${matrixUser?.access_token}`,
          payload
        )
        .then(({ data }) => data)
    },
    onError(error) {
      console.error('useUserIsTypingMutation', error)
    }
  })
}

export const useCreateLocalRoom = () => {
  const queryClient = useQueryClient()

  const { data: matrixUser } = useMatrixUser()
  const userId = matrixUser?.user_id ?? ''

  const createLocalRoom = useCallback(
    ({ members }: { members: Member[] }) => {
      const localRoomId = createLocalDmRoom(userId, members, queryClient)

      return localRoomId
    },
    [queryClient, userId]
  )

  return {
    createLocalRoom
  }
}

type FirstMessageMutation = {
  localRoomId: string
}
/**
 * Create a direct message room to send the first message
 *
 * @returns {string} roomId
 */
export const useSendFirstMessageMutation = () => {
  const queryClient = useQueryClient()
  const { data: matrixUser } = useMatrixUser()
  const userId = matrixUser?.user_id ?? ''

  const createRoomMutation = useCreatePrivateRoom()

  const mutation = useMutation({
    mutationFn: async ({ localRoomId }: FirstMessageMutation) => {
      const roomMembers: RoomMembers | undefined = queryClient.getQueryData(
        dmQueryKeys.getDmRoomMembers(localRoomId)
      )
      if (!roomMembers) {
        throw new Error('Create room: missing members.')
      }

      const userIds = roomMembers.chunk.reduce((acc, member) => {
        if (member.state_key === userId || member.content.membership !== 'invite') {
          return acc
        }
        if (member.state_key) {
          return [...acc, member.state_key]
        }
        return acc
      }, [] as string[])

      if (userIds.length === 0) {
        throw new Error('Create room: missing userIds for room creation.')
      }

      const roomId = await createRoomMutation.mutateAsync(userIds)
      return roomId
    }
  })

  return mutation
}

/**
 * Send an event with a reaction (emoji)
 * that relates to another event (message)
 */
export const useSendMessageReaction = () => {
  const queryClient = useQueryClient()
  const { data: matrixUser } = useMatrixUser()
  const userId = matrixUser?.user_id ?? ''

  const eventType = 'm.reaction'
  return useMutation({
    mutationFn: async ({
      roomId,
      eventId,
      reactionKey,
      transactionId
    }: {
      roomId: string
      eventId: string
      reactionKey: string
      transactionId: string
    }) => {
      const payload = {
        'm.relates_to': {
          event_id: eventId,
          key: reactionKey,
          rel_type: 'm.annotation'
        }
      }
      return await axios
        .put(
          `${matrixServerUrl}/_matrix/client/v3/rooms/${roomId}/send/${eventType}/${transactionId}?access_token=${matrixUser?.access_token}`,
          payload
        )
        .then(({ data }) => data)
    },
    onMutate: async ({ roomId, eventId, reactionKey, transactionId }) => {
      await queryClient.cancelQueries(dmQueryKeys.getDmMessageReactions(roomId, eventId))
      // snapshot of previous data
      const previousData = queryClient.getQueryData(
        dmQueryKeys.getDmMessageReactions(roomId, eventId)
      )

      queryClient.setQueryData(
        dmQueryKeys.getDmMessageReactions(roomId, eventId),
        (currentData: FetchReactions | undefined) => {
          const chunk = currentData?.chunk ?? []

          const tempEventId = `${TEMP_EVENT_PREFIX}${transactionId}`
          const tempEvent = {
            room_id: roomId,
            sender: userId,
            event_id: tempEventId,
            content: {
              'm.relates_to': {
                event_id: eventId,
                key: reactionKey,
                rel_type: 'm.annotation'
              }
            },
            type: 'm.reaction',
            origin_server_ts: new Date().getTime(),
            unsigned: {
              transaction_id: transactionId
            }
          }

          return {
            chunk: [tempEvent, ...chunk]
          }
        }
      )
      return {
        previousData
      }
    },
    onError(error, { roomId, eventId }, context) {
      console.error('useSendReaction', error)
      // Rollback to previous data
      queryClient.setQueryData(
        dmQueryKeys.getDmMessageReactions(roomId, eventId),
        context?.previousData
      )
    },
    onSuccess: (data, { roomId, eventId, transactionId }) => {
      queryClient.setQueryData<FetchReactions | undefined>(
        dmQueryKeys.getDmMessageReactions(roomId, eventId),
        currentData => {
          const chunk = currentData?.chunk ?? []

          // Update tempEventId to replace data.eventId
          const eventToUpdateIndex = chunk.findIndex(event => {
            return event.unsigned.transaction_id === transactionId
          })
          if (eventToUpdateIndex === -1) {
            return {
              chunk
            }
          }
          // Mutate previous data to update event
          chunk[eventToUpdateIndex].event_id = data.event_id

          return {
            chunk: [...chunk]
          }
        }
      )
    },
    onSettled: ({ roomId, eventId }, error, variables) => {
      // invalidate the query
      queryClient.invalidateQueries(dmQueryKeys.getDmMessageReactions(roomId, eventId))
    }
  })
}

export const useRedactMessageReaction = () => {
  const queryClient = useQueryClient()
  const eventRedactionMutation = useEventRedaction()
  const reason = 'm.annotation'

  return useMutation({
    mutationFn: async ({
      roomId,
      eventId,
      parentEventId
    }: {
      roomId: string
      eventId: string
      parentEventId: string
    }) => {
      const transactionId = createTxnId()
      const extraData = {
        'm.relates_to': {
          rel_type: reason,
          event_id: parentEventId
        }
      }
      return await eventRedactionMutation.mutateAsync({
        roomId,
        eventId,
        transactionId,
        reason,
        extraData
      })
    },
    onMutate({ roomId, eventId, parentEventId }) {
      const previousData = queryClient.getQueryData(
        dmQueryKeys.getDmMessageReactions(roomId, parentEventId)
      )

      // Remove event
      // queryClient.setQueryData(
      //   dmQueryKeys.getDmMessageReactions(roomId, parentEventId),
      //   (currentData: FetchReactions | undefined) => {
      //     const chunk = currentData?.chunk ?? []
      //     console.log('LOG: > onMutate > chunk 1:', chunk)
      //     const foundIndex = chunk.findIndex(item => item.event_id === eventId)
      //     if (foundIndex === -1) {
      //       console.log('LOG: > onMutate > foundIndex:', foundIndex)
      //       return {
      //         chunk
      //       }
      //     }
      //     const removed = chunk.splice(foundIndex, 1)
      //     console.log('LOG: > onMutate > removed:', removed)
      //     console.log('LOG: > onMutate > chunk 2:', chunk)

      //     return {
      //       chunk: [...chunk]
      //     }
      //   }
      // )

      queryClient.setQueryData(
        dmQueryKeys.getDmMessageReactions(roomId, parentEventId),
        (currentData: FetchReactions | undefined) => {
          const chunk = currentData?.chunk ?? []
          console.log('LOG: > onMutate > chunk:', chunk)
          const foundIndex = chunk.findIndex(item => item.event_id === eventId)
          if (foundIndex === -1) {
            return {
              chunk: [...chunk]
            }
          }
          const tempRedaction = {
            redacted_because: {
              content: {
                body: reason,
                redacts: eventId
              }
            }
          }
          const updatedEvent = {
            ...chunk[foundIndex],
            ...tempRedaction
          }
          console.log('LOG: > onMutate > updatedEvent:', updatedEvent)
          // Mutate to update data
          chunk[foundIndex] = updatedEvent
          const newData = {
            chunk: [...chunk]
          }
          console.log('LOG: > onMutate > newData:', newData)
          return newData
        }
      )

      return {
        previousData
      }
    },
    onError(error, { roomId, parentEventId }, context) {
      console.error('useRedactMessageReaction', error)

      queryClient.setQueryData(
        dmQueryKeys.getDmMessageReactions(roomId, parentEventId),
        context?.previousData
      )
    },
    onSuccess(data, { roomId, parentEventId }, context) {
      console.log('LOG: useRedactMessageReaction > onSuccess', data)
      // queryClient.invalidateQueries(dmQueryKeys.getDmMessageReactions(roomId, parentEventId))
    },
    onSettled: ({ roomId, parentEventId }, error, variables) => {
      // invalidate the query
      queryClient.invalidateQueries(dmQueryKeys.getDmMessageReactions(roomId, parentEventId))
    }
  })
}

type EventRedactionResponse = {
  ['event_id']: string
}

type EventRedactionMutation = {
  roomId: string
  eventId: string
  transactionId: string
}

export const useEventRedaction = () => {
  const { data: matrixUser } = useMatrixUser()

  return useMutation({
    mutationFn: async ({
      roomId,
      eventId,
      transactionId,
      reason,
      extraData
    }: {
      roomId: string
      eventId: string
      transactionId: string
      reason?: string
      extraData?: Record<string, unknown>
    }) => {
      const payload = {
        body: reason ?? '',
        unsigned: extraData ?? {}
      }
      return await axios
        .put(
          `${matrixServerUrl}/_matrix/client/v3/rooms/${roomId}/redact/${eventId}/${transactionId}?access_token=${matrixUser?.access_token}`,
          payload
        )
        .then(({ data }) => data)
    },
    onError(error) {
      console.error('useEventRedaction', error)
    }
  })

  // const sendEventRedaction = (
  //   mutationArgs: Omit<EventRedactionMutation, 'transactionId'>,
  //   options?: MutateOptions<EventRedactionResponse, unknown, EventRedactionMutation>
  // ) => {
  //   const txnId = createTxnId()
  //   mutation.mutate(
  //     {
  //       ...mutationArgs,
  //       transactionId: txnId
  //     },
  //     options
  //   )
  // }

  // return {
  //   mutation,
  //   sendEventRedaction
  // }
}

type SendThreadReplyMutation = {
  roomId: string
  eventId: string
  message: string
  messageType?: MsgType
  extraData?: Record<string, unknown>
}

const useThreadReply = () => {
  const { data: matrixUser } = useMatrixUser()
  const queryClient = useQueryClient()

  const eventType = 'v.room.reply'

  return useMutation({
    mutationFn: async ({ roomId, eventId }: SendThreadReplyMutation) => {
      const payload = {
        'm.relates_to': {
          rel_type: 'm.thread',
          event_id: eventId
        }
      }
      return await axios
        .put(
          `${matrixServerUrl}/_matrix/client/v3/rooms/${roomId}/send/${eventType}?access_token=${matrixUser?.access_token}`,
          payload
        )
        .then(({ data }) => data)
    }
  })
}

// TODO: delete message > useEventRedaction

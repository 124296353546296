import * as React from 'react'
import { BVatomTokenType } from '@vatom/BVatom/plugin'
import { TokenType } from '@vatom/sdk/core'
import { Button } from '@vatom/wombo'
import { AspectRatio, Image, Modal } from 'native-base'

import CloseIcon from '../../../assets/close.min.svg'

export interface IPostModal {
  isOpen: boolean
  token?: TokenType
  onCancel: () => void
  modalProps?: React.ComponentProps<typeof Modal>
}

export const PostPickupModal = ({ token, onCancel, modalProps, isOpen }: IPostModal) => {
  const image = (token as BVatomTokenType)?.getResource('CardImage')?.url
  const alt = 'post_pickup_modal' + token?.id
  return (
    <Modal
      isOpen={isOpen}
      alignItems="center"
      justifyContent="center"
      animationPreset="slide"
      {...modalProps}
      onClose={onCancel}
    >
      <Modal.Content borderRadius="10px" backgroundColor="transparent" width="90%" maxWidth={400}>
        <Button
          style={{ top: 15, right: 15, zIndex: 1, position: 'absolute' }}
          bgColor={'transparent'}
          onPress={onCancel}
        >
          <CloseIcon height={16} width={16} fill="#fff" />
        </Button>
        <AspectRatio ratio={0.77} width="100%">
          <Image
            resizeMode="contain"
            accessibilityIgnoresInvertColors
            source={{ uri: image }}
            borderRadius="15px"
            alt={alt}
          />
        </AspectRatio>
      </Modal.Content>
    </Modal>
  )
}

export default PostPickupModal

/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * AttributeVariantBase
 * auto generated base class for the model AttributeVariantModel.
 */
export const AttributeVariantModelBase = ModelBase
  .named('AttributeVariant')
  .props({
    __typename: types.optional(types.literal("AttributeVariant"), "AttributeVariant"),
    name: types.union(types.undefined, types.string),
    count: types.union(types.undefined, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class AttributeVariantModelSelector extends QueryBuilder {
  get name() { return this.__attr(`name`) }
  get count() { return this.__attr(`count`) }
}
export function selectFromAttributeVariant() {
  return new AttributeVariantModelSelector()
}

export const attributeVariantModelPrimitives = selectFromAttributeVariant().name.count

/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { GraphConnectionModel, GraphConnectionModelType } from "./GraphConnectionModel"
import { GraphConnectionModelSelector } from "./GraphConnectionModel.base"
import { TwitterProfileModel, TwitterProfileModelType } from "./TwitterProfileModel"
import { TwitterProfileModelSelector } from "./TwitterProfileModel.base"
import { RootStoreType } from "./index"


/**
 * FollowEventBase
 * auto generated base class for the model FollowEventModel.
 */
export const FollowEventModelBase = ModelBase
  .named('FollowEvent')
  .props({
    __typename: types.optional(types.literal("FollowEvent"), "FollowEvent"),
    createdAt: types.union(types.undefined, types.frozen()),
    walletAddress: types.union(types.undefined, types.string),
    profile: types.union(types.undefined, types.null, types.late((): any => TwitterProfileModel)),
    feedEventId: types.union(types.undefined, types.string),
    graphConnectionAddress: types.union(types.undefined, types.frozen()),
    connection: types.union(types.undefined, types.null, types.late((): any => GraphConnectionModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class FollowEventModelSelector extends QueryBuilder {
  get createdAt() { return this.__attr(`createdAt`) }
  get walletAddress() { return this.__attr(`walletAddress`) }
  get feedEventId() { return this.__attr(`feedEventId`) }
  get graphConnectionAddress() { return this.__attr(`graphConnectionAddress`) }
  profile(builder: string | TwitterProfileModelSelector | ((selector: TwitterProfileModelSelector) => TwitterProfileModelSelector) | undefined) { return this.__child(`profile`, TwitterProfileModelSelector, builder) }
  connection(builder: string | GraphConnectionModelSelector | ((selector: GraphConnectionModelSelector) => GraphConnectionModelSelector) | undefined) { return this.__child(`connection`, GraphConnectionModelSelector, builder) }
}
export function selectFromFollowEvent() {
  return new FollowEventModelSelector()
}

export const followEventModelPrimitives = selectFromFollowEvent().createdAt.walletAddress.feedEventId.graphConnectionAddress

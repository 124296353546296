import React, { useEffect } from 'react'
import { useAppConfig } from '@vatom/sdk/react'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { LoaderView } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Flex, StatusBar } from 'native-base'

import { useClaimOtp } from '../LogoutCallback'

import { BottomHalf, TopHalf } from './partials'
import { useAuth } from './useAuth'

const ConnectScreen = observer(() => {
  const isEmbedded = useVatomWalletSdkStore(state => state.isEmbedded)

  const { isLoading, tab, handleVatomLogin, walletSDKIsLoading } = useAuth(undefined)
  const { handleClaimPendingAndRedirect, isLoading: isOtpLoading } = useClaimOtp()

  // Todo - manage loading stat
  useEffect(() => {
    handleClaimPendingAndRedirect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (isEmbedded || isLoading || walletSDKIsLoading || isOtpLoading) {
    return <LoaderView />
  }

  return (
    <Flex
      testID="LoginScreen"
      flexDirection="column"
      flex={1}
      bg="white"
      style={{ alignContent: 'center', justifyContent: 'center' }}
    >
      <StatusBar barStyle={tab.style} backgroundColor={tab.back} />
      <TopHalf />
      <BottomHalf onPressVatom={handleVatomLogin} />
    </Flex>
  )
})

export const Connect = () => {
  const config = useAppConfig()

  if (config.isLoading) {
    return <LoaderView />
  }

  return <ConnectScreen />
}

export default Connect

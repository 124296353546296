import { getConfig } from '@vatom/sdk/react'
import { FixedLengthArray } from '@vatom/utils'
import { useVatomWalletSdkStore, VatomWallet } from '@vatom/wallet-sdk'
import { getRoot, getSnapshot, Instance, types } from 'mobx-state-tree'

import logger from '../../logger'
import { TokenType } from '../../models'
import { RootSDKStoreType } from '../RootSdk'
import { SessionSource, SessionStoreType, SessionType } from '../SessionInfo'

import { AnalyticsPersistenceManager } from './analytics'

const EventCache = new FixedLengthArray(200)

export const AnalyticsStore = types
  .model('AnalyticsStore')
  .views(self => ({
    get rootStore(): RootSDKStoreType {
      return getRoot<RootSDKStoreType>(self)
    },
    get config() {
      return getConfig()
    },
    get sessionStore(): SessionStoreType {
      return this.rootStore.dataPool.sessionStore
    }
  }))
  .volatile(self => ({
    getNetworkUserId(): string | undefined {
      const session = self.sessionStore.getFirstByTypeAndSource(
        SessionType.UserId,
        SessionSource.VatomNetwork
      )
      return session?.value as string
    },
    getUserId(): string | undefined {
      const session = self.sessionStore.getFirstByTypeAndSource(
        SessionType.UserId,
        SessionSource.Vatom
      )
      return session?.value as string
    }
  }))
  .actions(self => ({
    async track(name: string, args: any) {
      const anonymousId = await AnalyticsPersistenceManager.getAnonymousId()
      const context = await AnalyticsPersistenceManager.getContext()

      // Send analytics events via new framework
      const data = {
        anonymousId,
        context,
        event: name,
        properties: {
          event: name, // Not sure if this is needed?
          eventValue: 1, // Not sure if this is needed?
          ...args
        },
        timestamp: new Date().toISOString(),
        type: 'track',
        userId: args.userId || self.getUserId()
      }

      fetch('https://analytics.api.vatominc.com/t', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      }).catch(error => {
        logger.error('Error sending analytics:', error)
      })
    },
    async event(name: string, args: any = {}, token?: TokenType) {
      // Homegrown memoize
      // If args are in cache do nothing
      // eslint-disable-next-line prefer-rest-params
      // eslint-disable-next-line prefer-rest-params
      const event = EventCache.find(arguments)

      if (event) {
        return
      }
      // eslint-disable-next-line prefer-rest-params
      EventCache.push(arguments)

      if (!args.userId) {
        args.userId = self.getUserId()
      }
      args.provider = 'vatominc'

      args.networkUserId = self.getNetworkUserId()
      args.network = 'vatominc'

      args.viewerId = self.config.viewerId || (await AnalyticsPersistenceManager.getOrigin())
      args.viewerUri = self.config.viewerId
        ? `varius.viewer:varius.io:${self.config.viewerId}`
        : await AnalyticsPersistenceManager.getOrigin()

      if (token) args['digitalObjectId'] = token.id

      if (token?.studioInfo) {
        // Get standard vatom properties
        // TODO
        // args['templateVariationName'] =
        //   (token.properties && token.properties.template_variation) ||
        //   token.payload['vAtom::vAtomType'].template_variation
        args['vatomId'] = token.id

        const studioInfo = token.studioInfo
        // Get generic analytics data
        const mandatoryStack = {
          ...token.analyticsData,
          ...studioInfo
        }

        args = Object.assign({}, args, mandatoryStack)
      }

      // Get default viewerUri and campaignUri if not specified
      const campaignId = await AnalyticsPersistenceManager.getCampaignId()
      if (!args.campaignId && !args.campaignUri && !!campaignId) {
        args.campaignUri = `varius.campaign:varius.io:${campaignId}`
        args.campaignId = campaignId
      }

      // Send event to analytics system if specified
      // TODO
      // if (self.config.analytics?.onEvent) self.config.analytics.onEvent(name, args)

      // Send event using new framework
      const isEmbedded = useVatomWalletSdkStore.getState().isEmbedded
      if (token && isEmbedded) {
        VatomWallet._shared.sendMsgWithoutResponse('walletsdk:analytics', {
          token: getSnapshot(token),
          name: name,
          payload: args
        })
      }

      this.track(name, args)

      // window.gtag('event', name, args)
    },
    async identify(userId: string, args: any = {}) {
      // Set the uid cookie - is this needed?
      // AnalyticsPersistenceManager.setUserId(userId)
      // call identify on 3rd party add-ins
      // logger.info('identify', Config.analytics, Config.analytics?.onIdentify)
      // if (Config.analytics?.onIdentify) Config.analytics.onIdentify(userId, args)
    },
    error(error: string, extraFields = {}) {
      this.event('exception', Object.assign({ error: error, fatal: false }, extraFields))
    }
  }))

// TODO

/** Log a screen view */
// static screenView = debounce((name: string) => {
//   Analytics.event('screenView', { screen_name: name })
// }, 300)

/** Log a screen view */
// static pageView = debounce(() => {
//   Analytics.event('pageView', {
//     title: document.title,
//     location: window.location.href
//   })
//   // Check for tracking ID
//   if (!Config.analyticsTrackingID)
//     return logger.warn('No Google Analytics tracking ID provided.')
//   window.gtag('config', Config.analyticsTrackingID, {
//     page_path: '/' + window.location.hash
//   })
// }, 300)

export type AnalyticsApi = Instance<typeof AnalyticsStore>

import { FaceStoreSnapshot, RegionStore, Utils } from '@vatom/sdk/core'
import { getParentOfType, getSnapshot, Instance, SnapshotOut, types } from 'mobx-state-tree'

import logger from '../logger'
import { BVatomPlugin } from '../models'
import {
  ActionStoreSnapshot,
  BVatomSnapshot,
  BVatomToken,
  BVatomTokenType
} from '../models/BVatomToken'
import Vatom, { VatomPayload } from '../modules/Vatom/model/BVatom'
import { FacePayload } from '../modules/Vatom/model/Face'
import { VatomApiStore } from '../modules/VatomApiStore'

// export const MessageToken = types.model('MessageToken', {
//   id: types.maybe(types.string),
//   type: types.maybe(types.string),
//   parentId: types.maybe(types.string),
//   owner: types.maybe(types.string),
//   author: types.maybe(types.string),
//   lastOwner: types.maybe(types.maybe(types.string)),
//   modified: types.maybe(types.maybe(types.Date)),
//   sync: types.maybe(types.maybe(types.number))
// })

export const MessagePayload = types.model('MessagePayload', {
  event_id: types.string,
  op: types.string,
  id: types.string,
  action_name: types.string,
  new_owner: types.maybe(types.string),
  old_owner: types.maybe(types.string),
  new_object: types.maybe(BVatomToken),
  template_variation: types.maybe(types.string),
  parent_id: types.maybe(types.string)
})

export type MessageSnapshot = SnapshotOut<typeof Message>

export const Message = types.model('Message', {
  msg_type: types.string,
  user_id: types.string,
  payload: MessagePayload
})

export const VatomRegionStore = RegionStore.named('VatomRegionStore')
  .props({
    // The api is plugged in here because it is required before the Region has a parent and is a singleton, so its OK
    // api: types.optional(VatomApiStore, {}),
    _api: types.late(() => types.safeReference(VatomApiStore)),
    // queuedMessages: types.frozen([{}]),
    socketPaused: types.optional(types.boolean, false),
    socketProcessing: types.optional(types.boolean, false)
  })
  .views(self => ({
    get api() {
      return self._api!
    },
    get socket() {
      return this.api.webSockets
    }
  }))
  .actions(self => ({
    afterAttach() {
      const plugin = getParentOfType(self, BVatomPlugin)
      // self._api = getParentOfType(self, VatomApiStore)
      self._api = plugin.api
      logger.info('[VatomRegion] afterAttach parent set')
    },
    async performAction(action: string, payload: any) {
      return await self.api.vatomApi.performAction(action, payload)
    },
    async processMessage(msg: any) {
      logger.info('[VatomRegion] processMessage', msg)
      // We only handle state_update messages here
      if (msg.msg_type !== 'state_update') return

      // Get vatom ID
      const vatomID = msg.payload && msg.payload.id
      if (!vatomID) {
        throw new Error(`Got websocket message with no vatom ID in it.`)
      }

      // Ensure it's formatted correctly
      if (!msg.payload.new_object) {
        throw new Error(`WebSocket message had no new object payload.`)
      }

      const token = self.get(vatomID) as BVatomTokenType
      if (!token) return

      const tokenSnaphot = getSnapshot(token)

      const { new_object } = msg.payload

      // Update private keys
      const privatePayload = new_object?.private
      const keysToUpdate = Object.keys(privatePayload ?? {})

      const privateData = { ...(tokenSnaphot?.private ?? {}) }
      const newPrivate = keysToUpdate.reduce((acc, key) => {
        acc[key as keyof typeof acc] = {
          ...privateData[key as keyof typeof acc],
          ...privatePayload[key]
        }
        return acc
      }, privateData)

      token.setPrivate(newPrivate)

      // Create private data by normalizing the studio info and state (there are old deprecated standards)
      const cloningScore: number | undefined = new_object['vAtom::vAtomType'].cloning_score
      if (cloningScore) {
        const numDirectClones: number = new_object['vAtom::vAtomType'].num_direct_clones
        logger.info('Updating cloningScore')

        token.setPrivate({
          ...newPrivate,
          'cloneable-v1': {
            cloningScore,
            numDirectClones
          }
        })

        // Update cloning scores in properties as well
        token.setProperties({
          ...tokenSnaphot.properties,
          cloning_score: cloningScore,
          num_direct_clones: numDirectClones
        })

        logger.info('Updating cloningScore done')
      }

      const commerce = new_object?.private?.['commerce-v1'] ?? null
      if (commerce) {
        logger.info('Updating commerce data')
        const tokenCommerce = tokenSnaphot?.private['commerce-v1'] ?? {}
        token.setPrivate({
          ...tokenSnaphot.private,
          'commerce-v1': {
            ...tokenCommerce,
            ...commerce
          }
        })
        logger.info('Updating commerce data done')
      }

      // Apply any updates to properties
      if (Object.keys(new_object['vAtom::vAtomType'] || {}).length > 0) {
        logger.info('Updating properties', new_object['vAtom::vAtomType'])

        token.setProperties({
          ...tokenSnaphot.properties,
          ...new_object['vAtom::vAtomType']
        })
      }

      if (new_object?.eth) {
        logger.info('Updating eth', new_object?.eth)

        const tokenEth = token?.eth ?? {}

        token.setEth({
          ...tokenEth,
          ...new_object.eth
        })
      }

      if (new_object?.chain) {
        logger.info('Updating chain', new_object?.chain)

        const tokenChain = token?.chain ?? {}

        token.setChain({
          ...tokenChain,
          ...new_object.chain
        })
      }

      if (new_object.when_modified) {
        // Update existing tokens
        logger.info('Updating modified date', new Date(new_object.when_modified))
        token.setModified(new Date(new_object.when_modified))
      }

      // self._tokens.set(token.id, BVatomToken.create(token))
      logger.info(`[processMessage]: Token ${tokenSnaphot.id} is updated`)
    },
    mapObject(
      id: string,
      payload: VatomPayload,
      faces?: FacePayload[],
      actions?: ActionStoreSnapshot[]
    ) {
      // logger.info('[VatomRegion]: mapObject')
      const vatom = new Vatom(payload, [], [])
      const { tokenId, modified, created, properties } = vatom
      const vatomFaces: FaceStoreSnapshot[] = (faces ?? []).map(faceData => {
        return {
          ...faceData,
          properties: {
            ...faceData.properties,
            config: JSON.stringify(faceData?.properties?.config ?? {})
          }
        }
      })

      // Create private data by normalizing the studio info and state (there are old deprecated standards)
      const privateData = vatom.private
      const studioInfoDeprecated = vatom.private.state?.['varius.behavior:varius.io:studio-info-v1']
      const studioInfo = vatom.private['studio-info-v1']

      if (studioInfo || studioInfoDeprecated) {
        privateData['studio-info-v1'] = {
          ...studioInfo,
          businessId: (studioInfo?.businessId || studioInfoDeprecated?.businessId)!,
          blueprintId: Utils.getIdFromIdOrUri(
            (studioInfo?.blueprintId || studioInfoDeprecated?.blueprintUri)!
          ),
          campaignId: Utils.getIdFromIdOrUri(
            (studioInfo?.campaignId || studioInfoDeprecated?.campaignUri)!
          ),
          objectDefinitionId: Utils.getIdFromIdOrUri(
            (studioInfo?.objectDefinitionId || studioInfoDeprecated?.objectDefinitionUri)!
          )
        }
      }

      const cloningScore = payload['vAtom::vAtomType'].cloning_score || 0
      const numDirectClones = payload['vAtom::vAtomType'].num_direct_clones || 0
      const cloneable = {
        cloningScore,
        numDirectClones
      }

      privateData['cloneable-v1'] = {
        ...privateData['cloneable-v1'],
        ...cloneable
      }

      delete privateData.state

      // const vatomToken = BVatomToken.create({
      //   id,
      //   tokenId,
      //   type: 'vatom',
      //   sync: vatom.payload.sync,
      //   parentId: properties.parent_id,
      //   owner: properties.owner,
      //   author: properties.author,
      //   lastOwner: properties.transferred_by,
      //   modified,
      //   created,
      //   properties,
      //   private: privateData,
      //   eth: vatom.payload.eth,
      //   // payload,
      //   vatomActions: actions || [],
      //   vatomFaces
      //   // vatomFaces: faces || []
      // })
      // return getSnapshot(vatomToken)
      return {
        id,
        tokenId,
        type: 'vatom',
        sync: vatom.payload.sync,
        parentId: properties.parent_id,
        owner: properties.owner,
        author: properties.author,
        lastOwner: properties.transferred_by,
        modified: modified.getTime(),
        created: created.getTime(),
        properties,
        private: privateData,
        eth: vatom.payload.eth,
        chain: vatom.payload.chain,
        // payload,
        vatomActions: actions || [],
        vatomFaces
      } as unknown as BVatomSnapshot
    }
    // async processNextMessage() {
    //   // Stop if socket is paused
    //   if (self.socketPaused) {
    //     return
    //   }

    //   // Stop if already processing
    //   if (self.socketProcessing) {
    //     return
    //   }
    //   self.socketProcessing = true

    //   // Get next msg to process
    //   let msg = self.queuedMessages.shift()
    //   if (!msg) {
    //     // No more messages!
    //     self.socketProcessing = false
    //     return
    //   }

    //   // Process message
    //   try {
    //     // Process message
    //     await this.processMessage(msg)
    //   } catch (err) {
    //     // Error!
    //     if (err instanceof Error)
    //       logger.warn(
    //         '[DataPool > BVWebSocketRegion] Error processing WebSocket message! ' + err.message,
    //         msg
    //       )
    //   }

    //   // Done, process next message
    //   self.socketProcessing = false
    //   this.processNextMessage()
    // },
  }))
  .actions(self => {
    const onWebSocketMessage = async (msg: any) => {
      logger.info('[VatomRegion] onWebSocketMessage', msg, self.stateKey)
      // Add to queue
      // self.queuedMessages.push(msg)

      // // Process it if necessary
      // if (!self.socketPaused && !self.socketProcessing) {
      //   self.processNextMessage()
      // }
      await self.processMessage(msg)
    }

    const onWebSocketOpen = async (msg: any) => {
      logger.info('[VatomRegion] onWebSocketOpen', msg, self.stateKey)
      // Full refresh this region, in case any messages were missed
      self.load()
    }
    return { onWebSocketMessage, onWebSocketOpen }
  })
  .actions(self => ({
    async startSockets() {
      logger.info('[VatomRegion] startSockets', self.className, !!self.socket.isOpen)
      await self.socket.connect()
      self.socket.on('message', self.onWebSocketMessage)
      self.socket.on('connected', self.onWebSocketOpen)
    },
    pauseMessages() {
      self.socketPaused = true
    },
    resumeMessages() {
      // Unpause
      self.socketPaused = false

      // Process next message if needed
      // if (!self.socketProcessing) {
      //   this.processNextMessage()
      // }
    },
    beforeDestroy() {
      logger.info('[VatomRegion] beforeDestroy')
      self.socket.off('message', self.onWebSocketMessage)
      self.socket.off('connected', self.onWebSocketOpen)
      self.socket.close()
    },
    clear() {
      // Stop the websocket client
      self.socket.close()
      self._tokens.clear()
    }
  }))

export type VatomRegionStoreType = Instance<typeof VatomRegionStore>
export type VatomRegionStoreSnapshot = SnapshotOut<typeof VatomRegionStore>

import React from 'react'
import { Linking } from 'react-native'
import { Button, GradientButton, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Image, Link, Text, useColorModeValue, VStack } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import { ScreenHeader } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { AppRoutes, AppStackScreenProps } from '../../navigators'

export type MintSuccessProps = AppStackScreenProps<typeof AppRoutes.MintSuccess>

export const MintSuccess = observer(({ route, navigation }: MintSuccessProps) => {
  const { network, transactionId, address } = route.params

  const addressOrTransaction = transactionId ?? address

  const minimizeHash = (hash: string) => {
    const first = hash.substring(0, 10)
    const last = hash.split('').reverse().join('').substring(0, 10).split('').reverse().join('')
    return `${first}...${last}`
  }

  const headerIconColor = useColorModeValue(
    theme.colors.textLightMode[900],
    theme.colors.textDarkMode[100]
  )

  const backgroundColor = useColorModeValue(theme.colors.white, theme.colors.grayDarkMode[900])

  const onClose = useThrottledCallback(() => {
    navigation.navigate(AppRoutes.home)
  }, 300)

  return (
    <ScreenWrapper
      screenBackgroundColor={backgroundColor}
      statusBarBackgroundColor={backgroundColor}
    >
      <VStack flexGrow={1} paddingX={4}>
        <ScreenHeader
          headerLeftType={'close'}
          headerLeftPress={onClose}
          headerTextColor={headerIconColor}
          headerProps={{
            overflow: 'hidden'
          }}
          centerContainerProps={{
            flex: 1,
            paddingX: 2
          }}
          leftContainerProps={{
            flex: 0,
            _web: {
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto'
            }
          }}
          rightContainerProps={{
            flex: 0,
            _web: {
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto'
            }
          }}
        />
        <Box flex={1} justifyContent="center" alignItems="center">
          <Image
            accessibilityIgnoresInvertColors={true}
            source={require('./assets/success.png')}
            height="174px"
            width="203px"
            marginBottom="20px"
            alt="Success"
          />
          <Text
            marginBottom="20px"
            color="primary.500"
            fontFamily="Inter-Bold"
            fontSize="34px"
            textAlign="center"
            lineHeight="41px"
          >
            Success!
          </Text>

          <Text
            w="100%"
            marginBottom="20px"
            fontFamily="Inter-Regular"
            fontSize="15px"
            textAlign="center"
          >
            We have executed your Mint request. It can take up to 20 minutes based on the network
            congestion. Here is the transaction id for your request. Click on the link below for
            complete details.
            <Text
              color="lightBlue.500"
              underline={true}
              onPress={() =>
                Linking.openURL(
                  'https://support.vatom.com/hc/en-us/articles/8126135580187-Mint-your-Smart-NFT-to-the-Blockchain'
                )
              }
            >
              {' '}
              Learn More
            </Text>
          </Text>
          <Link
            href={
              transactionId
                ? getTokenLink(network, transactionId)
                : getAddressLink(network, address)
            }
            flexDirection="row"
            marginBottom="30px"
            marginTop="10px"
          >
            <Text fontFamily="Inter-Regular" fontSize="11px" color="lightBlue.500">
              {minimizeHash(
                !addressOrTransaction || addressOrTransaction === ''
                  ? 'empty'
                  : addressOrTransaction
              ).toUpperCase()}
            </Text>
            <Button padding={0} bgColor="transparent">
              <Image
                accessibilityIgnoresInvertColors={true}
                size="16px"
                source={require('../../assets/external-link.png')}
                alt="external-link"
              />
            </Button>
          </Link>
          <GradientButton onPress={onClose} h="38px" w="73px" justifyContent="center">
            <Text alignSelf="center" fontFamily="Inter-Medium" color="white">
              Done
            </Text>
          </GradientButton>
        </Box>
      </VStack>
    </ScreenWrapper>
  )
})

function getTokenLink(network: string, txId: string): string {
  switch (network) {
    case 'polygon':
    case 'matic_mainnet':
      return `https://polygonscan.com/tx/${txId}`
    case 'matic_testnet':
      return `https://mumbai.polygonscan.com/tx/${txId}`
    case 'bsc_mainnet':
      return `https://bscscan.com/tx/${txId}`
    case 'bsc_testnet':
      return `https://testnet.bscscan.com/tx/${txId}`
    case 'mainnet':
      return `https://etherscan.io/tx/${txId}`
    case 'testnet':
      return `https://goerli.etherscan.io/tx/${txId}`
    case 'kaleido':
      return `https://etherscan.io/tx/${txId}`
    case 'palm_mainnet':
      return `https://explorer.palm.io/tx/${txId}`
    case 'solana':
      return `https://solscan.io/tx/${txId}`
    case 'casper_testnet':
      return `https://testnet.cspr.live/deploy/${txId}`
    case 'casper_mainnet':
      return `https://cspr.live/deploy/${txId}`
    default:
      return `https://etherscan.io/tx/${txId}`
  }
}

function getAddressLink(network: string, address: string): string {
  switch (network) {
    case 'polygon':
    case 'matic_mainnet':
      return `https://polygonscan.com/address/${address}`
    case 'matic_testnet':
      return `https://mumbai.polygonscan.com/address/${address}`
    case 'bsc_mainnet':
      return `https://bscscan.com/address/${address}`
    case 'bsc_testnet':
      return `https://testnet.bscscan.com/address/${address}`
    case 'mainnet':
      return `https://etherscan.io/address/${address}`
    case 'testnet':
      return `https://goerli.etherscan.io/address/${address}`
    case 'kaleido':
      return `https://etherscan.io/address/${address}`
    case 'palm_mainnet':
      return `https://explorer.palm.io/address/${address}`
    case 'solana':
      return `https://solscan.io/address/${address}`
    case 'casper_testnet':
      return `https://testnet.cspr.live/account/${address}`
    case 'casper_mainnet':
      return `https://cspr.live/account/${address}`
    default:
      return `https://etherscan.io/address/${address}`
  }
}

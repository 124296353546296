import React, { useCallback, useMemo, useState } from 'react'
import { Linking, Modal, StyleSheet } from 'react-native'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { translate } from '@vatom/utils'
import { Button, Loader, theme } from '@vatom/wombo'
import { BlurView } from 'expo-blur'
import { Box, Text, useColorModeValue } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import { Title } from '../Title'

const LoadingComponent = () => (
  <Box
    style={StyleSheet.absoluteFillObject}
    flex={1}
    zIndex={999}
    alignItems={'center'}
    alignSelf={'center'}
    justifyItems={'center'}
    justifyContent={'center'}
  >
    <Box
      width={200}
      height={200}
      borderRadius={9999}
      _light={{
        backgroundColor: 'rgba(255, 255, 255, 0.35)'
      }}
      _dark={{
        backgroundColor: 'rgba(0, 0, 0, 0.35)'
      }}
      alignItems={'center'}
      justifyItems={'center'}
      justifyContent={'center'}
    >
      <Loader />
    </Box>
  </Box>
)

type UseModalQuery = {
  modalQueryKey: UseQueryOptions['queryKey']
  modalQueryFn: UseQueryOptions['queryFn']
  onSuccessFn?: UseQueryOptions['onSuccess']
  queryOptions?: Omit<UseQueryOptions, 'queryFn' | 'onSuccess' | 'queryKey' | 'onError'>
  promptError?: {
    title: string
    message: string
    onCloseError?: () => void
    onOpenSetting?: () => void
  }
  LoadingComponent?: React.FC
  ModalComponent?: React.FC
  defaultIsOpen?: boolean
}
export const useModalQuery = ({
  modalQueryKey,
  modalQueryFn,
  onSuccessFn,
  queryOptions = {},
  promptError,
  LoadingComponent: CustomLoadingComponent,
  ModalComponent: CustomModalComponent,
  defaultIsOpen = false
}: UseModalQuery) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen)

  const onOpenCallback = useCallback(() => {
    setIsOpen(true)
  }, [])
  const onOpenHandler = useThrottledCallback(onOpenCallback, 300)

  const onErrorHandler = useCallback(() => {
    // trigger open modal
    onOpenHandler()
  }, [onOpenHandler])

  const { data, isLoading, isFetching, isError, refetch } = useQuery({
    queryKey: modalQueryKey,
    queryFn: modalQueryFn,
    onSuccess: onSuccessFn,
    onError: onErrorHandler,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
    cacheTime: 0,
    retry: 1,
    ...queryOptions
  })

  const isLoadingOrFetching = isLoading || isFetching

  const onCloseCallback = useCallback(() => {
    // close modal
    setIsOpen(false)
    // trigger callback
    promptError?.onCloseError?.()
    // Refetch permissions
    refetch()
  }, [promptError, refetch])

  const onCloseHandler = useThrottledCallback(onCloseCallback, 300)

  const renderLoadingComponent = useMemo(() => {
    if (isLoadingOrFetching) {
      if (CustomLoadingComponent) {
        return <CustomLoadingComponent />
      }
      return <LoadingComponent />
    }

    return null
  }, [CustomLoadingComponent, isLoadingOrFetching])

  const renderPromptError = useMemo(() => {
    if (CustomModalComponent) {
      return CustomModalComponent
    }
    if (!promptError?.title && !promptError?.message) {
      throw new Error('useModalQuery: missing promptError props or ModalComponent')
    }
    return (
      <PromptModal
        isOpen={isOpen}
        title={promptError.title}
        message={promptError.message}
        onClose={onCloseHandler}
        onOpenSetting={promptError.onOpenSetting}
      />
    )
  }, [CustomModalComponent, isOpen, onCloseHandler, promptError])

  const renderModalComponent = useMemo(() => {
    if (renderLoadingComponent) {
      return renderLoadingComponent
    }
    return renderPromptError
  }, [renderLoadingComponent, renderPromptError])

  return {
    refetch,
    data,
    isError,
    isLoadingOrFetching,
    renderModalQueryComponent: renderModalComponent,
    // Self manage >
    renderPromptError: renderPromptError,
    renderLoadingComponent
  }
}

type PromptModalProps = {
  title: string
  message: string
  isOpen: boolean
  onClose: () => void
  onOpenSetting?: () => void
}
export const PromptModal = (props: PromptModalProps) => {
  const { title, message, onClose, isOpen } = props

  const blurStyles = useColorModeValue(
    {
      // blurType: 'light',
      // fallbackColor: 'white'
      blurType: 'dark',
      fallbackColor: 'black'
    },
    {
      blurType: 'dark',
      fallbackColor: 'black'
    }
  )

  const onPressSetting = useThrottledCallback(() => {
    if (props.onOpenSetting) {
      props.onOpenSetting()
      onClose()
      return
    }

    Linking.openSettings()
  }, 300)

  return (
    <Modal style={{ zIndex: 999999 }} visible={isOpen} onDismiss={onClose} transparent>
      <Box
        zIndex={999}
        flex={1}
        alignSelf={'center'}
        justifyItems={'center'}
        justifyContent={'center'}
      >
        <Box
          borderRadius={10}
          shadow={'9'}
          padding={4}
          width={300}
          minHeight={160}
          _light={{
            backgroundColor: theme.colors.white
          }}
          _dark={{
            backgroundColor: theme.colors.grayDarkMode[900]
          }}
        >
          <Title
            _light={{
              color: theme.colors.textLightMode[900]
            }}
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
          >
            {title}
          </Title>
          <Text
            _light={{
              color: theme.colors.textLightMode[600]
            }}
            _dark={{
              color: theme.colors.textDarkMode[300]
            }}
          >
            {message}
          </Text>
          <Box marginTop={4}>
            <Button
              onPress={onPressSetting}
              _light={{
                backgroundColor: theme.colors.systemColorsLight.orange
              }}
              _dark={{
                backgroundColor: theme.colors.systemColorsDark.orange
              }}
            >
              <Text color="white">{translate('common.goToSettings')}</Text>
            </Button>
            <Button
              onPress={onClose}
              marginTop={2}
              _light={{
                backgroundColor: theme.colors.white,
                borderWidth: 1,
                borderColor: theme.colors.grayCool[400]
              }}
              _dark={{
                backgroundColor: theme.colors.grayDarkMode[900],
                borderWidth: 1,
                borderColor: theme.colors.grayDarkMode[500]
              }}
            >
              <Text
                _light={{ color: theme.colors.grayCool[700] }}
                _dark={{
                  color: theme.colors.grayDarkMode[100]
                }}
              >
                {translate('common.close')}
              </Text>
            </Button>
          </Box>
        </Box>
      </Box>
      <BlurView tint={blurStyles.blurType} style={StyleSheet.absoluteFillObject} />
    </Modal>
  )
}

// Usage
const getSomePermission = async () => {
  return Promise.resolve({ some: 'data' })
}
const SampleOne = () => {
  const {
    //  isLoadingOrFetching,
    //  isError: isErrorPermission,
    renderModalQueryComponent,
    data: permissions
  } = useModalQuery({
    modalQueryKey: ['drop-location'],
    modalQueryFn: getSomePermission,
    onSuccessFn: data => {
      // // do something else needed
      // if (data?.some) {
      //   // setSomething()
      // }
    },
    queryOptions: {}, // custom query options
    promptError: {
      title: 'Permission Issue',
      message: 'We need more',
      onCloseError: () => console.log('Prompt Error was closed')
    }
    //  LoadingComponent: () => (
    //    <Box>
    //      <Text>Loading...</Text>
    //    </Box>
    //  ) // Not required
  })
  return (
    <>
      {renderModalQueryComponent}
      <Box>
        <Text>A Thing that requires permission</Text>
      </Box>
    </>
  )
}

// Usage: self manage with components
const SampleTwo = () => {
  const {
    isLoadingOrFetching,
    isError: isErrorPermission,
    renderLoadingComponent,
    renderPromptError,
    data: permissions
  } = useModalQuery({
    modalQueryKey: ['drop-location'],
    modalQueryFn: getSomePermission,
    queryOptions: {}, // custom query options
    promptError: {
      title: 'Permission Issue',
      message: 'We need more',
      onCloseError: () => console.log('Prompt Error was closed')
    },
    LoadingComponent: () => (
      <Box>
        <Text>Loading custom...</Text>
      </Box>
    ) // Not required
  })

  return (
    <>
      {isLoadingOrFetching && renderLoadingComponent}
      {isErrorPermission && renderPromptError}
      <Box>
        <Text>A Thing that requires permission</Text>
      </Box>
    </>
  )
}
// Usage: self manage full
const SampleTree = () => {
  const {
    isLoadingOrFetching,
    isError: isErrorPermission,
    data: permissions
  } = useModalQuery({
    modalQueryKey: ['drop-location'],
    modalQueryFn: getSomePermission
  })

  return (
    <>
      {isLoadingOrFetching && (
        <Box>
          <Text>Loading...</Text>
        </Box>
      )}
      {isErrorPermission && (
        <Box>
          <Text>Permission Issue</Text>
          <Text>We need more permission here</Text>
        </Box>
      )}
      <Box>
        <Text>A Thing that requires permission</Text>
      </Box>
    </>
  )
}

import React from 'react'
import { theme } from '@vatom/wombo'
import { Box, Image } from 'native-base'

import { useIsDesktop } from '../../../../hooks/useIsDesktop'

const CoinAvatar = ({
  source,
  name = '',
  size,
  containerProps
}: {
  source: string
  name: string
  size: number
  containerProps: React.ComponentProps<typeof Box>
}) => {
  const isDesktop = useIsDesktop()
  const spacer = isDesktop ? 4 : 2
  return (
    <Box
      position={'relative'}
      width={size + spacer}
      height={size + spacer}
      alignItems={'center'}
      justifyContent={'center'}
      borderRadius={999}
      style={{
        shadowColor: theme.colors.black,
        shadowOffset: {
          width: 0,
          height: 4
        },
        shadowOpacity: 0.12,
        shadowRadius: 30,
        elevation: 10
      }}
      _light={{
        backgroundColor: theme.colors.white
      }}
      _dark={{
        backgroundColor: theme.colors.grayDarkMode[900]
      }}
      {...containerProps}
    >
      <Box
        position={'absolute'}
        zIndex={2}
        width={size + spacer}
        height={size + spacer}
        marginLeft={`-${spacer}px`}
        marginTop={`-${spacer}px`}
        borderWidth={4}
        borderRadius={999}
        _light={{
          borderColor: theme.colors.white
        }}
        _dark={{
          borderColor: theme.colors.grayDarkMode[700]
        }}
      />
      <Image
        width={size - 1}
        height={size - 1}
        borderRadius={999}
        overflow={'hidden'}
        style={{ zIndex: 1 }}
        accessibilityIgnoresInvertColors={true}
        source={{ uri: source }}
        alt={name}
      />
    </Box>
  )
}

const CoinAvatarMemo = React.memo(CoinAvatar)
export { CoinAvatarMemo as CoinAvatar }

import React from 'react'
import { Dimensions, Modal, Pressable, TouchableOpacity, View } from 'react-native'
import Slider from 'react-slick'
import { Image } from 'expo-image'

import CloseIcon from '../../../../../assets/header-icons/close-icon.svg'
import { mxcToHttp } from '../../../../../helpers'
import { MessageMedia } from '../../../../../types'

import SliderArrow from './SliderArrow'

import './GallerySliderForWeb.css'

type GallerySliderModalProps = {
  setIsModalOpen: (arg0: boolean) => void
  selectedImage: number
  parsedMedia: MessageMedia[]
  isModalOpen: boolean
}

const GallerySliderModal = (props: GallerySliderModalProps) => {
  const { setIsModalOpen, selectedImage, parsedMedia, isModalOpen } = props
  const httpImages =
    parsedMedia
      .filter(media => media.type === 'image')
      .map(image => mxcToHttp(image.uri, 'matrix.api.vatominc.com')) ?? []

  const { width } = Dimensions.get('window')
  const maxWidth = 640

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    draggable: width < maxWidth,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: width < maxWidth ? null : <SliderArrow direction="right" placement="feed" />,
    prevArrow: width < maxWidth ? null : <SliderArrow direction="left" placement="feed" />,
    initialSlide: selectedImage,
    className: 'full-screen'
  }

  const { height } = Dimensions.get('screen')

  return (
    <Modal animationType="fade" visible={isModalOpen}>
      <View style={{ backgroundColor: 'black', flex: 1 }}>
        <TouchableOpacity accessibilityRole="button" onPress={() => setIsModalOpen(false)}>
          <View
            style={{
              width: 30,
              height: 30,
              left: 12,
              top: 20
            }}
          >
            <CloseIcon width={20} height={20} fill={'#FFFFFF'} />
          </View>
        </TouchableOpacity>

        <View style={{ marginVertical: 20 }}>
          <Pressable accessibilityRole="none">
            {httpImages.length > 1 ? (
              // @ts-expect-error style doesn't seem to be included in this component but I'm afraid to remove it as it might cause some ui issues
              <Slider {...settings} style={{ flex: 1 }}>
                {httpImages.map((image, index) => (
                  <View key={`gallery-slider-modal-${image}`} style={{ flex: 1 }}>
                    <Image
                      accessibilityIgnoresInvertColors
                      source={image}
                      contentFit="contain"
                      style={{ width: '100%', height: height - 40 }}
                    />
                  </View>
                ))}
              </Slider>
            ) : httpImages.length === 1 ? (
              httpImages.map((image, index) => (
                <Image
                  accessibilityIgnoresInvertColors
                  key={`gallery-slider-modal-${image}`}
                  source={image}
                  contentFit="contain"
                  style={{ width: '100%', height: height - 40 }}
                />
              ))
            ) : null}
          </Pressable>
        </View>
      </View>
    </Modal>
  )
}

export default GallerySliderModal

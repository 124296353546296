export function filterByKey<T extends object>(
  data: T[],
  searchTerm: string,
  filterKeys: (keyof T)[]
): T[] {
  if (searchTerm.length === 0) {
    return data
  }
  const filteredData = data.filter(item => {
    let hasMatch = false
    for (const key of filterKeys) {
      if (item[key]) {
        hasMatch =
          item[key as keyof T]?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ?? false
        if (hasMatch) {
          break
        }
      }
    }
    return hasMatch
  })
  return filteredData ?? data
}

import { useMemo, useState } from 'react'
import { Dimensions, Platform } from 'react-native'
import { Entypo } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { TokenTypes } from '@vatom/sdk/core'
import { Contact, DestinationTypes } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { Avatar, Text, theme } from '@vatom/wombo'
import { Box, Divider, FlatList, Icon, Pressable, View } from 'native-base'

import Alert from '../../../components/Alert'
import { AppRoutes } from '../../../navigators/AppNavigator'
import { isValidEthereumAddress } from '../../../utils/isValidAddress'

import { SendStorage } from './Constants'
import NoResult from './NoResult'
import { useSender } from './SendProvider'

interface ResultListItemProps {
  index: number
  item: Contact
}

export const ResultsList = () => {
  const navigation = useNavigation()

  const {
    token,
    isShare,
    destination,
    contacts,
    lastSearchString,
    searchString,
    contractAddress,
    owner
  } = useSender()

  const isSelfCustodialTransaction = contractAddress && owner
  const data = useMemo(() => {
    if (!isSelfCustodialTransaction) {
      return contacts.data
    }
    // TODO: REVIEW: isCustodial is valid?
    return contacts.data.filter(d => d.identities?.some(i => !i.isCustodial && i.type === 'eth'))
  }, [contacts.data, isSelfCustodialTransaction])

  const validateCustodial = (contact: Contact) => {
    const isCustodial =
      true || !!contact.identities?.find(i => i.value === destination.validatedValue && i.custodial)

    const tokenType = token.getTokenType()

    const isBlockchainAwareSmartNFT =
      tokenType === TokenTypes.VatomMintedCustodial ||
      tokenType === TokenTypes.VatomMintedNonCustodial ||
      tokenType === TokenTypes.VatomUnMinted

    if (
      destination.type === DestinationTypes.Eth &&
      isCustodial &&
      !isBlockchainAwareSmartNFT &&
      isValidEthereumAddress(destination.validatedValue?.toLowerCase() ?? '')
    ) {
      return true
    }

    if (destination.type === DestinationTypes.Eth && tokenType === TokenTypes.VatomBasic) {
      Alert.quickFire(translate('errors.unmintableSendFail'))
      return false
    }

    return true
  }

  const handleOnPressItem = (userToSend: Contact) => {
    const contactIsValid = validateCustodial(userToSend)
    if (!contactIsValid) {
      return
    }

    if (contractAddress && owner) {
      const toUserId = !isValidEthereumAddress(userToSend.id.toLowerCase())
        ? userToSend.id
        : undefined
      const toAddress = isValidEthereumAddress(destination.validatedValue?.toLowerCase() ?? '')
        ? destination.validatedValue
        : undefined

      if (toUserId) {
        navigation.navigate(AppRoutes.sendNFTConfirmation, {
          tokenId: token.id,
          owner,
          contractAddress,
          toUserId
        })
      } else if (toAddress) {
        navigation.navigate(AppRoutes.sendNFTConfirmation, {
          tokenId: token.id,
          owner,
          contractAddress,
          toAddress
        })
      }

      return
    }

    if (Platform.OS === 'web') {
      localStorage.setItem(SendStorage.Destination, JSON.stringify(destination))
      localStorage.setItem(SendStorage.UserToSend, JSON.stringify(userToSend))

      navigation.navigate(AppRoutes.sendConfirmation, {
        tokenId: token.id,
        isShare
      })
    } else {
      navigation.navigate(AppRoutes.sendConfirmation, {
        tokenId: token.id,
        userToSend,
        destination,
        isShare
      })
    }
  }

  const renderItem = ({ item, index }: ResultListItemProps) => {
    return (
      <Pressable
        onPress={() => handleOnPressItem(item)}
        accessibilityLabel="button"
        accessibilityHint="button"
      >
        <Box alignItems="center" flexDir="row" h={50} my="10px">
          <Box marginRight="8px" justifyContent="center" alignItems="center">
            <Avatar size={44} url={item.avatarUrl} name={item.name} key={index} />
          </Box>
          <Box flex={1}>
            <Text
              fontFamily="Inter-Bold"
              fontSize={15}
              _light={{
                color: theme.colors.textLightMode[900]
              }}
              _dark={{
                color: theme.colors.textDarkMode[100]
              }}
            >
              {item.name}
            </Text>
            {item.preferred_username && (
              <Text
                fontFamily="Inter-Regular"
                fontSize={13}
                lineHeight={18}
                _light={{
                  color: theme.colors.textLightMode[300]
                }}
                _dark={{
                  color: theme.colors.textDarkMode[600]
                }}
              >
                {`@${item.preferred_username}`}
              </Text>
            )}
          </Box>
          <Pressable accessibilityRole="button" alignItems="center">
            <Icon
              height="20px"
              as={Entypo}
              name="chevron-thin-right"
              color="light.200"
              _light={{
                color: theme.colors.grayCool[600]
              }}
              _dark={{
                color: theme.colors.grayDarkMode[300]
              }}
            />
          </Pressable>
        </Box>
        <Divider
          bg="gray.900"
          _light={{
            backgroundColor: theme.colors.grayCool[300]
          }}
          _dark={{
            backgroundColor: theme.colors.grayDarkMode[700]
          }}
          height="1px"
        />
      </Pressable>
    )
  }

  if (isValidEthereumAddress(searchString.toLowerCase())) {
    if (!data.find(d => d.value?.toLowerCase() === searchString.toLowerCase())) {
      data.push({
        avatarUrl: searchString,
        id: searchString,
        name: searchString,
        createdAt: Date.now()
      })
    }
  }

  function renderNoResult() {
    if (isSelfCustodialTransaction) {
      const custodialTransacionIsReady =
        !contacts.isLoading &&
        lastSearchString === searchString &&
        isValidEthereumAddress(searchString.toLowerCase())

      if (!custodialTransacionIsReady) return null
    }

    return <NoResult />
  }

  return (
    <Box
      flexGrow={1}
      _web={{
        height: '100%',
        overflow: 'scroll',
        marginX: -4
      }}
    >
      {data.length > 0 ? (
        <>
          {/* <Text
            fontFamily="Inter-Bold"
            mb="5px"
            fontSize="22px"
            fontWeight="700"
            color="black"
            _light={{
              color: theme.colors.textLightMode[900]
            }}
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
            tx="common.results"
          /> */}
          <FlatList
            keyboardDismissMode={'on-drag'}
            data={[...new Map(data.map(item => [item.id, item])).values()]}
            keyboardShouldPersistTaps={'never'}
            renderItem={renderItem}
            showsVerticalScrollIndicator={false}
            keyExtractor={contact => `results-${contact.id}`}
            _web={{
              overflow: 'scroll',
              height: '100%',
              paddingX: 4
            }}
            backgroundColor={'transparent'}
            contentContainerStyle={{
              paddingBottom: 116 + 20
            }}
          />
        </>
      ) : (
        renderNoResult()
      )}
    </Box>
  )
}

export default ResultsList

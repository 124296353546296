import { useCallback, useLayoutEffect, useRef } from 'react'
import { Platform } from 'react-native'

const isWeb = Platform.OS === 'web'

export function useWindowScroll({ scrollCallback }: { scrollCallback?: (arg: any) => void } = {}) {
  const lastKnownScrollPosition = useRef(0)
  const ticking = useRef(false)

  const handleScroll = useCallback(() => {
    lastKnownScrollPosition.current = window.scrollY

    if (!ticking.current) {
      window.requestAnimationFrame(() => {
        scrollCallback?.({
          x: 0,
          y: Math.round(lastKnownScrollPosition.current)
        })
        ticking.current = false
      })

      ticking.current = true
    }
  }, [scrollCallback])

  useLayoutEffect(() => {
    isWeb && window?.addEventListener('scroll', handleScroll)
    return () => {
      isWeb && window?.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])
}

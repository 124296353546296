/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * DenylistBase
 * auto generated base class for the model DenylistModel.
 */
export const DenylistModelBase = ModelBase
  .named('Denylist')
  .props({
    __typename: types.optional(types.literal("Denylist"), "Denylist"),
    storefronts: types.union(types.undefined, types.array(types.frozen())),
    listings: types.union(types.undefined, types.array(types.frozen())),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class DenylistModelSelector extends QueryBuilder {
  get storefronts() { return this.__attr(`storefronts`) }
  get listings() { return this.__attr(`listings`) }
}
export function selectFromDenylist() {
  return new DenylistModelSelector()
}

export const denylistModelPrimitives = selectFromDenylist().storefronts.listings

import React, { useMemo, useState } from 'react'
import { Text, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import RenderHtml, { HTMLContentModel, HTMLElementModel } from 'react-native-render-html'
import { useNavigation } from '@react-navigation/native'
import * as WebBrowser from 'expo-web-browser'

import { AppRoutes } from '../../../../../navigators'
import { useCommunitiesTheme } from '../../../themes'
import { useCommunitySpace } from '../../../useCommunitySpace'

export const WebDisplay = React.memo(
  ({ text, textStyle, spaceId }: { text: string; textStyle?: object; spaceId: string }) => {
    const { width } = useWindowDimensions()
    const newText = useMemo(
      () =>
        text
          .replace(/ style="[^"]*"/g, '')
          .replace(/<\/?div[^>]*>/g, '')
          .replace(/<\/?span[^>]*>/g, '')
          // Add space to mentions to avoid them being glued to the previous word
          .replaceAll(`class="mention">`, `class="mention">&nbsp;`),
      [text]
    )

    const onPress = (event: any, href: string) => {
      WebBrowser.openBrowserAsync(href)
    }

    const renderersProps = {
      a: {
        onPress: onPress
      }
    }

    function MentionRenderer({ TDefaultRenderer, ...props }: any) {
      const navigation = useNavigation()
      const { businessId } = useCommunitySpace()
      const onPress = () => {
        const userID = props.tnode.init.domNode.attribs.id
        navigation.navigate(AppRoutes.profileOtherUser_Business, {
          business: businessId,
          userId: userID?.match('@(.*):')?.[1] ?? '',
          spaceId: spaceId
        })
      }
      return <TDefaultRenderer {...props} onPress={onPress} />
    }

    const renderers = {
      mention: MentionRenderer
    }

    const communitiesTheme = useCommunitiesTheme()

    const customHTMLElementModels = {
      mention: HTMLElementModel.fromCustomModel({
        tagName: 'mention',
        mixedUAStyles: {
          color: '#2EA7FF',
          fontWeight: '500',
          fontSize: 15,
          lineHeight: 20
        },
        contentModel: HTMLContentModel.block
      })
    }
    return (
      <RenderHtml
        source={{ html: `<div class="wrapper">${newText}</div>` }}
        contentWidth={width}
        renderers={renderers}
        tagsStyles={{
          ul: {
            margin: 0,
            paddingLeft: 20,
            width: '100%'
          },
          ol: {
            margin: 0,
            paddingLeft: 20,
            width: '100%'
          }
        }}
        classesStyles={{
          wrapper: {
            color: communitiesTheme.chatBubbleContent.text,
            fontSize: 15,
            lineHeight: 20,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
            ...textStyle
          },
          ul: {
            margin: 0,
            paddingLeft: 20
          },
          ol: {
            margin: 0,
            paddingLeft: 20
          },
          a: {
            color: '#3798E4'
          }
        }}
        renderersProps={renderersProps}
        enableExperimentalBRCollapsing={true}
        customHTMLElementModels={customHTMLElementModels}
      />
    )
  }
)

type TextParagraphProps = {
  text: string
  align?: string
  isThread?: boolean
  textStyle?: object
  maxHeight?: number
  showMaxSize?: boolean
  spaceId: string
}

const TextParagraph = ({
  text,
  textStyle,
  maxHeight,
  showMaxSize,
  spaceId
}: TextParagraphProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [scrollViewHeight, setScrollViewHeight] = useState(0)

  const renderContent = () => {
    if (!text) {
      return
    }

    const height = maxHeight ?? 200

    const containerStyle = isExpanded || showMaxSize ? {} : { maxHeight: height }

    return (
      <View>
        <View
          style={{ ...containerStyle, overflow: 'hidden' }}
          onLayout={event => {
            const { height } = event.nativeEvent.layout
            setScrollViewHeight(height)
          }}
        >
          <WebDisplay text={text} textStyle={textStyle} spaceId={spaceId} />
        </View>
        {scrollViewHeight >= height && !showMaxSize ? (
          <TouchableOpacity
            accessibilityRole="button"
            onPress={() => setIsExpanded(!isExpanded)}
            style={{ marginTop: 10 }}
          >
            <Text style={{ color: '#2EA7FF', fontSize: 15, lineHeight: 20 }}>
              {isExpanded ? 'Show less' : 'Show more'}
            </Text>
          </TouchableOpacity>
        ) : null}
      </View>
    )
  }

  return <View>{renderContent()}</View>
}

export default TextParagraph

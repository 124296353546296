import React from 'react'
import { Alert, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Image } from 'expo-image'
import { getLinkPreview } from 'link-preview-js'
import { observer } from 'mobx-react-lite'

import CloseIcon from '../../../assets/header-icons/close-icon.svg'
import { Preview } from '../../../types'
import { useStoreContext } from '../../MSTContextProvider'

const ModalLinkPreview = ({ link }: { link: string }) => {
  const store = useStoreContext()
  const { linkPreviews } = store
  const currentLinkPreview = linkPreviews.find(lp => lp === link)
  const [preview, setPreview] = React.useState<Preview | null>(null)

  React.useEffect(() => {
    const getPreview = async () => {
      try {
        const p = await getLinkPreview(link).then(data => data)
        setPreview(p as Preview)
        store.addLinkPreview(link)
      } catch (e) {
        Alert.alert('Cannot preview link', `${link} cannot be previewed`)
      }
    }
    getPreview()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!preview || !currentLinkPreview) {
    return null
  }

  if (!preview.siteName) {
    return null
  }

  return (
    <View key={preview.title} style={styles.container}>
      <TouchableOpacity
        accessibilityRole="button"
        style={styles.removeButton}
        onPress={() => store?.removeLinkPreview(link)}
      >
        <CloseIcon fill="#FFF" height={10} width={10} />
      </TouchableOpacity>
      <View style={{ flexDirection: 'row', marginBottom: 3, alignItems: 'center' }}>
        <Image
          accessibilityIgnoresInvertColors
          source={{ uri: preview.images[0] }}
          style={{ width: 20, height: 20, marginRight: 5 }}
        />
        <Text style={styles.siteName}>{preview.siteName}</Text>
      </View>

      <Text style={styles.title}>{preview.title}</Text>
      <Text style={styles.description} numberOfLines={1} ellipsizeMode="tail">
        {preview.description}
      </Text>
    </View>
  )
}

export default observer(ModalLinkPreview)

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    backgroundColor: '#242730',
    padding: 10,
    maxWidth: 200,
    marginRight: 16
  },
  siteName: {
    color: '#FFF',
    fontWeight: '600'
  },
  title: {
    color: '#FFF',
    fontWeight: '600',
    marginBottom: 3
  },
  description: {
    color: '#868E96'
  },
  removeButton: {
    position: 'absolute',
    top: -7,
    right: -7,
    width: 20,
    height: 20,
    backgroundColor: '#29313A',
    zIndex: 1,
    borderRadius: 40,
    alignItems: 'center',
    justifyContent: 'center'
  }
})

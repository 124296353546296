import { createContext, useContext } from 'react'

import { RoomType } from '../types'

export const RoomContext = createContext<RoomType>({
  room_id: '',
  avatar_url: '',
  name: '',
  join_rule: '',
  num_joined_members: 0,
  space_id: '',
  business_id: ''
})

export const RoomProvider = ({
  value,
  children
}: {
  value: RoomType
  children: React.ReactNode
}) => {
  return <RoomContext.Provider value={value}>{children}</RoomContext.Provider>
}

export const useRoomContext = () => useContext(RoomContext)

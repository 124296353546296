import React from 'react'
import { Ionicons } from '@expo/vector-icons'
import { Avatar, Text, theme } from '@vatom/wombo'
import { Box, Pressable } from 'native-base'

type CoinItemProps = {
  title: string
  subTitle?: string
  imageURL: string
  networkURL?: string
  balance: string
  balanceAmount?: string
  id: string
  onPress: () => void
  pressableProps?: React.ComponentProps<typeof Pressable>
  containerProps?: React.ComponentProps<typeof Box>
  isWatching?: boolean
}

const CoinListItem = ({
  title,
  subTitle,
  imageURL,
  networkURL,
  balance,
  balanceAmount,
  onPress,
  pressableProps,
  containerProps,
  isWatching
}: CoinItemProps) => {
  return (
    <Pressable accessibilityRole="button" onPress={onPress} {...pressableProps}>
      <Box
        alignItems="center"
        borderBottomWidth={1}
        borderColor={theme.colors.grayCool[200]}
        flexDir="row"
        h={62}
        py={3}
        {...containerProps}
      >
        <Avatar size={44} url={imageURL} badgeColor={undefined} />
        {networkURL && <Avatar size={18} url={networkURL} badgeColor={undefined} />}

        <Box ml={3} flex={1}>
          <Text
            fontFamily="Inter-Bold"
            fontSize={15}
            lineHeight={20}
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
            _light={{
              color: theme.colors.bodyText
            }}
          >
            {title}{' '}
            {isWatching && (
              <Ionicons
                name="time-outline"
                size={12}
                _dark={{
                  color: theme.colors.textDarkMode[100]
                }}
                _light={{
                  color: theme.colors.bodyText
                }}
              />
            )}
          </Text>
          {subTitle && (
            <Text
              fontFamily={'Inter-Regular'}
              fontWeight={400}
              fontSize={11}
              lineHeight={15}
              _dark={{
                color: theme.colors.textDarkMode[100]
              }}
              _light={{
                color: theme.colors.bodyText
              }}
            >
              {subTitle}
            </Text>
          )}
        </Box>
        <Box alignItems={'flex-end'} ml={3} flex={1}>
          <Text
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
            _light={{
              color: theme.colors.bodyText
            }}
            fontFamily="Inter-Bold"
            fontSize={15}
          >
            {balance}
          </Text>
          {balanceAmount && (
            <Text
              _dark={{
                color: theme.colors.textDarkMode[100]
              }}
              _light={{
                color: theme.colors.bodyText
              }}
              fontFamily="Inter-Regular"
              fontSize={11}
              lineHeight={15}
            >
              {balanceAmount}
            </Text>
          )}
        </Box>
      </Box>
    </Pressable>
  )
}

CoinListItem.Skeleton = () => (
  <Box alignItems="center" borderBottomWidth={1} borderColor="gray.50" flexDir="row" h={62} py={3}>
    <Box size={44} backgroundColor={'#ccc'} />

    <Box ml={3} flex={1}>
      <Box
        _light={{
          backgroundColor: theme.colors.grayCool[100]
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[800]
        }}
        width={'90%'}
        height={5}
        borderRadius={3}
      />
      <Box
        _light={{
          backgroundColor: theme.colors.grayCool[100]
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[800]
        }}
        width={'33%'}
        height={4}
        marginTop={2}
        borderRadius={3}
      />
    </Box>
    <Box alignItems={'flex-end'} ml={3} flex={1}>
      <Box
        _light={{
          backgroundColor: theme.colors.grayCool[100]
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[800]
        }}
        width={20}
        height={4}
        marginTop={2}
        borderRadius={3}
      />
    </Box>
  </Box>
)

export { CoinListItem }
export type { CoinItemProps }

import React, { memo } from 'react'
import { useRoute } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'

import {
  CommunitiesParamList,
  CommunitiesRoutes,
  CommunitiesScreenProps
} from '../../../navigators'
import { CommunitiesHome } from '../screens/CommunitiesHome'
import { CommunitiesProvider, useCommunitySpace } from '../useCommunitySpace'

const Stack = createNativeStackNavigator<CommunitiesParamList>()

const CommunityNavigator = () => {
  // const route = useRoute<CommunitiesScreenProps<'Communities'>['route']>()
  // const spaceIdFromRoute = route.params?.spaceId

  const { rootId, community, spaceId: spaceIdFromRoute } = useCommunitySpace()
  const spaceId = spaceIdFromRoute ?? (rootId as string)
  // const initialParams = {
  //   ...route.params,
  //   spaceId: route.params?.spaceId ?? spaceId
  // }

  return (
    <CommunitiesProvider spaceId={spaceId}>
      <Stack.Navigator
        id="CommunitiesNavigator"
        // initialRouteName={CommunitiesRoutes.Communities}
        screenOptions={{
          headerShown: false
        }}
      >
        <Stack.Screen
          name={CommunitiesRoutes.Communities} // List of communities
          component={CommunitiesHome}
        />
        <Stack.Screen
          name={CommunitiesRoutes.CommunitiesHome} // List of community with id
          component={CommunitiesHome}
        />

        {/* <Stack.Screen name={CommunitiesRoutes.RoomView} component={RoomView} />
        <Stack.Screen name={CommunitiesRoutes.Members} component={Members} />
        <Stack.Screen name={CommunitiesRoutes.Thread} component={Thread} />

        <Stack.Group screenOptions={{ presentation: 'fullScreenModal' }}>
          <Stack.Screen name={CommunitiesRoutes.AddMessageModal} component={AddMessageModal} />

          <Stack.Screen name={CommunitiesRoutes.Reply} component={Reply} />
        </Stack.Group>

        <Stack.Group screenOptions={{ presentation: 'fullScreenModal' }}>
          <Stack.Screen name={CommunitiesRoutes.QuestionsList} component={Questions} />
          <Stack.Screen name={CommunitiesRoutes.AddQuestionModal} component={AddQuestion} />
          <Stack.Screen name={CommunitiesRoutes.SketchesList} component={Sketches} />
          <Stack.Screen name={CommunitiesRoutes.AddSketchModal} component={AddSketch} />
        </Stack.Group> */}
      </Stack.Navigator>
    </CommunitiesProvider>
  )
}

export default memo(CommunityNavigator)

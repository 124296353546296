import React from 'react'

import { withBusinessSelector } from '../../hooks/useBusinessSelector'
import { MainTabScreenProps } from '../../navigators'

import Navigator from './components/Navigator'
import { SyncContainer } from './SyncContainer'

export const Communities = (props: MainTabScreenProps<'Connect'>) => {
  return (
    <Navigator />
  )
}

export const CommunitiesWithBusinessSelector = withBusinessSelector(Communities)

import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const SendFromWalletIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 19 17" size={size} {...iconProps}>
    <Path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7377 0.0187504C11.8802 0.0574996 12.001 0.153509 12.0723 0.284662L13.7551 3.37962H15.6617C16.0246 3.37962 16.3675 3.53292 16.6164 3.79723C16.8646 4.0607 17 4.41303 17 4.77554V8.57106C17 8.5732 17 8.57533 17 8.57747V12.8019C17 12.8041 17 12.8062 17 12.8084V16.6041C17 16.9666 16.8646 17.3189 16.6164 17.5824C16.3675 17.8467 16.0246 18 15.6617 18H1.3383C0.97538 18 0.632479 17.8467 0.383549 17.5824C0.135403 17.3189 0 16.9666 0 16.6041V4.77554C0 4.41303 0.135403 4.0607 0.383549 3.79723C0.632479 3.53292 0.975379 3.37962 1.3383 3.37962H5.9331L11.317 0.0792728C11.4433 0.00185322 11.5952 -0.0199988 11.7377 0.0187504ZM12.5124 3.37388L11.395 1.31869L8.05073 3.36873L12.5124 3.37388ZM15.9149 13.353H12.9761C11.5248 13.353 10.3447 12.1626 10.3447 10.6897C10.3447 9.21682 11.5248 8.02645 12.9761 8.02645H15.9149V4.77554C15.9149 4.68989 15.8826 4.61241 15.8323 4.55898C15.7827 4.50639 15.7208 4.48166 15.6617 4.48166H1.3383C1.27912 4.48166 1.21722 4.50639 1.16769 4.55898C1.11737 4.61241 1.0851 4.68989 1.0851 4.77554V16.6041C1.0851 16.6897 1.11737 16.7672 1.16769 16.8206C1.21722 16.8732 1.27912 16.898 1.3383 16.898H15.6617C15.7208 16.898 15.7827 16.8732 15.8323 16.8206C15.8826 16.7672 15.9149 16.6897 15.9149 16.6041V13.353ZM15.9149 9.12849H12.9761C12.1197 9.12849 11.4298 9.82984 11.4298 10.6897C11.4298 11.5496 12.1197 12.2509 12.9761 12.2509H15.9149V9.12849Z"
    />
  </Icon>
)

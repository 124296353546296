/* eslint-disable unicorn/filename-case */
function getRGB(c: string) {
  return parseInt(c, 16)
}

function getsRGB(c: string) {
  return getRGB(c) / 255 <= 0.03928
    ? getRGB(c) / 255 / 12.92
    : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4)
}

function getLuminance(hexColor: string) {
  return (
    0.2126 * getsRGB(hexColor.substr(1, 2)) +
    0.7152 * getsRGB(hexColor.substr(3, 2)) +
    0.0722 * getsRGB(hexColor.substr(-2))
  )
}

function getContrast(f: string, b: string) {
  const L1 = getLuminance(f)
  const L2 = getLuminance(b)
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
}

const colors = {
  white: '#ffffff',
  black: '#000000'
} as const

type Color = typeof colors.white | typeof colors.black

export function getTextColor(bgColor: string): Color {
  const whiteContrast = getContrast(bgColor, colors.white)
  const blackContrast = getContrast(bgColor, colors.black)

  return whiteContrast > blackContrast ? colors.white : colors.black
}

export function getStatusBarStyle(bgColor: string) {
  const textColor = getTextColor(bgColor)
  return textColor === colors.white ? 'light-content' : 'dark-content'
}

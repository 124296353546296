import React from 'react'
import { Dimensions, Pressable, View } from 'react-native'
import Slider from 'react-slick'

import {
  extractVimeoID,
  extractYoutubeID,
  isLoomLink,
  isVimeoLink,
  isYouTubeLink
} from '../../../../../helpers'
import { useMessage } from '../../../../../queries'
import { useCommunitiesTheme } from '../../../../../themes'
import { MessageMedia } from '../../../../../types'
import { useMessageContext } from '../../../MessageContext'
import ImageComponent from '../ImageComponent'
import { VideoComponent, VideoWebView } from '../VideoWebView'

import GallerySliderForWebModal from './GallerySliderForWebModal'
import SliderArrow from './SliderArrow'

import './GallerySliderForWeb.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const ImageGalleryWrapper = ({ roomId }: { roomId: string }) => {
  const { messageEventID } = useMessageContext()
  const { data: matrixMessage } = useMessage(roomId, messageEventID)

  if (!matrixMessage) {
    return null
  }

  const {
    content: { media }
  } = matrixMessage

  if (!media) {
    return null
  }

  const parsedMedia: MessageMedia[] = typeof media === 'string' ? JSON.parse(media) : []

  return parsedMedia.length > 0 ? <ImageGalleryForWeb parsedMedia={parsedMedia} /> : null
}

const { width } = Dimensions.get('window')
const maxWidth = 640

export const ImageGalleryForWeb = ({ parsedMedia }: { parsedMedia: MessageMedia[] }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [selectedImage, setSelecteImage] = React.useState(0)
  const [sliderWidth, setSliderWidth] = React.useState(0)
  const handleSelectImage = (uri: string) => {
    setIsModalOpen(true)
    setSelecteImage(parsedMedia.findIndex((img: MessageMedia) => img.uri === uri))
  }

  const communitiesTheme = useCommunitiesTheme()

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    draggable: width < maxWidth,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: width < maxWidth ? null : <SliderArrow direction="right" placement="feed" />,
    prevArrow: width < maxWidth ? null : <SliderArrow direction="left" placement="feed" />,
    className: 'feed',
    appendDots: (dots: any[]) => (
      <ul style={{ margin: '0px' }}>
        {dots.map((dot, index) => {
          return (
            <li key={`dot-${index}`} style={{ width: '6px', height: '6px' }}>
              <div
                style={{
                  backgroundColor:
                    dot.props.className === 'slick-active'
                      ? communitiesTheme.carousel.activeDot
                      : communitiesTheme.carousel.dot,
                  width: 6,
                  height: 6,
                  borderRadius: '100%'
                }}
              ></div>
            </li>
          )
        })}
      </ul>
    )
  }

  const renderMediaComponents = () => {
    return parsedMedia.map(media => {
      if (!media) {
        return null
      }
      const { type, uri } = media
      if (type === 'image') {
        const elements = uri.split('/')
        const mediaId = elements[elements.length - 1]

        return (
          <ImageComponent
            key={`thumbnail-${uri}`}
            image={`https://matrix.vatom.com/_matrix/media/v3/thumbnail/vatom.com/${mediaId}?width=640&height=480`}
            uri={uri}
            handleSelectImage={handleSelectImage}
            imageStyle={{ height: sliderWidth / 1.7 }}
          />
        )
      }
      if (type === 'video-stream') {
        if (isYouTubeLink(uri)) {
          const videoId = extractYoutubeID(uri) ?? ''
          const embedURL = `https://www.youtube.com/embed/${videoId}?controls=1&enablejsapi=1`
          return (
            <VideoWebView
              key={`vimeo-video-${videoId}`}
              embedURL={embedURL}
              videoLink={uri}
              mediaLength={parsedMedia.length}
              type="youtube"
              videoId={videoId}
              webViewStyle={{ height: sliderWidth / 1.7 }}
            />
          )
        }

        if (isVimeoLink(uri)) {
          const videoId = extractVimeoID(uri)
          const embedURL = `https://player.vimeo.com/video/${videoId}`
          return (
            <VideoWebView
              key={`vimeo-video-${videoId}`}
              embedURL={embedURL}
              videoLink={uri}
              mediaLength={parsedMedia.length}
              webViewStyle={{ height: sliderWidth / 1.7 }}
            />
          )
        }

        if (isLoomLink(uri)) {
          const embedURL = uri.replace('share', 'embed')
          return (
            <VideoWebView
              key={`video-webview-${embedURL}`}
              embedURL={embedURL}
              videoLink={uri}
              mediaLength={parsedMedia.length}
              webViewStyle={{ height: sliderWidth / 1.7 }}
            />
          )
        }

        return (
          <VideoComponent
            key={`video-component-${uri}`}
            videoLink={uri}
            mediaLength={parsedMedia.length}
          />
        )
      }

      return null
    })
  }

  return (
    <View
      style={{ marginTop: 12, marginBottom: parsedMedia.length > 1 ? 30 : 0 }}
      onLayout={event => setSliderWidth(event.nativeEvent.layout.width)}
    >
      {parsedMedia.length > 1 ? (
        <Pressable accessibilityRole="none">
          <Slider {...settings}>{renderMediaComponents()}</Slider>
        </Pressable>
      ) : null}

      {parsedMedia.length === 1 ? renderMediaComponents() : null}

      {isModalOpen ? (
        <GallerySliderForWebModal
          isModalOpen={isModalOpen}
          key={`slider-sadasdd`}
          setIsModalOpen={setIsModalOpen}
          selectedImage={selectedImage}
          parsedMedia={parsedMedia}
        />
      ) : null}
    </View>
  )
}

export default ImageGalleryWrapper

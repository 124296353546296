import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useMatrixUser } from '@vatom/sdk/react'
import axios from 'axios'

import { matrixServerUrl } from '../../../../constants'
import { useMessageReactions } from '../../../../queries'
import { CommunitiesReactionType } from '../../../../types'
import { useMessageContext } from '../../MessageContext'

import VoteButton from './VoteButton'

type VotesProps = {
  roomId: string
  align: string
  isThread: boolean
}

const Votes = (props: VotesProps) => {
  const { messageEventID } = useMessageContext()
  const { roomId, align, isThread } = props
  const [thumbsUp, setThumbsUp] = useState(false)
  const [thumbsDown, setThumbsDown] = useState(false)

  const { data: matrixUser } = useMatrixUser()

  const accessToken = matrixUser?.access_token
  const userId = matrixUser?.user_id

  const { data: reactions } = useMessageReactions(roomId, messageEventID)

  const likes = reactions?.filter(reaction => reaction.key === 'like') ?? []
  const dislikes = reactions?.filter(reaction => reaction.key === 'dislike') ?? []

  React.useEffect(() => {
    likes.findIndex(like => like.sender === userId) !== -1 ? setThumbsUp(true) : setThumbsUp(false)

    dislikes.findIndex(dislike => dislike.sender === userId) !== -1
      ? setThumbsDown(true)
      : setThumbsDown(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [likes, dislikes])

  const sendEvent = async (type: string) => {
    const dateNow = Date.now()
    await axios.put(
      `${matrixServerUrl}/_matrix/client/r0/rooms/${roomId}/send/m.reaction/${type}.${dateNow}?access_token=${accessToken}`,
      {
        'm.relates_to': {
          rel_type: 'm.annotation',
          event_id: messageEventID,
          key: type
        }
      }
    )
  }

  const removeEvent = async (event: CommunitiesReactionType | undefined) => {
    const dateNow = Date.now()
    if (!event) {
      return null
    }
    const { eventId, key } = event
    await axios.put(
      `${matrixServerUrl}/_matrix/client/r0/rooms/${roomId}/redact/${eventId}/${messageEventID}.${key}.${dateNow}?access_token=${accessToken}`,
      {}
    )
  }

  const handleEvent = async (type: string) => {
    const foundLike = likes.find(like => like.sender === userId)
    const foundDislike = dislikes.find(dislike => dislike.sender === userId)

    if (type === 'like') {
      if (thumbsUp) {
        await removeEvent(foundLike)
      } else {
        await sendEvent('like')
        if (thumbsDown) {
          await removeEvent(foundDislike)
        }
      }
    }

    if (type === 'dislike') {
      if (thumbsDown) {
        removeEvent(foundDislike)
      } else {
        await sendEvent('dislike')
        if (thumbsUp) {
          removeEvent(foundLike)
        }
      }
    }
  }

  return (
    <View
      style={{
        ...styles.votesHolder,
        marginLeft: isThread ? 0 : align === 'indent' ? 55 : 0
      }}
    >
      <VoteButton
        eventType="like"
        isActive={thumbsUp}
        votesCount={likes}
        handleEvent={handleEvent}
      />

      <VoteButton
        eventType="dislike"
        isActive={thumbsDown}
        votesCount={dislikes}
        handleEvent={handleEvent}
      />
    </View>
  )
}

export default Votes

const styles = StyleSheet.create({
  votesHolder: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12
  }
})

import { WebWrapper } from '../../components/WebWrapper'

import SendNFTConfirmation, { ISendNFTConfirmationProps } from './SendNFTConfirmation'

export default (props: ISendNFTConfirmationProps) => {
  return (
    <WebWrapper justifyContent={'center'}>
      <SendNFTConfirmation {...props} />
    </WebWrapper>
  )
}

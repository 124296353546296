import { Animated, TouchableOpacity, ViewStyle } from 'react-native'
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs'
import { Route } from '@react-navigation/native'
import { theme } from '@vatom/wombo'
import { Box, Text, View } from 'native-base'

type TabBarProps = MaterialTopTabBarProps & {
  containerProps?: React.ComponentProps<typeof Box>
  tabItemStyle?: ViewStyle
  tabLabelStyle?: ViewStyle
}

export const TabBar = ({
  state,
  descriptors,
  navigation,
  containerProps,
  tabItemStyle,
  tabLabelStyle
}: TabBarProps) => {
  const getIsFocused = (index: number) => state.index === index

  const onPress = (route: Route<any>, index: number) => {
    const event = navigation.emit({
      type: 'tabPress',
      target: route.key,
      canPreventDefault: true
    })

    if (!getIsFocused(index) && !event.defaultPrevented) {
      // The `merge: true` option makes sure that the params inside the tab screen are preserved
      /* @ts-ignore */
      navigation.navigate({ name: route.name, merge: true })
    }
  }

  const onLongPress = (route: Route<any>) => {
    navigation.emit({
      type: 'tabLongPress',
      target: route.key
    })
  }

  return (
    <Box flexDirection={'row'} flex={1} justifyContent={'center'} {...containerProps}>
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key]
        const label = (
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name
        ) as React.ReactNode

        const isFocused = getIsFocused(index)

        const lightColor = isFocused ? theme.colors.bodyText : theme.colors.lightText
        const darkColor = isFocused
          ? theme.colors.textDarkMode[100]
          : theme.colors.textDarkMode[300]
        const fontWeight = isFocused ? '700' : '400'

        const lightBottomColor = isFocused ? '#000' : 'transparent'
        const darkBottomColor = isFocused ? theme.colors.textDarkMode[100] : 'transparent'

        return (
          <TouchableOpacity
            key={route.key}
            accessibilityRole="button"
            accessibilityState={isFocused ? { selected: true } : {}}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            accessibilityHint="Navigates to tab screen"
            testID={options.tabBarTestID}
            onPress={() => onPress(route, index)}
            onLongPress={() => onLongPress(route)}
            style={{
              paddingHorizontal: 8,
              ...tabItemStyle
            }}
          >
            <Text
              style={{
                // color,
                fontWeight,
                ...tabLabelStyle
              }}
              _light={{
                color: lightColor
              }}
              _dark={{
                color: darkColor
              }}
            >
              {label}
            </Text>
            <View
              _light={{
                borderBottomColor: lightBottomColor
              }}
              _dark={{
                borderBottomColor: darkBottomColor
              }}
              style={{
                borderBottomWidth: 2,
                marginTop: 6,
                marginHorizontal: 4
              }}
            />
          </TouchableOpacity>
        )
      })}
    </Box>
  )
}

import { Region, RegionType } from '@vatom/sdk/core'

import logger from '../logger'
import { useSDK } from '../store'
// Classname is too specific - we should find something else
export const useRegion = (type: RegionType, className: string) => {
  logger.info('useRegion: start')
  const sdk = useSDK()
  const region = sdk.dataPool.region(type)
  return region.regions.find(r => r.className === className) as Region
}

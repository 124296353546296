import { useEffect } from 'react'
import { Platform } from 'react-native'
import { doc, getDocFromServer, getFirestore, onSnapshot } from '@firebase/firestore'
import { FirebaseFirestoreTypes } from '@react-native-firebase/firestore'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Contact, useFirebase } from '@vatom/sdk/react'

import { GetContactPresenceOptions, Presence } from './types'

export const presenceKeys = {
  contactPresence: [{ scope: 'contact-presence' }] as const,
  getContactPresence: (contactId: string) =>
    [{ ...presenceKeys.contactPresence[0], contactId }] as const
}

// Hacky function for checking if firebase is ready on web
const getFireBaseIsReady = (firebaseQueryResult: ReturnType<typeof useFirebase>) => {
  return Platform.OS === 'web'
    ? // @ts-expect-error web types are wrong
      !!Object.values(firebaseQueryResult.data._container.providers).length
    : true
}

const usePresenceWebsocketSubscription = (contact: Contact) => {
  const firebase = useFirebase()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!firebase.data || !getFireBaseIsReady(firebase)) return
    const handleSnapshot = (snapshot: FirebaseFirestoreTypes.DocumentSnapshot<Presence>) => {
      const presence = snapshot.data()
      queryClient.setQueryData(presenceKeys.getContactPresence(contact.id), parsePresence(presence))
    }

    const onError = (error: unknown) => {
      console.error('usePresence onError: ', error)
    }

    const unsubscribe =
      Platform.OS === 'web'
        ? (onSnapshot(
            //@ts-expect-error web interface is different
            doc(getFirestore(firebase.data), 'users_public', `vatominc:${contact.id}`),
            handleSnapshot,
            onError
          ) as () => void)
        : firebase.data
            ?.firestore?.()
            .collection('users_public')
            .doc(`vatominc:${contact.id}`)
            .onSnapshot(handleSnapshot, onError)

    return () => {
      unsubscribe?.()
    }
  }, [contact, firebase, firebase.data, queryClient])
}

export const useGetContactPresence = <T = Presence | null>(
  contact: Contact,
  options?: GetContactPresenceOptions<T>
) => {
  const firebase = useFirebase()
  usePresenceWebsocketSubscription(contact)

  const result = useQuery({
    queryKey: presenceKeys.getContactPresence(contact.id),
    queryFn: async ({ queryKey: [{ contactId }] }) => {
      if (!firebase.data) {
        return null
      }
      const snapshot =
        Platform.OS === 'web'
          ? await getDocFromServer(
              // @ts-expect-error web interface is different
              doc(getFirestore(firebase.data), 'users_public', `vatominc:${contactId}`)
            )
          : await firebase.data
              .firestore()
              .collection('users_public')
              .doc(`vatominc:${contactId}`)
              .get({ source: 'server' })
      const presence = snapshot?.data()

      return parsePresence(presence) ?? null
    },
    enabled: !!firebase.data && options?.enabled && getFireBaseIsReady(firebase),
    ...options
  })

  return result
}

const parsePresence = (presence: Presence | undefined): Presence | undefined => {
  if (!presence) return presence
  const offline = !presence?.presence_date ? true : presence?.presence_date < Date.now() - 30000

  presence.presence_state = offline ? 'offline' : presence?.presence_state ?? 'offline'
  presence.presence_description = offline ? 'Offline' : presence?.presence_description ?? 'Offline'

  return presence
}

import { useMemo } from 'react'
import { Image } from 'react-native'
import { useCommunities } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { Avatar, PressableOpacity, theme } from '@vatom/wombo'
import { Box, Center, Text, useColorModeValue } from 'native-base'

import DefaultAvatar from '../../../assets/buildings-sharp-light.svg'
import { useIsDesktop } from '../../../hooks/useIsDesktop'

const vatomBusinessImage = require('../../../screens/Home/assets/vatom-business-all.png')

export const vatomBusinessAllImage = Image.resolveAssetSource
  ? Image.resolveAssetSource(vatomBusinessImage).uri
  : vatomBusinessImage

type DrawerItemProps = {
  disabled?: React.ComponentProps<typeof PressableOpacity>['disabled']
  onPress: () => void
  image: string
  name: string
  subtitle?: string
  containerProps?: React.ComponentProps<typeof PressableOpacity>
  rightComponent?: React.ReactNode
}

export const DrawerItem = ({
  disabled,
  onPress,
  image,
  name,
  containerProps,
  subtitle,
  rightComponent
}: DrawerItemProps) => {
  const isDesktop = useIsDesktop()
  const avatarBorder = useColorModeValue(
    theme.colors.grayCool[200],
    isDesktop ? theme.colors.grayCool[200] : theme.colors.grayDarkMode[700]
  )
  const hoverBgColor = useColorModeValue(theme.colors.grayCool[50], theme.colors.grayDarkMode[800])

  const avatarFill = useColorModeValue(
    theme.colors.textLightMode[300],
    isDesktop ? theme.colors.textDarkMode[300] : theme.colors.textDarkMode[300]
  )
  return (
    <PressableOpacity
      disabled={disabled}
      onPress={onPress}
      paddingLeft={4}
      paddingRight={4}
      width={'100%'}
      backgroundColor={disabled ? theme.colors.grayCool[100] : undefined}
      _hover={{
        backgroundColor: hoverBgColor
      }}
      _dark={{
        _hover: {
          backgroundColor: theme.colors.brand[500]
        }
      }}
      {...containerProps}
    >
      <Box flexDirection="row" maxWidth={'100%'} alignItems="center" marginY={3}>
        {/* <Avatar
          source={image ? { uri: image } : undefined}
          size={12}
          borderColor={avatarBorder}
          nbAvatarSize={'100%'}
          name={name}
        /> */}
        {image ? (
          <Avatar
            source={{ uri: image }}
            size={12}
            borderColor={avatarBorder}
            nbAvatarSize={'100%'}
          />
        ) : (
          <Center
            size={12}
            borderWidth={1}
            borderRadius={'full'}
            borderColor={avatarBorder}
            _light={{
              backgroundColor: theme.colors.grayCool[50]
            }}
            _dark={{
              backgroundColor: theme.colors.grayDarkMode[700]
            }}
          >
            <DefaultAvatar fill={avatarFill} width={20} height={20} />
          </Center>
        )}

        <Box
          flexDirection={'column'}
          paddingLeft={3}
          justifyItems={'center'}
          justifyContent={'center'}
          height={35}
        >
          <Box flex={1} justifyItems={'center'} justifyContent={'center'}>
            <Text
              fontSize={15}
              lineHeight={20}
              fontFamily="Inter-Medium"
              numberOfLines={1}
              _light={{
                color: theme.colors.textLightMode[600]
              }}
              _dark={{
                color: isDesktop ? theme.colors.textDarkMode[100] : theme.colors.textDarkMode[100]
              }}
            >
              {name}
            </Text>
          </Box>
          {subtitle && (
            <Box flex={1} justifyItems={'center'} justifyContent={'center'}>
              <Text
                fontSize={11}
                lineHeight={15}
                fontFamily="Inter"
                numberOfLines={1}
                _light={{
                  color: theme.colors.textLightMode[300]
                }}
                _dark={{
                  color: isDesktop ? theme.colors.textDarkMode[100] : theme.colors.textDarkMode[300]
                }}
              >
                {subtitle}
              </Text>
            </Box>
          )}
        </Box>
        {rightComponent && rightComponent}
      </Box>
    </PressableOpacity>
  )
}

DrawerItem.Skeleton = () => (
  <Box paddingLeft={4} paddingRight={4} width={'100%'} backgroundColor={theme.colors.white}>
    <Box flexDirection="row" maxWidth={'100%'} alignItems="center" marginY={3} maxHeight={12}>
      <Box borderRadius={999} size={12} backgroundColor={theme.colors.grayCool[100]} />
      <Box
        marginLeft={3}
        fontSize={15}
        width={150}
        height={5}
        backgroundColor={theme.colors.grayCool[100]}
      />
    </Box>
  </Box>
)

type BusinessItemProps = Omit<DrawerItemProps, 'onPress'> & {
  businessId: string
  onPress: (id: string, hasCommunities: boolean) => void
}

export const BusinessItem = ({ businessId, onPress, ...rest }: BusinessItemProps) => {
  const { data: communities } = useCommunities(businessId)

  const { hasCommunities, amount } = useMemo(() => {
    let filtered = []
    if (Array.isArray(communities)) {
      filtered = communities.filter(c => !c.isDefault) ?? []
    }
    return {
      hasCommunities: filtered.length > 0,
      amount: filtered.length
    }
  }, [communities])

  const fixedAmount = amount > 99 ? '99+' : amount
  const label = amount === 1 ? translate('common.community') : translate('common.communities')

  const subtitle = amount > 0 ? `${fixedAmount} ${label}` : undefined

  const onPressBusiness = () => {
    onPress(businessId, hasCommunities)
  }

  return (
    <DrawerItem
      {...rest}
      onPress={onPressBusiness}
      subtitle={subtitle}
      // rightComponent={!hasCommunities ? null : <CommunityChip amount={amount} />}
    />
  )
}

const CommunityChip = ({ amount }: { amount: number }) => {
  const isDesktop = useIsDesktop()
  const fixedAmount = amount > 99 ? '99+' : amount
  const label = amount === 1 ? translate('common.community') : translate('common.communities')

  return (
    <Box
      justifyContent={'center'}
      paddingX={3}
      height={28}
      borderRadius={25}
      _light={{
        backgroundColor: '#0012260A'
      }}
      _dark={{
        backgroundColor: '#D9D9DB1A',
        _web: {
          backgroundColor: isDesktop ? '#0012260A' : '#D9D9DB1A'
        }
      }}
    >
      <Text
        fontSize={13}
        fontFamily={'Inter-Regular'}
        lineHeight={18}
        _light={{
          color: theme.colors.textLightMode[300]
        }}
        _dark={{
          color: theme.colors.textLightMode[100],
          _web: {
            color: isDesktop ? theme.colors.textLightMode[300] : theme.colors.textLightMode[100]
          }
        }}
      >
        {`${fixedAmount} ${label}`}
      </Text>
    </Box>
  )
}

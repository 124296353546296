import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const PlusIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 17 16" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M12.3125 8.25C12.3125 8.52344 12.0977 8.71875 11.8438 8.71875H8.71875V11.8438C8.71875 12.1172 8.50391 12.332 8.25 12.332C7.97656 12.332 7.78125 12.1172 7.78125 11.8438V8.71875H4.65625C4.38281 8.71875 4.1875 8.52344 4.1875 8.26953C4.1875 7.99609 4.38281 7.78125 4.65625 7.78125H7.78125V4.65625C7.78125 4.40234 7.97656 4.20703 8.25 4.20703C8.50391 4.20703 8.71875 4.40234 8.71875 4.65625V7.78125H11.8438C12.0977 7.78125 12.3125 7.99609 12.3125 8.25Z"
    />
  </Icon>
)

import { IStateTreeNode } from 'mobx-state-tree'

import { EventEmitter } from './EventEmitter'

const emitter = new EventEmitter()
/**
 * Adds an emitter property to the node for a convenient
 * and strongly typed way for stores to access other stores.
 */
export const withEventEmitter = (self: IStateTreeNode) => {
  // const emitter = new EventEmitter()
  return {
    actions: {
      emit(eventName: string, ...args: any) {
        return emitter.emit(eventName, ...args)
      },
      on(type: string, callback: (...args: any) => any) {
        return emitter.on(type, callback)
      },
      /** Remove a callback function for an event type. */
      off(type: string, cb: (...args: any) => any) {
        return emitter.off(type, cb)
      },
      /** Add a callback function for an event type - to be fired only once. */
      once(type: string, cb: (...args: any) => any) {
        return emitter.once(type, cb)
      },

      trigger(...args: any) {
        return emitter.trigger(...args)
      },
      /**
       * Returns a promise which is resolved when the next occurrence of a given
       * event type is emitted.
       */
      until(type: string) {
        return emitter.until(type)
      }
    },
    views: {
      get cbs() {
        return []
      },
      /**
       * The root store.
       */
      get emitter(): EventEmitter {
        return emitter
      }
    }
  }
}

import React, { useEffect } from 'react'
import { useGetUserProfile } from '@vatom/sdk/react'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { LoaderView } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'

import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useOnOpenBusinessAnalytics } from '../../hooks/useBusinessAnalytics'
import { useBusinessSelector } from '../../hooks/useBusinessSelector'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../hooks/useIsDesktop'
import { useLastVisitedRouteMutation } from '../../hooks/useLastVisitedRoute'
import { TabRoutes } from '../../navigators'
import { useWalletSdkBusiness } from '../../utils/WalletSdk/hooks'
import { useTabNavConfig } from '../Home/hooks/useTabNavConfig'
import { ThemedHeaderWrapper } from '../Home/partials/ThemedHeader'

// import BusinessHeader from './Partials/BusinessHeader'
// import { BusinessTabNavigator, businessTabRoutes } from './Partials/BusinessTabNavigator'
// import BusinessTokens from './Partials/BusinessTokens'
import WebView from './Partials/WebView'
import { WebBusinessProvider } from './WebBusinessProvider'

const BusinessWebView = observer(() => {
  const { business, isLoading } = useBusinessSelector()
  const { businessId: sdkBusinessId } = useWalletSdkBusiness()

  const { tabsConfig } = useTabNavConfig()
  const linkWebView = tabsConfig[TabRoutes.Home]?.link ?? ''

  const { brandTheme, pageTheme } = useBusinessTheme()
  const lastVisitedRoute = useGetUserProfile({
    staleTime: 0,
    refetchOnWindowFocus: false,
    retry: false,
    select: user => {
      return user.lastVisitedRoute ?? ''
    }
  })
  const setLastVisitedRoute = useLastVisitedRouteMutation()
  useEffect(() => {
    if (business && lastVisitedRoute.data !== `/b/${business.id}`) {
      setLastVisitedRoute(business.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business, lastVisitedRoute.data])

  const { config, isEmbedded } = useVatomWalletSdkStore()

  const isDesktop = useIsDesktop()

  useOnOpenBusinessAnalytics()

  const shouldRenderHeader =
    !isEmbedded ||
    (isEmbedded && !sdkBusinessId) ||
    (isEmbedded && !config?.pageConfig?.features?.businessHeader?.hideHeader)

  if (!business || isLoading) {
    return <LoaderView />
  }

  return (
    <WebBusinessProvider>
      <ScreenWrapper
        unsafe
        screenBackgroundColor={brandTheme?.background ?? pageTheme.background}
        statusBarBackgroundColor={brandTheme?.background ?? pageTheme.background}
      >
        {shouldRenderHeader ? !isDesktop && <ThemedHeaderWrapper /> : null}
        {/* {shouldRenderHeader
          ? !isDesktop && (
              <WebWrapper
                flex={undefined}
                // marginBottom={shouldRenderHeader ? getHeaderHeight() : 0}
              >
                <ThemedHeaderWrapper />
              </WebWrapper>
            )
          : null} */}

        <WebView shouldRenderHeader={shouldRenderHeader} link={linkWebView} />
      </ScreenWrapper>
    </WebBusinessProvider>
  )
})

export { BusinessWebView }

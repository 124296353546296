import React, { useState } from 'react'
import { TextInput } from 'react-native'
import { Box, IInputProps, Image, Pressable } from 'native-base'

import { icons } from './icons'

import './index.web.css'

export type InputFieldProps = IInputProps & {
  hasDelete?: boolean
  isDark?: boolean
}

export const InputField = (props: InputFieldProps) => {
  const [ShowDelete, setShowDelete] = React.useState<boolean>(false)
  const [inputText, setInputText] = React.useState<string>(props.defaultValue || '')
  const { leftElement, rightElement, isDark } = props
  const [inputStyles, setInputStyles] = useState({
    padding: 10,
    borderColor: 'rgb(196, 196, 196)',
    borderWidth: 2,
    fontSize: 16,
    borderRadius: 3,
    backgroundColor: 'rgb(240, 242, 244)',
    paddingLeft: leftElement ? 32 : 10,
    paddingRight: rightElement ? 32 : 10,
    color: 'black'
  })

  const { onChangeText, value, style } = props

  const clearInputField = () => {
    setInputText('')
  }

  const onChange = (txt: string) => {
    if (onChangeText) {
      onChangeText(txt)
    }
    setInputText(txt)
  }

  const onFocus = () => {
    setShowDelete(true)
    setInputStyles({
      ...inputStyles,
      borderColor: 'rgb(212,61,3)',
      backgroundColor: 'rgba(212,61,3,0.1)',
      color: isDark ? 'white' : 'black'
    })
  }

  const onBlur = () => {
    setShowDelete(false)
    setInputStyles({
      ...inputStyles,
      borderColor: 'rgb(196, 196, 196)',
      backgroundColor: 'rgb(240, 242, 244)',
      color: 'black'
    })
  }

  return (
    <Box justifyContent="center">
      {leftElement ? <Box position="absolute">{leftElement}</Box> : null}
      {rightElement ? (
        <Box position="absolute" right={0}>
          {rightElement}
        </Box>
      ) : null}

      <TextInput
        accessibilityLabel="Text input field"
        accessibilityHint="text input"
        value={value || inputText}
        onFocus={() => onFocus()}
        onBlur={() => onBlur()}
        onChangeText={e => onChange(e)}
        placeholder={props.placeholder || ''}
        placeholderTextColor="rgb(196, 196, 196)"
        style={{
          ...inputStyles,
          ...style
        }}
      />
      {props.hasDelete === true && ShowDelete && inputText.length !== 0 && (
        <Pressable
          onPress={clearInputField}
          pb="10px"
          accessibilityRole="button"
          position="absolute"
          alignSelf="flex-end"
        >
          <Image
            alt="delete"
            accessibilityIgnoresInvertColors
            size="16px"
            position="absolute"
            source={icons.delete}
            alignSelf="flex-end"
          />
        </Pressable>
      )}
    </Box>
  )
}

export default InputField

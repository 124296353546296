import {
  useGetAlgoliaContacts,
  useGetDeviceVatomContacts,
  useGetFollowerPeople,
  useGetFollowingPeople,
  useGetRecentContacts,
  useGetTokenContacts
} from '@vatom/sdk/react'

import { Contact, ContactSources, Destination, Filters } from './types'

const removeUndefineds = (maybeContact: Array<Contact | undefined>): Contact[] => {
  return maybeContact.filter(contact => contact !== undefined) as Contact[]
}

export const useGetContacts = (destination?: Destination, filters?: Filters) => {
  const tokenContacts = useGetTokenContacts(destination)
  // const deviceContacts = useGetDeviceVatomContacts()
  const algoliaContacts = useGetAlgoliaContacts(destination, filters?.tokenType)
  const recentContacts = useGetRecentContacts()
  const relFollowingContacts = useGetFollowingPeople(destination)
  const relFollowerContacts = useGetFollowerPeople(destination)

  const tokenHistoryContacts = removeUndefineds(tokenContacts.map(contact => contact.data))
  // - @ts-expect-error this needs a deeper look. The initial fix I can think of is to make sure the the converter inside the hook should convert the updatedAt: string from user to updatedAt: number
  // const deviceVatomContacts = removeUndefineds(deviceContacts.map(contact => contact.data))
  const followingContacts = removeUndefineds(relFollowingContacts.map(contact => contact.data))
  const followerContacts = removeUndefineds(relFollowerContacts.map(contact => contact.data))
  const recentContactsData = recentContacts.data
  const algoliaContactsData = algoliaContacts.data

  const allContacts = [
    ...(filters?.sources?.includes(ContactSources.recents) && recentContactsData?.length
      ? recentContactsData
      : []),
    ...(filters?.sources?.includes(ContactSources.userdirectory) && algoliaContactsData?.length
      ? algoliaContactsData
      : []),
    ...(filters?.sources?.includes(ContactSources.tokenhistory) ? tokenHistoryContacts : []),
    // ...(filters?.sources?.includes(ContactSources.devicecontacts) ? deviceVatomContacts : []),
    ...(filters?.sources?.includes(ContactSources.following) ? followingContacts : []),
    ...(filters?.sources?.includes(ContactSources.followers) ? followerContacts : [])
  ]

  const uniqueContacts = removeDuplicateContacts(allContacts)
  const sortedContacts = uniqueContacts.sort((a, b) => {
    // Sort by recent
    if (b.updatedAt && a.updatedAt) {
      return b.updatedAt - a.updatedAt
    }
    if (b.updatedAt) return 1
    if (a.updatedAt) return -1
    if (b.source === ContactSources.recents) return 1
    if (a.source === ContactSources.recents) return -1

    if (b.source === ContactSources.userdirectory) return 1
    if (a.source === ContactSources.userdirectory) return -1

    // Sort by name
    return (a.name || '').localeCompare(b.name || '')
  })

  return {
    data: sortedContacts,
    isLoading:
      (filters?.sources?.includes(ContactSources.recents) && recentContacts.isLoading) ||
      (filters?.sources?.includes(ContactSources.userdirectory) && algoliaContacts.isLoading) ||
      (filters?.sources?.includes(ContactSources.tokenhistory) &&
        tokenContacts.some(contact => contact.isLoading)) ||
      // (filters?.sources?.includes(ContactSources.devicecontacts) &&
      //   deviceContacts.some(contact => contact.isLoading)) ||
      (filters?.sources?.includes(ContactSources.following) &&
        relFollowingContacts.some(contact => contact.isLoading)) ||
      (filters?.sources?.includes(ContactSources.followers) &&
        relFollowerContacts.some(contact => contact.isLoading)) ||
      false,
    refetch: async () => {
      recentContacts.refetch()
      algoliaContacts.refetch()
    }
  }
}

export const removeDuplicateContacts = (contacts: Contact[]) => {
  const uniqueContacts = contacts.reduce((acc, contact) => {
    const existsing = acc.get(contact.id)
    const updatedAt = existsing?.updatedAt ?? contact.updatedAt
    acc.set(contact.id, { ...contact, updatedAt })
    return acc
  }, new Map<string, Contact>())

  return Array.from(uniqueContacts.values())
}

import React, { useCallback, useMemo, useRef, useState } from 'react'
import { FlatList, Keyboard, Platform, Pressable, TextInput, ViewStyle } from 'react-native'
import Animated, {
  SharedValue,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Ionicons } from '@expo/vector-icons'
import {
  BottomSheetFlatList,
  BottomSheetModal,
  BottomSheetModalProvider
} from '@gorhom/bottom-sheet'
import { useNavigation } from '@react-navigation/native'
import {
  Contact,
  ContactSources,
  DestinationTypes,
  getMatrixUser,
  useConfig,
  useCreateLocalRoom,
  useGetContacts,
  useGetDmRooms,
  useGetUsersWithDmRooms,
  validateDestination
} from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { Avatar, Loader, PressableOpacity, theme } from '@vatom/wombo'
import { Box, Center, Input, Text, useColorModeValue } from 'native-base'
import { useDebounce, useThrottledCallback } from 'use-debounce'

import { BottomSheetCustomBackdrop } from '../../components/BottomSheetCustomBackdrop'
import { FAB } from '../../components/FAB'
import { SearchIcon } from '../../components/Icons'
import { HeaderButtonClose, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenHeaderWrapper } from '../../components/ScreenHeaderWrapper'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import { useMaxWidth } from '../../components/WebWrapper'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../hooks/useIsDesktop'
import { useLayoutScrollHandler } from '../../hooks/useLayoutScrollHandler'
import { AppRoutes, MainTabScreenProps } from '../../navigators'
import { TabBarHeight } from '../Home/components/TabBar'
import { DrawerSelectorButton } from '../Home/partials/DrawerSelectorButton'
import useSpaceFromNavParams from '../Space/useSpaceFromNavParams'

import { DmListItem, DmListSpaceItem } from './components/DmListItem'

const isWeb = Platform.OS === 'web'
const PlatformFlatList = isWeb ? FlatList : BottomSheetFlatList

export type DmListProps = MainTabScreenProps<'DMs'>
export function DmListScreen(props: DmListProps) {
  const { spaceHandle } = props.route?.params || {}
  const { pageTheme } = useBusinessTheme()

  const isDesktop = useIsDesktop()

  // const { config, isEmbedded } = useVatomWalletSdkStore()
  const config = useConfig()
  const hideNavigation = config.features.hideNavigation

  const bottomSheetModalRef = useRef<BottomSheetModal>(null)

  const headerIconColor = useColorModeValue(
    theme.colors.textLightMode[900],
    theme.colors.textDarkMode[100]
  )
  const addIconColor = useColorModeValue(theme.colors.white, theme.colors.textDarkMode[100])

  const showSearch = useSharedValue(false)

  const onPressSearch = useThrottledCallback(
    () => {
      console.log('LOG: > onPressSearch!!')
      showSearch.value = !showSearch.value
    },
    300,
    {
      leading: true
    }
  )

  const onPressNew = useThrottledCallback(
    () => {
      bottomSheetModalRef.current?.present()
    },
    300,
    {
      leading: true
    }
  )

  return (
    <BottomSheetModalProvider>
      <ScreenWrapper
        unsafe
        screenBackgroundColor={pageTheme.background}
        statusBarBackgroundColor={undefined}
      >
        {hideNavigation ? null : (
          <ScreenHeaderWrapper
            containerProps={{}}
            centerContainerProps={{}}
            centerComponent={
              isDesktop ? null : (
                <Title preset="h5" variant="semibold" _dark={{ color: 'white' }}>
                  {translate('dms.messages')}
                </Title>
              )
            }
            rightContainerProps={{
              paddingRight: 1
            }}
            rightComponent={
              <>
                <ScreenHeaderButton
                  onPress={onPressSearch}
                  containerProps={{
                    height: 44,
                    width: 44
                  }}
                >
                  <SearchIcon size={8} fill={headerIconColor} />
                </ScreenHeaderButton>
                {/* <ScreenHeaderButton
                  onPress={onPressNew}
                  containerProps={{
                    height: 44,
                    width: 44
                  }}
                >
                  <Ionicons name="add" size={24} color={headerIconColor} />
                </ScreenHeaderButton> */}
              </>
            }
            leftContainerProps={{}}
            leftComponent={<DrawerSelectorButton />}
          />
        )}
        <DmList spaceHandle={spaceHandle} showSearch={showSearch} />
        <UserListModal ref={bottomSheetModalRef} />
        <FAB>
          <Pressable accessibilityRole="button" onPress={onPressNew}>
            <Center
              borderRadius={999}
              size={60}
              _light={{
                backgroundColor: theme.colors.systemColorsLight.orange
              }}
              _dark={{
                backgroundColor: theme.colors.systemColorsDark.orange
              }}
            >
              <Ionicons name="add" size={28} color={addIconColor} style={{ marginLeft: 2 }} />
            </Center>
          </Pressable>
        </FAB>
      </ScreenWrapper>
    </BottomSheetModalProvider>
  )
}

const searchBarHeight = 50

const DmList = React.memo(function ({
  spaceHandle,
  showSearch
}: {
  spaceHandle?: string
  showSearch: SharedValue<boolean>
}) {
  const navigation = useNavigation()
  const isDesktop = useIsDesktop()

  const scrollRef = useRef<Animated.ScrollView | null>(null)
  const { getHeaderHeight, getFooterHeight } = useLayoutScrollHandler()

  const { space, isLoading: isLoadingSpace } = useSpaceFromNavParams({
    spaceHandle
  })
  // const { config, isEmbedded } = useVatomWalletSdkStore()
  const config = useConfig()
  const hideNavigation = config.features.hideNavigation

  const { data: dmRooms, isLoading, isError } = useGetDmRooms()
  const [filterString, setFilterString] = useState('')

  const roomsFiltered = useMemo(() => {
    if (filterString === '') {
      return dmRooms ?? []
    }
    // TODO: Add filter dm rooms by user name (or email?)
    return dmRooms ?? []
  }, [dmRooms, filterString])

  const onPressRoom = useCallback(
    (roomId: string) => {
      console.log('LOG: DM PRESSED!! RoomId', roomId)
      navigation.navigate(AppRoutes.DmScreen, {
        roomId
      })
    },
    [navigation]
  )

  const onPressSpace = useCallback(() => {
    navigation.navigate(AppRoutes.Room, {
      business: space?.businessId ?? '',
      spaceId: space?.id ?? ''
    })
  }, [navigation, space])

  const initialOffSet = !isDesktop ? searchBarHeight : 0

  const offsetTop = !isDesktop ? getHeaderHeight() : undefined

  const isScrolling = useSharedValue(false)

  const translationY = useSharedValue(0)

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: event => {
      translationY.value = event.contentOffset.y
      if (event.contentOffset.y < 0) {
        showSearch.value = true
        return
      }
      if (showSearch.value === true && event.contentOffset.y > searchBarHeight * 0.5) {
        showSearch.value = false
        return
      }
    },
    onBeginDrag: e => {
      isScrolling.value = true
    },
    onEndDrag: e => {
      isScrolling.value = false
    }
  })

  const searchAnimatedStyles = useAnimatedStyle(() => {
    if (isDesktop) {
      return {
        height: searchBarHeight
      }
    }
    const offset = showSearch.value ? 0 : -searchBarHeight
    return {
      height: showSearch.value ? searchBarHeight : 0,
      transform: [{ translateY: withTiming(offset) }]
    }
  }, [])

  const renderDmRooms = useMemo(() => {
    if (!roomsFiltered) {
      return null
    }
    return roomsFiltered.map(({ roomId, userIds, lastEvent }, index) => (
      <Box key={`dm-list-${roomId}-${index}`}>
        <DmListItem
          roomId={roomId}
          userIds={userIds}
          onPress={() => onPressRoom(roomId)}
          scrollRef={scrollRef}
          lastEvent={lastEvent}
        />
      </Box>
    ))
  }, [roomsFiltered, onPressRoom])

  return (
    <Box paddingTop={!hideNavigation ? offsetTop : undefined} flex={1}>
      {!dmRooms && isLoading && !isError && (
        <Box alignItems={'center'} paddingY={40}>
          <Loader />
        </Box>
      )}
      {!isLoading && isError && (
        <Box alignItems={'center'}>
          <Text>Error </Text>
        </Box>
      )}

      {dmRooms && (
        <>
          <Animated.View
            style={[
              {
                width: '100%',
                zIndex: 2
              },
              searchAnimatedStyles
            ]}
          >
            <Box
              style={{
                height: searchBarHeight
              }}
              paddingX={4}
              _android={{ paddingTop: 2 }}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Input
                placeholder={translate('common.search')}
                accessibilityHint="Search"
                accessibilityLabel="Search"
                defaultValue={filterString}
                onChangeText={setFilterString}
                clearButtonMode="always"
                fontSize={15}
                width={'100%'}
                _light={{
                  backgroundColor: theme.colors.white,
                  borderColor: theme.colors.grayCool[300],
                  placeholderTextColor: theme.colors.textLightMode[100]
                }}
                _dark={{
                  backgroundColor: theme.colors.grayDarkMode[800],
                  borderColor: theme.colors.grayDarkMode[500],
                  placeholderTextColor: theme.colors.textDarkMode[600]
                }}
              />
            </Box>
          </Animated.View>
          <Animated.ScrollView
            ref={scrollRef}
            // onScroll={scrollHandler}
            decelerationRate={'normal'}
            // contentOffset={{
            //   x: 0,
            //   y: initialOffSet
            // }}
            // snapToOffsets={[searchBarHeight]}
            // snapToStart={false}
            // snapToEnd={false}
            scrollEventThrottle={32}
            contentContainerStyle={[
              {
                flexGrow: 1,
                paddingBottom: getFooterHeight() + getHeaderHeight()
              }
            ]}
          >
            {!isLoadingSpace && space && space.matrixRoomId && (
              <DmListSpaceItem
                title={space.displayName}
                picture={space.cover as string}
                onPress={() => onPressSpace()}
              />
            )}

            {renderDmRooms}
          </Animated.ScrollView>
        </>
      )}
    </Box>
  )
})

// TODO: add invite contact to vatom
const UserListModal = React.memo(
  React.forwardRef<BottomSheetModal>(function (_, forwardedRef) {
    const navigation = useNavigation()
    const maxWidth = useMaxWidth()
    const insets = useSafeAreaInsets()
    const { createLocalRoom } = useCreateLocalRoom()

    const inputRef = useRef<TextInput | null>(null)

    const [showFilter, setShowFilter] = useState(false)
    const [searchString, setSearchString] = useState('')
    const [lastSearchString] = useDebounce(searchString, 150)

    const headerIconColor = useColorModeValue(
      theme.colors.textLightMode[900],
      theme.colors.textDarkMode[100]
    )
    const bottomSheetStyles = useColorModeValue(
      {
        container: {
          backgroundColor: theme.colors.white
        },
        indicator: {
          backgroundColor: theme.colors.grayCool[400]
        }
      },
      {
        container: {
          backgroundColor: theme.colors.grayDarkMode[900]
        },
        indicator: {
          backgroundColor: theme.colors.grayDarkMode[100]
        }
      }
    )

    const filterAnimatedStyle = useAnimatedStyle(() => {
      return {
        height: withTiming(showFilter ? searchBarHeight : 0),
        transform: [{ translateY: 0 }]
      }
    }, [showFilter])

    const { data: usersWithDm } = useGetUsersWithDmRooms()

    const destination = validateDestination(lastSearchString, true)

    const contacts = useGetContacts(
      {
        type: DestinationTypes.Id,
        value: lastSearchString ? destination.value : '',
        validatedValue: lastSearchString ? destination.validatedValue : ''
      },
      {
        sources: [
          ContactSources.followers,
          ContactSources.following,
          ContactSources.tokenhistory,
          ContactSources.recents,
          ContactSources.devicecontacts,
          lastSearchString ? ContactSources.userdirectory : ''
        ]
      }
    )
    // TODO: verify if this is needed
    // useRefreshOnFocus(contacts.refetch)

    const usersContact = useMemo(() => {
      return (
        contacts.data?.filter(contact => {
          const matrixUser = getMatrixUser(contact.id)
          return !usersWithDm?.includes(matrixUser)
        }) ?? []
      )
    }, [contacts, usersWithDm])

    const onPressClose = useThrottledCallback(
      () => {
        Keyboard.dismiss()
        // reset filter
        setSearchString('')
        // @ts-ignore
        forwardedRef?.current?.close()
      },
      300,
      { leading: true }
    )
    const onPressFilter = useThrottledCallback(
      () => {
        if (!showFilter) {
          setShowFilter(true)
          inputRef?.current?.focus()
        } else {
          setShowFilter(false)
          Keyboard.dismiss()
        }
      },
      300,
      { leading: true }
    )

    const onPressContact = useThrottledCallback(
      (user: Contact) => {
        const members = [
          {
            userId: getMatrixUser(user.id),
            displayname: user.name,
            avatar_url: user?.avatarUrl
          }
        ]
        console.log('LOG: DmList > UserList > createLocalRoom > members:', members)
        // Create local room
        const localRoomId = createLocalRoom({ members })
        onPressClose()

        navigation.navigate(AppRoutes.DmScreen, {
          roomId: localRoomId
        })
      },
      300,
      { leading: true }
    )

    return (
      <BottomSheetModal
        ref={forwardedRef}
        index={0}
        snapPoints={['70%', '94%']}
        enablePanDownToClose={true}
        enableDismissOnClose={true}
        handleIndicatorStyle={{
          backgroundColor: bottomSheetStyles.indicator.backgroundColor
        }}
        handleStyle={{
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          backgroundColor: bottomSheetStyles.container.backgroundColor
        }}
        backgroundStyle={{
          backgroundColor: bottomSheetStyles.container.backgroundColor
        }}
        containerStyle={{
          backgroundColor: 'transparent'
        }}
        style={{
          maxWidth: isWeb ? maxWidth : undefined,
          backgroundColor: 'transparent',
          marginHorizontal: isWeb ? 'auto' : 4
        }}
        backdropComponent={BottomSheetCustomBackdrop}
        bottomInset={isWeb ? 0 : TabBarHeight + insets.bottom}
        detached={isWeb ? false : true}
      >
        <Box
          flexDirection={'row'}
          marginX={3}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <ScreenHeaderButton onPress={onPressClose}>
            <HeaderButtonClose color={headerIconColor} />
          </ScreenHeaderButton>
          <Title preset="h5" variant="semibold">
            {translate('dms.sendMessage')}
          </Title>
          <ScreenHeaderButton onPress={onPressFilter}>
            <SearchIcon color={headerIconColor} size={8} />
          </ScreenHeaderButton>
        </Box>

        <Animated.View
          style={[
            {
              marginTop: 12,
              height: searchBarHeight,
              overflow: 'hidden'
            },
            filterAnimatedStyle
          ]}
        >
          <Box
            paddingX={4}
            _web={{ marginTop: 2 }}
            _android={{ paddingTop: 2 }}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Input
              ref={inputRef}
              placeholder={translate('common.filter')}
              accessibilityHint="Filter"
              accessibilityLabel="Filter"
              defaultValue={searchString}
              onChangeText={setSearchString}
              clearButtonMode="always"
              fontSize={15}
              _web={{ width: '100%' }}
              _light={{
                backgroundColor: theme.colors.white,
                borderColor: theme.colors.grayCool[300],
                placeholderTextColor: theme.colors.textLightMode[100]
              }}
              _dark={{
                backgroundColor: theme.colors.grayDarkMode[800],
                borderColor: theme.colors.grayDarkMode[500],
                placeholderTextColor: theme.colors.textDarkMode[600]
              }}
            />
          </Box>
        </Animated.View>

        <Box flex={1} marginTop={0}>
          {!contacts?.isLoading && (
            <UserList
              users={usersContact}
              onPress={onPressContact}
              listContainerStyle={{
                paddingBottom: TabBarHeight
              }}
            />
          )}
        </Box>
      </BottomSheetModal>
    )
  })
)

const UserList = React.memo(function ({
  users,
  onPress,
  listStyle,
  listContainerStyle
}: {
  users: Contact[]
  onPress: (user: Contact) => void
  listStyle?: ViewStyle
  listContainerStyle?: ViewStyle
}) {
  const { pageTheme } = useBusinessTheme()

  return (
    <Box flex={1}>
      <PlatformFlatList
        data={users}
        style={listStyle}
        contentContainerStyle={[
          {
            paddingHorizontal: 16
          },
          listContainerStyle
        ]}
        renderItem={({ item }) => {
          return (
            <Box key={item.id}>
              <PressableOpacity onPress={() => onPress(item)}>
                <Box alignItems="center" flexDir="row" h={62} py={3}>
                  <Avatar size={44} url={item?.avatarUrl} />
                  <Box ml={3} flex={1}>
                    <Text color={pageTheme.text} fontFamily="Inter-Bold" fontSize={15}>
                      {item.name}
                    </Text>
                    {item?.preferred_username ? (
                      <Text
                        color={theme.colors.textLightMode[100]}
                        fontFamily="Inter-Bold"
                        fontSize={11}
                        fontWeight={400}
                        lineHeight={15}
                      >
                        @{item.preferred_username}
                      </Text>
                    ) : null}
                  </Box>
                </Box>
              </PressableOpacity>
            </Box>
          )
        }}
      />
    </Box>
  )
})

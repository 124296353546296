import React from 'react'
import { useWindowDimensions } from 'react-native'
import { theme } from '@vatom/wombo'
import { Divider } from 'native-base'
import { InterfaceDividerProps } from 'native-base/lib/typescript/components/composites/Divider/types'

export const FullScreenDivider = (props: InterfaceDividerProps) => {
  const { width } = useWindowDimensions()
  return (
    <Divider
      w={width}
      _light={{
        backgroundColor: theme.colors.grayCool[300]
      }}
      _dark={{
        backgroundColor: theme.colors.grayDarkMode[800]
      }}
      mx="-16px"
      height="1px"
      {...props}
    />
  )
}

export default FullScreenDivider

import React, { useState } from 'react'
import { StyleSheet, TextInput, TouchableOpacity, View } from 'react-native'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'

import { useCommunitiesTheme } from '../../../themes'
import { useStoreContext } from '../../MSTContextProvider'
import AddNewChoiceIcon from '../assets/poll_add_new_choice_icon2.svg'
import DeleteChoiceIcon from '../assets/poll_delete_choice_icon2.svg'
import { PollChoice } from '../stores'

const AddChoiceButton = observer(() => {
  const communitiesTheme = useCommunitiesTheme()
  const store = useStoreContext()

  return (
    <TouchableOpacity
      accessibilityRole="button"
      onPress={() => store.addNewPollChoice()}
      style={[
        styles.addButton,
        { borderWidth: 1, borderColor: communitiesTheme.pollAddDeleteButton.border }
      ]}
    >
      <AddNewChoiceIcon fill={communitiesTheme.pollAddDeleteButton.icon} />
    </TouchableOpacity>
  )
})

const DeleteChoiceButton = observer(({ inputIndex }: { inputIndex: number }) => {
  const store = useStoreContext()
  const communitiesTheme = useCommunitiesTheme()

  return (
    <TouchableOpacity
      accessibilityRole="button"
      onPress={() => store.removePollChoice(inputIndex)}
      style={[
        styles.addButton,
        { borderWidth: 1, borderColor: communitiesTheme.pollAddDeleteButton.border }
      ]}
    >
      <DeleteChoiceIcon fill={communitiesTheme.pollAddDeleteButton.icon} />
    </TouchableOpacity>
  )
})

const ModalPollChoice = observer(
  ({ input, style }: { input: Instance<typeof PollChoice>; style?: object }) => {
    const store = useStoreContext()

    const [isTextInputFocused, setTextInputFocused] = useState(false)

    const communitiesTheme = useCommunitiesTheme()
    const numberOfChoices = store.pollChoices.length
    const lastChoiceIndex = store.pollChoices[numberOfChoices - 1].index

    return (
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <TextInput
          accessibilityLabel="Text input field"
          accessibilityHint="Text input"
          style={[
            { ...styles.choiceInput, ...style },
            { color: communitiesTheme.pollChoice.text },
            {
              borderColor:
                isTextInputFocused === true
                  ? communitiesTheme.pollChoice.activeBorder
                  : communitiesTheme.pollChoice.border
            }
          ]}
          placeholder={`Choice ${input.index}`}
          placeholderTextColor={communitiesTheme.pollChoice.placeholder}
          value={input?.value}
          onChangeText={input?.setValue}
          multiline={true}
          textAlignVertical="top"
          onFocus={() => setTextInputFocused(true)}
          onSubmitEditing={() => setTextInputFocused(false)}
          onEndEditing={() => setTextInputFocused(false)}
        />

        {input.index === lastChoiceIndex ? <AddChoiceButton /> : null}
        {numberOfChoices > 2 && input.index > 1 && input.index !== lastChoiceIndex ? (
          <DeleteChoiceButton inputIndex={input.index} />
        ) : null}
      </View>
    )
  }
)

const styles = StyleSheet.create({
  choiceInput: {
    height: 40,
    borderWidth: 1,
    borderRadius: 4,
    marginBottom: 12,
    flex: 1,
    marginRight: 48,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 12
  },
  addButton: {
    height: 40,
    width: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 0
  }
})

export default ModalPollChoice

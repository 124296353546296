import React from 'react'
import { useRoute } from '@react-navigation/native'
import { Box } from 'native-base'

import { BusinessCurrency } from '../../../components/BusinessCurrency'
import { ScreenHeaderWrapper } from '../../../components/ScreenHeaderWrapper'
import { Title } from '../../../components/Title'
import { useBusinessSelector } from '../../../hooks/useBusinessSelector'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../../hooks/useIsDesktop'
import { TabRoutes } from '../../../navigators'
import { useSiteHeader } from '../../Business/WebBusinessProvider/useWebBusinessProvider/useSiteHeader'

import { DrawerSelectorButton } from './DrawerSelectorButton'

const ThemedHeaderWrapper = (props: React.ComponentProps<typeof ScreenHeaderWrapper>) => {
  const { business } = useBusinessSelector()
  const isDesktop = useIsDesktop()
  const { pageTheme } = useBusinessTheme()

  const route = useRoute()
  const currentRouteName = route.name

  const { title } = useSiteHeader()
  const customTitle = currentRouteName === TabRoutes.Home ? title : ''
  // const style = {
  //   background: brandTheme?.primary ?? pageTheme.background,
  //   text: brandTheme?.primaryText ?? pageTheme.active
  // }
  const style = {
    background: pageTheme.background,
    text: pageTheme.active
  }

  const HeaderTitle = () => (
    <Box>
      <Title preset="h5" variant="semibold">
        {customTitle}
      </Title>
    </Box>
  )

  const CenterComponent = () => {
    return (
      <Box flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
        {customTitle ? <HeaderTitle /> : <BusinessCurrency businessId={business?.id} />}
      </Box>
    )
  }

  const RightComponent = () => {
    return <Box></Box>
  }

  return (
    <ScreenHeaderWrapper
      showInDesktop={props.showInDesktop || false}
      containerProps={{
        backgroundColor: style.background,
        position: 'relative'
      }}
      centerContainerProps={{
        justifyContent: 'center',
        flex: 1
      }}
      centerComponent={props?.centerComponent ? props.centerComponent : <CenterComponent />}
      rightContainerProps={{
        // Force push to the right to allow the centerComponent be centered
        right: 2,
        zIndex: 2
      }}
      rightComponent={props?.rightComponent ? props?.rightComponent : <RightComponent />}
      leftContainerProps={{
        left: 1,
        zIndex: 2
      }}
      leftComponent={!isDesktop && <DrawerSelectorButton />}
    />
  )
}

export { ThemedHeaderWrapper }

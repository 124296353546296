import { theme } from '@vatom/wombo'
import { Center, useColorModeValue } from 'native-base'

import { ArrowRightIcon } from '../../../components/Icons/ArrowRightIcon'

export const RightArrow = () => {
  const iconColor = useColorModeValue(
    theme.colors.textLightMode[600],
    theme.colors.textDarkMode[300]
  )

  return (
    <Center>
      <ArrowRightIcon size={4} fill={iconColor} />
    </Center>
  )
}

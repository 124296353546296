import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const BlockIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 16 16" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8ZM2.6875 3.4375C1.625 4.65625 1 6.25 1 8C1 11.875 4.125 15 8 15C9.75 15 11.3438 14.375 12.5625 13.3125L2.6875 3.4375ZM15 8C15 4.15625 11.8438 1 8 1C6.21875 1 4.625 1.65625 3.40625 2.71875L13.2812 12.5938C14.3438 11.375 15 9.78125 15 8Z"
    />
  </Icon>
)

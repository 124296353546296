import { Entypo } from '@expo/vector-icons'
import { Contact } from '@vatom/sdk/react'
import { Avatar, Loader, PressableOpacity, theme } from '@vatom/wombo'
import { Box, FlatList, Icon, Image, Text } from 'native-base'

const NoResult = ({ searchString }: { searchString: string }) => {
  return (
    <Box alignItems="center" marginTop="105px">
      <Image
        accessibilityIgnoresInvertColors={true}
        source={require('../assets/no-result.png')}
        height="182px"
        width="170px"
        marginBottom="20px"
        alt="no-result"
      />
      <Text marginBottom={6} fontFamily="Inter-Regular" fontSize={15} textAlign="center">
        We did not find <Text fontFamily="Inter-Bold">{searchString}</Text> in our records
      </Text>
    </Box>
  )
}

type ResultsListProps = {
  onPress: (item: Contact) => void
  searchMode: boolean
  data: Contact[]
  isLoading: boolean
  searchString: string
  lastSearchString: string
}

export const ResultsList = ({
  onPress,
  searchMode,
  data = [],
  isLoading,
  searchString,
  lastSearchString
}: ResultsListProps) => {
  const displayResults = (!isLoading && searchMode) || (!isLoading && lastSearchString)

  const NoResultsFound = () =>
    !isLoading && lastSearchString === searchString ? (
      <NoResult searchString={searchString} />
    ) : null

  return displayResults ? (
    <Box flex={1} mt={4}>
      {isLoading && (
        <Box marginY={4}>
          <Loader size={12} />
        </Box>
      )}

      {!isLoading && data.length > 0 ? (
        <>
          <Text
            fontFamily="Inter-Bold"
            marginBottom={2}
            fontSize={22}
            fontWeight="700"
            _light={{
              color: theme.colors.textLightMode[900]
            }}
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
          >
            Results
          </Text>
          <FlatList
            flex={1}
            data={data}
            renderItem={({ item, index }) => <ResultListItem item={item} onPress={onPress} />}
            showsVerticalScrollIndicator={false}
            keyExtractor={contact => `results-${contact.id}`}
            keyboardShouldPersistTaps="handled"
          />
        </>
      ) : (
        <NoResultsFound />
      )}
    </Box>
  ) : null
}

type ResultListItemProps = {
  item: Contact
  onPress: (userToSend: Contact) => void
}

const ResultListItem = ({ item, onPress }: ResultListItemProps) => {
  return (
    <PressableOpacity
      onPress={() => onPress(item)}
      accessibilityLabel="button"
      accessibilityHint="button"
    >
      <Box alignItems="center" flexDir="row" h={50} my="10px">
        <Box marginRight="8px" justifyContent="center" alignItems="center">
          <Avatar size={44} url={item.avatarUrl} name={item.name} />
        </Box>
        <Box flex={1}>
          <Text
            fontFamily="Inter-Bold"
            fontSize={15}
            _light={{
              color: theme.colors.textLightMode[900]
            }}
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
          >
            {item.name}
          </Text>
          {item.preferred_username && (
            <Text
              fontFamily="Inter-Regular"
              fontSize={13}
              lineHeight={18}
              _light={{
                color: theme.colors.textLightMode[300]
              }}
              _dark={{
                color: theme.colors.textDarkMode[600]
              }}
            >
              {`@${item.preferred_username}`}
            </Text>
          )}
        </Box>
        <Box alignItems="center">
          <Icon
            height="20px"
            as={Entypo}
            name="chevron-thin-right"
            _light={{
              color: theme.colors.textLightMode[300]
            }}
            _dark={{
              color: theme.colors.textDarkMode[600]
            }}
          />
        </Box>
      </Box>
    </PressableOpacity>
  )
}

export default ResultsList

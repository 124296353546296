/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'

import { useCommunitiesTheme } from '../themes'

import UserAvatar from './UserAvatar'

// import ChatButton from './../assets/chatButton.svg'
// import JoinButton from './../assets/joinButton.svg'

type Member = {
  id: string
  name: string
  picture: string
  tag?: string
}

type MemberProps = {
  member: Member
  onPress: () => void
}

const MemberItem = ({ member, onPress }: MemberProps) => {
  const communitiesTheme = useCommunitiesTheme()

  return (
    <TouchableOpacity accessibilityRole="button" onPress={onPress}>
      <View style={styles.memberCard}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <View>
            <UserAvatar
              memberId={member.id}
              width={64}
              height={64}
              style={{ marginRight: 8, borderRadius: 100 }}
            />
          </View>
          <View style={{ marginLeft: 8 }}>
            <Text
              style={{ fontSize: 15, fontWeight: '700', color: communitiesTheme.memberItem.name }}
            >
              {member.name ? member.name : 'Element Guest'}
            </Text>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text
                style={[
                  styles.secondaryText,
                  { fontSize: 11 },
                  { color: communitiesTheme.memberItem.tag }
                ]}
              >
                @{member.tag}
              </Text>
              {/* <UserReputationCounter value={member.reputation} /> */}
            </View>
          </View>
        </View>
        {/* <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity
            accessibilityRole="button"
            onPress={() => {
              // console.log('whatever')
            }}
          >
            <JoinButton style={{ marginRight: 8 }} />
          </TouchableOpacity>
          <TouchableOpacity
            accessibilityRole="button"
            onPress={() => {
              // console.log(whatever)
            }}
          >
            <ChatButton />
          </TouchableOpacity>
        </View> */}
      </View>
    </TouchableOpacity>
  )
  // }
}

const styles = StyleSheet.create({
  memberCard: {
    height: 80,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  reputationBadge: {
    backgroundColor: '#FFFFFF',
    width: 64,
    height: 24,
    borderRadius: 34,
    borderWidth: 1,
    borderColor: 'rgba(206, 212, 218, 0.20)',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -20,
    elevation: 6
  },
  secondaryText: {
    fontWeight: '500'
  }
})

export default MemberItem

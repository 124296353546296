import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { observer } from 'mobx-react-lite'

import MessageReputationIcon from './assets/message_reputation_icon.svg'

type PostReputationCounterProps = {
  value: string
  reputation: number
  isReply: boolean
  align?: string
}

const PostReputationCounter = observer(
  ({ value, reputation, isReply, align }: PostReputationCounterProps) => {
    return (
      <View style={{ flexDirection: 'row' }}>
        <View
          style={{
            ...styles(isReply).container,
            marginLeft: align === 'indent' ? 55 : 0
          }}
        >
          <MessageReputationIcon />
          <Text style={styles(isReply).text}>{reputation ? reputation : 0}</Text>
        </View>
      </View>
    )
  }
)

const styles = (isReply: boolean) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 100,
      paddingHorizontal: 8,
      paddingVertical: 4,
      borderColor: '#E9ECEF',
      borderWidth: 1,
      justifyContent: 'center',
      marginTop: 12
    },
    text: {
      fontWeight: '600',
      fontSize: 13,
      lineHeight: 18,
      color: '#868E96'
    }
  })

export default PostReputationCounter

import { useCallback, useEffect, useRef } from 'react'
import throttle from 'lodash/throttle'

interface ThrottleSettings {
  /**
   * If you'd like to disable the leading-edge call, pass this as false.
   */
  leading?: boolean

  /**
   * If you'd like to disable the execution on the trailing-edge, pass false.
   */
  trailing?: boolean
}

const defaultOptions: ThrottleSettings = { leading: true, trailing: false }

export function useThrottle<T extends Parameters<typeof throttle>[0]>(
  cb: T,
  delay: number,
  options = defaultOptions
) {
  const cbRef = useRef<T>(cb)
  useEffect(() => {
    cbRef.current = cb
  })
  return useCallback(throttle(cbRef.current, delay, options), [delay])
}

import { useMemo } from 'react'
import { useMatrixUser } from '@vatom/sdk/react'

import { isCurrentUserJoined } from '../helpers'
import { useMembers } from '../queries'

export const useIsMember = ({ roomId }: { roomId: string }) => {
  const { data: matrixUser } = useMatrixUser()
  const { data: roomMembers, isLoading: isLoadingMembers, isError } = useMembers(roomId)
  const matrixUserId = useMemo(() => {
    return matrixUser?.user_id
  }, [matrixUser])

  const isMember = useMemo(() => {
    if (isError) {
      return false
    }
    if (!matrixUserId) {
      return false
    }
    if (isLoadingMembers) {
      return undefined
    }
    if (!roomMembers) {
      return false
    }
    return isCurrentUserJoined(roomMembers, matrixUserId)
  }, [isError, isLoadingMembers, matrixUserId, roomMembers])

  return isMember
}

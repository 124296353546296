import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

export const SearchIcon = ({ size, fill, ...iconProps }: React.ComponentProps<typeof Icon>) => (
  <Icon viewBox="0 0 32 32" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M24.719 23.809c.351.351.351.878 0 1.195-.14.176-.352.246-.563.246a.847.847 0 01-.633-.246l-4.71-4.711a7.187 7.187 0 01-4.536 1.582C10.27 21.875 7 18.605 7 14.562c0-4.007 3.234-7.312 7.277-7.312 4.008 0 7.313 3.305 7.313 7.313 0 1.722-.563 3.304-1.582 4.535l4.71 4.71zM8.687 14.562a5.597 5.597 0 005.626 5.626 5.619 5.619 0 005.624-5.625c0-3.094-2.53-5.626-5.625-5.626a5.619 5.619 0 00-5.624 5.626z"
    />
  </Icon>
)

import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const TopicIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 17 16" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M14.9688 0C16.0938 0 16.9688 0.90625 16.9688 2V10.9375C16.9688 12 16.0625 12.9062 14.9688 12.9062H10.4688L6.5625 15.8438C6.3125 16 5.96875 15.8438 5.96875 15.5312V12.9375H2.96875C1.84375 12.9375 0.96875 12.0625 0.96875 10.9688V2C0.96875 0.90625 1.84375 0 2.96875 0H14.9688ZM16 11V2C16 1.46875 15.5312 1 15 1H3C2.4375 1 2 1.46875 2 2V11C2 11.5625 2.4375 12 3 12H6.5C6.75 12 7 12.25 7 12.5V14.375L9.875 12.2188C10.0625 12.0938 10.25 12 10.4688 12H15C15.5312 12 16 11.5625 16 11ZM9.5 7.5C9.75 7.5 9.96875 7.75 9.96875 8C9.96875 8.28125 9.75 8.5 9.5 8.5H5.5C5.21875 8.5 4.96875 8.28125 5 8C5 7.75 5.21875 7.5 5.5 7.5H9.5ZM12.5 4.5C12.75 4.5 12.9688 4.75 12.9688 5C12.9688 5.28125 12.75 5.5 12.5 5.5H5.5C5.21875 5.5 4.96875 5.28125 5 5C5 4.75 5.21875 4.5 5.5 4.5H12.5Z"
    />
  </Icon>
)

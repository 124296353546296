import { PropsWithChildren } from 'react'
import { useControls } from 'leva'

import { use8thWall } from '../../../components/XR8Canvas'
import { useCameraPanel } from '../hooks/useCameraPanel'
import { useARFilters } from '../hooks/useFilterStore'
import {
  useLocationStore,
  useStartWatchingHeading,
  useStartWatchingPosition
} from '../hooks/useLocationStore'

import { GeolocationPanel } from './GeolocationPanel'
import { SkyBox } from './Skybox'

export const SceneWrapper = ({
  children,
  usesLocation = true
}: PropsWithChildren<{
  usesLocation?: boolean
}>) => {
  const [{ showAxes }, set] = useControls('Debug', () => ({
    showAxes: {
      value: false,
      label: 'Show Axes'
    }
  }))

  useCameraPanel()

  useARFilters()

  const position = useLocationStore(state => !!state.position)

  const ref = use8thWall()

  return (
    <group ref={ref} visible={true}>
      <ambientLight />
      <directionalLight position={[4, 10, 4]} />
      {usesLocation ? <LocationSubscriptions /> : null}
      {showAxes ? <axesHelper /> : null}
      {position ? <GeolocationPanel /> : null}
      {children}
      <SkyBox />
    </group>
  )
}

const LocationSubscriptions = () => {
  useStartWatchingPosition()
  useStartWatchingHeading()
  return null
}

import ReactQRCode from 'react-qr-code'
import { theme } from '@vatom/wombo'
import { Box, Modal, Pressable } from 'native-base'

import { Title } from '../../../components/Title'

export const QRCodeModal = ({
  isOpen,
  onDismiss,
  title,
  link
}: {
  isOpen: boolean
  onDismiss: () => void
  title: string
  link: string
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onDismiss}>
      <Pressable accessibilityRole="button" onPress={() => onDismiss()}>
        <Box
          padding={4}
          backgroundColor={theme.colors.grayCool[50]}
          borderRadius={8}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Title preset="h3" variant="light" color={theme.colors.grayCool[900]}>
            {title}
          </Title>
          <Box marginTop={4}>
            <ReactQRCode value={link} size={240} />
          </Box>
        </Box>
      </Pressable>
    </Modal>
  )
}

import AsyncStorage from '@react-native-async-storage/async-storage'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IdentitySnapshot } from '@vatom/sdk/core'

import { Contact, ContactSources, Destination } from '../types'

import { recentContactsQueryKeys } from './queries'

export const useAddRecentContact = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (contactOrDestination: Contact | Destination | IdentitySnapshot) => {
      const contacts = await AsyncStorage.getItem('RecentContactsKey')

      let contact: Contact

      if ('id' in contactOrDestination) {
        contact = { ...contactOrDestination, updatedAt: Date.now() }
      } else {
        contact = {
          id: contactOrDestination.value,
          name: contactOrDestination.value,
          value:
            'validatedValue' in contactOrDestination
              ? contactOrDestination?.validatedValue
              : undefined,
          createdAt: Date.now(),
          priority: 0,
          isDummy: true,
          source: ContactSources.recents,
          updatedAt: Date.now()
        }
      }

      if (!contacts) {
        return await AsyncStorage.setItem('RecentContactsKey', JSON.stringify([contact]))
      }

      const parsedContacts = JSON.parse(contacts) as Contact[]

      /**
       * ? dont add if there is a newer contact
       */
      if (
        contact.createdAt &&
        parsedContacts.find(c => c.createdAt > contact.createdAt && c.id === contact.id)
      ) {
        return
      }

      // Remove all contacts with the same ID
      for (let i = 0; i < parsedContacts.length; i++) {
        if (parsedContacts[i].id === contact.id) parsedContacts.splice(i--, 1)
      }

      contact.source = ContactSources.recents
      // Add this contact
      parsedContacts.push(contact)

      return await AsyncStorage.setItem('RecentContactsKey', JSON.stringify(parsedContacts))
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: recentContactsQueryKeys.recentContacts })
    }
  })
}

export const useRemoveRecentContact = () => {
  return useMutation({
    mutationFn: async (contactId: string) => {
      const contacts = await AsyncStorage.getItem('RecentContactsKey')

      if (!contacts) return

      const parsedContacts = JSON.parse(contacts) as Contact[]

      // Remove all contacts with the same ID
      for (let i = 0; i < parsedContacts.length; i++) {
        if (parsedContacts[i].id === contactId && parsedContacts[i].isDummy)
          parsedContacts.splice(i--, 1)
      }

      return await AsyncStorage.setItem('RecentContactsKey', JSON.stringify(parsedContacts))
    }
  })
}

import React, { useCallback, useState } from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import { FontAwesome } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { SessionSource, SessionType } from '@vatom/sdk/core'
import { useAccessToken, useConfig, useSDK, useUser } from '@vatom/sdk/react'
import { theme } from '@vatom/wombo'
import * as AuthSession from 'expo-auth-session'
import * as WebBrowser from 'expo-web-browser'
import { observer } from 'mobx-react-lite'
import { Icon, Pressable, Text } from 'native-base'

import { useIsDesktop } from '../../hooks/useIsDesktop'
import { useStore } from '../../models'
import { AppNavigation, AppRoutes } from '../../navigators'

const GuestBanner = observer(() => {
  const { width } = useWindowDimensions()
  const sdk = useSDK()
  const accessToken = useAccessToken()
  const user = useUser()
  const store = useStore()
  const [isOpen, setIsOpen] = useState(true)
  const isDesktop = useIsDesktop()

  const config = useConfig()
  const { clientId, scopes, discoveryUrl, redirectUri: oRedirectUri } = config.authentication

  const navigation = useNavigation<AppNavigation>()
  const redirectUri = Platform.OS === 'web' ? `${window.location.origin}/callback` : oRedirectUri
  const discovery = AuthSession.useAutoDiscovery(discoveryUrl)
    
  const businessExtraParams: Record<string, string> = config.businessId && config.businessId !== "system"?  {
    "business-id": config.businessId
  } : {}

  // Create and load an auth request
  WebBrowser.maybeCompleteAuthSession()
  const [request, , promptAsync] = AuthSession.useAuthRequest(
    {
      clientId,
      redirectUri,
      // id_token will return a JWT token
      responseType: AuthSession.ResponseType.Code,
      prompt: AuthSession.Prompt.Consent,
      extraParams: {
        'access-token': accessToken ?? '',
        ...businessExtraParams
      },

      // prompt: AuthSession.Prompt.Login,
      // responseType: AuthSession.ResponseType.Token,
      scopes
    },
    discovery
  )

  const setSessionAndUser = useCallback(
    async (tokenRepsonse: AuthSession.TokenResponse) => {
      if (!discovery) return null

      const { accessToken, expiresIn, idToken, refreshToken } = tokenRepsonse

      // Add token to the axios services
      sdk.service.setToken(accessToken)
      const date = new Date()
      sdk.dataPool.sessionStore.add({
        type: SessionType.JWT,
        value: {
          idToken,
          accessToken,
          refreshToken,
          expiresAt: new Date(
            new Date(date).setSeconds(date.getSeconds() + (expiresIn || 0))
          ).getTime()
        },
        source: SessionSource.Vatom
      })

      // Perform token enchange
      await sdk.vatomIncApi.performTokenExchange(accessToken)
      await sdk.dataPool.user.fetchIdentities()
      await sdk.dataPool.user.fetchMe()
    },
    [discovery, sdk.service, sdk.dataPool.sessionStore, sdk.dataPool.user, sdk.vatomIncApi]
  )

  const authorizeCode = useCallback(
    async (stateCode: string) => {
      let codeVerifier = request?.codeVerifier ?? ''
      if (Platform.OS === 'web') {
        try {
          const storedCodeVerifier = localStorage.getItem('LOGIN_CODE_VERIFIER')
          localStorage.removeItem('LOGIN_CODE_VERIFIER')
          codeVerifier = storedCodeVerifier ?? codeVerifier
        } catch (error) {
          //
        }
      }
      if (discovery) {
        try {
          const authorizedState = await AuthSession.exchangeCodeAsync(
            {
              clientId,
              code: stateCode,
              redirectUri,
              extraParams: {
                code_verifier: codeVerifier
              }
            },
            discovery
          )

          setSessionAndUser(authorizedState)
        } catch (error) {
          console.error(error)
          if (Platform.OS === 'web') {
            navigation.navigate(AppRoutes.connect)
          }
        }
      } else {
        console.error('No discovery')
      }
    },
    [clientId, discovery, navigation, redirectUri, request?.codeVerifier, setSessionAndUser]
  )

  const doRegistration = async () => {
    try {
      if (Platform.OS === 'web') {
        if (discovery && request) {
          const url = await request.makeAuthUrlAsync(discovery)
          localStorage.setItem('LOGIN_CODE_VERIFIER', request?.codeVerifier ?? '')
          window.location.replace(url)

          return
        }
      } else {
        const response = await promptAsync({
          createTask: false,
          showInRecents: true,
          // NOTE: There's a missing type on the WebBrowser.openAuthSession
          // With ASWebAuthenticationSession, setting .prefersEphemeralWebBrowserSession to true prior to
          // calling .start() will force the user to enter credentials in the browser session. While not
          // the same as logging out, this will allow a new user to login with different credentials when
          // launching the next session.
          // https://stackoverflow.com/questions/47207914/sfauthenticationsession-aswebauthenticationsession-and-logging-out
          // @ts-ignore
          preferEphemeralSession: true,
          windowFeatures: {
            createTask: false,
            preferEphemeralSession: true
          }
        })

        if (discovery && response.type === 'success') {
          authorizeCode(response.params.code)
        } else {
          AuthSession.dismiss()
          store.linking.clearLinkingUrl()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (!user?.guest) return null
  if (!isOpen) return null

  return (
    <Pressable
      top={isDesktop ? 0 : Platform.OS === 'web' ? 60 : 110}
      accessibilityLabel="button"
      accessibilityHint="button"
      left={0}
      zIndex={1000}
      w={width}
      p={2}
      backgroundColor={theme.colors.brand[500]}
      onPress={doRegistration}
      flexDir="row"
      h={44}
    >
      <Text flex={1} textAlign="center" color="white" fontWeight="bold">
        Click to login or register.
      </Text>

      <Pressable
        accessibilityLabel="button"
        accessibilityHint="button"
        onPress={() => setIsOpen(false)}
      >
        <Icon as={FontAwesome} color="white" name="close" />
      </Pressable>
    </Pressable>
  )
})

export default GuestBanner

import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { View } from 'react-native'
import { useMeasure } from '@reactivers/use-measure'
import { ViewMode } from '@vatom/sdk/core'

interface IWebViewBase {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onIncomingBridgeMessage: (event: any) => void
  webviewRef: React.Ref<HTMLIFrameElement>
  wrapperRef: React.MutableRefObject<HTMLDivElement>
  displayUrl: string
  viewMode?: ViewMode
}

interface Measure {
  left: number
  top: number
  width: number
  bottom: number
  right: number
  x: number
  y: number
  height: number
  offsetLeft: number
  offsetTop: number
}

const getDimensions = (initialWidth = 0, initialHeight = 0) => {
  let width = initialWidth
  let height = initialHeight
  if (width === 0 && height === 0) {
    return { width, height }
  }
  const aspectRatio = 1.6
  const isBiggerThanAspectRatio = initialHeight / initialWidth > aspectRatio
  if (!isBiggerThanAspectRatio) {
    width = initialHeight / aspectRatio
    return { width, height: initialHeight }
  } else {
    height = initialWidth * aspectRatio
    return { width: initialWidth, height }
  }
}

export const WebViewBase = ({
  onIncomingBridgeMessage,
  webviewRef,
  displayUrl,
  viewMode,
  wrapperRef
}: IWebViewBase) => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0
  })

  const setMeasureDimensions = useCallback(
    (measure: Measure) => {
      const newDimensions = getDimensions(measure.width, measure.height)
      const width = Math.floor(newDimensions.width)
      const height = Math.floor(newDimensions.height)
      if (dimensions.width !== width || dimensions.height !== height) {
        setDimensions({ width, height })
      }
    },
    [dimensions.height, dimensions.width]
  )

  useMeasure({
    ref: wrapperRef,
    updateOnWindowResize: true,
    onResize: setMeasureDimensions
  })

  useLayoutEffect(() => {
    window.addEventListener('message', onIncomingBridgeMessage)

    return () => {
      window.removeEventListener('message', onIncomingBridgeMessage)
    }
  }, [onIncomingBridgeMessage])

  const defaultStyles = {
    width: dimensions.width > 0 ? dimensions.width : '100%',
    height: dimensions.height > 0 ? dimensions.height : '100%'
  }

  const style =
    viewMode === 'card'
      ? {
          // marginTop: 16,
          // aspectRatio: 9 / 16
        }
      : {
          // alignSelf: 'center'
        }
  return (
    <View style={defaultStyles}>
      <iframe
        title="web-face-iframe"
        id="web-face-iframe"
        ref={webviewRef}
        src={displayUrl}
        width="100%"
        height="100%"
        style={{
          border: 0,
          ...style
        }}
      />
    </View>
  )
}

import {
  BlockchainInfoSnapshot,
  ResourceFaceType,
  Token,
  ViewMode,
  Web3StoreType
} from '@vatom/sdk/core'
import { getParent, Instance, SnapshotOut, types } from 'mobx-state-tree'

import logger from '../logger'
import { TransferType } from '../modules'

// import { ErcPlugin } from './ErcPlugin'

export const ErcToken = Token.named('ErcToken')
  .props({
    name: types.optional(types.string, ''),
    image: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    network: types.optional(types.string, ''),
    quantity: types.optional(types.number, 1),
    contractAddress: types.string
  })
  .views(self => ({
    get api(): Web3StoreType {
      // @TODO: Circular dependency:
      // return getParentOfType(self, ErcPlugin).api
      return (getParent(self) as any).api
    },
    get displayImage() {
      return self.image || self.metadata.image
    }
  }))
  .views(self => ({
    getFace(view: ViewMode) {
      const faceId = self.getFaceId(view)
      return self.faces?.find(f => f.id === faceId)
    },
    getFaceId(view: ViewMode) {
      const image = self.faces?.find(f => f.id === ResourceFaceType.image)
      const video = self.faces?.find(f => f.id === ResourceFaceType.video)
      const generic3D = self.faces?.find(f => f.id === ResourceFaceType.generic3D)

      let face
      if (view === 'icon') {
        face = image
      } else {
        face = generic3D || video || image
      }

      if (!face) return 'ImageFace'

      switch (face.id) {
        case ResourceFaceType.image:
          return 'ImageFace'
        case ResourceFaceType.generic3D:
          return 'ThreeDFace'
        case ResourceFaceType.video:
          return 'VideoFace'

        default:
          return 'ImageFace'
      }
    }
  }))
  .actions(self => ({
    performAction(name: string, payload: any): any {
      switch (name) {
        case 'Transfer':
          return this.transferTo(payload)
        default:
          throw new Error('Not Implemented')
      }
    },
    async transferTo(payload: {
      user: string
      contract_type: any
      contractAddress: string
      token_id: number | string
    }) {
      const { user, contract_type, contractAddress, token_id } = payload
      const type = contract_type.toLowerCase()
      const options: {
        type: TransferType
        receiver: string
        contractAddress: string
        tokenId: number | string
      } = {
        type: type,
        receiver: user,
        contractAddress: contractAddress,
        tokenId: token_id
      }
      logger.info('transfer to', options)
      await self.api.moralis.enableWeb3() // Not sure if this is needed. Seems weird
      const transaction = await self.api.moralis.transfer(options)
      return transaction || true
    }
  }))
  .views(self => ({
    get metadata() {
      const animation = self.faces?.find(f => f.animation_url) || undefined

      return {
        name: self.name ?? '',
        image: self.image.replace('ipfs://', 'https://ipfs.io/ipfs/') ?? '',
        description: self.description ?? '',
        external_uri: undefined,
        attributes: [],
        // OpenSea specific
        background_color: undefined,
        animation_url: animation?.animation_url,
        youtube_url: undefined
        // contract_data: {
        //   name: nft.name ?? '',
        //   description: nft.description ?? '',
        //   image: nft.image ?? '',
        //   external_link: '',
        //   seller_fee_basis_points: '',
        //   fee_recipient: ''
        // }
      }
    },
    get actions(): string[] {
      return ['Transfer']
    },
    get supportedAddresses() {
      return ['identities.type:sol']
    },
    get isMinted(): boolean | undefined {
      return true
    },
    get royalties(): number | undefined {
      return undefined
    },
    get hasCardView() {
      return false
    },
    get blockchainInfo(): BlockchainInfoSnapshot | undefined {
      return {
        tokenId: self.id,
        network: self.network,
        networkName: self.getNetworkName(self.network),
        networkIcon: self.getNetworkIcon(self.network),
        contractAddress: self.contractAddress,
        owner: self.owner,
        tokenLink: self.getTokenLink(self.network, self.contractAddress)
      }
    }
  }))

export type ErcTokenType = Instance<typeof ErcToken>
export type ErcTokenSnapshot = SnapshotOut<typeof ErcToken>

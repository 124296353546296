import React from 'react'
import { Text, View } from 'react-native'

import { useMemberData } from '../../queries'

const ReplyTo = ({ userId }: { userId?: string }) => {
  const data = useMemberData(userId)

  return data ? (
    <View style={{ flexDirection: 'row' }}>
      <Text style={{ color: '#868E96', fontSize: 11, fontWeight: '400' }}>
        Replying to <Text style={{ fontWeight: '600' }}>@{data?.tag}</Text>
      </Text>
    </View>
  ) : null
}

export default ReplyTo

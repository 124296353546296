import React, { useState } from 'react'
import { TextInput } from 'react-native'
import { TEthereumChains, TSolanaChains } from '@vatom/sdk/core'
import { useBusiness } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box } from 'native-base'

import { HideKeyboard } from '../../../../components/HideKeyboard'
import { Title } from '../../../../components/Title'
import SearchIcon from '../../assets/search.svg'
import { useCoinTheme } from '../../hooks/useCoinTheme'

import { TransactionList } from './TransactionList'

export const TransactionHistory = observer(
  ({
    businessId,
    address,
    chain
  }: {
    businessId?: string
    address?: string
    chain?: TEthereumChains | TSolanaChains
  }) => {
    const [searchTerm, setSearchTerm] = useState('')
    const { data: business } = useBusiness({ businessId })
    const styles = useCoinTheme({ business })
    const searchColor = styles.isDark ? theme.colors.textDarkMode[600] : theme.colors.grayCool[300]
    const titleColor = styles.isDark
      ? theme.colors.textDarkMode[300]
      : theme.colors.textLightMode[900]

    const onSearch = (stringToSearch: string) => {
      if (stringToSearch) {
        setSearchTerm(stringToSearch)
      } else {
        setSearchTerm('')
      }
    }

    const searchBar = () => (
      <Box mx={0} pb={2} px={2}>
        <Box
          mx={2}
          flexDirection={'row'}
          alignItems={'center'}
          py={2}
          px={2}
          borderWidth={1}
          borderColor={theme.colors.gray[900]}
          borderRadius={4}
          _light={{
            borderColor: theme.colors.grayCool[500]
          }}
          _dark={{
            borderColor: theme.colors.grayDarkMode[500]
          }}
        >
          <SearchIcon height={16} width={16} fill={searchColor} />
          <TextInput
            accessibilityHint={translate('common.search')}
            accessibilityLabel={translate('common.textInputField')}
            placeholder={translate('common.searchTransactions')}
            placeholderTextColor={searchColor}
            value={searchTerm}
            onChangeText={searchText => onSearch(searchText)}
            style={{
              flex: 1,
              flexShrink: 0,
              height: 20,
              marginLeft: 8,
              fontSize: 15,
              lineHeight: 20,
              fontFamily: 'Inter-Regular',
              fontWeight: '400'
            }}
          />
        </Box>
      </Box>
    )

    return (
      <Box px={4} flex={1} flexShrink={0} backgroundColor={styles.background}>
        <HideKeyboard>
          <Box pt={8}>
            <Title
              preset="h3"
              variant="bold"
              marginBottom={4}
              color={titleColor}
              tx="common.transactionHistory"
            />
          </Box>
        </HideKeyboard>
        <Box mx={-4} flexGrow={1} flexShrink={0} flexBasis={'1'}>
          {searchBar()}
          <Box flex={1} flexShrink={0}>
            <TransactionList
              businessId={businessId}
              searchTerm={searchTerm}
              address={address}
              chain={chain}
            />
          </Box>
        </Box>
      </Box>
    )
  }
)

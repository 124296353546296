import { useRef } from 'react'

import { WebWrapper } from '../../../components/WebWrapper'

import RecipientScreen, { ITransferProps } from './Recipient'

export default (props: ITransferProps) => {
  const ref = useRef(null)
  return (
    <WebWrapper justifyContent={'center'}>
      <RecipientScreen ref={ref} {...props} />
    </WebWrapper>
  )
}

import { useRef } from 'react'

import { WebWrapper } from '../../../components/WebWrapper'

import Confirmation, { IConfirmationProps } from './Confirmation'

export default (props: IConfirmationProps) => {
  const ref = useRef(null)
  return (
    <WebWrapper justifyContent={'center'}>
      <Confirmation ref={ref} {...props} />
    </WebWrapper>
  )
}

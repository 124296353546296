import { UseQueryOptions } from '@tanstack/react-query'
import { TokenTypes, UserIdentity } from '@vatom/sdk/core'
import * as Contacts from 'expo-contacts'

import { deviceContactsQueryKeys } from './device'

export enum DestinationTypes {
  Eth = 'eth',
  Phone = 'phoneNumber',
  Email = 'email',
  ENS = 'ens',
  Id = 'id',
  Solana = 'solana',
  Unknown = 'unknown'
}

interface Identity {
  type: string
  value: string
  isCustodial: boolean
}

export const ContactSources = {
  recents: 'recents',
  tokenhistory: 'tokenhistory',
  devicecontacts: 'devicecontacts',
  userdirectory: 'userdirectory',
  following: 'following',
  followers: 'followers',
  '': ''
} as const

export type ContactSource = typeof ContactSources[keyof typeof ContactSources]

export type PeopleContact = {
  id: string
  source: ContactSource
  avatarUrl: string
  name: string
  createdAt: number
  priority: number
  identities: Identity[]
  preferred_username?: string
  isDummy?: boolean
  value?: string
}

export type AlgoliaContact = {
  objectID: string
  name: string
  createdAt: number
  picture: string
  identities: Identity[]
  preferred_username: string
}

// I think we could easily decouple the token filtering from the contacts
export const tokenTypes = {
  VatomBasic: 'vatom_basic', // not mintable
  VatomUnMinted: 'vatom_unminted',
  VatomMintedCustodial: 'vatom_minted_custodial',
  VatomMintedNonCustodial: 'vatom_minted_non_custodial',
  Erc721: 'erc721',
  Solana: 'solana',
  VatomNew: 'vatom-new'
  // vatom = 'vatom',
  // erc = 'erc'
} as const

export type TokenType = typeof tokenTypes[keyof typeof tokenTypes]

export type Contact = {
  id: string
  name: string
  avatarUrl?: string
  priority?: number
  createdAt: number
  source?: ContactSource
  identities?: UserIdentity[]
  preferred_username?: string
  isDummy?: boolean
  value?: string
  updatedAt?: number
}

export interface Destination {
  type: DestinationTypes
  value: string
  validatedValue?: string // This is for something like phone number where we correct the formatting etc
  isValid?: boolean
}

export interface IUseContactSearch {
  value: string
  tokenType: TokenTypes
  destination: Destination
}

export type Facet = 'identities.type:eth' | 'identities.type:sol'

export type Filters = {
  tokenType?: TokenType
  sources?: ContactSource[]
}

export const deviceContactTypes = {
  phoneNumber: 'phoneNumber',
  email: 'email'
} as const

export type DeviceContactType = typeof deviceContactTypes[keyof typeof deviceContactTypes]

export type DeviceContact = {
  type: DeviceContactType
  value: string
  name: string
}

export type GetDeviceContactsOptions<T> = Omit<
  UseQueryOptions<
    Contacts.ContactResponse,
    unknown,
    T,
    ReturnType<typeof deviceContactsQueryKeys.getDeviceContacts>
  >,
  'queryKey' | 'queryFn'
>

/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
import React from 'react'
import { View } from 'react-native'
import { useGetSpace } from '@vatom/sdk/react'
import { observer } from 'mobx-react-lite'

import { MatrixMessage, MatrixReplyType } from '../../types'

import EmojiLine from './chat-components/EmojiLine'
// import PostReputationCounter from './chat-components/PostReputationCounter'
import Replies from './chat-components/Replies'
import Votes from './chat-components/Votes/Votes'

type DecorationsProps = {
  matrixMessage: MatrixMessage | MatrixReplyType
  additionalInfo?: any
  isThread: boolean
  facadeElements: any
  spaceId: string
}

const Decorations = observer((props: DecorationsProps) => {
  const { matrixMessage, additionalInfo, isThread, facadeElements, spaceId } = props
  const space = useGetSpace(spaceId, {
    enabled: false
  })
  const roomId = space.data?.matrixRoomId ?? ''

  return facadeElements.elements.map((decoration: any, index: number) => {
    switch (decoration.type) {
      case 'reactions':
        return (
          <EmojiLine key={index} align={decoration.align} isThread={isThread} roomId={roomId} />
        )
      case 'replies':
        if (isThread) {
          return null
        }
        return <Replies spaceId={spaceId} key={index} align={decoration.align} />
      case 'votes':
        return <Votes roomId={roomId} key={index} align={decoration.align} isThread={isThread} />
      // case 'reputation-counter':
      //   let messageReputation = 0

      //   if ('reputation' in matrixMessage) {
      //     messageReputation = matrixMessage.reputation ?? 0
      //   }

      //   if ('stake' in matrixMessage) {
      //     messageReputation = matrixMessage.stake ?? 0
      //   }

      //   if (isThread && 'stake' in matrixMessage && !messageReputation) {
      //     return null
      //   }

      //   return (
      //     <PostReputationCounter
      //       value={decoration.value}
      //       reputation={messageReputation}
      //       isReply={!('eventType' in matrixMessage)}
      //       align={decoration.align}
      //       key={index}
      //     />
      //   )
      case 'row':
        return (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginLeft:
                decoration.align === 'indent'
                  ? matrixMessage.eventType !== 'v.room.reply' && isThread
                    ? 0
                    : 55
                  : 0
            }}
            key={index}
          >
            <Decorations
              matrixMessage={matrixMessage}
              spaceId={spaceId}
              additionalInfo={additionalInfo}
              isThread={isThread}
              facadeElements={decoration}
            />
          </View>
        )
    }
  })
})

export default Decorations

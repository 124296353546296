// eslint-disable-next-line simple-import-sort/imports
import React, { useCallback, useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { NftFilterBy, TEthereumChains, TSolanaChains } from '@vatom/sdk/core'
import { alchemyQueryKeys, useBusiness, useSDK } from '@vatom/sdk/react'
import { PressableOpacity, Text, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, HStack, VStack } from 'native-base'

import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import { useBusinessTheme, useBusinessThemeConfig } from '../../hooks/useBusinessTheme'
// import { useFungibleTokens } from '../../hooks/useFungibleTokens'
import { useIsDesktop } from '../../hooks/useIsDesktop'
import { Coin, useLoyaltyCoins } from '../../hooks/useLoyalty'
import { AppRoutes } from '../../navigators'
import { TransactionList } from '../CoinDetail/partials/TransactionHistory/TransactionList'
import { CoinStackRoutes } from '../CoinNavigation/CoinNavigation'
import { useCoinStore } from '../CoinNavigation/useCoinStore'

import { CoinsList } from './partials/CoinsList'
import { useSelfCustodialFungibleTokens, useWatchingFungibleTokens } from './useRetrieveCoins'
import { useQueryClient } from '@tanstack/react-query'
import { Network } from 'alchemy-sdk'

const CoinsScreen = observer(() => {
  const queryClient = useQueryClient()
  const navigation = useNavigation()
  const isDesktop = useIsDesktop()
  const {
    coins: selfCustodialCoins,
    isLoading: isSelfCustodialCoinsLoading,
    isRefetching: isSelfCustodialCoinsRefetching
  } = useSelfCustodialFungibleTokens()

  const {
    coins: watchingCoins,
    isLoading: isWatchingCoinsLoading,
    isRefetching: isWatchingCoinsRefetching
  } = useWatchingFungibleTokens()

  const sdk = useSDK()

  const {
    isLoading: isLoyaltyCoinLoading,
    data: coinsData,
    refetch: refetchLoyaltyCoin,
    isRefetching: isLoyaltyCoinRefetching
  } = useLoyaltyCoins({
    select: data => {
      if (
        sdk.nftFilter.filterBy !== NftFilterBy.All &&
        sdk.nftFilter.filterBy !== NftFilterBy.SmartNft
      ) {
        return (
          data
            ?.filter(d => d.address === sdk.nftFilter.walletAddress)
            .map(coin => ({ ...coin, isWatching: false, isSelfCustodial: false })) ?? []
        )
      }

      return data
    }
  })

  const isLoading = isLoyaltyCoinLoading && isSelfCustodialCoinsLoading && isWatchingCoinsLoading
  const isRefetching =
    isLoyaltyCoinRefetching && isSelfCustodialCoinsRefetching && isWatchingCoinsRefetching

  const refetch = () => {
    refetchLoyaltyCoin()
    queryClient.invalidateQueries({ queryKey: alchemyQueryKeys.balance })
    queryClient.invalidateQueries({ queryKey: alchemyQueryKeys.balances })
  }

  const onPressCoin = useCallback(
    (coin: Coin): void => {
      if (!coin.isWatching && !coin.isSelfCustodial) {
        navigation.navigate(AppRoutes.coin, {
          coinId: coin.id
        })
      } else {
        // should use the new coin transfer screen
        navigation.navigate(AppRoutes.FungibleTokenDetail, {
          address: coin.address ?? '',
          contractAddress: coin.contractAddress,
          network: coin.alchemyNetwork ?? Network.ETH_MAINNET
        })
      }
    },
    [navigation]
  )

  return isDesktop ? (
    <DesktopLayout
      coinsData={[...(coinsData ?? []), ...(selfCustodialCoins ?? []), ...(watchingCoins ?? [])]}
      isLoading={isLoading}
    />
  ) : (
    <CoinsList
      data={[...(coinsData ?? []), ...(selfCustodialCoins ?? []), ...(watchingCoins ?? [])]}
      isLoading={isLoading}
      onPressCoin={onPressCoin}
      containerProps={{
        marginX: 4
      }}
      onRefresh={() => refetch()}
      isRefreshing={isRefetching}
    />
  )
})

type ActionButtonProps = {
  businessId: string
  onPress: () => void
}
const ActionButton = ({ businessId, onPress }: ActionButtonProps) => {
  const { data: business } = useBusiness({ businessId })

  const { brandTheme, pageTheme } = useBusinessThemeConfig({
    pageConfig: business?.pageConfig,
    brandConfig: business?.brandConfig
  })

  const styles = {
    primary: brandTheme?.primary ?? pageTheme.colorHeader,
    primaryText: brandTheme?.primaryText ?? pageTheme.primaryText
  }

  return (
    <PressableOpacity
      onPress={onPress}
      paddingX={5}
      borderRadius={3}
      backgroundColor={styles.primary}
      height={10}
      display={'flex'}
      justifyContent={'center'}
    >
      <Text color={styles.primaryText} tx="coinsScreen.sendCoins" />
    </PressableOpacity>
  )
}

const DesktopLayout = ({
  coinsData,
  isLoading
}: {
  coinsData: ReturnType<typeof useLoyaltyCoins>['data']
  isLoading: boolean
}) => {
  const { pageTheme } = useBusinessTheme()
  const navigation = useNavigation()
  const { coinId, setCoinId, setBusinessId, businessId, setAdress, setChain, chain, adress } =
    useCoinStore()
  const setAdressAndChain = useCallback(
    (adress?: string, chain?: TEthereumChains | TSolanaChains) => {
      setAdress(adress)
      setChain(chain)
    },
    [setAdress, setChain]
  )
  const setCoinIdAndBusinessId = useCallback(
    (coinId: string, businessId: string) => {
      setCoinId(coinId)
      setBusinessId(businessId)
    },
    [setBusinessId, setCoinId]
  )

  useEffect(() => {
    // Set the first coin on the list
    if (!coinId && coinsData && !isLoading) {
      const coin = coinsData[0]
      setCoinIdAndBusinessId(coin?.id, coin?.businessId)
    }
  }, [coinId, coinsData, isLoading, setCoinIdAndBusinessId])
  const onPressCoin = useCallback(
    (coin: Coin) => {
      if (coin && !coin.isWatching && !coin.isSelfCustodial) {
        if (coin.isWeb3) {
          setAdressAndChain(coin?.address, coin?.chain)
        } else {
          setAdressAndChain(undefined, undefined)
        }
        setCoinIdAndBusinessId(coin.id, coin.businessId)
      } else {
        navigation.navigate(AppRoutes.FungibleTokenDetail, {
          address: coin.address ?? '',
          contractAddress: coin.contractAddress,
          network: coin.alchemyNetwork ?? Network.ETH_MAINNET
        })
      }
    },
    [setCoinIdAndBusinessId, setAdressAndChain, navigation]
  )

  const onPressSend = useCallback(() => {
    const coinId = useCoinStore.getState()?.coinId
    navigation.navigate(AppRoutes.coin, {
      coinId: coinId,
      screen: CoinStackRoutes.CoinTransfer
    })
  }, [navigation])

  return (
    <ScreenWrapper screenBackgroundColor={pageTheme.background}>
      <HStack
        _light={{
          backgroundColor: theme.colors.white
        }}
        _dark={{
          backgroundColor: theme.colors.grayCool[900]
        }}
        borderRadius={8}
        borderWidth={1}
        borderColor={theme.colors.grayCool[100]}
        marginTop={8}
        marginBottom={8}
        padding={8}
      >
        <VStack flex={1} maxWidth={376}>
          <Title preset="h3" variant="bold" marginTop={3} tx="coinsScreen.myCoins" />
          <Box marginTop={8}>
            <CoinsList data={coinsData} isLoading={isLoading} onPressCoin={onPressCoin} />
          </Box>
        </VStack>
        <VStack flex={1} marginLeft={9}>
          <HStack>
            <Title preset="h3" variant="bold" flex={1} marginTop={3}>
              Transaction History
            </Title>
            <ActionButton onPress={onPressSend} businessId={businessId} />
          </HStack>
          <Box marginTop={8}>
            {businessId ? (
              <TransactionList businessId={businessId} chain={chain} address={adress} />
            ) : (
              <Box>
                <Text tx="coinsScreen.noSelected" />
              </Box>
            )}
          </Box>
        </VStack>
      </HStack>
    </ScreenWrapper>
  )
}

export default CoinsScreen

import {
  getLastVisisitedRoomsSelector,
  useGetMatrixFullStateSync,
  useMatrixUser
} from '@vatom/sdk/react'
import { FlatList } from 'native-base'

import { EmptySpace } from './EmptySpace'
import { SpaceRoom } from './SectionSpaces'
import { SpacePreviewMemo } from './SpacePreview'

export const LastVisitedRooms = ({
  onSelectSpaceRoom
}: {
  onSelectSpaceRoom: (spaceRoom: SpaceRoom) => void
}) => {
  const matrixUser = useMatrixUser()
  const { data: rooms } = useGetMatrixFullStateSync({
    select: matrixData => {
      return getLastVisisitedRoomsSelector(matrixData, matrixUser)
    }
  })

  return !rooms?.length ? (
    <EmptySpace />
  ) : (
    <FlatList
      data={rooms}
      renderItem={({ item }) => (
        <SpacePreviewMemo
          onSelectSpaceRoom={onSelectSpaceRoom}
          spaceId={item.spaceId}
          businessId={item.businessId}
          matrixRoomId={item.matrixRoomId}
          displayName={item.displayName}
          cover={item.cover}
          isPrivate={item.visibility === 'private'}
          memberCount={Object.keys(item.membersDetails ?? {}).length}
        />
      )}
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      keyExtractor={(item, index) => `spaces-room-${item.matrixRoomId}-${index}`}
    />
  )
}

import React, { useCallback, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { Utils } from '@vatom/sdk/core'
import { VatomModelType } from '@vatom/vatomNew/plugin'

export const placeHolderImageOrange =
  'https://resources.varius.io/nQwtevgfOa/1a5e0bc4-239a-4886-87be-a4967d8d202c.png'

export const useImageFaceBase = (token: VatomModelType) => {
  const resizeMode = 'contain'

  const tokenImage = token?.metadata.image.replace('ipfs://', 'https://ipfs.io/ipfs/')

  const [img, setImage] = useState<string | undefined>(tokenImage)

  const style = StyleSheet.create({
    img: {
      width: '100%',
      height: '100%',
      resizeMode,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      objectFit: 'contain'
    }
  })
  const setFace = useCallback(async () => {
    if (tokenImage && Utils.validateUrl(tokenImage)) {
      setImage(tokenImage)
    } else {
      setImage(placeHolderImageOrange)
    }
  }, [tokenImage])

  useEffect(() => {
    setFace()
  }, [setFace])

  return {
    img,
    style
  }
}

import { useState } from 'react'
import { useThrottledCallback } from 'use-debounce'

export function useModalState() {
  const [isVisible, setIsVisible] = useState(false)

  const openModal = useThrottledCallback(() => setIsVisible(() => true), 300, {
    leading: true
  })
  const closeModal = useThrottledCallback(() => setIsVisible(() => false), 300, {
    leading: true
  })

  return {
    isVisible,
    openModal,
    closeModal
  }
}

import { useCallback } from 'react'
import { useNavigation } from '@react-navigation/native'

import { AppNavigation, AppRoutes } from '../navigators'

import { useBusinessSelector } from './useBusinessSelector'

type NavigateHome = {
  businessId?: string
  screenParams?: any
}

const useNavigateHome = () => {
  const { business, businessIdentifier } = useBusinessSelector()
  const navigation = useNavigation()

  const navigateHome = useCallback(
    ({ businessId, screenParams }: NavigateHome = {}) => {
      const bId = businessId ?? (business?.id || businessIdentifier)
      if (bId) {
        navigation.navigate(AppRoutes.BusinessProxy, {
          business: bId,
          ...screenParams
        })
      } else {
        navigation.navigate(AppRoutes.home, screenParams)
      }
    },
    [business?.id, businessIdentifier, navigation]
  )

  return { navigateHome }
}

export { useNavigateHome }

import { PropsWithChildren, useEffect } from 'react'
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

// 2. Set chains
const mainnetEth = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://eth.llamarpc.com'
}

const polygon = {
  chainId: 137,
  name: 'Polygon',
  currency: 'MATIC',
  explorerUrl: 'https://polygonscan.com',
  rpcUrl: 'https://polygon.llamarpc.com'
}

const binance = {
  chainId: 56,
  name: 'Binance',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://binance.llamarpc.com'
}

const goerli = {
  chainId: 5,
  name: 'Goerli',
  currency: 'ETH',
  explorerUrl: 'https://goerli.etherscan.io',
  rpcUrl: 'https://rpc.ankr.com/eth_goerli'
}

const solana = {
  chainId: 101,
  name: 'Solana',
  currency: 'SOL',
  explorerUrl: 'https://explorer.solana.com',
  rpcUrl: 'https://api.mainnet-beta.solana.com'
}

const solanaDevnet = {
  chainId: 101,
  name: 'Solana Devnet',
  currency: 'SOL',
  explorerUrl: 'https://explorer.solana.com',
  rpcUrl: 'https://api.devnet.solana.com'
}

const metadata = {
  name: 'Vatom Wallet ',
  description: '',
  url: 'https://wallet.vatom.com/',
  icons: ['https://resources.varius.io/system/defaults/views/logo.png']
}
const projectId = '9652231699c19fdfc5f67db5226dea52'

export const CreateWeb3Modal = (props: PropsWithChildren) => {
  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [mainnetEth, polygon, binance, goerli, solanaDevnet, solana],
    projectId
  })

  return props.children
}

import React, { useCallback, useImperativeHandle, useState } from 'react'
import { Modal, ScrollView, useWindowDimensions } from 'react-native'
import Animated, { FadeIn } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { MaterialIcons } from '@expo/vector-icons'
import { translate } from '@vatom/utils'
import { theme, Toast } from '@vatom/wombo'
import { Box, Text, useColorModeValue } from 'native-base'

import {
  HeaderButtonClose,
  ScreenHeader,
  ScreenHeaderButton
} from '../../../components/ScreenHeader'
import { Title } from '../../../components/Title'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { useModalState } from '../../../hooks/useModalState'

// import { ImagePager } from './ImagePager/ImagePager'
import { ImagePagerItem } from './ImagePager/ImagePagerItem'
import { ImageMedia } from './helpers'

export type ImagesViewerRef = {
  open: () => void
  close: () => void
  setData: (images: ImageMedia[], userName?: string) => void
}

export const ImagesViewerModal = React.memo(
  React.forwardRef<ImagesViewerRef, { roomId: string }>(({ roomId }, forwardRef) => {
    const insets = useSafeAreaInsets()
    const { width: windowWidth } = useWindowDimensions()
    const { pageTheme } = useBusinessTheme()

    const { openModal, closeModal, isVisible } = useModalState()
    const [data, setData] = useState<{
      images: ImageMedia[]
      userName?: string
    }>({
      images: [],
      userName: ''
    })

    const closeIconColor = useColorModeValue('#000', '#fff')
    const headerIconBackground = useColorModeValue(theme.colors.grayCool[100], 'rgba(0, 0, 0, 0.7)')
    const headerIconColor = useColorModeValue(
      theme.colors.textLightMode[900],
      theme.colors.textDarkMode[100]
    )

    const onOpenModal = useCallback(() => {
      openModal()
    }, [openModal])

    const onCloseModal = useCallback(() => {
      closeModal()
    }, [closeModal])

    const setDataHandler = useCallback((newImages: ImageMedia[], userName?: string) => {
      setData(() => ({
        images: newImages,
        userName: userName ?? ''
      }))
    }, [])
    // TODO: change this
    useImperativeHandle(
      forwardRef,
      () => {
        return {
          open: onOpenModal,
          close: onCloseModal,
          setData: setDataHandler
        }
      },
      [onCloseModal, onOpenModal, setDataHandler]
    )

    return (
      <Modal visible={isVisible} onDismiss={closeModal} transparent onShow={() => null}>
        <Animated.View
          style={{
            flex: 1,
            paddingTop: insets.top,
            backgroundColor: pageTheme.background
          }}
          entering={FadeIn.delay(300)}
        >
          <ScreenHeader
            headerTextColor={headerIconColor}
            headerProps={{
              overflow: 'hidden'
            }}
            centerContainerProps={{
              flex: 1,
              paddingX: 2
            }}
            leftContainerProps={{
              flex: 0,
              _web: {
                flexGrow: 0,
                flexShrink: 1,
                flexBasis: 'auto'
              }
            }}
            rightContainerProps={{
              flex: 0,
              _web: {
                flexGrow: 0,
                flexShrink: 1,
                flexBasis: 'auto'
              }
            }}
            headerLeft={() => (
              <ScreenHeaderButton
                onPress={() => closeModal()}
                containerProps={{
                  backgroundColor: headerIconBackground,
                  alignItems: 'center',
                  borderRadius: 99
                }}
              >
                <HeaderButtonClose color={closeIconColor} size={12} />
              </ScreenHeaderButton>
            )}
            headerRight={() => (
              <ScreenHeaderButton
                onPress={() => {
                  console.log('LOG: ImageViewer > Header > onPresRight!!')
                  Toast({
                    title: 'Options not implemented yet!',
                    status: 'warning',
                    duration: 1500
                  })
                }}
                containerProps={{
                  backgroundColor: headerIconBackground,
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignSelf: 'center',
                  borderRadius: 99
                }}
              >
                <MaterialIcons name="more-vert" size={24} color={closeIconColor} />
              </ScreenHeaderButton>
            )}
          >
            <Box alignItems={'center'}>
              <Title preset="h5">{data.userName}</Title>
              <Text fontSize={11}>{data.images.length} Photos</Text>
            </Box>
          </ScreenHeader>
          <Box flex={1} backgroundColor={'#000'}>
            <ScrollView
              contentContainerStyle={{
                marginTop: 16,
                paddingBottom: insets.bottom
              }}
            >
              {data.images?.map(image => (
                <Box key={image.url} marginBottom={4}>
                  <ImagePagerItem image={image} width={windowWidth - 32} />
                </Box>
              ))}
            </ScrollView>
          </Box>
        </Animated.View>
      </Modal>
    )
  })
)

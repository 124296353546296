import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { useMatrixUser } from '@vatom/sdk/react'
import axios from 'axios'

import { AppNavigation, navigateToConnectScreen, RoomRoutes } from '../../../../navigators'
// import RoomCallIcon from '../../assets/room-modal-icons/room_call_icon.svg'
// import RoomDetailsIcon from '../../assets/room-modal-icons/room_details_icon.svg'
import RoomLeaveIcon from '../../assets/room-modal-icons/room_leave_icon.svg'
import RoomMembersIcon from '../../assets/room-modal-icons/room_members_icon.svg'
import { matrixServerUrl } from '../../constants'
import { isCurrentUserJoined } from '../../helpers'
import { useMembers } from '../../queries'
import { useCommunitiesTheme } from '../../themes'
import { useCommunitySpace } from '../../useCommunitySpace'
import ModalOption from '../modal-components/ModalOption'
import { useRoomContext } from '../RoomProvider'

import PopModalWrapper from './PopModalWrapper'

type RoomModalProps = {
  isModalVisible: boolean
  setIsModalVisible: (arg0: boolean) => void
}

const RoomModal = (props: RoomModalProps) => {
  const room = useRoomContext()
  const { room_id: roomID, join_rule, space_id: spaceId, business_id: businessId } = room
  const { isModalVisible, setIsModalVisible } = props
  const { data: matrixUser } = useMatrixUser()

  const { data: roomMembers } = useMembers(roomID)

  const { community } = useCommunitySpace()

  const isUserJoined = isCurrentUserJoined(roomMembers, matrixUser?.user_id)

  const navigation = useNavigation<AppNavigation>()
  const communitiesTheme = useCommunitiesTheme()

  // const handleRoomDetails = () => {
  //   console.log('room details')
  // }

  const handleRoomMmembers = () => {
    setIsModalVisible(false)
    navigateToConnectScreen(navigation, RoomRoutes.RoomMembers, {
      spaceId,
      business: businessId,
      community
    })
  }

  // const handleRoomCall = () => {
  //   console.log('room call')
  // }

  const handleRoomLeave = async () => {
    await axios
      .post(
        `${matrixServerUrl}/_matrix/client/v3/rooms/${roomID}/leave?access_token=${matrixUser?.access_token}`
      )
      .then(() => setIsModalVisible(false))
  }

  return (
    <PopModalWrapper isModalVisibl={isModalVisible} setIsModalVisible={setIsModalVisible}>
      {/* <ModalOption handler={handleRoomDetails} title="Room Details" key="room-details">
        <RoomDetailsIcon style={{ marginRight: 13 }} fill={communitiesTheme.labelIconColor} />
      </ModalOption> */}
      {isUserJoined ? (
        <ModalOption handler={handleRoomMmembers} title="View Members" key="view-members">
          <RoomMembersIcon style={{ marginRight: 13 }} fill={communitiesTheme.labelIconColor} />
        </ModalOption>
      ) : null}
      {/* {isUserJoined ? (
        <ModalOption handler={handleRoomCall} title="Start Call" key="start-call">
          <RoomCallIcon style={{ marginRight: 13 }} fill={communitiesTheme.labelIconColor} />
        </ModalOption>
      ) : null} */}
      {isUserJoined && join_rule !== 'token gated' ? (
        <ModalOption
          handler={async () => await handleRoomLeave()}
          title="Leave Room"
          key="leave-room"
          titleStyle={{ color: '#DD4267' }}
        >
          <RoomLeaveIcon style={{ marginRight: 13 }} fill={communitiesTheme.labelIconColor} />
        </ModalOption>
      ) : null}
    </PopModalWrapper>
  )
}

export default RoomModal

import { TextStyle } from 'react-native'
import { GeoARGameConfig, MultiLevelGameConfig, SimpleARGameConfig } from '@vatom/BVatom/plugin'
import { PressableOpacity } from '@vatom/wombo'
import mustache from 'mustache'
import { Box, Text } from 'native-base'

import CloseIcon from '../../../assets/close.min.svg'
import {
  borderStyle,
  dimensionStyle,
  fontFamilyStyle,
  fontSizeStyle,
  nudgeStyle,
  rgbaStyle
} from '../hooks/useStyleConverters'

type ARGameHeaderProps = {
  gameConfig: SimpleARGameConfig | MultiLevelGameConfig | GeoARGameConfig
  campaignPoints?: {
    [key: string]: number
  }
  /** 
  @deprecated use campaignPoints instead. was used for the old ARGames, campaign points allows users to decide which channel to use
  {{ points.total }} for example
 *  */
  points?: number
  onClose: () => void
}

export function ARGameHeader(props: ARGameHeaderProps) {
  const { gameConfig, points, onClose, campaignPoints } = props
  // TODO: get the right text depending on the channel for MultiLevelGameConfig
  const overlayText = 'overlayText' in gameConfig ? gameConfig.overlayText : gameConfig.overlayText1
  const overlayHeaderText =
    'overlayHeaderText' in gameConfig ? gameConfig.overlayHeaderText : gameConfig.overlayHeaderText1
  const overlaySubText =
    'overlaySubText' in gameConfig ? gameConfig.overlaySubText : gameConfig.overlaySubText1

  const parsedOverlayText = mustache.render(overlayText, {
    count: campaignPoints?.total ?? points ?? 0,
    points: campaignPoints ?? { total: points ?? 0 }
  })

  const headerAdjustment = 12

  const overlayHeaderTextNudge = {
    ...gameConfig.overlayHeaderTextNudge,
    y: gameConfig.overlayHeaderTextNudge.y + headerAdjustment
  }

  const overlayTextNudge = {
    ...gameConfig.overlayTextNudge,
    y: gameConfig.overlayTextNudge.y + headerAdjustment
  }

  const overlaySubTextNudge = {
    ...gameConfig.overlaySubTextNudge,
    y: gameConfig.overlaySubTextNudge.y + headerAdjustment
  }

  return (
    <Box
      // @ts-expect-error style clash
      style={{
        backgroundColor: rgbaStyle(gameConfig.overlayBackgroundColor),
        height: dimensionStyle(gameConfig.overlayHeight)
      }}
    >
      <PressableOpacity top={'20px'} left={'20px'} onPress={onClose} position={'absolute'}>
        <CloseIcon height={16} width={16} fill={'white'} />
      </PressableOpacity>
      <Box
        // @ts-expect-error style clash
        style={{
          width: '100%',
          justifyContent: 'center',
          height: dimensionStyle(gameConfig.overlayHeaderTextStyle.height),
          ...nudgeStyle(overlayHeaderTextNudge)
        }}
      >
        <Text
          // @ts-expect-error style clash
          style={{
            textAlign: 'center',
            fontFamily: fontFamilyStyle(gameConfig.overlayHeaderTextStyle.font.family),
            color: rgbaStyle(gameConfig.overlayHeaderTextStyle.color),
            fontSize: fontSizeStyle(
              gameConfig.overlayHeaderTextStyle.font.size
            ) as TextStyle['fontSize'],
            fontWeight: gameConfig.overlayHeaderTextStyle.font.weight as TextStyle['fontWeight'],
            padding: dimensionStyle(gameConfig.overlayHeaderTextStyle.padding),
            borderRadius: gameConfig.overlayHeaderTextStyle.borderRadius.value,
            backgroundColor: rgbaStyle(gameConfig.overlayHeaderTextStyle.backgroundColor),
            alignSelf: 'center',
            width: dimensionStyle(gameConfig.overlayHeaderTextStyle.width),
            ...borderStyle(
              gameConfig.overlayHeaderTextStyle.borderPosition,
              gameConfig.overlayHeaderTextStyle.color
            )
          }}
        >
          {overlayHeaderText}
        </Text>
      </Box>

      <Box
        // @ts-expect-error style clash
        style={{
          width: '100%',
          justifyContent: 'center',
          height: dimensionStyle(gameConfig.overlayTextStyle.height),
          ...nudgeStyle(overlayTextNudge)
        }}
      >
        <Text
          // @ts-expect-error style clash
          style={{
            textAlign: 'center',
            fontFamily: fontFamilyStyle(gameConfig.overlayTextStyle.font.family),
            color: rgbaStyle(gameConfig.overlayTextStyle.color),
            fontSize: fontSizeStyle(gameConfig.overlayTextStyle.font.size) as TextStyle['fontSize'],
            fontWeight: gameConfig.overlayTextStyle.font.weight as TextStyle['fontWeight'],
            padding: dimensionStyle(gameConfig.overlayTextStyle.padding),
            borderRadius: gameConfig.overlayTextStyle.borderRadius.value,
            backgroundColor: rgbaStyle(gameConfig.overlayTextStyle.backgroundColor),
            alignSelf: 'center',
            width: dimensionStyle(gameConfig.overlayTextStyle.width),
            ...borderStyle(
              gameConfig.overlayTextStyle.borderPosition,
              gameConfig.overlayTextStyle.color
            )
          }}
        >
          {parsedOverlayText}
        </Text>
      </Box>

      <Box
        // @ts-expect-error style clash
        style={{
          width: '100%',
          justifyContent: 'center',
          height: dimensionStyle(gameConfig.overlaySubTextStyle.height),
          ...nudgeStyle(overlaySubTextNudge)
        }}
      >
        <Text
          // @ts-expect-error style clash
          style={{
            textAlign: 'center',
            fontFamily: fontFamilyStyle(gameConfig.overlaySubTextStyle.font.family),
            color: rgbaStyle(gameConfig.overlaySubTextStyle.color),
            fontSize: fontSizeStyle(
              gameConfig.overlaySubTextStyle.font.size
            ) as TextStyle['fontSize'],
            fontWeight: gameConfig.overlaySubTextStyle.font.weight as TextStyle['fontWeight'],
            padding: dimensionStyle(gameConfig.overlaySubTextStyle.padding),
            borderRadius: gameConfig.overlaySubTextStyle.borderRadius.value,
            backgroundColor: rgbaStyle(gameConfig.overlaySubTextStyle.backgroundColor),
            alignSelf: 'center',
            width: dimensionStyle(gameConfig.overlaySubTextStyle.width),
            ...borderStyle(
              gameConfig.overlaySubTextStyle.borderPosition,
              gameConfig.overlaySubTextStyle.color
            )
          }}
        >
          {overlaySubText}
        </Text>
      </Box>
    </Box>
  )
}

/* eslint-disable unicorn/filename-case */
import React from 'react'
import { BackHandler, Pressable } from 'react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { useFocusEffect, useNavigationState } from '@react-navigation/native'
import { useBusinessSearch, useConfig } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import {
  AppStackScreenProps,
  CommunitiesRoutes,
  TabRoutes,
  WalletRoutes
} from '@vatom/wallet-routes'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { LoaderView, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box } from 'native-base'

import { ARIcon, DmIcon, Home as HomeIcon } from '../../components/Icons'
import { TermsAndConditions } from '../../components/TermsAndConditions'
import { useAutoOpen } from '../../hooks/useAutoOpen'
import { useOnOpenBusinessAnalytics } from '../../hooks/useBusinessAnalytics'
import { useBusinessSelector } from '../../hooks/useBusinessSelector'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../hooks/useIsDesktop'
import { useLastVisitedRoute } from '../../hooks/useLastVisitedRoute'
import { navigateToHome } from '../../hooks/useWebViewRef'
import logger from '../../logger'
import { AppRoutes, DrawerNavigator, getActiveRoute } from '../../navigators'
import { useDebugMode } from '../../utils/useDebugMode'
import { BusinessWebView } from '../Business/BusinessWebView'
import { DmListScreen } from '../DmList'
import MapAR from '../MapAR'
import MapsScreen from '../Maps'
import { FabScanQr } from '../Wallet/partials/FabScanQr'

import MapIcon from './assets/icon_tabbar_map.svg'
import { useFirstTime } from './hooks/useFirstTime'
import { useFirstTimeStore } from './hooks/useFirstTimeStore'
import { useTabNavColors, useTabNavConfig } from './hooks/useTabNavConfig'
import { ActiveIcon } from './partials/ActiveIcon'
// import { ConnectNavigator } from './partials/ConnectNavigator'
import { ConnectTabIcon } from './partials/ConnectTabIcon'
import { CustomIcon } from './partials/CustomIcon'
import { DesktopHeader, HeaderDesktopProvider, headerMaxHeight } from './partials/DesktopHeader'
import SpacesNavigator from './partials/SpacesNavigation'
import { WalletNavigator } from './partials/WalletNavigator'
import { WalletTabIcon } from './partials/WalletTabIcon'

const Tab = createBottomTabNavigator()
let countRenders = 0

function arePathnamesEqual(path1: string, path2: string): boolean {
  const normalizedPath1 = path1.endsWith('/') ? path1.slice(0, -1) : path1
  const normalizedPath2 = path2.endsWith('/') ? path2.slice(0, -1) : path2
  return normalizedPath1 === normalizedPath2
}

export const Home = observer(
  ({
    route,
    navigation
  }: AppStackScreenProps<typeof AppRoutes.home | typeof AppRoutes.BusinessProxy>) => {
    useLastVisitedRoute()
    const debugMode = useDebugMode()
    const insets = useSafeAreaInsets()
    const isDesktop = useIsDesktop()
    const { isLoading, businessIdentifier, business } = useBusinessSelector()
    const { isDark, pageTheme, brandTheme } = useBusinessTheme()
    const { tabsConfig, tabsToRender, initialRoute, firstTimeRoute } = useTabNavConfig()

    const tabColors = useTabNavColors()
    const isInitialRouteApplied = React.useRef(false)
    const initialPathName = React.useRef(window.location.pathname)
    const { isFirstTime } = useFirstTime({ businessId: business?.id })
    const initialRouteName = isFirstTime && firstTimeRoute ? firstTimeRoute : initialRoute

    useBusinessSearch({
      business: route?.params?.context ? 'ym3t3mPi0g' : '',
      context: route?.params?.context,
      join: route?.params?.context ? true : false
    })

    logger.info('[HomeScreen] render')

    const onPressScanQr = () => {
      navigation.navigate(AppRoutes.CameraScreen)
      return
    }

    const { isEmbedded } = useVatomWalletSdkStore()
    const appConfig = useConfig()

    const currentRouteFromState = useNavigationState(getActiveRoute)
    const featureScanEnabled = !!appConfig?.features?.screensConfig?.Wallet?.scanner

    const isInRoute =
      currentRouteFromState?.name === AppRoutes.home ||
      currentRouteFromState?.name === TabRoutes.Wallet ||
      currentRouteFromState?.name === WalletRoutes.NFTs ||
      currentRouteFromState?.name === AppRoutes.BusinessProxy

    const isScanQrEnabled = featureScanEnabled && isInRoute
    console.log('LOG: > Home > isScanQrEnabled:', !isDesktop && isScanQrEnabled)
    console.log('Home.business', business)

    useFocusEffect(
      React.useCallback(() => {
        const onBackPress = () => {
          navigation.navigate(AppRoutes.home, {
            screen: 'Wallet'
          })
          return true
        }

        BackHandler.addEventListener('hardwareBackPress', onBackPress)

        return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress)
      }, [navigation])
    )

    useAutoOpen()
    useOnOpenBusinessAnalytics()

    const LazyLoader = () => {
      return (
        <Box h="100%" bgColor="white">
          <LoaderView loaderColor={brandTheme?.primary ?? undefined} />
        </Box>
      )
    }

    countRenders++
    logger.debug('[Home.web] countRenders', countRenders)

    const ActiveBarIndicator = () => (
      <Box
        position={'absolute'}
        top={-1}
        width={'100%'}
        borderTopColor={tabColors.indicatorBackground}
        borderTopWidth={2}
      />
    )

    const backgroundColor = isDesktop && !isDark ? theme.colors.grayCool[50] : pageTheme.background

    if (isLoading && businessIdentifier && !business) {
      return <LazyLoader />
    }

    const navigateConnect = () => {
      if (businessIdentifier) {
        navigation.navigate(AppRoutes.BusinessProxy, {
          business: businessIdentifier,
          screen: TabRoutes.Connect,
          params: {
            screen: CommunitiesRoutes.Communities
          }
        })
      }
    }

    const navigateToInitialRoute = () => {
      const businessId = (business?.id ?? businessIdentifier) as string
      navigation.navigate(AppRoutes.BusinessProxy, {
        business: businessId,
        screen: initialRouteName
      })
    }

    /**
     * Since react navigation is not able to apply the initial routename after the first navigation render we need to do it manually
     */
    const onLayout = () => {
      if (business?.id && !isInitialRouteApplied.current) {
        isInitialRouteApplied.current = true
        const pathName = initialPathName.current
        const isCustomDomainRoot = appConfig.isBusinessLocked && pathName === '/'
        // We only care about doing this if the route matches /b/:businessId/ since for the others the right deeplink should kick in
        const isNavigatingToBusinessRoot =
          arePathnamesEqual(pathName, `/b/${businessIdentifier}`) || isCustomDomainRoot

        if (business && isNavigatingToBusinessRoot) {
          // We mark the business as opened so we don't show the "initial" route next time. but the "default" if it exists or the microsite if it exists, otherwise it should show the wallet
          useFirstTimeStore.getState().setWasOpened(business?.id)
          useFirstTimeStore.getState().setWasOpened(business?.name)
          navigateToInitialRoute()
        }
      }
    }
    // const isSdkDrawerEnable = isEmbedded && config.hideDrawer
    const isSdkDrawerEnable = appConfig.features.hideDrawer
    const isNavSdkEnable = appConfig.features.hideNavigation

    return (
      <DrawerNavigator>
        <HeaderDesktopProvider>
          <GestureHandlerRootView
            style={{
              flex: 1,
              flexShrink: 0
            }}
          >
            <BottomSheetModalProvider>
              <Box backgroundColor={backgroundColor} flexGrow={1} onLayout={onLayout}>
                {/* <CallControl /> */}
                <TermsAndConditions businessId={business?.id} />
                <Tab.Navigator
                  initialRouteName={initialRouteName}
                  safeAreaInsets={{
                    top: insets.top,
                    right: insets.right,
                    bottom: insets.bottom,
                    left: insets.left
                  }}
                  sceneContainerStyle={{
                    backgroundColor: 'transparent',
                    paddingTop: isDesktop && !isSdkDrawerEnable ? headerMaxHeight : 0 // Buffer to push the content below the header
                  }}
                  screenOptions={{
                    header: () => isDesktop && !isSdkDrawerEnable && <DesktopHeader />,
                    headerTransparent: true,
                    lazy: true,
                    lazyPlaceholder: LazyLoader,
                    swipeEnabled: false,
                    tabBarShowLabel: true,
                    tabBarInactiveTintColor: tabColors.inactiveTint,
                    tabBarActiveTintColor: tabColors.activeTint,
                    tabBarLabelPosition: 'below-icon',
                    // @ts-expect-error
                    tabBarStyle: {
                      display:
                        isNavSdkEnable || isDesktop
                          ? 'none'
                          : tabsToRender.length <= 1
                          ? 'none'
                          : undefined,

                      position: 'sticky', // TS-Error: position?: Animated.Value | 'absolute' | 'relative'
                      height: 70,
                      backgroundColor: tabColors.background,
                      borderTopColor: tabColors.border,
                      borderTopWidth: 1,
                      opacity: isEmbedded && tabsToRender.length <= 1 ? 0 : 1
                    },
                    tabBarItemStyle: {
                      justifyContent: 'center'
                    },
                    tabBarIconStyle: {
                      width: '100%',
                      minHeight: 44,
                      flex: 0
                    },
                    tabBarLabelStyle: {
                      width: '100%',
                      flex: 1
                    }
                  }}
                >
                  {tabsToRender.includes(TabRoutes.Home) ? (
                    <Tab.Screen
                      name={TabRoutes.Home}
                      component={BusinessWebView}
                      options={{
                        tabBarLabel: tabsConfig[TabRoutes.Home]?.label || translate('common.home'),
                        tabBarButton: props => {
                          return (
                            <Pressable
                              {...props}
                              onPress={e => {
                                navigateToHome()
                                if (props.onPress) props.onPress(e)
                              }}
                            />
                          )
                        },
                        tabBarIcon: ({ focused, color }) => (
                          <>
                            {focused && <ActiveBarIndicator />}
                            {tabsConfig[TabRoutes.Home]?.icon ? (
                              <CustomIcon
                                focused={focused}
                                color={color}
                                src={tabsConfig[TabRoutes.Home].icon}
                              />
                            ) : (
                              <ActiveIcon isActive={false}>
                                <HomeIcon size={28} fill={color} />
                              </ActiveIcon>
                            )}
                          </>
                        )
                      }}
                    />
                  ) : null}

                  {tabsToRender.includes(TabRoutes.Connect) ? (
                    <Tab.Screen
                      name={TabRoutes.Connect}
                      // @ts-expect-error this need some love
                      component={SpacesNavigator}
                      options={{
                        tabBarLabel:
                          tabsConfig[TabRoutes.Connect]?.label || translate('common.connect'),
                        tabBarButton: props => {
                          return (
                            <Pressable
                              {...props}
                              onPress={e => {
                                e.preventDefault()
                                navigateConnect()
                                if (props.onPress) props.onPress(e)
                              }}
                            />
                          )
                        },
                        tabBarIcon: ({ focused, color }) => (
                          <>
                            {focused && <ActiveBarIndicator />}
                            {tabsConfig[TabRoutes.Connect]?.icon ? (
                              <CustomIcon
                                focused={focused}
                                color={color}
                                src={tabsConfig[TabRoutes.Connect].icon}
                              />
                            ) : (
                              <ConnectTabIcon focused={focused} color={color} />
                            )}
                          </>
                        )
                      }}
                    />
                  ) : null}

                  {(debugMode && !businessIdentifier) || tabsToRender.includes(TabRoutes.DMs) ? (
                    <Tab.Screen
                      name={TabRoutes.DMs}
                      component={DmListScreen}
                      options={{
                        tabBarLabel: tabsConfig[TabRoutes.DMs]?.label || translate('common.dms'),
                        tabBarIcon: ({ focused, color }) =>
                          tabsConfig[TabRoutes.DMs]?.icon ? (
                            <CustomIcon
                              focused={focused}
                              color={color}
                              src={tabsConfig[TabRoutes.DMs].icon}
                            />
                          ) : (
                            <ActiveIcon isActive={false}>
                              <DmIcon size={28} fill={color} />
                            </ActiveIcon>
                          )
                      }}
                    />
                  ) : null}

                  {tabsToRender.includes(TabRoutes.Wallet) ? (
                    <Tab.Screen
                      name={TabRoutes.Wallet}
                      component={WalletNavigator}
                      options={{
                        tabBarLabel:
                          tabsConfig[TabRoutes.Wallet]?.label || translate('common.wallet'),
                        tabBarIcon: ({ focused, color }) => (
                          <>
                            {focused && <ActiveBarIndicator />}
                            {tabsConfig[TabRoutes.Wallet]?.icon ? (
                              <CustomIcon
                                focused={focused}
                                color={color}
                                src={tabsConfig[TabRoutes.Wallet].icon}
                              />
                            ) : (
                              <WalletTabIcon focused={focused} color={color} />
                            )}
                          </>
                        )
                      }}
                    />
                  ) : null}

                  {tabsToRender.includes(TabRoutes.Map) ? (
                    <Tab.Screen
                      name={TabRoutes.Map}
                      component={MapsScreen}
                      options={{
                        tabBarLabel: tabsConfig[TabRoutes.Map]?.label || translate('common.map'),
                        tabBarIcon: ({ focused, color }) => (
                          <>
                            {focused && <ActiveBarIndicator />}
                            {tabsConfig[TabRoutes.Map]?.icon ? (
                              <CustomIcon
                                focused={focused}
                                color={color}
                                src={tabsConfig[TabRoutes.Map].icon}
                              />
                            ) : (
                              <ActiveIcon isActive={false}>
                                <MapIcon height={26} width={26} fill={color} />
                              </ActiveIcon>
                            )}
                          </>
                        )
                      }}
                    />
                  ) : null}
                  {tabsToRender.includes(TabRoutes.MapAr) ? (
                    <Tab.Screen
                      name={TabRoutes.MapAr}
                      component={MapAR}
                      options={{
                        tabBarLabel: tabsConfig[TabRoutes.MapAr]?.label || translate('common.ar'),
                        tabBarIcon: ({ focused, color }) =>
                          tabsConfig[TabRoutes.MapAr]?.icon ? (
                            <CustomIcon
                              focused={focused}
                              color={color}
                              src={tabsConfig[TabRoutes.MapAr].icon}
                            />
                          ) : (
                            <ActiveIcon isActive={false}>
                              <ARIcon size={30} fill={color} />
                            </ActiveIcon>
                          )
                      }}
                    />
                  ) : null}
                </Tab.Navigator>
              </Box>
              <FabScanQr enabled={!isDesktop && isScanQrEnabled} onPress={onPressScanQr} />
            </BottomSheetModalProvider>
          </GestureHandlerRootView>
        </HeaderDesktopProvider>
      </DrawerNavigator>
    )
  }
)

export default Home

import React from 'react'
import { AntDesign } from '@expo/vector-icons'
import { TokenType } from '@vatom/sdk/core'
import { Avatar, theme } from '@vatom/wombo'
import { Box, Center, Icon, Image, Text } from 'native-base'

interface DetailsProps {
  token: TokenType
  toUserName: string
  toUserImageUri?: string
}

const reconStructImage = (url: string) => {
  try {
    if (!url) return undefined
    let URL = url

    // Work around for IPFS; TODO: implement proxy through vatom, cloudfront or sth???
    URL = URL.replace('ipfs://', 'https://vatom.mypinata.cloud/ipfs/')
    // workaround for getting too many requests on pinata; TODO: implement proxy through vatom, cloudfront or sth???
    URL = URL.replace('https://gateway.pinata.cloud/ipfs/', 'https://vatom.mypinata.cloud/ipfs/')
    return URL
  } catch (error) {
    return undefined
  }
}

export const Details = ({ token, toUserName, toUserImageUri }: DetailsProps) => {
  const titleNFT = token.metadata.name
  const imageNft = reconStructImage(token.displayImage ?? '')

  const reducedName = () => {
    if (toUserName && toUserName.length > 15) {
      return toUserName.substring(0, 15) + '...'
    }
    return toUserName
  }

  return (
    <Box marginBottom="20px" justifyContent="center" alignItems="center">
      <Text
        marginBottom="20px"
        fontFamily="Inter-Bold"
        fontSize="34px"
        textAlign="center"
        lineHeight="41px"
        _light={{
          color: 'black'
        }}
        _dark={{
          color: theme.colors.systemColorsDark.orange
        }}
      >
        Send NFT
      </Text>

      <Text marginBottom="40px" fontFamily="Inter-Regular" fontSize="15px" textAlign="center">
        Are you sure you want to send your NFT, <Text fontFamily="Inter-SemiBold">{titleNFT}</Text>,
        to{' '}
        <Text fontFamily="Inter-SemiBold" flexWrap="wrap">
          {toUserName}
        </Text>
        ?
      </Text>

      <Box flexDirection="row" width="100%" justifyContent="center">
        <Box alignItems="center" width="50%" position="relative">
          <Image
            accessibilityIgnoresInvertColors={true}
            source={{ uri: imageNft }}
            size="160px"
            borderRadius="8px"
            marginRight="7px"
            marginBottom="10px"
            alt="NFT"
          />
          <Text
            lineHeight="22px"
            fontFamily="Inter-SemiBold"
            fontSize="16px"
            maxW="160px"
            textAlign="center"
            _light={{
              color: theme.colors.textLightMode[900]
            }}
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
          >
            {titleNFT}
          </Text>
        </Box>

        <Box alignItems="center" width="50%" justifyContent="space-between">
          {toUserImageUri && toUserImageUri !== '' ? (
            <Image
              accessibilityIgnoresInvertColors={true}
              source={{ uri: toUserImageUri }}
              size="160px"
              borderRadius="8px"
              marginBottom="10px"
              alt="user"
            />
          ) : (
            <Box size="160px" alignItems="center" justifyContent="center">
              <Avatar url={toUserImageUri} name={toUserName} size={55} fontSize="xl" />
            </Box>
          )}

          <Text
            lineHeight="22px"
            fontFamily="Inter-SemiBold"
            fontSize="16px"
            maxW="160px"
            textAlign="center"
            _light={{
              color: theme.colors.textLightMode[900]
            }}
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
          >
            {reducedName()}
          </Text>
        </Box>

        <Box
          top="0px"
          size="160px"
          justifyItems="center"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          alignSelf="center"
          position="absolute"
        >
          <Center
            alignSelf="center"
            // position="absolute"
            width="48px"
            borderRadius="48px"
            height="48px"
            // mb="60px"
            mx={4}
            shadow={1}
            borderWidth={1}
            _light={{
              borderColor: theme.colors.grayCool[200],
              backgroundColor: theme.colors.white
            }}
            _dark={{
              borderColor: theme.colors.grayDarkMode[900],
              backgroundColor: theme.colors.grayDarkMode[700]
            }}
          >
            <Icon
              as={AntDesign}
              size="24px"
              name="arrowright"
              color={theme.colors.systemColorsLight.orange}
            />
          </Center>
        </Box>
      </Box>
    </Box>
  )
}

export default Details

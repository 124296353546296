import { SessionSource, SessionTokenType, SessionType, withRootSDKStore } from '@vatom/sdk/core'
import { getConfig } from '@vatom/sdk/react'
import { Instance, types } from 'mobx-state-tree'

import logger from '../logger'

import VatomApi from './Vatom/internal/net/rest/api/BVatomApi'

export const VatomApiStore = types
  .model('VatomApiStore')
  .props({
    id: types.identifier
  })
  .extend(withRootSDKStore)
  .volatile(self => {
    const { appID, server, websocketServer } = getConfig()
    const vatomApi = VatomApi.getInstance(appID, server, websocketServer)

    return {
      client: vatomApi.client,
      store: vatomApi.store,
      vatomApi,
      webSockets: vatomApi.webSockets
      // __promises: new Map(),
      // __afterInit: false
    }
  })
  .actions(self => ({
    setRefreshToken(refreshToken: string | null) {
      self.vatomApi.store.refreshToken = refreshToken
    }
  }))
  .actions(self => ({
    afterAttach() {
      logger.info('[VatomApiStore.afterAttach]')
      const vatomApi = self.vatomApi
      const sessionStore = self.rootStore.dataPool?.sessionStore
      // If the accessToken has refreshed recently, store it in session
      if (sessionStore) {
        const sessionToken = sessionStore.getFirstByTypeAndSource(
          SessionType.JWT,
          SessionSource.VatomNetwork
        )?.value as SessionTokenType

        logger.info('[VatomApiStore.afterAttach] token?', vatomApi.store.accessToken)

        if (vatomApi.store.accessToken && vatomApi.store.accessToken !== sessionToken.accessToken) {
          logger.info('[VatomApiStore.afterAttach] token restored')
          sessionToken.setAccessToken(vatomApi.store.accessToken)
        } else {
          logger.info('[VatomApiStore.afterAttach] unable to restore token')
        }
      } else {
        logger.info('[VatomApiStore.afterAttach] unable to restore session')
      }
    }
  }))

export type VatomApiType = Instance<typeof VatomApiStore>

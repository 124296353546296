// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { BVatomTokenType } from '@vatom/BVatom/plugin'
import { useGetPublicProfiles, useSDK } from '@vatom/sdk/react'

import { converToContactSelector } from '../../profile'
import { ContactSources, Destination } from '../types'

export const useGetTokenContacts = (destination?: Destination) => {
  const sdk = useSDK()
  const networkId = sdk.analytics.getNetworkUserId()
  const inventory = sdk.dataPool.region('inventory')

  const userIds = inventory?.tokens
    .filter(token => {
      const vatom = token as BVatomTokenType

      const uid = vatom?.properties?.transferred_by
      return token.type === 'vatom' && uid && uid !== networkId
    })
    .map(token => {
      const vatom = token as BVatomTokenType
      const uid = vatom.properties.transferred_by

      return uid
    })

  const uniqueUserIds =
    userIds?.filter((element, index) => {
      return userIds.indexOf(element) === index
    }) ?? []

  const publicProfiles = useGetPublicProfiles(uniqueUserIds.length ? uniqueUserIds : [], user =>
    converToContactSelector(user, ContactSources.tokenhistory, destination)
  )

  return publicProfiles
}

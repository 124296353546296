import { useMutation, useQueryClient } from '@tanstack/react-query'
import * as Contacts from 'expo-contacts'

import { deviceContactsQueryKeys } from './queries'

export const useRequestContactsPermission = (
  onSuccess?: (permission: Contacts.PermissionResponse) => void
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async () => {
      return await Contacts.requestPermissionsAsync()
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: deviceContactsQueryKeys.contactsPermission })
    },
    onSuccess
  })
}

import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const InfoIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 16 16" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M8 0C3.5625 0 0 3.59375 0 8C0 12.4375 3.5625 16 8 16C12.4062 16 16 12.4375 16 8C16 3.59375 12.4062 0 8 0ZM8 15C4.125 15 1 11.875 1 8C1 4.15625 4.125 1 8 1C11.8438 1 15 4.15625 15 8C15 11.875 11.8438 15 8 15ZM8 5.75C8.40625 5.75 8.75 5.4375 8.75 5C8.75 4.59375 8.40625 4.25 8 4.25C7.5625 4.25 7.25 4.59375 7.25 5C7.25 5.4375 7.5625 5.75 8 5.75ZM9.5 11H8.5V7.5C8.5 7.25 8.25 7 8 7H7C6.71875 7 6.5 7.25 6.5 7.5C6.5 7.78125 6.71875 8 7 8H7.5V11H6.5C6.21875 11 6 11.25 6 11.5C6 11.7812 6.21875 12 6.5 12H9.5C9.75 12 10 11.7812 10 11.5C10 11.25 9.75 11 9.5 11Z"
    />
  </Icon>
)

import React from 'react'

import { FaceProps } from '../../'
import { Image } from '../partials/Image'

import { useImageFaceBase } from './base'

const ImageFace = (props: FaceProps) => {
  const [error, setError] = React.useState(false)
  const { token, viewMode } = props
  const icon = viewMode === 'icon'
  const resizeMode = icon ? 'cover' : 'contain'
  const { img, style } = useImageFaceBase(props)
  const defaultImage =
    'https://images.squarespace-cdn.com/content/v1/61e34043b0387b781e8fbaf7/1e0f129e-098c-4c86-a006-be77e1ad379c/MAIN+LOGO+VER+03-1.png?'

  return (
    <>
      {img ? (
        <Image
          accessibilityIgnoresInvertColors
          alt={token?.metadata.name || 'token'}
          source={{
            // cache: 'force-cache',
            headers: {
              Accept: '*/*'
            },
            uri: error ? defaultImage : img
          }}
          resizeMode={resizeMode}
          style={style.img}
          onError={() => setError(true)}
        />
      ) : null}
    </>
  )
}

Object.defineProperty(ImageFace, 'name', {
  writable: true,
  value: 'ImageFace'
})

export default ImageFace

import React from 'react'
import { Animated, Dimensions, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native'

import { usePlugins } from '../../queries'
import { useCommunitiesTheme } from '../../themes'

type RoomFiltersProps = {
  isMemberPage?: boolean
  setFilter: (filter: string) => void
  filter: string
}

const RoomFilters = (props: RoomFiltersProps) => {
  const { isMemberPage, setFilter, filter: activeFilter } = props

  const { data, isSuccess } = usePlugins()

  const communitiesTheme = useCommunitiesTheme()
  const firstPlugin = data?.[0]
  const roomFilters = firstPlugin?.descriptor?.room_filters
  const memberPageFilters = firstPlugin?.descriptor?.member_filters

  const filters = isMemberPage ? memberPageFilters : roomFilters

  if (!roomFilters?.some((filter: { message_type: string }) => filter.message_type === 'all')) {
    roomFilters?.unshift({
      name: 'All',
      message_type: 'all'
    })
  }

  if (!isSuccess) {
    return null
  }

  const appWidth = 768
  const { width } = Dimensions.get('screen')
  const spacing = filters.length > 5 ? 5 : filters.length
  const filterWidth = (width > appWidth ? appWidth : width) / spacing

  return (
    <Animated.View
      style={[
        { ...styles(communitiesTheme).filterHolder, marginTop: 20 },
        { backgroundColor: communitiesTheme.background }
      ]}
    >
      {filters.length > 0 ? (
        <ScrollView horizontal>
          {filters.map((filter: any) => {
            const { name, message_type } = filter
            const isActive = activeFilter === message_type

            return (
              <Pressable
                key={message_type}
                accessibilityRole="button"
                onPress={() => (!isActive ? setFilter(message_type) : null)}
              >
                <View
                  style={{
                    width: filterWidth < 57 ? 'auto' : filterWidth,
                    alignItems: 'center',
                    marginHorizontal: filterWidth < 57 ? 16 : 0
                  }}
                >
                  <View style={isActive ? styles(communitiesTheme).activeFilterWrapper : {}}>
                    <Text
                      style={[
                        styles(communitiesTheme).filter,
                        isActive ? styles(communitiesTheme).activeFilter : null
                      ]}
                    >
                      {name}
                    </Text>
                  </View>
                </View>
              </Pressable>
            )
          })}
        </ScrollView>
      ) : null}
    </Animated.View>
  )
}

const styles = (communitiesTheme?: any) =>
  StyleSheet.create({
    filterHolder: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: communitiesTheme.roomFilters.border
    },
    filter: {
      fontSize: 15,
      color: communitiesTheme.roomFilters.filter,
      lineHeight: 20,
      paddingBottom: 12,
      textAlign: 'center'
    },
    activeFilter: {
      fontWeight: 'bold',
      color: communitiesTheme.roomFilters.activeFilter,
      paddingBottom: 10
    },
    activeFilterWrapper: {
      borderBottomWidth: 2,
      borderBottomColor: communitiesTheme.roomFilters.activeBorder
    }
  })

export default RoomFilters

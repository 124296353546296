import React from 'react'
import { Ionicons } from '@expo/vector-icons'
import { useSpace } from '@vatom/sdk/react'
import { PressableOpacity } from '@vatom/wombo'

import { CallControlType } from './EndCallButton'

export function SpeakerButton(props: CallControlType) {
  const { toggleSpeaker, inSpeaker } = useSpace()
  const size = '58px'
  return (
    <PressableOpacity
      onPress={toggleSpeaker}
      background={inSpeaker ? 'green.500' : 'rgba(0, 0, 0, 0.7)'}
      h={size}
      w={size}
      borderRadius={30}
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      {inSpeaker ? (
        <Ionicons name="volume-high-outline" size={props.secondary ? 20 : 25} color="white" />
      ) : (
        <Ionicons name="volume-low-outline" size={props.secondary ? 20 : 25} color="white" />
      )}
      {/**/}
    </PressableOpacity>
  )
}

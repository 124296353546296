import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size: React.ComponentProps<typeof Icon>['size']
}

export const SpaceView = ({
  size,
  ...iconProps
}: IconProps & React.ComponentProps<typeof Icon>) => (
  <Icon viewBox="0 0 24 25" size={size} {...iconProps}>
    <Path d="M12.2031 5.91406C12.5312 5.80469 12.9414 5.80469 13.2695 5.91406L18.7383 7.99219C19.3398 8.21094 19.75 8.78516 19.75 9.44141V16.0859C19.75 16.7422 19.3398 17.2891 18.7383 17.5352L13.2695 19.6133C12.9414 19.7227 12.5312 19.7227 12.2031 19.6133L6.73438 17.5352C6.13281 17.2891 5.75 16.7422 5.75 16.0859V9.44141C5.75 8.78516 6.13281 8.21094 6.73438 7.99219L12.2031 5.91406ZM12.8047 7.14453C12.7773 7.14453 12.6953 7.14453 12.668 7.14453L7.47266 9.11328L12.75 11.1914L18 9.11328L12.8047 7.14453ZM7.19922 16.3047L12.0938 18.1641V12.3398L7.0625 10.3711V16.0859C7.0625 16.1953 7.11719 16.2773 7.19922 16.3047ZM13.4062 18.1641L18.2734 16.3047C18.3555 16.2773 18.4375 16.1953 18.4375 16.0859V10.3711L13.4062 12.3398V18.1641Z" />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 4.5H17V3H22C22.9665 3 23.75 3.7835 23.75 4.75V9.75H22.25V4.75C22.25 4.61193 22.1381 4.5 22 4.5Z"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.5 5L2.5 10L1 10L1 5C1 4.0335 1.7835 3.25 2.75 3.25L7.75 3.25L7.75 4.75L2.75 4.75C2.61193 4.75 2.5 4.86193 2.5 5Z"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 21.5H17V23H22C22.9665 23 23.75 22.2165 23.75 21.25V16.25H22.25V21.25C22.25 21.3881 22.1381 21.5 22 21.5Z"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.5 21L2.5 16L1 16L1 21C1 21.9665 1.7835 22.75 2.75 22.75L7.75 22.75L7.75 21.25L2.75 21.25C2.61193 21.25 2.5 21.1381 2.5 21Z"
    />
  </Icon>
)

export { SpaceView as SpaceViewIcon }

export interface RefType {
  ref: string
  type: string
}

export interface Dimension {
  unit: string
  value: number
}

export interface FontStyle {
  family: string[]
  size: number
  source: string
  stretch: string
  style: string
  variant: string
  weight: number | string
}

export interface Color {
  a: number
  b: number
  g: number
  r: number
}

export interface Style {
  backgroundColor: Color
  borderPosition: string
  borderRadius: Dimension
  color: Color
  font: FontStyle
  height: Dimension
  padding: Dimension
  width: Dimension
}

export interface Border {
  position: string
  radius: Dimension
}

export interface TextContent {
  isMarkdown: boolean
  text: string
}

export interface Action {
  type: string
}

export interface Nudge {
  x: number
  y: number
  unit: 'vw' | 'vh' | '%' | 'px'
  axis: 'vertical' | 'horizontal' | 'both'
}

export interface DateObject {
  text: string
  toggled: boolean
}

export interface SimpleARGameConfig {
  arOpen: boolean
  auto_show_card: boolean
  backgroundImage: RefType
  buttonNudge: Nudge
  buttonStyle: Style
  buttonText: string
  countText: string
  countTextNudge: Nudge
  countTextStyle: Style
  errorButton: string
  errorButtonNudge: Nudge
  errorButtonStyle: Style
  errorIcon: RefType
  errorNudge: Nudge
  errorStyle: Style
  errorTitle: TextContent
  finalImage?: RefType
  finalStartDate: DateObject
  gameOver: TextContent
  gameOverButton: string
  gameOverButtonAction: Action
  gameOverButtonNudge: Nudge
  gameOverButtonStyle: Style
  gameOverIcon: RefType
  gameOverNudge: Nudge
  gameOverStyle: Style
  headerText: string
  headerTextNudge: Nudge
  headerTextStyle: Style
  modalBackgroundColor: Color
  modalCloseColor: Color
  modelUrl: RefType
  overlayBackgroundColor: Color
  overlayHeaderText: string
  overlayHeaderTextNudge: Nudge
  overlayHeaderTextStyle: Style
  overlayHeight: Dimension
  overlaySubText: string
  overlaySubTextNudge: Nudge
  overlaySubTextStyle: Style
  overlayText: string
  overlayTextNudge: Nudge
  overlayTextStyle: Style
  pleaseWait: TextContent
  pleaseWaitButton: string
  pleaseWaitButtonNudge: Nudge
  pleaseWaitButtonStyle: Style
  pleaseWaitIcon: RefType
  pleaseWaitNudge: Nudge
  pleaseWaitStyle: Style
  previewImage?: RefType
  previewEndDate: DateObject
  warning: TextContent
  warningButton: string
  warningButtonNudge: Nudge
  warningButtonStyle: Style
  warningIcon: RefType
  warningNudge: Nudge
  warningStyle: Style
}

export interface GeoARGameConfig {
  arOpen: boolean
  auto_show_card: boolean
  backgroundImage: RefType
  buttonNudge: Nudge
  buttonStyle: Style
  buttonText: string
  countText: string
  countTextNudge: Nudge
  countTextStyle: Style
  errorButton: string
  errorButtonNudge: Nudge
  errorButtonStyle: Style
  errorIcon: RefType
  errorNudge: Nudge
  errorStyle: Style
  errorTitle: TextContent
  finalImage?: RefType
  finalStartDate: DateObject
  gameOver: TextContent
  gameOverButton: string
  gameOverButtonAction: Action
  gameOverButtonNudge: Nudge
  gameOverButtonStyle: Style
  gameOverIcon: RefType
  gameOverNudge: Nudge
  gameOverStyle: Style
  headerText: string
  headerTextNudge: Nudge
  headerTextStyle: Style
  modalBackgroundColor: Color
  modalCloseColor: Color
  overlay: RefType
  overlayBackgroundColor: Color
  overlayHeaderText: string
  overlayHeaderTextNudge: Nudge
  overlayHeaderTextStyle: Style
  overlayHeight: Dimension
  overlaySubText: string
  overlaySubTextNudge: Nudge
  overlaySubTextStyle: Style
  overlayText: string
  overlayTextNudge: Nudge
  overlayTextStyle: Style
  previewImage?: RefType
  previewEndDate: DateObject
  warning: TextContent
  warningButton: string
  warningButtonNudge: Nudge
  warningButtonStyle: Style
  warningIcon: RefType
  warningNudge: Nudge
  warningStyle: Style
}

export interface MultiLevelGameConfig {
  arOpen: boolean
  auto_show_card: boolean
  Resource: RefType
  buttonNudge: Nudge
  buttonStyle: Style
  buttonText: string
  countText: string
  countTextNudge: Nudge
  countTextStyle: Style
  errorButton: string
  errorButtonNudge: Nudge
  errorButtonStyle: Style
  errorIcon: RefType
  errorNudge: Nudge
  errorStyle: Style
  errorTitle: TextContent
  finalImage?: RefType
  finalStartDate: DateObject
  gameOver: TextContent
  gameOverButton: string
  gameOverButtonAction: Action
  gameOverButtonNudge: Nudge
  gameOverButtonStyle: Style
  gameOverIcon: RefType
  gameOverNudge: Nudge
  gameOverStyle: Style
  headerText: string
  headerTextNudge: Nudge
  headerTextStyle: Style
  modalBackgroundColor: Color
  modalCloseColor: Color
  overlayBackgroundColor: Color
  overlayHeaderText1: string
  overlayHeaderText2: string
  overlayHeaderText3: string
  overlayHeaderTextNudge: Nudge
  overlayHeaderTextStyle: Style
  overlayHeight: Dimension
  overlaySubText1: string
  overlaySubText2: string
  overlaySubText3: string
  overlaySubTextNudge: Nudge
  overlaySubTextStyle: Style
  overlayText1: string
  overlayText2: string
  overlayText3: string
  overlayTextNudge: Nudge
  overlayTextStyle: Style
  previewImage?: RefType
  previewEndDate: DateObject
  warning: TextContent
  warningButton: string
  warningButtonNudge: Nudge
  warningButtonStyle: Style
  warningIcon: RefType
  warningNudge: Nudge
  warningStyle: Style
}

export const getColor = (c: Color) => `rgb(${c.r}, ${c.g}, ${c.b})`

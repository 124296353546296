import React from 'react'
import { PressableOpacity, Text, theme } from '@vatom/wombo'
import { IPressableProps } from 'native-base'

interface ButtonTransparentProps extends IPressableProps {
  title: string
  onPress: () => void
}
export const ButtonTransparent = ({ title, onPress, ...props }: ButtonTransparentProps) => {
  return (
    <PressableOpacity
      accessibilityRole="button"
      alignItems="center"
      alignSelf="stretch"
      borderColor={theme.colors.grayCool[400]}
      borderRadius={3}
      _dark={{
        borderColor: '#FFFFFF4D'
      }}
      borderWidth={1}
      height={10}
      justifyContent="center"
      marginX={4}
      onPress={onPress}
      backgroundColor={'transparent'}
      {...props}
    >
      <Text color={theme.colors.bodyText} _dark={{ color: theme.colors.textDarkMode[200] }}>
        {title}
      </Text>
    </PressableOpacity>
  )
}

export default ButtonTransparent

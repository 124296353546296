import React from 'react'
import { StyleSheet, Text, TouchableOpacity } from 'react-native'

import { useCommunitiesTheme } from '../../../../themes'

import VoteIcon from './VoteIcon'

type VoteButtonProps = {
  eventType: 'like' | 'dislike'
  isActive: boolean
  votesCount: Array<any>
  handleEvent: (eventType: 'like' | 'dislike') => void
}

const VoteButton = ({ eventType, isActive, votesCount, handleEvent }: VoteButtonProps) => {
  const communitiesTheme = useCommunitiesTheme()

  return (
    <>
      <TouchableOpacity
        accessibilityRole="button"
        onPress={() => handleEvent(eventType)}
        style={[
          styles.voteButton,
          {
            backgroundColor: isActive
              ? communitiesTheme.accentColor
              : communitiesTheme.voteIcon.backgroundColor
          }
        ]}
      >
        <VoteIcon type={eventType} isActive={isActive} />
      </TouchableOpacity>
      <Text style={styles.votesCounter}>{votesCount.length}</Text>
    </>
  )
}

export default VoteButton

const styles = StyleSheet.create({
  voteButton: {
    width: 32,
    height: 32,
    borderRadius: 40,
    alignItems: 'center',
    justifyContent: 'center'
  },
  votesCounter: {
    fontSize: 15,
    color: '#868E96',
    marginLeft: 4,
    marginRight: 16
  }
})

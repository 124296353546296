import React, { forwardRef } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import Animated, { useAnimatedStyle } from 'react-native-reanimated'
import { TokenType, ViewMode } from '@vatom/sdk/core'
import { observer } from 'mobx-react-lite'

import ErrorFace from './Faces/ErrorFace'
import { useFaces } from './FaceProvider'

export type FaceHandler = {
  sendRequest?: (name: string, data: any) => Promise<any>
}

interface FaceProps {
  viewMode?: ViewMode
  onMessage?: (name: string, payload: any) => Promise<void>
  token: TokenType
  hide?: boolean
  style?: StyleProp<ViewStyle>
}
export const Face = observer(
  forwardRef((props: FaceProps, ref: any) => {
    const { token, viewMode: viewModeProp, hide = false, ...rest } = props
    const faces = useFaces()

    const viewMode = viewModeProp || 'icon'
    const faceId = token?.getFaceId(viewMode)

    const animatedStyle = useAnimatedStyle(() => {
      return {
        opacity: hide ? 0 : 1,
        display: hide ? 'none' : 'flex'
      }
    }, [hide])

    if (!token || !faceId) return null
    const Face = faces[faceId]
    if (!Face) return null

    const faceProps = {
      token,
      ref,
      modified: token.modified,
      viewMode,
      ...rest,
      url: token?.metadata?.animation_url
    }
    return (
      <Animated.View
        style={[
          {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            width: '100%'
          },
          animatedStyle
        ]}
      >
        {Face ? (
          // @ts-expect-error this error needs deeper look. Anyone who's going to work on face and see this should remove this comment and see what's happening
          <Face {...faceProps} />
        ) : (
          <ErrorFace token={token} {...rest} errorMessage="Face not Found" />
        )}
      </Animated.View>
    )
  })
)

/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { NftModel, NftModelType } from "./NftModel"
import { NftModelSelector } from "./NftModel.base"
import { RootStoreType } from "./index"


/**
 * BidReceiptBase
 * auto generated base class for the model BidReceiptModel.
 */
export const BidReceiptModelBase = ModelBase
  .named('BidReceipt')
  .props({
    __typename: types.optional(types.literal("BidReceipt"), "BidReceipt"),
    address: types.union(types.undefined, types.string),
    tradeState: types.union(types.undefined, types.string),
    buyer: types.union(types.undefined, types.frozen()),
    metadata: types.union(types.undefined, types.frozen()),
    auctionHouse: types.union(types.undefined, types.frozen()),
    price: types.union(types.undefined, types.frozen()),
    tradeStateBump: types.union(types.undefined, types.integer),
    tokenAccount: types.union(types.undefined, types.null, types.string),
    createdAt: types.union(types.undefined, types.frozen()),
    canceledAt: types.union(types.undefined, types.null, types.frozen()),
    bookkeeper: types.union(types.undefined, types.frozen()),
    purchaseReceipt: types.union(types.undefined, types.null, types.frozen()),
    tokenSize: types.union(types.undefined, types.integer),
    bump: types.union(types.undefined, types.integer),
    nft: types.union(types.undefined, types.null, types.late((): any => NftModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class BidReceiptModelSelector extends QueryBuilder {
  get address() { return this.__attr(`address`) }
  get tradeState() { return this.__attr(`tradeState`) }
  get buyer() { return this.__attr(`buyer`) }
  get metadata() { return this.__attr(`metadata`) }
  get auctionHouse() { return this.__attr(`auctionHouse`) }
  get price() { return this.__attr(`price`) }
  get tradeStateBump() { return this.__attr(`tradeStateBump`) }
  get tokenAccount() { return this.__attr(`tokenAccount`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get canceledAt() { return this.__attr(`canceledAt`) }
  get bookkeeper() { return this.__attr(`bookkeeper`) }
  get purchaseReceipt() { return this.__attr(`purchaseReceipt`) }
  get tokenSize() { return this.__attr(`tokenSize`) }
  get bump() { return this.__attr(`bump`) }
  nft(builder: string | NftModelSelector | ((selector: NftModelSelector) => NftModelSelector) | undefined) { return this.__child(`nft`, NftModelSelector, builder) }
}
export function selectFromBidReceipt() {
  return new BidReceiptModelSelector()
}

export const bidReceiptModelPrimitives = selectFromBidReceipt().address.tradeState.buyer.metadata.auctionHouse.price.tradeStateBump.tokenAccount.createdAt.canceledAt.bookkeeper.purchaseReceipt.tokenSize.bump

import React, { useCallback } from 'react'
import { isMobile } from 'react-device-detect'
import { Keyboard, Platform, TouchableWithoutFeedback } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { useLeaveRoom, useSpace } from '@vatom/sdk/react'
import { Text, theme } from '@vatom/wombo'
import { Actionsheet, Box, HStack, Icon, Pressable, useColorModeValue } from 'native-base'

import Alert from '../../../components/Alert'
import { useMaxWidth } from '../../../components/WebWrapper'
import { AppRoutes } from '../../../navigators'
import useSpaceFromNavParams from '../../Space/useSpaceFromNavParams'

import { SpaceRoom } from './SectionSpaces'

type ModalTypes = {
  showModal: boolean
  spaceRoom: SpaceRoom
  onDismiss: () => void
}

type ActionTypes = {
  label: string
  iconName: string
  color?: string
  onPress: () => void
}

export const SpaceActionsModal = ({ showModal, onDismiss, spaceRoom }: ModalTypes) => {
  const { space } = useSpaceFromNavParams({ spaceId: spaceRoom.spaceId })
  const maxWidth = useMaxWidth()
  const navigation = useNavigation()
  const leacerRoomMutation = useLeaveRoom()
  const { removeSpace } = useSpace()

  const actionColor = useColorModeValue(
    theme.colors.textLightMode[900],
    theme.colors.textDarkMode[100]
  )
  const cancelColor = useColorModeValue(
    theme.colors.systemColorsLight.red,
    theme.colors.systemColorsDark.red
  )

  /** Start a call to the specified space */
  const openSpace = (audioOnly = false) => {
    // Show screen
    navigation.navigate(AppRoutes.Metaverse, {
      spaceId: spaceRoom.spaceId,
      type: audioOnly ? 'call' : 'metaverse'
    })
  }

  const openRoom = () => {
    navigation.navigate(AppRoutes.Room, {
      business: space?.businessId ?? '',
      spaceId: space?.id ?? ''
    })
  }

  const forgetRoom = async () => {
    const result = await Alert.fire({
      title: 'Remove space?',
      text: `Are you sure you want to leave this Space?`,
      showCancelButton: true
    })

    // @ts-expect-error wrong type from result
    if (Platform.OS === 'web' ? !result?.isConfirmed : !result) return

    if (spaceRoom.matrixRoomId) {
      leacerRoomMutation.mutate(spaceRoom.matrixRoomId)
    } else if (spaceRoom.spaceId) {
      removeSpace(spaceRoom.spaceId)
    }
  }

  const actions: ActionTypes[] = [
    {
      label: 'Open Discussion',
      iconName: 'chat-outline',
      onPress: () => openRoom()
    },
    {
      label: 'Start Call',
      iconName: 'phone-outline',
      onPress: () => openSpace(true)
    }
  ]

  if (
    (isMobile && space?.compatibility?.mobile) || // mobile browser
    (Platform.OS !== 'web' && space?.compatibility?.mobile) || // native apps
    (!isMobile && Platform.OS === 'web') // desktop browser
  ) {
    actions.push({
      label: 'Visit 3D Space',
      iconName: 'cube-outline',
      onPress: () => openSpace()
    })
  }

  actions.push({
    label: 'Leave',
    iconName: 'delete-outline',
    color: cancelColor,
    onPress: forgetRoom
  })

  const closeModal = useCallback(() => {
    Keyboard.dismiss()
    onDismiss()
  }, [onDismiss])

  return (
    <TouchableWithoutFeedback
      style={{ height: '100%' }}
      onPress={Keyboard.dismiss}
      accessible={false}
    >
      <Actionsheet
        isOpen={showModal}
        onClose={closeModal}
        maxWidth={maxWidth}
        margin={'auto'}
        useRNModal={true}
        // @ts-ignore prop doesn't exit on types
        _backdropFade={{
          entryDelay: 150,
          exitDelay: 10
        }}
      >
        <Actionsheet.Content
          _light={{
            backgroundColor: theme.colors.white
          }}
          _dark={{
            backgroundColor: theme.colors.grayDarkMode[900]
          }}
        >
          <Box marginBottom="6px" width="100%" px="16px">
            {actions.map(action => (
              <Pressable
                alignItems="center"
                accessibilityRole="button"
                onPress={() => {
                  action.onPress()
                  onDismiss()
                }}
                key={`${action.label}-space-action`}
              >
                <Box
                  flexDir="row"
                  width="100%"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  h="48px"
                >
                  <HStack alignItems="center" h="48px" width="90%">
                    <Icon
                      marginRight="8px"
                      as={MaterialCommunityIcons}
                      name={action.iconName}
                      w="24px"
                      h="24px"
                      color={action.color ?? actionColor}
                    />
                    <Text
                      lineHeight={20}
                      letterSpacing={0}
                      fontWeight={400}
                      fontSize={15}
                      color={action.color ?? actionColor}
                    >
                      {action.label}
                    </Text>
                  </HStack>
                </Box>
              </Pressable>
            ))}
          </Box>
        </Actionsheet.Content>
      </Actionsheet>
    </TouchableWithoutFeedback>
  )
}

import Logger from '../../../logger'

const Share = {
  async open({ message }: { message: string }) {
    try {
      if (navigator.share) {
        navigator.share({ url: message })
        return true
      }
    } catch (error) {
      Logger.warn('navigator.share is not available')
    }
    return false
  }
}

interface ShareOptions {}

export { ShareOptions }

export default Share

import React, { FC } from 'react'
import { SvgProps } from 'react-native-svg'
import { useNavigation } from '@react-navigation/native'
import { useGetSpace, useMatrixUser } from '@vatom/sdk/react'

import { useBusinessSelector } from '../../../../hooks/useBusinessSelector'
import { AppNavigation, navigateToConnectScreen, RoomRoutes } from '../../../../navigators'
import ModalObjectIcon from '../../assets/fab-modal-icons/fab_modal_object_icon.svg'
import ModalPollIcon from '../../assets/fab-modal-icons/fab_modal_poll_icon.svg'
import ModalPostIcon from '../../assets/fab-modal-icons/fab_modal_post_icon.svg'
import ModalProposalIcon from '../../assets/fab-modal-icons/fab_modal_proposal_icon.svg'
import ModalSketchIcon from '../../assets/fab-modal-icons/fab_modal_sketch_icon.svg'
import ModalVoteIcon from '../../assets/fab-modal-icons/fab_modal_vote_icon.svg'
import { usePlugins, useRoomPowerLevels } from '../../queries'
import { useCommunitiesTheme } from '../../themes'
import { useCommunitySpace } from '../../useCommunitySpace'
import ModalOption from '../modal-components/ModalOption'

import PopModalWrapper from './PopModalWrapper'

type IconMapping = {
  [key: string]: {
    icon: FC<SvgProps>
  }
}

type ModelButtonProps = {
  spaceId: string
  messageType: {
    id: string
    name: string
  }
  setIsModalVisible: (isVisible: boolean) => void
}

const ModalButton = (props: ModelButtonProps) => {
  const { business } = useBusinessSelector()
  const businessId = business?.id ?? ''
  const { spaceId, messageType, setIsModalVisible } = props
  const navigation = useNavigation<AppNavigation>()
  const { community } = useCommunitySpace()
  const { id, name } = messageType

  const communitiesTheme = useCommunitiesTheme()

  const iconMap: IconMapping = {
    'm.room.message': {
      icon: ModalPostIcon
    },
    'v.room.object': {
      icon: ModalObjectIcon
    },
    'v.room.poll': {
      icon: ModalPollIcon
    },
    'v.room.score': {
      icon: ModalPollIcon
    },
    'v.room.sketch': {
      icon: ModalSketchIcon
    },
    'v.room.proposal': {
      icon: ModalProposalIcon
    },
    'v.room.vote': {
      icon: ModalVoteIcon
    }
  }

  const Icon = iconMap[id]?.icon

  return (
    <ModalOption
      handler={() => {
        setIsModalVisible(false)
        navigateToConnectScreen(navigation, RoomRoutes.RoomNewMessage, {
          messageType: id,
          business: businessId,
          spaceId,
          community
        })
      }}
      title={name}
    >
      {id in iconMap ? (
        <Icon style={{ marginRight: 13 }} fill={communitiesTheme.labelIconColor} />
      ) : null}
    </ModalOption>
  )
}

type SelectMessageTypeModalProps = {
  spaceId: string
  isModalVisible: boolean
  setIsModalVisible: (arg0: boolean) => void
}

const SelectMessageTypeModal = (props: SelectMessageTypeModalProps) => {
  const { spaceId, isModalVisible, setIsModalVisible } = props
  const { data: matrixUser } = useMatrixUser()
  const space = useGetSpace(spaceId, {
    enabled: false
  })
  const roomId = space.data?.matrixRoomId ?? ''

  const { data: plugins } = usePlugins()
  const pluginData = plugins[0]

  const messageTypes = pluginData.descriptor.facades.message

  const { data: powerLevels } = useRoomPowerLevels(roomId)
  const currentUserPowerLevel = powerLevels?.users[matrixUser?.user_id ?? ''] ?? 0

  return messageTypes.length > 0 ? (
    <PopModalWrapper isModalVisibl={isModalVisible} setIsModalVisible={setIsModalVisible}>
      {powerLevels
        ? messageTypes.map((messageType: any) => {
            const { id } = messageType

            const { events } = powerLevels
            if (events[id] > currentUserPowerLevel) {
              return null
            }
            if (!events[id]) {
              if (id !== 'm.room.message' && currentUserPowerLevel < 90) {
                return null
              }
            }

            return (
              <ModalButton
                key={id}
                setIsModalVisible={setIsModalVisible}
                spaceId={spaceId}
                messageType={messageType}
              />
            )
          })
        : null}
    </PopModalWrapper>
  ) : null
}

export default SelectMessageTypeModal

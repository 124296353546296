import React, { memo } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Image } from 'expo-image'

import RightArrow from '../../assets/arrow-icons/right-arrow-icon.svg'
import RoomLockIcon from '../../assets/room_lock_icon.svg'
import { useCommunitiesTheme } from '../../themes'

export function getRoomVisibility(additionalAuth: any): string {
  if (additionalAuth.code) return 'password'
  if (additionalAuth.whitelist) return 'whitelist'
  if (additionalAuth.tokenGated) return 'token gated'
  if (additionalAuth.invite) return 'invite'
  if (additionalAuth.knock) return 'knock'
  return 'public'
}

type RoomItemProps = {
  room: any
  categoryId?: string
  onPress: () => void
}

const RoomItem = ({ room, categoryId, onPress }: RoomItemProps) => {
  const { displayName, cover, members, additionalAuth } = room

  const memberType = members > 1 ? 'Members' : members === 0 ? 'Members' : 'Member'

  const communitiesTheme = useCommunitiesTheme()

  const roomVisibility = getRoomVisibility(additionalAuth)

  return (
    <TouchableOpacity accessibilityRole="button" onPress={onPress}>
      <View
        style={{
          ...styles.rowContainer,
          marginBottom: 8,
          marginTop: 8,
          justifyContent: 'space-between'
        }}
      >
        <View style={{ flexDirection: 'row' }}>
          <Image accessibilityIgnoresInvertColors source={cover} style={styles.roomImage} />
          <View>
            <Text style={[styles.roomName, { color: communitiesTheme.roomItem.name }]}>
              {displayName}
            </Text>
            <View style={styles.rowContainer}>
              {roomVisibility !== 'public' ? <RoomLockIcon style={{ marginRight: 3.5 }} /> : null}
              <Text style={[styles.roomSubInfo, { color: communitiesTheme.roomItem.sub }]}>
                {roomVisibility} · <Text style={{ fontWeight: 'bold' }}>{members} </Text>
                {memberType}
              </Text>
            </View>
          </View>
        </View>
        <RightArrow style={{ marginRight: 16 }} fill={communitiesTheme.roomItem.sub} />
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  roomSubInfo: {
    fontSize: 11,
    lineHeight: 15,
    textTransform: 'capitalize'
  },
  roomName: {
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20
  },
  roomImage: {
    height: 40,
    width: 40,
    marginLeft: 16,
    marginRight: 8,
    borderRadius: 4
  }
})

export default memo(RoomItem)

import React from 'react'
import { Linking } from 'react-native'
import {
  Channels,
  DeviceContact,
  deviceContactTypes,
  useGetInviteLink,
  useNotificationsStore
} from '@vatom/sdk/react'
import { Avatar, Text } from '@vatom/wombo'
import { Box, Pressable } from 'native-base'

export interface IItemProps {
  item: DeviceContact
}

export const ItemInvitePeople = ({ item }: IItemProps) => {
  const isInvited = useNotificationsStore(
    state => !!state.channels[Channels.invited]?.some(contactValue => contactValue === item.value)
  )

  const inviteLink = useGetInviteLink()
  const inviteUser = async () => {
    if (inviteLink.data?.shareUrl) {
      useNotificationsStore.getState().addNotification(Channels.invited, item.value)
      const message = `Click this ${inviteLink.data?.shareUrl} to join Vatom!`
      if (item.type === deviceContactTypes.phoneNumber) {
        Linking.openURL(`sms:/open?addresses=${item.value}&body=${message}`)
      } else if (item.type === deviceContactTypes.email) {
        Linking.openURL(`mailto:${item.value}?subject=Join Vatom&body=${message}`)
      }
    }
  }

  return (
    <Box
      alignItems="center"
      borderBottomWidth={1}
      borderColor="gray.50"
      flexDir="row"
      h={62}
      mx={4}
      py={3}
    >
      <Avatar size={44} />
      <Box ml={3} flex={1}>
        <Text
          color="darkText"
          fontFamily="Inter-Bold"
          fontSize={15}
          ellipsizeMode="tail"
          w="95%"
          noOfLines={1}
        >
          {item.name}
        </Text>
        <Text
          fontFamily="Inter-Bold"
          fontSize={11}
          fontWeight={400}
          lineHeight={15}
          ellipsizeMode="tail"
          w="95%"
          noOfLines={1}
        >
          {item.value}
        </Text>
      </Box>
      {isInvited ? (
        <Pressable
          accessibilityRole="button"
          alignItems="center"
          justifyContent="center"
          onPress={inviteUser}
          borderColor="extraLightText"
          bgColor="extraLightText"
          borderWidth={1}
          w="80px"
          h="32px"
        >
          <Text fontSize={12}>Resend</Text>
        </Pressable>
      ) : (
        <Pressable
          accessibilityRole="button"
          alignItems="center"
          justifyContent="center"
          onPress={inviteUser}
          borderColor="extraLightText"
          borderWidth={1}
          w="80px"
          h="32px"
        >
          <Text fontSize={12} tx="common.invite" />
        </Pressable>
      )}
    </Box>
  )
}

export default ItemInvitePeople

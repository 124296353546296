import React, { useMemo } from 'react'
import { TEthereumChains, TSolanaChains } from '@vatom/sdk/core'
import { Button } from '@vatom/wombo'
import { Image, Link, Text } from 'native-base'

interface TransactionLinkProps {
  hash?: string
  chain?: string
}

export const TransactionLink = ({ hash, chain }: TransactionLinkProps) => {
  const minimizeHash = () => {
    if (!hash) return ''
    const first = hash.substring(0, 10)
    const last = hash.split('').reverse().join('').substring(0, 10).split('').reverse().join('')
    return `${first}...${last}`
  }

  const chainHref = useMemo(() => getChainScanId(chain, hash), [chain, hash])
  if (chainHref === '') {
    return null
  }

  return (
    <Link href={chainHref} flexDirection="row" marginBottom="30px" marginTop="10px" isExternal>
      <Text fontFamily="Inter-Regular" fontSize="11px" color="lightBlue.500">
        {minimizeHash().toUpperCase()}
      </Text>
      <Button padding={0} bgColor="transparent">
        <Image
          accessibilityIgnoresInvertColors={true}
          size="16px"
          source={require('../../ConfirmationWeb3/assets/external-link.png')}
          alt="external-link"
        />
      </Button>
    </Link>
  )
}

export const getChainScanId = (chain?: string, hash?: string) => {
  if (!chain) return ''

  const faucets = {
    eth: 'https://etherscan.io/tx/',
    polygon: 'https://polygonscan.com/tx/',
    bsc: 'https://bscscan.com/tx/',
    goerli: 'https://goerli.etherscan.io/tx/',
    mainnet: 'https://solscan.io/tx/'
  }

  return faucets[chain.toLocaleLowerCase() as keyof typeof faucets] + hash || faucets.eth + hash
}

export default TransactionLink

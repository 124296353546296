import React from 'react'
import { UserSnapshot } from '@vatom/sdk/core'
import { useConfig } from '@vatom/sdk/react'
import { Box } from 'native-base'

import Content from './Content'
import WalletPreference from './WalletPreferrence'
import { LinkingComponent, LinkingType } from './'

interface LinkingDataItemProps {
  type: LinkingType
  label: string
  href?: string
  onPress?: () => void
}

interface IMetamaskProfileProps {
  user?: UserSnapshot
  logoutOrDisconnect: () => void
  openWalletQrCode: (walletAddress: string) => void
  background: string
}

export const MetamaskProfile = ({
  user,
  logoutOrDisconnect,
  openWalletQrCode,
  background
}: IMetamaskProfileProps) => {
  const config = useConfig()
  const linkingData: Array<LinkingDataItemProps> = [
    { type: LinkingType.externalLink, label: 'Feedback', href: 'https://feedback.vatom.com/' },
    {
      type: LinkingType.externalLink,
      label: 'Terms Of Use',
      href: config.termsOfUseURL
    },
    {
      type: LinkingType.externalLink,
      label: 'Privacy Policy',
      href: config.privacyPolicyURL
    }
  ]

  return (
    <Box background="white">
      <Content />
      {/* <IdentityList background={background} openQrCode={openWalletQrCode} /> */}
      {/* <ActionItem onPress={() => true} title="Add email or phone number" /> */}
      {/* <Box m={1} /> */}
      <WalletPreference />
      {linkingData.map(({ label, href, type }, index) => (
        <LinkingComponent key={`Linking${index}`} type={type} label={label} href={href} />
      ))}
      {/* <ButtonTransparent marginTop="50px" title="Log Out" onPress={logoutOrDisconnect} /> */}
      {/* <VersionInfo /> */}
    </Box>
  )
}

export default MetamaskProfile

import React, { useRef } from 'react'
import Animated from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { translate } from '@vatom/utils'
import { theme, Toast } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import {
  Box,
  HStack,
  Input,
  Pressable,
  Text,
  useColorModeValue,
  VStack,
  WarningIcon
} from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import { ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { AppStackScreenProps } from '../../navigators'

const mockNetwork = {
  id: 'network-id-123',
  name: 'Ethereum',
  rpc: 'https://chain-proxy.wallet.com',
  chainId: '1',
  symbol: 'ETH',
  blockUrl: 'https://etherscan.io'
}
function useBlockChainNetwork(networkId: string) {
  return {
    data: mockNetwork,
    isLoading: false,
    isError: false
  }
}

export const BlockChainNetworkEdit = observer(
  (props: AppStackScreenProps<'BlockChainNetworkEdit'>) => {
    const { networkId } = props.route.params

    const { data: networkData } = useBlockChainNetwork(networkId)

    // TODO: update value
    const canEdit = true
    // TODO: update value
    const isSaveEnabled = false

    const { pageTheme } = useBusinessTheme()
    const scrollRef = useRef<Animated.ScrollView | null>(null)
    const insets = useSafeAreaInsets()

    const headerIconColor = useColorModeValue(
      theme.colors.textLightMode[900],
      theme.colors.textDarkMode[100]
    )

    const onPressSave = useThrottledCallback(
      () => {
        console.log('LOG: BlockchainNetwork > Save!!')
        Toast({
          title: 'Save network not implemented yet!',
          status: 'warning',
          duration: 1500
        })
      },
      300,
      {
        leading: true
      }
    )

    return (
      <ScreenWrapper
        screenBackgroundColor={pageTheme.background}
        statusBarBackgroundColor={pageTheme.background}
        viewContainerStyle={{
          width: '100%',
          backgroundColor: pageTheme.background
        }}
        unsafe
      >
        <ScreenHeader
          headerLeftType={'back'}
          headerTextColor={headerIconColor}
          headerProps={{
            paddingTop: insets.top,
            paddingBottom: 1,
            overflow: 'hidden'
          }}
          centerContainerProps={{
            flex: 1,
            paddingX: 2
          }}
          leftContainerProps={{
            flex: 0,
            _web: {
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto'
            }
          }}
          rightContainerProps={{
            flex: 0,
            _web: {
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto'
            }
          }}
          headerRight={() => <ScreenHeaderButton />}
        >
          <Title preset="h5" variant="semibold" numberOfLines={1}>
            Edit Custom Network
          </Title>
        </ScreenHeader>

        <Animated.ScrollView
          ref={scrollRef}
          style={{
            paddingHorizontal: 16,
            marginTop: 12
          }}
          contentContainerStyle={{
            paddingBottom: 60 // headerHeight
          }}
        >
          <VStack flexGrow={1} marginTop={0}>
            <Box marginBottom={3}>
              <Text>Network Name</Text>
              <Input
                placeholder="Add a network name"
                isDisabled={!canEdit}
                defaultValue={networkData.name}
                marginTop={1}
              />
            </Box>
            <Box marginBottom={3}>
              <Text>RPC URL</Text>
              <Input
                placeholder="Add a RPC url"
                isDisabled={!canEdit}
                defaultValue={networkData.rpc}
                marginTop={1}
              />
            </Box>
            <Box marginBottom={3}>
              <Text>Chain ID</Text>
              <Input
                placeholder="Add a chain ID"
                isDisabled={!canEdit}
                defaultValue={networkData.chainId}
                marginTop={1}
              />
            </Box>
            <Box marginBottom={3}>
              <Text>Currency Symbol</Text>
              <Input
                placeholder="Add currency symbol"
                isDisabled={!canEdit}
                defaultValue={networkData.symbol}
                marginTop={1}
              />
            </Box>
            <Box marginBottom={3}>
              <Text>Block explorer URL (optional)</Text>
              <Input
                placeholder=""
                isDisabled={!canEdit}
                defaultValue={networkData.blockUrl}
                marginTop={1}
              />
            </Box>
          </VStack>

          {canEdit && (
            <Box marginTop={4} marginX={0}>
              <Pressable
                accessibilityRole="button"
                onPress={onPressSave}
                flexGrow={1}
                paddingY={3}
                paddingX={2}
                borderRadius={4}
                _light={{
                  backgroundColor: theme.colors.systemColorsLight.orange
                }}
                _dark={{
                  backgroundColor: theme.colors.systemColorsDark.orange
                }}
                _disabled={{
                  _light: {
                    backgroundColor: theme.colors.grayCool[400]
                  },
                  _dark: {
                    backgroundColor: theme.colors.grayDarkMode[600]
                  }
                }}
                disabled={!isSaveEnabled}
              >
                <HStack alignItems={'center'} justifyContent={'center'}>
                  <Text
                    fontSize={16}
                    lineHeight={22}
                    fontFamily={'Inter-Regular'}
                    letterSpacing={0.2}
                    _light={{
                      color: isSaveEnabled ? '#fff' : theme.colors.grayCool[200]
                    }}
                    _dark={{
                      color: isSaveEnabled ? '#fff' : theme.colors.textDarkMode[600]
                    }}
                  >
                    Save network
                  </Text>
                </HStack>
              </Pressable>
            </Box>
          )}
        </Animated.ScrollView>
      </ScreenWrapper>
    )
  }
)

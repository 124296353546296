import React from 'react'
import { TouchableOpacity, View } from 'react-native'

import ModalText from './ModalText'

const ModalOption = (props: any) => {
  const { handler, title, children, titleStyle } = props
  return (
    <TouchableOpacity accessibilityRole="button" onPress={() => handler()}>
      <View style={{ flexDirection: 'row', marginBottom: 28, alignItems: 'center' }}>
        <View style={{ width: 24, marginRight: 8 }}>{children}</View>
        <ModalText text={title} style={titleStyle} />
      </View>
    </TouchableOpacity>
  )
}

export default ModalOption

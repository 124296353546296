import React, { useCallback } from 'react'
import { Platform, StyleSheet } from 'react-native'
import { TokenType } from '@vatom/sdk/core'
import { BusinessData, Image, PageConfig, TokenBadges, useSDK } from '@vatom/sdk/react'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
// import { NFTCard } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { AspectRatio, Box } from 'native-base'
import { DebouncedState } from 'use-debounce'

import { TokenCard } from '../../../components/TokenCard/TokenCard'

type WalletItemProps = {
  item: TokenType
  pageConfig?: PageConfig
  business?: BusinessData
  onPress: DebouncedState<(item: TokenType, business?: BusinessData) => void>
  width?: number
}

export const onEndReachedThreshold = 0.7

const isPlatformNotWeb = Platform.OS !== 'web'

const Media = observer(({ item }: { item: TokenType }) => {
  const [error, setError] = React.useState(false)
  const defaultImage =
    'https://images.squarespace-cdn.com/content/v1/61e34043b0387b781e8fbaf7/1e0f129e-098c-4c86-a006-be77e1ad379c/MAIN+LOGO+VER+03-1.png?'

  const source = {
    // cache: 'force-cache',
    headers: {
      Accept: '*/*'
    },
    uri: error ? defaultImage : item.displayImage ?? defaultImage
  }

  if (item.type !== 'vatom') {
    const { faces } = item
    const iconFace = faces?.find(f => f.type === 'icon')

    if (iconFace) {
      const { animation_url, animation_url_type } = iconFace

      if (animation_url && animation_url_type?.includes('video')) {
        source.uri = getVideoGif(animation_url)
      }
    }
  }

  return isPlatformNotWeb ? (
    <Box style={{ alignItems: 'stretch' }}>
      <AspectRatio ratio={1}>
        <Image
          accessibilityIgnoresInvertColors
          alt={item?.metadata.name || 'token'}
          source={source}
          resizeMode={'contain'}
          style={styles.image}
        />
      </AspectRatio>
    </Box>
  ) : (
    <Image
      accessibilityIgnoresInvertColors
      alt={item?.metadata.name || 'token'}
      source={source}
      resizeMode={'cover'}
      style={styles.image}
      onError={() => setError(true)}
    />
  )
})

export const WalletItem = observer((props: WalletItemProps) => {
  const { business, onPress, item, pageConfig, width } = props
  const sdk = useSDK()
  const walletConfig = useVatomWalletSdkStore()
  const { showTitles, showBadges, showEditions } = sdk.walletPreference

  const {
    badges = true,
    titles = true,
    editions = true
  } = walletConfig.isEmbedded
    ? walletConfig.config.pageConfig?.features?.icon || {}
    : pageConfig?.features?.icon || {}

  const displayBadges = badges ?? showBadges
  const displayTitle = titles ?? showTitles
  const displayEditions = editions ?? showEditions

  const editionInfo =
    typeof item.editionInfo === 'object'
      ? `${item.editionInfo.numbered || '-'}/${
          item.editionInfo.scarcity === Infinity ? 'Inf' : item.editionInfo.scarcity
        }`
      : item.editionInfo

  const tokenName = item.metadata.name

  const handlePress = useCallback(() => onPress(item, business), [business, item, onPress])

  return (
    <TokenCard
      onPress={handlePress}
      width={width}
      borderWidth={!displayBadges && !displayTitle && !displayEditions ? 0 : 1}
      _web={{
        maxWidth: 180
      }}
    >
      {isPlatformNotWeb ? (
        <TokenCard.Media size={width} minHeight={width} minWidth={width}>
          <Media item={item} />
        </TokenCard.Media>
      ) : (
        <TokenCard.Media>
          <Media item={item} />
        </TokenCard.Media>
      )}

      {!displayBadges && !displayTitle && !displayEditions ? (
        <TokenCard.Content h={0} padding={0}>
          {displayTitle && (
            <TokenCard.Title>
              {item.quantity > 1 ? `${tokenName} (${item.quantity}x)` : tokenName}
            </TokenCard.Title>
          )}
          <TokenCard.Subtitle>{displayEditions ? editionInfo : ''}</TokenCard.Subtitle>
          {displayBadges && (
            <TokenCard.Footer>
              <TokenBadges token={item} style={styles.iconChain} />
            </TokenCard.Footer>
          )}
        </TokenCard.Content>
      ) : (
        <TokenCard.Content>
          {displayTitle && (
            <TokenCard.Title>
              {item.quantity > 1 ? `${tokenName} (${item.quantity}x)` : tokenName}
            </TokenCard.Title>
          )}
          <TokenCard.Subtitle>{displayEditions ? editionInfo : ''}</TokenCard.Subtitle>
          {displayBadges && (
            <TokenCard.Footer>
              <TokenBadges token={item} style={styles.iconChain} />
            </TokenCard.Footer>
          )}
        </TokenCard.Content>
      )}
    </TokenCard>
  )
})

const styles = StyleSheet.create({
  iconChain: {
    height: 17,
    marginRight: 13,
    resizeMode: 'contain',
    width: 17
  },
  image: {
    width: '100%',
    height: '100%',
    minWidth: '100%',
    minHeight: '100%',
    resizeMode: 'cover',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    objectFit: 'contain'
  }
})

const getVideoGif = (url: string) => {
  const encode = encodeURIComponent(url)
  return `https://resources.vatom.com/video-to-gif?url=${encode}`
}

import React, { useCallback, useMemo, useState } from 'react'
import { usePreventScroll } from 'react-aria'
import { Platform } from 'react-native'
import { useMutation } from '@tanstack/react-query'
import { IdentityType } from '@vatom/sdk/core'
import { useIdentities } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, KeyboardAvoidingView, useColorModeValue, VStack } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import Alert from '../../components/Alert'
import { ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import { withBusinessSelector } from '../../hooks/useBusinessSelector'
import { AppRoutes, AppStackScreenProps } from '../../navigators'
import SecondaryText from '../ActionSheets/partials/SecondaryText'
import { useToken } from '../NFTDetail/useNftDetail/useToken'

import CustomAddress from './partials/CustomAddress'
import MyAddress from './partials/MyAddress'

enum State {
  myAddress = 'myAddress',
  customAddress = 'customAddress',
  transactionSent = 'transactionSent'
}

export function getIdentityTypeFromNetwork(network?: string): IdentityType {
  switch (network) {
    case 'solana':
      return IdentityType.Solana
    case 'casper_testnet':
    case 'casper_mainnet':
      return IdentityType.Casper
    default:
      return IdentityType.Eth
  }
}

export type MintNFTProps = AppStackScreenProps<
  typeof AppRoutes.MintNFT | typeof AppRoutes.MintNFT_Business
>

export const MintNFT = withBusinessSelector(
  observer((props: MintNFTProps) => {
    const { navigation } = props
    const { tokenId, business } = props.route.params
    const { token } = useToken({
      tokenId
    })
    const [state, setState] = useState<State>(State.myAddress)
    const identityType = useMemo(
      () => getIdentityTypeFromNetwork(token?.blockchainInfo?.network),
      [token]
    )
    const { allowedIdentities } = useIdentities({ allowedTypes: [identityType] })
    const [selectedAddress, setSelectedAddress] = useState('')

    const tokenNetwork = useMemo(() => token?.blockchainInfo?.network, [token])

    usePreventScroll({})

    const headerIconColor = useColorModeValue(
      theme.colors.textLightMode[900],
      theme.colors.textDarkMode[100]
    )
    const backgroundColor = useColorModeValue(theme.colors.white, theme.colors.grayDarkMode[900])

    const mintMutation = useMutation({
      mutationKey: ['token-mint', token?.id],
      mutationFn: async () => {
        if (selectedAddress && tokenNetwork) {
          return await token.performAction('MintNFT', {
            'new.owner.address': selectedAddress
          })
        }
      },
      onError(error) {
        Alert.showError(error)
      },
      onSuccess(data) {
        navigation.navigate(AppRoutes.MintSuccess, {
          transactionId: data?.tx_hash,
          network: tokenNetwork ?? '',
          address: selectedAddress
        })
      }
    })

    const onSelectCustomAddress = useCallback(() => {
      //
      if (!mintMutation.isLoading) {
        setSelectedAddress('')
        setState(State.customAddress)
      }
    }, [mintMutation])

    const onSelectMyAddress = useCallback(() => {
      if (!mintMutation.isLoading) {
        setSelectedAddress('')
        setState(State.myAddress)
      }
    }, [mintMutation])

    const onClose = useCallback(() => {
      if (navigation.canGoBack()) {
        navigation.goBack()
      }
      if (business) {
        navigation.navigate(AppRoutes.NFTDetail_Business, {
          tokenId: token.id,
          business
        })
      }
      navigation.navigate(AppRoutes.NFTDetail, {
        tokenId: token.id
      })
    }, [navigation, business, token])

    const onMint = useThrottledCallback(
      async () => {
        mintMutation.mutate()
      },
      300,
      { leading: true }
    )

    const headerTitle = useMemo(() => {
      return state === State.myAddress
        ? translate('actionSheets.mintOn', {
            blockchain: token?.blockchainInfo?.networkName
          })
        : ''
    }, [state, token?.blockchainInfo?.networkName])

    return (
      <ScreenWrapper
        screenBackgroundColor={backgroundColor}
        statusBarBackgroundColor={backgroundColor}
      >
        <ScreenHeader
          headerLeftType={'close'}
          headerLeftPress={onClose}
          headerTextColor={headerIconColor}
          headerProps={{
            overflow: 'hidden'
          }}
          centerContainerProps={{
            flex: 1,
            paddingX: 2
          }}
          leftContainerProps={{
            flex: 0,
            _web: {
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto'
            }
          }}
          rightContainerProps={{
            flex: 0,
            _web: {
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto'
            }
          }}
          headerRight={() => <ScreenHeaderButton />}
        >
          <Box flexDirection={'column'} marginLeft={2} flex={1} justifyContent={'center'}>
            <Title preset="h5" variant="semibold" numberOfLines={1}>
              {headerTitle}
            </Title>
          </Box>
        </ScreenHeader>
        {/* CONTENT */}
        <KeyboardAvoidingView
          w="100%"
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          flexGrow={1}
        >
          <VStack paddingX={14} flexGrow={1}>
            {state !== State.transactionSent && (
              <Box marginTop={3} w="100%">
                <SecondaryText
                  hasLearnMore={true}
                  text={state === State.customAddress ? translate('mintNft.provideAddress') : ''}
                  token={token}
                />
              </Box>
            )}

            {state !== State.transactionSent && state === State.myAddress && (
              <MyAddress
                isLoading={mintMutation.isLoading}
                identities={allowedIdentities}
                onMint={onMint}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                onSelectCustomAddress={onSelectCustomAddress}
              />
            )}
            {state !== State.transactionSent && state === State.customAddress && (
              <CustomAddress
                isLoading={mintMutation.isLoading}
                onMint={onMint}
                mintAddress={selectedAddress}
                setMintAddress={setSelectedAddress}
                onSelectMyAddress={onSelectMyAddress}
              />
            )}
          </VStack>
        </KeyboardAvoidingView>
      </ScreenWrapper>
    )
  })
)

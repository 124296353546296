import { create } from 'zustand'

type SelectedBackup = {
  acct: string
  labl: string
  setSelectedBackup: (acct: string, labl: string) => void
}

export const useSelectedBackup = create<SelectedBackup>(set => ({
  acct: '',
  labl: '',
  setSelectedBackup: (acct: string, labl: string) =>
    set(() => {
      return {
        acct,
        labl
      }
    })
}))

import React, { useMemo } from 'react'
import { useSDK } from '@vatom/sdk/react'
import { PressableOpacity } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Text } from 'native-base'

import { NotificationOffIcon, NotificationOnIcon, SearchIcon } from '../../../components/Icons'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../../hooks/useIsDesktop'
import { ThemedHeaderWrapper } from '../../Home/partials/ThemedHeader'

const Header = observer(({ title }: { title: string }) => {
  const isDesktop = useIsDesktop()
  const { brandTheme, pageTheme } = useBusinessTheme()

  // const style = {
  //   background: brandTheme?.primary ?? pageTheme.background,
  //   text: brandTheme?.primaryText ?? pageTheme.active
  // }
  const style = {
    background: pageTheme.background,
    text: pageTheme.active
  }
  const CenterComponent = () => (
    <Box flexDir={'column'} flex={1} alignItems={'center'} justifyContent={'center'}>
      <Text color={isDesktop ? '#000' : style.text} fontSize={17}>
        {title}
      </Text>
      {/* <Text color={style.text} fontSize={12}>
        {numberOfRooms} spaces
      </Text> */}
    </Box>
  )

  const hasNotification = false
  const NotificationIcon = useMemo(
    () => (hasNotification ? NotificationOnIcon : NotificationOffIcon),
    [hasNotification]
  )

  const RightComponent = () => (
    <Box flexDirection={'row'}>
      <PressableOpacity onPress={() => console.log('Communities.Header > Search')}>
        <SearchIcon size={8} fill={style.text} />
      </PressableOpacity>
      <PressableOpacity onPress={() => console.log('Communities.Header > Notification')}>
        <NotificationIcon size={8} fill={style.text} />
      </PressableOpacity>
    </Box>
  )
  return (
    <ThemedHeaderWrapper
      centerComponent={<CenterComponent />}
      // rightComponent={<RightComponent />}
    />
  )
})

export default Header

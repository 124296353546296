import React, { useState } from 'react'
import { Platform, ScrollView } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { IdentitySnapshot, IdentityType, TokenTypes, UserIdentity } from '@vatom/sdk/core'
import { Contact, Destination, useTokenInventory } from '@vatom/sdk/react'
import { Text, theme } from '@vatom/wombo'
import { Box, FlatList, Pressable, Radio } from 'native-base'

import { AppRoutes, AppStackScreenProps } from '../../navigators'
import ScreenHeader from '../ActionSheets/partials/ScreenHeader'
import { getIdentityTypeFromNetwork } from '../MintNFT/MintNFT'
import { SendStorage } from '../SendNFT/SendProvider/Constants'
import { Sender } from '../SendNFT/SendProvider/Senders'
import SendNftDetails from '../SendNFT/SendProvider/SendNftDetails'

export type SendConfirmationProps = AppStackScreenProps<typeof AppRoutes.sendConfirmation>

export const SendConfirmation = (props: SendConfirmationProps) => {
  const { route, navigation } = props
  const { userToSend: rUserToSend, destination: rDestination, tokenId, isShare } = route.params

  let destination = rDestination
  let userToSend = rUserToSend

  if (Platform.OS === 'web' && !destination && !userToSend) {
    const dest = localStorage.getItem(SendStorage.Destination)
    const uts = localStorage.getItem(SendStorage.UserToSend)
    try {
      destination = dest ? JSON.parse(dest) : undefined
    } catch (error) {
      destination = undefined
    }

    try {
      userToSend = uts ? JSON.parse(uts) : undefined
    } catch (error) {
      userToSend = undefined
    }
  }

  return (
    <SendTokenView
      navigation={navigation}
      userToSend={userToSend}
      destination={destination}
      tokenId={tokenId}
      isShare={isShare}
    />
  )
}

export default SendConfirmation

type SendTokenViewProps = {
  userToSend?: Contact
  destination?: Destination
  tokenId: string
  isShare: boolean
  navigation: SendConfirmationProps['navigation']
}

const useIdentities = (props: SendTokenViewProps) => {
  let defaultIdentity = null
  let identityList = [] as IdentitySnapshot[]
  const { token } = useTokenInventory(props.tokenId)
  const type = token?.getTokenType()

  if (props.destination) {
    defaultIdentity = props.destination
  }
  if (type && props.userToSend) {
    const { identities } = props.userToSend
    if (
      type !== TokenTypes.VatomBasic &&
      type !== TokenTypes.VatomUnMinted &&
      type !== TokenTypes.VatomNew
    ) {
      let filteredIdentities = identities as IdentitySnapshot[]

      if (type === TokenTypes.VatomMintedCustodial || type === TokenTypes.VatomMintedNonCustodial) {
        filteredIdentities = identities?.filter(
          $ => $.type === getIdentityTypeFromNetwork(token?.blockchainInfo?.network)
        ) as IdentitySnapshot[]
      }

      if (type === TokenTypes.Solana) {
        filteredIdentities = identities?.filter(
          $ => $.type === IdentityType.Solana
        ) as IdentitySnapshot[]
      }

      if (type === TokenTypes.Erc721) {
        filteredIdentities = identities?.filter(
          $ => $.type === IdentityType.Eth
        ) as IdentitySnapshot[]
      }

      if (filteredIdentities?.length === 1) {
        defaultIdentity = filteredIdentities[0]
      } else {
        identityList = filteredIdentities
      }
    } else {
      const blockV = identities?.find($ => $.type === IdentityType.BlockV) as UserIdentity
      defaultIdentity = blockV
    }
  }

  return {
    defaultIdentity,
    identityList
  }
}

const SendTokenView = (props: SendTokenViewProps) => {
  const [selectedIdentity, setSelectedIdentity] = useState<
    Destination | IdentitySnapshot | undefined
  >(undefined)
  const { token } = useTokenInventory(props.tokenId)
  const route = useRoute<AppStackScreenProps<'sendConfirmation'>['route']>()

  const { identityList: identities, defaultIdentity } = useIdentities(props)

  return (
    <Box
      _light={{
        backgroundColor: theme.colors.white
      }}
      _dark={{
        backgroundColor: theme.colors.grayDarkMode[900]
      }}
      height="100%"
      safeArea
      alignItems={'flex-start'}
    >
      <ScreenHeader route={route} navigation={props.navigation} />
      <ScrollView style={{ margin: 'auto' }}>
        <Box
          width={'100%'}
          justifyContent={identities.length > 1 ? 'flex-start' : 'space-around'}
          alignItems="center"
          justifyItems="center"
          alignContent="center"
          height="100%"
          px="20px"
        >
          <Box marginTop={2}>
            <SendNftDetails
              destination={props.destination}
              tokenid={props.tokenId}
              user={props.userToSend}
              isShare={props.isShare}
            />
          </Box>
          {identities.length > 1 ? (
            <Box width="100%" h="175px" mb="100px">
              <Box alignItems="center" flexDir="row" justifyContent="space-between">
                <Text
                  color="darkText"
                  _dark={{ color: theme.colors.white }}
                  fontFamily="Inter-SemiBold"
                  fontSize="22px"
                >
                  Select Address
                </Text>
              </Box>
              <Radio.Group
                value={selectedIdentity?.value}
                name="walletAddressGroup"
                onChange={ident =>
                  setSelectedIdentity(props.userToSend?.identities?.find(i => i.value === ident))
                }
                style={{
                  width: '100%',
                  height: '100%'
                }}
              >
                <FlatList
                  data={identities}
                  style={{
                    width: '100%'
                  }}
                  renderItem={({ item, index }) => (
                    <Box
                      width="100%"
                      borderBottomWidth="1px"
                      borderBottomColor="light.100"
                      key={item.value}
                    >
                      <Text
                        color="lightText"
                        marginTop="16px"
                        marginBottom="8px"
                        fontFamily="Inter-Medium"
                        fontSize="12px"
                      >
                        {item.custodial ? 'Custodial' : item.type}
                      </Text>
                      <Pressable accessibilityLabel={`${item.value}`} accessibilityHint="">
                        <Box
                          aria-label="Close"
                          marginBottom="20px"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Text
                            ellipsizeMode="tail"
                            noOfLines={1}
                            fontFamily="Inter-Regular"
                            fontSize="11px"
                            // w="80%"
                          >
                            {item.value}
                          </Text>

                          <Radio
                            value={item.value}
                            aria-label="Close"
                            key={`${item.value}`}
                            accessibilityLabel={`${item.value}`}
                            accessibilityHint=""
                            alignSelf="flex-end"
                            mr="2px"
                          />
                        </Box>
                      </Pressable>
                    </Box>
                  )}
                  showsVerticalScrollIndicator={false}
                  keyExtractor={(item, index) => `identities-${index}`}
                />
              </Radio.Group>
            </Box>
          ) : null}
          {token ? (
            <Box alignSelf="flex-end" width="100%" pb="50px">
              <Sender
                token={token}
                userProfile={props.userToSend}
                destination={defaultIdentity ?? selectedIdentity}
                isShare={props.isShare}
              />
            </Box>
          ) : null}
        </Box>
      </ScrollView>
    </Box>
  )
}

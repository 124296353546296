import {
  createMaterialTopTabNavigator,
  MaterialTopTabBarProps
} from '@react-navigation/material-top-tabs'
import { useNavigationState } from '@react-navigation/native'
import { translate } from '@vatom/utils'

import { TabBar } from '../../../components/CommonHeader/TabBar'
import { ScreenHeaderWrapper } from '../../../components/ScreenHeaderWrapper'
import { ScreenWrapper } from '../../../components/ScreenWrapper'
import { useBusinessSelector } from '../../../hooks/useBusinessSelector'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../../hooks/useIsDesktop'
import {
  getActiveRouteName,
  MainTabScreenProps,
  SpacesRoutes,
  TabRoutes
} from '../../../navigators'
import Communities from '../../Communities'
import CommunitiesHome from '../../Communities/screens/CommunitiesHome'
import People from '../../People'
import { NavHeaderRight as PeopleHeaderRight } from '../../People/partials/NavHeaderRight'
import Spaces from '../../Spaces'

import { DrawerSelectorButton } from './DrawerSelectorButton'

const Tab = createMaterialTopTabNavigator()

const SpacesNavigatorTabsHeader = (props: MaterialTopTabBarProps) => {
  const isDesktop = useIsDesktop()
  const activeRouteName = useNavigationState(getActiveRouteName)
  return (
    <ScreenHeaderWrapper
      centerContainerProps={{
        flex: 1
      }}
      centerComponent={!isDesktop && <TabBar {...props} />}
      rightContainerProps={{
        // Force push to the right to allow the tab bar header to be centered
        // position: 'absolute',
        right: 2,
        zIndex: 2
      }}
      rightComponent={
        activeRouteName === SpacesRoutes.Spaces || activeRouteName === TabRoutes.Connect ? null : (
          <PeopleHeaderRight />
        )
      }
      leftContainerProps={{
        // position: 'absolute',
        left: 1,
        zIndex: 2
      }}
      leftComponent={<DrawerSelectorButton />}
    />
  )
}

const SpacesTabNavigator = (props: MainTabScreenProps<'Connect'>) => {
  const { businessIdentifier } = useBusinessSelector()
  const { pageTheme } = useBusinessTheme()
  // const {community} = props.route.params ?? {}

  if (businessIdentifier) {
    return (
      <ScreenWrapper
        unsafe
        // screenBackgroundColor={brandTheme?.background ?? pageTheme.background}
        // statusBarBackgroundColor={brandTheme?.primary ?? undefined}
        screenBackgroundColor={pageTheme.background}
        statusBarBackgroundColor={undefined}
      >
        {/* <CommunitiesHome {...props} /> */}
        <Communities {...props} />
      </ScreenWrapper>
    )
  }

  return (
    <ScreenWrapper unsafe screenBackgroundColor={pageTheme.background}>
      <Tab.Navigator
        initialRouteName={SpacesRoutes.Spaces}
        screenOptions={{
          lazy: true,
          swipeEnabled: false
        }}
        sceneContainerStyle={{
          minHeight: '100%',
          backgroundColor: 'transparent'
        }}
        tabBar={props => <SpacesNavigatorTabsHeader {...props} />}
      >
        <Tab.Screen
          options={{ tabBarLabel: translate('common.spaces') }}
          name={SpacesRoutes.Spaces}
          component={Spaces}
        />
        <Tab.Screen
          options={{ tabBarLabel: translate('common.people') }}
          name={SpacesRoutes.People}
          component={People}
        />
      </Tab.Navigator>
    </ScreenWrapper>
  )
}

// const SpacesNavigator = withBusinessSelector(SpacesTabNavigator)
export default SpacesTabNavigator

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react'
import { Platform } from 'react-native'
import { FaceHandler } from '@vatom/sdk/react'

import logger from '../../../logger'
import { AppRoutes, TabRoutes } from '../../../navigators'
import { NFTDetailProps } from '../NFTDetail'

import { useToken } from './useToken'
import { useTokenEvents } from './useTokenEvents'
import { useTokenInit } from './useTokenInit'

export interface IUseNftDetail {
  tokenId: string
  businessIdOrName?: string
  navigation: NFTDetailProps['navigation']
  faceRef: React.MutableRefObject<FaceHandler | undefined>
  route: NFTDetailProps['route']
}
export const useNftDetail = ({
  tokenId,
  businessIdOrName,
  navigation,
  faceRef,
  route
}: IUseNftDetail) => {
  const { token, isLoading } = useToken({
    tokenId,
    owner: route.params.owner,
    contractAddress: route.params.contractAddress
  })
  useTokenInit({ token, faceRef })

  const onClose = useCallback(() => {
    logger.info('[NFTDetal] onClose: ', token?.type, token?.parentId, token?.id, tokenId)
    if (Platform.OS === 'web') {
      window.scrollTo({ top: 0 })
    }
    if (navigation.canGoBack() && !businessIdOrName) {
      navigation.goBack()
      return
    }
    if (token?.type === 'vatom' && token.parentId !== '.') {
      if (businessIdOrName) {
        navigation.navigate(AppRoutes.NFTDetail_Business, {
          tokenId: token.parentId,
          business: businessIdOrName
        })
        return
      }
      navigation.navigate(AppRoutes.NFTDetail, {
        tokenId: token.parentId
      })
      return
    }

    if (businessIdOrName) {
      navigation.navigate(AppRoutes.BusinessProxy, {
        business: businessIdOrName,
        screen: TabRoutes.Wallet
      })
      return
    } else {
      navigation.navigate(AppRoutes.home, {
        screen: TabRoutes.Wallet
      })
      return
    }
  }, [businessIdOrName, navigation, token?.id, token?.parentId, token?.type, tokenId])

  const { handleMessage, isCameraOpen, setIsCameraOpen, qrPromise } = useTokenEvents({
    token,
    close: onClose
  })

  const [cardViewExpanded, setCardViewExpanded] = useState(false)

  useEffect(() => {
    if (!token && !isLoading) {
      logger.warn('[NFTDetail.render] no token')
      if (!isLoading) navigation.navigate(AppRoutes.home)
    }
  }, [token, navigation, isLoading])

  const onCardViewExpanded = useCallback(() => {
    setCardViewExpanded(true)
  }, [])

  const onCardViewCollapsed = useCallback(() => {
    setCardViewExpanded(false)
  }, [])

  const navigateToAR = () => {
    navigation.navigate(AppRoutes.TokenAR, { tokenId })
  }

  const isThreeD = token?.getFaceId('engaged') === 'ThreeDFace'

  return {
    token,
    onClose,
    isThreeD,
    handleMessage,
    navigateToAR,
    onCardViewCollapsed,
    onCardViewExpanded,
    cardViewExpanded,
    faceRef,
    isCameraOpen,
    setIsCameraOpen,
    qrPromise
  }
}

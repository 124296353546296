import { useRef } from 'react'

import { WebWrapper } from '../../components/WebWrapper'

import CoinTransfer, { CoinTransferProps } from './CoinTransfer'

export default (props: CoinTransferProps) => {
  const ref = useRef(null)

  return (
    <WebWrapper justifyContent={'center'} flexGrow={1}>
      <CoinTransfer ref={ref} {...props} />
    </WebWrapper>
  )
}

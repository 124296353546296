import React from 'react'
import { Platform } from 'react-native'
import Animated from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import {
  useConfig,
  useGetPublicProfile,
  useGetUserFollowers,
  useGetUserFollowing,
  useUser
} from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { Avatar, PressableOpacity, theme } from '@vatom/wombo'
import { Box, HStack, Link, ScrollView, Text, useColorModeValue } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import {
  AccountIcon,
  BackLightIcon,
  BlockchainNetworkIcon,
  BlockchainWalletIcon,
  ExternalLinkIcon,
  PayoutIcon,
  ProfileIcon,
  SettingsIcon,
  TopicIcon
} from '../../../components/Icons'
import VersionInfo from '../../../components/VersionInfo'
import { useAuthLogout } from '../../../hooks/useAuthLogout'
import { useIsDesktop } from '../../../hooks/useIsDesktop'
import { ButtonTransparent } from '../../../screens/ProfileUser/partials'
import { useDebugMode } from '../../../utils/useDebugMode'
import { AppRoutes } from '../../AppNavigator'

import { DrawerTabScreenProp, useDrawerContentContext } from './DrawerContent'

const isWeb = Platform.OS === 'web'

type ProfileDrawerProps = DrawerTabScreenProp<'ProfileDrawer'>

export const ProfileDrawer = ({ navigation }: ProfileDrawerProps) => {
  const isDebug = useDebugMode()
  const { appNavigation } = useDrawerContentContext()
  const insets = useSafeAreaInsets()
  const { doLogout } = useAuthLogout()

  const user = useUser()
  const { data } = useGetPublicProfile(user?.sub)
  const config = useConfig()

  const onPressBack = useThrottledCallback(
    () => {
      navigation.navigate('BusinessList')
    },
    500,
    {
      leading: true
    }
  )

  const onPressLogout = useThrottledCallback(
    () => {
      doLogout()
    },
    500,
    {
      leading: true
    }
  )

  const iconColor = useColorModeValue(
    theme.colors.textLightMode[600],
    theme.colors.textDarkMode[300]
  )

  const onPressProfile = useThrottledCallback(
    () => {
      console.log('LOG: > onPressProfile :')
      appNavigation.navigate('profileUser')
    },
    300,
    { leading: true }
  )

  const onPressAccount = useThrottledCallback(
    () => {
      if (isWeb) {
        window.open(AppRoutes.manageAccounts, 'profile')
        return
      }
      appNavigation.navigate(AppRoutes.manageAccounts)
    },
    300,
    { leading: true }
  )

  const onPressBlockchainWallet = useThrottledCallback(
    () => {
      console.log('LOG: > onPressBlockchainWallet:')
      appNavigation.navigate(AppRoutes.BlockChainWallets)
    },
    300,
    { leading: true }
  )

  const onPressBlockchainNetwork = useThrottledCallback(
    () => {
      appNavigation.navigate(AppRoutes.BlockChainNetwork)
    },
    300,
    { leading: true }
  )

  const onPressSettings = useThrottledCallback(
    () => {
      appNavigation.navigate(AppRoutes.Settings)
    },
    300,
    { leading: true }
  )

  const settings: SettingsOptions[] = [
    {
      type: 'button',
      title: 'Profile',
      icon: <ProfileIcon fill={iconColor} size={4} />,
      onPress: onPressProfile
    },
    {
      type: 'button',
      title: 'Account',
      icon: <AccountIcon fill={iconColor} size={4} />,
      onPress: onPressAccount,
      enabled: config.showManageAccountButton
    },
    {
      type: 'button',
      title: 'Blockchain Wallets',
      icon: <BlockchainWalletIcon fill={iconColor} size={4} />,
      onPress: onPressBlockchainWallet
    },
    {
      type: 'button',
      title: 'Blockchain Networks',
      icon: <BlockchainNetworkIcon fill={iconColor} size={4} />,
      onPress: onPressBlockchainNetwork,
      enabled: isDebug
    },
    {
      type: 'link',
      title: 'Payout',
      icon: <PayoutIcon fill={iconColor} size={4} />,
      href: `${config.studioServer}/${user?.default_business_id}/settings/payouts`,
      enabled: config.showBusinessProfile
    },
    {
      type: 'button',
      title: 'Settings',
      // subtitle: 'Manage your data',
      icon: <SettingsIcon fill={iconColor} size={4} />,
      onPress: onPressSettings
    }
  ]

  return (
    <Box flexGrow={1}>
      {!config.isBusinessLocked && <BackButton onPress={onPressBack} />}
      <ProfileCard
        title={user?.name ?? ''}
        subtitle={data?.preferred_username}
        picture={user?.picture ?? ''}
        userId={user?.sub ?? ''}
      />
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          marginHorizontal: 16
        }}
      >
        {settings.map(item => {
          if (item.enabled === false) {
            return null
          }
          switch (item.type) {
            case 'link':
              return (
                <SettingOptionLink
                  key={item.title}
                  title={item.title}
                  subtitle={item.subtitle}
                  icon={item.icon}
                  href={item.href}
                />
              )
            case 'button':
            default:
              return (
                <SettingOptionButton
                  key={item.title}
                  title={item.title}
                  subtitle={item.subtitle}
                  icon={item.icon}
                  onPress={item.onPress}
                />
              )
          }
        })}
      </ScrollView>
      <Box
        justifyContent={'flex-end'}
        paddingBottom={insets.bottom}
        borderTopWidth={1}
        _light={{
          borderColor: theme.colors.grayCool[200],
          backgroundColor: theme.colors.grayCool[50]
        }}
        _dark={{
          borderColor: theme.colors.grayDarkMode[700],
          backgroundColor: theme.colors.grayDarkMode[900]
        }}
      >
        <ProfileDrawerFooter onPressLogout={onPressLogout} />
      </Box>
    </Box>
  )
}

type SettingsOptions =
  | ({
      enabled?: boolean
      type: 'button'
    } & SettingOptionButtonProps)
  | ({
      enabled?: boolean
      type: 'link'
    } & SettingOptionLinkProps)

type SettingOptionLinkProps = {
  title: string
  subtitle?: string
  icon: React.ReactElement
  href: string
}

type SettingOptionButtonProps = {
  title: string
  subtitle?: string
  icon: React.ReactElement
  onPress: () => void
}

function SettingOptionButton({ title, subtitle, icon, onPress }: SettingOptionButtonProps) {
  return (
    <PressableOpacity onPress={onPress}>
      <SettingOption title={title} subtitle={subtitle} icon={icon} />
    </PressableOpacity>
  )
}
function SettingOptionLink({ title, subtitle, icon, href }: SettingOptionLinkProps) {
  const iconColorSecondary = useColorModeValue(
    theme.colors.textLightMode[300],
    theme.colors.textDarkMode[300]
  )
  return (
    <Link href={href} isExternal={true} width={'100%'}>
      <Box flexGrow={1}>
        <SettingOption title={title} subtitle={subtitle} icon={icon} />
      </Box>
      <Box alignSelf={'center'}>
        <ExternalLinkIcon fill={iconColorSecondary} width={14} height={16} />
      </Box>
    </Link>
  )
}
function SettingOption({
  title,
  subtitle,
  icon
}: {
  title: string
  subtitle?: string
  icon: React.ReactElement
}) {
  return (
    <HStack alignItems={'center'} paddingY={3}>
      <Box size={6} alignItems={'center'} justifyContent={'center'}>
        {icon}
      </Box>
      <Box marginLeft={2}>
        <Text
          fontFamily={'Inter-SemiBold'}
          fontSize={15}
          lineHeight={20}
          _light={{
            color: theme.colors.textLightMode[600]
          }}
          _dark={{
            color: theme.colors.textDarkMode[100]
          }}
        >
          {title}
        </Text>
        {subtitle && (
          <Text
            marginTop={1}
            fontFamily={'Inter-Regular'}
            fontSize={11}
            lineHeight={15}
            _light={{
              color: theme.colors.textLightMode[300]
            }}
            _dark={{
              color: theme.colors.textDarkMode[300]
            }}
          >
            {subtitle}
          </Text>
        )}
      </Box>
    </HStack>
  )
}

function ProfileDrawerFooter({ onPressLogout }: { onPressLogout: () => void }) {
  return (
    <Box flexShrink={0}>
      <Box marginX={4}>
        <ExternalLinks />
      </Box>
      <ButtonTransparent marginTop={6} title={translate('common.logout')} onPress={onPressLogout} />
      <Box marginTop={4}>
        <VersionInfo />
      </Box>
    </Box>
  )
}

type LinkingDataItemProps = {
  label: string
  href?: string
  onPress?: () => void
}

const ExternalLinks = () => {
  const config = useConfig()
  const externalLinks: Array<LinkingDataItemProps> = [
    {
      label: translate('common.feedback'),
      href: 'https://support.vatom.com/hc/en-us/requests/new' // TODO: update to config.supportURL
    },
    {
      label: translate('common.termsOfService'),
      href: config.termsOfUseURL
    },
    {
      label: translate('common.privacyPolicy'),
      href: config.privacyPolicyURL
    }
  ]
  const iconColor = useColorModeValue(
    theme.colors.textLightMode[600],
    theme.colors.textDarkMode[100]
  )
  const iconColorSecondary = useColorModeValue(
    theme.colors.textLightMode[300],
    theme.colors.textDarkMode[300]
  )

  return externalLinks.map(link => (
    <Link
      role="link"
      key={link.label}
      href={link.href}
      isExternal={true}
      accessibilityRole="button"
      flexGrow={1}
      alignItems={'center'}
      style={{
        height: 48
      }}
    >
      <Box size={8} marginRight={2} alignItems={'center'} justifyContent={'center'}>
        <TopicIcon fill={iconColor} width={14} height={16} />
      </Box>
      <Text
        flexGrow={1}
        fontSize={15}
        lineHeight={20}
        fontFamily="Inter"
        _light={{
          color: theme.colors.textLightMode[600]
        }}
        _dark={{
          color: theme.colors.textDarkMode[100]
        }}
      >
        {link.label}
      </Text>
      <Box alignSelf={'center'}>
        <ExternalLinkIcon fill={iconColorSecondary} width={14} height={16} />
      </Box>
    </Link>
  ))
}

type ProfileCardProps = {
  userId: string
  title: string
  picture: string
  subtitle?: string
}
const ProfileCard = ({ title, picture, subtitle, userId }: ProfileCardProps) => {
  return (
    <HStack alignItems="center" paddingX={4} paddingY={5}>
      <Animated.View sharedTransitionTag="profileDrawerCard">
        <Avatar url={picture} borderWidth={0} size={12} />
      </Animated.View>
      <Box marginLeft={3}>
        <Text
          fontSize={15}
          lineHeight={20}
          fontFamily="Inter-SemiBold"
          noOfLines={1}
          _light={{
            color: theme.colors.textLightMode[900]
          }}
          _dark={{
            color: theme.colors.textDarkMode[100]
          }}
        >
          {title}
        </Text>
        {subtitle && (
          <Text
            fontSize={11}
            lineHeight={15}
            noOfLines={1}
            _light={{
              color: theme.colors.textLightMode[300]
            }}
            _dark={{
              color: theme.colors.grayDarkMode[200]
            }}
          >
            {`@${subtitle}`}
          </Text>
        )}
        {/* Followers/Following */}
        <SocialStats userId={userId} />
      </Box>
    </HStack>
  )
}

function SocialStats({ userId }: { userId: string }) {
  const { data: followingUsers } = useGetUserFollowing(userId, {
    select: users => users.filter(contact => contact.type === 'user').length
  })

  const { data: followerUsers } = useGetUserFollowers(userId, {
    select: users => users.filter(contact => contact.type === 'user').length
  })

  return (
    <HStack flexGrow={1} marginTop={1}>
      <Box>
        <Text
          fontSize={11}
          lineHeight={15}
          noOfLines={1}
          _light={{
            color: theme.colors.textLightMode[300]
          }}
          _dark={{
            color: theme.colors.grayDarkMode[200]
          }}
        >
          <Text fontFamily={'Inter-Bold'}>{followerUsers ?? '-'}</Text>
          {` ${translate('common.followers')}`}
        </Text>
      </Box>
      <Box marginX={2} justifyContent={'center'}>
        <Text
          fontSize={12}
          lineHeight={8}
          noOfLines={1}
          _light={{
            color: theme.colors.textLightMode[300]
          }}
          _dark={{
            color: theme.colors.grayDarkMode[200]
          }}
          alignSelf={'center'}
        >
          {`.`}
        </Text>
      </Box>
      <Box>
        <Text
          fontSize={11}
          lineHeight={15}
          noOfLines={1}
          _light={{
            color: theme.colors.textLightMode[300]
          }}
          _dark={{
            color: theme.colors.grayDarkMode[200]
          }}
        >
          <Text fontFamily={'Inter-Bold'}>{followingUsers ?? '-'}</Text>
          {` ${translate('common.following')}`}
        </Text>
      </Box>
    </HStack>
  )
}

function BackButton({ onPress }: { onPress: () => void }) {
  const isDesktop = useIsDesktop()
  return (
    <PressableOpacity accessibilityRole="button" onPress={onPress} marginTop={4} marginLeft={4}>
      <Box flexDirection={'row'} alignItems={'center'} width={'100%'}>
        <BackLightIcon
          size={5}
          _light={{
            color: theme.colors.grayCool[900]
          }}
          _dark={{
            color: theme.colors.textDarkMode[300],
            _web: {
              color: isDesktop ? theme.colors.grayCool[700] : theme.colors.textDarkMode[300]
            }
          }}
        />
        <Text
          marginLeft={2}
          fontFamily={'Inter-SemiBold'}
          fontSize={13}
          lineHeight={18}
          _light={{
            color: theme.colors.grayCool[700]
          }}
          _dark={{
            color: theme.colors.textDarkMode[300],
            _web: {
              color: isDesktop ? theme.colors.grayCool[700] : theme.colors.textDarkMode[300]
            }
          }}
        >
          Back
        </Text>
      </Box>
    </PressableOpacity>
  )
}

import { useGetSearchSpaces } from '@vatom/sdk/react'
import { FlatList } from 'native-base'

import { EmptySpace } from './EmptySpace'
import { SpaceRoom } from './SectionSpaces'
import { SpacePreviewMemo } from './SpacePreview'

export const JustAddedRooms = ({
  onSelectSpaceRoom
}: {
  onSelectSpaceRoom: (spaceRoom: SpaceRoom) => void
}) => {
  const spaces = useGetSearchSpaces(
    {},
    {
      q: '',
      sort_by: 'created_at.desc',
      limit: 10
    }
  )

  return !spaces.data?.items?.length ? (
    <EmptySpace />
  ) : (
    <FlatList
      data={spaces.data?.items}
      renderItem={({ item }) => (
        <SpacePreviewMemo
          onSelectSpaceRoom={onSelectSpaceRoom}
          spaceId={item.id}
          businessId={item.businessId}
          matrixRoomId={item.matrixRoomId}
          displayName={item.displayName}
          cover={item.cover}
          isPrivate={false}
          memberCount={item.members}
        />
      )}
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      keyExtractor={(item, index) => `spaces-room-${item.matrixRoomId}-${index}`}
    />
  )
}

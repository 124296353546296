/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * NftCountBase
 * auto generated base class for the model NftCountModel.
 */
export const NftCountModelBase = ModelBase
  .named('NftCount')
  .props({
    __typename: types.optional(types.literal("NftCount"), "NftCount"),
    total: types.union(types.undefined, types.integer),
    listed: types.union(types.undefined, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class NftCountModelSelector extends QueryBuilder {
  get total() { return this.__attr(`total`) }
  get listed() { return this.__attr(`listed`) }
}
export function selectFromNftCount() {
  return new NftCountModelSelector()
}

export const nftCountModelPrimitives = selectFromNftCount().total.listed

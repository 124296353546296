import React from 'react'

import { useMessage, useMessageInputs } from '../../../queries'
import { useCommunitySpace } from '../../../useCommunitySpace'

import { getInputs } from './modal-helpers'
import ModalInput from './ModalInput'

export type PluginInput = {
  type: string
  placeholder: string
}

const ModalInputsHolder = React.forwardRef((props: any, ref: any) => {
  const { messageId, roomId, messageType, onFocus } = props
  const { businessId } = useCommunitySpace()
  const { data: matrixMessage } = useMessage(roomId, messageId)
  const { data: messagePluginInputs, isSuccess } = useMessageInputs([messageType], businessId)

  const trueInputs = (messagePluginInputs?.filter(
    ({ type }: { type: string }) => type !== 'decorations'
  ) ?? []) as PluginInput[]
  // const setInputs = () => {
  //   if (method === 'edit' || method === 'clone') {
  //     // if (!matrixMessage) {
  //     //   return
  //     // }
  //     // if (messageType === 'm.room.message') {
  //     //   fillInputsForSimplePost()
  //     // } else {
  //     //   const messageInputs = JSON.parse(matrixMessage.content.body)
  //     //   const replacedInputs = trueInputs?.map((input: { type: string }) => {
  //     //     const foundInput = messageInputs.elements.find(
  //     //       ({ type }: { type: string }) => type === input.type
  //     //     )
  //     //     return {
  //     //       ...input,
  //     //       value: foundInput.content
  //     //     }
  //     //   })
  //     //   store.setInputs(replacedInputs)
  //     //   const pollChoices = messageInputs.elements.find(
  //     //     ({ type }: { type: string }) => type === 'choices'
  //     //   )
  //     //   if (pollChoices) {
  //     //     store.initPollChoices(pollChoices.content)
  //     //   }
  //     // }
  //   } else {
  //     store.setInputs(trueInputs)
  //     if (messageType === 'v.room.poll') {
  //       const pollChoices = [
  //         { index: 1, value: '' },
  //         { index: 2, value: '' }
  //       ]
  //       store.initPollChoices(pollChoices)
  //     }
  //   }
  // }

  const inputs = isSuccess ? getInputs(messageType, trueInputs, matrixMessage) : []

  return inputs.length > 0
    ? inputs.map((input: any, index: number) => {
        return (
          <ModalInput
            key={`input-${index}`}
            input={input}
            ref={ref}
            focus={true}
            onFocus={onFocus}
          />
        )
      })
    : null
})

export default ModalInputsHolder

import React, { RefObject, useMemo } from 'react'
import { Pressable, ScrollView } from 'react-native'
import { RectButton } from 'react-native-gesture-handler'
import Swipeable, { SwipeableProps } from 'react-native-gesture-handler/Swipeable'
import { FontAwesome5 } from '@expo/vector-icons'
import {
  getVatomUserFromMatrixUserId,
  MsgType,
  RoomEvent,
  useDirectMessages,
  useGetPublicProfile,
  useMatrixUser,
  useMatrixUserProfile,
  useRoom,
  useUsersPresence
} from '@vatom/sdk/react'
import { Avatar, theme, Toast } from '@vatom/wombo'
import { Box, Text, useColorModeValue, View } from 'native-base'

import { TimeAgo } from '../../../components/TimeAgo'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'

function DmListItemBase({
  roomId,
  userIds,
  onPress,
  scrollRef,
  lastEvent
}: {
  roomId: string
  userIds: string[]
  onPress: () => void
  scrollRef: RefObject<ScrollView>
  lastEvent: RoomEvent
}) {
  const { pageTheme } = useBusinessTheme()

  const { data: matrixUser } = useMatrixUser()
  const userId = matrixUser?.user_id

  const { data: roomData } = useRoom(roomId)

  const { data: messages } = useDirectMessages({ roomId })

  const currentLastEvent = useMemo(() => {
    if (messages) {
      return messages.pages[0]
    }
    return lastEvent
  }, [lastEvent, messages])

  const otherUserId = userIds[0] ?? ''
  // TODO: add support for more that 1 user in room
  const { data: userData } = useMatrixUserProfile(otherUserId)
  const vatomUserId = getVatomUserFromMatrixUserId(otherUserId)
  // const usersDataVatom = useGetPublicProfiles([vatomUserId])
  const { data: userDataVatom } = useGetPublicProfile(vatomUserId)

  const { data: usersPresence } = useUsersPresence([otherUserId])

  const isUserOnline = usersPresence ? usersPresence[otherUserId]?.currently_active === true : false

  const badgeColor = useColorModeValue(
    theme.colors.systemColorsLight.green,
    theme.colors.systemColorsDark.green
  )
  const newMessageColor = useColorModeValue(
    theme.colors.systemColorsLight.red,
    theme.colors.systemColorsDark.red
  )

  const isLastMessageMine = currentLastEvent?.sender === userId

  // TODO: FIX THIS
  const hasUnReadMessages = useMemo(() => {
    if (!currentLastEvent) {
      return false
    }
    if (!roomData?.unread_notifications?.notification_count) {
      return false
    }
    return roomData.unread_notifications.notification_count > 0
  }, [roomData, currentLastEvent])

  const isNewMessage = isLastMessageMine ? false : currentLastEvent ? hasUnReadMessages : false
  // console.log('LOG: DmItem > isNewMessage:', isNewMessage)

  const messageToRender = useMemo(() => {
    // TODO: translate text
    switch (currentLastEvent?.content?.msgtype) {
      case MsgType.Image:
        return 'Sent an image'
      case MsgType.Audio:
        return 'Sent an audio'
      case MsgType.File:
        return 'Sent a fle'
      case MsgType.Text:
      default:
        return currentLastEvent?.content?.body ?? ''
    }
  }, [currentLastEvent])

  const userPicture = userData?.avatar_url ?? userDataVatom?.picture

  return (
    <ItemSwipeable simultaneousHandlers={scrollRef}>
      <Pressable
        accessibilityRole="button"
        onPress={onPress}
        style={{
          zIndex: 2
        }}
      >
        <Box
          paddingX={4}
          paddingY={2.5}
          flexDirection={'row'}
          backgroundColor={pageTheme.background}
        >
          <Avatar
            size={60}
            // TODO: remove dev avatar
            // url={'https://i.pravatar.cc/300'}
            url={userPicture}
            name={userData?.displayname}
            badgeColor={isUserOnline ? badgeColor : undefined}
            borderWidth={!userPicture ? 1 : 0}
            badgeStyles={{
              right: 3,
              bottom: 3
            }}
          />
          <Box paddingX={3} marginY={2.5} flex={1}>
            <Text fontSize={15} lineHeight={20} fontFamily={'Inter-Bold'}>
              {userData?.displayname}
            </Text>
            <Box flexDirection={'row'} alignItems={'center'}>
              {isNewMessage && (
                <Box
                  alignItems={'center'}
                  justifyContent={'center'}
                  width={'12px'}
                  height={'18px'}
                  marginRight={2}
                >
                  <View backgroundColor={newMessageColor} h="6px" w="6px" borderRadius="full" />
                </Box>
              )}
              <Box>
                <Text
                  fontSize={13}
                  lineHeight={18}
                  fontFamily={isNewMessage ? 'Inter-Semibold' : 'Inter'}
                  _light={{
                    color: isNewMessage
                      ? theme.colors.textLightMode[300]
                      : theme.colors.textLightMode[600]
                  }}
                  _dark={{
                    color: isNewMessage
                      ? theme.colors.textDarkMode[100]
                      : theme.colors.textDarkMode[300]
                  }}
                  numberOfLines={1}
                >
                  {/* TODO: translate this */}
                  {isLastMessageMine && <Text>You: </Text>}
                  {messageToRender}
                </Text>
              </Box>
            </Box>
            {/* TIME AGO */}
            <Box position={'absolute'} top={0} right={0} alignSelf={'flex-start'}>
              {currentLastEvent?.origin_server_ts && (
                <TimeAgo
                  time={currentLastEvent.origin_server_ts}
                  componentProps={{
                    fontSize: 11,
                    fontFamily: 'Inter',
                    lineHeight: 15,
                    _light: {
                      color: theme.colors.textLightMode[300]
                    },
                    _dark: {
                      color: theme.colors.textDarkMode[600]
                    }
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Pressable>
    </ItemSwipeable>
  )
}
export const DmListItem = React.memo(DmListItemBase)

function DmListSpaceItemBase({
  title,
  picture,
  onPress
}: {
  title: string
  picture: string
  onPress: () => void
}) {
  const { pageTheme } = useBusinessTheme()

  return (
    <Pressable
      accessibilityRole="button"
      onPress={onPress}
      style={{
        zIndex: 2
      }}
    >
      <Box
        paddingX={4}
        paddingY={2.5}
        flexDirection={'row'}
        backgroundColor={pageTheme.background}
        borderBottomWidth={2}
        _light={{
          borderBottomColor: theme.colors.grayCool[100]
        }}
        _dark={{
          borderBottomColor: theme.colors.grayDarkMode[800]
        }}
      >
        <Avatar
          size={60}
          url={picture}
          name={title}
          badgeColor={undefined}
          borderWidth={0}
          badgeStyles={{
            right: 3,
            bottom: 3
          }}
        />
        <Box paddingX={3} alignSelf={'center'} flex={1}>
          <Text fontSize={15} lineHeight={20} fontFamily={'Inter-Bold'}>
            {title}
          </Text>
        </Box>
      </Box>
    </Pressable>
  )
}
export const DmListSpaceItem = React.memo(DmListSpaceItemBase)

function ItemSwipeable({
  children,
  simultaneousHandlers
}: React.PropsWithChildren<{
  simultaneousHandlers: SwipeableProps['simultaneousHandlers']
}>) {
  const muteIconColor = useColorModeValue(
    theme.colors.textLightMode[600],
    theme.colors.textDarkMode[900]
  )
  const deleteBackgroundColor = useColorModeValue(
    theme.colors.systemColorsLight.red,
    theme.colors.systemColorsDark.red
  )

  const renderLeftActions = () => {
    return (
      <RectButton
        style={{
          flex: 1
        }}
        onPress={() => {
          console.log('LOG: ARCHIVE ACTION Press!!')
          Toast({
            title: 'ARCHIVE ACTION not implemented yet!',
            status: 'warning',
            duration: 1500
          })
        }}
      >
        <Text
          style={{
            color: 'white'
          }}
        >
          Archive
        </Text>
      </RectButton>
    )
  }
  const renderRightActions = () => {
    return (
      <>
        <RectButton
          style={{
            flex: 1,
            backgroundColor: deleteBackgroundColor,
            maxWidth: 72,
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onPress={() => {
            console.log('LOG: DELETE ACTION Press!!')
            Toast({
              title: 'DELETE ACTION not implemented yet!',
              status: 'warning',
              duration: 1500
            })
          }}
        >
          <View
            style={{
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <FontAwesome5 name="trash-alt" size={20} color={'white'} />
            <Text
              style={{
                color: 'white',
                marginTop: 6
              }}
            >
              Delete
            </Text>
          </View>
        </RectButton>
        <RectButton
          style={{
            flex: 1,
            backgroundColor: theme.colors.grayDarkMode[50],
            maxWidth: 72,
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onPress={() => {
            console.log('LOG: MUTE ACTION Press!!')
            Toast({
              title: 'MUTE ACTION not implemented yet!',
              status: 'warning',
              duration: 1500
            })
          }}
        >
          <View
            style={{
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <FontAwesome5 name="bell" size={20} color={muteIconColor} />
            <Text
              style={{
                color: muteIconColor,
                marginTop: 6
              }}
            >
              Mute
            </Text>
          </View>
        </RectButton>
      </>
    )
  }
  return (
    <Swipeable
      // renderLeftActions={renderLeftActions}
      renderRightActions={renderRightActions}
      simultaneousHandlers={simultaneousHandlers}
    >
      {children}
    </Swipeable>
  )
}

import { useCallback, useEffect, useRef, useState } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { clusterApiUrl, Connection, PublicKey, SystemProgram, Transaction } from '@solana/web3.js'
import { TEthereumChains, TokenType, TSolanaChains } from '@vatom/sdk/core'
import { translate } from '@vatom/utils'
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import { ethers } from 'ethers'
import { useThrottledCallback } from 'use-debounce'

export type Coin = {
  id: string
  businessId: string
  name: string
  plural_name: string
  logo: string
  points: number
  enabled: boolean
  userId: string
  symbol: string | null
  type: string | null
  address?: string
  isWeb3: boolean
  chain?: TSolanaChains | TEthereumChains
}

const IS_PHANTOM_CONNECTED = 'IS_PHANTOM_CONNECTED'

export type Web3Confirmation = {
  hash: string
  chain?: TEthereumChains | TSolanaChains
  to: string
}

export const useWeb3 = (asset: Coin | TokenType | null) => {
  const network = asset?.type || 'eth'

  const { open, close } = useWeb3Modal()
  const { address: connectionAddress, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const [solanaConnectionAddress, setSolanaConnectionAddress] = useState<string | null>(null)
  const [Web3Confirmation, setWeb3Confirmation] = useState<Web3Confirmation | null>(null)

  const disconnectPhantom = useCallback(() => {
    const provider = getPhantomProvider()
    provider?.disconnect()
    setSolanaConnectionAddress(null)
    AsyncStorage.removeItem(IS_PHANTOM_CONNECTED)
  }, [])

  const conntectPhantom = useCallback(async () => {
    const provider = getPhantomProvider()

    if (!provider) {
      throw new Error(translate('web3.phantomNotInstalled'))
    }

    try {
      const resp = await provider.connect()

      AsyncStorage.setItem(IS_PHANTOM_CONNECTED, resp.publicKey.toString())
      setSolanaConnectionAddress(resp.publicKey.toString())
    } catch (err) {
      console.log('conntectPhantom.error', err)
    }
  }, [])

  const getPhantomProvider = () => {
    if ('phantom' in window) {
      const provider = window.phantom?.solana

      if (provider?.isPhantom) {
        return provider
      }

      return { provider: null }
    }
  }

  const connectionSolanaNode = async () => {
    const connection = new Connection(
      'https://withered-ultra-daylight.solana-mainnet.discover.quiknode.pro/3b62eeb9edc324ce1bbe85749e4ae726c260ee9c/'
    )
    return connection
  }

  const getLastBlock = async () => {
    const connection = await connectionSolanaNode()
    const block = await connection.getLatestBlockhash()
    return block.blockhash
  }

  const sendSolanaFungibleToken = async (addressTo: string, amount: number, coin: Coin) => {
    try {
      const coinAdress = coin.address

      const isValid = await validateAddress(coinAdress || '')

      if (!isValid) {
        throw new Error('Invalid address.')
      }

      const provider = getPhantomProvider()
      const recentBlockhash = await getLastBlock()
      const decimals = 6
      const amountWithoutDecimals = BigInt(Math.round(amount * 10 ** decimals))

      const transaction = new Transaction({ recentBlockhash, feePayer: provider.publicKey }).add(
        SystemProgram.transfer({
          fromPubkey: provider.publicKey,
          toPubkey: new PublicKey(addressTo),
          lamports: BigInt(amountWithoutDecimals)
        })
      )

      const signedTransaction = await provider.signAndSendTransaction(transaction)

      return signedTransaction.signature
    } catch (e: any) {
      throw new Error(e.message)
    }
  }

  const validateAddress = async (address: string) => {
    return (
      address.toLowerCase() === connectionAddress?.toLocaleLowerCase() ||
      address.toLowerCase() === solanaConnectionAddress?.toLocaleLowerCase()
    )
  }

  const sendFungibleToken = async (addressTo: string, amount: number, coin: Coin) => {
    try {
      const coinAdress = coin.address

      const isValid = await validateAddress(coinAdress || '')

      if (!isValid) {
        throw new Error('Invalid address.')
      }

      if (!walletProvider) {
        throw new Error('Wallet provider not found.')
      }

      const provider = new ethers.BrowserProvider(walletProvider)

      const signer = await provider.getSigner()

      const chain = ethers.toBigInt(getChainId(coin.name.toLowerCase()))

      const transaction = await signer?.sendTransaction({
        to: addressTo,
        value: ethers.parseEther(amount.toString()),
        from: connectionAddress,
        chainId: chain
      })

      return transaction?.hash
    } catch (e: any) {
      throw new Error(e.message)
    }
  }

  const openModal = useThrottledCallback(open, 300)
  const closeModal = useThrottledCallback(close, 300)

  const openPhantomModal = useThrottledCallback(conntectPhantom, 300)
  const closePhantomModal = useThrottledCallback(disconnectPhantom, 300)

  const initPhantom = useCallback(async () => {
    const isPhantomConnected = await AsyncStorage.getItem(IS_PHANTOM_CONNECTED)

    if (isPhantomConnected) {
      conntectPhantom()
    }
  }, [conntectPhantom])

  useEffect(() => {
    if (network === 'sol') {
      const provider = getPhantomProvider()

      initPhantom()

      provider?.on('disconnect', disconnectPhantom)

      return () => {
        provider?.off('disconnect', disconnectPhantom)
      }
    }
  }, [network, conntectPhantom, disconnectPhantom, initPhantom])

  const sendEthereumNft = async (addressTo: string, token: TokenType) => {
    try {
      if (!walletProvider) {
        throw new Error('Wallet provider not found.')
      }

      if (!token.contractAddress) {
        throw new Error('Contract address not found.')
      }

      const provider = new ethers.BrowserProvider(walletProvider)

      const signer = await provider.getSigner()

      const tokenChainId = ethers.toBigInt(getChainId(token.network.toLowerCase()))

      const contract = new ethers.Contract(
        token.contractAddress,
        ['function safeTransferFrom(address,address,uint256)'],
        signer
      )

      const transaction = await contract.safeTransferFrom(connectionAddress, addressTo, 1, {
        chainId: tokenChainId
      })

      return transaction.hash
    } catch (e: any) {
      throw new Error(e.message)
    }
  }

  const checkCurrentNetwork = async (token: TokenType) => {
    if (!walletProvider) {
      throw new Error('Wallet provider not found.')
    }

    if (!token.network) {
      return false
    }

    const provider = new ethers.BrowserProvider(walletProvider)
    const network = await provider.getNetwork()
    const chainId = network.chainId
    const tokenChainId = ethers.toBigInt(getChainId(token.network.toLowerCase()))

    if (chainId !== tokenChainId) {
      return false
    }

    return true
  }

  if (network === 'sol') {
    return {
      open: openPhantomModal,
      close: closePhantomModal,
      sendFungibleToken: sendSolanaFungibleToken,
      isConnected: !!solanaConnectionAddress,
      checkCurrentNetwork,
      Web3Confirmation,
      connectionAddress: solanaConnectionAddress
    }
  }

  return {
    open: openModal,
    close: closeModal,
    sendFungibleToken,
    isConnected,
    connectionAddress,
    setWeb3Confirmation,
    checkCurrentNetwork,
    sendEthereumNft,
    Web3Confirmation
  }
}

export const useWeb3New = (network: 'sol' | 'eth') => {
  const { open, close } = useWeb3Modal()
  const { address: connectionAddress, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const [solanaConnectionAddress, setSolanaConnectionAddress] = useState<string | null>(null)
  const [Web3Confirmation, setWeb3Confirmation] = useState<Web3Confirmation | null>(null)

  const disconnectPhantom = useCallback(() => {
    const provider = getPhantomProvider()
    provider?.disconnect()
    setSolanaConnectionAddress(null)
    AsyncStorage.removeItem(IS_PHANTOM_CONNECTED)
  }, [])

  const conntectPhantom = useCallback(async () => {
    const provider = getPhantomProvider()

    if (!provider) {
      throw new Error(translate('web3.phantomNotInstalled'))
    }

    try {
      const resp = await provider.connect()

      AsyncStorage.setItem(IS_PHANTOM_CONNECTED, resp.publicKey.toString())
      setSolanaConnectionAddress(resp.publicKey.toString())
    } catch (err) {
      console.log('conntectPhantom.error', err)
    }
  }, [])

  const getPhantomProvider = () => {
    if ('phantom' in window) {
      const provider = window.phantom?.solana

      if (provider?.isPhantom) {
        return provider
      }

      return { provider: null }
    }
  }

  const connectionSolanaNode = async () => {
    const connection = new Connection(
      'https://withered-ultra-daylight.solana-mainnet.discover.quiknode.pro/3b62eeb9edc324ce1bbe85749e4ae726c260ee9c/'
    )
    return connection
  }

  const getLastBlock = async () => {
    const connection = await connectionSolanaNode()
    const block = await connection.getLatestBlockhash()
    return block.blockhash
  }

  const sendSolanaFungibleToken = async (addressTo: string, amount: number, coin: Coin) => {
    try {
      const coinAdress = coin.address

      const isValid = await validateAddress(coinAdress || '')

      if (!isValid) {
        throw new Error('Invalid address.')
      }

      const provider = getPhantomProvider()
      const recentBlockhash = await getLastBlock()
      const decimals = 6
      const amountWithoutDecimals = BigInt(Math.round(amount * 10 ** decimals))

      const transaction = new Transaction({ recentBlockhash, feePayer: provider.publicKey }).add(
        SystemProgram.transfer({
          fromPubkey: provider.publicKey,
          toPubkey: new PublicKey(addressTo),
          lamports: BigInt(amountWithoutDecimals)
        })
      )

      const signedTransaction = await provider.signAndSendTransaction(transaction)

      return signedTransaction.signature
    } catch (e: any) {
      throw new Error(e.message)
    }
  }

  const validateAddress = async (address: string) => {
    return (
      address.toLowerCase() === connectionAddress?.toLocaleLowerCase() ||
      address.toLowerCase() === solanaConnectionAddress?.toLocaleLowerCase()
    )
  }

  const sendFungibleToken = async (addressTo: string, amount: number, coin: Coin) => {
    try {
      const coinAdress = coin.address

      const isValid = await validateAddress(coinAdress || '')

      if (!isValid) {
        throw new Error('Invalid address.')
      }

      if (!walletProvider) {
        throw new Error('Wallet provider not found.')
      }

      const provider = new ethers.BrowserProvider(walletProvider)

      const signer = await provider.getSigner()

      const chain = ethers.toBigInt(getChainId(coin.name.toLowerCase()))

      const transaction = await signer?.sendTransaction({
        to: addressTo,
        value: ethers.parseEther(amount.toString()),
        from: connectionAddress,
        chainId: chain
      })

      return transaction?.hash
    } catch (e: any) {
      throw new Error(e.message)
    }
  }

  const openModal = useThrottledCallback(open, 300)
  const closeModal = useThrottledCallback(close, 300)

  const openPhantomModal = useThrottledCallback(conntectPhantom, 300)
  const closePhantomModal = useThrottledCallback(disconnectPhantom, 300)

  const initPhantom = useCallback(async () => {
    const isPhantomConnected = await AsyncStorage.getItem(IS_PHANTOM_CONNECTED)

    if (isPhantomConnected) {
      conntectPhantom()
    }
  }, [conntectPhantom])

  useEffect(() => {
    if (network === 'sol') {
      const provider = getPhantomProvider()

      initPhantom()

      provider?.on('disconnect', disconnectPhantom)

      return () => {
        provider?.off('disconnect', disconnectPhantom)
      }
    }
  }, [network, conntectPhantom, disconnectPhantom, initPhantom])

  const sendEthereumNft = async (addressTo: string, token: TokenType) => {
    try {
      if (!walletProvider) {
        throw new Error('Wallet provider not found.')
      }

      if (!token.contractAddress) {
        throw new Error('Contract address not found.')
      }

      const provider = new ethers.BrowserProvider(walletProvider)

      const signer = await provider.getSigner()

      const tokenChainId = ethers.toBigInt(getChainId(token.network.toLowerCase()))

      const contract = new ethers.Contract(
        token.contractAddress,
        ['function safeTransferFrom(address,address,uint256)'],
        signer
      )

      const transaction = await contract.safeTransferFrom(connectionAddress, addressTo, 1, {
        chainId: tokenChainId
      })

      return transaction.hash
    } catch (e: any) {
      throw new Error(e.message)
    }
  }

  const checkCurrentNetwork = async (token: TokenType) => {
    if (!walletProvider) {
      throw new Error('Wallet provider not found.')
    }

    if (!token.network) {
      return false
    }

    const provider = new ethers.BrowserProvider(walletProvider)
    const network = await provider.getNetwork()
    const chainId = network.chainId
    const tokenChainId = ethers.toBigInt(getChainId(token.network.toLowerCase()))

    if (chainId !== tokenChainId) {
      return false
    }

    return true
  }

  if (network === 'sol') {
    return {
      open: openPhantomModal,
      close: closePhantomModal,
      sendFungibleToken: sendSolanaFungibleToken,
      isConnected: !!solanaConnectionAddress,
      checkCurrentNetwork,
      Web3Confirmation,
      connectionAddress: solanaConnectionAddress
    }
  }

  return {
    open: openModal,
    close: closeModal,
    sendFungibleToken,
    isConnected,
    connectionAddress,
    setWeb3Confirmation,
    checkCurrentNetwork,
    sendEthereumNft,
    Web3Confirmation
  }
}

const getChainId = (chain: string) => {
  const chainId = {
    ethereum: 1,
    polygon: 137,
    binance: 56,
    goerli: 5
  }
  return chainId[chain as keyof typeof chainId] || 1
}

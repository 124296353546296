/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
import React, { createContext, useCallback, useContext, useMemo } from 'react'
import Animated, { SharedValue, useAnimatedStyle, useSharedValue } from 'react-native-reanimated'
import { type IEvent, IUnsigned, MsgType } from '@vatom/sdk/react'
// import { translate } from '@vatom/utils'
import { theme } from '@vatom/wombo'
import moment from 'moment'
import { Box, Text } from 'native-base'

import { getReplyToEventId } from '../helpers'

import {
  checkOnlyEmojiAmount,
  LayoutStyles,
  MessageEmoji,
  MessageImage,
  MessageImageCaption,
  MessageImageGroup,
  MessageLayout,
  MessagePressActions,
  MessageReactions,
  MessageReplyPreview,
  MessageText
} from './MessageTypes'

const AnimatedBox = Animated.createAnimatedComponent(Box)

// Message Partial Definitions
const Message = {
  // Structure
  Press: MessagePressActions,
  Layout: MessageLayout,
  Reactions: MessageReactions,
  ReplyPreview: MessageReplyPreview,
  // Components
  Text: MessageText,
  TextEmoji: MessageEmoji,
  Image: MessageImage,
  ImageCaption: MessageImageCaption,
  ImageGroup: MessageImageGroup
  // Audio: MessageAudio
  // File: MessageFile
}

// #####################
// #  Context
// #####################

export type MessageElementLayout = {
  pageX: number
  pageY: number
  width: number
  height: number
}
// TODO: trim extra data from events to avoid re renders
type MessageEvent = Omit<IEvent, 'age' | 'unsigned'> & Omit<IUnsigned, 'age'>

type MessageAnimatedProps = {
  messageOffsetX: SharedValue<number>
}

type MessageContextProps = {
  roomId: string
  layoutDirection: 'left' | 'right'
  events: IEvent[]
  onPress?: (events: IEvent[], eventId?: string) => void
  onLongPress?: (events: IEvent[], eventId: string, elementLayout: MessageElementLayout) => void
  onPressReactions?: (eventId: string) => void
  onPressReply?: (eventId: string) => void
} & MessageAnimatedProps

const MessageContext = createContext<MessageContextProps>({} as MessageContextProps)
export const MessageProvider = ({
  children,
  value
}: React.PropsWithChildren<{ value: MessageContextProps }>) => {
  return <MessageContext.Provider value={value}>{children}</MessageContext.Provider>
}
export const useMessageContext = () => {
  // TODO: check
  return useContext(MessageContext)
}

// #####################
// #  Message BLOCK
// #####################

function eventKeyExtractor(event: IEvent) {
  return event.unsigned.transaction_id ?? event.event_id
}

const MAX_TEXT_EMOJI_MAX = 4

type MessageBlockProps = Omit<MessageContextProps, keyof MessageAnimatedProps> & {
  hideTime?: boolean
  hideReactions?: boolean
  hideReplyPreview?: boolean
  containerStyle?: React.ComponentProps<typeof Box>['style']
  layoutContainerStyle?: React.ComponentProps<typeof Box>['style']
}

/**
 * NOTES:
 * Could have more that one message event inside
 * Always displays the timestamp at the end
 */
export const MessageBlock = React.memo(function ({
  roomId,
  layoutDirection = 'right',
  events,
  onPress,
  onLongPress,
  onPressReactions,
  onPressReply,
  hideTime,
  hideReactions,
  hideReplyPreview,
  containerStyle,
  layoutContainerStyle
}: MessageBlockProps) {
  if (events.length === 0) {
    throw new Error('MessageBlock require an events array')
  }
  const areSameMsgType = events.every(event => event.content.msgtype)
  if (!areSameMsgType) {
    throw new Error('MessageBlock all events in a block MUST be the same MsgType')
  }
  const layoutStyle = LayoutStyles[layoutDirection]
  const { origin_server_ts: messagesTime } = events[events.length - 1]

  const messageType = events[0].content.msgtype

  const messageOffsetX = useSharedValue(0)
  const messageAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: messageOffsetX.value }]
    }
  }, [])

  const replyToEventId = useMemo(
    () => (hideReplyPreview ? undefined : getReplyToEventId(events[0])),
    [events, hideReplyPreview]
  )

  const showReply = useMemo(
    () => !hideReplyPreview && replyToEventId,
    [hideReplyPreview, replyToEventId]
  )

  const renderContent = useCallback(() => {
    switch (messageType) {
      // IMAGE
      case MsgType.Image:
        if (events.length === 1) {
          return (
            <Message.Layout isLast={false} style={layoutContainerStyle}>
              {showReply && <Message.ReplyPreview />}
              <Message.Press>
                <Message.Image />
                <Message.ImageCaption />
              </Message.Press>
              {!hideReactions && <Message.Reactions />}
            </Message.Layout>
          )
        }
        if (events.length < 4) {
          // Message group under 4 should display individual images
          return events.map((event, index) => (
            <Message.Layout
              key={`${eventKeyExtractor(event)}`}
              isLast={false}
              style={[
                {
                  marginTop: index === 0 ? 0 : 4
                },
                layoutContainerStyle
              ]}
            >
              {showReply && <Message.ReplyPreview />}
              <Message.Press index={index}>
                <Message.Image index={index} />
                {index === events.length - 1 && <Message.ImageCaption />}
              </Message.Press>
              {!hideReactions && <Message.Reactions />}
            </Message.Layout>
          ))
        }
        // ImageGroups are for 4 images or more
        return (
          <Message.Layout
            key={eventKeyExtractor(events[0])}
            isLast={false}
            style={layoutContainerStyle}
          >
            <Message.Press>
              <Message.ImageGroup />
              <Message.ImageCaption />
            </Message.Press>
          </Message.Layout>
        )
      // TEXT
      case MsgType.Text:
      default:
        return (
          <Box>
            {events.map((event, index) => {
              // Check for emojis in text
              const emojiAmount = checkOnlyEmojiAmount(event.content?.body)
              if (emojiAmount > 0 && emojiAmount <= MAX_TEXT_EMOJI_MAX) {
                // Emoji only - Bigger Text
                return (
                  <Message.Layout
                    key={eventKeyExtractor(event)}
                    isLast={index === events.length - 1}
                    onlyDirection={true}
                    style={layoutContainerStyle}
                  >
                    {showReply && <Message.ReplyPreview />}
                    <Message.Press index={index}>
                      <Message.TextEmoji event={event}></Message.TextEmoji>
                    </Message.Press>
                    {!hideReactions && <Message.Reactions />}
                  </Message.Layout>
                )
              }

              // Normal Text
              return (
                <Message.Layout
                  key={eventKeyExtractor(event)}
                  isLast={index === events.length - 1}
                  style={[
                    {
                      marginTop: index === 0 ? 0 : 8
                    },
                    layoutContainerStyle
                  ]}
                >
                  {showReply && <Message.ReplyPreview />}
                  <Message.Press index={index}>
                    <Message.Text
                      event={event}
                      style={{
                        paddingTop: showReply ? 0 : undefined
                      }}
                    />
                  </Message.Press>
                  {!hideReactions && <Message.Reactions />}
                </Message.Layout>
              )
            })}
          </Box>
        )
    }
  }, [messageType, events, layoutContainerStyle, hideReactions, showReply])

  return (
    <MessageProvider
      value={{
        roomId,
        layoutDirection,
        events,
        onPress,
        onLongPress,
        onPressReactions,
        onPressReply,
        messageOffsetX
      }}
    >
      <AnimatedBox
        flex={0}
        marginBottom={hideTime ? 0 : 4}
        _web={{
          flexGrow: 0,
          flexShrink: 1,
          flexBasis: 'auto'
        }}
        style={[layoutStyle.container, containerStyle, messageAnimatedStyle]}
      >
        {renderContent()}
        {!hideTime && (
          <Text
            marginTop={1}
            fontFamily={'Inter'}
            fontSize={10}
            lineHeight={14}
            textAlign={layoutDirection}
            _light={{
              color: theme.colors.textLightMode[300]
            }}
            _dark={{
              color: theme.colors.textDarkMode[600]
            }}
          >
            {moment(messagesTime).format('h:mm a')}
          </Text>
        )}
      </AnimatedBox>
    </MessageProvider>
  )
})

import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type BackLightProps = {
  size: React.ComponentProps<typeof Icon>['size']
}

export const BackLight = ({
  size,
  ...iconProps
}: BackLightProps & React.ComponentProps<typeof Icon>) => (
  <Icon viewBox="0 0 7 12" size={size} {...iconProps}>
    <Path d="M1.15625 5.65625L5.65625 0.65625C5.84375 0.4375 6.15625 0.4375 6.34375 0.625C6.5625 0.8125 6.5625 1.125 6.375 1.3125L2.1875 6L6.375 10.6562C6.5625 10.8437 6.5625 11.1562 6.34375 11.3437C6.15625 11.5312 5.84375 11.5312 5.65625 11.3125L1.15625 6.34375C0.968751 6.125 0.968751 5.84375 1.15625 5.65625Z" />
  </Icon>
)

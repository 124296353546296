import { ThemesEnum } from '@vatom/experience-sdk'
import { theme } from '@vatom/wombo'

import { ThemeInterface } from './WebBusinessProvider/types'

export const themeBussines = (t: ThemesEnum | string): ThemeInterface => {
  if (t === ThemesEnum.dark) {
    return {
      backgroundRgba: 'rgb(35,35,35);',
      background: '#111111',
      borderColor: 'rgb(134, 142, 150)',
      colorHeader: 'white',
      active: 'white',
      inactive: '#868e96',
      disabled: '#29313a'
    }
  }

  return {
    backgroundRgba: 'white',
    background: 'white',
    borderColor: '#EFEFEF',
    colorHeader: theme.colors.normalText,
    active: theme.colors.darkText,
    inactive: theme.colors.lightText,
    disabled: theme.colors.extraLightText
  }
}

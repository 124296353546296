import { useQuery } from '@tanstack/react-query'
import { Firebase } from '@vatom/sdk/core'
import { useSDK } from '@vatom/sdk/react'

export const useFirebase = () => {
  const sdk = useSDK()
  const fireBase = useQuery({
    queryKey: ['firebase-client', sdk.dataPool.sessionStore.vatomIncSessionToken],
    queryFn: async () => {
      const res = await Firebase.init(sdk)
      return res
    },
    refetchOnWindowFocus: false
  })
  return fireBase
}

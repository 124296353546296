import React from 'react'

import { useCommunitiesTheme } from '../../../../themes'
import VotesThumbsDownIcon from '../assets/votes_thumbs_down_icon.svg'
import VotesThumbsUpIcon from '../assets/votes_thumbs_up_icon.svg'

const VoteIcon = ({ type, isActive }: { type: string; isActive: boolean }) => {
  const communitiesTheme = useCommunitiesTheme()

  const iconProps = {
    fill: isActive ? 'white' : communitiesTheme.voteIcon.fillColor,
    width: isActive ? 18 : 12,
    height: isActive ? 18 : 12
  }
  return (
    <>
      {type === 'like' ? (
        <VotesThumbsUpIcon {...iconProps} />
      ) : (
        <VotesThumbsDownIcon {...iconProps} />
      )}
    </>
  )
}

export default VoteIcon

import React, { useMemo, useRef } from 'react'
import { Alert } from 'react-native'
import Animated from 'react-native-reanimated'
import ReactQRCode from 'react-qr-code'
import { useDeleteAddressFromIdentities, useIdentities } from '@vatom/sdk/react'
import { convertToHexAlpha, GradientButton, theme, Toast } from '@vatom/wombo'
import * as Clipboard from 'expo-clipboard'
import { observer } from 'mobx-react-lite'
import { Box, Center, HStack, Modal, Pressable, Text, useColorModeValue, VStack } from 'native-base'

import { deleteKey, retrieveAndDecrypt } from '../../../../modules/web3-wallet'
import { EditIcon, ScanQrCode } from '../../components/Icons'
import { ArrowRightIcon } from '../../components/Icons/ArrowRightIcon'
import { ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { useModalState } from '../../hooks/useModalState'
import { AppRoutes, AppStackScreenProps } from '../../navigators'
import { useWalletStore } from '../BlockChainWallets/WalletStore'
import { useWalletDetails } from '../NewWallet/store'
import { useRetrieveWalletBackups } from '../RestoreFromCloud/screens/list'
import { useSelectedBackup } from '../RestoreFromCloud/store'

type WalletAddressManagementProps = AppStackScreenProps<typeof AppRoutes.WalletAddressManagement>

export const WalletAddressManagement = observer(
  ({ route, navigation }: WalletAddressManagementProps) => {
    const address = route.params.address
    const selectedWallet = useWalletStore(state => state.selectedAddress)
    const walletBackups = useRetrieveWalletBackups()
    const backup = walletBackups.data?.find(backup => backup.labl === selectedWallet?.wallet)
    const { pageTheme } = useBusinessTheme()
    const scrollRef = useRef<Animated.ScrollView | null>(null)
    const { identities } = useIdentities()
    const deleteAddressToIdentitiesMutation = useDeleteAddressFromIdentities()
    const selectedIdentity = identities.find(i => i.value.toLowerCase() === address.toLowerCase())

    // const [toggleState, setToggleState] = useState(false)

    const { isVisible, openModal, closeModal } = useModalState()

    const editIconColor = useColorModeValue(
      theme.colors.textLightMode[300],
      theme.colors.textDarkMode[300]
    )
    const qrIconColor = useColorModeValue(
      theme.colors.textLightMode[900],
      theme.colors.textDarkMode[100]
    )

    const iconColor = useColorModeValue(
      theme.colors.textLightMode[600],
      theme.colors.textDarkMode[300]
    )

    const headerIconColor = useColorModeValue(
      theme.colors.textLightMode[900],
      theme.colors.textDarkMode[100]
    )

    return (
      <ScreenWrapper
        screenBackgroundColor={pageTheme.background}
        statusBarBackgroundColor={pageTheme.background}
        viewContainerStyle={{
          width: '100%',
          backgroundColor: pageTheme.background
        }}
        // unsafe
      >
        <ScreenHeader
          headerLeftType={'back'}
          headerTextColor={headerIconColor}
          headerProps={{
            overflow: 'hidden'
          }}
          centerContainerProps={{
            flex: 1,
            paddingX: 2
          }}
          leftContainerProps={{
            flex: 0,
            _web: {
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto'
            }
          }}
          rightContainerProps={{
            flex: 0,
            _web: {
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto'
            }
          }}
          headerRight={() => <ScreenHeaderButton />}
        >
          {/* <Title preset="h5" variant="semibold" numberOfLines={1}>
          Address Management
        </Title> */}
        </ScreenHeader>

        {/* TODO: CHANGE for real values */}
        <QRCodeModal
          isOpen={isVisible}
          title={'Address QR Code'}
          link={`https://etherscan.io/address/${address}`}
          onDismiss={closeModal}
        />

        <Animated.ScrollView
          ref={scrollRef}
          style={{
            paddingHorizontal: 16
          }}
          contentContainerStyle={{
            paddingBottom: 60 // headerHeight
          }}
          scrollEnabled={false}
        >
          <VStack flexGrow={1} marginTop={0}>
            <HStack
              alignItems={'center'}
              style={{
                height: 60
              }}
            >
              <Box flexGrow={1}>
                <Pressable
                  accessibilityRole="button"
                  onPress={() => {
                    Clipboard.setStringAsync(address)
                    Toast({
                      title: 'Address copied',
                      status: 'success',
                      duration: 1500
                    })
                  }}
                >
                  <HStack alignItems={'center'}>
                    <Title
                      isTruncated={true}
                      preset="h4"
                      variant="bold"
                      marginRight={2}
                      ellipsizeMode="middle"
                      w="80%"
                    >
                      {selectedWallet?.name || selectedIdentity?.name || address}
                    </Title>
                    <Center size={6}>
                      <Pressable
                        accessibilityRole="button"
                        onPress={() => {
                          navigation.navigate(AppRoutes.EditAddressName, { address })
                        }}
                      >
                        <EditIcon size={4} fill={editIconColor} />
                      </Pressable>
                    </Center>
                  </HStack>
                </Pressable>
              </Box>
              <Pressable accessibilityRole="button" onPress={() => openModal()}>
                <Center size={8}>
                  <ScanQrCode size={6} fill={qrIconColor} />
                </Center>
              </Pressable>
            </HStack>

            <Divider />

            {/* Items */}
            <VStack flexGrow={1} marginTop={0}>
              {selectedWallet && (
                <ItemComponent
                  title={'Recovery Phrase'}
                  onPress={async () => {
                    if (backup) {
                      useSelectedBackup.getState().setSelectedBackup(backup.acct, backup.labl)
                      navigation.navigate(AppRoutes.ChangeBackupPassword, { address })
                    } else {
                      const seeds = await retrieveAndDecrypt(selectedWallet?.wallet ?? '')
                      useWalletDetails
                        .getState()
                        .setWalletDetails(selectedWallet?.wallet ?? '', seeds, selectedWallet?.n)
                      navigation.navigate(AppRoutes.NewWalletBackup, { isBackupDisabled: true })
                    }
                  }}
                  RightComponent={
                    <HStack alignItems={'center'}>
                      <Center
                        borderRadius={4}
                        marginRight={3}
                        paddingX={2}
                        paddingY={1}
                        _light={{
                          backgroundColor: convertToHexAlpha(
                            theme.colors.systemColorsLight.red,
                            0.1
                          )
                        }}
                        _dark={{
                          backgroundColor: convertToHexAlpha(theme.colors.systemColorsDark.red, 0.1)
                        }}
                        style={{
                          height: 28
                        }}
                      >
                        {!backup ? (
                          <Text
                            fontSize={13}
                            lineHeight={18}
                            fontFamily={'Inter-Regular'}
                            _light={{
                              color: theme.colors.systemColorsLight.red
                            }}
                            _dark={{
                              color: theme.colors.systemColorsDark.red
                            }}
                          >
                            Not backed up
                          </Text>
                        ) : (
                          <Text
                            fontSize={13}
                            lineHeight={18}
                            fontFamily={'Inter-Regular'}
                            _light={{
                              color: theme.colors.systemColorsLight.green
                            }}
                            _dark={{
                              color: theme.colors.systemColorsDark.green
                            }}
                          >
                            Backed up
                          </Text>
                        )}
                      </Center>
                      <ArrowRightIcon size={4} fill={iconColor} />
                    </HStack>
                  }
                />
              )}

              {/* <ItemComponent
              title={'Connected DApps'}
              RightComponent={
                <HStack alignItems={'center'}>
                  <Text
                    marginRight={2}
                    fontSize={13}
                    lineHeight={18}
                    fontFamily={'Inter-Regular'}
                    _light={{
                      color: theme.colors.textLightMode[600]
                    }}
                    _dark={{
                      color: theme.colors.textDarkMode[300]
                    }}
                  >
                    0
                  </Text>
                  <ArrowRightIcon size={4} fill={iconColor} />
                </HStack>
              }
            /> */}
              {/* <ItemComponent
              title={'Export Public Addresses'}
              RightComponent={
                <HStack alignItems={'center'}>
                  <ArrowRightIcon size={4} fill={iconColor} />
                </HStack>
              }
            /> */}
              {selectedWallet && (
                <ItemComponent
                  title={'Show Private Key'}
                  onPress={async () => {
                    const seeds = await retrieveAndDecrypt(selectedWallet?.wallet ?? '')
                    useWalletDetails.getState().setWalletDetails(address, seeds, selectedWallet?.n)
                    navigation.navigate(AppRoutes.ShowPrivateKey)
                  }}
                  RightComponent={
                    <HStack alignItems={'center'}>
                      <ArrowRightIcon size={4} fill={iconColor} />
                    </HStack>
                  }
                />
              )}

              {/* <ItemComponent
              title={'Token Allowances'}
              RightComponent={
                <HStack alignItems={'center'}>
                  <ArrowRightIcon size={4} fill={iconColor} />
                </HStack>
              }
            /> */}
              {/* <ItemComponent
              title={'Support'}
              RightComponent={
                <HStack alignItems={'center'}>
                  <ArrowRightIcon size={4} fill={iconColor} />
                </HStack>
              }
            /> */}
              {/* <ItemComponent
              title={'Hide Address'}
              RightComponent={
                <HStack alignItems={'center'}>
                  <SwitchBase
                    value={toggleState}
                    onToggle={() => setToggleState(state => !state)}
                    size="sm"
                  />
                </HStack>
              }
            /> */}
            </VStack>
            {!selectedWallet || selectedWallet?.n === 0 ? (
              <GradientButton
                onPress={() => {
                  if (selectedWallet?.n === 0) {
                    Alert.alert(
                      'Delete Private Keys',
                      'Are you sure you want to delete this keys? We will not be able to retrieve this once you proceed.',
                      [
                        {
                          text: 'Cancel',
                          onPress: () => console.log('Cancel Pressed'),
                          style: 'cancel'
                        },
                        {
                          text: 'OK',
                          onPress: async () => {
                            const addressesFromWallet = useWalletStore
                              .getState()
                              .wallets.filter(obj => obj.wallet === address)

                            addressesFromWallet.forEach(addressFromWallet => {
                              const identity = identities.find(
                                i =>
                                  i.value.toLowerCase() === addressFromWallet.address.toLowerCase()
                              )
                              if (identity) {
                                deleteAddressToIdentitiesMutation.mutate({
                                  type: identity.type,
                                  value: identity.value
                                })
                              }
                            })

                            await deleteKey(address)
                            useWalletStore.getState().removeWallet(address)

                            if (backup) {
                              Alert.alert(
                                'Backup Found',
                                "We found a cloud backup for this address. Do you also want to delete this? You'll be redirected to another screen to enter the password you use for backing it up.",
                                [
                                  { text: 'Cancel', style: 'cancel' },
                                  {
                                    text: 'Proceed',
                                    onPress: () => {
                                      useSelectedBackup
                                        .getState()
                                        .setSelectedBackup(backup.acct, backup.labl)
                                      navigation.navigate(AppRoutes.DeleteFromCloud)
                                    }
                                  }
                                ]
                              )
                            } else {
                              navigation.navigate(AppRoutes.BlockChainWallets)
                            }
                          }
                        }
                      ]
                    )
                  } else {
                    Alert.alert(
                      'Remove Address From Watching',
                      'Are you sure you want to remove this address from you watching list?',
                      [
                        {
                          text: 'Cancel',
                          onPress: () => console.log('Cancel Pressed'),
                          style: 'cancel'
                        },
                        {
                          text: 'OK',
                          onPress: async () => {
                            if (selectedIdentity) {
                              deleteAddressToIdentitiesMutation.mutate({
                                type: selectedIdentity.type,
                                value: selectedIdentity.value
                              })
                            }

                            navigation.navigate(AppRoutes.BlockChainWallets)
                          }
                        }
                      ]
                    )
                  }
                }}
                h="52px"
                w="100%"
                py="15px"
                px="30px"
                mt={20}
                alignItems="center"
                _dark={{
                  backgroundColor: theme.colors.systemColorsDark['red']
                }}
                _light={{
                  backgroundColor: theme.colors.systemColorsLight['red']
                }}
              >
                <Text
                  alignSelf="center"
                  fontFamily="Inter"
                  color="white"
                  fontSize={16}
                  lineHeight={22}
                >
                  Delete Address
                </Text>
              </GradientButton>
            ) : null}
            {backup ? (
              <GradientButton
                onPress={() => {
                  useSelectedBackup.getState().setSelectedBackup(backup.acct, backup.labl)
                  navigation.navigate(AppRoutes.DeleteFromCloud)
                }}
                h="52px"
                w="100%"
                py="15px"
                px="30px"
                mt={5}
                alignItems="center"
                _dark={{
                  backgroundColor: theme.colors.systemColorsDark['red']
                }}
                _light={{
                  backgroundColor: theme.colors.systemColorsLight['red']
                }}
              >
                <Text
                  alignSelf="center"
                  fontFamily="Inter"
                  color="white"
                  fontSize={16}
                  lineHeight={22}
                >
                  Delete Cloud Backup
                </Text>
              </GradientButton>
            ) : null}
          </VStack>
        </Animated.ScrollView>
      </ScreenWrapper>
    )
  }
)

function ItemComponent({
  title,
  onPress,
  RightComponent
}: {
  title: string
  onPress?: () => void
  RightComponent?: React.ReactElement
}) {
  const content = useMemo(
    () => (
      <HStack
        alignItems={'center'}
        style={{
          height: 48
        }}
      >
        <Text
          flexGrow={1}
          _light={{
            color: theme.colors.textLightMode[600]
          }}
          _dark={{
            color: theme.colors.textDarkMode[300]
          }}
        >
          {title}
        </Text>
        {RightComponent}
      </HStack>
    ),
    [RightComponent, title]
  )

  return onPress ? (
    <Pressable accessibilityRole="button" onPress={onPress}>
      {content}
    </Pressable>
  ) : (
    content
  )
}

function QRCodeModal({
  isOpen,
  onDismiss,
  title,
  link
}: {
  isOpen: boolean
  onDismiss: () => void
  title: string
  link: string
}) {
  return (
    <Modal isOpen={isOpen} onClose={onDismiss}>
      <Pressable accessibilityRole="button" onPress={() => onDismiss()}>
        <Box
          padding={4}
          backgroundColor={theme.colors.grayCool[50]}
          borderRadius={8}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Title preset="h3" variant="light" color={theme.colors.grayCool[900]}>
            {title}
          </Title>
          <Box marginTop={4}>
            <ReactQRCode value={link} size={240} />
          </Box>
        </Box>
      </Pressable>
    </Modal>
  )
}

function Divider(props?: React.ComponentProps<typeof Box>) {
  return (
    <Box
      minWidth={'auto'}
      flexGrow={1}
      style={{
        height: 1
      }}
      _light={{
        backgroundColor: theme.colors.grayCool[200]
      }}
      _dark={{
        backgroundColor: theme.colors.grayDarkMode[700]
      }}
      {...props}
    />
  )
}

export default WalletAddressManagement

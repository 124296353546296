import { BasePluginStore, Region, RegionType, SessionType, withRootSDKStore } from '@vatom/sdk/core'
import {
  Instance,
  isAlive,
  ISerializedActionCall,
  onAction,
  SnapshotOut,
  types
} from 'mobx-state-tree'
import { nanoid } from 'nanoid'

import logger from '../logger'
import { SolInventoryRegionStore } from '../regions/SolInventoryRegion'

import { SolanaToken } from './SolanaToken'

const className = 'SolanaPlugin'

const SolanaPluginBase = BasePluginStore.named('SolanaPluginBase').props({
  className
})

export const SolanaPlugin = types
  .compose(SolanaPluginBase, types.model('solana-placeholder', {}))
  .props({
    regions: types.optional(types.array(SolInventoryRegionStore), [])
  })
  .named(className)
  .extend(withRootSDKStore)
  .actions(self => ({
    async onSessionsChanged(data: ISerializedActionCall): Promise<void> {
      if (!isAlive(self)) return
      if (data.path !== '/dataPool/sessionStore' || data.name !== 'add') return
      this.initializeSessions(data.args)
    },
    async initializeSessions(sess?: any) {
      logger.info('[SolanaPlugin.initializeSessions]: start')
      const sessions = await self.rootStore.dataPool.sessionStore.sessions
      sessions.concat(sess)
      if (!sessions) return

      for (const session of sessions) {
        if (session.type === SessionType.Sol) {
          logger.info('[SolanaPlugin.onSessionInfoChanged] token.type', session.type)
          const value = session.value as string

          let region = self.regions.find(r => r.matches(RegionType.inventory, value))
          if (!region) {
            region = this.region(RegionType.inventory, value)
            const compositeRegion = self.rootStore.dataPool.region(region!.id)
            // const snap = getSnapshot(region)
            // logger.info('[SolanaPlugin.onSessionInfoChanged] region', region.id, snap)
            compositeRegion.addRegion(region!)
            logger.info(
              '[SolanaPlugin.onSessionsChanged] compositeRegion',
              compositeRegion.id,
              compositeRegion.regions.length
            )
          }
        }
      }
    },
    async init() {
      logger.info('[SolanaPlugin.init]')
      onAction(self.rootStore, this.onSessionsChanged, true)

      // Check if there are any existing credentials to initialize the plugin
      this.initializeSessions(self.rootStore.dataPool.sessionStore.sessions)
    },
    region(id: string, descriptor: string): Region | undefined {
      logger.info('[SolanaPlugin.region] Looking for region in plugin', id, descriptor)
      const region = self.regions.find(r => r.matches(id, descriptor))
      if (region) {
        logger.info('[SolanaPlugin.region] Found region', region)
        return region
      }

      if (id === RegionType.inventory && !!descriptor) {
        logger.info('[SolanaPlugin.region] Creating new region in plugin', id, descriptor)
        const newRegion = SolInventoryRegionStore.create({ id, address: descriptor, _id: nanoid() })
        self.regions.push(newRegion)
        return newRegion
      }
      return
    }
  }))
  .views(self => ({
    get availableRegions() {
      return [SolInventoryRegionStore]
    },
    get availableTokens() {
      return [SolanaToken]
    }
  }))

export type SolonaPluginType = Instance<typeof SolanaPlugin>
export type SolanaPluginSnapshot = SnapshotOut<typeof SolanaPlugin>

import React from 'react'
import { optionFieldInterface } from '@vatom/sdk/core'
import { Box, Checkbox, Text } from 'native-base'

import Error from './Error'
import LabelForm from './Label'
import { Subtitle } from './Subtitle'
import { fieldPropsInterfaceItemOverrideValue } from './Types'

export default function MultiBoolField(props: fieldPropsInterfaceItemOverrideValue) {
  const onChangeOption = (options: string[]) => {
    const last = options[options.length - 1]

    if (last === 'undisclosed') {
      props.onChange(props.field.name, ['undisclosed'])
    } else if (last !== 'undisclosed' && options.includes('undisclosed')) {
      const filter = options.filter(opt => opt !== 'undisclosed')
      props.onChange(props.field.name, filter)
    } else {
      props.onChange(props.field.name, options)
    }
  }

  return (
    <Box>
      <LabelForm {...props} />
      <Subtitle {...props} />
      <Checkbox.Group
        flexDirection="row"
        flexWrap="wrap"
        value={props.value}
        onChange={onChangeOption}
      >
        {props.field.options?.map((option: optionFieldInterface, idx) => {
          return (
            <MultiBoolOption
              option={option}
              key={idx}
              num={idx}
              check={props.value.includes(option.value)}
              // onChange={onChangeOption}
            />
          )
        })}
      </Checkbox.Group>
      <Error {...props} />
    </Box>
  )
}

export type MultiBoolOptionType = {
  option: optionFieldInterface
  check: boolean
  // onChange: (option: string, check: boolean) => void
  num: number
}

const MultiBoolOption = (props: MultiBoolOptionType) => {
  return (
    <Box w="50%" flexDirection="row" mb="20px" alignItems="center">
      <Checkbox
        value={props.option.value}
        id={props.option.value + '_' + props.num}
        accessibilityLabel={props.option.value + '_' + props.num}
        accessibilityHint={props.option.value + '_' + props.num}
        // onChange={c => props.onChange(props.option.value, c)}
        // isChecked={props.check}
      />
      <Text
        fontSize="13px"
        color="black"
        ml="1"
        accessibilityLabel={props.option.value + '_' + props.num}
        accessibilityHint={props.option.value + '_' + props.num}
      >
        {props.option.title}
      </Text>
    </Box>
  )
}

import { useQuery } from '@tanstack/react-query'
import { SessionSource, SessionType } from '@vatom/sdk/core'
import axios from 'axios'
import * as AuthSession from 'expo-auth-session'

import logger from '../logger'
import { useSDK } from '../store'

import { useConfig } from './useConfig'

export const useGrant = (gty: string, reqData: { scope: string }, shouldLogin: boolean) => {
  const sdk = useSDK()
  const config = useConfig()
  const { clientId, discoveryUrl } = config.authentication
  const discovery = AuthSession.useAutoDiscovery(discoveryUrl)

  const query = useQuery({
    queryKey: ['use-grant', clientId, discoveryUrl, gty, reqData],
    queryFn: async () => {
      try {
        if (!discovery) return

        const { data } = await axios.request({
          method: 'post',
          url: '/token',
          baseURL: config.api.oidc,
          data: new URLSearchParams({
            grant_type: gty,
            client_id: clientId,
            ...reqData
          })
        })

        const { access_token, id_token, refresh_token, token_type, scope, expires_in } = data
        // Add token to the axios services
        sdk.service.setToken(access_token)

        const date = new Date()

        sdk.dataPool.sessionStore.add({
          type: SessionType.JWT,
          value: {
            idToken: id_token,
            accessToken: access_token,
            refreshToken: refresh_token,
            expiresAt: new Date(new Date(date).setSeconds(date.getSeconds() + expires_in)).getTime()
          },
          source: SessionSource.Vatom
        })

        // Perform token enchange
        await sdk.vatomIncApi.performTokenExchange(access_token)

        // Load Identities
        await sdk.dataPool.user.fetchIdentities()
        // Load User
        await sdk.dataPool.user.fetchMe()
      } catch (error) {
        logger.error('Grant Error: ', JSON.stringify(error))
      }
    },
    enabled: !!discovery && shouldLogin,
    staleTime: 1000
  })

  return query
}

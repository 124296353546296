import React from 'react'
import { translate, TxKeyPath } from '@vatom/utils'
import { TranslateOptions } from 'i18n-js'
import { Text as NbText } from 'native-base'

export type TextProps = React.PropsWithChildren<React.ComponentProps<typeof NbText>> & {
  tx?: TxKeyPath

  txOptions?: TranslateOptions
}

export const Text = ({ children, tx, txOptions, ...rest }: TextProps) => {
  try {
    const t = tx ? translate(tx, { ...txOptions, missingBehavior: 'error' }) : children
    return <NbText {...rest}>{t}</NbText>
  } catch (error) {
    console.error(error)
    return <NbText {...rest}>{children}</NbText>
  }
}

export default Text

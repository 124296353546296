import React, { useState } from 'react'
import { translate } from '@vatom/utils'
import { GradientButton, PressableOpacity, Text, theme } from '@vatom/wombo'
import { BlurView } from 'expo-blur'
import * as Clipboard from 'expo-clipboard'
import { Box, TextArea } from 'native-base'

import BackIcon from '../../../assets/back.svg'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { AppRoutes, AppStackScreenProps } from '../../../navigators'
import { useRetrieveWalletBackups } from '../../RestoreFromCloud/screens/list'
import CopyIcon from '../assets/copy.svg'
import HiddenIcon from '../assets/hidden.svg'
import ShowIcon from '../assets/show.svg'
import { useWalletDetails } from '../store'

type BackupWalletProps = AppStackScreenProps<typeof AppRoutes.NewWalletBackup>

export const Backup = ({ route, navigation }: BackupWalletProps) => {
  const walletBackups = useRetrieveWalletBackups()
  const backup = walletBackups.data?.find(
    backup => backup.labl === useWalletDetails.getState().address
  )
  const { isDark } = useBusinessTheme()
  const [showSeeds, setShowSeeds] = useState(false)
  const [canContinue, setCanContinue] = useState(false)
  const seeds = useWalletDetails.getState().seeds

  const onBackUpManually = () => {
    if (canContinue) {
      navigation.navigate(AppRoutes.NewWalletConfirm)
    } else {
      alert(
        'You have not opened the words or copy it to clipboard. You can also use backup on iCloud.'
      )
    }
  }

  return (
    <Box flex={1} safeArea={true}>
      <Box mb="20px">
        <PressableOpacity
          hitSlop={{
            top: 20,
            left: 20,
            bottom: 20,
            right: 20
          }}
          accessibilityRole="button"
          onPress={() => navigation.goBack()}
        >
          <BackIcon
            height={40}
            width={40}
            fill={!isDark ? theme.colors.darkText : theme.colors.white}
          />
        </PressableOpacity>
      </Box>
      <Box h="100%" w="100%" flex={1} px="16px" justifyContent="flex-start">
        <Box flex={1} alignItems="center" height="100%" width="100%">
          <Text
            mb="20px"
            color={!isDark ? theme.colors.darkText : theme.colors.white}
            fontFamily="Inter-Bold"
            fontSize="34px"
            textAlign="left"
            lineHeight="41px"
            alignSelf="flex-start"
            tx="newWalletScreen.backupTitle"
          />

          <Text
            w="100%"
            fontFamily="Inter"
            fontSize="15px"
            textAlign="left"
            mb="20px"
            color={!isDark ? theme.colors.darkText : theme.colors.white}
            tx="newWalletScreen.backupDescription"
          />

          <Box
            borderRadius={10}
            borderWidth={1}
            borderColor={theme.colors.grayCool[200]}
            alignItems="center"
            w="100%"
            flexDirection="row"
            mb="20px"
            h="114px"
            overflow="hidden"
            display="block"
          >
            <TextArea
              placeholder="Text Area Placeholder"
              w="90%"
              h="114px"
              fontSize={16}
              autoCompleteType={undefined}
              color={isDark ? theme.colors.white : theme.colors.black}
              value={seeds}
              isDisabled={true}
              borderWidth={0}
            />
            <BlurView
              tint={!isDark ? 'light' : 'dark'}
              intensity={showSeeds ? 0 : 80}
              style={{
                position: 'absolute',
                top: 2,
                bottom: 2,
                left: 2,
                right: 0
              }}
            />

            <PressableOpacity
              alignItems="center"
              w="10%"
              h="100%"
              bgColor={theme.colors.grayCool[200]}
              justifyContent="center"
              onPress={() => {
                setShowSeeds(!showSeeds)
                setCanContinue(true)
              }}
            >
              {showSeeds ? (
                <HiddenIcon height={16} width={16} />
              ) : (
                <ShowIcon height={16} width={16} />
              )}
            </PressableOpacity>
          </Box>
          <PressableOpacity
            alignSelf="flex-start"
            onPress={() => {
              Clipboard.setStringAsync(seeds)
              setCanContinue(true)
            }}
          >
            <Text
              alignSelf="center"
              fontFamily="Inter"
              color={!isDark ? theme.colors.darkText : theme.colors.white}
              fontSize={13}
              lineHeight={18}
            >
              <CopyIcon height={16} width={16} /> {translate('newWalletScreen.copy')}
            </Text>
          </PressableOpacity>
        </Box>
        <Box justifyContent="center" width="100%" pb={20}>
          {!backup ? (
            <GradientButton
              onPress={() => navigation.navigate(AppRoutes.BackupToCloud)}
              h="52px"
              w="100%"
              py="15px"
              px="30px"
              mb={'10px'}
            >
              <Text
                alignSelf="center"
                fontFamily="Inter"
                color="white"
                fontSize={16}
                lineHeight={22}
              >
                {translate('newWalletScreen.backupIcloud')}
              </Text>
            </GradientButton>
          ) : null}

          {!route.params?.isBackupDisabled ? (
            <GradientButton
              onPress={onBackUpManually}
              h="52px"
              w="100%"
              py="15px"
              px="30px"
              _dark={{
                backgroundColor: theme.colors.grayCool[200]
              }}
              _light={{
                backgroundColor: theme.colors.grayCool[200]
              }}
            >
              <Text
                alignSelf="center"
                fontFamily="Inter"
                color="black"
                fontSize={16}
                lineHeight={22}
              >
                {translate('newWalletScreen.backupManually')}
              </Text>
            </GradientButton>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

export default Backup

import React from 'react'
import { Alert, Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useQuery } from '@tanstack/react-query'
import { Button, PressableOpacity, Text, theme } from '@vatom/wombo'
import { Box, ScrollView } from 'native-base'

import { retrieveWalletBackups } from '../../../../../modules/web3-wallet'
import BackIcon from '../../../assets/back.svg'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { AppRoutes } from '../../../navigators'
import { useSelectedBackup } from '../store'

export const retrieveWalletBackupsKey = ['retrieve-wallet-backups']

export const useRetrieveWalletBackups = () => {
  return useQuery({
    queryKey: retrieveWalletBackupsKey,
    queryFn: () => {
      return retrieveWalletBackups()
    },
    enabled: Platform.OS === 'ios'
  })
}

export const CloudBackupList = () => {
  const { isDark } = useBusinessTheme()
  const navigation = useNavigation()
  const walletBackups = useRetrieveWalletBackups()

  // if (walletBackups?.data?.length === 1) {
  //   useSelectedBackup
  //     .getState()
  //     .setSelectedBackup(walletBackups.data[0].acct, walletBackups.data[0].labl)
  //   return navigation.navigate(AppRoutes.RestoreFromCloud)
  // }

  return (
    <Box flex={1} safeArea={true}>
      <Box mb="20px">
        <PressableOpacity
          hitSlop={{ top: 20, left: 20, bottom: 20, right: 20 }}
          accessibilityRole="button"
          onPress={() => navigation.goBack()}
        >
          <BackIcon
            height={40}
            width={40}
            fill={!isDark ? theme.colors.darkText : theme.colors.white}
          />
        </PressableOpacity>
      </Box>
      <Box height="100%" w="100%" flex={1} px="16px">
        <Box flex={1} justifyContent="flex-start" alignItems="center" height="100%">
          <Text
            marginBottom="20px"
            color={!isDark ? theme.colors.darkText : theme.colors.white}
            fontFamily="Inter-Bold"
            fontSize="34px"
            textAlign="left"
            alignSelf="flex-start"
            lineHeight="41px"
            tx="restoreFromCloudList.title"
          />

          {!walletBackups?.data ? (
            <Text
              w="100%"
              fontFamily="Inter"
              fontSize="15px"
              textAlign="left"
              mb="20px"
              color={!isDark ? theme.colors.darkText : theme.colors.white}
            >
              No Cloud Backup found
            </Text>
          ) : (
            <Text
              w="100%"
              fontFamily="Inter"
              fontSize="15px"
              textAlign="left"
              mb="20px"
              color={!isDark ? theme.colors.darkText : theme.colors.white}
              tx="restoreFromCloudList.description"
            />
          )}

          <ScrollView w="100%">
            {walletBackups?.data?.map(backup => (
              <Button
                onPress={() => {
                  useSelectedBackup.getState().setSelectedBackup(backup.acct, backup.labl)
                  navigation.navigate(AppRoutes.RestoreFromCloud)
                }}
                onLongPress={() => {
                  Alert.alert(
                    'Delete Backup',
                    'Are you sure you want to delete this iCloud backup? We will not be able to retrieve this once you proceed. Please enter the password you use to delete this.',
                    [
                      {
                        text: 'Cancel',
                        onPress: () => console.log('Cancel Pressed'),
                        style: 'cancel'
                      },
                      {
                        text: 'OK',
                        onPress: async () => {
                          useSelectedBackup.getState().setSelectedBackup(backup.acct, backup.labl)
                          navigation.navigate(AppRoutes.DeleteFromCloud)
                        }
                      }
                    ]
                  )
                }}
                _dark={{
                  backgroundColor: theme.colors.grayDarkMode[500]
                }}
                _light={{
                  backgroundColor: theme.colors.grayCool[200]
                }}
                variant="outline"
                w="100%"
                mb={2}
              >
                <Text
                  _light={{
                    color: theme.colors.textLightMode[600]
                  }}
                  _dark={{
                    color: 'white'
                  }}
                  fontFamily="Inter-Regular"
                  fontSize="15px"
                >
                  {backup.labl}
                </Text>
              </Button>
            ))}
          </ScrollView>
        </Box>
      </Box>
    </Box>
  )
}

export default CloudBackupList

import React from 'react'
import { ActivityIndicator, Appearance, Platform, StyleSheet } from 'react-native'
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { RegionType } from '@vatom/sdk/core'
import { useConfig, useSDK } from '@vatom/sdk/react'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { PressableOpacity } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Center, Icon, View } from 'native-base'

import { ScreenHeaderWrapper } from '../../components/ScreenHeaderWrapper'
import { useBusinessSelector } from '../../hooks/useBusinessSelector'
import { useIsDesktop } from '../../hooks/useIsDesktop'
import { useLayoutScrollHandler } from '../../hooks/useLayoutScrollHandler'
import { useNavigateHome } from '../../hooks/useNavigateHome'
import { AppRoutes, TabRoutes } from '../../navigators'
import { color } from '../../theme'
import { useTabNavConfig } from '../Home/hooks/useTabNavConfig'
import { DrawerSelectorButton } from '../Home/partials/DrawerSelectorButton'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    height: '100%',
    justifyContent: 'flex-end',
    width: '100%',
    backgroundColor: 'white'
  }
})

export type ContainerProps = React.PropsWithChildren & {
  handleCenter: ({ isAnimated }: { isAnimated?: boolean }) => void
}

export const Container = ({ children, handleCenter }: ContainerProps) => {
  const colorScheme = Appearance.getColorScheme()
  const isDark = colorScheme === 'dark'

  const sdk = useSDK()
  const navigation = useNavigation()
  // const config = useVatomWalletSdkStore()
  const config = useConfig()
  const isDesktop = useIsDesktop()
  const { tabsToRender } = useTabNavConfig()
  const { getFooterHeight } = useLayoutScrollHandler()
  const { navigateHome } = useNavigateHome()
  const { businessIdentifier } = useBusinessSelector()

  const hasARTabBar = tabsToRender.includes(TabRoutes.MapAr)

  const navigateToARScreen = () => {
    if (hasARTabBar) {
      navigateHome({
        screenParams: {
          screen: TabRoutes.MapAr
        }
      })
    } else {
      navigation.navigate(
        AppRoutes.MapAR,
        businessIdentifier
          ? {
              business: businessIdentifier
            }
          : undefined
      )
    }
  }

  const regions = sdk.dataPool.regions.filter(r => r.id === RegionType.geopos)
  const isLoading = regions.some(r => r.isLoading)

  const getFabScanBtn = () => {
    if (config.features.screensConfig?.Map?.disableArPickup) {
      return null
    }
    if (isDesktop) {
      return null
    }
    return (
      <View
        position="absolute"
        alignItems={'flex-end'}
        right="20px"
        bottom={getFooterHeight() + 90}
        _web={{
          bottom: '20px'
        }}
      >
        <PressableOpacity accessibilityRole="button" onPress={navigateToARScreen} mb={'15px'}>
          <Center
            borderRadius={60 / 2}
            bgColor={isDark ? '#000000' : color.background}
            w="60px"
            h="60px"
          >
            <FontAwesome5
              size={18}
              name="vr-cardboard"
              color={isDark ? '#FFFFFF' : '#3F4A55'}
              bgColor={isDark ? '#000000' : color.background}
            />
          </Center>
        </PressableOpacity>
      </View>
    )
  }

  return (
    <View style={Platform.OS === 'web' ? styles.container : {}}>
      <ScreenHeaderWrapper
        containerProps={{
          position: 'absolute',
          safeAreaTop: true,
          top: 0,
          left: 0,
          right: 0,
          zIndex: 2,
          backgroundColor: 'transparent'
        }}
        leftContainerProps={{
          left: 1
        }}
        leftComponent={<DrawerSelectorButton />}
        rightContainerProps={{ right: '20px' }}
        rightComponent={
          <PressableOpacity
            accessibilityRole="button"
            accessibilityLabel="Vatom"
            accessibilityHint="Vatom"
            onPress={() => handleCenter({ isAnimated: true })}
            pointerEvents="auto"
          >
            <Center
              rounded="md"
              bgColor={isDark ? '#000000' : color.background}
              borderRadius={35 / 2}
              w="35px"
              h="35px"
            >
              <Icon
                paddingLeft="1px"
                size="24px"
                as={FontAwesome}
                name="location-arrow"
                color={isDark ? '#FFFFFF' : '#3F4A55'}
              />
            </Center>
          </PressableOpacity>
        }
      />
      <Box
        position={'absolute'}
        pointerEvents="none"
        top={0}
        left={0}
        right={0}
        zIndex={1}
        height={140}
        backgroundColor={{
          linearGradient: {
            colors: ['rgba(0,0,0,0.5)', 'rgba(0,0,0,0)'],
            start: [0, 0],
            end: [0, 1]
          }
        }}
      />
      {isLoading ? (
        <ActivityIndicator style={{ position: 'absolute', zIndex: 99999, bottom: 20 }} />
      ) : null}
      <View style={styles.container}>
        {children}
        {getFabScanBtn()}
      </View>
    </View>
  )
}

export default observer(Container)

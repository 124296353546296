import { useRef } from 'react'
import { Keyboard, Platform } from 'react-native'
import { AntDesign, Entypo, MaterialCommunityIcons } from '@expo/vector-icons'
import { useThrottle } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { InputField, theme } from '@vatom/wombo'
import { Box, HStack, Icon, Input, Pressable, useColorModeValue } from 'native-base'

import { useSender } from './SendProvider'

export const Search = ({ onFocus, onBlur }: { onFocus: () => void; onBlur: () => void }) => {
  const { setSearchString, searchString, setIsScannerOpen } = useSender()
  const inputRef = useRef<typeof Input>(null)

  const placeholderTextColor = useColorModeValue(
    theme.colors.textLightMode[600],
    theme.colors.textDarkMode[300]
  )

  const inputTextColor = useColorModeValue(
    theme.colors.textLightMode[900],
    theme.colors.textDarkMode[300]
  )

  const clearInput = () => {
    setSearchString('')
    Keyboard.dismiss()
  }
  const onSetIScannerOpen = useThrottle(setIsScannerOpen, 1000)
  return (
    <HStack
      alignItems="center"
      flexDirection="row"
      justifyContent="center"
      marginBottom={4}
      style={{
        height: 40
      }}
    >
      <HStack
        flex={1}
        alignItems={'center'}
        // justifyContent={'center'}
        borderWidth={1}
        _light={{
          backgroundColor: theme.colors.grayCool[100],
          borderColor: theme.colors.grayCool[200]
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[700],
          borderColor: theme.colors.grayDarkMode[600]
        }}
        style={{
          height: 40
        }}
      >
        {/* <Pressable alignItems="center" accessibilityRole="button" marginRight={2}>
          <Icon size={4} marginLeft="8px" color="gray.400" as={AntDesign} name="search1" />
        </Pressable> */}

        <Box w="100%">
          <InputField
            numberOfLines={1}
            ref={inputRef}
            onFocus={onFocus}
            onBlur={onBlur}
            accessibilityHint={'input'}
            accessibilityLabel={translate('dms.message') ?? ''}
            multiline={false}
            value={searchString}
            onChangeText={setSearchString}
            placeholder={translate('common.search')}
            placeholderTextColor={placeholderTextColor}
            style={{
              flex: 1,
              borderWidth: 0,
              backgroundColor: 'transparent',
              color: inputTextColor,
              fontSize: 15,
              lineHeight: 20,
              fontFamily: 'Inter',
              paddingBottom: 5,
              height: Platform.OS === 'web' ? 20 : undefined

              // minHeight: 20
            }}
            leftElement={
              <Pressable alignItems="center" accessibilityRole="button">
                <Icon size={4} marginLeft="8px" color="gray.400" as={AntDesign} name="search1" />
              </Pressable>
            }
            rightElement={
              searchString !== '' ? (
                <Pressable accessibilityRole="button" onPress={clearInput}>
                  <Icon marginRight="8px" as={Entypo} name={'circle-with-cross'} />
                </Pressable>
              ) : (
                <></>
              )
            }
          />
        </Box>

        {/* {searchString !== '' && (
          <Pressable accessibilityRole="button" onPress={clearInput}>
            <Icon marginRight="8px" as={Entypo} name={'circle-with-cross'} />
          </Pressable>
        )} */}
      </HStack>

      <Pressable accessibilityRole="button" onPress={() => onSetIScannerOpen(true)} ml={2}>
        <Icon
          size={7}
          color="black"
          _light={{
            color: theme.colors.textLightMode[600]
          }}
          _dark={{
            color: theme.colors.textDarkMode[300]
          }}
          as={MaterialCommunityIcons}
          name="qrcode-scan"
        />
      </Pressable>
    </HStack>
  )
}

export default Search

import { FontAwesome5 } from '@expo/vector-icons'
import { Box, Flex } from 'native-base'

import { color } from '../../../theme'

export const LoadingSpace = () => {
  return (
    <Flex mx={1} paddingLeft={4} flexDirection="row">
      {Array.from({ length: 3 }, (_, index) => (
        <Box
          key={`empty-${index}`}
          alignItems="center"
          borderColor={color.borderGrey}
          bgColor={color.bg}
          color={color.background}
          borderStyle="solid"
          borderWidth={1}
          borderRadius={8}
          justifyContent="center"
          h={215}
          w={150}
          mr={'10px'}
        >
          <Box
            alignItems="center"
            borderColor={color.borderGrey}
            bgColor={color.background}
            borderStyle="solid"
            borderWidth={1}
            borderRadius={50}
            h={50}
            justifyContent="center"
            w={50}
          >
            <FontAwesome5
              size={20}
              resizeMode="contain"
              color={color.borderGrey}
              alt="Plus"
              name="location-arrow"
            />
          </Box>
        </Box>
      ))}
    </Flex>
  )
}

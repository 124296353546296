import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { useGetUserFollowers, useGetUserFollowing } from '@vatom/sdk/react'
import { Text, theme } from '@vatom/wombo'
import { Box, Pressable } from 'native-base'

import { Title } from '../../../components/Title'
import { AppRoutes } from '../../../navigators'

type StatsCardProps = {
  containerProps?: React.ComponentProps<typeof Box>
  userId: string
}

function StatsCard({ containerProps, userId }: StatsCardProps) {
  const followingUsers = useGetUserFollowing(userId, {
    select: users => users.filter(contact => contact.type === 'user').length
  })

  const followerUsers = useGetUserFollowers(userId, {
    select: users => users.filter(contact => contact.type === 'user').length
  })

  const navigation = useNavigation()

  return (
    <Box
      flexDirection={'row'}
      _light={{
        backgroundColor: theme.colors.grayCool[100]
      }}
      _dark={{
        backgroundColor: theme.colors.grayDarkMode[900]
      }}
      flex={1}
      alignItems={'center'}
      justifyContent={'space-between'}
      marginX={4}
      borderRadius={4}
      _web={{
        minWidth: 360
      }}
      {...containerProps}
    >
      <Box flexDirection={'row'} flex={1}>
        <Pressable
          flex={1}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'space-between'}
          paddingY={2}
          onPress={() =>
            followerUsers?.data
              ? navigation.navigate(AppRoutes.followList, { userId: 'me', type: 'followers' })
              : null
          }
          accessibilityRole="button"
        >
          <Title
            fontSize="17px"
            lineHeight="24px"
            fontWeight="700"
            color={theme.colors.grayCool[900]}
            _dark={{
              color: '#ECECED'
            }}
          >
            {followerUsers?.data ?? 0}
          </Title>
          <Text
            fontFamily={'Inter'}
            fontWeight="400"
            fontSize="13px"
            lineHeight="18px"
            color="#868E96"
            tx="common.followers"
          />
        </Pressable>
        <Box
          position={'absolute'}
          top={0}
          right={0}
          bottom={0}
          borderRightWidth={1}
          borderRightColor={theme.colors.grayCool[400]}
          _dark={{
            borderRightColor: theme.colors.grayDarkMode[600]
          }}
        />
      </Box>
      <Box flexDirection={'row'} flex={1}>
        <Pressable
          flex={1}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'space-between'}
          paddingY={2}
          onPress={() =>
            followingUsers?.data
              ? navigation.navigate(AppRoutes.followList, {
                  userId: 'me',
                  type: 'following'
                })
              : null
          }
          accessibilityRole="button"
        >
          <Title
            fontSize="17px"
            lineHeight="24px"
            fontWeight="700"
            color={theme.colors.grayCool[900]}
            _dark={{
              color: '#ECECED'
            }}
          >
            {followingUsers?.data ?? 0}
          </Title>
          <Text
            fontFamily={'Inter'}
            fontWeight="400"
            fontSize="13px"
            lineHeight="18px"
            color="#868E96"
            tx="common.following"
          />
        </Pressable>
      </Box>
    </Box>
  )
}

export { StatsCard }

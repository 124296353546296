import { useCallback, useEffect, useRef, useState } from 'react'
import { Linking, Platform } from 'react-native'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { useOnAppFocus } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { Camera, PermissionResponse, PermissionStatus } from 'expo-camera'

import Alert from '../components/Alert'
import { VatomWallet } from '../utils/WalletSdk'

const useCameraPermissionByPlatform = () => {
  const [permissionState, setPermission] = useState<PermissionResponse | null>(null)
  const isEmbeddedFlutter =
    VatomWallet.isEmbedded() && VatomWallet.getEmbeddedType().includes('flutter')

  const [permission, requestPermission, getPermission] = Camera.useCameraPermissions({
    get: false,
    request: false
  })

  if (!isEmbeddedFlutter) {
    return {
      getPermission,
      permission,
      requestPermission
    }
  }

  // getPermission state without expo
  const getPermissionState = async () => {
    let permissionStatus = {} as PermissionResponse
    try {
      await navigator.mediaDevices.getUserMedia({ video: true })

      permissionStatus = {
        status: PermissionStatus.GRANTED,
        expires: 'never',
        canAskAgain: true,
        granted: true
      }
    } catch (error) {
      permissionStatus = {
        status: PermissionStatus.DENIED,
        expires: 'never',
        canAskAgain: true,
        granted: false
      }
    }
    setPermission(permissionStatus)
    return permissionStatus
  }
  return {
    getPermission: getPermissionState,
    permission: permissionState,
    requestPermission: getPermissionState
  }
}

export const useCameraPermission = () => {
  // For some reason permission doesn't update by itself when user initially denies so we need all this code...
  const { permission, requestPermission, getPermission } = useCameraPermissionByPlatform()

  const navigation = useNavigation()
  const [isLoading, setIsLoading] = useState(true)
  const checking = useRef(false)

  useEffect(() => {
    if (permission?.granted) {
      setIsLoading(false)
    }
  }, [permission])

  const request = useCallback(() => {
    if (checking.current) return
    checking.current = true

    const onPermissionDenied = async () => {
      const text = translate('common.enableCameraSettings')
      const alertConfig =
        Platform.OS === 'web'
          ? {
              text
            }
          : {
              text,
              confirmButtonText: 'Settings',
              cancelButtonText: 'Cancel',
              showCancelButton: true
            }

      const toSettings = await Alert.fire(alertConfig)
      checking.current = false

      if (Platform.OS !== 'web' && toSettings) {
        Linking.openSettings()
      } else {
        navigation.goBack()
      }
    }

    const onGranted = () => {
      checking.current = false
      setIsLoading(false)
    }

    const onPermissionChange = (permission: PermissionResponse) => {
      if (permission.status === 'undetermined') {
        requestPermission().then(onPermissionChange)
      } else if (permission.status === PermissionStatus.GRANTED) {
        onGranted()
      } else if (permission.status === PermissionStatus.DENIED) {
        onPermissionDenied()
      }
    }

    if (permission === null) {
      getPermission().then(onPermissionChange)
    }
  }, [getPermission, navigation, permission, requestPermission])

  useFocusEffect(request)

  // On android app doesn't restart when user changes permission so we need to check it on app state change
  useOnAppFocus(getPermission)

  return { permission, isLoading }
}

export default useCameraPermission

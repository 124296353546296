import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { View } from 'native-base'

import theme from '../theme'

interface IProps {
  name: string
  [key: string]: React.CSSProperties | string
}

const Keyframes = (props: IProps) => {
  const toCss = (cssObject: React.CSSProperties | string) =>
    typeof cssObject === 'string'
      ? cssObject
      : Object.keys(cssObject).reduce((accumulator, key) => {
          const cssKey = key.replace(/[A-Z]/g, v => `-${v.toLowerCase()}`)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const cssValue = (cssObject as any)[key].toString().replace("'", '')
          return `${accumulator}${cssKey}:${cssValue};`
        }, '')

  return (
    <style>
      {`@keyframes ${props.name} {
        ${Object.keys(props)
          .map(key => {
            return ['from', 'to'].includes(key)
              ? `${key} { ${toCss(props[key])} }`
              : /^_[0-9]+$/.test(key)
              ? `${key.replace('_', '')}% { ${toCss(props[key])} }`
              : ''
          })
          .join(' ')}
      }`}
    </style>
  )
}

export const LoaderLegacy = ({ size = 20 }: { size: number }) => {
  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          animation: 'oscillate 1.0s alternate infinite ease-in',
          backgroundColor: theme.colors.brand[200],
          width: size,
          height: size,
          borderRadius: size / 2,
          position: 'absolute'
        }}
      />
      <Keyframes name="oscillate" from={{ scale: 2 }} to={{ scale: 4 }} />
      <div
        style={{
          animation: 'oscillate2 1.0s alternate infinite ease-in',
          backgroundColor: theme.colors.brand[500],
          width: size,
          height: size,
          borderRadius: size / 2,
          position: 'absolute'
        }}
      />
      <Keyframes name="oscillate2" from={{ scale: 2 }} to={{ scale: 0 }} />
    </View>
  )
}

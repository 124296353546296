import { useState } from 'react'
import { useWindowDimensions } from 'react-native'

const useScreenSize = () => {
  const windowSize = useWindowDimensions()
  const [screenSize, setScreenSize] = useState({
    width: windowSize.width,
    height: windowSize.height
  })
  return {
    windowSize,
    screenSize,
    setScreenSize
  }
}

export { useScreenSize }

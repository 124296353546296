import { create } from 'zustand'

const DrawerStatus = {
  opened: 'opened',
  closed: 'closed'
} as const

type DrawerStore = {
  drawerStatus: keyof typeof DrawerStatus
  openDrawer: () => void
  closeDrawer: () => void
  toggleDrawer: () => void
}

const useDrawerStore = create<DrawerStore>(set => ({
  drawerStatus: DrawerStatus.closed,
  openDrawer: () =>
    set(() => ({
      drawerStatus: DrawerStatus.opened
    })),
  closeDrawer: () =>
    set(() => ({
      drawerStatus: DrawerStatus.closed
    })),
  toggleDrawer: () =>
    set(state => ({
      drawerStatus:
        state.drawerStatus === DrawerStatus.opened ? DrawerStatus.closed : DrawerStatus.opened
    }))
}))

export { useDrawerStore }

/* eslint-disable unicorn/filename-case */
import { FirebaseApp, initializeApp } from '@firebase/app'
import { getAuth, signInWithCustomToken } from '@firebase/auth'
import { getFunctions, httpsCallable } from '@firebase/functions'

import logger from '../../logger'
import { RootSDKStoreType } from '../RootSdk'

const firebaseConfig = {
  apiKey: 'AIzaSyBVY_EQCistyQdbJgEaMeR5kZadFLSt61o',
  authDomain: 'ydangle-high-fidelity-test-2.firebaseapp.com',
  databaseURL: 'https://ydangle-high-fidelity-test-2.firebaseio.com',
  projectId: 'ydangle-high-fidelity-test-2',
  storageBucket: 'ydangle-high-fidelity-test-2.appspot.com',
  messagingSenderId: '190616353628',
  appId: '1:190616353628:ios:4cb17f49c64e79342a1f99',
  measurementId: 'G-WJ1NRS9JKW'
}

const initialize = async (sdk: RootSDKStoreType, app: FirebaseApp) => {
  try {
    const session = sdk.dataPool.sessionStore.vatomIncSessionToken

    if (!session?.accessToken || session.expiresAt < Date.now()) return

    const firebaseAuth = getAuth(app)
    const firebaseFunctions = getFunctions(app)

    if (!firebaseAuth.currentUser) {
      logger.debug(`[People] Exchanging VatomInc token for a Firebase login token...`)
      const tokenExchange = httpsCallable(firebaseFunctions, 'vatomincTokenExchange')

      const result: any = await tokenExchange({
        vatomincAccessToken: session.accessToken,
        env: 'production_gcp'
      })

      const loginToken = result.data.userToken

      // Login with firebase using the user's token
      logger.debug(`[People] Logging in to Firebase...`)
      await signInWithCustomToken(firebaseAuth, loginToken)
      logger.debug(`[People] Signin successful...`)
    }
  } catch (error) {
    logger.debug(`[People] Signin error...`, error)
  }
}
/**
 * Initialize Firebase.
 */
export const init = async (sdk: RootSDKStoreType) => {
  logger.debug('[Firebase] initialize')
  const app = initializeApp(firebaseConfig, { name: 'HIFI' })

  // Start fetching
  await initialize(sdk, app)

  return app
}

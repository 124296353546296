import React from 'react'
import { StyleSheet, TextInput, View } from 'react-native'
import { RichEditor } from 'react-native-pell-rich-editor'
import * as WebBrowser from 'expo-web-browser'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'

import { useCommunitiesTheme } from '../../../themes'
import { useStoreContext } from '../../MSTContextProvider'
import { Input } from '../stores'

type ModalInputProps = {
  input: SnapshotOut<typeof Input>
  style?: object
  focus: boolean
  onFocus?: any
}

const ModalInput = observer(
  React.forwardRef<RichEditor, ModalInputProps>((props, ref) => {
    const store = useStoreContext()
    const { input, style, focus, onFocus } = props

    React.useEffect(() => {
      store.addInput(input)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const storeInput = store.inputs.find(sinput => sinput.type === input.type)

    const communitiesTheme = useCommunitiesTheme()

    const inputStyle = input.type === 'title' ? styles.inputTitle : styles.inputText

    const richTextHandle = (descriptionText: string) => {
      storeInput?.setValue(descriptionText)
    }

    const _handlePressButtonAsync = async (url: string) => await WebBrowser.openBrowserAsync(url)

    const isFocused = input.type === 'text' && store.inputs.length === 1 ? true : false

    const FontFamilyStylesheet = `
      a {
        color: #2EA7FF
      }
      .mention {
          color: #2EA7FF
      }
    `

    return input.type === 'text' ? (
      <View style={{ marginBottom: 24 }}>
        <RichEditor
          onChange={richTextHandle}
          placeholder={input.placeholder}
          androidLayerType="software"
          style={{ ...inputStyle, ...style }}
          initialContentHTML={storeInput?.value as string}
          ref={ref}
          onLink={url => _handlePressButtonAsync(url)}
          editorStyle={{
            backgroundColor: communitiesTheme.background,
            color: communitiesTheme.input.text,
            placeholderColor: communitiesTheme.input.placeholder,
            initialCSSText: `${FontFamilyStylesheet}`,
            contentCSSText: `${FontFamilyStylesheet}`
          }}
          initialFocus={isFocused}
          autoCapitalize="on"
          onFocus={() => onFocus()}
          onKeyUp={({ keyCode, key }) => {
            if (keyCode === 32) {
              const regex = /(<mention id="[^"]+" class="mention">[^<]+)&nbsp;<\/mention>/g
              const inputValue = storeInput?.value as string
              const trueInput = inputValue.replace(regex, '$1</mention>&nbsp;')
              // @ts-expect-error we know current exist so I'm not sure how to fix this
              ref?.current?.command(`$.execCommand('selectAll', false)`)
              // @ts-expect-error we know current exist so I'm not sure how to fix this
              ref?.current?.command(`$.execCommand('insertHTML', false, '${trueInput}')`)
              // @ts-expect-error we know current exist so I'm not sure how to fix this
              ref.current.insertText(' ')
            }
            if (key === 'Backspace') {
              if (storeInput?.value === '') {
                // @ts-expect-error we know current exist so I'm not sure how to fix this
                ref?.current?.command(`$.execCommand('selectAll', false)`)
                // @ts-expect-error we know current exist so I'm not sure how to fix this
                ref?.current?.command(`$.execCommand('insertHTML', false, '')`)
              }
            }
          }}
        />
      </View>
    ) : (
      // eslint-disable-next-line react-native-a11y/has-accessibility-hint
      <TextInput
        accessibilityLabel="Text input field"
        style={{ ...inputStyle, ...style, color: communitiesTheme.input.text }}
        placeholder={input.placeholder}
        value={storeInput?.value as string}
        onChangeText={storeInput?.setValue}
        multiline={true}
        textAlignVertical="top"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={focus}
        placeholderTextColor={communitiesTheme.input.placeholder}
      />
    )
  })
)

const styles = StyleSheet.create({
  inputText: {
    fontSize: 15,
    lineHeight: 20,
    marginLeft: -4
  },
  inputTitle: {
    marginBottom: 24,
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: 28
  }
})

export default ModalInput

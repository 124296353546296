/**
 * Return the right dimensions for the WebView using an aspect ratio of 1.6
 * @param initialWidth
 * @param initialHeight
 */
export const getDimensions = (initialWidth = 0, initialHeight = 0) => {
  let width = initialWidth
  let height = initialHeight
  if (width === 0 && height === 0) {
    return { width, height }
  }
  const aspectRatio = 1.6
  const isBiggerThanAspectRatio = initialHeight / initialWidth > aspectRatio
  if (!isBiggerThanAspectRatio) {
    width = initialHeight / aspectRatio
    return { width, height: initialHeight }
  } else {
    height = initialWidth * aspectRatio
    return { width: initialWidth, height }
  }
}

import { QueryFunctionContext, useQueries, useQuery } from '@tanstack/react-query'
import { TokenTypes } from '@vatom/sdk/core'
import algoliasearch from 'algoliasearch'

import { useConfig } from 'libs/sdk/react/src/hooks'

import { useAddRecentContact, useRemoveRecentContact } from '../recent/mutations'
import {
  AlgoliaContact,
  Contact,
  ContactSources,
  Destination,
  DestinationTypes,
  Facet,
  TokenType
} from '../types'

type AlgoliaQueryContext = QueryFunctionContext<
  ReturnType<typeof algoliaQueryKeys['getAlgoliaContacts']>
>

export const algoliaQueryKeys = {
  algoliaContacts: [{ scope: 'algolia-contacts' }] as const,
  getAlgoliaContacts: (
    searchString: string,
    facetFilters: Facet[][],
    appId: string,
    apiKey: string
  ) =>
    [{ ...algoliaQueryKeys.algoliaContacts[0], appId, apiKey, searchString, facetFilters }] as const
}

const fetchContacts = async ({
  queryKey: [{ appId, apiKey, searchString, facetFilters }]
}: AlgoliaQueryContext) => {
  const client = algoliasearch(appId, apiKey)
  const users = client.initIndex('users')
  const finalSearchString = searchString.startsWith('@') ? searchString.substring(1) : searchString

  const search = await users!.search(finalSearchString, {
    facetFilters,
    restrictSearchableAttributes: searchString.startsWith('@') ? ['preferred_username'] : []
  })

  return search.hits as AlgoliaContact[]
}

const defaultSelector = (hits: AlgoliaContact[]): Contact[] => {
  const contact = hits.map(s => {
    return {
      id: s.objectID,
      name: s.name,
      source: ContactSources.userdirectory,
      createdAt: s.createdAt,
      avatarUrl: s.picture,
      identities: s.identities,
      priority: 0, // Lower priority than inventory contacts of space contacts,
      preferred_username: s.preferred_username
    }
  })

  // @ts-expect-error mst and ts
  return contact
}

const getFacetFilters = (tokenType?: TokenType) => {
  const facets: Facet[][] = []

  if (tokenType === TokenTypes.Erc721 || tokenType === TokenTypes.VatomMintedNonCustodial) {
    facets.push(['identities.type:eth'])
  }

  if (tokenType === TokenTypes.Solana) {
    facets.push(['identities.type:sol'])
  }
  return facets
}

export const useGetAlgoliaContacts = (destination?: Destination, tokenType?: TokenType) => {
  const validatedValue = destination?.validatedValue ?? ''
  const searchString =
    destination?.type === DestinationTypes.Phone ? `"${validatedValue}"` : validatedValue
  const config = useConfig()
  const query = useQuery({
    queryKey: algoliaQueryKeys.getAlgoliaContacts(
      searchString,
      getFacetFilters(tokenType) ?? [],
      config.algoliaAppId ?? '',
      config.algoliaApiKey ?? ''
    ),
    keepPreviousData: true,
    queryFn: fetchContacts,
    enabled: !!destination?.validatedValue && !!config.algoliaAppId && !!config.algoliaApiKey,
    select: defaultSelector
  })
  return query
}

export const useGetMultiAlgoliaContacts = <T = AlgoliaContact[]>(
  destinations?: Destination[],
  select?: (contacts: AlgoliaContact[]) => T
) => {
  const addRecentContactMutation = useAddRecentContact()
  const removeRecentContactMutation = useRemoveRecentContact()
  const config = useConfig()

  useQueries({
    queries:
      destinations?.map(destination => {
        const queryKey = algoliaQueryKeys.getAlgoliaContacts(
          destination?.validatedValue ?? '',
          [],
          config.algoliaAppId ?? '',
          config.algoliaApiKey ?? ''
        )
        return {
          queryKey,
          queryFn: async (context: AlgoliaQueryContext) => {
            const [{ searchString }] = context.queryKey
            const hits = await fetchContacts(context)
            try {
              const newContact = hits.length ? hits[0] : null
              if (newContact) {
                const contact = {
                  id: newContact.objectID,
                  name: newContact.name,
                  source: ContactSources.recents,
                  createdAt: newContact.createdAt,
                  avatarUrl: newContact.picture,
                  // identities: newContact.identities,
                  priority: 0, // Lower priority than inventory contacts of space contacts,
                  preferred_username: newContact.preferred_username
                } as Contact

                removeRecentContactMutation.mutateAsync(searchString).then(() => {
                  addRecentContactMutation.mutate(contact)
                })
              }
            } catch (error) {
              //
            }

            return hits
          },
          enabled: !!destination?.validatedValue && !!config.algoliaAppId && !!config.algoliaApiKey,
          select
        }
      }) || []
  })
}

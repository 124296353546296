/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { BidReceiptModel, BidReceiptModelType } from "./BidReceiptModel"
import { BidReceiptModelSelector } from "./BidReceiptModel.base"
import { ListingReceiptModel, ListingReceiptModelType } from "./ListingReceiptModel"
import { ListingReceiptModelSelector } from "./ListingReceiptModel.base"
import { NftActivityModel, NftActivityModelType } from "./NftActivityModel"
import { NftActivityModelSelector } from "./NftActivityModel.base"
import { NftAttributeModel, NftAttributeModelType } from "./NftAttributeModel"
import { NftAttributeModelSelector } from "./NftAttributeModel.base"
import { NftCreatorModel, NftCreatorModelType } from "./NftCreatorModel"
import { NftCreatorModelSelector } from "./NftCreatorModel.base"
import { NftFileModel, NftFileModelType } from "./NftFileModel"
import { NftFileModelSelector } from "./NftFileModel.base"
import { NftOwnerModel, NftOwnerModelType } from "./NftOwnerModel"
import { NftOwnerModelSelector } from "./NftOwnerModel.base"
import { PurchaseReceiptModel, PurchaseReceiptModelType } from "./PurchaseReceiptModel"
import { PurchaseReceiptModelSelector } from "./PurchaseReceiptModel.base"
import { RootStoreType } from "./index"


/**
 * NftBase
 * auto generated base class for the model NftModel.
 */
export const NftModelBase = ModelBase
  .named('Nft')
  .props({
    __typename: types.optional(types.literal("Nft"), "Nft"),
    address: types.union(types.undefined, types.string),
    name: types.union(types.undefined, types.string),
    sellerFeeBasisPoints: types.union(types.undefined, types.integer),
    mintAddress: types.union(types.undefined, types.string),
    primarySaleHappened: types.union(types.undefined, types.boolean),
    description: types.union(types.undefined, types.string),
    category: types.union(types.undefined, types.string),
    /** The JSON parser with which the NFT was processed by the indexer  - `"full"` indicates the full Metaplex standard-compliant parser was   used. - `"minimal"` (provided with an optional description of an error)   indicates the full model failed to parse and a more lenient fallback   parser with fewer fields was used instead. */
    parser: types.union(types.undefined, types.null, types.string),
    image: types.union(types.undefined, types.string),
    creators: types.union(types.undefined, types.array(types.late((): any => NftCreatorModel))),
    attributes: types.union(types.undefined, types.array(types.late((): any => NftAttributeModel))),
    owner: types.union(types.undefined, types.null, types.late((): any => NftOwnerModel)),
    activities: types.union(types.undefined, types.array(types.late((): any => NftActivityModel))),
    listings: types.union(types.undefined, types.array(types.late((): any => ListingReceiptModel))),
    purchases: types.union(types.undefined, types.array(types.late((): any => PurchaseReceiptModel))),
    offers: types.union(types.undefined, types.array(types.late((): any => BidReceiptModel))),
    files: types.union(types.undefined, types.array(types.late((): any => NftFileModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class NftModelSelector extends QueryBuilder {
  get address() { return this.__attr(`address`) }
  get name() { return this.__attr(`name`) }
  get sellerFeeBasisPoints() { return this.__attr(`sellerFeeBasisPoints`) }
  get mintAddress() { return this.__attr(`mintAddress`) }
  get primarySaleHappened() { return this.__attr(`primarySaleHappened`) }
  get description() { return this.__attr(`description`) }
  get category() { return this.__attr(`category`) }
  get parser() { return this.__attr(`parser`) }
  get image() { return this.__attr(`image`) }
  creators(builder: string | NftCreatorModelSelector | ((selector: NftCreatorModelSelector) => NftCreatorModelSelector) | undefined) { return this.__child(`creators`, NftCreatorModelSelector, builder) }
  attributes(builder: string | NftAttributeModelSelector | ((selector: NftAttributeModelSelector) => NftAttributeModelSelector) | undefined) { return this.__child(`attributes`, NftAttributeModelSelector, builder) }
  owner(builder: string | NftOwnerModelSelector | ((selector: NftOwnerModelSelector) => NftOwnerModelSelector) | undefined) { return this.__child(`owner`, NftOwnerModelSelector, builder) }
  activities(builder: string | NftActivityModelSelector | ((selector: NftActivityModelSelector) => NftActivityModelSelector) | undefined) { return this.__child(`activities`, NftActivityModelSelector, builder) }
  listings(builder: string | ListingReceiptModelSelector | ((selector: ListingReceiptModelSelector) => ListingReceiptModelSelector) | undefined) { return this.__child(`listings`, ListingReceiptModelSelector, builder) }
  purchases(builder: string | PurchaseReceiptModelSelector | ((selector: PurchaseReceiptModelSelector) => PurchaseReceiptModelSelector) | undefined) { return this.__child(`purchases`, PurchaseReceiptModelSelector, builder) }
  offers(builder: string | BidReceiptModelSelector | ((selector: BidReceiptModelSelector) => BidReceiptModelSelector) | undefined) { return this.__child(`offers`, BidReceiptModelSelector, builder) }
  files(builder: string | NftFileModelSelector | ((selector: NftFileModelSelector) => NftFileModelSelector) | undefined) { return this.__child(`files`, NftFileModelSelector, builder) }
}
export function selectFromNft() {
  return new NftModelSelector()
}

export const nftModelPrimitives = selectFromNft().address.name.sellerFeeBasisPoints.mintAddress.primarySaleHappened.description.category.parser.image

import React from 'react'
import AnimatedLottieView from 'react-lottie'
import { ConnectionStatus } from '@vatom/sdk/react'

export const StatusIcon = ({
  status,
  width = 256,
  height = 256
}: {
  status: ConnectionStatus
  width?: number
  height?: number
}) => {
  let img = ''

  switch (status) {
    // Connecting icon
    case ConnectionStatus.Connecting:
      img = require('./animations/lottie-connecting.json')
      break
    // Connected icon
    case ConnectionStatus.Connected:
      img = require('./animations/lottie-connected.json')
      break
    // Ended icon
    case ConnectionStatus.Ended:
      img = require('./animations/lottie-ended.json')
      break
    // Error icon
    case ConnectionStatus.Error:
      img = require('./animations/lottie-error.json')
      break
    default:
      img = ''
      break
  }

  if (img === '') {
    return null
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: img
  }

  return <AnimatedLottieView options={defaultOptions} style={{ flex: 1, width, height }} />
}

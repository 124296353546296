/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { NftModel, NftModelType } from "./NftModel"
import { NftModelSelector } from "./NftModel.base"
import { RootStoreType } from "./index"


/**
 * PurchaseReceiptBase
 * auto generated base class for the model PurchaseReceiptModel.
 */
export const PurchaseReceiptModelBase = ModelBase
  .named('PurchaseReceipt')
  .props({
    __typename: types.optional(types.literal("PurchaseReceipt"), "PurchaseReceipt"),
    address: types.union(types.undefined, types.string),
    buyer: types.union(types.undefined, types.frozen()),
    seller: types.union(types.undefined, types.frozen()),
    metadata: types.union(types.undefined, types.frozen()),
    auctionHouse: types.union(types.undefined, types.frozen()),
    price: types.union(types.undefined, types.frozen()),
    createdAt: types.union(types.undefined, types.frozen()),
    nft: types.union(types.undefined, types.null, types.late((): any => NftModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PurchaseReceiptModelSelector extends QueryBuilder {
  get address() { return this.__attr(`address`) }
  get buyer() { return this.__attr(`buyer`) }
  get seller() { return this.__attr(`seller`) }
  get metadata() { return this.__attr(`metadata`) }
  get auctionHouse() { return this.__attr(`auctionHouse`) }
  get price() { return this.__attr(`price`) }
  get createdAt() { return this.__attr(`createdAt`) }
  nft(builder: string | NftModelSelector | ((selector: NftModelSelector) => NftModelSelector) | undefined) { return this.__child(`nft`, NftModelSelector, builder) }
}
export function selectFromPurchaseReceipt() {
  return new PurchaseReceiptModelSelector()
}

export const purchaseReceiptModelPrimitives = selectFromPurchaseReceipt().address.buyer.seller.metadata.auctionHouse.price.createdAt

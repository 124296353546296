import { useRef, useState } from 'react'
import { Dimensions, Platform, StyleSheet } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import { HeaderBackOptions, Loader, LoaderView, PressableOpacity, Text, theme } from '@vatom/wombo'
import { BarCodeScanner } from 'expo-barcode-scanner'
import { BarCodeScanningResult, Camera, CameraType, FlashMode } from 'expo-camera'
import { Box } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import { HeaderButtonClose } from '../../../components/ScreenHeader'
import useCameraPermission from '../../../hooks/useCameraPermission'
import ScanOutline from '../ScanOutline'

interface ICameraScanner {
  onClose: () => void
  onScanner: (data: BarCodeScanningResult) => void
}

export const CameraScanner = ({ onClose, onScanner }: ICameraScanner) => {
  const { permission, isLoading } = useCameraPermission()
  const [torch, setTorch] = useState(false)
  const cameraRef = useRef<Camera>()

  const [imagePadding, setImagePadding] = useState(0)
  const [ratio, setRatio] = useState('4:3') // default is 4:3
  const { height, width } = Dimensions.get('window')
  const screenRatio = height / width
  const [isRatioSet, setIsRatioSet] = useState(false)

  const handleOnScanner = useThrottledCallback(onScanner, 500)

  // set the camera ratio and padding.
  // this code assumes a portrait mode screen
  const prepareRatio = async () => {
    let desiredRatio = '4:3' // Start with the system default
    // This issue only affects Android
    if (Platform.OS === 'android' && cameraRef.current) {
      const ratios = await cameraRef.current.getSupportedRatiosAsync()

      // Calculate the width/height of each of the supported camera ratios
      // These width/height are measured in landscape mode
      // find the ratio that is closest to the screen ratio without going over
      const distances: Record<string, number> = {}
      const realRatios: Record<string, number> = {}
      let minDistance = null
      for (const ratio of ratios) {
        const parts = ratio.split(':')
        const realRatio = parseInt(parts[0]) / parseInt(parts[1])
        realRatios[ratio] = realRatio
        // ratio can't be taller than screen, so we don't want an abs()
        const distance = screenRatio - realRatio
        distances[ratio] = realRatio
        if (minDistance == null) {
          minDistance = ratio
        } else {
          if (distance >= 0 && distance < distances[minDistance]) {
            minDistance = ratio
          }
        }
      }
      // set the best match
      if (minDistance) {
        desiredRatio = minDistance
      }
      //  calculate the difference between the camera width and the screen height
      const remainder = Math.floor((height - realRatios[desiredRatio] * width) / 2)
      // set the preview padding and preview ratio
      setImagePadding(remainder / 2)
      setRatio(desiredRatio)
      // Set a flag so we don't do this
      // calculation each time the screen refreshes
      setIsRatioSet(true)
    }
  }

  // the camera must be loaded in order to access the supported ratios
  const setCameraReady = async () => {
    if (!isRatioSet) {
      await prepareRatio()
    }
  }

  return (
    <Box
      h="100%"
      w="100%"
      maxW={Platform.OS === 'web' ? '600px' : '100%'}
      margin={Platform.OS === 'web' ? 'auto' : 0}
      borderTopRadius={10}
    >
      {permission?.granted ? (
        <Box
          style={{
            ...StyleSheet.absoluteFillObject,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            overflow: 'hidden'
          }}
        >
          <Camera
            ref={ref => {
              if (ref) {
                cameraRef.current = ref
              }
            }}
            flashMode={torch ? FlashMode.torch : FlashMode.off}
            barCodeScannerSettings={{
              barCodeTypes: [
                BarCodeScanner.Constants.BarCodeType.qr,
                BarCodeScanner.Constants.BarCodeType.code128,
                BarCodeScanner.Constants.BarCodeType.code39,
                BarCodeScanner.Constants.BarCodeType.code93
              ]
            }}
            ratio={ratio}
            onCameraReady={setCameraReady}
            onBarCodeScanned={handleOnScanner}
            style={{
              flex: 1
            }}
            type={CameraType.back}
          />
        </Box>
      ) : null}

      <Box w="100%" zIndex="10" flex={1} justifyContent="center" alignItems="center">
        <Box
          zIndex={10}
          position="absolute"
          top="0"
          w="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Box width={'100%'} alignSelf="flex-start" flex={1}>
            <HeaderBackOptions
              closeIcon={
                <Box left={3} top={4}>
                  <Box
                    backgroundColor={'rgba(0,0,0,.5)'}
                    borderRadius={'99'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    height={10}
                    width={10}
                  >
                    <HeaderButtonClose
                      color={
                        permission && permission.granted ? theme.colors.white : theme.colors.black
                      }
                    />
                  </Box>
                </Box>
              }
              showOptions={false}
              onClose={onClose}
              color={permission && permission.granted ? theme.colors.white : theme.colors.black}
            />
          </Box>
        </Box>
        {isLoading ? (
          <Loader></Loader>
        ) : (
          <>
            <Box top={20} background="rgba(0,0,0,0.5)" w="50%" borderRadius="10">
              <Text textAlign="center" p="2" color="#FFFFFF" tx="actionSheets.scanCode" />
            </Box>

            <Box flex="1" justifyContent="center" alignItems="center" zIndex={9}>
              <ScanOutline />
            </Box>

            {Platform.OS !== 'web' && (
              <Box
                h="250"
                position="absolute"
                bottom={0}
                w="100%"
                justifyContent="center"
                alignItems="center"
                zIndex={10}
              >
                <PressableOpacity
                  // enable?: boolean, brightness?: number
                  onPress={() => setTorch(!torch)}
                  background={torch ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.5)'}
                  h="70"
                  w="70"
                  borderRadius="35"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Ionicons name="flashlight-outline" size={40} color={torch ? 'black' : 'white'} />
                </PressableOpacity>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

import React from 'react'

import { WebWrapper } from '../../components/WebWrapper'

import { SellNFT, SellNFTProps } from './SellNFT'

export default (props: SellNFTProps) => {
  return (
    <WebWrapper justifyContent={'center'} flex={1} flexShrink={0}>
      <SellNFT {...props} />
    </WebWrapper>
  )
}

import AsyncStorage from '@react-native-async-storage/async-storage'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { StoredAddresses } from '../../../../modules/web3-wallet'

type Wallets = StoredAddresses & {
  n: number
}

type WalletStore = {
  wallets: Wallets[]
  selectedAddress?: Wallets
  addWallet: (wallet: Wallets) => void
  removeAddress: (address: string) => void
  removeWallet: (address: string) => void
  setSelectedAddress: (address: string) => void
  updateAddressName: (address: string, name: string) => void
  clearPersist: () => void
}

const StorageKey = 'wallets-storage'

export const groupedWalletsSelector = (walletState: WalletStore) => {
  const currentWallets = walletState.wallets
  return Object.values(
    currentWallets
      .sort((a, b) => a.n - b.n)
      .reduce((acc, obj) => {
        /* @ts-ignore */
        acc[obj.wallet] = acc[obj.wallet] || []
        /* @ts-ignore */
        acc[obj.wallet].push(obj)
        return acc
      }, {})
  ) as Wallets[][]
}

export const useWalletStore = create(
  persist<WalletStore>(
    (set, get) => ({
      wallets: [],
      setSelectedAddress: (address: string) => {
        set(state => {
          return {
            selectedAddress: state.wallets.find(
              obj => obj.address.toLowerCase() === address.toLowerCase()
            )
          }
        })
      },
      addWallet: (wallet: Wallets) => {
        if (!get().wallets.find(obj => obj.address === wallet.address)) {
          set(state => {
            return {
              wallets: [...state.wallets, wallet]
            }
          })
        }
      },
      removeAddress: (address: string) => {
        set(state => {
          return {
            wallets: state.wallets.filter(obj => obj.address !== address)
          }
        })
      },
      removeWallet: (address: string) => {
        set(state => {
          return {
            wallets: state.wallets.filter(obj => obj.wallet !== address)
          }
        })
      },
      updateAddressName: (address: string, name: string) => {
        const selectedAddress = get().wallets.find(wallet => wallet.address === address)

        if (selectedAddress) {
          selectedAddress.name = name
          set(state => {
            return {
              wallets: [...state.wallets.filter(obj => obj.address !== address), selectedAddress]
            }
          })
        }
      },
      clearPersist: async () => {
        try {
          await AsyncStorage.removeItem(StorageKey)
          set(() => {
            return {
              wallets: []
            }
          })
          console.log('Persisted state cleared.')
        } catch (error) {
          console.error('Failed to clear persisted state:', error)
        }
      }
    }),
    {
      name: StorageKey,
      getStorage: () => AsyncStorage,
      version: 2
    }
  )
)

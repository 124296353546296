/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * NftFileBase
 * auto generated base class for the model NftFileModel.
 */
export const NftFileModelBase = ModelBase
  .named('NftFile')
  .props({
    __typename: types.optional(types.literal("NftFile"), "NftFile"),
    metadataAddress: types.union(types.undefined, types.string),
    uri: types.union(types.undefined, types.string),
    fileType: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class NftFileModelSelector extends QueryBuilder {
  get metadataAddress() { return this.__attr(`metadataAddress`) }
  get uri() { return this.__attr(`uri`) }
  get fileType() { return this.__attr(`fileType`) }
}
export function selectFromNftFile() {
  return new NftFileModelSelector()
}

export const nftFileModelPrimitives = selectFromNftFile().metadataAddress.uri.fileType

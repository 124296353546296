import { create } from 'zustand'
import { combine } from 'zustand/middleware'

const defaultNumberOfTokens = 5

type SimpleARGameStore = {
  modelPositions: ReturnType<typeof getRandomPositions>
  maxPoints: number
}

const initialState: SimpleARGameStore = {
  maxPoints: defaultNumberOfTokens,
  modelPositions: getRandomPositions(defaultNumberOfTokens, 5, 10)
}

export const useSimpleARGameStore = create(
  combine(initialState, (set, get) => ({
    startGame: (
      maxPoints = defaultNumberOfTokens,
      currentPoints = 0,
      positions = getRandomPositions(maxPoints - currentPoints, 5, 3)
    ) => {
      set({ maxPoints, modelPositions: positions })
    },
    onPickup: (index: number) => {
      const { modelPositions } = get()
      const newPositions = [...modelPositions]
      newPositions.splice(index, 1)

      set({
        modelPositions: newPositions
      })
    },
    reset: () => {
      set(initialState)
    }
  }))
)

function getRandomPositions(n: number, radius: number, minDistance: number, maxTries = 10000) {
  const positions = []
  for (let i = 0; i < n; i++) {
    let pos: [number, number, number]
    let overlap: boolean
    let tries = 0

    do {
      overlap = false
      const angle = Math.random() * Math.PI * 2 // Random angle
      const distance = radius + Math.random() * minDistance // Random distance, at least `radius` away from center

      // Convert polar coordinates to Cartesian coordinates
      const x = Math.round(distance * Math.cos(angle) * 100) / 100
      const y = Math.round(distance * Math.sin(angle) * 100) / 100

      pos = [x, 0, y]

      const positionsToAvoid = positions
      // check for overlap with existing positions
      for (const existingPos of positionsToAvoid) {
        const dx = existingPos[0] - pos[0]
        const dy = existingPos[2] - pos[2]
        const distanceBetween = Math.sqrt(dx * dx + dy * dy)
        if (distanceBetween < minDistance) {
          overlap = true
          break
        }
      }

      tries++
    } while (overlap && tries < maxTries)

    if (tries == maxTries) {
      // throw new Error('Could not find a suitable position after maximum tries')
      alert('Could not find a suitable position after maximum tries')
    }

    positions.push(pos)
  }
  return positions
}

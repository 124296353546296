import { useFrame } from '@react-three/fiber'
import { useControls } from 'leva'

import { useLocationStore } from '../hooks/useLocationStore'

export const GeolocationPanel = () => {
  const [_, set] = useControls('Geolocation', () => {
    return {
      latitude: {
        value: 0,
        editable: false
      },
      longitude: {
        value: 0,
        editable: false
      },
      altitude: {
        value: 0,
        editable: false
      },
      accuracy: {
        value: 0,
        editable: false
      },
      altitudeAccuracy: {
        value: 0,
        editable: false
      },
      heading: {
        value: 0,
        editable: false
      }
    }
  })

  useFrame(() => {
    const { position, heading } = useLocationStore.getState()
    set({
      latitude: position?.coords.latitude ?? 0,
      longitude: position?.coords.longitude ?? 0,
      altitude: position?.coords.altitude ?? 0,
      accuracy: position?.coords.accuracy ?? 0,
      altitudeAccuracy: position?.coords.altitudeAccuracy ?? 0,
      heading: heading ?? 0
    })
  })

  return null
}

import React from 'react'
import { EvilIcons } from '@expo/vector-icons'
import { Text, theme } from '@vatom/wombo'
import { Divider, Icon, Link } from 'native-base'

export enum LinkingType {
  externalLink = 'external-link',
  forward = 'forward'
}

interface LinkingComponentProps {
  href?: string
  label: string
  type: LinkingType
}

export const LinkingComponent = ({ href, label, type }: LinkingComponentProps) => {
  return (
    <>
      <Link
        href={href}
        justifyContent="space-between"
        paddingX="16px"
        h="44px"
        alignItems="center"
        backgroundColor="white"
        _dark={{
          backgroundColor: theme.colors.grayCool[900]
        }}
        isExternal={true}
      >
        <Text
          _dark={{
            color: theme.colors.textDarkMode[200]
          }}
          fontSize="15px"
          fontFamily="Inter-Regular"
        >
          {label}
        </Text>
        <Icon
          _dark={{
            color: theme.colors.textDarkMode[200]
          }}
          as={EvilIcons}
          name={type === 'forward' ? 'chevron-right' : 'external-link'}
          size={type === 'forward' ? '25px' : '20px'}
        />
      </Link>
      <Divider
        _dark={{
          backgroundColor: '#FFFFFF4D'
        }}
        backgroundColor="gray.900"
      />
    </>
  )
}

export default LinkingComponent

import { Platform } from 'react-native'
import { Channels, useNotificationsStore } from '@vatom/sdk/react'

import PeopleIcon from '../assets/icon_tabbar_people.svg'

import { ActiveIcon } from './ActiveIcon'

export const ConnectTabIcon = ({ focused, color }: { focused: boolean; color: string }) => {
  const shouldShowBadge = useNotificationsStore(state => !!state.channels[Channels.wallet]?.length)

  return (
    <ActiveIcon isActive={shouldShowBadge && !focused}>
      <PeopleIcon
        height={Platform.OS === 'web' ? 18 : 32}
        width={Platform.OS === 'web' ? 26 : 32}
        fill={color}
      />
    </ActiveIcon>
  )
}

import { Platform } from 'react-native'
import { STAGE } from 'react-native-dotenv'

const hosts = {
  dev: 'https://businesses.api.vatominc.cool',
  stage: 'https://businesses.api.vatominc.cc',
  prod: 'https://businesses.api.vatominc.com',
  local: Platform.select({
    android: 'http://localhost:3000',
    ios: 'http://localhost:3000'
  })
} as const
// export const host = hosts[STAGE as keyof typeof hosts] ?? hosts.dev

export const host = 'https://businesses.api.vatominc.com'
export const matrixServerUrl = 'https://matrix.api.vatominc.com'

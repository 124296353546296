import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { AntDesign } from '@expo/vector-icons'
import { useSDK, useTokenInventory } from '@vatom/sdk/react'
import { observer } from 'mobx-react-lite'
import { Icon, IconButton } from 'native-base'

import { XR8Canvas } from '../../components/XR8Canvas'
import { CompassHUD } from '../ARGame/partials/CompassHUD'
import { Model } from '../ARGame/partials/Model'
import { SceneWrapper } from '../ARGame/partials/SceneWrapper'

import { TokenARProps } from './'

export const TokenAR = observer(({ navigation, route }: TokenARProps) => {
  const { tokenId } = route.params
  const { token } = useTokenInventory(tokenId)
  const insets = useSafeAreaInsets()

  return (
    <>
      {/* Close button */}
      <IconButton
        borderRadius={'full'}
        position="absolute"
        zIndex={1000}
        top={`${insets.top || 20}px`}
        left={`16px`}
        opacity="0.7"
        backgroundColor={'black'}
        icon={<Icon color="white" as={AntDesign} size="24px" name="close" />}
        onPressOut={() => navigation.goBack()}
        _hover={{
          bg: 'transparent'
        }}
        _pressed={{
          bg: 'transparent',
          _icon: {
            name: 'close'
          },
          _ios: {
            _icon: {
              size: '2xl'
            }
          }
        }}
        _ios={{
          _icon: {
            size: '2xl'
          }
        }}
      />

      {/* Token AR */}
      {/* {token ? <ARViewerWeb sdk={sdk} tokens={[token]} /> : null} */}
      {token ? (
        <>
          <XR8Canvas top={0}>
            <SceneWrapper usesLocation={false}>
              {token?.threeDInfo?.url ? (
                <Model url={token.threeDInfo.url} position={[0, 0, -5]}></Model>
              ) : null}
            </SceneWrapper>
          </XR8Canvas>
          <CompassHUD />
        </>
      ) : null}
    </>
  )
})

export default TokenAR

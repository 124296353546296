import { Modal, StyleSheet, Text, TouchableOpacity, View } from 'react-native'

import { useCommunitiesTheme } from '../../../themes'

type ModalErrorProps = {
  showError: boolean
  errorTitle: string
  errorDescription: string
  setShowError: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalError = (props: ModalErrorProps) => {
  const communitiesTheme = useCommunitiesTheme()

  const { showError, errorTitle, errorDescription, setShowError } = props
  return (
    <Modal visible={showError} animationType="fade" transparent={true}>
      <TouchableOpacity
        accessibilityRole="button"
        style={styles.greyBackground}
        onPress={() => setShowError(false)}
      >
        <View style={styles.errorHolder}>
          <Text style={styles.errorTitle}>{errorTitle}</Text>
          <Text style={styles.errorDescription}>{errorDescription}</Text>
          <TouchableOpacity
            accessibilityRole="button"
            style={{ ...styles.button, backgroundColor: communitiesTheme.accentColor }}
            onPress={() => setShowError(false)}
          >
            <Text style={{ ...styles.buttonText, color: communitiesTheme.accentColorText }}>
              OK
            </Text>
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    </Modal>
  )
}

export default ModalError

const styles = StyleSheet.create({
  greyBackground: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: `rgba(0, 0, 0, 0.5)`
  },
  errorHolder: {
    backgroundColor: 'white',
    paddingHorizontal: 16,
    paddingVertical: 32,
    maxWidth: 270,
    width: '100%',
    borderRadius: 8
  },
  errorTitle: {
    fontSize: 17,
    lineHeight: 24,
    fontWeight: '700',
    textAlign: 'center'
  },
  errorDescription: {
    fontSize: 13,
    lineHeight: 18,
    color: '#3F4A55',
    textAlign: 'center',
    marginTop: 8
  },
  button: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 3,
    marginTop: 16
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 11,
    fontWeight: '500',
    lineHeight: 15
  }
})

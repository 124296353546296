/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AuctionHouseModel, AuctionHouseModelType } from "./AuctionHouseModel"
import { AuctionHouseModelSelector } from "./AuctionHouseModel.base"
import { MarketStatsModel, MarketStatsModelType } from "./MarketStatsModel"
import { MarketStatsModelSelector } from "./MarketStatsModel.base"
import { StoreCreatorModel, StoreCreatorModelType } from "./StoreCreatorModel"
import { StoreCreatorModelSelector } from "./StoreCreatorModel.base"
import { RootStoreType } from "./index"


/**
 * MarketplaceBase
 * auto generated base class for the model MarketplaceModel.
 */
export const MarketplaceModelBase = ModelBase
  .named('Marketplace')
  .props({
    __typename: types.optional(types.literal("Marketplace"), "Marketplace"),
    configAddress: types.union(types.undefined, types.string),
    subdomain: types.union(types.undefined, types.string),
    name: types.union(types.undefined, types.string),
    description: types.union(types.undefined, types.string),
    logoUrl: types.union(types.undefined, types.string),
    bannerUrl: types.union(types.undefined, types.string),
    ownerAddress: types.union(types.undefined, types.string),
    auctionHouseAddress: types.union(types.undefined, types.string),
    storeAddress: types.union(types.undefined, types.null, types.string),
    auctionHouse: types.union(types.undefined, types.null, types.late((): any => AuctionHouseModel)),
    creators: types.union(types.undefined, types.array(types.late((): any => StoreCreatorModel))),
    stats: types.union(types.undefined, types.null, types.late((): any => MarketStatsModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class MarketplaceModelSelector extends QueryBuilder {
  get configAddress() { return this.__attr(`configAddress`) }
  get subdomain() { return this.__attr(`subdomain`) }
  get name() { return this.__attr(`name`) }
  get description() { return this.__attr(`description`) }
  get logoUrl() { return this.__attr(`logoUrl`) }
  get bannerUrl() { return this.__attr(`bannerUrl`) }
  get ownerAddress() { return this.__attr(`ownerAddress`) }
  get auctionHouseAddress() { return this.__attr(`auctionHouseAddress`) }
  get storeAddress() { return this.__attr(`storeAddress`) }
  auctionHouse(builder: string | AuctionHouseModelSelector | ((selector: AuctionHouseModelSelector) => AuctionHouseModelSelector) | undefined) { return this.__child(`auctionHouse`, AuctionHouseModelSelector, builder) }
  creators(builder: string | StoreCreatorModelSelector | ((selector: StoreCreatorModelSelector) => StoreCreatorModelSelector) | undefined) { return this.__child(`creators`, StoreCreatorModelSelector, builder) }
  stats(builder: string | MarketStatsModelSelector | ((selector: MarketStatsModelSelector) => MarketStatsModelSelector) | undefined) { return this.__child(`stats`, MarketStatsModelSelector, builder) }
}
export function selectFromMarketplace() {
  return new MarketplaceModelSelector()
}

export const marketplaceModelPrimitives = selectFromMarketplace().configAddress.subdomain.name.description.logoUrl.bannerUrl.ownerAddress.auctionHouseAddress.storeAddress

import { Contact, ContactSource, Destination, DestinationTypes } from '../people'

import { UserData } from './types'

export const converToContactSelector = <T = Contact>(
  user: UserData,
  source: ContactSource,
  destination?: Destination,
  defaultName?: string
) => {
  const id = user.sub || user.id || ''
  if (!id) return undefined
  if (
    destination &&
    (destination.type !== DestinationTypes.Id ||
      (!user.name?.includes(destination.value) && !id.includes(destination.value)))
  ) {
    return undefined
  }

  return {
    id,
    source: source,
    avatarUrl: user.picture,
    name: user.name ?? defaultName,
    createdAt: user.createdAt,
    priority: 5,
    identities: user.identities,
    preferred_username: user.preferred_username,
    updatedAt: user.updatedAt
  } as T
}

import { theme } from '@vatom/wombo'
import { Box } from 'native-base'

export const Divider = (props?: React.ComponentProps<typeof Box>) => {
  return (
    <Box
      minWidth={'auto'}
      flexGrow={1}
      style={{
        height: 1
      }}
      _light={{
        backgroundColor: theme.colors.grayCool[200]
      }}
      _dark={{
        backgroundColor: theme.colors.grayDarkMode[700]
      }}
      {...props}
    />
  )
}

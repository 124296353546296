import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { getRootStore } from '../../../SDKProvider'

import { UserProfileData } from './types'

const publicProfileQueryKeys = {
  publicProfile: [{ scope: 'public-profile' }] as const,
  getPublicProfile: () => [{ ...publicProfileQueryKeys.publicProfile[0] }] as const
}

const fetchUserProfile = async () => {
  const apiInstance = getRootStore().service.oidc
  const response = await apiInstance.get<UserProfileData>('/me')
  return response.data as UserProfileData
}

type UserProfileOptions<T> = Omit<
  UseQueryOptions<
    UserProfileData,
    unknown,
    T,
    ReturnType<typeof publicProfileQueryKeys.getPublicProfile>
  >,
  'queryKey' | 'queryFn'
>

export const useGetUserProfile = <T = UserProfileData>(options?: UserProfileOptions<T>) => {
  const query = useQuery({
    queryKey: publicProfileQueryKeys.getPublicProfile(),
    queryFn: fetchUserProfile,
    enabled: options?.enabled !== false,
    ...options
  })

  return query
}

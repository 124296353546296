import { useCallback } from 'react'
import { useNavigation } from '@react-navigation/native'

import { useBusinessSelector } from '../../hooks/useBusinessSelector'
import { useIsDesktop } from '../../hooks/useIsDesktop'
import { AppNavigation, AppRoutes, WalletRoutes } from '../../navigators'

import { useCoinStore } from './useCoinStore'

const useCoinNavigation = () => {
  const navigation = useNavigation<AppNavigation>()
  const isDesktop = useIsDesktop()

  const { businessIdentifier } = useBusinessSelector()

  const { _reset: resetCoinStore } = useCoinStore()

  const onBack = useCallback(() => {
    navigation.goBack()
  }, [navigation])

  const onClose = useCallback(() => {
    if (isDesktop) {
      if (businessIdentifier) {
        navigation.navigate(AppRoutes.BusinessProxy, {
          business: businessIdentifier,
          screen: WalletRoutes.Coins
        })
      } else {
        navigation.navigate(AppRoutes.home, {
          screen: WalletRoutes.Coins
        })
      }
      // Reset Coin Store state
      resetCoinStore()
    } else {
      onBack()
    }
  }, [businessIdentifier, isDesktop, navigation, onBack, resetCoinStore])

  return { onBack, onClose }
}

export { useCoinNavigation }

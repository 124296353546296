import React, { useEffect, useRef, useState } from 'react'
import {
  ARGameObjectDefinition,
  ARGameTypes,
  BVatomTokenType,
  extractObjectDefinitionId,
  GeoARGameConfig,
  MultiLevelGameConfig,
  SimpleARGameConfig
} from '@vatom/BVatom/plugin'
import {
  useCampaignGroupsRules,
  useCampaignPoints,
  useVatomObjectDefinition
} from '@vatom/sdk/react'
import { PressableOpacity } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, ScrollView, Text } from 'native-base'

import { XR8Canvas } from '../../../components/XR8Canvas'
import { useTokensInRadius } from '../../MapAR/AR.web'
import { CompassHUD } from '../partials/CompassHUD'
import { Model } from '../partials/Model'
import { SceneWrapper } from '../partials/SceneWrapper'

import { useSimpleARGameStore } from './useSimpleGameARStore'

type AnyGameConfig = SimpleARGameConfig | MultiLevelGameConfig | GeoARGameConfig

type ARGameProps = {
  vatom: BVatomTokenType
  gameConfig: AnyGameConfig
  points: number
  setPoints: (i: number) => void //React.Dispatch<React.SetStateAction<number>>
  onGameOver: () => void
}
export const LegacyArGameScenes = observer((props: ARGameProps) => {
  const { gameConfig, vatom } = props

  const games = {
    [ARGameTypes.SimpleGame]: (
      <SimpleGameScene {...props} gameConfig={gameConfig as SimpleARGameConfig} />
    ),
    [ARGameTypes.GeoGame]: <GeoARGameScene {...props} gameConfig={gameConfig as GeoARGameConfig} />,
    //  <MultiLevelGameScene {...props} gameConfig={gameConfig as MultiLevelGameConfig} />
    [ARGameTypes.MultiLevelGeoGame]: null
  } as const
  const gameType = vatom.gameType
  const game = gameType && gameType in games ? games[gameType as keyof typeof games] : null
  // return <TestGame {...props}></TestGame>
  return (
    <>
      <XR8Canvas top={gameConfig.overlayHeight.value}>
        <SceneWrapper>
          {/* <Playground /> */}
          {game}
        </SceneWrapper>
      </XR8Canvas>
      <CompassHUD />
    </>
  )
})

const useGameRules = (vatom: BVatomTokenType) => {
  const businessId = vatom.studioInfo!.businessId
  const rules = useCampaignGroupsRules(businessId, vatom.studioInfo!.campaignId)
  return rules
}

const useGamePoints = (vatom: BVatomTokenType) => {
  const points = useCampaignPoints(vatom.studioInfo!.campaignId)
  return points
}

const TestGame = ({ vatom }: ARGameProps) => {
  const arFilter = vatom.private['ar-filter-v2']!
  const [levelIdx, setLevelIdx] = useState(0)
  const level = arFilter.levels[levelIdx]
  const fakeTokens = level.objectDefinitions.filter(t => !t.isToken)

  const rules = useGameRules(vatom)
  const points = useGamePoints(vatom)

  return (
    <ScrollView flex={1} backgroundColor="white">
      <Text>Rules</Text>
      <Text>{JSON.stringify(rules.data, null, 2)}</Text>
      <Text>Fake tokens</Text>
      {fakeTokens.map((objDef, index) => {
        return (
          <TestModel
            key={objDef.objectDefinitionLink}
            objectDefinition={objDef}
            gameVatom={vatom}
          />
        )
      })}
    </ScrollView>
  )
}

type GameTokenProps = {
  // Parent vatom
  gameVatom: BVatomTokenType
  objectDefinition: ARGameObjectDefinition
}

const TestModel = (props: GameTokenProps) => {
  const objDefinitionId = extractObjectDefinitionId(props.objectDefinition.objectDefinitionLink)

  const objectDefinition = useVatomObjectDefinition(
    props.gameVatom.studioInfo!.businessId,
    objDefinitionId
  )
  const rules = useGameRules(props.gameVatom)

  const onClick = () => {
    // const trigger = rules.data?.items.find(rule => rule.trigger.config)
  }

  // const rules = useCampaignRules(props.businessId, objDefinitionId)

  return (
    <PressableOpacity onPress={onClick}>
      <Box borderWidth={1}>
        <Text>Object Def</Text>
        <Text>{JSON.stringify(objectDefinition.data, null, 2)}</Text>
        {/* <Text>Object Rules</Text>
      <Text>{JSON.stringify(rules.data, null, 2)}</Text> */}
      </Box>
    </PressableOpacity>
  )
}

export function SimpleGameScene({
  vatom,
  gameConfig,
  points,
  setPoints,
  onGameOver
}: {
  vatom: BVatomTokenType
  gameConfig: SimpleARGameConfig
  points: number
  setPoints: (i: number) => void // React.Dispatch<React.SetStateAction<number>>
  onGameOver: () => void
}) {
  const positions = useSimpleARGameStore(state => state.modelPositions)
  const url = gameConfig.modelUrl.ref

  useEffect(
    () => {
      useSimpleARGameStore.getState().startGame(vatom.userPoints?.maxPoints, points)
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <>
      {positions.map((position, index) => {
        return (
          <Model
            key={JSON.stringify(position)}
            position={position}
            onClick={e => {
              e.stopPropagation()
              vatom.performAction('varius.action:varius.io:collect-game-token-v1')
              useSimpleARGameStore.getState().onPickup(index)
              const newUserPoints = points + 1
              if (newUserPoints >= vatom.userPoints!.maxPoints) {
                setPoints(newUserPoints)
                onGameOver()
              } else {
                setPoints(newUserPoints)
              }
            }}
            url={url}
          />
        )
      })}
    </>
  )
}

export const GeoARGameScene = observer(
  ({
    vatom,
    gameConfig,
    onGameOver,
    points,
    setPoints
  }: {
    vatom: BVatomTokenType
    gameConfig: GeoARGameConfig
    points: number
    setPoints: (i: number) => void // React.Dispatch<React.SetStateAction<number>>
    onGameOver: () => void
  }) => {
    const { location, tokens } = useTokensInRadius(500)
    const initialized = useRef(false)
    const tokenPositions = useRef<Record<string, [number, number, number]>>({})

    useEffect(() => {
      console.log('location', location)
      if (location && !initialized.current) {
        initialized.current = true
        const qty = (vatom.userPoints?.maxPoints ?? 0) - points

        vatom.performAction('varius.action:varius.io:open-ar-view-v1', {
          'this.id': vatom.id,
          'geo.pos': {
            Lon: location?.coords.longitude,
            Lat: location?.coords.latitude,
            qty
          }
        })
      }
    }, [location, onGameOver, points, vatom])

    useEffect(() => {
      if (tokens) {
        tokens.forEach(token => {
          if (token.metadata.animation_url && !tokenPositions.current[token.id]) {
            tokenPositions.current[token.id] = [Math.random() * 10, 1, Math.random() * 10]
          }
        })
      }
    }, [tokens])

    if (!tokens) {
      return null
    }

    return (
      <>
        {tokens.map((token, index) => {
          const v = token as BVatomTokenType
          if (!v.metadata.animation_url) return null

          return (
            <Model
              key={v.id}
              position={tokenPositions.current[v.id]}
              onClick={() => {
                token.performAction('Pickup')

                const newUserPoints = points + 1
                if (newUserPoints >= vatom.userPoints!.maxPoints) {
                  setPoints(newUserPoints)

                  onGameOver()
                } else {
                  setPoints(newUserPoints)
                }
              }}
              url={v.metadata.animation_url}
            />
          )
        })}
      </>
    )
  }
)
// Stuff for testing/debugging

// export const useCameraPositionStore = create<{
//   x: number
//   y: number
//   z: number
//   setCameraPosition({ x, y, z }: { x: number; y: number; z: number }): void
// }>(set => ({
//   x: 0,
//   y: 0,
//   z: 0,
//   setCameraPosition: ({ x, y, z }) => set({ x, y, z })
// }))

// const Playground = () => {
//   useFrame(({ camera }) => {
//     useCameraPositionStore.getState().setCameraPosition({
//       x: camera.position.x,
//       y: camera.position.y,
//       z: camera.position.z
//     })
//   })

//   return (
//     <>
//       <Model
//         position={[0, 0, -4]}
//         url="https://resources.vatom.com/6si0buNebN/ac1a8610-c00d-11ec-99f5-113b695a2251.glb?1650392547429"
//       />
//       <CameraPositionDisplay />
//     </>
//   )
// }

// const CameraPositionDisplay = () => {
//   const cameraPosition = useCameraPositionStore()
//   const { markers } = useCompassHUDMarkerStore()
//   if (!cameraPosition) return null
//   return (
//     <div>
//       <div style={{ position: 'absolute', top: 0, left: 0 }}>
//         <h2>Camera Position:</h2>
//         <p>x: {cameraPosition.x.toFixed(2)}</p>
//         <p>y: {cameraPosition.y.toFixed(2)}</p>
//         <p>z: {cameraPosition.z.toFixed(2)}</p>
//         {/* render markers */}
//         <h2>Markers:</h2>
//         {Object.entries(markers).map(([id, marker]) => (
//           <p key={id}>
//             <h2>id: {marker.id}</h2>
//             {'offset'}: {marker.offset.toFixed(2)},{'angle'}: {marker.angle}
//           </p>
//         ))}
//       </div>
//     </div>
//   )
// }

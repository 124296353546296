import React, { useState } from 'react'
import { Linking } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { getConfig } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { GradientButton, Loader, PressableOpacity, Text, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Checkbox, FormControl, Icon, Input, Pressable, WarningOutlineIcon } from 'native-base'

import BackIcon from '../../assets/back.svg'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { useEncryptAndStore } from '../../hooks/useEncryptAndStore'
import { AppRoutes } from '../../navigators'
import { useWalletDetails } from '../NewWallet/store'

export const BackupToCloud = observer(() => {
  const [isAgreed, setIsAgreed] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const { isDark } = useBusinessTheme()
  const navigation = useNavigation()
  const seeds = useWalletDetails.getState().seeds

  const validatePassword = (password: string) => {
    return /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/.test(password)
  }

  const canContinue = isAgreed && validatePassword(password) && password === confirmPassword
  const encryptAndStoreMutation = useEncryptAndStore()

  const togglePasswordVisibility = () => setShowPassword(!showPassword)
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword)

  return (
    <Box flex={1} safeArea={true}>
      <Box mb="20px">
        <PressableOpacity
          hitSlop={{ top: 20, left: 20, bottom: 20, right: 20 }}
          accessibilityRole="button"
          onPress={() => navigation.goBack()}
        >
          <BackIcon
            height={40}
            width={40}
            fill={!isDark ? theme.colors.darkText : theme.colors.white}
          />
        </PressableOpacity>
      </Box>
      <Box height="100%" w="100%" flex={1} px="16px">
        <Box flex={1} justifyContent="flex-start" alignItems="center" height="100%">
          <Text
            marginBottom="20px"
            color={!isDark ? theme.colors.darkText : theme.colors.white}
            fontFamily="Inter-Bold"
            fontSize="34px"
            textAlign="left"
            alignSelf="flex-start"
            lineHeight="41px"
            tx="backupToCloudScreen.title"
          />

          <Text
            w="100%"
            fontFamily="Inter"
            fontSize="15px"
            textAlign="left"
            mb="20px"
            color={!isDark ? theme.colors.darkText : theme.colors.white}
            tx="backupToCloudScreen.description"
          />

          <FormControl
            isInvalid={!validatePassword(password) && password !== ''}
            w="100%"
            maxW="100%"
            py={2}
          >
            <Input
              size="lg"
              value={password}
              onChangeText={setPassword}
              placeholder={translate('backupToCloudScreen.passwordPlaceholder')}
              type={showPassword ? 'text' : 'password'}
              InputRightElement={
                <Pressable accessibilityRole="button" onPress={togglePasswordVisibility}>
                  <Icon
                    as={<MaterialIcons name={showPassword ? 'visibility' : 'visibility-off'} />}
                    size={5}
                    mr="2"
                    color="muted.400"
                  />
                </Pressable>
              }
            />

            <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
              {translate('backupToCloudScreen.passwordValidation')}
            </FormControl.ErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={password !== confirmPassword && confirmPassword !== ''}
            w="100%"
            maxW="100%"
            py={2}
          >
            <Input
              size="lg"
              value={confirmPassword}
              onChangeText={setConfirmPassword}
              placeholder={translate('backupToCloudScreen.confirmPasswordPlaceholder')}
              type={showConfirmPassword ? 'text' : 'password'}
              InputRightElement={
                <Pressable accessibilityRole="button" onPress={toggleConfirmPasswordVisibility}>
                  <Icon
                    as={
                      <MaterialIcons name={showConfirmPassword ? 'visibility' : 'visibility-off'} />
                    }
                    size={5}
                    mr="2"
                    color="muted.400"
                  />
                </Pressable>
              }
            />

            <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
              {translate('backupToCloudScreen.confirmPasswordValidation')}
            </FormControl.ErrorMessage>
          </FormControl>
        </Box>

        <Box justifyContent="center" width="100%" pb={20}>
          <Checkbox
            value="agree"
            isChecked={isAgreed}
            onChange={() => setIsAgreed(!isAgreed)}
            mb={10}
          >
            <Text textAlign="left" fontSize="sm" color="gray.500" fontFamily="body">
              {translate('backupToCloudScreen.agree')}
              <Text
                color="lightBlue.600"
                underline={true}
                onPress={() => Linking.openURL(getConfig().termsOfUseURL)}
                tx="common.termsOfService"
              />
              {` ${translate('common.and')} `}
              <Text
                color="lightBlue.600"
                underline={true}
                onPress={() => Linking.openURL(getConfig().privacyPolicyURL)}
                tx="loginScreen.privacyPolicy"
              />
              {translate('backupToCloudScreen.agree2')}
            </Text>
          </Checkbox>

          <GradientButton
            disabled={!canContinue || encryptAndStoreMutation.isLoading}
            onPress={async () => {
              await encryptAndStoreMutation.mutateAsync({ seeds, cloudPassword: password })
              useWalletDetails.getState().setWalletDetails('', '')
              navigation.navigate(AppRoutes.BlockChainWallets)
            }}
            h="52px"
            w="100%"
            py="15px"
            px="30px"
            alignItems="center"
          >
            {encryptAndStoreMutation.isLoading ? (
              <Loader size={30} color="white" />
            ) : (
              <Text
                alignSelf="center"
                fontFamily="Inter"
                color="white"
                fontSize={16}
                lineHeight={22}
                tx="backupToCloudScreen.compelete"
              />
            )}
          </GradientButton>
        </Box>
      </Box>
    </Box>
  )
})

export default BackupToCloud

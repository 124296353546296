import { useFrame } from '@react-three/fiber'
import { Object3D } from 'three'

export const useAutoRotate = (ref: Object3D<THREE.Event>, enabled: boolean) => {
  useFrame((scene, delta) => {
    if (!ref || !enabled) return
    // rotate object around axis each frame
    ref.rotation.y += delta
  })
}

import React from 'react'
import { StyleProp, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import LeftArrowIcon from '../../../../assets/arrow-icons/left-arrow-icon.svg'
import CloseIcon from '../../../../assets/header-icons/close-icon.svg'
import { useCommunitiesTheme } from '../../../../themes'
import { CommunitiesNavigation } from '../../../../types'

const CloseButton = ({ iconType, style }: { iconType: string; style?: StyleProp<ViewStyle> }) => {
  const navigation = useNavigation<CommunitiesNavigation>()
  const communitiesTheme = useCommunitiesTheme()

  return (
    <TouchableOpacity
      accessibilityRole="button"
      onPress={() => navigation.goBack()}
      style={[styles.closeButton, style]}
    >
      {iconType === 'back' ? (
        <LeftArrowIcon fill={communitiesTheme.xButtonColor} />
      ) : (
        <CloseIcon fill={communitiesTheme.xButtonColor} width={20} height={20} />
      )}
    </TouchableOpacity>
  )
}

export default CloseButton

const styles = StyleSheet.create({
  closeButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32
  }
})

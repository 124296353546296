/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { PurchaseReceiptModel, PurchaseReceiptModelType } from "./PurchaseReceiptModel"
import { PurchaseReceiptModelSelector } from "./PurchaseReceiptModel.base"
import { TwitterProfileModel, TwitterProfileModelType } from "./TwitterProfileModel"
import { TwitterProfileModelSelector } from "./TwitterProfileModel.base"
import { RootStoreType } from "./index"


/**
 * PurchaseEventBase
 * auto generated base class for the model PurchaseEventModel.
 */
export const PurchaseEventModelBase = ModelBase
  .named('PurchaseEvent')
  .props({
    __typename: types.optional(types.literal("PurchaseEvent"), "PurchaseEvent"),
    createdAt: types.union(types.undefined, types.frozen()),
    walletAddress: types.union(types.undefined, types.string),
    profile: types.union(types.undefined, types.null, types.late((): any => TwitterProfileModel)),
    feedEventId: types.union(types.undefined, types.string),
    purchaseReceiptAddress: types.union(types.undefined, types.frozen()),
    purchase: types.union(types.undefined, types.null, types.late((): any => PurchaseReceiptModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PurchaseEventModelSelector extends QueryBuilder {
  get createdAt() { return this.__attr(`createdAt`) }
  get walletAddress() { return this.__attr(`walletAddress`) }
  get feedEventId() { return this.__attr(`feedEventId`) }
  get purchaseReceiptAddress() { return this.__attr(`purchaseReceiptAddress`) }
  profile(builder: string | TwitterProfileModelSelector | ((selector: TwitterProfileModelSelector) => TwitterProfileModelSelector) | undefined) { return this.__child(`profile`, TwitterProfileModelSelector, builder) }
  purchase(builder: string | PurchaseReceiptModelSelector | ((selector: PurchaseReceiptModelSelector) => PurchaseReceiptModelSelector) | undefined) { return this.__child(`purchase`, PurchaseReceiptModelSelector, builder) }
}
export function selectFromPurchaseEvent() {
  return new PurchaseEventModelSelector()
}

export const purchaseEventModelPrimitives = selectFromPurchaseEvent().createdAt.walletAddress.feedEventId.purchaseReceiptAddress

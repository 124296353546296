import { Channels, useNotificationsStore } from '@vatom/sdk/react'
import { theme } from '@vatom/wombo'

import WalletIcon from '../assets/icon_tabbar_wallet.svg'

import { ActiveIcon } from './ActiveIcon'

export const WalletTabIcon = ({ focused, color }: { focused: boolean; color: string }) => {
  const shouldShowBadge = useNotificationsStore(state => !!state.channels[Channels.wallet]?.length)

  return (
    <ActiveIcon isActive={shouldShowBadge && !focused}>
      <WalletIcon height={26} width={26} fill={color} />
    </ActiveIcon>
  )
}

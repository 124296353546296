import { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import { useControls } from 'leva'
import { create } from 'zustand'

type PositionStore = {
  position: GeolocationPosition | null
  error: GeolocationPositionError | null
  heading: number | null
}

export const useLocationStore = create<PositionStore>(() => ({
  position: null,
  error: null,
  heading: null
}))

// Only mount one of this at a time
export const useStartWatchingPosition = () => {
  const options = useControls('Geolocation Options', {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  })

  useEffect(() => {
    const watch = navigator.geolocation.watchPosition(
      position => {
        useLocationStore.setState({
          position: position
        })
      },
      error => {
        useLocationStore.setState({
          error: error
        })
      },
      options
    )
    return () => navigator.geolocation.clearWatch(watch)
  }, [options])
}

const isSafari =
  Platform.OS === 'web' && /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent)

const eventName = isSafari ? 'deviceorientation' : 'deviceorientationabsolute'

export const useStartWatchingHeading = () => {
  useEffect(() => {
    const handler = (e: Event) => {
      // @ts-expect-error
      if (e.webkitCompassHeading) {
        // @ts-expect-error
        const heading = e.webkitCompassHeading as number
        useLocationStore.setState({
          heading: heading
        })
      } else {
        useLocationStore.setState({
          // @ts-expect-error
          heading: 180 - (e?.alpha ?? 0)
        })
      }
    }

    window.addEventListener(eventName, handler, !isSafari)
    return () => {
      window.removeEventListener(eventName, handler, !isSafari)
    }
  }, [])
}

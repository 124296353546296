import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { translate } from '@vatom/utils'
import { GradientButton, PressableOpacity, Text, theme } from '@vatom/wombo'
import { Box, VStack } from 'native-base'

import CloseIcon from '../../../assets/close.svg'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { AppRoutes } from '../../../navigators'
import ImportWalletImg from '../assets/import-wallet.svg'

export const Start = () => {
  const { isDark } = useBusinessTheme()
  const navigation = useNavigation()

  return (
    <Box flex={1} safeArea={true}>
      <VStack height="100%" w="100%" flex={1} px="16px">
        <Box alignItems="flex-start">
          <PressableOpacity
            hitSlop={{
              top: 20,
              left: 20,
              bottom: 20,
              right: 20
            }}
            accessibilityRole="button"
            onPress={() => navigation.goBack()}
          >
            <CloseIcon
              height={40}
              width={40}
              fill={!isDark ? theme.colors.darkText : theme.colors.white}
            />
          </PressableOpacity>
        </Box>
        <Box flex={1} justifyContent="center" alignItems="center" height="100%">
          <Text
            marginBottom="20px"
            color={!isDark ? theme.colors.darkText : theme.colors.white}
            fontFamily="Inter-Bold"
            fontSize="34px"
            textAlign="center"
            lineHeight="41px"
            tx="importWalletScreen.startTitle"
          />

          <ImportWalletImg height={250} width={260} />

          <Text
            w="100%"
            fontFamily="Inter-Regular"
            fontSize="15px"
            textAlign="center"
            mt={10}
            color={!isDark ? theme.colors.darkText : theme.colors.white}
            tx={'importWalletScreen.startDescription'}
          />
        </Box>
        <Box justifyContent="center" width="100%" pb={20}>
          <GradientButton
            onPress={() => navigation.navigate(AppRoutes.CloudBackupList)}
            h="52px"
            w="100%"
            py="15px"
            px="30px"
            mb={'10px'}
          >
            <Text alignSelf="center" fontFamily="Inter" color="white" fontSize={16} lineHeight={22}>
              {translate('importWalletScreen.useIcloud')}
            </Text>
          </GradientButton>

          <GradientButton
            onPress={() => navigation.navigate(AppRoutes.ImportWalletRecover)}
            h="52px"
            w="100%"
            py="15px"
            px="30px"
            _dark={{
              backgroundColor: theme.colors.grayCool[200]
            }}
            _light={{
              backgroundColor: theme.colors.grayCool[200]
            }}
          >
            <Text alignSelf="center" fontFamily="Inter" color="black" fontSize={16} lineHeight={22}>
              {translate('importWalletScreen.enterPhrase')}
            </Text>
          </GradientButton>
        </Box>
      </VStack>
    </Box>
  )
}

export default Start

/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { ObservableMap } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLStore, configureStoreMixin, QueryOptions, withTypedRefs } from "mst-gql"

import { EnrichedBondingChangeModel, EnrichedBondingChangeModelType } from "./EnrichedBondingChangeModel"
import { enrichedBondingChangeModelPrimitives, EnrichedBondingChangeModelSelector } from "./EnrichedBondingChangeModel.base"
import { CreatorCountsModel, CreatorCountsModelType } from "./CreatorCountsModel"
import { creatorCountsModelPrimitives, CreatorCountsModelSelector } from "./CreatorCountsModel.base"
import { TwitterProfileModel, TwitterProfileModelType } from "./TwitterProfileModel"
import { twitterProfileModelPrimitives, TwitterProfileModelSelector } from "./TwitterProfileModel.base"
import { AttributeGroupModel, AttributeGroupModelType } from "./AttributeGroupModel"
import { attributeGroupModelPrimitives, AttributeGroupModelSelector } from "./AttributeGroupModel.base"
import { StorefrontModel, StorefrontModelType } from "./StorefrontModel"
import { storefrontModelPrimitives, StorefrontModelSelector } from "./StorefrontModel.base"
import { NftAttributeModel, NftAttributeModelType } from "./NftAttributeModel"
import { nftAttributeModelPrimitives, NftAttributeModelSelector } from "./NftAttributeModel.base"
import { NftCountModel, NftCountModelType } from "./NftCountModel"
import { nftCountModelPrimitives, NftCountModelSelector } from "./NftCountModel.base"
import { CreatorModel, CreatorModelType } from "./CreatorModel"
import { creatorModelPrimitives, CreatorModelSelector } from "./CreatorModel.base"
import { MetadataJsonModel, MetadataJsonModelType } from "./MetadataJsonModel"
import { metadataJsonModelPrimitives, MetadataJsonModelSelector } from "./MetadataJsonModel.base"
import { GraphConnectionModel, GraphConnectionModelType } from "./GraphConnectionModel"
import { graphConnectionModelPrimitives, GraphConnectionModelSelector } from "./GraphConnectionModel.base"
import { NftModel, NftModelType } from "./NftModel"
import { nftModelPrimitives, NftModelSelector } from "./NftModel.base"
import { ListingEventModel, ListingEventModelType } from "./ListingEventModel"
import { listingEventModelPrimitives, ListingEventModelSelector } from "./ListingEventModel.base"
import { FollowEventModel, FollowEventModelType } from "./FollowEventModel"
import { followEventModelPrimitives, FollowEventModelSelector } from "./FollowEventModel.base"
import { MintStatsModel, MintStatsModelType } from "./MintStatsModel"
import { mintStatsModelPrimitives, MintStatsModelSelector } from "./MintStatsModel.base"
import { ListingReceiptModel, ListingReceiptModelType } from "./ListingReceiptModel"
import { listingReceiptModelPrimitives, ListingReceiptModelSelector } from "./ListingReceiptModel.base"
import { MarketStatsModel, MarketStatsModelType } from "./MarketStatsModel"
import { marketStatsModelPrimitives, MarketStatsModelSelector } from "./MarketStatsModel.base"
import { PurchaseReceiptModel, PurchaseReceiptModelType } from "./PurchaseReceiptModel"
import { purchaseReceiptModelPrimitives, PurchaseReceiptModelSelector } from "./PurchaseReceiptModel.base"
import { PurchaseEventModel, PurchaseEventModelType } from "./PurchaseEventModel"
import { purchaseEventModelPrimitives, PurchaseEventModelSelector } from "./PurchaseEventModel.base"
import { WalletNftCountModel, WalletNftCountModelType } from "./WalletNftCountModel"
import { walletNftCountModelPrimitives, WalletNftCountModelSelector } from "./WalletNftCountModel.base"
import { AuctionHouseModel, AuctionHouseModelType } from "./AuctionHouseModel"
import { auctionHouseModelPrimitives, AuctionHouseModelSelector } from "./AuctionHouseModel.base"
import { NftOwnerModel, NftOwnerModelType } from "./NftOwnerModel"
import { nftOwnerModelPrimitives, NftOwnerModelSelector } from "./NftOwnerModel.base"
import { BidReceiptModel, BidReceiptModelType } from "./BidReceiptModel"
import { bidReceiptModelPrimitives, BidReceiptModelSelector } from "./BidReceiptModel.base"
import { NftCreatorModel, NftCreatorModelType } from "./NftCreatorModel"
import { nftCreatorModelPrimitives, NftCreatorModelSelector } from "./NftCreatorModel.base"
import { PriceChartModel, PriceChartModelType } from "./PriceChartModel"
import { priceChartModelPrimitives, PriceChartModelSelector } from "./PriceChartModel.base"
import { MarketplaceModel, MarketplaceModelType } from "./MarketplaceModel"
import { marketplaceModelPrimitives, MarketplaceModelSelector } from "./MarketplaceModel.base"
import { NftActivityModel, NftActivityModelType } from "./NftActivityModel"
import { nftActivityModelPrimitives, NftActivityModelSelector } from "./NftActivityModel.base"
import { DenylistModel, DenylistModelType } from "./DenylistModel"
import { denylistModelPrimitives, DenylistModelSelector } from "./DenylistModel.base"
import { QueryRootModel, QueryRootModelType } from "./QueryRootModel"
import { queryRootModelPrimitives, QueryRootModelSelector } from "./QueryRootModel.base"
import { ListingModel, ListingModelType } from "./ListingModel"
import { listingModelPrimitives, ListingModelSelector } from "./ListingModel.base"
import { ConnectionCountsModel, ConnectionCountsModelType } from "./ConnectionCountsModel"
import { connectionCountsModelPrimitives, ConnectionCountsModelSelector } from "./ConnectionCountsModel.base"
import { MintEventModel, MintEventModelType } from "./MintEventModel"
import { mintEventModelPrimitives, MintEventModelSelector } from "./MintEventModel.base"
import { ProfileModel, ProfileModelType } from "./ProfileModel"
import { profileModelPrimitives, ProfileModelSelector } from "./ProfileModel.base"
import { WalletModel, WalletModelType } from "./WalletModel"
import { walletModelPrimitives, WalletModelSelector } from "./WalletModel.base"
import { BidModel, BidModelType } from "./BidModel"
import { bidModelPrimitives, BidModelSelector } from "./BidModel.base"
import { AttributeVariantModel, AttributeVariantModelType } from "./AttributeVariantModel"
import { attributeVariantModelPrimitives, AttributeVariantModelSelector } from "./AttributeVariantModel.base"
import { NftFileModel, NftFileModelType } from "./NftFileModel"
import { nftFileModelPrimitives, NftFileModelSelector } from "./NftFileModel.base"
import { OfferEventModel, OfferEventModelType } from "./OfferEventModel"
import { offerEventModelPrimitives, OfferEventModelSelector } from "./OfferEventModel.base"
import { PricePointModel, PricePointModelType } from "./PricePointModel"
import { pricePointModelPrimitives, PricePointModelSelector } from "./PricePointModel.base"
import { StoreCreatorModel, StoreCreatorModelType } from "./StoreCreatorModel"
import { storeCreatorModelPrimitives, StoreCreatorModelSelector } from "./StoreCreatorModel.base"

import { feedEventModelPrimitives, FeedEventModelSelector , FeedEventUnion } from "./"


export type AttributeFilter = {
  traitType: string
  values: string[]
}
/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {

}


/**
* Enums for the names of base graphql actions
*/



/**
* Store, managing, among others, all the objects received through graphQL
*/
export const RootStoreBase = withTypedRefs<Refs>()(MSTGQLStore
  .named("RootStore")
  .extend(configureStoreMixin([['EnrichedBondingChange', () => EnrichedBondingChangeModel], ['CreatorCounts', () => CreatorCountsModel], ['TwitterProfile', () => TwitterProfileModel], ['AttributeGroup', () => AttributeGroupModel], ['Storefront', () => StorefrontModel], ['NftAttribute', () => NftAttributeModel], ['NftCount', () => NftCountModel], ['Creator', () => CreatorModel], ['MetadataJson', () => MetadataJsonModel], ['GraphConnection', () => GraphConnectionModel], ['Nft', () => NftModel], ['ListingEvent', () => ListingEventModel], ['FollowEvent', () => FollowEventModel], ['MintStats', () => MintStatsModel], ['ListingReceipt', () => ListingReceiptModel], ['MarketStats', () => MarketStatsModel], ['PurchaseReceipt', () => PurchaseReceiptModel], ['PurchaseEvent', () => PurchaseEventModel], ['WalletNftCount', () => WalletNftCountModel], ['AuctionHouse', () => AuctionHouseModel], ['NftOwner', () => NftOwnerModel], ['BidReceipt', () => BidReceiptModel], ['NftCreator', () => NftCreatorModel], ['PriceChart', () => PriceChartModel], ['Marketplace', () => MarketplaceModel], ['NftActivity', () => NftActivityModel], ['Denylist', () => DenylistModel], ['QueryRoot', () => QueryRootModel], ['Listing', () => ListingModel], ['ConnectionCounts', () => ConnectionCountsModel], ['MintEvent', () => MintEventModel], ['Profile', () => ProfileModel], ['Wallet', () => WalletModel], ['Bid', () => BidModel], ['AttributeVariant', () => AttributeVariantModel], ['NftFile', () => NftFileModel], ['OfferEvent', () => OfferEventModel], ['PricePoint', () => PricePointModel], ['StoreCreator', () => StoreCreatorModel]], [], "js"))
  .props({

  })
  .actions(self => ({
    // Recommend wallets to follow.
    queryFollowWallets(variables: { wallet?: any, limit: number, offset: number }, resultSelector: string | ((qb: WalletModelSelector) => WalletModelSelector) = walletModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ followWallets: WalletModelType[]}>(`query followWallets($wallet: PublicKey, $limit: Int!, $offset: Int!) { followWallets(wallet: $wallet, limit: $limit, offset: $offset) {
        ${typeof resultSelector === "function" ? resultSelector(new WalletModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Returns events for the wallets the user is following using the graph_program.
    queryFeedEvents(variables: { wallet: any, limit: number, offset: number }, resultSelector: string | ((qb: FeedEventModelSelector) => FeedEventModelSelector) = feedEventModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ feedEvents: FeedEventUnion[]}>(`query feedEvents($wallet: PublicKey!, $limit: Int!, $offset: Int!) { feedEvents(wallet: $wallet, limit: $limit, offset: $offset) {
        ${typeof resultSelector === "function" ? resultSelector(new FeedEventModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryNftCounts(variables: { creators: any[] }, resultSelector: string | ((qb: NftCountModelSelector) => NftCountModelSelector) = nftCountModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ nftCounts: NftCountModelType}>(`query nftCounts($creators: [PublicKey!]!) { nftCounts(creators: $creators) {
        ${typeof resultSelector === "function" ? resultSelector(new NftCountModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryCharts(variables: { auctionHouses: any[], startDate: any, endDate: any }, resultSelector: string | ((qb: PriceChartModelSelector) => PriceChartModelSelector) = priceChartModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ charts: PriceChartModelType}>(`query charts($auctionHouses: [PublicKey!]!, $startDate: DateTimeUtc!, $endDate: DateTimeUtc!) { charts(auctionHouses: $auctionHouses, startDate: $startDate, endDate: $endDate) {
        ${typeof resultSelector === "function" ? resultSelector(new PriceChartModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryActivities(variables: { auctionHouses: any[] }, resultSelector: string | ((qb: NftActivityModelSelector) => NftActivityModelSelector) = nftActivityModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ activities: NftActivityModelType[]}>(`query activities($auctionHouses: [PublicKey!]!) { activities(auctionHouses: $auctionHouses) {
        ${typeof resultSelector === "function" ? resultSelector(new NftActivityModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryProfile(variables: { handle: string }, resultSelector: string | ((qb: ProfileModelSelector) => ProfileModelSelector) = profileModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ profile: ProfileModelType}>(`query profile($handle: String!) { profile(handle: $handle) {
        ${typeof resultSelector === "function" ? resultSelector(new ProfileModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryEnrichedBondingChanges(variables: { address: any, startUnixTime: any, stopUnixTime: any, limit: number, offset: number }, resultSelector: string | ((qb: EnrichedBondingChangeModelSelector) => EnrichedBondingChangeModelSelector) = enrichedBondingChangeModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ enrichedBondingChanges: EnrichedBondingChangeModelType[]}>(`query enrichedBondingChanges($address: PublicKey!, $startUnixTime: NaiveDateTime!, $stopUnixTime: NaiveDateTime!, $limit: Int!, $offset: Int!) { enrichedBondingChanges(address: $address, startUnixTime: $startUnixTime, stopUnixTime: $stopUnixTime, limit: $limit, offset: $offset) {
        ${typeof resultSelector === "function" ? resultSelector(new EnrichedBondingChangeModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryOffer(variables: { address: string }, resultSelector: string | ((qb: BidReceiptModelSelector) => BidReceiptModelSelector) = bidReceiptModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ offer: BidReceiptModelType}>(`query offer($address: String!) { offer(address: $address) {
        ${typeof resultSelector === "function" ? resultSelector(new BidReceiptModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryConnections(variables: { from?: any[], to?: any[], limit: number, offset: number }, resultSelector: string | ((qb: GraphConnectionModelSelector) => GraphConnectionModelSelector) = graphConnectionModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ connections: GraphConnectionModelType[]}>(`query connections($from: [PublicKey!], $to: [PublicKey!], $limit: Int!, $offset: Int!) { connections(from: $from, to: $to, limit: $limit, offset: $offset) {
        ${typeof resultSelector === "function" ? resultSelector(new GraphConnectionModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryCreator(variables: { address: string }, resultSelector: string | ((qb: CreatorModelSelector) => CreatorModelSelector) = creatorModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ creator: CreatorModelType}>(`query creator($address: String!) { creator(address: $address) {
        ${typeof resultSelector === "function" ? resultSelector(new CreatorModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryNfts(variables: { owners?: any[], creators?: any[], offerers?: any[], attributes?: AttributeFilter[], listed?: boolean, auctionHouses?: any[], collection?: any, limit: number, offset: number }, resultSelector: string | ((qb: NftModelSelector) => NftModelSelector) = nftModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ nfts: NftModelType[]}>(`query nfts($owners: [PublicKey!], $creators: [PublicKey!], $offerers: [PublicKey!], $attributes: [AttributeFilter!], $listed: Boolean, $auctionHouses: [PublicKey!], $collection: PublicKey, $limit: Int!, $offset: Int!) { nfts(owners: $owners, creators: $creators, offerers: $offerers, attributes: $attributes, listed: $listed, auctionHouses: $auctionHouses, collection: $collection, limit: $limit, offset: $offset) {
        ${typeof resultSelector === "function" ? resultSelector(new NftModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryWallet(variables: { address: any }, resultSelector: string | ((qb: WalletModelSelector) => WalletModelSelector) = walletModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ wallet: WalletModelType}>(`query wallet($address: PublicKey!) { wallet(address: $address) {
        ${typeof resultSelector === "function" ? resultSelector(new WalletModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryListings(variables?: {  }, resultSelector: string | ((qb: ListingModelSelector) => ListingModelSelector) = listingModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ listings: ListingModelType[]}>(`query listings { listings {
        ${typeof resultSelector === "function" ? resultSelector(new ListingModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryNft(variables: { address: string }, resultSelector: string | ((qb: NftModelSelector) => NftModelSelector) = nftModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ nft: NftModelType}>(`query nft($address: String!) { nft(address: $address) {
        ${typeof resultSelector === "function" ? resultSelector(new NftModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryStorefronts(variables?: {  }, resultSelector: string | ((qb: StorefrontModelSelector) => StorefrontModelSelector) = storefrontModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ storefronts: StorefrontModelType[]}>(`query storefronts { storefronts {
        ${typeof resultSelector === "function" ? resultSelector(new StorefrontModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // A storefront
    queryStorefront(variables: { subdomain: string }, resultSelector: string | ((qb: StorefrontModelSelector) => StorefrontModelSelector) = storefrontModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ storefront: StorefrontModelType}>(`query storefront($subdomain: String!) { storefront(subdomain: $subdomain) {
        ${typeof resultSelector === "function" ? resultSelector(new StorefrontModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // A marketplace
    queryMarketplace(variables: { subdomain: string }, resultSelector: string | ((qb: MarketplaceModelSelector) => MarketplaceModelSelector) = marketplaceModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ marketplace: MarketplaceModelType}>(`query marketplace($subdomain: String!) { marketplace(subdomain: $subdomain) {
        ${typeof resultSelector === "function" ? resultSelector(new MarketplaceModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // returns metadata_jsons matching the term
    queryMetadataJsons(variables: { term: string, limit: number, offset: number }, resultSelector: string | ((qb: MetadataJsonModelSelector) => MetadataJsonModelSelector) = metadataJsonModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ metadataJsons: MetadataJsonModelType[]}>(`query metadataJsons($term: String!, $limit: Int!, $offset: Int!) { metadataJsons(term: $term, limit: $limit, offset: $offset) {
        ${typeof resultSelector === "function" ? resultSelector(new MetadataJsonModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // returns profiles matching the search term
    queryProfiles(variables: { term: string, limit: number, offset: number }, resultSelector: string | ((qb: WalletModelSelector) => WalletModelSelector) = walletModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ profiles: WalletModelType[]}>(`query profiles($term: String!, $limit: Int!, $offset: Int!) { profiles(term: $term, limit: $limit, offset: $offset) {
        ${typeof resultSelector === "function" ? resultSelector(new WalletModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryDenylist(variables?: {  }, resultSelector: string | ((qb: DenylistModelSelector) => DenylistModelSelector) = denylistModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ denylist: DenylistModelType}>(`query denylist { denylist {
        ${typeof resultSelector === "function" ? resultSelector(new DenylistModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
  })))

import React from 'react'
import { Animated, Image, StyleSheet, Text, View } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

import { usePlugins } from '../../Communities/queries'
import { useCommunitiesTheme } from '../../Communities/themes'

type RoomFiltersProps = {
  isMemberPage?: boolean
  setFilter: any
  filter: string
}

const RoomFilters = (props: RoomFiltersProps) => {
  const { isMemberPage, setFilter, filter: activeFilter } = props

  const { data, isSuccess } = usePlugins()

  const communitiesTheme = useCommunitiesTheme()

  useFocusEffect(
    React.useCallback(() => {
      setFilter(filters?.[0]?.message_type)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  )

  if (!isSuccess) {
    return null
  }

  const firstPlugin = data[0]

  const roomFilters = firstPlugin.descriptor.room_filters
  if (!roomFilters.some((filter: { message_type: string }) => filter.message_type === 'all')) {
    roomFilters.unshift({
      name: 'All',
      message_type: 'all'
    })
  }

  const memberPageFilters = firstPlugin.descriptor.member_filters

  const filters = isMemberPage ? memberPageFilters : roomFilters

  return (
    <Animated.View
      style={[
        { ...styles(communitiesTheme).filterHolder, paddingTop: 20 },
        { borderBottomColor: communitiesTheme.roomFilters.border },
        { backgroundColor: communitiesTheme.background }
      ]}
    >
      {/* <BlurView
        style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
        blurType="light"
        blurAmount={10}
        reducedTransparencyFallbackColor="white"
      /> */}

      {filters.length > 0
        ? filters.map((filter: any) => {
            const { name, message_type } = filter
            const isActive = activeFilter === message_type

            return (
              <View
                key={message_type}
                style={{
                  ...(isActive ? styles(communitiesTheme).activeFilterWrapper : {}),
                  flex: 1
                }}
              >
                <Text
                  style={
                    isActive
                      ? {
                          ...styles(communitiesTheme).filter,
                          ...styles(communitiesTheme).activeFilter
                        }
                      : styles(communitiesTheme).filter
                  }
                  onPress={() => (!isActive ? setFilter(message_type) : null)}
                >
                  {name}
                </Text>
              </View>
            )
          })
        : null}

      {filters.length > 5 ? (
        <Image
          accessibilityIgnoresInvertColors
          source={require('../assets/rightArrow.png')}
          style={{ width: 5, height: 10, marginBottom: 10 }}
        />
      ) : null}
    </Animated.View>
  )
}

const styles = (communitiesTheme?: any) =>
  StyleSheet.create({
    filterHolder: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      borderBottomWidth: 1
    },
    filter: {
      fontSize: 15,
      color: communitiesTheme.roomFilters.filter,
      lineHeight: 20,
      paddingBottom: 12,
      textAlign: 'center'
    },
    activeFilter: {
      fontWeight: 'bold',
      color: communitiesTheme.roomFilters.activeFilter,
      paddingBottom: 10
    },
    activeFilterWrapper: {
      borderBottomWidth: 2,
      borderBottomColor: communitiesTheme.roomFilters.activeBorder
    }
  })

export default RoomFilters

import React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useGetSpace, useMatrixUser } from '@vatom/sdk/react'

import { useBusinessSelector } from '../../../../../hooks/useBusinessSelector'
import { navigateToConnectScreen, RoomRoutes } from '../../../../../navigators'
import { getRepliesData } from '../../../helpers'
import { useMessageThreadReplies } from '../../../queries'
import { MatrixReplyType } from '../../../types'
import { useCommunitySpace } from '../../../useCommunitySpace'
import UserAvatar from '../../UserAvatar'
import { useMessageContext } from '../MessageContext'

type RepliesProps = {
  align: string
  spaceId: string
}

type Member = {
  id: string
  picture?: string
}

const Replies = (props: RepliesProps) => {
  const { business } = useBusinessSelector()
  const { community } = useCommunitySpace()
  const navigation = useNavigation()
  const businessId = business?.id ?? ''
  const { messageEventID } = useMessageContext()
  const { align, spaceId } = props
  const { data: matrixUser } = useMatrixUser()
  const space = useGetSpace(spaceId, {
    enabled: false
  })
  const roomId = space.data?.matrixRoomId ?? ''

  const { data: repliesData } = useMessageThreadReplies(roomId, messageEventID)
  const threadReplies = getRepliesData(repliesData)

  const getMembers = (repliesArray: MatrixReplyType[]) => {
    let members: Array<Member> = []
    members =
      repliesArray?.map(({ sender }) => ({
        id: sender
      })) ?? []
    const membersMapped = new Map(members.map(m => [m.id, m]))
    members = [...membersMapped.values()]

    return members.length > 7 ? members.slice(0, 6) : members
  }

  const members: Array<Member> = getMembers(threadReplies)

  const repliesCount = threadReplies.length

  if (!messageEventID) {
    return null
  }

  const onPressReply = () => {
    const routeName = members.length > 0 ? RoomRoutes.RoomThread : RoomRoutes.RoomReply

    navigateToConnectScreen(navigation, routeName, {
      spaceId,
      community,
      business: businessId,
      messageId: messageEventID
    })
  }

  return (
    <Pressable accessibilityRole="button" onPress={onPressReply}>
      <View style={styles(align).repliesHolder}>
        {members.length > 0 ? (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {members.map(({ id }) => (
              <UserAvatar
                key={id}
                memberId={id}
                width={24}
                height={24}
                style={styles().userAvatar}
              />
            ))}
            <Text style={styles().text}>
              {repliesCount} {repliesCount === 1 ? 'Reply' : 'Replies'}
            </Text>
          </View>
        ) : matrixUser ? (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <UserAvatar
              memberId={matrixUser?.user_id}
              width={24}
              height={24}
              style={styles().userAvatar}
            />
            <Text style={styles().text}>Be the first to reply</Text>
          </View>
        ) : null}
      </View>
    </Pressable>
  )
}

const styles = (align?: string) =>
  StyleSheet.create({
    repliesHolder: {
      marginTop: 16,
      marginLeft: align === 'indent' ? 55 : 0
    },
    memberPicture: {
      width: 24,
      height: 24,
      borderRadius: 24 / 2,
      borderWidth: 2,
      borderColor: 'white',
      marginRight: -5 // Adjust the spacing between the images if needed
    },
    text: {
      fontSize: 13,
      color: '#868E96',
      marginLeft: 8
    },
    userAvatar: {
      borderRadius: 24 / 2,
      borderWidth: 2,
      marginRight: -5
    }
  })

export default Replies

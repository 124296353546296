import { createContext, memo, useContext, useMemo, useState } from 'react'
import { useSafeAreaFrame, useSafeAreaInsets } from 'react-native-safe-area-context'
import { BottomTabScreenProps, createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import {
  createNavigationContainerRef,
  NavigationContainer,
  NavigationProp,
  useNavigation
} from '@react-navigation/native'
import { BusinessSnapshot } from '@vatom/sdk/core'
import { BusinessData, useCommunities, useConfig } from '@vatom/sdk/react'
import { theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box } from 'native-base'

import { useBusinessItems, useBusinessSelector } from '../../../hooks/useBusinessSelector'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../../hooks/useIsDesktop'

import { BusinessesList } from './BusinessList'
import { CommunitiesList } from './CommunitiesList'
import { ProfileDrawer } from './ProfileDrawer'

export const DRAWER_CONTENT_ROUTES = {
  BusinessList: 'BusinessList',
  CommunitiesList: 'CommunitiesList',
  ProfileDrawer: 'ProfileDrawer'
} as const

export type DrawerTabParams = {
  [DRAWER_CONTENT_ROUTES.BusinessList]: undefined
  [DRAWER_CONTENT_ROUTES.CommunitiesList]: undefined
  [DRAWER_CONTENT_ROUTES.ProfileDrawer]: undefined
}
export type DrawerTabScreenProp<TScreen extends keyof typeof DRAWER_CONTENT_ROUTES> =
  BottomTabScreenProps<DrawerTabParams, TScreen>

const Tab = createBottomTabNavigator<DrawerTabParams>()

type DrawerContentContextType = {
  parentBusiness?: BusinessData
  appNavigation: NavigationProp<ReactNavigation.RootParamList>
  selectBusiness: (id: string) => void
  businesses: BusinessSnapshot[]
  selectedBusinessId?: BusinessSnapshot['id']
  businessIdCommunity: string
  businessIdInNavigation?: string
}
const DrawerContentContext = createContext<DrawerContentContextType | undefined>(undefined)

export const useDrawerContentContext = () =>
  useContext(DrawerContentContext) as DrawerContentContextType

export const drawerNavigationRef = createNavigationContainerRef<DrawerTabParams>()

export const DrawerContentNavigator = observer(() => {
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()
  const { business } = useBusinessSelector()
  const { isDark } = useBusinessTheme()

  const config = useConfig()
  const businesses = useBusinessItems()
  const { data: communities } = useCommunities(business?.id ?? '')

  const isDesktop = useIsDesktop()
  const dimensions = useSafeAreaFrame()

  const defaultBusinessId =
    config.isBusinessLocked && !business?.id ? config.businessId : business?.id ? business?.id : ''

  const [businessIdCommunity, setBusinessIdCommunity] = useState(defaultBusinessId)
  const selectedBusinessId =
    config?.isBusinessLocked && config.subBusinesses.length === 0 ? config.businessId : business?.id

  const selectBusiness = (id: string) => setBusinessIdCommunity(id)

  const shouldOpenCommunitiesList = Boolean(
    businessIdCommunity && communities && communities?.length > 1
  )

  const maxHeight = dimensions.height > 700 ? 700 : dimensions.height

  const initialScreen = useMemo(() => {
    if (shouldOpenCommunitiesList && config.isBusinessLocked && config.subBusinesses.length === 0) {
      return DRAWER_CONTENT_ROUTES.CommunitiesList
    }
    if (config.isBusinessLocked && config.subBusinesses.length === 0) {
      return DRAWER_CONTENT_ROUTES.ProfileDrawer
    }

    return DRAWER_CONTENT_ROUTES.BusinessList
  }, [shouldOpenCommunitiesList, config])

  const businessesToShow = useMemo(() => {
    if (!config.isBusinessLocked) {
      return businesses as unknown as BusinessSnapshot[]
    }

    return businesses.filter(business =>
      config.subBusinesses.includes(business.id)
    ) as unknown as BusinessSnapshot[]
  }, [businesses, config.isBusinessLocked, config.subBusinesses])

  const parentBusiness = useMemo(() => {
    return businesses.find(b => b.id === config.businessId)
  }, [businesses, config.businessId])

  return (
    <DrawerContentContext.Provider
      value={{
        parentBusiness: parentBusiness,
        appNavigation: navigation,
        businessIdInNavigation: business?.id,
        businesses: businessesToShow,
        selectBusiness,
        selectedBusinessId,
        businessIdCommunity
      }}
    >
      <NavigationContainer
        ref={drawerNavigationRef}
        independent
        documentTitle={{
          enabled: false
        }}
      >
        <Tab.Navigator
          initialRouteName={initialScreen}
          screenOptions={{
            headerShown: false,
            lazy: true
          }}
          sceneContainerStyle={{
            backgroundColor: isDark ? theme.colors.grayCool[900] : theme.colors.white,
            minHeight: 300,
            maxHeight: isDesktop ? maxHeight : undefined,
            paddingTop: insets.top
          }}
          tabBar={() => null}
        >
          {config?.isBusinessLocked && config?.subBusinesses.length === 0 ? null : (
            <Tab.Screen name={DRAWER_CONTENT_ROUTES.BusinessList} component={BusinessesList} />
          )}
          <Tab.Screen name={DRAWER_CONTENT_ROUTES.CommunitiesList} component={CommunitiesList} />
          <Tab.Screen name={DRAWER_CONTENT_ROUTES.ProfileDrawer} component={ProfileDrawer} />
        </Tab.Navigator>
      </NavigationContainer>
    </DrawerContentContext.Provider>
  )
})

export const DrawerContentNavigatorMemo = memo(DrawerContentNavigator)

const DrawerContent = memo(
  observer(() => (
    <Box flex={1}>
      <DrawerContentNavigatorMemo />
    </Box>
  ))
)

export { DrawerContent }

/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * MintStatsBase
 * auto generated base class for the model MintStatsModel.
 */
export const MintStatsModelBase = ModelBase
  .named('MintStats')
  .props({
    __typename: types.optional(types.literal("MintStats"), "MintStats"),
    auctionHouse: types.union(types.undefined, types.string),
    mint: types.union(types.undefined, types.string),
    floor: types.union(types.undefined, types.null, types.frozen()),
    average: types.union(types.undefined, types.null, types.frozen()),
    volume24hr: types.union(types.undefined, types.null, types.frozen()),
    volumeTotal: types.union(types.undefined, types.null, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class MintStatsModelSelector extends QueryBuilder {
  get auctionHouse() { return this.__attr(`auctionHouse`) }
  get mint() { return this.__attr(`mint`) }
  get floor() { return this.__attr(`floor`) }
  get average() { return this.__attr(`average`) }
  get volume24hr() { return this.__attr(`volume24hr`) }
  get volumeTotal() { return this.__attr(`volumeTotal`) }
}
export function selectFromMintStats() {
  return new MintStatsModelSelector()
}

export const mintStatsModelPrimitives = selectFromMintStats().auctionHouse.mint.floor.average.volume24hr.volumeTotal

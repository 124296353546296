import React, { memo } from 'react'
import { Ionicons } from '@expo/vector-icons'
import { useBusiness } from '@vatom/sdk/react'
import { Text, theme } from '@vatom/wombo'
import { Box, Flex, Image, Pressable } from 'native-base'

import { color } from '../../../theme'
import useSpaceFromNavParams from '../../Space/useSpaceFromNavParams'

import { SpaceRoom } from './SectionSpaces'

type SpacePreviewProps = {
  spaceId: string
  businessId: string
  matrixRoomId: string
  displayName?: string
  cover?: string
  isPrivate?: boolean
  memberCount?: number
  noMiddle?: boolean
  onSelectSpaceRoom: (spaceRoom: SpaceRoom) => void
}

export const SpacePreview = ({
  spaceId,
  businessId,
  matrixRoomId,
  displayName,
  cover,
  isPrivate,
  memberCount,
  noMiddle = false,
  onSelectSpaceRoom
}: SpacePreviewProps) => {
  const business = useBusiness({ businessId: businessId })
  const { space } = useSpaceFromNavParams({ spaceId: spaceId })

  const coverUri = cover
    ? `${cover.replace(
        'mxc://',
        `https://matrix.vatom.com/_matrix/media/v3/thumbnail/`
      )}?width=250&height=250&method=scale`
    : ''

  const boxImageHeight = noMiddle ? '55%' : '60%'

  return (
    <Box
      h={215}
      w={150}
      mr={'10px'}
      borderRadius={8}
      _light={{
        borderColor: color.borderGrey
      }}
      _dark={{
        borderColor: theme.colors.grayDarkMode[700]
      }}
      // borderColor={color.borderGrey}
      borderWidth={1}
    >
      <Pressable
        accessibilityRole="button"
        onPress={e => onSelectSpaceRoom({ spaceId, matrixRoomId })}
        // onLongPress={e => onRemove()}
        h={'100%'}
        w={'100%'}
      >
        <Box w={'100%'} h={boxImageHeight}>
          <Image
            accessibilityIgnoresInvertColors={true}
            alt="Room Cover"
            alignItems="center"
            justifyContent="center"
            w={'100%'}
            h={'100%'}
            borderTopRadius={8}
            resizeMode="cover"
            source={coverUri ? { uri: coverUri } : require('../assets/defaultSpace.jpg')}
          />
          {space?.compatibility?.mobile ? (
            <Pressable
              position="absolute"
              accessibilityRole="button"
              onPress={e => null}
              alignItems="center"
              justifyContent="center"
              bgColor={color.textBlack}
              borderRadius={13}
              width="40px"
              height={26}
              right={2}
              top={2}
            >
              <Ionicons name="volume-high-outline" size={20} color={color.background} />
            </Pressable>
          ) : null}
          <Pressable
            position="absolute"
            accessibilityRole="button"
            onPress={e => null}
            justifyContent="center"
            bgColor={color.textBlack}
            borderRadius={13}
            height={26}
            minWidth={'40px'}
            right={2}
            bottom={2}
            paddingLeft={2}
          >
            <Flex flexDirection={'row'} justifyContent="space-between">
              <Ionicons name="person" size={12} color={color.background} />
              <Text ml={1} mr={2} fontSize={10} color={color.background}>
                {space?.attendeeCount || 0}
              </Text>
            </Flex>
          </Pressable>
        </Box>
        <Box
          _dark={{
            backgroundColor: theme.colors.grayDarkMode[900]
          }}
          flex={1}
          borderBottomRadius={8}
        >
          {!noMiddle ? (
            <Box justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
              <Box shadow={3} h={'36px'} w={'36px'} borderRadius={'62px'} marginTop={'-18px'}>
                <Image
                  accessibilityIgnoresInvertColors={true}
                  alt="Business Header"
                  h={'36px'}
                  w={'36px'}
                  borderRadius={'62px'}
                  borderColor={'white'}
                  borderWidth={'2px'}
                  source={
                    business.data?.logoSrc ?? coverUri
                      ? { uri: business.data?.logoSrc ?? coverUri }
                      : require('../assets/defaultSpace.jpg')
                  }
                />
              </Box>
            </Box>
          ) : null}

          <Box
            textAlign={'center'}
            justifyContent={'center'}
            alignItems={'center'}
            alignContent={'center'}
            w={'100%'}
            px={'12px'}
            pt={'10px'}
          >
            <Text
              _dark={{
                color: theme.colors.textDarkMode[100]
              }}
              _light={{
                color: theme.colors.textLightMode[900]
              }}
              fontFamily="Inter-bold"
              fontWeight={700}
              fontSize={15}
              letterSpacing={0}
              lineHeight={'20px'}
              textAlign={'center'}
              numberOfLines={2}
              h={'40px'}
            >
              {displayName}
            </Text>
            {noMiddle ? (
              <Text
                _dark={{
                  color: theme.colors.textDarkMode[100]
                }}
                _light={{
                  color: theme.colors.textLightMode[900]
                }}
                fontFamily="Inter"
                fontWeight={600}
                fontSize={10}
                letterSpacing={0}
                lineHeight={'15px'}
                w={'100%'}
                textAlign={'center'}
                numberOfLines={1}
                mt={'10px'}
              >
                <Ionicons name="lock-closed" size={10} color={color.grey} />
                &nbsp;{isPrivate ? 'Private' : 'Public'} - {memberCount || 0} Members
              </Text>
            ) : null}
          </Box>
        </Box>
      </Pressable>
    </Box>
  )
}

export const SpacePreviewMemo = memo(SpacePreview)

import { useCallback, useState } from 'react'
import { InteractionManager, TextStyle, useWindowDimensions } from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import {
  ARGameTypes,
  BVatomTokenType,
  GeoARGameConfig,
  MultiLevelGameConfig,
  SimpleARGameConfig
} from '@vatom/BVatom/plugin'
import { useTokenInventory } from '@vatom/sdk/react'
import { Button, LoaderView } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Image, Text } from 'native-base'

import { WebWrapper } from '../../../components/WebWrapper'
import { useAnalytics } from '../../../hooks/useAnalytics'
import { NFTDetailProps } from '../../NFTDetail'
import {
  borderStyle,
  dimensionStyle,
  fontFamilyStyle,
  fontSizeStyle,
  nudgeStyle,
  rgbaStyle
} from '../hooks/useStyleConverters'
import { ARGameHeader } from '../partials/ARGameHeader'
import { CardState } from '../partials/CardStateModal'

import { LegacyArGameScenes } from './ARGameScenes'

type PreGameProps = NFTDetailProps & {
  onClose: () => void
}

/** 
  @deprecated this was built originally to contain the UI piece of the 
  game but time has proven each game has the potential to require an entire different UI and internal state for it.
  So it's better to just have a different screen for each game type. 
 *  */

export const PreGame = observer((props: PreGameProps) => {
  const { route, onClose } = props
  const { tokenId } = route.params
  const { analytics } = useAnalytics()
  const { token } = useTokenInventory(tokenId)
  const vatom = token as BVatomTokenType

  const { height } = useWindowDimensions()

  const focused = useIsFocused()

  const gameConfig = vatom?.gameConfig as
    | SimpleARGameConfig
    | MultiLevelGameConfig
    | GeoARGameConfig

  const userPoints = vatom?.userPoints
  const clientPoints = Number(userPoints?.[userPoints?.channel as keyof typeof userPoints] ?? 0)
  const setClientPoints = useCallback(
    (i: number) => {
      vatom.setPrivate({
        ...vatom.private,
        'user-points-v2': {
          ...userPoints,
          [userPoints?.channel as keyof typeof userPoints]: i
        } as typeof userPoints
      })
    },
    [userPoints, vatom]
  )

  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)
  const [isOverlayModalOpen, setIsOverlayModalOpen] = useState(false)
  const [isGameOverModalOpen, setIsGameOverModalOpen] = useState(
    clientPoints >= (userPoints?.maxPoints ?? 10)
  )

  const onButtonPress = () => {
    setIsWarningModalOpen(true)
  }

  const startGame = () => {
    analytics.event('gameStart', {}, vatom)
    setIsWarningModalOpen(false)
    setIsOverlayModalOpen(true)
  }

  const onWarningModalButtonPress = () => {
    startGame()
  }

  const onGameOver = useCallback(() => {
    if (vatom.gameType === 'SimpleGame') {
      analytics.event(
        'gameEnd',
        {
          value: clientPoints
        },
        vatom
      )
    }

    InteractionManager.runAfterInteractions(() => {
      setIsOverlayModalOpen(false)
      setIsGameOverModalOpen(true)
    })
  }, [analytics, clientPoints, vatom])

  const gameOverButtonClick = () => {
    onClose()
  }

  if (!focused) return null

  if (!vatom || !gameConfig || (!userPoints && vatom.gameType !== ARGameTypes.GeoGame)) {
    return <LoaderView />
  }

  // if (vatom.gameType === ARGameTypes.MultiLevelGeoGame) {
  //   return <MultiTokenGame></MultiTokenGame>
  // }

  const backgroundImage = 'backgroundImage' in gameConfig && gameConfig.backgroundImage

  const buttonStyle = gameConfig.buttonStyle
  const countTextStyle = gameConfig.countTextStyle

  let previewOrEndImage = ''

  if (gameConfig.finalStartDate?.toggled && new Date() < new Date(gameConfig.finalStartDate.text)) {
    previewOrEndImage = gameConfig.finalImage?.ref ?? ''
  }

  if (gameConfig.previewEndDate?.toggled && new Date() < new Date(gameConfig.previewEndDate.text)) {
    previewOrEndImage = gameConfig.previewImage?.ref ?? ''
  }

  if (isOverlayModalOpen) {
    return (
      <WebWrapper
        style={{
          position: 'absolute',
          height: '100%',
          overflow: 'hidden'
        }}
      >
        <Box
          style={{
            display: 'flex',
            flex: 1,
            width: '100%'
          }}
        >
          <ARGameHeader
            onClose={() => {
              setIsOverlayModalOpen(false)
              if (vatom.gameType === 'SimpleGame') {
                analytics.event(
                  'gameAbort',
                  {
                    value: clientPoints
                  },
                  vatom
                )
              }
            }}
            gameConfig={gameConfig}
            points={clientPoints}
          />
          <LegacyArGameScenes
            points={clientPoints}
            setPoints={setClientPoints}
            vatom={vatom}
            gameConfig={gameConfig}
            onGameOver={onGameOver}
          />
        </Box>
      </WebWrapper>
    )
  }

  return (
    <>
      {previewOrEndImage ? (
        <Box flex={1}>
          <Image
            position={'absolute'}
            alt={'Preview or Ended'}
            w={'100%'}
            h={'100%'}
            resizeMode="cover"
            accessibilityIgnoresInvertColors
            source={{ uri: previewOrEndImage }}
          />
        </Box>
      ) : (
        <Box style={{ position: 'relative', height: height - 34 }}>
          {backgroundImage && (
            <Image
              position={'absolute'}
              alt={vatom.metadata.name}
              w={'100%'}
              h={'100%'}
              resizeMode="cover"
              accessibilityIgnoresInvertColors
              source={{ uri: backgroundImage.ref }}
            />
          )}

          <Box
            // @ts-expect-error style clash
            style={{
              flex: 1,
              width: '100%',
              height: dimensionStyle(countTextStyle.height),
              ...nudgeStyle(gameConfig.countTextNudge, true)
            }}
          >
            <Box
              style={{
                flex: 1,
                width: '100%',
                ...nudgeStyle(gameConfig.countTextNudge, true)
              }}
            >
              <Text
                // @ts-expect-error style clash
                style={{
                  display: 'flex',
                  textAlign: 'center',
                  color: rgbaStyle(countTextStyle.color),
                  backgroundColor: rgbaStyle(countTextStyle.backgroundColor),
                  fontFamily: fontFamilyStyle(countTextStyle.font.family),
                  fontWeight: countTextStyle.font.weight as TextStyle['fontWeight'],
                  borderRadius: countTextStyle.borderRadius.value,
                  height: dimensionStyle(countTextStyle.height),
                  width: dimensionStyle(countTextStyle.width),
                  fontSize: fontSizeStyle(countTextStyle.font.size) as TextStyle['fontSize'],
                  ...borderStyle(countTextStyle.borderPosition, countTextStyle.color),
                  ...nudgeStyle(gameConfig.countTextNudge),
                  position: 'relative'
                }}
              >
                {gameConfig.countText.replace('{{count}}', clientPoints.toString())}
              </Text>
            </Box>
          </Box>

          <Button
            onPress={onButtonPress}
            // @ts-expect-error style clash
            style={{
              height: dimensionStyle(buttonStyle.height),
              width: dimensionStyle(buttonStyle.width),
              padding: dimensionStyle(buttonStyle.padding),
              borderRadius: buttonStyle.borderRadius.value,
              backgroundColor: rgbaStyle(buttonStyle.backgroundColor),
              alignSelf: 'center',
              ...borderStyle(buttonStyle.borderPosition, buttonStyle.color),
              ...nudgeStyle(gameConfig.buttonNudge, true)
            }}
          >
            <Text
              style={{
                fontFamily: fontFamilyStyle(buttonStyle.font.family),
                color: rgbaStyle(buttonStyle.color),
                fontSize: fontSizeStyle(buttonStyle.font.size) as TextStyle['fontSize'],
                fontWeight: buttonStyle.font.weight as TextStyle['fontWeight']
              }}
            >
              {gameConfig.buttonText}
            </Text>
          </Button>
        </Box>
      )}

      <CardState
        isOpen={isWarningModalOpen}
        onButtonPress={onWarningModalButtonPress}
        content={gameConfig.warning}
        contentNudge={gameConfig.warningNudge}
        contentStyle={gameConfig.warningStyle}
        buttonText={gameConfig.warningButton}
        buttonNudge={gameConfig.warningButtonNudge}
        buttonStyle={gameConfig.warningButtonStyle}
        icon={gameConfig.warningIcon}
        bgColor={gameConfig.modalBackgroundColor}
        closeColor={gameConfig.modalCloseColor}
        onClosePress={() => setIsWarningModalOpen(false)}
      />

      {/* <CardState
        isOpen={true}
        onButtonPress={() => null}
        content={gameConfig.errorTitle}
        contentNudge={gameConfig.errorNudge}
        contentStyle={gameConfig.errorStyle}
        buttonText={gameConfig.errorButton}
        buttonNudge={gameConfig.errorButtonNudge}
        buttonStyle={gameConfig.errorButtonStyle}
        icon={gameConfig.errorIcon}
        bgColor={gameConfig.modalBackgroundColor}
        closeColor={gameConfig.modalCloseColor}
      /> */}

      {/* <CardState
        isOpen={true}
        onButtonPress={() => null}
        content={gameConfig.pleaseWait}
        contentNudge={gameConfig.pleaseWaitNudge}
        contentStyle={gameConfig.pleaseWaitStyle}
        buttonText={gameConfig.pleaseWaitButton}
        buttonNudge={gameConfig.pleaseWaitButtonNudge}
        buttonStyle={gameConfig.pleaseWaitButtonStyle}
        icon={gameConfig.pleaseWaitIcon}
        bgColor={gameConfig.modalBackgroundColor}
        closeColor={gameConfig.modalCloseColor}
      /> */}

      <CardState
        isOpen={isGameOverModalOpen}
        onClosePress={gameOverButtonClick}
        onButtonPress={gameOverButtonClick}
        content={gameConfig.gameOver}
        contentNudge={gameConfig.gameOverNudge}
        contentStyle={gameConfig.gameOverStyle}
        buttonText={gameConfig.gameOverButton}
        buttonNudge={gameConfig.gameOverButtonNudge}
        buttonStyle={gameConfig.gameOverButtonStyle}
        icon={gameConfig.gameOverIcon}
        bgColor={gameConfig.modalBackgroundColor}
        closeColor={gameConfig.modalCloseColor}
      />
    </>
  )
})

import React from 'react'
import { Text } from 'react-native'
import moment from 'moment-timezone'

import { getTimeDifference } from '../../../helpers'

type EndsInNoteProps = {
  startTime?: number
  endTime: number
  beforeText?: string
  afterText?: string
}

const EndsInNote = (props: EndsInNoteProps) => {
  const { startTime, endTime, beforeText, afterText } = props

  const [currentDateTimestamp, setCurrentDateTimestamp] = React.useState(new Date().getTime())

  let isVisible: boolean
  if (!startTime) {
    isVisible = currentDateTimestamp < endTime
  } else {
    isVisible = currentDateTimestamp > startTime && currentDateTimestamp < endTime
  }

  React.useEffect(() => {
    if (!isVisible) {
      return
    }
    const intervalId = setInterval(() => {
      setCurrentDateTimestamp(new Date().getTime())
    }, 10000)

    return () => clearInterval(intervalId)
  }, [isVisible])

  return (
    <>
      {isVisible ? (
        <Text
          style={{
            fontSize: 11,
            lineHeight: 15,
            fontWeight: '400',
            color: '#868E96',
            marginTop: 12
          }}
        >
          {beforeText} {getTimeDifference(endTime)} {afterText}
        </Text>
      ) : null}
    </>
  )
}

export default EndsInNote

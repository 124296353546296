import { useCallback, useRef } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { Object3D, Vector3 } from 'three'

const followCamera = (ref: Object3D<THREE.Event>, camera: THREE.Camera, v: Vector3) => {
  // rotate object around axis each frame
  // Get the camera position and project it onto the XZ plane.
  v.copy(camera.position)
  v.y = ref.position.y
  // Update the rotation of the object to face the camera.
  ref.lookAt(v)
}

export const useFollowCamera = (ref: Object3D<THREE.Event>, enabled: boolean) => {
  const { camera } = useThree()
  const v = useRef(new Vector3())
  const f = useCallback(() => followCamera(ref, camera, v.current), [camera, ref])

  useFrame(() => {
    if (!ref || !enabled) return
    f()
  })

  return f
}

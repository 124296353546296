import { BasePluginStore, Region, RegionType, SessionType, withRootSDKStore } from '@vatom/sdk/core'
import {
  Instance,
  isAlive,
  ISerializedActionCall,
  onAction,
  SnapshotOut,
  types
} from 'mobx-state-tree'
import { nanoid } from 'nanoid'

import logger from '../logger'
import { ErcInventoryRegionStore } from '../regions/ErcInventoryRegion'

import { ErcToken } from './ErcToken'

const className = 'ErcPlugin'

const ErcPluginBase = BasePluginStore.named('ErcPluginBase').props({
  className
})

export const ErcPlugin = types
  .compose(ErcPluginBase, types.model('erc-placeholder', {}))
  .props({
    regions: types.optional(types.array(types.late(() => ErcInventoryRegionStore)), [])
  })
  .named(className)
  .extend(withRootSDKStore)
  .actions(self => ({
    clearCache() {
      logger.info('[VatomPluginBase.clearCache]')
      self.regions.map(r => r.clear())
    },

    async onSessionsChanged(data: ISerializedActionCall): Promise<void> {
      if (!isAlive(self)) return
      if (data.path !== '/dataPool/sessionStore' || data.name !== 'add') return
      this.initializeSessions(data.args)
    },
    async initializeSessions(sess?: any) {
      logger.info('[ErcPlugin.initializeSessions]: start')
      const sessions = await self.rootStore.dataPool.sessionStore.sessions
      sessions.concat(sess)

      if (!sessions) return

      // logger.info('[ErcPlugin.initializeSessions]', getSnapshot(sessions))
      for (const session of sessions) {
        if (session.type === SessionType.Eth) {
          const value = session.value as string

          logger.info('[ErcPlugin.initializeSessions] token.type', session.type, value)
          let region = self.regions.find(r => r.matches(RegionType.inventory, value))
          if (!region) {
            region = this.region(RegionType.inventory, value)
            const compositeRegion = self.rootStore.dataPool.region(region!.id)
            compositeRegion.addRegion(region!)
            logger.info(
              '[ErcPlugin.initializeSessions] compositeRegion',
              compositeRegion.id,
              compositeRegion.regions.length
            )
          }
        }
      }
    },
    async init() {
      logger.info('[ErcPlugin.init]')
      onAction(self.rootStore, this.onSessionsChanged, true)
      // Check if there are any existing credentials to initialize the plugin
      this.initializeSessions(self.rootStore.dataPool.sessionStore.sessions)
    },
    region(id: string, descriptor: string): Region | undefined {
      logger.info('[ErcPlugin.region] Looking for region in plugin:', id, descriptor)
      const region = self.regions.find(r => r.matches(id, descriptor))
      if (region) {
        // logger.info('[ErcPlugin.region] Found region', region)
        return region
      }
      if (id === RegionType.inventory && !!descriptor) {
        logger.info('[ErcPlugin.region] Creating new region in plugin:', id, descriptor)
        const newRegion = ErcInventoryRegionStore.create({ id, address: descriptor, _id: nanoid() })
        self.regions.push(newRegion)
        return newRegion
      }
      return
    }
  }))
  .views(self => ({
    get availableRegions() {
      return [ErcInventoryRegionStore]
    },
    get availableTokens() {
      return [ErcToken]
    },
    get supportedRegions() {
      return [RegionType.inventory]
    }
  }))

export type ErcPluginType = Instance<typeof ErcPlugin>
export type ErcPluginSnapshot = SnapshotOut<typeof ErcPlugin>

import React, { useEffect, useRef } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { RichEditor } from 'react-native-pell-rich-editor'
import { useMatrixUser } from '@vatom/sdk/react'
import { Loader } from '@vatom/wombo'
import axios from 'axios'
import { observer } from 'mobx-react-lite'
import { getSnapshot, IMSTArray, SnapshotIn } from 'mobx-state-tree'

import { withBusinessSelector } from '../../../../../../hooks/useBusinessSelector'
import { AppRoutes, AppStackScreenProps } from '../../../../../../navigators'
import { matrixServerUrl } from '../../../../constants'
import { addMessageStore } from '../../../../screens/AddMessageModal'
import { useCommunitiesTheme } from '../../../../themes'
import { CreateMessageProvider } from '../../../MSTContextProvider'
import { base64ToArrayBuffer } from '../../add-modals-helpers'
import { AddMessageModalStore, Media } from '../../stores'
import CloseButton from '../modal-header-components/CloseButton'
import ModalButton from '../modal-header-components/ModalButton'
import ModalTitle from '../modal-header-components/ModalTitle'
import { DecorationsElements } from '../ModalDecorations'
import ModalError from '../ModalError'
import ModalImagesSlider from '../ModalImagesSlider'
import ModalInput from '../ModalInput'
import ModalPollChoice from '../ModalPollChoice'
import ModalToolbar from '../ModalToolBar'
import ModalWrapper, { ContentWrapper, PlatformWrapper } from '../ModalWrapper'

const addQuestionStore = AddMessageModalStore.create()

type AddSketchViewProps = AppStackScreenProps<
  typeof AppRoutes.RoomNewSketch | typeof AppRoutes.CommunitiesRoomNewSketch
>

const AddSketch = observer(({ navigation }: AddSketchViewProps) => {
  const store = addQuestionStore
  const { sketches } = addMessageStore
  const selectedSketch = sketches.find(sketch => sketch.isSelected === true)

  const inputs = [
    {
      additionalInfo: '',
      placeholder: 'Ask a question',
      type: 'title',
      value: selectedSketch ? selectedSketch['title' as keyof typeof selectedSketch] : ''
    },
    {
      additionalInfo: '',
      placeholder: 'Add some details...',
      type: 'text',
      value: selectedSketch ? selectedSketch['text' as keyof typeof selectedSketch] : ''
    }
  ]
  const decorations = [
    {
      type: 'sketch-category',
      placeholder: 'Category',
      value: selectedSketch ? selectedSketch['category' as keyof typeof selectedSketch] : ''
    }
  ]
  const inputRef = useRef<RichEditor | undefined>() as React.RefObject<RichEditor>
  const { data: matrixUser } = useMatrixUser()

  const communitiesTheme = useCommunitiesTheme()

  const [isLoading, setIsLoading] = React.useState(false)
  const [showError, setShowError] = React.useState(false)
  const [errorData, setErrorData] = React.useState({
    errorTitle: '',
    errorDescription: ''
  })

  let mediaJSON = ''
  if (selectedSketch) {
    const mediaSnapshot = getSnapshot(selectedSketch.media)
    if (mediaSnapshot) {
      const mediaCopy = mediaSnapshot.map(media => {
        const { isSketch, ...rest } = media
        return rest
      }) as IMSTArray<typeof Media>
      mediaJSON = JSON.stringify(mediaCopy)
    }
  }

  useEffect(() => {
    return () => {
      if (selectedSketch) {
        selectedSketch.setIsSelected(false)
      }
      store.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendMessage = async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    const { inputs, additionalInputs } = store
    const { sketches } = addMessageStore

    const { media } = store

    let uploadedMediaCopy: Array<SnapshotIn<typeof Media>> = []
    let isError = false

    if (media.length > 0) {
      await Promise.all(
        media
          .filter(media => media.type === 'image')
          .map(async media => {
            const { type } = media

            if (type !== 'image') {
              return null
            }

            if (!media.base64) {
              return null
            }
            const mimeType = 'image/jpeg'
            const blob = base64ToArrayBuffer(media.base64)

            try {
              const uploadUrl = await axios
                .post(
                  `${matrixServerUrl}/_matrix/media/v3/upload?access_token=${matrixUser?.access_token}`,
                  blob,
                  {
                    headers: {
                      'Content-Type': mimeType
                    }
                  }
                )
                .then(response => response.data.content_uri)
              media.change({ ...media, uri: uploadUrl })
            } catch (e) {
              isError = true
              setIsLoading(false)
              setShowError(true)
              setErrorData({
                errorTitle: 'Upload Error',
                errorDescription: `${media.fileName} could not be uploaded`
              })
              store.removeMedia(media.uri)
              return null
            }
          })
      )
      uploadedMediaCopy = media.map(media => ({ ...media, base64: '', isSketch: true }))
    }

    const sketchesData = {
      id: sketches.length,
      title: inputs?.find(({ type }) => type === 'title')?.value,
      text: inputs?.find(({ type }) => type === 'text')?.value,
      media: uploadedMediaCopy,
      category: additionalInputs?.find(({ type }) => type === 'sketch-category')?.value,
      isSelected: false
    }

    if (!isError) {
      if (selectedSketch) {
        selectedSketch.setData({ ...sketchesData, id: selectedSketch.id })
      } else {
        addMessageStore.addSketch(sketchesData)
      }
      navigation.goBack()
    }
  }

  const hasContent = store.inputs.some(({ value }) =>
    typeof value === 'string'
      ? value
          .replaceAll(/<\/?[^>]+(>|$)/gi, '')
          .replace(/&nbsp;/g, '')
          .replace(/\s/g, '').length > 0
      : null
  )
  const canSend = hasContent

  return (
    <CreateMessageProvider dataStore={store}>
      <ModalWrapper>
        <ModalError
          showError={showError}
          errorTitle={errorData.errorTitle}
          errorDescription={errorData.errorDescription}
          setShowError={setShowError}
        />
        <PlatformWrapper>
          {isLoading ? (
            <View style={modalStyles.loader}>
              <Loader size={60} />
            </View>
          ) : null}

          <ContentWrapper>
            <View style={{ ...modalStyles.header, backgroundColor: communitiesTheme.background }}>
              <CloseButton
                iconType="close"
                style={{
                  position: 'absolute',
                  left: 8,
                  top: 5
                }}
              />
              <ModalTitle title={selectedSketch ? 'Edit Question' : 'Add Question'} />
              <ModalButton
                action={() => (canSend ? sendMessage() : null)}
                text="Save"
                textStyle={{ color: canSend ? '#2EA7FF' : '#CED4DA' }}
              />
            </View>

            <ScrollView
              contentContainerStyle={{
                justifyContent: 'space-between',
                flexGrow: 1,
                flexShrink: 0
              }}
              keyboardShouldPersistTaps="always"
              style={{
                ...modalStyles.inputsHolder,
                backgroundColor: communitiesTheme.background
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 0,
                  paddingHorizontal: 20
                }}
                key="inputs-holder"
              >
                {inputs.map((input, index) => (
                  <ModalInput key={index} input={input} ref={inputRef} focus={true} />
                ))}
                {store.pollChoices.map((choice, index) => (
                  <ModalPollChoice key={index} input={choice} />
                ))}
              </View>
              <View
                key="decorations-holder"
                style={{
                  paddingHorizontal: 20
                }}
              >
                <ModalImagesSlider mediaJSON={mediaJSON} />
                <View>
                  <DecorationsElements elements={decorations} hasNoMessage />
                </View>
              </View>
            </ScrollView>

            <ModalToolbar
              ref={inputRef}
              type={'question'}
              sendMessage={sendMessage}
              hideShowButton
            />
          </ContentWrapper>
        </PlatformWrapper>
      </ModalWrapper>
    </CreateMessageProvider>
  )
})

export default withBusinessSelector(AddSketch)

const modalStyles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    position: 'relative',
    paddingBottom: 10
    // alignItems: 'center'
  },
  inputsHolder: {
    width: '100%',
    flexDirection: 'column',
    paddingTop: 14
  },
  toolbarHodlder: {
    bottom: 0,
    width: '100%'
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
    alignItems: 'center',
    justifyContent: 'center'
  }
})

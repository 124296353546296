import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { Text, theme } from '@vatom/wombo'
import { Box, Pressable } from 'native-base'

import { AppRoutes } from '../../../navigators'

export interface ISectionHeadingProps {
  title: string
  viewAll: boolean
}

export const SectionHeading = ({ title, viewAll }: ISectionHeadingProps) => {
  const navigation = useNavigation()
  return (
    <Box flexDir="row" justifyContent="space-between" mb={'10px'} pl={'16px'}>
      <Text
        _dark={{
          color: theme.colors.textDarkMode[100]
        }}
        _light={{
          color: theme.colors.textLightMode[900]
        }}
        letterSpacing={0}
        lineHeight={'28px'}
        fontFamily="Inter-bold"
        fontSize={'22px'}
        fontWeight={700}
      >
        {title}
      </Text>
      {viewAll ? (
        <Pressable
          accessibilityRole="button"
          alignSelf="center"
          onPress={() => navigation.navigate(AppRoutes.profileUser)}
        >
          <Text
            _dark={{
              color: theme.colors.systemColorsDark.blue
            }}
            _light={{
              color: theme.colors.systemColorsLight.blue
            }}
            fontFamily="Inter-Medium"
            fontSize={15}
          >
            View All
          </Text>
        </Pressable>
      ) : null}
    </Box>
  )
}

export default SectionHeading

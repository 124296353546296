import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type ScanQrCodeProps = {
  size: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
}

export const ScanQrCode = ({ size, fill }: ScanQrCodeProps) => (
  <Icon viewBox="0 0 34 34" size={size}>
    <Path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 12V7A.25.25 0 0 1 7 6.75h5v-1.5H7A1.75 1.75 0 0 0 5.25 7v5h1.5zM27 6.75h-5v-1.5h5c.966 0 1.75.784 1.75 1.75v5h-1.5V7a.25.25 0 0 0-.25-.25zM11 14v-3h3v3h-3zm-2-4a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-5zm11.429 8.143h-2.286v2.286h2.286v-2.286zm2.285 0H25v2.286h-2.286v-2.286zm-2.285 2.286h2.286v2.285H25V25h-2.286v-2.286H20.43V25h-2.286v-2.286h2.286V20.43zM11 20v3h3v-3h-3zm-1-2a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-5zm10-4v-3h3v3h-3zm-2-4a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-5zm9 17.25h-5v1.5h5A1.75 1.75 0 0 0 28.75 27v-5h-1.5v5a.25.25 0 0 1-.25.25zM6.75 22v5c0 .138.112.25.25.25h5v1.5H7A1.75 1.75 0 0 1 5.25 27v-5h1.5z"
    />
  </Icon>
)

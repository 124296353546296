import React, { useState } from 'react'
import { GradientButton, Text } from '@vatom/wombo'
import { Actionsheet, Box, Divider, Spinner, VStack } from 'native-base'

import { useMaxWidth } from '../../../components/WebWrapper'
import logger from '../../../logger'

interface ActionSheetMergeProps {
  isOpen: boolean
  onMerge: () => void
  onCancel: () => void
  message: string
}

export const ActionSheetMerge = ({ isOpen, onMerge, onCancel, message }: ActionSheetMergeProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const maxWidth = useMaxWidth()
  logger.info('ActionSheetMerge')

  if (!isOpen) {
    return null
  }

  return (
    <Actionsheet
      isOpen={isOpen}
      onClose={() => null}
      maxWidth={maxWidth}
      margin={'auto'}
      useRNModal={true}
      // @ts-ignore prop doesn't exit on types
      _backdropFade={{
        entryDelay: 150,
        exitDelay: 10
      }}
    >
      <Actionsheet.Content pt="20px" pb="16px" px={0}>
        <VStack w="100%" paddingX="16px">
          <Text textAlign="center">{message}</Text>
        </VStack>

        <Divider backgroundColor="gray.900" height="0px" mt={4} />
        <Box width={'100%'} px={4}>
          <GradientButton
            colorScheme={isLoading ? 'gray' : 'brand'}
            px={4}
            w="100%"
            justifyContent="center"
            _web={{
              outlineStyle: 'none'
            }}
            onPress={() => {
              setIsLoading(true)
              onMerge()
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner color="black" fontSize="18px" py={3} />
            ) : (
              <Text
                color="white"
                fontFamily="Inter-SemiBold"
                alignSelf="center"
                py={2}
                fontSize="18px"
              >
                Consolidate
              </Text>
            )}
          </GradientButton>
        </Box>

        <Divider backgroundColor="gray.900" height="0px" mt={4} />

        <Box width={'100%'} px={4}>
          <GradientButton
            colorScheme="gray"
            px={4}
            w="100%"
            justifyContent="center"
            onPress={onCancel}
            disabled={isLoading}
          >
            <Text
              color="white"
              fontFamily="Inter-SemiBold"
              alignSelf="center"
              py={2}
              fontSize="18px"
            >
              Keep them separate
            </Text>
          </GradientButton>
        </Box>
      </Actionsheet.Content>
      {/* )} */}
    </Actionsheet>
  )
}
export default ActionSheetMerge

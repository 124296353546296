import { useRef } from 'react'

import { WebWrapper } from '../../../components/WebWrapper'
import { useModalCloseHandler } from '../../../hooks/useModalCloseHandler'
import { AppRoutes, AppStackScreenProps } from '../../../navigators'

import NftFiltersModal from './NftFiltersModal'

type NFTFiltersProps = AppStackScreenProps<typeof AppRoutes.nftFilters>

export default ({ route, navigation }: NFTFiltersProps) => {
  const ref = useRef(null)
  const wrapperRef = useRef(null)

  useModalCloseHandler(ref)

  return (
    <WebWrapper backgroundColor="transparent" justifyContent={'center'} ref={wrapperRef}>
      <NftFiltersModal ref={ref} navigation={navigation} route={route} />
    </WebWrapper>
  )
}

import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { IdentitySnapshot } from '@vatom/sdk/core'
import { Destination, DestinationTypes, useAddRecentContact } from '@vatom/sdk/react'
import { Status, Text, Toast } from '@vatom/wombo'

import Alert from '../../../../components/Alert'
import { AppRoutes } from '../../../../navigators'

import { SendBtn, senderInterface } from '.'

export default function SendVatomInvitation({ token, destination, isShare }: senderInterface) {
  const navigation = useNavigation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const addRecentContactMutation = useAddRecentContact()

  const isValid =
    destination &&
    'isValid' in destination &&
    destination?.isValid &&
    (destination.type === DestinationTypes.Email || destination.type === DestinationTypes.Phone)

  const sendObject = async (): Promise<void> => {
    setIsLoading(true)

    if (destination?.value) {
      try {
        const payload: (Destination | IdentitySnapshot) & {
          phoneNumber?: string
          email?: string
        } = { ...destination }

        if (destination.type === DestinationTypes.Phone) {
          payload.phoneNumber = destination.validatedValue
        }

        if (destination.type === DestinationTypes.Email) {
          payload.email = destination.validatedValue
        }

        await token.performAction(isShare ? 'Clone' : 'Transfer', payload)
        if (destination) addRecentContactMutation.mutate(payload)

        Toast({
          title: `Your NFT has been ${isShare ? 'shared' : 'sent'}`,
          status: Status.success
        })
      } catch (error) {
        console.error('sendObject', error)
        Alert.showError(error)
      }
    }
  }

  return (
    <SendBtn
      smart={true}
      onPress={() => {
        sendObject()
        navigation.navigate(AppRoutes.home)
      }}
      disabled={!isValid || isLoading}
      isShare={isShare}
    >
      <Text tx="shareNft.sendInvitation" />
    </SendBtn>
  )
}

import * as Contacts from 'expo-contacts'
import { CountryCode } from 'libphonenumber-js'

import { RelationshipContact } from '../../relationships'
import { DeviceContact } from '../types'
import { validateDestination } from '../validate'

export const relationshipContactSelector = (
  contacts: Contacts.ContactResponse,
  countryCode?: CountryCode
) => {
  const relationContacts = [] as RelationshipContact[]
  contacts.data.forEach(contact => {
    contact.emails?.forEach(email => {
      if (email?.email) {
        relationContacts.push({
          type: 'email',
          value: email.email
        })
      }
    })
    contact.phoneNumbers?.forEach(phoneNumber => {
      if (phoneNumber.digits) {
        const res = validateDestination(phoneNumber.digits, false, countryCode)
        if (res.isValid && res.validatedValue) {
          relationContacts.push({
            type: 'phoneNumber',
            value: res.validatedValue
          })
        }
      }
    })
  })
  return relationContacts
}

export const deviceContactsSelector = (
  contacts: Contacts.ContactResponse,
  countryCode?: CountryCode
) => {
  const relationContacts = [] as DeviceContact[]
  contacts.data.forEach(contact => {
    contact.emails?.forEach(email => {
      if (email?.email) {
        relationContacts.push({
          type: 'email',
          value: email.email,
          name: contact.name
        })
      }
    })
    contact.phoneNumbers?.forEach(phoneNumber => {
      if (phoneNumber.digits) {
        const res = validateDestination(phoneNumber.digits, false, countryCode)
        if (res.isValid && res.validatedValue) {
          relationContacts.push({
            type: 'phoneNumber',
            value: res.validatedValue,
            name: contact.name
          })
        }
      }
    })
  })
  return relationContacts
}

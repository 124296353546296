import { Environment } from '@react-three/drei'

export const DefaultEnvironment = () => {
  return (
    /* Environment map, which adds ambient lighting and is required for PBR or reflective materials (also replaces manual dynamic lights in later Three.js versions) */

    <Environment
      files={[
        require('./envmap_px.jpg'),
        require('./envmap_nx.jpg'),
        require('./envmap_py.jpg'),
        require('./envmap_ny.jpg'),
        require('./envmap_pz.jpg'),
        require('./envmap_nz.jpg')
      ]}
    />
  )
}

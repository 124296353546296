import React from 'react'
import { useWindowDimensions } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { PublicProfileSnapshot, SpaceType } from '@vatom/sdk/core'
import { useSpaceAttendees, useUser } from '@vatom/sdk/react'
import { Avatar, theme } from '@vatom/wombo'
import { Actionsheet, Box, Divider, FlatList, Pressable, Text, View } from 'native-base'

import { AppRoutes } from '../../navigators'
import ActionSheetHeader, {
  HeaderType
} from '../../screens/ActionSheets/partials/ActionSheetHeader'
import { useMaxWidth } from '../WebWrapper'

export type AttendeesTypes = {
  open: boolean
  setOpen: (x: boolean) => void
  space: SpaceType
}

export default function AttendeesModal(props: AttendeesTypes) {
  const user = useUser()
  const { open, setOpen } = props
  const spaceAttendees = useSpaceAttendees()
  const guests = spaceAttendees.data ?? []
  const insets = useSafeAreaInsets()
  const { height: screenHeight } = useWindowDimensions()
  const maxWidth = useMaxWidth()

  const navigation = useNavigation()

  const openProfile = (userId: string) => {
    setOpen(false)
    if (userId === user?.sub) {
      navigation.navigate(AppRoutes.profileUser)
    } else {
      navigation.navigate(AppRoutes.profileOtherUser, { userId })
    }
  }

  return (
    <Actionsheet
      isOpen={open}
      onClose={() => setOpen(false)}
      maxWidth={maxWidth}
      margin={'auto'}
      useRNModal={true}
      // @ts-ignore prop doesn't exit on types
      _backdropFade={{
        entryDelay: 150,
        exitDelay: 10
      }}
    >
      <Actionsheet.Content maxHeight={screenHeight - insets.top} height={screenHeight - insets.top}>
        <View w="100%" h="100%">
          <ActionSheetHeader
            type={HeaderType.close}
            title={'Guests'}
            onPress={() => setOpen(false)}
            subTitle={`${guests.length} ${guests.length === 1 ? 'Guest' : 'Guests'}`}
          />
          <FlatList
            flex={1}
            data={guests}
            renderItem={({ item, index }) => (
              <AttendeesItem item={item} index={index} openProfile={openProfile} />
            )}
            showsVerticalScrollIndicator={false}
            keyExtractor={item => `results-${item.id}`}
          />
        </View>
      </Actionsheet.Content>
    </Actionsheet>
  )
}

interface ResultListItemProps {
  index: number
  item: PublicProfileSnapshot
  openProfile: (userId: string) => void
}

const AttendeesItem = ({ item, index, openProfile }: ResultListItemProps) => {
  return (
    <Pressable
      accessibilityLabel="button"
      accessibilityHint="button"
      onPress={() => openProfile(item.id)}
    >
      <Box alignItems="center" flexDir="row" h={50} my="10px">
        <Box marginRight="8px" justifyContent="center" alignItems="center">
          <Avatar size={44} url={item.picture} name={item.name} key={index} />
        </Box>
        <Box flex={1}>
          <Text color="black" fontFamily="Inter-Bold" fontSize={15}>
            {item.name}
          </Text>
          {item.preferred_username && (
            <Text
              color={theme.colors.lightText}
              fontFamily="Inter-Regular"
              fontSize={13}
              lineHeight={18}
            >
              {`@${item.preferred_username}`}
            </Text>
          )}
        </Box>
      </Box>
      <Divider bg="gray.900" height="1px" />
    </Pressable>
  )
}

import {
  businessQueryKeys,
  Channels,
  fetchBusiness,
  fetchUserProfile,
  publicProfileQueryKeys,
  SDKQueryClient,
  useCrateStore,
  useNotificationsStore
} from '@vatom/sdk/react'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { Status, Toast } from '@vatom/wombo'
import { create } from 'zustand'

const notificationStore = create<{
  items: string[]
  addItems: (val: string[]) => void
}>(set => ({
  items: [],
  addItems: (newItems: string[]) => {
    set(currentState => ({
      items: currentState.items.concat(newItems)
    }))
  }
}))

export type AnyTokenForNotification = {
  id: string
  title: string
  objectDefinitionId?: string
  businessId?: string
}

function shouldDisableWalletNotification() {
  const walletSDKhideNotifications =
    useVatomWalletSdkStore.getState().walletConfig.features.disableNewTokenToast
  // useVatomWalletSdkStore.getState()?.config?.disableNewTokenToast &&
  // useVatomWalletSdkStore.getState()?.isEmbedded

  return [walletSDKhideNotifications, useCrateStore.getState().isRewardsActive].some(v => !!v)
}

export async function notifyWalletFeature(items: AnyTokenForNotification[]) {
  if (shouldDisableWalletNotification()) {
    console.info('SendNotification.notifyWalletFeature: DISABLED')
    return
  }

  const currentItems = notificationStore.getState().items
  const itemsToNotify = items.filter(item => !currentItems.includes(item.id))
  if (itemsToNotify.length < 1) {
    console.info('SendNotification.notifyWalletFeature: nothing to send')
    return
  }

  // store items ids
  notificationStore.getState().addItems(itemsToNotify.map(i => i.id))

  itemsToNotify.forEach(item => {
    sendNotification({
      tokenId: item.id,
      tokenTitle: item.title,
      businessId: item?.businessId,
      objectDefinitionId: item?.objectDefinitionId
    })
  })
}

export async function sendNotification({
  tokenId,
  objectDefinitionId,
  tokenTitle,
  businessId,
  previousOwnerId
}: {
  tokenId: string
  tokenTitle: string
  objectDefinitionId?: string
  businessId?: string
  previousOwnerId?: string
}) {
  // First add it to global notification store to quickly avoid re processing
  useNotificationsStore.getState().addNotification(Channels.wallet, tokenId)
  //
  let isDisabled = false
  // User info
  let fromAvatar: string | undefined = undefined
  let fromName = 'A User'

  if (businessId) {
    const businessConfig = await SDKQueryClient.ensureQueryData({
      queryKey: businessQueryKeys.getBusiness(businessId ?? '', true),
      queryFn: fetchBusiness
    })
    const vatomFeatures = businessConfig?.pageConfig?.features?.vatom
    if (vatomFeatures?.disableNewTokenToast) {
      isDisabled = true
    } else if (
      objectDefinitionId &&
      vatomFeatures?.ignoreNewTokenToast?.includes(objectDefinitionId)
    ) {
      isDisabled = true
    }
  }

  if (isDisabled) {
    console.info('sendNotification.DISABLED: business config')
    return
  }

  if (previousOwnerId) {
    const userData = await SDKQueryClient.ensureQueryData({
      queryKey: publicProfileQueryKeys.getPublicProfile(previousOwnerId),
      queryFn: fetchUserProfile
    })

    fromAvatar = userData?.picture
    fromName = userData?.name || fromName
  }

  const toastPayload = {
    status: Status.info,
    title: `${fromName} sent you a ${tokenTitle} object`,
    avatar: fromAvatar
  }
  console.info('sendNotification.Toast:', toastPayload)
  Toast(toastPayload)
}

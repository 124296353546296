import { useCallback, useRef } from 'react'
import { Alert, Linking, Platform } from 'react-native'
import { QueryOptions, useQuery } from '@tanstack/react-query'
import * as Location from 'expo-location'
import { CountryCode } from 'libphonenumber-js'

import { useAnalytics } from './useAnalytics'
import { useLocationPermission } from './useLocationPermission'

export type useCurrentLocationProps = {
  locationOptions?: Location.LocationOptions
  useQueryOptions?: Omit<QueryOptions<Location.LocationObject>, 'queryKey' | 'queryFn' | 'enabled'>
  timeout?: number
  extraData?: Record<string, unknown>
}

const defaultTimeout = 2000
const isSafari =
  Platform.OS === 'web' && /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent)

// const getCompassHeading = () => {
//   return new Promise<number>(async (resolve, reject) => {
//     let permission
//     // @ts-ignore
//     if (DeviceOrientationEvent.requestPermission) {
//       // @ts-ignore
//       permission = await DeviceOrientationEvent.requestPermission()
//       if (permission !== 'granted') resolve(-1)
//     }
//     window.addEventListener(
//       isSafari ? 'deviceorientation' : 'deviceorientationabsolute',
//       function (e) {
//         // @ts-expect-error
//         if (e.webkitCompassHeading) {
//           // @ts-expect-error
//           const heading = e.webkitCompassHeading as number
//           resolve(heading)
//         } else {
//           // @ts-expect-error
//           resolve(180 - (e?.alpha ?? 0))
//         }
//       },
//       !isSafari
//     )
//   })
// }

const fetchLocation = async (locationOptions?: Location.LocationOptions, timeout?: number) => {
  console.log('fetchLocation')

  const timeOutPromise = new Promise<Location.LocationObject>((_, reject) => {
    setTimeout(() => {
      reject(new Error('Location request timed out'))
    }, timeout ?? defaultTimeout)
  })

  const createWebLocationPromise = () =>
    new Promise<Location.LocationObject>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => {
          resolve(position)
        },
        error => {
          reject(error)
        }
      )
    })

  const locationPromise =
    Platform.OS === 'web'
      ? createWebLocationPromise()
      : Location.getCurrentPositionAsync(locationOptions)
  const lastKnownLocationPromise = Location.getLastKnownPositionAsync()
  try {
    const result = await Promise.race([timeOutPromise, locationPromise])
    return result
  } catch (error) {
    console.error('Error: fetchlocation, using last known position', error)
    const result = await lastKnownLocationPromise
    if (result) {
      return result
    } else {
      throw error
    }
  }
  // const isMobileWeb =
  //   Platform.OS === 'web' &&
  //   /iphone|ipod|ipad|android/.test(window?.navigator.userAgent.toLowerCase())
  // if (!result.coords.heading && isMobileWeb) {
  //   const heading = await getCompassHeading()
  //   result.coords.heading = heading
  // }
}

export const fetchLocationFromIp = async () => {
  const response = await fetch(
    'https://api.ipstack.com/check?access_key=f6c1392b737ae773c32e839ccd03e87f&fields=country_code,latitude,longitude'
  )
  return (await response.json()) as {
    latitude: number
    longitude: number
    country_code: CountryCode
  }
}

export const useLocationFromIp = () => {
  const result = useQuery(['location-from-ip'], fetchLocationFromIp, {
    staleTime: 60 * 60 * 1000
  })
  return result
}

export const useCurrentLocation = (props?: useCurrentLocationProps) => {
  const { locationOptions, useQueryOptions, timeout, extraData = {} } = props ?? {}
  const analytics = useAnalytics()

  const {
    permission,
    isLoading: permissionIsLoading,
    isFetching: permissionIsFetching
  } = useLocationPermission()
  const requestedEnableLocation = useRef(false)

  const fetchFallBackLocation = useCallback(async (): Promise<Location.LocationObject> => {
    const { latitude, longitude } = await fetchLocationFromIp()

    return {
      coords: {
        latitude,
        longitude,
        accuracy: null,
        altitude: null,
        altitudeAccuracy: null,
        speed: null,
        heading: null
      },
      mocked: false,
      timestamp: Date.now()
    }
  }, [])

  const fetchLocationWithoutPermission = () => {
    if (!requestedEnableLocation.current && Platform.OS !== 'web') {
      return new Promise<Location.LocationObject>((resolve, reject) => {
        Alert.alert(
          'Location Permission',
          'For a better experience, Please enable location permission to see the nearby tokens',
          [
            {
              text: 'Cancel',
              onPress: () => {
                requestedEnableLocation.current = true
                fetchFallBackLocation().then(resolve).catch(reject)
              },
              style: 'cancel'
            },
            {
              text: 'OK',
              onPress: () => {
                requestedEnableLocation.current = true
                Linking.openSettings()
                reject(
                  "User sent to settings to enable location permission. We'll try again when they come back"
                )
              }
            }
          ]
        )
      })
    } else {
      return fetchFallBackLocation()
    }
  }

  const {
    data: location,
    isLoading,
    error,
    refetch
  } = useQuery({
    queryKey: ['currentLocation', permission?.status, locationOptions, timeout] as const,
    queryFn: async ({ queryKey }) => {
      const [, permissionStatus, locationOptions, timeout] = queryKey
      if (permissionStatus === 'granted') {
        return await fetchLocation(locationOptions, timeout)
      }
      return await fetchLocationWithoutPermission()
    },
    refetchInterval: 1000 * 60 * 10 * 5,
    refetchOnMount: true,
    staleTime: 10000,

    ...useQueryOptions,
    enabled: !permissionIsFetching && !permissionIsLoading,
    refetchOnWindowFocus: true,
    meta: {
      volatile: true
    },

    onError: error => {
      console.error('Error: useCurrentLocation', error)
    },

    onSuccess: location => {
      if (location?.coords) {
        console.log('LOG: > useCurrentLocation > extraData:', extraData)
        analytics.event('updateLocation', {
          ...location?.coords,
          ...extraData
        })
      }
    }
  })
  return { location, error, isLoading, refetch, permission }
}

import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const ExternalLinkIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 14 14" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M0 2V12C0 13.125 0.875 14 2 14H12C13.0938 14 14 13.125 14 12V2C14 0.90625 13.0938 0 12 0H2C0.875 0 0 0.90625 0 2ZM1 2C1 1.46875 1.4375 1 2 1H12C12.5312 1 13 1.46875 13 2V12C13 12.5625 12.5312 13 12 13H2C1.4375 13 1 12.5625 1 12V2ZM4.125 9.15625C3.9375 9.34375 3.9375 9.6875 4.125 9.875C4.21875 9.96875 4.34375 10 4.5 10C4.625 10 4.75 9.96875 4.84375 9.875L9 5.71875V8.5C9 8.78125 9.21875 9 9.5 9C9.75 9 10 8.78125 10 8.5V4.53125C10 4.25 9.75 4.03125 9.5 4.03125L5.5 4C5.21875 4 5 4.25 5 4.5C4.96875 4.78125 5.21875 5 5.46875 5L8.25 5.03125L4.125 9.15625Z"
    />
  </Icon>
)

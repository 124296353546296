import React, { Fragment } from 'react'
import { IdentityType } from '@vatom/sdk/core'
import { useConfig, useIdentities } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { Status, Text, Toast } from '@vatom/wombo'
import * as Clipboard from 'expo-clipboard'
import { observer } from 'mobx-react-lite'
import { Box, Divider } from 'native-base'

import { useBusinessTheme } from '../../../hooks/useBusinessTheme'

import EditableComponent from './EditableComponent'

interface IIdentityListProps {
  openQrCode: (walletAddress: string) => void
  background?: string
}

export const IdentityList = observer(
  ({ openQrCode, background = 'transparent' }: IIdentityListProps) => {
    const { identities } = useIdentities()
    const { isDark } = useBusinessTheme()
    const config = useConfig()

    const showEthIdentity = config.features.allowedChains.includes('eth')

    const showSolIdentity = config.features.allowedChains.includes('sol')

    const showCasperIdentity = config.features.allowedChains.includes('cspr')

    const copyToClipboard = async (copiedText: string) => {
      await Clipboard.setStringAsync(copiedText)

      Toast({
        title: translate('common.copiedToClipboard', { text: copiedText }),
        placement: 'top',
        status: Status.success
      })
    }

    return (
      <Box background={background}>
        <Text
          marginX="16px"
          marginTop="30px"
          marginBottom="18px"
          fontFamily="Inter-Bold"
          fontSize="22px"
          lineHeight="28px"
          color="darkText"
          tx={'profileScreen.accounts'}
          _dark={{
            color: 'textDarkMode.100'
          }}
        />
        {identities.map((i, index) => {
          if (i.type !== IdentityType.Email) return null

          const tokenName = `${i.value} ${i.preferred ? translate('profileScreen.default') : ''}`
          return (
            <Fragment key={index}>
              <EditableComponent
                valueFontSize="15px"
                label={translate('common.emailAddress')}
                value={tokenName}
              />
              <Divider backgroundColor={isDark ? '#FFFFFF4D' : 'gray.900'} />
            </Fragment>
          )
        })}
        {identities.map((i, index) => {
          if (i.type !== IdentityType.Phone) return null

          const tokenName = `${i.value} ${i.preferred ? translate('profileScreen.default') : ''}`
          return (
            <Fragment key={index}>
              <EditableComponent
                valueFontSize="15px"
                label={translate('common.phoneNumber')}
                value={tokenName}
              />
              <Divider backgroundColor={isDark ? '#FFFFFF4D' : 'gray.900'} />
            </Fragment>
          )
        })}

        {showEthIdentity
          ? identities.map((i, index) => {
              if (i.type !== IdentityType.Eth) return null

              return (
                <Fragment key={index}>
                  <EditableComponent
                    valueFontSize="12px"
                    label={`${translate('common.ethereumAddress')} ${
                      i.name ? ' (' + i.name + ')' : ''
                    } ${i.custodial ? ` (${translate('profileScreen.custodial')})` : ''}`}
                    value={i.value}
                    showCopy={true}
                    showQr={true}
                    onCopyPress={() => copyToClipboard(i.value)}
                    onQrPress={() => openQrCode(`ethereum:${i.value}`)}
                  />
                  <Divider backgroundColor={isDark ? '#FFFFFF4D' : 'gray.900'} />
                </Fragment>
              )
            })
          : null}

        {showSolIdentity
          ? identities.map((i, index) => {
              if (i.type !== IdentityType.Solana) return null

              return (
                <Fragment key={index}>
                  <EditableComponent
                    valueFontSize="12px"
                    label={`${translate('common.solanaAddress')} ${
                      i.name ? ' (' + i.name + ')' : ''
                    } ${i.custodial ? ` (${translate('profileScreen.custodial')})` : ''}`}
                    value={i.value}
                    showCopy={true}
                    showQr={true}
                    onCopyPress={() => copyToClipboard(i.value)}
                    onQrPress={() => openQrCode(`solana:${i.value}`)}
                  />
                  <Divider backgroundColor={isDark ? '#FFFFFF4D' : 'gray.900'} />
                </Fragment>
              )
            })
          : null}

        {showCasperIdentity
          ? identities.map((i, index) => {
              if (i.type !== IdentityType.Casper) return null

              return (
                <Fragment key={index}>
                  <EditableComponent
                    valueFontSize="12px"
                    label={`Casper Address ${i.name ? ' (' + i.name + ')' : ''} ${
                      i.custodial ? ` (${translate('profileScreen.custodial')})` : ''
                    }`}
                    value={i.value}
                    showCopy={true}
                    showQr={true}
                    onCopyPress={() => copyToClipboard(i.value)}
                    onQrPress={() => openQrCode(`casper:${i.value}`)}
                  />
                  <Divider backgroundColor={isDark ? '#FFFFFF4D' : 'gray.900'} />
                </Fragment>
              )
            })
          : null}
      </Box>
    )
  }
)

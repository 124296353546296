import React from 'react'
import { ReactSVG } from 'react-svg'

import { ActiveIcon } from '../ActiveIcon'

import { CustomIconProps } from '.'

const CustomIcon = ({ src, focused, color }: CustomIconProps) => {
  const shouldShowBadge = false

  if (!src) {
    return null
  }

  return (
    <ActiveIcon isActive={shouldShowBadge && !focused}>
      <ReactSVG
        src={src}
        wrapper="div"
        width={32}
        height={32}
        beforeInjection={svg => {
          svg.setAttribute('style', `width: 32; height: 32; fill: ${color}`)
        }}
      />
    </ActiveIcon>
  )
}

export { CustomIcon }

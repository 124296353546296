import React from 'react'
import { ScrollView } from 'react-native'
import { observer } from 'mobx-react-lite'
import { useDebounce } from 'use-debounce'

import { useStoreContext } from '../../MSTContextProvider'

import ModalLinkPreview from './ModalLinkPreview'

const ModalLinkPreviews = observer(() => {
  const store = useStoreContext()
  const storeInput = store.inputs.find(sinput => sinput.type === 'text')
  const inputValue = (storeInput?.value ?? '') as string
  const [htmlString] = useDebounce(inputValue, 250)

  const regex = /href="([^"]*)"/g
  let matches
  const links: string[] = []

  while ((matches = regex.exec(htmlString)) !== null) {
    links.push(matches[1])
  }

  const uniqueLinks = Array.from(new Set(links))

  return (
    <ScrollView style={{ paddingVertical: 16, flexDirection: 'row' }} horizontal={true}>
      {uniqueLinks?.map(link => (
        <ModalLinkPreview key={link} link={link} />
      ))}
    </ScrollView>
  )
})

export default ModalLinkPreviews

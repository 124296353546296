/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { useWebViewRefStore } from '../../../../hooks/useWebViewRef'
import { ThemeInterface } from '../../WebBusinessProvider/types'

interface IWebViewBase {
  businessTheme: ThemeInterface
  setWebviewRef: (ref: HTMLIFrameElement | null) => void
  uri: string
  onMessage: (event: any) => void
}

export default observer(({ businessTheme, setWebviewRef, uri, onMessage }: IWebViewBase) => {
  useEffect(() => {
    return () => useWebViewRefStore.getState().setRef(null)
  }, [])

  useEffect(() => {
    window.addEventListener('message', onMessage)

    return () => window.removeEventListener('message', onMessage)
  }, [onMessage])

  return (
    <iframe
      src={uri}
      width="100%"
      id="business-iframe"
      height="100%"
      style={{
        flex: 1,
        width: '100%',
        height: '100%',
        maxWidth: '415px',
        backgroundColor: businessTheme.background,
        border: 0
      }}
      allow="web-share"
      ref={ref => {
        setWebviewRef(ref)
      }}
    />
  )
})

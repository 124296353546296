import { useQuery } from '@tanstack/react-query'
import { useSDK } from '@vatom/sdk/react'

export const useUserById = (userId: string) => {
  const { vatomIncApi } = useSDK()
  const queryUser = useQuery(
    ['user', userId],
    async () => {
      return await vatomIncApi.getPublicProfile(userId)
    },
    {
      enabled: !!userId
    }
  )
  return queryUser
}

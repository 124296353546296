import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const BlockchainWalletIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 17 19" size={size} {...iconProps}>
    <Path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7377 0.51875C11.8802 0.5575 12.001 0.653509 12.0723 0.784662L13.7551 3.87962H15.6617C16.0246 3.87962 16.3675 4.03292 16.6164 4.29723C16.8646 4.5607 17 4.91303 17 5.27554V9.07106C17 9.0732 17 9.07533 17 9.07747V13.3019C17 13.3041 17 13.3062 17 13.3084V17.1041C17 17.4666 16.8646 17.8189 16.6164 18.0824C16.3675 18.3467 16.0246 18.5 15.6617 18.5H1.3383C0.97538 18.5 0.632479 18.3467 0.383549 18.0824C0.135403 17.8189 0 17.4666 0 17.1041V5.27554C0 4.91303 0.135403 4.5607 0.383549 4.29723C0.632479 4.03292 0.975379 3.87962 1.3383 3.87962H5.9331L11.317 0.579273C11.4433 0.501853 11.5952 0.480001 11.7377 0.51875ZM12.5124 3.87388L11.395 1.81869L8.05073 3.86873L12.5124 3.87388ZM15.9149 13.853H12.9761C11.5248 13.853 10.3447 12.6626 10.3447 11.1897C10.3447 9.71682 11.5248 8.52645 12.9761 8.52645H15.9149V5.27554C15.9149 5.18989 15.8826 5.11241 15.8323 5.05898C15.7827 5.00639 15.7208 4.98166 15.6617 4.98166H1.3383C1.27912 4.98166 1.21722 5.00639 1.16769 5.05898C1.11737 5.11241 1.0851 5.18989 1.0851 5.27554V17.1041C1.0851 17.1897 1.11737 17.2672 1.16769 17.3206C1.21722 17.3732 1.27912 17.398 1.3383 17.398H15.6617C15.7208 17.398 15.7827 17.3732 15.8323 17.3206C15.8826 17.2672 15.9149 17.1897 15.9149 17.1041V13.853ZM15.9149 9.62849H12.9761C12.1197 9.62849 11.4298 10.3298 11.4298 11.1897C11.4298 12.0496 12.1197 12.7509 12.9761 12.7509H15.9149V9.62849Z"
    />
  </Icon>
)

export const palette = {
  black: '#1d1d1d',
  white: '#ffffff',
  offWhite: '#e6e6e6',
  orange: '#FF8000',
  orangeDarker: '#EB9918',
  lightGrey: '#939AA4',
  lighterGrey: '#CDD4DA',
  angry: '#dd3333',
  deepPurple: '#5D2555',
  grey: '#868E96',
  tintColor: '#FA4E0F',
  bg: '#F1F3F5',
  textBlack: '#262626',
  textLight: '#868E96',
  borderGrey: '#E9ECEF',
  textBlue: '#2EA7FF'
}

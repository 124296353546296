import { ARFilterConfig } from '@vatom/BVatom/plugin'
import { useControls } from 'leva'
import { create } from 'zustand'

type FilterStore = ARFilterConfig

export const useFilterStore = create<FilterStore>(() => ({
  closeOnPickup: false,
  distanceMultiplier: 1,
  maxItems: 5,
  minDistance: 5,
  visibleRadius: 20,
  errorIfEmpty: false,
  levels: []
}))

export const useARFilters = () => {
  useControls('AR Filter', () => {
    return {
      // closeOnPickup: {
      //     value: ,
      //     label: 'Close on Pickup',
      // },
      distanceMultiplier: {
        min: 0.01,
        max: 1,
        step: 0.01,
        value: useFilterStore.getState().distanceMultiplier,
        label: 'Distance Multiplier',
        onChange: (value: number) => {
          useFilterStore.setState({ distanceMultiplier: value })
        }
      },
      maxItems: {
        min: 1,
        max: 100,
        step: 1,
        value: useFilterStore.getState().maxItems,
        label: 'Max Items',
        onChange: (value: number) => {
          useFilterStore.setState({ maxItems: value })
        }
      },
      minDistance: {
        min: 1,
        max: 100,
        step: 1,
        value: useFilterStore.getState().minDistance,
        label: 'Min Distance',
        onChange: (value: number) => {
          useFilterStore.setState({ minDistance: value })
        }
      },
      visibleRadius: {
        min: 1,
        max: 100,
        step: 1,
        value: useFilterStore.getState().visibleRadius,
        label: 'Visible Radius',
        onChange: (value: number) => {
          useFilterStore.setState({ visibleRadius: value })
        }
      }
      // errorIfEmpty: {
      //     value: false,
      //     label: 'Error If Empty',
      // }
    }
  })
}

/* eslint-disable unicorn/filename-case */

import { CommonActions, useLinkTo } from '@react-navigation/native'
import { BVatomPluginType } from '@vatom/BVatom/plugin'
import { RegionType, RootSDKStoreType } from '@vatom/sdk/core'

import { AppRoutes, AppStackParamList, navRefType, RoomRoutes } from '../../navigators'

export interface businessTabsConfig {
  tabsToRender: string[]
}

type logoutOrDisconnect = () => Promise<void>

const SDKHandlers = {
  rootStore: null as RootSDKStoreType | null,
  navigator: null as navRefType | null,
  businessTabsConfig: null as businessTabsConfig | null,
  logoutOrDisconnect: null as logoutOrDisconnect | null,

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pendingNavigation: null as { route: string; params?: any } | null,
  navigateWithPath: null as ReturnType<typeof useLinkTo<AppStackParamList>> | null,
  setLinkTo: (linkTo: ReturnType<typeof useLinkTo<AppStackParamList>>) => {
        SDKHandlers.navigateWithPath = linkTo
  },
  setRootStore: (payload: {
    sdk: RootSDKStoreType
    navigation: navRefType
    businessTabsConfig: businessTabsConfig
    logoutOrDisconnect: () => Promise<void>
  }) => {
    SDKHandlers.rootStore = payload.sdk
    SDKHandlers.navigator = payload.navigation
    SDKHandlers.businessTabsConfig = payload.businessTabsConfig
    SDKHandlers.logoutOrDisconnect = payload.logoutOrDisconnect

    if (SDKHandlers.navigator.isReady() && SDKHandlers.pendingNavigation) {
      SDKHandlers.navigateToTab(SDKHandlers.pendingNavigation)
      SDKHandlers.pendingNavigation = null
    }
  },
  getInventory: () => {
    return SDKHandlers.rootStore?.dataPool.region(RegionType.inventory).tokens
  },
  getTokenById: (id: string) => {
    return SDKHandlers.getInventory()?.find(t => t.id === id)
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  performAction: async (payload: { tokenId: string; actionName: string; actionPayload: any }) => {
    const { tokenId, actionName, actionPayload } = payload
    try {
      let res
      if (SDKHandlers.getTokenById(tokenId)) {
        res = await SDKHandlers.getTokenById(tokenId)?.performAction(actionName, actionPayload, {userProfile:{id: actionPayload.userID}})
      } else {
        res = await SDKHandlers.performPublicAction(actionName, tokenId, actionPayload)
      }
      return JSON.stringify(res || '')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error('performAction error', err)
      return JSON.stringify({ error: err.message })
    }
  },
  combineTokens: async (payload: { thisTokenId: string; otherTokenId: string }) => {
    try {
      const { thisTokenId, otherTokenId } = payload
      const thisToken = SDKHandlers.getTokenById(thisTokenId)
      const otherToken = SDKHandlers.getTokenById(otherTokenId)
      if (thisToken && otherToken) {
        const res = await thisToken.performAction('Combine', otherToken)
        return JSON.stringify(res || '')
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      return JSON.stringify({ error: err.message })
    }
  },
  trashToken: async (payload: { tokenId: string }) => {
    try {
      const res = await SDKHandlers.getTokenById(payload.tokenId)?.performAction('Delete')
      return JSON.stringify(res || '')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      return JSON.stringify({ error: err.message })
    }
  },
  getToken: (payload: { tokenId: string }) => {
    return JSON.stringify(SDKHandlers.getTokenById(payload.tokenId) || {})
  },
  getPublicToken: async (payload: { tokenId: string }) => {
    const bVatomPlugin = SDKHandlers.rootStore?.dataPool.getPlugin('BVatomPlugin') as BVatomPluginType
    const data = await bVatomPlugin!.api.client
      .request('POST', `/v1/user/vatom/get`, { ids: [payload.tokenId] }, true)
      .then(data => {
        return {
          vatom: data.vatoms[0],
          vatomActions: data.actions,
          vatomFaces: data.faces
        }
      })
      .catch(error => {
        console.warn(`Error when getting token with id ${payload.tokenId}`, error)
        return {}
      })
    return JSON.stringify(data)
  },
  listTokens: () => {
    return JSON.stringify(SDKHandlers.getInventory())
  },
  isLoggedIn: () => {
    return SDKHandlers.rootStore?.dataPool.sessionStore.isLoggedIn
  },
  getPublicProfile: async (payload: { userId?: string }) => {
    const userId = payload.userId || SDKHandlers.rootStore?.dataPool.user.user?.sub || ''
    try {
      const res = await SDKHandlers.rootStore?.vatomIncApi.getPublicProfile(userId)
      return JSON.stringify(res || '')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      return JSON.stringify({ error: err.message })
    }
  },
  getCurrentUserPoints: async (payload: { campaignId: string }) => {
    try {
      const usrid = SDKHandlers.rootStore?.dataPool.user.user?.sub

      if (!usrid) {
        return JSON.stringify({ error: 'User not found' })
      }

      const res = await SDKHandlers.rootStore?.vatomIncApi.getUserPoints(usrid, payload.campaignId)
      return JSON.stringify(res || '')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      return JSON.stringify({ error: err.message })
    }
  },
  getCurrentUser: () => {
    // TODO
    const res = SDKHandlers.rootStore?.dataPool.user.userInfo
    return JSON.stringify(res || '')
  },

  getBusinessTabs: () => {
    return JSON.stringify(SDKHandlers.businessTabsConfig?.tabsToRender)
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigateToTab: (payload: { route: string; params?: any }) => {
    const { route, params } = payload
    const { businessId, ...rest } = params || {}

    try {
      if (!SDKHandlers.navigator?.isReady()) {
        SDKHandlers.pendingNavigation = payload
        return
      }

      SDKHandlers.navigator?.navigate(AppRoutes.BusinessProxy, {
        business: businessId,
        screen: route,
        ...(rest || {})
      })
    } catch (error: unknown) {
      console.warn('navigateTo error', error)
      SDKHandlers.pendingNavigation = payload
      return JSON.stringify({ error: (error as Error).message })
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigate: (payload: { route: any; params?: any }) => {
    const { params } = payload
    const { businessId, ...rest } = params || {}

    try {
      SDKHandlers.navigator?.navigate(payload.route, { ...rest })
    } catch (error: unknown) {
      console.warn('navigateTo error', error)
      return JSON.stringify({ error: (error as Error).message })
    }
  },
  openCommunity: (payload: { bussinesId: string; communityId?: string; spaceId?: string }) => {
    SDKHandlers.navigator?.dispatch(
      CommonActions.navigate({
        name: RoomRoutes.Room,
        params: {
          business: payload.bussinesId,
          spaceId: payload.spaceId
        }
      })
    )
  },
  logOut: async () => {
    SDKHandlers.logoutOrDisconnect?.()
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  performPublicAction: async (actionName: string, tokenId: string, actionPayload?: any) => {
    const bVatomPlugin = SDKHandlers.rootStore?.dataPool.getPlugin('BVatomPlugin') as BVatomPluginType
    const analytics = SDKHandlers.rootStore?.analytics

    const data = await bVatomPlugin!.api.client
      .request(
        'POST',
        `/v1/user/vatom/action/${actionName}`,
        {
          'this.id': tokenId,
          ...actionPayload
        },
        true
      )
      .then(data => {
        analytics?.event('performAction', {
          event: 'performAction',
          actionUri: actionName,
          eventValue: 1,
          digitalObjectId: tokenId
        })
        return data
      })
      .catch(error => {
        console.warn(
          `Error when performing public action: ${actionName} in token with id ${tokenId}`,
          error
        )
        return {}
      })
    return JSON.stringify(data)
  },
  navigationISready: () => {
    const isR = SDKHandlers.navigator?.isReady()
    return isR || false
  },
  dispatchNavigationAction: (payload: { action: CommonActions.Action }) => {
    try {
      SDKHandlers.navigator?.dispatch(payload.action)
    } catch (error: unknown) {
      console.warn('dispatchNavigationAction error', error)
      return JSON.stringify({ error: (error as Error).message })
    }
  },
  linkTo: (payload: { url: string }) => {
    if(!SDKHandlers.navigateWithPath) {
      throw new Error('linkTo is not available yet')
    }
    try{
      SDKHandlers.navigateWithPath(
      payload.url
    )
    } catch (error){
      console.error("linkToError", error)
      throw (error)
    }
  }
}

export default SDKHandlers



import React from 'react'

import { FixedBottom } from '../../components/FixedBottom'
import { WebWrapper } from '../../components/WebWrapper'
import { useIsDesktop } from '../../hooks/useIsDesktop'

import {
  SendConfirmation as SendConfirmationComponent,
  SendConfirmationProps
} from './SendConfirmation'

export * from './SendConfirmation'

export const SendConfirmation = (props: SendConfirmationProps) => {
  const isDesktop = useIsDesktop()
  return (
    <WebWrapper
      justifyContent={'center'}
      borderRadius={isDesktop ? 8 : undefined}
      overflow={'hidden'}
      flexGrow={1}
    >
      <FixedBottom>
        <SendConfirmationComponent {...props} />
      </FixedBottom>
    </WebWrapper>
  )
}

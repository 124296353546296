import React, { ReactNode } from 'react'
import { Animated, Modal, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { observer } from 'mobx-react-lite'

import ModalPollIcon from '../../../assets/fab-modal-icons/fab_modal_poll_icon.svg'
import CloseIcon from '../../../assets/header-icons/close-icon.svg'
import { useCommunitiesTheme } from '../../../themes'
import ArrowRightIcon from '../../modal-components/ArrowRightIcon'
import ModalText from '../../modal-components/ModalText'
import {
  closeAnimation,
  ModalAnimatedWrapper,
  ModalGreyBackground,
  ModalHeaderWrapper,
  ModalSaveButton,
  ModalTitle,
  openAnimation
} from '../../modalHelpers'
import { useStoreContext } from '../../MSTContextProvider'
import CheckIcon from '../assets/check_icon.svg'
import EmojiIcon from '../assets/emoji_icon.svg'
import ScoreStyleIcon from '../assets/score_styles_icon.svg'
import StarIcon from '../assets/star_icon_empty.svg'

import { DecorationData } from './ModalDecorations'
import ModalDecorationWrapper from './ModalDecorationWrapper'

const Category = ({ children, style }: { children: ReactNode; style?: object }) => {
  const communitiesTheme = useCommunitiesTheme()

  return (
    <View
      style={{
        borderTopWidth: 1,
        borderTopColor: communitiesTheme.labelBorder,
        paddingLeft: 16,
        ...style
      }}
    >
      {children}
    </View>
  )
}

const CategoryTitle = ({ text }: { text: string }) => {
  const communitiesTheme = useCommunitiesTheme()

  return <Text style={{ ...styles.stylesTitle, color: communitiesTheme.popUp.title }}>{text}</Text>
}

const CheckIconComponent = ({ value, style }: { value: string; style: string }) => {
  const communitiesTheme = useCommunitiesTheme()

  return value === style ? (
    <CheckIcon fill={communitiesTheme.accentColor} style={{ position: 'absolute', right: 16 }} />
  ) : null
}

const ModalScoreStyle = ({
  decorationData,
  messageType
}: {
  decorationData: DecorationData
  messageType?: string
}) => {
  const store = useStoreContext()
  const communitiesTheme = useCommunitiesTheme()
  const [isModalVisible, setIsModalVisible] = React.useState(false)

  React.useEffect(() => {
    store.addAdditionalInputs(decorationData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const scoreStyleInput = store.additionalInputs.find(({ type }) => type === 'score-style')

  const fadeAnim = React.useRef(new Animated.Value(0)).current
  const slideAnim = React.useRef(new Animated.Value(1000)).current

  const animationDuration = 300

  if (!scoreStyleInput) {
    return null
  }

  const scoreStyles: string[] = JSON.parse(scoreStyleInput.additionalInfo).styles
  const scoreStyleValue = scoreStyleInput.value as string

  const openModal = () => {
    setIsModalVisible(true)
    openAnimation(fadeAnim, slideAnim, animationDuration).start()
  }

  const closeModal = () => {
    closeAnimation(fadeAnim, slideAnim, animationDuration).start(() => {
      setIsModalVisible(false)
    })
  }

  const handleStyleChoosing = (style: string) => {
    scoreStyleInput.setValue(style)
    closeModal()
  }

  const pollStyles = scoreStyles.filter(style => style === 'single-choice-poll')
  const likertStyles = scoreStyles.filter(style => style.includes('numbered'))
  const analogStyles = scoreStyles.filter(
    style => !style.includes('numbered') && style !== 'single-choice-poll'
  )

  return (
    <>
      <ModalDecorationWrapper handler={() => openModal()}>
        <ScoreStyleIcon
          width="20"
          style={{ marginRight: 12 }}
          fill={communitiesTheme.labelIconColor}
        />
        <ModalText text={decorationData.placeholder} />
        <View style={{ marginLeft: 'auto', flexDirection: 'row', alignItems: 'center' }}>
          <Text
            style={{
              fontSize: 13,
              lineHeight: 18,
              color: communitiesTheme.labelIconColor,
              textTransform: 'capitalize'
            }}
          >
            {scoreStyleValue.replace('numbered-', '')}
          </Text>
          <ArrowRightIcon style={{ marginLeft: 12 }} />
        </View>
      </ModalDecorationWrapper>

      <Modal visible={isModalVisible} transparent={true} animationType="none">
        <ModalGreyBackground fadeAnim={fadeAnim} handler={closeModal} />

        <ModalAnimatedWrapper slideAnim={slideAnim} style={{ paddingTop: 34, paddingBottom: 12 }}>
          <ModalHeaderWrapper style={{ paddingBottom: 12 }}>
            <TouchableOpacity accessibilityRole="button" onPress={() => closeModal()}>
              <CloseIcon fill={communitiesTheme.confirmationModal.title} width={20} height={20} />
            </TouchableOpacity>

            <ModalTitle placeholder={decorationData.placeholder} />

            <ModalSaveButton handler={closeModal} text="Done" />
          </ModalHeaderWrapper>

          <View key="content" style={{ paddingVertical: 16 }}>
            {pollStyles.length > 0 ? (
              <Category style={{ borderTopWidth: 0 }}>
                <CategoryTitle text="BASIC" />

                {pollStyles.map((style: string) => {
                  return (
                    <ModalDecorationWrapper handler={() => handleStyleChoosing(style)}>
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <ModalPollIcon
                          style={{ marginRight: 11 }}
                          height={16}
                          fill={communitiesTheme.text}
                        />
                        <ModalText text={style} style={{ textTransform: 'capitalize' }} />
                      </View>

                      <CheckIconComponent value={scoreStyleValue} style={style} />
                    </ModalDecorationWrapper>
                  )
                })}
              </Category>
            ) : null}

            {likertStyles.length > 0 ? (
              <Category>
                <CategoryTitle text="LIKERT SCALE" />

                {likertStyles.map((style: string) => {
                  return (
                    <ModalDecorationWrapper handler={() => handleStyleChoosing(style)}>
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={[styles.hashtag, { color: communitiesTheme.labelIconColor }]}>
                          #
                        </Text>
                        <ModalText
                          text={style.replace('numbered-', '')}
                          style={{ textTransform: 'capitalize' }}
                        />
                      </View>

                      <CheckIconComponent value={scoreStyleValue} style={style} />
                    </ModalDecorationWrapper>
                  )
                })}
              </Category>
            ) : null}

            {analogStyles.length > 0 ? (
              <Category>
                <CategoryTitle text="VISUAL ANALOG SCALE" />
                <View>
                  {analogStyles.map((style: string) => {
                    return (
                      <ModalDecorationWrapper handler={() => handleStyleChoosing(style)}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          {style === 'stars' ? (
                            <StarIcon
                              style={{ marginRight: 11 }}
                              fill={communitiesTheme.labelIconColor}
                            />
                          ) : null}
                          {style === 'emojies' ? (
                            <EmojiIcon
                              style={{ marginRight: 11 }}
                              fill={communitiesTheme.labelIconColor}
                            />
                          ) : null}
                          <ModalText text={style} style={{ textTransform: 'capitalize' }} />
                        </View>

                        <CheckIconComponent value={scoreStyleValue} style={style} />
                      </ModalDecorationWrapper>
                    )
                  })}
                </View>
              </Category>
            ) : null}
          </View>
        </ModalAnimatedWrapper>
      </Modal>
    </>
  )
}

export default observer(ModalScoreStyle)

const styles = StyleSheet.create({
  stylesTitle: {
    fontSize: 11,
    fontWeight: '600',
    lineHeight: 15,
    paddingVertical: 12,
    textTransform: 'uppercase'
  },
  hashtag: {
    fontSize: 16,
    fontWeight: '300',
    marginRight: 13
  }
})

import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const MapMarkerIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 16 16" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M8.58398 14.3203C9.9043 12.6699 12.875 8.73438 12.875 6.5C12.875 3.80859 10.6914 1.625 8 1.625C5.30859 1.625 3.125 3.80859 3.125 6.5C3.125 8.73438 6.0957 12.6699 7.39062 14.3203C7.69531 14.7012 8.2793 14.7012 8.58398 14.3203ZM8 8.125C7.08594 8.125 6.375 7.41406 6.375 6.5C6.375 5.61133 7.08594 4.875 8 4.875C8.88867 4.875 9.625 5.61133 9.625 6.5C9.625 7.41406 8.88867 8.125 8 8.125Z"
    />
  </Icon>
)

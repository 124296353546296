import React, { useState } from 'react'
import { Keyboard, TouchableWithoutFeedback } from 'react-native'
import { Button, theme } from '@vatom/wombo'
import { Actionsheet, Box, Divider, ScrollView, Text } from 'native-base'

import { Title } from '../../../components/Title'
import { useMaxWidth } from '../../../components/WebWrapper'

interface ActionSheetConfirmTransferProps {
  setOpen: (b: boolean) => void
  isOpen: boolean
  toAddress: string
  nftName: string
  onSend: () => void
}

export const ActionSheetConfirmTransfer = ({
  isOpen,
  setOpen,
  toAddress,
  nftName,
  onSend
}: ActionSheetConfirmTransferProps) => {
  const maxWidth = useMaxWidth()

  const [isLoading, setIsLoading] = useState(false)

  if (!isOpen) {
    return null
  }

  const onPress = async () => {
    setIsLoading(true)
    onSend()
  }

  const onClose = () => {
    setIsLoading(false)
    setOpen(false)
  }

  return (
    <Actionsheet
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={maxWidth}
      margin={'auto'}
      useRNModal={true}
      // @ts-ignore prop doesn't exit on types
      _backdropFade={{
        entryDelay: 150,
        exitDelay: 10
      }}
    >
      <Actionsheet.Content
        pt="20px"
        pb="16px"
        px={0}
        _light={{
          backgroundColor: theme.colors.white
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[900]
        }}
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
          <ScrollView w="100%">
            <Divider
              _light={{
                backgroundColor: theme.colors.grayCool[300]
              }}
              _dark={{
                backgroundColor: theme.colors.grayDarkMode[700]
              }}
              height="1px"
            />
            <Divider
              _light={{
                backgroundColor: theme.colors.grayCool[300]
              }}
              _dark={{
                backgroundColor: theme.colors.grayDarkMode[700]
              }}
              height="1px"
              marginBottom="20px"
            />

            <Text
              fontFamily="Inter-SemiBold"
              fontSize={17}
              _dark={{
                color: theme.colors.textDarkMode[100]
              }}
              _light={{
                color: theme.colors.textLightMode[900]
              }}
              // color={theme.colors.grayCool[900]}
              fontWeight="600"
              textAlign="center"
            >
              Verify the full address
            </Text>

            <Box marginTop={3} w="100%" p={4}>
              <Text
                w="100%"
                marginBottom={4}
                fontFamily="Inter-Regular"
                fontSize="15px"
                textAlign="center"
                color="#3F4A55"
                _light={{
                  color: theme.colors.textLightMode[900]
                }}
                _dark={{
                  color: theme.colors.textDarkMode[100]
                }}
              >
                You're about to send {nftName}, please ensure the entire address is correct.
              </Text>
            </Box>

            <Box
              _light={{
                bgColor: theme.colors.grayCool[200]
              }}
              _dark={{
                bgColor: theme.colors.grayCool[900]
              }}
              alignContent="center"
              justifyContent="center"
              alignItems="center"
            >
              <Text
                fontSize={20}
                textAlign="center"
                noOfLines={2}
                p="20px"
                _light={{
                  color: theme.colors.black
                }}
                _dark={{
                  color: theme.colors.textDarkMode[300]
                }}
                fontFamily="Inter-Bold"
              >
                {toAddress}
              </Text>
            </Box>

            <Button
              isDisabled={isLoading}
              onPress={onPress}
              isLoading={isLoading}
              opacity={isLoading ? 0.7 : 1}
              marginX={4}
              mt={4}
              _light={{
                backgroundColor: theme.colors.systemColorsLight.orange
              }}
              _dark={{
                backgroundColor: theme.colors.systemColorsDark.orange
              }}
            >
              <Title preset="h5" variant="semibold" paddingY={1}>
                Yes, looks good
              </Title>
            </Button>

            <Button onPress={onClose} marginX={4} mt={4} colorScheme="gray">
              <Title preset="h5" variant="semibold" paddingY={1}>
                Cancel
              </Title>
            </Button>
          </ScrollView>
        </TouchableWithoutFeedback>
      </Actionsheet.Content>
    </Actionsheet>
  )
}
export default ActionSheetConfirmTransfer

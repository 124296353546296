import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { useConfig } from '@vatom/sdk/react'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { GradientButton, Text, theme } from '@vatom/wombo'
import { Box, Image } from 'native-base'

import { AppRoutes } from '../../navigators'

const ClaimNFT = () => {
  // const navigation = useNavigation()
  // const { config, isEmbedded } = useVatomWalletSdkStore()
  const config = useConfig()

  const emptyStateImage =
    config.features.screensConfig?.Wallet?.emptyStateImage || require('./assets/claim.png')

  const emptyStateTitle = config.features.screensConfig?.Wallet?.emptyStateTitle

  const emptyStateMessage = config.features.screensConfig?.Wallet?.emptyStateMessage

  const ImageComponent = () => {
    return (
      <Image
        accessibilityIgnoresInvertColors={true}
        alt="claim"
        h={250}
        w={250}
        resizeMode="contain"
        source={emptyStateImage}
      />
    )
  }

  return (
    <Box flex={1} marginTop={20} justifyContent="flex-start" alignItems="center">
      <ImageComponent />
      <Text
        color="black"
        fontFamily="Inter-SemiBold"
        fontSize={16}
        fontWeight="600"
        mb={2.5}
        mt={10}
        textAlign="center"
        _dark={{
          color: theme.colors.textDarkMode[100]
        }}
      >
        {emptyStateTitle}
      </Text>
      <Text
        color="gray.500"
        fontFamily="Inter-Regular"
        fontSize={13}
        fontWeight="400"
        mb={10}
        textAlign="center"
      >
        {/* Looks like your wallet is empty. Add your wallet address to show your NFTs */}
        {emptyStateMessage}
      </Text>
    </Box>
  )
}
export default ClaimNFT

import React from 'react'
import { Image, LayoutChangeEvent, StyleSheet, Text, View } from 'react-native'
import Animated, {
  Extrapolation,
  interpolate,
  SharedValue,
  useAnimatedStyle,
  useSharedValue
} from 'react-native-reanimated'
import { observer } from 'mobx-react-lite'

import { useLayoutScrollHandler } from '../../../../hooks/useLayoutScrollHandler'
import { mxcToHttp } from '../../helpers'
import { useCommunitiesTheme } from '../../themes'
import { useRoomContext } from '../RoomProvider'

import RoomFilters from './RoomFilters'
import RoomJoinButton from './RoomJoinButton'
import RoomTags from './RoomTags'
import RoomTopButtons from './RoomTopButtons'

type RoomHeaderProps = {
  filter: string
  setFilter: (f: string) => void
  headerHeight: SharedValue<number>
  hideBackButton?: boolean
}
const RoomHeader = ({ filter, setFilter, headerHeight, hideBackButton }: RoomHeaderProps) => {
  const room = useRoomContext()
  const { avatar_url, name } = room

  const communitiesTheme = useCommunitiesTheme()

  const [topButtonsHeight, setTopButtonsHeight] = React.useState(0)
  const roomInfoHeightDefault = 200
  const roomInfoHeight = useSharedValue(roomInfoHeightDefault)

  const { lastContentOffset: scrollOffsetY } = useLayoutScrollHandler()

  const headerBackdropAnimatedStyle = useAnimatedStyle(() => {
    const height = interpolate(scrollOffsetY.value, [0, 60], [60, 0], Extrapolation.CLAMP)
    return {
      height
    }
  })

  const roomInfoAnimatedStyle = useAnimatedStyle(() => {
    const translate = interpolate(scrollOffsetY.value, [0, 100], [0, 100], Extrapolation.CLAMP)
    const height = interpolate(
      scrollOffsetY.value,
      [0, 100],
      [roomInfoHeight.value, 0],
      Extrapolation.CLAMP
    )
    const opacity = interpolate(scrollOffsetY.value, [0, 100], [1, 0], Extrapolation.CLAMP)
    return {
      height: height,
      transform: [
        {
          translateY: translate
        }
      ],
      opacity: opacity
    }
  }, [])

  const roomAvatarUrl = avatar_url ? mxcToHttp(avatar_url, 'matrix.api.vatominc.com') : avatar_url

  return (
    <Animated.View
      style={{
        backgroundColor: communitiesTheme.background,
        overflow: 'hidden'
      }}
      onLayout={event => {
        const height = event.nativeEvent.layout.height
        headerHeight.value = height
      }}
    >
      <Animated.View
        onLayout={event => {
          const height = event.nativeEvent.layout.height
          setTopButtonsHeight(height)
        }}
      >
        <RoomTopButtons hideBackButton={hideBackButton} />
      </Animated.View>
      <Animated.View
        style={[
          {
            backgroundColor: communitiesTheme.header.background,
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
            width: '100%',
            position: 'absolute',
            top: topButtonsHeight - 2
          },
          headerBackdropAnimatedStyle
        ]}
      />
      <Animated.View style={roomInfoAnimatedStyle}>
        <View
          style={{
            paddingHorizontal: 16
          }}
        >
          <Image
            accessibilityIgnoresInvertColors
            source={{ uri: roomAvatarUrl }}
            style={[styles.roomAvatar, { borderColor: communitiesTheme.roomAvatarBorder }]}
          />
          <Text style={[styles.roomName, { color: communitiesTheme.roomTitle }]}>{name}</Text>
          <RoomTags />
          <View
            onLayout={(event: LayoutChangeEvent) => {
              const height = event.nativeEvent.layout.height
              if (height === 0) {
                roomInfoHeight.value = roomInfoHeightDefault
                return
              }
              roomInfoHeight.value = roomInfoHeight.value + height
            }}
          >
            <RoomJoinButton />
          </View>
        </View>
      </Animated.View>

      <RoomFilters setFilter={setFilter} filter={filter} />
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  roomName: {
    fontSize: 20,
    marginTop: 15,
    alignSelf: 'center',
    fontWeight: 'bold'
  },
  roomAvatar: {
    height: 100,
    width: 100,
    borderRadius: 12,
    borderWidth: 6,
    marginTop: 10,
    alignSelf: 'center'
  }
})

export default observer(RoomHeader)

import { useState } from 'react'
import { useGetSearchSpaces, useJoinRoom } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { Button, Text, theme } from '@vatom/wombo'
import { Box } from 'native-base'
import { useDebounce } from 'use-debounce'

import Alert from '../../../components/Alert'
import { DropdownSelect } from '../../../components/DropdownSelect'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'

export const AddSpace = () => {
  const { isDark } = useBusinessTheme()
  const [isLoading, setIsLoading] = useState(false)
  const joinRoomMutation = useJoinRoom()

  const [searchString, setSearchString] = useState('')
  const [lastSearchString] = useDebounce(searchString, 250)
  const spaces = useGetSearchSpaces(
    {
      select: result => {
        return result.items
      },
      enabled: lastSearchString.length > 2
    },
    {
      q: lastSearchString,
      sort_by: 'alias.asc',
      limit: 5
    }
  )

  const joinSpace = async () => {
    setIsLoading(true)
    try {
      const res = await joinRoomMutation.mutateAsync(searchString)
      if (res) {
        setSearchString('')
      }
    } catch (error) {
      Alert.showError(error)
    }
    setIsLoading(false)
  }

  return (
    <Box
      mb="8px"
      px="16px"
      h="40px"
      _dark={{
        backgroundColor: theme.colors.grayCool[900]
      }}
      _light={{
        backgroundColor: theme.colors.white
      }}
      w="100%"
      alignItems="center"
      flexDir={'row'}
      zIndex={9999}
      mt={2}
    >
      <DropdownSelect
        mainContainerProps={{ w: '80%', mr: '4px' }}
        inputProps={{
          isDisabled: isLoading,
          autoCapitalize: 'none',
          placeholder: translate('spaceScreen.spaceAlias'),
          w: '100%',
          borderColor: isDark ? 'grayDarkMode.500' : 'grayCool.300',
          borderWidth: 1,
          fontSize: 15,
          fontWeight: 600,
          letterSpacing: 0,
          focusOutlineColor: isDark ? theme.colors.grayDarkMode[400] : theme.colors.grayCool[500],
          leftElement: (
            <Box
              borderRightWidth={1}
              h="100%"
              borderColor={isDark ? 'grayDarkMode.500' : 'grayCool.300'}
              p={2}
              justifyContent="center"
            >
              <Text fontSize={15} fontWeight={600} letterSpacing={0} color="grayCool.600">
                @
              </Text>
            </Box>
          )
        }}
        keyExtractor={item => `spaces-search-result-${item.id}`}
        getLabel={result => result.alias}
        data={spaces?.data ?? []}
        renderItem={result => (
          <Text
            noOfLines={1}
            fontSize={15}
            p={2}
            color={isDark ? 'grayCool.300' : 'grayCool.700'}
            ellipsizeMode="tail"
          >
            @{result.item.alias} ({result.item.displayName})
          </Text>
        )}
        searchString={searchString}
        setSearchString={setSearchString}
      />
      <Button
        w="20%"
        isDisabled={isLoading || !searchString}
        bgColor={isLoading || !searchString ? 'grayCool.200' : 'brand.500'}
        onPress={joinSpace}
        padding={2}
      >
        <Text
          fontWeight={600}
          fontSize={15}
          alignSelf="center"
          letterSpacing={0}
          height="100%"
          color={isLoading || !searchString ? 'grayCool.500' : 'white'}
        >
          {isLoading ? 'Joining' : 'Join'}
        </Text>
      </Button>
    </Box>
  )
}

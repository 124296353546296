/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
import React, { memo, useMemo } from 'react'
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useGetSpace, useMatrixUser } from '@vatom/sdk/react'
import { IEvent } from 'matrix-js-sdk'
import { observer } from 'mobx-react-lite'

import { useBusinessSelector } from '../../../../hooks/useBusinessSelector'
import { AppNavigation, navigateToConnectScreen, RoomRoutes } from '../../../../navigators'
import { getAdditionalInfo, transformedMessageData } from '../../helpers'
import {
  useMessage,
  useMessageFacades,
  useMessageHide,
  usePlugins,
  useRoomPowerLevels
} from '../../queries'
import { useCommunitiesTheme } from '../../themes'
import { useCommunitySpace } from '../../useCommunitySpace'

import Badge from './chat-components/Badge'
import Header from './chat-components/Header'
import PostHiddenTag from './chat-components/PostHiddenTag'
import ScoringTimeInfoLabel from './chat-components/ScoringTimeInfoLabel'
import ThreadRootInfo from './chat-components/ThreadRootInfo'
import Content from './Content'
import Decorations from './Decorations'
import { MessageProvider } from './MessageContext'

type ChatBubbleProps = {
  matrixMessage: IEvent
  spaceId: string
  isThread: boolean
}

const ChatBubbleHidden = ({
  roomId,
  sender,
  eventID,
  children
}: {
  roomId: string
  sender: string
  eventID: string
  children: React.ReactNode
}) => {
  const { data: matrixUser } = useMatrixUser()
  const { data: messageHidden } = useMessageHide(roomId, eventID)
  const isHidden = messageHidden?.chunk[0]?.content.isHidden

  const { data: powerLevels } = useRoomPowerLevels(roomId)
  let currentUserPowerLevel = 0
  if (matrixUser) {
    currentUserPowerLevel = powerLevels?.users[matrixUser?.user_id] ?? 0
  }

  const isDisplayed = currentUserPowerLevel >= 90 || matrixUser?.user_id === sender

  return (
    <View
      style={{
        ...styles.container,
        display: isHidden && !isDisplayed ? 'none' : 'flex'
      }}
    >
      {children}
    </View>
  )
}

const ChatBubble = ({ matrixMessage: message, isThread, spaceId }: ChatBubbleProps) => {
  const { business } = useBusinessSelector()
  const businessId = business?.id ?? ''
  const communitiesTheme = useCommunitiesTheme()
  const { community } = useCommunitySpace()
  const space = useGetSpace(spaceId, {
    enabled: false
  })
  const roomId = space.data?.matrixRoomId ?? ''

  const plugins = usePlugins()
  const firstPlugin = plugins.data ? plugins.data?.[0] : null

  const messageTypes = firstPlugin?.descriptor.facades.message.map((msg: any) => msg.id)
  const facades = useMessageFacades(messageTypes)
  const navigation = useNavigation<AppNavigation>()

  const { data: messagee } = useMessage(roomId, message.event_id)

  const matrixMessage = useMemo(() => {
    if (!messagee) {
      return null
    }
    return transformedMessageData(messagee)
  }, [messagee])

  const messageFacadeElements = useMemo(() => {
    if (!matrixMessage) {
      return null
    }
    const { eventType } = matrixMessage
    const messageFacade = facades.data?.find(
      (facade: { type: string }) => facade.type === eventType
    )
    return messageFacade?.elements
  }, [facades.data, matrixMessage])

  const { messageContentElements, additionalInfo, parsedBody } = useMemo(() => {
    if (!matrixMessage) {
      return {
        messageContentElements: [],
        additionalInfo: {},
        parsedBody: null
      }
    }
    if (
      typeof matrixMessage.content === 'string' &&
      // sorry mom
      !matrixMessage.content.startsWith('{') &&
      !matrixMessage.content.startsWith('[')
    ) {
      return {
        messageContentElements: [{ type: 'text', content: matrixMessage.content }],
        additionalInfo: {},
        parsedBody: null
      }
    }

    try {
      const parsedBody = JSON.parse(matrixMessage.content)
      const messageContentElements = parsedBody.elements
      const additionalInfo = getAdditionalInfo(parsedBody)
      return { messageContentElements, additionalInfo, parsedBody }
    } catch (e) {
      console.error('Error parsing message content', e, matrixMessage.content)
      return {
        messageContentElements: [{ type: 'text', content: matrixMessage.content }],
        additionalInfo: {},
        parsedBody: null
      }
    }
  }, [matrixMessage])

  //

  const facadeElements = () => {
    return messageFacadeElements?.map((facadeElements: any) => {
      switch (facadeElements.type) {
        case 'header':
          return (
            <Header
              key={`header-${message.event_id}`}
              isThread={isThread}
              decorations={facadeElements.decorations}
              spaceId={spaceId}
            />
          )
        case 'badge':
          return (
            matrixMessage && (
              <Badge type={matrixMessage.eventType} key={`badge-${message.event_id}`} />
            )
          )
        case 'content':
          return (
            <View
              key={`contentWrapper-${message.event_id}`}
              style={{
                marginLeft: facadeElements.align === 'indent' ? (isThread ? 0 : 55) : 0
              }}
            >
              {parsedBody && matrixMessage ? (
                <ScoringTimeInfoLabel
                  key={`content-scoring-${message.event_id}`}
                  parsedBody={parsedBody}
                  eventType={matrixMessage.eventType}
                  isThread={isThread}
                />
              ) : null}

              <PostHiddenTag key={`hidden-tag-${message.event_id}`} roomId={roomId} />
              <Content
                key={`content-${message.event_id}`}
                facadeElements={facadeElements}
                messageContentElements={messageContentElements}
                isThread={isThread}
                additionalInfo={additionalInfo}
                spaceId={spaceId}
              />
            </View>
          )
        case 'decorations':
          return (
            matrixMessage && (
              <Decorations
                key={`decorations-${message.event_id}`}
                matrixMessage={matrixMessage}
                spaceId={spaceId}
                additionalInfo={additionalInfo}
                isThread={isThread}
                facadeElements={facadeElements}
              />
            )
          )
        default:
          return null
      }
    })
  }

  if (!messageFacadeElements || !matrixMessage || !messagee) {
    return null
  }

  const onPress = () => {
    navigateToConnectScreen(navigation, RoomRoutes.RoomThread, {
      spaceId,
      community,
      business: businessId,
      messageId: matrixMessage.eventId
    })
  }

  return (
    <>
      <TouchableWithoutFeedback
        accessibilityRole="button"
        style={{ backgroundColor: communitiesTheme.background }}
        onPress={() => (!isThread ? onPress : null)}
      >
        <View>
          <ChatBubbleHidden
            roomId={roomId}
            sender={matrixMessage.sender}
            eventID={message.event_id}
          >
            <MessageProvider messageEventID={message.event_id}>{facadeElements()}</MessageProvider>
          </ChatBubbleHidden>
        </View>
      </TouchableWithoutFeedback>
      {isThread ? (
        <ThreadRootInfo
          key={`thread-info-${message.event_id}`}
          matrixMessage={matrixMessage}
          roomId={roomId}
        />
      ) : null}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingTop: 8,
    paddingBottom: 24
  }
})

export default memo(observer(ChatBubble))

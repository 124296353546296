/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { NftModel, NftModelType } from "./NftModel"
import { NftModelSelector } from "./NftModel.base"
import { RootStoreType } from "./index"


/**
 * NftActivityBase
 * auto generated base class for the model NftActivityModel.
 */
export const NftActivityModelBase = ModelBase
  .named('NftActivity')
  .props({
    __typename: types.optional(types.literal("NftActivity"), "NftActivity"),
    address: types.union(types.undefined, types.string),
    metadata: types.union(types.undefined, types.frozen()),
    auctionHouse: types.union(types.undefined, types.string),
    price: types.union(types.undefined, types.frozen()),
    createdAt: types.union(types.undefined, types.frozen()),
    wallets: types.union(types.undefined, types.array(types.string)),
    activityType: types.union(types.undefined, types.string),
    nft: types.union(types.undefined, types.null, types.late((): any => NftModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class NftActivityModelSelector extends QueryBuilder {
  get address() { return this.__attr(`address`) }
  get metadata() { return this.__attr(`metadata`) }
  get auctionHouse() { return this.__attr(`auctionHouse`) }
  get price() { return this.__attr(`price`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get wallets() { return this.__attr(`wallets`) }
  get activityType() { return this.__attr(`activityType`) }
  nft(builder: string | NftModelSelector | ((selector: NftModelSelector) => NftModelSelector) | undefined) { return this.__child(`nft`, NftModelSelector, builder) }
}
export function selectFromNftActivity() {
  return new NftActivityModelSelector()
}

export const nftActivityModelPrimitives = selectFromNftActivity().address.metadata.auctionHouse.price.createdAt.wallets.activityType

import { useState } from 'react'
import { FlatList, InteractionManager, Keyboard } from 'react-native'
import { Box, IBoxProps, IInputProps, Input, Pressable } from 'native-base'

import { useBusinessTheme } from '../../hooks/useBusinessTheme'

type DropdownSelectProps<T> = {
  searchString: string
  setSearchString: React.Dispatch<React.SetStateAction<string>>
  renderItem: ({ item }: { item: T }) => JSX.Element
  data: T[]
  getLabel: (item: T) => string
  keyExtractor: (item: T) => string
  onSelected?: (item: T) => void
  inputProps?: IInputProps
  resultContainerProps?: IBoxProps
  mainContainerProps?: IBoxProps
}

export function DropdownSelect<T = unknown>({
  searchString,
  setSearchString,
  renderItem,
  data,
  getLabel,
  keyExtractor,
  onSelected,
  inputProps,
  resultContainerProps,
  mainContainerProps
}: DropdownSelectProps<T>) {
  const { isDark } = useBusinessTheme()
  const [hideResults, setHideResults] = useState(false) //useState(true)
  const onChangeText = (text: string) => {
    setHideResults(false)
    setSearchString(text)
  }

  const onSelect = (item: T) => {
    const text = getLabel(item)
    setSearchString(text)
    setHideResults(true)
    onSelected?.(item)
    InteractionManager.runAfterInteractions(Keyboard.dismiss)
  }

  return (
    <Box w={'100%'} bgColor={isDark ? 'grayDarkMode.800' : 'white'} {...mainContainerProps}>
      <Input onChangeText={onChangeText} value={searchString} {...inputProps} />
      <Box
        width="100%"
        top={'42px'}
        bgColor={isDark ? 'grayDarkMode.800' : 'white'}
        {...resultContainerProps}
        position="absolute"
        display={hideResults || searchString.length < 3 ? 'none' : 'flex'}
        shadow={2}
      >
        <FlatList
          data={data}
          keyboardShouldPersistTaps={'always'}
          renderItem={result => {
            return (
              <Pressable accessibilityRole="button" onPress={() => onSelect(result.item)}>
                {({ isHovered }) => {
                  const bgColor = isDark
                    ? isHovered
                      ? 'grayDarkMode.700'
                      : 'grayDarkMode.800'
                    : isHovered
                    ? 'grayCool.50'
                    : 'white'
                  return (
                    <Box width="100%" bgColor={bgColor}>
                      {renderItem(result)}
                    </Box>
                  )
                }}
              </Pressable>
            )
          }}
          keyExtractor={keyExtractor}
        />
      </Box>
    </Box>
  )
}

import React, { useRef } from 'react'
import { Platform } from 'react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import Animated from 'react-native-reanimated'
import { translate } from '@vatom/utils'
import { theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { useColorModeValue, VStack } from 'native-base'

import { ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'

import { CustodialWallets } from './partials/CustodialWallets'
import { Divider } from './partials/Divider'
import { SelfCustodialWallets } from './partials/SelfCustodial'
import { WatchingWallets } from './partials/Watching'

export const BlockChainWallets = observer(() => {
  const { pageTheme } = useBusinessTheme()
  const scrollRef = useRef<Animated.ScrollView | null>(null)

  const headerBorderColor = useColorModeValue(
    theme.colors.grayCool[300],
    theme.colors.grayDarkMode[700]
  )
  const headerIconColor = useColorModeValue(
    theme.colors.textLightMode[900],
    theme.colors.textDarkMode[100]
  )

  return (
    <ScreenWrapper
      screenBackgroundColor={pageTheme.background}
      statusBarBackgroundColor={pageTheme.background}
      viewContainerStyle={{
        width: '100%',
        backgroundColor: pageTheme.background
      }}
      // unsafe
    >
      <ScreenHeader
        headerLeftType={'back'}
        headerTextColor={headerIconColor}
        headerProps={{
          borderBottomColor: headerBorderColor,
          borderBottomWidth: 1,
          overflow: 'hidden'
        }}
        centerContainerProps={{
          flex: 1,
          paddingX: 2
        }}
        leftContainerProps={{
          flex: 0,
          _web: {
            flexGrow: 0,
            flexShrink: 1,
            flexBasis: 'auto'
          }
        }}
        rightContainerProps={{
          flex: 0,
          _web: {
            flexGrow: 0,
            flexShrink: 1,
            flexBasis: 'auto'
          }
        }}
        headerRight={() => <ScreenHeaderButton />}
      >
        <Title preset="h5" variant="semibold" numberOfLines={1}>
          Blockchain Wallets
        </Title>
      </ScreenHeader>
      <GestureHandlerRootView>
        <Animated.ScrollView
          ref={scrollRef}
          style={{
            paddingHorizontal: 16,
            marginTop: 12
          }}
          contentContainerStyle={{
            paddingBottom: 60 // headerHeight
          }}
        >
          {Platform.OS === 'ios' ? <SelfCustodialWallets /> : null}

          <WatchingWallets />

          <Divider marginTop={0} />

          <VStack flexGrow={1} marginTop={3}>
            <Title preset="h3" variant="bold">
              {translate('profileScreen.custodial')}
            </Title>
            <CustodialWallets />
          </VStack>
        </Animated.ScrollView>
      </GestureHandlerRootView>
    </ScreenWrapper>
  )
})

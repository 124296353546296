import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'

import { useCommunitiesTheme } from '../themes'

import StarIcon from './AddModals/assets/star_icon.svg'

const ScoreItemWrapper = ({
  children,
  style,
  isItemVoted
}: {
  children: React.ReactNode
  style?: object
  isItemVoted?: boolean
}) => {
  const communitiesTheme = useCommunitiesTheme()

  return (
    <View
      style={{
        ...styles(communitiesTheme).circle,
        ...styles(communitiesTheme).bigCircle,
        ...(isItemVoted ? styles(communitiesTheme).activeChoice : null),
        ...style
      }}
    >
      {children}
    </View>
  )
}

const ScoreItem = ({ item, type }: { item: number | string; type: string }) => {
  const communitiesTheme = useCommunitiesTheme()

  return (
    <Text
      style={{
        ...styles(communitiesTheme).circleText,
        ...(type === 'number'
          ? styles(communitiesTheme).bigCircleText
          : styles(communitiesTheme).bigEmojie)
      }}
    >
      {item}
    </Text>
  )
}

export const NumberedStyle = (props: any) => {
  const { fromZero, length, isInChatBubble, handleVote, pollVotes, userId } = props

  const firstStyle = fromZero
    ? Array.from(Array(length + 1).keys())
    : Array.from({ length }, (_, i) => i + 1)

  return (
    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
      {firstStyle.map(item => {
        let userVote = null
        if (isInChatBubble && pollVotes.length > 0) {
          userVote = pollVotes.find((pollVote: any) => pollVote.sender === userId)
        }

        const isItemVoted = userVote ? userVote.index === item : false

        return (
          <View key={item}>
            {isInChatBubble ? (
              <TouchableOpacity
                accessibilityRole="button"
                onPress={() => handleVote({ index: item })}
              >
                <ScoreItemWrapper isItemVoted={isItemVoted}>
                  <ScoreItem item={item} type="number" />
                </ScoreItemWrapper>
              </TouchableOpacity>
            ) : (
              <ScoreItemWrapper>
                <ScoreItem item={item} type="number" />
              </ScoreItemWrapper>
            )}
          </View>
        )
      })}
    </View>
  )
}

export const EmojiStyle = (props: any) => {
  const { isInChatBubble, handleVote, pollVotes, userId } = props
  const emojies = ['😡', '😣', '😕', '😄', '😍']

  const communitiesTheme = useCommunitiesTheme()

  return (
    <View style={{ flexDirection: 'row' }}>
      {emojies.map((item, index) => {
        let userVote = null
        if (isInChatBubble && pollVotes.length > 0) {
          userVote = pollVotes.find((pollVote: any) => pollVote.sender === userId)
        }

        const isItemVoted = userVote ? userVote.index === index + 1 : false

        return (
          <View key={item}>
            {isInChatBubble ? (
              <TouchableOpacity
                accessibilityRole="button"
                onPress={() => handleVote({ index: index + 1 })}
              >
                <ScoreItemWrapper isItemVoted={isItemVoted}>
                  <ScoreItem item={item} type="emojie" />
                </ScoreItemWrapper>
              </TouchableOpacity>
            ) : (
              <ScoreItemWrapper>
                <Text style={styles(communitiesTheme).bigEmojie}>{item}</Text>
              </ScoreItemWrapper>
            )}
          </View>
        )
      })}
    </View>
  )
}

export const StarStyle = (props: any) => {
  const { isInChatBubble, handleVote, pollVotes, userId } = props
  const firstStyle = Array.from({ length: 5 }, (_, i) => i + 1)
  const communitiesTheme = useCommunitiesTheme()

  return (
    <View style={{ flexDirection: 'row' }}>
      {firstStyle.map(item => {
        let userVote = null
        let isActiveIndex = 0
        if (isInChatBubble && pollVotes.length > 0) {
          userVote = pollVotes.find((pollVote: any) => pollVote.sender === userId)
          isActiveIndex = userVote ? userVote.index : 0
        }

        return (
          <View key={item}>
            {isInChatBubble ? (
              <TouchableOpacity
                accessibilityRole="button"
                style={{ marginRight: 21, marginBottom: 8 }}
                onPress={() => handleVote({ index: item })}
              >
                <StarIcon
                  fill={
                    item <= isActiveIndex
                      ? communitiesTheme.stars.starColorActive
                      : communitiesTheme.stars.starColor
                  }
                  height={24}
                />
              </TouchableOpacity>
            ) : (
              <View style={{ marginRight: 13 }}>
                <StarIcon fill={'#CED4DA'} height={24} />
              </View>
            )}
          </View>
        )
      })}
    </View>
  )
}

const styles = (communitiesTheme?: any) =>
  StyleSheet.create({
    circle: {
      borderRadius: 100,
      borderWidth: 1,
      height: 24,
      width: 24,
      borderColor: communitiesTheme.score.borderColor,
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 2
    },
    bigCircle: {
      width: 40,
      height: 40,
      marginRight: 8,
      marginBottom: 8
    },
    bigEmojie: {
      fontSize: 28,
      lineHeight: 40,
      textAlign: 'center'
    },
    activeChoice: {
      borderWidth: 2,
      borderColor: communitiesTheme.score.borderColorActive
    },
    circleText: {
      fontSize: 11,
      lineHeight: 15,
      fontWeight: '600',
      color: communitiesTheme.score.textColor
    },
    bigCircleText: {
      fontSize: 17,
      lineHeight: 24,
      fontWeight: '700',
      color: communitiesTheme.score.textColor
    }
  })

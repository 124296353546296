import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

try {
  if (Platform.OS === 'web') {
    const url: URL = new URL(window.location.href)
    if (url.pathname.includes('/redirect')) {
      const params: URLSearchParams = url.searchParams
      const destination = params.get('destination')

      if (destination) {
        const destinationUrl: URL = new URL(destination)
        const destinationParams: URLSearchParams = destinationUrl.searchParams
        const context = destinationParams.get('context')

        if (context) {
          AsyncStorage.setItem('REDIRECT_CONTEXT', context).then(() => {
            window.location.replace(destination)
          })
        }
      }
    }
  }
} catch (error) {
  console.error(error)
}

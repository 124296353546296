/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import React, {
  ComponentProps,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react'
import { Platform } from 'react-native'
import { OneSignal } from 'react-native-onesignal'
import {
  DarkTheme,
  DefaultTheme,
  getPathFromState,
  LinkingOptions,
  NavigationContainer,
  PathConfigMap
} from '@react-navigation/native'
import { useBusiness, useConfig, useInitialQsStore, useIsAuthed, useSDK } from '@vatom/sdk/react'
import {
  AppRoutes,
  AppStackParamList,
  PrivateScreensConfig,
  PublicScreensConfig,
  screenConfigNonWeb
} from '@vatom/wallet-routes'
import { theme } from '@vatom/wombo'
import * as Linking from 'expo-linking'
import { observer } from 'mobx-react-lite'
import qs from 'qs'

import { useAnalytics } from '../../hooks/useAnalytics'
import { useBusinessThemeConfig } from '../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../hooks/useIsDesktop'
import { useWasVisitedStore } from '../../hooks/useLastVisitedRoute'
import { navigationPendingStore } from '../../hooks/useNavigationPending'
import logger from '../../logger'
import { getInitialNavigationState } from '../getInitialNavigationState'
import { navigationRef } from '../NavigationUtilities'

import { AppStack } from './AppStack'
/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */

interface NavigationProps extends Partial<React.ComponentProps<typeof NavigationContainer>> {
  initialUrl: string | undefined
}

export const AppNavigator = observer((props: PropsWithChildren<NavigationProps>) => {
  const { initialUrl, onStateChange, ...rest } = props
  console.log('LOG: > AppNavigator > initialUrl:', initialUrl)
  const isAuthed = useIsAuthed()
  const initialRouteName = isAuthed ? AppRoutes.home : undefined
  const sdk = useSDK()
  const config = useConfig()
  // const store = useStore()

  const businessInPath = window?.location?.pathname?.split('/b/')[1]?.split('/')[0] ?? null
  const { data: business } = useBusiness({
    business: businessInPath,
    businessId: businessInPath
  })

  const [isNavigationReady, setNavigationReady] = useState(false)
  const routeNameRef = useRef<string>()
  const analytics = useAnalytics()
  const isDesktop = useIsDesktop()

  const { isDark } = useBusinessThemeConfig({
    pageConfig: business?.pageConfig,
    brandConfig: business?.brandConfig
  })
  const overrideDefaultTheme = useMemo(
    () => ({
      ...DefaultTheme,
      colors: {
        ...DefaultTheme,
        background: isDesktop ? 'transparent' : theme.colors.grayCool[50]
      }
    }),
    [isDesktop]
  )
  const colorScheme = useMemo(
    () => (isDark ? DarkTheme : overrideDefaultTheme),
    [overrideDefaultTheme, isDark]
  )

  logger.info('[AppNavigator.render] initialUrl', initialUrl)

  // https://reactnavigation.org/docs/configuring-links
  const linking: LinkingOptions<Partial<AppStackParamList>> = {
    prefixes: [
      /* your linking prefixes */
      Platform.select({
        web: 'http://localhost',
        ios: 'com.vatomcorp.viewer',
        android: 'com.vatom.viewer'
      }) || 'http://localhost',
      'com.vatom',
      Linking.createURL('/'),
      'https://wallet.vatom.com',
      'wallet.vatom.com://',
      'spaces.vatom.com://',
      'https://spaces.vatom.com',
      'https://wallet.localhost:3000',
      'https://wallet.vatominc.com/',
      'https://wow.vatom.com'
    ],
    /* configuration for matching screens with paths */
    config: {
      initialRouteName: initialRouteName,
      screens: getScreensConfig(isAuthed)
    },
    getStateFromPath: (path, options) => {
      const state = getInitialNavigationState(path, options, isAuthed)
      console.log('LOG: > AppNavigator > state:', state)
      useWasVisitedStore.setState({ isDeeplink: true })
      return state
    },
    async getInitialURL() {
      // As a fallback, you may want to do the default deep link handling
      const url = await Linking.getInitialURL()
      return url
    },
    getPathFromState(state, opts) {
      const path = getPathFromState(state, opts)
      console.log('getPathFromState', path, opts)
      const initialQsFromStore = useInitialQsStore.getState().initialQS
      /**
        We either grap the persisted query params if they exist or the query params from the initial
        URL (mostly for the login flow that leaves the app) this will cleaned up after we pass the 
        params to the experience
       */
      const qsToUse = initialQsFromStore ? initialQsFromStore : initialUrl?.split('?')[1]

      const qsParamsFromInitialUrlOrStore = qs.parse(qsToUse ?? '')
      // Merge the query params from the initial URL with the query params from the path
      const qsParamsFromPath = qs.parse(path.split('?')[1] ?? '')

      const qsParams = { ...qsParamsFromInitialUrlOrStore, ...qsParamsFromPath }
      const qsString = qs.stringify(qsParams)
      useInitialQsStore.setState({
        qsForExperienceSDK: qsString
      })
      if (!qsString) {
        return path
      }

      const basePath = path.split('?')[0]

      return `${basePath}?${qsString}`
    },
    subscribe(listener) {
      const onReceiveURL = ({ url }: { url: string }) => listener(url)

      // Listen to incoming links from deep linking
      const subscription = Linking.addEventListener('url', e => {
        const { url } = e
        console.log('Linking.addEventListener url:', url)
        onReceiveURL({ url })
      })

      // DEBUG: Test deep linking
      // setTimeout(() => {
      //   console.log('Testing deep linking...')
      //   listener(
      //     'https://wallet.vatom.com/b/sahMOa1qQR/c/!zwiZXiEStuihuAqVzB%3Avatom.com/room/!DVHVGCqtgIjZeXnzcI%3Avatom.com'
      //   )
      // }, 2000)

      // Listen to OneSignal push notifications when the app is in the foreground
      OneSignal.Notifications.addEventListener('foregroundWillDisplay', event => {
        console.log('One signal foregroundWillDisplay', event)
        event.preventDefault()
        // some async work

        // Use display() to display the notification after some async work
        event.getNotification().display()
      })
      // Listen to OneSignal push notifications when they are opened
      OneSignal.Notifications.addEventListener('click', openedEvent => {
        console.log('useOneSignalOpenHandler notification opened:', openedEvent)
        const launchUrl = openedEvent.notification?.launchURL
        console.log('useOneSignalOpenHandler launchUrl:', launchUrl)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const additionalData: any = openedEvent.notification?.additionalData
        console.log('useOneSignalOpenHandler additionalData:', additionalData)
        const deepLink = additionalData?.deepLink
        console.log('useOneSignalOpenHandler deepLink:', deepLink)

        if (launchUrl) {
          // store.linking.setLinkingUrl(launchUrl)
          listener(launchUrl)
        } else if (deepLink) {
          // store.linking.setLinkingUrl(deepLink)
          listener(deepLink)
        }
      })

      return () => {
        // Clean up the event listeners
        subscription.remove()
      }
    }
  }

  const onNavigationReady = useCallback(async () => {
    navigationPendingStore.getState().setNavigationIsReady(true)
    // console.log('[Linking. AppNavigator.onNavigationReady] isAuthed', isAuthed)
    // if (!isNavigationReady) {
    //   setNavigationReady(true)
    //   if (isAuthed && initialUrl) {
    //     // INFO: Deep link was use. clear rootStore > linking ??
    //     // store.linking.clearLinkingUrl()
    //     await openDeepLink()
    //   }
    // }
    // // Set route name
    // if (navigationRef) {
    //   const route = navigationRef.getCurrentRoute()
    //   if (route?.name) {
    //     routeNameRef.current = route.name
    //   }
    // }
  }, [])

  const onNavigationStateChange: NonNullable<
    ComponentProps<typeof NavigationContainer>['onStateChange']
  > = state => {
    if (!state) {
      console.warn('onNavigationStateChange state is undefined')
      return
    }
    analytics.navigationStateSubscriber(state)
  }

  return (
    <NavigationContainer
      linking={linking}
      documentTitle={{
        formatter: () => config.appTitle,
        enabled: true
      }}
      onReady={onNavigationReady}
      // onStateChange={onStateChange}

      onStateChange={onNavigationStateChange}
      // documentTitle={{
      // enabled: false
      // formatter: (options, route) => options?.title ?? route?.name
      // }}
      ref={navigationRef}
      // NOTE: We don't currently support dark mode. This will change the background color of the navigator in a way that looks like a bug.
      // theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}

      // @ts-expect-error not sure how to fix this. First idea was the Stylesheet.create but wasn't able to make it work
      theme={colorScheme}
      // theme={DefaultTheme}
      {...rest}
    >
      {props.children}
    </NavigationContainer>
  )
})

export function getScreensConfig(isAuthed: boolean) {
  const screensConfigCommon = isAuthed ? PrivateScreensConfig : PublicScreensConfig

  const screenConfig = Platform.select({
    web: {
      ...screensConfigCommon
    },
    // @ts-expect-error
    native: {
      ...screensConfigCommon,
      ...screenConfigNonWeb
    }
  }) as PathConfigMap<Partial<AppStackParamList>>

  return screenConfig
}

export * from '@vatom/wallet-routes'
export { AppStack }

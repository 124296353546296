import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { useSDK } from '../../../SDKProvider'
import { getConfig } from '../appConfig'

import {
  BusinessSpaces,
  SearchSpacesParams,
  SpaceInfo,
  UseGetBusinessSpacesOptions,
  UseGetSearchSpacesOptions,
  UseSpaceOptions
} from './types'

const spacesHost = 'https://events.api.vatominc.com'

export const spacesQueryKeys = {
  space: [{ scope: 'space' }] as const,
  businessSpaces: [{ scope: 'business-spaces' }] as const,
  searchSpaces: [{ scope: 'search-spaces' }] as const,
  getSpace: (appId: string, vatomToken: string, spaceId: string) =>
    [{ ...spacesQueryKeys.space[0], appId, vatomToken, spaceId }] as const,
  getBusinessSpaces: (
    appId: string,
    vatomToken: string,
    businessId: string,
    communityId?: string
  ) =>
    [{ ...spacesQueryKeys.businessSpaces[0], appId, vatomToken, businessId, communityId }] as const,
  getSearchSpaces: (appId: string, vatomToken: string, params?: SearchSpacesParams) =>
    [{ ...spacesQueryKeys.searchSpaces[0], appId, vatomToken, params }] as const
}

const fetchSpace = async ({
  queryKey: [{ appId, vatomToken, spaceId }]
}: QueryFunctionContext<ReturnType<typeof spacesQueryKeys['getSpace']>>) => {
  const res = await fetch(`${spacesHost}/spaces/${spaceId}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${vatomToken}`,
      'App-Id': appId
    })
  })

  const data = await res.json()
  return data
}

const fetchBusinessSpaces = async ({
  queryKey: [{ appId, vatomToken, businessId, communityId }]
}: QueryFunctionContext<ReturnType<typeof spacesQueryKeys['getBusinessSpaces']>>) => {
  const res = await fetch(`${spacesHost}/b/${businessId}/spaces?communityId=${communityId}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${vatomToken}`,
      'App-Id': appId
    })
  })

  const data = await res.json()
  return data
}

const fetchSearchSpaces = async ({
  queryKey: [{ appId, vatomToken, params }]
}: QueryFunctionContext<ReturnType<typeof spacesQueryKeys['getSearchSpaces']>>) => {
  const queryParams = new URLSearchParams({
    ...params,
    limit: params?.limit ? String(params?.limit) : '10'
  })
  const res = await fetch(`${spacesHost}/spaces?${queryParams.toString()}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${vatomToken}`,
      'App-Id': appId
    })
  })

  const data = await res.json()
  return data
}

export const useGetSpace = <T = SpaceInfo>(spaceId: string, options?: UseSpaceOptions<T>) => {
  const sdk = useSDK()
  const accessToken = sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken ?? ''
  const appId = getConfig().appID

  const query = useQuery({
    queryKey: spacesQueryKeys.getSpace(appId, accessToken, spaceId),
    queryFn: fetchSpace,
    enabled: !!accessToken && !!appId,
    ...options
  })

  return query
}

export const useGetBusinessSpaces = <T = BusinessSpaces>(
  businessId: string,
  communityId?: string,
  options?: UseGetBusinessSpacesOptions<T>
) => {
  const sdk = useSDK()
  const accessToken = sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken ?? ''
  const appId = getConfig().appID

  const query = useQuery({
    queryKey: spacesQueryKeys.getBusinessSpaces(appId, accessToken, businessId, communityId),
    queryFn: fetchBusinessSpaces,
    enabled: !!accessToken && !!appId,
    ...options
  })

  return query
}

export const useGetSearchSpaces = <T = BusinessSpaces>(
  options?: UseGetSearchSpacesOptions<T>,
  searchParams?: SearchSpacesParams
) => {
  const sdk = useSDK()
  const accessToken = sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken ?? ''
  const appId = getConfig().appID

  const query = useQuery({
    queryKey: spacesQueryKeys.getSearchSpaces(appId, accessToken, searchParams),
    queryFn: fetchSearchSpaces,
    enabled: !!accessToken && !!appId,
    ...options
  })

  return query
}

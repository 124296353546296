import React from 'react'
import { Box, Select } from 'native-base'

import { countries, regions } from './countries'
import Error from './Error'
import LabelForm from './Label'
import { fieldPropsInterfaceItem } from './Types'

export default function CountryAndRegionField(props: fieldPropsInterfaceItem) {
  const country = props.value?.country
  const region = props.value?.region
  const regs = regions[countries.find((c: any) => c.name === country)?.code] ?? []

  return (
    <Box>
      <LabelForm {...props} title="Country" />
      <Select
        selectedValue={country ?? ''}
        w="100%"
        accessibilityLabel={props.field.name}
        accessibilityHint={props.field.name}
        borderWidth="1"
        borderColor="rgb(196, 196, 196)"
        borderRadius="4px"
        onValueChange={itemValue =>
          props.onChange(props.field.name, { ...props.value, country: itemValue })
        }
      >
        {countries.map((c: any) => (
          <Select.Item label={c.name} value={c.name} key={c.code} />
        ))}
      </Select>

      <LabelForm {...props} title="State" />
      <Select
        selectedValue={region ?? ''}
        w="100%"
        accessibilityLabel={props.field.name}
        accessibilityHint={props.field.name}
        borderWidth="1"
        borderColor="rgb(196, 196, 196)"
        borderRadius="4px"
        onValueChange={itemValue =>
          props.onChange(props.field.name, { ...props.value, region: itemValue })
        }
      >
        {regs.map((c: any) => (
          <Select.Item label={c} value={c} key={c} />
        ))}
      </Select>

      <Error {...props} />
    </Box>
  )
}

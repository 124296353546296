import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { TextStyle, useWindowDimensions } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { AntDesign } from '@expo/vector-icons'
import { useIsFocused } from '@react-navigation/native'
import { useQueryClient } from '@tanstack/react-query'
import {
  BVatomTokenType,
  Color,
  getColor,
  isVatom,
  SimpleARGameConfig,
  TextContent
} from '@vatom/BVatom/plugin'
import { CompositeRegion, RegionType } from '@vatom/sdk/core'
import {
  CampaignPoints,
  getPickUpPointsForObjectDefinitionId,
  isVatomEphemeral,
  useAnalytics,
  useInventory,
  usePointsOptimisticUpdate,
  useSDK,
  useTokenInventory,
  useUser,
  vatomQueryKeys
} from '@vatom/sdk/react'
import { Button, LoaderView } from '@vatom/wombo'
import { useControls } from 'leva'
import { observer } from 'mobx-react-lite'
import mustache from 'mustache'
import { Box, Icon, IconButton, Image, Text } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import Alert from '../../../../components/Alert'
import { WebWrapper } from '../../../../components/WebWrapper'
import { XR8Canvas } from '../../../../components/XR8Canvas'
import { useBusinessSelector } from '../../../../hooks/useBusinessSelector'
import { AppRoutes } from '../../../../navigators'
import { getBoundingBoxFromRegion } from '../../../../utils/map'
import { useFirstTimeStore } from '../../../Home/hooks/useFirstTimeStore'
import PickUpModal from '../../../Maps/partials/PickUpModal'
import { useFilterStore } from '../../hooks/useFilterStore'
import { useLocationStore } from '../../hooks/useLocationStore'
import {
  borderStyle,
  dimensionStyle,
  fontFamilyStyle,
  fontSizeStyle,
  nudgeStyle,
  rgbaStyle
} from '../../hooks/useStyleConverters'
import { ARGameHeader } from '../../partials/ARGameHeader'
import { ARGameProps } from '../../partials/ARGameProps'
import { CardState } from '../../partials/CardStateModal'
import { CompassHUD } from '../../partials/CompassHUD'
import { GeoModel } from '../../partials/Model'
import { SceneWrapper } from '../../partials/SceneWrapper'

import {
  getTargetTokenId,
  useGameOverview,
  useGamePoints,
  useGameRules,
  useSortedTokenStore,
  useTokenPositions,
  useTokenSorter
} from './utils'
import { ViewIncomingVatomModal } from './ViewTokenModal'

type CollectionV1GameConfig = SimpleARGameConfig & {
  openAR?: boolean

  showViewTokenModal: boolean
  viewTokenModalTitle: TextContent
  viewTokenModalDescription: TextContent
  viewTokenModalButtonColor: Color
  showPickUpModal: boolean
  pickupModalButtonColor: Color
  showOverlay: boolean
}

export const CollectionGameV1 = observer<ARGameProps>(props => {
  const { route, onClose, navigation } = props
  const { tokenId } = route.params
  const { businessIdentifier } = useBusinessSelector()
  const user = useUser()
  const insets = useSafeAreaInsets()
  const { token } = useTokenInventory(tokenId)
  const queryClient = useQueryClient()

  const vatom = token && isVatom(token) ? token : null
  if (!vatom || !vatom.gameType) {
    throw new Error('Vatom token required for playing an ar game')
  }

  const { height } = useWindowDimensions()

  const focused = useIsFocused()

  const gameConfig = vatom?.gameConfig as CollectionV1GameConfig

  const campaignPoints = useGamePoints(vatom)
  const channel = vatom.userPoints?.channel

  const pointsFromChannel =
    channel && campaignPoints?.data?.[channel]
      ? campaignPoints?.data?.[channel]
      : campaignPoints?.data?.['total']

  const totalPoints = pointsFromChannel ? pointsFromChannel : 0

  const rules = useGameRules(vatom)
  const overview = useGameOverview(vatom)
  const filter = vatom.private['ar-filter-v2']

  const [pointsToAdd, setPointsToAdd] = useState({
    points: 0,
    channel: 'total'
  })

  console.log('points and rules', {
    campaignPoints,
    rules: rules.data,
    private: vatom.private,
    overview: overview.data,
    gameConfig
  })

  const firstTimeKey = `ar-game-${vatom.id}`
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(
    navigation && !!gameConfig.openAR && useFirstTimeStore().getIsFirstTime(firstTimeKey)
  )
  const isGameOver = !vatom.userPoints?.maxPoints
    ? false
    : vatom.userPoints?.maxPoints <= totalPoints
  const [isGameOverModalOpen, setIsGameOverModalOpen] = useState(isGameOver)

  const [isAROpen, setIsAROpen] = useState(
    (route.params.openAR || !!gameConfig.openAR) && !isGameOver
  )
  const [isViewTokenModalOpen, setIsViewTokenModalOpen] = useState(false)
  const [tokenForPickupConfirmation, setTokenForPickupConfirmation] = useState<BVatomTokenType>()
  const analytics = useAnalytics()
  const [openModal, setOpenModal] = useState(false)

  const onSelectToken = useThrottledCallback((token: BVatomTokenType) => {
    setOpenModal(true)
    setTokenForPickupConfirmation(token)
  }, 300)

  const onCloseModal = useThrottledCallback(() => {
    setOpenModal(false)
    setTokenForPickupConfirmation(undefined)
  }, 300)

  const { location, tokens: allGeoRegionTokens } = useTokensInRadius(40, vatom, !!isAROpen)
  const { tokens: inventoryTokens } = useInventory()

  const [tokenIdToListenForNavigation, setTokenIdToListenForNavigation] = useState<string>()
  const incommingToken = tokenIdToListenForNavigation
    ? (inventoryTokens?.find(
        t => t.studioInfo?.objectDefinitionId === tokenIdToListenForNavigation
      ) as BVatomTokenType)
    : undefined

  const tokens = useTokenPositions(
    vatom,
    allGeoRegionTokens as BVatomTokenType[],
    inventoryTokens as BVatomTokenType[],
    location
  )

  const handleOptimisticPickup = usePointsOptimisticUpdate(vatom.studioInfo?.campaignId, rules.data)

  useEffect(() => {
    if (filter) {
      useFilterStore.setState({
        ...filter,
        minDistance: 5,
        distanceMultiplier: 1
      })
    }
  }, [filter])

  useEffect(() => {
    if (tokenForPickupConfirmation && incommingToken) {
      setIsViewTokenModalOpen(true)
    }
  }, [incommingToken, tokenForPickupConfirmation])

  const onGameOver = useCallback(
    (points: number) => {
      analytics.event(
        'gameEnd',
        {
          value: points
        },
        vatom
      )

      setIsAROpen(false)
      setIsGameOverModalOpen(true)
    },
    [analytics, vatom]
  )

  const gameOverButtonClick = () => {
    onClose()
  }

  useEffect(() => {
    if (isGameOverModalOpen) {
      return
    }
    if (!vatom.userPoints?.maxPoints || !totalPoints) {
      return
    }

    console.log(
      'points for channel',
      totalPoints,
      vatom.userPoints?.maxPoints,
      vatom.userPoints?.maxPoints <= totalPoints
    )

    if (vatom.userPoints?.maxPoints <= totalPoints) {
      onGameOver(totalPoints)
    }
  }, [isGameOverModalOpen, onGameOver, totalPoints, vatom.userPoints?.maxPoints])

  // useEffect(() => {
  //   console.log('location', location)
  //   if (location && !initialized.current) {
  //     initialized.current = true
  //     // const qty = (vatom.userPoints?.maxPoints ?? 0) - points

  //     vatom.performAction('varius.action:varius.io:open-ar-view-v1', {
  //       'this.id': vatom.id,
  //       'geo.pos': {
  //         Lon: location?.coords.longitude,
  //         Lat: location?.coords.latitude
  //         // qty
  //       }
  //     })
  //   }
  // }, [location, vatom])

  const onButtonPress = () => {
    setIsWarningModalOpen(true)
  }

  const startGame = () => {
    analytics.event('gameStart', {}, vatom)
    setIsWarningModalOpen(false)
    setIsAROpen(true)
  }

  const onWarningModalButtonPress = () => {
    useFirstTimeStore.getState().setWasOpened(firstTimeKey)
    startGame()
  }

  const navigateToTokenDetail = (tokenId: string) => {
    if (businessIdentifier) {
      navigation.replace(AppRoutes.NFTDetail_Business, {
        tokenId: tokenId,
        business: businessIdentifier
      })
    } else {
      navigation.replace(AppRoutes.NFTDetail, {
        tokenId: tokenId
      })
    }
  }

  const onPickUp = (actionType?: string, response?: any) => {
    console.log('onPickUp', response)
    if (!tokenForPickupConfirmation) {
      console.error('No token for pickup confirmation')
      throw new Error('No token for pickup confirmation')
    }
    if (actionType === 'Dispense') {
      const post = response?.post?.[0]
      if (!post) {
        console.error('No post action')
        return
      }
      const output = post.output?.output
      if (!output) {
        console.error('No post action output')
        return
      }
      const points = output.points
      if (!points) {
        console.error('No post action output points')
        return
      }

      setPointsToAdd(response.post[0].output.output.points.updates[0])
      setTokenIdToListenForNavigation(response.post[0].output.output.vatoms[0].id)

      queryClient.setQueryData<CampaignPoints>(
        vatomQueryKeys.getCampaignPoints(vatom.studioInfo?.campaignId, user?.sub),
        currentPoints => {
          return points.aggregate
        }
      )
      onCloseModal()
    } else {
      handleOptimisticPickup(tokenForPickupConfirmation)
      if (gameConfig.showViewTokenModal) {
        setOpenModal(false)
        setIsViewTokenModalOpen(true)
      } else {
        onCloseModal()
      }
    }
  }

  const onTokenClick = useCallback(
    (clickedVatom: BVatomTokenType) => {
      const shouldShowPickUpModal =
        gameConfig.showPickUpModal &&
        !!clickedVatom.studioInfo?.objectDefinitionId &&
        overview.data &&
        !isVatomEphemeral(clickedVatom.studioInfo?.objectDefinitionId, overview.data)

      if (shouldShowPickUpModal) {
        // setTokenForPickupConfirmation(vatom)
        onSelectToken(clickedVatom)
      } else {
        handleOptimisticPickup(clickedVatom)
        analytics.event(
          'initiateAction',
          {
            actionUri: 'Pickup',
            source: 'ARGame',
            location: {
              longitude: location?.coords?.longitude,
              latitude: location?.coords?.latitude
            }
          },
          clickedVatom
        )
        clickedVatom
          .performAction('Pickup')
          .then(() => {
            analytics.event(
              'performAction',
              {
                event: 'performAction',
                actionUri: 'Pickup',
                eventValue: 1
              },
              clickedVatom
            )
          })
          .catch(error => {
            Alert.showError(error)
          })
      }
    },
    [
      analytics,
      gameConfig.showPickUpModal,
      handleOptimisticPickup,
      location?.coords?.latitude,
      location?.coords?.longitude,
      onSelectToken,
      overview.data
    ]
  )

  const handleBackButton = useThrottledCallback(() => {
    // setIsAROpen(false)
    analytics.event(
      'gameAbort',
      {
        value: totalPoints ?? 0
      },
      vatom
    )
    onClose()
  }, 300)

  if (!focused) return null

  if (!vatom || !gameConfig) {
    return <LoaderView />
  }
  const backgroundImage = 'backgroundImage' in gameConfig && gameConfig.backgroundImage

  const buttonStyle = gameConfig.buttonStyle
  const countTextStyle = gameConfig.countTextStyle

  let previewOrEndImage = ''

  if (gameConfig.finalStartDate?.toggled && new Date() < new Date(gameConfig.finalStartDate.text)) {
    previewOrEndImage = gameConfig.finalImage?.ref ?? ''
  }

  if (gameConfig.previewEndDate?.toggled && new Date() < new Date(gameConfig.previewEndDate.text)) {
    previewOrEndImage = gameConfig.previewImage?.ref ?? ''
  }

  const countText = mustache.render(gameConfig.countText, {
    count: totalPoints,
    points:
      channel && campaignPoints?.data?.[channel]
        ? campaignPoints?.data
        : { total: totalPoints, [channel ?? 'total']: totalPoints },
    [channel ?? 'total']: totalPoints
  })

  const pointsEarned =
    tokenForPickupConfirmation?.studioInfo?.objectDefinitionId && rules.data
      ? getPickUpPointsForObjectDefinitionId(
          tokenForPickupConfirmation?.studioInfo?.objectDefinitionId,
          rules.data
        ).points
      : pointsToAdd.points

  const viewPickedModalDescription =
    (rules.data &&
      tokenForPickupConfirmation &&
      tokenForPickupConfirmation?.studioInfo?.objectDefinitionId) ||
    (pointsToAdd && incommingToken && incommingToken?.studioInfo?.objectDefinitionId)
      ? mustache.render(
          //  mustache requires triple {{{}}} for escaping html
          gameConfig.viewTokenModalDescription.text.replace(/\{\{([^}]*)\}\}/g, '{{{$1}}}'),
          {
            token: {
              name: tokenForPickupConfirmation?.metadata.name ?? incommingToken?.metadata.name,
              pointsEarned: pointsEarned
            },
            points:
              channel && campaignPoints?.data?.[channel]
                ? campaignPoints?.data
                : { total: totalPoints, [channel ?? 'total']: totalPoints },
            [channel ?? 'total']: totalPoints
          }
        )
      : ''

  if (isAROpen) {
    return (
      <WebWrapper
        style={{
          position: 'absolute',
          height: '100%',
          overflow: 'hidden',
          backgroundColor: 'white',
          zIndex: 900
        }}
      >
        <Box
          style={{
            display: 'flex',
            flex: 1,
            width: '100%'
          }}
        >
          {gameConfig.showOverlay ? (
            <ARGameHeader
              onClose={handleBackButton}
              gameConfig={gameConfig}
              campaignPoints={{
                ...campaignPoints.data,
                [channel ?? 'total']: totalPoints
              }}
            />
          ) : (
            <>
              <IconButton
                borderRadius={'full'}
                position="absolute"
                top={`${insets.top || 20}px`}
                left={`16px`}
                opacity="0.7"
                backgroundColor={'black'}
                icon={<Icon color="white" as={AntDesign} size="24px" name="close" />}
                onPress={handleBackButton}
                zIndex={1001}
                _hover={{
                  bg: 'transparent'
                }}
                _pressed={{
                  bg: 'transparent',
                  _icon: {
                    name: 'close'
                  },
                  _ios: {
                    _icon: {
                      size: '2xl'
                    }
                  }
                }}
                _ios={{
                  _icon: {
                    size: '2xl'
                  }
                }}
              />
              <Text
                fontWeight={'600'}
                color="#313131"
                height="44px"
                fontSize={'2xl'}
                display={'flex'}
                alignItems={'center'}
                position="absolute"
                top={`${insets.top || 20}px`}
                right={`16px`}
              >
                {countText}
              </Text>
              {/* <Text
                fontWeight={'600'}
                color="#313131"
                height="44px"
                fontSize={'2xl'}
                display={'flex'}
                alignItems={'center'}
                position="absolute"
                top={`${insets.top || 20}px`}
                right={`16px`}
              >
                {location?.coords.altitude} {location?.coords.heading} {location?.coords.longitude}
              </Text> */}
            </>
          )}
          <ErrorBoundary
            onError={(error, info) => {
              console.error('ARGAme ErrorBoundary', error, info)
            }}
            fallbackRender={({ error, resetErrorBoundary }) => {
              return (
                <div role="alert">
                  <p>Something went wrong</p>
                </div>
              )
            }}
          >
            <XR8Canvas top={gameConfig.showOverlay ? gameConfig.overlayHeight.value : 0}>
              {location?.coords && tokens.length > 0 ? (
                <Game tokens={tokens} onTokenClick={onTokenClick} />
              ) : null}
            </XR8Canvas>
          </ErrorBoundary>

          {location?.coords && tokens.length > 0 ? <CompassHUD /> : null}
          <PickUpModal
            source="ARGame"
            fakePickup={true}
            buttonProps={{
              backgroundColor: gameConfig.pickupModalButtonColor
                ? getColor(gameConfig.pickupModalButtonColor)
                : 'primary.500'
            }}
            userLocation={location?.coords}
            token={isViewTokenModalOpen ? undefined : tokenForPickupConfirmation}
            onPickUp={onPickUp}
            onCancel={onCloseModal}
            isOpen={openModal}
          />
          <ViewIncomingVatomModal
            isOpen={isViewTokenModalOpen || !!incommingToken}
            token={incommingToken ?? tokenForPickupConfirmation}
            buttonColor={gameConfig.viewTokenModalButtonColor}
            title={gameConfig.viewTokenModalTitle}
            descriprion={viewPickedModalDescription}
            onCancel={() => {
              setIsViewTokenModalOpen(false)
              setTokenForPickupConfirmation(undefined)
              setTokenIdToListenForNavigation(undefined)
            }}
            onViewPress={() => {
              const token = incommingToken ?? tokenForPickupConfirmation
              if (!token) {
                console.warn('No token to view')
                return
              }

              navigateToTokenDetail(getTargetTokenId(token))
              setIsViewTokenModalOpen(false)
              setTokenForPickupConfirmation(undefined)
              setTokenIdToListenForNavigation(undefined)
            }}
          />
        </Box>
        <CardState
          isOpen={isWarningModalOpen}
          onButtonPress={onWarningModalButtonPress}
          content={gameConfig.warning}
          contentNudge={gameConfig.warningNudge}
          contentStyle={gameConfig.warningStyle}
          buttonText={gameConfig.warningButton}
          buttonNudge={gameConfig.warningButtonNudge}
          buttonStyle={gameConfig.warningButtonStyle}
          icon={gameConfig.warningIcon}
          bgColor={gameConfig.modalBackgroundColor}
          closeColor={gameConfig.modalCloseColor}
          onClosePress={() => {
            useFirstTimeStore.getState().setWasOpened(firstTimeKey)
            setIsWarningModalOpen(false)
          }}
        />
      </WebWrapper>
    )
  }

  return (
    <>
      {previewOrEndImage ? (
        <Box flex={1}>
          <Image
            position={'absolute'}
            alt={'Preview or Ended'}
            w={'100%'}
            h={'100%'}
            resizeMode="cover"
            accessibilityIgnoresInvertColors
            source={{ uri: previewOrEndImage }}
          />
        </Box>
      ) : (
        <Box style={{ position: 'relative', height: height - 34 }}>
          {backgroundImage && (
            <Image
              position={'absolute'}
              alt={vatom.metadata.name}
              w={'100%'}
              h={'100%'}
              resizeMode="cover"
              accessibilityIgnoresInvertColors
              source={{ uri: backgroundImage.ref }}
            />
          )}

          <Box
            // @ts-expect-error
            style={{
              flex: 1,
              width: '100%',
              height: dimensionStyle(countTextStyle.height),
              ...nudgeStyle(gameConfig.countTextNudge, true)
            }}
          >
            <Box
              style={{
                flex: 1,
                width: '100%',
                ...nudgeStyle(gameConfig.countTextNudge, true)
              }}
            >
              <Text
                // @ts-expect-error
                style={{
                  display: 'flex',
                  textAlign: 'center',
                  color: rgbaStyle(countTextStyle.color),
                  backgroundColor: rgbaStyle(countTextStyle.backgroundColor),
                  fontFamily: fontFamilyStyle(countTextStyle.font.family),
                  fontWeight: countTextStyle.font.weight as TextStyle['fontWeight'],
                  borderRadius: countTextStyle.borderRadius.value,
                  height: dimensionStyle(countTextStyle.height),
                  width: dimensionStyle(countTextStyle.width),
                  fontSize: fontSizeStyle(countTextStyle.font.size) as TextStyle['fontSize'],
                  ...borderStyle(countTextStyle.borderPosition, countTextStyle.color),
                  ...nudgeStyle(gameConfig.countTextNudge),
                  position: 'relative'
                }}
              >
                {countText}
              </Text>
            </Box>
          </Box>

          <Button
            onPressIn={onButtonPress}
            // @ts-expect-error
            style={{
              height: dimensionStyle(buttonStyle.height),
              width: dimensionStyle(buttonStyle.width),
              padding: dimensionStyle(buttonStyle.padding),
              borderRadius: buttonStyle.borderRadius.value,
              backgroundColor: rgbaStyle(buttonStyle.backgroundColor),
              alignSelf: 'center',
              ...borderStyle(buttonStyle.borderPosition, buttonStyle.color),
              ...nudgeStyle(gameConfig.buttonNudge, true)
            }}
          >
            <Text
              style={{
                fontFamily: fontFamilyStyle(buttonStyle.font.family),
                color: rgbaStyle(buttonStyle.color),
                fontSize: fontSizeStyle(buttonStyle.font.size) as TextStyle['fontSize'],
                fontWeight: buttonStyle.font.weight as TextStyle['fontWeight']
              }}
            >
              {gameConfig.buttonText}
            </Text>
          </Button>
        </Box>
      )}

      {isAROpen ? (
        <CardState
          isOpen={isWarningModalOpen}
          onButtonPress={onWarningModalButtonPress}
          content={gameConfig.warning}
          contentNudge={gameConfig.warningNudge}
          contentStyle={gameConfig.warningStyle}
          buttonText={gameConfig.warningButton}
          buttonNudge={gameConfig.warningButtonNudge}
          buttonStyle={gameConfig.warningButtonStyle}
          icon={gameConfig.warningIcon}
          bgColor={gameConfig.modalBackgroundColor}
          closeColor={gameConfig.modalCloseColor}
          onClosePress={() => setIsWarningModalOpen(false)}
        />
      ) : null}
      <CardState
        isOpen={isWarningModalOpen}
        onButtonPress={onWarningModalButtonPress}
        content={gameConfig.warning}
        contentNudge={gameConfig.warningNudge}
        contentStyle={gameConfig.warningStyle}
        buttonText={gameConfig.warningButton}
        buttonNudge={gameConfig.warningButtonNudge}
        buttonStyle={gameConfig.warningButtonStyle}
        icon={gameConfig.warningIcon}
        bgColor={gameConfig.modalBackgroundColor}
        closeColor={gameConfig.modalCloseColor}
        onClosePress={() => {
          useFirstTimeStore.getState().setWasOpened(firstTimeKey)
          setIsWarningModalOpen(false)
        }}
      />

      {/* <CardState
        isOpen={true}
        onButtonPress={() => null}
        content={gameConfig.errorTitle}
        contentNudge={gameConfig.errorNudge}
        contentStyle={gameConfig.errorStyle}
        buttonText={gameConfig.errorButton}
        buttonNudge={gameConfig.errorButtonNudge}
        buttonStyle={gameConfig.errorButtonStyle}
        icon={gameConfig.errorIcon}
        bgColor={gameConfig.modalBackgroundColor}
        closeColor={gameConfig.modalCloseColor}
      /> */}

      {/* <CardState
        isOpen={true}
        onButtonPress={() => null}
        content={gameConfig.pleaseWait}
        contentNudge={gameConfig.pleaseWaitNudge}
        contentStyle={gameConfig.pleaseWaitStyle}
        buttonText={gameConfig.pleaseWaitButton}
        buttonNudge={gameConfig.pleaseWaitButtonNudge}
        buttonStyle={gameConfig.pleaseWaitButtonStyle}
        icon={gameConfig.pleaseWaitIcon}
        bgColor={gameConfig.modalBackgroundColor}
        closeColor={gameConfig.modalCloseColor}
      /> */}

      <CardState
        isOpen={isGameOverModalOpen}
        onClosePress={gameOverButtonClick}
        onButtonPress={gameOverButtonClick}
        content={gameConfig.gameOver}
        contentNudge={gameConfig.gameOverNudge}
        contentStyle={gameConfig.gameOverStyle}
        buttonText={gameConfig.gameOverButton}
        buttonNudge={gameConfig.gameOverButtonNudge}
        buttonStyle={gameConfig.gameOverButtonStyle}
        icon={gameConfig.gameOverIcon}
        bgColor={gameConfig.modalBackgroundColor}
        closeColor={gameConfig.modalCloseColor}
      />
    </>
  )
})

const Game = observer(
  (props: { tokens: BVatomTokenType[]; onTokenClick: (vatom: BVatomTokenType) => void }) => {
    const { tokens, onTokenClick } = props
    useTokenSorter(tokens)

    return (
      <SceneWrapper>
        {tokens.map(token => {
          const v = token as BVatomTokenType
          if (!v.metadata.animation_url) return null

          return (
            <GeoModel
              key={v.id}
              token={token}
              onClick={() => onTokenClick(v)}
              url={v.metadata.animation_url}
            />
          )
        })}
      </SceneWrapper>
    )
  }
)

/** React hook to fetch all tokens within a radius around the user */
export const useTokensInRadius = (
  radiusInMeters: number,
  gameVatom: BVatomTokenType,
  gameReady: boolean
) => {
  // Get the SDK
  const sdk = useSDK()
  const regionRef = useRef<CompositeRegion>()
  // Get the current location
  const [initialGeo, setInitialGeo] = useState<GeolocationPosition>()
  // const [geoError, setGeoError] = useState<GeolocationPositionError>()

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        setInitialGeo(position)
      },
      error => {
        alert(`${error.message}. Please refresh the app and allow location permission`)
      }
    )
  }, [])

  // Get region to monitor
  const region: CompositeRegion | undefined = useMemo(() => {
    if (regionRef.current && regionRef.current?.ARTokens?.length > 0) {
      return regionRef.current
    }
    if (!initialGeo) return
    const LATITUDE_DELTA = Math.abs(radiusInMeters / 111111.1)
    const LONGITUDE_DELTA = Math.abs(
      radiusInMeters / (111111.1 * Math.cos(initialGeo.coords.latitude))
    )

    const regionInfo = {
      latitude: initialGeo.coords.latitude,
      longitude: initialGeo.coords.longitude,
      latitudeDelta: LATITUDE_DELTA,
      longitudeDelta: LONGITUDE_DELTA
    }

    const boundingBox = getBoundingBoxFromRegion(regionInfo)
    if (boundingBox) {
      const r = sdk.dataPool.region(RegionType.geopos, JSON.stringify({ ...boundingBox }))
      regionRef.current = r
      return r
    }
  }, [initialGeo, radiusInMeters, sdk.dataPool])

  const regions = sdk.dataPool.regions.filter(r => r.id === RegionType.geopos)

  const filteredTokens = regions[0]?.ARTokens ?? []

  const isFirstTime = useRef(true)
  useEffect(() => {
    if (isFirstTime.current && initialGeo && gameReady) {
      const somethingToshow = useSortedTokenStore.getState().tokens.length > 0
      const extra = somethingToshow ? {} : { qty: 0 }
      isFirstTime.current = false
      gameVatom.performAction('varius.action:varius.io:open-ar-view-v1', {
        'this.id': gameVatom.id,
        'geo.pos': {
          Lon: initialGeo.coords.longitude,
          Lat: initialGeo.coords.latitude
        },
        ...extra
      })
    }
  }, [gameVatom, initialGeo, gameReady])

  useEffect(() => {
    const interval = setInterval(() => {
      const storePosition = useLocationStore.getState().position
      if (!initialGeo && !storePosition) {
        console.error('No location')
        return
      }

      const currentLocation = (storePosition ?? initialGeo) as GeolocationPosition
      // create new region
      const LATITUDE_DELTA = Math.abs(radiusInMeters / 111111.1)
      const LONGITUDE_DELTA = Math.abs(
        radiusInMeters / (111111.1 * Math.cos(currentLocation.coords.latitude))
      )

      const regionInfo = {
        latitude: currentLocation.coords.latitude,
        longitude: currentLocation.coords.longitude,
        latitudeDelta: LATITUDE_DELTA,
        longitudeDelta: LONGITUDE_DELTA
      }

      const boundingBox = getBoundingBoxFromRegion(regionInfo)
      if (boundingBox) {
        const r = sdk.dataPool.region(
          RegionType.geopos,
          JSON.stringify({ ...boundingBox, keepAlive: true })
        )
        regionRef.current = r
      }
    }, 20000)
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialGeo])

  const [_, set] = useControls('Tokens in state', () => ({
    value: filteredTokens.length
  }))

  set({
    value: filteredTokens.length
  })

  return {
    location: useLocationStore.getState().position ?? initialGeo,
    tokens: filteredTokens ?? []
  }
}

import React from 'react'
import { Text } from '@vatom/wombo'
import { Box, Flex, Image } from 'native-base'

export const TopHalf = () => {
  return (
    <Flex
      bg="brand.500"
      alignItems="center"
      justifyContent="flex-end"
      _web={{ height: '50vh' }}
      _ios={{ flex: 0.8 }}
      _android={{ flex: 1 }}
      pb={30}
    >
      <Box mx={[6, 16]} _web={{ paddingTop: '80px' }}>
        <Image
          h="113px"
          w="131px"
          source={require('../assets/WhiteLogoVatomWhite.png')}
          alt="Vatom Logo"
          accessibilityIgnoresInvertColors
        />
        <Text
          color="white"
          fontSize={22}
          m={3}
          textAlign="center"
          fontFamily="heading"
          fontWeight="bold"
          tx="common.wallet"
        >
          Wallet
        </Text>
      </Box>
      <Text
        mx={[6, 16]}
        mt={30}
        color="white"
        fontWeight="normal"
        fontSize="14px"
        textAlign="center"
        fontFamily="body"
        tx="loginScreen.subHeading"
      />
    </Flex>
  )
}

export default TopHalf

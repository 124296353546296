import React, { useEffect, useRef } from 'react'
import { Platform } from 'react-native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { SpaceMonitor, useConfig, useIsAuthed, useMatrixFullStateSync } from '@vatom/sdk/react'
import { i18n } from '@vatom/utils'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { theme } from '@vatom/wombo'
import * as Linking from 'expo-linking'
import { useLocales } from 'expo-localization'
import { observer } from 'mobx-react-lite'

import CustomFavicon from '../../components/Favicon'
import ModalReedem from '../../components/ModalRedeem/ModalReedem'
import RewardsCard from '../../components/RewardsCard'
import { useBroadcastSession } from '../../hooks/useBroadcastSession'
import { useBrowserCompatibilityWarning } from '../../hooks/useBrowserCompatibilityWarning'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { useForceUpdate } from '../../hooks/useForceUpdate'
import { LayoutScrollHandlerProvider } from '../../hooks/useLayoutScrollHandler'
import { useNavigationPending } from '../../hooks/useNavigationPending'
import { removeBodyScroll } from '../../hooks/useRemoveBodyScroll.web'
import logger from '../../logger'
import Acquire from '../../screens/Acquire'
import AcquirePubVariation from '../../screens/AcquirePubVariation'
import NftFiltersModal from '../../screens/ActionSheets/NftFiltersModal'
import BackupToCloud from '../../screens/BackupToCloud'
import { BlockchainNetwork } from '../../screens/BlockChainNetworks'
import { BlockChainNetworkAdd } from '../../screens/BlockChainNetworks/BlockChainNetworksAdd'
import { BlockChainNetworkEdit } from '../../screens/BlockChainNetworks/BlockChianNetworksEdit'
import { BlockChainWallets } from '../../screens/BlockChainWallets'
import CameraScreen from '../../screens/Camera'
import ChangeBackupPassword from '../../screens/ChangeBackupPassword'
import Claim from '../../screens/Claim'
import CoinNavigation from '../../screens/CoinNavigation'
import AddQuestion from '../../screens/Communities/components/AddModals/modal-components/modal-questions-components/AddQuestion'
import Questions from '../../screens/Communities/components/AddModals/modal-components/modal-questions-components/Questions'
import AddSketch from '../../screens/Communities/components/AddModals/modal-components/modal-sketches-components/AddSketch'
import Sketches from '../../screens/Communities/components/AddModals/modal-components/modal-sketches-components/Sketches'
import RoomNewMessage from '../../screens/Communities/screens/AddMessageModal'
import RoomMembers from '../../screens/Communities/screens/Members'
import RoomReply from '../../screens/Communities/screens/Reply'
import RoomView from '../../screens/Communities/screens/Room'
import RoomThread from '../../screens/Communities/screens/Thread'
import { SyncContainer } from '../../screens/Communities/SyncContainer'
import Connect from '../../screens/Connect/Connect'
import LoginCallback from '../../screens/Connect/LoginCallback'
import DeleteFromCloud from '../../screens/DeleteFromCloud'
import { DmScreen } from '../../screens/DmScreen/DmScreen'
import DropNFT from '../../screens/DropNFT'
import EditAddressName from '../../screens/EditAddressName'
import EditProfile from '../../screens/EditProfile'
import { FindToken } from '../../screens/FindToken'
import FollowList from '../../screens/FollowList'
import FungibleTokenConfirmation from '../../screens/FungibleTokens/confirmation'
import FungibleTokenDetail from '../../screens/FungibleTokens/detail'
import FungibleTokenRecipient from '../../screens/FungibleTokens/recipient'
import FungibleTokenTransfer from '../../screens/FungibleTokens/transfer'
import Home from '../../screens/Home'
import ImportWalletRecover from '../../screens/ImportWallet/screen/recover'
import ImportWalletStart from '../../screens/ImportWallet/screen/start'
import InvitePeople from '../../screens/InvitePeople'
import LogoutCallback from '../../screens/LogoutCallback'
import ManageAccounts from '../../screens/ManageAccounts'
import MapAR from '../../screens/MapAR'
import MintNFT from '../../screens/MintNFT'
import MintSuccess from '../../screens/MintSuccess'
import NewWalletBackup from '../../screens/NewWallet/screens/backup'
import NewWalletConfirm from '../../screens/NewWallet/screens/confirm'
import NewWalletStart from '../../screens/NewWallet/screens/start'
import NFTDetail from '../../screens/NFTDetail'
import ProfileOtherUser from '../../screens/ProfileOtherUser'
import ProfileUser from '../../screens/ProfileUser'
import CloudBackupList from '../../screens/RestoreFromCloud/screens/list'
import RestoreFromCloud from '../../screens/RestoreFromCloud/screens/password'
import SellNFT from '../../screens/SellNFT'
import { SendConfirmation } from '../../screens/SendConfirmation'
import SendNFT from '../../screens/SendNFT'
import SendNFTConfirmation from '../../screens/SendNFTConfirmation'
import SendNFTShare from '../../screens/SendNFTShare'
import { SendToChainConfirmation } from '../../screens/SendToChainConfirmation'
import { Settings } from '../../screens/Settings'
import SettingsMetamask from '../../screens/SettingsMetamask'
import ShowPrivateKey from '../../screens/ShowPrivateKey'
import Space from '../../screens/Space'
import TokenAR from '../../screens/TokenAR'
import WalletAddressManagement from '../../screens/WalletAddressManagement'
import { DebugPanel } from '../../utils/DebugPanel'
import { useSDKHandlers } from '../../utils/WalletSdk/hooks'

import { AppRoutes, AppStackParamList } from './AppNavigator'

const Stack = createNativeStackNavigator<AppStackParamList>()

const isWeb = Platform.OS === 'web'

const modalListeners = {
  focus: () => {
    isWeb && removeBodyScroll().add()
  },
  blur: () => {
    isWeb && removeBodyScroll().remove()
  },
  beforeRemove: () => {
    isWeb && removeBodyScroll().remove()
  }
}

export const AppStack = observer(() => {
  const renderCount = useRef(0)
  const { isDark } = useBusinessTheme()

  renderCount.current++
  // always put the login screen first on the stack even when restoring initialState
  const isAuthed = useIsAuthed()

  console.info('[AppStack] renderCount:', renderCount)

  return (
    <LayoutScrollHandlerProvider>
      <Subscriptions />
      <SpaceMonitor />
      <CustomFavicon />

      <Stack.Navigator
        screenOptions={{
          headerShown: false,
          gestureEnabled: false
        }}
      >
        {!isAuthed && (
          <>
            <Stack.Screen name={AppRoutes.connect} component={Connect} />
            <Stack.Screen name={AppRoutes.businessConnect} component={Connect} />
          </>
        )}
        {isAuthed && (
          <>
            <Stack.Screen
              name={AppRoutes.home}
              component={Home}
              options={{ gestureEnabled: false }}
            />
            <Stack.Screen
              name={AppRoutes.BusinessProxy}
              component={Home}
              getId={({ params }) => {
                return params?.business
              }}
            />
            {/* NFT DETAILS  */}
            <Stack.Group
              screenOptions={{
                gestureDirection: 'horizontal',
                animation: 'slide_from_bottom'
              }}
            >
              <Stack.Screen
                name={AppRoutes.NFTDetail}
                component={NFTDetail}
                listeners={modalListeners}
              />
              <Stack.Screen
                name={AppRoutes.NFTDetail_Business}
                component={NFTDetail}
                getId={({ params }) => `${params.business}-${params.tokenId}`}
                listeners={modalListeners}
              />
              <Stack.Screen name={AppRoutes.SendNFT} component={SendNFT} />
              <Stack.Screen
                name={AppRoutes.SendNFT_Business}
                component={SendNFT}
                getId={({ params }) => `${params.business}-${params.tokenId}`}
              />
              <Stack.Screen name={AppRoutes.SendNFTShare} component={SendNFTShare} />
              <Stack.Screen
                name={AppRoutes.SendNFTShare_Business}
                component={SendNFTShare}
                getId={({ params }) => `${params.business}-${params.tokenId}`}
              />
              <Stack.Screen name={AppRoutes.DropNFT} component={DropNFT} />
              <Stack.Screen
                name={AppRoutes.DropNFT_Business}
                component={DropNFT}
                getId={({ params }) => `${params.business}-${params.tokenId}`}
              />
              <Stack.Screen name={AppRoutes.SellNFT} component={SellNFT} />
              <Stack.Screen
                name={AppRoutes.SellNFT_Business}
                component={SellNFT}
                getId={({ params }) => `${params.business}-${params.tokenId}`}
              />
              <Stack.Screen name={AppRoutes.MintNFT} component={MintNFT} />
              <Stack.Screen
                name={AppRoutes.MintNFT_Business}
                component={MintNFT}
                getId={({ params }) => `${params.business}-${params.tokenId}`}
              />
            </Stack.Group>
            <Stack.Screen name={AppRoutes.FindToken} component={FindToken} />
            <Stack.Screen name={AppRoutes.FindToken_Business} component={FindToken} />
            <Stack.Screen
              name={AppRoutes.sendConfirmation}
              component={SendConfirmation}
              options={{
                presentation: 'transparentModal'
              }}
              // had to remove this cause scrolling is not working
              // listeners={modalListeners}
            />
            <Stack.Screen name={AppRoutes.editProfile} component={EditProfile} />
            <Stack.Screen name={AppRoutes.manageAccounts} component={ManageAccounts} />
            <Stack.Screen name={AppRoutes.settingsMetamask} component={SettingsMetamask} />
            <Stack.Screen name={AppRoutes.invitePeople} component={InvitePeople} />
            <Stack.Screen name={AppRoutes.MintSuccess} component={MintSuccess} />
            <Stack.Screen name={AppRoutes.BackupToCloud} component={BackupToCloud} />
            <Stack.Screen name={AppRoutes.RestoreFromCloud} component={RestoreFromCloud} />
            <Stack.Screen name={AppRoutes.DeleteFromCloud} component={DeleteFromCloud} />
            <Stack.Screen name={AppRoutes.ChangeBackupPassword} component={ChangeBackupPassword} />
            <Stack.Screen name={AppRoutes.CloudBackupList} component={CloudBackupList} />
            <Stack.Screen name={AppRoutes.NewWalletStart} component={NewWalletStart} />
            <Stack.Screen name={AppRoutes.NewWalletBackup} component={NewWalletBackup} />
            <Stack.Screen name={AppRoutes.NewWalletConfirm} component={NewWalletConfirm} />
            <Stack.Screen name={AppRoutes.ImportWalletStart} component={ImportWalletStart} />
            <Stack.Screen name={AppRoutes.ImportWalletRecover} component={ImportWalletRecover} />
            <Stack.Screen
              name={AppRoutes.WalletAddressManagement}
              component={WalletAddressManagement}
            />
            <Stack.Screen name={AppRoutes.ShowPrivateKey} component={ShowPrivateKey} />
            <Stack.Screen name={AppRoutes.EditAddressName} component={EditAddressName} />
            <Stack.Screen
              name={AppRoutes.sendToChainConfirmation}
              component={SendToChainConfirmation}
            />
            <Stack.Group
              screenOptions={{
                presentation: 'modal'
              }}
            >
              <Stack.Screen
                name={AppRoutes.MapAR}
                component={MapAR}
                options={{}}
                listeners={modalListeners}
              />
              <Stack.Screen
                name={AppRoutes.TokenAR}
                component={TokenAR}
                options={{}}
                listeners={modalListeners}
              />
              <Stack.Screen
                name={AppRoutes.CameraScreen}
                component={CameraScreen}
                options={{}}
                listeners={modalListeners}
              />
            </Stack.Group>
            <Stack.Screen
              name={AppRoutes.profileUser}
              component={ProfileUser}
              options={{
                contentStyle: {
                  backgroundColor: isDark ? theme.colors.grayCool[900] : theme.colors.grayCool[50],
                  height: '100%'
                }
              }}
            />
            <Stack.Screen name={AppRoutes.Metaverse} component={Space} />
            <Stack.Screen name={AppRoutes.followList} component={FollowList} />
            <Stack.Screen name={AppRoutes.profileOtherUser} component={ProfileOtherUser} />
            <Stack.Screen name={AppRoutes.profileOtherUser_Business} component={ProfileOtherUser} />
            <Stack.Screen
              name={AppRoutes.nftFilters}
              component={NftFiltersModal}
              options={{
                presentation: 'transparentModal',
                animation: 'fade'
              }}
              listeners={modalListeners}
            />
          </>
        )}
        {/* <Stack.Screen name={AppRoutes.CommunitiesHome} component={CommunitiesHomeWithBusiness} /> */}
        <Stack.Screen name={AppRoutes.Room} component={RoomView} />
        <Stack.Screen name={AppRoutes.RoomMembers} component={RoomMembers} />
        <Stack.Screen name={AppRoutes.RoomThread} component={RoomThread} />
        <Stack.Screen name={AppRoutes.CommunitiesRoom} component={RoomView} />
        <Stack.Screen name={AppRoutes.CommunitiesRoomMembers} component={RoomMembers} />
        <Stack.Screen name={AppRoutes.CommunitiesRoomThread} component={RoomThread} />
        <Stack.Group screenOptions={{ presentation: 'fullScreenModal' }}>
          <Stack.Screen name={AppRoutes.RoomNewMessage} component={RoomNewMessage} />
          <Stack.Screen name={AppRoutes.CommunitiesRoomNewMessage} component={RoomNewMessage} />

          <Stack.Screen name={AppRoutes.RoomReply} component={RoomReply} />
          <Stack.Screen name={AppRoutes.CommunitiesRoomReply} component={RoomReply} />

          <Stack.Screen name={AppRoutes.RoomNewQuestion} component={AddQuestion} />
          <Stack.Screen name={AppRoutes.CommunitiesRoomNewQuestion} component={AddQuestion} />

          <Stack.Screen name={AppRoutes.RoomSketchList} component={Sketches} />
          <Stack.Screen name={AppRoutes.CommunitiesRoomSketchList} component={Sketches} />

          <Stack.Screen name={AppRoutes.RoomNewSketch} component={AddSketch} />
          <Stack.Screen name={AppRoutes.CommunitiesRoomNewSketch} component={AddSketch} />

          <Stack.Screen name={AppRoutes.RoomQuestionList} component={Questions} />
          <Stack.Screen name={AppRoutes.CommunitiesRoomQuestionList} component={Questions} />
        </Stack.Group>
        <Stack.Screen name={AppRoutes.AcquirePubVariation} component={AcquirePubVariation} />
        <Stack.Screen name={AppRoutes.AcquirePubVariation1} component={AcquirePubVariation} />
        <Stack.Screen name={AppRoutes.AcquirePubVariation2} component={AcquirePubVariation} />
        <Stack.Screen
          name={AppRoutes.Claim}
          component={Claim}
          navigationKey={isAuthed ? 'authed-claim' : 'non-authed-claim'}
        />
        <Stack.Screen name={AppRoutes.Acquire} component={Acquire} />
        <Stack.Screen name={AppRoutes.Acquire_Business} component={Acquire} />
        <Stack.Screen
          navigationKey={isAuthed ? 'authed-logoutcb' : 'non-authed-logoutcb'}
          name={AppRoutes.LogoutCallback}
          component={LogoutCallback}
        />
        {Platform.OS === 'web' && (
          <Stack.Screen name={AppRoutes.LoginCallback} component={LoginCallback} />
        )}
        <Stack.Screen name={AppRoutes.coin} component={CoinNavigation} options={{}} />
        <Stack.Screen
          name={AppRoutes.CoinDetail_Business}
          component={CoinNavigation}
          options={{}}
        />
        {/** 🔥 Your screens go here */}
        <Stack.Screen
          name={AppRoutes.DmScreen}
          component={DmScreen}
          options={{
            gestureEnabled: true
          }}
        />
        <Stack.Screen name={AppRoutes.Settings} component={Settings} options={{}} />
        <Stack.Screen
          name={AppRoutes.BlockChainWallets}
          component={BlockChainWallets}
          options={{}}
        />
        <Stack.Screen
          name={AppRoutes.BlockChainNetwork}
          component={BlockchainNetwork}
          options={{}}
        />
        <Stack.Screen
          name={AppRoutes.BlockChainNetworkAdd}
          component={BlockChainNetworkAdd}
          options={{}}
        />
        <Stack.Screen
          name={AppRoutes.BlockChainNetworkEdit}
          component={BlockChainNetworkEdit}
          options={{}}
        />
        <Stack.Screen
          name={AppRoutes.FungibleTokenDetail}
          component={FungibleTokenDetail}
          options={{}}
        />
        <Stack.Screen
          name={AppRoutes.FungibleTokenTransfer}
          component={FungibleTokenTransfer}
          options={{}}
        />
        <Stack.Screen
          name={AppRoutes.FungibleTokenRecipient}
          component={FungibleTokenRecipient}
          options={{}}
        />
        <Stack.Screen
          name={AppRoutes.FungibleTokenConfirmation}
          component={FungibleTokenConfirmation}
          options={{}}
        />
        <Stack.Screen
          name={AppRoutes.sendNFTConfirmation}
          component={SendNFTConfirmation}
          // had to remove this cause scrolling is not working
          // listeners={modalListeners}
        />
      </Stack.Navigator>
      {isAuthed ? (
        <>
          <ModalReedem />
          <RewardsCard />
        </>
      ) : null}
      <DebugPanel />
    </LayoutScrollHandlerProvider>
  )
})

export const openDeepLink = async () => {
  if (Platform.OS === 'web') {
    const initialUrl = await Linking.getInitialURL()
    logger.info('[openDeepLink] initialUrl', initialUrl)
    logger.info('[openDeepLink] window.location.href', window.location.href)
    return initialUrl
  }
  try {
    const initialUrl = await Linking.getInitialURL()

    if (initialUrl) {
      const urlObject = new URL(initialUrl)

      // for some reason ios opens a browser if the scheme is https
      const deeplink =
        Platform.OS === 'ios'
          ? `${urlObject.hostname}:/${urlObject.pathname}?${urlObject.searchParams}`
          : initialUrl
      const canOpen = await Linking.canOpenURL(deeplink)
      if (canOpen) {
        // await Linking.openURL(deeplink)
      } else {
        logger.error('Cannot open url: ', deeplink, initialUrl)
      }
    }

    return initialUrl
  } catch (error) {
    logger.error('Error opening deep link', error)
  }
  return null
}

const Subscriptions = observer(() => {
  useBroadcastSession()

  const isAuthed = useIsAuthed()
  useMatrixFullStateSync({
    enabled: !!isAuthed,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })

  useSDKHandlers()
  useBrowserCompatibilityWarning()
  useForceUpdate()
  useNavigationPending()
  console.info('[Subscriptions] isAuthed:', isAuthed)

  const locale = useLocales()

  const config = useConfig()

  i18n.locale = config?.features.language ?? locale?.[0]?.languageCode ?? 'en'

  return <SyncContainer />
})

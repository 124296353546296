import React, { useCallback, useMemo, useState } from 'react'
import { Platform, TouchableOpacity, useWindowDimensions } from 'react-native'
import {
  GestureEvent,
  GestureHandlerRootView,
  PanGestureHandler,
  PanGestureHandlerEventPayload
} from 'react-native-gesture-handler'
import Animated, {
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withSpring
} from 'react-native-reanimated'
import { useSafeAreaFrame, useSafeAreaInsets } from 'react-native-safe-area-context'
import { AntDesign } from '@expo/vector-icons'
import { useMeasure } from '@reactivers/use-measure'
import { TokenType } from '@vatom/sdk/core'
import { useThrottle } from '@vatom/sdk/react'
import { Text, theme } from '@vatom/wombo'
import { Box, Icon, Pressable, useBreakpointValue } from 'native-base'

import { getAspectRatio } from '../../../hooks/useAspectRatio'
import { ThemeInterface } from '../../Business/WebBusinessProvider/types'

type CardViewProps = {
  token: TokenType
  CardFace: React.ReactNode
  onExpanded?: () => void
  onCollapsed?: () => void
  themePage: ThemeInterface
}

const headerHeight = 60
const cardHeaderHeight = 40
const cardOffsetTop = 40
const cardOffsetHorizontal = 16
const cardFooterHeight = 60

const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent)

const isWeb = Platform.OS === 'web'

const CardView = ({ token, CardFace, themePage, onExpanded, onCollapsed }: CardViewProps) => {
  const insets = useSafeAreaInsets()
  const frame = useSafeAreaFrame()

  const refAnimatedCard = React.useRef<any>(null)

  const windowDimensions = useWindowDimensions()
  const windowWidth = frame.width || windowDimensions.width
  const windowHeight = frame.height || windowDimensions.height
  const desktopWidth = 460
  const maxWidth = useBreakpointValue({
    base: windowWidth,
    md: desktopWidth,
    lg: desktopWidth
  })

  const insetsOffset = insets.top + insets.bottom
  const topOffset = headerHeight + insetsOffset
  const cardMaxHeight = windowHeight - topOffset

  const cardCloseButtonSize = isSafari ? 'small' : 'large'

  const cardViewDimensions = useCallback(() => {
    const cardHeight = cardMaxHeight - cardFooterHeight
    const cardWidth = maxWidth - cardOffsetHorizontal * 2

    return getAspectRatio(cardWidth, cardHeight)
  }, [cardMaxHeight, maxWidth])

  const [cardSize, setCardSize] = useState(cardViewDimensions())

  const setMeasureDimensions = useCallback(() => {
    const { width, height } = cardViewDimensions()
    if (cardSize.width !== width || cardSize.height !== height) {
      setCardSize({ width, height })
    }
  }, [cardSize, cardViewDimensions])

  const measurePayload = useMemo(() => {
    if (isWeb) {
      return {
        ref: refAnimatedCard,
        updateOnWindowResize: true,
        onResize: setMeasureDimensions
      }
    }
    return {
      ref: refAnimatedCard
    }
  }, [setMeasureDimensions])
  // Resize card on window resize
  useMeasure(measurePayload)

  const INITIAL_POSITION = windowHeight - cardOffsetTop
  const FIRST_POSITION = -cardOffsetTop
  const SECOND_POSITION = useMemo(() => {
    const position = windowHeight * 0.5 + cardSize.height * 0.5 - headerHeight
    const availableSpace = windowHeight - headerHeight
    if (!isWeb) {
      return -position
    }
    if (position > availableSpace) {
      return -position
    } else {
      return -availableSpace
    }
  }, [cardSize.height, windowHeight])

  const damping = { damping: 25 }

  const isDragging = useSharedValue(false)
  const isUp = useSharedValue(false)
  const translateY = useSharedValue(FIRST_POSITION)

  const handleToggleCard = (value: boolean) => {
    if (value) {
      onExpanded && onExpanded()
    } else {
      onCollapsed && onCollapsed()
    }
  }

  useDerivedValue(() => {
    runOnJS(handleToggleCard)(isUp.value)
  }, [])

  const onGestureEvent = useAnimatedGestureHandler<
    GestureEvent<PanGestureHandlerEventPayload>,
    {
      startY: number
    }
  >({
    onStart: (_, ctx) => {
      ctx.startY = translateY.value
      isDragging.value = true
    },
    onActive: (event, ctx) => {
      translateY.value = Math.max(Math.min(ctx.startY + event.translationY, windowHeight), 0)
      isUp.value = translateY.value > FIRST_POSITION
    },
    onEnd: event => {
      isDragging.value = false
      const shouldOpen = event.velocityY < 0
      translateY.value = withSpring(shouldOpen ? SECOND_POSITION : FIRST_POSITION, damping)
      isUp.value = shouldOpen
    }
  })

  const animatedContainerStyle = useAnimatedStyle(() => {
    const translateTo = translateY.value
    return {
      transform: [{ translateY: translateTo }],
      height: '100%',
      width: '100%'
    }
  })
  const animatedTitleStyle = useAnimatedStyle(() => {
    const isOpen = isUp.value
    if (isOpen) {
      return {
        opacity: 0,
        display: 'none'
      }
    }

    return {
      opacity: 1,
      display: 'flex',
      marginTop: -cardHeaderHeight,
      zIndex: 2
    }
  })

  const onPress = useThrottle(() => {
    if (isWeb && refAnimatedCard?.current) {
      refAnimatedCard.current.scrollTo({ top: 0 })
    }
    if (isUp.value) {
      translateY.value = withSpring(FIRST_POSITION, { damping: 25 })
    } else {
      translateY.value = withSpring(SECOND_POSITION, { damping: 25 })
    }
    isUp.value = !isUp.value
  }, 1000)

  const cardShadow = useMemo(() => {
    return Platform.select({
      ios: {
        shadowColor: themePage.active ?? '#000',
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 0.4,
        shadowRadius: 6
      },
      android: {
        elevation: 5
      },
      web: {
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 20px 5px'
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cardTopOffset = isSafari ? '100dvh' : isWeb ? windowHeight : INITIAL_POSITION

  return (
    <GestureHandlerRootView
      style={{
        position: 'absolute',
        left: cardOffsetHorizontal,
        right: cardOffsetHorizontal
      }}
    >
      <PanGestureHandler onGestureEvent={onGestureEvent}>
        <Animated.View
          ref={refAnimatedCard}
          style={[
            // @ts-ignore
            {
              position: 'absolute',
              zIndex: 99,
              top: cardTopOffset,
              maxHeight: cardMaxHeight
            },
            animatedContainerStyle
          ]}
        >
          <Animated.View style={animatedTitleStyle}>
            <TouchableOpacity
              accessibilityRole="button"
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                height: cardHeaderHeight
              }}
              onPress={onPress}
            >
              <Text
                marginBottom={1}
                fontSize={13}
                lineHeight={18}
                fontWeight={400}
                fontFamily={'Inter-Regular'}
                color={themePage.active ?? theme.colors.coolGray[700]}
                tx="nftDetail.showCard"
              />

              <Box
                width={10}
                height={1}
                borderRadius={2}
                backgroundColor={theme.colors.coolGray[400]}
              />
            </TouchableOpacity>
          </Animated.View>

          <Box flex={1} flexDirection={'column'}>
            {/* NOTE: double box is needed to display shadows on ios with borderRadius and overflow interaction */}
            <Box
              key="BoxToken"
              alignItems="center"
              justifyContent="center"
              marginX={'auto'}
              borderRadius={8}
              width={cardSize.width}
              height={cardSize.height}
              style={cardShadow}
              pointerEvents={isUp.value ? 'auto' : 'none'}
              backgroundColor={'rgba(135, 135, 135, .3)'}
            >
              <Box
                borderRadius={8}
                overflow={'hidden'}
                width={cardSize.width}
                height={cardSize.height}
              >
                {token ? CardFace : null}
              </Box>
            </Box>
            <SlideDownButton size={cardCloseButtonSize} onPress={onPress} themePage={themePage} />
          </Box>
        </Animated.View>
      </PanGestureHandler>
    </GestureHandlerRootView>
  )
}

const sizes = {
  small: 10,
  large: 12
} as const
const SlideDownButton = ({
  size,
  onPress,
  themePage
}: {
  size: keyof typeof sizes
  onPress: () => void
  themePage: ThemeInterface
}) => {
  const iconSize = size === 'small' ? 4 : 5
  return (
    <Pressable accessibilityRole="button" onPress={onPress} marginY={2}>
      <Box
        size={sizes[size]}
        borderRadius="full"
        alignItems="center"
        justifyContent="center"
        backgroundColor={themePage.background ?? 'white'}
        borderWidth="1px"
        borderColor={themePage.borderColor ?? theme.colors.coolGray[500]}
        alignSelf="center"
      >
        <Icon as={AntDesign} size={iconSize} name="down" color={theme.colors.coolGray[600]} />
      </Box>
    </Pressable>
  )
}

export { CardView as CardViewV2 }

import { theme } from '@vatom/wombo'
import { Box } from 'native-base'

import { WebWrapper } from '../../components/WebWrapper'
import { withBusinessSelector } from '../../hooks/useBusinessSelector'
import { DesktopHeader, HeaderDesktopProvider } from '../Home/partials/DesktopHeader'

import { ProfileUser } from './ProfileUser'

export default withBusinessSelector(() => (
  <HeaderDesktopProvider>
    <DesktopHeader />
    <Box
      _light={{
        backgroundColor: theme.colors.white
      }}
      _dark={{
        backgroundColor: theme.colors.grayCool[900]
      }}
    >
      <WebWrapper flex={1} flexShrink={0}>
        <ProfileUser />
      </WebWrapper>
    </Box>
  </HeaderDesktopProvider>
))

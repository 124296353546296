// import * as Localization from 'expo-localization'
import { I18n, TranslateOptions } from 'i18n-js'

import en from './en.json'
import es from './es.json'
// import ja from './ja.json'

const i18n = new I18n({ en, es })

i18n.defaultLocale = 'en'
i18n.enableFallback = true
i18n.translations = { en, es }

/**
 * Builds up valid keypaths for translations.
 * Update to your default locale of choice if not English.
 */
type DefaultLocale = typeof en
/**
 * Builds up valid keypaths for translations.
 */
export type TxKeyPath = RecursiveKeyOf<DefaultLocale>

// via: https://stackoverflow.com/a/65333050
type RecursiveKeyOf<TObj extends object> = {
  [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<TObj[TKey], `${TKey}`>
}[keyof TObj & (string | number)]

type RecursiveKeyOfInner<TObj extends object> = {
  [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<
    TObj[TKey],
    `['${TKey}']` | `.${TKey}`
  >
}[keyof TObj & (string | number)]

type RecursiveKeyOfHandleValue<TValue, Text extends string> = TValue extends any[]
  ? Text
  : TValue extends object
  ? Text | `${Text}${RecursiveKeyOfInner<TValue>}`
  : Text

function translate(key: TxKeyPath, options?: TranslateOptions) {
  return i18n.t(key, options)
}

export { i18n, translate }

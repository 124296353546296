import { useFirstTimeStore } from './useFirstTimeStore'

const useFirstTime = ({ businessId }: { businessId?: string }) => {
  const { getIsFirstTime } = useFirstTimeStore.getState()

  const isFirstTime = businessId ? getIsFirstTime(businessId) : false

  return { isFirstTime }
}

export { useFirstTime }

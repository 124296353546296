import React, { useCallback, useState } from 'react'
import { usePreventScroll } from 'react-aria'
import { useNavigation } from '@react-navigation/native'
import { translate } from '@vatom/utils'
import { Status, theme, Toast } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, useColorModeValue, VStack } from 'native-base'

import Alert from '../../components/Alert'
import { ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import { withBusinessSelector } from '../../hooks/useBusinessSelector'
import { AppRoutes, AppStackScreenProps, TabRoutes } from '../../navigators'
import { useToken } from '../NFTDetail/useNftDetail/useToken'

import ConfirmModal from './partials/ConfirmModal'
import Map from './partials/Map'
import { LatLng } from './react-native-maps'

export type DropNFTProps = AppStackScreenProps<
  typeof AppRoutes.DropNFT | typeof AppRoutes.DropNFT_Business
>

export const DropNFT = withBusinessSelector(
  observer((props: DropNFTProps) => {
    const { tokenId, business } = props.route.params
    const { token, isLoading: tokenIsLoading } = useToken({
      tokenId
    })

    usePreventScroll({})

    const headerIconColor = useColorModeValue(
      theme.colors.textLightMode[900],
      theme.colors.textDarkMode[100]
    )
    const backgroundColor = useColorModeValue(theme.colors.white, theme.colors.grayDarkMode[900])

    const navigation = useNavigation()
    const [isLoading, setIsLoading] = useState(false)
    const [isConfirmOpen, setIsConfirmOpen] = useState(false)
    const [markerLocation, setMarkerLocation] = useState<LatLng>()

    const onCancel = useCallback(() => setIsConfirmOpen(false), [])

    const onClose = useCallback(() => {
      if (navigation.canGoBack()) {
        navigation.goBack()
      } else {
        if (business) {
          navigation.reset({
            index: 0,
            routes: [{ name: AppRoutes.BusinessProxy, params: { business } }]
          })
        } else {
          navigation.reset({
            index: 0,
            routes: [{ name: AppRoutes.home, params: { screen: TabRoutes.Wallet } }]
          })
        }
      }
    }, [business, navigation])

    const onDrop = useCallback((latitude: number, longitude: number) => {
      setMarkerLocation({
        latitude,
        longitude
      })
      setIsConfirmOpen(true)
    }, [])

    const doDrop = useCallback(() => {
      if (!token && !isLoading) {
        return
      }
      setIsConfirmOpen(false)
      setIsLoading(true)

      if (markerLocation) {
        token
          .performAction('Drop', {
            'geo.pos': { Lat: markerLocation.latitude, Lon: markerLocation.longitude }
          })
          .then($ => {
            Toast({
              title: translate('dropNft.success', { name: token.metadata.name }),
              placement: 'top',
              status: Status.success
            })
            if (business) {
              navigation.reset({
                index: 0,
                routes: [{ name: AppRoutes.BusinessProxy, params: { business } }]
              })
            } else {
              navigation.reset({
                index: 0,
                routes: [{ name: AppRoutes.home, params: { screen: TabRoutes.Wallet } }]
              })
            }
          })
          .catch(Alert.showError)
      }
    }, [business, isLoading, markerLocation, navigation, token])

    return (
      <ScreenWrapper
        screenBackgroundColor={backgroundColor}
        statusBarBackgroundColor={backgroundColor}
      >
        <ScreenHeader
          headerLeftType={'close'}
          headerLeftPress={onClose}
          headerTextColor={headerIconColor}
          headerProps={{
            overflow: 'hidden'
          }}
          centerContainerProps={{
            flex: 1,
            paddingX: 2
          }}
          leftContainerProps={{
            flex: 0,
            _web: {
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto'
            }
          }}
          rightContainerProps={{
            flex: 0,
            _web: {
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto'
            }
          }}
          headerRight={() => <ScreenHeaderButton />}
        >
          <Box flexDirection={'column'} marginLeft={2} flex={1} justifyContent={'center'}>
            <Title preset="h5" variant="semibold" numberOfLines={1}>
              {translate('common.drop')}
            </Title>
          </Box>
        </ScreenHeader>
        <VStack paddingX={4} flexGrow={1}>
          {/* CONTENT */}
          <Map isOpen={true} onDrop={onDrop} onClose={onClose} isLoading={isLoading} />
          <ConfirmModal token={token} isOpen={isConfirmOpen} onCancel={onCancel} onDrop={doDrop} />
        </VStack>
      </ScreenWrapper>
    )
  })
)

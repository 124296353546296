/* eslint-disable react-native-a11y/has-accessibility-hint */
import React, { useState } from 'react'
import { FlatList, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { useQueries } from '@tanstack/react-query'
import { PublicProfileSnapshot } from '@vatom/sdk/core'
import { useGetSpace } from '@vatom/sdk/react'

import { withBusinessSelector } from '../../../hooks/useBusinessSelector'
import { useLayoutScrollHandler } from '../../../hooks/useLayoutScrollHandler'
import { AppRoutes, AppStackScreenProps } from '../../../navigators'
import BackArrow from '../assets/arrow-icons/left-arrow-icon.svg'
import SearchIcon from '../assets/header-icons/search-icon.svg'
import MemberItem from '../components/MemberItem'
import { fetchMemberData, useMembers } from '../queries'
import { useCommunitiesTheme } from '../themes'

type MembersScreenProps = AppStackScreenProps<
  typeof AppRoutes.CommunitiesRoomMembers | typeof AppRoutes.RoomMembers
>

const Members = (props: MembersScreenProps) => {
  const { spaceId, business } = props.route.params
  const space = useGetSpace(spaceId)
  const roomId = space.data?.matrixRoomId ?? ''
  const roomName = space.data?.displayName ?? ''
  const insets = useSafeAreaInsets()

  const { getFooterHeight } = useLayoutScrollHandler()

  const { data: roomMembers } = useMembers(roomId)
  const [searchString, setSearchString] = useState('')
  const navigation = useNavigation()

  const communitiesTheme = useCommunitiesTheme()

  const members = useQueries({
    queries:
      roomMembers?.map(member => {
        const userId = member.id.split('@').pop()?.split(':')[0]
        return {
          queryKey: ['member-data', userId],
          queryFn: () => fetchMemberData(userId),
          meta: {
            volatile: true
          },
          select: (data: { data: PublicProfileSnapshot }) => {
            const { name, picture, preferred_username } = data.data
            return {
              id: member.id,
              name,
              picture,
              tag: preferred_username
            }
          }
        }
      }) ?? []
  })

  let filtered = members.map(member => {
    return member.data
  })
  filtered =
    filtered.filter(
      member =>
        member?.name?.toLowerCase().includes(searchString.toLowerCase()) ||
        member?.tag?.toLowerCase().includes(searchString.toLowerCase())
    ) ?? []

  const onPressMember = (roomId: string, memberId: string) => {
    navigation.navigate(AppRoutes.profileOtherUser_Business, {
      business,
      userId: memberId?.match('@(.*):')?.[1] ?? '',
      spaceId
    })
  }

  return (
    <View
      style={{
        backgroundColor: communitiesTheme.background,
        paddingHorizontal: 16,
        height: '100%',
        paddingTop: insets.top
      }}
    >
      <View style={styles.header}>
        <TouchableOpacity
          accessibilityRole="button"
          onPress={() => {
            navigation.goBack()
          }}
          style={{ width: '100%', position: 'absolute', left: -16, top: 0 }}
        >
          <BackArrow fill={communitiesTheme.membersPage.title} />
        </TouchableOpacity>
        <View>
          <Text
            style={{ fontSize: 17, fontWeight: '600', color: communitiesTheme.membersPage.title }}
          >
            {roomMembers?.length} Members
          </Text>
          <Text
            style={[
              styles.secondaryText,
              { fontSize: 12, alignSelf: 'center' },
              { color: communitiesTheme.membersPage.sub }
            ]}
          >
            {roomName}
          </Text>
        </View>
      </View>
      <View style={[styles.input, { borderColor: communitiesTheme.input.border }]}>
        <SearchIcon
          style={{ marginRight: 9 }}
          fill={communitiesTheme.input.icon}
          height={18}
          width={14}
        />
        <TextInput
          accessibilityLabel="Text input field"
          value={searchString}
          onChangeText={setSearchString}
          placeholder="Search members"
          placeholderTextColor={'#CED4DA'}
          style={{ ...styles.inputText, color: communitiesTheme.input.text }}
        />
      </View>

      <FlatList
        data={filtered}
        renderItem={({ item }) =>
          item ? <MemberItem onPress={() => onPressMember(roomId, item.id)} member={item} /> : null
        }
        keyExtractor={(item, index) => `${item?.id}-${index}`}
        contentContainerStyle={{
          paddingBottom: getFooterHeight() + 60
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    width: '100%',
    borderRadius: 6,
    borderWidth: 1,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12
  },
  header: {
    height: 40,
    marginBottom: 18,
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 18,
    marginHorizontal: 12
  },
  secondaryText: {
    fontWeight: '500'
  },
  inputText: {
    fontSize: 15,
    paddingVertical: 10
  }
})

const MembersWithBusinessSelector = withBusinessSelector(Members)
export default MembersWithBusinessSelector

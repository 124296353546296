import React from 'react'
import { useWindowDimensions } from 'react-native'
import WebView from 'react-native-webview'
import { Entypo } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { SpaceType } from '@vatom/sdk/core'
import { PressableOpacity } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Flex, StatusBar } from 'native-base'

type Props = {
  space: SpaceType
}

export const SpaceWebview = observer((props: Props) => {
  const { height, width } = useWindowDimensions()
  const { space } = props
  /** Screen options */
  const navigation = useNavigation()

  const goBack = () => {
    navigation.goBack()
  }

  return (
    <Flex bg="white" flex={1}>
      <StatusBar barStyle="dark-content" />
      <Flex flex={1}>
        {/* <Box bg="brand.500" flexDirection="row" px={5} justifyContent="space-between" py={5}>
            <Pressable accessibilityRole="button" w="40px" alignItems="flex-start" onPress={goBack}>
              <Entypo name="chevron-small-left" size={40} color="white" />
            </Pressable>
          </Box> */}

        {/* <HeaderBackOptions showOptions={false} onClose={goBack} /> */}
        <Box
          bgColor="#000000"
          position="absolute"
          top={20}
          zIndex={999}
          w={'44px'}
          h={'44px'}
          borderRadius={'44px'}
          left={4}
          flexDirection="row"
          alignItems={'center'}
        >
          <PressableOpacity
            hitSlop={{
              top: 20,
              left: 20,
              bottom: 20,
              right: 20
            }}
            accessibilityRole="button"
            onPress={goBack}
          >
            <Entypo name="chevron-small-left" size={40} color="#FFFFFF" />
          </PressableOpacity>
        </Box>

        <WebView
          originWhitelist={['*']}
          source={{
            uri: `https://spaces.vatom.com/@${space.alias || space.id}?layout=embedded`
          }}
          style={{ flex: 1, width, height }}
          scalesPageToFit={false}
          javaScriptEnabled={true}
          allowsInlineMediaPlayback={true}
        />
        {/* <Pressable
            style={{ position: 'absolute', top: 45, left: 20 }}
            accessibilityRole="button"
            w="40px"
            alignItems="flex-start"
            onPress={goBack}
          >
            <Entypo name="chevron-small-left" size={40} color="white" />
          </Pressable> */}
      </Flex>
    </Flex>
  )
})
export default SpaceWebview

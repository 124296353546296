import { useQuery } from '@tanstack/react-query'
import * as Location from 'expo-location'

import { useRefreshOnFocus } from './useRefreshOnFocus'

export const useLocationPermission = () => {
  const {
    data: permission,
    isLoading,
    error,
    refetch,
    isFetching
  } = useQuery(
    ['locationPermission'],
    async () => {
      const permission = await Location.requestForegroundPermissionsAsync()
      return permission
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true
    }
  )

  useRefreshOnFocus(refetch)

  return {
    permission,
    isLoading,
    error,
    isFetching
  }
}

import React from 'react'
import DateTimePicker from 'react-datetime-picker'
import {
  Animated,
  Dimensions,
  Modal,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Switch,
  Text,
  TouchableWithoutFeedback,
  View
} from 'react-native'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import moment from 'moment-timezone'

import ArrowLeftIcon from '../../../../assets/arrow-icons/left-arrow-icon.svg'
import CloseIcon from '../../../../assets/header-icons/close-icon.svg'
import { getCombinedDateTimestamp } from '../../../../helpers'
import { useCommunitiesTheme } from '../../../../themes'
import ArrowRightIcon from '../../../modal-components/ArrowRightIcon'
import ModalText from '../../../modal-components/ModalText'
import {
  closeAnimation,
  ModalAnimatedWrapper,
  ModalGreyBackground,
  ModalHeaderWrapper,
  ModalSaveButton,
  ModalTitle,
  openAnimation
} from '../../../modalHelpers'
import { useStoreContext } from '../../../MSTContextProvider'
import CheckIcon from '../../assets/check_icon.svg'
import PollLengthIcon from '../../assets/poll_length_icon.svg'
import { Input } from '../../stores'
import { DecorationData } from '../ModalDecorations'
import ModalDecorationWrapper from '../ModalDecorationWrapper'
import ModalError from '../ModalError'

import { getDisplayDate, ModalRow, ModalRowText, SelectedValueText } from './components'
import { generalToIANAMapping } from './constants'

const getAllTimezones = (currentOffset: number) => {
  const formattedTimezones = []

  for (const key in generalToIANAMapping) {
    const tz = generalToIANAMapping[key as keyof typeof generalToIANAMapping]

    const offset = moment.tz(tz).utcOffset()
    let gmtString

    const environmentOffset = offset - currentOffset

    if (environmentOffset === 0) {
      gmtString = '(GMT)'
    } else {
      const hours = Math.floor(Math.abs(environmentOffset) / 60)
      const minutes = Math.abs(environmentOffset) % 60
      const sign = environmentOffset < 0 ? '-' : '+'
      gmtString = `(GMT${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )})`
    }

    formattedTimezones.push({ offset, key, name: `${key} ${gmtString}` })
  }

  return formattedTimezones
}

const ModalStartTimeHolder = observer(({ decorationData }: { decorationData: DecorationData }) => {
  const store = useStoreContext()

  const dateInput = store.additionalInputs.find(({ type }) => type === decorationData.type)
  const endTimeInput = store.additionalInputs.find(({ type }) => type === 'end-time')

  React.useEffect(() => {
    store.addAdditionalInputs(decorationData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return dateInput && endTimeInput ? (
    <ModalStartTime
      decorationData={decorationData}
      dateInput={dateInput}
      endTimeInput={endTimeInput}
    />
  ) : null
})

const ModalStartTime = observer(
  ({
    decorationData,
    dateInput,
    endTimeInput
  }: {
    decorationData: DecorationData
    dateInput: Instance<typeof Input>
    endTimeInput: Instance<typeof Input>
  }) => {
    const store = useStoreContext()
    const startTimeInputValue = dateInput.value as number
    const endTimeInputValue = endTimeInput.value as number

    const currentTimeZone = moment.tz.guess()
    const currentOffset = moment.tz(currentTimeZone).utcOffset()

    const formattedTimezones = getAllTimezones(currentOffset)

    const [isModalVisible, setIsModalVisible] = React.useState(false)
    const [startNow, setStartNow] = React.useState(!startTimeInputValue)
    const [date, setDate] = React.useState(new Date())
    const [time, setTime] = React.useState(new Date())
    const [isTimezone, setIsTimezone] = React.useState(false)
    const [timezone, setTimezone] = React.useState({
      key: formattedTimezones.find(timezone => timezone.offset === currentOffset)?.key,
      offset: currentOffset
    })
    const [showError, setShowError] = React.useState(false)
    const [errorData, setErrorData] = React.useState({
      errorTitle: '',
      errorDescription: ''
    })
    const communitiesTheme = useCommunitiesTheme()

    const isAndroid = Platform.OS === 'android'

    const getCurrentTimezoneFullKey = () => {
      return (
        Object.keys(generalToIANAMapping).find(
          key => generalToIANAMapping[key as keyof typeof generalToIANAMapping] === currentTimeZone
        ) ?? ''
      )
    }

    const mainPlaceholder = isTimezone === false ? decorationData.placeholder : 'Time Zone'

    React.useEffect(() => {
      const timezoneKey = getCurrentTimezoneFullKey()

      if (isModalVisible) {
        const dateInputValue = dateInput
          ? dateInput.value
            ? new Date(startTimeInputValue)
            : new Date()
          : new Date()

        const { key, offset } = store.timezone

        setDate(dateInputValue)
        setTime(dateInputValue)
        setTimezone({
          key: key ? key : timezoneKey,
          offset: key ? offset : currentOffset
        })
      } else {
        setDate(new Date())
        setTime(new Date())
        setTimezone({
          key: timezoneKey,
          offset: currentOffset
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalVisible])

    React.useEffect(() => {
      setTimezone(store.timezone)
    }, [store.timezone])

    React.useEffect(() => {
      store.addAdditionalInputs(decorationData)
      if (startNow) {
        const timestamp = new Date().getTime()
        dateInput.setValue(timestamp)
        store.setTimezone({
          key: timezone.key,
          offset: timezone.offset
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fadeAnim = React.useRef(new Animated.Value(0)).current
    const slideAnim = React.useRef(new Animated.Value(1000)).current
    const animationDuration = 300

    const openModal = () => {
      setIsModalVisible(true)
      openAnimation(fadeAnim, slideAnim, animationDuration).start()
    }

    const closeModal = () => {
      closeAnimation(fadeAnim, slideAnim, animationDuration).start(() => {
        setIsModalVisible(false)
      })
    }

    const handleDone = () => {
      if (startNow) {
        const timestamp = new Date().getTime()
        dateInput.setValue(timestamp)
        store.setTimezone({
          key: timezone.key,
          offset: timezone.offset
        })
        closeModal()
        return
      }

      const timestamp = getCombinedDateTimestamp(date, time)

      if (dateInput) {
        if (endTimeInputValue > 0) {
          if (endTimeInputValue < timestamp) {
            setShowError(true)
            setErrorData({
              errorTitle: 'Error',
              errorDescription: 'You cannot select a higher date than end date'
            })
            return
          }
        }

        dateInput.setValue(timestamp)
        store.setTimezone({
          key: timezone.key,
          offset: timezone.offset
        })
      }
      closeModal()
    }

    const onDateChange = (date?: Date) => {
      if (date) {
        setDate(date)
      }
    }

    const onTimeChange = (date?: Date) => {
      if (date) {
        setTime(date)
      }
    }
    const { height } = Dimensions.get('window')

    const getFirstLetters = (str: string) => {
      return str
        .split(' ')
        .map(word => word.charAt(0))
        .join('')
    }

    return (
      <>
        <ModalError
          showError={showError}
          errorTitle={errorData.errorTitle}
          errorDescription={errorData.errorDescription}
          setShowError={setShowError}
        />

        <ModalDecorationWrapper handler={() => openModal()}>
          <View style={styles.contentHolder}>
            <PollLengthIcon
              width="20"
              style={{ marginRight: 12 }}
              fill={communitiesTheme.labelIconColor}
            />
            <ModalText text={decorationData.placeholder} />
          </View>
          <View style={styles.contentHolder}>
            <SelectedValueText
              value={getDisplayDate(startTimeInputValue, store.timezone.offset, currentOffset)}
            />
            <ArrowRightIcon style={{ marginLeft: 12 }} />
          </View>
        </ModalDecorationWrapper>

        <Modal visible={isModalVisible && !showError} transparent={true} animationType="none">
          <ModalGreyBackground fadeAnim={fadeAnim} handler={closeModal} />

          <ModalAnimatedWrapper
            slideAnim={slideAnim}
            style={{ height: isAndroid ? height - 86 : height - 50, paddingTop: 19 }}
          >
            <ModalHeaderWrapper>
              <TouchableWithoutFeedback
                accessibilityRole="button"
                onPress={() => (isTimezone ? setIsTimezone(false) : closeModal())}
              >
                <View>
                  {isTimezone ? (
                    <ArrowLeftIcon fill={communitiesTheme.confirmationModal.title} />
                  ) : (
                    <CloseIcon
                      fill={communitiesTheme.confirmationModal.title}
                      width={20}
                      height={20}
                    />
                  )}
                </View>
              </TouchableWithoutFeedback>

              <ModalTitle placeholder={mainPlaceholder} />

              <ModalSaveButton handler={handleDone} text="Done" />
            </ModalHeaderWrapper>

            {!isTimezone ? (
              <ScrollView>
                <ModalRow style={styles.flexRow}>
                  <ModalRowText text="Start Immediately" />
                  <Switch
                    trackColor={{
                      false: communitiesTheme.labelTrackColor,
                      true: communitiesTheme.accentColor
                    }}
                    thumbColor={communitiesTheme.accentColorText}
                    onValueChange={value => setStartNow(value)}
                    value={startNow}
                    style={[{ transform: [{ scaleX: 0.6 }, { scaleY: 0.6 }] }, { width: 40 }]}
                  />
                </ModalRow>

                {!startNow && (
                  <ModalRow
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      zIndex: 1
                    }}
                  >
                    <ModalRowText text={'Select Start Date'} />
                    <View>
                      <DateTimePicker
                        onChange={value => {
                          onDateChange(value as Date)
                          onTimeChange(value as Date)
                        }}
                        value={date}
                        minDate={new Date()}
                        className={communitiesTheme.name}
                        locale="en-US"
                      />
                      <Text
                        style={{
                          fontSize: 13,
                          lineHeight: 18,
                          color: '#868E96',
                          marginRight: 12
                        }}
                      >
                        (
                        {getDisplayDate(
                          getCombinedDateTimestamp(date, time),
                          timezone.offset,
                          currentOffset
                        )}
                        )
                      </Text>
                    </View>
                  </ModalRow>
                )}

                <ModalRow
                  style={{ ...styles.flexRow, borderBottomWidth: 0 }}
                  onClick={() => setIsTimezone(true)}
                >
                  <ModalRowText text="Time Zone" />
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text
                      style={{
                        fontSize: 13,
                        lineHeight: 18,
                        color: '#868E96',
                        marginRight: 12
                      }}
                    >
                      {getFirstLetters(timezone.key ?? '')}
                    </Text>
                    <ArrowRightIcon />
                  </View>
                </ModalRow>
              </ScrollView>
            ) : (
              <SafeAreaView>
                <ScrollView style={{ marginTop: 12, marginBottom: 50 }}>
                  {formattedTimezones.map(tz => {
                    const { key, offset, name } = tz
                    return (
                      <ModalRow
                        key={key}
                        style={styles.flexRow}
                        onClick={() => {
                          setTimezone({ key, offset })
                          setIsTimezone(false)
                        }}
                      >
                        <ModalRowText text={name} />
                        {key === timezone.key ? (
                          <CheckIcon fill={communitiesTheme.accentColor} />
                        ) : null}
                      </ModalRow>
                    )
                  })}
                </ScrollView>
              </SafeAreaView>
            )}
          </ModalAnimatedWrapper>
        </Modal>
      </>
    )
  }
)

export default ModalStartTimeHolder

const styles = StyleSheet.create({
  contentHolder: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  flexRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  rowText: {
    fontSize: 15,
    lineHeight: 20
  },
  timezoneRow: {
    paddingVertical: 14,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

import React from 'react'
import { Dimensions, ImageSourcePropType } from 'react-native'
import { IImageProps, Image } from 'native-base'

interface WallpaperProps extends IImageProps {
  /**
   * An optional background image to override the default image.
   */
  backgroundImage: ImageSourcePropType
}

const defaultImage = require('./assets/bg.png')

/**
 * For displaying fullscreen image BG.
 *
 * This component is a HOC.
 */
export const Wallpaper = ({ backgroundImage = require('./assets/bg.png')! }: WallpaperProps) => {
  return (
    <Image
      source={backgroundImage}
      alt="wallpaper"
      h={Dimensions.get('window').height}
      w={Dimensions.get('window').width}
      accessibilityIgnoresInvertColors={true}
    />
  )
}

export default Wallpaper

import React from 'react'
import DeviceInfo from 'react-native-device-info'
import { REACT_APP_BUILD_NUMBER } from 'react-native-dotenv'
import { Text, theme } from '@vatom/wombo'

import { version } from '../../../package.json'

export const VersionInfo = () => {
  const buildNumber =
    REACT_APP_BUILD_NUMBER || DeviceInfo.getBuildNumber() || 'missing build number'

  return (
    <Text
      fontFamily={'Inter-Regular'}
      fontSize={11}
      lineHeight={15}
      color={theme.colors.grayCool[600]}
      textAlign="center"
      tx={'common.version'}
      txOptions={{ version, buildNumber }}
    />
  )
}

export default VersionInfo

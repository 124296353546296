import React from 'react'
import { Animated, Pressable, Text, View } from 'react-native'

import { useCommunitiesTheme } from '../themes'

export const openAnimation = (
  fadeAnim: Animated.Value,
  slideAnim: Animated.Value,
  duration: number
) => {
  return Animated.parallel([
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: duration,
      useNativeDriver: true
    }),
    Animated.timing(slideAnim, {
      toValue: 0,
      duration: duration,
      useNativeDriver: true
    })
  ])
}

export const closeAnimation = (
  fadeAnim: Animated.Value,
  slideAnim: Animated.Value,
  duration: number
) => {
  return Animated.parallel([
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: duration,
      useNativeDriver: true
    }),
    Animated.timing(slideAnim, {
      toValue: 1000,
      duration: duration,
      useNativeDriver: true
    })
  ])
}

export const ModalGreyBackground = ({
  fadeAnim,
  handler
}: {
  fadeAnim: Animated.Value
  handler: () => void
}) => {
  return (
    <Pressable accessibilityRole="button" onPress={() => handler()} style={{ flex: 1 }}>
      <Animated.View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: `rgba(0, 0, 0, 0.5)`,
          opacity: fadeAnim
        }}
      />
    </Pressable>
  )
}

export const ModalAnimatedWrapper = ({
  children,
  slideAnim,
  style
}: {
  children: React.ReactNode
  slideAnim: Animated.Value
  style: object
}) => {
  const communitiesTheme = useCommunitiesTheme()

  return (
    <Animated.View
      style={{
        position: 'absolute',
        bottom: 0,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        width: '100%',
        transform: [{ translateY: slideAnim }],
        backgroundColor: communitiesTheme.background,
        ...style
      }}
      pointerEvents="box-none"
    >
      {children}
    </Animated.View>
  )
}

export const ModalHeaderWrapper = ({
  style,
  children
}: {
  style?: object
  children: React.ReactNode
}) => {
  return (
    <View
      key="header"
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 16,
        ...style
      }}
    >
      {children}
    </View>
  )
}

export const ModalTitle = ({ placeholder }: { placeholder: string }) => {
  const communitiesTheme = useCommunitiesTheme()

  return (
    <Text
      key="modal-title"
      style={{
        fontSize: 17,
        lineHeight: 24,
        fontWeight: '600',
        color: communitiesTheme.confirmationModal.title
      }}
    >
      {placeholder}
    </Text>
  )
}

export const ModalSaveButton = ({ handler, text }: { handler: () => void; text: string }) => {
  return (
    <Pressable accessibilityRole="button" key="modal-save-button" onPress={() => handler()}>
      <Text style={{ fontSize: 15, lineHeight: 20, fontWeight: '400', color: '#2EA7FF' }}>
        {text}
      </Text>
    </Pressable>
  )
}

// import AsyncStorage from '@react-native-async-storage/async-storage'
import { applySnapshot, getSnapshot, Instance, SnapshotOut, types } from 'mobx-state-tree'

export enum NftFilterBy {
  All = 'all',
  SmartNft = 'smartNft',
  Wallet = 'wallet',
  Custodial = 'custodial'
}

// const NftFilterStoreKey = 'NftFilterStore'

export const NftFilterStore = types
  .model('NftFilterStore')
  .props({
    filterBy: types.optional(
      types.union(types.enumeration(Object.values(NftFilterBy)), types.string),
      NftFilterBy.All
    ),
    walletAddress: types.optional(types.string, ''),
    allowedCategories: types.optional(types.array(types.string), [])
  })
  .actions(self => ({
    setFilterBy(filterBy: NftFilterBy | string) {
      self.filterBy = filterBy
      // self.persist()
    },
    setWalletAddress(address: string) {
      self.walletAddress = address
      // self.persist()
    },
    addAllowedCategory(category: string) {
      if (!self.allowedCategories.includes(category)) {
        const toTitleCase = (str: string) => {
          return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
          })
        }

        self.allowedCategories.push(toTitleCase(category))
      }
    }
  }))

export type NftFilter = Instance<typeof NftFilterStore>
export type NftFilterSnaphot = SnapshotOut<typeof NftFilterStore>

/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * TwitterProfileBase
 * auto generated base class for the model TwitterProfileModel.
 */
export const TwitterProfileModelBase = ModelBase
  .named('TwitterProfile')
  .props({
    __typename: types.optional(types.literal("TwitterProfile"), "TwitterProfile"),
    handle: types.union(types.undefined, types.string),
    profileImageUrl: types.union(types.undefined, types.string),
    bannerImageUrl: types.union(types.undefined, types.string),
    description: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class TwitterProfileModelSelector extends QueryBuilder {
  get handle() { return this.__attr(`handle`) }
  get profileImageUrl() { return this.__attr(`profileImageUrl`) }
  get bannerImageUrl() { return this.__attr(`bannerImageUrl`) }
  get description() { return this.__attr(`description`) }
}
export function selectFromTwitterProfile() {
  return new TwitterProfileModelSelector()
}

export const twitterProfileModelPrimitives = selectFromTwitterProfile().handle.profileImageUrl.bannerImageUrl.description

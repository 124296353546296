import React from 'react'
import EmojiPicker from 'rn-emoji-keyboard'

type EmojiPickerProps = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  handlePick: (emoji: string) => Promise<void>
}

const EmojiPickerComponent = (props: EmojiPickerProps) => {
  const { isOpen, setIsOpen, handlePick } = props

  return (
    <EmojiPicker
      onEmojiSelected={value => handlePick(value.emoji)}
      open={isOpen}
      enableSearchBar={true}
      emojiSize={26}
      enableRecentlyUsed={true}
      categoryPosition={'bottom'}
      categoryOrder={['recently_used']}
      onClose={() => setIsOpen(false)}
      key="emoji-picker"
    />
  )
}

export default EmojiPickerComponent

import React, { useMemo } from 'react'
import { Linking } from 'react-native'
import { TokenType, TokenTypes } from '@vatom/sdk/core'
import { translate, TxKeyPath } from '@vatom/utils'
import { Text, theme } from '@vatom/wombo'

interface SecondaryTextProps {
  text?: string
  hasLearnMore?: boolean
  token?: TokenType
  linkAddress?: string | undefined
  isShare?: boolean
}

export const SecondaryText = ({
  text,
  hasLearnMore,
  token,
  linkAddress,
  isShare = false
}: SecondaryTextProps) => {
  const type = token?.getTokenType()
  const shareOrSend = isShare ? 'share' : 'send'

  const displayText = useMemo(() => {
    if (token) {
      if (type && Object.values(TokenTypes).includes(type)) {
        return translate(`shareNft.${type.toString()}` as TxKeyPath, { shareOrSend })
      }
      return translate('shareNft.defaultVatomText', { shareOrSend })
    } else {
      return text ?? ''
    }
  }, [shareOrSend, text, token, type])

  return (
    <Text
      w="100%"
      marginBottom={4}
      fontFamily="Inter-Regular"
      fontSize="15px"
      textAlign="center"
      color="#3F4A55"
      _light={{
        color: theme.colors.textLightMode[900]
      }}
      _dark={{
        color: theme.colors.textDarkMode[100]
      }}
    >
      {displayText}
      {hasLearnMore && (
        <Text
          _dark={{
            color: theme.colors.systemColorsDark.blue
          }}
          _light={{
            color: theme.colors.systemColorsLight.blue
          }}
          underline={true}
          onPress={() =>
            Linking.openURL(
              linkAddress
                ? linkAddress
                : 'https://support.vatom.com/hc/en-us/articles/8126382588571-Sending-Transferring-an-NFT'
            )
          }
        >
          {' '}
          {translate('common.learnMore')}
        </Text>
      )}
    </Text>
  )
}

export default SecondaryText

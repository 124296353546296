import React from 'react'
// import { useRooms } from '../../queries'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { Community } from '@vatom/sdk/react'
import { Image } from 'expo-image'

import { CommunitiesParamList, CommunitiesRoutes } from '../../../../navigators'
import RightArrow from '../../assets/arrow-icons/right-arrow-icon.svg'
import { useCommunitiesTheme } from '../../themes'

const CategoryRoom = (props: { community: Community }) => {
  const { community } = props
  const { name, icon, description } = community

  const communitiesTheme = useCommunitiesTheme()
  const navigation = useNavigation<NavigationProp<CommunitiesParamList>>()

  return (
    <TouchableOpacity
      accessibilityRole="button"
      onPress={() => {
        navigation.navigate(CommunitiesRoutes.CommunitiesHome, { community: community.id })
      }}
    >
      <View
        style={{
          ...styles.rowContainer,
          marginBottom: 8,
          marginTop: 8,
          justifyContent: 'space-between'
        }}
      >
        <View style={{ flexDirection: 'row' }}>
          <Image accessibilityIgnoresInvertColors source={icon} style={styles.roomImage} />
          <View>
            <Text style={[styles.roomName, { color: communitiesTheme.roomItem.name }]}>{name}</Text>
            <View style={styles.rowContainer}>
              <Text style={[styles.roomSubInfo, { color: communitiesTheme.roomItem.sub }]}>
                {description}
              </Text>
            </View>
          </View>
        </View>
        <RightArrow style={{ marginRight: 16 }} fill={communitiesTheme.roomItem.sub} />
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  roomSubInfo: {
    fontSize: 11,
    lineHeight: 15,
    textTransform: 'capitalize'
  },
  roomName: {
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20
  },
  roomImage: {
    height: 40,
    width: 40,
    marginLeft: 16,
    marginRight: 8,
    borderRadius: 4
  }
})

export default CategoryRoom

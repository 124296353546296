import { getRoot, IStateTreeNode } from 'mobx-state-tree'

import { RootSDKStoreType } from '../../RootSdk'
import { DataPool } from '../DataPoolStore'

/**
 * Adds a rootStore property to the node for a convenient
 * and strongly typed way for stores to access other stores.
 */
export const withDataPool = (self: IStateTreeNode) => ({
  views: {
    /**
     * The root store.
     */
    get dataPool(): DataPool {
      const root = getRoot<RootSDKStoreType>(self)
      return root.dataPool
    }
  }
})

import { z } from 'zod'

export const LocationModelSchema = z.object({
  country: z.string().optional().default('US'),
  latitude: z.number().optional().default(0),
  locality: z.string().optional().default(''),
  longitude: z.number().optional().default(0),
  postal_code: z.string().optional().default(''),
  region: z.string().optional().default('')
})

export type LocationModel = z.infer<typeof LocationModelSchema>
export type LocationModelInput = z.input<typeof LocationModelSchema>

export const UserModelSchema = z.object({
  bio: z.string().optional().default(''),
  default_business_id: z.string().nullable().optional(),
  default_space_id: z.string().nullable().optional(),
  email: z.string().optional().default(''),
  email_verified: z.boolean().optional().default(false),
  location: LocationModelSchema.optional().default({}),
  name: z.string().optional().default(''),
  phone_number: z.string().optional().default(''),
  phone_number_verified: z.boolean().optional().default(false),
  picture: z.string().optional().default(''),
  sub: z.string().optional().default(''),
  updated_at: z.number().optional().default(0),
  wallet_address: z.string().optional().default(''),
  website: z.string().optional().default(''),
  guest: z.boolean().optional().default(false),
  deferred_deeplink: z.string().nullable().optional()
})

export type UserModel = z.infer<typeof UserModelSchema>
export type UserModelInput = z.input<typeof UserModelSchema>

import React from 'react'
import { InputField } from '@vatom/wombo'
import { Box } from 'native-base'

import Error from './Error'
import LabelForm from './Label'
import { fieldPropsInterfaceItem } from './Types'

export default function DateField(props: fieldPropsInterfaceItem) {
  const [, year = '', month = '', day = ''] = props.value?.match?.(/^([^-]*)-([^-]*)-([^-]*)/) ?? []

  const onChangeMonth = (val: string) => {
    props.onChange(props.field.name, `${year}-${val}-${day}`)
  }

  const onChangeDay = (val: string) => {
    props.onChange(props.field.name, `${year}-${month}-${val}`)
  }

  const onChangeYear = (val: string) => {
    props.onChange(props.field.name, `${val}-${month}-${day}`)
  }

  return (
    <Box>
      <LabelForm {...props} />
      <Box flexDirection="row">
        <Box flex="1" mr={2}>
          <InputField
            flex={1}
            borderWidth="1"
            borderColor="rgb(196, 196, 196)"
            borderRadius="4px"
            placeholder="MM"
            onChangeText={(e: string) => onChangeMonth(e)}
            keyboardType="number-pad"
            min={1}
            max={12}
            maxLength={2}
            value={month}
          />
        </Box>
        <Box flex="1" mr={2}>
          <InputField
            flex={1}
            borderWidth="1"
            borderColor="rgb(196, 196, 196)"
            borderRadius="4px"
            placeholder="DD"
            onChangeText={(e: string) => onChangeDay(e)}
            min={1}
            max={31}
            maxLength={2}
            value={day}
            keyboardType="number-pad"
          />
        </Box>
        <Box flex="1">
          <InputField
            flex={1}
            borderWidth="1"
            borderColor="rgb(196, 196, 196)"
            borderRadius="4px"
            placeholder="YYYY"
            min={1000}
            max={9999}
            maxLength={4}
            onChangeText={(e: string) => onChangeYear(e)}
            keyboardType="number-pad"
            value={year}
          />
        </Box>
      </Box>
      <Error {...props} />
    </Box>
  )
}

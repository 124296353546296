import React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useGetSpace } from '@vatom/sdk/react'

import { AppNavigation, AppRoutes } from '../../../../../navigators'
import { getTimeToDisplay } from '../../../helpers'
import { useMemberData, useMessage } from '../../../queries'
import { useCommunitiesTheme } from '../../../themes'
import { useCommunitySpace } from '../../../useCommunitySpace'
import UserAvatar from '../../UserAvatar'
import { useMessageContext } from '../MessageContext'

import ElipsisButton from './header-components/ElipsisButton'
// import UserReputationCounter from './header-components/UserReputationCounter'

type HeaderProps = {
  isThread: boolean
  decorations: any
  isReplyModal?: boolean
  isReplyMessage?: boolean
  spaceId: string
}

const Header = ({ isThread, decorations, isReplyModal, isReplyMessage, spaceId }: HeaderProps) => {
  const space = useGetSpace(spaceId, {
    enabled: false
  })
  const roomId = space.data?.matrixRoomId ?? ''
  const { messageEventID } = useMessageContext()
  const { data: matrixMessage } = useMessage(roomId, messageEventID)
  const { businessId } = useCommunitySpace()

  // const reputationCounterData =
  //   decorations?.find((decoration: any) => decoration.type === 'reputation-counter') ?? undefined
  const {
    chatBubbleHeader: { text: textColor, userName: userNameColor }
  } = useCommunitiesTheme()

  const data = useMemberData(matrixMessage?.sender)

  const navigation = useNavigation<AppNavigation>()
  const navigateToProfile = () =>
    navigation.navigate(AppRoutes.profileOtherUser_Business, {
      business: businessId,
      userId: matrixMessage?.sender?.match('@(.*):')?.[1] ?? '',
      spaceId
    })

  const timeAgo = getTimeToDisplay(matrixMessage?.origin_server_ts)

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
        alignItems: 'center'
      }}
    >
      <View style={{ ...senderInforStyles.container, overflow: 'hidden' }}>
        <Pressable
          accessibilityRole="button"
          onPress={() => (isReplyModal ? null : navigateToProfile())}
        >
          <View>
            <UserAvatar
              memberId={matrixMessage?.sender}
              width={48}
              height={48}
              style={{ marginRight: 8, borderRadius: 100 }}
            />
          </View>
        </Pressable>

        <View style={{ flex: 1 }}>
          <View style={senderInforStyles.container}>
            <Pressable
              accessibilityRole="button"
              onPress={() => (isReplyModal ? null : navigateToProfile())}
              style={{ flexDirection: 'row', flex: 1, marginRight: 20 }}
            >
              {data?.name ? (
                <Text style={[senderInforStyles.memberName, { color: userNameColor }]}>
                  {data.name}
                </Text>
              ) : null}
              <Text
                style={[{ marginHorizontal: 4, ...senderInforStyles.text }, { color: textColor }]}
              >
                ·
              </Text>
              {data?.tag ? (
                <Text
                  style={{
                    ...senderInforStyles.text,
                    color: textColor,
                    flex: 1
                  }}
                  ellipsizeMode="tail"
                  numberOfLines={1}
                >
                  @{data.tag}
                </Text>
              ) : null}
            </Pressable>

            {(!isReplyModal && matrixMessage?.type && !isThread) || isReplyMessage ? (
              <ElipsisButton
                messageId={messageEventID}
                spaceId={spaceId}
                isThread={isThread}
                isReplyMessage={isReplyMessage}
              />
            ) : null}
            {/* {reputationCounterData ? <UserReputationCounter value={reputation} /> : null} */}
          </View>
          <Text
            style={{
              ...senderInforStyles.text,
              fontSize: 11,
              color: textColor
            }}
          >
            {timeAgo}
          </Text>
        </View>
      </View>
    </View>
  )
}

export default Header

const senderInforStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  memberName: {
    fontSize: 15,
    fontWeight: 'bold',
    flexShrink: 0
  },
  text: {
    fontSize: 13
  }
})

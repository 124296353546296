import * as React from 'react'
import { ImageStyle, View } from 'react-native'

import { AutoImage as Image } from '../AutoImage'

import { icons } from './icons'
import { IconProps } from './index.props'

const ROOT: ImageStyle = {
  resizeMode: 'contain'
}

export const Icon = ({ style: styleOverride, icon = 'bug', containerStyle }: IconProps) => {
  return (
    <View style={containerStyle}>
      <Image style={[ROOT, styleOverride]} source={icons[icon]} accessibilityIgnoresInvertColors />
    </View>
  )
}

export default Icon

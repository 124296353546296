/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { BidReceiptModel, BidReceiptModelType } from "./BidReceiptModel"
import { BidReceiptModelSelector } from "./BidReceiptModel.base"
import { TwitterProfileModel, TwitterProfileModelType } from "./TwitterProfileModel"
import { TwitterProfileModelSelector } from "./TwitterProfileModel.base"
import { RootStoreType } from "./index"


/**
 * OfferEventBase
 * auto generated base class for the model OfferEventModel.
 */
export const OfferEventModelBase = ModelBase
  .named('OfferEvent')
  .props({
    __typename: types.optional(types.literal("OfferEvent"), "OfferEvent"),
    createdAt: types.union(types.undefined, types.frozen()),
    walletAddress: types.union(types.undefined, types.string),
    profile: types.union(types.undefined, types.null, types.late((): any => TwitterProfileModel)),
    feedEventId: types.union(types.undefined, types.string),
    lifecycle: types.union(types.undefined, types.string),
    bidReceiptAddress: types.union(types.undefined, types.frozen()),
    offer: types.union(types.undefined, types.null, types.late((): any => BidReceiptModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class OfferEventModelSelector extends QueryBuilder {
  get createdAt() { return this.__attr(`createdAt`) }
  get walletAddress() { return this.__attr(`walletAddress`) }
  get feedEventId() { return this.__attr(`feedEventId`) }
  get lifecycle() { return this.__attr(`lifecycle`) }
  get bidReceiptAddress() { return this.__attr(`bidReceiptAddress`) }
  profile(builder: string | TwitterProfileModelSelector | ((selector: TwitterProfileModelSelector) => TwitterProfileModelSelector) | undefined) { return this.__child(`profile`, TwitterProfileModelSelector, builder) }
  offer(builder: string | BidReceiptModelSelector | ((selector: BidReceiptModelSelector) => BidReceiptModelSelector) | undefined) { return this.__child(`offer`, BidReceiptModelSelector, builder) }
}
export function selectFromOfferEvent() {
  return new OfferEventModelSelector()
}

export const offerEventModelPrimitives = selectFromOfferEvent().createdAt.walletAddress.feedEventId.lifecycle.bidReceiptAddress

import React from 'react'
import { Animated, Platform } from 'react-native'
import { IPressableProps, Pressable } from 'native-base'

interface PressableOpacityProps extends IPressableProps {
  children?: React.ReactNode
}

export const PressableOpacity = (props: PressableOpacityProps) => {
  const { children, ...rest } = props
  const animated = new Animated.Value(1)
  const fadeIn = () => {
    Animated.timing(animated, {
      toValue: 0.1,
      duration: 100,
      useNativeDriver: Platform.OS !== 'web' ? true : false
    }).start()
  }
  const fadeOut = () => {
    Animated.timing(animated, {
      toValue: 1,
      duration: 200,
      useNativeDriver: Platform.OS !== 'web' ? true : false
    }).start()
  }

  return (
    <Pressable onPressIn={fadeIn} onPressOut={fadeOut} {...rest}>
      <Animated.View style={{ opacity: animated }}>{children}</Animated.View>
    </Pressable>
  )
}

import AsyncStorage from '@react-native-async-storage/async-storage'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
// For adding new channels follow the numbers

export const Channels = {
  wallet: 'wallet',
  autoOpen: 'autoOpen',
  invited: 'invited'
  // 1) Add new channel name here
} as const

type AutoOpenChannelRecord = {
  objectDefinitionId?: string
  id: string
}

// 2) Add new channel record type here
type ChannelMap = {
  [Channels.wallet]: string[]
  [Channels.autoOpen]: AutoOpenChannelRecord[]
  [Channels.invited]: string[]
}

const initialChannels: ChannelMap = {
  [Channels.wallet]: [],
  [Channels.autoOpen]: [],
  [Channels.invited]: []
  // 3) Add new channel initial value here
}

type Channel = keyof typeof Channels

type NotificationStore = {
  channels: typeof initialChannels
  cleanChannel: (channel: Channel) => void
  addNotification: <T extends Channel>(channel: T, value: typeof initialChannels[T][number]) => void
}

export const useNotificationsStore = create(
  persist<NotificationStore>(
    set => {
      return {
        channels: initialChannels,
        cleanChannel: channel =>
          set(state => {
            return {
              channels: {
                ...state.channels,
                [channel]: []
              }
            }
          }),
        addNotification: (channel, value) =>
          set(state => {
            return {
              channels: {
                ...state.channels,
                [channel]: [...state.channels[channel], value]
              }
            }
          })
      }
    },
    {
      name: 'channel-storage',
      getStorage: () => AsyncStorage,
      version: 2
    }
  )
)

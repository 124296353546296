import React, { useCallback, useImperativeHandle, useRef } from 'react'
import Slider from 'react-slick'
import { Box } from 'native-base'

import { type ImagePagerRef, ImagePagerProps } from './ImagePager'
import { ImagePagerItem } from './ImagePagerItem'

const ImagePagerWeb = React.memo(
  React.forwardRef<ImagePagerRef, ImagePagerProps>(({ images, onSelected }, forwardedRef) => {
    const sliderRef = useRef<Slider | null>(null)

    const setPage = useCallback((index: number) => {
      sliderRef.current && sliderRef.current.slickGoTo(index)
    }, [])

    useImperativeHandle(
      forwardedRef,
      () => {
        return {
          setPage
        }
      },
      [setPage]
    )

    return (
      <Box flexGrow={1}>
        <Slider
          ref={sliderRef}
          initialSlide={0}
          slidesToShow={1}
          slidesToScroll={1}
          afterChange={onSelected}
        >
          {images.map((image, index) => {
            return <ImagePagerItem key={index} image={image} />
          })}
        </Slider>
      </Box>
    )
  })
)
export { ImagePagerWeb as ImagePager }

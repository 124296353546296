import React from 'react'
import { Avatar, theme } from '@vatom/wombo'
import { Box } from 'native-base'

import AvatarSVG from '../../../assets/avatar.svg'

function ProfileAvatar(props: React.ComponentProps<typeof Avatar>) {
  if (!props.url) {
    return (
      <Box
        backgroundColor={theme.colors.white}
        borderRadius={999}
        borderWidth={5}
        borderColor={theme.colors.white}
        shadow="9"
      >
        <AvatarSVG width={100} height={100} />
      </Box>
    )
  }
  return (
    <Box
      backgroundColor={theme.colors.white}
      borderRadius={999}
      borderWidth={5}
      borderColor={theme.colors.white}
      shadow="9"
    >
      <Avatar size="110px" url={props.url} nbAvatarSize={'105%'} />
    </Box>
  )
}

export { ProfileAvatar }

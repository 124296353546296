import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { Text } from '@vatom/wombo'
import { Box, Pressable } from 'native-base'

import { AppRoutes } from '../../../navigators'

export interface IActionItem {
  onPress: () => void
  title: string
}

export const Footer = () => {
  const navigation = useNavigation()

  return (
    <Box position="absolute" bottom={0} width="100%">
      <Pressable
        accessibilityRole="button"
        onPress={() => navigation.navigate(AppRoutes.connect)}
        alignItems="center"
        borderColor="gray.200"
        borderWidth={1}
        h={38}
        justifyContent="center"
        mb={3}
        mx={4}
      >
        <Text fontWeight="500" fontSize={13} color="red.500">
          Log Out
        </Text>
      </Pressable>
      <Text
        color="gray.300"
        fontSize={11}
        fontWeight="400"
        lineHeight={30}
        textAlign="center"
        tx={'common.version'}
        txOptions={{ version: '2.4.1', buildNumber: 'SDK 3.4.8' }}
      />
    </Box>
  )
}

export default Footer

import web3 from 'web3'

export function isValidEthereumAddress(address: string) {
  // Check if it has the basic requirements of an address
  if (!/^(0x)?[0-9a-fA-F]{40}$/.test(address)) {
    return false
  }

  // If it's all small caps or all caps, return true
  if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
    return true
  }

  // Otherwise, check each case
  return isChecksumAddress(address)
}

// Checks if the address is checksummed
function isChecksumAddress(address: string) {
  // Remove the "0x" prefix
  address = address.replace('0x', '')

  // Convert address to lowercase and hash it
  const addressHash = web3.utils.sha3(address.toLowerCase())

  for (let i = 0; i < 40; i++) {
    // The nth letter should be uppercase if the nth digit of hash is 8-15
    if (
      (parseInt(addressHash?.[i] ?? '', 16) > 7 && address[i].toUpperCase() !== address[i]) ||
      (parseInt(addressHash?.[i] ?? '', 16) <= 7 && address[i].toLowerCase() !== address[i])
    ) {
      return false
    }
  }
  return true
}

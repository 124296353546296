import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const EditIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 18 18" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M13.3438 2.25L11.6562 3.96875L14.0312 6.34375L15.75 4.65625C16.0312 4.34375 16.0312 3.875 15.75 3.59375L14.4062 2.25C14.125 1.96875 13.6562 1.96875 13.3438 2.25ZM10.9375 4.65625L4.09375 11.5H4.5C4.75 11.5 5 11.75 5 12V13H6C6.25 13 6.5 13.25 6.5 13.5V13.9062L13.3438 7.0625L10.9375 4.65625ZM3.0625 12.875L2.21875 15.7812L5.125 14.9375C5.25 14.875 5.375 14.8438 5.5 14.7812V14H4.5C4.21875 14 4 13.7812 4 13.5V12.5H3.21875C3.15625 12.625 3.125 12.75 3.0625 12.875ZM15.125 1.53125L16.4688 2.875C17.125 3.5625 17.125 4.65625 16.4688 5.34375L6.6875 15.125C6.3125 15.4688 5.875 15.75 5.40625 15.875L1.625 17C1.4375 17.0312 1.25 17 1.125 16.875C1 16.75 0.96875 16.5625 1 16.375L2.125 12.5938C2.25 12.125 2.53125 11.6875 2.875 11.3125L12.6562 1.53125C13.3438 0.875 14.4375 0.875 15.125 1.53125ZM11.3438 7.34375L7.34375 11.3438C7.15625 11.5312 6.8125 11.5312 6.625 11.3438C6.4375 11.1562 6.4375 10.8438 6.625 10.6562L10.625 6.65625C10.8125 6.4375 11.1562 6.4375 11.3438 6.65625C11.5312 6.84375 11.5312 7.15625 11.3438 7.34375Z"
    />
  </Icon>
)

import React, { forwardRef, useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { actions, RichEditor, RichToolbar } from 'react-native-pell-rich-editor'
import { observer } from 'mobx-react-lite'

import { useCommunitiesTheme } from '../../../themes'
import { useStoreContext } from '../../MSTContextProvider'
import CloseRichEditorIcon from '../assets/poll_delete_choice_icon2.svg'
// import EmojiIcon from '../assets/toolbar_emoji_icon.svg'
import ImageIcon from '../assets/toolbar_image_icon.svg'
// import MicIcon from '../assets/toolbar_mic_icon.svg'
import SendIcon from '../assets/toolbar_send_icon.svg'
import VideoIcon from '../assets/toolbar_stream_video_icon.svg'
import TextIcon from '../assets/toolbar_text_icon.svg'

import BoldIcon from './../assets/texteditor/bold.svg'
import CutIcon from './../assets/texteditor/cut.svg'
import ItalicIcon from './../assets/texteditor/italic.svg'
import LinkIcon from './../assets/texteditor/link.svg'
import OLIcon from './../assets/texteditor/ol.svg'
import ULIcon from './../assets/texteditor/ul.svg'
import ModalAddVideo from './ModalAddVideo'
import ModalImagePicker from './ModalImagePicker'

type ModalToolbarProps = {
  type: string
  isThread?: boolean
  sendMessage?: () => Promise<void> | void
  hideShowButton?: boolean
  hideImagesModal?: boolean
}

const ModalToolbar = observer(
  forwardRef<RichEditor, ModalToolbarProps>((props, ref) => {
    const { type, sendMessage, isThread, hideShowButton, hideImagesModal } = props
    const store = useStoreContext()

    const hasContent = store.inputs.some(({ value }) =>
      typeof value === 'string'
        ? value
            .replaceAll(/<\/?[^>]+(>|$)/gi, '')
            .replace(/&nbsp;/g, '')
            .replace(/\s/g, '').length > 0
        : null
    )
    const [showRichTextEditor, setShowRichTextEditor] = useState(false)
    const [showVideoModal, setShowVideoModal] = useState(false)

    const bold = ({ tintColor }: { tintColor: string }) => <BoldIcon fill={tintColor} />
    const cut = ({ tintColor }: { tintColor: string }) => <CutIcon fill={tintColor} />
    const italic = ({ tintColor }: { tintColor: string }) => <ItalicIcon fill={tintColor} />
    const link = ({ tintColor }: { tintColor: string }) => <LinkIcon fill={tintColor} />
    const ol = ({ tintColor }: { tintColor: string }) => <OLIcon fill={tintColor} />
    const ul = ({ tintColor }: { tintColor: string }) => <ULIcon fill={tintColor} />

    const getInputValue = (inputType: string) =>
      additionalInputs.find(({ type }) => type === inputType)?.value

    const hasMedia = store.media.length > 0
    let canSend = hasContent || hasMedia
    const { additionalInputs } = store
    if (type === 'v.room.poll') {
      const scoreStyle = (getInputValue('score-style') ?? '') as string
      const choices = store.pollChoices.filter(input => input.value)
      const pollLength = (getInputValue('poll-length') ?? 0) as number
      if (scoreStyle === 'single-choice-poll') {
        canSend = (hasContent || hasMedia) && choices.length > 1 && pollLength > 0
      } else {
        canSend = (hasContent || hasMedia) && pollLength > 0
      }
    }
    if (type === 'v.room.score') {
      const scoreStyle = (getInputValue('score-style') ?? '') as string
      const scoreLength = (getInputValue('poll-length') ?? 0) as number
      canSend = (hasContent || hasMedia) && !!scoreStyle && scoreLength > 0
    }
    if (type === 'v.room.sketch') {
      const startTime = (getInputValue('start-time') ?? 0) as number
      const endTime = (getInputValue('end-time') ?? 0) as number
      canSend = hasContent && store.questions.length > 0 && startTime > 0 && endTime > 0
    }
    if (type === 'v.room.vote') {
      const startTime = (getInputValue('start-time') ?? 0) as number
      const endTime = (getInputValue('end-time') ?? 0) as number
      canSend = hasContent && store.sketches.length > 0 && startTime > 0 && endTime > 0
    }
    if (type === 'question') {
      const scoreStyle = getInputValue('score-style')
      canSend = hasContent && !!scoreStyle
    }

    const [imagePicker, setImagePicker] = useState({
      show: false,
      type: ''
    })
    const communitiesTheme = useCommunitiesTheme()

    // React.useEffect(() => {
    //   setInputStateRef(inputRef)
    // }, [inputRef])

    return (
      <View
        style={{
          paddingVertical: 10,
          backgroundColor: communitiesTheme.modalToolBar.background,
          width: '100%',
          borderTopColor: communitiesTheme.modalToolBar.border,
          borderTopWidth: 1
        }}
      >
        <ModalImagePicker imagePicker={imagePicker} setImagePicker={setImagePicker} />
        <ModalAddVideo showVideoModal={showVideoModal} setShowVideoModal={setShowVideoModal} />

        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          {!showRichTextEditor && (
            <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 12 }}>
              {hideImagesModal ? null : (
                <>
                  <Pressable
                    accessibilityRole="button"
                    onPress={() =>
                      !isThread ? setImagePicker({ show: true, type: 'photo' }) : null
                    }
                    key="media"
                  >
                    <View style={styles.iconHolder}>
                      <ImageIcon />
                    </View>
                  </Pressable>
                  {!isThread ? (
                    <Pressable
                      accessibilityRole="button"
                      onPress={() => setShowVideoModal(true)}
                      key="video-link"
                    >
                      <View style={styles.iconHolder}>
                        <VideoIcon fill={'#868E96'} />
                      </View>
                    </Pressable>
                  ) : null}
                </>
              )}

              {/* <EmojiIcon style={{ marginRight: 22 }} /> */}
              <Pressable
                key="rich-editor"
                accessibilityRole="button"
                // @ts-expect-error we know current exist so I'm not sure how to fix this
                onPress={() => (ref?.current ? setShowRichTextEditor(true) : null)}
              >
                <View style={styles.iconHolder}>
                  <TextIcon />
                </View>
              </Pressable>
              {/* {inputStateRef ? (
              <TouchableWithoutFeedback
                accessibilityRole="button"
                onPress={() => {
                  inputStateRef.current ? setShowRichTextEditor(true) : null
                }}
                key="rich-editor"
              >
                <View style={styles.iconHolder}>
                  <TextIcon />
                </View>
              </TouchableWithoutFeedback>
            ) : null} */}
            </View>
          )}

          {showRichTextEditor && store.inputs.length > 0 && (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Pressable
                accessibilityRole="button"
                onPress={() => {
                  setShowRichTextEditor(false)
                }}
              >
                <View
                  style={[
                    {
                      marginLeft: 16,
                      marginRight: 8,
                      height: 40,
                      width: 40,
                      borderRadius: 20,
                      justifyContent: 'center',
                      alignItems: 'center'
                    },
                    { backgroundColor: communitiesTheme.toolbar.closeButtonBackground }
                  ]}
                >
                  <CloseRichEditorIcon fill={communitiesTheme.toolbar.closeIconColor} />
                </View>
              </Pressable>
              <RichToolbar
                style={{ backgroundColor: communitiesTheme.modalToolBar.background }}
                editor={ref}
                selectedIconTint="#3F4A55"
                iconTint={communitiesTheme.toolbar.textColor}
                backgroundColor="red"
                actions={[
                  actions.setBold,
                  actions.setItalic,
                  actions.setStrikethrough,
                  actions.insertLink,
                  actions.insertBulletsList,
                  actions.insertOrderedList
                ]}
                iconMap={{
                  [actions.setBold]: bold,
                  [actions.setItalic]: italic,
                  [actions.setStrikethrough]: cut,
                  [actions.insertLink]: link,
                  [actions.insertBulletsList]: ul,
                  [actions.insertOrderedList]: ol
                }}
              />
            </View>
          )}

          {hideShowButton ? null : (
            <View style={{ marginLeft: 'auto' }}>
              {!isThread ? (
                <Pressable
                  accessibilityRole="button"
                  onPress={() => (canSend ? (sendMessage ? sendMessage() : null) : null)}
                >
                  <View
                    style={[
                      styles.button,
                      {
                        backgroundColor: canSend
                          ? communitiesTheme.accentColor
                          : communitiesTheme.replyMessageScreen.inactiveSendButtonBackground
                      }
                    ]}
                  >
                    <SendIcon
                      fill={
                        canSend
                          ? communitiesTheme.accentColorText
                          : communitiesTheme.replyMessageScreen.inactiveSendButtonColor
                      }
                    />
                  </View>
                </Pressable>
              ) : null}
            </View>
          )}
        </View>
      </View>
    )
  })
)

export default ModalToolbar

const styles = StyleSheet.create({
  button: {
    width: 40,
    height: 40,
    borderRadius: 40,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16
  },
  iconHolder: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center'
  }
})

import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useMatrixUser } from '@vatom/sdk/react'
import axios from 'axios'
import { IEvent } from 'matrix-js-sdk'

import { matrixServerUrl } from '../../../constants'
import { useMessagePollVotes } from '../../../queries'
import { EmojiStyle, NumberedStyle, StarStyle } from '../../ScoreStyles'
import { useMessageContext } from '../MessageContext'

import ProgressBar from './score-components/ProgressBar'
import ProgressBarHeader from './score-components/ProgressBarHeader'

const Score = (props: any) => {
  const { data: matrixUser } = useMatrixUser()
  const userId = matrixUser?.user_id
  const accessToken = matrixUser?.access_token
  const { roomId, additionalInfo } = props
  const { messageEventID } = useMessageContext()

  const { hideResults, scoreStyle, responseAnchors, pollLength } = additionalInfo

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useMessagePollVotes(
    roomId,
    messageEventID,
    'score.vote'
  )
  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage()
  }

  const pollVotes = data?.pages.flatMap(group => {
    return group?.chunk?.map((pollVote: IEvent) => ({
      index: pollVote.content.body.index,
      sender: pollVote.sender,
      eventId: pollVote.event_id
    }))
  })

  if (!pollVotes) {
    return null
  }

  const addVote = async (choice: any) => {
    await axios.post(
      `${matrixServerUrl}/_matrix/client/r0/rooms/${roomId}/send/v.room.score.vote?access_token=${accessToken}`,
      {
        'm.relates_to': {
          rel_type: 'score.vote',
          event_id: messageEventID
        },
        body: {
          index: choice.index
        }
      }
    )
  }

  const removeVote = async (choice: any) => {
    const dateNow = Date.now()
    await axios.put(
      `${matrixServerUrl}/_matrix/client/r0/rooms/${roomId}/redact/${choice.eventId}/${messageEventID}.score.${dateNow}?access_token=${accessToken}`,
      {}
    )
  }

  const handleVote = async (choice: any) => {
    const userVote = pollVotes.find((pollVote: any) => pollVote.sender === userId)
    if (userVote) {
      await removeVote(userVote)
      if (userVote.index !== choice.index) {
        await addVote(choice)
      }
    } else {
      await addVote(choice)
    }
  }

  const displayScoreStyle = (style: string) => {
    switch (style) {
      case 'numbered-1-5':
        return {
          styleComponent: (
            <NumberedStyle
              length={5}
              isInChatBubble
              handleVote={handleVote}
              pollVotes={pollVotes}
              userId={userId}
              pollLength={pollLength}
            />
          ),
          choices: Array.from({ length: 5 }, (_, i) => i + 1)
        }
      case 'numbered-0-5':
        return {
          styleComponent: (
            <NumberedStyle
              fromZero
              length={5}
              isInChatBubble
              handleVote={handleVote}
              pollVotes={pollVotes}
              userId={userId}
              pollLength={pollLength}
            />
          ),
          choices: Array.from(Array(6).keys())
        }
      case 'numbered-1-10':
        return {
          styleComponent: (
            <NumberedStyle
              length={10}
              isInChatBubble
              handleVote={handleVote}
              pollVotes={pollVotes}
              userId={userId}
              pollLength={pollLength}
            />
          ),
          choices: Array.from({ length: 10 }, (_, i) => i + 1)
        }
      case 'numbered-0-10':
        return {
          styleComponent: (
            <NumberedStyle
              fromZero
              length={10}
              isInChatBubble
              handleVote={handleVote}
              pollVotes={pollVotes}
              userId={userId}
              pollLength={pollLength}
            />
          ),
          choices: Array.from(Array(11).keys())
        }
      case 'emojies':
        return {
          styleComponent: (
            <EmojiStyle
              isInChatBubble
              handleVote={handleVote}
              pollVotes={pollVotes}
              userId={userId}
              pollLength={pollLength}
            />
          ),
          choices: Array.from({ length: 5 }, (_, i) => i + 1)
        }
      case 'stars':
        return {
          styleComponent: (
            <StarStyle
              isInChatBubble
              handleVote={handleVote}
              pollVotes={pollVotes}
              userId={userId}
              pollLength={pollLength}
            />
          ),
          choices: Array.from({ length: 5 }, (_, i) => i + 1)
        }
      default:
        return {
          styleComponent: null,
          choices: []
        }
    }
  }

  const { styleComponent, choices } = displayScoreStyle(scoreStyle)

  return (
    <View style={{ marginTop: 20 }}>
      {styleComponent}
      {responseAnchors ? <Text style={styles.responseAnchor}>{responseAnchors}</Text> : null}
      {!hideResults ? (
        <View style={{ marginTop: 18 }}>
          {choices.map(item => {
            let fillBarLength = 0
            let choiceVotes = []
            if (pollVotes.length > 0) {
              choiceVotes = pollVotes.filter((pollVote: any) => pollVote.index === item)
              fillBarLength = (choiceVotes.length / pollVotes.length) * 100
            }

            return (
              <View key={item} style={styles.progressBarHolder}>
                <ProgressBarHeader scoreStyle={scoreStyle} item={item} />
                <ProgressBar fillBarLength={fillBarLength} />
              </View>
            )
          })}
          <Text style={{ ...styles.responseAnchor, marginTop: 8 }}>
            Results based on {pollVotes.length} {pollVotes.length === 1 ? 'response' : 'responses'}
          </Text>
        </View>
      ) : null}
    </View>
  )
}

export default Score

const styles = StyleSheet.create({
  responseAnchor: {
    fontSize: 11,
    lineHeight: 15,
    color: '#868E96'
  },
  progressBarHolder: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 2
  }
})

import React, { useMemo } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import moment from 'moment'

import { useCommunitiesTheme } from '../themes'

const Border = () => {
  const communitiesTheme = useCommunitiesTheme()
  return <View key="left-border" style={styles(communitiesTheme.dayBanner.border).border} />
}

const DayBanner = ({ date }: { date: string }) => {
  const communitiesTheme = useCommunitiesTheme()
  const formattedDate = useMemo(
    () => moment(date, 'ddd MMM DD YYYY').format('dddd, MMMM DD'),
    [date]
  )

  return (
    <View
      style={{
        backgroundColor: communitiesTheme.background,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row'
      }}
    >
      <Border key="left-border" />
      <Text key="text" style={styles('', communitiesTheme.roomItem.sub).text}>
        {formattedDate}
      </Text>
      <Border key="right-border" />
    </View>
  )
}

export default DayBanner

const styles = (borderColor?: string, textColor?: string) =>
  StyleSheet.create({
    border: {
      width: '100%',
      height: 1,
      backgroundColor: borderColor,
      flex: 1
    },
    text: {
      color: textColor,
      marginHorizontal: 20
    }
  })

import { ARGameType, ARGameTypes, isVatom } from '@vatom/BVatom/plugin'
import { useTokenInventory } from '@vatom/sdk/react'
import { observer } from 'mobx-react-lite'

import { CollectionGameV1 } from './games/CollectionGameV1/CollectionGameV1'
import { PreGame } from './legacy/PreGame'
import { ARGameProps } from './partials/ARGameProps'

const LEGACY_GAMES: Array<ARGameType> = [
  ARGameTypes.GeoGame,
  ARGameTypes.MultiLevelGeoGame,
  ARGameTypes.SimpleGame
]

const GAMES: Record<string, React.FunctionComponent<ARGameProps>> = {
  [ARGameTypes.CollectionGameV1]: CollectionGameV1
}

export const ARGame = observer((props: ARGameProps) => {
  const { route } = props
  const { tokenId } = route.params

  const { token } = useTokenInventory(tokenId)
  const vatom = token && isVatom(token) ? token : null
  if (!vatom || !vatom.gameType) {
    console.error('Vatom required for playing an ar game', {
      vatom,
      gameType: vatom?.gameType
    })
    throw new Error('Vatom required for playing an ar game')
  }
  console.log('ar games info', {
    vatom,
    config: vatom?.gameConfig,
    gameUrl: vatom?.gameType
  })

  if (LEGACY_GAMES.includes(vatom.gameType)) {
    return <PreGame {...props} />
  }

  const Game = GAMES[vatom.gameType] ?? null
  if (!Game) {
    throw new Error(`Game not found for game type ${vatom.gameType}`)
  }
  return <Game {...props} />
})

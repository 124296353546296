import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const AccountIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 20 17" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M8.5625 10C11.5625 10 14 12.4375 14 15.4375C14 16.0312 13.5 16.5 12.9062 16.5H1.0625C0.46875 16.5 0 16.0312 0 15.4375C0 12.4375 2.40625 10 5.40625 10H8.5625ZM12.9062 15.5C12.9375 15.5 13 15.4688 13 15.4375C13 13 11 11 8.5625 11H5.40625C2.96875 11 1 13 1 15.4375C1 15.4688 1.03125 15.5 1.0625 15.5H12.9062ZM7 8.5C4.78125 8.5 3 6.71875 3 4.5C3 2.3125 4.78125 0.5 7 0.5C9.1875 0.5 11 2.3125 11 4.5C11 6.71875 9.1875 8.5 7 8.5ZM7 1.5C5.34375 1.5 4 2.875 4 4.5C4 6.15625 5.34375 7.5 7 7.5C8.625 7.5 10 6.15625 10 4.5C10 2.875 8.625 1.5 7 1.5ZM11.7188 8.03125C11.5 7.90625 11.4062 7.59375 11.5625 7.375C11.6875 7.125 12 7.03125 12.25 7.1875C12.625 7.40625 13.0312 7.5 13.5 7.5C14.875 7.5 16 6.40625 16 5C16 3.625 14.875 2.5 13.5 2.5C13.1875 2.5 12.9062 2.5625 12.6562 2.65625C12.4062 2.75 12.0938 2.625 12 2.34375C11.9062 2.09375 12.0625 1.8125 12.3125 1.71875C12.6875 1.59375 13.0938 1.5 13.5 1.5C15.4062 1.5 17 3.09375 17 5C17 6.9375 15.4062 8.5 13.5 8.5C12.875 8.5 12.25 8.34375 11.7188 8.03125ZM15.3125 10.5C17.9062 10.5 20 12.5938 20 15.1875C20 15.9062 19.375 16.5 18.6562 16.5H15.5C15.2188 16.5 15 16.2812 15 16C15 15.75 15.2188 15.5 15.5 15.5H18.6562C18.8438 15.5 19 15.375 19 15.1875C19 13.1562 17.3438 11.5 15.3125 11.5H14C13.7188 11.5 13.5 11.2812 13.5 11C13.5 10.75 13.7188 10.5 14 10.5H15.3125Z"
    />
  </Icon>
)

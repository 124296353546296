import React, { useCallback } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useSpace } from '@vatom/sdk/react'
import { PressableOpacity } from '@vatom/wombo'
import { StyledProps } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import Exitblack from './exit-black.svg'
import ExitWhite from './exit-white.svg'

export type CallControlType = StyledProps & {
  secondary?: boolean
}

export function EndCallButton(props: CallControlType) {
  const { endCall } = useSpace()
  const size = '58px'
  const navigation = useNavigation()

  const endCallHandler = useCallback(async () => {
    if (props.secondary) {
      await endCall()
      return
    }
    await endCall()
    navigation.goBack()
  }, [endCall, navigation, props.secondary])

  const onPressEndCall = useThrottledCallback(endCallHandler, 300)

  if (props.secondary) {
    return (
      <PressableOpacity onPress={onPressEndCall} {...props}>
        <Exitblack />
      </PressableOpacity>
    )
  }

  return (
    <PressableOpacity
      onPress={() => onPressEndCall()}
      background="rgba(0, 0, 0, 0.7)"
      h={size}
      w={size}
      borderRadius={30}
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <ExitWhite />
    </PressableOpacity>
  )
}

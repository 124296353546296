import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const EllipsisIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 34 34" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M19.25 17C19.25 15.7812 18.2188 14.75 17 14.75C15.7344 14.75 14.75 15.7812 14.75 17C14.75 18.2656 15.7344 19.25 17 19.25C18.2188 19.25 19.25 18.2656 19.25 17ZM24.875 14.75C23.6094 14.75 22.625 15.7812 22.625 17C22.625 18.2656 23.6094 19.25 24.875 19.25C26.0938 19.25 27.125 18.2656 27.125 17C27.125 15.7812 26.0938 14.75 24.875 14.75ZM9.125 14.75C7.85938 14.75 6.875 15.7812 6.875 17C6.875 18.2656 7.85938 19.25 9.125 19.25C10.3438 19.25 11.375 18.2656 11.375 17C11.375 15.7812 10.3438 14.75 9.125 14.75Z"
    />
  </Icon>
)

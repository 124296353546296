import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useAddRecentContact } from '@vatom/sdk/react'
import { Status, Toast } from '@vatom/wombo'

import Alert from '../../../../components/Alert'
import { AppRoutes, AppStackParamList } from '../../../../navigators'

import { SendBtn, senderInterface } from '.'

export default function SendVatomBasic({
  token,
  userProfile,
  destination,
  isShare
}: senderInterface) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const addRecentContactMutation = useAddRecentContact()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const sendVatom = async () => {
    setIsLoading(true)
    if (destination?.value) {
      try {
        const res = await token.performAction(
          isShare ? 'Clone' : 'Transfer',
          'validatedValue' in destination ? destination.validatedValue : destination?.value,
          { userProfile, destination }
        )

        if (res) {
          const contactOrDestination = userProfile || destination
          if (contactOrDestination) addRecentContactMutation.mutate(contactOrDestination)

          Toast({
            title: `Your NFT has been ${isShare ? 'shared' : 'sent'}`,
            status: Status.success
          })
        }
      } catch (error) {
        Alert.showError(error)
      }
    }
  }

  return (
    <SendBtn
      smart={true}
      onPress={() => {
        sendVatom()
        navigation.navigate(AppRoutes.home)
      }}
      disabled={!destination?.value || isLoading}
      isShare={isShare}
    />
  )
}

import React from 'react'
// import DraggableFlatList, { ScaleDecorator } from 'react-native-draggable-flatlist'
import { FlatList, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Swipeable } from 'react-native-gesture-handler'
import { useNavigation, useRoute } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'

import {
  AppNavigation,
  AppStackScreenProps,
  navigateToConnectScreen,
  RoomRoutes
} from '../../../../../../navigators'
import { addMessageStore } from '../../../../screens/AddMessageModal'
import { useCommunitiesTheme } from '../../../../themes'
import { useCommunitySpace } from '../../../../useCommunitySpace'
// import DragBarsIcon from '../../assets/drag_bars_icon.svg'
import ArrowRightIcon from '../../../modal-components/ArrowRightIcon'
import BinIcon from '../../assets/bin_icon.svg'

type CurrentRoute = AppStackScreenProps<
  typeof RoomRoutes.RoomNewQuestion | typeof RoomRoutes.RoomNewSketch
>['route']

const Question = observer((props: any) => {
  const { question, type } = props
  const navigation = useNavigation<AppNavigation>()
  const { params } = useRoute<CurrentRoute>()
  const { community } = useCommunitySpace()

  const description = question.text.replaceAll(/<\/?[^>]+(>|$)/gi, '').replace(/&nbsp;/g, '')

  const communitiesTheme = useCommunitiesTheme()

  const renderRightActions = () => {
    return (
      <TouchableOpacity
        accessibilityRole="button"
        style={styles(communitiesTheme).deleteButton}
        onPress={() =>
          type === 'question'
            ? addMessageStore.removeQuestion(question.id)
            : addMessageStore.removeSketch(question.id)
        }
      >
        <BinIcon style={{ marginBottom: 10 }} width={14} height={16} />
        <Text style={styles(communitiesTheme).deleteButtonText}>Delete</Text>
      </TouchableOpacity>
    )
  }

  const handleSelection = () => {
    const name = type === 'question' ? RoomRoutes.RoomNewQuestion : RoomRoutes.RoomNewSketch
    question.setIsSelected(true)

    navigateToConnectScreen(navigation, name, {
      ...params,
      community
    })
  }

  return (
    <Swipeable renderRightActions={renderRightActions}>
      <View style={styles(communitiesTheme).row}>
        {/* <TouchableOpacity
          style={{ paddingLeft: 16, paddingRight: 8, paddingVertical: 20 }}
        >
          <DragBarsIcon />
        </TouchableOpacity> */}

        <View style={{ flex: 1, marginRight: 5, marginLeft: 20 }}>
          <Text style={styles(communitiesTheme).title}>{question.title}</Text>
          <Text style={styles(communitiesTheme).description} numberOfLines={1}>
            {description}
          </Text>
        </View>
        <TouchableOpacity
          accessibilityRole="button"
          style={styles(communitiesTheme).arrow}
          onPress={() => handleSelection()}
        >
          <ArrowRightIcon />
        </TouchableOpacity>
      </View>
    </Swipeable>
  )
})

const QuestionsList = observer(({ type }: { type: string }) => {
  const questions = addMessageStore.questions
  const sketches = addMessageStore.sketches

  const communitiesTheme = useCommunitiesTheme()
  const data = type === 'question' ? questions : sketches

  const renderItem = (item: any) => {
    return <Question question={item} type={type} />
  }

  return (
    <View style={styles(communitiesTheme).container}>
      {data.length > 0 ? (
        <FlatList
          // @ts-expect-error not sure how to fix this
          data={data}
          keyExtractor={item => item.id.toString()}
          renderItem={({ item }) => renderItem(item)}
        />
      ) : null}
    </View>
  )
})

export default QuestionsList

const styles = (communitiesTheme?: any) =>
  StyleSheet.create({
    container: {
      flex: 1,
      marginTop: 16
    },
    row: {
      flexDirection: 'row',
      backgroundColor: communitiesTheme.background,
      borderBottomWidth: 1,
      borderBottomColor: communitiesTheme.questionBadge.borderColor,
      alignItems: 'center'
    },
    deleteButtonText: {
      fontSize: 13,
      lineHeight: 18,
      color: '#FFFFFF'
    },
    deleteButton: {
      alignItems: 'center',
      paddingHorizontal: 20,
      backgroundColor: '#DD4267',
      justifyContent: 'center'
    },
    arrow: {
      marginLeft: 'auto',
      paddingRight: 16,
      paddingVertical: 20
    },
    title: {
      fontSize: 15,
      lineHeight: 20,
      color: communitiesTheme.questionBadge.textColor
    },
    description: {
      fontSize: 11,
      lineHeight: 15,
      color: '#868E96'
    }
  })

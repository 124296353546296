import { Suspense, useEffect } from 'react'
import { View } from 'react-native'
import { Bounds, OrbitControls, useAnimations, useGLTF } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { Loader } from '@vatom/wombo'

import { DefaultEnvironment } from './DefaultEnvirontment'

interface IModel {
  url: string
}

/** Display a 3D model in the scene graph */
const Model = ({ url }: IModel) => {
  // Load the model
  const { scene, animations } = useGLTF(url)

  // Play the first animation in the model
  const { names, actions } = useAnimations(animations, scene)
  useEffect(() => {
    const action = actions[names[0]]
    action?.play()
  }, [actions, names])

  // Render the model
  return <primitive object={scene} />
}

// Render a 3D vatom face
function ThreeDFace(props: { url?: string }) {
  // Get model details
  const { url } = props

  // Render it
  return (
    <>
      {/* Suspense is used to show the loader while the assets are loading */}
      <Suspense
        fallback={
          <View
            style={{
              width: '100%',
              height: '100%',
              flex: 1
            }}
          >
            <Loader />
          </View>
        }
      >
        {/* 3D Canvas ... the camera is set very far away so that it can align correctly when <Bounds/> zooms it in */}
        <Canvas camera={{ position: [0, 0, 50000] }}>
          {/* Rotate the camera via user input */}
          <OrbitControls makeDefault minZoom={0.5} maxZoom={2} enablePan={false} />

          <DefaultEnvironment />

          {/* Render the model if it exists */}
          {url && (
            <Bounds fit clip observe>
              <Model url={url} />
            </Bounds>
          )}
        </Canvas>
      </Suspense>
    </>
  )
}

Object.defineProperty(ThreeDFace, 'name', {
  writable: true,
  value: 'ThreeDFace'
})

export default ThreeDFace

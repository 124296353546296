import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const BlockchainNetworkIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 16 17" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M8 0.5C12.4062 0.5 16 4.09375 16 8.5C16 12.9375 12.4062 16.5 8 16.5C3.5625 16.5 0 12.9375 0 8.5C0 4.09375 3.5625 0.5 8 0.5ZM8 15.5C8.5 15.5 9.25 15.0625 9.90625 13.7188C10.2188 13.0938 10.5 12.3438 10.6562 11.5H5.3125C5.46875 12.3438 5.75 13.0938 6.0625 13.7188C6.71875 15.0625 7.46875 15.5 8 15.5ZM5.125 10.5H10.8438C10.9375 9.875 11 9.21875 11 8.5C11 7.8125 10.9375 7.15625 10.8438 6.5H5.125C5.03125 7.15625 5 7.8125 5 8.5C5 9.21875 5.03125 9.875 5.125 10.5ZM10.6562 5.5C10.5 4.65625 10.2188 3.9375 9.90625 3.3125C9.25 1.96875 8.5 1.5 8 1.5C7.46875 1.5 6.71875 1.96875 6.0625 3.3125C5.75 3.9375 5.46875 4.65625 5.3125 5.5H10.6562ZM11.8438 6.5C11.9375 7.15625 12 7.8125 12 8.5C12 9.21875 11.9375 9.875 11.8438 10.5H14.6875C14.875 9.875 15 9.21875 15 8.5C15 7.8125 14.875 7.15625 14.6875 6.5H11.8438ZM10.2188 1.875C10.875 2.75 11.4062 4.03125 11.6875 5.5H14.3125C13.5 3.8125 12.0312 2.5 10.2188 1.875ZM5.75 1.875C3.9375 2.5 2.46875 3.8125 1.65625 5.5H4.28125C4.5625 4.03125 5.09375 2.75 5.75 1.875ZM1 8.5C1 9.21875 1.09375 9.875 1.28125 10.5H4.125C4.03125 9.875 4 9.21875 4 8.5C4 7.8125 4.03125 7.15625 4.125 6.5H1.28125C1.09375 7.15625 1 7.8125 1 8.5ZM14.3125 11.5H11.6875C11.4062 13 10.875 14.25 10.2188 15.1562C12.0312 14.5312 13.5 13.2188 14.3125 11.5ZM4.28125 11.5H1.65625C2.46875 13.2188 3.9375 14.5312 5.75 15.1562C5.09375 14.25 4.5625 13 4.28125 11.5Z"
    />
  </Icon>
)

import React from 'react'
import { StyleProp, Text, TextStyle } from 'react-native'

import { useCommunitiesTheme } from '../../themes'

const ModalText = ({ text, style }: { text: string; style?: StyleProp<TextStyle> }) => {
  const communitiesTheme = useCommunitiesTheme()
  return (
    <Text
      style={[{ fontSize: 15, lineHeight: 20, color: communitiesTheme.labelTitleColor }, style]}
    >
      {text}
    </Text>
  )
}

export default ModalText

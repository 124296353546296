import React from 'react'
import { FlatList } from 'react-native'
import { TEthereumChains, TSolanaChains } from '@vatom/sdk/core'
import { LoaderView, Text } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box } from 'native-base'

import { useWeb3Transactions } from '../../../../../hooks/useFungibleTokens'
import { useLoyaltyTransactionPoints } from '../../../../../hooks/useLoyalty'
import { filterByKey } from '../../utils/filterData'
import { ItemType, TransactionCrypto, TransactionPoint, transactionTypes } from '../TransactionItem'

const EMPTY_LIST_TEXT = 'No entries'

const ListEmptyComponent = () => (
  <Box px={4} pt={2}>
    <Text>{EMPTY_LIST_TEXT}</Text>
  </Box>
)
const ListLoadingComponent = () => <LoaderView blocking={false} />

type TransactionListProps = {
  businessId?: string
  searchTerm?: string
  address?: string
  chain?: TEthereumChains | TSolanaChains
}
const TransactionList = observer(
  ({ businessId, searchTerm = '', address, chain }: TransactionListProps) => {
    const { data: defaultData, isLoading } = useLoyaltyTransactionPoints({
      businessId
    })

    const { data: ethTransactions, isLoading: isEthTransactionsLoading } = useWeb3Transactions(
      chain,
      address
    )

    if (!businessId) {
      return null
    }
    const filteredData = filterByKey(address ? [] : defaultData, searchTerm, [
      'title',
      'description',
      'operationType'
    ])
    const filteredEthData = filterByKey(ethTransactions, searchTerm, [
      'from',
      'to',
      'operationType',
      'amount',
      'operationType'
    ])

    const renderItem = ({ item }: { item: ItemType }) => {
      if (item.type === transactionTypes.crypto) {
        return (
          <TransactionCrypto
            operation={item.operationType}
            timeAgo={item.timeAgo}
            from={item.from}
            to={item.to}
            amount={item.amount}
            scanUrl={item.scanUrl}
          />
        )
      } else if (item.type === transactionTypes.points) {
        return (
          <TransactionPoint
            operation={item.operationType}
            title={item?.title}
            description={item?.description}
            amount={item?.amount}
            timeAgo={item.timeAgo}
            image={item?.image}
          />
        )
      } else return null
    }

    return (
      <Box flex={1}>
        {isLoading || isEthTransactionsLoading ? (
          <ListLoadingComponent />
        ) : (
          <FlatList
            data={[...filteredData, ...filteredEthData]}
            keyExtractor={item => `${item.id}`}
            renderItem={renderItem}
            scrollEnabled={true}
            ListEmptyComponent={ListEmptyComponent}
          />
        )}
      </Box>
    )
  }
)

export { TransactionList }

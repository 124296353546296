import React, { useMemo } from 'react'
// import { useNavigation } from '@react-navigation/native'
import {
  getRoomsByMemberIdSelector,
  useGetBusinessSpaces,
  useGetMatrixFullStateSync
} from '@vatom/sdk/react'
import { PressableOpacity } from '@vatom/wombo'
import { Box, FlatList, Image, Text } from 'native-base'

// import { AppRoutes } from '../../../navigators'

type SharedSpacesProps = {
  memberId: string
  businessId: string
}

type SpaceProps = {
  displayName: string
  cover: string
  onPress?: () => void
}

const Space = ({ displayName, cover, onPress }: SpaceProps) => {
  const coverUri = cover
    ? `${cover.replace(
        'mxc://',
        `https://matrix.vatom.com/_matrix/media/v3/thumbnail/`
      )}?width=50&height=50&method=scale`
    : ''

  const renderContent = useMemo(
    () => (
      <Box flex={1} flexDir="row" marginBottom="10px" width="100%">
        <Box w={'40px'} h={'40px'}>
          <Image
            accessibilityIgnoresInvertColors={true}
            alt="Room Cover"
            alignItems="center"
            justifyContent="center"
            w={'40px'}
            h={'40px'}
            resizeMode="cover"
            source={coverUri ? { uri: coverUri } : require('../assets/defaultSpace.jpg')}
          />
        </Box>
        <Text marginLeft="10px" fontWeight={700} fontSize="15px" lineHeight="20px">
          {displayName}
        </Text>

        {onPress && (
          <Box justifyContent={'center'} alignItems={'flex-end'} flexGrow={1}>
            <Image
              accessibilityIgnoresInvertColors
              source={require('../assets/rightArrow.png')}
              style={{ width: 10, height: 15, marginBottom: 10 }}
            />
          </Box>
        )}
      </Box>
    ),
    [coverUri, displayName, onPress]
  )

  if (!onPress) {
    return renderContent
  }

  return (
    <PressableOpacity accessibilityRole="button" onPress={onPress}>
      {renderContent}
    </PressableOpacity>
  )
}

function SharedSpaces({ memberId, businessId }: SharedSpacesProps) {
  // const navigation = useNavigation()
  const rooms = useGetMatrixFullStateSync({
    select: matrixData => {
      const simplified = getRoomsByMemberIdSelector(matrixData, memberId)
      return simplified.map(simply => simply.displayName)
    }
  })

  const spaces = useGetBusinessSpaces(businessId, '', {
    select: spacesList => spacesList.items.filter(space => rooms.data?.includes(space.displayName))
  })

  // const navigateToRoom = (spaceId: string) => {
  //   navigation.navigate(AppRoutes.Room, {
  //     business: businessId,
  //     spaceId
  //   })
  // }

  return (
    <Box
      flex={1}
      alignItems={'flex-start'}
      marginX={4}
      _web={{
        minWidth: 360
      }}
    >
      <Text fontWeight={700} fontSize="17px" lineHeight="24px">
        Shared Spaces
      </Text>

      <FlatList
        marginTop={2}
        keyExtractor={key => `shared-spaces-${key?.id}`}
        data={spaces.data}
        width={'100%'}
        renderItem={({ item }) =>
          item ? (
            <Space
              // onPress={() => {
              //   navigateToRoom(item.id)
              // }}
              displayName={item?.displayName ?? ''}
              cover={item?.cover ?? ''}
            />
          ) : null
        }
      />
    </Box>
  )
}

export { SharedSpaces }

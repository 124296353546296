import { useCallback, useEffect, useState } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useLinkTo, useNavigation } from '@react-navigation/native'
import { SessionSource, SessionType } from '@vatom/sdk/core'
import { useIsAuthed, useSDK } from '@vatom/sdk/react'
import { LoaderView } from '@vatom/wombo'
import { TokenResponse } from 'expo-auth-session'
import jwtDecode from 'jwt-decode'
import { observer } from 'mobx-react-lite'

import { useAnalytics } from '../../hooks/useAnalytics'
import { useAuthLogout } from '../../hooks/useAuthLogout'
import { navigationPendingStore } from '../../hooks/useNavigationPending'
import { useStore } from '../../models'
import { AppRoutes } from '../../navigators'

export const LogoutCallback = observer(() => {
  const navigation = useNavigation()
  const { clearAllSessions } = useAuthLogout()

  useEffect(() => {
    const clearAllSessionsAndRedirect = async () => {
      try {
        clearAllSessions()
      } catch (error) {
        console.error('LOG: > LogoutCallback > error:', error)
      } finally {
        setTimeout(() => {
          navigation.navigate(AppRoutes.connect)
        }, 200)
      }
    }
    clearAllSessionsAndRedirect()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <LoaderView />
})

export const useClaimOtp = () => {
  // const navigation = useNavigation()
  const sdk = useSDK()
  const { analytics } = useAnalytics()
  const store = useStore()
  // const link = useLinkTo()
  const [isLoading, setIsLoading] = useState(false)
  const navigationStore = navigationPendingStore()

  const checkClaimRemaining = useCallback(async () => {
    try {
      const claimDetails = await AsyncStorage.getItem('CLAIM_DETAILS')
      if (!claimDetails) {
        return undefined
      }

      const { claimId, ...otherDetails } = JSON.parse(claimDetails)
      return { claimId, ...otherDetails }
    } catch (error) {
      console.log('LOG: > checkClaimRemaining > error:', error)
      return undefined
    }
  }, [])

  const handleClaimPendingAndRedirect = useCallback(async () => {
    const claimDetails = await checkClaimRemaining()
    if (!claimDetails) return

    const performLogin = async () => {
      setIsLoading(true)
      const loginType = claimDetails['iden-type']
      let loginValue = claimDetails['iden-value'].trim()
      if (loginType === 'phoneNumber') {
        loginValue = loginValue.startsWith('+') ? loginValue : `+${loginValue}`
      }

      const session = await sdk.vatomIncApi.loginWithOtp(loginValue, loginType, claimDetails.otp)
      if (!session.access_token) {
        setIsLoading(false)
        return null
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const decodedToken: any = jwtDecode(session.access_token)

      const tokenResponse = {
        accessToken: session.access_token,
        refreshToken: session.refresh_token,
        expiresIn: decodedToken.exp,
        issuedAt: decodedToken.iat,
        tokenType: session.token_type,
        scope: decodedToken.scope
      } as TokenResponse

      const { accessToken, expiresIn, refreshToken, tokenType, scope } = tokenResponse

      // Add token to the axios services
      sdk.service.setToken(accessToken)
      const date = new Date()
      sdk.dataPool.sessionStore.add({
        type: SessionType.JWT,
        value: {
          idToken: '',
          accessToken,
          refreshToken,
          expiresAt: new Date(
            new Date(date).setSeconds(date.getSeconds() + (expiresIn || 0))
          ).getTime()
        },
        source: SessionSource.Vatom
      })

      // Perform token enchange
      await sdk.vatomIncApi.performTokenExchange(accessToken)

      if (accessToken && expiresIn && refreshToken && tokenType && scope) {
        await sdk.dataPool.user.fetchIdentities()
        await sdk.dataPool.user.fetchMe()

        analytics.event('initiateAction', {
          actionUri: 'Claim',
          claimId: claimDetails.claimId
        })
      }

      const { linking } = store
      if (!linking.linkingUrl) {
        await AsyncStorage.removeItem('CLAIM_DETAILS')
        store.linking?.clearLinkingUrl()
        setIsLoading(false)
        return null
      }

      const url = new URL(linking.linkingUrl)

      await AsyncStorage.removeItem('CLAIM_DETAILS')
      store.linking?.clearLinkingUrl()

      // setIsLoading(false)
      // setTimeout(() => {
      //   link(url.pathname)
      // }, 100)

      navigationStore.setPendingNavigationPath(url.pathname)
    }
    await performLogin()
  }, [
    analytics,
    checkClaimRemaining,
    navigationStore,
    sdk.dataPool.sessionStore,
    sdk.dataPool.user,
    sdk.service,
    sdk.vatomIncApi,
    store
  ])

  return { handleClaimPendingAndRedirect, isLoading }
}

export default LogoutCallback

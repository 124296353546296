import { useWindowDimensions } from 'react-native'

export function getWidthRatio(w: number, h: number, maxWidth: number) {
  const ratio = maxWidth / w
  const ratioW = Math.floor(w * ratio)
  const ratioH = Math.floor(h * ratio)
  return {
    width: ratioW,
    height: ratioH
  }
}
export function getHeightRatio(w: number, h: number, maxHeight: number) {
  const ratio = maxHeight / h
  const ratioW = Math.floor(w * ratio)
  const ratioH = Math.floor(h * ratio)
  return {
    width: ratioW,
    height: ratioH
  }
}
export function getRatio(w: number, h: number, maxWidth: number, maxHeight: number) {
  const ratio = w / h
  if (ratio > 1) {
    // width bigger
    return getWidthRatio(w, h, maxWidth)
  } else {
    return getHeightRatio(w, h, maxHeight)
  }
}

export function useImagesSize() {
  const { width: windowWidth } = useWindowDimensions()

  const getImageRatio = (w: number, h: number) => getWidthRatio(w, h, windowWidth)

  return { getImageRatio }
}

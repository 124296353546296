import {
  BasePluginStore,
  Region,
  RegionType,
  Sentry,
  SessionSource,
  SessionTokenType,
  SessionType,
  withRootSDKStore
} from '@vatom/sdk/core'
import { getConfig } from '@vatom/sdk/react'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import {
  IAnyType,
  Instance,
  isAlive,
  ISerializedActionCall,
  onAction,
  SnapshotOut,
  types
} from 'mobx-state-tree'

import logger from '../logger'
import { VatomApiStore } from '../modules/VatomApiStore'
import { BVatomInventoryRegionStore, VatomGeoPosRegionStore } from '../regions'

import { BVatomToken } from './BVatomToken'

const className = 'BVatomPlugin'

export const VatomPluginBase = BasePluginStore.named('VatomPluginBase').props({
  className
})

const AnyVatomRegionMap: Record<RegionType, IAnyType> = {
  [RegionType.geopos]: VatomGeoPosRegionStore,
  [RegionType.inventory]: BVatomInventoryRegionStore
}

const anyVatomRegionTypes = Object.values(AnyVatomRegionMap)

export const BVatomPlugin = types
  .compose(VatomPluginBase, types.model('vatom-placeholder', {}))
  .props({
    api: types.optional(VatomApiStore, { id: `VatomApiStore1` }),
    regions: types.optional(
      types.array(
        types.union(
          {
            eager: false,
            dispatcher: (snapshot: any): IAnyType => {
              logger.info(
                '[BVatomPluginBase.regions dispatcher] snapshot',
                // snapshot,
                snapshot.id,
                snapshot.className,
                'region names',
                Object.values(AnyVatomRegionMap).map(region => region.name)
              )
              const discriminatedRegion =
                AnyVatomRegionMap[snapshot.id as RegionType] ||
                anyVatomRegionTypes.find(
                  (region: any) =>
                    region.name === snapshot.name || region.className === snapshot.className
                )
              if (!discriminatedRegion) {
                throw new Error(
                  '[BVatomPluginBase.regions dispatcher] Unable to discrimate region',
                  snapshot.id
                )
              }
              logger.info(
                '[BVatomPluginBase.regions dispatcher] snapshot',
                discriminatedRegion?.name
              )
              return discriminatedRegion
            }
          },
          ...anyVatomRegionTypes
        )
      ),
      []
    )
  })
  .named(className)
  .extend(withRootSDKStore)
  .actions(self => ({
    region(id: RegionType, descriptor?: string): Region {
      try {
        logger.info('[BVatomPlugin.region] Looking for region in plugin', id, descriptor)
        const region = self.regions.find(r => r.matches(id, descriptor))
        if (region) {
          // logger.info('[BVatomPlugin.region] Found region', region)
          return region
        }

        logger.info('[BVatomPlugin.region] Creating new region in plugin', id, descriptor)
        let newRegion = null
        if (id === RegionType.geopos) {
          newRegion = VatomGeoPosRegionStore.create({
            id,
            descriptor,
            _id: `BVatomGeoPosRegionStore-${id}-${descriptor}`
          })
        } else {
          newRegion = BVatomInventoryRegionStore.create({
            id,
            _id: `BVatomInventoryRegionStore-${id}`
          })
        }
        self.regions.push(newRegion)
        return newRegion
      } catch (error) {
        logger.error('[BVatomPlugin.region] creating region', error)
        throw error
      }
    }
  }))
  .actions(self => ({
    clearAuth() {
      const sdk = self.rootStore
      sdk.dataPool.user.logout()
      sdk.dataPool.user.clearCache()
      sdk.service.setToken(undefined)
      sdk.dataPool.sessionStore.clear()
    }
  }))
  .actions(self => ({
    async onSessionsChanged(data: ISerializedActionCall): Promise<void> {
      if (!isAlive(self)) {
        return
      }

      if (data.path === '/dataPool/sessionStore' && data.name === 'delete') {
        logger.info('[BVatomPlugin.onSessionsChanged DELETED]', data)
      }

      if (data.path === '/dataPool/sessionStore' && data.name === 'update') {
        const region = self.regions.find(r => r.matches(RegionType.inventory))
        if (region) {
          await region.load()
        }
      }

      if (data.path !== '/dataPool/sessionStore' || !['add'].includes(data.name)) {
        return
      }

      this.initializeSessions(data.args)
    },
    async initializeSessions(sessions?: any) {
      logger.info('[BVatomPlugin.initializeSessions]: start')

      const session = sessions.find(
        ($: any) => $.type === SessionType.JWT && $.source === SessionSource.VatomNetwork
      )

      if (session) {
        const currentUserId = self.api.store.userID
        const sessionToken = session.value as SessionTokenType

        if (self.api.store.refreshToken !== sessionToken.refreshToken) {
          self.api.setRefreshToken(sessionToken.refreshToken || null)
        }

        logger.info('[BVatomPlugin.initializeSessions]: sessionToken', session.value)
        logger.info(
          '[BVatomPlugin.initializeSessions]: self.api.store.refreshToken',
          self.api.store.refreshToken
        )

        try {
          let region = self.regions.find(r => r.matches(RegionType.inventory))
          if (!region) {
            region = self.region(RegionType.inventory)
            logger.info('[BVatomPlugin.initializeSessions]: creating region: ', region.id)
            const compositeRegion = self.rootStore.dataPool.region(region.id)
            logger.info(
              '[BVatomPlugin.initializeSessions]: adding region to compositeRegion: ',
              compositeRegion.id
            )
            compositeRegion.addRegion(region)
            logger.info(
              '[BVatomPlugin.initializeSessions] compositeRegion',
              compositeRegion.id,
              compositeRegion.regions.length
            )
          }
          await this.checkToken(sessionToken)
          // Get assetProviders
          const assetProviders = await self.api.client.request(
            'GET',
            '/v1/user/asset_providers',
            null,
            true
          )
          self.api.store.assetProvider = assetProviders.asset_provider

          // Only load region if the userId changed
          logger.info(
            '[BVatomPlugin.initializeSessions]: check',
            self.api.store.userID,
            currentUserId
          )
          // Add to sessionInfo
          self.rootStore.dataPool.sessionStore.add({
            type: SessionType.UserId,
            source: SessionSource.VatomNetwork,
            value: self.api.store.userID!
          })

          // Create the Inventory region if it does not exist
          logger.info('[BVatomPlugin.initializeSessions]: count regions', self.regions.length)
          if (self.api.store.userID !== currentUserId) {
            // Reload the region because this is a new or different user
            logger.warn('[BVatomPlugin.initializeSessions] different user')
            if (currentUserId) region.clear()
          }

          region.load()
        } catch (error) {
          logger.error('[BVatomPlugin.initializeSessions]', error)
        }
      }
    },
    async checkToken(sessionToken: SessionTokenType) {
      logger.info('[BVatomPlugin.checkToken]', !!sessionToken, sessionToken)
      try {
        // Verify the access token
        const decodedToken: any = jwtDecode(sessionToken.accessToken)
        const expirationTime = decodedToken.exp * 1000
        logger.info('[BVatomPlugin.checkToken] expirationTime:', expirationTime)

        // quick calc to determine if the token has expired
        if (Date.now() + 5000 > expirationTime) {
          logger.info('[BVatomPlugin.checkToken]: token expired')
          logger.info('[BVatomPlugin.checkToken]: attempt refresh...', sessionToken.refreshToken)
          // Refresh the token
          const { data } = await axios.post('/v1/access_token', undefined, {
            baseURL: 'https://api.vi.vatom.network',
            headers: {
              'App-Id': getConfig().appID,
              Authorization: `Bearer ${sessionToken.refreshToken}`
            }
          })

          logger.info('[BVatomPlugin.checkToken]: data', data)
          self.api.store.token = data.payload.access_token.token
          if (sessionToken.setAccessToken) {
            sessionToken.setAccessToken(data.payload.access_token.token)
          }
        } else {
          self.api.store.token = sessionToken.accessToken
        }
        logger.info('[BVatomPlugin.checkToken] self.api.store.token', self.api.store.token)
      } catch (error) {
        logger.error('[BVatomPlugin.checkToken] ERROR', error)
        Sentry.captureMessage(String(`BVatomPlugin.checkToken: ${String(error)}`))
        self.clearAuth()
      }
    },
    async init() {
      logger.info('[BVatomPlugin.init]')
      onAction(self.rootStore, this.onSessionsChanged)

      // Check if there are any existing credentials to initialize the plugin
      await this.initializeSessions(self.rootStore?.dataPool?.sessionStore?.sessions)
    },
    clearCache() {
      logger.info('[BVatomPlugin.clearCache]')
      self.api.store.userID = null
      self.api.store.refreshToken = null
      self.api.store.assetProvider = []
      self.regions.map(r => r.clear())
    }
  }))
  .views(self => ({
    get availableRegions() {
      return [BVatomInventoryRegionStore, VatomGeoPosRegionStore]
    },
    get availableTokens() {
      return [BVatomToken]
    }
  }))

export type BVatomPluginType = Instance<typeof BVatomPlugin>
export type BVatomPluginSnapshot = SnapshotOut<typeof BVatomPlugin>

import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { useConfig } from '@vatom/sdk/react'
import { observer } from 'mobx-react-lite'

export type MapViewProps = React.PropsWithChildren<React.ComponentProps<typeof GoogleMap>>

export const MapView = observer(({ children, ...rest }: MapViewProps) => {
  const config = useConfig()
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.googleMapsKey
  })

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: '100vw',
        height: '100%'
      }}
      {...rest}
    >
      {children}
    </GoogleMap>
  ) : null
})

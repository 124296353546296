import { Platform } from 'react-native'
import { STAGE } from 'react-native-dotenv'

export const hosts = {
  dev: 'https://sites.vatominc.cool/',
  stage: 'https://sites.vatominc.cool/',
  prod: 'https://sites.vatom.com/',
  local: Platform.select({
    android: 'http://10.0.2.2:3001/',
    ios: 'http://localhost:3001/'
  })
} as const
export const host = hosts[STAGE as keyof typeof hosts] ?? hosts.dev

/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { TwitterProfileModel, TwitterProfileModelType } from "./TwitterProfileModel"
import { TwitterProfileModelSelector } from "./TwitterProfileModel.base"
import { RootStoreType } from "./index"


/**
 * NftCreatorBase
 * auto generated base class for the model NftCreatorModel.
 */
export const NftCreatorModelBase = ModelBase
  .named('NftCreator')
  .props({
    __typename: types.optional(types.literal("NftCreator"), "NftCreator"),
    address: types.union(types.undefined, types.string),
    metadataAddress: types.union(types.undefined, types.string),
    share: types.union(types.undefined, types.integer),
    verified: types.union(types.undefined, types.boolean),
    position: types.union(types.undefined, types.null, types.integer),
    twitterHandle: types.union(types.undefined, types.null, types.string),
    profile: types.union(types.undefined, types.null, types.late((): any => TwitterProfileModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class NftCreatorModelSelector extends QueryBuilder {
  get address() { return this.__attr(`address`) }
  get metadataAddress() { return this.__attr(`metadataAddress`) }
  get share() { return this.__attr(`share`) }
  get verified() { return this.__attr(`verified`) }
  get position() { return this.__attr(`position`) }
  get twitterHandle() { return this.__attr(`twitterHandle`) }
  profile(builder: string | TwitterProfileModelSelector | ((selector: TwitterProfileModelSelector) => TwitterProfileModelSelector) | undefined) { return this.__child(`profile`, TwitterProfileModelSelector, builder) }
}
export function selectFromNftCreator() {
  return new NftCreatorModelSelector()
}

export const nftCreatorModelPrimitives = selectFromNftCreator().address.metadataAddress.share.verified.position.twitterHandle

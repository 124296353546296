/* eslint-disable unicorn/filename-case */
import React, { CSSProperties } from 'react'
import { Image as FallbackImage, ImageProps, ImageURISource } from 'react-native'

export const Image = (props: ImageProps & { alt?: string }) => {
  const { alt, style, source, resizeMode, onLoadEnd } = props
  const isDetail = window.location.href.includes('NFTDetail')

  if (source !== undefined) {
    const cssStyle = style as CSSProperties
    const { uri } = source as ImageURISource
    const maxWidth = '732px'
    const maxHeight = '732px'
    const height = cssStyle.height === '100%' && !isDetail ? 'inherit' : undefined

    if (uri) {
      return (
        <div style={{ backgroundSize: resizeMode, maxWidth, maxHeight, height }}>
          <img loading="lazy" onLoad={onLoadEnd} src={uri} style={cssStyle} alt={alt} />
        </div>
      )
    } else {
      return (
        <div style={{ backgroundSize: resizeMode, maxWidth, maxHeight, height }}>
          <img
            loading="lazy"
            onLoad={onLoadEnd}
            src={source as string}
            style={cssStyle}
            alt={alt}
          />
        </div>
      )
    }
  }

  return <FallbackImage accessibilityIgnoresInvertColors {...props} />
}

/* eslint-disable react-native-a11y/has-valid-accessibility-descriptors */
/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
/* eslint-disable react-native-a11y/has-accessibility-hint */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native'
import { RichEditor } from 'react-native-pell-rich-editor'
import { CommonActions } from '@react-navigation/native'
import { useGetSpace, useMatrixUser } from '@vatom/sdk/react'
import { Loader } from '@vatom/wombo'
import axios from 'axios'
import { observer } from 'mobx-react-lite'
import { getSnapshot, SnapshotIn } from 'mobx-state-tree'
import { Box } from 'native-base'

import { Title } from '../../../components/Title'
import { withBusinessSelector } from '../../../hooks/useBusinessSelector'
import { AppRoutes, AppStackScreenProps } from '../../../navigators'
import { base64ToArrayBuffer, getSendMessageUrl } from '../components/AddModals/add-modals-helpers'
import ModalDecorations from '../components/AddModals/modal-components/ModalDecorations'
import ModalError from '../components/AddModals/modal-components/ModalError'
import ModalHeader from '../components/AddModals/modal-components/ModalHeader'
import ModalImagesSliderHolder from '../components/AddModals/modal-components/ModalImageSliderHolder'
import ModalInputsHolder from '../components/AddModals/modal-components/ModalInputsHolder'
import ModalLinkPreviews from '../components/AddModals/modal-components/ModalLinkPreviews'
import ModalMentions from '../components/AddModals/modal-components/ModalMentions'
import ModalPollChoicesHolder from '../components/AddModals/modal-components/ModalPollChoicesHolder'
import ModalToolbar from '../components/AddModals/modal-components/ModalToolBar'
import ModalWrapper, {
  ContentWrapper,
  PlatformWrapper
} from '../components/AddModals/modal-components/ModalWrapper'
import { AddMessageModalStore, Media } from '../components/AddModals/stores'
import { CreateMessageProvider } from '../components/MSTContextProvider'
import { EmojiStyle, NumberedStyle, StarStyle } from '../components/ScoreStyles'
import { matrixServerUrl } from '../constants'
import { getUploadedMediaUrl } from '../helpers'
import { useIsMember } from '../hooks/useIsMember'
import { useCommunitySpace } from '../useCommunitySpace'

type AddMessageModalProps = AppStackScreenProps<
  typeof AppRoutes.CommunitiesRoomNewMessage | typeof AppRoutes.RoomNewMessage
>

export const addMessageStore = AddMessageModalStore.create()

type Element = {
  type: string
  content: any
}

const AddMessageModal = observer(({ navigation, route }: AddMessageModalProps) => {
  const store = addMessageStore
  const { messageType, spaceId, messageId, method, business } = route.params
  const space = useGetSpace(spaceId, {
    enabled: false
  })
  const roomId = space.data?.matrixRoomId ?? ''
  const { data: matrixUser } = useMatrixUser()
  const accessToken = matrixUser?.access_token

  const isMember = useIsMember({ roomId })
  const { community } = useCommunitySpace()

  const inputRef = useRef<RichEditor | undefined>() as React.RefObject<RichEditor>

  const [isLoading, setIsLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorData, setErrorData] = useState({
    errorTitle: '',
    errorDescription: ''
  })

  const scoreStyleInput = store.additionalInputs.find(input => input.type === 'score-style')

  React.useEffect(() => {
    return () => {
      store.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const redirectNonMember = useCallback(() => {
    const resetAction = CommonActions.reset({
      index: 1,
      routes: [
        {
          name: AppRoutes.BusinessProxy,
          params: {
            business
          }
        },
        {
          name: community ? AppRoutes.CommunitiesRoom : AppRoutes.Room,
          params: {
            spaceId,
            business,
            community: community ?? undefined
          }
        }
      ]
    })
    navigation.dispatch(resetAction)
  }, [business, community, navigation, spaceId])

  useEffect(() => {
    if (isMember === false) {
      setTimeout(redirectNonMember, 1500)
    }
  }, [isMember, redirectNonMember])

  const renderNonMember = useMemo(() => {
    if (!isMember) {
      return null
    }
    return (
      <Box safeAreaTop margin="0 auto" paddingTop={4} flexDirection={'column'}>
        <Title preset="h5" textAlign={'center'}>
          You are not a member. Redirecting...
        </Title>
        <Pressable accessibilityRole="button" onPress={redirectNonMember}>
          <Text>If you are not redirected. Please click here</Text>
        </Pressable>
      </Box>
    )
  }, [isMember, redirectNonMember])

  const sendMessage = async () => {
    if (isLoading) {
      return
    }
    // setIsLoading(true)
    const inputs = store.inputs.map(input => ({
      type: input.type,
      content: input.value
    }))

    const elements: Element[] = [...inputs]

    const choices = getSnapshot(store.pollChoices)
    if (choices.length > 1 && scoreStyleInput?.value === 'single-choice-poll') {
      elements.push({
        type: 'choices',
        content: choices
      })
    }

    const questions = getSnapshot(store.questions)
    if (questions.length > 0) {
      elements.push({
        type: 'questions',
        content: questions
      })
    }

    const sketches = store.sketches.map(input => {
      const { media } = input
      return media.length > 0 ? { ...input, media: JSON.stringify(media) } : input
    })
    if (sketches.length > 0) {
      elements.push({
        type: 'sketches',
        content: sketches
      })
    }

    const additionalInputs = store.additionalInputs.map(input => {
      const inputName = input.type.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase())
      return {
        [inputName]: input.value
      }
    })

    let sendMessageUrl = getSendMessageUrl(messageType, roomId, accessToken)
    const stringifiedContent = JSON.stringify({
      elements,
      ...Object.assign({}, ...additionalInputs),
      timezone: { ...store.timezone }
    })
    let postBody: any = {}

    let uploadedMediaCopy: Array<SnapshotIn<typeof Media>> = []
    let isError = false

    const { media, linkPreviews } = store

    if (media.length > 0) {
      await Promise.all(
        media
          .filter(media => media.type === 'image')
          .map(async media => {
            const { type } = media
            if (type === 'image') {
              if (!media.base64) {
                return null
              }
              const mimeType = 'image/jpeg'
              const blob = base64ToArrayBuffer(media.base64)

              try {
                const uploadUrl = await getUploadedMediaUrl(blob, mimeType, accessToken)
                media.change({ ...media, uri: uploadUrl })
              } catch (e) {
                isError = true
                setIsLoading(false)
                setShowError(true)
                setErrorData({
                  errorTitle: 'Upload Error',
                  errorDescription: `${media.fileName} could not be uploaded`
                })
                store.removeMedia(media.uri)
                return null
              }
            }
          })
      )
      uploadedMediaCopy = media.map(media => ({ ...media, base64: '' }))
    }

    postBody = {
      msgtype: 'text',
      body: stringifiedContent,
      ...(messageId ? { replacing: messageId } : null)
    }
    if (uploadedMediaCopy.length > 0) {
      postBody['media'] = JSON.stringify(uploadedMediaCopy)
    }

    if (messageType === 'm.room.message') {
      const messageBody = String(store.inputs[0].value)
      postBody = {
        msgtype: 'text',
        body: messageBody.replaceAll(/<\/?[^>]+(>|$)/gi, '').replace(/&nbsp;/g, ' '),
        format: 'org.matrix.custom.html',
        formatted_body: store.inputs[0].value
      }
      if (uploadedMediaCopy.length === 1) {
        if (uploadedMediaCopy[0].type !== 'video-stream') {
          postBody.msgtype = 'm.image'
          postBody['url'] = uploadedMediaCopy[0].uri
        }
      }
      if (uploadedMediaCopy.length > 0) {
        postBody['media'] = JSON.stringify(uploadedMediaCopy)
      }
    }

    if (linkPreviews.length > 0) {
      postBody['link-previews'] = linkPreviews
    }
    const textInput = inputs.find(input => input.type === 'text')
    if (textInput) {
      const inputValue = textInput.content as string
      const idRegex = /<mention[^>]*id="([^"]*)"/g
      let match
      const ids = []

      while ((match = idRegex.exec(inputValue)) !== null) {
        ids.push(match[1])
      }
      postBody['m.mentions'] = {
        user_ids: ids
      }
    }

    if (!isError) {
      if (method === 'edit') {
        sendMessageUrl = getSendMessageUrl('v.room.edit', roomId, accessToken)

        await axios
          .post(sendMessageUrl, {
            'm.new_content': postBody,
            'm.relates_to': {
              rel_type: 'm.replace',
              event_id: messageId
            },
            parent_message_type: messageType
          })
          .then(() => navigation.goBack())
          .catch(e => setIsLoading(false))

        return
      }

      if (messageId && method === 'upgrade-to-topic') {
        const dateNow = Date.now()
        await axios.put(
          `${matrixServerUrl}/_matrix/client/r0/rooms/${roomId}/redact/${messageId}/${messageId}.message.${dateNow}?access_token=${matrixUser?.access_token}`,
          {}
        )
      }

      await axios
        .post(sendMessageUrl, postBody)
        .then(() => navigation.goBack())
        .catch(e => setIsLoading(false))
    }
  }

  let styleComponent = null
  if (scoreStyleInput) {
    switch (scoreStyleInput.value) {
      case 'numbered-1-5':
        styleComponent = <NumberedStyle length={5} />
        break
      case 'numbered-0-5':
        styleComponent = <NumberedStyle fromZero length={5} />
        break
      case 'numbered-1-10':
        styleComponent = <NumberedStyle length={10} />
        break
      case 'numbered-0-10':
        styleComponent = <NumberedStyle fromZero length={10} />
        break
      case 'emojies':
        styleComponent = <EmojiStyle />
        break
      case 'stars':
        styleComponent = <StarStyle />
        break
    }
  }

  if (!isMember) {
    return (
      <ModalWrapper>
        {renderNonMember}
        <Loader />
      </ModalWrapper>
    )
  }

  return (
    <CreateMessageProvider dataStore={store}>
      <ModalWrapper>
        <ModalError
          showError={showError}
          errorTitle={errorData.errorTitle}
          errorDescription={errorData.errorDescription}
          setShowError={setShowError}
        />
        <PlatformWrapper>
          {isLoading ? (
            <View style={modalStyles.loader}>
              <Loader size={60} />
            </View>
          ) : null}

          <ContentWrapper>
            <ModalHeader eventType={messageType} />

            <ScrollView
              contentContainerStyle={{
                justifyContent: 'space-between',
                flexGrow: 1,
                flexShrink: 0
              }}
              // style={{ backgroundColor: communitiesTheme.background, flexGrow: 1, flexShrink: 0 }}
              style={modalStyles.inputsHolder}
              keyboardShouldPersistTaps="always"
            >
              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 0,
                  paddingHorizontal: 20
                }}
                key="inputs-holder"
              >
                <ModalInputsHolder
                  messageType={messageType}
                  roomId={roomId}
                  messageId={messageId}
                  ref={inputRef}
                />
                {styleComponent ? styleComponent : null}
                {scoreStyleInput?.value === 'single-choice-poll' &&
                messageType === 'v.room.poll' ? (
                  <ModalPollChoicesHolder roomId={roomId} messageId={messageId} />
                ) : null}
              </View>

              <View
                key="decorations-holder"
                style={{
                  paddingHorizontal: 20
                }}
              >
                <ModalLinkPreviews />
                <ModalImagesSliderHolder roomId={roomId} messageId={messageId} />
                <ModalDecorations roomId={roomId} messageId={messageId} messageType={messageType} />
              </View>
            </ScrollView>
            <ModalMentions ref={inputRef} roomID={roomId} />
            {store.isMentionsOpen ? null : (
              <ModalToolbar ref={inputRef} type={messageType} sendMessage={sendMessage} />
            )}
          </ContentWrapper>
        </PlatformWrapper>
      </ModalWrapper>
    </CreateMessageProvider>
  )
})

const AddMessageModalWithBusinessSelector = withBusinessSelector(AddMessageModal)
export default AddMessageModalWithBusinessSelector

const modalStyles = StyleSheet.create({
  inputsHolder: {
    width: '100%',
    flexDirection: 'column',
    paddingTop: 14
  },
  toolbarHodlder: {
    bottom: 0,
    width: '100%'
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
    alignItems: 'center',
    justifyContent: 'center'
  }
})

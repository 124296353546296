import React from 'react'
import { SafeAreaView, StyleSheet, View } from 'react-native'
import { observer } from 'mobx-react-lite'

import { withBusinessSelector } from '../../../../../../hooks/useBusinessSelector'
import {
  AppRoutes,
  AppStackScreenProps,
  navigateToConnectScreen,
  RoomRoutes
} from '../../../../../../navigators'
import { addMessageStore } from '../../../../screens/AddMessageModal'
import { useCommunitiesTheme } from '../../../../themes'
import { useCommunitySpace } from '../../../../useCommunitySpace'
import NoQuestionsImage from '../../assets/no_questions_image.svg'
import CloseButton from '../modal-header-components/CloseButton'
import ModalButton from '../modal-header-components/ModalButton'
import ModalTitle from '../modal-header-components/ModalTitle'

import { AddButton, PlaceholderDescription, PlaceholderTitle } from './components'
import QuestionsList from './QuestionsList'

type QuestionsScreenProps = AppStackScreenProps<
  typeof AppRoutes.RoomQuestionList | typeof AppRoutes.CommunitiesRoomQuestionList
>

const Questions = observer(({ navigation, route }: QuestionsScreenProps) => {
  const { questions } = addMessageStore
  const communitiesTheme = useCommunitiesTheme()
  const { community } = useCommunitySpace()

  const onPressAddButton = () => {
    navigateToConnectScreen(navigation, RoomRoutes.RoomNewQuestion, {
      ...route.params,
      community
    })
  }

  return (
    <View style={styles.container}>
      <SafeAreaView style={[styles.modalContent, { backgroundColor: communitiesTheme.background }]}>
        <View style={styles.header}>
          <CloseButton
            iconType="close"
            style={{
              position: 'absolute',
              left: 8,
              top: 5
            }}
          />
          <ModalTitle title="Questions" />
          {questions.length > 0 ? <ModalButton action={onPressAddButton} text="Add" /> : null}
        </View>

        {questions.length > 0 ? (
          <QuestionsList type="question" />
        ) : (
          <View style={styles.content}>
            <NoQuestionsImage style={{ marginBottom: 40 }} />
            <PlaceholderTitle text="Add some questions" />
            <PlaceholderDescription
              text="The purpose of a Sketch is to allow users to score an idea based on some criteria. Add
              one or more questions below that describe that criteria."
            />
            <AddButton text="Add a Question" onPress={onPressAddButton} />
          </View>
        )}
      </SafeAreaView>
    </View>
  )
})

export default withBusinessSelector(Questions)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContent: {
    width: '100%',
    flex: 1
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    position: 'relative',
    paddingBottom: 10
  },
  content: {
    alignItems: 'center',
    paddingHorizontal: 40,
    marginTop: 100
  }
})

import React, { useCallback } from 'react'
import { Animated, Pressable, PressableProps, Text, View } from 'react-native'
import { observer } from 'mobx-react-lite'

/* UPDATE TO USE NATIVE-BASE THEME */
import { color } from './theme'

interface PressableOpacityProps extends PressableProps {
  children?: React.ReactNode
}

const PressableOpacity = (props: PressableOpacityProps) => {
  const { children, ...rest } = props
  const animated = new Animated.Value(1)
  const fadeIn = () => {
    Animated.timing(animated, {
      toValue: 0.1,
      duration: 100,
      useNativeDriver: false
    }).start()
  }
  const fadeOut = () => {
    Animated.timing(animated, {
      toValue: 1,
      duration: 200,
      useNativeDriver: false
    }).start()
  }

  return (
    <Pressable onPressIn={fadeIn} onPressOut={fadeOut} {...rest}>
      <Animated.View style={{ opacity: animated }}>{children}</Animated.View>
    </Pressable>
  )
}

interface NFTCardProps {
  onPress: () => void
  tokenId: string
  tokenName: string
  editionInfo: string
  Face?: React.ReactNode
  Badges?: React.ReactNode
  showDivider?: boolean
  titles?: boolean
  editions?: boolean
  showBadges?: boolean
  showEditions?: boolean
  showTitles?: boolean
  cardStyle?: any
  titleStyle?: any
}

const Divider = () => (
  <View
    key="spacer-1"
    style={{ borderBottomWidth: 1, borderColor: color.borderGrey, marginTop: 5 }}
  />
)

export const NFTCard = observer((props: NFTCardProps) => {
  const {
    tokenName,
    editionInfo,
    tokenId,
    onPress,
    Face,
    Badges,
    cardStyle,
    titleStyle,
    titles = true,
    editions = true
  } = props

  const renderDivider = useCallback(() => {
    if (titles && Badges) {
      return <Divider />
    } else if (Badges && editions) {
      return <Divider />
    } else {
      return null
    }
  }, [Badges, editions, titles])

  const { fontSize, ...rest } = titleStyle
  const cardSize = '173px'

  const tokenNameStyle = {
    color: '#262626',
    fontFamily: 'Inter',
    fontSize: '1.06rem',
    fontWeight: '600',
    marginTop: 10,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: '-0.41px',
    height: '1.33rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
    textRendering: 'optimizelegibility',
    textAlign: 'left',
    ...rest
  }

  return (
    <PressableOpacity
      accessibilityRole="button"
      style={{
        alignSelf: 'center',
        borderColor: color.borderGrey,
        borderRadius: 8,
        borderWidth: cardStyle && cardStyle.border ? parseInt(cardStyle.border) : 1,
        // marginVertical: 5,
        width: cardSize,
        overflow: 'hidden',
        backgroundColor: 'rgba(255,255,255,0.25)',
        ...cardStyle
      }}
      onPress={onPress}
    >
      {Face ? (
        <View style={{ alignSelf: 'center', width: cardSize, height: cardSize }}>{Face}</View>
      ) : null}

      <View style={{ marginHorizontal: 10 }}>
        <View>
          {props.showTitles ? (
            <View key={`${tokenId}.name`} style={{ marginTop: 10 }}>
              <Text numberOfLines={1} style={tokenNameStyle}>
                {tokenName}
              </Text>
            </View>
          ) : null}

          {props.showEditions ? (
            <Text
              style={{
                color: color.grey,
                fontFamily: 'Inter-Regular',
                fontSize: 13,
                fontWeight: '400',
                marginTop: 10,
                ...rest
              }}
            >
              {editionInfo}{' '}
            </Text>
          ) : null}
        </View>

        {renderDivider()}

        {props.showBadges ? (
          <View style={{ marginVertical: 10 }}>{Badges ? Badges : null}</View>
        ) : null}
      </View>
    </PressableOpacity>
  )
})

export default NFTCard

import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query'
import { PresenceEvent, useGetMatrixFullStateSync, useMatrixUser } from '@vatom/sdk/react'
import axios from 'axios'
import { IEvent } from 'matrix-js-sdk'

import { host, matrixServerUrl } from './constants'
import { getFilters } from './helpers'
import { CommunitiesReactionType, MemberData, MemberType } from './types'
import { useCommunitySpace } from './useCommunitySpace'

export const fetchMemberData = async (memberId?: string) => {
  const userId = memberId?.split('@').pop()?.split(':')[0]
  return axios.get(`https://users.vatom.com/${userId}`)
}

export const useMemberData = (memberId?: string) => {
  const result = useQuery(['member-data', memberId], () => fetchMemberData(memberId), {
    enabled: !!memberId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,

    meta: {
      volatile: true
    }
  })

  if (result.isLoading || !result.data) {
    return null
  }

  const { name, picture, preferred_username } = result.data.data
  return {
    name,
    picture,
    tag: preferred_username
  } as MemberData
}

export const fetchMedia = async (mediaId: string) => {
  return axios.get(
    `https://matrix.vatom.com/_matrix/media/v3/thumbnail/vatom.com/${mediaId}?width=320&height=240`
  )
}

export const useMedia = (mediaId: string) => {
  const result = useQuery(['media', mediaId], () => fetchMedia(mediaId), {
    enabled: !!mediaId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity
  })
  return result
}

export const fetchMembers = async (roomId: string, synapseAccessToken?: string) => {
  return axios.get(
    `${matrixServerUrl}/_matrix/client/v3/rooms/${roomId}/members?access_token=${synapseAccessToken}`
  )
}

export const useMembers = (roomId: string) => {
  const { data: matrixUser } = useMatrixUser()
  const result = useQuery(
    ['matrix-members', roomId],
    () => fetchMembers(roomId, matrixUser?.access_token),
    {
      enabled: !!roomId && !!matrixUser?.access_token,
      select: data =>
        data.data.chunk.map((item: any) => ({
          eventId: item.event_id,
          id: item.sender,
          isJoined: item.content.membership === 'join'
        })) as Array<MemberType>,
      retry: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  )
  return result
}

export const fetchMessageFacades = async (
  pluginId: string,
  messageTypes: string[],
  businessId?: string
) => {
  return axios.post(`${host}/b/${businessId}/spark-plugins/${pluginId}/events`, {
    type: 'message.display',
    messageTypes
  })
}

export const useMessageFacades = (messageTypes: string[], options = {}) => {
  const { businessId } = useCommunitySpace()
  const { data: plugins } = usePlugins()
  const pluginData = plugins ? plugins[0] : null

  const result = useQuery({
    queryKey: ['message-facades', pluginData?.id, messageTypes, businessId],
    queryFn: () => fetchMessageFacades(pluginData?.id, messageTypes, businessId),
    enabled: !!pluginData?.id && !!businessId,
    select: data => data.data,
    meta: {
      volatile: true
    },
    ...options
  })
  return result
}

export const fetchMessageInputs = async (
  pluginId: string,
  messageTypes: string[],
  businessId?: string
) => {
  return axios.post(`${host}/b/${businessId}/spark-plugins/${pluginId}/events`, {
    type: 'message.new',
    messageTypes
  })
}

export const useMessageInputs = (messageTypes: string[], businessId?: string) => {
  const { data: plugins } = usePlugins()
  const pluginData = plugins ? plugins[0] : null

  const result = useQuery(
    ['message-inputs', pluginData?.id, businessId, messageTypes],
    () => fetchMessageInputs(pluginData?.id, messageTypes, businessId),
    {
      enabled: !!pluginData?.id && !!businessId,
      select: data => data.data[0].inputs,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      meta: {
        volatile: true
      }
    }
  )

  return result
}

export const fetchPlugins = async (businessId?: string) => {
  return await axios.get(`${host}/b/${businessId}/spark-plugins`)
}

export const usePlugins = (options = {}) => {
  const { businessId } = useCommunitySpace()
  const result = useQuery(['business-plugins', businessId], () => fetchPlugins(businessId), {
    enabled: !!businessId,
    select: data => data.data,
    meta: {
      volatile: true
    },
    ...options
  })
  return result
}

export const fetchMessageReactions = async (
  roomId: string,
  eventId?: string,
  accessToken?: string
) => {
  return axios.get(
    `${matrixServerUrl}/_matrix/client/v1/rooms/${roomId}/relations/${eventId}/m.annotation?access_token=${accessToken}&limit=20`
  )
}

export const useMessageReactions = (roomId: string, eventId?: string) => {
  const { data: matrixUser } = useMatrixUser()
  const result = useQuery(
    ['message-reactions', roomId, eventId],
    () => fetchMessageReactions(roomId, eventId, matrixUser?.access_token),
    {
      enabled: !!matrixUser?.access_token && !!eventId,
      select: data => {
        if (!data?.data?.chunk) {
          return []
        }
        const reactions = data.data.chunk.map((reaction: IEvent) => ({
          sender: reaction.sender,
          eventId: reaction.event_id,
          key: reaction.content['m.relates_to']?.key
        }))
        return reactions as Array<CommunitiesReactionType>
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      meta: {
        volatile: true
      }
    }
  )
  return result
}

export const fetchPollVotes = async (
  pageParam: string,
  roomId: string,
  eventId?: string,
  type?: string,
  accessToken?: string
) => {
  const res = axios
    .get(
      `${matrixServerUrl}/_matrix/client/v1/rooms/${roomId}/relations/${eventId}/${type}?access_token=${accessToken}&limit=20&from=${
        pageParam ?? ''
      }`
    )
    .then(({ data }) => data)
  return res
}

export const useMessagePollVotes = (roomId: string, eventId?: string, type?: string) => {
  const { data: matrixUser } = useMatrixUser()
  const result = useInfiniteQuery(
    ['message-poll-votes', roomId, eventId, type],
    ({ pageParam = '' }) =>
      fetchPollVotes(pageParam, roomId, eventId, type, matrixUser?.access_token),
    {
      getNextPageParam: (lastPage, pages) => lastPage.end,
      enabled: !!matrixUser?.access_token,
      meta: {
        volatile: true
      },
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false
    }
  )
  return result
}

const useMessagesReplace = (queryMessages: IEvent[], roomId: string) => {
  const { data: matrixUser } = useMatrixUser()
  const queryClient = useQueryClient()
  const messageReplaces = useQueries({
    queries: queryMessages?.map(event => {
      return {
        queryKey: ['message-replace', roomId, event.event_id],
        queryFn: () => fetcMessageReplace(roomId, event.event_id, matrixUser?.access_token),
        enabled: queryMessages.length > 0,
        select: (data: { chunk: IEvent[] }) => data.chunk[0]?.content['m.new_content'],
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
        onSuccess: (data: any) => {
          queryClient.setQueryData(['message', roomId, event.event_id], () => {
            if (data) {
              return {
                ...event,
                content: data
              }
            } else {
              return event
            }
          })
        }
      }
    })
  })
  return messageReplaces
}

export const fetchThreadReplies = async (
  pageParam: string,
  roomId: string,
  eventId?: string,
  accessToken?: string
) => {
  const res = axios
    .get(
      `${matrixServerUrl}/_matrix/client/v1/rooms/${roomId}/relations/${eventId}/m.thread?access_token=${accessToken}&limit=20&dir=f&from=${
        pageParam ?? ''
      }`
    )
    .then(({ data }) => data)
  return res
}

export const useMessageThreadReplies = (roomId: string, eventId?: string) => {
  const { data: matrixUser } = useMatrixUser()
  const result = useInfiniteQuery(
    ['message-thread-replies', roomId, eventId],
    ({ pageParam = '' }) =>
      fetchThreadReplies(pageParam, roomId, eventId, matrixUser?.access_token),
    {
      getNextPageParam: (lastPage, pages) => lastPage.next_batch,
      enabled: !!matrixUser?.access_token && !!eventId,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      select: data => {
        const pages = data.pages.map(page => [
          ...page.chunk.filter((event: IEvent) => !('redacted_because' in event))
        ])

        return {
          pages,
          pageParams: data.pageParams
        }
      }
    }
  )

  const queryMessagesMapped =
    result.data?.pages.flatMap(group => {
      return group?.map((event: IEvent) => event)
    }) ?? []

  useMessagesReplace(queryMessagesMapped, roomId)

  return result
}

type FetchMessagesResult = { chunk: IEvent[]; start?: string; end?: string }

export const fetchMessages = async <T = FetchMessagesResult>(
  pageParam: string | null = null,
  roomId: string,
  accessToken?: string,
  filters = ''
) => {
  const url = `${matrixServerUrl}/_matrix/client/v3/rooms/${roomId}/messages?access_token=${accessToken}&dir=b&limit=10&from=${
    pageParam ?? ''
  }${filters ?? ''}`
  const res = await axios.get(url).then(({ data }) => data)
  return res
}

export const useMessages = (roomId: string, activeFilter: string, enabled: boolean) => {
  const { data: filters } = usePlugins({
    select: (data: any) => getFilters(activeFilter, data.data[0]?.descriptor.facades.message)
  })

  const { data: matrixUser } = useMatrixUser()
  const result = useInfiniteQuery(
    ['messages', roomId, filters],
    async ({ pageParam = '' }) => {
      return await fetchMessages(pageParam, roomId, matrixUser?.access_token, filters)
    },
    {
      getNextPageParam: (lastPage, pages) => (pages.length < 1 ? lastPage.end : undefined),
      enabled: !!matrixUser?.access_token && !!filters && enabled,
      // keepPreviousData: true,
      onError: error => {
        console.error(error)
      },

      // onSuccess: data => {
      //   data.pages[data.pages.length - 1].forEach(event => {
      //     queryClient.setQueryData(['message', roomId, event.event_id], () => event)
      //   })
      // },
      select: data => {
        const pages = data?.pages.map(page => [
          ...page.chunk.filter((event: IEvent) => !('redacted_because' in event))
        ])

        return {
          pages,
          pageParams: data?.pageParams,
          nextPage: data?.pages[data.pages.length - 1].end
        }
      }
    }
  )

  const queryMessagesMapped =
    result.data?.pages.flatMap(group => {
      return group?.map((event: IEvent) => event)
    }) ?? []

  useMessagesReplace(queryMessagesMapped, roomId)

  return result
}

export const fetchMessage = (roomId: string, eventId?: string, accessToken?: string) => {
  return axios
    .get(
      `${matrixServerUrl}/_matrix/client/r0/rooms/${roomId}/event/${eventId}?access_token=${accessToken}`
    )
    .then(data => data.data)
}

export const useMessage = (roomId: string, eventId?: string, enabled = false) => {
  const { data: matrixUser } = useMatrixUser()
  const result = useQuery({
    queryKey: ['message', roomId, eventId],
    queryFn: async () => {
      const message = await fetchMessage(roomId, eventId, matrixUser?.access_token)
      return message
    },
    enabled,
    cacheTime: Infinity,
    staleTime: Infinity
  })

  return result
}

export const fetcMessageReplace = async (
  roomId: string,
  eventId?: string,
  accessToken?: string
) => {
  const res = axios
    .get(
      `${matrixServerUrl}/_matrix/client/v1/rooms/${roomId}/relations/${eventId}/m.replace?access_token=${accessToken}&limit=1`
    )
    .then(({ data }) => data)
  return res
}

export const useMessageReplace = (roomId: string, eventId?: string, options = {}) => {
  const queryClient = useQueryClient()
  const { data: matrixUser } = useMatrixUser()
  const result = useQuery(
    ['message-replace', roomId, eventId],
    () => fetcMessageReplace(roomId, eventId, matrixUser?.access_token),
    {
      enabled: !!matrixUser?.access_token && !!eventId,
      meta: {
        volatile: true
      },
      select: (data: { chunk: IEvent[] }) => data.chunk[0]?.content['m.new_content'],
      onSuccess: data => {
        console.log('data', data)
        queryClient.setQueryData(['message', roomId, eventId], (old: any) => {
          if (data) {
            return {
              ...old,
              content: data
            }
          } else {
            return old
          }
        })
      },
      ...options
    }
  )

  return result
}

export const fetchMessageHide = async (roomId: string, eventId?: string, accessToken?: string) => {
  const res = axios
    .get(
      `${matrixServerUrl}/_matrix/client/v1/rooms/${roomId}/relations/${eventId}/v.hide?access_token=${accessToken}&limit=1`
    )
    .then(({ data }) => data)
  return res
}

export const useMessageHide = (roomId: string, eventId?: string, options = {}) => {
  const { data: matrixUser } = useMatrixUser()
  const result = useQuery(
    ['message-hide', roomId, eventId],
    () => fetchMessageHide(roomId, eventId, matrixUser?.access_token),
    {
      enabled: !!matrixUser?.access_token && !!eventId,
      refetchOnWindowFocus: false,
      ...options
    }
  )

  return result
}

export const useUploadMedia = () => {
  const { data: matrixUser } = useMatrixUser()
  const mutation = useMutation((payload: { blob: ArrayBufferLike; mimeType: string }) => {
    return axios.post(
      `${matrixServerUrl}/_matrix/media/v3/upload?access_token=${matrixUser?.access_token}`,
      payload.blob,
      {
        headers: {
          'Content-Type': payload.mimeType
        }
      }
    )
  })
  return mutation
}

type PowerLevels = {
  events: {
    [type in string]: number
  }
  users: {
    [userId in string]: number
  }
}

const fetchPowerLevels = async (roomID: string, accessToken?: string) => {
  const res = await axios.get<PowerLevels>(
    `${matrixServerUrl}/_matrix/client/r0/rooms/${roomID}/state/m.room.power_levels?access_token=${accessToken}`
  )
  return res.data
}

export const useRoomPowerLevels = (
  roomID: string,
  options = {
    enabled: true
  }
) => {
  const matrixUser = useMatrixUser()
  const result = useQuery(
    ['user-power-level', roomID, matrixUser.data?.access_token],
    () => fetchPowerLevels(roomID, matrixUser.data?.access_token),
    {
      enabled: !!matrixUser.data?.access_token && options.enabled
    }
  )
  return result
}

export const fetchSketchVote = async (
  roomId: string,
  eventId?: string,
  userID?: string,
  accessToken?: string
) => {
  const res = axios
    .get(
      `${matrixServerUrl}/_matrix/client/v1/rooms/${roomId}/relations/${eventId}/${userID}.sketch.vote?access_token=${accessToken}&limit=1`
    )
    .then(({ data }) => data)
  return res
}

export const useUserSketchVotes = (roomId: string, eventId?: string) => {
  const { data: matrixUser } = useMatrixUser()
  const result = useQuery(
    ['message-user-sketch-votes', roomId, eventId, matrixUser?.user_id],
    () => fetchSketchVote(roomId, eventId, matrixUser?.user_id, matrixUser?.access_token),
    {
      enabled: !!matrixUser?.access_token && !!eventId
    }
  )
  return result
}

export const fetchQuestionVote = async (
  roomId: string,
  eventId?: string,
  questionNumber?: string,
  userID?: string,
  accessToken?: string
) => {
  const res = axios
    .get(
      `${matrixServerUrl}/_matrix/client/v1/rooms/${roomId}/relations/${eventId}/${userID}.question.vote.qn-${questionNumber}?access_token=${accessToken}&limit=1`
    )
    .then(({ data }) => data)
  return res
}

export const useUserQuestionVotes = (roomId: string, eventId?: string, questionNumber?: string) => {
  const { data: matrixUser } = useMatrixUser()
  const result = useQuery(
    ['message-user-question-votes', roomId, eventId, questionNumber, matrixUser?.user_id],
    () =>
      fetchQuestionVote(
        roomId,
        eventId,
        questionNumber,
        matrixUser?.user_id,
        matrixUser?.access_token
      ),
    {
      enabled: !!matrixUser?.access_token && !!eventId,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false
    }
  )
  return result
}

export const useUserPowerLevel = (roomId: string) => {
  const { data: matrixUser } = useMatrixUser()
  const { data: powerLevels } = useRoomPowerLevels(roomId)
  let currentUserPowerLevel = 0
  if (matrixUser) {
    currentUserPowerLevel = powerLevels?.users[matrixUser?.user_id] ?? 0
  }
  return currentUserPowerLevel
}

import AsyncStorage from '@react-native-async-storage/async-storage'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type InitialQsStore = {
  /**
   * Used to keep track of the initial qs while we do things like launching id.vatom.com
   */
  initialQS: string
  /**
   * Used to keep track of the qs that we need to pass to the experience sdk
   */
  qsForExperienceSDK: string
}

export const useInitialQsStore = create(
  persist<InitialQsStore>(
    () => {
      return {
        initialQS: '',
        qsForExperienceSDK: ''
      }
    },
    {
      name: 'initial-qs',
      getStorage: () => AsyncStorage,
      version: 1
    }
  )
)

import React, { useCallback, useEffect } from 'react'
import { Linking } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import { useConfig } from '@vatom/sdk/react'
import { translate, TxKeyPath } from '@vatom/utils'
import { PressableOpacity, Text, theme } from '@vatom/wombo'
import { Box, Pressable } from 'native-base'

import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import Market from '../assets/market.svg'
// import Spaces from '../assets/spaces.svg'
import Studio from '../assets/studio.svg'
import Wallet from '../assets/wallet.svg'

type MenuItems = {
  name: TxKeyPath
  link: string
  icon: React.ReactNode
}

const menuItems: MenuItems[] = [
  {
    name: 'common.wallet',
    link: '#wallet',
    icon: <Wallet width={24} height={25} />
  },
  {
    name: 'common.market',
    link: 'https://market.vatom.com/',
    icon: <Market width={24} height={24} />
  },
  {
    name: 'common.studio',
    link: 'https://studio.vatom.com/',
    icon: <Studio width={24} height={24} />
  }
  // {
  //   name: 'Spaces',
  //   link: 'https://spaces.vatom.com/',
  //   icon: <Spaces width={27} height={24} />
  // }
]

export const VatomMenu = () => {
  const ref = React.useRef(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const { isDark } = useBusinessTheme()
  const config = useConfig()

  const handleClickedOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && event.target) {
        // @ts-ignore
        if (!ref.current?.contains(event.target)) {
          event.stopPropagation()
          closeMenu()
        }
      }
    },
    [ref]
  )

  const handleEscPress = useCallback(
    (event: KeyboardEvent) => {
      if (ref.current && event.key === 'Escape') {
        event.stopPropagation()
        closeMenu()
      }
    },
    [ref]
  )

  useEffect(() => {
    document.addEventListener('click', handleClickedOutside)
    return () => {
      document.removeEventListener('click', handleClickedOutside)
    }
  }, [handleClickedOutside])

  useEffect(() => {
    document.addEventListener('keydown', handleEscPress)
    return () => {
      document.removeEventListener('keydown', handleEscPress)
    }
  }, [handleEscPress])

  const toggleMenu = () => setIsOpen(!isOpen)
  const closeMenu = () => setIsOpen(false)

  const onPressItem = async (url: string) => {
    const supported = await Linking.canOpenURL(url)
    if (supported) {
      await Linking.openURL(url)
    } else {
      console.log(`Don't know how to open this URL: ${url}`)
    }
  }

  if (!config.features.showProductSelector) return null

  return (
    <Box position={'relative'}>
      <Pressable
        ref={ref}
        onPress={toggleMenu}
        accessibilityHint=""
        accessibilityLabel={translate('homeScreen.moreOptions')}
        height={30}
        justifyContent={'center'}
      >
        <Ionicons
          name="apps"
          size={24}
          color={isDark ? theme.colors.textDarkMode[100] : theme.colors.black}
        />
      </Pressable>
      {isOpen && (
        <Box
          position={'absolute'}
          right={0}
          style={{
            transform: [{ translateY: 30 }]
          }}
          minWidth={200}
          flexDirection={'column'}
          flexWrap={'nowrap'}
          _light={{
            backgroundColor: theme.colors.white
          }}
          _dark={{
            backgroundColor: theme.colors.grayCool[900],
            borderColor: theme.colors.white,
            borderWidth: 1
          }}
          borderRadius={3}
          shadow={0}
        >
          {menuItems.map(item => (
            <PressableOpacity
              key={item.name}
              minWidth={100}
              padding={3}
              alignItems={'center'}
              justifyContent={'space-around'}
              color={
                item.name === 'common.wallet' ? theme.colors.brand[500] : theme.colors.bodyText
              }
              _hover={{
                backgroundColor: theme.colors.trueGray[50]
              }}
              _dark={{
                color:
                  item.name === 'common.wallet'
                    ? theme.colors.brand[500]
                    : theme.colors.textDarkMode[100],
                _hover: {
                  backgroundColor: theme.colors.brand[500],
                  color: theme.colors.textDarkMode[100]
                }
              }}
              onPress={() => onPressItem(item.link)}
            >
              <Box justifyContent={'space-between'} alignItems={'center'}>
                {item.icon}
                <Text color="inherit" margin={2} tx={item.name} />
              </Box>
            </PressableOpacity>
          ))}
        </Box>
      )}
    </Box>
  )
}

import React, { memo } from 'react'
import { OverlayView } from 'react-google-maps'
import { Marker, OVERLAY_MOUSE_TARGET, OverlayViewF } from '@react-google-maps/api'
import { GeoDiscoveryGroup } from '@vatom/sdk/react'
import { Text, View } from 'native-base'

import { useCommunitiesTheme } from '../../../Communities/themes'

type GroupMarkerProps = {
  geoGroup: GeoDiscoveryGroup
  onPress: (geoGroup: GeoDiscoveryGroup) => void
}

export const GroupMarker = memo(
  ({ geoGroup, onPress }: GroupMarkerProps) => {
    const { key, lon, lat, count } = geoGroup
    const keyMarkerAlt = `group_marker_${key}`

    const communitiesTheme = useCommunitiesTheme()

    const finalWH = count > 9 && count < 100 ? 40 : count < 10 ? 32 : 52

    if (!lon || !lat) return null

    return (
      <OverlayViewF
        key={keyMarkerAlt}
        position={{
          lat,
          lng: lon
        }}
        mapPaneName={OVERLAY_MOUSE_TARGET}
      >
        <View
          style={{
            width: finalWH,
            height: finalWH,
            backgroundColor: communitiesTheme.accentColor ?? '#FF8000',
            borderColor: '#FFFFFF',
            borderRadius: finalWH / 2,
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            borderWidth: 3,
            shadowColor: 'rgba(0, 0, 0, 0.2)',
            shadowOpacity: 24,
            shadowOffset: { width: 0, height: 0 }
          }}
        >
          <Text
            onPress={() => onPress(geoGroup)}
            style={{
              color: '#FFFFFF',
              fontSize: 13,
              lineHeight: 18,
              fontWeight: '600'
            }}
          >
            {count.toString()}
          </Text>
        </View>
      </OverlayViewF>
    )
  },
  () => true
)

export default GroupMarker

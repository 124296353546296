import React from 'react'
import { StyleSheet } from 'react-native'
import { Button, Image, Text, View } from 'native-base'

import logger from '../../logger'
import { FaceProps, placeHolderImage } from '../'

function ErrorFace(props: FaceProps) {
  const { errorMessage, viewMode } = props

  const icon = viewMode === 'icon'

  const styles = StyleSheet.create({
    container: {
      position: 'relative'
    },
    img: {
      borderTopLeftRadius: icon ? 8 : 0,
      borderTopRightRadius: icon ? 8 : 0,
      resizeMode: 'contain',
      width: '100%',
      height: '100%',
      zIndex: 10
    },
    btn: {
      zIndex: 11,
      position: 'absolute',
      top: 5,
      right: 5,
      backgroundColor: 'yellow'
    }
  })

  const renderComents = (): JSX.Element | null => {
    if (__DEV__) {
      logger.info('[ErrorFace].renderComents: ', errorMessage)
      return (
        <Button
          style={styles.btn}
          onPress={e => window.alert(`[ErrorFace].renderComents:${errorMessage}`)}
        >
          <Text>?</Text>
        </Button>
      )
    } else {
      return null
    }
  }

  return (
    <View style={styles.container}>
      <Image
        alt="Image"
        source={placeHolderImage}
        accessibilityIgnoresInvertColors
        style={styles.img}
      ></Image>
      {renderComents()}
    </View>
  )
}

Object.defineProperty(ErrorFace, 'name', {
  writable: true,
  value: 'ErrorFace'
})

export default ErrorFace

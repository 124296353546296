/* eslint-disable unicorn/filename-case */
import React, { useCallback, useRef } from 'react'
import { useWindowDimensions } from 'react-native'
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native'
import { CompositeRegion, TokenType } from '@vatom/sdk/core'
import { AppStackParamList, TabRoutes } from '@vatom/wallet-routes'
import { observer } from 'mobx-react-lite'
import { ScrollView } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import { useBusinessSelector } from '../../../hooks/useBusinessSelector'
import { useIsDesktop } from '../../../hooks/useIsDesktop'
import { useLayoutScrollHandler } from '../../../hooks/useLayoutScrollHandler'
import { useWindowScrollRestoration } from '../../../hooks/useWindowScrollRestoration.web'
import logger from '../../../logger'
import { AppRoutes } from '../../../navigators'

import { onEndReachedThreshold } from './WalletFlatlistItem'
import { WalletItem } from './WalletItem'

let countRenders = 0

interface WalletListProps {
  isLoading: boolean
  isMobile: boolean
  numColumns: number
  region: CompositeRegion
  tokens: CompositeRegion['walletTokens']
}

export const WalletList = observer((props: WalletListProps) => {
  const { isLoading, numColumns, region, tokens } = props
  const navigation = useNavigation()
  const ref = useRef<HTMLDivElement>(null)
  const { business, businessIdentifier } = useBusinessSelector()
  const { getHeaderHeight } = useLayoutScrollHandler()
  const isDesktop = useIsDesktop()
  const { width: windowWidth } = useWindowDimensions()

  const columnGap = 10
  const paddingGap = columnGap
  const cw = (windowWidth - paddingGap * 2 - columnGap) / numColumns
  const cardWidth = cw ? cw : 180

  const onPressHandler = useCallback(
    (item: TokenType) => {
      type CommonParams = Omit<AppStackParamList[typeof AppRoutes.NFTDetail_Business], 'business'> &
        AppStackParamList[typeof AppRoutes.NFTDetail]

      const commonParams: CommonParams = {
        tokenId: item.id
      }

      if (!item.type.includes('vatom')) {
        commonParams.contractAddress = item.contractAddress
        commonParams.owner = item.owner
      }

      if (businessIdentifier) {
        return navigation.navigate(AppRoutes.NFTDetail_Business, {
          ...commonParams,
          business: businessIdentifier
        })
      }

      navigation.navigate(AppRoutes.NFTDetail, commonParams)
    },
    [businessIdentifier, navigation]
  )
  const onItemPress = useThrottledCallback(onPressHandler, 1000)
  const renderItem = useCallback(
    ({ item }: { item: TokenType }) => {
      return (
        <WalletItem
          onPress={onItemPress}
          item={item}
          business={business}
          pageConfig={business?.pageConfig}
          width={cardWidth}
        />
      )
    },
    [business, cardWidth, onItemPress]
  )

  countRenders++
  logger.debug('[Wallet.render] countRenders', countRenders)
  logger.debug('[Wallet.render] isLoading', isLoading)
  logger.debug('[Wallet.render] tokens.length', tokens.length)

  const fetchNextPage = async () => {
    await region.fetchPage({
      businessId: business?.id ?? ''
    })
  }
  const throttledCopyFromStash = useThrottledCallback(fetchNextPage, 300)

  const onWindowScroll = useCallback(() => {
    if (
      ref.current &&
      window.innerHeight + window.scrollY >= ref.current.offsetHeight * onEndReachedThreshold
    ) {
      // you're at the bottom of the page
      throttledCopyFromStash()
    }
  }, [throttledCopyFromStash])

  useFocusEffect(
    useCallback(() => {
      window.addEventListener('scroll', onWindowScroll)
      return () => {
        window.removeEventListener('scroll', onWindowScroll)
      }
    }, [onWindowScroll])
  )

  useWindowScrollRestoration(TabRoutes.Wallet)
  const isFocused = useIsFocused()
  const offFocusStyles = !isFocused
    ? {
        maxHeight: '100svh',

        overflow: 'hidden'
      }
    : {}

  return (
    <ScrollView horizontal={false}>
      <div
        ref={ref}
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${numColumns}, minmax(0px, 1fr))`,
          margin: '0px 15px',
          maxWidth: '100%',
          paddingInlineStart: 0,
          marginBlockStart: 0,
          gap: '10px',
          paddingTop: !isDesktop ? getHeaderHeight() : 0,
          ...offFocusStyles
        }}
      >
        {tokens.map(token => (
          <div key={`${token.id}`}>{renderItem({ item: token })}</div>
        ))}
      </div>
    </ScrollView>
  )
})

import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useLocationFromIp, useRefreshOnFocus, useUser } from '@vatom/sdk/react'
import * as Contacts from 'expo-contacts'

import {
  converToContactSelector,
  DeviceContactsWithUserId,
  useGetPublicProfilesWithDevice
} from '../../profile'
import { useRelGetContacts } from '../../relationships'
import { ContactSources, DeviceContact, GetDeviceContactsOptions } from '../types'

import { deviceContactsSelector, relationshipContactSelector } from './selectors'

export const deviceContactsQueryKeys = {
  deviceContacts: [{ scope: 'device-contacts' }] as const,
  isAvailable: [{ scope: 'is-contacts-available' }] as const,
  contactsPermission: [{ scope: 'contacts-permission' }] as const,
  getDeviceContacts: (permission?: Contacts.PermissionStatus) =>
    [{ ...deviceContactsQueryKeys.deviceContacts[0], permission }] as const
}

const fetchContacts = () => {
  return Contacts.getContactsAsync({
    fields: [Contacts.Fields.Emails, Contacts.Fields.PhoneNumbers]
  })
}

export const useIsContactsAvailable = () => {
  const query = useQuery({
    queryKey: deviceContactsQueryKeys.isAvailable,
    queryFn: () => Contacts.isAvailableAsync()
  })

  return query
}

export const useGetDeviceContacts = <T = Contacts.ContactResponse>(
  options?: GetDeviceContactsOptions<T>
) => {
  const contactPermission = useGetContactsPermission()
  const query = useQuery({
    queryKey: deviceContactsQueryKeys.getDeviceContacts(contactPermission.data?.status),
    queryFn: fetchContacts,
    enabled:
      contactPermission.data?.status === Contacts.PermissionStatus.GRANTED && options?.enabled,
    ...options,
    refetchOnMount: true,
    refetchOnWindowFocus: true
  })

  useRefreshOnFocus(query.refetch)

  return query
}

export const useGetInvitePeople = () => {
  const deviceVatomContacts = useGetDeviceVatomContacts()
  const deviceNonVatomContacts = useGetDeviceNonVatomContacts()

  useRefreshOnFocus(deviceNonVatomContacts.refetch)

  const vatomContacts = deviceVatomContacts.map(contact => {
    return contact.data
  })

  return [...vatomContacts, ...(deviceNonVatomContacts?.data ?? [])].sort((a, b) => {
    return a?.name && b?.name ? a.name.localeCompare(b.name) : -1
  })
}

export const useGetDeviceVatomContacts = () => {
  const currentUser = useUser()
  const locationFromIp = useLocationFromIp()
  const deviceContacts = useGetDeviceContacts({
    select: contacts => relationshipContactSelector(contacts, locationFromIp?.data?.country_code)
  })
  const relContactsQuery = useRelGetContacts(deviceContacts?.data ?? [])

  const uniqueUserIdsSet: Set<string> = new Set()

  const deviceContactsWithUserIds = useMemo(() => {
    if (!relContactsQuery.data || 'error' in relContactsQuery.data) {
      return []
    }

    return relContactsQuery.data
      .map((contact, index) => {
        if (contact && !contact.error) {
          return {
            userId: contact.id,
            deviceContact: deviceContacts?.data?.[index] ?? null
          }
        }

        return null
      })
      .filter(contact => contact) as DeviceContactsWithUserId[]
  }, [relContactsQuery, deviceContacts])

  const uniqueContactWithIds =
    deviceContactsWithUserIds?.reduce((accumulator, contactObject) => {
      if (
        contactObject &&
        contactObject.deviceContact &&
        !uniqueUserIdsSet.has(contactObject.userId) &&
        currentUser?.sub !== contactObject.userId
      ) {
        uniqueUserIdsSet.add(contactObject.userId)
        accumulator.push(contactObject)
      }
      return accumulator
    }, [] as DeviceContactsWithUserId[]) ?? []

  const publicProfiles = useGetPublicProfilesWithDevice(uniqueContactWithIds, user =>
    converToContactSelector(user, ContactSources.devicecontacts)
  )

  return publicProfiles
}

export const useGetDeviceNonVatomContacts = () => {
  const locationFromIp = useLocationFromIp()
  const relationContacts = useGetDeviceContacts({
    select: contacts => relationshipContactSelector(contacts, locationFromIp?.data?.country_code),
    enabled: true
  })
  const deviceContacts = useGetDeviceContacts({
    select: contacts => deviceContactsSelector(contacts, locationFromIp?.data?.country_code),
    enabled: true
  })
  const relContactsQuery = useRelGetContacts(relationContacts?.data ?? [], {
    select: contacts => {
      return contacts
        .map((contact, index) => {
          if (!contact && deviceContacts?.data) {
            deviceContacts.data[index].name =
              deviceContacts.data[index]?.name ?? deviceContacts.data[index].value
            return deviceContacts.data[index]
          }
          return null
        })
        .filter(contact => contact) as DeviceContact[]
    }
  })

  return relContactsQuery
}

export const useGetContactsPermission = () => {
  const query = useQuery({
    queryKey: deviceContactsQueryKeys.contactsPermission,
    queryFn: Contacts.getPermissionsAsync,
    refetchOnMount: true,
    refetchOnWindowFocus: true
  })

  useRefreshOnFocus(query.refetch)

  return query
}

import React from 'react'
import { Switch, View } from 'react-native'
import { observer } from 'mobx-react-lite'

import { useCommunitiesTheme } from '../../../themes'
import ModalText from '../../modal-components/ModalText'
import { useStoreContext } from '../../MSTContextProvider'
import HideResultsIcon from '../assets/hide_results_icon.svg'

import { DecorationData } from './ModalDecorations'
import ModalDecorationWrapper from './ModalDecorationWrapper'

const ModalHideResults = observer(({ decorationData }: { decorationData: DecorationData }) => {
  const store = useStoreContext()
  const hideResultsInput = store.additionalInputs.find(
    ({ type }: { type: string }) => type === 'hide-results'
  )

  React.useEffect(() => {
    store.addAdditionalInputs(decorationData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const communitiesTheme = useCommunitiesTheme()

  if (!hideResultsInput) {
    return null
  }

  return (
    <ModalDecorationWrapper>
      <View style={{ flexDirection: 'row' }}>
        <HideResultsIcon
          width="20"
          style={{ marginRight: 12 }}
          fill={communitiesTheme.labelIconColor}
        />
        <ModalText text={decorationData.placeholder} />
      </View>
      <Switch
        trackColor={{
          false: communitiesTheme.labelTrackColor,
          true: communitiesTheme.accentColor
        }}
        thumbColor={communitiesTheme.accentColorText}
        onValueChange={value => hideResultsInput.setValue(value)}
        value={hideResultsInput.value as boolean}
        ios_backgroundColor={communitiesTheme.labelTrackColor}
        style={{ transform: [{ scaleX: 0.6 }, { scaleY: 0.6 }] }}
      />
    </ModalDecorationWrapper>
  )
})

export default ModalHideResults

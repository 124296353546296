import { Image, Pressable } from 'native-base'

interface coverinterface {
  url?: string
  onCoverClick: (state: boolean) => void
}

export const RenderCover = (props: coverinterface) => {
  const { onCoverClick, url } = props

  return (
    <Pressable
      accessibilityRole="button"
      onPress={() => onCoverClick(true)}
      zIndex={10}
      position="absolute"
      top={0}
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
    >
      <Image
        w="100%"
        h="100%"
        alt={'token_cover'}
        source={{
          uri: url,
          cache: 'force-cache',
          headers: {
            Accept: '*/*'
          }
        }}
        accessibilityIgnoresInvertColors
      />
    </Pressable>
  )
}

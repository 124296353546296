import { Suspense, useCallback, useEffect, useMemo, useRef } from 'react'
import { useAnimations, useGLTF } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import { BVatomTokenType } from '@vatom/BVatom/plugin'
import { observer } from 'mobx-react-lite'
import { LoopOnce, Object3D, Vector3 } from 'three'
import { SkeletonUtils } from 'three-stdlib'
import { create } from 'zustand'

import {
  computeBearing,
  haversineDistance,
  useSortedTokenStore
} from '../games/CollectionGameV1/utils'
import { useAutoRotate } from '../hooks/useAutoRotate'
import { useFilterStore } from '../hooks/useFilterStore'
import { useFollowCamera } from '../hooks/useFollowCamera'
import { useLocationStore } from '../hooks/useLocationStore'
import { useNormalizedScale } from '../hooks/useNormalizedScale'
import { ModelProps } from '../types/ModelProps'

import { useCompassHUDMarker } from './CompassHUD'

export const Model = (props: ModelProps) => {
  return (
    // TODO: add loading state model
    <Suspense fallback={null}>
      <BaseModel {...props} />
    </Suspense>
  )
}

const useEnvMap = (group: Object3D) => {
  const { scene } = useThree()
  group.traverse(node => {
    // @ts-ignore
    if (node.isObject3D && node.isMesh && node.material) {
      // @ts-ignore
      node.material.envMap = scene.environment
      // @ts-ignore
      node.material.needsUpdate = true
    }
  })
}

export const BaseModel = (props: ModelProps) => {
  const { scene, animations } = useGLTF(props.url)
  const copiedScene = useMemo(() => SkeletonUtils.clone(scene), [scene])
  useEnvMap(copiedScene)
  const { names, actions } = useAnimations(animations, copiedScene)
  useNormalizedScale(copiedScene)
  useCompassHUDMarker(copiedScene)
  // useFollowCamera(copiedScene, props)
  useEffect(() => {
    const action = actions[names[0]]
    action?.play()
  }, [actions, names])
  useAutoRotate(copiedScene, true)

  return (
    <primitive
      envMapIntensity={5}
      onClick={props.onClick}
      position={props.position}
      // scale={scale}
      object={copiedScene}
    />
  )
}

type GeoModelProps = Omit<ModelProps, 'position'> & {
  token: BVatomTokenType
}

const getRuleByEvent = (token: BVatomTokenType, eventName: string) => {
  return token.resources
    .find(r => r.name === 'Scene')
    ?.animation_rules?.find(r => r.on === eventName)
}

const MAX_RETRIES = 10 // Number of times to retry if position is taken
const ANGLE_INCREMENT = Math.PI / 18 // 10 degrees in radians
const DISTANCE_INCREMENT = 10

export const GeoModelContainer = observer((props: GeoModelProps) => {
  const { scene, animations } = useGLTF(props.url)
  const copiedScene = useMemo(() => SkeletonUtils.clone(scene), [scene])
  const { onClick: _onClick, token } = props
  const [longitude, latitude] = token.position?.coordinates ?? [0, 0]
  const positionVector = useRef<Vector3>(new Vector3(0, 0, 0))

  useEnvMap(copiedScene)
  const { names, actions, mixer } = useAnimations(animations, copiedScene)
  useNormalizedScale(copiedScene)
  useCompassHUDMarker(copiedScene)
  const startAnimationRule = getRuleByEvent(token, 'start')
  const startAnimation = startAnimationRule?.play ? actions[startAnimationRule.play] : null
  const clickAnimationRule = getRuleByEvent(token, 'click')
  const clickAnimation = clickAnimationRule?.play ? actions[clickAnimationRule.play] : null

  // TODO: enable from seetings
  const followCamera = useFollowCamera(copiedScene, false)
  //
  useAutoRotate(copiedScene, !startAnimation && !names[0])

  useEffect(() => {
    if (startAnimation) {
      startAnimation?.play()
    } else {
      const action = actions[names[0]]
      action?.play()
    }
  }, [actions, names, startAnimation])

  const onClick = useCallback(
    (e: any) => {
      e.stopPropagation()
      if (e.eventObject.visible) {
        if (startAnimation && startAnimation.isRunning()) {
          startAnimation.stop()
        }
        if (clickAnimation) {
          clickAnimation.setLoop(LoopOnce, 1)
          clickAnimation.play()

          // hide after animation
          mixer.addEventListener('finished', () => {
            e.eventObject.visible = false
            copiedScene.userData.hiddenFromFilter = true
            // @ts-expect-error don't want to touch this as much as I can for now
            _onClick?.(e, copiedScene, startAnimation)
          })
        } else {
          // @ts-expect-error don't want to touch this as much as I can for now
          _onClick?.(e, copiedScene, startAnimation)
        }
        console.log('visible', e)
      } else {
        console.log('not visible', e)
      }
    },
    [_onClick, clickAnimation, copiedScene, mixer, startAnimation]
  )

  const hideTimeoutRef = useRef<NodeJS.Timeout>()
  const holdPositionTimeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    return () => {
      if (hideTimeoutRef.current) clearTimeout(hideTimeoutRef.current)
      if (holdPositionTimeoutRef.current) clearTimeout(holdPositionTimeoutRef.current)
    }
  }, [])
  const filter = useFilterStore(state => state)

  useEffect(
    () => {
      copiedScene.visible = false
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  )

  useFrame((scene, delta) => {
    const { position, heading } = useLocationStore.getState()
    const filter = useFilterStore.getState()

    if (position?.coords.latitude && position?.coords.longitude && heading) {
      const distance = haversineDistance(
        position.coords.latitude,
        position?.coords.longitude,
        latitude,
        longitude
      )
      copiedScene.userData.distanceFromUser = distance

      const shouldHide = distance > Math.max(filter.visibleRadius, position.coords.accuracy)

      if (shouldHide) {
        // usePositionsStore.setState(state => {
        //   state.visiblePositions.delete(token.id)
        //   return state
        // })
        copiedScene.visible = false
        return
      }

      const sortedTokens = useSortedTokenStore.getState().tokens
      const isInSortedList = sortedTokens.find(t => t.id === token.id)

      if (!isInSortedList) {
        return
      }

      if (!copiedScene.visible) {
        const computedBearing = computeBearing(
          position.coords.latitude,
          position.coords.longitude,
          latitude,
          longitude
        )

        const headingInDegrees = (computedBearing - heading + 360) % 360
        let headingInRadians = (headingInDegrees * Math.PI) / 180

        let finalDistance = Math.max(distance * filter.distanceMultiplier, filter.minDistance)
        let retries = 0
        let x = scene.camera.position.x + finalDistance * Math.cos(headingInRadians)
        let z = scene.camera.position.z + finalDistance * Math.sin(headingInRadians)

        positionVector.current.set(x, 1, z)
        const visiblePositions = usePositionsStore.getState().visiblePositions
        let isPositionTaken = Array.from(visiblePositions.values()).some(
          v => v.distanceTo(positionVector.current) < 4
        )

        while (isPositionTaken && retries < MAX_RETRIES) {
          headingInRadians += ANGLE_INCREMENT
          finalDistance += DISTANCE_INCREMENT

          x = scene.camera.position.x + finalDistance * Math.cos(headingInRadians)
          z = scene.camera.position.z + finalDistance * Math.sin(headingInRadians)
          positionVector.current.set(x, 1, z)

          isPositionTaken = Array.from(visiblePositions.values()).some(
            v => v.distanceTo(positionVector.current) < 4
          )

          retries++
        }

        usePositionsStore.setState(state => {
          state.visiblePositions.set(token.id, positionVector.current.clone())
          return state
        })

        copiedScene.position.copy(positionVector.current)

        followCamera()
        copiedScene.visible = true
      }
    }
  }, -1)

  return (
    <primitive
      envMapIntensity={5}
      onClick={onClick}
      scale={1}
      object={copiedScene}
      visible={false}
    />
  )
})

export const GeoModel = (props: GeoModelProps) => {
  return (
    <Suspense fallback={null}>
      <GeoModelContainer {...props} />
    </Suspense>
  )
}

const usePositionsStore = create<{ visiblePositions: Map<string, Vector3> }>(set => ({
  visiblePositions: new Map()
}))

import React, { useCallback, useState } from 'react'
import { useFocusEffect } from '@react-navigation/native'
import { PublicProfileSnapshot } from '@vatom/sdk/core'
import { translate } from '@vatom/utils'
import { Status, Toast } from '@vatom/wombo'
import { Box, Pressable, Radio, Text } from 'native-base'

import { Coin } from '../../../hooks/useLoyalty'

const UserIdentities = (props: {
  user: PublicProfileSnapshot
  coin: Coin
  setWeb3address: (address: string) => void
}) => {
  const { user } = props
  const userIdentities = user.identities
    .filter(identity => identity.type === props.coin.type)
    .filter(identity => 'isCustodial' in identity && identity.isCustodial === false)

  const [state, setState] = useState<string>('')

  const setWeb3address = useCallback(
    (add: string) => {
      props.setWeb3address(add)
      setState(add)
    },
    [props]
  )

  useFocusEffect(() => {
    if (userIdentities.length === 0) {
      console.warn('No identities found for user: ', user)

      Toast({
        title: translate('errors.default'),
        placement: 'top',
        status: Status.error,
        withMargin: true
      })
    }

    if (userIdentities.length === 1) {
      setWeb3address(userIdentities[0].value)
    }
  })

  return userIdentities.length > 1 ? (
    <Box width="100%" flex={1} mt={8} px={4}>
      <Box alignItems="center" flexDir="row" justifyContent="space-between">
        <Text color="darkText" fontFamily="Inter-SemiBold" fontSize="22px">
          Select Address
        </Text>
      </Box>
      <Radio.Group
        value={state}
        name="walletAddressGroup"
        onChange={ident => setWeb3address(ident)}
      >
        {userIdentities.map((item, index) => {
          return (
            <Box
              width="100%"
              borderBottomWidth="1px"
              borderBottomColor="light.100"
              key={item.value}
            >
              <Text
                color="lightText"
                marginTop="16px"
                marginBottom="8px"
                fontFamily="Inter-Medium"
                fontSize="12px"
              >
                {/* {'isCustodial' in item && item.isCustodial === true ? 'Custodial' : item.type} */}
                {item.type}
              </Text>
              <Pressable accessibilityLabel={`${item.value}`} accessibilityHint="">
                <Box
                  aria-label="Close"
                  marginBottom="20px"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text
                    ellipsizeMode="tail"
                    noOfLines={1}
                    fontFamily="Inter-Regular"
                    fontSize="11px"
                    pr={5}
                    w="90%"
                  >
                    {item.value}
                  </Text>

                  <Radio
                    value={item.value}
                    aria-label="Close"
                    key={`${item.value}`}
                    accessibilityLabel={`${item.value}`}
                    accessibilityHint=""
                  ></Radio>
                </Box>
              </Pressable>
            </Box>
          )
        })}
      </Radio.Group>
    </Box>
  ) : null
}

export default UserIdentities

import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { useNavigation } from '@react-navigation/native'

import { useBusinessSelector } from '../../hooks/useBusinessSelector'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { getAllParamsFromRoutes } from '../../navigators'

const useCommunitySpace = () => {
  const { communities, business } = useBusinessSelector()
  const { brandTheme, pageTheme, isDark } = useBusinessTheme()
  // const [categoryId, setCategoryId] = useState(null)
  const navigation = useNavigation()
  const paramsInRoutes = getAllParamsFromRoutes(navigation.getState())
  const { spaceId, community } = paramsInRoutes ?? {}

  const rootId = useMemo(() => {
    if (communities) {
      // get default community id from business
      const defaultCommunity = communities.find(community => community.isDefault)
      return defaultCommunity?.id
    }
  }, [communities])

  const getCommunityName = (id: string) => {
    const community = communities?.find(c => c.id === id)
    if (community?.isDefault) return business?.displayName ?? ''
    if (community?.name) return community.name
    return business?.displayName ?? ''
  }

  return {
    rootId: rootId,
    getCommunityName,
    accentColor: brandTheme?.primary ?? pageTheme.background,
    businessId: business?.id as string,
    spaceId,
    community: community as string
  }
}

type CommunitiesContextProps = {
  spaceId: string
}
const CommunitiesContext = createContext({
  spaceId: ''
} as CommunitiesContextProps)

export const CommunitiesProvider = ({
  children,
  spaceId
}: PropsWithChildren<{ spaceId: string }>) => {
  const value = useMemo(
    () => ({
      spaceId
    }),
    [spaceId]
  )

  return <CommunitiesContext.Provider value={value}>{children}</CommunitiesContext.Provider>
}

export const useCommunitiesContext = () => useContext(CommunitiesContext) as CommunitiesContextProps

export { useCommunitySpace }

import { useQuery } from '@tanstack/react-query'

import { BusinessData, BusinessReference, useBusiness, useBusinessByIds, useSDK } from '../store'

// TODO: remove stage hard code
export const useBusinessProfile = (props: BusinessReference, STAGE: string) => {
  const { data: business } = useBusiness(props)
  const sdk = useSDK()

  const businessId = business?.id

  const result = useQuery(
    ['business-profile', businessId, business?.pageConfig],
    async () => {
      if (!businessId) {
        throw new Error('[useBusinessProfile]: no business id available')
      }
      const b = await sdk.vatomIncApi.getPublicBusinessProfile(businessId)
      // if (STAGE !== 'prod' && b?.id === 'gsndxqUVp1') {
      //   b.pageConfig.features.footer = {
      //     enabled: true,
      //     icons: [
      //       {
      //         src: '',
      //         link: 'lays/2022/',
      //         title: 'Home',
      //         id: 'home'
      //       }
      //     ]
      //   }
      // }

      if (b?.id === 'nQwtevgfOa' && b.pageConfig.theme) {
        console.log('new page config', JSON.stringify(b?.pageConfig))
        // @TODO: remove this once we properly define the theme schema
        //@ts-ignore
        delete b.pageConfig.theme.main
        delete b.pageConfig.theme.icon
        delete b.pageConfig.theme.iconTitle
      }

      return b
    },
    {
      enabled: !!businessId,
      cacheTime: 5 * 60 * 1000
    }
  )
  return result
}
export const useBusinesses = (ids: string[]) => {
  const select = (data: BusinessData[]) => data
  return useBusinessByIds(ids, select)
}

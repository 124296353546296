/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * ConnectionCountsBase
 * auto generated base class for the model ConnectionCountsModel.
 */
export const ConnectionCountsModelBase = ModelBase
  .named('ConnectionCounts')
  .props({
    __typename: types.optional(types.literal("ConnectionCounts"), "ConnectionCounts"),
    fromCount: types.union(types.undefined, types.integer),
    toCount: types.union(types.undefined, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ConnectionCountsModelSelector extends QueryBuilder {
  get fromCount() { return this.__attr(`fromCount`) }
  get toCount() { return this.__attr(`toCount`) }
}
export function selectFromConnectionCounts() {
  return new ConnectionCountsModelSelector()
}

export const connectionCountsModelPrimitives = selectFromConnectionCounts().fromCount.toCount

import { useCallback, useEffect, useMemo } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { SessionSource, SessionType } from '@vatom/sdk/core'
import { useIsAuthed, useSDK } from '@vatom/sdk/react'
import { LoaderView } from '@vatom/wombo'
import * as AuthSession from 'expo-auth-session'
import jwtDecode from 'jwt-decode'
import parsePhoneNumberFromString from 'libphonenumber-js'
import { observer } from 'mobx-react-lite'
import { View } from 'native-base'

import { useAnalytics } from '../../hooks/useAnalytics'
import { useAuthLogout } from '../../hooks/useAuthLogout'
import { navigationPendingStore } from '../../hooks/useNavigationPending'
import { useStore } from '../../models'
import { AppRoutes, AppStackScreenProps } from '../../navigators'

import ActionSheetMerge from './partials/MergeAccounts'

type ClaimProps = AppStackScreenProps<typeof AppRoutes.Claim>

export const Claim = observer(({ route }: ClaimProps) => {
  const navigationStore = navigationPendingStore()
  const focused = useIsFocused()
  const isAuthed = useIsAuthed()
  const navigation = useNavigation()
  const sdk = useSDK()
  const { analytics } = useAnalytics()
  const { doLogout } = useAuthLogout()
  const store = useStore()

  const setSessionAndUser = async (tokenRepsonse: AuthSession.TokenResponse) => {
    try {
      const { accessToken, expiresIn, refreshToken, tokenType, scope } = tokenRepsonse

      // Add token to the axios services
      sdk.service.setToken(accessToken)
      const date = new Date()
      sdk.dataPool.sessionStore.add({
        type: SessionType.JWT,
        value: {
          idToken: '',
          accessToken,
          refreshToken,
          expiresAt: new Date(
            new Date(date).setSeconds(date.getSeconds() + (expiresIn || 0))
          ).getTime()
        },
        source: SessionSource.Vatom
      })

      // Perform token enchange
      await sdk.vatomIncApi.performTokenExchange(accessToken)

      if (accessToken && expiresIn && refreshToken && tokenType && scope) {
        await sdk.dataPool.user.fetchIdentities()
        await sdk.dataPool.user.fetchMe()

        analytics.event('initiateAction', {
          actionUri: 'Claim',
          claimId: route.params.claimId
        })
      }
    } catch (error) {
      console.error('Error setting session and user', error)
      navigation.navigate(AppRoutes.connect)
    }
  }

  useEffect(() => {
    const performLogin = async () => {
      const loginType = route.params['iden-type']
      let loginValue = route.params['iden-value'].trim()
      if (loginType === 'phoneNumber') {
        loginValue = loginValue.startsWith('+') ? loginValue : `+${loginValue}`
      }

      try {
        const session = await sdk.vatomIncApi.loginWithOtp(loginValue, loginType, route.params.otp)

        if (!session.access_token) {
          return null
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const decodedToken: any = jwtDecode(session.access_token)

        const accessToken = {
          accessToken: session.access_token,
          refreshToken: session.refresh_token,
          expiresIn: decodedToken.exp,
          issuedAt: decodedToken.iat,
          tokenType: session.token_type,
          scope: decodedToken.scope
        }

        /* @ts-ignore */
        setSessionAndUser(accessToken)
      } catch (error) {
        console.error('Error performing claim', error)

        navigation.navigate(AppRoutes.connect)
      }

      const { linking } = store

      if (!linking.linkingUrl) {
        return null
      }

      const url = new URL(linking.linkingUrl)

      await AsyncStorage.removeItem('CLAIM_DETAILS')
      store.linking?.clearLinkingUrl()

      navigationStore.setPendingNavigationPath(url.pathname)
    }
    if (focused && !isAuthed) {
      performLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused, isAuthed, navigation, route.params, sdk.vatomIncApi, store.linking])

  const onMerge = useCallback(async () => {
    const loginType = route.params['iden-type']
    let loginValue = route.params['iden-value'].trim()
    if (loginType === 'phoneNumber') {
      loginValue = loginValue.startsWith('+') ? loginValue : `+${loginValue}`
    }
    const session = await sdk.vatomIncApi.loginWithOtp(loginValue, loginType, route?.params?.otp)

    if (!session.access_token) {
      await AsyncStorage.removeItem('CLAIM_DETAILS')
      return null
    }

    if (sdk.dataPool.sessionStore?.vatomIncSessionToken) {
      await sdk.vatomIncApi.mergeAccounts(
        session.access_token,
        sdk.dataPool.sessionStore?.vatomIncSessionToken.accessToken
      )
    }
    store.linking?.clearLinkingUrl()
    await AsyncStorage.removeItem('CLAIM_DETAILS')
    // navigation.navigate(AppRoutes.home)
    navigation.navigate(AppRoutes.NFTDetail, { tokenId: route.params.claimId })
  }, [
    navigation,
    route.params,
    sdk.dataPool.sessionStore?.vatomIncSessionToken,
    sdk.vatomIncApi,
    store.linking
  ])

  const onCancel = async () => {
    // Logout and Claim again
    AsyncStorage.setItem('CLAIM_DETAILS', JSON.stringify(route.params))
    await doLogout()
  }

  const userInfo = sdk.dataPool?.user?.userInfo

  const getShouldOfferMerge = () => {
    if (!isAuthed) {
      return false
    }
    // id methods where different
    return !getShouldAutoMerge()
  }

  const identification = useMemo(() => {
    const idenType = route.params['iden-type']
    let idenValue = route.params['iden-value'].trim()
    if (idenType === 'phoneNumber') {
      idenValue = idenValue.startsWith('+') ? idenValue : `+${idenValue}`
    }
    return idenValue
  }, [route])

  const getShouldAutoMerge = () => {
    const idenType = route.params?.['iden-type']
    if (!isAuthed) {
      return false
    }
    if (
      idenType === 'phoneNumber' &&
      userInfo?.phone_number_verified &&
      arePhoneNumbersEqual(identification, userInfo?.phone_number)
    ) {
      return true
    }

    if (idenType === 'email' && userInfo?.email_verified && userInfo?.email === identification) {
      return true
    }
  }

  const shouldAutoMerge = getShouldAutoMerge()
  const shouldOfferMerge = getShouldOfferMerge()

  const loggedInAs = sdk.dataPool.user.userInfo?.email
    ? sdk.dataPool.user.userInfo?.email
    : sdk.dataPool.user.userInfo?.phone_number

  useEffect(() => {
    if (shouldAutoMerge) onMerge()
  }, [onMerge, shouldAutoMerge])

  if (!focused) {
    return null
  }

  if (shouldOfferMerge) {
    const message = `You're logged in as ${loggedInAs} but this Smart NFT was sent to ${identification}. Would you like to consolidate these accounts?`

    return (
      <View h="100%" bgColor="white">
        <ActionSheetMerge message={message} onMerge={onMerge} onCancel={onCancel} isOpen={true} />
      </View>
    )
  } else {
    return <LoaderView />
  }
})

function arePhoneNumbersEqual(a: string, b: string) {
  const phone1 = parsePhoneNumberFromString(a)
  const phone2 = parsePhoneNumberFromString(b)
  return phone1 && phone2 && phone1.isEqual(phone2)
}

export default Claim

import React, { useMemo } from 'react'
import { Pressable, StyleSheet } from 'react-native'
import Animated, { Extrapolate, interpolate, useAnimatedStyle } from 'react-native-reanimated'
import { BottomSheetBackdropProps, useBottomSheetModal } from '@gorhom/bottom-sheet'

export const BottomSheetCustomBackdrop = ({
  animatedIndex,
  style,
  onPress
}: BottomSheetBackdropProps & { onPress?: () => void }) => {
  const { dismiss } = useBottomSheetModal()

  const containerAnimatedStyle = useAnimatedStyle(() => ({
    opacity: interpolate(animatedIndex.value, [-1, 0], [0, 0.5], Extrapolate.CLAMP)
  }))

  const containerStyle = useMemo(
    () => [style, { backgroundColor: '#000' }, containerAnimatedStyle],
    [style, containerAnimatedStyle]
  )

  return (
    <Animated.View style={[StyleSheet.absoluteFill, containerStyle]}>
      <Pressable
        accessibilityRole="button"
        onPress={() => {
          if (onPress) {
            onPress()
          } else {
            dismiss()
          }
        }}
        style={StyleSheet.absoluteFill}
      ></Pressable>
    </Animated.View>
  )
}

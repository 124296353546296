import { create } from 'zustand'

interface ScrollStore {
  lastKnownPositions: Record<string, number>
  setLastKnownPosition: (key: string, position: number) => void
}

export const useScrollStore = create<ScrollStore>(set => ({
  lastKnownPositions: {},
  setLastKnownPosition: (key, position) => {
    set(state => ({
      lastKnownPositions: {
        ...state.lastKnownPositions,
        [key]: position
      }
    }))
  }
}))

import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { useSDK } from '@vatom/sdk/react'

export const inviteQueryKeys = {
  inviteContact: [{ scope: 'invite-contact' }] as const,
  getInviteLink: (vatomToken: string, userId: string) =>
    [{ ...inviteQueryKeys.inviteContact[0], vatomToken, userId }] as const
}

export const distibutionHost = 'https://distribution.api.vatominc.com'

type InviteLink = {
  shareUrl: string
}

const fetchInviteLink = async ({
  queryKey: [{ vatomToken, userId }]
}: QueryFunctionContext<ReturnType<typeof inviteQueryKeys['getInviteLink']>>) => {
  const res = await fetch(`${distibutionHost}/share`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${vatomToken}`,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      userId: userId,
      campaignId: 'oxne8JyguG',
      businessId: 'ym3t3mPi0g',
      shareTargetUrl: 'https://wallet.vatom.com',
      type: 'join'
    })
  })

  const data = (await res.json()) as InviteLink
  return data
}

export const useGetInviteLink = () => {
  const sdk = useSDK()
  const user = sdk.dataPool.user.userInfo

  const query = useQuery({
    queryKey: inviteQueryKeys.getInviteLink(
      sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken ?? '',
      user?.sub ?? ''
    ),
    queryFn: fetchInviteLink,
    enabled: !!sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken && !!user?.sub
  })

  return query
}

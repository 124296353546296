import React from 'react'
import { Text, View } from 'react-native'

import { useCommunitiesTheme } from '../../../themes'

const Title = ({ text }: { text: string }) => {
  const communitiesTheme = useCommunitiesTheme()

  return (
    <View style={{ marginBottom: 8 }}>
      <Text
        style={{
          fontSize: 17,
          color: communitiesTheme.chatBubbleContent.text,
          lineHeight: 24,
          fontWeight: 'bold'
        }}
      >
        {text}
      </Text>
    </View>
  )
}

export default Title

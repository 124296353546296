import { type IEvent, TEMP_EVENT_PREFIX } from '@vatom/sdk/react'

export type ImageMedia = {
  url: string
  alt?: string
  width: number
  height: number
  size?: number
  blurhash?: string
  isLocal?: boolean
  uri?: string
}

// Helpers
export function getMediaId(uri: string) {
  const elements = uri.split('/')
  const mediaId = elements[elements.length - 1]
  return mediaId
}

export function getThumbnailUrl(
  uri: string,
  options = {
    width: 320,
    height: 240
  }
) {
  if (!uri.startsWith('mxc://')) {
    return uri
  }
  const mediaId = getMediaId(uri)
  return `https://matrix.vatom.com/_matrix/media/v3/thumbnail/vatom.com/${mediaId}?width=${options.width}&height=${options.height}`
}

export function getImageDataFromEvent(event: IEvent) {
  const isLocalEvent = event.event_id.startsWith(TEMP_EVENT_PREFIX)
  return {
    isLocal: isLocalEvent,
    uri: event.content?.uri,
    url: event.content?.url,
    alt: event.content?.body,
    blurhash: event.content.info?.blurhash ?? '',
    width: event.content?.info?.w,
    height: event.content?.info?.h
  } as ImageMedia
}

export function getReplyToEventId(event: IEvent) {
  if (
    event.content['m.relates_to'] &&
    'm.in_reply_to' in event.content['m.relates_to'] &&
    event.content['m.relates_to']['m.in_reply_to'] &&
    'event_id' in event.content['m.relates_to']['m.in_reply_to']
  ) {
    return event.content['m.relates_to']['m.in_reply_to']['event_id'] ?? undefined
  }
  return undefined
}

import React from 'react'
import { Text, theme } from '@vatom/wombo'

export interface TextProps {
  text: string
  color?: string
}

export const PrimaryText = ({ text, color }: TextProps) => {
  return (
    <Text
      marginBottom="20px"
      fontFamily="Inter-Bold"
      fontSize="34px"
      textAlign="center"
      lineHeight="41px"
      _light={{
        color: color ?? theme.colors.systemColorsLight.orange
      }}
      _dark={{
        color: theme.colors.systemColorsDark.orange
      }}
    >
      {text}
    </Text>
  )
}

export default PrimaryText

import React from 'react'
import { Center } from 'native-base'

import { ProfileAvatar } from './ProfileAvatar'
import { ProfileUserBasicInfo } from './ProfileUserBasicInfo'

type ProfileUserCardProps = {
  avatarUrl?: string
  name?: string
  username?: string
}

function ProfileUserCard(props: ProfileUserCardProps) {
  return (
    <Center>
      <ProfileAvatar url={props.avatarUrl} />
      {props.name && <ProfileUserBasicInfo name={props.name} username={props.username} />}
    </Center>
  )
}

export { ProfileUserCard }

import { get } from 'lodash'
import * as THREE from 'three'

const ShowDebugLogs = false

/** Possible start conditions for an animation rule */
enum AnimationStartCondition {
  /** Triggers when another animation starts */
  AnimationStart = 'animation-start',

  /** Triggers when another animation completes */
  AnimationComplete = 'animation-complete',

  /** Triggers when an action completes */
  ActionComplete = 'action-complete',

  /** Triggers when an action fails */
  ActionFail = 'action-fail',

  /** Triggers when the scene starts */
  Start = 'start',

  /** Triggers when the user clicks on the object */
  Click = 'click',

  /** Triggered when the state changes to a specified value */
  StateChange = 'state',

  /** Triggered when the item is being removed */
  Remove = 'remove'
}

/** Animation rule */
interface AnimationRule {
  /** Specify what triggers this animation */
  on: AnimationStartCondition | string

  /** Trigger target, depends on the trigger type */
  target?: string

  /** Value, depends on the trigger type */
  value?: string

  /** Wait time in milliseconds before performing this rule */
  delay?: number

  /** AnimationClip name to play */
  play?: string

  /** Custom action to be performed by the host app */
  custom?: string

  /** A sound resource to play */
  sound?: {
    /** Name of the sound resource */
    resource_name: string

    /** True if the sound is positional, false if it's played directly */
    is_positional?: boolean

    /** Sound volume */
    volume?: number
  }

  /** Action to perform */
  action?: {
    /** Action name */
    name: string
  }

  /** Display an alert to the user */
  alert?: {
    /** Alert message */
    text: string

    /** Alert title */
    title?: string
  }

  /** If true, this object will be removed when this rule is executed */
  remove?: boolean
}

/**
 * Manages the animations being played for a scene.
 */
export class AnimationManager {
  // Root object for animations
  scene: THREE.Object3D

  // Animation clips
  clips: THREE.AnimationClip[]

  // Animation rules
  rules: AnimationRule[]

  // Audio listener
  audioListener: THREE.AudioListener

  // Currently playing animation
  currentAnimation: string

  // Stores pending and completed audio buffer promises
  audioBufferPromises: { [key: string]: Promise<AudioBuffer> } = {}

  // Stores the last playback time of a sound. This helps prevent playing back multiple times after loading
  audioPlayTimes: any

  // Delayed actions. Each object contains an `at` timestamp and a `rule` to perform
  delayedActions: any[]

  // Animation mixer
  mixer: THREE.AnimationMixer

  // Set by the user of this class, will be called when an animation rule requests to perform an action
  requestingPerformAction: any

  // Set by the user of this class, will be called to map a resource name to a URL
  requestingResourceURL: any

  // Set by the user of this class, will be called when an alert needs to be displayed to the user. If null, will use the browser's `alert()` instead
  requestingAlert: any

  // Set by the user of this class, will be called when an animation rule passes a custom value to the host app. This can be used to trigger host actions, such as closing a window, etc.
  requestingCustomAction: any

  // Set by the user of this class, will be called when an animation rule wants to remove this object.
  requestingRemove: any

  // Stores the latest result of an action
  latestResult: any

  /**
   * Manages and executes animations.
   *
   * @param scene The root object for animations.
   * @param clips Animation clips
   * @param rules Set of animation_rules attached to the vatom
   * @param objectState The vatom's raw payload
   * @param audioListener Audio listener for the scene
   */
  constructor(
    scene: THREE.Object3D,
    clips: THREE.AnimationClip[],
    rules: AnimationRule[],
    objectState: any = {},
    audioListener: THREE.AudioListener
  ) {
    // Store vars
    this.scene = scene
    this.clips = clips || []
    this.rules = rules || []
    this.audioListener = audioListener
    if (ShowDebugLogs) console.debug(`[Animation Manager] Loaded, rules =`, rules)

    // Currently playing animation
    this.currentAnimation = ''

    // Stores the last playback time of a sound. This helps prevent playing back multiple times after loading.
    this.audioPlayTimes = {}

    // Delayed actions. Each object contains an `at` timestamp and a `rule` to perform.
    this.delayedActions = []

    // Create animation mixer
    this.mixer = new THREE.AnimationMixer(scene)
    this.mixer.addEventListener('finished', this.onAnimationFinished.bind(this))

    // Trigger event
    this.onStart()

    // Trigger state changed event
    this.onStateChanged(objectState)
  }

  /** Must be called every frame by the renderer */
  update(delta: number) {
    // Update animation mixer
    this.mixer.update(delta)

    // Perform any delayed actions
    while (this.delayedActions.length > 0 && this.delayedActions[0].at <= Date.now()) {
      // Perform this delayed action now
      const action = this.delayedActions.shift()
      this.performAction(action.rule, true)
    }
  }

  /** Plays the specified named animation */
  play(name: string) {
    // Expand vars in name
    name = this.expandVars(name)

    // Check if currently playing animation name matches this one
    if (name == this.currentAnimation) return

    // Find new animation
    const anim = this.clips.find(c => c.name == name)
    if (!anim)
      return console.warn(
        `[Animation Manager] Unable to find animation with the name ${name}. Ignoring.`
      )

    // Stop all current animations
    this.mixer.stopAllAction()

    // HACK: For some reason the animation just won't play if you call play() a second time? Let's just remove all
    // cached actions until this can be sorted.
    for (const clip of this.clips) this.mixer.uncacheClip(clip)

    // Play this one
    if (ShowDebugLogs) console.debug(`[Animation Manager] Playing animation = ${name}`)
    this.currentAnimation = name
    const action = this.mixer.clipAction(anim)
    action.clampWhenFinished = true
    action.setLoop(THREE.LoopOnce, 1)
    action.reset()
    action.play()

    // Check for "animation-start" events (also prevent replaying the animation again)
    this.performActions(
      this.rules.filter(r => r.on == 'animation-start' && r.target == name && r.play != name)
    )
  }

  /** Expand variables in the string */
  expandVars(str: string) {
    // Ensure it's a string
    if (!str || !str.substring) return str

    // Create variable context
    const ctx = {
      result: this.latestResult
    }

    // Go through each item, maximum of 100 vars
    for (let count = 0; count < 100; count++) {
      // Find brackets
      const startIdx = str.indexOf('${')
      const endIdx = str.indexOf('}', startIdx)
      if (startIdx == -1 || endIdx == -1) break

      // Get value
      const path = str.substring(startIdx + 2, endIdx)
      const value = get(ctx, path)

      // Replace in string
      str = str.substring(0, startIdx) + value + str.substring(endIdx + 1)
    }

    // Done
    return str
  }

  /** Display an alert */
  showAlert(text: string, title: string) {
    // Expand vars
    text = this.expandVars(text)
    title = this.expandVars(title)

    // Show alert, either by asking the host to show it, or just using the browser alert
    if (this.requestingAlert) this.requestingAlert(text, title)
    else alert(`${title} - ${text}`)
  }

  /** Perform the specified actions from the rules specified */
  performActions(rules: any[]) {
    // Perform all rules that don't have a condition
    rules.filter(r => !r.condition).map(r => this.performAction(r))

    // Go through each rule with a condition
    for (const rule of rules.filter(r => r.condition)) {
      // Get components
      const matched = /(.*)(==|!=)(.*)/g.exec(rule.condition)
      let left = (matched && matched[1]) || ''
      const type = (matched && matched[2]) || ''
      let right = (matched && matched[3]) || ''

      // Expand vars
      left = this.expandVars(left)
      right = this.expandVars(right)

      // Check type
      let passes = false
      if (!type && !right && left == 'true') {
        // Always match
        passes = true
      } else if (type == '==') {
        // Check if equal
        passes = left == right
      } else if (type == '!=') {
        // Check if unequal
        passes = left != right
      }

      // Stop if didn't pass
      if (!passes) {
        console.log('[Animation Manager] Condition did not pass', left, type, right)
        continue
      }

      // Passed, run it
      console.log('[Animation Manager] Condition passed', left, type, right)
      this.performAction(rule)
      return
    }
  }

  /** Currently running action */
  runningVatomAction?: Promise<any>

  /** Perform the specified action(s) from the rule payload */
  performAction(rule: AnimationRule, isDelayedAlready = false) {
    // Execute action
    if (ShowDebugLogs) {
      const info = []
      if (rule.delay) info.push('delay = ' + rule.delay)
      if (rule.play) info.push('animation = ' + rule.play)
      if (rule.sound) info.push('sound = ' + rule.sound.resource_name)
      if (rule.action) info.push('action = ' + rule.action.name)
      console.debug(
        `[Animation Manager] ${rule.delay && !isDelayedAlready ? 'Delaying' : 'Executing'} ${
          rule.on
        } rule: ${info.join(', ')}`
      )
    }

    // Do delay if necessary
    if (rule.delay && !isDelayedAlready)
      return this.delayedActions.push({ rule, at: Date.now() + rule.delay })

    // Trigger animation
    if (rule.play) this.play(rule.play)

    // Pass custom data to the host
    if (rule.custom && !this.requestingCustomAction)
      console.warn(
        `[Animation Manager] Tried to perform custom action, but requestingCustomAction is not supplied by the host. custom = ${this.expandVars(
          rule.custom
        )}`
      )
    else if (rule.custom) this.requestingCustomAction(this.expandVars(rule.custom))

    // Show alert
    if (rule.alert) this.showAlert(rule.alert.text, rule.alert.title || '')

    // Trigger action
    if (rule.action) {
      // Send action
      this.runningVatomAction = Promise.resolve()
        .then(e => this.requestingPerformAction(rule.action))
        .then(result => {
          // Action success, play related animations
          if (ShowDebugLogs)
            console.debug(`[Animation Manager] ${rule.action!.name} action complete`, result)
          this.latestResult = result
          this.performActions(
            this.rules.filter(
              r => r.on == 'action-complete' && (!r.target || r.target == rule.action!.name)
            )
          )
        })
        .catch(err => {
          // Action failed, play related animations
          console.warn(`[Animation Manager] ${rule.action!.name} action failed: ${err.message}`)
          const failRules = this.rules.filter(
            r => r.on == 'action-fail' && (!r.target || r.target == rule.action!.name)
          )
          if (failRules.length == 0) this.showAlert(err.message, 'There was a problem')
          else this.performActions(failRules)
        })
        .then(e => {
          // Remove running action
          this.runningVatomAction = undefined
        })
    }

    // Play audio
    if (rule.sound) {
      const soundResource = this.expandVars(rule.sound.resource_name)
      this.fetchAudioBuffer(this.expandVars(soundResource)).then(buffer => {
        // Prevent overkill
        if (ShowDebugLogs) console.debug(`[Animation Manager] Playing sound = ${soundResource}`)
        const lastPlayTime = this.audioPlayTimes[soundResource] || 0
        if (Date.now() - lastPlayTime < 100) return
        this.audioPlayTimes[soundResource] = Date.now()

        // Check which class to create
        const sound = rule.sound!.is_positional
          ? new THREE.PositionalAudio(this.audioListener)
          : new THREE.Audio(this.audioListener)

        // Set buffer
        sound.setBuffer(buffer)

        // Set volume
        const volume = parseFloat(rule.sound!.volume as any)
        if (volume) sound.setVolume(volume)

        // Add to scene if positional
        if (rule.sound!.is_positional) {
          // Add to scene
          this.scene.add(sound)

          // Remove from scene once sound has finished playing
          sound.onEnded = () => {
            this.scene.remove(sound)
          }
        }

        // Play
        sound.play()
      })
    }

    // Remove object if requested
    if (rule.remove && this.requestingRemove) this.requestingRemove()
  }

  /** Fetch audio buffer */
  fetchAudioBuffer(resourceName: string): Promise<AudioBuffer> {
    // Check if one exists already
    if (this.audioBufferPromises[resourceName] as any) return this.audioBufferPromises[resourceName]

    // Create promise chain
    const promise = Promise.resolve()
      .then(e => this.requestingResourceURL(resourceName))
      .then(url => {
        // Load resource
        return new Promise((resolve, reject) =>
          new THREE.AudioLoader().load(url, resolve, undefined, reject)
        )
      }) as Promise<AudioBuffer>

    // Store promise
    this.audioBufferPromises[resourceName] = promise
    return promise
  }

  /** Called on startup */
  onStart() {
    // If no animation rules, just play the first clip
    if (this.rules.length == 0) {
      // Play first clip, if any
      if (this.clips.length > 0) this.mixer.clipAction(this.clips[0]).play()

      // Done
      return
    }

    // Go through all "start" rules
    this.performActions(this.rules.filter(r => r.on == 'start'))
  }

  /** Called when the current animation clip finishes */
  onAnimationFinished() {
    // No longer playing an animation
    const lastAnimation = this.currentAnimation
    this.currentAnimation = ''

    // Go through all rules matching what to do next
    this.performActions(
      this.rules.filter(r => r.on == 'animation-complete' && r.target == lastAnimation)
    )
  }

  /** Call this when the user clicks on the 3D model. Returns `true` if a click rule was executed. */
  onClick() {
    // Stop if an action is running
    if (this.runningVatomAction) {
      console.log("[Animation Manager] Click ignored since there's a running action.")
      return true
    }

    // Fetch click events
    this.performActions(
      this.rules.filter(
        r =>
          r.on == 'click' && (typeof r.target == 'undefined' || r.target == this.currentAnimation)
      )
    )

    // Done. Return true if we have any click handlers at all.
    return this.rules.some(r => r.on == 'click')
  }

  /** Call this when the object state changes. */
  onStateChanged(newState: any) {
    // Go through each rule
    const rulesToPerform = []
    for (const rule of this.rules) {
      // Check rule type
      if (rule.on != 'state') continue

      // Get key path
      const keyPath = rule.target
        // eslint-disable-next-line no-useless-escape
        ?.split(/\.(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/)
        .map(k => k.replace(/"/g, ''))
      if (!keyPath) continue

      // Follow key path and get the value
      let keyValue = newState
      while (keyPath.length > 0) {
        keyValue = keyValue[keyPath[0]]
        keyPath.splice(0, 1)
        if (!keyValue) break
      }

      // Check if value matches
      if (rule.value != keyValue) continue

      // Matched!
      rulesToPerform.push(rule)
    }

    // Do them
    this.performActions(rulesToPerform)
  }

  /** Call this when the 3D model is going to be removed. Returns `true` if a rule was executed. */
  onRemoveAction() {
    // Run actions
    this.performActions(
      this.rules.filter(
        r =>
          r.on == 'remove' && (typeof r.target == 'undefined' || r.target == this.currentAnimation)
      )
    )

    // Done. Return true if we have any remove handlers at all.
    return this.rules.some(r => r.on == 'remove')
  }
}

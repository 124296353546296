import MapView, {
  LatLng,
  MapEvent,
  PROVIDER_DEFAULT,
  PROVIDER_GOOGLE,
  Region
  // @ts-expect-error type definition is not available
} from 'react-native-web-maps'

export default MapView

const Marker = MapView.Marker

export { LatLng, MapEvent, Marker, PROVIDER_DEFAULT, PROVIDER_GOOGLE, Region }

import { useReducer } from 'react'

const transferInitialState = {
  isOnGoing: false,
  isCompleted: false
}

const transferActions = {
  started: 'started',
  failed: 'failed',
  completed: 'completed'
}

const transferReducer = (
  state: typeof transferInitialState,
  action: {
    type: string
  }
) => {
  switch (action.type) {
    case transferActions.started:
      return {
        ...state,
        isOnGoing: true,
        isCompleted: false
      }
    case transferActions.completed:
      return {
        ...state,
        isOnGoing: false,
        isCompleted: true
      }
    case transferActions.failed:
      return {
        ...state,
        isOnGoing: false,
        isCompleted: false
      }
    default:
      return state
  }
}

const useTransferState = () => {
  const [transferState, dispatch] = useReducer(transferReducer, transferInitialState)

  const startTransfer = () => dispatch({ type: transferActions.started })
  const completeTransfer = () => dispatch({ type: transferActions.completed })
  const failTransfer = () => dispatch({ type: transferActions.failed })

  return {
    transferState,
    startTransfer,
    completeTransfer,
    failTransfer
  }
}

export { useTransferState }

// eslint-disable-next-line unicorn/filename-case
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef, useState } from 'react'
import { Linking } from 'react-native'
import { useLinkTo, useNavigation } from '@react-navigation/native'
import { RegionType, TokenType } from '@vatom/sdk/core'
import { useAnalytics, useSDK } from '@vatom/sdk/react'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { Status, Toast } from '@vatom/wombo'
import { getSnapshot } from 'mobx-state-tree'

import { useBusinessSelector } from '../../../hooks/useBusinessSelector'
import logger from '../../../logger'
import { AppRoutes, TabRoutes } from '../../../navigators'
import { VatomWallet } from '../../../utils/WalletSdk'

export interface IUseTokenEvents {
  token?: TokenType
  close: () => void
}

export const useTokenEvents = ({ token, close }: IUseTokenEvents) => {
  const navigation = useNavigation()
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const qrPromise = useRef<any>()
  const sdk = useSDK()
  const analytics = useAnalytics()
  const linkTo = useLinkTo()
  const { businessIdentifier } = useBusinessSelector()
  const isEmbedded = useVatomWalletSdkStore(state => state.isEmbedded)

  const region = sdk.dataPool.region(RegionType.inventory)

  const getToken = useCallback(
    (payload: { id: string; objectDefinitionId: string }) => {
      let id: string = payload.id
      let token: TokenType | undefined
      if (!id && payload.objectDefinitionId) {
        // Find the first token in inventory with this objectDefinitionId
        token = region.tokens.find(
          t => t.studioInfo?.objectDefinitionId === payload?.objectDefinitionId
        )

        id = token?.id || ''
      } else {
        token = region.tokens.find(t => t.id === id)
      }
      return token
    },
    [region.tokens]
  )

  const handleOpenUrl = useCallback(
    (payload: { url: string }) => {
      try {
        analytics.event(
          'openURL',
          {
            url: payload.url
          },
          token
        )
        const url = new URL(payload.url)
        const hostnames = [
          'wallet.vatom.com',
          'wallet.localhost:3000',
          'wallet.vatominc.com',
          'wow.vatom.com'
        ]

        if (hostnames.some(hostname => url.hostname.includes(hostname))) {
          // Is app link so we link to it

          linkTo(url.pathname)
        } else {
          // Is external link so we open in a new tab or browser
          Linking.openURL(payload.url)
          return payload
        }
      } catch (error) {
        // invalid url so we can assume it's a relative path
        linkTo(payload.url)
      }
    },
    [analytics, linkTo, token]
  )

  /** Called when the face in the card view has a message for us */
  const handleMessage = useCallback(
    async (name: string, payload: any) => {
      if (!token) return

      logger.info('Received message from face:', name, payload)
      // Log analytics
      analytics.event('webBridgeEvent', { bridgeEventName: name }, token)

      if (isEmbedded) {
        const tk = getSnapshot(token)

        VatomWallet._shared.sendMsgWithoutResponse('walletsdk:analytics', {
          name,
          payload,
          token: tk
        })
      }

      if (name === 'vatom.view.close' || name === 'viewer.view.close') {
        // if (useVatomWalletSdkStore.getState().viewMessages?.includes('vatom.view.close')) {
        //   return
        // }
        close()
        return payload
      } else if (name === 'ui.scanner.show' || name === 'viewer.scanner.show') {
        analytics.event('scannerShow', {}, token)
        // Face is requesting we go to the scanner screen
        throw new Error('Bridge message not implemented.')
        // props.history.push('/scanner')
      } else if (name === 'viewer.vatom.delete' || name === 'viewer.delete-vatom') {
        // Face is requesting we delete a specific vatom
        const vatomId = payload['this.id'] || payload.id
        await token.performAction('Delete')

        // Log analytics
        if (token.type !== 'vatom-new') {
          analytics.event('performAction', {}, token)
        }

        // If this vatom is being deleted, return to home
        if (vatomId === token.id) navigation.navigate(AppRoutes.home)
        return payload
      } else if (name === 'viewer.analytics.push') {
        // Remove the BV userid
        delete payload.userId
        // Face is requesting we send analytics
        analytics.event(payload.event, payload, token)
        return payload
      } else if (name === 'ui.vatom.show' || name === 'viewer.vatom.show') {
        // Face is requesting we display a specific vatom
        const { id: tokenId } = payload
        const childToken = getToken(payload)
        if (childToken) {
          if (childToken?.studioInfo?.businessId) {
            navigation.navigate(AppRoutes.NFTDetail_Business, {
              business: childToken?.studioInfo?.businessId,
              tokenId
            })
          } else {
            navigation.navigate(AppRoutes.NFTDetail, { tokenId })
          }
        } else {
          throw new Error('Token not found.')
        }
        return payload
      } else if (name === 'viewer.ar.show') {
        // Face is requesting we display a specific vatom
        if (token?.studioInfo?.businessId) {
          navigation.navigate(AppRoutes.BusinessProxy, {
            business: token?.studioInfo?.businessId,
            screen: TabRoutes.MapAr
          })
          return
        } else {
          navigation.navigate(AppRoutes.MapAR)
          return
        }
      } else if (name === 'viewer.ar.begin-custom-session') {
        // TODO: Validate AR config
        payload.tokenId = token.id
        // Begin AR session, pass the custom AR session payload to the AR view
        throw new Error('Bridge message not implemented.')
        // if (business) {
        //   props.history.push(`/b/${business.name}/ar?c=${btoa(JSON.stringify(payload))}`)
        // } else {
        //   props.history.push(`/ar?c=${btoa(JSON.stringify(payload))}`)
        // }
      } else if (name === 'ui.qr.scan') {
        // Log analytics from qr scanner v1
        analytics.event('initiateQRScanv1', {}, token)
        // Show the scanner, return the promise
        // return scan()
      } else if (name === 'viewer.qr.scan') {
        analytics.event('initiateQRScanv2', {}, token)
        // navigation.navigate(AppRoutes.CameraScreen)
        setIsCameraOpen(true)
        return new Promise((resolve, reject) => {
          qrPromise.current = resolve
        })
      } else if (name === 'ui.browser.open' || name === 'viewer.url.open') {
        handleOpenUrl(payload)
        return payload
      } else if (name === 'viewer.view.clone') {
        analytics.event(
          'initiateAction',
          {
            actionUri: 'Clone',
            source: 'Bridge'
          },
          token
        )

        if (businessIdentifier) {
          navigation.navigate(AppRoutes.SendNFTShare_Business, {
            tokenId: token.id,
            business: businessIdentifier
          })
          return
        } else {
          navigation.navigate(AppRoutes.SendNFTShare, {
            tokenId: token.id
          })
          return
        }
      } else if (name === 'ui.map.show' || name === 'viewer.map.show') {
        analytics.event('showMap', {}, token)
        // Face is requesting we go to the map screen
        if (token?.studioInfo?.businessId) {
          navigation.navigate(AppRoutes.BusinessProxy, {
            business: token?.studioInfo?.businessId,
            screen: TabRoutes.Map
          })
        } else {
          navigation.navigate(AppRoutes.home, {
            screen: TabRoutes.Map
          })
          return
        }
      } else if (name === 'ui.vatom.transfer' || name === 'viewer.action.send') {
        // Log Analytics for initiateSend
        analytics.event(
          'initiateAction',
          {
            actionUri: 'Send',
            source: 'Bridge'
          },
          token
        )
        // Show send popup
        throw new Error('Bridge message not implemented.')
      } else if (name === 'ui.vatom.clone' || name === 'viewer.action.share') {
        analytics.event(
          'initiateAction',
          {
            actionUri: 'Share',
            eventValue: 1,
            source: 'Bridge'
          },
          token
        )
        // Show share popup
        throw new Error('Bridge message not implemented.')
      } else if (name === 'viewer.alert.show') {
        logger.info('Received message:', name, payload)
        // Show Alert
        // Alert.fire({ text: payload.message, showConfirmButton: true })

        Toast({
          title: payload.message,
          placement: 'top',
          status: payload?.type || Status.success,
          duration: payload?.ms
        })

        // if (payload.ms) {
        //   const alertTimer = setTimeout(() => {
        //     logger.info('Dismissing alert')
        //     Alert.close()
        //   }, parseInt(payload.ms))

        //   setAlertTimer(alertTimer)
        // }
        return payload
      } else if (name === 'viewer.alert.hide') {
        throw new Error('Bridge message not implemented.')
        // Hide Alert
        // Alert.close()

        // if (alertTimer) {
        //   clearTimeout(alertTimer)
        // }
      } else if (name === 'viewer.customsessionconfig.update') {
        // Do nothing
      } else {
        // Error!
        return Promise.reject(new Error('Unknown bridge message: ' + name))
      }
      // Done
      return {}
    },
    [analytics, businessIdentifier, close, getToken, handleOpenUrl, isEmbedded, navigation, token]
  )

  return {
    handleMessage,
    isCameraOpen,
    setIsCameraOpen,
    qrPromise,
    handleOpenUrl
  }
}

import * as React from 'react'
import { Entypo, Ionicons } from '@expo/vector-icons'
import { useConfig } from '@vatom/sdk/react'
import { Box, theme } from 'native-base'

import { PressableOpacity } from '../PressableOpacity'

interface HeaderBackOptionsProps {
  title?: React.ReactNode
  onPressOptions?: () => void
  onClose: () => void
  showOptions?: boolean
  color?: string
  closeIcon?: React.ReactNode
  optionIcon?: React.ReactNode
}

export const HeaderBackOptions = ({
  title,
  onPressOptions,
  onClose,
  showOptions = true,
  color = theme.colors.darkText,
  closeIcon,
  optionIcon
}: HeaderBackOptionsProps) => {
  const config = useConfig()

  return (
    <Box
      flexDirection="row"
      alignItems={'center'}
      justifyContent={'space-between'}
      height={60}
      padding={2}
    >
      <PressableOpacity
        hitSlop={{
          top: 20,
          left: 20,
          bottom: 20,
          right: 20
        }}
        accessibilityRole="button"
        onPress={onClose}
      >
        {config.features.hideCloseButtonOnNft ||
        config.features.screensConfig?.NFTDetail?.hideCloseButton ? null : closeIcon ? (
          closeIcon
        ) : (
          <Entypo name="chevron-small-left" size={40} color={color} />
        )}
      </PressableOpacity>
      {title}
      {showOptions && (
        <PressableOpacity accessibilityRole="button" onPress={() => onPressOptions?.()}>
          {optionIcon ? (
            optionIcon
          ) : (
            <Ionicons name="ellipsis-horizontal-sharp" size={30} color={color} />
          )}
        </PressableOpacity>
      )}
    </Box>
  )
}

export default HeaderBackOptions

import React from 'react'
import { Text, View } from 'react-native'
import { IEvent } from 'matrix-js-sdk'

import { useMessagePollVotes } from '../../../queries'
import { useMessageContext } from '../MessageContext'

import EndsInNote from './EndsInNote'
import PollElement from './PollElement'

const PollChoices = (props: any) => {
  const { messageEventID } = useMessageContext()
  const { choices, roomId, hideResults, pollLength } = props

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useMessagePollVotes(
    roomId,
    messageEventID,
    'poll.vote'
  )
  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage()
  }

  const pollVotes = data?.pages.flatMap(group => {
    return group?.chunk?.map((pollVote: IEvent) => ({
      index: pollVote.content.body.index,
      sender: pollVote.sender,
      eventId: pollVote.event_id
    }))
  })

  if (!pollVotes) {
    return null
  }

  const isPollLocked = () => {
    const currentDateTimestamp = new Date().getTime()
    return pollLength < currentDateTimestamp
  }

  return (
    <View style={{ marginTop: 12 }}>
      {choices?.content.map((choice: any, index: number) => {
        return (
          <PollElement
            key={index}
            choice={choice}
            hideResults={hideResults}
            pollVotes={pollVotes}
            messageId={messageEventID}
            roomId={roomId}
            isLocked={isPollLocked()}
          />
        )
      })}
      <View>
        <Text style={{ fontSize: 11, lineHeight: 15, fontWeight: '400', color: '#868E96' }}>
          <Text>
            {pollVotes.length} {pollVotes.length === 1 ? 'Vote' : 'Votes'}
            <EndsInNote endTime={pollLength} beforeText={'· '} afterText="left" />
          </Text>
        </Text>
      </View>
    </View>
  )
}

export default PollChoices

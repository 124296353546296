import React, { useCallback, useMemo } from 'react'
import { useNavigation } from '@react-navigation/native'
import { PressableOpacity } from '@vatom/wombo'
import { throttle } from 'lodash'
import { Box } from 'native-base'

import BackIcon from '../../assets/back.min.svg'
import CloseIcon from '../../assets/close.min.svg'
import { HeaderBackdrop, HeaderBackdropSize } from '../HeaderBackdrop'

type HeaderButtonArgs = {
  textColor?: string
}

type ScreenHeader = React.PropsWithChildren<{
  headerProps?: React.ComponentProps<typeof Box>
  headerLeft?: (args: HeaderButtonArgs) => React.ReactNode
  headerLeftType?: keyof typeof headerLeftTypes
  headerLeftPress?: () => void
  headerRight?: (args: HeaderButtonArgs) => React.ReactNode
  backdropProps?: React.ComponentProps<typeof HeaderBackdrop>
  headerTextColor?: string
  leftContainerProps?: React.ComponentProps<typeof Box>
  centerContainerProps?: React.ComponentProps<typeof Box>
  rightContainerProps?: React.ComponentProps<typeof Box>
}>

const ScreenHeader = ({
  children,
  headerProps,
  headerTextColor = '#000',
  headerLeft,
  headerLeftType = 'back',
  headerLeftPress,
  headerRight,
  backdropProps,
  leftContainerProps,
  centerContainerProps,
  rightContainerProps
}: ScreenHeader) => {
  const navigation = useNavigation()

  const onPressLeftDefault = useCallback(() => {
    navigation.goBack()
  }, [navigation])

  const onPressLeft = useMemo(
    () => headerLeftPress ?? onPressLeftDefault,
    [headerLeftPress, onPressLeftDefault]
  )

  const onPressLeftDebounce = useMemo(() => throttle(onPressLeft, 300), [onPressLeft])

  const defaultHeaderLeft = () =>
    headerLeftType === 'none' ? null : (
      <HeaderLeftDefault
        onPress={onPressLeftDebounce}
        type={headerLeftType}
        color={headerTextColor}
      />
    )

  const headerButtonArgs = useMemo(() => ({ textColor: headerTextColor }), [headerTextColor])
  const leftButton = headerLeft ? headerLeft(headerButtonArgs) : defaultHeaderLeft()

  const rightButton = headerRight ? headerRight(headerButtonArgs) : null

  const withBackdrop = useMemo(() => {
    if (!backdropProps) return null
    const height = backdropProps.height ?? HeaderBackdropSize.height
    return {
      Component: HeaderBackdrop,
      height
    }
  }, [backdropProps])

  return (
    <>
      {withBackdrop?.Component && <withBackdrop.Component {...backdropProps} />}
      <Box
        position={'relative'}
        zIndex={1}
        flexDirection={'row'}
        alignItems={'stretch'}
        justifyContent={'space-between'}
        minHeight={60}
        marginTop={withBackdrop?.height ? `-${withBackdrop.height}` : 0}
        backgroundColor={'transparent'}
        paddingX={3}
        {...headerProps}
      >
        <Box flex={1} alignItems={'flex-start'} justifyContent={'center'} {...leftContainerProps}>
          {leftButton}
        </Box>
        <Box
          // flex={2}
          alignItems={'center'}
          justifyContent={'center'}
          paddingX={1}
          {...centerContainerProps}
        >
          {children}
        </Box>
        <Box flex={1} alignItems={'flex-end'} justifyContent={'center'} {...rightContainerProps}>
          {rightButton}
        </Box>
      </Box>
    </>
  )
}

const headerLeftTypes = {
  none: null,
  back: HeaderButtonBack,
  close: HeaderButtonClose
} as const

export function HeaderButtonBack({
  color,
  size,
  width,
  height
}: {
  color: string
  size?: number
  width?: number
  height?: number
}) {
  return <BackIcon height={size ?? height ?? 25} width={size ?? width ?? 15} fill={color} />
}

export function HeaderButtonClose({
  color,
  size,
  width,
  height
}: {
  color: string
  size?: number
  width?: number
  height?: number
}) {
  return <CloseIcon height={size ?? width ?? 16} width={size ?? height ?? 16} fill={color} />
}

const headerButtonSize = 8 // = 32px
function ScreenHeaderButton({
  children,
  onPress,
  buttonProps,
  containerProps,
  buttonSize = headerButtonSize
}: React.PropsWithChildren<{
  onPress?: () => void
  buttonProps?: React.ComponentProps<typeof PressableOpacity>
  containerProps?: React.ComponentProps<typeof Box>
  buttonSize?: number
}>) {
  return (
    <PressableOpacity onPress={onPress} accessibilityRole="button" {...buttonProps}>
      <Box
        minWidth={buttonSize}
        minHeight={buttonSize}
        alignSelf={'flex-start'}
        justifyContent={'center'}
        alignItems={'center'}
        {...containerProps}
      >
        {children}
      </Box>
    </PressableOpacity>
  )
}

function HeaderLeftDefault({
  onPress,
  type,
  color
}: {
  onPress: () => void
  type: keyof typeof headerLeftTypes
  color: string
}) {
  if (type === headerLeftTypes.none) return null
  // default to Back icon
  const HeaderIcon = headerLeftTypes[type] ?? headerLeftTypes.back
  return (
    <ScreenHeaderButton onPress={onPress}>
      <HeaderIcon color={color} />
    </ScreenHeaderButton>
  )
}

export { ScreenHeader, ScreenHeaderButton }

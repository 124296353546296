import React, { useCallback, useMemo, useState } from 'react'
import { ScrollView } from 'react-native'
import { BottomSheetFlatList, BottomSheetModal, TouchableOpacity } from '@gorhom/bottom-sheet'
import {
  useDmReactions,
  useMatrixUser,
  useRedactMessageReaction,
  useRoomMembers
} from '@vatom/sdk/react'
import { Avatar, PressableOpacity, theme } from '@vatom/wombo'
import { Box, HStack, Text, useColorModeValue, VStack } from 'native-base'
import { useThrottledCallback } from 'use-debounce'
import { create } from 'zustand'

import { BottomSheetCustomBackdrop } from '../../../components/BottomSheetCustomBackdrop'
import { Title } from '../../../components/Title'

type ReactionDetailsStore = {
  eventId: string | undefined
}
const reactionDetailsStore = create<ReactionDetailsStore>(set => ({
  eventId: undefined
}))

export const useReactionDetailsModal = () => {
  const setEventId = useCallback((eventId: string) => {
    if (!eventId) {
      console.warn('useReactionDetailsModal. You are trying to set an empty eventId:', eventId)
      return
    }
    reactionDetailsStore.setState(() => ({ eventId }))
  }, [])

  const clearEventId = useCallback(() => {
    reactionDetailsStore.setState(() => ({ eventId: undefined }))
  }, [])

  return {
    setReactionDetailModalEventId: setEventId,
    clearReactionDetailModalEventId: clearEventId
  }
}

export const ReactionDetailsModal = React.memo(
  React.forwardRef<BottomSheetModal, { roomId: string }>(function ({ roomId }, forwardedRef) {
    const redactionMessageReactionMutation = useRedactMessageReaction()
    const { eventId } = reactionDetailsStore()
    const { data: reactions } = useDmReactions({ roomId, eventId: eventId ?? '' })

    // Get user data for reactions
    const { data: matrixUser } = useMatrixUser()
    const userId = useMemo(() => matrixUser?.user_id ?? '', [matrixUser])

    const { data: roomMembers } = useRoomMembers(roomId, {
      select(data) {
        return data?.chunk
      }
    })

    const filterOptions = useMemo(() => {
      const options = [] as string[]
      reactions?.forEach(reaction => {
        if (!options.includes(reaction.key)) {
          options.push(reaction.key)
        }
      })
      return options
    }, [reactions])

    const defaultFilter = 'All'
    const [filterKey, setFilterKey] = useState(defaultFilter.toLowerCase())

    const filterAllTitle = useMemo(() => {
      if (reactions && reactions?.length > 1) {
        return `${defaultFilter} ${reactions?.length}`
      }
      return `${defaultFilter}`
    }, [reactions])

    const reactionsFiltered = useMemo(() => {
      //
      if (!reactions) {
        return []
      }
      if (filterKey === defaultFilter.toLocaleLowerCase()) {
        return reactions
      }
      return reactions.filter(reaction => reaction.key === filterKey)
    }, [reactions, filterKey])

    const onPressFilter = useCallback((key: string) => {
      setFilterKey(key)
    }, [])

    const bottomSheetStyles = useColorModeValue(
      {
        container: {
          backgroundColor: theme.colors.white
        },
        indicator: {
          backgroundColor: theme.colors.grayCool[400]
        }
      },
      {
        container: {
          backgroundColor: theme.colors.grayDarkMode[900]
        },
        indicator: {
          backgroundColor: theme.colors.grayDarkMode[100]
        }
      }
    )

    const closeModal = useCallback(() => {
      if (forwardedRef && 'current' in forwardedRef) {
        forwardedRef.current?.forceClose()
      }
    }, [forwardedRef])

    const onPressRemoveReaction = useThrottledCallback(
      async (reactionEventId: string) => {
        if (!eventId || !reactionEventId) {
          return
        }
        // Redact reaction
        redactionMessageReactionMutation.mutate({
          roomId,
          eventId: reactionEventId,
          parentEventId: eventId
        })

        closeModal()
      },
      300,
      { leading: true }
    )

    return (
      <BottomSheetModal
        ref={forwardedRef}
        index={0}
        snapPoints={['50%']}
        enablePanDownToClose={true}
        enableDismissOnClose={true}
        handleIndicatorStyle={{
          backgroundColor: bottomSheetStyles.indicator.backgroundColor
        }}
        handleStyle={{
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          backgroundColor: bottomSheetStyles.container.backgroundColor
        }}
        backgroundStyle={{
          backgroundColor: bottomSheetStyles.container.backgroundColor
        }}
        containerStyle={{
          backgroundColor: 'transparent'
        }}
        style={{
          backgroundColor: 'transparent',
          marginHorizontal: 4
        }}
        backdropComponent={BottomSheetCustomBackdrop}
      >
        {eventId && reactionsFiltered && (
          <>
            <Box
              flexDirection={'row'}
              marginX={3}
              justifyContent={'space-between'}
              alignItems={'center'}
              paddingBottom={2}
              borderBottomWidth={1}
              _light={{
                borderBottomColor: theme.colors.grayCool[400]
              }}
              _dark={{
                borderBottomColor: theme.colors.grayDarkMode[600]
              }}
            >
              {/* Filter options: All (x) | emoji | emoji*/}
              <ScrollView horizontal={true}>
                <FilterItem
                  onPress={onPressFilter}
                  title={filterAllTitle}
                  isSelected={filterKey === defaultFilter.toLowerCase()}
                />
                {filterOptions.map(option => (
                  <FilterItem
                    key={option}
                    onPress={onPressFilter}
                    title={option}
                    isSelected={filterKey === option}
                  />
                ))}
              </ScrollView>
            </Box>

            <Box flex={1} marginTop={2}>
              <BottomSheetFlatList
                data={reactionsFiltered}
                contentContainerStyle={[
                  {
                    paddingHorizontal: 16
                  }
                ]}
                ItemSeparatorComponent={() => (
                  <Box
                    marginY={2}
                    borderBottomWidth={1}
                    _light={{
                      borderBottomColor: theme.colors.grayCool[400]
                    }}
                    _dark={{
                      borderBottomColor: theme.colors.grayDarkMode[600]
                    }}
                  />
                )}
                renderItem={({ item }) => {
                  const user = roomMembers?.find(member => member.sender === item.sender)
                  return (
                    <Box key={item.sender} flexDirection={'row'}>
                      <HStack flex={1}>
                        <Avatar
                          size={60}
                          url={user?.content.avatar_url}
                          name={user?.content.displayname}
                          borderWidth={!user?.content.avatar_url ? 1 : 0}
                        />
                        <VStack paddingX={3} marginY={2.5} flex={1}>
                          <Text fontSize={15} lineHeight={20} fontFamily={'Inter-Bold'}>
                            {user?.content.displayname}
                          </Text>
                          {userId === item.sender && (
                            <TouchableOpacity
                              accessibilityRole="button"
                              onPress={() => onPressRemoveReaction(item.eventId)}
                              style={{
                                marginTop: 4
                              }}
                            >
                              <Text
                                fontSize={12}
                                lineHeight={15}
                                fontFamily={'Inter-Light'}
                                _light={{
                                  color: theme.colors.textLightMode[300]
                                }}
                                _dark={{
                                  color: theme.colors.textDarkMode[600]
                                }}
                              >
                                {/* TODO: translate */}
                                Tap to remove
                              </Text>
                            </TouchableOpacity>
                          )}
                        </VStack>
                      </HStack>

                      <Box flex={0} alignItems={'center'} justifyContent={'center'}>
                        <Title preset="h4">{item.key}</Title>
                      </Box>
                    </Box>
                  )
                }}
              />
            </Box>
          </>
        )}
      </BottomSheetModal>
    )
  })
)

const FilterItem = React.memo(function ({
  onPress,
  title,
  isSelected
}: {
  onPress: (key: string) => void
  title: string
  isSelected?: boolean
}) {
  const handleOnPress = useThrottledCallback(() => onPress(title), 300)
  return (
    <PressableOpacity
      accessibilityRole="button"
      onPress={handleOnPress}
      _light={{
        backgroundColor: isSelected ? theme.colors.grayCool[200] : undefined
      }}
      _dark={{
        backgroundColor: isSelected ? theme.colors.grayDarkMode[700] : undefined
      }}
      borderRadius={99}
      paddingX={2}
      padding={1}
      marginRight={2}
      alignItems={'center'}
      justifyContent={'center'}
      style={{
        minWidth: 32,
        minHeight: 32
      }}
    >
      <Text
        textAlign={'center'}
        alignSelf={'center'}
        alignContent={'center'}
        alignItems={'center'}
        justifyContent={'center'}
        fontFamily={'Inter'}
      >
        {title}
      </Text>
    </PressableOpacity>
  )
})

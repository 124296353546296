import React, { useState } from 'react'
import { Keyboard, TouchableWithoutFeedback } from 'react-native'
import { translate } from '@vatom/utils'
import { GradientButton, PressableOpacity, Text, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Link, TextArea } from 'native-base'

import BackIcon from '../../../assets/back.svg'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { useEncryptAndStore } from '../../../hooks/useEncryptAndStore'
import { AppRoutes, AppStackScreenProps } from '../../../navigators'

type ImportRecover = AppStackScreenProps<typeof AppRoutes.ImportWalletRecover>

export const Recover = observer(({ route, navigation }: ImportRecover) => {
  const { isDark } = useBusinessTheme()
  const [seeds, setSeeds] = useState(route.params?.seeds ?? '')
  const encryptAndStoreMutation = useEncryptAndStore()

  return (
    <TouchableWithoutFeedback
      style={{ height: '100%' }}
      onPress={Keyboard.dismiss}
      accessible={false}
    >
      <Box flex={1} safeArea={true}>
        <Box alignItems="flex-start">
          <PressableOpacity
            hitSlop={{
              top: 20,
              left: 20,
              bottom: 20,
              right: 20
            }}
            accessibilityRole="button"
            onPress={() => navigation.goBack()}
          >
            <BackIcon
              height={40}
              width={40}
              fill={!isDark ? theme.colors.darkText : theme.colors.white}
            />
          </PressableOpacity>
        </Box>
        <Box h="100%" w="100%" flex={1} px="16px" justifyContent="flex-start">
          <Box flex={1} alignItems="flex-start" height="100%" width="100%">
            <Text
              marginBottom="20px"
              color={!isDark ? theme.colors.darkText : theme.colors.white}
              fontFamily="Inter-Bold"
              fontSize="34px"
              textAlign="left"
              alignSelf="flex-start"
              lineHeight="41px"
              tx="importWalletScreen.recoverTitle"
            />

            <Text
              w="100%"
              fontFamily="Inter-Regular"
              fontSize="15px"
              textAlign="left"
              mt={10}
              color={!isDark ? theme.colors.darkText : theme.colors.white}
              tx={'importWalletScreen.recoverDescription'}
            />

            <Text
              w="100%"
              fontFamily="Inter-Regular"
              fontSize="15px"
              textAlign="left"
              mt={10}
              color={!isDark ? theme.colors.darkText : theme.colors.white}
            >
              <Link
                href="#"
                isExternal={true}
                _text={{
                  color: 'blue.400',
                  fontSize: '15px'
                }}
                mt={-0.5}
                _web={{
                  mb: -2
                }}
              >
                {translate('importWalletScreen.whereToFind')}
              </Link>
            </Text>

            <TextArea
              placeholder="Recovery Phrase"
              w="100%"
              h="114px"
              mt="20px"
              fontSize={16}
              autoCompleteType={undefined}
              color={isDark ? theme.colors.darkText : theme.colors.black}
              value={seeds}
              onChangeText={setSeeds}
              opacity={1}
              bgColor={!isDark ? undefined : theme.colors.white}
              borderColor={theme.colors.gray[500]}
            />
          </Box>

          <Box justifyContent="center" width="100%" pb={20}>
            <GradientButton
              onPress={async () => {
                try {
                  await encryptAndStoreMutation.mutateAsync({ seeds })
                  navigation.navigate(AppRoutes.BlockChainWallets)
                } catch (error) {
                  alert(
                    'Wallet might be already verified from another account. Please delete that first then add here again.'
                  )
                  setSeeds('')
                }
              }}
              h="52px"
              w="100%"
              py="15px"
              px="30px"
              disabled={encryptAndStoreMutation.isLoading}
            >
              <Text
                alignSelf="center"
                fontFamily="Inter"
                color="white"
                fontSize={16}
                lineHeight={22}
              >
                {encryptAndStoreMutation.isLoading ? 'Importing your wallet now' : 'Continue'}
              </Text>
            </GradientButton>
          </Box>
        </Box>
      </Box>
    </TouchableWithoutFeedback>
  )
})

export default Recover

import React from 'react'
import { Animated, Modal } from 'react-native'

import { useCommunitiesTheme } from '../../themes'
import {
  closeAnimation,
  ModalAnimatedWrapper,
  ModalGreyBackground,
  openAnimation
} from '../modalHelpers'

const PopModalWrapper = (props: any) => {
  const { isModalVisible, setIsModalVisible, children } = props
  const fadeAnim = React.useRef(new Animated.Value(0)).current
  const slideAnim = React.useRef(new Animated.Value(1000)).current

  const animationDuration = 250

  const openModal = () => openAnimation(fadeAnim, slideAnim, animationDuration).start()

  React.useEffect(() => {
    openModal()
  }, [openModal])

  const closeModal = () =>
    closeAnimation(fadeAnim, slideAnim, animationDuration).start(() => {
      setIsModalVisible(false)
    })

  return (
    <Modal visible={isModalVisible} transparent={true} animationType="none">
      <ModalGreyBackground fadeAnim={fadeAnim} handler={closeModal} />
      <ModalAnimatedWrapper
        slideAnim={slideAnim}
        style={{ paddingTop: 34, paddingBottom: 12, paddingLeft: 19 }}
      >
        {children}
      </ModalAnimatedWrapper>
    </Modal>
  )
}

export default PopModalWrapper

import { useCallback, useEffect, useRef } from 'react'
import { Linking } from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import { useMutation, useQuery } from '@tanstack/react-query'
import { RegionType } from '@vatom/sdk/core'
import { useSDK } from '@vatom/sdk/react'
import { AppRoutes, AppStackScreenProps, TabRoutes } from '@vatom/wallet-routes'
import { LoaderView, Status, Toast } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { match } from 'path-to-regexp'

import {
  useBusinessSelector,
  useInventoryByBusiness,
  withBusinessSelector
} from '../../hooks/useBusinessSelector'
import { useAquireMutation } from '../Acquire'

export type FindTokenProps = AppStackScreenProps<
  typeof AppRoutes.FindToken | typeof AppRoutes.FindToken_Business
>

const pathVariations = ['/acquire/:tokenId', '/b/:businessId/acquire/:tokenId']

export const extractTokenIdFromAcquireLink = (url: string): string | null => {
  for (const path of pathVariations) {
    const matcher = match<{
      tokenId: string
    }>(path, { decode: decodeURIComponent })

    const matched = matcher(url)
    if (matched) {
      return matched.params?.tokenId
    }
  }
  return null
}

export const FindTokenScreen = observer(({ route, navigation }: FindTokenProps) => {
  const params = route.params
  // const inventory = useInventoryByBusiness()
  const { businessIdentifier } = useBusinessSelector()
  const aquireMutation = useAquireMutation()
  const isFocused = useIsFocused()
  const objectDefinitionId = 'objectDefinitionId' in params ? params.objectDefinitionId : undefined
  const campaignId = 'campaignId' in params ? params.campaignId : undefined
  const businessId = businessIdentifier
  const { autoClaim, sync } = params
  const sdk = useSDK()

  const region = sdk.dataPool.region(RegionType.inventory)

  const isRegionLoading = region.isLoading

  const resetNavigation = useCallback(() => {
    if (businessId) {
      navigation.reset({
        index: 0,
        routes: [{ name: AppRoutes.BusinessProxy, params: { business: businessId } }]
      })
    } else {
      navigation.reset({
        index: 0,
        routes: [{ name: AppRoutes.home, params: { screen: TabRoutes.Wallet } }]
      })
    }
  }, [businessId, navigation])

  const resetNavigationWithToken = useCallback(
    (tokenId: string) => {
      if (businessId) {
        navigation.reset({
          index: 1,
          routes: [
            { name: AppRoutes.BusinessProxy, params: { business: businessId } },
            { name: AppRoutes.NFTDetail_Business, params: { tokenId, business: businessId } }
          ]
        })
      } else {
        navigation.reset({
          index: 1,
          routes: [
            { name: AppRoutes.home, params: { screen: TabRoutes.Wallet } },
            { name: AppRoutes.NFTDetail, params: { tokenId } }
          ]
        })
      }
    },
    [businessId, navigation]
  )

  const tokenDidLandPromise = useRef<() => void>()

  const claimTokenMutation = useMutation({
    mutationFn: async () => {
      try {
        if (!businessId) {
          resetNavigation()
          return
        }

        if (!autoClaim) {
          // user doesn't have the token nor wants to claim it
          resetNavigation()
          return
        }

        const claimLink = 'claimLink' in params ? params.claimLink : undefined

        Toast({
          id: 'aquiring',
          title: `Aquiring...`,
          placement: 'top',
          status: Status.success
        })

        const shareToken = Buffer.from(
          JSON.stringify({
            objectDefinitionId,
            campaignId
          })
        ).toString('base64')

        const finalClaimLink =
          claimLink ?? `https://${businessId}.share.vatominc.com/emit/${shareToken}?json=true`

        if (finalClaimLink.includes('share.vatominc.com/emit/')) {
          const res = await fetch(finalClaimLink)
          const body = (await res.json()) as { acquireLink: string }
          const acquireLink = !sync ? body.acquireLink : `${body.acquireLink}&sync=true`
          const aquireUrl = new URL(acquireLink)
          const acquireParams = new URLSearchParams(aquireUrl.search)

          type MutationParams = Omit<
            Parameters<typeof aquireMutation.mutateAsync>[0],
            'id' | 'businessId'
          >
          const mutationParams = Object.fromEntries(acquireParams.entries()) as MutationParams

          const tokenId = extractTokenIdFromAcquireLink(aquireUrl.pathname)

          if (tokenId) {
            await aquireMutation.mutateAsync({
              id: tokenId,
              businessId: businessId,
              ...mutationParams
            })

            tokenDidLandPromise.current = () => {
              resetNavigationWithToken(tokenId)
            }
          }
        } else {
          console.info('Performing standard claim')
          Linking.openURL(finalClaimLink)
        }
      } catch (error) {
        console.error('Error claiming token', error)
        Toast({
          id: 'error',
          title: 'Error claiming token',
          description: 'An error occurred while claiming the token',
          status: Status.error
        })

        resetNavigation()
      }
    }
  })

  const findOrAqcquireToken = useCallback(async () => {
    const tokens = region.tokens

    if (!businessId) {
      resetNavigation()
      return
    }

    if (!campaignId && !objectDefinitionId) {
      // nothing to look for
      resetNavigation()
      return
    }

    const token = tokens?.find(token => token.studioInfo?.objectDefinitionId === objectDefinitionId)

    if (token) {
      resetNavigationWithToken(token.id)

      return token
    }

    // Check if the user has the token by campaignId
    const tokenByCampaignId = tokens?.find(
      token =>
        token.studioInfo?.campaignId === campaignId &&
        token.studioInfo?.objectDefinitionId === objectDefinitionId
    )

    if (tokenByCampaignId) {
      resetNavigationWithToken(tokenByCampaignId.id)

      return tokenByCampaignId
    }

    await claimTokenMutation.mutateAsync()
    return {}
  }, [
    businessId,
    campaignId,
    claimTokenMutation,
    objectDefinitionId,
    region,
    resetNavigation,
    resetNavigationWithToken
  ])

  useQuery({
    queryKey: ['findToken', objectDefinitionId, campaignId],
    queryFn: findOrAqcquireToken,
    enabled: !isRegionLoading && isFocused,
    staleTime: 0
  })

  useEffect(() => {
    if (!isRegionLoading) {
      const tokens = region.tokens

      const token = tokens?.find(
        token => token.studioInfo?.objectDefinitionId === objectDefinitionId
      )

      if (tokenDidLandPromise.current && token) {
        tokenDidLandPromise.current()
        tokenDidLandPromise.current = undefined
      }
    }
  }, [
    claimTokenMutation.isLoading,
    isRegionLoading,
    objectDefinitionId,
    region.tokens,
    resetNavigationWithToken,
    sdk.dataPool
  ])

  return <LoaderView />
})

export const FindToken = withBusinessSelector(FindTokenScreen)

/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { BidModel, BidModelType } from "./BidModel"
import { BidModelSelector } from "./BidModel.base"
import { NftModel, NftModelType } from "./NftModel"
import { NftModelSelector } from "./NftModel.base"
import { StorefrontModel, StorefrontModelType } from "./StorefrontModel"
import { StorefrontModelSelector } from "./StorefrontModel.base"
import { RootStoreType } from "./index"


/**
 * ListingBase
 * auto generated base class for the model ListingModel.
 */
export const ListingModelBase = ModelBase
  .named('Listing')
  .props({
    __typename: types.optional(types.literal("Listing"), "Listing"),
    address: types.union(types.undefined, types.string),
    extAddress: types.union(types.undefined, types.string),
    cacheAddress: types.union(types.undefined, types.string),
    storeAddress: types.union(types.undefined, types.string),
    endsAt: types.union(types.undefined, types.null, types.frozen()),
    ended: types.union(types.undefined, types.boolean),
    storefront: types.union(types.undefined, types.null, types.late((): any => StorefrontModel)),
    nfts: types.union(types.undefined, types.array(types.late((): any => NftModel))),
    bids: types.union(types.undefined, types.array(types.late((): any => BidModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ListingModelSelector extends QueryBuilder {
  get address() { return this.__attr(`address`) }
  get extAddress() { return this.__attr(`extAddress`) }
  get cacheAddress() { return this.__attr(`cacheAddress`) }
  get storeAddress() { return this.__attr(`storeAddress`) }
  get endsAt() { return this.__attr(`endsAt`) }
  get ended() { return this.__attr(`ended`) }
  storefront(builder: string | StorefrontModelSelector | ((selector: StorefrontModelSelector) => StorefrontModelSelector) | undefined) { return this.__child(`storefront`, StorefrontModelSelector, builder) }
  nfts(builder: string | NftModelSelector | ((selector: NftModelSelector) => NftModelSelector) | undefined) { return this.__child(`nfts`, NftModelSelector, builder) }
  bids(builder: string | BidModelSelector | ((selector: BidModelSelector) => BidModelSelector) | undefined) { return this.__child(`bids`, BidModelSelector, builder) }
}
export function selectFromListing() {
  return new ListingModelSelector()
}

export const listingModelPrimitives = selectFromListing().address.extAddress.cacheAddress.storeAddress.endsAt.ended

import { useQuery } from '@tanstack/react-query'
import * as FileSystem from 'expo-file-system'

import logger from '../logger'

export const GLB_CACHE_DIR = `${FileSystem.cacheDirectory}glbs/`
if (__DEV__) {
  /**
   * Useful for opening the dir with finder and see the cache updates on real time
   */
  logger.info('[GLB_CACHE_DIR]', GLB_CACHE_DIR.replace('file://', ''))
}

export const cleanGLBCache = async () => {
  await FileSystem.deleteAsync(GLB_CACHE_DIR, {
    idempotent: true
  })
}
const createCacheDirectory = async () => {
  logger.info('[useGLBModel] createCacheDirectory')
  const dirInfo = await FileSystem.getInfoAsync(GLB_CACHE_DIR)
  if (!dirInfo.exists) {
    await FileSystem.makeDirectoryAsync(GLB_CACHE_DIR, { intermediates: true })
  }
}

const downloadModel = async (from: string, to: string) => {
  logger.info('[useGLBModel] downloadModel', from)

  const file = await FileSystem.downloadAsync(from, to)
  logger.info('[useGLBModel] finished downloading to ', file)
  return file
}
export const getLocalGLBUri = (url: string) => {
  const filename = url.substring(url.lastIndexOf('/') + 1).split('?')[0]
  return `${GLB_CACHE_DIR}${filename}`
}

export const useGLBModel = (url: string) => {
  const encodedUrl = encodeURI(url)
  const uri = getLocalGLBUri(encodedUrl)
  const result = useQuery<FileSystem.FileInfo>([GLB_CACHE_DIR, encodedUrl], async () => {
    const maybeCached = await FileSystem.getInfoAsync(uri)

    logger.info('[useGLBModel]: exists', maybeCached)
    if (maybeCached.exists) {
      return maybeCached
    } else {
      await createCacheDirectory()
      await downloadModel(encodedUrl, uri)
      return await FileSystem.getInfoAsync(uri)
    }
  })

  return result
}

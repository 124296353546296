import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useMatrixUser } from '@vatom/sdk/react'

import { AppNavigation, navigateToConnectScreen, RoomRoutes } from '../../../../navigators'
import RoomLockIcon from '../../assets/room_lock_icon.svg'
import { isCurrentUserJoined } from '../../helpers'
import { useMembers } from '../../queries'
import { useCommunitiesTheme } from '../../themes'
import { useCommunitySpace } from '../../useCommunitySpace'
import { useRoomContext } from '../RoomProvider'

const RoomTags = () => {
  const room = useRoomContext()
  const {
    room_id,
    join_rule,
    num_joined_members,
    space_id: spaceId,
    business_id: businessId
  } = room
  const navigation = useNavigation<AppNavigation>()
  const { data: matrixUser } = useMatrixUser()

  const { data: roomMembers } = useMembers(room_id)
  const { community } = useCommunitySpace()

  const communitiesTheme = useCommunitiesTheme()

  const roomMembersLength = roomMembers
    ? roomMembers.filter(member => member.isJoined).length
    : num_joined_members

  const isUserJoined = isCurrentUserJoined(roomMembers, matrixUser?.user_id)
  return (
    <View style={styles.roomTagsHolder}>
      {join_rule === 'private' ? (
        <View style={[styles.roomTag, { backgroundColor: communitiesTheme.roomTags.background }]}>
          <RoomLockIcon style={{ marginRight: 3.5 }} />
          <Text style={{ color: '#868E96', textTransform: 'capitalize' }}>{join_rule}</Text>
        </View>
      ) : null}

      <TouchableOpacity
        accessibilityRole="button"
        onPress={() =>
          isUserJoined
            ? navigateToConnectScreen(navigation, RoomRoutes.RoomMembers, {
                spaceId,
                business: businessId,
                community
              })
            : null
        }
        style={{ ...styles.roomTag, backgroundColor: communitiesTheme.roomTags.background }}
      >
        <Text
          style={{
            color: communitiesTheme.roomTags.text,
            marginRight: 4,
            fontWeight: 'bold'
          }}
        >
          {roomMembersLength}
        </Text>
        <Text style={{ color: communitiesTheme.roomTags.text }}>
          {roomMembersLength > 0
            ? `${roomMembersLength !== 1 ? 'Members' : 'Member'}`
            : 'No Members'}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  roomTagsHolder: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 12
  },
  roomTag: {
    flexDirection: 'row',
    height: 28,
    borderRadius: 4,
    marginLeft: 8,
    alignItems: 'center',
    padding: 6
  }
})

export default RoomTags

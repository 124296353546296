import React, { useEffect, useState } from 'react'
import { translate } from '@vatom/utils'
import { InputField, Text, theme } from '@vatom/wombo'
import { Box, Input, Pressable } from 'native-base'

import ProcessButton from '../../ActionSheets/partials/ProcessButton'

interface ICustomAddress {
  mintAddress: string
  setMintAddress: (address: string) => void
  onMint: () => void
  onSelectMyAddress: () => void
  isLoading: boolean
}

export const CustomAddress = ({
  onMint,
  onSelectMyAddress,
  mintAddress,
  setMintAddress,
  isLoading
}: ICustomAddress) => {
  const [isMintDisabled, setIsMintDisabled] = useState(true)

  useEffect(() => {
    if (!/^(0x)?[0-9a-f]{40}$/i.test(mintAddress)) {
      setIsMintDisabled(true)
    } else if (/^(0x)?[0-9a-fA-F]{40}$/.test(mintAddress)) {
      setIsMintDisabled(false)
    }
  }, [mintAddress])

  return (
    <Box flexGrow={1}>
      <Box flex={1}>
        <>
          <Text
            marginTop={6}
            marginBottom={2}
            lineHeight="18px"
            fontSize="13px"
            fontFamily="Inter-SemiBold"
          >
            BlockChain Address
          </Text>
          <Input
            accessibilityHint=""
            accessibilityLabel=""
            placeholder="Ox address"
            value={mintAddress}
            onChangeText={setMintAddress}
            clearButtonMode="always"
            fontSize={16}
            borderWidth={1}
            p={2.5}
            _light={{
              backgroundColor: theme.colors.white,
              borderColor: theme.colors.grayCool[300],
              placeholderTextColor: theme.colors.textLightMode[100]
            }}
            _dark={{
              backgroundColor: theme.colors.grayDarkMode[800],
              borderColor: theme.colors.grayDarkMode[500],
              placeholderTextColor: theme.colors.textDarkMode[600]
            }}
          />
        </>
      </Box>
      <Box alignItems={'flex-end'} paddingBottom={5}>
        <Pressable onPress={onSelectMyAddress} accessibilityRole="button" paddingY={4} marginY={3}>
          <Text
            fontFamily="Inter-Regular"
            fontSize="13px"
            lineHeight="18px"
            color="lightBlue.500"
            textAlign="center"
          >
            I would like to mint to one of my wallets.
          </Text>
        </Pressable>

        <ProcessButton
          label={translate('mintNft.mint')}
          isDisabled={isMintDisabled || isLoading}
          onPress={onMint}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  )
}
export default CustomAddress

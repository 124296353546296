import React from 'react'
import { Box } from 'native-base'

import { usePlugins } from '../../../queries'
import { useCommunitiesTheme } from '../../../themes'

import CloseButton from './modal-header-components/CloseButton'
import ModalTitle from './modal-header-components/ModalTitle'

type ModalHeaderProps = {
  eventType: string
}

const ModalHeader = (props: ModalHeaderProps) => {
  const { eventType } = props
  const { data: plugins, isSuccess } = usePlugins()
  const pluginData = isSuccess ? plugins[0] : null

  const communitiesTheme = useCommunitiesTheme()

  let title = ''
  if (eventType !== 'm.room.message') {
    if (!pluginData) {
      return null
    }

    const pluginDescriptor = pluginData.descriptor
    const messageFacades = pluginDescriptor.facades.message
    const messageFacade = messageFacades?.find((facade: any) => facade.id === eventType) || ''
    title = 'Create ' + messageFacade.name
  }

  return (
    <Box
      flexDirection={'row'}
      justifyContent={'space-between'}
      paddingY={2}
      backgroundColor={communitiesTheme.background}
      width={'100%'}
    >
      <Box alignSelf={'flex-start'} marginLeft={2}>
        <CloseButton iconType="close" />
      </Box>

      <Box flex={1} justifyContent={'center'}>
        <ModalTitle title={title} />
      </Box>
      <Box alignSelf={'flex-end'} marginRight={2} width={8}></Box>
    </Box>
  )
}

export default ModalHeader

import { MatrixClient } from 'matrix-js-sdk'
import { v4 } from 'uuid'

import { LOCAL_ROOM_ID_PREFIX } from './actions'

const USER_ORG_KEY = ':vatom.com'

export function getVatomUserFromMatrixUserId(matrixUserId: string) {
  if (!matrixUserId) {
    throw new Error('Required userId ')
  }
  if (!isMatrixUser(matrixUserId)) {
    console.warn('getVatomUser. userId is not a matrix user id', matrixUserId)
    return matrixUserId
  }
  return matrixUserId.split(USER_ORG_KEY)[0].replace('@', '').trim()
}

export function getMatrixUser(userId: string) {
  if (!userId) {
    throw new Error('Required userId ')
  }
  return `@${userId}:vatom.com`
}

export function getMatrixUsers(userIds: string[]) {
  return userIds.map(id => getMatrixUser(id))
}

export function isMatrixUser(userId: string): boolean {
  return userId.startsWith('@') && userId.endsWith(USER_ORG_KEY)
}

export function areMatrixUsers(userIds: string[]): boolean {
  return userIds.every(uId => uId.startsWith('@') && uId.endsWith(USER_ORG_KEY))
}

export function createTxnId() {
  const txnId = new MatrixClient({
    baseUrl: ''
  }).makeTxnId()
  return txnId ?? v4()
}

export function isLocalRoom(roomId: string) {
  const isLocal = roomId.startsWith(LOCAL_ROOM_ID_PREFIX)
  return isLocal
}

import { useCallback, useMemo } from 'react'
import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  clearRootSDKStore,
  getConfig,
  SDKQueryClient,
  SDKQueryClientPersister,
  useSDK
} from '@vatom/sdk/react'
import * as AuthSession from 'expo-auth-session'

import { deleteKey, retrieveAddresses } from '../../../modules/web3-wallet'
import { useStore } from '../models'
import { AppRoutes, navigationRef } from '../navigators'
import { useWalletStore } from '../screens/BlockChainWallets/WalletStore'

import { sendLogoutMessage } from './useBroadcastSession'

const isWeb = Platform.OS === 'web'
const isAndroid = Platform.OS === 'android'

export const LOGOUT_REDIRECT_URI = isWeb
  ? `${window.location.origin}/logout-callback`
  : 'com.vatom://logout-callback'

export const clearQueryClient = () => {
  SDKQueryClient.clear()
  SDKQueryClientPersister.removeClient()
}

export const clearSdkSession = (sdk: ReturnType<typeof useSDK>) => {
  sdk?.service?.setToken(undefined)
  sdk?.dataPool?.sessionStore?.clear()
  sdk?.dataPool?.user?.clearCache()
  sdk?.dataPool?.user?.logout()
  clearRootSDKStore()
}

export function useAuthLogout({ onLogoutAction }: { onLogoutAction?: () => void } = {}) {
  const store = useStore()
  const sdk = useSDK()
  const config = getConfig()
  const session = sdk?.dataPool?.sessionStore?.vatomIncSessionToken
  const accessToken = session?.accessToken
  const { clientId, scopes, discoveryUrl, redirectUri } = config.authentication
  const { clearPersist } = useWalletStore()

  const getDiscovery = useCallback(async () => {
    return await AuthSession.fetchDiscoveryAsync(discoveryUrl)
  }, [discoveryUrl])

  const deletePrivateKeys = async () => {
    const addresses = await retrieveAddresses()
    addresses.forEach(async address => {
      await deleteKey(address.address)
    })
  }

  const logoutAuthRequest = useMemo(() => {
    return new AuthSession.AuthRequest({
      clientId,
      redirectUri: redirectUri ?? encodeURI('com.vatom://auth'),
      responseType: AuthSession.ResponseType.Code,
      prompt: AuthSession.Prompt.Consent,
      scopes,
      extraParams: {
        post_logout_redirect_uri: LOGOUT_REDIRECT_URI,
        id_token_hint: session?.idToken ?? ''
      }
    })
  }, [clientId, redirectUri, scopes, session?.idToken])

  const clearAppStore = useCallback(async () => {
    const claimDetails = await AsyncStorage.getItem('CLAIM_DETAILS')

    if (!claimDetails) {
      store.linking?.clearLinkingUrl()
    }
  }, [store.linking])

  const clearAllSessions = useCallback(() => {
    try {
      clearAppStore()
      clearQueryClient()
      clearSdkSession(sdk)
    } catch (error) {
      console.log('LOG: useAuthLogout.clearAllSessions > error:', error)
    }
  }, [clearAppStore, sdk])

  const logoutNavigation = useCallback(() => {
    navigationRef.current?.navigate(AppRoutes.LogoutCallback)
  }, [])

  const logout = useCallback(
    async ({ fromBroadcast = false }: { fromBroadcast?: boolean } = {}) => {
      try {
        const discovery = await getDiscovery()
        if (discovery && accessToken) {
          if (isAndroid) {
            await logoutAuthRequest.promptAsync(
              {
                authorizationEndpoint: discovery.endSessionEndpoint
              },
              {
                createTask: false // android
              }
            )
          } else {
            await AuthSession.revokeAsync(
              {
                clientId,
                token: accessToken,
                tokenTypeHint: AuthSession.TokenTypeHint.AccessToken
              },
              {
                revocationEndpoint: discovery.revocationEndpoint
              }
            )
          }
        }

        if (Platform.OS !== 'web') {
          clearPersist()
          await deletePrivateKeys()
        }
        onLogoutAction?.()

        if (isWeb) {
          const requestUrl = await logoutAuthRequest.makeAuthUrlAsync({
            authorizationEndpoint: discovery.endSessionEndpoint
          })

          if (!fromBroadcast) {
            // send message only if is the call is not coming from a broadcast msg
            sendLogoutMessage() // To logout across tabs
          }
          // Destroy session only once
          if (!fromBroadcast && requestUrl) {
            window.location.replace(requestUrl)
            return
          }
        }
        if (Platform.OS !== 'android') {
          AuthSession.dismiss()
        }
        logoutNavigation()
      } catch (error) {
        console.log('LOG: > logout > error:', error)
      }
    },
    [
      getDiscovery,
      accessToken,
      clearPersist,
      onLogoutAction,
      logoutNavigation,
      logoutAuthRequest,
      clientId
    ]
  )

  return {
    doLogout: logout,
    clearAllSessions
  }
}

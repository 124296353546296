import * as React from 'react'
import { TokenType } from '@vatom/sdk/core'
import { Button, Text, theme } from '@vatom/wombo'
import { Box, HStack, Image, Modal } from 'native-base'

export interface IConfirmModal {
  token: TokenType
  onDrop: () => void
  onCancel: () => void
  isOpen: boolean
}

export const ConfirmModal = ({ token, onDrop, onCancel, isOpen }: IConfirmModal) => {
  if (!token) return null
  return (
    <Modal
      useRNModal={true}
      isOpen={isOpen}
      alignItems="center"
      justifyContent="center"
      animationPreset="fade"
    >
      <Modal.Content
        p={4}
        borderRadius="8px"
        _light={{
          backgroundColor: theme.colors.white
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[800]
        }}
        height={280}
        width="300px"
      >
        <Box px="16px" flex={1}>
          <Box marginY="16px" height="100px" alignItems="center" justifyContent="center">
            {token?.metadata.image && (
              <Image
                accessibilityIgnoresInvertColors
                source={{ uri: token.displayImage ?? token.metadata.image }}
                height="100px"
                width="100px"
                alt="modalData.image"
                borderRadius="8px"
                resizeMode="stretch"
              />
            )}
          </Box>
          <Text
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
            _light={{
              color: theme.colors.textLightMode[900]
            }}
            ellipsizeMode="tail"
            fontSize="17px"
            marginBottom="8px"
            fontFamily="Inter-Bold"
            textAlign="center"
            noOfLines={1}
          >
            {token?.metadata.name}
          </Text>
          <Text
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
            _light={{
              color: theme.colors.textLightMode[900]
            }}
            textAlign="center"
            fontSize={12}
            tx="dropNft.shouldDrop"
          />
        </Box>
        <HStack height="44px" space={2} justifyContent="center">
          <Button
            onPress={onCancel}
            _dark={{
              backgroundColor: theme.colors.grayDarkMode[500]
            }}
            _light={{
              backgroundColor: theme.colors.grayCool[200]
            }}
            variant="outline"
            w="50%"
          >
            <Text
              _light={{
                color: theme.colors.textLightMode[600]
              }}
              _dark={{
                color: 'white'
              }}
              fontFamily="Inter-Regular"
              fontSize="15px"
              tx="common.notNow"
            />
          </Button>
          <Button
            onPress={onDrop}
            _light={{
              backgroundColor: theme.colors.systemColorsLight['orange']
            }}
            _dark={{
              backgroundColor: theme.colors.systemColorsDark['orange']
            }}
            variant="outline"
            w="50%"
          >
            <Text fontFamily="Inter-SemiBold" fontSize="15px" color="white" tx="common.drop" />
          </Button>
        </HStack>
      </Modal.Content>
    </Modal>
  )
}

export default ConfirmModal

import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { IEvent } from 'matrix-js-sdk'

import HiddenIcon from '../../../assets/message-action-sheet-icons/hidden-icon.svg'
import { useMessageHide } from '../../../queries'
import { useCommunitiesTheme } from '../../../themes'
import { useMessageContext } from '../MessageContext'

const PostHiddenTag = ({ roomId }: { roomId: string }) => {
  const { messageEventID } = useMessageContext()
  const { data: isHidden } = useMessageHide(roomId, messageEventID, {
    select: (data: { chunk: IEvent[] }) => data.chunk[0]?.content.isHidden
  })
  const communitiesTheme = useCommunitiesTheme()
  const { postHiddenTag } = communitiesTheme

  return isHidden ? (
    <View style={{ ...styles.container, backgroundColor: postHiddenTag.background }}>
      <View style={{ alignItems: 'center', flexDirection: 'row', marginBottom: 4 }}>
        <HiddenIcon height={14} style={{ marginRight: 7 }} fill={postHiddenTag.icon} />
        <Text style={{ ...styles.title, color: postHiddenTag.title }}>Post is hidden</Text>
      </View>

      <Text style={{ ...styles.description, color: postHiddenTag.description }}>
        An administrator has hidden this post and is visible only to administrators.
      </Text>
    </View>
  ) : null
}

export default PostHiddenTag

const styles = StyleSheet.create({
  container: {
    paddingTop: 12,
    paddingBottom: 16,
    paddingHorizontal: 16,
    borderRadius: 8,
    marginBottom: 10
  },
  title: {
    fontSize: 13,
    fontWeight: '600',
    color: '#11141E',
    lineHeight: 18
  },
  description: {
    fontSize: 11,
    lineHeight: 15,
    color: '#3F4A55'
  }
})

import React from 'react'
import { View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { IdentityType, NftFilterBy } from '@vatom/sdk/core'
import { useConfig, useIdentities, useSDK } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { ScrollView, VStack } from 'native-base'

import logger from '../../../logger'
// import { Actionsheet, ScrollView, VStack } from 'native-base'
import ActionSheetHeader, { HeaderType } from '../partials/ActionSheetHeader'

import FilterList, { IFilterListItemProps } from './partials/FilterList'

interface INftFilter {
  setOpen: (b: boolean) => void
  isOpen: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const preDefinedCategories: any = {
  Art: {
    label: 'Art',
    iconSource: require('./assets/art.png'),
    key: 'Art'
  },
  Collectibles: {
    label: 'Collectibles',
    iconSource: require('./assets/collectibles.png'),
    key: 'Collectibles'
  },
  Entertainment: {
    label: 'Entertainment',
    iconSource: require('./assets/entertainment.png'),
    key: 'Entertainment'
  },
  Miscellaneous: {
    label: 'Miscellaneous',
    iconSource: require('./assets/miscellaneous.png'),
    key: 'Miscellaneous'
  },
  Utilities: {
    label: 'Utilities',
    iconSource: require('./assets/utilities.png'),
    key: 'Utilities'
  }
}

export const NftFilter = observer(({ isOpen, setOpen }: INftFilter) => {
  logger.info('[NftFilter.render]')
  const sdk = useSDK()
  const sdKConfig = useConfig()
  const allowdChains = sdKConfig.features.allowedChains

  const { allowedIdentities } = useIdentities({
    allowedTypes: allowdChains as IdentityType[]
  })
  const insets = useSafeAreaInsets()

  const onItemPress = (key: NftFilterBy, value?: string) => {
    sdk.nftFilter.setFilterBy(key)
    if (value) {
      sdk.nftFilter.setWalletAddress(value)
    }
    setOpen(false)
  }

  const dataCategories = [...new Set(sdk.nftFilter.allowedCategories)]
    .filter(cat => cat !== 'none')
    .map(category => {
      const i = preDefinedCategories[category]
        ? preDefinedCategories[category]
        : {
            label: category,
            iconSource: require('./assets/all_nfts.png'),
            key: category
          }

      return {
        ...i,
        selected: sdk.nftFilter.filterBy === category
      }
    })

  const dataAllowed = allowedIdentities.map((identity, index) => {
    return {
      label: identity.value,
      subtitle: identity.custodial ? 'Custodial' : '',
      key: identity.custodial ? NftFilterBy.Custodial : NftFilterBy.Wallet,
      iconSource:
        identity.type === IdentityType.Eth
          ? require('./assets/eth.png')
          : identity.type === IdentityType.Solana
          ? require('./assets/solana.png')
          : require('./assets/casper.png'),
      small: true,
      withSpace: index + 1 === allowedIdentities.length,
      bottomDivider: index + 1 === allowedIdentities.length,
      selected:
        (sdk.nftFilter.filterBy === NftFilterBy.Custodial &&
          sdk.nftFilter.walletAddress === identity.value) ||
        (sdk.nftFilter.filterBy === NftFilterBy.Wallet &&
          sdk.nftFilter.walletAddress === identity.value)
    }
  })

  const data: IFilterListItemProps[] = [
    {
      label: translate('actionSheets.allNft'),
      iconSource: require('./assets/all_nfts.png'),
      key: NftFilterBy.All,
      topDivider: true,
      bottomDivider: true,
      withSpace: true,
      selected: sdk.nftFilter.filterBy === NftFilterBy.All
    },
    {
      iconSource: require('./assets/digital_objects.png'),
      label: translate('actionSheets.smartNft'),
      key: NftFilterBy.SmartNft,
      selected: sdk.nftFilter.filterBy === NftFilterBy.SmartNft
    },
    ...dataAllowed,
    ...dataCategories
  ]

  return (
    <>
      <View>
        <VStack px="16px" w="100%">
          <ActionSheetHeader
            type={HeaderType.close}
            title={translate('common.filter')}
            onPress={() => setOpen(false)}
          />
        </VStack>
      </View>
      <ScrollView
        w="100%"
        _light={{
          backgroundColor: theme.colors.grayCool[100]
        }}
        _dark={{
          backgroundColor: theme.colors.grayCool[900]
        }}
        contentContainerStyle={{
          paddingBottom: insets.bottom
        }}
      >
        <FilterList data={data} onItemPress={onItemPress} />
      </ScrollView>
    </>
  )
})

export default NftFilter

import React, { useCallback } from 'react'
import { useNavigation } from '@react-navigation/native'
import { Destination, useAddRecentContact, useWeb3 } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { Status, Toast } from '@vatom/wombo'
import { useThrottledCallback } from 'use-debounce'

import Alert from '../../../../components/Alert'
import logger from '../../../../logger'
import { AppRoutes } from '../../../../navigators'

import { SendBtn, senderInterface } from '.'

export default function SendNft(props: senderInterface) {
  const { token, destination, userProfile } = props
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const navigation = useNavigation()
  const addRecentContactMutation = useAddRecentContact()
  const web3 = useWeb3(token)

  const onSendObject = useThrottledCallback(async () => {
    if (!web3.isConnected) {
      web3.open()
    }

    const send = await Alert.fire({
      text: translate('web3.nftDisclairmer', { network: token.network ? `(${token.network})` : '' })
    })

    if (send) {
      sendObject()
      return
    }
    setIsLoading(false)
    return
  }, 300)

  const sendObject = useCallback(async (): Promise<void> => {
    if (token) {
      setIsLoading(true)
      try {
        if (destination?.value && token.network) {
          const res = await web3.sendEthereumNft(destination.value, token)

          if (!res) {
            setIsLoading(false)
            Alert.showError(translate('web3.onSendError'))
            return
          }

          const contactOrDestination = userProfile || destination
          if (contactOrDestination) addRecentContactMutation.mutate(contactOrDestination)

          web3.setWeb3Confirmation({
            hash: res,
            to: destination.value,
            chain: token.network
          })

          navigation.navigate(AppRoutes.sendToChainConfirmation)
          return
        }
      } catch (err) {
        logger.error(err)
        Alert.showError(err)

        // Errors.show(err)
      } finally {
        setIsLoading(false)
      }
    }
  }, [destination, token, userProfile, web3, addRecentContactMutation, navigation])

  return (
    <SendBtn onPress={() => onSendObject()} disabled={!destination?.value || isLoading}>
      {web3.isConnected ? 'Send NFT' : 'Connect Wallet'}
    </SendBtn>
  )
}

/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * StorefrontBase
 * auto generated base class for the model StorefrontModel.
 *
 * A Metaplex storefront
 */
export const StorefrontModelBase = ModelBase
  .named('Storefront')
  .props({
    __typename: types.optional(types.literal("Storefront"), "Storefront"),
    address: types.union(types.undefined, types.string),
    ownerAddress: types.union(types.undefined, types.string),
    subdomain: types.union(types.undefined, types.string),
    title: types.union(types.undefined, types.string),
    description: types.union(types.undefined, types.string),
    faviconUrl: types.union(types.undefined, types.string),
    logoUrl: types.union(types.undefined, types.string),
    bannerUrl: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class StorefrontModelSelector extends QueryBuilder {
  get address() { return this.__attr(`address`) }
  get ownerAddress() { return this.__attr(`ownerAddress`) }
  get subdomain() { return this.__attr(`subdomain`) }
  get title() { return this.__attr(`title`) }
  get description() { return this.__attr(`description`) }
  get faviconUrl() { return this.__attr(`faviconUrl`) }
  get logoUrl() { return this.__attr(`logoUrl`) }
  get bannerUrl() { return this.__attr(`bannerUrl`) }
}
export function selectFromStorefront() {
  return new StorefrontModelSelector()
}

export const storefrontModelPrimitives = selectFromStorefront().address.ownerAddress.subdomain.title.description.faviconUrl.logoUrl.bannerUrl

import React from 'react'
import { ScrollView } from 'react-native'
import { EvilIcons } from '@expo/vector-icons'
import { BottomSheetView } from '@gorhom/bottom-sheet'
import { TokenType, TokenTypes } from '@vatom/sdk/core'
import { useConfig, useIdentities } from '@vatom/sdk/react'
import { translate, TxKeyPath } from '@vatom/utils'
import { GradientButton, Text, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Divider, Icon, Link, Pressable, VStack } from 'native-base'

import { useBusinessSelector } from '../../../hooks/useBusinessSelector'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'

const labelTranslate: any = {
  templatevariation: 'Template Variation',
  blueprintid: 'Blueprint ID',
  businessid: 'Business ID',
  campaignid: 'Campaign ID',
  distributionid: 'Distribution ID',
  objectdefinitionid: 'Object Defination ID'
}

export const InfoSheetModal = observer(
  ({ token, onDismiss }: { token: TokenType; onDismiss: () => void }) => {
    const { identities } = useIdentities()
    const config = useConfig()
    const showGeneralInfo = config.features.screensConfig?.NFTDetail?.actionsheet?.generalInfo

    const { business } = useBusinessSelector()
    const { brandTheme } = useBusinessTheme()

    const hasBrandColor = business?.id && brandTheme?.primary

    const toTitleCase = (str: string | undefined) => {
      if (!str) return str

      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
      })
    }

    const tokenType = token.getTokenType()
    const custodialWallet = identities?.find(i => i.custodial)?.value
    const account = token.blockchainInfo?.tokenLink
      ? tokenType === TokenTypes.VatomMintedCustodial
        ? custodialWallet
        : token.blockchainInfo?.owner || 'unknown'
      : 'N/A'

    const TokenId = () => {
      return token.blockchainInfo?.tokenLink ? (
        <>
          <DividerStyled />
          <Label tx="infoNft.tokenId">Token Id</Label>
          <Link
            href={token.blockchainInfo.tokenLink.replace(/\s/g, '')}
            accessibilityRole="button"
            alignItems="center"
          >
            <TextStyled ellipsizeMode="tail" noOfLines={1} pr={5} w="90%" fontSize="sm">
              {token.blockchainInfo.tokenId}
            </TextStyled>
            <Icon as={EvilIcons} color="black" name="chevron-right" size={'30px'} />
          </Link>
        </>
      ) : (
        <>
          <DividerStyled />
          <Label tx="infoNft.tokenId">Token Id</Label>
          <TextStyled>{token.id}</TextStyled>
        </>
      )
    }

    return (
      <BottomSheetView>
        <VStack pt={4} pb={4} safeAreaBottom paddingLeft={4} paddingRight={4}>
          <ScrollView
            style={{
              maxHeight: 500,
              marginBottom: 16
            }}
            contentContainerStyle={{
              paddingBottom: 20
            }}
          >
            <Label tx="infoNft.name">Name</Label>
            <TextStyled>{token.metadata.name}</TextStyled>
            {!showGeneralInfo || showGeneralInfo.includes('description') ? (
              <>
                <DividerStyled />
                <Label tx="infoNft.description">Description</Label>
                <TextStyled>
                  {!token.metadata.description || token.metadata.description === ''
                    ? translate('infoNft.noDescription')
                    : token.metadata.description}
                </TextStyled>
              </>
            ) : null}

            {token.blockchainInfo && (
              <>
                {!showGeneralInfo || showGeneralInfo.includes('blockchain') ? (
                  <>
                    <DividerStyled />
                    <Label tx="infoNft.blockchain">Blockchain</Label>
                    <TextStyled>{token.blockchainInfo?.networkName}</TextStyled>
                  </>
                ) : null}

                {!showGeneralInfo || showGeneralInfo.includes('isminted') ? (
                  <>
                    <DividerStyled />
                    <Label tx="infoNft.isMinted">Is Minted?</Label>
                    <TextStyled>{token.isMinted ? 'Yes' : 'No'}</TextStyled>
                  </>
                ) : null}

                {!showGeneralInfo || showGeneralInfo.includes('account') ? (
                  <>
                    <DividerStyled />
                    <Label tx="infoNft.account">Account</Label>
                    <TextStyled>{account}</TextStyled>
                  </>
                ) : null}
              </>
            )}

            {!showGeneralInfo || showGeneralInfo.includes('tokenid') ? <TokenId /> : null}

            {token.created && (
              <>
                <DividerStyled />
                <Label tx="infoNft.dateCreated">Date Created</Label>
                <TextStyled>{token.created.toLocaleString()}</TextStyled>
              </>
            )}

            {token.modified && (
              <>
                <DividerStyled />
                <Label tx="infoNft.dateModified">Date Modified</Label>
                <TextStyled>{token.modified.toLocaleString()}</TextStyled>
              </>
            )}

            {token.quantity && token.quantity > 1 && (
              <>
                <DividerStyled />
                <Label>Quantity</Label>
                <TextStyled>{token.quantity}</TextStyled>
              </>
            )}

            {token.contractType && (
              <>
                <DividerStyled />
                <Label>Contract Type</Label>
                <TextStyled>{token.contractType}</TextStyled>
              </>
            )}

            {token.metadata?.attributes?.map((attrb, idx) => {
              if (attrb.value) {
                return (
                  <React.Fragment key={`attrbute-${idx}`}>
                    {!showGeneralInfo || showGeneralInfo.includes(attrb.trait_type) ? (
                      <>
                        <DividerStyled />
                        <Label tx={`${(`infoNft.` + attrb.trait_type) as TxKeyPath}`}>
                          {toTitleCase(labelTranslate[attrb.trait_type] ?? attrb.trait_type)}
                        </Label>
                        <TextStyled>{attrb.value.toString()}</TextStyled>
                      </>
                    ) : null}
                  </React.Fragment>
                )
              }
              return null
            })}
          </ScrollView>
          <Box>
            {hasBrandColor ? (
              <Box width="100%" px={4}>
                <Pressable
                  accessibilityRole="button"
                  backgroundColor={brandTheme.primary}
                  padding={4}
                  justifyContent="center"
                  rounded="sm"
                  onPress={onDismiss}
                >
                  <Text
                    fontFamily="Inter-SemiBold"
                    alignSelf="center"
                    fontSize="18px"
                    color={brandTheme.primaryText ?? '#fff'}
                    tx="common.done"
                  />
                </Pressable>
              </Box>
            ) : (
              <GradientButton
                colorScheme="brand"
                px={4}
                w="100%"
                justifyContent="center"
                onPress={onDismiss}
              >
                <Text
                  color="white"
                  fontFamily="Inter-SemiBold"
                  alignSelf="center"
                  py={2}
                  fontSize="18px"
                  tx="common.done"
                />
              </GradientButton>
            )}
          </Box>
        </VStack>
      </BottomSheetView>
    )
  }
)

const DividerStyled = () => (
  <Divider
    height="1px"
    mt={2}
    mb={2}
    _light={{
      backgroundColor: theme.colors.grayCool[300]
    }}
    _dark={{
      backgroundColor: theme.colors.grayDarkMode[600]
    }}
  />
)

const Label = ({
  children,
  ...textProps
}: React.PropsWithChildren<React.ComponentProps<typeof Text>>) => (
  <Text
    fontSize="sm"
    _light={{
      color: theme.colors.textLightMode[300]
    }}
    _dark={{
      color: theme.colors.textDarkMode[300]
    }}
    {...textProps}
  >
    {children}
  </Text>
)
const TextStyled = ({
  children,
  ...textProps
}: React.PropsWithChildren<React.ComponentProps<typeof Text>>) => (
  <Text
    fontSize="md"
    _light={{
      color: theme.colors.textLightMode[900]
    }}
    _dark={{
      color: theme.colors.textDarkMode[100]
    }}
    {...textProps}
  >
    {children}
  </Text>
)

import * as React from 'react'
import { HStack, IStackProps, Text } from 'native-base'

import theme from '../theme'

interface ChipProps extends IStackProps {
  children: React.ReactNode
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
}

const renderChild = (child: React.ReactNode) =>
  typeof child === 'string' || typeof child === 'number' ? (
    <Text color={theme.colors.bodyText} fontFamily="Inter-SemiBold" fontSize="14" lineHeight="16">
      {child}
    </Text>
  ) : (
    child
  )

export const Chip = ({ children, startAdornment, endAdornment, ...rest }: ChipProps) => {
  return (
    <HStack
      space="2"
      alignItems="center"
      px="5"
      py="2"
      borderWidth="1"
      borderColor="extraLightText"
      borderRadius="full"
      {...rest}
    >
      {renderChild(startAdornment)}
      {renderChild(children)}
      {renderChild(endAdornment)}
    </HStack>
  )
}
export default Chip

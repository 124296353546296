import { useEffect } from 'react'

import { useMessage } from './useMessage'

export const useNativeBackHandler = (cb?: () => void) => {
  const { sendToParent } = useMessage('BACK_BUTTON_PRESSED', () => {
    cb?.()
  })

  useEffect(() => {
    sendToParent({
      type: 'SUBSCRIBE_GO_BACK'
    })
    return () => {
      sendToParent({
        type: 'UNSUBSCRIBE_GO_BACK'
      })
    }
  }, [sendToParent])

  return {
    goBack: () => {
      sendToParent({ type: 'NATIVE_GO_BACK' })
    }
  }
}

import {
  BlockchainInfoSnapshot,
  ERC721Metadata,
  ERC721MetadataSnapshot,
  Token,
  ViewMode,
  withRootSDKStore
} from '@vatom/sdk/core'
import { Instance, SnapshotOut } from 'mobx-state-tree'

import { NftModel } from '../modules/Holaplex'

// import { clusterApiUrl, Connection, PublicKey, Transaction } from '@solana/web3.js'
// import {
//   createAssociatedTokenAccountInstruction,
//   createTransferCheckedInstruction,
//   getAssociatedTokenAddress,
//   getMint
// } from '@solana/spl-token'

export const SolanaToken = Token.named('SolanaToken')
  .props({
    nft: NftModel
  })
  .extend(withRootSDKStore)
  .views(self => ({
    getFaceId(view: ViewMode) {
      let face
      switch (view) {
        // This display mode is used when a user opens the vatom. It falls back to icon if no engaged face is found.
        // Currently the Solana token only supports the ImageFace
        case 'engaged':
        case 'icon':
          return 'ImageFace'
        case 'fullscreen':
        case 'card':
          return undefined
        default:
          throw new Error(`Unknown View: ${view}`)
      }
    }
  }))
  .actions(self => ({
    afterCreate() {
      if (self.nft.category && self.rootStore.nftFilter) {
        self.rootStore.nftFilter.addAllowedCategory(self.nft.category)
      }
    }
  }))
  .actions(self => ({
    performAction(name: string, payload: any): any {
      switch (name) {
        case 'Transfer':
          return this.transferTo(payload, this)
        default:
          throw new Error('Not Implemented')
      }
    },
    async transferTo(payload: any, token: any): Promise<{ hash: string } | undefined> {
      // const connection = new Connection(clusterApiUrl('mainnet-beta'), 'confirmed')
      // // TODO: change for
      // const sender = new PublicKey(token.payload.owner.address)
      // const mintPubkey = new PublicKey(token.payload.mintAddress)
      // const currentATA = new PublicKey(token.payload.owner.associatedTokenAccountAddress)
      // const bh = await connection.getLatestBlockhash('finalized')
      // const blockhash = bh.blockhash
      // const recipientPubkey = new PublicKey(payload)
      // let mintAccount = await getMint(connection, mintPubkey)
      // const tx = new Transaction()

      // // calculate ATA
      // let ata = await getAssociatedTokenAddress(
      //   mintPubkey, // mint
      //   recipientPubkey
      // )
      // // FIRST WE NEED TO CREATE ATA OR CHECK IF IT ALREADY EXISTS
      // // const account = await getAccount(
      // // 	connection,
      // // 	ata,
      // // 	"finalized",
      // // 	ASSOCIATED_TOKEN_PROGRAM_ID
      // // );
      // try {
      //   tx.add(
      //     createAssociatedTokenAccountInstruction(
      //       sender, // payer
      //       ata, // ata
      //       recipientPubkey, // owner
      //       mintPubkey // mint
      //     )
      //   )
      //   tx.add(
      //     createTransferCheckedInstruction(
      //       currentATA, // from (should be a token account)
      //       mintPubkey, // mint
      //       ata, // to (should be a token account)
      //       sender, // from's owner
      //       10 ** mintAccount.decimals, // amount, if your deciamls is 8, send 10^8 for 1 token
      //       mintAccount.decimals // decimals
      //     )
      //   )
      //   const res = await window.solana?.connect()
      //   if (res) {
      //     tx.recentBlockhash = blockhash
      //     tx.feePayer = sender
      //     const signedTransaction = await window.solana.signTransaction(tx)
      //     const signature = await connection.sendRawTransaction(signedTransaction.serialize())
      //     logger.info('transaction result', signature)
      //     return { hash: signature }
      //     // TODO: return transcation hash
      //   } else {
      //     // todo: show error: user cancelled transaction
      //   }
      // } catch (err) {
      //   logger.info(err)
      //   logger.info('ATA ALREADY EXISTED???')
      //   // THEN WE TRANSFER THE NFT TO THAT ATA
      //   const tx2 = new Transaction()
      //   tx2.add(
      //     createTransferCheckedInstruction(
      //       currentATA, // from (should be a token account)
      //       mintPubkey, // mint
      //       ata, // to (should be a token account)
      //       sender, // from's owner
      //       10 ** mintAccount.decimals, // amount, if your deciamls is 8, send 10^8 for 1 token
      //       mintAccount.decimals // decimals
      //     )
      //   )
      //   const bh2 = await connection.getLatestBlockhash('finalized')
      //   const blockhash2 = bh2.blockhash
      //   tx2.recentBlockhash = blockhash2
      //   tx2.feePayer = sender

      //   const res = await window.solana?.connect()
      //   if (res) {
      //     const signedTransaction2 = await window.solana.signTransaction(tx2)
      //     const signature2 = await connection.sendRawTransaction(signedTransaction2.serialize())
      //     logger.info('transaction result', signature2)
      //     return { hash: signature2 }
      //     // TODO: return transcation hash
      //   }
      // }
      return
    }
  }))
  .views(self => ({
    get actions(): string[] {
      return ['Transfer']
    },
    get metadata(): ERC721MetadataSnapshot {
      const { name, image, description } = self.nft

      const attributes: [{ trait_type: string; value: string | number }] = [
        {
          trait_type: 'category',
          value: self.nft.category || 'none'
        }
      ]

      return ERC721Metadata.create({
        name,
        image: (image ?? '').replace('ipfs://', 'https://ipfs.io/ipfs/'),
        description,
        attributes
      })
    },
    get blockchainInfo(): BlockchainInfoSnapshot | undefined {
      const network = 'solana'

      const mintAddress = self.nft.mintAddress
      return {
        tokenId: self.id,
        network,
        networkName: self.getNetworkName(network),
        networkIcon: self.getNetworkIcon(network),
        contractAddress: mintAddress || '',
        owner: self.owner,
        tokenLink: mintAddress && self.getTokenLink(network, mintAddress)
      }
    },
    // get editionInfo() {
    //   return this.metadata.edition
    // },
    get supportedAddresses() {
      return ['identities.type:sol']
    },
    get isMinted(): boolean | undefined {
      return true
    },
    get royalties(): number | undefined {
      return undefined
    },
    get hasCardView() {
      return false
    },
    get displayImage() {
      return self.metadata.image
    }
  }))

// 	get faceSelector(): FaceSelector {
// 		return FaceSelection;
// 	}

// 	getView(fsp: FSP, config: ViewConfig): View {
// 		return new SolView(this, fsp || FaceSelection.Icon, config);
// 	}

// 	getViewNative(fsp: FSP, config: ViewConfig) {
// 		return (
// 			<SolViewNative
// 				token={this}
// 				fsp={fsp || FaceSelection.Icon}
// 				config={config}
// 			/>
// 		);
// 	}
// }

export type SolanaTokenType = Instance<typeof SolanaToken>
export type SolanaSnapshot = SnapshotOut<typeof SolanaToken>

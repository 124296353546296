import React from 'react'
import { Text } from 'react-native'

import { usePlugins } from '../../../../queries'
import { useCommunitiesTheme } from '../../../../themes'

const ModalTitle = ({
  title,
  eventType,
  isThread
}: {
  title: string
  eventType?: string
  isThread?: boolean
}) => {
  const communitiesTheme = useCommunitiesTheme()
  const { data: plugins, isSuccess } = usePlugins()
  const pluginData = isSuccess ? plugins[0] : null

  let threadTitle = ''
  if (isThread && eventType !== 'm.room.message') {
    if (!pluginData) {
      return null
    }

    const pluginDescriptor = pluginData.descriptor
    const messageFacades = pluginDescriptor.facades.message
    const messageFacade = messageFacades.find((facade: any) => facade.id === eventType)
    threadTitle = messageFacade.name
  }

  return (
    <Text
      style={{
        marginRight: 'auto',
        marginLeft: 'auto',
        fontWeight: 'bold',
        fontSize: 17,
        textTransform: 'capitalize',
        color: communitiesTheme.replyHeaderTitle
      }}
    >
      {title ? title : threadTitle}
    </Text>
  )
}

export default ModalTitle

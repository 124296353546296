import React from 'react'
import { Feather } from '@expo/vector-icons'
import { useSpace } from '@vatom/sdk/react'
import { PressableOpacity } from '@vatom/wombo'

import { CallControlType } from './EndCallButton'
import MicOn from './mic.svg'
import MicOff from './mic-mute.svg'

export function MicButton(props: CallControlType) {
  const { toggleMute, isMuted } = useSpace()
  const size = '58px'

  return (
    <PressableOpacity
      onPress={toggleMute}
      background={!isMuted ? 'green.500' : '#DD4267'}
      h={size}
      w={size}
      borderRadius={30}
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      {/* <Feather name="mic" size={props.secondary ? 20 : 25} color="white" /> */}
      {!isMuted ? (
        <MicOn width={props.secondary ? 20 : 12} height={props.secondary ? 20 : 25} />
      ) : (
        <MicOff height={props.secondary ? 20 : 25} width={props.secondary ? 20 : 25} />
      )}
    </PressableOpacity>
  )
}

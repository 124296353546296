import React from 'react'
import { View } from 'react-native'
import { useGetSpace } from '@vatom/sdk/react'

import ReplyArrow from '../../assets/replyArrow.svg'
import { getAdditionalInfo, isJson, transformedMessageData } from '../../helpers'
import { useMessage, useMessageFacades } from '../../queries'
import { MatrixMessage } from '../../types'
import { useCommunitySpace } from '../../useCommunitySpace'
import Header from '../ChatBubble/chat-components/Header'
import Content from '../ChatBubble/Content'
import { MessageProvider } from '../ChatBubble/MessageContext'

import ReplyTo from './ReplyTo'

type MessageInformation = {
  replyToMessageId: string
  spaceId: string
}

const MessageContent = ({
  spaceId,
  matrixMessage
}: {
  spaceId: string
  matrixMessage: MatrixMessage
}) => {
  const { businessId } = useCommunitySpace()
  const { data: messagesFacades } = useMessageFacades([matrixMessage.eventType], businessId)

  let messageContentElements: unknown = []
  let additionalInfo: object | null = null

  if (isJson(matrixMessage?.content)) {
    const parsedBody = JSON.parse(matrixMessage?.content)
    messageContentElements = parsedBody.elements
    additionalInfo = getAdditionalInfo(parsedBody)
  } else {
    messageContentElements = [{ type: 'text', content: matrixMessage?.content }]
  }

  return messagesFacades
    ? messagesFacades[0]?.elements.map((facadeElements: any) => {
        switch (facadeElements.type) {
          case 'header':
            return (
              <Header
                spaceId={spaceId}
                isThread
                decorations={facadeElements.decorations}
                key="classicheader"
                isReplyModal
              />
            )
          case 'content':
            return (
              <View
                key="content"
                style={{
                  marginLeft: 24,
                  borderLeftWidth: 1,
                  borderLeftColor: '#CED4DA',
                  paddingLeft: 24,
                  paddingBottom: 20
                }}
              >
                <Content
                  facadeElements={facadeElements}
                  messageContentElements={messageContentElements}
                  isThread={true}
                  additionalInfo={additionalInfo}
                  spaceId={spaceId}
                />
              </View>
            )
          default:
            return null
        }
      })
    : null
}

const MessageInformation = (props: MessageInformation) => {
  const { replyToMessageId, spaceId } = props
  const space = useGetSpace(spaceId, {
    enabled: false
  })
  const roomId = space.data?.matrixRoomId ?? ''

  const { data: message } = useMessage(roomId, replyToMessageId)
  let matrixMessage: MatrixMessage | null = null
  if (message) {
    matrixMessage = transformedMessageData(message)
  }

  return matrixMessage ? (
    <MessageProvider messageEventID={matrixMessage.eventId}>
      <MessageContent spaceId={spaceId} matrixMessage={matrixMessage} />
      <View style={{ flexDirection: 'row', alignItems: 'flex-end', marginBottom: 8 }}>
        <ReplyArrow style={{ marginLeft: 24, marginRight: 13 }} />
        <ReplyTo userId={matrixMessage.sender} />
      </View>
    </MessageProvider>
  ) : null
}

export default MessageInformation

import { createContext, memo, PropsWithChildren, useCallback, useContext } from 'react'
import { useThrottledCallback } from 'use-debounce'

import { useDrawerStore } from './drawerStore'

type DrawerContextProps = {
  getDrawerStatus: () => void
  openDrawer: () => void
  closeDrawer: () => void
  toggleDrawer: () => void
}
const DrawerContext = createContext<DrawerContextProps | undefined>(undefined)

const useDrawerNavigation = () => useContext(DrawerContext) as DrawerContextProps

const DRAWER_DEBOUNCE = 300

const DrawerProvider = memo(({ children }: PropsWithChildren) => {
  const { openDrawer, closeDrawer, toggleDrawer } = useDrawerStore.getState()

  const getDrawerStatus = useCallback(() => useDrawerStore.getState().drawerStatus, [])

  const openDrawerThrottled = useThrottledCallback(openDrawer, DRAWER_DEBOUNCE, {
    leading: true
  })
  const closeDrawerThrottled = useThrottledCallback(closeDrawer, DRAWER_DEBOUNCE, {
    leading: true
  })
  const toggleDrawerThrottled = useThrottledCallback(toggleDrawer, DRAWER_DEBOUNCE, {
    leading: true
  })

  return (
    <DrawerContext.Provider
      value={{
        getDrawerStatus,
        openDrawer: openDrawerThrottled,
        closeDrawer: closeDrawerThrottled,
        toggleDrawer: toggleDrawerThrottled
      }}
    >
      {children}
    </DrawerContext.Provider>
  )
})

export { DrawerProvider, useDrawerNavigation }

import React from 'react'
import { Ionicons } from '@expo/vector-icons'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { Contact, useGetContactPresence } from '@vatom/sdk/react'
import { Avatar, PressableOpacity, Text, theme } from '@vatom/wombo'
import { Box, Pressable, useColorModeValue } from 'native-base'

import { AppRoutes } from '../../../navigators'
import { color } from '../../../theme'
// import ChatIcon from '../assets/chat.svg'
import ConnectIcon from '../assets/connect.svg'

interface IItemPeople {
  contact: Contact
  setSelectedSpaceId: (spaceId: string) => void
}

export const ItemPeople = ({ contact, setSelectedSpaceId }: IItemPeople) => {
  const isFocused = useIsFocused()
  const navigation = useNavigation()
  const presence = useGetContactPresence(contact, { enabled: isFocused })
  const presenceId = presence?.data?.presence_id?.split(':')?.[1]
  const presenceState = presence?.data?.presence_state ?? 'offline'
  const openProfile = () => navigation.navigate(AppRoutes.profileOtherUser, { userId: contact.id })

  const connectIconColor = useColorModeValue(
    theme.colors.grayCool[700],
    theme.colors.grayDarkMode[200]
  )
  const badgeColor = useColorModeValue(
    theme.colors.systemColorsLight.green,
    theme.colors.systemColorsDark.green
  )
  const locationIconColor = useColorModeValue(
    theme.colors.textLightMode[300],
    theme.colors.textDarkMode[600]
  )

  return (
    <PressableOpacity onPress={openProfile}>
      <Box
        alignItems="center"
        borderBottomWidth={1}
        flexDir="row"
        h={62}
        mx={4}
        py={3}
        _light={{
          borderColor: theme.colors.grayCool[300]
        }}
        _dark={{
          borderColor: theme.colors.grayDarkMode[700]
        }}
      >
        <Avatar
          size={44}
          url={contact.avatarUrl}
          badgeColor={presenceState === 'online' ? badgeColor : undefined}
        />
        <Box ml={3} flex={1}>
          <Text
            _light={{
              color: theme.colors.textLightMode[900]
            }}
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
            fontFamily="Inter-Bold"
            fontSize={15}
          >
            {contact.name}
          </Text>
          {contact.preferred_username ? (
            <Text
              fontFamily="Inter-Bold"
              fontSize={11}
              fontWeight={400}
              lineHeight={15}
              _light={{
                color: theme.colors.textLightMode[300]
              }}
              _dark={{
                color: theme.colors.textDarkMode[600]
              }}
            >
              @{contact.preferred_username}
            </Text>
          ) : null}

          {presenceState === 'online' ? (
            <Box flexDir="row" alignItems="center">
              <Ionicons name="location-outline" size={12} color={locationIconColor} />
              <Text
                fontFamily="Inter-Regular"
                fontSize={13}
                fontWeight={400}
                lineHeight={18}
                _light={{
                  color: theme.colors.textLightMode[300]
                }}
                _dark={{
                  color: theme.colors.textDarkMode[600]
                }}
              >
                {presence?.data?.presence_alias}
              </Text>
            </Box>
          ) : null}
        </Box>

        {presenceState === 'online' && presenceId ? (
          <Pressable
            accessibilityRole="button"
            alignItems="center"
            _light={{
              borderColor: theme.colors.textLightMode[300]
            }}
            _dark={{
              borderColor: theme.colors.textDarkMode[600]
            }}
            borderRadius={3}
            borderWidth={1}
            h={30}
            justifyContent="center"
            w={30}
            onPress={() => setSelectedSpaceId(presenceId)}
            mr={'5px'}
          >
            <ConnectIcon height={17} width={17} fill={connectIconColor} />
          </Pressable>
        ) : null}
        {/* <Pressable
        accessibilityRole="button"
        alignItems="center"
        borderColor="extraLightText"
        borderRadius={3}
        borderWidth={1}
        h={30}
        justifyContent="center"
        w={30}
        onPress={() => alert('not yet implemented')}
      >
        <ChatIcon height={17} width={17} />
      </Pressable> */}
      </Box>
    </PressableOpacity>
  )
}

export default ItemPeople

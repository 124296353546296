import React from 'react'
import { translate } from '@vatom/utils'
import { Switch, Text, theme } from '@vatom/wombo'
import { Box, Divider, Link } from 'native-base'

interface ISellSwitches {
  isHideOnMarket: boolean
  setIsHideOnMarket: (b: boolean) => void
  isListOnUSD: boolean
  setIsListOnUSD: (b: boolean) => void
  isListOnETH: boolean
  setIsListOnETH: (b: boolean) => void
}

export const SellSwitches = ({
  isHideOnMarket,
  setIsHideOnMarket,
  isListOnUSD,
  setIsListOnUSD,
  isListOnETH,
  setIsListOnETH
}: ISellSwitches) => {
  return (
    <>
      <Box
        w="100%"
        paddingY="11px"
        flexDirection="row"
        justifyContent="space-between"
        paddingX="16px"
      >
        <Box>
          <Text fontSize="15px" fontWeight="400" tx="sellNft.hideOnMarket" />
          <Text
            _light={{
              color: theme.colors.textLightMode[300]
            }}
            _dark={{
              color: theme.colors.textDarkMode[300]
            }}
            fontSize="11px"
            fontWeight="400"
          >
            {translate('sellNft.onlyAccessLink')}{' '}
            <Link
              href="https://support.vatom.com/hc/en-us/articles/8126145555099-Create-a-private-listing-on-the-Vatom-Market"
              isExternal={true}
              _text={{
                color: 'blue.400',
                fontSize: '11px'
              }}
              mt={-0.5}
              _web={{
                mb: -2
              }}
            >
              {translate('common.learnMore')}
            </Link>
            .
          </Text>
        </Box>
        <Switch value={isHideOnMarket} onToggle={() => setIsHideOnMarket(!isHideOnMarket)} />
      </Box>
      <Divider
        backgroundColor="gray.900"
        _light={{
          backgroundColor: theme.colors.grayCool[300]
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[700]
        }}
        height="1px"
      />
      <Divider
        backgroundColor="trueGray.300"
        _light={{
          backgroundColor: theme.colors.grayCool[100]
        }}
        _dark={{
          backgroundColor: theme.colors.grayCool[900]
        }}
        height="6px"
      />
      <Divider
        backgroundColor="gray.900"
        _light={{
          backgroundColor: theme.colors.grayCool[300]
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[700]
        }}
        height="1px"
      />
      <Box w="100%" paddingY="11px" paddingX="16px">
        <Switch
          label={translate('sellNft.listIn', { cur: 'US$' })}
          value={isListOnUSD}
          onToggle={() => setIsListOnUSD(!isListOnUSD)}
        />
      </Box>
      <Divider
        _light={{
          backgroundColor: theme.colors.grayCool[300]
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[700]
        }}
        height="1px"
      />
      <Box w="100%" paddingY="11px" paddingX="16px">
        <Switch
          label={translate('sellNft.listIn', { cur: 'ETH' })}
          value={isListOnETH}
          onToggle={() => setIsListOnETH(!isListOnETH)}
        />
      </Box>
    </>
  )
}

export default SellSwitches

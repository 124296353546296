import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const GlobeIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 24 24" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M12 4C16.4062 4 20 7.59375 20 12C20 16.4375 16.4062 20 12 20C7.5625 20 4 16.4375 4 12C4 7.59375 7.5625 4 12 4ZM12 19C12.5 19 13.25 18.5625 13.9062 17.2188C14.2188 16.5938 14.5 15.8438 14.6562 15H9.3125C9.46875 15.8438 9.75 16.5938 10.0625 17.2188C10.7188 18.5625 11.4688 19 12 19ZM9.125 14H14.8438C14.9375 13.375 15 12.7188 15 12C15 11.3125 14.9375 10.6562 14.8438 10H9.125C9.03125 10.6562 9 11.3125 9 12C9 12.7188 9.03125 13.375 9.125 14ZM14.6562 9C14.5 8.15625 14.2188 7.4375 13.9062 6.8125C13.25 5.46875 12.5 5 12 5C11.4688 5 10.7188 5.46875 10.0625 6.8125C9.75 7.4375 9.46875 8.15625 9.3125 9H14.6562ZM15.8438 10C15.9375 10.6562 16 11.3125 16 12C16 12.7188 15.9375 13.375 15.8438 14H18.6875C18.875 13.375 19 12.7188 19 12C19 11.3125 18.875 10.6562 18.6875 10H15.8438ZM14.2188 5.375C14.875 6.25 15.4062 7.53125 15.6875 9H18.3125C17.5 7.3125 16.0312 6 14.2188 5.375ZM9.75 5.375C7.9375 6 6.46875 7.3125 5.65625 9H8.28125C8.5625 7.53125 9.09375 6.25 9.75 5.375ZM5 12C5 12.7188 5.09375 13.375 5.28125 14H8.125C8.03125 13.375 8 12.7188 8 12C8 11.3125 8.03125 10.6562 8.125 10H5.28125C5.09375 10.6562 5 11.3125 5 12ZM18.3125 15H15.6875C15.4062 16.5 14.875 17.75 14.2188 18.6562C16.0312 18.0312 17.5 16.7188 18.3125 15ZM8.28125 15H5.65625C6.46875 16.7188 7.9375 18.0312 9.75 18.6562C9.09375 17.75 8.5625 16.5 8.28125 15Z"
    />
  </Icon>
)

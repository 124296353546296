/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * MarketStatsBase
 * auto generated base class for the model MarketStatsModel.
 */
export const MarketStatsModelBase = ModelBase
  .named('MarketStats')
  .props({
    __typename: types.optional(types.literal("MarketStats"), "MarketStats"),
    nfts: types.union(types.undefined, types.null, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class MarketStatsModelSelector extends QueryBuilder {
  get nfts() { return this.__attr(`nfts`) }
}
export function selectFromMarketStats() {
  return new MarketStatsModelSelector()
}

export const marketStatsModelPrimitives = selectFromMarketStats().nfts

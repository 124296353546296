import React, { useCallback, useEffect, useRef } from 'react'
import { Platform } from 'react-native'
import { Box, Select } from 'native-base'

import Error from './Error'
import LabelForm from './Label'
import { fieldPropsInterfaceItem } from './Types'

const EnumFieldWeb = (props: fieldPropsInterfaceItem) => {
  const isMounted = useRef(false)

  const setDefaultOption = useCallback(() => {
    // Set default to internal storage
    if (props.field?.options) {
      props.onChange(props.field.name, props.field?.options[0].value)
    }
  }, [props])

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      setDefaultOption()
    }
  }, [setDefaultOption])

  return (
    <Box>
      <LabelForm {...props} />
      <select
        className="select-wrapper"
        value={props.value ?? ''}
        style={{
          width: '100%',
          color: 'black',
          borderRadius: '4px',
          padding: '11px',
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          backgroundColor: 'rgb(240, 242, 244)',
          borderColor: 'rgb(196, 196, 196)',
          borderWidth: '2px',
          fontSize: '16px'
        }}
        onChange={itemValue => props.onChange(props.field.name, itemValue.target.value)}
      >
        {props.field.options?.map(c => (
          <option label={c.title} value={c.value} key={c.value}>
            {c.title}
          </option>
        ))}
      </select>
      <Error {...props} />
    </Box>
  )
}

const EnumFieldNative = (props: fieldPropsInterfaceItem) => {
  return (
    <Box>
      <LabelForm {...props} />
      <Select
        selectedValue={props.value ?? ''}
        w="100%"
        accessibilityLabel={props.field.name}
        accessibilityHint={props.field.name}
        borderWidth="1"
        borderColor="rgb(196, 196, 196)"
        borderRadius="4px"
        onValueChange={itemValue => props.onChange(props.field.name, itemValue)}
      >
        {props.field.options?.map(c => (
          <Select.Item label={c.title} value={c.value} key={c.value} />
        ))}
      </Select>
      <Error {...props} />
    </Box>
  )
}
const EnumField = Platform.select({
  web: EnumFieldWeb,
  native: EnumFieldNative
})

export default EnumField

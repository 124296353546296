import { Platform } from 'react-native'
import { CommonActions, useNavigation } from '@react-navigation/native'
import { useMutation, useQuery } from '@tanstack/react-query'
import { RegionType } from '@vatom/sdk/core'
import {
  useAnalytics,
  useGrant,
  useIsAuthed,
  useNetworkUserId,
  useRegion,
  useSDK
} from '@vatom/sdk/react'
import { InventoryRegionType } from '@vatom/vatomNew/plugin'
import { LoaderView, Status, Toast } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Toast as T } from 'native-base'

import Alert from '../../components/Alert'
import { useNavigateHome } from '../../hooks/useNavigateHome'
import logger from '../../logger'
import { useStore } from '../../models'
import { AppRoutes, AppStackScreenProps, TabRoutes } from '../../navigators'

type AcquireProps = AppStackScreenProps<
  typeof AppRoutes.Acquire | typeof AppRoutes.Acquire_Business
>

export const Acquire = observer((props: AcquireProps) => {
  const store = useStore()
  const isAuthed = useIsAuthed()
  const sdk = useSDK()
  const networkUserId = useNetworkUserId()
  const analytics = useAnalytics()
  const navigation = useNavigation()
  const bvatomRegion = useRegion(RegionType.inventory, 'BVatomInventoryRegionStore')
  const vatomRegion = useRegion(RegionType.inventory, 'InventoryRegionStore') as InventoryRegionType
  const { navigateHome } = useNavigateHome()
  const { id, context, login, ar, msg, from, businessId, name } = props.route.params ?? {}

  const loginReqd = login !== '0'

  const userId = sdk.dataPool.user.userInfo?.sub

  const enabledQuery =
    !!props.route.params &&
    !!isAuthed &&
    !ar &&
    !!vatomRegion &&
    userId !== undefined &&
    networkUserId !== undefined

  const { isLoading, isError, isSuccess } = useQuery({
    queryKey: ['acquire', id, userId, networkUserId, context],
    queryFn: async ({ queryKey }) => {
      try {
        const [_, tokenId, userId, networkUserId] = queryKey
        logger.info('[Acquire] acquire', userId, networkUserId)

        let decContext
        if (context) {
          const decoded = atob(context)
          decContext = decoded && JSON.parse(decoded)
        }

        if (id.length === 10 && tokenId) {
          await vatomRegion.performAction('Acquire', {
            tokenId
          })
          vatomRegion.reload()
        } else {
          await bvatomRegion.performAction('Acquire', {
            'this.id': tokenId,
            userRef: {
              id: userId,
              provider: 'vatominc'
            },
            // geo,
            userId: networkUserId
          })

          analytics.event('performAction', {
            actionUri: 'Acquire',
            digitalObjectId: id,
            ...decContext
          })
        }

        const nameOrToken = name ?? 'a token'

        Toast({
          title: msg || `You have successfully acquired ${nameOrToken} from ${from}`,
          placement: 'top',
          status: Status.success
        })

        if (businessId) {
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: AppRoutes.BusinessProxy, params: { business: businessId } }]
            })
          )
        } else {
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: AppRoutes.home, params: { screen: TabRoutes.Wallet } }]
            })
          )
        }
        store.linking.clearLinkingUrl()
        return null
      } catch (error) {
        Alert.showError(error)
        navigateHome({
          businessId
        })
        throw error
      }
    },
    enabled: enabledQuery,
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false
  })

  useGrant(
    'urn:vatominc:params:oauth:grant-type:guest-registration',
    {
      scope: 'profile offline_access'
    },
    isAuthed === false && !loginReqd && !!props.route.params
  )

  if (loginReqd && !isAuthed && Platform.OS === 'web') {
    logger.info('[Acquire] navigate to connect')
    navigation.navigate(AppRoutes.connect)
    return
  } else if (!isLoading && !isSuccess && !isError && !enabledQuery) {
    // nothing to do, go home
    navigateHome({
      businessId
    })
  }

  return <LoaderView />
})

export function useAquireMutation() {
  const isAuthed = useIsAuthed()
  const sdk = useSDK()
  const networkUserId = useNetworkUserId()
  const analytics = useAnalytics()
  const bvatomRegion = useRegion(RegionType.inventory, 'BVatomInventoryRegionStore')
  const vatomRegion = useRegion(RegionType.inventory, 'InventoryRegionStore') as InventoryRegionType

  const mutationFn = async (params: AcquireProps['route']['params']) => {
    const { id, context, ar, msg, from, name } = params ?? {}

    try {
      const userId = sdk.dataPool.user.userInfo?.sub
      const canAquire =
        !!params &&
        !!isAuthed &&
        !ar &&
        !!vatomRegion &&
        userId !== undefined &&
        networkUserId !== undefined

      if (!canAquire) {
        throw new Error('Cannot acquire')
      }

      let decContext
      if (context) {
        const decoded = atob(context)
        decContext = decoded && JSON.parse(decoded)
      }
      if (id.length === 10) {
        await vatomRegion.performAction('Acquire', {
          tokenId: id
        })
        vatomRegion.reload()
      } else {
        await bvatomRegion.performAction('Acquire', {
          'this.id': id,
          userRef: {
            id: userId,
            provider: 'vatominc'
          },
          userId: networkUserId
        })
        analytics.event('performAction', {
          actionUri: 'Acquire',
          digitalObjectId: id,
          ...decContext
        })
      }

      const nameOrToken = name ?? 'a token'
      const fromMessage = from ? ` from ${from}` : '.'
      T.close('aquiring')
      Toast({
        id: 'aquire-success',
        title: msg || `You have successfully acquired ${nameOrToken}${fromMessage}`,
        placement: 'top',
        status: Status.success
      })
    } catch (err) {
      Toast({
        title: 'Failed to acquire',
        placement: 'top',
        status: Status.error
      })
    }
  }

  return useMutation(mutationFn)
}

export default Acquire

import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const ShareIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 16 16" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M9.25 5C9.65625 5 10 4.6875 10 4.25V4V3.5V2L15 6.5L10 11V9.5V9V8.75C10 8.34375 9.65625 8 9.25 8H9H6C3.78125 8 2 9.8125 2 12C2 12.2812 2 12.5312 2.0625 12.75C1.46875 12 1 10.9688 1 9.5C1 7.03125 3 5 5.5 5H9H9.25ZM9 9.5V10V11C9 11.4062 9.21875 11.7812 9.59375 11.9375C9.9375 12.0938 10.375 12.0312 10.6562 11.75L15.6562 7.25C15.875 7.0625 16 6.8125 16 6.5C16 6.21875 15.875 5.96875 15.6562 5.78125L10.6562 1.28125C10.375 1 9.9375 0.9375 9.59375 1.09375C9.21875 1.25 9 1.625 9 2V3V3.5V4H8H5.5C2.4375 4 0 6.46875 0 9.5C0 13.0625 2.53125 14.625 3.125 14.9688C3.1875 15 3.28125 15 3.375 15C3.71875 15 4 14.75 4 14.4062C4 14.1562 3.84375 13.9375 3.6875 13.7812C3.375 13.5 3 12.9688 3 12C3 10.3438 4.34375 9 6 9H8H9V9.5Z"
    />
  </Icon>
)

import React from 'react'
import { Text, View } from 'react-native'

import NftIcon from './assets/badge-icons/nft_icon.svg'
import ProposalIcon from './assets/badge-icons/proposal_icon.svg'
import TopicIcon from './assets/badge-icons/topic_icon.svg'

const Badge = ({ type, text, isThread }: { type: string; text?: string; isThread?: boolean }) => {
  const badgeType = () => {
    switch (type) {
      case 'v.room.nft':
        return <NftIcon width="12" height="12" style={{ marginRight: 8, marginLeft: 4 }} />
      case 'v.room.proposal':
        return <ProposalIcon width="12" height="12" style={{ marginRight: 8, marginLeft: 4 }} />
      // case 'v.room.topic':
      //   return <TopicIcon width="12" height="12" />
      default:
        return null
    }
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        backgroundColor: 'rgba(0, 18, 38, 0.04)',
        alignSelf: 'flex-start',
        borderRadius: 4,
        marginVertical: 4,
        alignItems: 'center',
        padding: 8,
        marginBottom: 8,
        marginLeft: isThread ? 0 : 55
      }}
    >
      {badgeType()}

      <Text style={{ color: '#868E96', textTransform: 'capitalize' }}>
        {text ? text : type.split('.')[2]}
      </Text>
    </View>
  )
}

export default Badge

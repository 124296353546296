import { useMemo } from 'react'
import { useFrame } from '@react-three/fiber'
import { BVatomTokenType } from '@vatom/BVatom/plugin'
import { TokenType } from '@vatom/sdk/core'
import {
  useCampaignGroupOverview,
  useCampaignGroupsRules,
  useCampaignPoints
} from '@vatom/sdk/react'
import { LocationObject } from 'expo-location'
import { create } from 'zustand'

import { useFilterStore } from '../../hooks/useFilterStore'
import { useLocationStore } from '../../hooks/useLocationStore'

export const useTokenPositions = (
  vatom: BVatomTokenType,
  geoPosRegionTokens: BVatomTokenType[],
  inventoryTokens: BVatomTokenType[],
  location?: LocationObject | GeolocationPosition | null
) => {
  const overview = useGameOverview(vatom)

  return useMemo(() => {
    if (!location || !vatom || !overview.data?.rules || geoPosRegionTokens.length === 0) {
      return []
    }
    const tokens = getTokens(vatom, geoPosRegionTokens, inventoryTokens, location, overview)

    return tokens
  }, [geoPosRegionTokens, inventoryTokens, location, overview, vatom])
}

const getMemoKey = (lat: number, long: number) => `${lat}-${long}`

export const getTargetTokenId = (token: BVatomTokenType | TokenType) => {
  return token.parentId !== '.' ? token.parentId : token.id
}

export const useGameRules = (vatom: BVatomTokenType) => {
  const businessId = vatom.studioInfo!.businessId
  const rules = useCampaignGroupsRules(businessId, vatom.studioInfo!.campaignId)
  return rules
}

export const useGamePoints = (vatom: BVatomTokenType) => {
  const points = useCampaignPoints(vatom.studioInfo!.campaignId)
  return points
}

export const useGameOverview = (vatom: BVatomTokenType) => {
  const businessId = vatom.studioInfo!.businessId
  const overview = useCampaignGroupOverview(businessId, vatom.studioInfo!.campaignId)
  return overview
}

// Helper function to compute the Haversine distance
export const haversineDistance = (lat1: number, long1: number, lat2: number, long2: number) => {
  const R = 6371000 // Earth's radius in meters
  const dLat = (lat2 - lat1) * (Math.PI / 180)
  const dLong = (long2 - long1) * (Math.PI / 180)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c
}

export const computeBearing = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const y = Math.sin(lon2 - lon1) * Math.cos(lat2)
  const x =
    Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1)
  return ((Math.atan2(y, x) * 180) / Math.PI + 360) % 360
}

export const getUniquePositionFromCoords = (
  tokenLong: number,
  tokenLat: number,
  userLong: number,
  userLat: number,
  existingPositions: [number, number, number][],
  heading?: number | null,
  minDistanceFromUser = 5,
  _minDistanceBetweenTokens = 3,
  maxTries = 10000
): [number, number, number] => {
  let pos: [number, number, number]
  let overlap: boolean
  let tries = 0
  const minDistanceBetweenTokens = Math.max(_minDistanceBetweenTokens, 3)

  const distanceFromUser = haversineDistance(userLat, userLong, tokenLat, tokenLong)

  do {
    overlap = false
    const bearing = computeBearing(userLat, userLong, tokenLat, tokenLong)

    let angle = typeof heading === 'number' ? (bearing - heading + 360) % 360 : Math.random() * 360

    // Add a random offset to the angle for retries
    if (tries) {
      angle += (Math.random() - 0.5) * 30 // Vary the angle by +/- 15 degrees
    }

    const adjustedDistance = Math.max(distanceFromUser, minDistanceFromUser) // Ensure it's at least `minDistanceFromUser` away
    const x = adjustedDistance * Math.cos(angle)
    const y = 0
    const z = adjustedDistance * Math.sin(angle)

    pos = [x, y, z]

    for (const existingPos of existingPositions) {
      const dx = existingPos[0] - pos[0]
      const dy = existingPos[2] - pos[2]
      const distanceBetween = Math.sqrt(dx * dx + dy * dy)

      if (distanceBetween < minDistanceBetweenTokens) {
        // You can adjust this value as needed
        overlap = true
        break
      }
    }

    tries++
  } while (overlap && tries < maxTries)

  if (tries === maxTries) {
    console.error('Could not find a suitable position after maximum tries')
  }

  return pos
}

// export const getSingleInstanceTokenIds = (overview: ReturnType<typeof useGameOverview>) => {
//   return overview.data?.rules?.reduce<string[]>((acc, rule) => {
//     if (rule.effects.some(effect => effect.isNonceEnabled)) {
//       acc.push(rule.trigger.config.objectDefinitionId)
//     }
//     return acc
//   }, [])
// }

export const getNonces = (overview: ReturnType<typeof useGameOverview>) => {
  return overview.data?.rules?.reduce<{
    dispensers: Record<string, string[]>
    pickups: string[]
  }>(
    (acc, rule) => {
      const dispenseId = 'action-id-dispense-v1'
      const pickUpId = 'action-id-pickup-v1'
      const isPickup = rule.trigger.id === pickUpId
      const isDispense = rule.trigger.id === dispenseId
      if (!isPickup && !isDispense) {
        return acc
      }

      if (isPickup && rule.effects.some(effect => effect.isNonceEnabled)) {
        acc.pickups.push(rule.trigger.config.objectDefinitionId)
      }

      if (isDispense) {
        const effectId = 'send-object-v2'
        const tokenIdsFromDispense = rule.effects.reduce<string[]>((ids, effect) => {
          if (effect.id === effectId && effect.isNonceEnabled) {
            // @ts-ignore
            ids = ids.concat(effect?.config?.objectDefinitionIds)
          }
          return ids
        }, [])

        const maybeDispenser = acc.dispensers[rule.trigger.config.objectDefinitionId]
        if (maybeDispenser) {
          acc.dispensers[rule.trigger.config.objectDefinitionId] =
            acc.dispensers[rule.trigger.config.objectDefinitionId]?.concat(tokenIdsFromDispense)
        } else {
          acc.dispensers[rule.trigger.config.objectDefinitionId] = tokenIdsFromDispense
        }
      }

      return acc
    },
    {
      dispensers: {},
      pickups: []
    }
  )
}

export const filterTokens = (
  gameVatom: BVatomTokenType,
  gameTokens: BVatomTokenType[],
  inventoryTokens: BVatomTokenType[],
  nonces?: ReturnType<typeof getNonces>
) => {
  return gameTokens.reduce<BVatomTokenType[]>((acc, currentToken) => {
    const doNothing =
      !currentToken.studioInfo?.objectDefinitionId ||
      !currentToken.position?.coordinates ||
      currentToken.studioInfo?.campaignId !== gameVatom.studioInfo?.campaignId

    if (doNothing) {
      return acc
    }
    // Filter pickups uniquely
    const shouldBeUnique = nonces?.pickups?.includes(currentToken.studioInfo?.objectDefinitionId)

    const isInInventoryAndShouldBeUnique =
      currentToken.studioInfo?.objectDefinitionId &&
      shouldBeUnique &&
      !!inventoryTokens.find(
        token =>
          token.studioInfo?.objectDefinitionId === currentToken.studioInfo?.objectDefinitionId
      )

    if (isInInventoryAndShouldBeUnique) {
      return acc
    }

    // Filter dispensers uniquely
    const dispenserUniqueTokens = nonces?.dispensers[currentToken.studioInfo?.objectDefinitionId]
    const userAlreadyDispensed = dispenserUniqueTokens?.some(objectDefinitionId =>
      inventoryTokens.find(token => token.studioInfo?.objectDefinitionId === objectDefinitionId)
    )
    if (userAlreadyDispensed) {
      return acc
    }

    // should be unique and is already in the array (since it's ordered we will get the closest one)
    if (
      shouldBeUnique &&
      !!acc.find(
        token =>
          token.studioInfo?.objectDefinitionId === currentToken.studioInfo?.objectDefinitionId
      )
    ) {
      return acc
    }

    acc.push(currentToken)
    return acc
  }, [])
}

export const sortTokensByDistance = (
  tokens: BVatomTokenType[],
  location: LocationObject | GeolocationPosition
) => {
  const copy = [...tokens]
  copy.sort((a, b) => {
    if (!a.position?.coordinates || !b.position?.coordinates) return 0
    const aDistance = haversineDistance(
      location?.coords.latitude ?? 0,
      location?.coords.longitude ?? 0,
      a.position?.coordinates[1] ?? 0,
      a.position?.coordinates[0] ?? 0
    )
    const bDistance = haversineDistance(
      location?.coords.latitude ?? 0,
      location?.coords.longitude ?? 0,
      b.position?.coordinates[1] ?? 0,
      b.position?.coordinates[0] ?? 0
    )
    return aDistance - bDistance
  })
  return copy
}

const getTokens = (
  vatom: BVatomTokenType,
  geoPosRegionTokens: BVatomTokenType[],
  inventoryTokens: BVatomTokenType[],
  location: LocationObject,
  overview: ReturnType<typeof useGameOverview>
) => {
  const nonces = getNonces(overview)
  const filteredTokens = filterTokens(vatom, geoPosRegionTokens, inventoryTokens, nonces)
  return filteredTokens
}

export const useSortedTokenStore = create<{
  tokens: BVatomTokenType[]
}>(set => ({
  tokens: [],
  setTokens: (tokens: BVatomTokenType[]) => set({ tokens })
}))

export const useTokenSorter = (tokens: BVatomTokenType[]) => {
  useFrame(() => {
    const currentLocation = useLocationStore.getState().position
    const sortedTokens = currentLocation ? sortTokensByDistance(tokens, currentLocation) : []
    const maxItems = useFilterStore.getState().maxItems

    useSortedTokenStore.setState({ tokens: sortedTokens.slice(0, maxItems) })
  })
}

import { useCallback } from 'react'
import { Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { BVatomPluginType } from '@vatom/BVatom/plugin'
import { useSDK } from '@vatom/sdk/react'
import { Status, Toast } from '@vatom/wombo'
import { BarCodeScanningResult } from 'expo-camera'
import { observer } from 'mobx-react-lite'

import DeepLinkManager from '../../components/DeepLinkManager'
import logger from '../../logger'

import { CameraScanner } from './partials/CameraScanner'

const isWeb = Platform.OS === 'web'

export const CameraScreen = observer(() => {
  const navigation = useNavigation()
  const sdk = useSDK()
  const bVatomPlugin = sdk.dataPool.getPlugin('BVatomPlugin') as BVatomPluginType

  const processLink = useCallback(
    (link: string) => {
      logger.info('Processing link: ', link)
      try {
        const url = new URL(link)
        if (isWeb && url.protocol === 'http:') {
          window.open(link, '_self')
          return
        } else if (DeepLinkManager.isDeepLink(url)) {
          DeepLinkManager.handleDeepLink(url, bVatomPlugin.api!, navigation)
          return
        } else {
          throw new Error(`Qr code wasn't handle`)
        }
      } catch (error) {
        throw new Error(`Could't process qr code`)
      }
    },
    [navigation, bVatomPlugin.api]
  )

  const onScanner = (scanResult: BarCodeScanningResult) => {
    console.log('LOG: > onScanner > scanResult:', scanResult)
    const { data } = scanResult ?? {}
    // Give feedback to the user
    Toast({
      title: `Processing QR Code`,
      status: Status.info,
      placement: 'top',
      duration: 2000
    })
    try {
      // Process the link, (this will do something then navigate > give feedback)
      processLink(data)
    } catch (error) {
      // process error, show Toast
      console.error('ERROR: CameraScreen > onScanner:', error)
      const errorMsg = `${data.substring(0, 100)}...`
      Toast({
        title: `Invalid QR code: ${errorMsg}`,
        status: Status.error,
        placement: 'top'
      })
    } finally {
      // Close Screen
      navigation.goBack()
    }
  }

  const onClose = () => {
    navigation.goBack()
  }

  return <CameraScanner onScanner={onScanner} onClose={onClose} />
})

export default CameraScreen

import React from 'react'
import { StyleSheet, View } from 'react-native'

import { useCommunitiesTheme } from '../../../../themes'

const ProgressBar = ({ fillBarLength }: { fillBarLength: number }) => {
  const communitiesTheme = useCommunitiesTheme()
  return (
    <View style={progressBar(fillBarLength, communitiesTheme).bar}>
      <View style={progressBar(fillBarLength, communitiesTheme).fill}></View>
    </View>
  )
}

const progressBar = (fillBarLength?: number, communitiesTheme?: any) =>
  StyleSheet.create({
    bar: {
      flex: 1,
      height: 8,
      backgroundColor: communitiesTheme.score.lineColor,
      borderRadius: 100,
      marginLeft: 4
    },
    fill: {
      height: 8,
      width: `${fillBarLength ?? 0}%`,
      backgroundColor: communitiesTheme.score.lineFill,
      borderRadius: 100
    }
  })

export default ProgressBar

/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * ProfileBase
 * auto generated base class for the model ProfileModel.
 */
export const ProfileModelBase = ModelBase
  .named('Profile')
  .props({
    __typename: types.optional(types.literal("Profile"), "Profile"),
    walletAddress: types.union(types.undefined, types.null, types.string),
    handle: types.union(types.undefined, types.string),
    profileImageUrlLowres: types.union(types.undefined, types.string),
    profileImageUrlHighres: types.union(types.undefined, types.string),
    bannerImageUrl: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ProfileModelSelector extends QueryBuilder {
  get walletAddress() { return this.__attr(`walletAddress`) }
  get handle() { return this.__attr(`handle`) }
  get profileImageUrlLowres() { return this.__attr(`profileImageUrlLowres`) }
  get profileImageUrlHighres() { return this.__attr(`profileImageUrlHighres`) }
  get bannerImageUrl() { return this.__attr(`bannerImageUrl`) }
}
export function selectFromProfile() {
  return new ProfileModelSelector()
}

export const profileModelPrimitives = selectFromProfile().walletAddress.handle.profileImageUrlLowres.profileImageUrlHighres.bannerImageUrl

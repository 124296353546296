import React from 'react'
import { Box, IBoxProps } from 'native-base'

export interface GradientBackgroundProps extends IBoxProps {
  colors: string[]
  children: React.ReactNode
}

export const GradientBackground = ({ colors, children, ...props }: GradientBackgroundProps) => {
  return (
    <Box
      bg={{
        linearGradient: {
          colors
        }
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

export default GradientBackground

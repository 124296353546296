import React, { useMemo } from 'react'
import { ParamListBase, useNavigation } from '@react-navigation/native'
import { BusinessData, useConfig, useUser } from '@vatom/sdk/react'
import { TxKeyPath } from '@vatom/utils'
import {
  AppRoutes,
  CommunitiesRoutes,
  SpacesRoutes,
  TabRoutes,
  WalletRoutes
} from '@vatom/wallet-routes'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { Avatar, PressableOpacity, Text, theme } from '@vatom/wombo'
import { Box, Pressable } from 'native-base'

import { BusinessCurrency } from '../../../../components/BusinessCurrency'
import { useBusinessSelector } from '../../../../hooks/useBusinessSelector'
import { useIsDesktop } from '../../../../hooks/useIsDesktop'
import { navigateToHome } from '../../../../hooks/useWebViewRef'
import { DrawerContentNavigatorMemo, getActiveRouteName } from '../../../../navigators'
import { useDebugMode } from '../../../../utils/useDebugMode'
import { DrawerSelectorButton } from '../DrawerSelectorButton'
import { VatomMenu } from '../VatomMenu'

import { useHeaderDesktopContext } from './useHeaderDesktop'

export const headerMaxHeight = 70
type RoutesBusiness = {
  label: TxKeyPath
  name?: string
  matches?: string[]
  route: keyof typeof TabRoutes | keyof typeof AppRoutes
  params?: ParamListBase | Record<'screen', string>
}

const HomeNavRoutes: RoutesBusiness[] = [
  {
    label: 'common.spaces',
    name: SpacesRoutes.Spaces,
    route: TabRoutes.Connect,
    params: {
      screen: SpacesRoutes.Spaces
    }
  },
  {
    label: 'common.people',
    name: SpacesRoutes.People,
    route: TabRoutes.Connect,
    params: {
      screen: SpacesRoutes.People
    }
  },
  {
    label: 'common.map',
    route: TabRoutes.Map
  },
  {
    label: 'common.nfts',
    name: WalletRoutes.NFTs,
    route: TabRoutes.Wallet,
    params: {
      screen: WalletRoutes.NFTs
    }
  },
  {
    label: 'common.coins',
    name: WalletRoutes.Coins,
    route: TabRoutes.Wallet,
    params: {
      screen: WalletRoutes.Coins
    }
  }
]

const businessNavRoutesDefault: RoutesBusiness[] = [
  {
    label: 'common.wallet',
    route: TabRoutes.Wallet
  }
]
// TODO: add custom routes based on pageConfig features

const getNavRoutesForBusiness = (business?: BusinessData) => {
  if (!business) {
    return businessNavRoutesDefault
  }
  const routes = Array.from(businessNavRoutesDefault)
  const shouldRenderMicrosite = business?.pageConfig?.features?.footer?.icons?.find(
    item => item?.id === 'home' && item?.link !== ''
  )
  const shouldRenderConnect = business?.pageConfig?.features?.footer?.icons?.find(
    item => item?.id === 'connect' || item?.id === 'people' || item?.id === 'spaces'
  )
  const shouldRenderMap = business?.pageConfig?.features?.footer?.icons?.find(
    item => item?.id === 'map'
  )

  if (shouldRenderMicrosite) {
    routes.push({
      label: 'common.home',
      route: TabRoutes.Home
    })
  }
  if (shouldRenderConnect) {
    routes.push({
      label: 'common.connect',
      matches: [TabRoutes.Connect, CommunitiesRoutes.Communities],
      route: TabRoutes.Connect
    })
  }
  if (shouldRenderMap) {
    routes.push({
      label: 'common.map',
      route: TabRoutes.Map
    })
  }

  return routes
}

export const DesktopHeader = () => {
  const { config, isEmbedded } = useVatomWalletSdkStore()
  const navigation = useNavigation()
  const activeRouteName = getActiveRouteName(navigation.getState())
  const configApp = useConfig()

  const user = useUser()
  const isDesktop = useIsDesktop()
  const { businessIdentifier, business } = useBusinessSelector()
  const { headerPortalCenter, headerPortalRight, drawerOpen } = useHeaderDesktopContext()
  const debugMode = useDebugMode()

  const homeRoutes = useMemo(
    () =>
      !debugMode
        ? HomeNavRoutes
        : [
            ...HomeNavRoutes,
            {
              label: 'common.dms',
              name: TabRoutes.DMs,
              route: TabRoutes.DMs
            } as RoutesBusiness
          ],
    [debugMode]
  )

  const desktopNavRoutes = useMemo(
    () => (business ? getNavRoutesForBusiness(business) : homeRoutes),
    [business, homeRoutes]
  )

  if (!isDesktop) return null

  const navigateTo = (name: string, params?: ParamListBase) => () =>
    // @ts-expect-error fix
    navigation.navigate(name, params)

  return (
    <Box
      display={
        !isEmbedded || (isEmbedded && !config?.pageConfig?.features?.inventory?.hideHeader)
          ? undefined
          : 'none'
      }
      flex={1}
      flexDirection={'row'}
      alignItems={'center'}
      paddingY={3}
      backgroundColor={theme.colors.white}
      borderBottomWidth={1}
      maxHeight={headerMaxHeight}
      minHeight={headerMaxHeight}
      zIndex={2}
      _light={{
        backgroundColor: theme.colors.white,
        borderBottomColor: '#DFE0E1'
      }}
      _dark={{
        backgroundColor: theme.colors.grayCool[900],
        borderBottomColor: theme.colors.grayCool[800]
      }}
    >
      <Box marginRight={10} marginLeft={5}>
        <DrawerSelectorButton />
        {!drawerOpen ||
        (configApp.isBusinessLocked && configApp.subBusinesses.length === 0) ? null : (
          <Box
            position={'absolute'}
            left={0}
            top={2}
            style={{
              transform: [{ translateY: 50 }]
            }}
            minWidth={320}
            maxWidth={'100%'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            _dark={{
              backgroundColor: theme.colors.grayCool[900]
            }}
            _light={{
              backgroundColor: 'white'
            }}
            zIndex={1}
            // @ts-ignore - RN Web
            dataSet={{ className: 'business-menu' }}
          >
            <Box
              position={'absolute'}
              marginTop={'-10px'}
              marginLeft={'43px'}
              _dark={{
                borderBottomColor: theme.colors.grayCool[900]
              }}
              _light={{
                borderBottomColor: 'white'
              }}
              style={{
                width: 0,
                height: 0,
                backgroundColor: 'transparent',
                borderStyle: 'solid',
                borderLeftWidth: 5,
                borderRightWidth: 5,
                borderBottomWidth: 10,
                borderLeftColor: 'transparent',
                borderRightColor: 'transparent'
              }}
            />
            <DrawerContentNavigatorMemo />
          </Box>
        )}
      </Box>
      <Box flexDirection={'row'}>
        {desktopNavRoutes.map((link, index) => {
          const routeName = link?.name ?? link.route
          const isActive =
            activeRouteName === routeName &&
            (link?.matches ? link?.matches?.includes(routeName) : true)
          return (
            <Pressable
              accessibilityRole="button"
              key={`${link.name}-${index}`}
              onPress={() => {
                if (link.label.toLowerCase() === 'home') {
                  navigateToHome()
                }
                // @ts-expect-error params shouldn't be undefined
                navigateTo(link.route, link?.params)()
              }}
              p={2}
              m={0.5}
              _hover={{
                backgroundColor: isActive ? 'transparent' : theme.colors.trueGray[50]
              }}
              _dark={{
                _hover: {
                  backgroundColor: isActive ? 'transparent' : theme.colors.brand[500],
                  color: theme.colors.white
                }
              }}
            >
              <Text
                color={isActive ? theme.colors.brand[500] : 'black'}
                _dark={{
                  color: isActive ? theme.colors.brand[500] : theme.colors.textDarkMode[100]
                }}
                fontFamily={'Outfit'}
                fontWeight={500}
                fontSize={14}
                lineHeight={16}
                letterSpacing={1.5}
                textTransform={'uppercase'}
                tx={link.label}
              />
            </Pressable>
          )
        })}
      </Box>
      <Box flexDirection={'row'} justifyContent={'space-between'} flex={1}>
        <Box alignSelf={'flex-start'} flexDirection={'row'}></Box>
        <Box alignSelf={'center'} flexDirection={'row'}>
          {headerPortalCenter}
        </Box>
        <Box alignSelf={'flex-end'} flexDirection={'row'}>
          {headerPortalRight}
        </Box>
      </Box>
      <Box
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        marginLeft={5}
        marginRight={5}
      >
        {isDesktop && businessIdentifier && (
          <Box marginRight={3}>
            <BusinessCurrency businessId={businessIdentifier} />
          </Box>
        )}
        <VatomMenu />
        <PressableOpacity
          onPress={() =>
            navigation.navigate(AppRoutes.profileUser, {
              business: businessIdentifier
            })
          }
          marginLeft={4}
        >
          <Avatar
            nbAvatarSize="100%"
            borderWidth={1}
            borderColor={theme.colors.brand[500]}
            size={44}
            url={user?.picture}
          />
        </PressableOpacity>
      </Box>
    </Box>
  )
}

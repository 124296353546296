import React from 'react'
import { TokenType, TokenTypes } from '@vatom/sdk/core'
import { PressableOpacity, Text, theme } from '@vatom/wombo'
import { Box, useColorMode } from 'native-base'

import BackIcon from '../../../assets/back.min.svg'
import CloseIcon from '../../../assets/close.min.svg'

import SecondaryText from './SecondaryText'

interface ActionSheetHeaderProps {
  onPress: () => void
  hasText?: boolean
  text?: string
  title?: string
  subTitle?: string
  hasLearnMore?: boolean
  type: HeaderType
  token?: TokenType
  isShare?: boolean
}

export enum HeaderType {
  close = 'close',
  back = 'left',
  none = 'none'
}

export const getTitle = (token: TokenType) => {
  if (token) {
    const type = token.getTokenType()
    if (type === TokenTypes.VatomUnMinted) return 'Unminted Smart NFT'
    if (type === TokenTypes.VatomMintedNonCustodial) return 'Non-Custodial Smart NFT'
    if (type === TokenTypes.VatomMintedCustodial) return 'Custodial Smart NFT'
    if (type === TokenTypes.VatomBasic) return 'Smart NFT'
    return 'NFT'
  }
}

export const ActionSheetHeader = ({
  title,
  onPress,
  type,
  token,
  subTitle
}: ActionSheetHeaderProps) => {
  const { colorMode } = useColorMode()

  return (
    <Box key="ActionSheetHeader">
      <Box
        marginBottom={3}
        w="100%"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {type !== HeaderType.none ? (
          <PressableOpacity
            width={10}
            height={10}
            alignItems={'center'}
            justifyContent={'center'}
            accessibilityRole="button"
            onPress={onPress}
          >
            {type === HeaderType.back ? (
              <BackIcon
                height={25}
                width={25}
                fill={colorMode === 'light' ? theme.colors.darkText : theme.colors.white}
              />
            ) : (
              <CloseIcon
                height={16}
                width={16}
                fill={colorMode === 'light' ? theme.colors.darkText : theme.colors.white}
              />
            )}
          </PressableOpacity>
        ) : null}
        <Box width={type === HeaderType.none ? '100%' : 'auto'}>
          <Text
            fontFamily="Inter-SemiBold"
            fontSize={17}
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
            _light={{
              color: theme.colors.textLightMode[900]
            }}
            // color={theme.colors.grayCool[900]}
            fontWeight="600"
            textAlign="center"
          >
            {token ? getTitle(token) : title ? title : ''}
          </Text>
          {subTitle ? (
            <Text
              w={type === HeaderType.none ? '100%' : 'auto'}
              fontSize={12}
              color={theme.colors.grayCool[600]}
              fontWeight="500"
              textAlign="center"
            >
              {subTitle}
            </Text>
          ) : null}
        </Box>

        {/* Spacer */}
        {type !== HeaderType.none ? <Box width={10} height={10} /> : null}
      </Box>
    </Box>
  )
}

export default ActionSheetHeader

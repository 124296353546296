import React from 'react'
import { IdentitySnapshot } from '@vatom/sdk/core'
import { Text, theme } from '@vatom/wombo'
import { Box, Pressable, Radio } from 'native-base'

interface ISelectAddressProps {
  title: string
  hasManage: boolean
  setSelectedAddress: (I: string) => void
  selectedAddress: string
  identities: IdentitySnapshot[]
}

interface IItemProps {
  item: IdentitySnapshot
  index: number
}

export const SelectAddressOld = ({
  title,
  hasManage,
  setSelectedAddress,
  selectedAddress,
  identities
}: ISelectAddressProps) => {
  const RenderWalletListItem = ({ item, index }: IItemProps) => {
    return (
      <Pressable
        key={`render-wallet-list-item-${index}`}
        accessibilityRole="button"
        width="100%"
        borderBottomWidth="1px"
        borderBottomColor="light.100"
        _light={{
          borderBottomColor: theme.colors.grayCool[200]
        }}
        _dark={{
          borderBottomColor: theme.colors.grayDarkMode[600]
        }}
      >
        <Text
          _light={{
            color: theme.colors.lightText
          }}
          _dark={{
            color: theme.colors.grayDarkMode[100]
          }}
          marginTop="16px"
          marginBottom="8px"
          fontFamily="Inter-Medium"
          fontSize={12}
          _web={{
            fontSize: 14
          }}
        >
          {item.custodial ? 'Custodial' : item.type}
        </Text>
        <Box
          marginBottom="20px"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            ellipsizeMode="tail"
            noOfLines={1}
            fontFamily="Inter-Regular"
            fontSize={11}
            pr={5}
            w="90%"
            _light={{
              color: theme.colors.textLightMode[900]
            }}
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
          >
            {item.value}
          </Text>
          <Radio
            value={item.value}
            my={index}
            accessibilityHint={item.value}
            accessibilityLabel={item.value}
          />
        </Box>
      </Pressable>
    )
  }

  return identities.length > 0 ? (
    <Box width="100%" flex={1}>
      <Box alignItems="center" flexDir="row" justifyContent="space-between">
        <Text
          _light={{
            backgroundColor: theme.colors.white
          }}
          _dark={{
            backgroundColor: theme.colors.grayDarkMode[900]
          }}
          fontFamily="Inter-SemiBold"
          fontSize="22px"
        >
          {title}
        </Text>
        {hasManage && (
          <Pressable accessibilityRole="button">
            <Text
              fontFamily="Inter-Medium"
              fontSize="15px"
              _dark={{
                color: theme.colors.systemColorsDark.blue
              }}
              _light={{
                color: theme.colors.systemColorsLight.blue
              }}
              tx="common.manage"
            />
          </Pressable>
        )}
      </Box>

      <Radio.Group
        value={selectedAddress}
        name="walletAddressGroup"
        onChange={i => setSelectedAddress(i)}
        accessibilityHint="Navigates to the previous screen"
      >
        {identities.map((item, index) => (
          <RenderWalletListItem key={`WalletListItem${index}`} item={item} index={index} />
        ))}
      </Radio.Group>
    </Box>
  ) : null
}

export default SelectAddressOld

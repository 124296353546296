import { PublicKey } from '@solana/web3.js'
import { CountryCode, isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js'

import { Destination, DestinationTypes } from './types'

// import Location from './Location'

export function validateEmail(email: string): boolean {
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export function validateSol(wallet: string): boolean {
  return /^[1-9A-HJ-NP-Za-km-z]{32,44}$/.test(wallet)
}

export function validateEth(wallet: string): boolean {
  return /^0x[a-fA-F0-9]{40}$/.test(wallet)
}

export function validateENS(value: string): boolean {
  return value.slice(-4) === '.eth'
}

export function validateDestination(
  input: string,
  sol?: boolean,
  countryCode?: CountryCode
): Destination {
  const value = sol ? input?.trim() || '' : input?.toLowerCase().trim() || ''

  if (validateSol(value)) {
    let PK = false
    try {
      // eslint-disable-next-line no-constant-condition
      if (new PublicKey(value)) PK = true
    } catch (error) {
      PK = false
    }
    return {
      type: DestinationTypes.Solana,
      value: value,
      validatedValue: value?.toLowerCase(),
      isValid: PK
    }
  }

  if (value.indexOf('0x') !== -1) {
    return {
      type: DestinationTypes.Eth,
      value,
      validatedValue: value?.toLowerCase(),
      isValid: validateEth(value)
    }
  }

  if (value.indexOf('@') !== -1) {
    return {
      type: DestinationTypes.Email,
      value,
      validatedValue: value?.toLowerCase(),
      isValid: validateEmail(value)
    }
  }

  if (validateENS(value)) {
    return {
      type: DestinationTypes.ENS,
      value,
      validatedValue: value?.toLowerCase(),
      isValid:
        // eslint-disable-next-line no-useless-escape
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/.test(
          value
        )
    }
  }

  if (/^[ +()\-0-9]+$/.test(value)) {
    const phoneNumber = parsePhoneNumberFromString(
      value,
      value.includes('+') ? undefined : countryCode ?? 'US'
    )
    const result = {
      type: DestinationTypes.Phone,
      value,
      validatedValue: phoneNumber?.number.toString() || value,
      isValid: !!phoneNumber && isValidPhoneNumber(phoneNumber.number)
    }

    return result
  }

  return {
    type: DestinationTypes.Id,
    value,
    validatedValue: value?.toLowerCase(),
    isValid: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(value)
  }
}

import React, { createContext, useContext, useMemo } from 'react'

type MessageContextProps = {
  messageEventID: string
}

export const MessageContext = createContext({
  messageEventID: ''
} as MessageContextProps)
export const useMessageContext = () => useContext(MessageContext) as MessageContextProps

export const MessageProvider = ({
  messageEventID,
  children
}: {
  messageEventID: string
  children: React.ReactNode
}) => {
  const value = useMemo(
    () => ({
      messageEventID
    }),
    [messageEventID]
  )

  return <MessageContext.Provider value={value}>{children}</MessageContext.Provider>
}

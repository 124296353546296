import React, { useMemo } from 'react'
import { Image, Platform } from 'react-native'
import { useConfig } from '@vatom/sdk/react'
import { theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { useColorModeValue } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import { EllipsisIcon } from '../../components/Icons'
import { ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'

const isWeb = Platform.OS === 'web'

type NFTDetailHeaderProps = {
  onClose: () => void
  onPressOptions: () => void
}

export const NFTDetailHeader = observer((props: NFTDetailHeaderProps) => {
  const config = useConfig()
  const hideOptionButton = !!config.features.screensConfig?.NFTDetail?.hideTokenActions
  const hideCloseButton = !!config.features.screensConfig?.NFTDetail?.hideCloseButton
  const customActionBtn = config.features.screensConfig?.NFTDetail?.customActionBtn
  // GET SOMETHING CONFIG
  const headerIconColor = useColorModeValue(
    theme.colors.textLightMode[900],
    theme.colors.textDarkMode[100]
  )

  const handleOnPressOptions = useThrottledCallback(props.onPressOptions, 300, {
    leading: true
  })

  const customActionOptionIcon = useMemo(() => {
    if (customActionBtn) {
      const img = customActionBtn?.icon
      const style = customActionBtn?.style ?? {}

      if (!img) {
        return null
      }

      if (isWeb) {
        return <img src={img} style={style as React.CSSProperties} alt="customActionBtn" />
      }

      return (
        <Image
          accessibilityIgnoresInvertColors
          source={{ uri: img }}
          style={{ objectFit: 'contain', height: 30, width: 150, ...style }}
        />
      )
    }
  }, [customActionBtn])

  const renderHeaderRight = useMemo(() => {
    if (hideOptionButton) {
      return <ScreenHeaderButton />
    }
    if (customActionOptionIcon) {
      return (
        <ScreenHeaderButton onPress={handleOnPressOptions}>
          {customActionOptionIcon}
        </ScreenHeaderButton>
      )
    }
    return (
      <ScreenHeaderButton onPress={handleOnPressOptions}>
        <EllipsisIcon fill={headerIconColor} size={8} />
      </ScreenHeaderButton>
    )
  }, [customActionOptionIcon, handleOnPressOptions, headerIconColor, hideOptionButton])

  if (hideCloseButton && hideOptionButton) {
    return null
  }

  return (
    <ScreenHeader
      headerLeftType={hideCloseButton ? 'none' : 'close'}
      headerTextColor={headerIconColor}
      headerLeftPress={props.onClose}
      headerProps={{
        paddingBottom: 1,
        overflow: 'hidden'
      }}
      centerContainerProps={{
        flex: 1,
        paddingX: 2
      }}
      leftContainerProps={{
        flex: 0,
        _web: {
          flexGrow: 0,
          flexShrink: 1,
          flexBasis: 'auto'
        }
      }}
      rightContainerProps={{
        flex: 0,
        _web: {
          flexGrow: 0,
          flexShrink: 1,
          flexBasis: 'auto'
        }
      }}
      headerRight={() => renderHeaderRight}
    />
  )
})

import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const MapIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 18 17" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M8.25 3.5C8.25 3.09375 8.5625 2.75 9 2.75C9.40625 2.75 9.75 3.09375 9.75 3.5C9.75 3.9375 9.40625 4.25 9 4.25C8.5625 4.25 8.25 3.9375 8.25 3.5ZM8.53125 9.75C7.53125 8.5 5.25 5.46875 5.25 3.75C5.25 1.6875 6.90625 0 9 0C11.0625 0 12.75 1.6875 12.75 3.75C12.75 5.46875 10.4375 8.5 9.4375 9.75C9.21875 10.0625 8.75 10.0625 8.53125 9.75ZM11.4375 4.84375C11.6562 4.34375 11.75 4 11.75 3.75C11.75 2.25 10.5 1 9 1C7.46875 1 6.25 2.25 6.25 3.75C6.25 4 6.3125 4.34375 6.53125 4.84375C6.71875 5.34375 7.03125 5.875 7.34375 6.40625C7.875 7.28125 8.5 8.125 9 8.75C9.46875 8.125 10.0938 7.28125 10.625 6.40625C10.9375 5.875 11.25 5.34375 11.4375 4.84375ZM12.6562 6.96875C12.6562 7 12.625 7 12.5938 7C12.8438 6.5625 13.0938 6.09375 13.2812 5.65625L16.9688 4.1875C17.4375 4 18 4.34375 18 4.875V13.3438C18 13.6562 17.8125 13.9375 17.5 14.0312L12.6562 15.9688C12.5625 16.0312 12.4688 16.0312 12.3438 16L5.5 14.0312L1 15.8438C0.53125 16.0312 0 15.6875 0 15.1562V6.6875C0 6.375 0.15625 6.09375 0.46875 6L4.3125 4.4375C4.375 4.78125 4.46875 5.09375 4.59375 5.40625L1 6.84375V14.7812L5 13.1875V9.5C5 9.25 5.21875 9 5.5 9C5.75 9 6 9.25 6 9.5V13.125L12 14.8438V9.5C12 9.25 12.2188 9 12.5 9C12.75 9 13 9.25 13 9.5V14.7812L17 13.1875V5.25L12.6562 6.96875Z"
    />
  </Icon>
)

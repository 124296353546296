/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
import React from 'react'
import { Platform, useWindowDimensions, View, ViewStyle } from 'react-native'
import { theme } from '@vatom/wombo'
import { Image } from 'expo-image'

import { getWidthRatio } from '../../hooks/useImageSize'

const isWeb = Platform.OS === 'web'

export type PossibleImage = {
  uri?: string
  url?: string
  alt?: string
  blurhash?: string
  width: number
  height: number
}

export const ImagePagerItem = React.memo(
  ({
    image,
    width,
    height,
    containerStyle
  }: {
    image: PossibleImage
    width?: number
    height?: number
    containerStyle?: ViewStyle
  }) => {
    const { width: windowWidth, height: windowHeight } = useWindowDimensions()
    const webMaxWith = theme.breakpoints.md < windowWidth ? theme.breakpoints.md : windowWidth
    const maxWidth = isWeb ? webMaxWith : windowWidth
    const imgSize = getWidthRatio(image.width, image.height, width ?? maxWidth)
    return (
      <View
        style={[
          {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center'
          },
          containerStyle
        ]}
        collapsable={false}
      >
        <Image
          alt={image?.alt}
          placeholder={image?.blurhash}
          source={{ uri: image?.url ?? image?.uri }}
          style={{
            maxWidth: maxWidth,
            width: imgSize.width,
            height: imgSize.height,
            maxHeight: windowHeight
          }}
        />
      </View>
    )
  }
)

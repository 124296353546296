export const generalToIANAMapping = {
  'Universal Coordinated Time': 'Etc/UTC',
  'European Central Time': 'Europe/Paris',
  'Eastern European Time': 'Europe/Athens',
  'Eastern African Time': 'Africa/Nairobi',
  'Pakistan Lahore Time': 'Asia/Karachi',
  'India Standard Time': 'Asia/Kolkata',
  'Bangladesh Standard Time': 'Asia/Dhaka',
  'Vietnam Standard Time': 'Asia/Ho_Chi_Minh',
  'China Taiwan Time': 'Asia/Taipei',
  'Japan Standard Time': 'Asia/Tokyo',
  'Australia Central Time': 'Australia/Adelaide',
  'Australia Eastern Time': 'Australia/Sydney',
  'Solomon Standard Time': 'Pacific/Guadalcanal',
  'New Zealand Standard Time': 'Pacific/Auckland',
  'Midway Islands Time': 'Pacific/Midway',
  'Hawaii Standard Time': 'Pacific/Honolulu',
  'Alaska Standard Time': 'America/Anchorage',
  'Pacific Standard Time': 'America/Los_Angeles',
  'Phoenix Standard Time': 'America/Phoenix',
  'Eastern Standard Time': 'America/New_York',
  'Puerto Rico and US Virgin Islands Time': 'America/Puerto_Rico',
  'Canada Newfoundland Time': 'America/St_Johns',
  'Argentina Standard Time': 'America/Buenos_Aires',
  'Brazil Eastern Time': 'America/Sao_Paulo'
}

export const IANAToGeneralMapping = {
  'Etc/UTC': 'Universal Coordinated Time',
  'Europe/Paris': 'European Central Time',
  'Europe/Athens': 'Eastern European Time',
  'Africa/Cairo': '(Arabic) Egypt Standard Time',
  'Africa/Nairobi': 'Eastern African Time',
  'Asia/Beirut': 'Middle East Time',
  'Asia/Damascus': 'Near East Time',
  'Asia/Karachi': 'Pakistan Lahore Time',
  'Asia/Kolkata': 'India Standard Time',
  'Asia/Dhaka': 'Bangladesh Standard Time',
  'Asia/Ho_Chi_Minh': 'Vietnam Standard Time',
  'Asia/Taipei': 'China Taiwan Time',
  'Asia/Tokyo': 'Japan Standard Time',
  'Australia/Adelaide': 'Australia Central Time',
  'Australia/Sydney': 'Australia Eastern Time',
  'Pacific/Guadalcanal': 'Solomon Standard Time',
  'Pacific/Auckland': 'New Zealand Standard Time',
  'Pacific/Midway': 'Midway Islands Time',
  'Pacific/Honolulu': 'Hawaii Standard Time',
  'America/Anchorage': 'Alaska Standard Time',
  'America/Los_Angeles': 'Pacific Standard Time',
  'America/Phoenix': 'Phoenix Standard Time',
  'America/Denver': 'Mountain Standard Time',
  'America/Chicago': 'Central Standard Time',
  'America/New_York': 'Eastern Standard Time',
  'America/Indiana/Indianapolis': 'Indiana Eastern Standard Time',
  'America/Puerto_Rico': 'Puerto Rico and US Virgin Islands Time',
  'America/St_Johns': 'Canada Newfoundland Time',
  'America/Buenos_Aires': 'Argentina Standard Time',
  'America/Sao_Paulo': 'Brazil Eastern Time',
  'Africa/Harare': 'Central African Time'
}

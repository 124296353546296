import AsyncStorage from '@react-native-async-storage/async-storage'
import { useQuery } from '@tanstack/react-query'
import { useGetMultiAlgoliaContacts } from '@vatom/sdk/react'

import { Contact, ContactSources, Destination } from '../types'
import { validateDestination } from '../validate'

export const recentContactsQueryKeys = {
  recentContacts: [{ scope: 'recent-contacts' }] as const,
  searchRecentToAlgolia: [{ scope: 'recent-to-algolia-contacts' }] as const,
  getRecentContacts: () => [{ ...recentContactsQueryKeys.recentContacts[0] }] as const,
  getRecentContactsToAlgolia: (destination: Destination) =>
    [{ ...recentContactsQueryKeys.searchRecentToAlgolia[0], destination }] as const
}

const defaultSelector = (contacts: string) => {
  try {
    const parsedContacts = JSON.parse(contacts) as Contact[]
    return parsedContacts.map(contact => {
      contact.source = ContactSources.recents
      return contact
    })
  } catch (error) {
    return []
  }
}

const fetchContacts = async () => {
  return (await AsyncStorage.getItem('RecentContactsKey')) as string
}

export const useGetRecentContacts = () => {
  const query = useQuery({
    queryKey: recentContactsQueryKeys.getRecentContacts(),
    queryFn: fetchContacts,
    select: defaultSelector
  })

  const recentContacts = query.data
  const destinations = recentContacts
    ?.filter(c => c?.isDummy)
    .map(contact => validateDestination(contact.name))

  useGetMultiAlgoliaContacts(destinations)

  return query
}

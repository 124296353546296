import React, { useCallback, useLayoutEffect, useState } from 'react'
import { useMeasure } from '@reactivers/use-measure'
import { ViewMode } from '@vatom/sdk/core'
import { View } from 'native-base'

import { getDimensions } from './WebView.utils'

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace WebView {
  export interface Props {
    displayUrl: string
    onMessage: (msg: Event) => void
    viewMode?: ViewMode
    style?: any
    wrapperRef: React.MutableRefObject<HTMLDivElement | undefined>
  }
}

export const WebView = React.forwardRef<HTMLIFrameElement, WebView.Props>(
  ({ onMessage, displayUrl, viewMode, wrapperRef }, forwardedRef) => {
    const [dimensions, setDimensions] = useState({
      width: 0,
      height: 0
    })

    const setMeasureDimensions = useCallback(
      (measure: Measure) => {
        const newDimensions = getDimensions(measure.width, measure.height)
        const width = Math.floor(newDimensions.width)
        const height = Math.floor(newDimensions.height)
        if (dimensions.width !== width || dimensions.height !== height) {
          setDimensions({ width, height })
        }
      },
      [dimensions.height, dimensions.width]
    )

    useMeasure({
      ref: wrapperRef,
      updateOnWindowResize: true,
      onResize: setMeasureDimensions
    })

    useLayoutEffect(() => {
      window.addEventListener('message', onMessage)

      return () => {
        window.removeEventListener('message', onMessage)
      }
    }, [onMessage])

    const defaultStyles = {
      width: dimensions.width > 0 ? dimensions.width : '100%',
      height: dimensions.height > 0 ? dimensions.height : '100%'
    }

    return (
      // @ts-ignore expected error for web styles
      <View
        _web={{
          ...defaultStyles,
          display: 'block'
        }}
      >
        <iframe
          title="web-face-iframe"
          id="web-face-iframe"
          ref={forwardedRef}
          src={displayUrl}
          width="100%"
          height="100%"
          style={{
            border: 0
          }}
        />
      </View>
    )
  }
)

// NOTE: This interface is not being exported by useMeasure
interface Measure {
  left: number
  top: number
  width: number
  bottom: number
  right: number
  x: number
  y: number
  height: number
  offsetLeft: number
  offsetTop: number
}

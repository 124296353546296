import React from 'react'
import { useGetSpace } from '@vatom/sdk/react'

import ImageGallery from './chat-components/ImageGallery/ImageGallery'
import LinkPreviews from './chat-components/LinkPreviews'
import PollChoices from './chat-components/PollChoices'
import Questions from './chat-components/Questions/Questions'
import Score from './chat-components/Score'
import Sketches from './chat-components/Sketches/Sketches'
import TextParagraph from './chat-components/TextParagraph'
import Title from './chat-components/Title'
import { useMessageContext } from './MessageContext'

type ContentProps = {
  isThread: boolean
  facadeElements: any
  messageContentElements: any
  additionalInfo?: any
  spaceId: string
}

const ElementType = {
  text: 'text',
  title: 'title',
  media: 'media',
  choices: 'choices',
  score: 'score',
  questions: 'questions',
  sketches: 'sketches',
  block: 'block'
} as const

const Content = (props: ContentProps) => {
  const { isThread, facadeElements, messageContentElements, additionalInfo, spaceId } = props
  const space = useGetSpace(spaceId, {
    enabled: false
  })
  const roomId = space.data?.matrixRoomId ?? ''
  const { messageEventID } = useMessageContext()

  if (!('elements' in facadeElements)) {
    return null
  }

  const hideResults = additionalInfo?.hideResults || false
  const pollLength = additionalInfo?.pollLength

  const findElement = (elementType: keyof typeof ElementType) =>
    messageContentElements.find((elem: any) => elem.type === elementType)

  return (
    <>
      {facadeElements.elements.map((facadeElementsChild: any, index: number) => {
        const elementType = findElement(facadeElementsChild.type)
        switch (facadeElementsChild.type) {
          case 'text':
            if (!elementType) return null
            return (
              <>
                <TextParagraph
                  key={`content-text-${messageEventID}-${index}`}
                  text={elementType.content}
                  align={facadeElementsChild.align}
                  isThread={isThread}
                  spaceId={spaceId}
                />
                <LinkPreviews roomId={roomId} />
              </>
            )
          case 'title':
            if (!elementType) return null
            return (
              <Title key={`content-title-${messageEventID}-${index}`} text={elementType.content} />
            )
          case 'media':
            return <ImageGallery key={`content-media-${messageEventID}-${index}`} roomId={roomId} />
          case 'choices':
            return additionalInfo.scoreStyle === 'single-choice-poll' || elementType ? (
              <PollChoices
                key={`content-poll-${messageEventID}-${index}`}
                choices={elementType}
                roomId={roomId}
                hideResults={hideResults}
                pollLength={pollLength}
              />
            ) : null
          case 'score':
            return additionalInfo.scoreStyle &&
              additionalInfo.scoreStyle !== 'single-choice-poll' ? (
              <Score
                key={`content-score-${messageEventID}-${index}`}
                additionalInfo={additionalInfo}
                roomId={roomId}
              />
            ) : null
          case 'questions':
            return (
              <Questions
                key={`content-questions-${messageEventID}-${index}`}
                questions={elementType}
                additionalInfo={additionalInfo}
                isThread={isThread}
                spaceId={spaceId}
              />
            )
          case 'sketches':
            return (
              <Sketches
                key={`content-sketches-${messageEventID}-${index}`}
                sketches={elementType}
                additionalInfo={additionalInfo}
                spaceId={spaceId}
                isThread={isThread}
              />
            )
          case 'block':
            return (
              <Content
                key={`content-block-${messageEventID}-${index}`}
                facadeElements={facadeElementsChild}
                messageContentElements={messageContentElements}
                isThread={isThread}
                spaceId={spaceId}
              />
            )
          default:
            return null
        }
      })}
      {!facadeElements.elements.some(({ type }: { type: string }) => type === 'media') ? (
        <ImageGallery key={`image-gallery-component-${messageEventID}`} roomId={roomId} />
      ) : null}
    </>
  )
}

export default React.memo(Content)

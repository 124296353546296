import React, { useState } from 'react'
import { Box, HStack, ISwitchProps, Switch as NbSwitch, useColorModeValue } from 'native-base'

import Text from '../Text'
import theme from '../theme'

export interface SwitchProps extends ISwitchProps {
  label?: string
  labelAlign?: 'left' | 'right'
}

export const Switch = ({ label, labelAlign, ...props }: SwitchProps) => {
  const [toggle, setToggle] = useState(true)

  const handleOnToggle = () => {
    setToggle(!toggle)
  }

  return (
    <Box alignItems="flex-start">
      <HStack
        alignItems="center"
        space={4}
        w="full"
        justifyContent="space-between"
        flexDirection={labelAlign === 'right' ? 'row-reverse' : 'row'}
      >
        {label && <Text>{label}</Text>}
        <SwitchBase onToggle={handleOnToggle} value={toggle} {...props} />
      </HStack>
    </Box>
  )
}

export const SwitchBase = (props: React.ComponentProps<typeof NbSwitch>) => {
  const colors = useColorModeValue(
    {
      onTrackColor: theme.colors.systemColorsLight.orange,
      offTrackColor: theme.colors.grayCool[400],
      onThumbColor: theme.colors.white,
      offThumbColor: theme.colors.white
    },
    {
      onTrackColor: theme.colors.systemColorsDark.orange,
      offTrackColor: theme.colors.grayDarkMode[500],
      onThumbColor: '#ECECED',
      offThumbColor: '#ECECED'
    }
  )

  return (
    <NbSwitch
      onTrackColor={colors.onTrackColor}
      offTrackColor={colors.offTrackColor}
      onThumbColor={colors.onThumbColor}
      offThumbColor={colors.offThumbColor}
      {...props}
    />
  )
}

import React from 'react'
import { ImageURISource } from 'react-native'
import { Image as ExpoImage, ImageProps } from 'expo-image'

interface NativeImageProps extends Partial<ImageProps> {}

// const resizeModeMap: Record<ImageResizeMode, FastImageProps['resizeMode']> = {
//   center: FastImage.resizeMode.center,
//   contain: FastImage.resizeMode.contain,
//   cover: FastImage.resizeMode.cover,
//   stretch: FastImage.resizeMode.stretch,
//   repeat: FastImage.resizeMode.center
// }

export const Image = (props: NativeImageProps) => {
  const { source, style } = props

  if (!source) return null

  const { uri, headers } = source as ImageURISource

  if (!uri) return null

  return (
    <ExpoImage
      source={{ uri, headers }}
      style={[{ width: '100%', height: '100%' }, style]}
      {...props}
      // resizeMode={resizeMode}
      // style={{ width, height }}
      // resizeMode={resizeMode}
    />
  )
}

import { useCallback, useEffect, useRef, useState } from 'react'
import { usePreventScroll } from 'react-aria'
import { Platform } from 'react-native'
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'
import { theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, KeyboardAvoidingView, useColorModeValue, VStack } from 'native-base'

import { CameraScannerModalUser } from '../../components/CameraScannerModal'
import { ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import { withBusinessSelector } from '../../hooks/useBusinessSelector'
import { AppRoutes, AppStackScreenProps } from '../../navigators'
import { getTitle } from '../ActionSheets/partials/ActionSheetHeader'
import SecondaryText from '../ActionSheets/partials/SecondaryText'
import { useToken } from '../NFTDetail/useNftDetail/useToken'

import RecentList from './SendProvider/RecentList'
import ResultsList from './SendProvider/ResultsList'
import Search from './SendProvider/Search'
import SenderProvider, { useSender } from './SendProvider/SendProvider'

export type SendNFTProps = AppStackScreenProps<
  typeof AppRoutes.SendNFT | typeof AppRoutes.SendNFT_Business
>
type ExtraProps = {
  isShare?: boolean
}

export const SendNFT = withBusinessSelector(
  observer((props: SendNFTProps & ExtraProps) => {
    const isShare = props?.isShare ?? false
    const { contractAddress, owner, tokenId, business } = props.route.params
    const { token, isLoading } = useToken({
      tokenId,
      owner,
      contractAddress
    })

    usePreventScroll({})

    const headerIconColor = useColorModeValue(
      theme.colors.textLightMode[900],
      theme.colors.textDarkMode[100]
    )
    const backgroundColor = useColorModeValue(theme.colors.white, theme.colors.grayDarkMode[900])

    useEffect(() => {
      if (!token && !isLoading) {
        console.log('LOG: [SendNFT.render] no token')
        props.navigation.navigate(AppRoutes.home)
      }
    }, [token, isLoading, props.navigation])

    const onClose = useCallback(() => {
      if (props.navigation.canGoBack()) {
        props.navigation.goBack()
      }
      if (business) {
        props.navigation.navigate(AppRoutes.NFTDetail_Business, {
          tokenId: token.id,
          business,
          contractAddress,
          owner
        })
      }
      props.navigation.navigate(AppRoutes.NFTDetail, {
        tokenId: token.id,
        contractAddress,
        owner
      })
    }, [props.navigation, business, token?.id, contractAddress, owner])

    return (
      <ScreenWrapper
        screenBackgroundColor={backgroundColor}
        statusBarBackgroundColor={backgroundColor}
      >
        <ScreenHeader
          headerLeftType={'close'}
          headerLeftPress={onClose}
          headerTextColor={headerIconColor}
          headerProps={{
            overflow: 'hidden'
          }}
          centerContainerProps={{
            flex: 1,
            paddingX: 2
          }}
          leftContainerProps={{
            flex: 0,
            _web: {
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto'
            }
          }}
          rightContainerProps={{
            flex: 0,
            _web: {
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto'
            }
          }}
          headerRight={() => <ScreenHeaderButton />}
        >
          <Box flexDirection={'column'} marginLeft={2} flex={1} justifyContent={'center'}>
            <Title preset="h5" variant="semibold" numberOfLines={1}>
              {token && getTitle(token)}
            </Title>
          </Box>
        </ScreenHeader>
        <VStack
          paddingX={4}
          _web={{
            height: '100%'
          }}
        >
          {/* CONTENT */}
          {token && (
            <SenderProvider
              token={token}
              isShare={isShare}
              contractAddress={contractAddress}
              owner={owner}
            >
              <Content />
            </SenderProvider>
          )}
        </VStack>
      </ScreenWrapper>
    )
  })
)

const AnimatedBox = Animated.createAnimatedComponent(Box)

function Content() {
  const sender = useSender()
  const [inputOnFocus, setInputOnFocus] = useState(false)
  const inputDidFocus = useRef(false)

  const handleInputFocus = useCallback((focus: boolean) => {
    if (focus) {
      inputDidFocus.current = true
    }
    setInputOnFocus(() => focus)
  }, [])

  const hideContent = !inputOnFocus && sender.searchString === '' && !inputDidFocus.current
  return (
    <>
      {hideContent && (
        <Box marginTop={3} w="100%">
          <SecondaryText hasLearnMore={true} token={sender.token} isShare={sender.isShare} />
        </Box>
      )}

      {hideContent && (
        <AnimatedBox
          flexGrow={1}
          _web={{
            flexGrow: 0
          }}
          marginBottom={5}
          entering={FadeIn.duration(200)}
          exiting={FadeOut.duration(200)}
        >
          <RecentList />
        </AnimatedBox>
      )}
      <KeyboardAvoidingView
        style={{
          height: '100%'
        }}
        contentContainerStyle={{
          flexGrow: 1
        }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        <Search onFocus={() => handleInputFocus(true)} onBlur={() => handleInputFocus(false)} />

        {!hideContent && (
          <AnimatedBox
            entering={FadeIn.duration(200)}
            exiting={FadeOut.duration(200)}
            style={{
              flexGrow: 1
            }}
            _web={{
              height: '100%'
            }}
          >
            <ResultsList />
          </AnimatedBox>
        )}
      </KeyboardAvoidingView>
      <CameraScannerModalUser
        isOpen={sender.isScannerOpen}
        onDismiss={() => sender.setIsScannerOpen(false)}
        onSuccess={sender.onScann}
      />
    </>
  )
}

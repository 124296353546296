import React, { useState } from 'react'
import { TouchableOpacity, TouchableWithoutFeedback } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Image } from 'expo-image'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { useCommunitiesTheme } from '../../../../themes'

type ImageComponentProps = {
  image: string
  imageStyle?: object
  uri: string
  handleSelectImage: (uri: string) => void
}

type ImageStore = {
  failedImages: string[]
  addFailedImage: (uri: string) => void
}

// store to keep track of the images that previously failed
const useFailedImagesStore = create(
  persist<ImageStore>(
    set => ({
      failedImages: [],
      addFailedImage: (uri: string) =>
        set(state => ({ failedImages: [...state.failedImages, uri] }))
    }),
    {
      name: 'failedImages',
      getStorage: () => AsyncStorage
    }
  )
)

const ImageComponent = (props: ImageComponentProps) => {
  const { image, imageStyle, handleSelectImage, uri } = props
  const communitiesTheme = useCommunitiesTheme()
  const { postHiddenTag } = communitiesTheme
  const showError = useFailedImagesStore(state => state.failedImages).includes(image)

  return (
    <TouchableOpacity
      accessibilityRole="button"
      style={{
        height: 180,
        width: '100%',
        borderRadius: 8,
        overflow: 'hidden',
        ...imageStyle
      }}
      onPress={() => handleSelectImage(uri)}
    >
      <Image
        onError={error => {
          console.error(error)
          useFailedImagesStore.getState().addFailedImage(image)
        }}
        accessibilityIgnoresInvertColors
        source={showError ? require('../../../../assets/no-image.png') : image}
        // placeholder={)}
        style={{ width: '100%', height: '100%', backgroundColor: postHiddenTag.background }}
        placeholderContentFit="cover"
      />
    </TouchableOpacity>
  )
}

export default ImageComponent

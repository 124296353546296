import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { PressableOpacity } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'

import { useBusinessPoints } from '../../hooks/useBusinessPoints'
import { useBusinessSelector } from '../../hooks/useBusinessSelector'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../hooks/useIsDesktop'
import { useLoyaltyBusiness } from '../../hooks/useLoyalty'
import { AppNavigation, AppRoutes } from '../../navigators'

import { BusinessCoins } from './BusinessCoins'
import { CampaignPoints } from './CampaignPoints'

const BusinessCurrency = observer(({ businessId }: { businessId?: string }) => {
  const navigation = useNavigation<AppNavigation>()
  const { business } = useBusinessSelector()
  const { pageTheme } = useBusinessTheme()
  const isDesktop = useIsDesktop()
  const { data: businessCoins, isLoading: businessCoinsLoading } = useLoyaltyBusiness({
    businessId: businessId ?? (business?.id || '')
  })
  const { points } = useBusinessPoints()
  const pointsHeader = business?.pageConfig?.features?.pointsHeader
  const pageConfigTheme = business?.pageConfig?.theme
  const coinsHeader = business?.pageConfig?.features?.coinsHeader

  if ((!pointsHeader?.enabled && !coinsHeader?.enabled) || businessCoinsLoading) {
    return null
  }

  // const styles = {
  //   color: isDesktop
  //     ? '#111'
  //     : brandTheme?.primaryText ?? pageConfigTheme?.pointsHeader?.color ?? pageTheme.text
  // }
  const styles = {
    color: isDesktop ? '#111' : pageConfigTheme?.pointsHeader?.color ?? pageTheme.text
  }

  const renderPoints = () =>
    points && (
      <CampaignPoints
        points={points}
        position={pointsHeader?.position}
        title={pointsHeader?.title}
        channel={pointsHeader?.channel}
        color={styles.color}
      />
    )

  const renderCoins = () =>
    businessCoins?.points ? (
      <PressableOpacity
        onPress={() => {
          navigation.navigate(AppRoutes.CoinDetail_Business, {
            business: businessId as string,
            coinId: businessCoins.id
          })
        }}
      >
        <BusinessCoins
          isLoading={businessCoinsLoading}
          coins={businessCoins.points}
          name={businessCoins.plural_name}
          image={businessCoins?.logo}
          color={styles.color}
          containerProps={{
            borderColor: styles.color
          }}
        />
      </PressableOpacity>
    ) : null

  if (coinsHeader?.enabled) {
    return renderCoins()
  } else if (pointsHeader?.enabled) {
    return renderPoints()
  } else {
    return null
  }
})

export { BusinessCurrency }

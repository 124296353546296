import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useGetSpace } from '@vatom/sdk/react'

import { useBusinessSelector } from '../../../../../../hooks/useBusinessSelector'
import { AppNavigation, navigateToConnectScreen, RoomRoutes } from '../../../../../../navigators'
import { useCommunitiesTheme } from '../../../../themes'
import { QuestionType } from '../../../../types'
import { useCommunitySpace } from '../../../../useCommunitySpace'
import { useMessageContext } from '../../MessageContext'
import EndsInNote from '../EndsInNote'

import AnsweredNumber from './AnsweredNumber'
import Question from './Question'

type QuestionsProps = {
  questions: {
    content: QuestionType[]
  }
  isThread: boolean
  additionalInfo: any
  spaceId: string
}

const Questions = (props: QuestionsProps) => {
  const { business } = useBusinessSelector()
  const businessId = business?.id ?? ''
  const { messageEventID } = useMessageContext()
  const { community } = useCommunitySpace()
  const { questions, isThread, additionalInfo, spaceId } = props
  const space = useGetSpace(spaceId, {
    enabled: false
  })
  const roomId = space.data?.matrixRoomId ?? ''
  const { startTime, endTime } = additionalInfo
  const navigation = useNavigation<AppNavigation>()

  const questionsLength = questions?.content?.length ?? 0

  const communitiesTheme = useCommunitiesTheme()

  return (
    <View style={{ marginTop: 12, marginBottom: 16 }}>
      {!isThread ? (
        <TouchableOpacity
          accessibilityRole="button"
          style={[
            styles.showQuestionsButton,
            { backgroundColor: communitiesTheme.sketches.backgroundColor }
          ]}
          onPress={() =>
            navigateToConnectScreen(navigation, RoomRoutes.RoomThread, {
              spaceId,
              business: businessId,
              community,
              messageId: messageEventID
            })
          }
        >
          <Text
            key="question-number"
            style={[styles.questionsNumber, { color: communitiesTheme.sketches.titleColor }]}
          >
            {questionsLength} {questionsLength === 1 ? 'Score' : 'Scores'}
          </Text>
          {questionsLength > 1 ? (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <AnsweredNumber roomID={roomId} eventID={messageEventID} questions={questions} />
            </View>
          ) : null}
        </TouchableOpacity>
      ) : (
        <View style={styles.detailsContainer}>
          <Text
            key="question-number"
            style={[styles.questionsNumber, { color: communitiesTheme.sketches.titleColor }]}
          >
            {questionsLength} {questionsLength === 1 ? 'Score' : 'Scores'}
          </Text>
          {questionsLength > 1 ? (
            <AnsweredNumber
              roomID={roomId}
              eventID={messageEventID}
              questions={questions}
              isThread
            />
          ) : null}
        </View>
      )}

      {isThread
        ? questions?.content?.map((question, index) => (
            <Question
              key={question.id}
              question={question}
              roomId={roomId}
              additionalInfo={additionalInfo}
              spaceId={spaceId}
            />
          ))
        : null}

      <EndsInNote startTime={startTime} endTime={endTime} beforeText="Ends in" />
    </View>
  )
}

export default Questions

const styles = StyleSheet.create({
  detailsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  questionsNumber: {
    fontSize: 15,
    lineHeight: 20,
    fontWeight: '600',
    color: '#3F4A55'
  },
  numberAnswered: {
    fontSize: 11,
    lineHeight: 15,
    color: '#868E96'
  },
  showQuestionsButton: {
    padding: 16,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { PricePointModel, PricePointModelType } from "./PricePointModel"
import { PricePointModelSelector } from "./PricePointModel.base"
import { RootStoreType } from "./index"


/**
 * PriceChartBase
 * auto generated base class for the model PriceChartModel.
 */
export const PriceChartModelBase = ModelBase
  .named('PriceChart')
  .props({
    __typename: types.optional(types.literal("PriceChart"), "PriceChart"),
    listingFloor: types.union(types.undefined, types.array(types.late((): any => PricePointModel))),
    salesAverage: types.union(types.undefined, types.array(types.late((): any => PricePointModel))),
    totalVolume: types.union(types.undefined, types.array(types.late((): any => PricePointModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PriceChartModelSelector extends QueryBuilder {
  listingFloor(builder: string | PricePointModelSelector | ((selector: PricePointModelSelector) => PricePointModelSelector) | undefined) { return this.__child(`listingFloor`, PricePointModelSelector, builder) }
  salesAverage(builder: string | PricePointModelSelector | ((selector: PricePointModelSelector) => PricePointModelSelector) | undefined) { return this.__child(`salesAverage`, PricePointModelSelector, builder) }
  totalVolume(builder: string | PricePointModelSelector | ((selector: PricePointModelSelector) => PricePointModelSelector) | undefined) { return this.__child(`totalVolume`, PricePointModelSelector, builder) }
}
export function selectFromPriceChart() {
  return new PriceChartModelSelector()
}

export const priceChartModelPrimitives = selectFromPriceChart()

import React from 'react'
import { Modal, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native'
import axios from 'axios'
import * as VideoThumbnails from 'expo-video-thumbnails'
import { observer } from 'mobx-react-lite'
import { IMSTArray } from 'mobx-state-tree'

import {
  extractLoomID,
  extractVimeoID,
  extractYoutubeID,
  isLoomLink,
  isM3U8Link,
  isVimeoLink,
  isYouTubeLink
} from '../../../helpers'
import { useCommunitiesTheme } from '../../../themes'
import { useStoreContext } from '../../MSTContextProvider'
import { Media } from '../stores'

import ModalWrapper from './ModalWrapper'

type ModalAddVideoProps = {
  showVideoModal: boolean
  setShowVideoModal: (arg0: boolean) => void
}

const ModalAddVideo = observer((props: ModalAddVideoProps) => {
  const { showVideoModal, setShowVideoModal } = props
  const store = useStoreContext()
  const [link, setLink] = React.useState('')
  const [error, setError] = React.useState(null)
  const communitiesTheme = useCommunitiesTheme()

  const addVideoLink = async () => {
    const data = await generateThumbnail()
    if (!data) {
      return
    }
    store?.setMedia(data)
    setLink('')
    setShowVideoModal(false)
  }

  const generateThumbnail = async () => {
    if (isYouTubeLink(link)) {
      const videoId = extractYoutubeID(link)

      return [
        {
          type: 'video-stream',
          uri: link,
          thumbnail: `https://img.youtube.com/vi/${videoId}/0.jpg`
        }
      ] as IMSTArray<typeof Media>
    }

    if (isVimeoLink(link)) {
      const videoId = extractVimeoID(link)
      try {
        const videoDetails = await axios
          .get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`)
          .then(({ data }) => data)

        return [
          {
            type: 'video-stream',
            uri: link,
            thumbnail: videoDetails.thumbnail_url
          }
        ] as IMSTArray<typeof Media>
      } catch (e) {
        return null
      }
    }

    if (isLoomLink(link)) {
      const videoId = extractLoomID(link)
      return [
        {
          type: 'video-stream',
          uri: link,
          thumbnail: `https://cdn.loom.com/sessions/thumbnails/${videoId}-00001.jpg`
        }
      ] as IMSTArray<typeof Media>
    }

    if (isM3U8Link(link)) {
      return [
        {
          type: 'video-stream',
          uri: link,
          thumbnail: ''
        }
      ] as IMSTArray<typeof Media>
    }

    try {
      const { uri } = await VideoThumbnails.getThumbnailAsync(link, {
        time: 15000
      })

      return [
        {
          type: 'video-stream',
          uri: link,
          thumbnail: uri
        }
      ] as IMSTArray<typeof Media>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setError(e.toString())
      return null
    }
  }

  const cancel = () => {
    setLink('')
    setShowVideoModal(false)
  }

  return (
    <Modal visible={showVideoModal} animationType="fade" transparent={true}>
      <ModalWrapper>
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <TouchableOpacity
            accessibilityRole="button"
            style={styles.greyBackground}
            onPress={() => setShowVideoModal(false)}
          />

          <View style={styles.errorHolder}>
            <Text style={styles.title}>Enter Video URL</Text>
            <Text style={styles.description}>
              {error ?? 'Video URL can be a link to a YouTube or a Vimeo or an HLS video.'}
            </Text>
            <TextInput
              accessibilityLabel="Text input field"
              accessibilityHint="Text input"
              placeholder="Video Link"
              value={link}
              onChangeText={setLink}
              contextMenuHidden={false}
              style={styles.input}
              placeholderTextColor={'#CED4DA'}
            />
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <TouchableOpacity
                accessibilityRole="button"
                style={{
                  ...styles.button,
                  ...styles.withBorder,
                  marginRight: 8
                }}
                onPress={() => cancel()}
                key="cancel"
              >
                <Text style={{ ...styles.buttonText, color: '#3F4A55' }}>Cancel</Text>
              </TouchableOpacity>
              <TouchableOpacity
                accessibilityRole="button"
                style={{
                  ...styles.button,
                  backgroundColor: communitiesTheme.accentColor,
                  marginLeft: 8
                }}
                onPress={() => (link ? addVideoLink() : null)}
                key="add"
              >
                <Text style={{ ...styles.buttonText, color: communitiesTheme.accentColorText }}>
                  Add
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </ModalWrapper>
    </Modal>
  )
})

export default ModalAddVideo

const styles = StyleSheet.create({
  greyBackground: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: `rgba(0, 0, 0, 0.5)`,
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  errorHolder: {
    backgroundColor: 'white',
    paddingHorizontal: 16,
    paddingVertical: 32,
    maxWidth: 270,
    width: '100%',
    borderRadius: 8
  },
  title: {
    fontSize: 17,
    lineHeight: 24,
    fontWeight: '700',
    textAlign: 'center'
  },
  description: {
    fontSize: 13,
    lineHeight: 18,
    color: '#3F4A55',
    textAlign: 'center',
    marginTop: 8
  },
  button: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 3,
    marginTop: 16,
    flex: 1
  },
  withBorder: {
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: '#CED4DA'
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 11,
    fontWeight: '500',
    lineHeight: 15
  },
  input: {
    marginTop: 10,
    color: '#3F4A55',
    paddingHorizontal: 12,
    paddingVertical: 10,
    borderWidth: 1,
    borderColor: '#DEE2E6',
    borderRadius: 4,
    fontSize: 15,
    lineHeight: 20
  }
})

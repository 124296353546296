import { z } from 'zod'

const StateSchema = z.object({
  'studio-info-v1': z
    .object({
      businessId: z.string(),
      campaignId: z.string(),
      objectDefinitionId: z.string()
    })
    .optional(),
  'map-ready-v1': z
    .object({
      location: z.object({
        lat: z.number(),
        lon: z.number()
      }),
      businessId: z.string().optional(),
      campaignId: z.string().optional(),
      objectDefinitionId: z.string().optional()
    })
    .optional(),
  'meta-v1': z
    .object({
      displayName: z.string(),
      description: z.string(),
      category: z.string().nullable().optional(),
      isDraft: z.boolean()
    })
    .optional(),
  'map-ready-poi-business-v1': z
    .object({
      hoursText: z.string().optional()
    })
    .optional(),
  'pepsi-store-prizes-v1': z
    .object({
      numPrizesAvailable: z.number().optional()
    })
    .optional(),
  'gft-reward-v1': z
    .object({
      gftCampaignId: z.string().optional(),
      gftEnvironment: z.string().optional(),
      couponId: z.string().optional(),
      status: z.string().optional()
    })
    .optional()
})

const ViewConfigImageInnerSchema = z.object({
  type: z.string(),
  ref: z.string()
})

const ViewConfigImageSchema = z.object({
  image: ViewConfigImageInnerSchema
})

const ViewConfigStatesSchema = z.object({
  states: z.array(
    z.object({
      image: ViewConfigImageInnerSchema,
      name: z.string(),
      rule: z.object({})
    })
  )
})

const ImageV1Schema = z.object({
  id: z.literal('image-v1'),
  config: ViewConfigImageSchema
})

const DynamicImageV1Schema = z.object({
  id: z.literal('dynamic-image-v1'),
  config: ViewConfigStatesSchema
})

const CardView1Schema = z.object({
  id: z.string(),
  entrypoint: z.string(),
  config: z.any()
})

const ViewPlacementModelSchema = z.union([CardView1Schema, ImageV1Schema, DynamicImageV1Schema])

const ViewPlacementSchema = z.object({
  placeholder: z.object({
    id: z.string()
  }),
  view: ViewPlacementModelSchema
})

export const VatomModelSchema = z.object({
  id: z.string(),
  type: z.string(),
  parentId: z.string(),
  owner: z.string(),
  author: z.string(),
  lastOwner: z.string().optional(),
  state: StateSchema,
  viewPlacements: z.array(ViewPlacementSchema),
  studioInfo: z
    .object({
      businessId: z.string(),
      campaignId: z.string(),
      objectDefinitionId: z.string()
    })
    .optional()
})

export type VatomModelType = z.infer<typeof VatomModelSchema>
export type VatomModelInput = z.input<typeof VatomModelSchema>

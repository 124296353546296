import React from 'react'
import { SafeAreaView as RnSafeAreaView } from 'react-native'
import { Factory } from 'native-base'

export const SafeAreaView = (props: any) => {
  // @ts-expect-error not sure how to fix this
  const FactorySafeAreaView = Factory(RnSafeAreaView)
  return <FactorySafeAreaView {...props} />
}

export default SafeAreaView

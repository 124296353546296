import {
  businessQueryKeys,
  fetchBusiness,
  fetchUserProfile,
  publicProfileQueryKeys,
  SDKQueryClient
} from '@vatom/sdk/react'

import { ItemPoints, pointsOperationTypes, transactionTypes } from '../partials/TransactionItem'

export const convertToPointsItem = async (
  transaction: any,
  {
    userId
  }: {
    userId: string
  }
): Promise<ItemPoints> => {
  const { id, creditType, debitType, debit, credit, detail, createdAt, link, points } = transaction
  const isFromBusiness = creditType === 'user' && debitType === 'business'
  const isFromUser = creditType === 'user' && debitType === 'user'
  const isFromMarket = creditType === 'business' && debitType === 'user'

  let title = ''
  let image = ''
  if (isFromBusiness) {
    const businessId = debit
    const business = await SDKQueryClient.ensureQueryData({
      queryKey: businessQueryKeys.getBusiness(businessId ?? '', true),
      queryFn: fetchBusiness
    })
    if (business) {
      title = business.displayName
      image = business.logoSrc
    }
  }

  if (isFromUser) {
    const currentUserId = credit === userId ? debit : credit
    const user = await SDKQueryClient.ensureQueryData({
      queryKey: publicProfileQueryKeys.getPublicProfile(currentUserId),
      queryFn: fetchUserProfile
    })

    title = user?.name ?? 'From user'
    image = user?.picture ?? ''
  }

  if (isFromMarket && link) {
    const marketLinkData = await fetch(link).then(res => res.text())
    if (marketLinkData) {
      const marketImage = marketLinkData
        .split('<meta property="og:image" content="')[1]
        .split('"')[0]
      const marketDescription = marketLinkData
        .split('<meta property="og:description" content="')[1]
        .split('"')[0]

      title = marketDescription
      image = marketImage
    }
  }

  const isBurn = credit === 'burn'
  const isDebit = Math.sign(points) === -1
  const isGiftIn = (isFromUser && credit === userId) || (isFromBusiness && credit === userId)
  const isGiftOut = isFromUser && debit === userId
  const isRedeem = isBurn || isDebit || isFromMarket
  const isReward = !isRedeem && !isGiftIn && !isGiftOut

  const operationType = isReward
    ? pointsOperationTypes.reward
    : isRedeem
    ? pointsOperationTypes.redeem
    : isGiftIn
    ? pointsOperationTypes.giftIn
    : pointsOperationTypes.giftOut

  return {
    id,
    type: transactionTypes.points,
    title,
    description: detail,
    image,
    amount: Math.abs(points).toString(),
    timeAgo: createdAt,
    operationType
  }
}

/* eslint-disable unicorn/filename-case */
import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useGetSpace, useMatrixUser } from '@vatom/sdk/react'
import { Box } from 'native-base'

import { useBusinessSelector } from '../../../hooks/useBusinessSelector'
import { AppNavigation, navigateToConnectScreen, RoomRoutes } from '../../../navigators'
import PlusIcon from '../assets/plus-icon.svg'
import { useIsMember } from '../hooks/useIsMember'
import { usePlugins, useRoomPowerLevels } from '../queries'
import { useCommunitiesTheme } from '../themes'
import { useCommunitySpace } from '../useCommunitySpace'

import SelectMessageTypeModal from './ActionsModals/SelectMessageTypeModal'

const FABComponent = ({ spaceId }: { spaceId: string }) => {
  const { business } = useBusinessSelector()
  const businessId = business?.id ?? ''
  const space = useGetSpace(spaceId, {
    enabled: false
  })
  const roomId = space.data?.matrixRoomId ?? ''
  const navigation = useNavigation<AppNavigation>()
  const { data: matrixUser } = useMatrixUser()
  const { community } = useCommunitySpace()

  const isUserJoined = useIsMember({ roomId })

  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const communitiesTheme = useCommunitiesTheme()

  const { data: plugins } = usePlugins()
  const pluginData = plugins ? plugins[0] : null

  const powerLevels = useRoomPowerLevels(roomId, {
    enabled: Boolean(isUserJoined)
  })

  const currentUserPowerLevel = matrixUser?.user_id
    ? powerLevels.data?.users[matrixUser.user_id] ?? 0
    : 0

  const availableMessageTypes =
    pluginData?.descriptor.facades.message.filter((messageType: { id: string }) => {
      if (!powerLevels.data) {
        return false
      }
      const id = messageType.id as string
      // The power level reponse can miss some events, so we default to 0
      const powerRequired = powerLevels.data.events[id] ?? 0
      return currentUserPowerLevel >= powerRequired
    }) ?? []

  return isUserJoined && availableMessageTypes.length > 0 ? (
    <Box
      safeAreaBottom
      _web={{
        position: 'fixed'
      }}
      position={'absolute'}
      bottom={5}
      right={5}
    >
      <TouchableOpacity
        accessibilityRole="button"
        onPress={() =>
          availableMessageTypes.length === 1
            ? navigateToConnectScreen(navigation, RoomRoutes.RoomNewMessage, {
                messageType: availableMessageTypes[0].id as string,
                business: businessId,
                spaceId,
                community
              })
            : setIsModalVisible(true)
        }
      >
        <View style={{ ...styles.container, backgroundColor: communitiesTheme.accentColor }}>
          <PlusIcon fill={communitiesTheme.accentColorText} />
        </View>
      </TouchableOpacity>

      {isModalVisible ? (
        <SelectMessageTypeModal
          spaceId={spaceId}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      ) : null}
    </Box>
  ) : null
}

const styles = StyleSheet.create({
  container: {
    width: 60,
    height: 60,
    borderRadius: 60 / 2,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default FABComponent

/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { TwitterProfileModel, TwitterProfileModelType } from "./TwitterProfileModel"
import { TwitterProfileModelSelector } from "./TwitterProfileModel.base"
import { RootStoreType } from "./index"


/**
 * NftOwnerBase
 * auto generated base class for the model NftOwnerModel.
 */
export const NftOwnerModelBase = ModelBase
  .named('NftOwner')
  .props({
    __typename: types.optional(types.literal("NftOwner"), "NftOwner"),
    address: types.union(types.undefined, types.string),
    associatedTokenAccountAddress: types.union(types.undefined, types.string),
    twitterHandle: types.union(types.undefined, types.null, types.string),
    profile: types.union(types.undefined, types.null, types.late((): any => TwitterProfileModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class NftOwnerModelSelector extends QueryBuilder {
  get address() { return this.__attr(`address`) }
  get associatedTokenAccountAddress() { return this.__attr(`associatedTokenAccountAddress`) }
  get twitterHandle() { return this.__attr(`twitterHandle`) }
  profile(builder: string | TwitterProfileModelSelector | ((selector: TwitterProfileModelSelector) => TwitterProfileModelSelector) | undefined) { return this.__child(`profile`, TwitterProfileModelSelector, builder) }
}
export function selectFromNftOwner() {
  return new NftOwnerModelSelector()
}

export const nftOwnerModelPrimitives = selectFromNftOwner().address.associatedTokenAccountAddress.twitterHandle

import type { BottomTabScreenProps } from '@react-navigation/bottom-tabs'
import type {
  CompositeScreenProps,
  NavigationProp,
  NavigatorScreenParams,
  RouteProp
} from '@react-navigation/native'
import type { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Network } from 'alchemy-sdk'

import {
  AppRoutes,
  CommunitiesRoutes,
  RoomRoutes,
  SpacesRoutes,
  TabRoutes,
  WalletRoutes
} from './routes'

type SpacesParamList = {
  [SpacesRoutes.Spaces]: undefined
  [SpacesRoutes.People]: undefined
}

type WalletParamList = {
  [WalletRoutes.NFTs]: undefined
  [WalletRoutes.Coins]: undefined
}

export type CommunitiesParamList = {
  [CommunitiesRoutes.CommunitiesHome]:
    | undefined
    | {
        community: string
      }
  [CommunitiesRoutes.Communities]:
    | undefined
    | {
        spaceId?: string
        community: string
      }
}

// TabRoutes
export type MainTabParamList<TBusiness extends string | undefined = string> = {
  [TabRoutes.Home]: undefined
  [TabRoutes.Connect]: TBusiness extends undefined
    ? NavigatorScreenParams<SpacesParamList>
    : NavigatorScreenParams<CommunitiesParamList>
  [TabRoutes.Map]: undefined
  [TabRoutes.Wallet]: TBusiness extends string ? undefined : NavigatorScreenParams<WalletParamList>
  [TabRoutes.MapAr]:
    | undefined
    | {
        business: string
      }
  [TabRoutes.Coins]: undefined
  [TabRoutes.Space]: undefined
  [TabRoutes.People]: undefined
  [TabRoutes.DMs]:
    | {
        spaceHandle?: string
      }
    | undefined
}

type MainStackParamList<TBusiness extends string | undefined = string> = TBusiness extends undefined
  ? {
      context?: string
      community?: string
    }
  : {
      business?: TBusiness
      context?: string
      community?: string
    }

type MainStackParams<TBusiness extends string | undefined = string> =
  MainStackParamList<TBusiness> & Partial<NavigatorScreenParams<MainTabParamList<TBusiness>>>

export type CommunitiesScreenProps<
  TScreen extends keyof CommunitiesParamList,
  TBusiness extends string = string
> = CompositeScreenProps<
  NativeStackScreenProps<CommunitiesParamList, TScreen>,
  BottomTabScreenProps<MainTabParamList<TBusiness>, 'Connect'>
>

export type MainTabScreenProps<
  TScreen extends keyof MainTabParamList,
  TBusiness extends string = string
> = BottomTabScreenProps<MainTabParamList<TBusiness>, TScreen>

type RequiredFindTokenParams =
  | {
      objectDefinitionId: string
    }
  | {
      objectDefinitionIds: string[]
    }
  | {
      campaignId: string
    }

type OptionalFindTokenParams = {
  claimLink?: string
  autoClaim?: boolean
  sync?: boolean
}

type FindTokenParams = RequiredFindTokenParams & OptionalFindTokenParams

export type AppStackParamList = {
  [AppRoutes.profileOtherUser]: {
    userId?: string
    spaceId?: string
    memberId?: string
    showSharedSpaces?: boolean
    from?: string
  }
  [AppRoutes.FindToken]: FindTokenParams
  [AppRoutes.FindToken_Business]: FindTokenParams & { business: string }

  [AppRoutes.Claim]: {
    uid: string
    otp: string
    'iden-type': string
    'iden-value': string
    claimId: string
  }

  [AppRoutes.home]: MainStackParams<undefined> | undefined
  [AppRoutes.BusinessProxy]: MainStackParams<string>

  // sendNft: {
  //   tokenId: string
  //   userToSend?: Contact
  //   destination?: Destination
  //   isShare: boolean
  // }
  [AppRoutes.sendConfirmation]: {
    tokenId: string
    // @TODO: Contact creates a circular ref with the react sdk/mst
    userToSend?: any
    // @TODO: Destination creates a circular ref with the react sdk/mst
    destination?: any
    isShare: boolean
  }

  [AppRoutes.sendToChainConfirmation]: MainStackParams<undefined> | undefined
  // [AppRoutes.sendConfirmation]: {
  //   titleNFT: string
  //   imageNFT: ImageSourcePropType
  //   imageUser: ImageSourcePropType
  //   nameUser: string
  // }

  [AppRoutes.nftFilters]: undefined

  [AppRoutes.NFTDetail]: {
    tokenId: string
    business?: string
    openAR?: boolean
    contractAddress?: string
    owner?: string
  }
  [AppRoutes.NFTDetail_Business]: {
    tokenId: string
    business: string
    openAR?: boolean
    contractAddress?: string
    owner?: string
  }

  [AppRoutes.MintSuccess]: {
    network: string
    transactionId?: string
    address: string
  }
  [AppRoutes.BackupToCloud]: undefined
  [AppRoutes.RestoreFromCloud]: undefined
  [AppRoutes.DeleteFromCloud]: undefined
  [AppRoutes.ChangeBackupPassword]: {
    address: string
  }
  [AppRoutes.CloudBackupList]: undefined
  [AppRoutes.NewWalletStart]: undefined
  [AppRoutes.NewWalletBackup]:
    | undefined
    | {
        isBackupDisabled?: boolean
      }
  [AppRoutes.NewWalletConfirm]: undefined
  [AppRoutes.ImportWalletStart]: undefined
  [AppRoutes.ImportWalletRecover]:
    | undefined
    | {
        seeds?: string
      }
  [AppRoutes.WalletAddressManagement]: {
    address: string
  }
  [AppRoutes.ShowPrivateKey]: undefined
  [AppRoutes.EditAddressName]: {
    address: string
  }
  [AppRoutes.NFTSellDetail]: {
    tokenId: string
  }

  [AppRoutes.Acquire]: {
    id: string
    businessId?: string
    context: string
    login: string
    ar?: string
    msg?: string
    redirect?: string
    from: string
    name?: string
  }
  [AppRoutes.Acquire_Business]: {
    id: string
    businessId?: string
    context: string
    login: string
    ar?: string
    msg?: string
    redirect?: string
    from: string
    name?: string
  } // same as Acquire
  /**
   * The Space screen is able to launch either by the alias or the space object. And we can force audio only if needed
   */
  [AppRoutes.Metaverse]:
    | {
        spaceId: string
        spaceHandle?: undefined
        type?: 'call' | 'metaverse'
      }
    | {
        spaceId?: undefined
        spaceHandle: string
        type?: 'call' | 'metaverse'
      }

  [AppRoutes.TokenAR]: {
    tokenId: string
    msg?: string
  }

  [AppRoutes.AcquirePubVariation]: {
    msg?: string
    ar?: boolean
    tokenId: string
  }
  [AppRoutes.AcquirePubVariation1]: {
    msg?: string
    ar?: boolean
    tokenId: string
  }
  [AppRoutes.AcquirePubVariation2]: {
    msg?: string
    ar?: boolean
    tokenId: string
  }

  [AppRoutes.LoginCallback]: {
    code: string
  }

  [AppRoutes.coin]: {
    coinId: string
    screen?: string
  }
  [AppRoutes.CoinDetail_Business]: {
    coinId: string
    business?: string
  }
  [AppRoutes.businessConnect]: {
    business: string
  }
  // Communities: {
  //   business: string
  //   communityId: string
  // }
  [AppRoutes.connect]: undefined

  [AppRoutes.followList]: {
    userId: string
    type: 'followers' | 'following'
  }

  [AppRoutes.editProfile]: undefined
  [AppRoutes.manageAccounts]: undefined
  [AppRoutes.settingsMetamask]: undefined
  [AppRoutes.invitePeople]: undefined
  [AppRoutes.MapAR]:
    | undefined
    | {
        business: string
      }
  [AppRoutes.CameraScreen]: undefined
  [AppRoutes.profileUser]:
    | undefined
    | {
        business?: string
      }
  [AppRoutes.LogoutCallback]: undefined
  [AppRoutes.profileOtherUser_Business]: {
    business: string
    userId: string
    spaceId?: string
    memberId?: string
    showSharedSpaces?: boolean
  }

  [AppRoutes.Room]: {
    spaceId: string
    business: string
    roomTab?: string
  }
  [AppRoutes.RoomMembers]: {
    spaceId: string
    business: string
    memberId?: string
  }
  [AppRoutes.RoomNewMessage]: {
    messageType: string
    business: string
    spaceId: string
    messageId?: string
    method?: string
  }
  [AppRoutes.RoomThread]: {
    spaceId: string
    business: string
    messageId: string
  }
  [AppRoutes.RoomReply]: {
    spaceId: string
    business: string
    messageId: string
    method?: string
  }
  [AppRoutes.RoomQuestionList]: {
    spaceId: string
    business: string
  }
  [AppRoutes.RoomNewQuestion]: {
    spaceId: string
    business: string
  }
  [AppRoutes.RoomSketchList]: {
    spaceId: string
    business: string
  }
  [AppRoutes.RoomNewSketch]: {
    spaceId: string
    business: string
  }

  [AppRoutes.CommunitiesRoom]: {
    spaceId: string
    business: string
    roomTab?: string
    community: string
  }
  [AppRoutes.CommunitiesRoomMembers]: {
    spaceId: string
    business: string
    memberId?: string
    community: string
  }
  [AppRoutes.CommunitiesRoomNewMessage]: {
    messageType: string
    business: string
    community: string
    spaceId: string
    messageId?: string
    method?: string
  }
  [AppRoutes.CommunitiesRoomThread]: {
    spaceId: string
    business: string
    community: string
    messageId: string
  }
  [AppRoutes.CommunitiesRoomReply]: {
    spaceId: string
    business: string
    community: string
    messageId: string
    method?: string
  }
  [AppRoutes.CommunitiesRoomQuestionList]: {
    spaceId: string
    business: string
    community: string
  }
  [AppRoutes.CommunitiesRoomNewQuestion]: {
    spaceId: string
    business: string
    community: string
  }
  [AppRoutes.CommunitiesRoomSketchList]: { spaceId: string; business: string; community: string }
  [AppRoutes.CommunitiesRoomNewSketch]: { spaceId: string; business: string; community: string }
  // DMs
  [AppRoutes.DmScreen]: { roomId: string }
  // Profile Other Screens
  [AppRoutes.Settings]: undefined
  [AppRoutes.BlockChainWallets]: undefined
  [AppRoutes.BlockChainNetwork]: undefined
  [AppRoutes.BlockChainNetworkAdd]: undefined
  [AppRoutes.BlockChainNetworkEdit]: { networkId: string }
  // NFT Options Actions
  [AppRoutes.SendNFT]: {
    tokenId: string
    business?: string
    contractAddress?: string
    owner?: string
  }
  [AppRoutes.SendNFT_Business]: {
    tokenId: string
    business: string
    contractAddress?: string
    owner?: string
  }
  [AppRoutes.SendNFTShare]: Pick<AppStackParamList['SendNFT'], 'tokenId' | 'business'>
  [AppRoutes.SendNFTShare_Business]: Pick<
    AppStackParamList['SendNFT_Business'],
    'tokenId' | 'business'
  >
  [AppRoutes.DropNFT]: {
    tokenId: string
    business?: string
  }
  [AppRoutes.DropNFT_Business]: {
    tokenId: string
    business: string
  }
  [AppRoutes.SellNFT]: {
    tokenId: string
    business?: string
  }
  [AppRoutes.SellNFT_Business]: {
    tokenId: string
    business: string
  }
  [AppRoutes.MintNFT]: {
    tokenId: string
    business?: string
  }
  [AppRoutes.MintNFT_Business]: {
    tokenId: string
    business: string
  }

  [AppRoutes.FungibleTokenDetail]: BaseFungibleToken
  [AppRoutes.FungibleTokenTransfer]: BaseFungibleToken
  [AppRoutes.FungibleTokenRecipient]: FungibleTokenWithAmount
  [AppRoutes.FungibleTokenConfirmation]: FungibleTokenWithToUserId | FungibleTokenWithToAddress
  [AppRoutes.sendNFTConfirmation]:
    | SendNFTConfirmationWithToUserId
    | SendNFTConfirmationWithToAddress
}

type BaseSendNFTConfirmation = {
  tokenId: string
  contractAddress: string
  owner: string
}

type SendNFTConfirmationWithToUserId = BaseSendNFTConfirmation & {
  toUserId: string
  toAddress?: string
}

type SendNFTConfirmationWithToAddress = BaseSendNFTConfirmation & {
  toUserId?: string
  toAddress: string
}

type BaseFungibleToken = {
  address: string
  network: Network
  contractAddress?: string
}

type FungibleTokenWithAmount = BaseFungibleToken & {
  amount: number
}

type FungibleTokenWithToUserId = BaseFungibleToken & {
  amount: number
  toAddress?: string
  toUserId: string
}

type FungibleTokenWithToAddress = BaseFungibleToken & {
  amount: number
  toAddress: string
  toUserId?: string
}

export type AppNavigation = NavigationProp<AppStackParamList>
export type AppRouteProp<TRoute extends keyof AppStackParamList> = RouteProp<
  AppStackParamList,
  TRoute
>

export type AppStackScreenProps<T extends keyof AppStackParamList> = NativeStackScreenProps<
  AppStackParamList,
  T
>

const isCommunityNavigation = <T extends typeof RoomRoutes[keyof typeof RoomRoutes]>(
  route: T,
  params: AppStackParamList[T] | AppStackParamList[`Communities${T}`]
): params is AppStackParamList[`Communities${T}`] => {
  return !!('community' in params && params.community)
}

export const navigateToConnectScreen = <T extends typeof AppRoutes[keyof typeof RoomRoutes]>(
  navigation: AppNavigation,
  route: T,
  params: AppStackParamList[T] | AppStackParamList[`Communities${T}`]
) => {
  if (isCommunityNavigation(route, params)) {
    // @ts-expect-error
    navigation.navigate(`Communities${route}`, params)
  } else {
    // @ts-expect-error
    navigation.navigate(route, params)
  }
}

// INFO: https://reactnavigation.org/docs/typescript/#specifying-default-types-for-usenavigation-link-ref-etc
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    interface RootParamList extends AppStackParamList {}
  }
}

import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const PhoneIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 32 32" size={size} {...iconProps}>
    <G clipPath="url(#clip0_15474_14623)">
      <Path
        fill={fill}
        d="M25.7969 19.9727C26.6562 20.3594 27.1289 21.3047 26.9141 22.207L26.0117 26.2461C25.7969 27.1484 25.0234 27.75 24.0781 27.75C13.5508 27.75 5 19.1992 5 8.67188C5 7.72656 5.60156 6.95312 6.50391 6.78125L10.543 5.83594C11.4453 5.62109 12.3906 6.09375 12.7773 6.95312L14.625 11.3359C14.9688 12.1094 14.7539 13.0547 14.1094 13.6133L12.3477 15.0312C13.5938 17.2656 15.4844 19.1562 17.7188 20.4023L19.1797 18.6836C19.6953 17.9961 20.6406 17.7812 21.4141 18.082L25.7969 19.9727ZM24.6367 25.9453L25.582 21.9062C25.668 21.6055 25.4961 21.3477 25.2383 21.2188L20.8984 19.3711C20.6406 19.2852 20.3828 19.3281 20.2109 19.543L18.4492 21.6914C18.2344 21.9492 17.8906 22.0352 17.6328 21.8633C14.7109 20.4453 12.3047 18.0391 10.8867 15.1602C10.7148 14.8594 10.8008 14.5156 11.0586 14.3008L13.207 12.5391C13.4219 12.3672 13.4648 12.1094 13.3789 11.8516L11.5312 7.51172C11.4023 7.29688 11.1875 7.125 10.9727 7.125C10.9297 7.125 10.8867 7.16797 10.8438 7.16797L6.80469 8.11328C6.54688 8.15625 6.375 8.37109 6.375 8.67188C6.375 18.4258 14.3242 26.418 24.0781 26.418C24.3789 26.418 24.5938 26.2031 24.6367 25.9453Z"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_15474_14623">
        <Rect width="32" height="32" fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
)

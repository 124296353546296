import React from 'react'
import { VatomModelType } from '@vatom/vatomNew/plugin'

import { Image } from '../partials/Image'

import { useImageFaceBase } from './useImageFaceBase'

const ImageDisplay = ({ token }: { token: VatomModelType }) => {
  const resizeMode = 'contain'
  const { img, style } = useImageFaceBase(token)

  return (
    <Image
      accessibilityIgnoresInvertColors
      alt={token?.metadata.name || 'token'}
      source={{
        // cache: 'force-cache',
        headers: {
          Accept: '*/*'
        },
        uri: img
      }}
      resizeMode={resizeMode}
      style={style.img}
    />
  )
}

export default ImageDisplay

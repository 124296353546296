/* eslint-disable unicorn/filename-case */
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { usePreventScroll } from 'react-aria'
import { Linking, Platform, StatusBar, View } from 'react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import {
  BottomSheetModal,
  BottomSheetModalProps,
  BottomSheetModalProvider,
  BottomSheetView
} from '@gorhom/bottom-sheet'
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { isVatom } from '@vatom/BVatom/plugin'
import { ListingStatus, TokenType } from '@vatom/sdk/core'
import {
  Face,
  FaceHandler,
  useAccount,
  useAnalytics,
  useBusiness,
  useConfig,
  useUser
} from '@vatom/sdk/react'
import { translate, TxKeyPath } from '@vatom/utils'
import { isImageV1, isThreeDView, VatomModelType } from '@vatom/vatomNew/plugin'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { Loader, PressableOpacity, Status, Text, theme, Toast } from '@vatom/wombo'
import { TranslateOptions } from 'i18n-js'
import { observer } from 'mobx-react-lite'
import { Box, Center, HStack, Image, Pressable, useColorModeValue } from 'native-base'

import Alert from '../../components/Alert'
import { BottomSheetCustomBackdrop } from '../../components/BottomSheetCustomBackdrop'
import { CameraScannerModal } from '../../components/CameraScannerModal'
import {
  BlockIcon,
  CreditIcon,
  DeleteIcon,
  ExternalLinkIcon,
  GlobeIcon,
  InfoIcon,
  MapIcon,
  MapMarkerIcon,
  SendIcon,
  ShareIcon,
  SpaceViewIcon
} from '../../components/Icons'
import { TermsAndConditions } from '../../components/TermsAndConditions'
import { useMaxWidth } from '../../components/WebWrapper'
import { useBusinessSelector } from '../../hooks/useBusinessSelector'
import { useBusinessThemeConfig } from '../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../hooks/useIsDesktop'
import { useScrollLock } from '../../hooks/useScrollLock'
import logger from '../../logger'
import { AppNavigation, AppRouteProp, AppRoutes, AppStackScreenProps } from '../../navigators'
import { VatomWallet } from '../../utils/WalletSdk'
import { ARGame } from '../ARGame'
import { Crate } from '../Crate'

import { DeleteSheetModal } from './components/DeleteSheetModal'
import { InfoSheetModal } from './components/InfoSheetModal'
import { UnSellSheetModal } from './components/UnSellSheetModal'
import ImageDisplay from './Faces/ImageDisplay'
import ThreeDDisplay from './Faces/ThreeDDisplay'
import { WebDisplay } from './Faces/WebDisplay'
import { CardViewV2 } from './partials/CardView.V2'
import Share from './partials/Share'
import { isVatomNew, useToken } from './useNftDetail/useToken'
import { NFTDetailHeader } from './NFTDetail.Header'
import { useNftDetail } from './useNftDetail'

const isWeb = Platform.OS === 'web'

export type NFTDetailProps = AppStackScreenProps<
  typeof AppRoutes.NFTDetail | typeof AppRoutes.NFTDetail_Business
>

const DisplayNFT = observer((props: NFTDetailProps) => {
  const { route } = props
  const businessIdOrName = route.params.business
  const AAA = useBusinessSelector()
  console.log('AQUI DOO', businessIdOrName, AAA)
  const tokenId = route.params.tokenId
  const { token, isLoading } = useToken({ tokenId })

  if (!token && isLoading) {
    // Loading
    return <Loader />
  }
  if (isVatomNew(token)) {
    return <VatomNFT businessIdOrName={businessIdOrName} token={token} {...props} />
  }

  return <NFTDetail {...props} />
})

type BrandColors = {
  background: string
  primary: string
  primaryText: string
  textColor: string
}

const VatomNFTInner: React.FC<{ token: VatomModelType; brandColors: BrandColors }> = observer(
  ({ token, brandColors }) => {
    // Filter viewPlacements to remove icon-v1
    const viewPlacements = token.viewPlacements.filter(p => p.placeholder.id !== 'icon-v1')

    // Error handling if no valid viewPlacements are found
    if (viewPlacements.length === 0) {
      console.error('there are no valid viewPlacements for this vatom.')
      return <Loader />
    }
    const backgroundv1 = token.viewPlacements.find(p => p.placeholder.id === 'background-v1')
    const cardv1 = token.viewPlacements.find(p => p.placeholder.id === 'card-v1')

    return (
      <>
        <Display token={token} brandColors={brandColors} />
        {backgroundv1 && cardv1 ? <Text>Render the Card here</Text> : null}
      </>
    )
  }
)

const Display = ({ token, brandColors }: { token: VatomModelType; brandColors: BrandColors }) => {
  const backgroundv1 = token.viewPlacements.find(p => p.placeholder.id === 'background-v1')
  const cardv1 = token.viewPlacements.find(p => p.placeholder.id === 'card-v1')
  // isImageV1
  // isDynamicImageV1
  // isThreeDView

  if (cardv1 && !backgroundv1) {
    return <WebDisplay token={token} />
  }

  if (backgroundv1 && isImageV1(backgroundv1.view)) {
    return <ImageDisplay token={token} />
  }

  if (token?.threeDInfo?.url) {
    return (
      <ThreeDDisplay
        token={token}
        style={{
          backgroundColor: brandColors.background
        }}
      />
    )
  }

  return <Loader />
}

export type VatomNFTProps = AppStackScreenProps<
  typeof AppRoutes.NFTDetail | typeof AppRoutes.NFTDetail_Business
> & {
  businessIdOrName?: string
  token?: VatomModelType
}

const VatomNFT = observer(({ businessIdOrName, token, route, navigation }: VatomNFTProps) => {
  const insets = useSafeAreaInsets()
  // TODO: deprecate
  const faceRef = useRef<FaceHandler>()
  const bottomSheetModalRef = useRef<BottomSheetModal>(null)

  // const faceRef = useRef<FaceHandler>()
  // const isDesktop = useIsDesktop()

  const {
    onClose,
    // isThreeD,
    // handleMessage,
    // navigateToAR,
    // onCardViewCollapsed,
    // onCardViewExpanded,
    // cardViewExpanded,
    isCameraOpen,
    setIsCameraOpen,
    qrPromise
  } = useNftDetail({
    tokenId: route.params.tokenId,
    businessIdOrName,
    navigation,
    faceRef,
    route
  })

  useScrollLock()
  const { data: business } = useBusiness({
    business: businessIdOrName,
    businessId: businessIdOrName
  })

  const { pageTheme, brandTheme, isDark } = useBusinessThemeConfig({
    pageConfig: business?.pageConfig,
    brandConfig: business?.brandConfig
  })

  const brandColors = {
    background: brandTheme?.background ?? pageTheme.background,
    primary: brandTheme?.primary ?? pageTheme.active,
    primaryText: brandTheme?.primaryText ?? pageTheme.text,
    textColor: brandTheme?.text ?? pageTheme.text
  }

  const onCloseHandler = () => {
    onClose()
  }

  const onPressOptions = useCallback(() => {
    bottomSheetModalRef?.current?.snapToIndex(0)
    bottomSheetModalRef?.current?.present()
  }, [])

  return (
    <View
      style={{
        flex: 1,
        flexShrink: 0,
        backgroundColor: brandColors.background,
        paddingTop: insets.top,
        overflow: 'hidden'
      }}
    >
      <GestureHandlerRootView
        style={{
          flex: 1,
          flexShrink: 0
        }}
      >
        <BottomSheetModalProvider>
          <CameraScannerModal
            processLink={link => {
              qrPromise.current({ data: link })
              setIsCameraOpen(false)
            }}
            isOpen={isCameraOpen}
            onDismiss={() => setIsCameraOpen(false)}
          />
          <StatusBar
            barStyle={isDark ? 'light-content' : 'dark-content'}
            backgroundColor={brandColors.background}
          />
          {token && <TermsAndConditions businessId={token.studioInfo?.businessId} />}

          <NFTDetailHeader onClose={onCloseHandler} onPressOptions={onPressOptions} />

          {token && <VatomNFTInner token={token} brandColors={brandColors} />}
          {/* BOTTOM SHEET CONTENT */}
          {token && <OptionsSheetModal ref={bottomSheetModalRef} token={token} />}
        </BottomSheetModalProvider>
      </GestureHandlerRootView>
    </View>
  )
})

const NFTDetail = observer(({ route, navigation }: NFTDetailProps) => {
  const insets = useSafeAreaInsets()
  const faceRef = useRef<FaceHandler>()
  const isDesktop = useIsDesktop()
  usePreventScroll()

  const businessIdOrName = route.params.business
  const focused = useIsFocused()

  const bottomSheetModalRef = useRef<BottomSheetModal>(null)

  const onPressOptions = useCallback(() => {
    bottomSheetModalRef?.current?.snapToIndex(0)
    bottomSheetModalRef?.current?.present()
  }, [])

  const { data: business } = useBusiness({
    business: businessIdOrName,
    businessId: businessIdOrName
  })

  const { pageTheme, brandTheme, isDark } = useBusinessThemeConfig({
    pageConfig: business?.pageConfig,
    brandConfig: business?.brandConfig
  })

  const styles = {
    background: brandTheme?.background ?? pageTheme.background,
    primary: brandTheme?.primary ?? pageTheme.active,
    primaryText: brandTheme?.primaryText ?? pageTheme.text,
    textColor: brandTheme?.text ?? pageTheme.text
  }

  const themePage = pageTheme
  const [forceIsHidden, setForceIsHidden] = useState(false)

  const {
    token,
    onClose,
    isThreeD,
    handleMessage,
    navigateToAR,
    onCardViewCollapsed,
    onCardViewExpanded,
    cardViewExpanded,
    isCameraOpen,
    setIsCameraOpen,
    qrPromise
  } = useNftDetail({
    tokenId: route.params.tokenId,
    businessIdOrName,
    navigation,
    faceRef,
    route
  })

  const isHidden = Platform.OS === 'android' && (cardViewExpanded || forceIsHidden || !focused)
  if (!token) {
    logger.warn('[NFTDetail.render] no token.')
    return null
  }

  const onCloseHandler = () => {
    setForceIsHidden(true)
    onClose()
  }

  const renderFaces = () => (
    <>
      <Box flexDirection={'column'} flex={1}>
        <Box
          style={{
            flex: isThreeD ? 5 / 6 : 1
          }}
        >
          {token ? (
            <Face
              ref={token.hasCardView ? undefined : faceRef}
              token={token}
              viewMode="engaged"
              onMessage={handleMessage}
              hide={isHidden}
              style={{
                backgroundColor: styles.background
              }}
            />
          ) : null}
        </Box>

        {isThreeD && !isDesktop ? (
          <Box alignItems="center" marginY={4}>
            <PressableOpacity
              onPress={navigateToAR}
              borderWidth={1}
              borderRadius={3}
              maxWidth={'100%'}
              width={228}
              height={12}
              alignItems={'center'}
              justifyContent={'center'}
              _light={{
                backgroundColor: theme.colors.grayCool[50],
                borderColor: theme.colors.grayCool[200]
              }}
              _dark={{
                backgroundColor: theme.colors.grayDarkMode[900],
                borderColor: theme.colors.grayDarkMode[800]
              }}
              style={{
                ...Platform.select({
                  ios: {
                    shadowColor: styles.primaryText ?? '#000',
                    shadowOffset: { width: 0, height: 3 },
                    shadowOpacity: 0.15,
                    shadowRadius: 4
                  },
                  android: {
                    elevation: 3
                  },
                  web: {
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 14px'
                  }
                })
              }}
            >
              <Box flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                <SpaceViewIcon
                  size={6}
                  _light={{
                    color: theme.colors.textLightMode[600]
                  }}
                  _dark={{
                    color: theme.colors.textDarkMode[300]
                  }}
                />
                <Text
                  fontSize={15}
                  lineHeight={20}
                  marginLeft={2}
                  _light={{
                    color: theme.colors.textLightMode[600]
                  }}
                  _dark={{
                    color: theme.colors.textDarkMode[300]
                  }}
                  tx="nftDetail.viewSpace"
                />
              </Box>
            </PressableOpacity>
          </Box>
        ) : null}
      </Box>
      {token.hasCardView ? (
        <CardViewV2
          themePage={themePage}
          onCollapsed={onCardViewCollapsed}
          onExpanded={onCardViewExpanded}
          CardFace={<Face ref={faceRef} token={token} viewMode="card" onMessage={handleMessage} />}
          token={token}
        />
      ) : null}
    </>
  )

  const renderContent = () => {
    if (!isVatom(token)) {
      return renderFaces()
    }

    const isARGame = isVatom(token) && token.isARGame && Platform.OS === 'web'
    if (isARGame) {
      return <ARGame route={route} navigation={navigation} onClose={onClose} />
    }

    if (token.isCrate) {
      return (
        <Crate onMessage={handleMessage} route={route} navigation={navigation} onClose={onClose} />
      )
    }

    return renderFaces()
  }

  return (
    <GestureHandlerRootView
      style={{
        flex: 1,
        flexShrink: 0
      }}
    >
      <View
        style={{
          flex: 1,
          flexShrink: 0,
          backgroundColor: styles.background,
          paddingTop: insets.top,
          paddingBottom: insets.bottom,
          overflow: 'hidden'
        }}
      >
        <BottomSheetModalProvider>
          <CameraScannerModal
            processLink={link => {
              qrPromise.current({ data: link })
              setIsCameraOpen(false)
            }}
            isOpen={isCameraOpen}
            onDismiss={() => setIsCameraOpen(false)}
          />
          <StatusBar
            barStyle={isDark ? 'light-content' : 'dark-content'}
            backgroundColor={styles.background}
          />

          <TermsAndConditions businessId={token.studioInfo?.businessId} />

          <NFTDetailHeader onClose={onCloseHandler} onPressOptions={onPressOptions} />
          {renderContent()}
          {/* BOTTOM SHEET CONTENT */}
          <OptionsSheetModal ref={bottomSheetModalRef} token={token} />
        </BottomSheetModalProvider>
      </View>
    </GestureHandlerRootView>
  )
})

type SheetContent = ({
  token,
  onDismiss
}: {
  token: TokenType
  onDismiss: () => void
}) => React.ReactElement

type Option<T extends keyof typeof optionsActions = keyof typeof optionsActions> = {
  title: string
  action: T extends keyof typeof optionsActions ? typeof optionsActions[T] : () => void
  Icon: ({ size, fill }: { size: number; fill: string }) => React.ReactElement
  SheetContent?: SheetContent
  sheetContentOptions?: Omit<BottomSheetModalProps, 'children'>
  style: 'base' | 'danger'
  tokenAction: string
  tx?: TxKeyPath
  txOptions?: TranslateOptions
}

type OptionActionProps = {
  token: TokenType
  navigation: AppNavigation
  businessId?: string
  route: AppRouteProp<'NFTDetail' | 'NFTDetail_Business'>
  analytics: ReturnType<typeof useAnalytics>
  optionModalRef: React.RefObject<BottomSheetModal>
  validateSeller: () => boolean | void
}

const optionsActions = {
  shareAction: (props: OptionActionProps) => {
    // do share
    props.analytics.event(
      'initiateAction',
      {
        actionUri: 'Clone',
        source: 'ObjectMenu'
      },
      props.token
    )

    if (props.businessId) {
      props.navigation.navigate('SendNFTShare_Business', {
        tokenId: props.token.id,
        business: props.businessId
      })
      return
    }
    props.navigation.navigate('SendNFTShare', {
      tokenId: props.token.id
    })
  },
  shareLinkAction: async (props: OptionActionProps) => {
    const shareLink = props.token.shareInfo ?? ''
    const isShared = await Share.open({
      message: shareLink
    })

    if (!isShared) {
      props.analytics.event(
        'initiateAction',
        {
          actionUri: 'Share',
          source: 'ObjectMenu'
        },
        props.token
      )
      if (props.businessId) {
        props.navigation.navigate('SendNFTShare_Business', {
          tokenId: props.token.id,
          business: props.businessId
        })
        return
      }
      props.navigation.navigate('SendNFTShare', {
        tokenId: props.token.id
      })
    }
  },
  dropAction: (props: OptionActionProps) => {
    const { isEmbedded } = useVatomWalletSdkStore.getState()
    props.analytics.event(
      'initiateAction',
      {
        actionUri: 'Drop',
        source: 'ObjectMenu'
      },
      props.token
    )

    const spaces_domains = ['spaces.vatom.com', 'spaces.vatominc.cool', 'spaces.vatominc.cc']
    if (isEmbedded && spaces_domains.includes(sessionStorage?.getItem('POPUP_HOSTNAME') ?? '')) {
      // Check if host app wants to override this action
      VatomWallet._shared.sendMsgWithoutResponse('vatomwallet:handleAction', {
        action: 'Drop',
        vatomID: props.token.id
      })
      props.navigation.goBack()
      return
    }

    if (props.businessId) {
      props.navigation.navigate(AppRoutes.DropNFT_Business, {
        tokenId: props.token.id,
        business: props.businessId
      })
      return
    }
    props.navigation.navigate(AppRoutes.DropNFT, {
      tokenId: props.token.id
    })
    props.optionModalRef?.current?.close()
  },
  pickupAction: async (props: OptionActionProps) => {
    //
    props.analytics.event(
      'initiateAction',
      {
        actionUri: 'Pickup',
        source: 'ObjectMenu'
      },
      props.token
    )

    await props.token.performAction('Pickup').then(() => {
      const tokenName = props.token.metadata.name ?? ''
      Toast({
        title: translate('mapScreen.pickupSuccess', {
          name: tokenName
        }),
        status: Status.success,
        placement: 'top'
      })
    })
  },
  sellAction: (props: OptionActionProps) => {
    //
    props.analytics.event(
      'initiateAction',
      {
        actionUri: 'Sell',
        source: 'ObjectMenu'
      },
      props.token
    )
    // Check user for a valid business config
    if (props.validateSeller()) {
      // navigate to sell
      if (props.businessId) {
        props.navigation.navigate(AppRoutes.SellNFT_Business, {
          tokenId: props.token.id,
          business: props.businessId
        })
        return
      }
      props.navigation.navigate(AppRoutes.SellNFT, {
        tokenId: props.token.id
      })
    }
  },
  unSellAction: (props: OptionActionProps) => {
    //
    props.optionModalRef?.current?.snapToIndex(0)
    props.optionModalRef?.current?.present()
  },
  sendAction: (props: OptionActionProps) => {
    props.analytics.event(
      'initiateAction',
      {
        actionUri: 'Send',
        source: 'ObjectMenu'
      },
      props.token
    )

    const owner = props.route.params.owner
    const contractAddress = props.route.params.contractAddress
    // SEND
    if (props.businessId) {
      props.navigation.navigate(AppRoutes.SendNFT_Business, {
        tokenId: props.token.id,
        business: props.businessId,
        owner,
        contractAddress
      })
      return
    }
    props.navigation.navigate(AppRoutes.SendNFT, {
      tokenId: props.token.id,
      owner,
      contractAddress
    })
  },
  mintAction: (props: OptionActionProps) => {
    //
    props.analytics.event(
      'initiateAction',
      {
        actionUri: 'Mint',
        source: 'ObjectMenu'
      },
      props.token
    )

    if (props.businessId) {
      props.navigation.navigate(AppRoutes.MintNFT_Business, {
        tokenId: props.token.id,
        business: props.businessId
      })
      return
    }

    props.navigation.navigate(AppRoutes.MintNFT, {
      tokenId: props.token.id
    })
  },
  deleteAction: (props: OptionActionProps) => {
    //
    props.analytics.event(
      'initiateAction',
      {
        actionUri: 'Delete',
        source: 'ObjectMenu'
      },
      props.token
    )

    props.optionModalRef?.current?.snapToIndex(0)
    props.optionModalRef?.current?.present()
  },
  infoAction: (props: OptionActionProps) => {
    //
    props.optionModalRef?.current?.snapToIndex(0)
    props.optionModalRef?.current?.present()
  }
}

const options: Record<string, Option> = {
  share: {
    title: 'Share',
    tokenAction: 'Share',
    action: optionsActions.shareAction,
    Icon: ShareIcon,
    style: 'base',
    tx: 'common.share'
  },
  shareLink: {
    title: 'Share as a Link',
    tokenAction: 'ShareLink',
    action: optionsActions.shareLinkAction,
    Icon: ShareIcon,
    style: 'base',
    tx: 'actionSheets.shareLink'
  },
  drop: {
    title: 'Drop',
    tokenAction: 'Drop',
    action: optionsActions.dropAction,
    Icon: MapIcon,
    style: 'base',
    tx: 'common.drop'
  },
  pickup: {
    title: 'Pickup',
    tokenAction: 'Pickup',
    action: optionsActions.pickupAction,
    Icon: MapMarkerIcon,
    style: 'base',
    tx: 'common.pickup'
  },
  sell: {
    title: 'Sell',
    tokenAction: 'List',
    action: optionsActions.sellAction,
    Icon: CreditIcon,
    style: 'base',
    tx: 'common.sell'
  },
  unsell: {
    title: 'Remove From Sale',
    tokenAction: 'Delist',
    action: optionsActions.unSellAction,
    Icon: BlockIcon,
    SheetContent: UnSellSheetModal,
    sheetContentOptions: {
      name: 'UnSellSheetModal'
    },
    style: 'base',
    tx: 'actionSheets.removeSale'
  },
  send: {
    title: 'Send',
    tokenAction: 'Transfer',
    action: optionsActions.sendAction,
    Icon: SendIcon,
    style: 'base',
    tx: 'common.send'
  }
}

const canPerformAction = (token: TokenType, name: string) => {
  return !!token.actions.find(a => a === name)
}

function getOptionsForToken(token: TokenType) {
  // TODO: get extra options for business config?

  const actions: Option[] = []
  Object.values(options).forEach(option => {
    // Hack for Tide and State Farm
    const hack =
      option.tokenAction === 'ShareLink' &&
      (token.studioInfo?.campaignId === 'NxY6V4ZkZH' ||
        token.studioInfo?.campaignId === 'ZVrhQOIIsR')
    const canPerform: boolean = !hack && canPerformAction(token, option.tokenAction)

    if (canPerform) {
      actions.push(option)
    }
  })

  if (token.blockchainInfo && !token.blockchainInfo.tokenLink) {
    const name = token.blockchainInfo.network
    const badge = token.getNetworkIcon(name)
    actions.push({
      title: `Mint on ${token.blockchainInfo.networkName}`,
      tokenAction: 'MintNFT',
      action: optionsActions.mintAction,
      Icon: !badge
        ? GlobeIcon
        : ({ size, fill }: { size: number; fill: string }) => (
            // eslint-disable-next-line react-native-a11y/has-valid-accessibility-ignores-invert-colors
            <Image source={badge} width={size} height={size - 1} /> // img original size 35×32
          ),
      style: 'base',
      tx: 'actionSheets.mintOn',
      txOptions: {
        blockchain: token.blockchainInfo.networkName
      }
    })
  }

  if (token.commerceInfo?.status === ListingStatus.Active) {
    actions.push({
      title: `View on Marketplace`,
      tokenAction: 'MintNFT',
      action: () => {
        token.commerceInfo?.productUrl && Linking.openURL(token.commerceInfo?.productUrl)
      },
      Icon: ExternalLinkIcon,
      style: 'base',
      tx: 'actionSheets.viewMarketplace'
    })
  }

  // Default
  actions.push({
    title: 'General Info',
    tokenAction: 'info',
    action: optionsActions.infoAction,
    Icon: InfoIcon,
    SheetContent: InfoSheetModal,
    sheetContentOptions: {
      name: 'InfoSheetModal',
      maxDynamicContentSize: 752
    },
    style: 'base',
    tx: 'actionSheets.generalInfo'
  })

  if (token.studioInfo?.businessId !== 'PoIvoqVCm3' && token.type.includes('vatom')) {
    actions.push({
      title: 'Delete',
      tokenAction: 'Delete',
      action: optionsActions.deleteAction,
      Icon: DeleteIcon,
      SheetContent: DeleteSheetModal,
      sheetContentOptions: {
        name: 'DeleteSheetModal'
      },
      style: 'danger',
      tx: 'common.delete'
    })
  }

  return actions
}

function isValidSeller(account: ReturnType<typeof useAccount>['account']) {
  return account?.sellerAccount.status === 'enabled'
}

function OptionsItem(props: Option & { token: TokenType }) {
  const navigation = useNavigation()
  const route = useRoute<AppRouteProp<'NFTDetail' | 'NFTDetail_Business'>>()
  const analytics = useAnalytics()
  const { account } = useAccount()
  const config = useConfig()
  const { businessIdentifier } = useBusinessSelector()
  const user = useUser()

  const validateSeller = useCallback(() => {
    if (isValidSeller(account)) {
      return true
    }
    const link = `${config.studioServer}/${user?.default_business_id}/settings/payouts`
    Alert.fire({
      showCancelButton: true,
      title: 'Business Profile not Configured',
      text: 'You must first complete your business profile to receive payments.',
      confirmButtonText: 'Configure'
    }).then((alert: any) => {
      if (Platform.OS === 'web') {
        if (alert && alert.isConfirmed) window.open(link, '_blank')
        return false
      }
      if (alert) Linking.openURL(link)
      return false
    })
    return false
  }, [account, config.studioServer, user?.default_business_id])

  const { Icon } = props
  const currentOptionSheetModalRef = useRef<BottomSheetModal>(null)

  const colorScheme = {
    light: {
      base: theme.colors.textLightMode[600],
      danger: theme.colors.systemColorsLight.red
    },
    dark: {
      base: theme.colors.textDarkMode[300],
      danger: theme.colors.systemColorsDark.red
    }
  }
  const styles: { base: string; danger: string } = useColorModeValue(
    colorScheme.light,
    colorScheme.dark
  )

  const onDismiss = useCallback(() => {
    currentOptionSheetModalRef.current?.dismiss()
  }, [])

  return (
    <>
      <Pressable
        accessibilityRole="button"
        onPress={async () =>
          await props.action({
            token: props.token,
            navigation,
            route,
            analytics,
            optionModalRef: currentOptionSheetModalRef,
            validateSeller,
            businessId: businessIdentifier
          })
        }
        _light={{
          backgroundColor: theme.colors.white
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[900]
        }}
        _hover={{
          bg: 'gray.100:alpha.20'
        }}
        _pressed={{
          bg: 'gray.100:alpha.20'
        }}
        paddingX={4}
      >
        <HStack alignItems={'center'} height={12} flexGrow={1}>
          <Center marginRight={2} size={8}>
            <Icon size={6} fill={styles[props.style]} />
          </Center>
          <Text
            fontSize={15}
            fontWeight="normal"
            color={styles[props.style]}
            tx={props.tx}
            txOptions={props.txOptions}
          >
            {props.title}
          </Text>
        </HStack>
      </Pressable>
      {props?.SheetContent ? (
        <CustomSheetModal
          ref={currentOptionSheetModalRef}
          closeModal={onDismiss}
          {...props.sheetContentOptions}
        >
          <props.SheetContent token={props.token} onDismiss={onDismiss} />
        </CustomSheetModal>
      ) : null}
    </>
  )
}

function OptionsItemCustom({
  onPress,
  leftIcon,
  text,
  style
}: {
  onPress: () => void
  leftIcon?: string
  text?: string
  style?: any
}) {
  return (
    <Pressable
      accessibilityRole="button"
      onPress={onPress}
      _light={{
        backgroundColor: theme.colors.white
      }}
      _dark={{
        backgroundColor: theme.colors.grayDarkMode[900]
      }}
      _hover={{
        bg: 'gray.100:alpha.20'
      }}
      _pressed={{
        bg: 'gray.100:alpha.20'
      }}
      paddingX={4}
      marginBottom={style?.marginBottom}
      style={style}
    >
      <HStack alignItems={'center'} height={12} flexGrow={1}>
        {leftIcon && (
          <Center marginRight={2} size={8}>
            <Image
              accessibilityIgnoresInvertColors={true}
              size="24px"
              resizeMode="contain"
              source={{ uri: leftIcon }}
            />
          </Center>
        )}
        <Text
          fontSize={style?.fontSize ?? 15}
          fontWeight="normal"
          _light={{
            color: style?.color ?? theme.colors.textDarkMode[300]
          }}
          _dark={{
            color: style?.color ?? theme.colors.textLightMode[600]
          }}
        >
          {text}
        </Text>
      </HStack>
    </Pressable>
  )
}

const CustomSheetModal = observer(
  React.forwardRef<
    BottomSheetModal,
    {
      children: React.ReactNode[] | React.ReactNode
      closeModal: () => void
    } & BottomSheetModalProps
  >((props, forwardedRef) => {
    const { children, closeModal, ...sheetModalProps } = props
    const maxWidth = useMaxWidth()

    const bottomSheetStyles = useColorModeValue(
      {
        container: {
          backgroundColor: theme.colors.white
        },
        indicator: {
          backgroundColor: theme.colors.grayCool[400]
        }
      },
      {
        container: {
          backgroundColor: theme.colors.grayDarkMode[900]
        },
        indicator: {
          backgroundColor: theme.colors.grayDarkMode[100]
        }
      }
    )

    return (
      <BottomSheetModal
        ref={forwardedRef}
        enableDynamicSizing={true}
        enablePanDownToClose={false}
        enableDismissOnClose={true}
        enableHandlePanningGesture={false}
        enableOverDrag={false}
        keyboardBehavior="interactive"
        keyboardBlurBehavior="none"
        backdropComponent={props => (
          <BottomSheetCustomBackdrop {...props} style={{ zIndex: 999 }} onPress={closeModal} />
        )}
        handleIndicatorStyle={{
          backgroundColor: bottomSheetStyles.indicator.backgroundColor
        }}
        handleStyle={{
          display: 'none',
          marginTop: 12,
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          backgroundColor: bottomSheetStyles.container.backgroundColor
        }}
        backgroundStyle={{
          backgroundColor: bottomSheetStyles.container.backgroundColor
        }}
        containerStyle={{
          zIndex: 999,
          backgroundColor: 'transparent'
        }}
        style={{
          maxWidth: isWeb ? maxWidth : undefined,
          backgroundColor: 'transparent',
          marginHorizontal: isWeb ? 'auto' : 0
        }}
        {...sheetModalProps}
      >
        {children}
      </BottomSheetModal>
    )
  })
)

const OptionsSheetModal = observer(
  React.forwardRef<BottomSheetModal, { token: TokenType | VatomModelType }>(function (
    { token },
    forwardedRef
  ) {
    const tokenOptions = getOptionsForToken(token)
    const insets = useSafeAreaInsets()
    const maxWidth = useMaxWidth()
    const user = useUser()
    const config = useConfig()
    const customActionsList = config.features.screensConfig?.NFTDetail?.actionsheet?.customActions

    const bottomSheetStyles = useColorModeValue(
      {
        container: {
          backgroundColor: theme.colors.white
        },
        indicator: {
          backgroundColor: theme.colors.grayCool[400]
        }
      },
      {
        container: {
          backgroundColor: theme.colors.grayDarkMode[900]
        },
        indicator: {
          backgroundColor: theme.colors.grayDarkMode[100]
        }
      }
    )

    const onPressCustomAction = useCallback(
      (action?: string) => {
        try {
          if (!action) return
          VatomWallet._shared.sendMsgWithoutResponse('walletsdk:sendCustomAction', {
            token: JSON.stringify(token),
            user: JSON.stringify(user),
            tokenMetadata: JSON.stringify(token.metadata),
            action
          })
        } catch (error) {
          console.log('error', error)
        }
      },
      [token, user]
    )

    const renderItems = useMemo(() => {
      if (customActionsList && customActionsList.length > 0) {
        return customActionsList.map(item => (
          <OptionsItemCustom {...item} onPress={() => onPressCustomAction(item.action)} />
        ))
      }
      return (
        tokenOptions &&
        tokenOptions.map(item => <OptionsItem key={item.title} {...item} token={token} />)
      )
    }, [customActionsList, onPressCustomAction, token, tokenOptions])

    // const handleSheetChanges = useCallback((index: number) => {
    //   console.log('LOG: OptionsSheetModal > handleSheetChanges', index)
    // }, [])

    return (
      <BottomSheetModal
        ref={forwardedRef}
        enableDynamicSizing={true}
        enablePanDownToClose={true}
        enableDismissOnClose={true}
        enableHandlePanningGesture={true}
        enableOverDrag={false}
        // onChange={handleSheetChanges}
        handleIndicatorStyle={{
          backgroundColor: bottomSheetStyles.indicator.backgroundColor
        }}
        handleStyle={{
          marginTop: 12,
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          backgroundColor: bottomSheetStyles.container.backgroundColor
        }}
        backgroundStyle={{
          backgroundColor: bottomSheetStyles.container.backgroundColor
        }}
        containerStyle={{
          backgroundColor: 'transparent'
        }}
        style={{
          maxWidth: isWeb ? maxWidth : undefined,
          backgroundColor: 'transparent',
          marginHorizontal: isWeb ? 'auto' : 4
        }}
        backdropComponent={BottomSheetCustomBackdrop}
        bottomInset={0}
        // detached={isWeb ? false : true}
      >
        <BottomSheetView
          style={{
            paddingBottom: insets.bottom > 0 ? insets.bottom : 16
          }}
        >
          {renderItems}
        </BottomSheetView>
      </BottomSheetModal>
    )
  })
)

export default DisplayNFT

import { useMutation } from '@tanstack/react-query'

import { getRootStore } from '../../../SDKProvider'

import { UserPatchBody } from './types'

const patchUser = async (profileBody: UserPatchBody) => {
  return await getRootStore().service.oidc.patch(`/me`, profileBody)
}

export const usePatchUserProfile = () => {
  return useMutation({
    mutationFn: async (profileBody: UserPatchBody) => {
      await patchUser(profileBody)
    }
  })
}

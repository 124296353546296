/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
import React, { useCallback, useRef, useState } from 'react'
import {
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Modal,
  Platform,
  Pressable,
  ScrollView,
  TextInput,
  TouchableWithoutFeedback,
  View
} from 'react-native'
import Animated, { FadeIn, FadeOutLeft } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Ionicons } from '@expo/vector-icons'
import { Loader, theme } from '@vatom/wombo'
import * as ExpoImagePicker from 'expo-image-picker'
import { Box, useColorModeValue } from 'native-base'
import { create } from 'zustand'

import { SendMessageIcon } from '../../../../components/Icons'
import {
  HeaderButtonClose,
  ScreenHeader,
  ScreenHeaderButton
} from '../../../../components/ScreenHeader'
import { useBusinessTheme } from '../../../../hooks/useBusinessTheme'
import { useModalState } from '../../../../hooks/useModalState'
import { useImagePicker } from '../../hooks/useImagePicker'
import { type ImagePagerRef, ImagePager } from '../ImagePager/ImagePager'

type ImagePickerModalStore = {
  images: ExpoImagePicker.ImagePickerAsset[]
}
const imagePickerModalStore = create<ImagePickerModalStore>(set => ({
  images: []
}))

export const useImagePickerModal = () => {
  const { isVisible, openModal, closeModal } = useModalState()
  const { images } = imagePickerModalStore()

  const onCancelPick = useCallback(() => {
    if (images.length === 0) {
      // Picker cancel without any images previously selected
      closeModal()
    }
  }, [images, closeModal])

  const { pickImage } = useImagePicker({
    onCancel: onCancelPick,
    onSuccess(results) {
      imagePickerModalStore.setState(() => ({ images: results.assets }))
      if (results.assets.length > 0) {
        !isVisible && openModal()
      }
    }
  })

  const handlePickImage = useCallback(async () => {
    try {
      await pickImage({
        allowsMultipleSelection: true
      })
    } catch (error) {
      console.warn('useImagePickerModal:', error)
    }
  }, [pickImage])

  const removePickedImage = useCallback(
    (imageIndex: number) => {
      const newImages = new Map(images.entries())
      newImages.delete(imageIndex)
      imagePickerModalStore.setState(() => ({ images: [...newImages.values()] }))
      return newImages.size
    },
    [images]
  )

  return {
    imagePickerVisible: isVisible,
    openImagePicker: openModal,
    closeImagePicker: closeModal,
    pickImage: handlePickImage,
    removePickedImage,
    imagesPicked: images
  }
}

export type ImageToSend = {
  uri: string
  width: number
  height: number
  fileName?: string | null
  fileSize?: number | null
}
export function ImagePickerModal({
  onSend,
  showModal,
  onDismiss,
  defaultCaption = ''
}: {
  onSend: (images: ImageToSend[], caption?: string) => void
  showModal: boolean
  onDismiss: () => void
  defaultCaption: string
}) {
  const insets = useSafeAreaInsets()

  const imagePagerRef = useRef<ImagePagerRef | null>(null)

  const [captionValue, setCaptionValue] = useState(defaultCaption)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const { imagesPicked: images, removePickedImage, pickImage } = useImagePickerModal()

  const onSelectedImage = useCallback(
    (index: number) => {
      if (index !== currentImageIndex) {
        setCurrentImageIndex(index)
      }
    },
    [currentImageIndex]
  )

  const { pageTheme } = useBusinessTheme()

  const headerIconColor = useColorModeValue(
    theme.colors.textLightMode[900],
    theme.colors.textDarkMode[100]
  )
  const placeholderTextColor = useColorModeValue(
    theme.colors.textLightMode[100],
    theme.colors.textDarkMode[600]
  )
  const inputTextColor = useColorModeValue(
    theme.colors.textLightMode[100],
    theme.colors.textDarkMode[300]
  )
  const closeIconColor = useColorModeValue('#000', '#fff')
  const closeIconBg = useColorModeValue(theme.colors.grayCool[100], 'rgba(0,0,0,0.7)')
  const sendIconColor = useColorModeValue(
    theme.colors.systemColorsLight.orange,
    theme.colors.systemColorsDark.orange
  )

  const handleChangeText = useCallback((text: string) => {
    setCaptionValue(() => text)
  }, [])

  const handleSend = useCallback(async () => {
    if (images.length === 0) {
      return
    }
    // TODO: check for images sizes before trying to upload them.
    onSend(images, captionValue)

    setCaptionValue(() => '')
    onDismiss()
  }, [captionValue, images, onDismiss, onSend])

  const removeImage = useCallback(
    (imageIndex: number) => {
      const remainingImage = removePickedImage(imageIndex)
      if (remainingImage > 0) {
        setCurrentImageIndex(remainingImage > 0 ? imageIndex : 0)
      }
    },
    [removePickedImage]
  )

  return (
    <Modal visible={showModal} onDismiss={onDismiss} transparent>
      <Animated.View
        style={{
          flex: 1,
          paddingTop: insets.top,
          paddingBottom: insets.bottom,
          backgroundColor: pageTheme.background
        }}
        entering={FadeIn.delay(300)}
      >
        <ScreenHeader
          headerTextColor={headerIconColor}
          headerProps={{
            position: 'absolute',
            top: insets.top,
            overflow: 'hidden',
            width: '100%'
          }}
          centerContainerProps={{
            flex: 1,
            paddingX: 2
          }}
          leftContainerProps={{
            flex: 0,
            _web: {
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto'
            }
          }}
          rightContainerProps={{
            flex: 0,
            _web: {
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto'
            }
          }}
          headerLeft={() => (
            <ScreenHeaderButton
              onPress={() => onDismiss()}
              containerProps={{
                backgroundColor: closeIconBg,
                alignItems: 'center',
                borderRadius: 99
              }}
            >
              <HeaderButtonClose color={closeIconColor} />
            </ScreenHeaderButton>
          )}
          headerRight={() => (
            <ScreenHeaderButton
              onPress={pickImage}
              containerProps={{
                backgroundColor: closeIconBg,
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'center',
                borderRadius: 99
              }}
            >
              <Ionicons name="swap-horizontal-outline" size={16} color={closeIconColor} />
            </ScreenHeaderButton>
          )}
        >
          {/* <View>
            <Text>{`${images.length} Selected`}</Text>
          </View> */}
        </ScreenHeader>
        <KeyboardAvoidingView
          style={{
            flex: 1
          }}
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          keyboardVerticalOffset={Platform.OS === 'ios' ? 12 : 0}
        >
          <View
            style={{
              flex: 1,
              overflow: 'hidden'
            }}
          >
            {images.length === 0 && (
              <View
                style={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Box
                  borderRadius={10}
                  _light={{
                    backgroundColor: theme.colors.grayCool[100]
                  }}
                  _dark={{
                    backgroundColor: theme.colors.grayDarkMode[900]
                  }}
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '75%',
                    aspectRatio: 1
                  }}
                >
                  <Loader />
                </Box>
              </View>
            )}
            {images.length > 0 && (
              <>
                <TouchableWithoutFeedback
                  accessibilityRole="button"
                  onPress={() => Keyboard.dismiss()}
                >
                  <ImagePager ref={imagePagerRef} images={images} onSelected={onSelectedImage} />
                </TouchableWithoutFeedback>

                {images.length > 1 && (
                  <Box
                    position={'absolute'}
                    width={'100%'}
                    bottom={1}
                    justifyContent={'center'}
                    alignContent={'center'}
                    alignItems={'center'}
                  >
                    <ScrollView
                      horizontal={true}
                      // scrollEnable={}
                      showsHorizontalScrollIndicator={false}
                      contentContainerStyle={{
                        paddingHorizontal: 8,
                        alignSelf: 'center',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {images.map((image, index) => {
                        const isSelected = index === currentImageIndex
                        return (
                          <Pressable
                            key={`slider-${index}`}
                            accessibilityRole="button"
                            onPress={() => {
                              if (!isSelected) {
                                setCurrentImageIndex(() => index)
                                imagePagerRef.current?.setPage(index)
                              } else {
                                removeImage(index)
                              }
                            }}
                          >
                            <>
                              {isSelected && (
                                <Box
                                  position={'absolute'}
                                  zIndex={2}
                                  backgroundColor={'rgba(0,0,0,0.25)'}
                                  width={'100%'}
                                  height={'100%'}
                                  alignContent={'center'}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                  opacity={0.85}
                                  borderRadius={6}
                                >
                                  <Ionicons name="trash-outline" size={30} color={'white'} />
                                </Box>
                              )}
                              <Image
                                source={{ uri: image?.uri }}
                                style={{
                                  borderWidth: 1,
                                  borderColor: isSelected ? '#fff' : 'transparent',
                                  marginHorizontal: 2,
                                  borderRadius: 6,
                                  width: 44,
                                  height: 44
                                }}
                              />
                            </>
                          </Pressable>
                        )
                      })}
                    </ScrollView>
                  </Box>
                )}
              </>
            )}
          </View>

          {/* FOOTER */}
          {images.length > 0 && (
            <Box paddingX={4} paddingTop={2}>
              <Box flexDirection={'row'}>
                <TextInput
                  accessibilityHint={'input'}
                  accessibilityLabel="Image caption"
                  // placeholder={translate('dms.imageCaption')}
                  // TODO: translate
                  placeholder={'Add a caption'}
                  placeholderTextColor={placeholderTextColor}
                  value={captionValue}
                  onChangeText={handleChangeText}
                  style={{
                    flex: 1,
                    borderWidth: 1,
                    borderRadius: 8,
                    borderColor: placeholderTextColor,
                    backgroundColor: 'transparent',
                    color: inputTextColor,
                    fontSize: 15,
                    lineHeight: 20,
                    fontFamily: 'Inter',
                    paddingVertical: 10,
                    paddingHorizontal: 8,
                    marginRight: 16
                  }}
                />
                <Animated.View entering={FadeIn.duration(300)} exiting={FadeOutLeft.duration(300)}>
                  <Pressable
                    accessibilityRole="button"
                    onPress={() => handleSend()}
                    style={{
                      backgroundColor: sendIconColor,
                      borderRadius: 99,
                      width: 40,
                      height: 40,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <SendMessageIcon width={16} height={20} fill={'white'} />
                  </Pressable>
                </Animated.View>
              </Box>
            </Box>
          )}
        </KeyboardAvoidingView>
      </Animated.View>
    </Modal>
  )
}

import { TEthereumChains, TSolanaChains } from '@vatom/sdk/core'
import { Contact } from '@vatom/sdk/react'
import { create } from 'zustand'

import appJson from '../../../../app.json'

const TRANSFER_SOURCE = `${appJson.expo.scheme}://` || 'com.vatom://'

type Web3Confirmation = {
  hash: string
  chain?: TEthereumChains | TSolanaChains
  to: string
}

type CoinStore = {
  coinId: string
  businessId: string
  toUserId: string
  amount: number
  detail: string
  source: string
  contact: Contact | null
  adress?: string
  chain?: TEthereumChains | TSolanaChains
  web3Confirmation: Web3Confirmation | null
  setCoinId: (coinId: string) => void
  setBusinessId: (businessId: string) => void
  setAmount: (amount: number) => void
  setToUserId: (toUserId: string) => void
  setContact: (contact: Contact) => void
  setDetail: (detail: string) => void
  setWeb3Confirmation: (web3Confirmation: Web3Confirmation) => void
  setAdress: (adress?: string) => void
  setChain: (chain?: TEthereumChains | TSolanaChains) => void
  _reset: () => void
}

const initialState = {
  coinId: '',
  businessId: '',
  toUserId: '',
  amount: 0,
  detail: '',
  source: TRANSFER_SOURCE,
  contact: null,
  web3Confirmation: null,
  adress: undefined,
  chain: undefined
}
const useCoinStore = create<CoinStore>(set => ({
  ...initialState,
  setCoinId: (coinId: string) => set(() => ({ coinId: coinId })),
  setBusinessId: (businessId: string) => set(() => ({ businessId: businessId })),
  setToUserId: (toUserId: string) => set(() => ({ toUserId: toUserId })),
  setAmount: (amount: number) => set(() => ({ amount: amount })),
  setDetail: (detail: string) => set(() => ({ detail: detail })),
  setContact: (contact: Contact) => set(() => ({ contact: contact })),
  setWeb3Confirmation: (web3Confirmation: Web3Confirmation) =>
    set(() => ({ web3Confirmation: web3Confirmation })),
  setAdress: (adress?: string) => set(() => ({ adress: adress })),
  setChain: (chain?: TEthereumChains | TSolanaChains) => set(() => ({ chain: chain })),
  _reset: () => set(initialState)
}))

export { useCoinStore }

import { StyleSheet } from 'react-native'
import Animated from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { FontAwesome } from '@expo/vector-icons'
import { useUser } from '@vatom/sdk/react'
import { Avatar, convertToHexAlpha, PressableOpacity, theme } from '@vatom/wombo'
import { BlurTint, BlurView } from 'expo-blur'
import { observer } from 'mobx-react-lite'
import { Box, HStack, Icon, Text, useColorModeValue } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

const ProfileButton = observer(({ onPress }: { onPress: () => void }) => {
  const insets = useSafeAreaInsets()
  const user = useUser()
  const blurTint: BlurTint = useColorModeValue('light', 'dark')

  const handleOnPress = useThrottledCallback(
    () => {
      onPress()
    },
    300,
    { leading: true }
  )

  return (
    <PressableOpacity
      onPress={handleOnPress}
      position={'absolute'}
      bottom={0}
      width={'100%'}
      borderTopWidth={1}
      _light={{
        backgroundColor: `${convertToHexAlpha(theme.colors.grayCool[50], 0.5)}`,
        borderTopColor: theme.colors.grayCool[200]
      }}
      _dark={{
        borderTopColor: theme.colors.grayDarkMode[700],
        backgroundColor: `${convertToHexAlpha(theme.colors.grayDarkMode[900], 0.5)}`
      }}
    >
      <Box paddingBottom={insets.bottom}>
        <BlurView tint={blurTint} style={StyleSheet.absoluteFillObject} intensity={50} />
        <HStack flexDirection="row" alignItems="center" paddingX={4} paddingY={5} zIndex={1}>
          <Animated.View sharedTransitionTag="profileDrawerCard">
            <Avatar url={user?.picture} borderWidth={0} size={12} />
          </Animated.View>
          <Box marginLeft={3}>
            <Text fontSize={15} fontFamily="Inter-Bold" noOfLines={1}>
              {user?.name}
            </Text>
            <Text fontSize={12} noOfLines={1}>
              {user?.email}
            </Text>
          </Box>
          <Box marginLeft="auto">
            <Icon
              textAlign={'right'}
              as={FontAwesome}
              name="angle-right"
              size={30}
              _light={{
                color: theme.colors.textLightMode[300]
              }}
              _dark={{
                color: theme.colors.textDarkMode[100]
              }}
            />
          </Box>
        </HStack>
      </Box>
    </PressableOpacity>
  )
})

export { ProfileButton }

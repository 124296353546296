import React from 'react'

import { WebWrapper } from '../../components/WebWrapper'

import { DropNFT, DropNFTProps } from './DropNFT'

export default (props: DropNFTProps) => {
  return (
    <WebWrapper justifyContent={'center'} flex={1} flexShrink={0}>
      <DropNFT {...props} />
    </WebWrapper>
  )
}

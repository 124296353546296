import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Platform, SafeAreaView, StatusBar, Text as RNText, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import KeepAwake from '@sayem314/react-native-keep-awake'
import { SpaceType } from '@vatom/sdk/core'
import { useSpace } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { HeaderBackOptions, Toast } from '@vatom/wombo'
import { Audio } from 'expo-av'
import { observer } from 'mobx-react-lite'
import { Box } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import {
  EndCallButton,
  MicButton,
  PeopleButton,
  SpeakerButton
} from '../../components/CallControls'
import { PromptModal } from '../../components/PromptModal/PromptModal'
import { useNavigateHome } from '../../hooks/useNavigateHome'
import { TabRoutes } from '../../navigators'

import { StatusIcon } from './StatusIcon'

type Props = {
  space?: SpaceType
}

export const AudioSpace = observer(({ space }: Props) => {
  const {
    startCall,
    callBackgroundColor,
    connectionStatus,
    getHasCurrentSession,
    space: currentSpace,
    endCall
  } = useSpace()
  const navigation = useNavigation()
  const { navigateHome } = useNavigateHome()

  const hasCurrentSession = getHasCurrentSession()
  const callRef = useRef(false)
  const hasErrorRef = useRef(false)

  const [isPermissionError, setIsPermissionError] = useState(false)

  const checkPermission = useCallback(async () => {
    try {
      const audioPermission = await Audio.requestPermissionsAsync()
      if (audioPermission.status !== 'granted') {
        setIsPermissionError(() => true)
        return false
      }
      return true
    } catch (error) {
      console.log('LOG: > checkPermission > error:', error)
      return false
    }
  }, [])

  const endOtherCallRunning = useCallback(async () => {
    if (!space || !currentSpace) {
      return
    }

    try {
      if (hasCurrentSession) {
        if (space.id !== currentSpace.id) {
          await endCall()
          callRef.current = false
        }
      }
    } catch (error) {
      console.error('endOtherCallRunning:', error)
    }
  }, [currentSpace, space, endCall, hasCurrentSession])

  const callStart = useCallback(async () => {
    callRef.current = true
    if (!space) {
      return
    }
    // Begin the call
    startCall(space, {
      onSuccess: () => {
        console.log('LOG: > startCall > onSuccess:')
      },
      onError: e => {
        if (hasErrorRef.current === false) {
          Toast({
            title: 'Unable to start the call. Try agin',
            status: 'warning'
          })
          hasErrorRef.current = true
        }
      }
    })
  }, [space, startCall])

  const onGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigateHome({
        screenParams: {
          screen: TabRoutes.Connect
        }
      })
    }
  }, [navigateHome, navigation])

  const initCall = useCallback(async () => {
    try {
      const hasAudioPermission = await checkPermission()
      if (!hasAudioPermission) {
        setIsPermissionError(() => true)
        return
      }
      await endOtherCallRunning()

      await callStart()
    } catch (error) {
      console.log('LOG: > initCall > error:', error)
      if (hasErrorRef.current === false) {
        Toast({
          title: 'Unable to start the call. Try agin',
          status: 'warning'
        })
        hasErrorRef.current = true
      }
      onGoBack()
    }
  }, [callStart, checkPermission, endOtherCallRunning, onGoBack])

  /** Called on start */
  useEffect(() => {
    if (callRef.current === false) {
      callRef.current = true
      initCall()
    }
  }, [initCall])

  const onCloseError = useThrottledCallback(onGoBack, 300)

  const renderPermissionError = useMemo(
    () =>
      isPermissionError ? (
        <PromptModal
          isOpen={isPermissionError}
          title={'Audio Permission'}
          message={translate('errors.deniedMicrophone')}
          onClose={onCloseError}
        />
      ) : null,
    [isPermissionError, onCloseError]
  )

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: callBackgroundColor
      }}
    >
      {/* Keep screen awake while this screen is active */}
      <KeepAwake />

      {/* Status bar */}
      <StatusBar barStyle="light-content" />

      {/* Content within safe area insets */}
      <SafeAreaView
        style={{
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {/* Back options */}
        <Box w="100%">
          <HeaderBackOptions showOptions={false} onClose={onGoBack} color={'white'} />
        </Box>
        {renderPermissionError}
        {/* Top header, name of the space */}
        <RNText
          style={{
            flex: 0,
            fontSize: 28,
            fontWeight: 'bold',
            color: 'white',
            marginLeft: 20,
            marginRight: 20
          }}
        >
          {space ? `@${space?.alias || space?.id}` : 'Loading...'}
        </RNText>

        {/* Center area */}
        <StatusIcon status={connectionStatus} />

        <Box alignSelf="flex-end" marginRight="20px">
          <EndCallButton mb="8px" />
          <PeopleButton mb="8px" />
          {Platform.OS !== 'web' && <SpeakerButton mb="8px" />}
          <MicButton mb="8px" />
        </Box>
      </SafeAreaView>
    </View>
  )
})

import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import { useCommunitiesTheme } from '../../../../themes'
import StarIcon from '../../../AddModals/assets/star_icon.svg'

const ProgressBarHeader = (props: any) => {
  const { scoreStyle, item } = props
  const emojies = ['😡', '😣', '😕', '😄', '😍']

  const isStarsStyle = scoreStyle === 'stars'
  const isEmojiesStyle = scoreStyle === 'emojies'

  const communitiesTheme = useCommunitiesTheme()

  return (
    <View style={styles(isStarsStyle, communitiesTheme).circle}>
      <Text style={styles(isStarsStyle, communitiesTheme).circleText}>
        {isEmojiesStyle ? emojies[item - 1] : item}
      </Text>
      {isStarsStyle ? (
        <StarIcon
          fill={communitiesTheme.stars.progressStarColor}
          style={{ position: 'absolute' }}
          width={24}
          height={24}
        />
      ) : null}
    </View>
  )
}

export default ProgressBarHeader

const styles = (isStarsStyle?: boolean, communitiesTheme?: any) =>
  StyleSheet.create({
    circle: {
      borderRadius: isStarsStyle ? 0 : 100,
      borderWidth: isStarsStyle ? 0 : 1,
      borderColor: communitiesTheme.score.borderColor,
      height: 24,
      width: 24,
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 2
    },
    circleText: {
      fontSize: 11,
      lineHeight: 15,
      fontWeight: '600',
      zIndex: 1,
      color: communitiesTheme.score.textColor
    }
  })

import React, { forwardRef } from 'react'
import { Text } from '@vatom/wombo'
import { Input } from 'native-base'

const TransferAmount = ({
  children,
  ...rest
}: React.PropsWithChildren<React.ComponentProps<typeof Text>>) => (
  <Text
    fontSize={64}
    lineHeight={64}
    fontWeight={400}
    color={'#11141E'}
    adjustsFontSizeToFit={true}
    numberOfLines={1}
    // minimumFontScale={0.5}
    allowFontScaling
    textAlign={'center'}
    style={{
      textAlignVertical: 'center',
      textAlign: 'center'
    }}
    _web={{
      fontSize: 32
    }}
    {...rest}
  >
    {children}
  </Text>
)

const TransferAmountInput = forwardRef((props: React.ComponentProps<typeof Input>, ref: any) => (
  <Input
    ref={ref}
    variant="unstyled"
    placeholder="0"
    fontSize={64}
    lineHeight={64}
    fontWeight={400}
    color={'#11141E'}
    numberOfLines={1}
    // minimumFontScale={0.5}
    allowFontScaling
    textAlign={'center'}
    {...props}
  />
))

export { TransferAmount, TransferAmountInput }

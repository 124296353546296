import { WebWrapper } from '../../components/WebWrapper'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../hooks/useIsDesktop'

import { SendNFT, SendNFTProps } from './SendNFT'

export default (props: SendNFTProps) => {
  const isDesktop = useIsDesktop()

  const { brandTheme, pageTheme } = useBusinessTheme()

  return (
    <WebWrapper
      justifyContent={'center'}
      backgroundColor={brandTheme?.background ?? pageTheme.background}
      borderRadius={isDesktop ? 8 : undefined}
      overflow={'hidden'}
      flexGrow={1}
      maxHeight={'100%'}
    >
      <SendNFT {...props} />
    </WebWrapper>
  )
}

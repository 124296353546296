/* This is a mst-gql generated file, don't modify it manually */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'

import { RootStoreType } from './index'
import { ModelBase } from './ModelBase'

/**
 * EnrichedBondingChangeBase
 * auto generated base class for the model EnrichedBondingChangeModel.
 *
 * Bonding change enriched with reserve change and supply change
 */
export const EnrichedBondingChangeModelBase = ModelBase.named('EnrichedBondingChange')
  .props({
    __typename: types.optional(types.literal('EnrichedBondingChange'), 'EnrichedBondingChange'),
    address: types.union(types.undefined, types.string),
    slot: types.union(types.undefined, types.frozen()),
    insertTs: types.union(types.undefined, types.frozen()),
    reserveChange: types.union(types.undefined, types.frozen()),
    supplyChange: types.union(types.undefined, types.frozen())
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class EnrichedBondingChangeModelSelector extends QueryBuilder {
  get address() {
    return this.__attr(`address`)
  }
  get slot() {
    return this.__attr(`slot`)
  }
  get insertTs() {
    return this.__attr(`insertTs`)
  }
  get reserveChange() {
    return this.__attr(`reserveChange`)
  }
  get supplyChange() {
    return this.__attr(`supplyChange`)
  }
}
export function selectFromEnrichedBondingChange() {
  return new EnrichedBondingChangeModelSelector()
}

export const enrichedBondingChangeModelPrimitives =
  selectFromEnrichedBondingChange().address.slot.insertTs.reserveChange.supplyChange

import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { Keyboard, Platform } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Contact, useBusiness } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { observer } from 'mobx-react-lite'
import { Box, HStack, KeyboardAvoidingView, VStack } from 'native-base'

import {
  HeaderButtonClose,
  ScreenHeader,
  ScreenHeaderButton
} from '../../../components/ScreenHeader'
import { ScreenWrapper } from '../../../components/ScreenWrapper'
import { Title } from '../../../components/Title'
import { useIsDesktop } from '../../../hooks/useIsDesktop'
import { AppRoutes, AppStackScreenProps } from '../../../navigators'
import { isValidEthereumAddress } from '../../../utils/isValidAddress'
import { useCoinTheme } from '..//useCoinTheme'
import { ButtonBack } from '../transfer/partials/Buttons'

import { ChoseRecipients } from './partials/ChoseRecipients'

export type ITransferProps = AppStackScreenProps<typeof AppRoutes.FungibleTokenRecipient>

export const RecipientsScreen = observer(
  forwardRef(({ route, navigation }: ITransferProps, ref) => {
    const insets = useSafeAreaInsets()
    const [searchMode, setSearchMode] = useState(false)
    const [keyboardShow, setKeyboardShow] = useState(false)
    const isDesktop = useIsDesktop()
    const businessId = 'nQwtevgfOa'

    const { data: business } = useBusiness({ business: businessId })

    const styles = useCoinTheme({ business })

    useEffect(() => {
      const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
        setKeyboardShow(true)
      })
      const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
        setKeyboardShow(false)
      })

      return () => {
        keyboardDidHideListener.remove()
        keyboardDidShowListener.remove()
      }
    }, [])

    useEffect(() => {
      if (keyboardShow) {
        setSearchMode(true)
      } else {
        setSearchMode(false)
      }
    }, [keyboardShow])

    const handleOnPressContact = (item: Contact) => {
      const identity = item.identities?.find(identity => identity.type === 'eth')
      if (identity) {
        navigation.navigate(AppRoutes.FungibleTokenConfirmation, {
          ...route.params,
          toUserId: item.id,
          toAddress: identity.value
        })
      } else if (isValidEthereumAddress(item.id.toLowerCase())) {
        navigation.navigate(AppRoutes.FungibleTokenConfirmation, {
          ...route.params,
          toAddress: item.id
        })
      } else {
        alert("The user doesn't have an Ethereum address")
      }
    }

    const hideHeader = useMemo(() => {
      if (!isDesktop) {
        return searchMode
      }
      return false
    }, [searchMode, isDesktop])

    const onClose = () => {
      if (navigation.canGoBack()) {
        navigation.goBack()
      } else {
        navigation.navigate(AppRoutes.home)
      }
    }

    const DesktopLayout = (
      <Box
        ref={ref}
        backgroundColor={styles.background}
        borderRadius={10}
        padding={6}
        shadow={'modal'}
        _web={{
          minHeight: '80vh',
          maxHeight: '80vh'
        }}
      >
        <ScreenHeader
          headerLeftType="none"
          headerProps={{
            minHeight: 8, // 32px
            paddingX: 0
          }}
          headerRight={() => (
            <ScreenHeaderButton onPress={onClose}>
              <HeaderButtonClose color={styles.textColor} />
            </ScreenHeaderButton>
          )}
        ></ScreenHeader>
        <Title
          preset="h2"
          fontWeight={700}
          color={styles.textColor}
          textAlign={'left'}
          tx="common.chooseRecipient"
        />
        <VStack flex={1} alignItems={'stretch'} width="100%" marginY={8}>
          <ChoseRecipients
            handleOnPress={handleOnPressContact}
            searchMode={searchMode}
            setSearchMode={setSearchMode}
          />
        </VStack>
        <HStack
          width={'100%'}
          alignSelf={'flex-end'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <ButtonBack onPress={onClose}>{translate('common.back')}</ButtonBack>
        </HStack>
      </Box>
    )

    if (isDesktop) {
      return DesktopLayout
    }

    return (
      <KeyboardAvoidingView
        paddingBottom={insets.bottom}
        flex={1}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        <ScreenWrapper
          screenBackgroundColor={styles.background}
          statusBarBackgroundColor={styles.primary}
        >
          <ScreenHeader
            headerLeftPress={onClose}
            headerTextColor={styles.textColor}
            headerProps={{
              display: hideHeader ? 'none' : 'flex'
            }}
          >
            <Title
              preset="h5"
              variant="semibold"
              color={styles.textColor}
              textAlign={'center'}
              tx="common.chooseRecipient"
            />
          </ScreenHeader>
          <VStack paddingX={3} width="100%" flex={1} mt={searchMode ? 2 : 0}>
            <ChoseRecipients
              handleOnPress={handleOnPressContact}
              searchMode={searchMode}
              setSearchMode={setSearchMode}
            />
          </VStack>
        </ScreenWrapper>
      </KeyboardAvoidingView>
    )
  })
)

export default RecipientsScreen

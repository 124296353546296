import React, { useCallback, useMemo, useState } from 'react'
import { useWindowDimensions } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Route, TabBar, TabView } from 'react-native-tab-view'
import { useNavigation } from '@react-navigation/native'
import { translate } from '@vatom/utils'
import { theme } from '@vatom/wombo'
import { Network as AlchemyNetwork } from 'alchemy-sdk'
import { Image } from 'expo-image'
import { observer } from 'mobx-react-lite'
import {
  Box,
  Center,
  FlatList,
  HStack,
  Input,
  Pressable,
  Text,
  useColorModeValue
} from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import { Add } from '../../components/Icons'
import { ArrowRightIcon } from '../../components/Icons/ArrowRightIcon'
import { ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'

const NETWORK_ROUTES = {
  Mainnets: 'Mainnets',
  Testnets: 'Testnets'
} as const

const mainnets = [
  {
    name: 'Ethereum',
    id: AlchemyNetwork.ETH_MAINNET,
    image: require('./assets/eth.png')
  },
  {
    name: 'Optimism',
    id: AlchemyNetwork.OPT_MAINNET,
    image: require('./assets/op.png')
  },
  {
    name: 'Polygon',
    id: AlchemyNetwork.MATIC_MAINNET,
    image: require('./assets/matic.png')
  },
  {
    name: 'Arbitrum',
    id: AlchemyNetwork.ARB_MAINNET,
    image: require('./assets/arb.png')
  },
  // { name: 'Astar', id: AlchemyNetwork.ASTAR_MAINNET, image: require('./assets/astr.png') },
  { name: 'Base', id: AlchemyNetwork.BASE_MAINNET, image: require('./assets/base.png') },
  { name: 'Zksync', id: AlchemyNetwork.ZKSYNC_MAINNET, image: require('./assets/zksync.png') }
]

const testnets = [
  {
    name: 'Sepolia',
    id: AlchemyNetwork.ETH_SEPOLIA,
    image: require('./assets/eth.png')
  },
  {
    name: 'Optimism Sepolia',
    id: AlchemyNetwork.OPT_SEPOLIA,
    image: require('./assets/op.png')
  },
  {
    name: 'Polygon Amoy',
    id: AlchemyNetwork.MATIC_AMOY,
    image: require('./assets/matic.png')
  },
  {
    name: 'Arbitrum Sepolia',
    id: AlchemyNetwork.ARB_SEPOLIA,
    image: require('./assets/arb.png')
  },
  { name: 'Base Sepolia', id: AlchemyNetwork.BASE_SEPOLIA, image: require('./assets/base.png') },
  {
    name: 'Zksync Sepolia',
    id: AlchemyNetwork.ZKSYNC_SEPOLIA,
    image: require('./assets/zksync.png')
  }
]

export const BlockchainNetwork = observer(() => {
  const navigation = useNavigation()
  const { pageTheme } = useBusinessTheme()
  const layout = useWindowDimensions()
  const insets = useSafeAreaInsets()

  const maxWidth = layout.width > 360 ? 360 : layout.width

  const [filterString, setFilterString] = useState('')

  const headerIconColor = useColorModeValue(
    theme.colors.textLightMode[900],
    theme.colors.textDarkMode[100]
  )
  const tabBarIndicatorColor = useColorModeValue(
    theme.colors.systemColorsLight.orange,
    theme.colors.systemColorsDark.orange
  )
  const tabBarLabelColor = useColorModeValue('#000', '#fff')

  const onPressNew = useThrottledCallback(
    () => {
      navigation.navigate('BlockChainNetworkAdd')
    },
    300,
    {
      leading: true
    }
  )

  const onPressItem = useCallback(
    (item: Network) => {
      console.log('LOG: BlockchainNetwork > Go To Network!!')
      navigation.navigate('BlockChainNetworkEdit', {
        networkId: 'network-id-123'
      })
    },
    [navigation]
  )

  const [index, setIndex] = React.useState(0)
  const [routes] = React.useState([
    { key: NETWORK_ROUTES.Mainnets, title: NETWORK_ROUTES.Mainnets },
    { key: NETWORK_ROUTES.Testnets, title: NETWORK_ROUTES.Testnets }
  ])

  const mainNetsData = useMemo(() => {
    if (filterString.length === 0) {
      return mainnets
    }
    // TODO: change mock data
    return mainnets.filter(item => item.name.toLowerCase().includes(filterString.toLowerCase()))
  }, [filterString])

  const testNetsData = useMemo(() => {
    if (filterString.length === 0) {
      return testnets
    }
    // TODO: change mock data
    return testnets.filter(item => item.name.toLowerCase().includes(filterString.toLowerCase()))
  }, [filterString])

  const renderScene = useCallback(
    ({ route }: { route: Route }) => {
      switch (route.key) {
        case NETWORK_ROUTES.Mainnets:
        default:
          return <Mainnets data={mainNetsData} onPressItem={onPressItem} />
        case NETWORK_ROUTES.Testnets:
          return <Testnets data={testNetsData} onPressItem={onPressItem} />
      }
    },
    [mainNetsData, onPressItem, testNetsData]
  )

  return (
    <ScreenWrapper
      screenBackgroundColor={pageTheme.background}
      statusBarBackgroundColor={pageTheme.background}
      viewContainerStyle={{
        width: '100%',
        backgroundColor: pageTheme.background
      }}
      unsafe
    >
      <ScreenHeader
        headerLeftType={'back'}
        headerTextColor={headerIconColor}
        headerProps={{
          paddingTop: insets.top,
          paddingBottom: 1,
          overflow: 'hidden'
        }}
        centerContainerProps={{
          flex: 1,
          paddingX: 2
        }}
        leftContainerProps={{
          flex: 0,
          _web: {
            flexGrow: 0,
            flexShrink: 1,
            flexBasis: 'auto'
          }
        }}
        rightContainerProps={{
          flex: 0,
          _web: {
            flexGrow: 0,
            flexShrink: 1,
            flexBasis: 'auto'
          }
        }}
        // headerRight={() => (
        //   <ScreenHeaderButton onPress={onPressNew} containerProps={{}}>
        //     <Add size={4} fill={headerIconColor} />
        //   </ScreenHeaderButton>
        // )}
      >
        <Title preset="h5" variant="semibold" numberOfLines={1}>
          Blockchain Networks
        </Title>
      </ScreenHeader>

      <Box
        marginTop={3}
        style={{
          width: '100%',
          zIndex: 2
        }}
      >
        <Box
          style={{
            height: 40 //searchBarHeight
          }}
          paddingX={4}
          _android={{ paddingTop: 2 }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Input
            placeholder={translate('common.search')}
            accessibilityHint="Search"
            accessibilityLabel="Search"
            defaultValue={filterString}
            onChangeText={setFilterString}
            clearButtonMode="always"
            fontSize={15}
            width={'100%'}
            _light={{
              backgroundColor: theme.colors.white,
              borderColor: theme.colors.grayCool[300],
              placeholderTextColor: theme.colors.textLightMode[100]
            }}
            _dark={{
              backgroundColor: theme.colors.grayDarkMode[800],
              borderColor: theme.colors.grayDarkMode[500],
              placeholderTextColor: theme.colors.textDarkMode[600]
            }}
          />
        </Box>
      </Box>

      <Box marginTop={2} flexGrow={1}>
        <TabView
          navigationState={{ index, routes }}
          renderScene={renderScene}
          onIndexChange={setIndex}
          initialLayout={{ width: maxWidth }}
          keyboardDismissMode="auto"
          renderTabBar={props => (
            <TabBar
              {...props}
              activeColor={tabBarIndicatorColor}
              tabStyle={{
                width: 'auto'
              }}
              labelStyle={{
                color: tabBarLabelColor,
                textTransform: 'capitalize',
                fontSize: 15,
                lineHeight: 20,
                fontFamily: 'Inter-SemiBold',
                letterSpacing: 0.2
              }}
              style={{
                width: 200,
                alignSelf: 'center',
                backgroundColor: 'transparent'
              }}
              contentContainerStyle={{}}
              indicatorStyle={{
                backgroundColor: tabBarIndicatorColor
              }}
              indicatorContainerStyle={{
                backgroundColor: 'transparent'
              }}
            />
          )}
        />
      </Box>
    </ScreenWrapper>
  )
})

type Network = {
  id: string
  image: string
  name: string
}

function Mainnets({
  data,
  onPressItem
}: {
  data: Network[]
  onPressItem: (item: Network) => void
}) {
  const onPress = useThrottledCallback(
    (item: Network) => {
      onPressItem(item)
    },
    300,
    {
      leading: true
    }
  )

  return (
    <FlatList
      data={data}
      renderItem={({ item }) => (
        <NetworkItem image={item.image} name={item.name} onPress={() => onPress(item)} />
      )}
      contentContainerStyle={{
        marginTop: 8,
        paddingHorizontal: 16,
        paddingBottom: 60
      }}
    />
  )
}

function Testnets({
  data,
  onPressItem
}: {
  data: Network[]
  onPressItem: (item: Network) => void
}) {
  const onPress = useThrottledCallback(
    (item: Network) => {
      onPressItem(item)
    },
    300,
    {
      leading: true
    }
  )

  return (
    <FlatList
      data={data}
      renderItem={({ item }) => (
        <NetworkItem image={item.image} name={item.name} onPress={() => onPress(item)} />
      )}
      contentContainerStyle={{
        marginTop: 8,
        paddingHorizontal: 16,
        paddingBottom: 60
      }}
    />
  )
}

function NetworkItem({
  image,
  name,
  onPress
}: {
  image?: string
  name: string
  onPress: () => void
}) {
  const iconColor = useColorModeValue(
    theme.colors.textLightMode[600],
    theme.colors.textDarkMode[300]
  )

  return (
    <Pressable accessibilityRole="button" onPress={onPress}>
      <HStack alignItems={'center'} paddingY={3}>
        <Center marginRight={3}>
          <Center size={8} borderRadius={99} overflow={'hidden'}>
            {/* eslint-disable-next-line react-native-a11y/has-valid-accessibility-ignores-invert-colors */}
            <Image
              source={image}
              style={{
                // backgroundColor: '#f09', // TODO: remove bg
                width: 32,
                height: 32
              }}
            />
          </Center>
        </Center>
        <Box flexGrow={1}>
          <Text fontSize={15} lineHeight={20} fontFamily={'Inter-SemiBold'} letterSpacing={0.2}>
            {name}
          </Text>
        </Box>
        <Center>
          <ArrowRightIcon size={4} fill={iconColor} />
        </Center>
      </HStack>
    </Pressable>
  )
}

import React from 'react'
import { Platform } from 'react-native'
import { observer } from 'mobx-react-lite'

import PeopleSection from './partials/SectionPeople'

export const People = observer(() => {
  if (Platform.OS === 'web') {
    return (
      <PeopleSection
        containerProps={{
          marginTop: 0
        }}
      />
    )
  }
  return (
    <PeopleSection
      containerProps={{
        marginTop: 0
      }}
    />
  )
})

export default People

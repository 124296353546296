/* eslint-disable unicorn/filename-case */
import { theme } from '@vatom/wombo'

import GuestBanner from '../../components/GuestBanner'
import { WebWrapper } from '../../components/WebWrapper'

import { WalletScreen } from './Wallet'

export default () => (
  <>
    <GuestBanner />
    <WebWrapper
      _light={{
        backgroundColor: theme.colors.white
      }}
      _dark={{
        backgroundColor: theme.colors.grayCool[900]
      }}
    >
      <WalletScreen />
    </WebWrapper>
  </>
)

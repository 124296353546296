/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
import React, { useMemo } from 'react'
import { FontAwesome } from '@expo/vector-icons'
import {
  type IEvent,
  MsgType,
  useMatrixUser,
  useMatrixUserProfile,
  useRoomEvent
} from '@vatom/sdk/react'
import { theme } from '@vatom/wombo'
import { Image } from 'expo-image'
import { Box, HStack, Text, useColorModeValue, VStack } from 'native-base'

import { Title } from '../../../../components/Title'
import { getWidthRatio } from '../../hooks/useImageSize'
import { getImageDataFromEvent, getThumbnailUrl } from '../helpers'

const REPLY_PREVIEW_HEIGHT = 50

export const ReplyPreview = React.memo(function ({
  roomId,
  eventId,
  containerProps
}: {
  roomId: string
  eventId: string
  containerProps?: React.ComponentProps<typeof Box>
}) {
  const { data: matrixUser } = useMatrixUser()
  const userId = matrixUser?.user_id ?? ''

  const { data: event } = useRoomEvent(
    { roomId, eventId: eventId ?? '' },
    {
      enabled: !!eventId && eventId !== ''
    }
  )

  const senderId = useMemo(() => event?.sender ?? '', [event])
  const { data: userData } = useMatrixUserProfile(senderId, {
    enabled: senderId !== ''
  })

  const userTitle = useMemo(() => {
    if (senderId !== '' && userId === senderId) {
      // TODO: translate text
      return 'You'
    }
    return userData?.displayname ?? ' '
  }, [senderId, userId, userData?.displayname])

  const iconColor = useColorModeValue(
    theme.colors.textLightMode[300],
    theme.colors.grayDarkMode[200]
  )

  const renderContent = useMemo(() => {
    switch (event?.content.msgtype) {
      // TODO: handle more msgtypes
      // TODO: translate text
      case MsgType.Audio:
        return (
          <HStack alignItems={'center'}>
            <FontAwesome name="microphone" size={12} color={iconColor} />
            <Text
              marginLeft={1.5}
              fontFamily={'Inter'}
              textAlign={'left'}
              _light={{
                color: theme.colors.textLightMode[300]
              }}
              _dark={{
                color: theme.colors.grayDarkMode[200]
              }}
              style={{
                paddingVertical: 3
              }}
              numberOfLines={1}
            >
              Audio
            </Text>
          </HStack>
        )
      case MsgType.Image:
        return (
          <HStack alignItems={'center'}>
            <FontAwesome name="camera" size={12} color={iconColor} />
            <Text
              marginLeft={1.5}
              fontSize={14}
              lineHeight={18}
              fontFamily={'Inter'}
              textAlign={'left'}
              _light={{
                color: theme.colors.textLightMode[300]
              }}
              _dark={{
                color: theme.colors.grayDarkMode[200]
              }}
              style={{
                paddingVertical: 3
              }}
              numberOfLines={1}
            >
              Image
            </Text>
          </HStack>
        )
      case MsgType.Text:
        return (
          <Text
            fontSize={14}
            lineHeight={18}
            fontFamily={'Inter'}
            textAlign={'left'}
            _light={{
              color: theme.colors.textLightMode[300]
            }}
            _dark={{
              color: theme.colors.grayDarkMode[200]
            }}
            style={{
              paddingVertical: 3
            }}
            numberOfLines={1}
          >
            {event?.content?.body ?? 'Text message'}
          </Text>
        )
      default:
        return null
    }
  }, [event, iconColor])

  if (!event) {
    return null
  }

  return (
    <HStack
      flex={0}
      overflow={'hidden'}
      style={[
        {
          minWidth: 100,
          height: REPLY_PREVIEW_HEIGHT
        },
        containerProps?.style
      ]}
      {...containerProps}
    >
      <Box
        _light={{
          backgroundColor: theme.colors.systemColorsLight.orange
        }}
        _dark={{
          backgroundColor: theme.colors.systemColorsDark.orange
        }}
        style={{
          width: 3,
          alignSelf: 'stretch'
        }}
      />
      <HStack flex={1} paddingX={2} paddingY={1}>
        <VStack flex={1}>
          <Title
            preset="h6"
            variant="semibold"
            _light={{
              color: theme.colors.systemColorsLight.orange
            }}
            _dark={{
              color: theme.colors.systemColorsDark.orange
            }}
            numberOfLines={1}
          >
            {userTitle}
          </Title>
          <Text
            marginTop={0.5}
            fontFamily={'Inter'}
            textAlign={'left'}
            _light={{
              color: theme.colors.textLightMode[300]
            }}
            _dark={{
              color: theme.colors.grayDarkMode[200]
            }}
            numberOfLines={1}
          >
            {renderContent}
          </Text>
        </VStack>
        <Box flex={0} justifyContent={'center'}>
          <ReplyPreviewExtra event={event} />
        </Box>
      </HStack>
    </HStack>
  )
})

const REPLY_IMAGE_SIZE = 40

const ReplyPreviewExtra = React.memo(function ({ event }: { event: IEvent }) {
  if (!event) {
    return null
  }

  const messageType = event?.content.msgtype

  if (messageType !== MsgType.Image) {
    return null
  }

  const image = getImageDataFromEvent(event)
  const imageSize = getWidthRatio(image.width, image.height, REPLY_IMAGE_SIZE)
  const thumbnailUrl = getThumbnailUrl(image.url, {
    width: imageSize.width,
    height: imageSize.height
  })
  const cacheKey = event.unsigned.transaction_id ?? event.event_id
  return (
    <Image
      alt={image.alt}
      placeholder={image?.uri}
      placeholderContentFit={'cover'}
      source={{
        cacheKey,
        uri: thumbnailUrl,
        width: REPLY_IMAGE_SIZE,
        height: REPLY_IMAGE_SIZE
      }}
      style={{
        borderRadius: 4,
        width: REPLY_IMAGE_SIZE,
        height: REPLY_IMAGE_SIZE
      }}
      contentFit="cover"
    />
  )
})

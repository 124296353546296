import { FirebaseApp } from '@firebase/app'
import { getFunctions, httpsCallable } from '@firebase/functions'

export const getLoginInfo = async (
  serverID: string,
  dimensionID: string,
  mixerID: string,
  firebaseApp: FirebaseApp
) => {
  const firebaseFunctions = getFunctions(firebaseApp)
  const hifiMixerGenerateJWT = httpsCallable(firebaseFunctions, 'hifiMixerGenerateJWT')

  return hifiMixerGenerateJWT({
    serverID,
    dimensionID,
    mixerID
  })
}

export const mixerCreate = async (
  serverID: string,
  dimensionID: string,
  config: any,
  firebaseApp: FirebaseApp
) => {
  const firebaseFunctions = getFunctions(firebaseApp)
  const hifiMixerCreate = httpsCallable(firebaseFunctions, 'hifiMixerCreate')

  return hifiMixerCreate({
    serverID,
    dimensionID,
    vatomIncEnvironment: 'production',
    hifi_group: 'multiregion',
    config
  })
}

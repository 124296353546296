import React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { Image } from 'expo-image'
import * as WebBrowser from 'expo-web-browser'
import { getLinkPreview } from 'link-preview-js'

import { useMessage } from '../../../queries'
import { Preview } from '../../../types'
import { useMessageContext } from '../MessageContext'

const LinkPreview = ({ link }: { link: string }) => {
  const [preview, setPreview] = React.useState<Preview | null>(null)

  React.useEffect(() => {
    const getPreview = async () => {
      try {
        const p = await getLinkPreview(link).then(data => data)
        setPreview(p as Preview)
      } catch (e) {
        return
      }
    }
    getPreview()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPress = () => {
    WebBrowser.openBrowserAsync(link)
  }

  if (!preview) {
    return null
  }

  return (
    <View style={{ marginVertical: 10 }}>
      <View style={{ flexDirection: 'row', marginBottom: 3, alignItems: 'center' }}>
        <Image
          accessibilityIgnoresInvertColors
          source={{ uri: preview.images[0] }}
          style={{ width: 20, height: 20, marginRight: 5 }}
        />
        <Text style={styles.siteName}>{preview.siteName}</Text>
      </View>

      <Pressable accessibilityLabel="button" accessibilityHint="button" onPress={() => onPress()}>
        <Text style={styles.title}>{preview.title}</Text>
      </Pressable>

      <Text style={styles.description}>{preview.description}</Text>
    </View>
  )
}

const LinkPreviews = ({ roomId }: { roomId: string }) => {
  const { messageEventID } = useMessageContext()
  const { data: matrixMessage } = useMessage(roomId, messageEventID)
  if (!matrixMessage) {
    return null
  }

  const links: string[] = matrixMessage.content['link-previews'] ?? []
  if (!links) {
    return null
  }

  return (
    <View>
      {links.map(link => (
        <LinkPreview link={link} key={link} />
      ))}
    </View>
  )
}
export default LinkPreviews

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    backgroundColor: '#242730',
    padding: 10,
    maxWidth: 200,
    marginRight: 16
  },
  siteName: {
    color: '#FFF',
    fontWeight: '600'
  },
  title: {
    color: '#3798E4',
    fontWeight: '600',
    marginBottom: 3
  },
  description: {
    color: '#868E96'
  }
})

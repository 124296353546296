import { RegionStore } from '@vatom/sdk/core'
import { AnyTokenForNotification, notifyWalletFeature } from '@vatom/vatomNew/react'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

import logger from '../logger'
import { type VatomModelSnapshotIn, VatomModel } from '../models/VatomModel'
import VatomIncWebSocketManager from '../modules/Vatom/model/Socket'

import { convertItemToVatomModel } from './VatomGeoMapRegion'

const className = 'InventoryRegionStore'

const PAGE_LIMIT = 50

const shouldSkipNotificationsActionNames = ['Activate', 'Acquire', 'Pickup'] as const

type PerformActionActionMap =
  | {
      Acquire: {
        tokenId: string
      }
    }
  | never

type InventoryResponse = {
  items: any[]
  nextCursor: string
}

export const InventoryRegionStore = RegionStore.named(className)
  .props({
    className,
    _tokens: types.optional(types.map(VatomModel), {}),
    shownNotifications: types.optional(types.array(types.string), [])
  })
  .views(self => ({
    /** Our state key is the current user's ID */
    get stateKey() {
      return `inventory:Vatom:${self._id}`
    },
    get vatoms() {
      return self.tokens
    },
    get isLoading() {
      console.log('self._isLoading vatom inventory: ', self._isLoading)
      return self._isLoading //|| self.api.store.assetProvider.length === 0
    },
    get isFetching() {
      console.log('self._isFetching vatom inventory: ', self._isLoading)
      return self._isFetching
    },
    get inventoryApi() {
      return self.rootStore.service.network
    },
    get vatomAuth() {
      return self.rootStore.dataPool.sessionStore.vatomIncSessionToken
    }
  }))
  .views(self => ({
    getItem(id: string) {
      return self.vatoms?.find(t => t.id === id)
    }
  }))
  .actions(self => ({
    // storeShownNotification(id: string) {
    //   self.shownNotifications.push(id)
    // },
    pushTokens(tokens: VatomModelSnapshotIn[]) {
      for (const obj of tokens) {
        try {
          const instance = VatomModel.create(obj)
          self._tokens.set(instance.id, instance)
        } catch (error) {
          console.error('pushTokens error:', obj, error)
        }
      }
    },
    removeTokens(tokenIds: VatomModelSnapshotIn['id'][]) {
      for (const id of tokenIds) {
        try {
          self._tokens.delete(id)
        } catch (error) {
          console.error('removeTokens error:', id, error)
        }
      }
    }
  }))
  .actions(self => {
    let _maxPage = 0
    let _lastPage = 0
    let _lastKey = ''

    function _setMaxPage(totalHits?: number, maxAmount?: number) {
      if (!totalHits || !maxAmount) {
        _maxPage = 0
        return
      }
      const maxPage = totalHits <= maxAmount ? 0 : Math.ceil(totalHits / maxAmount)
      _maxPage = maxPage
    }
    function _setLastPage(number?: number) {
      _lastPage = number ?? 0
    }
    function _setLastKey(key: string) {
      _lastKey = key
    }
    function _resetKeys() {
      _setMaxPage(0)
      _setLastPage(0)
      _setLastKey('')
    }

    type FetchTokensParams = {
      parentId?: string
      businessId?: string
      pageParam?: number
      limit?: number
      sortBy?: 'when_modified' | 'when_created' | 'name'
      sortOrder?: 'asc' | 'desc'
    }

    const defaultFetchTokensParams: FetchTokensParams = {
      // parentId: '.',
      businessId: '',
      pageParam: 0,
      limit: PAGE_LIMIT,
      sortBy: 'when_modified',
      sortOrder: 'desc'
    }

    async function fetchTokens(params: FetchTokensParams = defaultFetchTokensParams) {
      const { parentId, businessId, pageParam, limit, sortBy, sortOrder } = params

      try {
        const apiEndpoint = self.inventoryApi

        const payload = {
          // parent_id: parentId,
          business_id: businessId,
          limit,
          page: pageParam,
          sort_by: sortBy,
          sort_order: sortOrder
        }
        // JSON.stringify(payload)
        const res = await apiEndpoint.post(`/vatoms/user-inventory/search`)
        const data = res.data as InventoryResponse
        return data || null
      } catch (error) {
        console.log('ERROR: fetchTokens:', error)
        return null
      }
    }

    function getNextPage(currentKey: string) {
      if (_lastKey !== currentKey) {
        // reset pages do to key change
        return 0
      }
      const maxPage = _maxPage ?? 0
      const lastPage = _lastPage ?? 0
      if (lastPage === maxPage && maxPage !== 0) {
        return undefined
      }
      return lastPage < maxPage ? lastPage + 1 : 0
    }

    type FetchPageParams = {
      parentId?: string
      businessId?: string
      page?: number
      limit?: number
      sortBy?: 'when_modified' | 'when_created' | 'name'
      sortOrder?: 'asc' | 'desc'
    }

    async function fetchPage({
      // parentId,
      businessId,
      page,
      limit,
      sortBy,
      sortOrder
    }: FetchPageParams = {}) {
      try {
        self.setIsLoading(true)
        self.setIsFetching(true)

        const args = {
          // parentId: parentId ?? '.',
          businessId: businessId ?? '',
          limit: limit ?? PAGE_LIMIT,
          sortBy: sortBy ?? 'when_modified',
          sortOrder: sortOrder ?? 'desc'
        }

        const fetchKey = Object.entries(args).flat().join(':')

        const pageParam = page ?? getNextPage(fetchKey)
        if (pageParam === undefined) {
          return // no more pages
        }
        const response = await fetchTokens({
          ...args,
          pageParam
        })

        console.log('LOG: fetchTokens NEW > response:', response)
        if (!response) {
          return
        }

        // _setMaxPage(response?.limit ?? 0, FETCH_LIMIT)
        _setMaxPage(0, PAGE_LIMIT)
        _setLastPage(pageParam)
        _setLastKey(fetchKey)

        if (response && response?.items) {
          const newVatoms: VatomModelSnapshotIn[] = []
          for (const item of response.items) {
            // const actions =
            //   tokensPayload?.actions?.filter((action: ActionPayload) =>
            //     action.name.includes(token['vAtom::vAtomType'].template)
            //   ) ?? []
            // const faces =
            //   tokensPayload?.faces?.filter(
            //     (face: FacePayload) => face.template === token['vAtom::vAtomType'].template
            //   ) ?? []
            const transformedItem = convertItemToVatomModel(item)
            newVatoms.push(transformedItem)
          }
          self.pushTokens(newVatoms)
        }
      } catch (error) {
        console.log('ERROR: VatomInventoryRegion.fetchPage:', error)
        _resetKeys()
      } finally {
        // Resume websocket events
        // self.resumeMessages()
        self.setIsLoading(false)
        self.setIsFetching(false)
      }
    }

    function clear() {
      _resetKeys()
      self._tokens.clear()
    }

    return {
      fetchPage,
      clear,
      resetKeys: _resetKeys
    }
  })
  .actions(self => ({
    notify: (tokenIds: string[]) => {
      const tokens = tokenIds.reduce<AnyTokenForNotification[]>((acc, id) => {
        const token = self.get(id)
        if (token) {
          acc.push({
            id: token.id,
            title: token.metadata.name,
            objectDefinitionId: token.studioInfo?.objectDefinitionId,
            businessId: token.studioInfo?.businessId
          })
        }
        return acc
      }, [])

      notifyWalletFeature(tokens)
    },
    propagateUpdate: () => {
      // Call upon any type of inventory update
      const isEmbedded = useVatomWalletSdkStore.getState().isEmbedded
      if (isEmbedded) {
        useVatomWalletSdkStore.getState().setinventoryWasUpdated(true)
      }
    }
  }))
  .actions(self => ({
    doUpdateEvent: (updates: UserInventoryUpdate[]) => {
      console.log('websocket update', updates)
      // from eventType = vatominc-user-inventory-updated
      try {
        if (updates.some(update => update.operation === 'add')) {
          // reload and notify
          self.reload().then(() => {
            // Uncomment once we have more data from the websocket to be able to tell if it's a received vatom
            //  self.notify(updates.filter(u => u.operation === 'add').map(u => u.vatomId))
          })
          return
        }
        //
        const tokensToRemove = updates
          .filter(update => update.operation === 'remove')
          .map(update => update.vatomId)

        self.removeTokens(tokensToRemove)
      } catch (error) {
        console.error(`${className}.doUpdateEvent`, error)
      } finally {
        self.propagateUpdate()
      }
    }
  }))
  .actions(self => ({
    handleReceivedEvent: (event: Event) => {
      if ('detail' in event) {
        const { detail: eventDetail } = event as SocketEvent['detail']
        if (eventDetail?.eventType === 'vatominc-user-inventory-updated') {
          const updates = eventDetail?.eventData?.userInventoryUpdates as UserInventoryUpdate[]
          logger.info(`[${className}] user inventory updates`, updates)
          self.doUpdateEvent(updates)
          return
        }
      }
    }
  }))
  .actions(self => ({
    async startSockets() {
      logger.info(`[${className}] startSockets`)
      const accessToken = self.vatomAuth?.accessToken ?? ''
      VatomIncWebSocketManager.shared.connect()
      VatomIncWebSocketManager.shared.authenticate(accessToken)

      VatomIncWebSocketManager.shared.addEventListener('receivedEvent', self.handleReceivedEvent)
    }
  }))
  .actions(self => ({
    async load(): Promise<void> {
      logger.info(
        '[VatomInventoryRegion.new.load] init isLoggedIn',
        self.rootStore.dataPool.sessionStore.isLoggedIn
      )

      if (self.isLoading && self.isFetching) {
        logger.warn('[VatomInventoryRegion.new.load] we already loading...')
        return
      }

      try {
        // Start the websocket server if it is not running
        await self.fetchPage()
        self.startSockets()
      } catch (err) {
        console.log('LOG: VatomInventoryRegion.load > err:', err)
      } finally {
        logger.info('[VatomInventoryRegion.new.load] loaded')
      }
    }
  }))
  .actions(self => ({
    async reload(): Promise<void> {
      self.resetKeys()
      await self.load()
    },
    async performAction<T extends keyof PerformActionActionMap>(
      action: T,
      payload: PerformActionActionMap[T]
    ) {
      console.log('performAction', action, payload)
      if (action === 'Acquire') {
        return await self.inventoryApi.post(`/vatoms/${payload.tokenId}/execute-action`, {
          behaviorId: 'acquirable-v1',
          actionId: 'acquire-v1'
        })
      }
      console.error(`Action ${action} not implemented`)
      throw new Error(`Action ${action} not implemented`)
    }
  }))
  .actions(self => ({
    afterCreate() {
      // self.setIsLoading(true)
    }
  }))
  .actions(self => ({
    beforeDestroy() {
      logger.info(`[${className}] beforeDestroy`)
      VatomIncWebSocketManager.shared.removeEventListener('receivedEvent', self.handleReceivedEvent)
    }
  }))

type SocketEvent = CustomEvent<{
  detail: { eventType: string; eventData: Record<string, unknown> }
}>
interface UserInventoryUpdate {
  operation: 'add' | 'remove' | 'update'
  vatomId: string
}

export type InventoryRegionType = Instance<typeof InventoryRegionStore>
export type InventoryRegionSnapshotIn = SnapshotIn<typeof InventoryRegionStore>
export type InventoryRegionSnapshotOut = SnapshotOut<typeof InventoryRegionStore>

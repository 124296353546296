import React from 'react'
import { Text, TouchableOpacity } from 'react-native'

type ModalButtonProps = {
  action: () => void
  style?: object
  text: string
  textStyle?: object
}

const ModalButton = (props: ModalButtonProps) => {
  const { action, style, text, textStyle } = props
  // TODO: change action for onPress
  return (
    <TouchableOpacity
      accessibilityRole="button"
      onPress={() => action()}
      style={{ paddingRight: 16, paddingVertical: 11, position: 'absolute', right: 0, ...style }}
    >
      <Text style={{ fontSize: 15, lineHeight: 20, color: '#2EA7FF', ...textStyle }}>{text}</Text>
    </TouchableOpacity>
  )
}

export default ModalButton

import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = React.ComponentProps<typeof Icon>

export const ARIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 23 14" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M16.563 3.875a2.798 2.798 0 00-2.813 2.813A2.777 2.777 0 0016.563 9.5a2.798 2.798 0 002.812-2.813 2.82 2.82 0 00-2.813-2.812zm0 4.5c-.95 0-1.688-.738-1.688-1.688 0-.914.738-1.687 1.688-1.687.914 0 1.687.773 1.687 1.688 0 .949-.773 1.687-1.688 1.687zM20.5.5h-18A2.243 2.243 0 00.25 2.75v9c0 1.266.984 2.25 2.25 2.25h5.238c.88 0 1.688-.492 2.074-1.3l.563-1.302c.21-.457.633-.773 1.125-.773.457 0 .879.316 1.09.773l.562 1.301A2.289 2.289 0 0015.227 14H20.5c1.23 0 2.25-.984 2.25-2.25v-9C22.75 1.52 21.73.5 20.5.5zm1.125 11.25c0 .633-.527 1.125-1.125 1.125h-5.273c-.457 0-.844-.246-1.055-.633l-.563-1.3c-.386-.88-1.23-1.442-2.144-1.442-.88 0-1.723.563-2.11 1.441l-.597 1.301c-.176.387-.563.633-1.02.633H2.5a1.11 1.11 0 01-1.125-1.125v-9c0-.598.492-1.125 1.125-1.125h18c.598 0 1.125.527 1.125 1.125v9zM6.437 3.875a2.798 2.798 0 00-2.812 2.813A2.777 2.777 0 006.438 9.5 2.798 2.798 0 009.25 6.687a2.82 2.82 0 00-2.813-2.812zm0 4.5c-.949 0-1.687-.738-1.687-1.688C4.75 5.774 5.488 5 6.438 5c.914 0 1.687.773 1.687 1.688 0 .949-.773 1.687-1.688 1.687z"
    />
  </Icon>
)

import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import ArrowRight from '../../assets/arrow-icons/right-arrow-icon.svg'
import { useCommunitiesTheme } from '../../themes'

const ArrowRightIcon = ({ style }: { style?: StyleProp<ViewStyle> }) => {
  const communitiesTheme = useCommunitiesTheme()
  return <ArrowRight style={style} fill={communitiesTheme.labelIconColor} />
}

export default ArrowRightIcon

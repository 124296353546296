// Workarounds for hifi api expecting to be in the browser
// if (!window.addEventListener) window.addEventListener = noop
// if (!window.removeEventListener) window.removeEventListener = noop
// @ts-ignore
// if (!global.document) global.document = window.document
// if (!window.document) window.document = global.document
// if (!window.document.addEventListener) window.document.addEventListener = noop
// if (!window.document.removeEventListener) window.document.removeEventListener = noop
// if (!navigator.mediaDevices.getSupportedConstraints)
//   navigator.mediaDevices.getSupportedConstraints = function () {
//     return { deviceId: true, sampleRate: true }
//   }

// Oh god, the WebRTC library is really missing a lot of features... See https://github.com/react-native-webrtc/react-native-webrtc/issues/1005
import {
  AvailableUserDataSubscriptionComponents,
  HiFiAudioAPIData,
  HiFiCommunicator,
  HiFiConnectionStates,
  Point3D,
  Quaternion as HiFiQuaternion,
  UserDataSubscription
} from '@vatom/hifi-spatial-audio'

import { getLoginInfo } from '../functions'

import Mixers from './Mixers'

if (!RTCPeerConnection.prototype.getSenders)
  RTCPeerConnection.prototype.getSenders = function () {
    return []
  }
if (!RTCPeerConnection.prototype.addTrack)
  // @ts-ignore
  RTCPeerConnection.prototype.addTrack = function (track) {
    // @ts-ignore
    this.addStream(new MediaStream([track]))
  }

let lastMixerIndex = 0

/** Possible mixer states */
export const MixerState = {
  /** Connecting */
  Connecting: 1,

  /** Connected */
  Connected: 2,

  /** Failed. Will retry connection soon. */
  Error: 3,

  /** Closed. */
  Closed: 4,

  /** Preparing a new mixer */
  Provisioning: 5
}

/**
 * Represents a connection to a single mixer.
 */
export default class Mixer {
  /** Mixer index, mainly used for console logs */
  index = lastMixerIndex++
  inputAudioTrack: any
  lastError: any
  communicator: any
  shutdownTimer: any
  reconnectTimer: any
  _lastStream?: MediaStream
  lastConnectionState?: HiFiConnectionStates

  /** Mixer ID */
  get id() {
    return this.config.id
  }

  /** Reference to the Mixers class */
  mixers?: Mixers

  /** Mixer config document on Firebase */
  config: any = {}

  /** Mixer's current state */
  state = MixerState.Closed

  /** Version string of the server */
  version = ''

  /** True if this is the primary mixer. Don't set this directly, call becomePrimary() instead. */
  isPrimary = false

  /** True if this mixer has been disposed */
  isDisposed = false

  /** True if the last connection error was because the mixer is over capacity. */
  get isOverCapacity() {
    return this.lastError?.isOverCapacity
  }

  /** Avatars from this mixer */
  avatars: any[] = []

  /** Muted state */
  _muted = true
  get muted() {
    return this._muted
  }
  set muted(v) {
    // Update connected device
    this._muted = v
    this.onAudioInputChanged()
  }

  /** Last received position */
  lastPosition = { x: 0, y: 0, z: 0 }

  /** Last received quaternion */
  lastQuaternion = { x: 0, y: 0, z: 0, w: 1 }

  userId?: string
  firebaseApp?: any

  /** Audio output stream */
  get audioOutputStream() {
    return this.communicator?.getOutputAudioMediaStream()
  }

  /** Constructor */
  constructor(firebaseApp?: any) {
    // Listen for audio input changes
    // AudioManager.addEventListener('input.changed', this.onAudioInputChanged)
    this.firebaseApp = firebaseApp
  }

  /** @private Connects to the mixer */
  async connect() {
    // Stop if disposed
    if (this.isDisposed) return

    // Stop if in the process of shutting down due to lack of owners
    if (this.shutdownTimer) return

    if (!this.mixers) return

    // Stop if already connected or connecting
    if (this.state == MixerState.Connected) return
    if (this.state == MixerState.Connecting) return

    // Cancel retry timer if it still is going
    if (this.reconnectTimer) clearTimeout(this.reconnectTimer)
    this.reconnectTimer = null

    // Set new state
    this.state = MixerState.Connecting
    this.version = ''

    // Catch errors
    try {
      // Create a JWT for our connection to the mixer
      const loginInfo = await getLoginInfo(
        this.mixers.serverID,
        this.mixers.dimensionID,
        this.config.id,
        this.firebaseApp
      )

      // Check if over capacity, and if so throw a catchable error
      if (loginInfo.data.isOverCapacity) {
        const err: any = new Error('The mixer is over capacity.')
        err.isOverCapacity = true
        throw err
      }

      // Setup custom STUN config using Xirsys
      // TODO: Once HiFi works in China on it's own, this can be removed
      const stunConfig = {
        stunUrls: ['stun:ws-turn4.xirsys.com'],
        turnUrls: [
          'turn:ws-turn4.xirsys.com:80?transport=udp',
          'turn:ws-turn4.xirsys.com:3478?transport=udp',
          'turn:ws-turn4.xirsys.com:80?transport=tcp',
          'turn:ws-turn4.xirsys.com:3478?transport=tcp',
          'turns:ws-turn4.xirsys.com:443?transport=tcp',
          'turns:ws-turn4.xirsys.com:5349?transport=tcp'
        ],
        turnUsername:
          'ByFitRqfy1ibiEQyY9A3-uOkzb0FD1Ygo31Q9wPF5iMjY58VH_N1QqlMg4DFNr7pAAAAAGM_GF12YXRvbWluYw==',
        turnCredential: '23099f72-45a1-11ed-8259-0242ac140004'
      }

      // Create communicator
      this.communicator = new HiFiCommunicator({
        customSTUNandTURNConfig: stunConfig,
        onConnectionStateChanged: this.onConnectionStateChanged.bind(this),
        onUsersDisconnected: this.onUsersDisconnected.bind(this),
        initialHiFiAudioAPIData: new HiFiAudioAPIData({
          position: new Point3D({
            x: this.lastPosition.x,
            y: this.lastPosition.y,
            z: this.lastPosition.z
          }),
          orientation: new HiFiQuaternion({
            x: this.lastQuaternion.x,
            y: this.lastQuaternion.y,
            z: this.lastQuaternion.z,
            w: this.lastQuaternion.w
          })
        }),
        connectionRetryAndTimeoutConfig: {
          autoRetryInitialConnection: false,
          autoRetryOnDisconnect: false
        }
      })

      // Add a user data subscription. This will let us know when other users change their position etc
      this.communicator.addUserDataSubscription(
        new UserDataSubscription({
          callback: this.onUserDataUpdated.bind(this),
          components: [
            AvailableUserDataSubscriptionComponents.VolumeDecibels, // <-- Lets us know their speaking volume
            AvailableUserDataSubscriptionComponents.Orientation, // <-- Lets us know their orientation
            AvailableUserDataSubscriptionComponents.Position // <-- Lets us know their position
          ]
        })
      )

      // Listen for WebRTC stats
      this.communicator.startCollectingWebRTCStats(this.onWebRTCStats.bind(this))

      // Connect!
      await this.communicator.connectToHiFiAudioAPIServer(
        loginInfo.data.jwt,
        loginInfo.data.stack || null
      )

      // Reset our primary mixer state, so that the audio can be reconnected by Mixers class
      this.isPrimary = false

      // Get info about the server
      const info = await this.communicator.getCommunicatorInfo()
      this.version = info.serverInfo?.build_version

      // Set audio track if we have one yet
      this._lastStream = undefined
      this.onAudioInputChanged()

      // Done
      console.debug(`[Mixer ${this.index}] Connection successful`)
      this.state = MixerState.Connected
      this.lastError = null

      // Notify updated
      this.mixers.emit('updated')
    } catch (err: any) {
      // Check if error is because of over capacity
      const errorText = err?.error || err?.message || ''
      if (errorText.includes('at capacity')) err.isOverCapacity = true

      // Update state
      this.state = MixerState.Error
      this.lastError = err

      // Close and remove the communicator
      // TODO: AudioManager.stopAudioStream(this.audioOutputStream)
      this.communicator?.disconnectFromHiFiAudioAPIServer()
      this.communicator = null

      // Start retry timer
      this.reconnectSoon()

      // Report error
      console.error(`[Mixer ${this.index}] Unable to connect: `, err)

      // Notify updated
      this.mixers.emit('updated')
      if (err.isOverCapacity)
        console.warn(`[Mixer ${this.index}] Unable to connect, server is at capacity.`, err)
      else
        console.warn(
          `[Mixer ${this.index}] Unable to connect, will attempt a reconnect soon. ${err.message}`,
          err
        )
    }
  }

  /** @private Called automatically when the mixer should be closed. */
  close() {
    // Stop listening
    this.isDisposed = true
    // AudioManager.removeEventListener('input.changed', this.onAudioInputChanged)

    // Remove mixer from the mixer list
    this.state = MixerState.Closed

    // Shutdown the session if it exists
    // TODO: AudioManager.stopAudioStream(this.audioOutputStream)
    this.communicator?.disconnectFromHiFiAudioAPIServer()
    this.communicator = null

    // Stop the reconnect timer
    if (this.reconnectTimer) clearTimeout(this.reconnectTimer)
    this.reconnectTimer = null

    // Remove all current avatars
    this.removeAllAvatars()
  }

  /** Attempt to reconnect soon */
  reconnectSoon() {
    // Stop if shutting down
    if (this.state == MixerState.Closed) return

    // Create connect timer
    if (!this.reconnectTimer)
      this.reconnectTimer = setTimeout(e => {
        // Stop if state changed in the mean time
        this.reconnectTimer = null
        if (this.state == MixerState.Closed) return

        // Try connect again
        this.connect()
      }, 5000)
  }

  /** @private Called if the user's audio input device changes */
  onAudioInputChanged = () => {
    // Stop if not connected
    if (!this.communicator) return

    if (!this.mixers) return

    // Set it if not muted
    const stream = this.muted ? undefined : this.mixers.micInputStream

    // Check if changed
    if (stream == this._lastStream) return

    // Set it, using the stereo mode if streaming from the device's output. Stereo mode is not "spatial" but does have distance attenuation, it's designed for music I guess...
    const useStereo = false //AudioManager.inputDeviceID == 'display'
    if (stream) this.communicator.setInputAudioMediaStream(stream, useStereo)
    this.communicator.setInputAudioMuted(!stream)
    this._lastStream = stream
    console.debug(`[Mixer ${this.index}] Upload stream ${stream ? 'set' : 'removed'}`, stream?.id)
  }

  /** Called by Map3D to show mixer stats in the debug overlay */
  getStats() {
    const flags = []
    if (this.state == MixerState.Connecting) flags.push('connecting')
    if (this.state == MixerState.Connected) flags.push('connected')
    if (this.state == MixerState.Error) flags.push('error')
    if (this.state == MixerState.Closed) flags.push('closed')
    if (this.isPrimary) flags.push('primary')
    if (this._lastStream) flags.push('sending')
    let txt = `
            Mixer ${this.index}: id=${this.id}
            Mixer ${this.index}: ${flags.join(' ')} peers=${this.avatars.length} version=${
      this.version || '?'
    }
        `.trim()

    // Get mixer state
    if (this.lastConnectionState == HiFiConnectionStates.Connected) txt += ' state=connected'
    else if (this.lastConnectionState == HiFiConnectionStates.Connecting) txt += ' state=connecting'
    else if (this.lastConnectionState == HiFiConnectionStates.Disconnected)
      txt += ' state=disconnected'
    else if (this.lastConnectionState == HiFiConnectionStates.Disconnecting)
      txt += ' state=disconnecting'
    else if (this.lastConnectionState == HiFiConnectionStates.Failed) txt += ' state=failed'
    else if (this.lastConnectionState == HiFiConnectionStates.Reconnecting)
      txt += ' state=reconnecting'
    else if (this.lastConnectionState == HiFiConnectionStates.Unavailable)
      txt += ' state=unavailable'
    else txt += ' state=?'

    // Add distance
    txt += ` distance=${Math.floor(this.config.distance)}m`

    // Add error status if any
    if (this.lastError)
      txt += `\nMixer ${this.index}: error=(${this.lastError.code || 0}) ${this.lastError.message}`

    // Done
    return txt
  }

  /** @private Set the avatar's position. Called by Mixers class. */
  setPosition(x: number, y: number, z: number) {
    // Store position for use when reconnecting
    this.lastPosition.x = x
    this.lastPosition.y = y
    this.lastPosition.z = z
    // this.lastQuaternion = orientationQuat

    // Stop if not connected
    if (this.state != MixerState.Connected) return

    // Send new positional data to the mixer
    this.communicator.updateUserDataAndTransmit({
      position: new Point3D({
        x: this.lastPosition.x,
        y: this.lastPosition.y,
        z: this.lastPosition.z
      }),
      orientation: new HiFiQuaternion({
        x: this.lastQuaternion.x,
        y: this.lastQuaternion.y,
        z: this.lastQuaternion.z,
        w: this.lastQuaternion.w
      })
    })
  }

  /** Called by the HiFiCommunicator when the connection state changes */
  onConnectionStateChanged(state: HiFiConnectionStates) {
    // Check if we have been disconnected
    console.debug(`[Mixer ${this.index}] Mixer state changed: ${state}`)
    this.lastConnectionState = state
    this.mixers?.emit('updated')
    if (state != HiFiConnectionStates.Disconnected) return

    // Workaround: The HF SDK auto reconnects, but all user data like providedUserID is undefined afterwards!
    // So, let's just shut down here and reconnect manually.
    // Update: This is still necessary it seems, since the HF SDK is no longer auto-reconnecting. 18 May 2021
    this.state = MixerState.Error
    this.lastError = new Error('Mixer disconnected, state: ' + state)

    // Close and remove the communicator
    // TODO: AudioManager.stopAudioStream(this.audioOutputStream)
    this.communicator?.disconnectFromHiFiAudioAPIServer()
    this.communicator = null

    // Remove all current avatars
    this.removeAllAvatars()

    // Start retry timer
    this.reconnectSoon()

    // Report error
    console.error(`[Mixer ${this.index}] Disconnected!`)
  }

  /** @private Remove all users */
  removeAllAvatars() {
    // Remove all
    // for (let avatar of this.avatars) {

    //     // Remove item
    //     Map3D.main.source.removeItem(avatar.id)

    //     // Refresh all avatars
    //     for (let otherAvatar of Map3D.main.source.avatars)
    //         if (otherAvatar.firebaseUserID == avatar.firebaseUserID)
    //             otherAvatar.consolidateAvatars()

    // }

    // Clear list
    this.avatars = []
  }

  /** Called by the HiFiCommunicator when a user's data is updated */
  onUserDataUpdated(updates: any) {
    // Stop if we've been disposed already
    if (this.isDisposed)
      return console.warn(`[Mixer ${this.index}] Ignoring updates received after closed.`)

    // Go through each update
    for (const update of updates) {
      // Stop if no user ID
      if (!update.providedUserID) {
        console.warn(`[Mixer ${this.index}] User update received for a user with no ID!`, update)
        continue
      }

      console.info(`[Mixer ${this.index}] User update received`, update, this.userId)
      // Skip if it's our own user
      if (update.providedUserID.includes(this.userId)) continue

      // Find existing avatar
      let avatar = this.avatars.find(a => a.firebaseUserID == update.providedUserID)
      if (!avatar) {
        // Create this new avatar
        console.debug(`[Mixer ${this.index}] User connected. id=${update.providedUserID}`)
        const id = `mixeruser:${this.index}:${update.providedUserID}`
        avatar = { id } //Map3D.main.source.addAvatar(id)
        avatar.properties = { id }
        avatar.firebaseUserID = update.providedUserID
        this.avatars.push(avatar)

        // Refresh all other avatars with the same user ID, in case they were hidden since ours took priority
        // for (let otherAvatar of Map3D.main.source.avatars)
        //     if (otherAvatar.firebaseUserID == avatar.firebaseUserID)
        //         otherAvatar.refreshUserInfo()
      }

      // If position exists, move the avatar
      if (update.position) {
        // Cancel old tween
        // if (avatar.moveTween) avatar.moveTween.stop()
        // avatar.moveTween = null

        // Check if should tween
        // if (avatar.isOnscreen) {

        //     // Tween into the new position
        //     avatar.moveTween = new TWEEN.Tween(avatar.properties)
        //         .to({ x: update.position.x || 0, height: update.position.y || 0, y: update.position.z || 0 }, 250)
        //         .onUpdate(e => avatar.needsTransformUpdate = true)
        //         .start()

        // } else {

        // Not on screen, just move it immediately
        avatar.properties.x = update.position.x || 0
        avatar.properties.y = update.position.z || 0
        avatar.properties.height = update.position.y || 0
        avatar.needsTransformUpdate = true

        // }
      }

      // If rotation exists, set the orientation
      // if (update.orientation) {

      //     // Convert to euler
      //     let quaternion = TemporaryVars.quaternionA.set(update.orientation.x || 0, update.orientation.y || 0, update.orientation.z || 0, update.orientation.w || 0)
      //     let euler = TemporaryVars.vector3a
      //     quaternion.toEulerAnglesToRef(euler)
      //     // if (!this.tempQuat1) this.tempQuat1 = new BABYLON.Quaternion()
      //     // if (!this.tempEuler1) this.tempEuler1 = new BABYLON.Vector3()
      //     // this.tempQuat1.set(update.orientation.x || 0, update.orientation.y || 0, update.orientation.z || 0, update.orientation.w || 0)
      //     // this.tempQuat1.toEulerAnglesToRef(this.tempEuler1)

      //     // Store orientation
      //     // TODO: Why is this orientation inverted??
      //     avatar.orientation = -euler.y

      // }

      // Check for volume changes
      if (update.volumeDecibels) {
        // Update volume
        avatar.volume = update.volumeDecibels

        // Keep the connection active since people are talking
        // Map3D.main.inactivity.lastActivity = Date.now()
      }
    }
  }

  /** Called by the HiFiCommunicator when a user is disconnected */
  onUsersDisconnected(updates: any) {
    // Go through each one
    for (const update of updates) {
      // Skip if it's our own user
      if (update.providedUserID == this.userId) continue

      // Find avatar
      const avatar = this.avatars.find(a => a.firebaseUserID == update.providedUserID)
      if (!avatar) {
        console.warn(
          `[Mixer ${this.index}] User disconnected, but there is no avatar for them. id=${update.providedUserID}`
        )
        continue
      }

      // Remove this avatar
      console.debug(`[Mixer ${this.index}] User disconnected. id=${update.providedUserID}`)
      this.avatars = this.avatars.filter(i => i != avatar)
      // Map3D.main.source.removeItem(avatar.id)

      // Refresh other avatars with the same user ID, in case they were hidden since ours took priority
      // for (let otherAvatar of Map3D.main.source.avatars)
      //     if (otherAvatar.firebaseUserID == avatar.firebaseUserID)
      //         otherAvatar.refreshUserInfo()
    }
  }

  /** Called by the HiFiCommunicator when WebRTC stats are updated. This is called continuously. */
  onWebRTCStats(info: any, lastInfo: any) {
    // console.log('HF WEBRTC', info, lastInfo)
  }
}

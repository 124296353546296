import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useBusiness } from '@vatom/sdk/react'

import { WebWrapper } from '../../components/WebWrapper'
import { withBusinessSelector } from '../../hooks/useBusinessSelector'
import { useIsDesktop } from '../../hooks/useIsDesktop'

import { useTokenBusiness } from './useNftDetail/useTokenBusiness'
import NFTDetail, { NFTDetailProps } from './NFTDetail'

export default withBusinessSelector((props: NFTDetailProps) => {
  const ref = useRef(null)
  const navigation = useNavigation()
  const isDesktop = useIsDesktop()

  const businessIdOrName = props.route.params.business
  const { data: business } = useBusiness({
    business: businessIdOrName,
    businessId: businessIdOrName
  })
  const { themePage } = useTokenBusiness({ business })

  const handleEscPress = useCallback(
    (event: KeyboardEvent) => {
      if (ref.current && event.key === 'Escape') {
        event.stopPropagation()
        navigation.goBack()
      }
    },
    [navigation]
  )

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0
    })
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleEscPress)
    return () => {
      document.removeEventListener('keydown', handleEscPress)
    }
  }, [handleEscPress])

  return (
    <WebWrapper
      justifyContent={'center'}
      ref={ref}
      backgroundColor={themePage?.background}
      borderRadius={isDesktop ? 8 : undefined}
      overflow={'hidden'}
      flexGrow={1}
      maxHeight={'100%'}
    >
      <NFTDetail {...props} />
    </WebWrapper>
  )
})

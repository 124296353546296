import React from 'react'
import { GradientButton, Text, theme } from '@vatom/wombo'
import { Pressable, Spinner, useColorMode, useColorModeValue } from 'native-base'

interface IProcessButtonProps {
  onPress: () => void
  isLoading: boolean
  isDisabled: boolean
  label: string
}

export const ProcessButton = ({ onPress, isLoading, isDisabled, label }: IProcessButtonProps) => {
  const spinnerColor = useColorModeValue('#000', '#fff')

  const baseStyles = {
    _light: {
      backgroundColor: theme.colors.brand[500]
    },
    _dark: {
      backgroundColor: theme.colors.brand[700]
    }
  }
  const disabledStyles = {
    _light: {
      backgroundColor: theme.colors.grayCool[600]
    },
    _dark: {
      backgroundColor: theme.colors.grayDarkMode[700]
    }
  }
  const buttonStyles = isDisabled ? disabledStyles : baseStyles

  return (
    <Pressable
      accessibilityRole="button"
      w="100%"
      p={2}
      rounded={'sm'}
      justifyContent="center"
      onPress={onPress}
      disabled={isDisabled}
      {...buttonStyles}
    >
      {isLoading ? (
        <Spinner color={spinnerColor} fontSize="18px" py={3} />
      ) : (
        <Text
          opacity={isDisabled ? 0.7 : 1}
          _dark={{
            color: 'white'
          }}
          _light={{
            color: 'white'
          }}
          fontFamily="Inter-SemiBold"
          alignSelf="center"
          py={2}
          fontSize="18px"
        >
          {label}
        </Text>
      )}
    </Pressable>
  )
}
export default ProcessButton

import React from 'react'
import { createStackNavigator, StackScreenProps } from '@react-navigation/stack'

import { withBusinessSelector } from '../../hooks/useBusinessSelector'
import { AppStackParamList } from '../../navigators'
import { ValueOf } from '../../types/ValueOf'
import CoinConfirmation from '../CoinConfirmation'
import CoinDetail from '../CoinDetail'
import CoinTransfer from '../CoinTransfer'
import ConfirmationWeb3 from '../ConfirmationWeb3'
import RecipientsScreen from '../Recipients'

export enum CoinStackRoutes {
  CoinDetail = 'CoinDetail',
  CoinTransfer = 'CoinTransfer',
  CoinRecipients = 'CoinRecipients',
  CoinConfirmation = 'CoinConfirmation',
  CoinConfirmationWeb3 = 'CoinConfirmationWeb3'
}

export type CoinStackParamList = {
  CoinDetail: {
    coinId: string
  }
}

export type CoinNavigatorParamList = Record<
  keyof typeof CoinStackRoutes,
  ValueOf<CoinStackParamList> | undefined
>

const Stack = createStackNavigator<CoinNavigatorParamList>()

type CoinNavigationProps = StackScreenProps<AppStackParamList, 'coin' | 'CoinDetail_Business'>

const CoinNavigation = ({ route }: CoinNavigationProps) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        cardStyle: {
          backgroundColor: 'transparent'
        }
      }}
      initialRouteName={CoinStackRoutes.CoinDetail}
    >
      <Stack.Screen
        initialParams={route.params}
        name={CoinStackRoutes.CoinDetail}
        // @ts-expect-error not sure how to fix this
        component={CoinDetail}
      />
      <Stack.Screen name={CoinStackRoutes.CoinTransfer} component={CoinTransfer} />
      <Stack.Screen name={CoinStackRoutes.CoinRecipients} component={RecipientsScreen} />
      <Stack.Screen name={CoinStackRoutes.CoinConfirmation} component={CoinConfirmation} />
      <Stack.Screen name={CoinStackRoutes.CoinConfirmationWeb3} component={ConfirmationWeb3} />
    </Stack.Navigator>
  )
}

const CoinNavigationBusiness = withBusinessSelector(CoinNavigation)

export { CoinNavigationBusiness as CoinNavigation }

import React, { useState } from 'react'
import { MaterialIcons } from '@expo/vector-icons'
import { useMutation } from '@tanstack/react-query'
import { useIdentities, usePatchAddressFromIdentities, useSDK } from '@vatom/sdk/react'
import { GradientButton, Loader, PressableOpacity, Text, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, FormControl, Icon, Input, Pressable, WarningOutlineIcon } from 'native-base'

import BackIcon from '../../assets/back.svg'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { AppRoutes, AppStackScreenProps } from '../../navigators'
import { useWalletStore } from '../BlockChainWallets/WalletStore'

type EditAddressNameProps = AppStackScreenProps<typeof AppRoutes.EditAddressName>

export const EditAddressName = observer(({ route, navigation }: EditAddressNameProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const [name, setName] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const { isDark } = useBusinessTheme()
  const sdk = useSDK()
  const { identities } = useIdentities()
  const identity = identities.find(
    i => i.value.toLowerCase() === route.params.address.toLowerCase()
  )
  const patchIdentityMutation = usePatchAddressFromIdentities()
  useWalletStore.getState().setSelectedAddress(route.params.address)

  const editNameMutation = useMutation({
    mutationFn: async () => {
      if (useWalletStore.getState().selectedAddress) {
        useWalletStore.getState().updateAddressName(route.params.address, name)
      }
      if (identity) {
        await patchIdentityMutation.mutateAsync({
          params: {
            value: identity.value,
            type: identity.type
          },
          payload: {
            name
          }
        })
        sdk.dataPool.user.fetchIdentities()
      }

      navigation.goBack()
    },
    onError: () => {
      setErrorMsg('Something went wrong, please try again later.')
    }
  })

  const togglePasswordVisibility = () => setShowPassword(!showPassword)

  return (
    <Box flex={1} safeArea={true}>
      <Box mb="20px">
        <PressableOpacity
          hitSlop={{ top: 20, left: 20, bottom: 20, right: 20 }}
          accessibilityRole="button"
          onPress={() => navigation.goBack()}
        >
          <BackIcon
            height={40}
            width={40}
            fill={!isDark ? theme.colors.darkText : theme.colors.white}
          />
        </PressableOpacity>
      </Box>
      <Box height="100%" w="100%" flex={1} px="16px">
        <Box flex={1} justifyContent="flex-start" alignItems="center" height="100%">
          <Text
            marginBottom="20px"
            color={!isDark ? theme.colors.darkText : theme.colors.white}
            fontFamily="Inter-Bold"
            fontSize="34px"
            textAlign="left"
            alignSelf="flex-start"
            lineHeight="41px"
            tx="editAddressName.title"
          />

          <Text
            w="100%"
            fontFamily="Inter"
            fontSize="15px"
            textAlign="left"
            mb="20px"
            color={!isDark ? theme.colors.darkText : theme.colors.white}
            tx="editAddressName.description"
          />

          <FormControl isInvalid={errorMsg !== ''} w="100%" maxW="100%" py={2}>
            <Input
              size="lg"
              value={name}
              onChangeText={setName}
              placeholder={'Enter new address nickname'}
              type={'text'}
              InputRightElement={
                <Pressable accessibilityRole="button" onPress={togglePasswordVisibility}>
                  <Icon
                    as={<MaterialIcons name={showPassword ? 'visibility' : 'visibility-off'} />}
                    size={5}
                    mr="2"
                    color="muted.400"
                  />
                </Pressable>
              }
            />

            <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
              {errorMsg}
            </FormControl.ErrorMessage>
          </FormControl>
        </Box>

        <Box justifyContent="center" width="100%" pb={20}>
          <GradientButton
            disabled={editNameMutation.isLoading}
            onPress={() => editNameMutation.mutate()}
            h="52px"
            w="100%"
            py="15px"
            px="30px"
            alignItems="center"
          >
            {editNameMutation.isLoading ? (
              <Loader size={30} color="white" />
            ) : (
              <Text
                alignSelf="center"
                fontFamily="Inter"
                color="white"
                fontSize={16}
                lineHeight={22}
                tx="common.save"
              />
            )}
          </GradientButton>
        </Box>
      </Box>
    </Box>
  )
})

export default EditAddressName

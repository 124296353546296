import React from 'react'
import { Text } from '@vatom/wombo'
import { Box, Image, Pressable } from 'native-base'

export interface IActionItem {
  onPress: () => void
  title: string
}

export const ActionItem = ({ onPress, title }: IActionItem) => {
  return (
    <Box borderColor="gray.100" bgColor="white" borderBottomWidth={1} h={44} px={4} py={3}>
      <Pressable
        justifyContent="space-between"
        flexDir="row"
        accessibilityRole="button"
        onPress={onPress}
      >
        <Text fontSize={15} fontWeight="400" color="black">
          {title}
        </Text>
        <Pressable accessibilityRole="button">
          <Image
            source={require('../assets/Goto.png')}
            h={5}
            resizeMode="contain"
            accessibilityIgnoresInvertColors={true}
            alt="Go To"
          />
        </Pressable>
      </Pressable>
    </Box>
  )
}

export default ActionItem

import { WebWrapper } from '../../components/WebWrapper'
import { withBusinessSelector } from '../../hooks/useBusinessSelector'
import { DesktopHeader, HeaderDesktopProvider } from '../Home/partials/DesktopHeader'

import { ProfileOtherUser, ProfileOtherUserProps } from './ProfileOtherUser'

export default withBusinessSelector((props: ProfileOtherUserProps) => (
  <HeaderDesktopProvider>
    {!props?.route?.params?.showSharedSpaces && !props?.route?.params?.spaceId ? (
      <DesktopHeader />
    ) : null}
    <WebWrapper flex={1} flexGrow={1}>
      <ProfileOtherUser {...props} />
    </WebWrapper>
  </HeaderDesktopProvider>
))

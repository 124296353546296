/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
import React, { useCallback, useMemo } from 'react'
import { Platform } from 'react-native'
import {
  createMaterialTopTabNavigator,
  MaterialTopTabBarProps
} from '@react-navigation/material-top-tabs'
import { useNavigation } from '@react-navigation/native'
import { useConfig } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { theme } from '@vatom/wombo'

import { TabBar } from '../../../components/CommonHeader/TabBar'
import { ScreenHeaderWrapper } from '../../../components/ScreenHeaderWrapper'
import { ScreenWrapper } from '../../../components/ScreenWrapper'
import { useBusinessSelector } from '../../../hooks/useBusinessSelector'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../../hooks/useIsDesktop'
import { AppRoutes, WalletRoutes } from '../../../navigators'
import CoinsScreen from '../../Coins'
import WalletScreen from '../../Wallet'
import { FabScanQr } from '../../Wallet/partials/FabScanQr'
import { WalletHeaderRight } from '../../Wallet/partials/WalletHeader'

import { DrawerSelectorButton } from './DrawerSelectorButton'
import { ThemedHeaderWrapper } from './ThemedHeader'

const Tab = createMaterialTopTabNavigator()

const WalletNavigatorTabsHeader = (props: MaterialTopTabBarProps) => {
  const isDesktop = useIsDesktop()
  return (
    <ScreenHeaderWrapper
      centerContainerProps={{
        flex: 1
      }}
      centerComponent={!isDesktop && <TabBar {...props} />}
      rightContainerProps={{
        // Force push to the right to allow the tab bar header to be centered
        right: 2,
        zIndex: 2
      }}
      rightComponent={<WalletHeaderRight />}
      leftContainerProps={{
        left: 1,
        zIndex: 2
      }}
      leftComponent={<DrawerSelectorButton />}
    />
  )
}

const WalletNavigatorHeader = () => {
  const { business } = useBusinessSelector()
  const isDesktop = useIsDesktop()
  // const { config, isEmbedded } = useVatomWalletSdkStore()
  const config = useConfig()

  // if (isEmbedded && config.hideDrawer) {
  if (config.features.hideDrawer) {
    return null
  }

  if (business?.id && !isDesktop) {
    // Maybe custom Header for business
    return <ThemedHeaderWrapper />
  }
  return (
    <ScreenHeaderWrapper
      // centerContainerProps={}
      // centerComponent={}
      // rightContainerProps={}
      // rightComponent={}
      leftContainerProps={{
        position: 'absolute',
        left: 1,
        zIndex: 2
      }}
      leftComponent={<DrawerSelectorButton />}
    />
  )
}

const WalletWithTabs = () => {
  const { isDark } = useBusinessTheme()
  return (
    <Tab.Navigator
      initialRouteName={WalletRoutes.NFTs}
      screenOptions={{
        lazy: true,
        swipeEnabled: Platform.OS === 'ios'
      }}
      sceneContainerStyle={{
        backgroundColor: isDark ? theme.colors.grayCool[900] : 'transparent'
      }}
      tabBar={props => <WalletNavigatorTabsHeader {...props} />}
    >
      <Tab.Screen
        options={{ tabBarLabel: translate('common.wallet') }}
        name={WalletRoutes.NFTs}
        component={WalletScreen}
      />
      <Tab.Screen
        options={{ tabBarLabel: translate('common.coins') }}
        name={WalletRoutes.Coins}
        component={CoinsScreen}
      />
    </Tab.Navigator>
  )
}

const WalletStandard = () => {
  return (
    <>
      <WalletNavigatorHeader />
      <WalletScreen />
    </>
  )
}

export const WalletNavigator = () => {
  const navigation = useNavigation()
  const isDesktop = useIsDesktop()
  // const isEmbedded = useVatomWalletSdkStore(state => state.isEmbedded)
  // const config = useVatomWalletSdkStore(state => state.config)
  const config = useConfig()
  const { businessIdentifier } = useBusinessSelector()
  const { pageTheme } = useBusinessTheme()

  const isScanQrEnabled = !!config.features.screensConfig?.Wallet?.scanner && !isDesktop

  const onPressScanQr = useCallback(() => {
    navigation.navigate(AppRoutes.CameraScreen)
    return
  }, [navigation])

  const WalletToRender = useMemo(
    () => (businessIdentifier ? WalletStandard : WalletWithTabs),
    [businessIdentifier]
  )

  return (
    <ScreenWrapper
      unsafe
      // screenBackgroundColor={brandTheme?.background ?? pageTheme.background}
      // statusBarBackgroundColor={brandTheme?.primary ?? undefined}
      screenBackgroundColor={pageTheme.background}
      statusBarBackgroundColor={undefined}
    >
      {Platform.OS !== 'web' ? (
        <FabScanQr enabled={isScanQrEnabled} onPress={onPressScanQr} />
      ) : null}
      <WalletToRender />
    </ScreenWrapper>
  )
}

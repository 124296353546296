import React, { useEffect, useState } from 'react'
import { Pressable, ScrollView } from 'react-native'
import {
  ContactSources,
  Filters,
  getAlchemyClient,
  useAddRecentContact,
  useAddressesBalance,
  useGetContacts,
  useTokenInventory,
  useTransactionData,
  useTransactionGasEstimates,
  useWeb3New,
  validateDestination,
  web3ChainListToAlchemyNetwork
} from '@vatom/sdk/react'
import { Button, GradientButton, LoaderView, Status, Text, theme, Toast } from '@vatom/wombo'
import { Network, TransactionRequest, Utils, Wallet } from 'alchemy-sdk'
// import { ethers } from 'ethers'
import { Box, FlatList, Radio, useColorModeValue } from 'native-base'

import { getWallet, retrieveAndDecrypt } from '../../../../modules/web3-wallet'
import CloseIcon from '../../assets/close.svg'
import { AppRoutes, AppStackScreenProps } from '../../navigators'
import { useWalletStore } from '../BlockChainWallets/WalletStore'

import ActionSheetConfirmTransfer from './partials/ActionSheetConfirmTransfer'
import Details from './partials/details'

export type ISendNFTConfirmationProps = AppStackScreenProps<typeof AppRoutes.sendNFTConfirmation>

export const SendNFTConfirmation = ({ route, navigation }: ISendNFTConfirmationProps) => {
  const { contractAddress, owner, tokenId, toAddress, toUserId } = route.params
  const [selectedAddress, setSelectedAddress] = useState(toAddress ?? '')
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const options = contractAddress && owner ? { contractAddress, owner } : undefined
  const { token, isLoading } = useTokenInventory(tokenId, options)
  const iconColor = useColorModeValue(theme.colors.grayDarkMode[900], theme.colors.white)
  const isSelfCustodial = useWalletStore(walletState =>
    Boolean(
      walletState.wallets.find(wallet => wallet.address.toLowerCase() === owner.toLowerCase())
    )
  )
  const ownerWalletAddress = useWalletStore(walletState =>
    walletState.wallets.find(wallet => wallet.address.toLowerCase() === owner.toLowerCase())
  )

  const addRecentContactMutation = useAddRecentContact()

  /* @ts-expect-error not listed everything */
  const alchemyNetwork = web3ChainListToAlchemyNetwork[token?.network] as Network

  const transactionData = useTransactionData({
    network: alchemyNetwork,
    contractType: token?.contractType?.toLowerCase() ?? 'erc721',
    contractAddress,
    toAddress: selectedAddress.toLowerCase(),
    fromAddress: owner.toLowerCase(),
    amount: 1,
    tokenId: tokenId
  })
  const gasEstimates = useTransactionGasEstimates({
    fromAddress: owner,
    contractAddress,
    network: alchemyNetwork,
    data: transactionData.data
  })
  const coins = useAddressesBalance({
    addresses: [owner.toLowerCase()],
    networks: [alchemyNetwork]
  })

  const filters: Filters = {
    tokenType: undefined,
    sources: [
      ContactSources.userdirectory,
      ContactSources.tokenhistory,
      ContactSources.devicecontacts,
      ContactSources.followers,
      ContactSources.following
    ]
  }

  const destination = validateDestination(toUserId ?? '', true)
  const contacts = useGetContacts(destination, filters)
  const contact = contacts.data.find(c => c.id === toUserId)

  const toUserName = contact?.name ?? toAddress ?? ''
  const toUserImageUri = contact?.avatarUrl ?? ''

  const identitiesAddresses = [
    ...(contact?.identities?.filter(i => i.type === 'eth' && !i.isCustodial).map(i => i.value) ??
      [])
  ]
  const addressesArray = toAddress ? [...identitiesAddresses, toAddress] : identitiesAddresses
  const toAddresses = Array.from(new Set(addressesArray))

  useEffect(() => {
    if (toAddresses.length === 1) {
      setSelectedAddress(toAddresses[0])
    }
  }, [toAddresses])

  const onPressSendNFT = () => {
    if (selectedAddress !== '') {
      setIsConfirmOpen(true)
    } else {
      if (!toAddresses.length) {
        alert('Not allowed to send to this contact. No valid address.')
      } else {
        alert('Select a valid address')
      }
    }
  }
  const onPressConnect = async () => {
    if (!web3.isConnected) {
      await web3.open()
      return
    }
  }

  const onSendFromSelfCustodial = async () => {
    try {
      const contractType = token?.contractType?.toLowerCase() ?? 'erc721'

      if (!['erc1155', 'erc721'].includes(contractType)) {
        throw new Error(`Contract Type Not Supported ${contractType}`)
      }

      const alchemy = getAlchemyClient(alchemyNetwork)

      const seeds = await retrieveAndDecrypt(ownerWalletAddress?.address ?? '')
      const walletDetails = await getWallet(seeds)

      const nonce = await alchemy.core.getTransactionCount(walletDetails.address, 'latest')
      const alchemyWallet = new Wallet(walletDetails.privateKey, alchemy)
      const network = await alchemy.core.getNetwork()

      const transaction: TransactionRequest = {
        to: contractAddress,
        data: transactionData.data,
        gasLimit: Number(gasEstimates.data?.gasEstimate),
        gasPrice: gasEstimates.data?.gasPrice,
        nonce: nonce,
        chainId: network.chainId
      }

      const rawTransaction = await alchemyWallet.signTransaction(transaction)
      await alchemy.core.sendTransaction(rawTransaction)

      Toast({
        title: 'Transfer Complete. It may take a while to finish.',
        placement: 'top',
        status: Status.success,
        withMargin: true
      })

      if (contact) {
        addRecentContactMutation.mutate(contact)
      }

      navigation.navigate(AppRoutes.home)
    } catch (error) {
      if (error instanceof Error && 'code' in error && 'reason' in error) {
        alert(`${error.reason}. Code: ${error.code}`)
      } else {
        alert('Something went wrong')
      }

      setIsConfirmOpen(false)
    }
  }

  const onSendFromWatching = () => {
    alert('Not yet implemented')
  }

  const web3 = useWeb3New('eth')

  if (isLoading) {
    return <LoaderView />
  }

  return (
    <Box
      _light={{
        backgroundColor: theme.colors.white
      }}
      _dark={{
        backgroundColor: theme.colors.grayDarkMode[900]
      }}
      height="100%"
      safeArea
      alignItems={'flex-start'}
    >
      <ActionSheetConfirmTransfer
        toAddress={selectedAddress}
        nftName={token?.metadata.name ?? ''}
        isOpen={isConfirmOpen}
        setOpen={setIsConfirmOpen}
        onSend={isSelfCustodial ? onSendFromSelfCustodial : onSendFromWatching}
      />
      <Button
        padding={2}
        onPress={() =>
          navigation.canGoBack() ? navigation.goBack() : navigation.navigate(AppRoutes.home)
        }
        bgColor="transparent"
        alignSelf="flex-start"
      >
        <CloseIcon height={40} width={40} fill={iconColor} />
      </Button>
      <ScrollView style={{ margin: 'auto' }}>
        <Box
          width={'100%'}
          justifyContent={toAddresses.length > 1 ? 'flex-start' : 'space-around'}
          alignItems="center"
          justifyItems="center"
          alignContent="center"
          height="100%"
        >
          <Box marginTop={2} marginBottom={2}>
            {token && (
              <Details token={token} toUserName={toUserName} toUserImageUri={toUserImageUri} />
            )}
            {isSelfCustodial && (
              <>
                <Text alignSelf="center">Estimated Gas Fee</Text>
                <Text alignSelf="center">
                  {coins?.[0].data?.symbol}{' '}
                  {Number(
                    Utils.formatUnits(
                      gasEstimates.data?.gasEstimate.mul(gasEstimates.data?.gasPrice) ?? 0,
                      18
                    )
                  )}
                </Text>
              </>
            )}
          </Box>
          {toAddresses.length > 1 ? (
            <Box width="100%" h="175px" mb="100px">
              <Box alignItems="center" flexDir="row" justifyContent="space-between">
                <Text
                  color="darkText"
                  _dark={{ color: theme.colors.white }}
                  fontFamily="Inter-SemiBold"
                  fontSize="22px"
                >
                  Select Address
                </Text>
              </Box>
              <Radio.Group
                value={selectedAddress}
                name="walletAddressGroup"
                onChange={address => setSelectedAddress(address)}
                style={{
                  width: '100%',
                  height: '100%'
                }}
              >
                <FlatList
                  data={toAddresses}
                  style={{
                    width: '100%'
                  }}
                  renderItem={({ item, index }) => (
                    <Box
                      width="100%"
                      borderBottomWidth="1px"
                      borderBottomColor="light.100"
                      key={item}
                    >
                      <Pressable accessibilityLabel={item} accessibilityHint="">
                        <Box
                          aria-label="Close"
                          marginBottom="20px"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Text
                            ellipsizeMode="tail"
                            noOfLines={1}
                            fontFamily="Inter-Regular"
                            fontSize="11px"
                            // w="80%"
                          >
                            {item}
                          </Text>

                          <Radio
                            value={item}
                            aria-label="Close"
                            key={item}
                            accessibilityLabel={item}
                            accessibilityHint=""
                            alignSelf="flex-end"
                            mr="2px"
                          />
                        </Box>
                      </Pressable>
                    </Box>
                  )}
                  showsVerticalScrollIndicator={false}
                  keyExtractor={(item, index) => `identities-${index}`}
                />
              </Radio.Group>
            </Box>
          ) : null}
          {!isSelfCustodial ? (
            <GradientButton
              onPress={web3.isConnected ? onPressSendNFT : onPressConnect}
              w="100%"
              justifyContent="center"
            >
              <Text
                color="white"
                fontFamily="Inter-SemiBold"
                alignSelf="center"
                py={2}
                fontSize="18px"
              >
                {web3.isConnected ? 'Send NFT' : 'Connect'}
              </Text>
            </GradientButton>
          ) : (
            <GradientButton onPress={onPressSendNFT} w="100%" justifyContent="center">
              <Text
                color="white"
                fontFamily="Inter-SemiBold"
                alignSelf="center"
                py={2}
                fontSize="18px"
              >
                Send NFT
              </Text>
            </GradientButton>
          )}
        </Box>
      </ScrollView>
    </Box>
  )
}

export default SendNFTConfirmation

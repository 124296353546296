import React from 'react'
import { ImageSourcePropType, Platform } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { NftFilterBy } from '@vatom/sdk/core'
import { theme } from '@vatom/wombo'
import { Box, Divider, HStack, Icon, Image, Pressable, Text, VStack } from 'native-base'

import FullScreenDivider from '../../partials/FullScreenDivider'

export interface IFilterListItemProps {
  label: string
  iconSource: ImageSourcePropType
  key: NftFilterBy
  subtitle?: string
  topDivider?: boolean
  bottomDivider?: boolean
  withSpace?: boolean
  selected?: boolean
  small?: boolean
}

interface Props {
  data: IFilterListItemProps[]
  onItemPress: (s: NftFilterBy, t: string) => void
}

interface ItemListProps {
  item: IFilterListItemProps
  index: number
  countData: number
  onItemPress: (s: NftFilterBy, t: string) => void
}

export const RenderFilterListItem = ({ item, index, countData, onItemPress }: ItemListProps) => {
  const topDivider = item?.topDivider ?? false
  return (
    <>
      {topDivider ? <FullScreenDivider /> : null}
      <Pressable
        alignItems="center"
        accessibilityRole="button"
        onPress={() => onItemPress(item.key, item.label)}
      >
        <Box
          flexDir="row"
          width="100%"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          px="16px"
          _light={{
            backgroundColor: theme.colors.white
          }}
          _dark={{
            backgroundColor: theme.colors.grayDarkMode[900]
          }}
        >
          <HStack alignItems="center" h="48px" width="90%">
            {Platform.OS === 'web' ? (
              <div
                style={{
                  width: '20px',
                  marginRight: '8px'
                }}
              >
                <img
                  src={(item.iconSource as string) ?? ''}
                  alt={item.label}
                  style={{
                    width: '20px',
                    height: '20px',
                    objectFit: 'contain'
                  }}
                />
              </div>
            ) : (
              <Image
                accessibilityIgnoresInvertColors
                source={item.iconSource}
                w="20px"
                resizeMode="contain"
                alt={item.label}
                mr="8px"
                position="relative"
              />
            )}
            <VStack width="100%">
              {item.subtitle && item.subtitle !== '' ? (
                <Text
                  marginBottom="4px"
                  fontFamily="Inter-Medium"
                  fontSize="12px"
                  lineHeight="16px"
                  _light={{
                    color: theme.colors.textLightMode[600]
                  }}
                  _dark={{
                    color: theme.colors.textDarkMode[600]
                  }}
                >
                  {item.subtitle}
                </Text>
              ) : null}
              <Text
                ellipsizeMode="tail"
                noOfLines={1}
                fontFamily="Inter-Regular"
                fontSize={item.small ? '11px' : '15px'}
                pr={5}
                w="100%"
                lineHeight={item.small ? '15px' : '20px'}
                _light={{
                  color: theme.colors.textLightMode[900]
                }}
                _dark={{
                  color: theme.colors.textDarkMode[100]
                }}
              >
                {item.label}
              </Text>
            </VStack>
          </HStack>
          {item.selected && <Icon as={AntDesign} name="check" color="primary.500" w="14px" />}
        </Box>
        {index + 1 !== countData && <FullScreenDivider />}
      </Pressable>
      {item.withSpace && <Divider bgColor="transparent" h={2} />}
      {item.bottomDivider && <FullScreenDivider />}
    </>
  )
}

export const FilterList = ({ data, onItemPress }: Props) => {
  const countData = data.length
  return (
    <Box marginBottom="6px">
      {data.map((item, index) => {
        return (
          <RenderFilterListItem
            countData={countData}
            index={index}
            item={item}
            key={`render-filter-list-item-${index}`}
            onItemPress={onItemPress}
          />
        )
      })}
    </Box>
  )
}

export default FilterList

import React from 'react'
import DateTimePicker from 'react-datetime-picker'
import {
  Animated,
  Dimensions,
  Modal,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View
} from 'react-native'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import moment from 'moment-timezone'

import CloseIcon from '../../../../assets/header-icons/close-icon.svg'
import { getCombinedDateTimestamp } from '../../../../helpers'
import { useCommunitiesTheme } from '../../../../themes'
import ArrowRightIcon from '../../../modal-components/ArrowRightIcon'
import ModalText from '../../../modal-components/ModalText'
import {
  closeAnimation,
  ModalAnimatedWrapper,
  ModalGreyBackground,
  ModalHeaderWrapper,
  ModalSaveButton,
  ModalTitle,
  openAnimation
} from '../../../modalHelpers'
import { useStoreContext } from '../../../MSTContextProvider'
import PollLengthIcon from '../../assets/poll_length_icon.svg'
import { Input } from '../../stores'
import { DecorationData } from '../ModalDecorations'
import ModalDecorationWrapper from '../ModalDecorationWrapper'
import ModalError from '../ModalError'

import { getDisplayDate, ModalRow, ModalRowText, SelectedValueText } from './components'
import { generalToIANAMapping } from './constants'

const ModalEndTimeHolder = observer(({ decorationData }: { decorationData: DecorationData }) => {
  const store = useStoreContext()

  const dateInput = store.additionalInputs.find(({ type }) => type === decorationData.type)
  const startTimeInput = store.additionalInputs.find(({ type }) => type === 'start-time')

  React.useEffect(() => {
    store.addAdditionalInputs(decorationData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return dateInput && startTimeInput ? (
    <ModalDateTimePicker
      decorationData={decorationData}
      dateInput={dateInput}
      startTimeInput={startTimeInput}
    />
  ) : null
})

const ModalDateTimePicker = observer(
  ({
    decorationData,
    dateInput,
    startTimeInput
  }: {
    decorationData: DecorationData
    dateInput: Instance<typeof Input>
    startTimeInput: Instance<typeof Input>
  }) => {
    const store = useStoreContext()
    const [isModalVisible, setIsModalVisible] = React.useState(false)
    const startTimeInputValue = startTimeInput.value as number
    const endTimeInputValue = dateInput.value as number

    const dateInputValue = dateInput
      ? endTimeInputValue
        ? new Date(endTimeInputValue)
        : new Date()
      : new Date()

    const currentTimeZone = moment.tz.guess()
    const currentOffset = moment.tz(currentTimeZone).utcOffset()

    const [date, setDate] = React.useState(dateInputValue)
    const [time, setTime] = React.useState(dateInputValue)
    const [timezone, setTimezone] = React.useState({
      key: currentTimeZone,
      offset: currentOffset
    })
    const [showError, setShowError] = React.useState(false)
    const [errorData, setErrorData] = React.useState({
      errorTitle: '',
      errorDescription: ''
    })

    React.useEffect(() => {
      if (startTimeInputValue && dateInput.value === 0) {
        const startDate = new Date(startTimeInputValue)
        setDate(startDate)
        setTime(startDate)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateInput.value, startTimeInputValue])

    React.useEffect(() => {
      setTimezone(store.timezone)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.timezone])

    const fadeAnim = React.useRef(new Animated.Value(0)).current
    const slideAnim = React.useRef(new Animated.Value(1000)).current
    const animationDuration = 300

    const communitiesTheme = useCommunitiesTheme()

    const openModal = () => {
      setIsModalVisible(true)
      openAnimation(fadeAnim, slideAnim, animationDuration).start()
    }

    const closeModal = () => {
      closeAnimation(fadeAnim, slideAnim, animationDuration).start(() => {
        setIsModalVisible(false)
      })
    }

    const handleDone = () => {
      const timestamp = getCombinedDateTimestamp(date, time)

      if (dateInput) {
        if (startTimeInputValue > timestamp) {
          handleError('Error', 'You cannot select a smaller date than start date')
          return
        }

        dateInput.setValue(timestamp)
        store.setTimezone({
          key: timezone.key,
          offset: timezone.offset
        })
      }
      closeModal()
    }

    const onDateChange = (date?: Date) => {
      if (date) {
        setDate(date)
      }
    }

    const onTimeChange = (date?: Date) => {
      if (date) {
        setTime(date)
      }
    }

    const handleError = (errorTitle: string, errorDescription: string) => {
      setShowError(true)
      setErrorData({
        errorTitle,
        errorDescription
      })
    }

    const { height } = Dimensions.get('window')

    const getTimeZoneNote = () => {
      const tz = generalToIANAMapping[timezone.key as keyof typeof generalToIANAMapping]
      const offset = moment.tz(tz).utcOffset()
      const environmentOffset = offset - currentOffset

      let gmtString
      if (environmentOffset === 0) {
        gmtString = '(GMT)'
      } else {
        const hours = Math.floor(Math.abs(environmentOffset) / 60)
        const minutes = Math.abs(environmentOffset) % 60
        const sign = environmentOffset < 0 ? '-' : '+'
        gmtString = `(GMT${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(
          2,
          '0'
        )})`
      }

      return timezone.key + ' ' + gmtString
    }

    const isAndroid = Platform.OS === 'android'

    return (
      <>
        <ModalError
          showError={showError}
          errorTitle={errorData.errorTitle}
          errorDescription={errorData.errorDescription}
          setShowError={setShowError}
        />

        <ModalDecorationWrapper
          handler={() =>
            !startTimeInput.value
              ? handleError('Error', 'You cannot select the end time before start time')
              : openModal()
          }
        >
          <View style={styles.contentHolder}>
            <PollLengthIcon
              width="20"
              style={{ marginRight: 12 }}
              fill={communitiesTheme.labelIconColor}
            />
            <ModalText text={decorationData.placeholder} />
          </View>
          <View style={styles.contentHolder}>
            <SelectedValueText
              value={getDisplayDate(endTimeInputValue, store.timezone.offset, currentOffset)}
            />
            <ArrowRightIcon style={{ marginLeft: 12 }} />
          </View>
        </ModalDecorationWrapper>

        <Modal visible={isModalVisible && !showError} transparent={true} animationType="none">
          <ModalGreyBackground fadeAnim={fadeAnim} handler={closeModal} />

          <ModalAnimatedWrapper
            slideAnim={slideAnim}
            style={{ height: isAndroid ? height - 86 : height - 50, paddingTop: 19 }}
          >
            <ModalHeaderWrapper>
              <TouchableWithoutFeedback accessibilityRole="button" onPress={() => closeModal()}>
                <View>
                  <CloseIcon
                    fill={communitiesTheme.confirmationModal.title}
                    width={20}
                    height={20}
                  />
                </View>
              </TouchableWithoutFeedback>

              <ModalTitle placeholder={decorationData.placeholder} />

              <ModalSaveButton handler={handleDone} text="Done" />
            </ModalHeaderWrapper>

            <ScrollView>
              <ModalRow
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <ModalRowText text="Select End Date" />
                <View>
                  <DateTimePicker
                    onChange={value => {
                      onDateChange(value as Date)
                      onTimeChange(value as Date)
                    }}
                    value={date}
                    minDate={new Date()}
                    className={communitiesTheme.name}
                  />
                  <Text
                    style={{
                      fontSize: 13,
                      lineHeight: 18,
                      color: '#868E96',
                      marginRight: 12
                    }}
                  >
                    (
                    {getDisplayDate(
                      getCombinedDateTimestamp(date, time),
                      timezone.offset,
                      currentOffset
                    )}
                    )
                  </Text>
                </View>
              </ModalRow>

              <Text
                style={{
                  fontSize: 15,
                  marginBottom: 4,
                  color: communitiesTheme.labelTitleColor,
                  marginLeft: 16,
                  marginTop: 8
                }}
              >
                {getTimeZoneNote()}
              </Text>
              <View>
                <Text style={styles.timeZoneNote}>
                  Edit timezone in
                  <Text style={{ fontWeight: '700' }}> Scoring Starts </Text>
                  settings
                </Text>
              </View>
              <View style={{ height: 50 }}></View>
            </ScrollView>
          </ModalAnimatedWrapper>
        </Modal>
      </>
    )
  }
)

export default ModalEndTimeHolder

const styles = StyleSheet.create({
  text: {
    color: '#3F4A55',
    fontSize: 15,
    lineHeight: 20
  },
  contentHolder: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  flexRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  rowText: {
    fontSize: 15,
    lineHeight: 20
  },
  timeZoneNote: {
    fontSize: 11,
    color: '#868E96',
    fontWeight: '400',
    marginLeft: 16
  }
})

import * as React from 'react'
import { Image, Text, View } from 'native-base'

export const StoryCard = ({ image, name }: { image: string; name?: string }) => {
  return (
    <View style={{ margin: 10 }} alignItems="center" w={74}>
      <View
        accessibilityRole="button"
        style={{
          width: 74,
          height: 74,
          borderRadius: 37,
          borderWidth: 1,
          borderColor: '#EFEFEF',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Image
          alt="name"
          accessibilityIgnoresInvertColors={true}
          source={{ uri: image }}
          borderRadius={31}
          h={62}
          w={62}
          resizeMode="contain"
        />
      </View>
      {name && (
        <Text
          numberOfLines={1}
          style={{
            fontFamily: 'Inter-Medium',
            fontSize: 12,
            fontWeight: '500',
            textAlign: 'center',
            marginTop: 10
          }}
        >
          {name}
        </Text>
      )}
    </View>
  )
}
export default StoryCard

/* eslint-disable unicorn/filename-case */
import { theme } from '@vatom/wombo'

import { WebWrapper } from '../../components/WebWrapper'

import Coins from './Coins'

export default () => (
  <WebWrapper
    _light={{
      backgroundColor: theme.colors.white
    }}
    _dark={{
      backgroundColor: theme.colors.grayCool[900]
    }}
    maxWidth={'1000px'}
  >
    <Coins />
  </WebWrapper>
)

import {
  applyPatch,
  applySnapshot,
  Instance,
  recordPatches,
  SnapshotIn,
  SnapshotOut,
  types
} from 'mobx-state-tree'

import logger from '../../logger'
import { Token, TokenMap, TokenSnapshot, TokenType } from '../../models'
import { withEventEmitter } from '../EventEmitter'
import { withRootSDKStore } from '../RootSdk/extensions/withRootSDKStore'

interface VolatileRegion {
  _isLoading: boolean
  _syncPromise: Promise<any> | null
  tokenStash: TokenMap
  _isFetching: boolean
}

export type Coin = {
  id: string
  businessId: string
  name: string
  plural_name: string
  logo: string
  points: number
  enabled: boolean
  userId: string
  symbol: string | null
  type: string | null
  address: string | null
  isWeb3: boolean
}

export const className = 'RegionStore'

const blank = () => {
  return
}

const tokenStash: TokenMap = {} // Singleton Map

export const RegionIds = {
  inventory: 'inventory',
  geopos: 'geopos'
} as const
export type RegionTypes = keyof typeof RegionIds

export const RegionStore = types
  .model(className)
  .props({
    _id: types.identifier,
    _tokens: types.optional(types.map(Token), {}),
    id: types.enumeration('RegionType', Object.values(RegionIds)),
    className,
    descriptor: types.maybe(types.string)
  })
  .extend(withRootSDKStore)
  .extend(withEventEmitter)
  .volatile((_self): VolatileRegion => {
    return {
      _isLoading: true,
      _syncPromise: null,
      tokenStash,
      _isFetching: false
    }
  })
  .actions(self => ({
    setIsLoading(value: boolean) {
      self._isLoading = value
    },
    setIsFetching(value: boolean) {
      self._isFetching = value
    },
    addTokens(tokens: TokenSnapshot[]) {
      // const items = Array.from<TokenType>(self._tokens.values())
      // logger.info('[Region.addTokens]', self.className, items.length, '>>>', tokens.length)
      for (const obj of tokens) {
        const existing = self._tokens.get(obj.id)
        let skip = false
        if (existing) {
          console.log('addTokens() new token modified', obj.modified)
          console.log('addTokens() existing token modified', existing?.modified?.getTime())

          if (obj.modified && existing.modified) {
            const isNewer = obj.modified >= existing.modified.getTime()
            console.log('addTokens() Is it newer?', isNewer)
            if (isNewer) {
              console.log('addTokens() YES')
            } else {
              skip = true
              console.log('addTokens() NO')
            }
          } else {
            console.log(
              "addTokens() one of them doesn't have modified",
              obj.modified,
              existing.modified
            )
          }
        }

        if (!skip) {
          self._tokens.set(obj.id, obj)
        }
      }
    },
    setTokens(tokens: Array<SnapshotIn<typeof Token>>) {
      type SnapshotMap = Record<string, SnapshotIn<typeof Token>>
      const map = tokens.reduce<SnapshotMap>((acc, token) => {
        const id = token.id
        acc[id] = token
        return acc
      }, {})
      applySnapshot(self._tokens, map)
    },
    mergeTokens(tokens: TokenMap) {
      self._tokens.merge(tokens)
    },
    mergeTokenStash(tokenMap: TokenMap) {
      self.tokenStash = Object.assign(self.tokenStash, tokenMap)
    },
    copyFromStash({ limit }: { limit?: number }) {
      logger.info('[Region.copyFromStash] limit', limit)
      const keys = Object.keys(self.tokenStash)
      if (keys.length > 0) {
        if (limit) {
          // copy the limit amount
          const subset = keys.slice(0, limit)
          const subStash: TokenMap = {}
          subset.forEach(key => {
            subStash[key] = Object.assign({}, self.tokenStash[key])
            delete self.tokenStash[key]
            // this.mergeTokens(subStash)
            // console.log('Region.copyFromStash', key, subStash[key])
            // self._tokens.set(subStash[key].id, subStash[key])
          })
          // logger.info('[Region.copyFromStash]', subStash)
          this.mergeTokens(subStash)
        } else {
          // copy all
          this.mergeTokens(self.tokenStash)
          self.tokenStash = {}
        }
      }
    },
    removeTokens(ids: string[]) {
      logger.info('[Region.removeTokens]', ids.length)
      for (const id of ids) {
        if (self._tokens.has(id)) {
          // logger.info('[Region].removeTokens', id)
          self._tokens.delete(id)
        }
      }
    },
    /**
     * Start initial load. This should resolve once the region is up to date.
     *
     * @private Called by the Region superclass.
     * @abstract Subclasses should override this.
     * @returns {Promise<>} Once this promise resolves, the region should be stable.
     */
    async load(): Promise<string[] | null> {
      throw new Error(`Subclasses must override Region.load()`)
    },

    async fetchPage(args?: any) {
      throw new Error(`Subclasses must override Region.fetchPage()`)
    },

    /**
     * Reload. Force the region to reload.
     *
     * @private Called by the Region superclass.
     * @abstract Subclasses should override this.
     * @returns {Promise<>} Once this promise resolves, the region should be stable.
     */
    async reload(): Promise<string[] | null> {
      throw new Error(`Subclasses must override Region.reload(): ${self.className}, ${self.id}`)
    },

    clear() {
      // Clear out this region
      self._tokens.clear()
    },
    preemptiveChange(id: string, keyPath: string, value: any) {
      // Get token. If it doesn't exist, do nothing and return an undo function which does nothing.
      const token = self._tokens.get(id)
      if (!token) {
        return blank
      }

      logger.info('Performing preemptive change', keyPath, value)

      // Get current value
      // const tokenSnapShot = getSnapshot(token)
      const patchRecorder = recordPatches(token)
      token.setModified(new Date())
      // Update
      applyPatch(token, {
        op: 'replace',
        path: keyPath,
        value
      })

      patchRecorder.stop()

      // Return undo function
      return () => {
        logger.info('Reverting preemptive change', keyPath)
        // Revert
        patchRecorder.undo()
      }
    },
    async performAction(action: string, payload: any) {
      throw new Error(`Subclasses must override Region.performAction()`)
    }
  }))
  .views(self => ({
    /**
     * Returns all the objects within this region.
     *
     */
    get tokens() {
      return Array.from<TokenType>(self._tokens.values())
    },
    get ARTokens(): TokenType[] {
      throw new Error('Subclasses must override Region.ARTokens()')
    },
    // get tokens(): TokenType[] {
    //   throw new Error('Subclasses must override Region.tokens()')
    // },
    /**
     * Checks if the specified query matches our region. This is used to identify if a region request
     * can be satisfied by this region, or if a new region should be created.
     *
     * @private Called by DataPool.
     * @abstract Subclasses should override this.
     * @param {string} id The region plugin ID
     * @param {*} descriptor The region-specific filter data.
     */
    matches(id: string, descriptor?: string): boolean {
      if (descriptor)
        return self.id === id && JSON.stringify(self.descriptor) === JSON.stringify(descriptor)
      return self.id === id
    },
    /**
     * A key which is unique for this exact region. This is used when saving/restoring state to disk.
     *
     * @abstract Subclasses should override this.
     * @returns {String} The state key.
     */
    get stateKey(): string {
      throw new Error(
        `Subclasses must override 'get stateKey()' in order to correctly handle saving/restoring state to disk.`
      )
    },
    get(id: string) {
      return self._tokens.get(id)
    },
    get isLoading(): boolean {
      throw new Error('Subclasses must override Region get isLoading()')
    },
    get isFetching(): boolean {
      throw new Error('Subclasses must override Region get getIsFetching()')
    }
  }))

export type RegionStoreType = Instance<typeof RegionStore>
export type Region = RegionStoreType
export type RegionStoreSnapshot = SnapshotOut<typeof RegionStore>
export type RegionStoreSnapshotOut = SnapshotOut<typeof RegionStore>
export type RegionStoreSnapshotIn = SnapshotIn<typeof RegionStore>

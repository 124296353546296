import { useMutation } from '@tanstack/react-query'
import { fetchNonce, getConfig, useAddressToIdentities, useSDK } from '@vatom/sdk/react'

import {
  backupWallet,
  encryptAndStore,
  getWallet,
  retrieveAndDecrypt,
  signPersonalMessage
} from '../../../modules/web3-wallet'
import { useWalletStore } from '../screens/BlockChainWallets/WalletStore'

type EncryptAndStorePayload = {
  seeds: string
  cloudPassword?: string
}

type EncryptAndStoreDerivedAddress = {
  address: string
  n?: number
}

export const useEncryptAndStore = () => {
  const sdk = useSDK()
  const addressToIdentitiesMutation = useAddressToIdentities()

  return useMutation({
    mutationFn: async ({ seeds, cloudPassword }: EncryptAndStorePayload) => {
      if (seeds === '') {
        throw new Error('Invalid recovery phrase')
      }

      const data = await getWallet(seeds)
      const vatomToken = sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken ?? ''
      const appId = getConfig().appID

      const nonce = await fetchNonce({ appId, vatomToken, address: data.address })

      if (!nonce.value) {
        throw new Error('No nonce')
      }

      const signMessage = await signPersonalMessage(seeds, nonce.value, 0)
      await addressToIdentitiesMutation.mutateAsync({
        type: 'eth',
        nonce: nonce.value,
        signature: `0x${signMessage}`
      })

      if (cloudPassword) {
        await backupWallet(seeds, data.address, cloudPassword)
      }
      await encryptAndStore(data.address, data.words)
      await retrieveAndDecrypt(data.address)

      useWalletStore.getState().addWallet({
        address: data.address,
        wallet: data.address,
        name: `Address 1`,
        n: 0
      })

      return true
    },

    onSuccess: () => {
      sdk.dataPool.user.fetchIdentities()
    }
  })
}

export const useEncryptAndStoreDerivedAddress = () => {
  const sdk = useSDK()
  const addressToIdentitiesMutation = useAddressToIdentities()

  return useMutation({
    mutationFn: async ({ address, n = 0 }: EncryptAndStoreDerivedAddress) => {
      const seeds = await retrieveAndDecrypt(address)
      const newAddress = await getWallet(seeds, n)

      const vatomToken = sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken ?? ''
      const appId = getConfig().appID

      const nonce = await fetchNonce({ appId, vatomToken, address: newAddress.address })

      if (!nonce.value) {
        throw new Error('No nonce')
      }

      const data = await signPersonalMessage(seeds, nonce.value, n)
      await addressToIdentitiesMutation.mutateAsync({
        type: 'eth',
        nonce: nonce.value,
        signature: `0x${data}`
      })

      useWalletStore.getState().addWallet({
        address: newAddress.address,
        wallet: address,
        name: `Address ${n + 1}`,
        n: n
      })

      return true
    },
    onSuccess: () => {
      sdk.dataPool.user.fetchIdentities()
    }
  })
}

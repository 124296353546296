import { WebWrapper } from '../../../components/WebWrapper'

// import { withBusinessSelector } from '../../../hooks/useBusinessSelector'
import CoinDetail, { IDetailsProps } from './Detail'

const Detail = (props: IDetailsProps) => (
  <WebWrapper flexGrow={1}>
    <CoinDetail {...props} />
  </WebWrapper>
)

// const CoinDetail = withBusinessSelector(Detail)

export default Detail

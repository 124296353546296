/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AttributeVariantModel, AttributeVariantModelType } from "./AttributeVariantModel"
import { AttributeVariantModelSelector } from "./AttributeVariantModel.base"
import { RootStoreType } from "./index"


/**
 * AttributeGroupBase
 * auto generated base class for the model AttributeGroupModel.
 */
export const AttributeGroupModelBase = ModelBase
  .named('AttributeGroup')
  .props({
    __typename: types.optional(types.literal("AttributeGroup"), "AttributeGroup"),
    name: types.union(types.undefined, types.string),
    variants: types.union(types.undefined, types.array(types.late((): any => AttributeVariantModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class AttributeGroupModelSelector extends QueryBuilder {
  get name() { return this.__attr(`name`) }
  variants(builder: string | AttributeVariantModelSelector | ((selector: AttributeVariantModelSelector) => AttributeVariantModelSelector) | undefined) { return this.__child(`variants`, AttributeVariantModelSelector, builder) }
}
export function selectFromAttributeGroup() {
  return new AttributeGroupModelSelector()
}

export const attributeGroupModelPrimitives = selectFromAttributeGroup().name

import React from 'react'
import { useIsContactsAvailable, useRequestContactsPermission } from '@vatom/sdk/react'
import { Text } from '@vatom/wombo'
import { Image, Pressable, View } from 'native-base'

export interface IZeroStatProps {
  onPress: () => void
}

export const ZeroState = () => {
  const contactsPermissionMutation = useRequestContactsPermission()
  const isAvailable = useIsContactsAvailable()

  return (
    <View alignItems="center" flex={1} justifyContent="center" py={10}>
      <Image
        h={208}
        w={240}
        accessibilityIgnoresInvertColors={true}
        alt="People BG"
        source={require('../assets/peopleBG.png')}
      />
      <Text
        fontSize={16}
        fontWeight="600"
        lineHeight={22}
        mx={5}
        mt={5}
        textAlign="center"
        w="100%"
        fontFamily="Inter-SemiBold"
        tx="spaceScreen.inviteFriends"
      />
      {/* <Text color="gray.400" fontSize={13} mx={10} mt={2} textAlign="center">
        Praesent commodo cursus magna vel scelerisque nisl consectetur et.
      </Text> */}
      {isAvailable.data ? (
        <Pressable
          onPress={() => contactsPermissionMutation.mutate()}
          accessibilityRole="button"
          alignSelf="center"
          borderColor="gray.200"
          borderRadius={3}
          borderWidth={1}
          m={30}
        >
          <Text
            alignItems="center"
            color="black"
            fontSize={13}
            fontWeight="500"
            letterSpacing={-0.08}
            lineHeight={18}
            px={5}
            py={1.5}
            textAlign="center"
          >
            Connect Address Book
          </Text>
        </Pressable>
      ) : null}
    </View>
  )
}

export default ZeroState

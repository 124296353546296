import Favicon from 'react-favicon'
import { Platform } from 'react-native'
import { useConfig } from '@vatom/sdk/react'

const CustomFavicon = () => {
  const config = useConfig()

  if (Platform.OS === 'web' && config.features.favicon) {
    return (
      <Favicon url={config.features.favicon} />
      // <Favicon url="https://archive.smashing.media/assets/344dbf88-fdf9-42bb-adb4-46f01eedd629/d0a4481f-e801-4cb7-9daa-17cdae32cc89/icon-design-21-opt.png" />
    )
  } else {
    return null
  }
}

export default CustomFavicon

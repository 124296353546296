import { useQuery } from '@tanstack/react-query'
import { useSDK } from '@vatom/sdk/react'

type Props = {
  spaceId?: string
  spaceHandle?: string
  type?: 'call' | 'metaverse'
}

export const useSpaceFromNavParams = (props: Props) => {
  const sdk = useSDK()
  const { spaceHandle, spaceId } = props

  const space = spaceId ? sdk.spaces.get(spaceId) : undefined

  const result = useQuery(
    ['space', spaceHandle, spaceId],
    async () => {
      if (spaceHandle) {
        const newSpace = await sdk.vatomIncApi.getSpace(spaceHandle.substring(1))
        sdk.spaces.addUpdate(newSpace)
        return newSpace
      } else if (spaceId) {
        const newSpace = await sdk.vatomIncApi.getSpace(spaceId)
        return newSpace
      } else {
        return space
      }
    },
    {
      enabled: !!spaceHandle || !!spaceId
    }
  )

  return {
    space: space ?? result.data,
    isLoading: result.isLoading && !space
  }
}

export default useSpaceFromNavParams

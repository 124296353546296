import { QueryFunctionContext } from '@tanstack/react-query'

import { getConfig } from '../appConfig'

import { relationshipsQueryKeys } from './queryKeys'
import {
  DeviceContactResponse,
  FollowersResponse,
  FollowingResponse,
  RelationshipContact
} from './types'

export const getRelationShipHost = () => getConfig().authentication.discoveryUrl

export const fetchContacts = async ({
  queryKey: [{ vatomToken, contacts }]
}: QueryFunctionContext<ReturnType<typeof relationshipsQueryKeys['getContacts']>>) => {
  const res = await fetch(`${getRelationShipHost()}/contacts`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${vatomToken}`,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(contacts)
  })

  const data = (await res.json()) as DeviceContactResponse[]
  return data
}

export const fetchUserFollowing = async ({
  queryKey: [{ vatomToken, userId }]
}: QueryFunctionContext<ReturnType<typeof relationshipsQueryKeys['getUserFollowing']>>) => {
  const res = await fetch(`${getRelationShipHost()}/users/${userId}/rels/following`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${vatomToken}`
    })
  })

  const data = (await res.json()) as FollowingResponse[]
  return data
}

export const fetchUserFollowers = async ({
  queryKey: [{ vatomToken, userId }]
}: QueryFunctionContext<ReturnType<typeof relationshipsQueryKeys['getUserFollowers']>>) => {
  const res = await fetch(`${getRelationShipHost()}/users/${userId}/rels/followers`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${vatomToken}`
    })
  })

  const data = (await res.json()) as FollowersResponse[]
  return data
}

export const fetchFollowing = async ({
  queryKey: [{ vatomToken }]
}: QueryFunctionContext<ReturnType<typeof relationshipsQueryKeys['getFollowing']>>) => {
  const res = await fetch(`${getRelationShipHost()}/me/rels/following`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${vatomToken}`
    })
  })

  const data = (await res.json()) as RelationshipContact[]
  return data
}

export const followUser = async (contact: RelationshipContact, accessToken: string) => {
  await fetch(`${getRelationShipHost()}/me/rels/following`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(contact)
  })
}

export const unFollowUser = async (
  contact: RelationshipContact | undefined,
  accessToken: string
) => {
  await fetch(`${getRelationShipHost()}/me/rels/following/${contact?.key}`, {
    method: 'DELETE',
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`
    })
  })
}

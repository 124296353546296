import React from 'react'
import { theme } from '@vatom/wombo'
import { Box, Text } from 'native-base'

import { useCommunitiesTheme } from '../../Communities/themes'

function ProfileUserBasicInfo({ name, username }: { name: string; username?: string }) {
  const communitiesTheme = useCommunitiesTheme()

  return (
    <Box textAlign="center">
      <Text
        fontFamily="Inter-Bold"
        color={communitiesTheme?.memberInfo?.name ?? theme.colors.black}
        fontSize={17}
        lineHeight={24}
        marginTop={2}
      >
        {name}
      </Text>
      <Box flexDirection="row" justifyContent="center" alignItems="center" marginTop={1}>
        {username && (
          <Box marginRight={2}>
            <Text
              color={communitiesTheme?.memberInfo?.tag ?? theme.colors.lightText}
              fontFamily="Inter-Regular"
              fontSize={13}
              lineHeight={18}
            >
              {`@${username}`}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export { ProfileUserBasicInfo }

import { useEffect, useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { useControls } from 'leva'
import { Box3, Object3D, Vector3 } from 'three'

export const useNormalizedScale = (object: Object3D) => {
  const { scaleFactor } = useControls({
    scaleFactor: {
      value: 3,
      min: 1,
      max: 10,
      step: 0.001
    }
  })

  const maxDimRef = useRef<number>()

  useEffect(() => {
    // Calculate the reference bounding box size when the component mounts
    const bbox = new Box3().setFromObject(object)
    const size = bbox.getSize(new Vector3())
    maxDimRef.current = Math.max(size.x, size.y, size.z)
  }, [object])

  useFrame(() => {
    // Ensure maxDimRef.current is not null to avoid division by zero
    if (maxDimRef.current) {
      const scale = scaleFactor / maxDimRef.current
      object.scale.set(scale, scale, scale)
    }
  })
}

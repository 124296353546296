import * as React from 'react'
import { Platform } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { KeyboardAvoidingView, ScrollView, StatusBar, View } from 'native-base'

import { isNonScrolling, offsets, presets } from './index.presets'
import { ScreenProps } from './index.props'

const isIos = Platform.OS === 'ios'

function ScreenWithoutScrolling({
  backgroundColor,
  unsafe,
  style,
  statusBar,
  children,
  keyboardOffset
}: ScreenProps) {
  const insets = useSafeAreaInsets()
  const preset = presets.fixed
  // const style = style || {}
  const backgroundStyle = backgroundColor ? { backgroundColor } : {}
  const insetStyle = { paddingTop: unsafe ? 0 : insets.top }

  return (
    <KeyboardAvoidingView
      style={[preset.outer, backgroundStyle]}
      behavior={isIos ? 'padding' : undefined}
      keyboardVerticalOffset={offsets[keyboardOffset || 'none']}
    >
      <StatusBar barStyle={statusBar || 'light-content'} />
      <View style={[preset.inner, style, insetStyle]}>{children}</View>
    </KeyboardAvoidingView>
  )
}

function ScreenWithScrolling({
  backgroundColor,
  unsafe,
  style,
  statusBar,
  children,
  keyboardOffset,
  keyboardShouldPersistTaps
}: ScreenProps) {
  const insets = useSafeAreaInsets()
  const preset = presets.scroll
  // const style = style || {}
  const backgroundStyle = backgroundColor ? { backgroundColor } : {}
  const insetStyle = { paddingTop: unsafe ? 0 : insets.top }

  return (
    <KeyboardAvoidingView
      style={[preset.outer, backgroundStyle]}
      behavior={isIos ? 'padding' : undefined}
      keyboardVerticalOffset={offsets[keyboardOffset || 'none']}
    >
      <StatusBar barStyle={statusBar || 'light-content'} />
      <View style={[preset.outer, backgroundStyle, insetStyle]}>
        <ScrollView
          style={[preset.outer, backgroundStyle]}
          contentContainerStyle={[preset.inner, style]}
          keyboardShouldPersistTaps={keyboardShouldPersistTaps || 'handled'}
        >
          {children}
        </ScrollView>
      </View>
    </KeyboardAvoidingView>
  )
}

/**
 * The starting component on every screen in the app.
 *
 * @param props The screen props
 */
export const Screen = ({ preset, ...rest }: ScreenProps) => {
  if (isNonScrolling(preset)) {
    return <ScreenWithoutScrolling {...rest} />
  } else {
    return <ScreenWithScrolling {...rest} />
  }
}

export default Screen

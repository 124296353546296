import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { usePreventScroll } from 'react-aria'
import { ScrollView } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useMutation } from '@tanstack/react-query'
import { translate } from '@vatom/utils'
import { theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Button, Divider, useColorModeValue, VStack } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import Alert from '../../components/Alert'
import { ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import { withBusinessSelector } from '../../hooks/useBusinessSelector'
import { AppRoutes, AppStackScreenProps } from '../../navigators'
import { useToken } from '../NFTDetail/useNftDetail/useToken'

import ListSwitches from './partials/ListSwitches'
import PriceDetails from './partials/PriceDetails'
import Success from './partials/Success'

export type SellNFTProps = AppStackScreenProps<
  typeof AppRoutes.SellNFT | typeof AppRoutes.SellNFT_Business
>

export const SellNFT = withBusinessSelector(
  observer((props: SellNFTProps) => {
    const insets = useSafeAreaInsets()
    const { navigation } = props
    const { tokenId, business } = props.route.params
    const { token, isLoading: tokenIsLoading } = useToken({
      tokenId
    })
    const promptedError = useRef<boolean>(false)
    const canSell = useMemo(() => {
      return token && !!token.actions.find(a => a === 'List')
    }, [token])

    usePreventScroll({})

    const headerIconColor = useColorModeValue(
      theme.colors.textLightMode[900],
      theme.colors.textDarkMode[100]
    )
    const backgroundColor = useColorModeValue(theme.colors.white, theme.colors.grayDarkMode[900])

    const onClose = useCallback(() => {
      if (navigation.canGoBack()) {
        navigation.goBack()
      }
      if (business) {
        navigation.navigate(AppRoutes.NFTDetail_Business, {
          tokenId: token.id,
          business
        })
      }
      navigation.navigate(AppRoutes.NFTDetail, {
        tokenId: token.id
      })
    }, [navigation, business, token])

    const [sellPrice, setSellPrice] = useState('')
    const [isHideOnMarket, setIsHideOnMarket] = useState(false)
    const [isListOnUSD, setIsListOnUSD] = useState(true)
    const [isListOnETH, setIsListOnETH] = useState(false)

    const sellMutation = useMutation({
      mutationKey: ['token-sell', token?.id],
      mutationFn: async () => {
        const channel = !isHideOnMarket ? 'marketplace' : 'direct'
        const currencies = []

        if (isListOnETH) currencies.push('eth')
        if (isListOnUSD) currencies.push('usd')

        await token.performAction('varius.action:varius.io:list-for-sale-v1', {
          price: parseInt(sellPrice),
          channel: channel,
          currency: currencies.join(',')
        })
      },
      onError(error) {
        //
        Alert.showError(error)
      }
    })

    const isSellDisabled = useMemo(() => {
      if (!canSell) {
        return true
      }
      const price = parseFloat(sellPrice)
      return price > -1 ? false : true
    }, [canSell, sellPrice])

    const royalties = useMemo(() => token?.royalties ?? 0, [token])
    const marketplace = 10

    const onSell = useThrottledCallback(
      async () => {
        sellMutation.mutate()
      },
      300,
      {
        leading: true
      }
    )

    const promptCanSellError = useCallback(async () => {
      promptedError.current = true
      await Alert.showError({
        name: 'There was a problem',
        message: `Token can't be sell.`
      }).then(result => {
        onClose()
      })
    }, [onClose])

    useEffect(() => {
      if (!token && !tokenIsLoading) {
        // No token found, nothing to do
        onClose()
      }
      if (token && !tokenIsLoading && !canSell) {
        if (promptedError.current === false) {
          promptCanSellError()
        }
      }
    }, [canSell, onClose, promptCanSellError, token, tokenIsLoading])

    return (
      <ScreenWrapper
        screenBackgroundColor={backgroundColor}
        statusBarBackgroundColor={backgroundColor}
      >
        <ScreenHeader
          headerLeftType={'close'}
          headerLeftPress={onClose}
          headerTextColor={headerIconColor}
          headerProps={{
            overflow: 'hidden'
          }}
          centerContainerProps={{
            flex: 1,
            paddingX: 2
          }}
          leftContainerProps={{
            flex: 0,
            _web: {
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto'
            }
          }}
          rightContainerProps={{
            flex: 0,
            _web: {
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto'
            }
          }}
          headerRight={() => <ScreenHeaderButton />}
        >
          <Box flexDirection={'column'} marginLeft={2} flex={1} justifyContent={'center'}>
            <Title preset="h5" variant="semibold" numberOfLines={1}>
              {translate('common.sellNft')}
            </Title>
          </Box>
        </ScreenHeader>
        {/* CONTENT */}
        {sellMutation.isSuccess ? (
          <Success onPress={onClose} />
        ) : (
          <VStack paddingX={0} flexGrow={1}>
            <ScrollView
              contentContainerStyle={{
                flexGrow: 1
              }}
              keyboardDismissMode="interactive"
            >
              <VStack paddingX={4}>
                <PriceDetails
                  sellPrice={sellPrice}
                  setSellPrice={setSellPrice}
                  royalties={royalties}
                  marketplace={marketplace}
                />
              </VStack>
              <Divider
                _light={{
                  backgroundColor: theme.colors.grayCool[300]
                }}
                _dark={{
                  backgroundColor: theme.colors.grayDarkMode[700]
                }}
                height="1px"
              />
              <ListSwitches
                isHideOnMarket={isHideOnMarket}
                setIsHideOnMarket={setIsHideOnMarket}
                isListOnETH={isListOnETH}
                setIsListOnETH={setIsListOnETH}
                isListOnUSD={isListOnUSD}
                setIsListOnUSD={setIsListOnUSD}
              />
              <Divider
                _light={{
                  backgroundColor: theme.colors.grayCool[300]
                }}
                _dark={{
                  backgroundColor: theme.colors.grayDarkMode[700]
                }}
                height="1px"
                marginBottom="20px"
              />
            </ScrollView>
            <VStack
              flexGrow={1}
              justifyContent={'flex-end'}
              style={{
                paddingBottom: insets.bottom > 0 ? insets.bottom : 16
              }}
            >
              <Button
                isDisabled={isSellDisabled || sellMutation.isLoading}
                onPress={onSell}
                isLoading={sellMutation.isLoading}
                opacity={isSellDisabled || sellMutation.isLoading ? 0.7 : 1}
                marginX={4}
                _light={{
                  backgroundColor: theme.colors.systemColorsLight.orange
                }}
                _dark={{
                  backgroundColor: theme.colors.systemColorsDark.orange
                }}
              >
                <Title preset="h5" variant="semibold" paddingY={1}>
                  {translate('common.sell')}
                </Title>
              </Button>
            </VStack>
          </VStack>
        )}
      </ScreenWrapper>
    )
  })
)

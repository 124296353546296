import React from 'react'
import { Box, Checkbox } from 'native-base'

import Error from './Error'
import LabelForm from './Label'
import { fieldPropsInterfaceItem } from './Types'

export default function Boolean(props: fieldPropsInterfaceItem) {
  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Checkbox
          accessibilityHint={props.field.name}
          value={props.field.name}
          accessibilityLabel={props.field.name}
          onChange={e => props.onChange(props.field.name, e)}
        />
        <LabelForm {...props} mb={0} mt={0} ml={2} />
      </Box>
      <Error {...props} />
    </>
  )
}

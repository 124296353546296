import React from 'react'
import { Box, Text } from 'native-base'

import { fieldPropsInterface } from './Types'

export default function Error(props: fieldPropsInterface) {
  const { error } = props

  if (!error?.message) return null

  return (
    <Box>
      <Text color="red.500"> {error?.message || ' '}</Text>
    </Box>
  )
}

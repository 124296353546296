import React, { useEffect, useState } from 'react'
import { WalletPreferenceSortBy } from '@vatom/sdk/core'
import { useSDK } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Actionsheet, ScrollView, VStack } from 'native-base'

import { useMaxWidth } from '../../../components/WebWrapper'
import ActionSheetHeader, { HeaderType } from '../partials/ActionSheetHeader'
import FullScreenDivider from '../partials/FullScreenDivider'
import RadioList from '../partials/RadioList'

import ClickableCategoryField from './partials/ClickableCategoryField'
import ListSwitches, { ISwitchItem } from './partials/ListSwitches'

export enum State {
  filter = 'filter',
  sortBy = 'sortBy'
}

interface IWalletNftPreference {
  isOpen: boolean
  setOpen: (b: boolean) => void
}

export const WalletNftPreference = observer(({ isOpen, setOpen }: IWalletNftPreference) => {
  const sdk = useSDK()
  const maxWidth = useMaxWidth()

  const [state, setState] = useState<State>(State.filter)
  const [switchesData, setSwitchesData] = useState<ISwitchItem[]>([])
  const sortByData = [
    {
      key: WalletPreferenceSortBy.Newest,
      label: translate('common.newest')
    },
    {
      key: WalletPreferenceSortBy.Oldest,
      label: translate('common.oldest')
    },
    {
      key: WalletPreferenceSortBy.Ascending,
      label: 'A - Z'
    },
    {
      key: WalletPreferenceSortBy.Descending,
      label: 'Z - A'
    }
  ]

  const onSortByItemPress = (sortBy: WalletPreferenceSortBy) => {
    sdk.walletPreference.setSortBy(sortBy)
    setState(State.filter)
  }

  useEffect(() => {
    setSwitchesData([
      {
        label: translate('common.showTitles'),
        value: sdk.walletPreference.showTitles,
        onToggle: () => sdk.walletPreference.setShowTitles(!sdk.walletPreference.showTitles)
      },
      {
        label: translate('common.showEditions'),
        value: sdk.walletPreference.showEditions,
        onToggle: () => sdk.walletPreference.setShowEditions(!sdk.walletPreference.showEditions)
      },
      {
        label: translate('common.showBadges'),
        value: sdk.walletPreference.showBadges,
        onToggle: () => sdk.walletPreference.setShowBadges(!sdk.walletPreference.showBadges)
      },
      {
        label: translate('common.showDropped'),
        value: sdk.walletPreference.showDroppedSmartNft,
        onToggle: () =>
          sdk.walletPreference.setShowDroppedSmartNft(!sdk.walletPreference.showDroppedSmartNft)
      }
    ])
  }, [
    sdk.walletPreference.showTitles,
    sdk.walletPreference.showEditions,
    sdk.walletPreference.showBadges,
    sdk.walletPreference.showDroppedSmartNft,
    sdk.walletPreference
  ])

  return (
    <Actionsheet
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      maxWidth={maxWidth}
      margin={'auto'}
      useRNModal={true}
      // @ts-ignore prop doesn't exit on types
      _backdropFade={{
        entryDelay: 150,
        exitDelay: 10
      }}
    >
      <Actionsheet.Content
        pt="20px"
        pb="16px"
        p={0}
        w="100%"
        bg="white"
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[900]
        }}
      >
        <ScrollView w="100%">
          <VStack px="16px" w="100%">
            <ActionSheetHeader
              type={state === State.sortBy ? HeaderType.back : HeaderType.close}
              title={
                state === State.sortBy
                  ? translate('common.sortBy')
                  : translate('common.walletPreferences')
              }
              onPress={() => {
                state === State.filter ? setOpen(false) : setState(State.filter)
              }}
            />
            <FullScreenDivider />
            {state === State.filter && (
              <>
                <ClickableCategoryField
                  title={translate('common.sortBy')}
                  value={
                    sortByData.find(val => val.key === sdk.walletPreference.sortBy)?.label ?? ''
                  }
                  onPress={() => setState(State.sortBy)}
                />
                <FullScreenDivider />
                <ListSwitches data={switchesData} topDivider={true} bottomDivider={true} />
                <FullScreenDivider />
              </>
            )}
            {state === State.sortBy && (
              <RadioList
                currentlySelectedValue={sdk.walletPreference.sortBy}
                data={sortByData}
                onItemPress={onSortByItemPress}
              />
            )}
          </VStack>
        </ScrollView>
      </Actionsheet.Content>
    </Actionsheet>
  )
})

export default WalletNftPreference

import React from 'react'
import { TouchableOpacity } from 'react-native'
import { useGetSpace } from '@vatom/sdk/react'

import { useCommunitiesTheme } from '../../../../themes'
import MessagesPopUpModal from '../../../ActionsModals/MessagesPopUpModal'
import MoreDotsBulkyIcon from '../assets/more_dots_bulky_icon.svg'
import MoreDotsIcon from '../assets/more_dots_icon.svg'
import DeleteConfirmation from '../DeleteConfirmation'

type ElipsisButtonProps = {
  messageId: string
  isThread: boolean
  isReplyMessage?: boolean
  buttonStyle?: object
  spaceId: string
}

const ElipsisButton = (props: ElipsisButtonProps) => {
  const { messageId, isThread, isReplyMessage, buttonStyle, spaceId } = props
  const space = useGetSpace(spaceId, {
    enabled: false
  })
  const roomId = space.data?.matrixRoomId ?? ''
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const [isConfirmationVisible, setIsConfirmationVisible] = React.useState(false)
  const communitiesTheme = useCommunitiesTheme()

  return (
    <>
      <TouchableOpacity
        accessibilityRole="button"
        onPress={() => setIsModalVisible(true)}
        style={{
          width: 24,
          marginLeft: 'auto',
          height: 24,
          justifyContent: 'center',
          alignItems: 'center',
          ...buttonStyle
        }}
      >
        {isThread && !isReplyMessage ? (
          <MoreDotsBulkyIcon fill={communitiesTheme.replyHeaderTitle} />
        ) : (
          <MoreDotsIcon fill={communitiesTheme.chatBubbleHeader.text} width={14} />
        )}
      </TouchableOpacity>

      {isModalVisible && !isConfirmationVisible ? (
        <MessagesPopUpModal
          messageId={messageId}
          spaceId={spaceId}
          isThread={isThread}
          isReplyMessage={isReplyMessage}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          setIsConfirmationVisible={setIsConfirmationVisible}
        />
      ) : null}

      {isConfirmationVisible ? (
        <DeleteConfirmation
          roomId={roomId}
          isConfirmationVisible={isConfirmationVisible}
          setIsConfirmationVisible={setIsConfirmationVisible}
          setIsModalVisible={setIsModalVisible}
          isReplyMessage={isReplyMessage}
          messageId={messageId}
        />
      ) : null}
    </>
  )
}

export default ElipsisButton

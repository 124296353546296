import React from 'react'
import { Modal, Platform, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { KeyboardAvoidingView } from 'native-base'

import { useCommunitiesTheme } from '../../../themes'

const ModalWrapper = ({ children }: { children: React.ReactNode }) => {
  const insets = useSafeAreaInsets()
  const communitiesTheme = useCommunitiesTheme()

  return (
    <View style={{ flex: 1, backgroundColor: communitiesTheme.modalToolBar.background }}>
      <KeyboardAvoidingView
        flex={1}
        marginBottom={insets.bottom}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        {children}
      </KeyboardAvoidingView>
    </View>
  )
}

export default ModalWrapper

export const PlatformWrapper = ({ children }: { children: React.ReactNode }) => {
  const isAndroid = Platform.OS === 'android'

  return isAndroid ? (
    <Modal>{children}</Modal>
  ) : (
    <View style={{ justifyContent: 'center', height: '100%' }}>{children}</View>
  )
}

export const ContentWrapper = ({ children }: { children: React.ReactNode }) => {
  const insets = useSafeAreaInsets()
  const communitiesTheme = useCommunitiesTheme()

  return (
    <View
      style={{
        flex: 1,
        width: '100%',
        backgroundColor: communitiesTheme.background,
        paddingTop: insets.top
      }}
    >
      {children}
    </View>
  )
}

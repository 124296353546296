import { memo, PropsWithChildren, useCallback } from 'react'
import { Keyboard, Platform } from 'react-native'
import { Drawer as DrawerLayout } from 'react-native-drawer-layout'
import { useConfig } from '@vatom/sdk/react'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { theme } from '@vatom/wombo'
import { useColorModeValue } from 'native-base'

import { useRemoveBodyScroll } from '../../hooks/useRemoveBodyScroll'

import { DrawerContent, drawerNavigationRef } from './components/DrawerContent'
import { useDrawerStore } from './drawerStore'
import { DrawerProvider } from './useDrawerNavigation'

const DrawerNavigator = ({ children }: PropsWithChildren) => {
  const drawerStatus = useDrawerStore(state => state.drawerStatus)
  const { config: sdkConfig, isEmbedded } = useVatomWalletSdkStore()
  const config = useConfig()
  useRemoveBodyScroll({
    enabled: drawerStatus === 'opened'
  })

  const handleDrawerOpen = useCallback(() => {
    useDrawerStore.getState().openDrawer()
  }, [])

  const handleDrawerClose = useCallback(() => {
    Keyboard.dismiss()
    useDrawerStore.getState().closeDrawer()
    drawerNavigationRef.current?.navigate('BusinessList')
  }, [])

  const styles = useColorModeValue(
    {
      backgroundColor: theme.colors.white
    },
    {
      backgroundColor: theme.colors.grayDarkMode[900]
    }
  )

  if (config?.features?.hideDrawer) {
    return children
  }

  return (
    <DrawerProvider>
      <DrawerLayout
        open={drawerStatus === 'opened'}
        onOpen={handleDrawerOpen}
        onClose={handleDrawerClose}
        renderDrawerContent={() => <DrawerContent />}
        swipeEnabled={Platform.OS === 'android' ? false : true}
        swipeEdgeWidth={30}
        swipeMinDistance={10}
        overlayStyle={{
          backgroundColor: 'rgba(0,0,0,0.4)'
        }}
        drawerStyle={{
          backgroundColor: styles.backgroundColor,
          width: '80%',
          maxWidth: 500
        }}
      >
        {children}
      </DrawerLayout>
    </DrawerProvider>
  )
}

const DrawerNavigatorMemo = memo(DrawerNavigator)

export { DrawerNavigatorMemo as DrawerNavigator }

import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'

import {
  AppStackScreenProps,
  CommunitiesRoomRoutes,
  navigateToConnectScreen,
  RoomRoutes
} from '../../../../../navigators'
import { useCommunitiesTheme } from '../../../themes'
import { useCommunitySpace } from '../../../useCommunitySpace'
import ArrowRightIcon from '../../modal-components/ArrowRightIcon'
import ModalText from '../../modal-components/ModalText'
import { useStoreContext } from '../../MSTContextProvider'
import QuestionsModalIcon from '../assets/questions_modal_icon.svg'

import { DecorationData } from './ModalDecorations'
import ModalDecorationWrapper from './ModalDecorationWrapper'

type CurrentRoute = AppStackScreenProps<
  typeof RoomRoutes.RoomQuestionList | typeof CommunitiesRoomRoutes.CommunitiesRoomQuestionList
>['route']

const ModalQuestions = observer(({ decorationData }: { decorationData: DecorationData }) => {
  const store = useStoreContext()
  const navigation = useNavigation()
  const communitiesTheme = useCommunitiesTheme()
  const { questions } = store
  const route = useRoute<CurrentRoute>()
  const { community } = useCommunitySpace()

  React.useEffect(() => {
    const { type, placeholder, value, additionalInfo } = decorationData
    const questionsInput = {
      type,
      placeholder,
      value
    }
    if (additionalInfo) {
      store.setQuestions(additionalInfo)
    }
    store.addAdditionalInputs(questionsInput)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ModalDecorationWrapper
      handler={() => {
        navigateToConnectScreen(navigation, RoomRoutes.RoomQuestionList, {
          ...route.params,
          community
        })
      }}
    >
      <View style={styles.contentHolder}>
        <QuestionsModalIcon
          width="20"
          style={{ marginRight: 12 }}
          fill={communitiesTheme.labelIconColor}
        />
        <ModalText text={decorationData.placeholder} />
      </View>
      <View style={styles.contentHolder}>
        <Text style={{ ...styles.text, color: communitiesTheme.labelIconColor }}>
          {questions.length > 0 ? questions.length : null}
        </Text>
        <ArrowRightIcon style={{ marginLeft: 12 }} />
      </View>
    </ModalDecorationWrapper>
  )
})

export default ModalQuestions

const styles = StyleSheet.create({
  text: {
    color: '#3F4A55',
    fontSize: 15,
    lineHeight: 20
  },
  contentHolder: {
    flexDirection: 'row',
    alignItems: 'center'
  }
})

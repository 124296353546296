import { Circle, Marker } from '@react-google-maps/api'
import { TokenType } from '@vatom/sdk/core'
import { GeoDiscoveryGroup } from '@vatom/sdk/react'
import * as Location from 'expo-location'

import { getDistance } from '../../../utils/map'

import CustomMarker from './CustomMarker'
import GroupMarker from './GroupMarker'

export const createCustomMarkers = (
  filteredTokens: TokenType[],
  onMarkerClick: (item: TokenType) => void
) =>
  filteredTokens.map(item => (
    <CustomMarker key={`custom_marker_${item?.id}`} item={item} onMarkerClick={onMarkerClick} />
  ))

export const createGroupMarkers = (
  groups: GeoDiscoveryGroup[],
  onGroupMarkerPress: (geoGroup: GeoDiscoveryGroup) => void
) =>
  groups.map(item => (
    <GroupMarker key={`${item.key}-group-marker`} geoGroup={item} onPress={onGroupMarkerPress} />
  ))

export const createVatomMarkers = (items: TokenType[], onPress: (item: TokenType) => void) => {
  return (
    items?.map(item => (
      <CustomMarker key={`${item?.id}_vatom_marker`} item={item} onMarkerClick={onPress} />
    )) ?? null
  )
}

export const createSingleMarkers = (
  singles: GeoDiscoveryGroup[],
  filteredTokens: TokenType[],
  onMarkerClick: (item: TokenType) => void
) => {
  const distancesByTokenId = new Map<string, number>()
  const usedTokens = new Set<string>()

  if (singles.length === 0) {
    return []
  }

  const singleTokens = singles.map(item => {
    const singleLocation = { lat: item.lat, lng: item.lon }

    // find closest token to single group

    const closestToken = filteredTokens.reduce<TokenType | null>((prev, curr) => {
      const distance = getDistance(singleLocation, {
        lat: curr.position?.coordinates[1] ?? 0,
        lng: curr.position?.coordinates[0] ?? 0
      })
      distancesByTokenId.set(curr.id, distance)
      // token was already used
      if (usedTokens.has(curr.id)) {
        return null
      }

      if (!prev) {
        return curr
      }

      const prevDistance = distancesByTokenId.get(prev.id) ?? 0

      if (distance < prevDistance) {
        return curr
      }

      return prev
    }, null)

    if (closestToken) {
      // mark token as used
      usedTokens.add(closestToken.id)
    }
    return closestToken
  })

  const singleMarkers = singleTokens.map(token =>
    token ? (
      <CustomMarker
        key={`${token?.id}_custom_marker_with_groups`}
        item={token}
        onMarkerClick={onMarkerClick}
      />
    ) : null
  )

  return singleMarkers
}

export const createUserLocationMarkers = (location?: Location.LocationObject) => [
  <Circle
    center={{ lat: location?.coords?.latitude ?? 0, lng: location?.coords?.longitude ?? 0 }}
    key={'userLocationAccuracyCircle'}
    radius={location?.coords.accuracy || 0}
    options={{
      fillColor: '#C8D6EC',
      strokeOpacity: 0,
      clickable: false
    }}
  />,
  <Marker
    position={{ lat: location?.coords.latitude ?? 0, lng: location?.coords.longitude ?? 0 }}
    key={'userLocation'}
    icon={{
      path: 0,
      fillColor: '#4285F4',
      fillOpacity: 1,
      scale: 8,
      strokeColor: 'white',
      strokeWeight: 2
    }}
  />
]

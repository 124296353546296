/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ListingModel, ListingModelType } from "./ListingModel"
import { ListingModelSelector } from "./ListingModel.base"
import { RootStoreType } from "./index"


/**
 * BidBase
 * auto generated base class for the model BidModel.
 */
export const BidModelBase = ModelBase
  .named('Bid')
  .props({
    __typename: types.optional(types.literal("Bid"), "Bid"),
    listingAddress: types.union(types.undefined, types.string),
    bidderAddress: types.union(types.undefined, types.string),
    lastBidTime: types.union(types.undefined, types.string),
    lastBidAmount: types.union(types.undefined, types.frozen()),
    cancelled: types.union(types.undefined, types.boolean),
    listing: types.union(types.undefined, types.null, types.late((): any => ListingModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class BidModelSelector extends QueryBuilder {
  get listingAddress() { return this.__attr(`listingAddress`) }
  get bidderAddress() { return this.__attr(`bidderAddress`) }
  get lastBidTime() { return this.__attr(`lastBidTime`) }
  get lastBidAmount() { return this.__attr(`lastBidAmount`) }
  get cancelled() { return this.__attr(`cancelled`) }
  listing(builder: string | ListingModelSelector | ((selector: ListingModelSelector) => ListingModelSelector) | undefined) { return this.__child(`listing`, ListingModelSelector, builder) }
}
export function selectFromBid() {
  return new BidModelSelector()
}

export const bidModelPrimitives = selectFromBid().listingAddress.bidderAddress.lastBidTime.lastBidAmount.cancelled

import { Instance, SnapshotOut, types } from 'mobx-state-tree'

export enum IdentityType {
  Eth = 'eth',
  Phone = 'phoneNumber',
  Email = 'email',
  BlockV = 'blockv',
  // Id = "id",
  Solana = 'sol',
  Casper = 'cspr'
}

export const Identity = types.model('Identity', {
  type: types.enumeration<IdentityType>(Object.values(IdentityType)),
  value: types.identifier,
  verified: types.optional(types.boolean, false),
  preferred: types.optional(types.boolean, false),
  name: types.maybe(types.string),
  custodial: types.optional(types.boolean, false),
  isCustodial: types.optional(types.boolean, true)
})

export type IdentitySnapshot = SnapshotOut<typeof Identity>

const UserIdentityImportedFormModel = types.model('UserIdentityImportedFormModel', {
  type: types.optional(types.string, ''),
  value: types.optional(types.string, '')
})

export const UserIdentityModel = Identity.named('UserIdentityModel').props({
  importedFrom: types.optional(UserIdentityImportedFormModel, {})
})

export type UserIdentity = Instance<typeof UserIdentityModel>
export type UserIdentitySnapshot = SnapshotOut<typeof UserIdentityModel>

import React, { useCallback, useMemo } from 'react'
import { LayoutChangeEvent, Platform, SectionList, View } from 'react-native'
import Animated, { runOnJS, useDerivedValue } from 'react-native-reanimated'
import { IEvent } from 'matrix-js-sdk'
import { useThrottledCallback } from 'use-debounce'

import { useLayoutScrollHandler } from '../../../hooks/useLayoutScrollHandler'
import { useWindowScroll } from '../../../hooks/useWindowScroll'
import { useIsMember } from '../hooks/useIsMember'
import { useMessages } from '../queries'
import { useCommunitiesTheme } from '../themes'

import ChatBubble from './ChatBubble/ChatBubble'
import DayBanner from './DayBanner'

const isWeb = Platform.OS === 'web'

const AnimatedSectionList = Animated.createAnimatedComponent(SectionList)

type OrderedData = {
  date: string
  data: IEvent[]
}

type MessageListProps = {
  roomId: string
  spaceId: string
  isFiltered?: boolean
  memberId?: string
  filter: string
}

type Accumulator = {
  [key: string]: { data: IEvent[]; date: string; key: number }
}

const MessageList = ({
  roomId,
  isFiltered,
  memberId,
  filter: activeFilter,
  spaceId
}: MessageListProps) => {
  const isMember = useIsMember({ roomId })
  const {
    data: queryMessages,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isFetching
  } = useMessages(roomId, activeFilter, Boolean(isMember))

  const { scrollHandler, scrollEventThrottle, onScrollWebView, lastContentOffset } =
    useLayoutScrollHandler()
  useWindowScroll({
    scrollCallback: onScrollWebView
  })

  const [contentHeight, setContentHeight] = React.useState(0)
  const [layoutHeight, setLayoutHeight] = React.useState(0)

  const queryMessagesMapped = queryMessages?.pages.flatMap(group => {
    return group?.map((event: IEvent) => event)
  })

  const filteredData = useMemo(() => {
    return memberId
      ? queryMessagesMapped?.filter(msg => msg.sender === memberId) ?? []
      : queryMessagesMapped ?? []
  }, [memberId, queryMessagesMapped])

  const sectionData: OrderedData[] = useMemo(() => {
    if (filteredData.length === 0) {
      return []
    }
    filteredData.sort((a, b) => b.origin_server_ts - a.origin_server_ts)

    return Object.values(
      filteredData.reduce((acc: Accumulator, curr) => {
        const dateFormat = new Date(curr.origin_server_ts)
        const date = dateFormat.toDateString()
        acc[date] ??= { date, data: [], key: curr.origin_server_ts }
        acc[date].data.push(curr)
        return acc
      }, {})
    )
  }, [filteredData])

  const renderItem = ({ item }: { item: IEvent }) =>
    item ? <ChatBubble matrixMessage={item} spaceId={spaceId} isThread={false} /> : null

  const communitiesTheme = useCommunitiesTheme()

  const renderItemSeparatorComponent = (index: number) => (
    <View
      key={index}
      style={{ width: '100%', height: 1, backgroundColor: communitiesTheme.messageList.border }}
    />
  )

  const throttledFetch = useThrottledCallback(
    () => {
      if (isFetchingNextPage) return null
      fetchNextPage({
        // @ts-expect-error this is existing
        pageParam: queryMessages?.nextPage
      }).catch(error => {
        console.error('Error fetching next page', error)
      })
    },
    500,
    {
      leading: true,
      trailing: false
    }
  )

  const onEndReached = () => {
    // @ts-expect-error this is existing
    if (!isWeb && !isFetchingNextPage && queryMessages?.nextPage) {
      throttledFetch()
    }
  }

  const onContentSizeChange = (width: number, height: number) => {
    setContentHeight(Math.floor(height))
  }

  const onScrollLayout = (layout: LayoutChangeEvent) => {
    const height = layout.nativeEvent.layout.height
    setLayoutHeight(Math.floor(height))
  }

  const isCloseToBottom = useCallback(
    (offsetY: number) => {
      let isClose = false

      if (contentHeight > 0 && layoutHeight > 0) {
        const reachBufferOffset = 100
        isClose = reachBufferOffset >= contentHeight - layoutHeight - offsetY
      }

      return isClose
    },
    [contentHeight, layoutHeight]
  )

  const checkShouldFetchNextPage = useCallback(
    (offsetY: number, hasNextPage?: boolean, isFetchingNextPage?: boolean) => {
      if (!isWeb) {
        return
      }
      if (offsetY > 0 && isCloseToBottom(offsetY)) {
        // @ts-expect-error this is existing
        if (!isFetchingNextPage && queryMessages?.nextPage) {
          throttledFetch()
        }
      }
    },
    // @ts-expect-error this is existing
    [isCloseToBottom, queryMessages?.nextPage, throttledFetch]
  )

  useDerivedValue(() => {
    runOnJS(checkShouldFetchNextPage)(lastContentOffset.value, hasNextPage, isFetchingNextPage)
  }, [isCloseToBottom, hasNextPage, isFetchingNextPage, isFetching])

  if (isFiltered) {
    return (
      <Animated.FlatList
        data={filteredData}
        keyExtractor={(item, index) => `wrapper-${item.event_id}-${index}`}
        renderItem={renderItem}
        ItemSeparatorComponent={renderItemSeparatorComponent}
        maxToRenderPerBatch={5}
        initialNumToRender={5}
        scrollEventThrottle={scrollEventThrottle}
        onScroll={scrollHandler}
        onEndReachedThreshold={0.5}
        onEndReached={onEndReached}
        onContentSizeChange={onContentSizeChange}
        onLayout={onScrollLayout}
        contentContainerStyle={{
          paddingBottom: !isWeb ? 180 : undefined
        }}
      />
    )
  }

  return (
    <AnimatedSectionList
      sections={sectionData}
      // @ts-ignore
      renderItem={renderItem}
      // @ts-ignore
      renderSectionHeader={({ section: { date } }) => <DayBanner date={date} />}
      ItemSeparatorComponent={renderItemSeparatorComponent}
      maxToRenderPerBatch={5}
      initialNumToRender={5}
      scrollEventThrottle={scrollEventThrottle}
      onScroll={scrollHandler}
      onEndReachedThreshold={0.5}
      onEndReached={onEndReached}
      onContentSizeChange={onContentSizeChange}
      onLayout={onScrollLayout}
      contentContainerStyle={{
        paddingBottom: !isWeb ? 180 : undefined
      }}
    />
  )
}

export default MessageList

import React, { useCallback, useMemo, useRef } from 'react'
import { useFocusEffect } from '@react-navigation/native'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { LoaderView } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'

import { Container } from '../container'
import PickUpModal from '../partials/PickUpModal'
import PostPickupModal from '../partials/PostPickupModal'
import { useMaps } from '../useMaps'

import { MapView } from './partials/MapView'

export const MapScreen = observer(() => {
  const mapRef = useRef<google.maps.Map>()

  const {
    initialCenter,
    location,
    selectedToken,
    onPickUp,
    onCloseModal,
    openModal,
    markers,
    onClosePostModal,
    onPostModal,
    openPostModal,
    handleCenter,
    onCenterChanged,
    refetchLocation,
    renderModalQueryComponent,
    setOpenModal
  } = useMaps({
    // @ts-ignore
    mapRef
  })

  useFocusEffect(
    useCallback(() => {
      // If is refocus try to center to user location
      if (mapRef.current) {
        refetchLocation().then(locationQueryResult => {
          // @ts-expect-error
          handleCenter?.({ isAnimated: false, location: locationQueryResult.data })
        })
      }
    }, [handleCenter, refetchLocation])
  )
  const newCenter = mapRef?.current?.getCenter()

  const center = useMemo(() => {
    const lat = newCenter?.lat() ?? initialCenter?.latitude
    const lng = newCenter?.lng() ?? initialCenter?.longitude
    if (!lat || !lng) {
      return undefined
    }

    return {
      lat,
      lng
    }
  }, [initialCenter, newCenter])

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map
  }, [])
  const onMapUnmount = useCallback(() => {
    mapRef.current = undefined
  }, [])

  const { walletConfig } = useVatomWalletSdkStore()

  if (!center || !location?.coords) {
    return (
      <>
        {renderModalQueryComponent}
        <LoaderView />
      </>
    )
  }

  return (
    <>
      <PickUpModal
        userLocation={location?.coords}
        token={selectedToken}
        onPickUp={onPickUp}
        onCancel={onCloseModal}
        isOpen={openModal}
        onPostModal={onPostModal}
        setOpenModal={setOpenModal}
        // There might be a better condition for this but some research is needed to find out when exactly we need to wait for performAction
        optimistic={selectedToken ? !selectedToken?.actions.find(a => a === 'Dispense') : true}
      />
      <PostPickupModal token={selectedToken} isOpen={openPostModal} onCancel={onClosePostModal} />
      {renderModalQueryComponent}
      <Container handleCenter={handleCenter}>
        <MapView
          onLoad={onMapLoad}
          onUnmount={onMapUnmount}
          onCenterChanged={onCenterChanged}
          center={center}
          zoom={16}
          options={{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            styles: walletConfig.features.screensConfig?.Map?.mapStyle
          }}
        >
          {markers}
        </MapView>
      </Container>
    </>
  )
})

export default MapScreen

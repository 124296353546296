import { useCallback } from 'react'
import { Platform, StyleSheet, ViewProps } from 'react-native'
import { BusinessType, PageConfigInterface } from '@vatom/experience-sdk'
import { BusinessSnapshot, PageConfigSnapshot, TokenType } from '@vatom/sdk/core'
import { Image, TokenBadges, useSDK } from '@vatom/sdk/react'
import { NFTCard } from '@vatom/wombo'
import transform, { StyleTuple } from 'css-to-react-native'
import { observer } from 'mobx-react-lite'
import { AspectRatio, Box } from 'native-base'
import { DebouncedState } from 'use-debounce'

interface WalletFlatlistItemProps extends ViewProps {
  item: TokenType
  pageConfig?: PageConfigSnapshot | PageConfigInterface
  numColumns?: number
  business?: BusinessSnapshot | BusinessType
  onPress: DebouncedState<(item: TokenType, business?: BusinessSnapshot | BusinessType) => void>
}

export const onEndReachedThreshold = 0.7

const isPlatformNotWeb = Platform.OS !== 'web'

const FallbackWithNoFace = observer(({ item }: { item: TokenType }) => {
  const defaultImage =
    'https://images.squarespace-cdn.com/content/v1/61e34043b0387b781e8fbaf7/1e0f129e-098c-4c86-a006-be77e1ad379c/MAIN+LOGO+VER+03-1.png?'

  const source = {
    // cache: 'force-cache',
    headers: {
      Accept: '*/*'
    },
    uri: item.displayImage || defaultImage
  }

  if (item.type !== 'vatom') {
    const { faces } = item
    const iconFace = faces?.find(f => f.type === 'icon')

    if (iconFace) {
      const { animation_url, animation_url_type } = iconFace

      if (animation_url && animation_url_type?.includes('video')) {
        source.uri = getVideoGif(animation_url)
      }
    }
  }

  return isPlatformNotWeb ? (
    <Box style={{ alignItems: 'stretch' }}>
      <AspectRatio ratio={1}>
        <Image
          accessibilityIgnoresInvertColors
          alt={item?.metadata.name || 'token'}
          source={source}
          resizeMode={'cover'}
          style={styles.image}
        />
      </AspectRatio>
    </Box>
  ) : (
    <Image
      accessibilityIgnoresInvertColors
      alt={item?.metadata.name || 'token'}
      source={source}
      resizeMode={'cover'}
      style={styles.image}
    />
  )
})

export const WalletFlatlistItem = observer((props: WalletFlatlistItemProps) => {
  const { business, onPress, item, pageConfig } = props
  const sdk = useSDK()
  const { showTitles, showBadges, showEditions } = sdk.walletPreference
  const { badges = true, titles = true, editions = true } = pageConfig?.features?.icon || {}

  const editionInfo =
    typeof item.editionInfo === 'object'
      ? `${item.editionInfo.numbered || '-'}/${
          item.editionInfo.scarcity === Infinity ? 'Inf' : item.editionInfo.scarcity
        }`
      : item.editionInfo

  const tokenName = item.metadata.name

  const handlePress = useCallback(() => onPress(item, business), [business, item, onPress])

  // const shouldRenderFace = item?.getFaceId('icon') === 'ImagePolicyFace'

  // console.log('[WalletFlatlistItem.render] shouldRenderFace', shouldRenderFace)

  // Transform pageConfig.theme.icon and title to react native styles
  const convertedIconConfig: StyleTuple[] = pageConfig?.theme?.icon
    ? Object.entries(pageConfig?.theme?.icon).map(([name, style]) => [name, style])
    : []
  const transformedIconStyle =
    convertedIconConfig.length > 0 ? transform(convertedIconConfig) : null

  const convertedTitleConfig: StyleTuple[] = pageConfig?.theme?.iconTitle
    ? Object.entries(pageConfig?.theme?.iconTitle).map(([name, style]) => [name, style])
    : []
  const transformedTitleStyle =
    convertedTitleConfig.length > 0 ? transform(convertedTitleConfig) : null

  return (
    <NFTCard
      Face={<FallbackWithNoFace item={item} />}
      onPress={handlePress}
      tokenName={tokenName}
      editionInfo={editionInfo ?? ''}
      tokenId={item.id}
      showTitles={showTitles && titles}
      showBadges={showBadges && badges}
      showEditions={showEditions && editions}
      Badges={<TokenBadges token={item} style={styles.iconChain} />}
      cardStyle={
        transformedIconStyle
          ? transformedIconStyle
          : {
              borderColor: '#E7E7E8',
              borderRadius: 4,
              backgroundColor: '#fff'
            }
      }
      titleStyle={transformedTitleStyle ? transformedTitleStyle : {}}
    />
  )
})

const styles = StyleSheet.create({
  iconChain: {
    height: 17,
    marginRight: 13,
    resizeMode: 'contain',
    width: 17
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    objectFit: 'contain'
  }
})

const getVideoGif = (url: string) => {
  const encode = encodeURIComponent(url)
  return `https://resources.vatom.com/video-to-gif?url=${encode}`
}

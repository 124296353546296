import React from 'react'
import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { useQuery } from '@tanstack/react-query'
import { MatrixData, matrixQueryKeys, RoomEvent, useMatrixUser } from '@vatom/sdk/react'
import axios from 'axios'

import RoomUserJoinIcon from '../../assets/room_user_join_icon.svg'
import { matrixServerUrl } from '../../constants'
import { useIsMember } from '../../hooks/useIsMember'
import { useMembers } from '../../queries'
import { useCommunitiesTheme } from '../../themes'
import { useRoomContext } from '../RoomProvider'

const RoomJoinButton = () => {
  const room = useRoomContext()
  const { room_id, join_rule } = room
  const communitiesTheme = useCommunitiesTheme()

  const { data: matrixUser } = useMatrixUser()
  const accessToken = matrixUser?.access_token

  const { data: roomMembers } = useMembers(room_id)

  const isMember = useIsMember({ roomId: room_id })

  const currentFullState = useQuery<MatrixData>(
    matrixQueryKeys.getMatrixFullStateSync(matrixUser?.access_token),
    { enabled: false }
  )

  let invites: RoomEvent[] = []
  const roomInviteEvents = currentFullState?.data?.rooms?.invite
  if (roomInviteEvents) {
    invites =
      roomInviteEvents[room_id]?.invite_state?.events.filter(
        inv => inv.state_key === matrixUser?.user_id && inv.content.membership === 'invite'
      ) ?? []
  }

  const handleRoomJoin = async () => {
    await axios.post(
      `${matrixServerUrl}/_matrix/client/v3/join/${room_id}?access_token=${accessToken}`
    )
  }

  const hasRoomMembers = roomMembers && roomMembers?.length > 0

  const shouldDisplayJoin = join_rule === 'public' || invites?.length > 0 || hasRoomMembers

  if (isMember) {
    return null
  }

  if (!shouldDisplayJoin) {
    return null
  }

  return (
    <TouchableOpacity
      accessibilityRole="button"
      style={{ ...styles.joinButton, backgroundColor: communitiesTheme.accentColor }}
      onPress={async () => await handleRoomJoin()}
    >
      <RoomUserJoinIcon />
      <Text style={{ ...styles.joinButtonText, color: communitiesTheme.accentColorText }}>
        Join
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  joinButton: {
    paddingVertical: 11,
    paddingHorizontal: 20,
    justifyContent: 'center',
    marginTop: 16,
    marginBottom: 5,
    marginHorizontal: 16,
    borderRadius: 3,
    flexDirection: 'row',
    alignItems: 'center'
  },
  joinButtonText: {
    marginLeft: 5,
    fontSize: 13,
    lineHeight: 18,
    fontWeight: '500'
  }
})

export default RoomJoinButton

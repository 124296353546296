import React, { PropsWithChildren, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Dimensions } from 'react-native'
import { Canvas, useThree } from '@react-three/fiber'
import * as THREE from 'three'
// @TODO: Declare window.XR8

export const use8thWall = () => {
  // @ts-ignore
  const { scene, camera } = window.XR8.Threejs.xrScene()
  const hasSet = useRef(false)

  const set = useThree(state => state.set)
  // ref to root of our ThreeJS app
  const appRef = useRef<THREE.Group>(null)

  // add our app to 8thWall's ThreeJS scene
  useEffect(() => {
    // @ts-ignore
    window.XR8.XrController.updateCameraProjectionMatrix({
      origin: camera.position,
      facing: camera.quaternion
    })
    if (scene) {
      scene.add(appRef.current)
    }
  }, [camera.position, camera.quaternion, scene])

  // set 8thWall's ThreeJS camera as default camera of
  // react-three-fiber
  useEffect(() => {
    // @ts-ignore
    window.XR8.XrController.updateCameraProjectionMatrix({
      origin: camera.position,
      facing: camera.quaternion
    })
    if (camera && !hasSet.current) {
      hasSet.current = true
      set({
        camera: camera
      })
    }
  }, [camera, set])

  return appRef
}

export const XR8Canvas = React.memo(
  (
    props: PropsWithChildren<{
      top?: number
    }>
  ) => {
    const ref = useRef(null)
    const [xr8Ready, setxr8Ready] = useState(false)
    //   const dom = useStore(s => s.dom)

    const canvasEl = useRef<HTMLCanvasElement>(null)

    useLayoutEffect(() => {
      const spamTryStart = setInterval(() => {
        // @ts-ignore
        const { XR8, XRExtras } = window

        if (XR8 && !xr8Ready) {
          clearInterval(spamTryStart)

          const load = () => {
            const grey = '#707278'
            const white = '#ffffff'
            const primary = '#FF8000'
            const black = '#000000'

            XRExtras.Loading.showLoading()
            const background = document.getElementById('loadBackground')

            const loadImage = document.getElementById('loadImage')
            if (loadImage) {
              // Create a new container div
              const container = document.createElement('div')

              // container.style.position = 'absolute'
              container.style.top = '0'
              container.style.left = '0'
              container.style.bottom = '0'
              container.style.right = '0'
              container.style.width = '100%'
              container.style.height = '100%'

              // Create a new div to hold the SVG
              const svgContainer = document.createElement('div')
              svgContainer.style.position = 'absolute'
              svgContainer.style.top = '50%'
              svgContainer.style.left = '50%'
              svgContainer.style.transform = 'translate(-50%, -50%)'

              svgContainer.style.width = '120px'
              svgContainer.style.height = '120px'
              svgContainer.innerHTML = `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#e85b2d" stroke="none">
        <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51;360 50 51"></animateTransform>
      </path>
    </svg>
  `

              // Create a new img element for the logo
              const img = document.createElement('img')
              img.src = require('./vatom-logo-small.png') // Set the correct path to your image
              img.style.position = 'absolute'
              img.style.top = '50%'
              img.style.left = '50%'
              img.style.width = '50px' // Adjust the size as needed
              img.style.height = '50px' // Adjust the size as needed
              img.style.transform = 'translate(-50%, -50%)'
              img.style.objectFit = 'contain'

              // Append the SVG and image to the container
              container.appendChild(svgContainer)
              container.appendChild(img)

              // Replace the loadImage element with the new container div
              loadImage.replaceWith(container)
            }

            const orientationPermissionBox =
              document.querySelector<HTMLDivElement>('.prompt-box-8w')
            if (orientationPermissionBox) {
              orientationPermissionBox.style.backgroundColor = grey
            }
            const buttonSecondary = document.querySelector<HTMLButtonElement>('.prompt-button-8w')
            if (buttonSecondary) {
              buttonSecondary.style.backgroundColor = grey
              buttonSecondary.style.borderWidth = '1px'
              buttonSecondary.style.borderColor = white
              buttonSecondary.style.color = white
            }

            const buttonPrimary = document.querySelector<HTMLButtonElement>('.button-primary-8w')
            if (buttonPrimary) {
              buttonPrimary.style.backgroundColor = primary
              buttonPrimary.style.color = white
            }

            if (background) {
              background.style.backgroundColor = black

              // bg.style.backgroundColor = 'red'
            }

            const requesttingCameraPermissionHeader = document.getElementById(
              'requestingCameraPermissions'
            )
            if (requesttingCameraPermissionHeader) {
              requesttingCameraPermissionHeader.style.backgroundColor = grey
            }
          }
          XRExtras ? load() : window.addEventListener('xrextrasloaded', load)

          // XR8.GlTextureRenderer.configure({
          //   fragmentSource: fragment
          // }) // postprocess

          window.THREE = THREE
          XR8.addCameraPipelineModules([
            XR8.GlTextureRenderer.pipelineModule(), // Draws the camera feed.
            XR8.Threejs.pipelineModule(), // Creates a ThreeJS AR Scene.
            XR8.XrController.pipelineModule(), // Enables SLAM tracking.
            // window.LandingPage.pipelineModule(), // Detects unsupported browsers and gives hints.
            //   XRExtras.FullWindowCanvas.pipelineModule(), // Modifies the canvas to fill the window.
            XRExtras.Loading.pipelineModule(), // Manages the loading screen on startup.
            XRExtras.RuntimeError.pipelineModule() // Shows an error image on runtime error.
          ])

          XR8.addCameraPipelineModule({
            name: 'callbackmount',
            onException: (e: any) => {
              console.error(' addCameraPipelineModule exception', e)
              alert('ERROR ' + e)
            },
            onStart: () => {
              console.log('isLOADED!')
              setxr8Ready(true)
            }
          })
          XR8.XrController.configure({
            enableLighting: false,
            enableWorldPoints: true,
            disableWorldTracking: false
            // scale: 'absolute'
          })

          XR8.run({
            canvas: canvasEl.current
          })
        }
      }, 100)
    }, [xr8Ready])

    useEffect(() => {
      return () => {
        // @ts-ignore
        if (window.XR8) {
          // @ts-ignore
          window.XR8.stop()
          // @ts-ignore
          window.XR8.clearCameraPipelineModules()
        }
      }
    }, [])

    return (
      <>
        <Canvas
          linear
          ref={ref}
          id={'r3fcanvas'}
          // @ts-ignore

          domElement={canvasEl.current}
          onCreated={state => {
            // state.events.connect(dom.current)
          }}
          style={{
            position: 'absolute',
            left: 0,
            top: props.top ?? 0,
            right: 0,
            bottom: 0
          }}
        >
          {xr8Ready && props.children}
        </Canvas>

        <canvas
          height={Dimensions.get('window').height - (props.top ?? 0)}
          width={Dimensions.get('window').width}
          id="xrcanvas"
          ref={canvasEl}
        />
      </>
    )
  }
)

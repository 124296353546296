import React, { useMemo } from 'react'
import { Platform } from 'react-native'
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Box } from 'native-base'

import { useLayoutScrollHandler } from '../../hooks/useLayoutScrollHandler'
import { useTabNavConfig } from '../../screens/Home/hooks/useTabNavConfig'

const AnimatedBox = Animated.createAnimatedComponent(Box)

type FABProps = React.PropsWithChildren<{
  enabled?: boolean
  containerProps?: React.ComponentProps<typeof Box>
}>

const FAB = ({ children, enabled = true, containerProps = {} }: FABProps) => {
  const insets = useSafeAreaInsets()
  const { shouldHideElements, defaultAnimationDuration, defaultAnimationEasing, getFooterHeight } =
    useLayoutScrollHandler()
  const { tabsToRender } = useTabNavConfig()

  const hasFooter = tabsToRender.length > 1

  const footerHeight = hasFooter ? getFooterHeight() : 0
  const distanceToFooter = Platform.select({
    android: 40,
    ios: 20,
    default: 0
  })
  const bottomOffsetDefault = footerHeight + distanceToFooter + insets.bottom + insets.top
  const bottomOffset = Platform.select({
    android: bottomOffsetDefault + footerHeight,
    default: bottomOffsetDefault
  })

  const animatedStyle = useAnimatedStyle(() => {
    const height = hasFooter ? footerHeight - insets.bottom : footerHeight
    const offsetY = shouldHideElements.value ? height : 0
    return {
      transform: [
        {
          translateY: withTiming(offsetY, {
            duration: defaultAnimationDuration,
            easing: defaultAnimationEasing
          })
        }
      ]
    }
  }, [hasFooter, footerHeight])

  if (!enabled) return null

  return (
    <AnimatedBox
      position={'absolute'}
      bottom={bottomOffset}
      right={4}
      zIndex={9}
      _web={{
        marginTop: -60,
        textAlign: 'right',
        position: 'sticky',
        zIndex: 999,
        alignItems: 'flex-end',
        right: 4,
        bottom: 24,
        width: 60,
        // @ts-expect-error - web only
        transform: [{ translateY: 0 }],
        alignSelf: 'flex-end'
      }}
      style={animatedStyle}
      {...containerProps}
    >
      {children}
    </AnimatedBox>
  )
}

export { FAB }

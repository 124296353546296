import React from 'react'
import ReactQRCode from 'react-qr-code'
import { Ionicons } from '@expo/vector-icons'
import { Box, Modal, Pressable, VStack } from 'native-base'

interface QRCodeVisible {
  qrCodeVisible: boolean
  setQrCodeVisible: (b: boolean) => void
  qrLink: string
}
export function QrCode({ qrCodeVisible, setQrCodeVisible, qrLink }: QRCodeVisible) {
  return (
    <Modal
      padding="24px"
      isOpen={qrCodeVisible}
      onClose={() => setQrCodeVisible(false)}
      safeAreaTop={true}
    >
      <Box padding="30px" alignItems="center" backgroundColor="white" borderRadius="8px">
        <Pressable
          accessibilityRole="button"
          top="21px"
          right="21px"
          position="absolute"
          onPress={() => setQrCodeVisible(false)}
        >
          <Ionicons name="close-sharp" size={24} color="black" />
        </Pressable>
        {/* <Text
          alignSelf="center"
          fontSize="16"
          fontWeight="400"
          mt={4}
          noOfLines={1}
          ellipsizeMode="tail"
        >
          {walletAddress}
        </Text> */}
        <VStack marginTop="30px" marginX="5px">
          <ReactQRCode value={qrLink} size={255} />
        </VStack>
      </Box>
    </Modal>
  )
}

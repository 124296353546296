import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const SendMessageIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 18 18" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M16.5625 1.1875C16.875 1.40625 17.0312 1.78125 16.9688 2.15625L14.9688 15.1562C14.9375 15.4688 14.75 15.7188 14.4688 15.875C14.2188 16.0312 13.875 16.0625 13.5938 15.9375L9.875 14.375L7.71875 16.6875C7.4375 17 7 17.0938 6.625 16.9375C6.25 16.8125 6 16.4375 6 16V13.4062C6 13.2812 6.03125 13.1562 6.125 13.0625L11.3438 7.34375C11.5312 7.15625 11.5312 6.84375 11.3438 6.65625C11.1562 6.46875 10.8438 6.46875 10.6562 6.65625L4.3125 12.2812L1.53125 10.9062C1.21875 10.75 1 10.4062 1 10.0312C0.96875 9.6875 1.15625 9.34375 1.5 9.15625L15.5 1.15625C15.8125 0.96875 16.25 0.96875 16.5625 1.1875Z"
    />
  </Icon>
)

import React from 'react'
import { StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useMatrixUser } from '@vatom/sdk/react'
import axios from 'axios'
import { IEvent } from 'matrix-js-sdk'

import { matrixServerUrl } from '../../../../constants'
import { useUserQuestionVotes } from '../../../../queries'
import { useCommunitiesTheme } from '../../../../themes'
import { QuestionType } from '../../../../types'
import { EmojiStyle, NumberedStyle, StarStyle } from '../../../ScoreStyles'
import { useMessageContext } from '../../MessageContext'
import { WebDisplay } from '../TextParagraph'

import PollComponent from './PollComponent'

type QuestionProps = {
  question: QuestionType
  roomId: string
  additionalInfo: any
  spaceId: string
}

const Description = ({
  description,
  roomID,
  spaceId
}: {
  description: string
  roomID: string
  spaceId: string
}) => {
  const [showMore, setShowMore] = React.useState(false)
  const [webViewHeight, setWebViewHeight] = React.useState(0)
  const communitiesTheme = useCommunitiesTheme()

  return description ? (
    <View style={{ marginBottom: 8 }}>
      <View
        style={{
          height: showMore ? webViewHeight : 0,
          position: 'relative',
          maxHeight: webViewHeight,
          overflow: 'hidden'
        }}
      >
        <View
          onLayout={event => {
            const { height } = event.nativeEvent.layout
            setWebViewHeight(height)
          }}
          style={{ position: 'absolute', flex: 1, top: 0, zIndex: 1 }}
        >
          <WebDisplay
            text={description}
            textStyle={{ color: communitiesTheme.sketches.titleColor }}
            spaceId={spaceId}
          />
        </View>
      </View>

      <TouchableWithoutFeedback accessibilityRole="button" onPress={() => setShowMore(!showMore)}>
        <View>
          <Text
            style={{
              fontSize: 15,
              fontWeight: '600',
              lineHeight: 20,
              color: communitiesTheme.memberInfo.linkColor
            }}
          >
            Show {showMore ? 'less' : 'more'}
          </Text>
        </View>
      </TouchableWithoutFeedback>
    </View>
  ) : null
}

const Question = (props: QuestionProps) => {
  const { messageEventID } = useMessageContext()
  const { question, roomId, additionalInfo, spaceId } = props
  const { startTime, endTime } = additionalInfo
  const { data: matrixUser } = useMatrixUser()
  const userId = matrixUser?.user_id

  const queryClient = useQueryClient()
  const communitiesTheme = useCommunitiesTheme()

  const { data } = useUserQuestionVotes(roomId, messageEventID, question.id)
  const questionVotes =
    data?.chunk?.map((pollVote: IEvent) => ({
      index: pollVote.content.body.index,
      sender: pollVote.sender,
      eventId: pollVote.event_id
    })) ?? []

  if (!questionVotes) {
    return null
  }

  const hasUserVoted = questionVotes.length > 0

  const handleVote = async (choice: any) => {
    if (isSketchLocked()) {
      return
    }
    const userVote = questionVotes.find((pollVote: any) => pollVote.sender === userId)
    if (userVote) {
      if (userVote.index !== choice.index) {
        removeVote.mutate(userVote)
        addVote.mutate(choice)
      }
    } else {
      addVote.mutate(choice)
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const addVote = useMutation({
    mutationFn: (choice: any) =>
      axios.post(
        `${matrixServerUrl}/_matrix/client/r0/rooms/${roomId}/send/v.room.question.vote?access_token=${matrixUser?.access_token}`,
        {
          'm.relates_to': {
            rel_type: matrixUser?.user_id + '.question.vote.qn-' + question.id,
            event_id: messageEventID
          },
          body: {
            index: choice.index,
            questionIndex: question.id
          }
        }
      ),
    onMutate: async (choice: any) => {
      await queryClient.cancelQueries({
        queryKey: ['message-user-question-votes', roomId, messageEventID, question.id, userId]
      })

      const previousVote = queryClient.getQueryData([
        ['message-user-question-votes', roomId, messageEventID, question.id, userId]
      ])

      queryClient.setQueryData(
        ['message-user-question-votes', roomId, messageEventID, question.id, userId],
        (old: any) => ({
          chunk: [
            {
              content: {
                body: {
                  index: choice.index
                },
                'm.relates_to': {
                  event_id: messageEventID,
                  rel_type: matrixUser?.user_id + '.question.vote.qn-' + question.id
                }
              },
              room_id: roomId,
              sender: matrixUser?.user_id,
              type: 'v.room.question.vote',
              unsigned: {
                age: 330
              },
              user_id: matrixUser?.user_id
            }
          ]
        })
      )

      return { previousVote }
    },
    onError: (err, newVote, context) => {
      queryClient.setQueryData(
        ['message-user-question-votes', roomId, messageEventID, question.id, userId],
        context?.previousVote
      )
    }
  })

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const removeVote = useMutation({
    mutationFn: (choice: any) => {
      const dateNow = Date.now()
      return axios.put(
        `${matrixServerUrl}/_matrix/client/r0/rooms/${roomId}/redact/${choice.event_id}/${messageEventID}.question.${dateNow}?access_token=${matrixUser?.access_token}`,
        {}
      )
    },
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ['message-user-question-votes', roomId, messageEventID, question.id, userId]
      })

      const previousVote = queryClient.getQueryData([
        ['message-user-question-votes', roomId, messageEventID, question.id, userId]
      ])

      return { previousVote }
    },
    onError: (err, newVote, context) => {
      queryClient.setQueryData(
        ['message-user-question-votes', roomId, messageEventID, question.id, userId],
        context?.previousVote
      )
    }
  })

  const displayScoreStyle = (style: string) => {
    switch (style) {
      case 'numbered-1-5':
        return {
          styleComponent: (
            <NumberedStyle
              length={5}
              isInChatBubble
              handleVote={handleVote}
              pollVotes={questionVotes}
              userId={userId}
            />
          ),
          choices: Array.from({ length: 5 }, (_, i) => i + 1)
        }
      case 'numbered-0-5':
        return {
          styleComponent: (
            <NumberedStyle
              fromZero
              length={5}
              isInChatBubble
              handleVote={handleVote}
              pollVotes={questionVotes}
              userId={userId}
            />
          ),
          choices: Array.from(Array(6).keys())
        }
      case 'numbered-1-10':
        return {
          styleComponent: (
            <NumberedStyle
              length={10}
              isInChatBubble
              handleVote={handleVote}
              pollVotes={questionVotes}
              userId={userId}
            />
          ),
          choices: Array.from({ length: 10 }, (_, i) => i + 1)
        }
      case 'numbered-0-10':
        return {
          styleComponent: (
            <NumberedStyle
              fromZero
              length={10}
              isInChatBubble
              handleVote={handleVote}
              pollVotes={questionVotes}
              userId={userId}
            />
          ),
          choices: Array.from(Array(11).keys())
        }
      case 'emojies':
        return {
          styleComponent: (
            <EmojiStyle
              isInChatBubble
              handleVote={handleVote}
              pollVotes={questionVotes}
              userId={userId}
            />
          ),
          choices: Array.from({ length: 5 }, (_, i) => i + 1)
        }
      case 'stars':
        return {
          styleComponent: (
            <StarStyle
              isInChatBubble
              handleVote={handleVote}
              pollVotes={questionVotes}
              userId={userId}
            />
          ),
          choices: Array.from({ length: 5 }, (_, i) => i + 1)
        }
      default:
        return {
          styleComponent: null,
          choices: []
        }
    }
  }

  const { styleComponent } = displayScoreStyle(question.scoreStyle)

  const { borderLeft, borderLeftActive } = communitiesTheme.sketches

  const isSketchLocked = () => {
    const currentDateTimestamp = new Date().getTime()
    return endTime < currentDateTimestamp || startTime > currentDateTimestamp
  }

  return question.pollChoices.length > 0 ? (
    <PollComponent
      question={question}
      roomID={roomId}
      eventID={messageEventID}
      isLocked={isSketchLocked()}
      spaceId={spaceId}
    />
  ) : (
    <View
      style={[
        { ...styles.container, borderLeftColor: hasUserVoted ? borderLeftActive : borderLeft },
        { backgroundColor: communitiesTheme.sketches.backgroundColor }
      ]}
    >
      <Text style={[styles.questionNumber, { color: communitiesTheme.sketches.subTitleColor }]}>
        {question.id + 1}
      </Text>
      <Text style={[styles.title, { color: communitiesTheme.sketches.titleColor }]}>
        {question.title}
      </Text>
      <Description description={question.text} roomID={roomId} spaceId={spaceId} />

      <View style={{ marginTop: 8 }}>{styleComponent}</View>

      {question.responseAnchor ? (
        <Text style={styles.anchor}>{question.responseAnchor}</Text>
      ) : null}
    </View>
  )
}

export default Question

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    paddingHorizontal: 16,
    paddingBottom: 12,
    marginTop: 8,
    borderRadius: 4,
    borderLeftWidth: 2
  },
  title: {
    fontSize: 16,
    lineHeight: 22,
    fontWeight: '600',
    marginBottom: 4
  },
  anchor: {
    fontSize: 11,
    lineHeight: 15,
    color: '#868E96',
    marginTop: 8
  },
  questionNumber: {
    fontSize: 13,
    lineHeight: 18,
    fontWeight: '500',
    position: 'absolute',
    right: 10,
    top: 10
  }
})

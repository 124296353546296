import React from 'react'
import { Switch } from '@vatom/wombo'
import { Box } from 'native-base'

import FullScreenDivider from '../../partials/FullScreenDivider'

export interface ISwitchItem {
  label: string
  value: boolean
  onToggle: () => void
}

interface ListSwitchesProps {
  data: ISwitchItem[]
  topDivider?: boolean
  bottomDivider?: boolean
}

interface SwitchItemProps {
  item: ISwitchItem
  index: number
}

export const ListSwitches = ({ data, topDivider, bottomDivider }: ListSwitchesProps) => {
  const SwitchItem = ({ item, index }: SwitchItemProps) => {
    return (
      <>
        <Box w="100%" paddingY="11px">
          <Switch label={item.label} value={item.value} onToggle={item.onToggle} />
        </Box>
        {index + 1 !== data.length && <FullScreenDivider />}
      </>
    )
  }

  return (
    <Box marginY="6px">
      {topDivider && <FullScreenDivider />}
      {data.map((item, index) => (
        <SwitchItem
          key={`wallet-preference-${item.label.toLowerCase().replace(/\s/g, '-')}`}
          item={item}
          index={index}
        />
      ))}
      {bottomDivider && <FullScreenDivider />}
    </Box>
  )
}

export default ListSwitches

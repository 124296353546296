/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * WalletNftCountBase
 * auto generated base class for the model WalletNftCountModel.
 */
export const WalletNftCountModelBase = ModelBase
  .named('WalletNftCount')
  .props({
    __typename: types.optional(types.literal("WalletNftCount"), "WalletNftCount"),
    owned: types.union(types.undefined, types.integer),
    offered: types.union(types.undefined, types.integer),
    listed: types.union(types.undefined, types.integer),
    created: types.union(types.undefined, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class WalletNftCountModelSelector extends QueryBuilder {
  get owned() { return this.__attr(`owned`) }
  get offered() { return this.__attr(`offered`) }
  get listed() { return this.__attr(`listed`) }
  get created() { return this.__attr(`created`) }
}
export function selectFromWalletNftCount() {
  return new WalletNftCountModelSelector()
}

export const walletNftCountModelPrimitives = selectFromWalletNftCount().owned.offered.listed.created

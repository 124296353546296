import { useState } from 'react'
import * as ExpoImagePicker from 'expo-image-picker'
import { useThrottledCallback } from 'use-debounce'

export function useImagePicker({
  throttled = 300,
  onSuccess,
  onCancel
}: {
  throttled?: number
  onSuccess?: (result: ExpoImagePicker.ImagePickerSuccessResult) => void
  onCancel?: () => void
} = {}) {
  const [images, setImages] = useState<ExpoImagePicker.ImagePickerAsset[]>([])

  const pickImage = useThrottledCallback(
    async (imagePickerOptions: ExpoImagePicker.ImagePickerOptions = {}) => {
      // No permissions request is necessary for launching the image library
      const result = await ExpoImagePicker.launchImageLibraryAsync({
        mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
        // allowsEditing: true,
        // aspect: [4, 3],
        allowsMultipleSelection: true,
        selectionLimit: 10,
        quality: 1,
        ...imagePickerOptions
      })
      console.log('LOG: useImagePicker > result:', result)
      if (result.canceled === true) {
        onCancel && onCancel()
      }

      if (!result.canceled) {
        onSuccess && onSuccess(result)
        setImages(() => result.assets)
      }
    },
    throttled,
    {
      leading: true
    }
  )

  return {
    images,
    pickImage
  }
}

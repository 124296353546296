import { useEffect, useRef } from 'react'
import { useMatrixUser } from '@vatom/sdk/react'
import * as MatrixSDK from 'matrix-js-sdk'

export function initMatrixClient(userId: string, accessToken: string) {
  const matrixClient = MatrixSDK.createClient({
    baseUrl: 'https://matrix.api.vatominc.com',
    accessToken: accessToken,
    userId: userId
  })
  matrixClient.startClient()
  return matrixClient
}
const useMatrixSDK = () => {
  const { data: matrixUser } = useMatrixUser()
  const matrixClient = useRef<MatrixSDK.MatrixClient | null>(null)

  useEffect(() => {
    if (matrixUser?.user_id && matrixUser?.access_token) {
      if (matrixClient.current === null)
        matrixClient.current = initMatrixClient(matrixUser.user_id, matrixUser.access_token)
    }
  }, [matrixUser])

  return matrixClient
}

export { MatrixSDK, useMatrixSDK }

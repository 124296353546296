/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useGetSpace, useMatrixUser } from '@vatom/sdk/react'

import { useMessage, useMessageFacades, useMessageHide, useUserPowerLevel } from '../../queries'
import { useCommunitiesTheme } from '../../themes'
import { MatrixReplyType } from '../../types'
import { useCommunitySpace } from '../../useCommunitySpace'

import Header from './chat-components/Header'
import PostHiddenTag from './chat-components/PostHiddenTag'
import Content from './Content'
import Decorations from './Decorations'
import { MessageProvider } from './MessageContext'

const ThreadChatBubble = ({
  threadMessage,
  spaceId
}: {
  threadMessage: MatrixReplyType
  spaceId: string
}) => {
  const eventType = 'v.room.reply'
  const { data: matrixUser } = useMatrixUser()
  const { businessId } = useCommunitySpace()
  const space = useGetSpace(spaceId, {
    enabled: false
  })
  const roomId = space.data?.matrixRoomId ?? ''

  const { data: matrixThreadMessage } = useMessage(roomId, threadMessage.eventId)

  const { data: messagesFacades } = useMessageFacades(['v.room.reply'], businessId)

  const messageFacade = messagesFacades?.find(
    (facade: { type: string }) => facade.type === eventType
  )
  const { data: messageHidden } = useMessageHide(roomId, threadMessage.eventId)
  const isHidden = messageHidden?.chunk[0]?.content.isHidden

  const userPowerLevel = useUserPowerLevel(roomId)
  const isDisplayed = userPowerLevel >= 90 || matrixUser?.user_id === threadMessage.sender

  const messageFacadeElements = messageFacade?.elements

  const communitiesTheme = useCommunitiesTheme()

  if (!messageFacade || !matrixThreadMessage) {
    return null
  }

  const isJson = (str: string) => {
    let value = typeof str !== 'string' ? JSON.stringify(str) : str
    try {
      value = JSON.parse(value)
    } catch (e) {
      return false
    }
    return typeof value === 'object' && value !== null
  }

  let messageContentElements: any = []

  const {
    content: { body }
  } = matrixThreadMessage

  if (isJson(body)) {
    const parsedBody = JSON.parse(body)
    messageContentElements = parsedBody.elements
  } else {
    messageContentElements = [{ type: 'text', body }]
  }

  return (
    <View
      style={[
        styles.container,
        { backgroundColor: communitiesTheme.background },
        { display: isHidden && !isDisplayed ? 'none' : 'flex' }
      ]}
    >
      <MessageProvider messageEventID={threadMessage.eventId}>
        {messageFacadeElements.map((facadeElements: any) => {
          switch (facadeElements.type) {
            case 'header':
              return (
                <Header
                  spaceId={spaceId}
                  isThread={true}
                  decorations={facadeElements.decorations}
                  isReplyMessage
                  key="header"
                />
              )
            case 'content':
              return (
                <View
                  key="content"
                  style={{ marginLeft: facadeElements.align === 'indent' ? 55 : 0 }}
                >
                  <PostHiddenTag key={`hidden-tag-${threadMessage.eventId}`} roomId={roomId} />
                  <Content
                    facadeElements={facadeElements}
                    messageContentElements={messageContentElements}
                    isThread={false}
                    spaceId={spaceId}
                  />
                </View>
              )
            case 'decorations':
              return (
                <Decorations
                  key="decoration"
                  matrixMessage={threadMessage}
                  spaceId={spaceId}
                  isThread={true}
                  facadeElements={facadeElements}
                />
              )
            default:
              return null
          }
        })}
      </MessageProvider>
    </View>
  )
}

export default ThreadChatBubble

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingTop: 8,
    paddingBottom: 16
  }
})

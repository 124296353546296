import { memo } from 'react'
import {
  getRoomsByBusinessIdSelector,
  useBusiness,
  useGetMatrixFullStateSync,
  useMatrixUser
} from '@vatom/sdk/react'
import { Loader } from '@vatom/wombo'
import { Box, FlatList } from 'native-base'

import { RoomHeading } from './RoomHeading'
import { SpaceRoom } from './SectionSpaces'
import { SpacePreviewMemo } from './SpacePreview'

export const SectionHeader = ({ businessId }: { businessId: string }) => {
  const business = useBusiness({ businessId })

  return business ? (
    <RoomHeading
      coverImage={business?.data?.logoSrc ?? ''}
      displayName={business?.data?.displayName ?? ''}
      // subTitle={business?.data?.subtitle} ???
      trophy={0}
    />
  ) : null
}

export const MySpaces = memo(
  ({
    businessId,
    onSelectSpaceRoom
  }: {
    businessId: string
    onSelectSpaceRoom: (spaceRoom: SpaceRoom) => void
  }) => {
    const matrixUser = useMatrixUser()
    const rooms = useGetMatrixFullStateSync({
      select: matrixData => {
        return getRoomsByBusinessIdSelector(matrixData, matrixUser, businessId)
      }
    })

    return rooms.isLoading ? (
      <Box>
        <Loader />
      </Box>
    ) : (
      <FlatList
        data={rooms.data}
        renderItem={({ item }) => (
          <SpacePreviewMemo
            onSelectSpaceRoom={onSelectSpaceRoom}
            spaceId={item.spaceId}
            businessId={item.businessId}
            matrixRoomId={item.matrixRoomId}
            displayName={item.displayName}
            cover={item.cover}
            isPrivate={item.visibility === 'private'}
            memberCount={Object.keys(item.membersDetails ?? {}).length}
            noMiddle={true}
          />
        )}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item, index) => `spaces-room-${item.matrixRoomId}-${index}`}
      />
    )
  }
)

import { StatusBarStyle } from 'react-native'
import { PageThemeEnum } from '@vatom/sdk/core'
import { BusinessData } from '@vatom/sdk/react'
import { theme } from '@vatom/wombo'

import { themeBussines } from '../../Business'

export interface IUseTokenBusiness {
  business?: BusinessData
}

export const useTokenBusiness = ({ business }: IUseTokenBusiness) => {
  const pageConfig = business?.pageConfig
  const pageTheme = pageConfig?.theme?.pageTheme || PageThemeEnum.Light
  const themePage = themeBussines(pageTheme)

  const statusBarStyle: StatusBarStyle =
    pageTheme === PageThemeEnum.Dark ? 'light-content' : 'dark-content'
  const statusBarBackgroundColor =
    pageTheme === PageThemeEnum.Dark ? themePage.backgroundRgba : theme.colors.white

  return {
    themePage,
    statusBarStyle,
    statusBarBackgroundColor
  }
}

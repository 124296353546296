// eslint-disable-next-line unicorn/filename-case
import { useMemo, useRef } from 'react'
import { TokenType } from '@vatom/sdk/core'
import { useTokenInventory } from '@vatom/sdk/react'
import { VatomModelType } from '@vatom/vatomNew/plugin'

export interface IUseToken {
  tokenId: string
  contractAddress?: string
  owner?: string
}

export function isVatomNew(token?: TokenType): token is VatomModelType {
  return token?.type === 'vatom-new'
}

export const useToken = <TToken extends TokenType>({
  tokenId,
  contractAddress,
  owner
}: IUseToken) => {
  const options = contractAddress && owner ? { contractAddress, owner } : undefined
  const { token: theToken, isLoading } = useTokenInventory(tokenId, options)
  const prevToken = useRef(theToken)

  const shouldRerender = prevToken.current?.id !== theToken?.id
  const token = useMemo(() => {
    if (theToken && shouldRerender) {
      prevToken.current = theToken
    }
    return prevToken.current
  }, [shouldRerender, theToken])

  return {
    token,
    isLoading
  } as {
    token: TToken
    isLoading: boolean
  }
}

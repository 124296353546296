/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { BidReceiptModel, BidReceiptModelType } from './BidReceiptModel'
import { BidReceiptModelSelector } from './BidReceiptModel.base'
import { CreatorModel, CreatorModelType } from './CreatorModel'
import { CreatorModelSelector } from './CreatorModel.base'
import { DenylistModel, DenylistModelType } from './DenylistModel'
import { DenylistModelSelector } from './DenylistModel.base'
import {
  EnrichedBondingChangeModel,
  EnrichedBondingChangeModelType
} from './EnrichedBondingChangeModel'
import { EnrichedBondingChangeModelSelector } from './EnrichedBondingChangeModel.base'
import { FeedEventModelSelector } from './FeedEventModelSelector'
import { FollowEventModel, FollowEventModelType } from './FollowEventModel'
import { FollowEventModelSelector } from './FollowEventModel.base'
import { GraphConnectionModel, GraphConnectionModelType } from './GraphConnectionModel'
import { GraphConnectionModelSelector } from './GraphConnectionModel.base'
import { ListingEventModel, ListingEventModelType } from './ListingEventModel'
import { ListingEventModelSelector } from './ListingEventModel.base'
import { ListingModel, ListingModelType } from './ListingModel'
import { ListingModelSelector } from './ListingModel.base'
import { MarketplaceModel, MarketplaceModelType } from './MarketplaceModel'
import { MarketplaceModelSelector } from './MarketplaceModel.base'
import { MetadataJsonModel, MetadataJsonModelType } from './MetadataJsonModel'
import { MetadataJsonModelSelector } from './MetadataJsonModel.base'
import { MintEventModel, MintEventModelType } from './MintEventModel'
import { MintEventModelSelector } from './MintEventModel.base'
import { NftActivityModel, NftActivityModelType } from './NftActivityModel'
import { NftActivityModelSelector } from './NftActivityModel.base'
import { NftCountModel, NftCountModelType } from './NftCountModel'
import { NftCountModelSelector } from './NftCountModel.base'
import { NftModel, NftModelType } from './NftModel'
import { NftModelSelector } from './NftModel.base'
import { OfferEventModel, OfferEventModelType } from './OfferEventModel'
import { OfferEventModelSelector } from './OfferEventModel.base'
import { PriceChartModel, PriceChartModelType } from './PriceChartModel'
import { PriceChartModelSelector } from './PriceChartModel.base'
import { ProfileModel, ProfileModelType } from './ProfileModel'
import { ProfileModelSelector } from './ProfileModel.base'
import { PurchaseEventModel, PurchaseEventModelType } from './PurchaseEventModel'
import { PurchaseEventModelSelector } from './PurchaseEventModel.base'
import { StorefrontModel, StorefrontModelType } from './StorefrontModel'
import { StorefrontModelSelector } from './StorefrontModel.base'
import { WalletModel, WalletModelType } from './WalletModel'
import { WalletModelSelector } from './WalletModel.base'
import { RootStoreType } from './index'

/**
 * QueryRootBase
 * auto generated base class for the model QueryRootModel.
 */
export const QueryRootModelBase = ModelBase.named('QueryRoot')
  .props({
    __typename: types.optional(types.literal('QueryRoot'), 'QueryRoot'),
    /** Recommend wallets to follow. */
    followWallets: types.union(types.undefined, types.array(types.late((): any => WalletModel))),
    /** Returns events for the wallets the user is following using the graph_program. */
    feedEvents: types.union(
      types.undefined,
      types.array(
        types.union(
          types.late((): any => ListingEventModel),
          types.late((): any => FollowEventModel),
          types.late((): any => PurchaseEventModel),
          types.late((): any => MintEventModel),
          types.late((): any => OfferEventModel)
        )
      )
    ),
    nftCounts: types.union(
      types.undefined,
      types.late((): any => NftCountModel)
    ),
    charts: types.union(
      types.undefined,
      types.late((): any => PriceChartModel)
    ),
    activities: types.union(types.undefined, types.array(types.late((): any => NftActivityModel))),
    profile: types.union(
      types.undefined,
      types.null,
      types.late((): any => ProfileModel)
    ),
    enrichedBondingChanges: types.union(
      types.undefined,
      types.array(types.late((): any => EnrichedBondingChangeModel))
    ),
    offer: types.union(
      types.undefined,
      types.null,
      types.late((): any => BidReceiptModel)
    ),
    connections: types.union(
      types.undefined,
      types.array(types.late((): any => GraphConnectionModel))
    ),
    creator: types.union(
      types.undefined,
      types.late((): any => CreatorModel)
    ),
    nfts: types.union(types.undefined, types.array(types.late((): any => NftModel))),
    wallet: types.union(
      types.undefined,
      types.late((): any => WalletModel)
    ),
    listings: types.union(types.undefined, types.array(types.late((): any => ListingModel))),
    nft: types.union(
      types.undefined,
      types.null,
      types.late((): any => NftModel)
    ),
    storefronts: types.union(types.undefined, types.array(types.late((): any => StorefrontModel))),
    /** A storefront */
    storefront: types.union(
      types.undefined,
      types.null,
      types.late((): any => StorefrontModel)
    ),
    /** A marketplace */
    marketplace: types.union(
      types.undefined,
      types.null,
      types.late((): any => MarketplaceModel)
    ),
    /** returns metadata_jsons matching the term */
    metadataJsons: types.union(
      types.undefined,
      types.array(types.late((): any => MetadataJsonModel))
    ),
    /** returns profiles matching the search term */
    profiles: types.union(types.undefined, types.array(types.late((): any => WalletModel))),
    denylist: types.union(
      types.undefined,
      types.late((): any => DenylistModel)
    )
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class QueryRootModelSelector extends QueryBuilder {
  followWallets(
    builder:
      | string
      | WalletModelSelector
      | ((selector: WalletModelSelector) => WalletModelSelector)
      | undefined,
    args: { wallet?: any; limit: number; offset: number }
  ) {
    return this.__child(
      `followWallets(wallet: ${JSON.stringify(args['wallet'])}, limit: ${JSON.stringify(
        args['limit']
      )}, offset: ${JSON.stringify(args['offset'])})`,
      WalletModelSelector,
      builder
    )
  }
  feedEvents(
    builder:
      | string
      | FeedEventModelSelector
      | ((selector: FeedEventModelSelector) => FeedEventModelSelector)
      | undefined
  ) {
    return this.__child(`feedEvents`, FeedEventModelSelector, builder)
  }
  nftCounts(
    builder:
      | string
      | NftCountModelSelector
      | ((selector: NftCountModelSelector) => NftCountModelSelector)
      | undefined,
    args: { creators: any[] }
  ) {
    return this.__child(
      `nftCounts(creators: ${JSON.stringify(args['creators'])})`,
      NftCountModelSelector,
      builder
    )
  }
  charts(
    builder:
      | string
      | PriceChartModelSelector
      | ((selector: PriceChartModelSelector) => PriceChartModelSelector)
      | undefined,
    args: { auctionHouses: any[]; startDate: any; endDate: any }
  ) {
    return this.__child(
      `charts(auctionHouses: ${JSON.stringify(args['auctionHouses'])}, startDate: ${JSON.stringify(
        args['startDate']
      )}, endDate: ${JSON.stringify(args['endDate'])})`,
      PriceChartModelSelector,
      builder
    )
  }
  activities(
    builder:
      | string
      | NftActivityModelSelector
      | ((selector: NftActivityModelSelector) => NftActivityModelSelector)
      | undefined,
    args: { auctionHouses: any[] }
  ) {
    return this.__child(
      `activities(auctionHouses: ${JSON.stringify(args['auctionHouses'])})`,
      NftActivityModelSelector,
      builder
    )
  }
  profile(
    builder:
      | string
      | ProfileModelSelector
      | ((selector: ProfileModelSelector) => ProfileModelSelector)
      | undefined,
    args: { handle: string }
  ) {
    return this.__child(
      `profile(handle: ${JSON.stringify(args['handle'])})`,
      ProfileModelSelector,
      builder
    )
  }
  enrichedBondingChanges(
    builder:
      | string
      | EnrichedBondingChangeModelSelector
      | ((selector: EnrichedBondingChangeModelSelector) => EnrichedBondingChangeModelSelector)
      | undefined,
    args: { address: any; startUnixTime: any; stopUnixTime: any; limit: number; offset: number }
  ) {
    return this.__child(
      `enrichedBondingChanges(address: ${JSON.stringify(
        args['address']
      )}, startUnixTime: ${JSON.stringify(args['startUnixTime'])}, stopUnixTime: ${JSON.stringify(
        args['stopUnixTime']
      )}, limit: ${JSON.stringify(args['limit'])}, offset: ${JSON.stringify(args['offset'])})`,
      EnrichedBondingChangeModelSelector,
      builder
    )
  }
  offer(
    builder:
      | string
      | BidReceiptModelSelector
      | ((selector: BidReceiptModelSelector) => BidReceiptModelSelector)
      | undefined,
    args: { address: string }
  ) {
    return this.__child(
      `offer(address: ${JSON.stringify(args['address'])})`,
      BidReceiptModelSelector,
      builder
    )
  }
  connections(
    builder:
      | string
      | GraphConnectionModelSelector
      | ((selector: GraphConnectionModelSelector) => GraphConnectionModelSelector)
      | undefined,
    args: { from?: any[]; to?: any[]; limit: number; offset: number }
  ) {
    return this.__child(
      `connections(from: ${JSON.stringify(args['from'])}, to: ${JSON.stringify(
        args['to']
      )}, limit: ${JSON.stringify(args['limit'])}, offset: ${JSON.stringify(args['offset'])})`,
      GraphConnectionModelSelector,
      builder
    )
  }
  creator(
    builder:
      | string
      | CreatorModelSelector
      | ((selector: CreatorModelSelector) => CreatorModelSelector)
      | undefined,
    args: { address: string }
  ) {
    return this.__child(
      `creator(address: ${JSON.stringify(args['address'])})`,
      CreatorModelSelector,
      builder
    )
  }
  nfts(
    builder:
      | string
      | NftModelSelector
      | ((selector: NftModelSelector) => NftModelSelector)
      | undefined,
    args: {
      owners?: any[]
      creators?: any[]
      offerers?: any[]
      attributes?: any
      listed?: boolean
      auctionHouses?: any[]
      collection?: any
      limit: number
      offset: number
    }
  ) {
    return this.__child(
      `nfts(owners: ${JSON.stringify(args['owners'])}, creators: ${JSON.stringify(
        args['creators']
      )}, offerers: ${JSON.stringify(args['offerers'])}, attributes: ${JSON.stringify(
        args['attributes']
      )}, listed: ${JSON.stringify(args['listed'])}, auctionHouses: ${JSON.stringify(
        args['auctionHouses']
      )}, collection: ${JSON.stringify(args['collection'])}, limit: ${JSON.stringify(
        args['limit']
      )}, offset: ${JSON.stringify(args['offset'])})`,
      NftModelSelector,
      builder
    )
  }
  wallet(
    builder:
      | string
      | WalletModelSelector
      | ((selector: WalletModelSelector) => WalletModelSelector)
      | undefined,
    args: { address: any }
  ) {
    return this.__child(
      `wallet(address: ${JSON.stringify(args['address'])})`,
      WalletModelSelector,
      builder
    )
  }
  listings(
    builder:
      | string
      | ListingModelSelector
      | ((selector: ListingModelSelector) => ListingModelSelector)
      | undefined
  ) {
    return this.__child(`listings`, ListingModelSelector, builder)
  }
  nft(
    builder:
      | string
      | NftModelSelector
      | ((selector: NftModelSelector) => NftModelSelector)
      | undefined,
    args: { address: string }
  ) {
    return this.__child(
      `nft(address: ${JSON.stringify(args['address'])})`,
      NftModelSelector,
      builder
    )
  }
  storefronts(
    builder:
      | string
      | StorefrontModelSelector
      | ((selector: StorefrontModelSelector) => StorefrontModelSelector)
      | undefined
  ) {
    return this.__child(`storefronts`, StorefrontModelSelector, builder)
  }
  storefront(
    builder:
      | string
      | StorefrontModelSelector
      | ((selector: StorefrontModelSelector) => StorefrontModelSelector)
      | undefined,
    args: { subdomain: string }
  ) {
    return this.__child(
      `storefront(subdomain: ${JSON.stringify(args['subdomain'])})`,
      StorefrontModelSelector,
      builder
    )
  }
  marketplace(
    builder:
      | string
      | MarketplaceModelSelector
      | ((selector: MarketplaceModelSelector) => MarketplaceModelSelector)
      | undefined,
    args: { subdomain: string }
  ) {
    return this.__child(
      `marketplace(subdomain: ${JSON.stringify(args['subdomain'])})`,
      MarketplaceModelSelector,
      builder
    )
  }
  metadataJsons(
    builder:
      | string
      | MetadataJsonModelSelector
      | ((selector: MetadataJsonModelSelector) => MetadataJsonModelSelector)
      | undefined,
    args: { term: string; limit: number; offset: number }
  ) {
    return this.__child(
      `metadataJsons(term: ${JSON.stringify(args['term'])}, limit: ${JSON.stringify(
        args['limit']
      )}, offset: ${JSON.stringify(args['offset'])})`,
      MetadataJsonModelSelector,
      builder
    )
  }
  profiles(
    builder:
      | string
      | WalletModelSelector
      | ((selector: WalletModelSelector) => WalletModelSelector)
      | undefined,
    args: { term: string; limit: number; offset: number }
  ) {
    return this.__child(
      `profiles(term: ${JSON.stringify(args['term'])}, limit: ${JSON.stringify(
        args['limit']
      )}, offset: ${JSON.stringify(args['offset'])})`,
      WalletModelSelector,
      builder
    )
  }
  denylist(
    builder:
      | string
      | DenylistModelSelector
      | ((selector: DenylistModelSelector) => DenylistModelSelector)
      | undefined
  ) {
    return this.__child(`denylist`, DenylistModelSelector, builder)
  }
}
export function selectFromQueryRoot() {
  return new QueryRootModelSelector()
}

export const queryRootModelPrimitives = selectFromQueryRoot()

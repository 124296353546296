import React, { useMemo } from 'react'
import { SafeAreaView, StatusBar, StatusBarStyle, View, ViewStyle } from 'react-native'
// import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { theme } from '@vatom/wombo'

import { useIsDesktop } from '../../hooks/useIsDesktop'

import { getStatusBarStyle } from './utils/textColor.util'

type ScreenWrapperProps = React.PropsWithChildren & {
  statusBarStyle?: StatusBarStyle
  statusBarBackgroundColor?: string
  screenBackgroundColor?: string
  screenContainerStyle?: ViewStyle
  viewContainerStyle?: ViewStyle
  viewContainerProps?: React.ComponentProps<typeof View>
  unsafe?: boolean
}

function ScreenWrapper({
  children,
  statusBarStyle,
  statusBarBackgroundColor,
  screenBackgroundColor,
  screenContainerStyle = {},
  viewContainerStyle = {},
  viewContainerProps = {},
  unsafe
}: ScreenWrapperProps) {
  // const insets = useSafeAreaInsets()
  const isDesktop = useIsDesktop()

  const defaultBackgroundColor = isDesktop ? theme.colors.grayCool[50] : theme.colors.white

  const currentBackgroundColor = screenBackgroundColor ?? defaultBackgroundColor
  const statusBarBg = statusBarBackgroundColor ?? currentBackgroundColor
  const defaultStatusBarStyle = getStatusBarStyle(statusBarBg)

  const statusBarContentStyle = useMemo(() => {
    if (statusBarStyle) {
      return statusBarStyle
    } else if (statusBarBg) {
      return getStatusBarStyle(statusBarBg)
    } else {
      return defaultStatusBarStyle
    }
  }, [statusBarStyle, statusBarBg, defaultStatusBarStyle])

  return (
    <View
      style={{ flex: 1, flexShrink: 0, height: '100%', ...viewContainerStyle }}
      {...viewContainerProps}
    >
      {unsafe ? (
        <>
          <StatusBar barStyle={statusBarContentStyle} backgroundColor={statusBarBg} />
          {children}
        </>
      ) : (
        <>
          <StatusBar barStyle={statusBarContentStyle} backgroundColor={statusBarBg} />

          <SafeAreaView
            style={{
              flex: 0,
              zIndex: 99,
              backgroundColor: statusBarBg
            }}
          />
          <SafeAreaView
            style={{
              flex: 1,
              backgroundColor: currentBackgroundColor,
              ...screenContainerStyle
            }}
          >
            {children}
          </SafeAreaView>
        </>
      )}
    </View>
  )
}

export { ScreenWrapper }
export type { ScreenWrapperProps }

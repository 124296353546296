import React from 'react'
import { Center } from 'native-base'

import { ProfileAvatar } from './ProfileAvatar'
import { ProfileUserBasicInfo } from './ProfileUserBasicInfo'

type ProfileUserCardProps = {
  avatarUrl?: string
  name?: string
  username?: string
  onPressQrCode?: () => void
}

function ProfileUserCard(props: ProfileUserCardProps) {
  return (
    <Center>
      <ProfileAvatar url={props.avatarUrl} />
      {props.name && props.onPressQrCode && (
        <ProfileUserBasicInfo
          name={props.name}
          username={props.username}
          onPressQrCode={props.onPressQrCode}
        />
      )}
    </Center>
  )
}

export { ProfileUserCard }

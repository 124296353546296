import React, { ErrorInfo } from 'react'
import { Button, Text } from '@vatom/wombo'
import { Box, Image, ScrollView } from 'native-base'

export interface ErrorComponentProps {
  error: Error
  errorInfo: ErrorInfo | null
  onReset(): void
}

export const ErrorView = ({ onReset, error }: ErrorComponentProps) => (
  <Box alignItems="center" flex={1} p={8} py={12} backgroundColor="white">
    <Image
      alt="Bug"
      accessibilityIgnoresInvertColors={true}
      h={16}
      w={16}
      mt={8}
      resizeMode="contain"
      source={require('./assets/ladybug.png')}
    />
    <Text color="red.500" fontWeight="bold" py={15} tx="errorScreen.title" />
    <Text color="black" fontWeight="normal" py={3} tx="errorScreen.friendlySubtitle" />
    <Box
      w="100%"
      maxHeight="60%"
      backgroundColor="gray.100"
      my={4}
      px={3}
      paddingBottom={15}
      borderRadius={6}
    >
      <ScrollView>
        <Text selectable color="red.500" fontSize={14} fontWeight="bold" py={4}>
          {error.message}
        </Text>
      </ScrollView>
    </Box>

    <Button colorScheme="brand" onPress={onReset} variant="solid" px={10}>
      <Text color="white" fontSize={10} tx="errorScreen.reset" />
    </Button>
  </Box>
)

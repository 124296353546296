import { MutableRefObject, useCallback, useRef } from 'react'
import { RegionType } from '@vatom/sdk/core'
import { GeoDiscoveryGroup, useSDK } from '@vatom/sdk/react'
import { LocationObject } from 'expo-location'
import { useDebouncedCallback, useThrottledCallback } from 'use-debounce'

import { IMapActions, UseMapResponse } from './types'

interface MapActions extends IMapActions {
  mapRef?: MutableRefObject<google.maps.Map>
}

export const useMapActions = ({
  location,
  selectedToken,
  focused,
  setMapBoundingBox,
  setPrecision,
  mapRef
}: MapActions): UseMapResponse => {
  const sdk = useSDK()

  const lastCenterChange = useRef({
    latitude: 0,
    longitude: 0
  })

  const onGroupMarkerPress = useCallback(
    (geoGroup: GeoDiscoveryGroup) => {
      mapRef?.current?.panTo({
        lat: geoGroup.lat,
        lng: geoGroup.lon
      })

      mapRef?.current?.setZoom(18)
    },
    [mapRef]
  )

  const handleCenterCallback = useCallback(
    ({
      isAnimated,
      location: locationOverride
    }: { isAnimated?: boolean; location?: LocationObject } = {}) => {
      const loc = locationOverride ?? location

      if (!loc?.coords) {
        console.error('Location not found so cannot center the map')
        return
      }

      const position = {
        lat: loc.coords.latitude,
        lng: loc.coords.longitude
      }
      if (isAnimated) {
        mapRef?.current?.panTo(position)
      } else {
        mapRef?.current.setCenter(position)
      }
    },
    [location, mapRef]
  )

  const handleCenter = useThrottledCallback(handleCenterCallback, 250)

  const getBoundingBox = useCallback(() => {
    if (!mapRef?.current) return

    const bounds = mapRef?.current.getBounds()
    if (!bounds) return

    const sw = bounds.getSouthWest()
    const ne = bounds.getNorthEast()
    return {
      bottom_left: {
        lat: sw.lat(),
        lon: sw.lng()
      },
      top_right: {
        lat: ne.lat(),
        lon: ne.lng()
      }
    }
  }, [mapRef])

  const handleCenterChanged = useCallback(() => {
    const boundingBox = getBoundingBox()
    if (boundingBox && !selectedToken && focused) {
      setMapBoundingBox && setMapBoundingBox(boundingBox)
      sdk.dataPool.region(RegionType.geopos, JSON.stringify(boundingBox))
      // sdk.dataPool.region(RegionIds.geomap, JSON.stringify(boundingBox))
      // -n seems to be the magic number to make the precision work with the zoomLevel
      const zoomLevel = mapRef?.current?.getZoom() ?? 0
      const p = zoomLevel - 9
      setPrecision && setPrecision(p > 0 ? p : 2)
    }
  }, [
    focused,
    getBoundingBox,
    mapRef,
    sdk.dataPool,
    selectedToken,
    setMapBoundingBox,
    setPrecision
  ])

  const onCenterChanged = useDebouncedCallback(async () => {
    if (!mapRef?.current) return
    const newCenter = mapRef?.current?.getCenter()
    if (newCenter) {
      const currentNewCenter = {
        latitude: newCenter.lat(),
        longitude: newCenter.lng()
      }
      if (
        lastCenterChange.current.latitude.toString() !== currentNewCenter.latitude.toString() ||
        lastCenterChange.current.longitude.toString() !== currentNewCenter.longitude.toString()
      ) {
        handleCenterChanged()
        lastCenterChange.current = currentNewCenter
      }
    }
  }, 500)

  return {
    onGroupMarkerPress,
    handleCenter,
    onCenterChanged
  }
}

import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const SendIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 16 16" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M14.6445 1.77734C14.8984 1.95508 15.0254 2.25977 14.9746 2.56445L13.3496 13.127C13.3242 13.3809 13.1719 13.584 12.9434 13.7109C12.7402 13.8379 12.4609 13.8633 12.2324 13.7617L9.21094 12.4922L7.45898 14.3711C7.23047 14.625 6.875 14.7012 6.57031 14.5742C6.26562 14.4727 6.0625 14.168 6.0625 13.8125V11.7051C6.0625 11.6035 6.08789 11.502 6.16406 11.4258L10.4043 6.7793C10.5566 6.62695 10.5566 6.37305 10.4043 6.2207C10.252 6.06836 9.99805 6.06836 9.8457 6.2207L4.69141 10.791L2.43164 9.67383C2.17773 9.54688 2 9.26758 2 8.96289C1.97461 8.68359 2.12695 8.4043 2.40625 8.25195L13.7812 1.75195C14.0352 1.59961 14.3906 1.59961 14.6445 1.77734Z"
    />
  </Icon>
)

import { Platform } from 'react-native'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { getRoot, getSnapshot, Instance, SnapshotOut, types } from 'mobx-state-tree'

import { RootSDKStoreType, Sentry } from '../..'
import logger from '../../logger'
import { withEventEmitter } from '../EventEmitter/withEventEmitter'

export enum SessionEvents {
  changed = 'sessioninfo.changed'
}

export enum SessionSource {
  Metamask = 'metamask',
  Vatom = 'vatom',
  Phantom = 'phantom',
  VatomNetwork = 'vatom_network'
  // BlockV = 'blockv'
}

export enum SessionType {
  Eth = 'eth',
  Sol = 'sol',
  UserId = 'userId',
  JWT = 'jwt'
}

export type SessionTokenType = Instance<typeof SessionToken>
export type SessionTokenSnapshot = SnapshotOut<typeof SessionToken>

export const isSessionObject = (
  session: SessionInstanceType['value']
): session is SessionTokenType => typeof session !== 'string'

export const SessionToken = types
  .model('SessionToken')
  .props({
    idToken: types.maybe(types.string),
    accessToken: types.string,
    refreshToken: types.maybe(types.string),
    expiresAt: types.Date
  })
  .actions(self => ({
    setAccessToken(accessToken: string) {
      self.accessToken = accessToken
    }
  }))

export const Session = types.model('Session').props({
  source: types.optional(types.enumeration(Object.values(SessionSource)), SessionSource.Vatom),
  type: types.optional(types.enumeration(Object.values(SessionType)), SessionType.JWT),
  value: types.union(types.string, SessionToken)
})

export type SessionInstanceType = Instance<typeof Session>
export type SessionSnapshot = SnapshotOut<typeof Session>

export const SessionStore = types
  .model('SessionStore')
  .props({
    sessions: types.array(Session)
  })
  .extend(withEventEmitter)
  .actions(self => ({
    /**
     * Update session-specific information used by plugins.
     */
    // setAuthorizedState(authorizedState: AuthorizedStateStoreSnapshot) {
    //   logger.info('setAuthorizedState', authorizedState)
    //   self.authorizedState = authorizedState
    //   return true
    // },
    clear() {
      self.sessions.clear()
    },
    add(sessionSnapshot: SessionSnapshot) {
      logger.info('[SessionStore] add', sessionSnapshot)
      if (Session.is(sessionSnapshot)) {
        const session = Session.create(sessionSnapshot)
        // Remove existing by source and type
        const existing = self.sessions.filter(
          $ => $.source === session.source && $.type === session.type
        )

        if (existing.length > 1)
          throw new Error("Cannot use 'add' for a session with multiple tokens. Must use addAll")

        // If token does not exist, simply add it
        if (existing.length === 0) {
          self.sessions.push(session)
          return
        }

        // If the token does exist, see if it has changed
        let exists = false
        for (const existingSession of existing) {
          if (JSON.stringify(existingSession.value) !== JSON.stringify(session.value)) {
            self.sessions.remove(existingSession)
          } else {
            exists = true
          }
        }
        if (!exists) self.sessions.push(session)
      } else {
        logger.warn('[SessionStore] add - invalid SessionSnapshot')
      }
    },
    update(source: SessionSource, type: SessionType, value: SessionTokenSnapshot | string) {
      logger.info('[SessionStore] update', source, type, value)

      // Remove existing by source and type
      const existing = self.sessions.filter($ => $.source === source && $.type === type)

      if (existing.length > 1)
        throw new Error("Cannot use 'update' for a session with multiple tokens. Must use addAll")

      // If token does not exist, simply add it
      if (existing.length === 0) {
        self.sessions.push(
          Session.create({
            source,
            type,
            value
          })
        )
        return
      }

      const existingIndex = self.sessions.findIndex($ => $.source === source && $.type === type)

      // Update the token
      self.sessions[existingIndex].value = value
    },
    addAll(source: SessionSource, type: SessionType, values: (SessionTokenSnapshot | string)[]) {
      logger.info('[SessionStore] addAll', source, type, values)
      // Check to see if any existing sessions can be removed
      for (let i = 0; i < self.sessions.length; i++) {
        const session = self.sessions[i]
        if (session.type === type && session.source === source) {
          const has = values.find(v => JSON.stringify(v) === JSON.stringify(session.value))
          if (!has) {
            logger.info('[SessionStore] removing', source, type, session.value)
            self.sessions.splice(i, 1)
          }
        }
      }

      // Check to see if any should be added
      for (const value of values) {
        const has = self.sessions.find(
          s =>
            s.type === type &&
            s.source === source &&
            JSON.stringify(s.value) === JSON.stringify(value)
        )
        if (!has) {
          logger.info('[SessionStore] adding', source, type, value)
          self.sessions.push(
            Session.create({
              source,
              type,
              value
            })
          )
        }
      }
    },
    delete(source: SessionSource) {
      const sessionsToRemove = self.sessions.filter(s => s.source === source)
      for (const session of sessionsToRemove) {
        self.sessions.remove(session)
      }
    }
  }))
  .views(self => ({
    getFirstBySource(source: SessionSource) {
      return self.sessions.find($ => $.source === source)
    },
    getByType(type: SessionType) {
      return self.sessions.filter($ => $.type === type)
    },
    getFirstByTypeAndSource(type: SessionType, source: SessionSource) {
      return self.sessions.find($ => $.type === type && $.source === source)
    },
    get vatomExpiresAt() {
      const session = this.getFirstByTypeAndSource(SessionType.JWT, SessionSource.Vatom)
      if (!session) return false

      /* @ts-ignore */
      const expiresAt = session.value.expiresAt
      return expiresAt.getTime()
    },
    get isVatomExpired() {
      const session = this.getFirstByTypeAndSource(SessionType.JWT, SessionSource.Vatom)
      if (!session) return false

      const expiresAt = this.vatomExpiresAt
      if (!expiresAt) {
        return false
      }

      const isExpired =
        typeof expiresAt === 'number'
          ? expiresAt < new Date().getTime()
          : expiresAt.getTime() < new Date().getTime()
      return isExpired
    },
    get vatomIncSessionToken() {
      const session = self.sessions.find(
        $ => $.source === SessionSource.Vatom && $.type === SessionType.JWT
      )
      logger.info('[vatomIncSessionToken]', session)
      // if (session) return getSnapshot(session).value as SessionTokenSnapshot
      if (session) {
        return session.value as SessionTokenSnapshot
      }
      return null
    },
    get vatomNetworkSessionToken() {
      const session = self.sessions.find(
        $ => $.source === SessionSource.VatomNetwork && $.type === SessionType.JWT
      )
      logger.info('[vatomNetworkSessionToken]', session)
      // if (session) return getSnapshot(session).value as SessionTokenSnapshot
      if (session) {
        return session.value as SessionTokenSnapshot
      }
      return null
    },
    get isLoggedInWithVatom() {
      return this.vatomIncSessionToken !== null
    },
    get isLoggedInWithWallet() {
      return this.vatomIncSessionToken === null
    },
    get isLoggedIn() {
      const sessionToken = this.vatomIncSessionToken
      return !!sessionToken?.accessToken && !this.isVatomExpired
    }
  }))
  .actions(self => ({
    async embeddedRefreshToken() {
      const existingSession = self.getFirstByTypeAndSource(SessionType.JWT, SessionSource.Vatom)
      const jwtSessionValue = existingSession?.value as SessionTokenSnapshot
      try {
        const value =
          typeof existingSession?.value === 'string'
            ? existingSession?.value
            : existingSession?.value.accessToken

        const refreshValue =
          typeof existingSession?.value === 'string'
            ? undefined
            : existingSession?.value.refreshToken

        const response = await getVatomAccessToken(value, refreshValue)
        if (!response) {
          console.info('embeddedRefreshToken: no refresh token response')
          return jwtSessionValue
        }

        //  sdk.service.setToken(accessToken)
        console.info('embeddedRefreshToken: setToken', response)
        const date = new Date()
        self.update(SessionSource.Vatom, SessionType.JWT, {
          idToken: response?.idToken,
          accessToken: response?.accessToken,
          refreshToken: response?.refreshToken,
          expiresAt: response?.expiresAt
        })

        const session = self.getFirstByTypeAndSource(SessionType.JWT, SessionSource.Vatom)
        return session?.value as SessionTokenSnapshot
      } catch (error) {
        console.error('embeddedRefreshToken', error)
        throw error
      }
    }
  }))
  .actions(self => ({
    async refreshVatomToken({ baseURL, clientId }: { baseURL: string; clientId: string }) {
      console.info('Client id', clientId)
      if (isEmbedded()) {
        const newSession = await self.embeddedRefreshToken().catch(error => {
          logger.error('Embedded wallet refresh token error', error)
          Sentry.captureError(new Error(`Embedded wallet refresh token error: ${error}`))
        })
        if (newSession?.accessToken) {
          getRoot<RootSDKStoreType>(self).service.setToken(newSession.accessToken)
        }
        return newSession
      }

      const session = self.getFirstByTypeAndSource(SessionType.JWT, SessionSource.Vatom)
      const jwtSessionValue = session?.value as SessionTokenSnapshot
      if (!jwtSessionValue?.refreshToken) {
        return jwtSessionValue
      }

      try {
        logger.info('refreshVatomToken doRefreshToken', jwtSessionValue)

        const { data } = await axios.request({
          method: 'post',
          url: '/token',
          baseURL,
          data: new URLSearchParams({
            grant_type: 'refresh_token',
            client_id: clientId,
            refresh_token: jwtSessionValue.refreshToken
          })
        })
        const { access_token, id_token, refresh_token, expires_in } = data
        logger.info('refreshVatomToken data', data)

        const date = new Date()
        const updatedSession = {
          idToken: id_token,
          accessToken: access_token,
          refreshToken: refresh_token,
          expiresAt: new Date(new Date(date).setSeconds(date.getSeconds() + expires_in)).getTime()
        }
        self.update(SessionSource.Vatom, SessionType.JWT, updatedSession)

        return updatedSession
      } catch (error) {
        console.error('[RefreshTokenProvider.useEffect] Do Refresh Token', error)
        Sentry.captureError(
          new Error(`[RefreshTokenProvider.useEffect] Do Refresh Token: ${error}`)
        )
        throw error
      }
    }
  }))

async function getVatomAccessToken(token?: string, refreshToken?: string) {
  try {
    // const vatomAccessToken = token ?? sessionStorage?.getItem('VATOM_ACCESS_TOKEN') ?? ''
    const vatomAccessToken = token ?? useVatomWalletSdkStore.getState().accessToken ?? ''
    // const vatomRefreshToken = refreshToken ?? sessionStorage?.getItem('VATOM_REFRESH_TOKEN') ?? ''
    const vatomRefreshToken = refreshToken ?? useVatomWalletSdkStore.getState().refreshToken ?? ''

    console.info('getVatomAccessToken', {
      vatomAccessToken,
      vatomRefreshToken
    })
    if (!vatomAccessToken) {
      return null
    }
    type DecodedToken = {
      aud: string
      exp: number
      iat: number
      scope: string
    }

    const decodedToken = jwtDecode<DecodedToken>(vatomAccessToken)

    if (decodedToken.aud === 'wallet-rn') {
      const { data } = await axios.request({
        method: 'post',
        url: '/token',
        baseURL: 'https://id.vatom.com/',
        data: new URLSearchParams({
          grant_type: 'refresh_token',
          client_id: 'wallet-rn',
          refresh_token: vatomRefreshToken ?? ''
        })
      })
      const { access_token, id_token, refresh_token, expires_in } = data
      logger.info('refreshVatomToken data', data)

      const date = new Date()
      const updatedSession = {
        idToken: id_token,
        accessToken: access_token,
        refreshToken: refresh_token,
        expiresAt: new Date(new Date(date).setSeconds(date.getSeconds() + expires_in)).getTime()
      }
      return updatedSession
    }
    const urlencoded = new URLSearchParams({
      grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
      client_id: decodedToken.aud,
      resource: 'wallet-rn',
      subject_token_type: 'urn:ietf:params:oauth:token-type:access_token',
      subject_token: vatomAccessToken
    })

    const res = await fetch('https://id.vatom.com/token', {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded;charset=UTF-8' },
      body: urlencoded
    })

    const data = await res.json()
    const exp = data.exp
    console.info('refreshed embedded', {
      decodedToken,
      data
    })

    return {
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
      expiresAt: exp,

      issuedAt: decodedToken.iat,
      tokenType: 'Bearer',
      scope: decodedToken.scope,
      idToken: data.id_token
    }
  } catch (error) {
    console.error('getVatomAccessToken', error)
    throw error
  }
}

function isEmbedded() {
  const isWeb = Platform.OS === 'web'
  if (!isWeb) return false

  let isEmbedded =
    (window.parent && window.parent !== window) ||
    // sessionStorage?.getItem('isEmbedded') === 'true' ||
    useVatomWalletSdkStore.getState().isEmbedded ||
    // sessionStorage?.getItem('embeddedType') !== null ||
    !!useVatomWalletSdkStore.getState().embeddedType ||
    false

  isEmbedded = JSON.parse(isEmbedded.toString().toLowerCase())

  return isEmbedded
}

export type SessionStoreType = Instance<typeof SessionStore>
export type SessionStoreSnapshot = SnapshotOut<typeof SessionStore>

import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const ArrowRightIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 7 13" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M6.69922 6.63672L1.63672 12.2617C1.42578 12.5078 1.07422 12.5078 0.863281 12.2969C0.617188 12.0859 0.617188 11.7344 0.828125 11.5234L5.53906 6.25L0.828125 1.01172C0.617188 0.800781 0.617188 0.449219 0.863281 0.238281C1.07422 0.0273438 1.42578 0.0273438 1.63672 0.273438L6.69922 5.86328C6.91016 6.10938 6.91016 6.42578 6.69922 6.63672Z"
    />
  </Icon>
)

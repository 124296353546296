import { AntDesign, Entypo, MaterialCommunityIcons } from '@expo/vector-icons'
import { useThrottle } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { InputField, theme } from '@vatom/wombo'
import { Box, Icon, KeyboardAvoidingView, Pressable } from 'native-base'

import { useBusinessTheme } from '../../../hooks/useBusinessTheme'

type SearchProps = {
  searchMode: boolean
  searchString: string
  setSearchString: (s: string) => void
  searchPlaceholder?: string
  onFocus?: () => void
  onBlur?: () => void
  setIScannerOpen: (b: boolean) => void
}
export const Search = ({
  searchMode,
  searchString,
  setSearchString,
  onFocus,
  onBlur,
  searchPlaceholder = translate('common.search'),
  setIScannerOpen
}: SearchProps) => {
  const clearInput = () => {
    setSearchString('')
  }
  const { isDark } = useBusinessTheme()
  const onSetIScannerOpen = useThrottle(setIScannerOpen, 1000)

  return (
    <Box alignItems="center" flexDirection="row" justifyContent="center">
      <KeyboardAvoidingView flex={1} behavior="position">
        <InputField
          isDark={isDark}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={searchPlaceholder}
          _light={{
            borderColor: theme.colors.grayCool[300],
            placeholderTextColor: theme.colors.textLightMode[100]
          }}
          _dark={{
            borderColor: theme.colors.grayDarkMode[500],
            placeholderTextColor: theme.colors.textDarkMode[600]
          }}
          value={searchString}
          onChangeText={setSearchString}
          leftElement={
            <Pressable alignItems="center" accessibilityRole="button">
              <Icon
                size={4}
                marginLeft="8px"
                _light={{
                  color: theme.colors.grayCool[500]
                }}
                _dark={{
                  color: theme.colors.textDarkMode[600]
                }}
                as={AntDesign}
                name="search1"
              />
            </Pressable>
          }
          rightElement={
            searchMode && searchString !== '' ? (
              <Pressable accessibilityRole="button" onPress={clearInput}>
                <Icon marginRight="8px" as={Entypo} name={'circle-with-cross'} />
              </Pressable>
            ) : (
              <Box />
            )
          }
        />
      </KeyboardAvoidingView>
      <Pressable accessibilityRole="button" ml={2} onPress={() => onSetIScannerOpen(true)}>
        <Icon size={7} color="black" as={MaterialCommunityIcons} name="qrcode-scan" />
      </Pressable>
    </Box>
  )
}

export default Search

import { create } from 'zustand'

interface WebViewRefStore {
  ref: HTMLIFrameElement | null
  setRef: (webView: HTMLIFrameElement | null) => void
}

export const useWebViewRefStore = create<WebViewRefStore>(set => ({
  ref: null,
  setRef: webView => {
    set({
      ref: webView
    })
  }
}))

export const navigateToHome = () => {
  if (!useWebViewRefStore.getState().ref?.contentWindow) {
    return
  }

  const date = Date.now()
  const msg = {
    data: JSON.stringify({
      type: 'NAVIGATE_TO_HOME'
    }),
    triggeredAt: date
  }

  useWebViewRefStore.getState().ref?.contentWindow?.postMessage(msg, '*')
}

import React from 'react'

import { WebWrapper } from '../../components/WebWrapper'

import { MintNFT, MintNFTProps } from './MintNFT'

export default (props: MintNFTProps) => {
  return (
    <WebWrapper justifyContent={'center'} flex={1} flexShrink={0}>
      <MintNFT {...props} />
    </WebWrapper>
  )
}

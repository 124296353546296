import { ComponentProps } from 'react'
import { Platform, TextStyle } from 'react-native'
import { Color, Nudge, RefType, Style, TextContent } from '@vatom/BVatom/plugin'
import { Button, PressableOpacity } from '@vatom/wombo'
import { Box, Image, Modal, Text } from 'native-base'

import CloseIcon from '../../../assets/close.min.svg'
import {
  borderStyle,
  dimensionStyle,
  fontFamilyStyle,
  fontSizeStyle,
  nudgeStyle,
  rgbaStyle
} from '../hooks/useStyleConverters'

interface ICardStateProps {
  content: TextContent
  contentNudge: Nudge
  contentStyle: Style
  onClosePress: () => void
  buttonText: string
  buttonNudge: Nudge
  buttonStyle: Style

  icon: RefType

  bgColor: Color
  closeColor: Color

  onButtonPress: () => void
}

// We take out the children and the bg color since we need are passing the children ourselves and we need to convert the bg color to rgba
type OmmitedModalProps = 'children' | 'bgColor'

type ICardStateModalProps = Omit<ComponentProps<typeof Modal>, OmmitedModalProps> & ICardStateProps

export const CardState = ({
  content,
  contentNudge,
  contentStyle,
  buttonText,
  buttonNudge,
  buttonStyle,
  icon,
  bgColor,
  closeColor,
  onButtonPress,
  onClosePress,
  ...rest
}: ICardStateModalProps) => {
  return (
    <Modal
      useRNModal={true}
      // bgColor={rgbaStyle(bgColor)}
      {...rest}
    >
      <Modal.Content alignItems={'center'} w={'80vw'} maxH={'550px'} paddingY={'40px'} h={'60vh'}>
        <PressableOpacity position={'absolute'} top={'17px'} right={'18px'} onPress={onClosePress}>
          <CloseIcon height={16} width={16} fill={rgbaStyle(closeColor)} />
        </PressableOpacity>

        <Box
          /* @ts-ignore static is needed for web */
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'static'
          }}
        >
          {Platform.OS === 'web' ? (
            <img
              alt="card-state-icon"
              src={icon.ref}
              style={{
                width: '50%',
                objectFit: 'contain'
              }}
            />
          ) : (
            <Image
              w={'50%'}
              h={20}
              accessibilityIgnoresInvertColors
              source={{ uri: icon.ref }}
              resizeMode="contain"
            />
          )}

          <Box
            // @ts-expect-error style clash
            style={{
              width: dimensionStyle(contentStyle.width),
              ...nudgeStyle(contentNudge)
            }}
          >
            <Text
              // @ts-expect-error style clash
              style={{
                textAlign: 'center',
                fontFamily: fontFamilyStyle(contentStyle.font.family),
                color: rgbaStyle(contentStyle.color),
                fontSize: fontSizeStyle(contentStyle.font.size) as TextStyle['fontSize'],
                fontWeight: contentStyle.font.weight as TextStyle['fontWeight'],
                padding: dimensionStyle(contentStyle.padding),
                borderRadius: contentStyle.borderRadius.value,
                backgroundColor: rgbaStyle(contentStyle.backgroundColor),
                alignSelf: 'center',
                ...borderStyle(contentStyle.borderPosition, contentStyle.color)
              }}
            >
              {content.text}
            </Text>
          </Box>

          <Box
            // @ts-expect-error style clash
            style={{
              height: dimensionStyle(buttonStyle.height),
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...nudgeStyle(buttonNudge, true)
            }}
          >
            <Button
              onPress={onButtonPress}
              // @ts-expect-error style clash
              style={{
                height: '100%',
                width: dimensionStyle(buttonStyle.width),
                padding: dimensionStyle(buttonStyle.padding),
                borderRadius: buttonStyle.borderRadius.value,
                backgroundColor: rgbaStyle(buttonStyle.backgroundColor),
                alignSelf: 'center',
                ...borderStyle(buttonStyle.borderPosition, buttonStyle.color),
                ...nudgeStyle(buttonNudge),
                position: 'relative'
              }}
            >
              <Text
                style={{
                  fontFamily: fontFamilyStyle(buttonStyle.font.family),
                  color: rgbaStyle(buttonStyle.color),
                  fontSize: fontSizeStyle(buttonStyle.font.size) as TextStyle['fontSize'],
                  fontWeight: buttonStyle.font.weight as TextStyle['fontWeight']
                }}
              >
                {buttonText}
              </Text>
            </Button>
          </Box>
        </Box>
      </Modal.Content>
    </Modal>
  )
}

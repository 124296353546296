import { Animated } from 'react-native'
import { IEvent } from 'matrix-js-sdk'

import { PluginInput } from './ModalInputsHolder'

export const openAnimation = (
  fadeAnim: Animated.Value,
  slideAnim: Animated.Value,
  duration: number
) => {
  return Animated.parallel([
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: duration,
      useNativeDriver: true
    }),
    Animated.timing(slideAnim, {
      toValue: 0,
      duration: duration,
      useNativeDriver: true
    })
  ])
}

export const closeAnimation = (
  fadeAnim: Animated.Value,
  slideAnim: Animated.Value,
  duration: number
) => {
  return Animated.parallel([
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: duration,
      useNativeDriver: true
    }),
    Animated.timing(slideAnim, {
      toValue: 1000,
      duration: duration,
      useNativeDriver: true
    })
  ])
}

export const getInputs = (
  messageType: string,
  trueInputs: PluginInput[],
  matrixMessage?: IEvent
) => {
  const messageInputs =
    matrixMessage && messageType !== 'm.room.message' ? JSON.parse(matrixMessage.content.body) : []

  return trueInputs?.map(input => {
    let value = ''
    if (matrixMessage) {
      if (messageType === 'm.room.message') {
        if (input.type === 'text') {
          value = matrixMessage.content.formatted_body
        }
      } else {
        const foundInput = messageInputs.elements.find(
          ({ type }: { type: string }) => type === input.type
        )
        value = foundInput.content
      }
    }

    return { ...input, value: value }
  })
}

import { FlexAlignType, Platform, ViewStyle } from 'react-native'
import { Color, Dimension, Nudge } from '@vatom/BVatom/plugin'

const isColor = (color: unknown): color is Color => {
  const isValid =
    color &&
    typeof color === 'object' &&
    'r' in color &&
    'g' in color &&
    'b' in color &&
    'a' in color

  if (!isValid) {
    console.warn('Invalid color: ', color, 'expected Color type')
  }
  return !!isValid
}

export const rgbaStyle = (rgba: Color) =>
  isColor(rgba) ? `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})` : undefined

export const dimensionStyle = (dimenstion: Dimension) => {
  return `${dimenstion.value}${dimenstion.unit}`
}

export const borderStyle = (position: string, borderColor: Color): ViewStyle => {
  switch (position) {
    case 'all':
      return {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: rgbaStyle(borderColor)
      }
    case 'bottom':
      return {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: rgbaStyle(borderColor),
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0
      }
    default:
      return {}
  }
}

export const nudgeStyle = (nudge: Nudge, onlyY = false): ViewStyle => {
  const nudgeStyle = {
    bottom: undefined,
    left: undefined,
    position: 'absolute' as ViewStyle['position'],
    justifyContent: 'center' as ViewStyle['justifyContent'],
    alignItems: 'center' as FlexAlignType
  }

  if (nudge.y !== undefined) {
    /* @ts-ignore nothing we can do about this type for now */
    nudgeStyle.bottom = `${nudge.y}${nudge.unit}`
  }

  if (nudge.x !== undefined && !onlyY) {
    /* @ts-ignore nothing we can do about this type for now */
    nudgeStyle.left = `${nudge.x}${nudge.unit}`
  }

  return nudgeStyle
}

export const fontSizeStyle = (size: number) => {
  if (Platform.OS === 'web') {
    return `calc(${size * 4}vw)`
  }

  return 12
}

export const fontFamilyStyle = (fontFamily: string[]) => {
  return fontFamily.join(',')
}

import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const SendImageIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 19 17" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M12.3906 4.80469L16.5391 11.0977C16.7852 11.4141 16.7852 11.8359 16.6094 12.1875C16.4336 12.5391 16.0469 12.75 15.6602 12.75H4.30469C3.91797 12.75 3.53125 12.5391 3.35547 12.1875C3.17969 11.8008 3.21484 11.3789 3.42578 11.0625L5.71094 7.89844C6.09766 7.33594 7.04688 7.33594 7.46875 7.89844L8.03125 8.70703L10.5977 4.80469C11.0195 4.20703 12.0039 4.20703 12.3906 4.80469ZM4.33984 11.625H15.5898L11.4766 5.4375L8.52344 10.0078C8.41797 10.1484 8.24219 10.2539 8.06641 10.2539C7.89062 10.2891 7.71484 10.1836 7.60938 10.0078L6.55469 8.56641L4.33984 11.625ZM16.7148 0.375C17.9805 0.375 18.9648 1.39453 18.9648 2.625V13.875C18.9648 15.1406 17.9453 16.125 16.7148 16.125H3.21484C1.98438 16.125 0.964844 15.1406 0.964844 13.875V2.625C0.964844 1.39453 1.98438 0.375 3.21484 0.375H16.7148ZM17.875 13.875V2.625C17.875 2.02734 17.3477 1.5 16.75 1.5H3.25C2.61719 1.5 2.125 2.02734 2.125 2.625V13.875C2.125 14.5078 2.61719 15 3.25 15H16.75C17.3477 15 17.875 14.5078 17.875 13.875ZM6.0625 6C5.11328 6 4.375 5.26172 4.375 4.3125C4.375 3.39844 5.11328 2.625 6.0625 2.625C6.97656 2.625 7.75 3.39844 7.75 4.3125C7.75 5.26172 6.97656 6 6.0625 6ZM6.0625 3.75C5.74609 3.75 5.5 4.03125 5.5 4.3125C5.5 4.62891 5.74609 4.875 6.0625 4.875C6.34375 4.875 6.58984 4.62891 6.58984 4.3125C6.58984 4.03125 6.34375 3.75 6.0625 3.75Z"
    />
  </Icon>
)
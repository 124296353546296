import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const ImportIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 17 16" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M8.46875 11.7812L5.09375 8.40625C4.95312 8.26562 4.95312 8.00781 5.09375 7.86719C5.23438 7.72656 5.49219 7.72656 5.63281 7.86719L8.375 10.6094V3.625C8.375 3.4375 8.53906 3.25 8.75 3.25C8.9375 3.25 9.125 3.4375 9.125 3.625V10.6094L11.8438 7.86719C11.9844 7.72656 12.2422 7.72656 12.3828 7.86719C12.5234 8.00781 12.5234 8.26562 12.3828 8.40625L9.00781 11.7812C8.9375 11.8516 8.84375 11.875 8.75 11.875C8.63281 11.875 8.53906 11.8516 8.46875 11.7812ZM12.875 13C13.0625 13 13.25 13.1875 13.25 13.375C13.25 13.5859 13.0625 13.75 12.875 13.75H4.625C4.41406 13.75 4.25 13.5859 4.25 13.375C4.25 13.1875 4.41406 13 4.625 13H12.875Z"
    />
  </Icon>
)

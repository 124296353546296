import React from 'react'
import { translate } from '@vatom/utils'
import { observer } from 'mobx-react-lite'

import { WalletNftPreference } from '../../ActionSheets/Filters/WalletNftPreference'

import ActionItem from './ActionItem'

export const WalletPreference = observer(() => {
  const [isWalletNftFilterOpen, setWalletNftFilterOpen] = React.useState(false)

  return (
    <>
      <WalletNftPreference setOpen={setWalletNftFilterOpen} isOpen={isWalletNftFilterOpen} />
      <ActionItem
        onPress={() => setWalletNftFilterOpen(true)}
        title={translate('common.walletPreferences')}
      />
    </>
  )
})

export default WalletPreference

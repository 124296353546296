import { ResourceFaceType, Sentry } from '@vatom/sdk/core'
import { getNFTMetadata, web3ChainListToAlchemyNetwork } from '@vatom/sdk/react'
import { Network } from 'alchemy-sdk'
import mime from 'mime'
import { getSnapshot, Instance, SnapshotOut, types } from 'mobx-state-tree'
import { components } from 'moralis-v1/types/generated/web3Api'

import logger from '../logger'
import { ErcToken, ErcTokenSnapshot } from '../models/ErcToken'
import { TNftEthereumFace } from '../modules/CommonTypes'

import { ErcRegionStore } from './ErcRegion'

const className = 'ErcInventoryRegionStore'

export const ErcInventoryRegionStore = ErcRegionStore.named(className)
  .props({
    className,
    _tokens: types.optional(
      types.late(() => types.map(ErcToken)),
      {}
    ),
    address: types.optional(types.string, '')
  })
  .volatile(self => {
    const sockets = self.api.sockets
    return {
      sockets
    }
  })
  .volatile(self => ({
    get allImgSupported() {
      return [
        'image/gif',
        'image/jpeg',
        'image/png',
        'image/tiff',
        'image/vnd.microsoft.icon',
        'image/x-icon',
        'image/vnd.djvu',
        'image/svg+xml',
        'image/webp'
      ]
    },
    get allVideosSupported() {
      return [
        'video/mpeg',
        'video/mp4',
        'video/quicktime',
        'video/x-ms-wmv',
        'video/x-msvideo',
        'video/x-flv',
        'video/webm'
      ]
    },
    get allModelsSupported() {
      return [
        'model/gltf-binary',
        // TODO: make mores test
        //  "image/webp",
        'application/json',
        'application/octet-stream'
      ]
    },
    validURL(str: string) {
      let url
      try {
        url = new URL(str)
      } catch (_) {
        return false
      }
      return url.protocol === 'http:' || url.protocol === 'https:'
    },
    restructureUrl(url: string | undefined) {
      try {
        if (!url) return undefined
        let URL = url

        // Work around for IPFS; TODO: implement proxy through vatom, cloudfront or sth???
        URL = URL.replace('ipfs://', 'https://vatom.mypinata.cloud/ipfs/')
        URL = URL.replace('https://ipfs.io/ipfs/', 'https://vatom.mypinata.cloud/ipfs/')
        // workaround for getting too many requests on pinata; TODO: implement proxy through vatom, cloudfront or sth???
        URL = URL.replace(
          'https://gateway.pinata.cloud/ipfs/',
          'https://vatom.mypinata.cloud/ipfs/'
        )
        return URL
      } catch (error) {
        logger.error('nft', error, 'this is the ', url)
        return undefined
      }
    },
    async getMime(url: string | undefined) {
      if (!url) return undefined
      let MIME = mime.getType(url)
      try {
        if (!MIME) {
          const nft_heads = await fetch(url, {
            method: 'HEAD'
          })
          MIME = nft_heads.headers.get('content-type')
        }
        return MIME
      } catch (error) {
        logger.error('nft', error, 'this is the ', url)
        return undefined
      }
    },
    isSupported(type: string | undefined) {
      return type
        ? this.allImgSupported.includes(type) ||
            this.allModelsSupported.includes(type) ||
            this.allVideosSupported.includes(type)
        : false
    }
  }))
  .actions(self => ({
    matches(id: string, descriptor?: string) {
      return id === 'inventory' && descriptor === self.address
    },
    async fetchPage(args?: any) {
      return
    },
    async load() {
      self.setIsLoading(true)
      logger.info('[ErcInventoryRegion.load]')
      self.api.setAddress(self.address)
      const nfts = await this.getInventory()
      const response: ErcTokenSnapshot[] = []
      nfts.forEach(nft => {
        const { contract_type } = nft || {}
        switch (contract_type) {
          case 'ERC721':
          case 'ERC1155':
            response.push(this.mapObject(nft.token_id, nft))
            break

          default:
            break
        }
      })
      self.addTokens(response)
      self.setIsLoading(false)
    },
    async reload() {
      self.clear()
      return self.load()
    },
    mapObject(id: string, payload: TNftEthereumFace) {
      const metadata = JSON.parse(payload.metadata || '{"name":"","description":"","image":""}')
      const ercToken = ErcToken.create({
        id: id,
        type: 'erc',
        name: metadata.name,
        description: metadata.description,
        image: self.restructureUrl(metadata.image),
        parentId: '.',
        owner: payload.owner_of,
        author: '',
        // created: (await self.api.getDateFromBlock(payload.block_number_minted)) || new Date(0)
        created: new Date(0),
        network: payload.chain,
        contractAddress: payload.token_address,
        tokenUri: payload.token_uri,
        faces: payload.faces || [],
        quantity: Number(payload.amount),
        contractType: payload.contract_type
      })

      return getSnapshot(ercToken)
    },

    async getInventory(
      lookupChains?: components['schemas']['chainList'][]
    ): Promise<TNftEthereumFace[]> {
      logger.info('[ErcPlugin.getInventory]')

      try {
        const response = await self.api.getNfts(lookupChains)
        logger.info('[ErcPlugin.getInventory] querying...', self.address)
        const nfts = await Promise.all(
          response
            .filter(token => {
              const tokenUri = token.token_uri
              if (!token.metadata && !tokenUri) {
                logger.warn('No metadata and token_uri empty')
                return true
              }
              try {
                new URL(tokenUri || '')
                return true
              } catch (error) {
                logger.warn('No metadata and token_uri invalid URL')
                return false
              }
            })
            .map(async token => {
              if (!token.metadata && token.token_uri) {
                try {
                  const res = await fetch(token.token_uri)
                  if (res.status !== 200) {
                    throw new Error('')
                  }
                  const data = await res.text()
                  token.metadata = data
                } catch (error) {
                  if (error instanceof Error) {
                    logger.warn('Error loading metadata for NFT', token)
                    Sentry.captureError(error)
                  }
                }
              }

              if (!token.metadata) {
                const s = await getNFTMetadata(
                  token.token_address,
                  token.token_id,
                  /* @ts-expect-error not listed everything */
                  web3ChainListToAlchemyNetwork[token.chain] as Network
                )

                if (s) {
                  token.name = s.name ?? ''
                  token.metadata = JSON.stringify({
                    ...s.raw.metadata,
                    image: self.restructureUrl(s.image.originalUrl)
                  })
                } else {
                  return false
                }
              }

              return token
            })
        )

        logger.info('[ErcPlugin.getInventory] mapping')

        const final_nfts: any = []
        await Promise.all(
          nfts.flat().map(async nft => {
            try {
              if (!nft) return
              const metadata = JSON.parse(nft?.metadata || '{}')
              const image = self.restructureUrl(metadata.image || metadata.image_url)
              const animation_url = self.restructureUrl(
                metadata.animation_url || metadata.animation
              )

              const icon = {
                type: 'icon',
                id: ResourceFaceType.image,
                image,
                image_type: await self.getMime(image || ''),
                animation_url,
                animation_url_type: await self.getMime(animation_url || '')
              }

              const engaged = {
                type: 'engaged',
                id: ResourceFaceType.image,
                image,
                animation_url
              }

              const mime_animation = await self.getMime(animation_url)

              if (mime_animation) {
                const isVideo = self.allVideosSupported.includes(mime_animation)
                const isModel = self.allModelsSupported.includes(mime_animation)
                if (isVideo) engaged.id = ResourceFaceType.video
                if (isModel) engaged.id = ResourceFaceType.generic3D
              }

              const nftWithFace = nft as TNftEthereumFace
              nftWithFace.faces = [icon, engaged]
              final_nfts.push(nft)
            } catch (error) {
              logger.error('Error processing metadata for NFT:', error, JSON.stringify(nft))
            }
          })
        )

        return final_nfts
      } catch (error) {
        logger.error('[ErcPlugin.getInventory]', error)
        throw error
      }
    }
  }))
  .actions(self => {
    const onWebSocketMessage = async (object: any) => {
      logger.info('WS NEW TRANSFER: ', object)
      if (object.attributes.confirmed) {
        const networks: components['schemas']['chainList'][] = [object.network]
        const objects = await self.getInventory(networks)
        const tokens = []
        for (const t of objects) {
          tokens.push(await self.mapObject(t.token_id, t))
        }
        self._tokens.replace(tokens)
      }
    }
    return { onWebSocketMessage }
  })
  .actions(self => ({
    afterCreate() {
      self.sockets.loadWS(self.address)
      self.sockets.on('message', self.onWebSocketMessage)
      self.load()
    },
    beforeDestroy() {
      self.sockets.off('message', self.onWebSocketMessage)
      self.sockets.close()
    }
  }))

  .views(self => ({
    /** Our state key is the current user's ID */
    get stateKey() {
      return `inventory:ErcNet:${self.address}`
    },
    get isLoading() {
      console.log('self._isLoading erc: ', self._isLoading)
      return self._isLoading
    }
  }))

export type ErcInventoryRegionStoreType = Instance<typeof ErcInventoryRegionStore>
export type ErcInventoryRegion = ErcInventoryRegionStoreType
export type ErcInventoryRegionSnapshot = SnapshotOut<typeof ErcInventoryRegionStore>

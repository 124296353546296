/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { QueryBuilder } from "mst-gql"
import { FollowEventModelType } from "./FollowEventModel"
import { FollowEventModelSelector, followEventModelPrimitives } from "./FollowEventModel.base"
import { ListingEventModelType } from "./ListingEventModel"
import { ListingEventModelSelector, listingEventModelPrimitives } from "./ListingEventModel.base"
import { MintEventModelType } from "./MintEventModel"
import { MintEventModelSelector, mintEventModelPrimitives } from "./MintEventModel.base"
import { OfferEventModelType } from "./OfferEventModel"
import { OfferEventModelSelector, offerEventModelPrimitives } from "./OfferEventModel.base"
import { PurchaseEventModelType } from "./PurchaseEventModel"
import { PurchaseEventModelSelector, purchaseEventModelPrimitives } from "./PurchaseEventModel.base"

export type FeedEventUnion = ListingEventModelType | FollowEventModelType | PurchaseEventModelType | MintEventModelType | OfferEventModelType

export class FeedEventModelSelector extends QueryBuilder {
  listingEvent(builder?: string | ListingEventModelSelector | ((selector: ListingEventModelSelector) => ListingEventModelSelector)) { return this.__inlineFragment(`ListingEvent`, ListingEventModelSelector, builder) }
  followEvent(builder?: string | FollowEventModelSelector | ((selector: FollowEventModelSelector) => FollowEventModelSelector)) { return this.__inlineFragment(`FollowEvent`, FollowEventModelSelector, builder) }
  purchaseEvent(builder?: string | PurchaseEventModelSelector | ((selector: PurchaseEventModelSelector) => PurchaseEventModelSelector)) { return this.__inlineFragment(`PurchaseEvent`, PurchaseEventModelSelector, builder) }
  mintEvent(builder?: string | MintEventModelSelector | ((selector: MintEventModelSelector) => MintEventModelSelector)) { return this.__inlineFragment(`MintEvent`, MintEventModelSelector, builder) }
  offerEvent(builder?: string | OfferEventModelSelector | ((selector: OfferEventModelSelector) => OfferEventModelSelector)) { return this.__inlineFragment(`OfferEvent`, OfferEventModelSelector, builder) }
}
export function selectFromFeedEvent() {
  return new FeedEventModelSelector()
}

// provides all primitive fields of union member types combined together
export const feedEventModelPrimitives = selectFromFeedEvent().listingEvent(listingEventModelPrimitives).followEvent(followEventModelPrimitives).purchaseEvent(purchaseEventModelPrimitives).mintEvent(mintEventModelPrimitives).offerEvent(offerEventModelPrimitives)
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AttributeGroupModel, AttributeGroupModelType } from "./AttributeGroupModel"
import { AttributeGroupModelSelector } from "./AttributeGroupModel.base"
import { CreatorCountsModel, CreatorCountsModelType } from "./CreatorCountsModel"
import { CreatorCountsModelSelector } from "./CreatorCountsModel.base"
import { MintStatsModel, MintStatsModelType } from "./MintStatsModel"
import { MintStatsModelSelector } from "./MintStatsModel.base"
import { TwitterProfileModel, TwitterProfileModelType } from "./TwitterProfileModel"
import { TwitterProfileModelSelector } from "./TwitterProfileModel.base"
import { RootStoreType } from "./index"


/**
 * CreatorBase
 * auto generated base class for the model CreatorModel.
 */
export const CreatorModelBase = ModelBase
  .named('Creator')
  .props({
    __typename: types.optional(types.literal("Creator"), "Creator"),
    address: types.union(types.undefined, types.string),
    counts: types.union(types.undefined, types.late((): any => CreatorCountsModel)),
    stats: types.union(types.undefined, types.array(types.late((): any => MintStatsModel))),
    attributeGroups: types.union(types.undefined, types.array(types.late((): any => AttributeGroupModel))),
    profile: types.union(types.undefined, types.null, types.late((): any => TwitterProfileModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CreatorModelSelector extends QueryBuilder {
  get address() { return this.__attr(`address`) }
  counts(builder: string | CreatorCountsModelSelector | ((selector: CreatorCountsModelSelector) => CreatorCountsModelSelector) | undefined) { return this.__child(`counts`, CreatorCountsModelSelector, builder) }
  stats(builder: string | MintStatsModelSelector | ((selector: MintStatsModelSelector) => MintStatsModelSelector) | undefined, args: { auctionHouses: any[] }) { return this.__child(`stats(auctionHouses: ${JSON.stringify(args['auctionHouses'])})`, MintStatsModelSelector, builder) }
  attributeGroups(builder: string | AttributeGroupModelSelector | ((selector: AttributeGroupModelSelector) => AttributeGroupModelSelector) | undefined) { return this.__child(`attributeGroups`, AttributeGroupModelSelector, builder) }
  profile(builder: string | TwitterProfileModelSelector | ((selector: TwitterProfileModelSelector) => TwitterProfileModelSelector) | undefined) { return this.__child(`profile`, TwitterProfileModelSelector, builder) }
}
export function selectFromCreator() {
  return new CreatorModelSelector()
}

export const creatorModelPrimitives = selectFromCreator().address

import React from 'react'
import { View } from 'react-native'

import { useIsMember } from '../hooks/useIsMember'

const IsUserJoinedWrapper = (props: any) => {
  const { roomId, children } = props

  const isUserJoined = useIsMember({ roomId })

  return <View style={{ marginTop: 20, overflow: 'hidden' }}>{isUserJoined ? children : null}</View>
}

export default IsUserJoinedWrapper

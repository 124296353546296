/* eslint-disable unicorn/filename-case */
import React, { useCallback } from 'react'
import { Box } from 'native-base'

import { useDrawerStore } from '../../../../navigators/DrawerNavigator/drawerStore'

type HeaderDesktopContextType = {
  headerPortalLeft: React.ReactNode | null
  headerPortalCenter: React.ReactNode | null
  headerPortalRight: React.ReactNode | null
  setHeaderPortalLeft: (Component: React.ReactNode) => void
  setHeaderPortalCenter: (Component: React.ReactNode) => void
  setHeaderPortalRight: (Component: React.ReactNode) => void
  setContainerProps: (props: React.ComponentProps<typeof Box>) => void
  drawerOpen: boolean
  toggleDrawer: () => void
  openDrawer: () => void
  closeDrawer: () => void
  cleanUp: () => void
}

const HeaderDesktopContext = React.createContext<HeaderDesktopContextType>({
  headerPortalLeft: null,
  headerPortalCenter: null,
  headerPortalRight: null,
  setHeaderPortalLeft: () => null,
  setHeaderPortalCenter: () => null,
  setHeaderPortalRight: () => null,
  setContainerProps: () => null,
  drawerOpen: false,
  toggleDrawer: () => null,
  openDrawer: () => null,
  closeDrawer: () => null,
  cleanUp: () => null
})
export const useHeaderDesktopContext = () => React.useContext(HeaderDesktopContext)

export const HeaderDesktopProvider = ({ children }: { children: React.ReactNode }) => {
  const [containerProps, setContainerProps] = React.useState({})
  const [portalLeft, setPortalLeft] = React.useState(null as React.ReactNode | null)
  const [portalCenter, setPortalCenter] = React.useState(null as React.ReactNode | null)
  const [portalRight, setPortalRight] = React.useState(null as React.ReactNode | null)

  const [drawerOpen, setDrawerOpen] = React.useState(false)

  const toggleDrawer = useCallback(() => {
    setDrawerOpen(!drawerOpen)
  }, [drawerOpen, setDrawerOpen])

  const openDrawer = useCallback(() => {
    setDrawerOpen(true)
  }, [])
  const closeDrawer = useCallback(() => {
    setDrawerOpen(false)
  }, [])

  const setHeaderPortalLeft = useCallback((component: React.ReactNode) => {
    component && setPortalLeft(component)
  }, [])
  const setHeaderPortalCenter = useCallback((component: React.ReactNode) => {
    component && setPortalCenter(component)
  }, [])
  const setHeaderPortalRight = useCallback((component: React.ReactNode) => {
    component && setPortalRight(component)
  }, [])

  const providerValue = {
    setHeaderPortalLeft,
    setHeaderPortalCenter,
    setHeaderPortalRight,
    headerPortalLeft: portalLeft,
    headerPortalCenter: portalCenter,
    headerPortalRight: portalRight,
    containerProps,
    setContainerProps,
    drawerOpen,
    toggleDrawer,
    openDrawer,
    closeDrawer,
    cleanUp: () => {
      setPortalLeft(null)
      setPortalCenter(null)
      setPortalRight(null)
      setContainerProps({})
    }
  }

  return (
    <HeaderDesktopContext.Provider value={providerValue}>{children}</HeaderDesktopContext.Provider>
  )
}

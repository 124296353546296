import React from 'react'
import { TouchableOpacity } from 'react-native'
import { useMatrixUser } from '@vatom/sdk/react'

import ElipsisIcon from '../../assets/header-icons/elipsis-icon.svg'
import { isCurrentUserJoined } from '../../helpers'
import { useMembers } from '../../queries'
import { useCommunitiesTheme } from '../../themes'
import RoomModal from '../ActionsModals/RoomModal'
import { useRoomContext } from '../RoomProvider'

const RoomElipsisButton = () => {
  const room = useRoomContext()
  const { room_id: roomID } = room
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const communitiesTheme = useCommunitiesTheme()

  const { data: matrixUser } = useMatrixUser()
  const { data: roomMembers } = useMembers(roomID)

  const isUserJoined = isCurrentUserJoined(roomMembers, matrixUser?.user_id)

  return (
    <>
      {isUserJoined ? (
        <TouchableOpacity
          accessibilityRole="button"
          onPress={() => setIsModalVisible(true)}
          style={{ width: 18, height: 18 }}
        >
          <ElipsisIcon height={18} fill={communitiesTheme.header.icons} />
        </TouchableOpacity>
      ) : null}

      {isModalVisible ? (
        <RoomModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
      ) : null}
    </>
  )
}

export default RoomElipsisButton

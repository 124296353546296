import React from 'react'
import { Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import Animated, { Extrapolation, interpolate, useAnimatedStyle } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'

import { useLayoutScrollHandler } from '../../../../hooks/useLayoutScrollHandler'
import BackIcon from '../../assets/arrow-icons/left-arrow-icon.svg'
import { useMembers } from '../../queries'
import { useCommunitiesTheme } from '../../themes'
import { useRoomContext } from '../RoomProvider'

import RoomElipsisButton from './RoomElipsisButton'

const isWeb = Platform.OS === 'web'

type RoomTopButtonsProps = {
  hideBackButton?: boolean
}

const RoomTopButtons = (props: RoomTopButtonsProps) => {
  const room = useRoomContext()
  const { lastContentOffset } = useLayoutScrollHandler()
  const { name, room_id, num_joined_members } = room
  const communitiesTheme = useCommunitiesTheme()
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()

  const { data: roomMembers } = useMembers(room_id)

  const roomMembersLength = roomMembers
    ? roomMembers.filter(member => member.isJoined).length
    : num_joined_members

  const memberType =
    roomMembersLength > 1 ? 'Members' : roomMembersLength === 0 ? 'Members' : 'Member'

  const opacityAnimatedStyle = useAnimatedStyle(() => {
    const opacity = interpolate(lastContentOffset.value, [0, 200], [0, 1], Extrapolation.CLAMP)
    return {
      opacity
    }
  })

  return (
    <Animated.View
      style={{
        ...styles.buttonsHolder,
        backgroundColor: communitiesTheme.header.background,
        paddingTop: !isWeb ? insets.top : 7
      }}
    >
      <TouchableOpacity
        disabled={props.hideBackButton}
        accessibilityRole="button"
        onPress={
          () => navigation.goBack()
          // navigation
          //   /* @ts-expect-error from navigation */
          //   .getParent('CommunitiesNavigator')
          //   ?.navigate(CommunitiesAppRoutes.Communities)
        }
        style={{ width: 32, height: 32 }}
      >
        {!props.hideBackButton && <BackIcon fill={communitiesTheme.header.icons} />}
      </TouchableOpacity>

      <Animated.View
        style={[
          {
            flexDirection: 'column',
            alignItems: 'center'
          },
          opacityAnimatedStyle
        ]}
      >
        <Text style={{ color: communitiesTheme.header.icons, fontSize: 17, fontWeight: '600' }}>
          {name}
        </Text>
        <Text
          style={{
            color: communitiesTheme.header.icons,
            fontSize: 12,
            fontWeight: '500',
            opacity: 0.8
          }}
        >
          {roomMembersLength} {memberType}
        </Text>
      </Animated.View>

      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {/* <SearchIcon fill={communitiesTheme.header.icons} style={{ marginRight: 16 }} /> */}
        <RoomElipsisButton />
      </View>
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  buttonsHolder: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 7,
    paddingHorizontal: 16,
    zIndex: 2
  }
})

export default RoomTopButtons

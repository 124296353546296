import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
}

export const Filters = ({ size, fill }: IconProps) => (
  <Icon viewBox="0 0 32 32" size={size}>
    <Path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 7.25C9.41421 7.25 9.75 7.58579 9.75 8V12C9.75 12.0315 9.74806 12.0626 9.74428 12.093C11.0411 12.4241 12 13.6001 12 15C12 16.3999 11.0411 17.5759 9.74428 17.9069C9.74806 17.9374 9.75 17.9685 9.75 18L9.75 24C9.75 24.4142 9.41421 24.75 9 24.75C8.58579 24.75 8.25 24.4142 8.25 24L8.25 18C8.25 17.9685 8.25194 17.9374 8.25572 17.9069C6.95889 17.5759 6 16.3999 6 15C6 13.6001 6.9589 12.4241 8.25572 12.093C8.25194 12.0626 8.25 12.0315 8.25 12V8C8.25 7.58579 8.58579 7.25 9 7.25ZM10.5 15C10.5 15.8284 9.82843 16.5 9 16.5C8.17157 16.5 7.5 15.8284 7.5 15C7.5 14.1716 8.17157 13.5 9 13.5C9.82843 13.5 10.5 14.1716 10.5 15ZM13 20C13 21.3999 13.9589 22.5759 15.2557 22.9069C15.2519 22.9374 15.25 22.9685 15.25 23V24C15.25 24.4142 15.5858 24.75 16 24.75C16.4142 24.75 16.75 24.4142 16.75 24V23C16.75 22.9685 16.7481 22.9374 16.7443 22.9069C18.0411 22.5759 19 21.3999 19 20C19 18.6001 18.0411 17.4241 16.7443 17.0931C16.7481 17.0626 16.75 17.0315 16.75 17V8C16.75 7.58579 16.4142 7.25 16 7.25C15.5858 7.25 15.25 7.58579 15.25 8V17C15.25 17.0315 15.2519 17.0626 15.2557 17.0931C13.9589 17.4241 13 18.6001 13 20ZM16 21.5C16.8284 21.5 17.5 20.8284 17.5 20C17.5 19.1716 16.8284 18.5 16 18.5C15.1716 18.5 14.5 19.1716 14.5 20C14.5 20.8284 15.1716 21.5 16 21.5ZM23.75 8C23.75 7.58579 23.4142 7.25 23 7.25C22.5858 7.25 22.25 7.58579 22.25 8V9C22.25 9.03151 22.2519 9.06256 22.2557 9.09305C20.9589 9.42409 20 10.6001 20 12C20 13.3999 20.9589 14.5759 22.2557 14.9069C22.2519 14.9374 22.25 14.9685 22.25 15V24C22.25 24.4142 22.5858 24.75 23 24.75C23.4142 24.75 23.75 24.4142 23.75 24V15C23.75 14.9685 23.7481 14.9374 23.7443 14.9069C25.0411 14.5759 26 13.3999 26 12C26 10.6001 25.0411 9.42409 23.7443 9.09305C23.7481 9.06256 23.75 9.03151 23.75 9V8ZM24.5 12C24.5 12.8284 23.8284 13.5 23 13.5C22.1716 13.5 21.5 12.8284 21.5 12C21.5 11.1716 22.1716 10.5 23 10.5C23.8284 10.5 24.5 11.1716 24.5 12Z"
    />
  </Icon>
)

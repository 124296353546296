import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const ReplyIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 16 15" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M6.75 5C6.3125 5 6 4.6875 6 4.25V4V3.5V2L1 6.5L6 11V9.5V9V8.75C6 8.34375 6.3125 8 6.75 8H7H10C12.1875 8 14 9.8125 14 12C14 12.2812 13.9688 12.5312 13.9375 12.75C14.5 12 15 10.9688 15 9.5C15 7.03125 12.9688 5 10.5 5H7H6.75ZM7 9.5V10V11C7 11.4062 6.75 11.7812 6.40625 11.9375C6.03125 12.0938 5.625 12.0312 5.3125 11.75L0.3125 7.25C0.09375 7.0625 0 6.8125 0 6.5C0 6.21875 0.09375 5.96875 0.3125 5.78125L5.3125 1.28125C5.625 1 6.03125 0.9375 6.40625 1.09375C6.75 1.25 7 1.625 7 2V3V3.5V4H8H10.5C13.5312 4 16 6.46875 16 9.5C16 13.0625 13.4375 14.625 12.8438 14.9688C12.7812 15 12.6875 15 12.5938 15C12.25 15 12 14.75 12 14.4062C12 14.1562 12.125 13.9375 12.2812 13.7812C12.5938 13.5 13 12.9688 13 12.0312C13 10.375 11.6562 9.03125 10 9.03125H8H7V9.53125V9.5Z"
    />
  </Icon>
)

{
  /* <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.75 5C6.3125 5 6 4.6875 6 4.25V4V3.5V2L1 6.5L6 11V9.5V9V8.75C6 8.34375 6.3125 8 6.75 8H7H10C12.1875 8 14 9.8125 14 12C14 12.2812 13.9688 12.5312 13.9375 12.75C14.5 12 15 10.9688 15 9.5C15 7.03125 12.9688 5 10.5 5H7H6.75ZM7 9.5V10V11C7 11.4062 6.75 11.7812 6.40625 11.9375C6.03125 12.0938 5.625 12.0312 5.3125 11.75L0.3125 7.25C0.09375 7.0625 0 6.8125 0 6.5C0 6.21875 0.09375 5.96875 0.3125 5.78125L5.3125 1.28125C5.625 1 6.03125 0.9375 6.40625 1.09375C6.75 1.25 7 1.625 7 2V3V3.5V4H8H10.5C13.5312 4 16 6.46875 16 9.5C16 13.0625 13.4375 14.625 12.8438 14.9688C12.7812 15 12.6875 15 12.5938 15C12.25 15 12 14.75 12 14.4062C12 14.1562 12.125 13.9375 12.2812 13.7812C12.5938 13.5 13 12.9688 13 12.0312C13 10.375 11.6562 9.03125 10 9.03125H8H7V9.53125V9.5Z" fill="#868E96"/>
</svg> */
}

import { TokenType } from '@vatom/sdk/core'
import { getSnapshot, Instance, SnapshotOut, types } from 'mobx-state-tree'

import logger from '../logger'
import { SolanaToken } from '../models/SolanaToken'

import { SolanaRegionStore } from './SolRegion'

const className = 'SolInventoryRegionStore'

export const SolInventoryRegionStore = SolanaRegionStore.named(className)
  .props({
    className,
    _tokens: types.optional(types.map(SolanaToken), {}),
    address: types.optional(types.string, '')
  })

  .actions(self => ({
    matches(id: string, descriptor?: string) {
      return id === 'inventory' && descriptor === self.address
    },
    async fetchPage(args?: any) {
      return
    },
    async load(): Promise<string[]> {
      try {
        self.setIsLoading(true)
        logger.info('[SolInventoryRegion.load]', self.address)
        self.api.setAddress(self.address)
        // const objects = await this.inventory.getInventory()
        const objects = await this.getInventory()

        // Create list of new objects
        // const newObjects = []
        const loadedIDs = []
        // Add vatoms to the list
        for (const t of objects) {
          loadedIDs.push(t.id)
          // newObjects.push(new DataObject('erc721', t.id, t))
        }

        logger.info('[SolInventoryRegion.load] finished', self.address)
        // self.emit('updated', objects)
        // this.addObjects(newObjects)
        self.setIsLoading(false)
        return loadedIDs
      } catch (error) {
        self.setIsLoading(false)
        return []
      }
    },
    async reload() {
      self.clear()
      return self.load()
    },
    async getInventory() {
      logger.info('[SolanaPlugin.getInventory]')
      try {
        const tokens = await self.api.getSolanaNfts()

        const nfts = await Promise.all(
          tokens.map(nft => {
            const nftItem = SolanaToken.create({
              id: nft.mintAddress,
              type: 'solana',
              parentId: '.',
              owner: nft.owner?.address || self.address,
              author: '',
              lastOwner: nft.owner?.address ?? `fake-${Math.random().toString()}`,
              modified: new Date().getTime(),
              created: new Date().getTime(),
              nft
            })
            return getSnapshot(nftItem)
          })
        )
        self.addTokens(nfts)
        return Array.from<TokenType>(self._tokens.values())
      } catch (error) {
        logger.error('[SolanaPlugin.getInventory]', error)
        throw error
      }
    }
  }))
  .actions(self => ({
    afterCreate() {
      self.load()
    }
  }))
  .views(self => ({
    get tokens() {
      return Array.from<TokenType>(self._tokens.values())
    },
    /** Our state key is the current user's ID */
    get stateKey() {
      return `inventory:SolNet:${self.address}`
    },
    get isLoading() {
      console.log('self._isLoading sol: ', self._isLoading)
      return self._isLoading
    }
  }))

export type SolInventoryRegionStoreType = Instance<typeof SolInventoryRegionStore>
export type SolInventoryRegion = SolInventoryRegionStoreType
export type SolInventoryRegionSnapshot = SnapshotOut<typeof SolInventoryRegionStore>

import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
}

export const Home = ({ size, fill }: IconProps) => (
  <Icon viewBox="0 0 28 25" size={size}>
    <Path
      fill={fill}
      d="M26.8387 12.4375C27.1669 12.7188 27.1669 13.1875 26.8856 13.5156C26.6512 13.8438 26.1825 13.8438 25.8544 13.5625L24.12 12.0156V21.25C24.12 23.3594 22.4325 25 20.37 25H6.87C4.76062 25 3.12 23.3594 3.12 21.25V12.0156L1.33875 13.5625C1.01062 13.8438 0.541875 13.8438 0.3075 13.5156C0.0262499 13.1875 0.0262499 12.7188 0.354375 12.4375L13.1044 1.23438C13.3856 0.953125 13.8075 0.953125 14.0887 1.23438L26.8387 12.4375ZM6.87 23.5H9.87V16C9.87 15.2031 10.5262 14.5 11.37 14.5H15.87C16.6669 14.5 17.37 15.2031 17.37 16V23.5H20.37C21.5887 23.5 22.62 22.5156 22.62 21.25V10.7031L13.62 2.78125L4.62 10.7031V21.25C4.62 22.5156 5.60437 23.5 6.87 23.5ZM11.37 23.5H15.87V16H11.37V23.5Z"
    />
  </Icon>
)

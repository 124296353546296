import React, { useRef } from 'react'
import { Dimensions, Keyboard, ScrollView, StyleSheet, View } from 'react-native'
import { RichEditor } from 'react-native-pell-rich-editor'
import { useGetSpace, useMatrixUser } from '@vatom/sdk/react'
import { Loader } from '@vatom/wombo'
import axios from 'axios'
import { observer } from 'mobx-react-lite'
import { SnapshotIn } from 'mobx-state-tree'
import { Box } from 'native-base'

import { withBusinessSelector } from '../../../hooks/useBusinessSelector'
import { AppRoutes, AppStackScreenProps } from '../../../navigators'
import { base64ToArrayBuffer, getSendMessageUrl } from '../components/AddModals/add-modals-helpers'
import CloseButton from '../components/AddModals/modal-components/modal-header-components/CloseButton'
import ModalDecorations from '../components/AddModals/modal-components/ModalDecorations'
import ModalError from '../components/AddModals/modal-components/ModalError'
import ModalImagesSliderHolder from '../components/AddModals/modal-components/ModalImageSliderHolder'
import ModalInputsHolder from '../components/AddModals/modal-components/ModalInputsHolder'
import ModalMentions from '../components/AddModals/modal-components/ModalMentions'
import ModalToolbar from '../components/AddModals/modal-components/ModalToolBar'
import ModalWrapper, {
  PlatformWrapper
} from '../components/AddModals/modal-components/ModalWrapper'
import { AddMessageModalStore, Media } from '../components/AddModals/stores'
import { CreateMessageProvider } from '../components/MSTContextProvider'
import MessageInformation from '../components/reply-components/MessageInformation'
import UserAvatar from '../components/UserAvatar'
import { getUploadedMediaUrl } from '../helpers'
import { useIsMember } from '../hooks/useIsMember'
import { useMessage } from '../queries'
import { useCommunitiesTheme } from '../themes'

type ReplyProps = AppStackScreenProps<
  typeof AppRoutes.CommunitiesRoomReply | typeof AppRoutes.RoomReply
>

const threadMsgStore = AddMessageModalStore.create()

const ReplyScreen = observer(({ navigation, route }: ReplyProps) => {
  const store = threadMsgStore
  const { messageId, method, spaceId } = route.params

  const space = useGetSpace(spaceId)
  const roomId = space.data?.matrixRoomId ?? ''
  const inputRef = useRef<RichEditor | undefined>() as React.RefObject<RichEditor>
  const [keyboardHeight, setKeyboardHeight] = React.useState(0)
  const { data: matrixUser } = useMatrixUser()

  const userId = matrixUser?.user_id

  const isMember = useIsMember({ roomId })

  const { data: event } = useMessage(roomId, messageId, Boolean(isMember))

  const replyToMessageId = event
    ? event.type !== 'v.room.reply'
      ? messageId
      : event.content['m.relates_to'].event_id
    : ''

  const accessToken = matrixUser?.access_token

  // const { data: roomMembers } = useMembers(roomId)

  // const { data: plugins, isSuccess } = usePlugins(businessId)

  const communitiesTheme = useCommunitiesTheme()

  const scrollViewRef = React.useRef<ScrollView>(null)

  const [isLoading, setIsLoading] = React.useState(false)
  const [showError, setShowError] = React.useState(false)
  const [errorData, setErrorData] = React.useState({
    errorTitle: '',
    errorDescription: ''
  })
  const [messageInfoHeight, setMessageInfoHeight] = React.useState(0)

  React.useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', e => {
      const keyboardHeight = e.endCoordinates.height
      setKeyboardHeight(keyboardHeight)
    })

    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardHeight(0)
    })

    return () => {
      keyboardDidShowListener.remove()
      keyboardDidHideListener.remove()
      store.reset()
    }
  }, [store])

  React.useEffect(() => {
    if (messageInfoHeight > 0 && keyboardHeight > 0) {
      setTimeout(() => {
        scrollViewRef.current?.scrollTo({ y: messageInfoHeight, animated: true })
      }, 100)
    }
  }, [messageInfoHeight, keyboardHeight])

  // const getStakeSliderData = () => {
  //   const pluginData = plugins ? plugins[0] : null
  //   if (!pluginData) {
  //     return null
  //   }
  //   const pluginDescriptor = pluginData.descriptor
  //   if (pluginDescriptor.plugin_id !== 'reputation') {
  //     return null
  //   }

  //   const stakeSliderInput = store.additionalInputs.find(input => input.type === 'stake-slider')
  //   if (!stakeSliderInput) {
  //     return null
  //   }

  //   const parentMessageMember = roomMembers?.find(member => member.id === message?.sender)
  //   const currentUserInfo = roomMembers?.find(member => member.id === userId)

  //   const { value } = stakeSliderInput
  //   const stakeValue = typeof value === 'number' ? value : parseFloat(value.toString())

  //   return {
  //     stake: Math.floor(stakeValue),
  //     parentMemberEventId: parentMessageMember?.eventId,
  //     replyMemberEventId: currentUserInfo?.eventId
  //   }
  // }

  const sendMessage = async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    let sendMessageUrl = getSendMessageUrl('v.room.reply', roomId, accessToken)
    // const additionalData = getStakeSliderData()

    let uploadedMediaCopy: Array<SnapshotIn<typeof Media>> = []
    let isError = false

    const { media } = store

    if (media.length > 0) {
      await Promise.all(
        media
          .filter(media => media.type === 'image')
          .map(async media => {
            const { type } = media
            if (type === 'image') {
              if (!media.base64) {
                return null
              }
              const mimeType = 'image/jpeg'
              const blob = base64ToArrayBuffer(media.base64)

              try {
                const uploadUrl = await getUploadedMediaUrl(blob, mimeType, accessToken)
                media.change({ ...media, uri: uploadUrl })
              } catch (e) {
                isError = true
                setIsLoading(false)
                setShowError(true)
                setErrorData({
                  errorTitle: 'Upload Error',
                  errorDescription: `${media.fileName} could not be uploaded`
                })
                store.removeMedia(media.uri)
                return null
              }
            }
          })
      )
      uploadedMediaCopy = media.map(media => ({ ...media, base64: '' }))
    }

    const postBody = {
      msgtype: 'm.text',
      body: JSON.stringify({
        elements: store.inputs.map(input => ({
          type: input.type,
          content: input.value
        }))
      }),
      ...(uploadedMediaCopy.length > 0 ? { media: JSON.stringify(uploadedMediaCopy) } : {})
      // ...additionalData
    }

    if (!isError) {
      if (method === 'edit') {
        sendMessageUrl = getSendMessageUrl('v.room.edit', roomId, accessToken)

        await axios
          .post(sendMessageUrl, {
            'm.new_content': {
              'm.relates_to': {
                rel_type: 'm.thread',
                event_id: replyToMessageId
              },
              ...postBody
            },
            'm.relates_to': {
              rel_type: 'm.replace',
              event_id: messageId
            }
          })
          .then(() => navigation.goBack())
          .catch(e => setIsLoading(false))

        return
      }

      await axios
        .post(sendMessageUrl, {
          'm.relates_to': {
            rel_type: 'm.thread',
            event_id: messageId
          },
          ...postBody
        })
        .then(() => {
          navigation.goBack()
        })
        .catch(e => setIsLoading(false))
    }
  }

  // if (!isSuccess) {
  //   return null
  // }

  const { height } = Dimensions.get('window')

  return (
    <CreateMessageProvider dataStore={threadMsgStore}>
      <ModalWrapper>
        <PlatformWrapper>
          {isLoading ? (
            <View
              style={[
                styles.loader,
                {
                  backgroundColor: communitiesTheme.background
                }
              ]}
            >
              <Loader />
            </View>
          ) : null}

          <ModalError
            showError={showError}
            errorTitle={errorData.errorTitle}
            errorDescription={errorData.errorDescription}
            setShowError={setShowError}
          />
          <Box
            safeAreaTop
            flexDirection={'row'}
            justifyContent={'space-between'}
            paddingY={3}
            backgroundColor={communitiesTheme.background}
          >
            <Box alignSelf={'flex-start'} marginLeft={2}>
              <CloseButton iconType="close" />
            </Box>
          </Box>

          <ScrollView
            ref={scrollViewRef}
            style={{
              paddingTop: 10,
              backgroundColor: communitiesTheme.background
            }}
            contentContainerStyle={{
              flexGrow: 1,
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <View
              style={{
                paddingHorizontal: 16,
                justifyContent: 'flex-start'
              }}
            >
              <View onLayout={event => setMessageInfoHeight(event.nativeEvent.layout.height)}>
                {replyToMessageId ? (
                  <MessageInformation spaceId={spaceId} replyToMessageId={replyToMessageId} />
                ) : null}
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  width: '100%',
                  height: height - keyboardHeight - 300
                }}
              >
                {userId ? (
                  <UserAvatar
                    memberId={userId}
                    width={48}
                    height={48}
                    style={{ marginRight: 4, borderRadius: 100 }}
                  />
                ) : null}

                <View style={{ width: '100%', paddingRight: 40 }}>
                  <ModalInputsHolder
                    messageType={'v.room.reply'}
                    roomId={roomId}
                    messageId={event?.type === 'v.room.reply' ? messageId : ''}
                    ref={inputRef}
                  />
                </View>
              </View>
            </View>

            <View style={{ paddingHorizontal: 20 }}>
              <ModalImagesSliderHolder
                roomId={roomId}
                messageId={event?.type === 'v.room.reply' ? messageId : ''}
              />
              <ModalDecorations
                roomId={roomId}
                messageId={event?.type === 'v.room.reply' ? messageId : ''}
                messageType={'v.room.reply'}
              />
            </View>
          </ScrollView>
          <ModalMentions ref={inputRef} roomID={roomId} />
          <ModalToolbar
            ref={inputRef}
            type="v.room.proposal"
            isThread={false}
            sendMessage={sendMessage}
          />
        </PlatformWrapper>
      </ModalWrapper>
    </CreateMessageProvider>
  )
})

const styles = StyleSheet.create({
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const ReplyScreenWithBusinessSelector = withBusinessSelector(ReplyScreen)
export default ReplyScreenWithBusinessSelector

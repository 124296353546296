import { useEffect, useId } from 'react'
import { useFrame } from '@react-three/fiber'
import { Vector3 } from 'three'
import { create } from 'zustand'

export const CompassHUD = () => {
  const lineColor = '#000000'
  const { markers } = useCompassHUDMarkerStore()

  return (
    <div
      id="container"
      style={{
        position: 'fixed',
        zIndex: '5',
        bottom: '40px',
        left: '40px',
        width: 'calc(100% - 80px)',
        height: '32px',
        overflow: 'hidden'
      }}
    >
      {Object.entries(markers).map(markerTuple => {
        const [id, marker] = markerTuple
        return <CompassMarker key={id} marker={marker} />
      })}
      <div
        id="left"
        style={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '1px',
          height: '100%',
          zIndex: 2,
          backgroundColor: lineColor
        }}
      />
      <div
        id="right"
        style={{
          position: 'absolute',
          top: '0px',
          right: '0px',
          width: '1px',
          height: '100%',
          zIndex: 2,
          backgroundColor: lineColor
        }}
      />
      <div
        id="line"
        style={{
          position: 'absolute',
          top: '50%',
          left: '0px',
          width: '100%',
          height: '1px',
          backgroundColor: lineColor
        }}
      />
      <div
        id="guidline"
        style={{
          position: 'absolute',
          top: '0px',
          left: '0%',
          width: '100%',
          height: '100%',
          // background: url('${require("./guideline.svg")}') 0px 0px/contain,
          fill: lineColor,
          opacity: '0.5'
        }}
      />
    </div>
  )
}

type CompassHUDMarker = {
  id: string
  angle: 'up' | 'down' | 'forward'
  /** 0 to 100 */
  offset: number
}

type CompassHUDMarkerStore = {
  markers: Record<string, CompassHUDMarker>
  setMarker: (id: string, marker: CompassHUDMarker) => void
}

export const useCompassHUDMarkerStore = create<CompassHUDMarkerStore>(set => ({
  markers: {},
  setMarker: (id, marker) => set(state => ({ markers: { ...state.markers, [id]: marker } }))
}))

// This hook will add a marker to the compass HUD any 3d object
export const useCompassHUDMarker = (ref: THREE.Object3D) => {
  const id = useId()
  useFrame(({ camera }) => {
    if (!ref.visible) {
      useCompassHUDMarkerStore.setState(({ markers }) => {
        delete markers[id]
        return {
          markers: { ...markers }
        }
      })
      return
    }
    const { setMarker } = useCompassHUDMarkerStore.getState()
    const offset = calculateCompassOffset(camera, ref)
    const height = calculateCompassHeight(camera, ref)
    const angle = height > 1 ? 'up' : height < -1 ? 'down' : 'forward'
    setMarker(id, { id, angle, offset })
  })

  useEffect(() => {
    return () => {
      useCompassHUDMarkerStore.setState(({ markers }) => {
        delete markers[id]
        return {
          markers: { ...markers }
        }
      })
    }
  }, [id])
}

function calculateCompassOffset(camera: THREE.Camera, target: THREE.Object3D) {
  // If we have a target, calculate angle or else just look straight ahead
  const vector = new Vector3()
  if (target) vector.copy(target.position)
  else vector.set(0, 0, 1)

  // Project using camera
  vector.project(camera)
  let rotY = vector.x

  // Wrap values
  rotY /= 2
  rotY += 0.5
  rotY *= 100

  // Limit range
  rotY = Math.max(0, Math.min(100, rotY))

  // If the object is not in view (ie reversed projection), keep it on the sides
  if (target && vector.z > 1) rotY = rotY > 50 ? 0 : 100

  // Done
  return rotY
}

// Calculates the height of the object based on tilt
function calculateCompassHeight(camera: THREE.Camera, target: THREE.Object3D) {
  const vector = new Vector3()
  // If we have a target, calculate angle or else just look straight ahead
  if (target) vector.copy(target.position)
  else vector.set(0, 0, 1)

  // Project using camera
  vector.project(camera)
  const rotX = vector.y

  // Wrap values
  // rotY /= 2
  // rotY += 0.5
  // rotY *= 100

  // Limit range
  // rotY = Math.max(0, Math.min(100, rotY))

  // If the object is not in view (ie reversed projection), keep it on the sides
  // if (target && vector1.z > 1)
  //     rotY = rotY > 50 ? 0 : 100

  // Done
  return rotX
}

function CompassMarker({ marker }: { marker: CompassHUDMarker }) {
  const { angle, offset } = marker
  const hudcolor = 'white'

  const getStyle = () => {
    switch (angle) {
      case 'up':
        return {
          backgroundColor: hudcolor,
          borderRadius: '0px',
          transform: 'rotate(45deg) translateY(24px)'
        }
      case 'down':
        return {
          backgroundColor: hudcolor,
          borderRadius: '0px',
          transform: 'rotate(45deg) translateY(-24px)'
        }
      case 'forward':
        return {
          backgroundColor: hudcolor,
          borderRadius: '3px',
          transform: 'rotate(0deg)'
        }
      default:
        return {}
    }
  }

  return (
    <div
      style={{
        fontSize: '4px',
        position: 'absolute',
        top: 'calc(50% - 12px)',
        left: `calc(${offset}% - 12px)`,
        width: '24px',
        height: '24px',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        zIndex: 1000,
        ...getStyle()
      }}
    />
  )
}

import { AnalyticsPayload } from '@vatom/experience-sdk'
import { useAnalytics } from '@vatom/sdk/react'

import { AnalyticsEvents } from '../utils/AnalyticsEvents'

import { useBusinessSelector } from './useBusinessSelector'
import { useIsFirstRender } from './useIsFirstRender'

type EventType = keyof typeof AnalyticsEvents

const useBusinessAnalytics = () => {
  const analytics = useAnalytics()
  const { business } = useBusinessSelector()

  const onAnalytics = (type: EventType, payload?: AnalyticsPayload) => {
    const businessId = business?.id ?? ''
    const campaignId = business?.defaultCampaignId ?? ''
    analytics.event(AnalyticsEvents[type], { businessId, campaignId, ...payload })
  }

  const onOpenBusiness = () => {
    if (business) onAnalytics('OPEN_BUSINESS')
  }

  return {
    onAnalyticsBusiness: onAnalytics,
    onOpenBusiness
  }
}

const useOnOpenBusinessAnalytics = () => {
  const { onOpenBusiness } = useBusinessAnalytics()
  const isFirstTime = useIsFirstRender()

  isFirstTime && onOpenBusiness()
}

export { useBusinessAnalytics, useOnOpenBusinessAnalytics }

import React, { useMemo, useState } from 'react'
import { Linking } from 'react-native'
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue
} from 'react-native-reanimated'
import { useNavigation } from '@react-navigation/native'
import { useGetPublicProfile, useGetSpace, useUser } from '@vatom/sdk/react'
import { PressableOpacity, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Center, Text, View } from 'native-base'

import { HeaderButtonBack, ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import { useBusinessSelector, withBusinessSelector } from '../../hooks/useBusinessSelector'
import { useIsDesktop } from '../../hooks/useIsDesktop'
import { AppRoutes, AppStackScreenProps } from '../../navigators'
import MessageList from '../Communities/components/MessageList'
import { useCommunitiesTheme } from '../Communities/themes'

import ProfileActions from './components/ProfileActions'
import { ProfileUserBio } from './components/ProfileUserBio'
import { ProfileUserCard } from './components/ProfileUserCard'
import RoomFilters from './components/RoomFilters'
import { SharedSpaces } from './components/SharedSpaces'
import { StatsCard } from './components/StatsCard'

export type ProfileOtherUserProps = AppStackScreenProps<
  typeof AppRoutes.profileOtherUser_Business | typeof AppRoutes.profileOtherUser
>

const ProfileOtherUser = observer(({ route }: ProfileOtherUserProps) => {
  const currentUser = useUser()
  const [filter, setFilter] = useState('')

  const { userId, memberId, showSharedSpaces, spaceId } = route.params
  const navigation = useNavigation()
  const space = useGetSpace(spaceId ?? '', {
    enabled: false
  })
  const roomId = space.data?.matrixRoomId ?? ''

  const { business } = useBusinessSelector()

  const businessId = useMemo(() => {
    return business?.id ?? null
  }, [business?.id])

  const finalMemberId = userId ? `@${userId}:vatom.com` : memberId ?? ''
  const finalUserId = userId ?? finalMemberId?.match('@(.*):')?.[1] ?? ''

  const user = useGetPublicProfile(finalUserId)
  const isDesktop = useIsDesktop()

  const communitiesTheme = useCommunitiesTheme()

  const scrollOffsetY = useSharedValue(0)
  const scrollHandler = useAnimatedScrollHandler(event => {
    scrollOffsetY.value = event.contentOffset.y
  })
  const Max_Header_Height = 100
  const Min_Header_Height = 70
  const scrollDiff = Max_Header_Height - Min_Header_Height // 30

  const animatedHeaderStyles = useAnimatedStyle(() => {
    const height = interpolate(scrollOffsetY.value, [0, scrollDiff], [100, 60], {
      extrapolateRight: Extrapolation.CLAMP,
      extrapolateLeft: Extrapolation.CLAMP
    })
    return {
      height: height
    }
  })
  const animatedTitleStyles = useAnimatedStyle(() => {
    const show = interpolate(scrollOffsetY.value, [130, 200], [0, 1], {
      extrapolateRight: Extrapolation.CLAMP,
      extrapolateLeft: Extrapolation.CLAMP
    })
    return {
      display: show === 0 ? 'none' : 'flex'
    }
  })

  // @ts-expect-error it's saying display block is not available but block is working as expected. Maybe flex would work too which available
  const animatedScrollStyles = useAnimatedStyle(() => {
    const value = interpolate(scrollOffsetY.value, [0, 30], [-50, 0], {
      extrapolateRight: Extrapolation.CLAMP,
      extrapolateLeft: Extrapolation.CLAMP
    })

    return {
      marginTop: value,
      display: 'block'
    }
  })

  const brandColor = theme.colors.brand[500]
  const brandColorLight = '#FF7A00'

  const statusBarColor = businessId ? communitiesTheme.background : brandColor

  const backdropProps = !isDesktop
    ? {
        zIndex: 0,
        backgroundColor: {
          linearGradient: {
            colors:
              businessId && communitiesTheme.header.background
                ? [communitiesTheme.header.background, communitiesTheme.header.background]
                : [brandColor, brandColorLight]
          }
        }
      }
    : undefined

  const onPressBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate(AppRoutes.home)
    }
  }

  return (
    <ScreenWrapper
      screenBackgroundColor={communitiesTheme.background}
      statusBarBackgroundColor={statusBarColor}
    >
      <Animated.View
        style={[{ zIndex: 0, position: 'relative', overflow: 'hidden' }, animatedHeaderStyles]}
      >
        <ScreenHeader
          headerTextColor={theme.colors.white}
          headerProps={{
            zIndex: 0,
            backgroundColor:
              businessId && communitiesTheme.header.background
                ? communitiesTheme.header.background
                : 'transparent'
          }}
          backdropProps={backdropProps}
          headerLeft={() => (
            <ScreenHeaderButton onPress={onPressBack}>
              <HeaderButtonBack color={theme.colors.white} />
            </ScreenHeaderButton>
          )}
          headerRight={() => <ScreenHeaderButton />}
        >
          <Animated.View
            style={[
              {
                alignSelf: 'stretch',
                alignItems: 'center',
                justifyContent: 'center'
              },
              animatedTitleStyles
            ]}
          >
            {user.data?.name && (
              <Title preset="h5" variant="bold" color={theme.colors.white}>
                {user.data?.name}
              </Title>
            )}
          </Animated.View>
        </ScreenHeader>
      </Animated.View>
      <Animated.ScrollView
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={16}
        onScroll={scrollHandler}
        style={animatedScrollStyles}
      >
        <ProfileUserCard
          avatarUrl={user.data?.picture}
          name={user.data?.name}
          username={user.data?.preferred_username}
        />
        <Center>
          {user.data?.bio && <ProfileUserBio text={user.data?.bio} marginTop={4} />}

          {user.data?.website && (
            <PressableOpacity onPress={() => Linking.openURL(user.data?.website ?? '')}>
              <Center marginY={2}>
                <Text color={communitiesTheme.memberInfo.linkColor}>{user.data?.website}</Text>
              </Center>
            </PressableOpacity>
          )}

          <StatsCard
            userId={finalUserId}
            containerProps={{
              marginTop: 8
            }}
          />
        </Center>

        {user.data?.id && user.data?.id !== currentUser?.sub ? (
          <ProfileActions userId={user.data?.id} user={user.data} />
        ) : null}

        {!roomId && businessId && showSharedSpaces && finalMemberId ? (
          <SharedSpaces businessId={businessId} memberId={finalMemberId} />
        ) : null}

        {!showSharedSpaces && roomId ? (
          <>
            <RoomFilters
              setFilter={setFilter}
              filter={filter}
              isMemberPage={roomId ? true : false}
            />

            <View>
              <MessageList
                spaceId={spaceId ?? ''}
                roomId={roomId ?? ''}
                isFiltered
                memberId={finalMemberId}
                filter={filter}
              />
            </View>
          </>
        ) : null}
      </Animated.ScrollView>
    </ScreenWrapper>
  )
})

const ProfileOtherUserWithBusinessSelector = withBusinessSelector(ProfileOtherUser)

export { ProfileOtherUserWithBusinessSelector as ProfileOtherUser }

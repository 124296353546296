import { SpaceType } from '@vatom/sdk/core'

// @ts-ignore
import MessageManager from './audio/MessageManager'
import { MixerState } from './audio/Mixer'
import Mixers from './audio/Mixers'
// @ts-ignore
import Octree from './audio/Octree'
// @ts-ignore
import SWebP2PAvatars from './audio/SWebP2PAvatars'
import { getAllOnlineUsers, getDimension, setUsersPublic, setVatomIncUsers } from './firestore'

/** Connection statuses */
export enum ConnectionStatus {
  Connecting,
  Connected,
  Error,
  Ended
}

/** User definition */
export class SpaceSessionUser {
  /** The ID of the user */
  id = ''

  /** The name of the user */
  name = ''

  /** The current position of the user */
  position = { x: 0, y: 0, z: 0 }

  /** Distance from the current user in meters */
  distance = 0

  /** The user's volume in decibels */
  volume = 0

  /** The user's muted state */
  muted = true

  /** True if the user is speaking */
  speaking = false
}

/**
 * Manages a call into Spaces.
 */
export default class SpaceSessionManager {
  /** The space info */
  space: SpaceType

  /** The current user ID */
  userID = ''

  /** Mixers, if we are connected to them */
  mixers?: Mixers

  /** Time to wait for a connected mixer */
  mixerConnectingWaitUntil = 0

  /** Get current state */
  status = ConnectionStatus.Connecting

  /** Current status text description */
  statusText = 'Discovering space'

  /** Input stream from the microphone */
  micStream?: MediaStream

  /** Current avatar position */
  position = { x: 0, y: 0, z: 0 }

  /** @private Start promise, resolves once the start() function resolves */
  startPromise?: Promise<any>

  /** Message manager */
  messages: MessageManager

  /** P2P manager */
  p2p?: SWebP2PAvatars

  firebaseApp = null

  /** Outgoing mute status */
  _muted = true
  get muted() {
    return this._muted
  }
  set muted(v) {
    // Stop if no change, otherwise store the change
    if (this._muted == v) return
    this._muted = v

    // Update system
    if (this.mixers) {
      // We are on the mixers, update mute status there
      this.mixers.muted = v
    } else if (this.p2p) {
      // We are on P2P, update mute status there
      this.p2p.muted = v
    }
  }

  /** Current user's VatomInc access token */
  accessToken: string

  /** Constructor */
  constructor(space: SpaceType, userID: string, accessToken: string, firebaseApp?: any) {
    // Store vars
    this.space = space
    this.userID = userID
    this.accessToken = accessToken

    // Create message manager
    this.messages = new MessageManager(this.space.id, 'vatominc:' + userID)

    this.firebaseApp = firebaseApp
  }

  /** Start the call */
  async start() {
    // Only allow once
    if (this.startPromise) return await this.startPromise

    // Store promise
    this.startPromise = Promise.resolve()
      .then(async () => {
        // Enter the server, allow the server to fail this if the user doesn't have permission to enter
        // TODO: This fails with some error about 'moduleWithDashes' ???
        // await firebase.app('HIFI').functions().httpsCallable('enterServer')({
        //     instance: this.space.id,
        //     dimension: 'default'
        // })

        // Setup messages
        this.messages.setup()

        // Get space dimension doc
        const dimensionDoc = await getDimension(this.space.id, this.firebaseApp)

        // Stop if it doesn't exist
        if (!dimensionDoc.exists) throw new Error(`Space ${this.space.id} was not found.`)
        const dimensionData = dimensionDoc.data() || {}

        // Fetch microphone input
        this.micStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })

        // Check how to connect
        if (dimensionData.avatar_system == 'p2p') {
          // Start in P2P mode
          await this.startP2P()
        } else {
          // Start in mixer mode
          await this.startMixers()
        }
      })
      .catch(err => {
        // Connection failed!
        // logger.warn(`[SpaceSessionManager] Connection failed: ${err.message}`)
        this.status = ConnectionStatus.Error
        this.statusText = 'Connection failed'
        throw err
      })

    // Return the promise
    return await this.startPromise
  }

  /** @private Start the call via mixers */
  async startMixers() {
    // Start the mixer logic
    this.mixers = new Mixers(this.space.id, this.userID, this.firebaseApp)
    this.mixers.micInputStream = this.micStream
    this.mixers.mixerConnectionNeeded = true
    await this.mixers.start()

    // The time to wait until users are found
    this.statusText = 'Discovering users'
    this.mixerConnectingWaitUntil = Date.now() + 15 * 1000
  }

  /** @private Start the call via the P2P system */
  async startP2P() {
    // Create P2P session
    this.p2p = new SWebP2PAvatars(false, this.messages, this.space.id, 'vatominc:' + this.userID)

    // Add mic stream
    if (this.micStream) this.p2p.audio.microphoneStream = this.micStream
    else
      console.warn(
        `[SesionSessionManager] No microphone input stream found. People will not be able to hear you.`
      )

    // Default muted state
    this.p2p.muted = this.muted

    // Start it
    await this.p2p.start()

    // Do first presence fetch
    this.fetchPresenceData()
  }

  /** End the current session */
  async end() {
    this.startPromise = undefined

    // Stop the micrphone stream
    this.micStream?.getTracks().forEach(function (track) {
      track.stop()
    })

    // Stop everything
    await this.mixers?.shutdown()
    await this.messages?.stop()
    await this.p2p?.stop()

    // Remove presence
    this.updateUserFields({ presence_date: 0 })
  }

  /** Get list of all connected users in this session, sorted by distance from the current user. */
  listUsers(): SpaceSessionUser[] {
    // Create list of users
    const users: SpaceSessionUser[] = []

    // Check connection mode
    if (this.mixers) {
      // Count users from connected mixers
      for (const mixer of this.mixers.active) {
        for (const avatar of mixer.avatars) {
          // Create object
          const user = new SpaceSessionUser()
          user.id = avatar.firebaseUserID
          user.volume = avatar.volume
          user.muted = avatar.volume < -30 // <-- We can't get mute state from the mixers, so try to detect from their volume
          user.speaking = !user.muted && user.volume > -40
          user.position.x = avatar.properties.x
          user.position.y = avatar.properties.height
          user.position.z = avatar.properties.y
          user.distance = Math.sqrt(
            (user.position.x - this.position.x) ** 2 +
              (user.position.y - this.position.y) ** 2 +
              (user.position.z - this.position.z) ** 2
          )
          users.push(user)
        }
      }
    } else if (this.p2p) {
      // Count P2P connections
      for (const connection of this.p2p.connections) {
        // Ignore connections that aren't fully open yet
        if (!connection.isConnected) continue

        // Check if not added already (since we can have duplicate connections for brief moments)
        if (users.find(u => u.id == connection.userID)) continue

        // Create object
        const user = new SpaceSessionUser()
        user.id = connection.userID
        user.volume = connection.metadata.volume || -70
        user.muted = connection.metadata.muted
        user.speaking = !user.muted && user.volume > -40
        user.position.x = connection.metadata.x
        user.position.y = connection.metadata.y
        user.position.z = connection.metadata.z
        user.distance = Math.sqrt(
          (user.position.x - this.position.x) ** 2 +
            (user.position.y - this.position.y) ** 2 +
            (user.position.z - this.position.z) ** 2
        )
        users.push(user)
      }
    }

    // Sort
    users.sort((a, b) => a.distance - b.distance)

    // Done
    return users
  }

  /** Sets the avatar position in 3D space. */
  setPosition(x: number, y: number, z: number) {
    // Store it
    this.position.x = x
    this.position.y = y
    this.position.z = z

    // Check connection method
    if (this.mixers) {
      // Set position on the mixers
      this.mixers.setPosition(x, y, z)
    } else {
      // Set P2P position
      this.p2p?.setMetadata({ x, y, z })
    }
  }

  /** The host must call this every second to update state and to move the user around, etc */
  async update() {
    // Check if using mixers or P2P
    if (this.mixers) this.updateMixers()
    else this.updateP2P()

    // Stay near other users automatically
    const nearestUser = this.listUsers()[0]
    if (nearestUser && nearestUser.distance > 3) {
      // Move us
      const randomness = 0.5
      this.setPosition(
        nearestUser.position.x + 0 + Math.random() * randomness * 2 - randomness,
        nearestUser.position.y,
        nearestUser.position.z + 1 + Math.random() * randomness * 2 - randomness
      )
    }

    // Update presence
    this.fetchPresenceData()
    this.updateMyPresenceData()
  }

  /** Updates the user profile and merges in the specified fields */
  async updateUserFields(props: any) {
    // Check fields
    props.lastModified = Date.now()
    delete props.id
    delete props.auth

    // Do the update to the space-specific profile
    await setVatomIncUsers(this.space.id, this.userID, props, this.firebaseApp)

    // Do the update to the public profile
    await setUsersPublic(this.userID, props, this.firebaseApp)
  }

  /** @private Called every second while on a Mixer call */
  updateMixers() {
    // Sanity check: We should have mixers here
    if (!this.mixers) return

    // Check mixer status
    if (!this.mixers.primaryMixer && Date.now() < this.mixerConnectingWaitUntil) {
      // No mixers means no users, but let's keep showing "connecting" until the time runs out
      this.status = ConnectionStatus.Connecting
    } else if (!this.mixers.primaryMixer) {
      // No mixers means no users
      this.status = ConnectionStatus.Connected
    } else if (this.mixers.primaryMixer.state == MixerState.Connecting) {
      // Primary mixer is still connecting
      this.status = ConnectionStatus.Connecting
    } else if (this.mixers.primaryMixer.lastError) {
      // Primary mixer is in an error state!
      this.status = ConnectionStatus.Error
      this.statusText = this.mixers.primaryMixer.lastError.message
    } else {
      // All good, we are connected
      this.status = ConnectionStatus.Connected
    }
  }

  /** Called every second while on a P2P call */
  updateP2P() {
    // Check state based on P2P values
    if (!this.p2p) return

    // Check connection status
    if (this.p2p.connections.length === 0 || this.p2p.connections.find((c: any) => c.isConnected)) {
      // No users in the space OR we are connected to some users
      this.status = ConnectionStatus.Connected
    } else {
      // Still connecting to users
      this.status = ConnectionStatus.Connecting
    }
  }

  /** Get debug text */
  get debugText(): string {
    // Check type
    if (this.mixers) {
      // Output debug text for the mixers
      return `space=${this.space.id} mixers=${this.mixers?.all?.length} active=${
        this.mixers?.active?.length
      } users=${this.listUsers().length}`
    } else {
      // Output debug text for P2P
      const countConnected = this.p2p?.connections.reduce(
        (prev: any, current: any) => prev + (current.isConnected ? 1 : 0),
        0
      )
      const countTotal = this.presenceSnapshot.length / 4
      return `space=${this.space.id} connected=${countConnected}/${countTotal}`
    }
  }

  /** Fetch latest presence data */
  presenceSnapshot: any[] = []
  _lastPresenceFetch = 0
  async fetchPresenceData() {
    // Skip if not in P2P mode, since the mixers don't use presence data
    if (!this.p2p) return

    // Only do every 30 seconds
    if (this._lastPresenceFetch && Date.now() - this._lastPresenceFetch < 30000) return
    this._lastPresenceFetch = Date.now()

    // Get all online users
    const snap = await getAllOnlineUsers(this.space.id, this.firebaseApp)

    // Pass it to P2P
    for (const doc of snap.docs) {
      // Parse values
      const data = doc.data()

      // Skip our own
      if (data.id == 'vatominc:' + this.userID) continue

      // Feed it to the P2P system
      this.p2p.peerDiscovered(data.id, data.presence_x, data.presence_y, data.presence_z)
    }
  }

  /** Update presence data */
  _lastPresenceUpdate = 0
  async updateMyPresenceData() {
    // Only do every 30 seconds
    if (this._lastPresenceUpdate && Date.now() - this._lastPresenceUpdate < 30000) return
    this._lastPresenceUpdate = Date.now()

    // Get new presence values
    await this.updateUserFields({
      presence_date: Date.now(),
      presence_id: 'space:' + this.space.id,
      presence_state: 'online',
      presence_description: `Online in @${this.space.alias || this.space.id}`,
      presence_alias: this.space.alias || '',
      presence_x: this.position.x,
      presence_y: this.position.y,
      presence_z: this.position.z,
      presence_geohash: `vsp.octree:${this.space.id}:${Octree.calculateLocationString(
        this.position.x,
        this.position.y,
        this.position.z
      )}`
    })
  }
}

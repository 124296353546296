import React from 'react'
import { Box, Text } from 'native-base'

type CampaignPointsProps = {
  points: {
    total: string
    [key: string]: string
  }
  position?: string
  title?: string
  channel?: string
  color: string
}

export function CampaignPoints({ points, title, channel, color }: CampaignPointsProps) {
  const defaultChannel = 'total'
  const currentChannel = channel || defaultChannel

  if (!points || !Object.keys(points).includes(currentChannel)) return null

  return (
    <Box>
      <Text
        color={color}
        fontFamily="Inter-SemiBold"
        fontSize={15}
        lineHeight={20}
        textTransform={'capitalize'}
      >
        {title
          ? `${title} ${points[currentChannel] || 0}`
          : `${points[currentChannel] || 0} ${currentChannel}`}
      </Text>
    </Box>
  )
}

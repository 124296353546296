import React from 'react'
// import { ImageSourcePropType } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { Contact, Destination, useTokenInventory } from '@vatom/sdk/react'
import { Avatar, theme } from '@vatom/wombo'
import { Box, Center, Icon, Image, Text } from 'native-base'

import { getTitle } from '../../ActionSheets/partials/ActionSheetHeader'
import PrimaryText from '../../ActionSheets/partials/PrimaryText'

interface SendNftDetailsProps {
  tokenid: string

  isShare: boolean
  destination?: Destination
  user?: Contact
}

export const SendNftDetails = ({ tokenid, user, isShare, destination }: SendNftDetailsProps) => {
  const { token } = useTokenInventory(tokenid)
  const titleNFT = token?.metadata.name
  const imageNft = token?.displayImage
  const nameUser = user?.name || destination?.value
  const imageUser = user?.avatarUrl
  const reduceName = (name?: string) => {
    if (name && name.length > 15) {
      return name.substring(0, 15) + '...'
    }
    return name
  }

  return token ? (
    <Box marginBottom="20px" justifyContent="center" alignItems="center">
      <PrimaryText color={'black'} text={`${isShare ? 'Share' : 'Send'} ${getTitle(token)}`} />

      <Text marginBottom="40px" fontFamily="Inter-Regular" fontSize="15px" textAlign="center">
        Are you sure you want to {isShare ? 'share' : 'send'} your {getTitle(token)},{' '}
        <Text fontFamily="Inter-SemiBold">{titleNFT}</Text>, {isShare ? 'with ' : 'to '}
        <Text fontFamily="Inter-SemiBold">{nameUser}</Text>?
      </Text>

      <Box flexDirection="row" width="100%" justifyContent="center">
        <Box alignItems="center" width="50%" position="relative">
          <Image
            accessibilityIgnoresInvertColors={true}
            source={{ uri: imageNft }}
            size="160px"
            borderRadius="8px"
            marginRight="7px"
            marginBottom="10px"
            alt="NFT"
          />
          <Text
            lineHeight="22px"
            fontFamily="Inter-SemiBold"
            fontSize="16px"
            maxW="160px"
            textAlign="center"
            _light={{
              color: theme.colors.textLightMode[900]
            }}
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
          >
            {titleNFT}
          </Text>
        </Box>

        <Box alignItems="center" width="50%" justifyContent="space-between">
          {imageUser && imageUser !== '' ? (
            <Image
              accessibilityIgnoresInvertColors={true}
              source={{ uri: imageUser }}
              size="160px"
              borderRadius="8px"
              marginBottom="10px"
              alt="user"
            />
          ) : (
            <Box size="160px" alignItems="center" justifyContent="center">
              <Avatar url={user?.avatarUrl} name={user?.name} size={55} fontSize="xl" />
            </Box>
          )}

          <Text
            lineHeight="22px"
            fontFamily="Inter-SemiBold"
            fontSize="16px"
            maxW="160px"
            textAlign="center"
            _light={{
              color: theme.colors.textLightMode[900]
            }}
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
          >
            {reduceName(nameUser)}
          </Text>
        </Box>

        <Box
          top="0px"
          size="160px"
          justifyItems="center"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          alignSelf="center"
          position="absolute"
        >
          <Center
            alignSelf="center"
            // position="absolute"
            width="48px"
            borderRadius="48px"
            height="48px"
            // mb="60px"
            mx={4}
            shadow={1}
            borderWidth={1}
            _light={{
              borderColor: theme.colors.grayCool[200],
              backgroundColor: theme.colors.white
            }}
            _dark={{
              borderColor: theme.colors.grayDarkMode[900],
              backgroundColor: theme.colors.grayDarkMode[700]
            }}
          >
            <Icon
              as={AntDesign}
              size="24px"
              name="arrowright"
              color={theme.colors.systemColorsLight.orange}
            />
          </Center>
        </Box>
      </Box>
    </Box>
  ) : null
}

export default SendNftDetails

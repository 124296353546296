import React, { useMemo } from 'react'
import { PressableOpacity, theme } from '@vatom/wombo'
import { AspectRatio, Box, HStack, Pressable, Text, VStack } from 'native-base'

const colorScheme = {
  light: {
    container: {
      borderColor: theme.colors.grayCool[200],
      backgroundColor: theme.colors.white
    },
    placeHolder: {
      backgroundColor: theme.colors.grayCool[100]
    }
  },
  dark: {
    container: {
      borderColor: theme.colors.grayDarkMode[700],
      backgroundColor: theme.colors.grayDarkMode[900]
    },
    placeHolder: {
      backgroundColor: theme.colors.grayDarkMode[800]
    }
  }
}

type TokenCardProps = React.PropsWithChildren<
  React.ComponentProps<typeof Pressable> & {
    containerProps?: React.ComponentProps<typeof Box>
  }
>

const TokenCard = ({
  children,
  onPress,
  width,
  containerProps,
  ...pressableProps
}: TokenCardProps) => {
  return (
    <Pressable
      accessibilityRole="button"
      onPress={onPress}
      width={width}
      borderRadius={4}
      borderWidth={1}
      marginBottom={2}
      maxWidth={'100%'}
      overflow={'hidden'}
      _light={colorScheme.light.container}
      _dark={colorScheme.dark.container}
      _web={{
        margin: 0
      }}
      {...pressableProps}
    >
      <VStack {...containerProps}>{children}</VStack>
    </Pressable>
  )
}

TokenCard.Media = ({
  children,
  ...aspectRatioProps
}: React.PropsWithChildren<React.ComponentProps<typeof AspectRatio>>) => (
  <AspectRatio
    width={'100%'}
    minWidth={'100%'}
    ratio={1}
    alignSelf={'center'}
    _light={colorScheme.light.container}
    _dark={colorScheme.dark.container}
    {...aspectRatioProps}
  >
    {children}
  </AspectRatio>
)

TokenCard.Content = ({
  children,
  ...boxProps
}: React.PropsWithChildren<React.ComponentProps<typeof VStack>>) => (
  <VStack padding={3} {...boxProps} alignItems={'flex-start'}>
    {children}
  </VStack>
)

TokenCard.Title = ({
  children,
  ...textProps
}: React.PropsWithChildren<React.ComponentProps<typeof Text>>) => (
  <Text
    numberOfLines={1}
    fontFamily={'Inter-SemiBold'}
    fontSize={16}
    lineHeight={22}
    _light={{
      color: theme.colors.textLightMode[900]
    }}
    _dark={{
      color: theme.colors.textDarkMode[100]
    }}
    {...textProps}
  >
    {children}
  </Text>
)
TokenCard.Subtitle = ({
  children,
  ...textProps
}: React.PropsWithChildren<React.ComponentProps<typeof Text>>) => (
  <Text
    marginTop={1}
    fontFamily={'Inter-Regular'}
    fontSize={13}
    lineHeight={18}
    _light={{
      color: theme.colors.textLightMode[300]
    }}
    _dark={{
      color: theme.colors.textDarkMode[600]
    }}
    height={4}
    {...textProps}
  >
    {children}
  </Text>
)

TokenCard.Footer = ({
  children,
  ...boxProps
}: React.PropsWithChildren<React.ComponentProps<typeof HStack>>) => (
  <HStack
    width={'100%'}
    marginTop={2}
    marginBottom={2}
    paddingTop={2}
    borderTopWidth={1}
    _light={{
      borderTopColor: theme.colors.grayCool[200]
    }}
    _dark={{
      borderTopColor: theme.colors.grayDarkMode[700]
    }}
    {...boxProps}
  >
    {children}
  </HStack>
)

type TokenCardLoadingProps = {
  width?: React.ComponentProps<typeof Box>['width']
  themed?: keyof typeof themeVariant
} & React.ComponentProps<typeof Box>

const themeVariant = {
  light: 'light',
  dark: 'dark'
} as const

const TokenCardLoading = ({
  width = 180,
  themed = themeVariant.light,
  ...boxProps
}: TokenCardLoadingProps) => {
  const colors = useMemo(() => colorScheme[themed], [themed])

  return (
    <Box
      width={width}
      margin={1}
      borderRadius={4}
      borderWidth={1}
      borderColor={colors.container.borderColor}
      backgroundColor={colors.container.backgroundColor}
      overflow={'hidden'}
      _web={{
        margin: 0
      }}
      {...boxProps}
    >
      <Box size={width} backgroundColor={colors.placeHolder.backgroundColor} />
      <Box padding={3} flexDirection={'column'}>
        <Box
          backgroundColor={colors.placeHolder.backgroundColor}
          width={'90%'}
          height={5}
          borderRadius={3}
        />
        <Box
          backgroundColor={colors.placeHolder.backgroundColor}
          width={'33%'}
          height={4}
          marginTop={2}
          borderRadius={3}
        />
      </Box>
    </Box>
  )
}

export { TokenCard, TokenCardLoading }

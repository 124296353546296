import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { LayoutChangeEvent, Platform, Pressable, StyleSheet, View } from 'react-native'
import { CommonActions } from '@react-navigation/native'
import { useGetSpace } from '@vatom/sdk/react'
import { Loader } from '@vatom/wombo'
import { Box, Text } from 'native-base'

import { Title } from '../../../components/Title'
import { withBusinessSelector } from '../../../hooks/useBusinessSelector'
import { useLayoutScrollHandler } from '../../../hooks/useLayoutScrollHandler'
import {
  AppRoutes,
  AppStackScreenProps,
  navigateToConnectScreen,
  RoomRoutes
} from '../../../navigators'
import CloseButton from '../components/AddModals/modal-components/modal-header-components/CloseButton'
import ModalTitle from '../components/AddModals/modal-components/modal-header-components/ModalTitle'
import ModalThreadReplies from '../components/AddModals/modal-components/ModalThreadReplies'
import ElipsisButton from '../components/ChatBubble/chat-components/header-components/ElipsisButton'
import { useIsMember } from '../hooks/useIsMember'
import { useMessage } from '../queries'
import { useCommunitiesTheme } from '../themes'
import { useCommunitySpace } from '../useCommunitySpace'

type ThreadViewProps = AppStackScreenProps<
  typeof AppRoutes.CommunitiesRoomThread | typeof AppRoutes.RoomThread
>

type MessageComponentProps = {
  onPress: () => void
}
const MessageComponent = ({ onPress }: MessageComponentProps) => {
  const communitiesTheme = useCommunitiesTheme()

  return (
    <Pressable accessibilityRole="button" onPress={onPress} style={styles.messageComponentWrapper}>
      <View
        style={{
          ...styles.messageComponent,
          backgroundColor: communitiesTheme.modalToolBar.messageComponent.background
        }}
      >
        <Text style={{ color: communitiesTheme.modalToolBar.messageComponent.text }}>Reply</Text>
      </View>
    </Pressable>
  )
}

const Thread = ({ navigation, route }: ThreadViewProps) => {
  const { spaceId, messageId, business } = route.params
  const { community } = useCommunitySpace()
  const space = useGetSpace(spaceId)
  const roomId = space.data?.matrixRoomId ?? ''
  const isMember = useIsMember({ roomId })

  const { getFooterHeight } = useLayoutScrollHandler()

  const message = useMessage(roomId, messageId, Boolean(isMember))
  const communitiesTheme = useCommunitiesTheme()
  const isWeb = Platform.OS === 'web'

  const [footerHeight, setFooterHeight] = useState(0)

  const redirectNonMember = useCallback(() => {
    const resetAction = CommonActions.reset({
      index: 1,
      routes: [
        {
          name: AppRoutes.BusinessProxy,
          params: {
            business
          }
        },
        {
          name: community ? AppRoutes.CommunitiesRoom : AppRoutes.Room,
          params: {
            spaceId,
            business,
            community: community ?? undefined
          }
        }
      ]
    })
    navigation.dispatch(resetAction)
  }, [business, community, navigation, spaceId])

  useEffect(() => {
    if (isMember === false) {
      setTimeout(redirectNonMember, 1500)
    }
  }, [isMember, redirectNonMember])

  const renderNonMember = useMemo(() => {
    if (isMember === false) {
      return null
    }
    return (
      <Box safeAreaTop margin="0 auto" paddingTop={4} flexDirection={'column'}>
        <Title preset="h5" textAlign={'center'}>
          You are not a member. Redirecting...
        </Title>
        <Pressable accessibilityRole="button" onPress={redirectNonMember}>
          <Text>If you are not redirected. Please click here</Text>
        </Pressable>
      </Box>
    )
  }, [isMember, redirectNonMember])

  const tabBarHeight = getFooterHeight()
  const replayHeight = isWeb ? 0 : tabBarHeight + footerHeight

  const onFooterLayout = (layout: LayoutChangeEvent) => {
    setFooterHeight(layout.nativeEvent.layout.height)
  }

  const onPressMessage = ({ spaceId, messageId }: { spaceId: string; messageId: string }) => {
    navigateToConnectScreen(navigation, RoomRoutes.RoomReply, {
      spaceId,
      business,
      community,
      messageId
    })
  }

  return message.status === 'success' ? (
    <Box flex={1} flexShrink={0} backgroundColor={communitiesTheme.background} safeAreaTop>
      <Box
        flexDirection={'row'}
        justifyContent={'space-between'}
        paddingY={2}
        backgroundColor={communitiesTheme.background}
        width={'100%'}
      >
        <Box alignSelf={'flex-start'} marginLeft={2}>
          <CloseButton iconType="back" />
        </Box>

        {message.data ? (
          <Box flex={1} justifyContent={'center'}>
            <ModalTitle title="" eventType={message.data.type} isThread />
          </Box>
        ) : null}
        <Box alignSelf={'flex-end'} marginRight={2}>
          <ElipsisButton
            spaceId={spaceId}
            messageId={messageId}
            buttonStyle={{ width: 32, height: 32 }}
            isThread
          />
        </Box>
      </Box>
      <Box flex={1} flexShrink={0}>
        <ModalThreadReplies spaceId={spaceId} message={message.data} />
      </Box>
      <Box flex={0} justifyContent={'flex-end'} marginBottom={replayHeight}>
        <Box
          paddingY={3}
          backgroundColor={communitiesTheme.modalToolBar.background}
          borderTopColor={communitiesTheme.modalToolBar.border}
          borderTopWidth={1}
          onLayout={onFooterLayout}
        >
          <MessageComponent onPress={() => onPressMessage({ spaceId, messageId })} />
        </Box>
      </Box>
    </Box>
  ) : (
    <Box flex={1} flexShrink={0} backgroundColor={communitiesTheme.background} safeAreaTop>
      {renderNonMember}
      <Loader />
    </Box>
  )
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    position: 'relative',
    paddingBottom: 10
  },
  messageComponentWrapper: {
    height: 40
  },
  messageComponent: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 16,
    borderRadius: 40,
    flexGrow: 1,
    marginHorizontal: 20
  }
})

const ThreadWithBusinessSelector = withBusinessSelector(Thread)
export default ThreadWithBusinessSelector

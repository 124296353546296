import {
  QueryClient,
  QueryFunctionContext,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import { allSettled, isFulfilled, isRejected } from '@vatom/sdk/react'
import { Alchemy, GetNftMetadataOptions, Network, Utils } from 'alchemy-sdk'
import { ethers } from 'ethers'

export const alchemyQueryKeys = {
  balances: [{ scope: 'balances', provider: 'alchemy' }] as const,
  balance: [{ scope: 'balance', provider: 'alchemy' }] as const,
  tokenMetadata: [{ scope: 'contract-metadata', provider: 'alchemy' }] as const,
  gasEstimates: [{ scope: 'gas-estimates', provider: 'alchemy' }] as const,
  transactionGasEstimates: [{ scope: 'transaction-gas-estimates', provider: 'alchemy' }] as const,
  transactionData: [{ scope: 'transaction-data', provider: 'alchemy' }] as const,
  NFTMetadata: [{ scope: 'nft-metadata', provider: 'alchemy' }],
  getAllTokenBalances: (addresses: string[], networks: Network[]) =>
    [
      {
        ...alchemyQueryKeys.balances[0],
        addresses,
        networks
      }
    ] as const,

  getTokenBalances: (address: string, network: Network) =>
    [{ ...alchemyQueryKeys.balances[0], address, network }] as const,
  getBalance: (address: string, network: Network) =>
    [{ ...alchemyQueryKeys.balance[0], address, network }] as const,
  getTokenMetadata: ({ contractAddress, network }: { contractAddress: string; network: Network }) =>
    [{ ...alchemyQueryKeys.tokenMetadata[0], contractAddress, network }] as const,
  getGasEstimates: ({
    toAddress,
    amount,
    network
  }: {
    toAddress: string
    amount: number
    network: Network
  }) => [{ ...alchemyQueryKeys.tokenMetadata[0], toAddress, amount, network }] as const,
  getTransactionGasEstimates: ({
    data,
    contractAddress,
    fromAddress,
    network
  }: {
    data: string
    contractAddress: string
    fromAddress: string
    network: Network
  }) =>
    [
      { ...alchemyQueryKeys.tokenMetadata[0], data, contractAddress, network, fromAddress }
    ] as const,
  getTransactionData: ({
    network,
    contractType,
    contractAddress,
    fromAddress,
    toAddress,
    tokenId,
    amount
  }: {
    network: Network
    contractType: string
    contractAddress: string
    fromAddress: string
    toAddress: string
    tokenId: string
    amount: number
  }) =>
    [
      {
        ...alchemyQueryKeys.tokenMetadata[0],
        network,
        contractType,
        contractAddress,
        fromAddress,
        toAddress,
        tokenId,
        amount
      }
    ] as const,
  getNFTMetadata: ({
    contractAddress,
    tokenId,
    network,
    options
  }: {
    contractAddress: string
    tokenId: string
    network: Network
    options?: GetNftMetadataOptions
  }) =>
    [{ ...alchemyQueryKeys.NFTMetadata[0], contractAddress, tokenId, network, options }] as const
} as const

export const vatomNetworkToAlchemyNetwork = {
  sepolia: Network.ETH_SEPOLIA
}

export const web3ChainListToAlchemyNetwork = {
  eth: Network.ETH_MAINNET,
  sepolia: Network.ETH_SEPOLIA,
  polygon: Network.MATIC_MAINNET,
  mumbai: Network.MATIC_MUMBAI
}

export const defaultNetworkSymbols = {
  [Network.ETH_MAINNET]: 'ETH',
  [Network.ETH_SEPOLIA]: 'SepoliaETH',
  [Network.OPT_MAINNET]: 'OPT',
  [Network.OPT_SEPOLIA]: 'SepoliaOPT',
  [Network.ARB_MAINNET]: 'ARB',
  [Network.ARB_SEPOLIA]: 'SepoliaARB',
  [Network.MATIC_MAINNET]: 'MATIC',
  [Network.MATIC_AMOY]: 'AMOY',
  [Network.BASE_MAINNET]: 'BASE',
  [Network.BASE_SEPOLIA]: 'SepoliaBASE',
  [Network.ZKSYNC_MAINNET]: 'ZKSYNC',
  [Network.ZKSYNC_SEPOLIA]: 'SepoliaZKSYNC'
}

export const defaultNetworks = [
  Network.ETH_MAINNET,
  Network.ETH_SEPOLIA,
  // Network.OPT_MAINNET,
  // Network.OPT_SEPOLIA,
  // Network.ARB_MAINNET,
  // Network.ARB_SEPOLIA,
  Network.MATIC_MAINNET,
  Network.MATIC_AMOY
  // Network.BASE_MAINNET,
  // Network.BASE_SEPOLIA,
  // Network.ZKSYNC_MAINNET,
  // Network.ZKSYNC_SEPOLIA
]

function getConfigForNetwork(network: Network) {
  const apiKey = 'umRvojxdI6ZY4IbRdJqUDgyG4sIghoAo'
  // const apiKey = '1qPmsG4QrGYKBsQGHTkz_5yiZEjvEp0i'
  // const polygonApiKey = 'I5FKB_z-z_BtWL1bIVsbcJv29HdV6NVJ'

  const config = {
    // apiKey: [
    //   Network.MATIC_MAINNET,
    //   Network.POLYGONZKEVM_MAINNET,
    //   Network.POLYGONZKEVM_TESTNET,
    //   Network.MATIC_MUMBAI
    // ].includes(network)
    //   ? polygonApiKey
    //   : apiKey,
    apiKey,

    network: network
  }
  return config
}

export function getAlchemyClient(network: Network) {
  const config = getConfigForNetwork(network)
  return new Alchemy(config)
}

type ContractMetadataCtx = QueryFunctionContext<
  ReturnType<typeof alchemyQueryKeys['getTokenMetadata']>
>

function fetchContractMetadata({ queryKey }: ContractMetadataCtx) {
  const [{ contractAddress, network }] = queryKey
  const alchemy = getAlchemyClient(network)
  return alchemy.core.getTokenMetadata(contractAddress)
}

export function useTokenMetadata(payload: { contractAddress: string; network: Network }) {
  return useQuery({
    queryKey: alchemyQueryKeys.getTokenMetadata(payload),
    queryFn: fetchContractMetadata
  })
}

type BalancesCtx = QueryFunctionContext<ReturnType<typeof alchemyQueryKeys['getTokenBalances']>>
type BalanceCtx = QueryFunctionContext<ReturnType<typeof alchemyQueryKeys['getBalance']>>
type GasEstimatesCtx = QueryFunctionContext<ReturnType<typeof alchemyQueryKeys['getGasEstimates']>>
type TransactionGasEstimatesCtx = QueryFunctionContext<
  ReturnType<typeof alchemyQueryKeys['getTransactionGasEstimates']>
>
type TransactionDataCtx = QueryFunctionContext<
  ReturnType<typeof alchemyQueryKeys['getTransactionData']>
>
type NFTMetadataCtx = QueryFunctionContext<ReturnType<typeof alchemyQueryKeys['getNFTMetadata']>>

async function fetchTokenBalances({ queryKey }: BalancesCtx) {
  const [{ address, network }] = queryKey

  const alchemy = getAlchemyClient(network)

  const allBalances = await alchemy.core.getTokenBalances(address)

  const nonZeroBalances = allBalances.tokenBalances.filter(token => {
    return Number(token.tokenBalance ?? 0) !== 0
  })

  return nonZeroBalances
}

async function fetchTokenBalance({ queryKey }: BalanceCtx) {
  const [{ address, network }] = queryKey

  const alchemy = getAlchemyClient(network)
  const balance = await alchemy.core.getBalance(address)

  if (Number(balance) <= 0) {
    throw new Error('Zero balance')
  }

  return {
    balance,
    address,
    network,
    /* @ts-expect-error */
    symbol: defaultNetworkSymbols[network]
  }
}

async function fetchGasEstimates({ queryKey }: GasEstimatesCtx) {
  const [{ toAddress, amount, network }] = queryKey

  const alchemy = getAlchemyClient(network)
  const gasEstimate = await alchemy.core.estimateGas({
    to: toAddress,
    value: Utils.parseEther(String(amount))
  })

  const fee = await alchemy.core.getFeeData()

  const gasPrice = await alchemy.core.getGasPrice()
  const estimatedTotal = gasEstimate.mul(gasPrice)

  return {
    gasLimit: Number(gasEstimate),
    maxPriorityFeePerGas: Number(fee.maxPriorityFeePerGas),
    maxFeePerGas: Number(fee.maxFeePerGas),
    estimatedTotal
  }
}

async function fetchTransactionData({ queryKey }: TransactionDataCtx) {
  const [{ network, contractAddress, contractType, fromAddress, toAddress, tokenId, amount }] =
    queryKey
  const alchemy = getAlchemyClient(network)
  const networkProvider = await alchemy.core.getNetwork()
  const provider = new ethers.AlchemyProvider(networkProvider, alchemy.config.apiKey)

  // ERC-1155 ABI
  // ERC-721 ABI
  const abi =
    contractType === 'erc1155'
      ? [
          'function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes data)'
        ]
      : ['function safeTransferFrom(address from, address to, uint256 tokenId)']

  const nftContract = new ethers.Contract(contractAddress, abi, provider)

  const data =
    contractType === 'erc1155'
      ? nftContract.interface.encodeFunctionData('safeTransferFrom', [
          fromAddress,
          toAddress,
          tokenId,
          amount,
          '0x'
        ])
      : nftContract.interface.encodeFunctionData('safeTransferFrom', [
          fromAddress,
          toAddress,
          tokenId
        ])

  return data
}

async function fetchTransactionGasEstimates({ queryKey }: TransactionGasEstimatesCtx) {
  const [{ contractAddress, data, fromAddress, network }] = queryKey

  const alchemy = getAlchemyClient(network)
  const gasPrice = await alchemy.core.getGasPrice()

  const gasEstimate = await alchemy.core.estimateGas({
    to: contractAddress,
    from: fromAddress,
    data
  })

  return {
    gasPrice,
    gasEstimate
  }
}

async function fetchNFTMetadata({ queryKey }: NFTMetadataCtx) {
  const [{ contractAddress, tokenId, network, options }] = queryKey

  return getNFTMetadata(contractAddress, tokenId, network, options)
}

export async function getNFTMetadata(
  contractAddress: string,
  tokenId: string,
  network: Network,
  options?: GetNftMetadataOptions
) {
  const alchemy = getAlchemyClient(network)
  const nftMetadata = await alchemy.nft.getNftMetadata(contractAddress, tokenId, options)

  return nftMetadata
}

export function useTokenBalances({ address, network }: { address: string; network: Network }) {
  return useQuery({
    queryKey: alchemyQueryKeys.getTokenBalances(address, network),
    queryFn: fetchTokenBalances
  })
}

type AllBalancesCtx = QueryFunctionContext<
  ReturnType<typeof alchemyQueryKeys['getAllTokenBalances']>
>

type TokenBalanceQueryOptions = {
  address: string
  network: Network
  contractAddress?: string
}

export const useTokenBalanceQuery = ({
  address,
  network,
  contractAddress
}: TokenBalanceQueryOptions) => {
  const tokenBalance = useQuery({
    queryKey: alchemyQueryKeys.getTokenBalances(address, network),
    queryFn: fetchTokenBalances,
    select: balances => {
      return balances.find(balance => balance.contractAddress === contractAddress)
    },
    enabled: !!contractAddress
  })

  const tokenMetadata = useQuery({
    queryKey: alchemyQueryKeys.getTokenMetadata({
      contractAddress: tokenBalance.data?.contractAddress ?? '',
      network
    }),
    queryFn: fetchContractMetadata,
    enabled: !!tokenBalance.data
  })

  const addressBalance = useQuery({
    queryKey: alchemyQueryKeys.getBalance(address, network),
    queryFn: fetchTokenBalance,
    enabled: !!contractAddress === false,
    select: balance => {
      return {
        balance,
        /* @ts-expect-error */
        symbol: defaultNetworkSymbols[network]
      }
    }
  })

  return {
    tokenBalance,
    tokenMetadata,
    addressBalance,
    network,
    address
  }
}

function getAllBalancesFn(queryClient: QueryClient) {
  return async ({ queryKey }: AllBalancesCtx) => {
    const [{ addresses, networks }] = queryKey
    // Flat list of request for each address and network we do push to the queryClient to hydrate the cache
    const promisesPerAddress = addresses.flatMap(address => {
      const promisesPerNetworks = networks.flatMap(async network => {
        const balanceQueryKey = alchemyQueryKeys.getTokenBalances(address, network)
        const allBalancesForNetwork = await queryClient.ensureQueryData({
          queryKey: balanceQueryKey,
          queryFn: fetchTokenBalances,
          staleTime: 0,
          cacheTime: 0
        })

        const metadataPromises = allBalancesForNetwork.flatMap(async tokenBalance => {
          const tokenMetadataQueryKey = alchemyQueryKeys.getTokenMetadata({
            contractAddress: tokenBalance.contractAddress,
            network
          })

          const tokenMetadata = await queryClient.ensureQueryData({
            queryKey: tokenMetadataQueryKey,
            queryFn: fetchContractMetadata,
            staleTime: 0,
            cacheTime: 0
          })

          return {
            tokenBalance,
            tokenMetadata,
            network,
            address
          }
        })

        const allResults = await allSettled(metadataPromises)
        const fulfilledResults = allResults.filter(isFulfilled).map(result => result.value)
        const rejectedResults = allResults.filter(isRejected)

        rejectedResults.forEach(result => {
          console.error('Error fetching token balances', result.reason)
        })

        return fulfilledResults
      })

      return promisesPerNetworks
    })

    const allResults = await allSettled(promisesPerAddress)
    const fulfilledResults = allResults.filter(isFulfilled).flatMap(result => result.value)
    const rejectedResults = allResults.filter(isRejected)

    rejectedResults.forEach(result => {
      console.error('Error fetching token balances', result.reason)
    })
    return fulfilledResults
  }
}

type AllBalancesQueryFn = ReturnType<typeof getAllBalancesFn>
export type AllBalances = Awaited<ReturnType<AllBalancesQueryFn>>

type AllTokenBalancesQueryOptions<T = AllBalances> = Omit<
  UseQueryOptions<AllBalances, unknown, T, ReturnType<typeof alchemyQueryKeys.getAllTokenBalances>>,
  'queryKey' | 'queryFn'
>

export type AddressBalance = Awaited<ReturnType<typeof fetchTokenBalance>>
type AddressBalanceQueryOptions<T = AddressBalance> = Omit<
  UseQueryOptions<AddressBalance, unknown, T, ReturnType<typeof alchemyQueryKeys.getBalance>>,
  'queryKey' | 'queryFn'
>

export type GasEstimates = Awaited<ReturnType<typeof fetchGasEstimates>>
type GetEstimatesQueryOptions<T = GasEstimates> = Omit<
  UseQueryOptions<GasEstimates, unknown, T, ReturnType<typeof alchemyQueryKeys.getGasEstimates>>,
  'queryKey' | 'queryFn'
>

export type TransactionGasEstimates = Awaited<ReturnType<typeof fetchTransactionGasEstimates>>
type GetTransactionEstimatesQueryOptions<T = TransactionGasEstimates> = Omit<
  UseQueryOptions<
    TransactionGasEstimates,
    unknown,
    T,
    ReturnType<typeof alchemyQueryKeys.getTransactionGasEstimates>
  >,
  'queryKey' | 'queryFn'
>

export type TransactionData = Awaited<ReturnType<typeof fetchTransactionData>>
type GetTransactionDataQueryOptions<T = TransactionData> = Omit<
  UseQueryOptions<
    TransactionData,
    unknown,
    T,
    ReturnType<typeof alchemyQueryKeys.getTransactionData>
  >,
  'queryKey' | 'queryFn'
>

export type NFTMetadata = Awaited<ReturnType<typeof fetchNFTMetadata>>
type NFTMetadataQueryOptions<T = NFTMetadata> = Omit<
  UseQueryOptions<NFTMetadata, unknown, T, ReturnType<typeof alchemyQueryKeys.getNFTMetadata>>,
  'queryKey' | 'queryFn'
>

type AllBalancesPayload = {
  addresses: string[]
  networks: Network[]
}

type GasEstimatesPayload = {
  toAddress: string
  amount: number
  network: Network
}

type TransactionGasEstimatesPayload = {
  data?: string
  contractAddress: string
  fromAddress: string
  network: Network
}

type NFTMetadataPayload = {
  contractAddress: string
  tokenId: string
  network: Network
  options?: GetNftMetadataOptions
}

type TransactionDataPayload = {
  network: Network
  contractType: string
  contractAddress: string
  fromAddress: string
  toAddress: string
  tokenId: string
  amount: number
}

export function useAllTokenBalances<T = AllBalances>(
  { addresses, networks }: AllBalancesPayload,
  options?: AllTokenBalancesQueryOptions<T>
) {
  const queryClient = useQueryClient()

  const query = useQuery({
    queryKey: alchemyQueryKeys.getAllTokenBalances(addresses, networks),
    queryFn: getAllBalancesFn(queryClient),
    ...options
  })

  return query
}

export function useAddressesBalance<T = AddressBalance>(
  { addresses, networks }: AllBalancesPayload,
  options?: AddressBalanceQueryOptions<T>
) {
  return useQueries({
    queries: addresses.flatMap(address =>
      networks.map(network => ({
        queryKey: alchemyQueryKeys.getBalance(address, network),
        queryFn: fetchTokenBalance,
        ...options
      }))
    )
  }) as UseQueryResult<T, unknown>[]
}

export function useGasEstimates<T = GasEstimates>(
  { toAddress, amount, network }: GasEstimatesPayload,
  options?: GetEstimatesQueryOptions<T>
) {
  return useQuery({
    queryKey: alchemyQueryKeys.getGasEstimates({ toAddress, amount, network }),
    queryFn: fetchGasEstimates,
    enabled: !!toAddress,
    ...options
  })
}

export function useTransactionGasEstimates<T = TransactionGasEstimates>(
  { fromAddress, data, contractAddress, network }: TransactionGasEstimatesPayload,
  options?: GetTransactionEstimatesQueryOptions<T>
) {
  return useQuery({
    queryKey: alchemyQueryKeys.getTransactionGasEstimates({
      fromAddress,
      data: data ?? '',
      contractAddress,
      network
    }),
    queryFn: fetchTransactionGasEstimates,
    enabled: !!data,
    ...options
  })
}

export function useTransactionData<T = TransactionData>(
  {
    network,
    contractType,
    contractAddress,
    fromAddress,
    toAddress,
    tokenId,
    amount
  }: TransactionDataPayload,
  options?: GetTransactionDataQueryOptions<T>
) {
  return useQuery({
    queryKey: alchemyQueryKeys.getTransactionData({
      network,
      contractType,
      contractAddress,
      fromAddress,
      toAddress,
      tokenId,
      amount
    }),
    queryFn: fetchTransactionData,
    ...options
  })
}

export function useNFTMetadata<T = NFTMetadata>(
  { contractAddress, tokenId, network, options }: NFTMetadataPayload,
  queryOptions?: NFTMetadataQueryOptions<T>
) {
  return useQuery({
    queryKey: alchemyQueryKeys.getNFTMetadata({ contractAddress, tokenId, network, options }),
    queryFn: fetchNFTMetadata,
    ...queryOptions
  })
}

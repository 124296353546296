import React, { useCallback } from 'react'
import { StyleSheet, Text } from 'react-native'
import { CommonActions, TabActions, useNavigation } from '@react-navigation/native'
import {
  getMatrixUser,
  useCreateLocalRoom,
  useGetDmRoomBetweenUsers,
  UserData,
  useRelFollowContact,
  useRelGetFollowing,
  useRelUnfollowContact
} from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { colors } from '@vatom/wombo'
import { Box, Pressable } from 'native-base'

import { AppRoutes, TabRoutes } from '../../../navigators'
import { useDebugMode } from '../../../utils/useDebugMode'
import { useCommunitiesTheme } from '../../Communities/themes'
import FollowIcon from '../assets/follow.svg'
import FollowingIcon from '../assets/following.svg'
import MessageIcon from '../assets/message.svg'

interface IProfileActionsProps {
  userId: string
  user: UserData
}

const ProfileActions = ({ userId, user }: IProfileActionsProps) => {
  const navigation = useNavigation()
  const communitiesTheme = useCommunitiesTheme()
  console.log(
    'LOG: > ProfileActions > communitiesTheme:',
    communitiesTheme,
    communitiesTheme?.memberActionButtons
  )

  const debugMode = useDebugMode()
  const { createLocalRoom } = useCreateLocalRoom()
  const { getDmRoomBetweenUsers } = useGetDmRoomBetweenUsers()

  const following = useRelGetFollowing({
    select: contacts => {
      return contacts.find(c => c.value === userId)
    }
  })
  const followMutation = useRelFollowContact()
  const unfollowMutation = useRelUnfollowContact()

  const followOrUnfollow = () => {
    if (following.data) {
      unfollowMutation.mutate(following.data)
    } else {
      followMutation.mutate({
        type: 'user',
        value: userId
      })
    }
  }

  const onPressMessage = useCallback(async () => {
    const userIds = [userId]
    const foundRoomForUsers = getDmRoomBetweenUsers(userIds)

    let roomIdForNavigation = foundRoomForUsers
    if (!roomIdForNavigation) {
      const members = [
        {
          userId: getMatrixUser(userId),
          displayname: user.name,
          avatar_url: user.picture
        }
      ]
      //Create local room
      roomIdForNavigation = createLocalRoom({ members })
    }

    navigation.dispatch(CommonActions.goBack())
    navigation.dispatch(TabActions.jumpTo(TabRoutes.DMs))
    navigation.dispatch(
      CommonActions.navigate(AppRoutes.DmScreen, {
        roomId: roomIdForNavigation
      })
    )
    return
  }, [createLocalRoom, getDmRoomBetweenUsers, navigation, user, userId])

  return (
    <Box
      flexDirection={'row'}
      flex={1}
      alignItems={'center'}
      justifyContent={'space-between'}
      marginX={4}
      borderRadius={4}
      marginY="20px"
      _web={{
        minWidth: 360
      }}
    >
      {debugMode && (
        <Pressable
          accessibilityRole="button"
          style={[
            styles.memberAction,
            {
              backgroundColor: 'transparent',
              borderColor: communitiesTheme?.memberActionButtons__OUTLINE?.border,
              marginRight: 16
            }
          ]}
          onPress={() => onPressMessage()}
        >
          <MessageIcon
            style={{ width: 16, height: 16, marginRight: 4 }}
            fill={communitiesTheme?.memberActionButtons__OUTLINE?.text ?? '#3F4A55'}
          />
          <Text
            style={[styles.text, { color: communitiesTheme.memberActionButtons__OUTLINE.text }]}
          >
            Message
          </Text>
        </Pressable>
      )}

      <Pressable
        accessibilityRole="button"
        style={[
          styles.memberAction,
          {
            backgroundColor: following.data
              ? 'transparent'
              : communitiesTheme.memberActionButtons.backgroundColor
          },
          { borderColor: communitiesTheme?.memberActionButtons?.border }
        ]}
        onPress={followOrUnfollow}
      >
        {following.data ? (
          <>
            <FollowingIcon style={{ width: 16, height: 16, marginRight: 4 }} fill={'#3F4A55'} />
            <Text style={[styles.text, { color: '#3F4A55' }]}>{translate('common.following')}</Text>
          </>
        ) : (
          <>
            <FollowIcon
              style={{ width: 16, height: 16, marginRight: 4 }}
              fill={communitiesTheme?.memberActionButtons?.text ?? '#3F4A55'}
            />
            <Text style={[styles.text, { color: communitiesTheme.memberActionButtons.text }]}>
              {translate('common.follow')}
            </Text>
          </>
        )}
      </Pressable>
    </Box>
  )
}

export default ProfileActions

const styles = StyleSheet.create({
  memberAction: {
    backgroundColor: 'white',
    paddingVertical: 11,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#CED4DA',
    borderRadius: 3,
    flexDirection: 'row',
    flex: 1
  },
  text: {
    fontSize: 13,
    lineHeight: 18,
    fontWeight: '500',
    color: '#3F4A55'
  }
})

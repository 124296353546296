import React, { useCallback, useState } from 'react'
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { useSpace } from '@vatom/sdk/react'
import { PressableOpacity, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Text, View } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import { useLayoutScrollHandler } from '../../hooks/useLayoutScrollHandler'
import { AppNavigation, AppRoutes } from '../../navigators'
import { TabBarHeight } from '../../screens/Home/components/TabBar'
import { StatusIcon } from '../../screens/Space/StatusIcon'

import { EndCallButton, MicButton, PeopleButton, SpeakerButton } from '.'

const CallControlMini = observer(() => {
  const insets = useSafeAreaInsets()
  const navigation = useNavigation<AppNavigation>()
  const [layoutHeight, setLayoutHeight] = useState(0)

  const {
    setHeaderHeight,
    getHeaderHeight,
    shouldHideElements,
    defaultAnimationDuration,
    defaultAnimationEasing
  } = useLayoutScrollHandler()

  const { inCall, space, callBackgroundColor, connectionStatus, getHasCurrentSession } = useSpace()

  const hasCurrentSession = getHasCurrentSession()

  const spaceTitle = space?.displayName ?? ''

  const openSpace = useCallback(
    (spaceId: string) => {
      navigation.navigate(AppRoutes.Metaverse, { spaceId, type: 'call' })
    },
    [navigation]
  )

  const onOpenSpace = useThrottledCallback(openSpace, 300)

  const animatedStyle = useAnimatedStyle(() => {
    const yOffset = shouldHideElements.value ? TabBarHeight : 0
    return {
      transform: [
        {
          translateY: withTiming(yOffset, {
            duration: defaultAnimationDuration,
            easing: defaultAnimationEasing
          })
        }
      ]
    }
  })

  if (!space && !hasCurrentSession) return null

  return (
    <Animated.View
      style={[
        {
          zIndex: 100,
          position: 'absolute',
          width: '100%',
          bottom: insets.bottom + getHeaderHeight() - 14
        },
        animatedStyle
      ]}
      onLayout={layout => setHeaderHeight(layout.nativeEvent.layout.height)}
    >
      <Box
        borderTopColor={theme.colors.brand[500]}
        borderTopWidth={2}
        flexDirection="row"
        padding={2}
        height={60}
        paddingRight="18px"
        paddingLeft="18px"
        background="white"
        alignItems="center"
        borderBottomColor={theme.colors.grayCool[300]}
        borderBottomWidth={1}
      >
        {!inCall ? (
          <View
            backgroundColor={callBackgroundColor}
            borderRadius={30}
            justifyContent="center"
            alignItems="center"
            w="40px"
            h="40px"
            mr="10px"
          >
            <StatusIcon status={connectionStatus} width={40} height={40} />
          </View>
        ) : (
          <>
            <MicButton w="40px" h="40px" mr="10px" secondary />
            <SpeakerButton w="40px" h="40px" mr="10px" secondary />
          </>
        )}

        <PressableOpacity
          flex={1}
          alignSelf={'stretch'}
          justifyContent="center"
          onPress={() => onOpenSpace(space?.id ?? '')}
        >
          <Text
            numberOfLines={2}
            style={{
              color: '#262626',
              fontFamily: 'Inter-SemiBold',
              fontWeight: '600'
            }}
          >
            {spaceTitle}
          </Text>
        </PressableOpacity>

        <PeopleButton secondary />
        <EndCallButton secondary ml="20px" />
      </Box>
    </Animated.View>
  )
})

export default CallControlMini

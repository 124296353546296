import { Route, useIsFocused } from '@react-navigation/native'
import { LoaderView } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'

import { AppStackParamList } from '../../navigators'

import { useAuth } from './useAuth'

export interface ILoginCallback {
  route: Route<string, AppStackParamList['LoginCallback']>
}

export const LoginCallback = observer(({ route }: ILoginCallback) => {
  const focused = useIsFocused()
  useAuth(route.params.code)

  if (!focused) {
    return null
  }

  return <LoaderView />
})

export default LoginCallback

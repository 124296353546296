import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const VideoIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 32 32" size={size} {...iconProps}>
    <G clipPath="url(#clip0_15474_14625)">
      <Path
        fill={fill}
        d="M28.1016 10.0469C28.5742 10.3047 28.875 10.7344 28.875 11.25V22.25C28.875 22.7656 28.5742 23.2383 28.1016 23.4961C27.9297 23.582 27.7148 23.625 27.5 23.625C27.1992 23.625 26.9414 23.582 26.6836 23.4102L22.2578 20.3164C21.957 20.1016 21.8711 19.6719 22.0859 19.3281C22.3008 19.0273 22.7305 18.9414 23.0742 19.1562L27.5 22.25V11.25L23.0742 14.3438C22.7305 14.5586 22.3008 14.5156 22.0859 14.2148C21.8711 13.8711 21.957 13.4414 22.2578 13.2266L26.6836 10.1328C27.1133 9.83203 27.6719 9.83203 28.1016 10.0469ZM17.875 8.5C19.3789 8.5 20.625 9.74609 20.625 11.25V22.25C20.625 23.7969 19.3789 25 17.875 25H6.875C5.32812 25 4.125 23.7969 4.125 22.25V11.25C4.125 9.74609 5.32812 8.5 6.875 8.5H17.875ZM19.25 22.25V11.25C19.25 10.5195 18.6055 9.875 17.875 9.875H6.875C6.10156 9.875 5.5 10.5195 5.5 11.25V22.25C5.5 23.0234 6.10156 23.625 6.875 23.625H17.875C18.6055 23.625 19.25 23.0234 19.25 22.25Z"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_15474_14625">
        <Rect width="32" height="32" fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
)

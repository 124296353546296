import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const CopyIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 17 16" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M9.5 13C9.75 13 10 13.25 10 13.5V14C10 15.125 9.09375 16 8 16H3C1.875 16 1 15.125 1 14L0.96875 6C0.96875 4.90625 1.875 4 2.96875 4H6.5C6.75 4 7 4.25 7 4.5C7 4.78125 6.75 5 6.5 5H3C2.4375 5 2 5.46875 2 6V14C2 14.5625 2.4375 15 3 15H8C8.53125 15 9 14.5625 9 14V13.5C9 13.25 9.21875 13 9.5 13ZM16.6875 2.71875C16.875 2.90625 17 3.15625 17 3.4375V10C17 11.125 16.0938 12 15 12H10C8.875 12 8 11.125 8 10V2C8 0.90625 8.875 0 10 0H13.5625C13.8438 0 14.0938 0.125 14.2812 0.3125L16.6875 2.71875ZM14 1.4375V3H15.5625L14 1.4375ZM16 10V4H14C13.4375 4 13 3.5625 13 3V1H10C9.4375 1 9 1.46875 9 2V10C9 10.5625 9.4375 11 10 11H15C15.5312 11 16 10.5625 16 10Z"
    />
  </Icon>
)

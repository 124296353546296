import React, { useCallback } from 'react'
import { Platform } from 'react-native'
import { NavigationProp, Route, useNavigation } from '@react-navigation/native'
import { useBackHandler, useBusiness } from '@vatom/sdk/react'
import { LoaderView, PressableOpacity, Text, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Center, KeyboardAvoidingView } from 'native-base'

import { HideKeyboard } from '../../components/HideKeyboard'
import { ScreenHeader } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useFungibleToken } from '../../hooks/useFungibleTokens'
import { useLoyalty } from '../../hooks/useLoyalty'
import {
  CoinNavigatorParamList,
  CoinStackParamList,
  CoinStackRoutes
} from '../CoinNavigation/CoinNavigation'
import { useCoinStore } from '../CoinNavigation/useCoinStore'

import { useCoinTheme } from './hooks/useCoinTheme'
import { TransactionHistory } from './partials/TransactionHistory'
import { formatAmount, formatName } from './utils/formatString'
import { CoinAvatar } from './partials'

const CoinBalance = ({
  amount,
  name,
  textProps
}: {
  amount: number
  name: string
  textProps?: React.ComponentProps<typeof Text>
}) => (
  <Center mt={4}>
    <Text fontSize={17} lineHeight={24} fontFamily={'Inter-Bold'} fontWeight={'700'} {...textProps}>
      {`${formatAmount(amount)} ${formatName(name)}`}
    </Text>
  </Center>
)

export interface ICoinDetailsProps {
  route: Route<string, CoinStackParamList['CoinDetail']>
  navigation: NavigationProp<CoinNavigatorParamList>
}

const CoinDetail = observer(({ route, navigation: coinNavigation }: ICoinDetailsProps) => {
  const navigation = useNavigation()
  const { coinId } = route.params
  const { setCoinId, setBusinessId } = useCoinStore()
  const fungibleToken = useFungibleToken(coinId)

  const setCoinIdAndBusinessId = useCallback(
    (coinId: string, businessId: string) => {
      setCoinId(coinId)
      setBusinessId(businessId)
    },
    [setBusinessId, setCoinId]
  )

  const { getCoinById } = useLoyalty()

  const coin = getCoinById(coinId) || fungibleToken

  const { data: business, isLoading } = useBusiness({ businessId: coin?.businessId })

  const styles = useCoinTheme({ business })

  // const networkImage = business?.logoSrc
  // const networkName = business?.displayName
  const coinImage = coin?.logo
  const coinName = coin?.name
  const coinBalance = coin?.points ?? 0

  useBackHandler(() => {
    onClose()
    return true
  })

  const onClose = () => {
    navigation.goBack()
  }

  const onSendCoin = () => {
    if (business?.id) {
      setCoinIdAndBusinessId(coinId, business.id)
      coinNavigation.navigate(CoinStackRoutes.CoinTransfer)
    }
  }

  if (isLoading && !coin?.isWeb3) {
    return <LoaderView />
  }

  return (
    <ScreenWrapper
      screenBackgroundColor={styles.background}
      statusBarBackgroundColor={styles.primary}
    >
      <ScreenHeader
        headerLeftPress={onClose}
        headerTextColor={styles.primaryText}
        backdropProps={{
          backgroundColor: styles.primary
        }}
      />

      <HideKeyboard>
        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
          <Box
            px={4}
            position={'relative'}
            zIndex={1}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            {coinImage && (
              <CoinAvatar
                // Coin
                source={coinImage}
                size={100}
                name={coinName ?? ''}
                containerProps={{ zIndex: 0 }}
              />
            )}
            {/* {networkImage && (
              <CoinAvatar
                // Network
                source={networkImage}
                size={32}
                name={networkImage && networkName ? networkName : ''}
                containerProps={{ zIndex: 1, position: 'absolute', bottom: 0, right: 0 }}
              />
            )} */}
          </Box>
          <CoinBalance
            amount={coinBalance}
            name={coinName || ''}
            textProps={{
              color: styles.textColor
            }}
          />

          <PressableOpacity
            accessibilityRole="button"
            onPress={onSendCoin}
            mt={25}
            py={3}
            mx={4}
            borderWidth={1}
            borderRadius={3}
            _light={{
              borderColor: theme.colors.grayCool[400]
            }}
            _dark={{
              borderColor: theme.colors.grayDarkMode[500]
            }}
          >
            <Center>
              <Text
                color={styles.textColor}
                fontSize={13}
                lineHeight={18}
                fontFamily={'Inter-Regular'}
                fontWeight="500"
                tx="coinsScreen.sendCoin"
                txOptions={{ coinName: coinName ?? '' }}
              />
            </Center>
          </PressableOpacity>
        </KeyboardAvoidingView>
      </HideKeyboard>
      <TransactionHistory
        businessId={coin?.businessId}
        chain={coin?.chain}
        address={coin?.address}
      />
    </ScreenWrapper>
  )
})

export default CoinDetail

import { forwardRef, useEffect } from 'react'
import { Platform, Pressable, useWindowDimensions, View } from 'react-native'
import Animated, {
  Easing,
  SlideInDown,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withTiming
} from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Divider } from 'native-base'
import { useDebouncedCallback } from 'use-debounce'

import logger from '../../../logger'
import { AppRoutes, AppStackScreenProps } from '../../../navigators'
import { NftFilter } from '../Filters/NftFilter'

const ANIMATION_DURATION = 300
const isWeb = Platform.OS === 'web'

type NftFiltersModalProps = AppStackScreenProps<typeof AppRoutes.nftFilters>

export const NftFiltersModal = observer(
  forwardRef((props: NftFiltersModalProps, ref) => {
    const insets = useSafeAreaInsets()
    logger.info('[NftFiltersModal.render]')
    const navigation = useNavigation()
    const dismissModal = () => {
      navigation.goBack()
    }

    const dismissModalHandler = useDebouncedCallback(dismissModal, 50, {
      leading: true,
      trailing: false
    })

    const opacityValue = useSharedValue(0)

    const windowDimension = useWindowDimensions()
    // Get the screen height
    const screenHeight = windowDimension.height
    // 30% of the screen height is the overlay
    const overlayHeight = screenHeight * 0.3
    // 70% of the screen height is the modal
    const modalHeight = screenHeight * 0.7

    useEffect(() => {
      opacityValue.value = withDelay(ANIMATION_DURATION, withTiming(1, { duration: 150 }))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const animatedStyles = useAnimatedStyle(() => ({
      opacity: opacityValue.value
    }))

    return (
      <Animated.View entering={SlideInDown.duration(ANIMATION_DURATION).easing(Easing.ease)}>
        <Box ref={ref} paddingBottom={insets.bottom}>
          <Pressable accessibilityRole="button" onPress={dismissModalHandler}>
            <Animated.View
              style={[
                {
                  backgroundColor: !isWeb ? 'rgba(0,0,0,0.3)' : undefined,
                  minHeight: overlayHeight
                },
                animatedStyles
              ]}
            />
          </Pressable>
          <Box
            style={{
              top: -10,
              borderRadius: 10,
              overflow: 'hidden',
              shadowColor: 'black',
              shadowOffset: { width: 2, height: -1 },
              shadowRadius: 1,
              shadowOpacity: 0.1,
              elevation: 1,
              height: modalHeight
            }}
            _light={{
              backgroundColor: theme.colors.white
            }}
            _dark={{
              backgroundColor: theme.colors.grayDarkMode[900]
            }}
          >
            <View style={{ alignItems: 'center', justifyContent: 'center', height: 20 }}>
              <Divider
                height="2px"
                backgroundColor="dark.300"
                _light={{
                  backgroundColor: theme.colors.grayCool[500]
                }}
                _dark={{
                  backgroundColor: theme.colors.grayDarkMode[500]
                }}
                width={'24%'}
              />
            </View>
            <NftFilter
              setOpen={(shouldOpen: boolean) => {
                logger.info('[NftFiltersModal] setOpen')
                if (shouldOpen === false) {
                  dismissModal()
                }
              }}
              isOpen={true}
            />
          </Box>
        </Box>
      </Animated.View>
    )
  })
)

export default NftFiltersModal

import React from 'react'
import { WalletPreferenceSortBy } from '@vatom/sdk/core'
import { observer } from 'mobx-react-lite'
import { Box, Pressable, Radio, Text } from 'native-base'

import FullScreenDivider from './FullScreenDivider'

interface ISortByData {
  key: WalletPreferenceSortBy
  label: string
}

type Props = {
  data: ISortByData[]
  onItemPress: (i: WalletPreferenceSortBy) => void
  currentlySelectedValue: string
}

interface RadioListItemProps {
  item: ISortByData
  key: string
}

export const RadioList = observer(({ data, onItemPress, currentlySelectedValue }: Props) => {
  const RenderRadioListItem = ({ item, key }: RadioListItemProps) => {
    return (
      <Pressable accessibilityRole="button" onPress={() => onItemPress(item.key)}>
        <Box
          h="48px"
          width="100%"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text fontFamily="Inter-Regular" fontSize="15px" lineHeight="20px">
            {item.label}
          </Text>
          <Radio key={key} boxSize="20px" value={item.key} />
        </Box>
        <FullScreenDivider />
      </Pressable>
    )
  }
  return (
    <Radio.Group
      value={currentlySelectedValue}
      onChange={value =>
        onItemPress(data.find(v => v.key === value)?.key ?? WalletPreferenceSortBy.Newest)
      }
      name="RadioList"
    >
      {data.map((item, index) => {
        return <RenderRadioListItem key={`radio-group-${item.key}`} item={item} />
      })}
    </Radio.Group>
  )
})

export default RadioList

/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { NftModel, NftModelType } from "./NftModel"
import { NftModelSelector } from "./NftModel.base"
import { TwitterProfileModel, TwitterProfileModelType } from "./TwitterProfileModel"
import { TwitterProfileModelSelector } from "./TwitterProfileModel.base"
import { RootStoreType } from "./index"


/**
 * StoreCreatorBase
 * auto generated base class for the model StoreCreatorModel.
 */
export const StoreCreatorModelBase = ModelBase
  .named('StoreCreator')
  .props({
    __typename: types.optional(types.literal("StoreCreator"), "StoreCreator"),
    storeConfigAddress: types.union(types.undefined, types.string),
    creatorAddress: types.union(types.undefined, types.string),
    twitterHandle: types.union(types.undefined, types.null, types.string),
    preview: types.union(types.undefined, types.array(types.late((): any => NftModel))),
    profile: types.union(types.undefined, types.null, types.late((): any => TwitterProfileModel)),
    nftCount: types.union(types.undefined, types.null, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class StoreCreatorModelSelector extends QueryBuilder {
  get storeConfigAddress() { return this.__attr(`storeConfigAddress`) }
  get creatorAddress() { return this.__attr(`creatorAddress`) }
  get twitterHandle() { return this.__attr(`twitterHandle`) }
  get nftCount() { return this.__attr(`nftCount`) }
  preview(builder: string | NftModelSelector | ((selector: NftModelSelector) => NftModelSelector) | undefined) { return this.__child(`preview`, NftModelSelector, builder) }
  profile(builder: string | TwitterProfileModelSelector | ((selector: TwitterProfileModelSelector) => TwitterProfileModelSelector) | undefined) { return this.__child(`profile`, TwitterProfileModelSelector, builder) }
}
export function selectFromStoreCreator() {
  return new StoreCreatorModelSelector()
}

export const storeCreatorModelPrimitives = selectFromStoreCreator().storeConfigAddress.creatorAddress.twitterHandle.nftCount

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { Keyboard, Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import {
  Contact,
  ContactSources,
  Filters,
  useGetContacts,
  useLocationFromIp,
  UserData,
  validateDestination
} from '@vatom/sdk/react'
import { useDebounce } from 'use-debounce'

import { AppRoutes } from '../../../navigators'

import { SendStorage } from './Constants'
import { SendProviderProps, SendProviderTypes } from './Types'

export const ContextSender = createContext<SendProviderTypes>({} as SendProviderTypes)
export const useSender = () => useContext(ContextSender)

const contactsWhileTyping = [
  ContactSources.followers,
  ContactSources.following,
  ContactSources.tokenhistory,
  ContactSources.userdirectory
]

const contactsWhileNotTyping = [
  ContactSources.followers,
  ContactSources.following,
  ContactSources.tokenhistory,
  ContactSources.userdirectory,
  ContactSources.devicecontacts,
  ContactSources.recents
]

const SenderProvider = (props: PropsWithChildren<SendProviderProps>) => {
  const [searchString, setSearchString] = useState('')
  const navigation = useNavigation()

  const [isScannerOpen, setIsScannerOpen] = useState(false)

  const { children, ...rest } = props

  const locationFromIp = useLocationFromIp()
  const [lastSearchString] = useDebounce(searchString, 250)

  const destination = validateDestination(
    lastSearchString,
    true,
    locationFromIp?.data?.country_code
  )

  const filters: Filters = {
    tokenType: props.token?.getTokenType(),
    sources: lastSearchString ? contactsWhileTyping : contactsWhileNotTyping
  }
  const contacts = useGetContacts(destination, filters)

  const onScann = useCallback(
    (user: UserData) => {
      if (!user) return null

      const userToSend = user as unknown as Contact

      setIsScannerOpen(false)
      // props.setOpen(false)

      setTimeout(() => {
        if (Platform.OS === 'web') {
          localStorage.setItem(SendStorage.Destination, JSON.stringify(destination))
          localStorage.setItem(SendStorage.UserToSend, JSON.stringify(userToSend))

          navigation.navigate(AppRoutes.sendConfirmation, {
            tokenId: props.token.id,
            isShare: props.isShare
          })
        } else {
          navigation.navigate(AppRoutes.sendConfirmation, {
            tokenId: props.token.id,
            userToSend,
            destination,
            isShare: props.isShare
          })
        }
      }, 400)
    },
    [destination, navigation, props]
  )

  return (
    <ContextSender.Provider
      value={{
        ...rest,
        searchString,
        setSearchString,
        destination,
        lastSearchString,
        contacts,
        isScannerOpen,
        setIsScannerOpen,
        onScann,
        contractAddress: props.contractAddress,
        owner: props.owner
      }}
    >
      {children}
    </ContextSender.Provider>
  )
}

export default SenderProvider

import React, { memo } from 'react'
import { GradientButton, Text } from '@vatom/wombo'
import { Box, Divider, VStack } from 'native-base'

const SuccessComponent = ({ onPress }: { onPress: () => void }) => {
  return (
    <Box pt="20px" pb="16px" px={0}>
      <VStack alignItems="center" w="100%" paddingX="16px" justifyContent="center">
        <Text textAlign="center">
          Your NFT is being listed on the Vatom Market and could take up to a minute. Once listing
          is complete, the 'Waiting' badge on your NFT will change to a 'Listed' badge.
        </Text>
        <Divider backgroundColor="gray.900" height="0px" marginBottom="20px" />
        <GradientButton
          onPress={onPress}
          colorScheme="brand"
          px={4}
          w="100%"
          justifyContent="center"
        >
          <Text
            color="white"
            fontFamily="Inter-SemiBold"
            alignSelf="center"
            py={2}
            fontSize="18px"
            tx="common.done"
          />
        </GradientButton>
      </VStack>
    </Box>
  )
}

const Success = memo(SuccessComponent)
export default Success

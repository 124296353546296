import { StyleSheet } from 'react-native'
import axios from 'axios'

import { host, matrixServerUrl } from '../../constants'

export const getInputs = async (messageType: string, businessId?: string, pluginId?: string) => {
  if (!businessId || !pluginId) {
    return
  }
  const data = await axios
    .post(`${host}/b/${businessId}/spark-plugins/${pluginId}/events`, {
      type: 'message.new',
      messageType
    })
    .then(({ data }) => data)

  return 'error' in data ? null : data.inputs
}

export const getInputsWithValue = (apiInputs: any, storeInputs: any) => {
  return apiInputs.map((apiInput: any) => ({
    ...apiInput,
    value: storeInputs.find((storeInput: any) => storeInput.type === apiInput.type).value
  }))
}

export const getSendMessageUrl = (messageType: string, roomId: string, accessToken?: string) => {
  return `${matrixServerUrl}/_matrix/client/r0/rooms/${roomId}/send/${messageType}?access_token=${accessToken}`
}

export const modalStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalBackground: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'flex-end'
  },
  modalContent: {
    height: '100%',
    backgroundColor: '#ffffff'
  },
  inputsHolder: {
    height: '100%',
    paddingHorizontal: 20,
    width: '100%',
    flexDirection: 'column',
    marginTop: 14
  },
  toolbarHodlder: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: '#fff'
  }
})

export const base64ToBlob = (base64: string, mimeType: string) => {
  const byteCharacters = atob(base64)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  return new Blob([byteArray], { type: mimeType })
}

export const base64ToArrayBuffer = (base64: string) => {
  const binaryString = atob(base64)
  const bytes = new Uint8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

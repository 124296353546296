import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
}

export const DmIcon = ({ size, fill }: IconProps) => (
  <Icon viewBox="0 0 31 24" size={size}>
    <Path
      fill={fill}
      d="M27.12 6C28.7606 6 30.12 7.35938 30.12 9V18.0469C30.12 19.6875 28.7606 21 27.12 21H25.62V23.4375C25.62 23.8125 25.3387 24 25.0575 24C24.9169 24 24.8231 24 24.6825 23.9062L19.62 21H15.0731C13.4325 21 12.12 19.6875 12.12 18V16.5H13.62V18C13.62 18.8438 14.2762 19.5 15.12 19.5H19.995L24.12 21.8438V19.5H27.12C27.9169 19.5 28.62 18.8438 28.62 18V9C28.62 8.20312 27.9169 7.5 27.12 7.5H21.12V6H27.12ZM19.62 12C19.62 13.6875 18.2606 15 16.62 15H10.62L5.51062 17.9062C5.37 18 5.27625 18 5.1825 18C4.85437 18 4.62 17.8125 4.62 17.4375V15.0469L3.12 15C1.4325 15 0.12 13.6875 0.12 12V3C0.12 1.35938 1.4325 0 3.12 0H16.62C18.2606 0 19.62 1.35938 19.62 3V12ZM10.1981 13.5H16.62C17.4169 13.5 18.12 12.8438 18.12 12V3C18.12 2.20312 17.4169 1.5 16.62 1.5H3.12C2.27625 1.5 1.62 2.20312 1.62 3V12C1.62 12.8438 2.27625 13.5 3.12 13.5H6.12V15.8438L10.1981 13.5Z"
    />
  </Icon>
)

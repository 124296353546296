import React, { useRef } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { RichEditor } from 'react-native-pell-rich-editor'
import { observer } from 'mobx-react-lite'

import { withBusinessSelector } from '../../../../../../hooks/useBusinessSelector'
import { AppRoutes, AppStackScreenProps } from '../../../../../../navigators'
import { addMessageStore } from '../../../../screens/AddMessageModal'
import { useCommunitiesTheme } from '../../../../themes'
import { CreateMessageProvider } from '../../../MSTContextProvider'
import { AddMessageModalStore } from '../../stores'
import CloseButton from '../modal-header-components/CloseButton'
import ModalButton from '../modal-header-components/ModalButton'
import ModalTitle from '../modal-header-components/ModalTitle'
import { DecorationsElements } from '../ModalDecorations'
import ModalInput from '../ModalInput'
import ModalPollChoice from '../ModalPollChoice'
import ModalToolbar from '../ModalToolBar'
import ModalWrapper, { ContentWrapper, PlatformWrapper } from '../ModalWrapper'

const addQuestionStore = AddMessageModalStore.create()

type AddQuestionViewProps = AppStackScreenProps<
  typeof AppRoutes.RoomNewQuestion | typeof AppRoutes.CommunitiesRoomNewQuestion
>

const AddQuestion = observer(({ navigation }: AddQuestionViewProps) => {
  const store = addQuestionStore
  const { questions } = addMessageStore
  const selectedQuestion = questions.find(question => question.isSelected === true)

  const scoreStyleInput = store.additionalInputs.find(input => input.type === 'score-style')
  const inputs = [
    {
      additionalInfo: '',
      placeholder: 'Ask a question',
      type: 'title',
      value: selectedQuestion ? selectedQuestion['title' as keyof typeof selectedQuestion] : ''
    },
    {
      additionalInfo: '',
      placeholder: 'Add some details...',
      type: 'text',
      value: selectedQuestion ? selectedQuestion['text' as keyof typeof selectedQuestion] : ''
    }
  ]
  const decorations = [
    {
      type: 'score-style',
      placeholder: 'Style',
      additionalInfo: JSON.stringify({
        styles: [
          'single-choice-poll',
          'numbered-1-5',
          'numbered-0-5',
          'numbered-1-10',
          'numbered-0-10',
          'emojies',
          'stars'
        ]
      }),
      value: selectedQuestion ? selectedQuestion['scoreStyle' as keyof typeof selectedQuestion] : ''
    },
    {
      type: 'response-anchors',
      placeholder: 'Response Anchors',
      value: selectedQuestion
        ? selectedQuestion['responseAnchor' as keyof typeof selectedQuestion]
        : ''
    }
  ]

  const inputRef = useRef<RichEditor | undefined>() as React.RefObject<RichEditor>

  const communitiesTheme = useCommunitiesTheme()

  React.useEffect(() => {
    return () => {
      if (selectedQuestion) {
        selectedQuestion.setIsSelected(false)
      }
      store.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (scoreStyleInput?.value === 'single-choice-poll') {
      if (selectedQuestion) {
        if (selectedQuestion.pollChoices.length > 0) {
          store.initPollChoices(
            selectedQuestion.pollChoices.map(pollChoice => ({
              index: pollChoice.index,
              value: pollChoice.value
            }))
          )
        }
      } else {
        const pollChoices = [
          { index: 1, value: '' },
          { index: 2, value: '' }
        ]
        store.initPollChoices(pollChoices)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreStyleInput?.value])

  const sendMessage = () => {
    const { inputs, additionalInputs, pollChoices } = store
    const { questions } = addMessageStore

    const questionData = {
      id: questions.length,
      title: inputs?.find(input => input.type === 'title')?.value,
      text: inputs?.find(input => input.type === 'text')?.value,
      scoreStyle: additionalInputs?.find(input => input.type === 'score-style')?.value,
      responseAnchor: additionalInputs?.find(input => input.type === 'response-anchors')?.value,
      isSelected: false,
      pollChoices: pollChoices.map(pollChoice => ({
        index: pollChoice.index,
        value: pollChoice.value
      }))
    }

    if (selectedQuestion) {
      selectedQuestion.setData({ ...questionData, id: selectedQuestion.id })
    } else {
      addMessageStore.addQuestion(questionData)
    }
    navigation.goBack()
  }

  const hasContent = store.inputs.some(({ value }) =>
    typeof value === 'string'
      ? value
          .replaceAll(/<\/?[^>]+(>|$)/gi, '')
          .replace(/&nbsp;/g, '')
          .replace(/\s/g, '').length > 0
      : null
  )
  const scoreStyle = store.additionalInputs.find(({ type }) => type === 'score-style')
  const canSend = hasContent && !!scoreStyle?.value

  return (
    <CreateMessageProvider dataStore={store}>
      <ModalWrapper>
        <PlatformWrapper>
          <ContentWrapper>
            <View style={{ ...modalStyles.header, backgroundColor: communitiesTheme.background }}>
              <CloseButton
                iconType="close"
                style={{
                  position: 'absolute',
                  left: 8,
                  top: 5
                }}
              />
              <ModalTitle title={selectedQuestion ? 'Edit Question' : 'Add Question'} />
              <ModalButton
                action={() => (canSend ? sendMessage() : null)}
                text="Save"
                textStyle={{ color: canSend ? '#2EA7FF' : '#CED4DA' }}
              />
            </View>

            <ScrollView
              contentContainerStyle={{
                flexGrow: 1,
                justifyContent: 'space-between'
              }}
              keyboardShouldPersistTaps="always"
              style={{
                ...modalStyles.inputsHolder,
                backgroundColor: communitiesTheme.background
              }}
            >
              <View>
                {inputs.map((input, index) => (
                  <ModalInput key={index} input={input} ref={inputRef} focus={true} />
                ))}
                {scoreStyle?.value === 'single-choice-poll' &&
                  store.pollChoices.map((choice, index) => (
                    <ModalPollChoice key={index} input={choice} />
                  ))}
              </View>

              <View>
                <DecorationsElements elements={decorations} hasNoMessage />
              </View>
            </ScrollView>

            <ModalToolbar
              ref={inputRef}
              type={'question'}
              sendMessage={sendMessage}
              hideShowButton
              hideImagesModal
            />
          </ContentWrapper>
        </PlatformWrapper>
      </ModalWrapper>
    </CreateMessageProvider>
  )
})

export default withBusinessSelector(AddQuestion)

const modalStyles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    position: 'relative',
    paddingBottom: 10
    // alignItems: 'center'
  },
  inputsHolder: {
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
    flexDirection: 'column',
    paddingTop: 14
  },
  toolbarHodlder: {
    bottom: 0,
    width: '100%'
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
    alignItems: 'center',
    justifyContent: 'center'
  }
})

import React, { useCallback, useEffect, useMemo } from 'react'
import { useIsFocused } from '@react-navigation/native'
import {
  Channels,
  useGetUserProfile,
  useIsAuthed,
  useNotificationsStore,
  usePatchUserProfile
} from '@vatom/sdk/react'
import { observer } from 'mobx-react-lite'
import { Box } from 'native-base'

import { TokenCardLoading } from '../../components/TokenCard/TokenCard'
import { useBusinessSelector, useInventoryByBusiness } from '../../hooks/useBusinessSelector'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../hooks/useIsDesktop'
import { useLastVisitedRouteMutation } from '../../hooks/useLastVisitedRoute'
import { useLayoutScrollHandler } from '../../hooks/useLayoutScrollHandler'
import ClaimNFT from '../ClaimNFT'

import { WalletList } from './partials/WalletList'

export interface INftCard {
  name: string
  image: string
  num: number
}

export const WalletScreen = observer(() => {
  const isAuthed = useIsAuthed()

  const { business, isLoading: businessIsLoading, businessIdentifier } = useBusinessSelector()

  const { getHeaderHeight } = useLayoutScrollHandler()

  const patchUserMutation = usePatchUserProfile()
  const lastVisitedRoute = useGetUserProfile({
    staleTime: 0,
    refetchOnWindowFocus: false,
    retry: false,
    select: user => {
      return user.lastVisitedRoute ?? ''
    }
  })
  const setLastVisitedRoute = useLastVisitedRouteMutation()

  const businessId = business?.id ?? businessIdentifier
  const updateVisitedRoute = useCallback(
    () => {
      if (
        !patchUserMutation.isLoading &&
        !businessIsLoading &&
        businessId &&
        lastVisitedRoute.data !== `/b/${businessId}`
      ) {
        setLastVisitedRoute(businessId)
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [businessId, businessIsLoading]
  )

  useEffect(() => {
    updateVisitedRoute()
  }, [updateVisitedRoute])

  const isDesktop = useIsDesktop()
  const isMobile = !isDesktop
  const numColumns = useMemo(() => (isMobile ? 2 : 4), [isMobile])
  const isFocused = useIsFocused()
  const { isDark } = useBusinessTheme()

  const isDeferred = isDesktop && !isFocused ? true : false

  const inventory = useInventoryByBusiness({
    defer: isDeferred
  })

  const shouldShowBadge = useNotificationsStore(state => !!state.channels[Channels.wallet]?.length)

  useEffect(() => {
    if (shouldShowBadge && isFocused) {
      useNotificationsStore.getState().cleanChannel(Channels.wallet)
    }
  }, [shouldShowBadge, isFocused])

  const isLoading = inventory.isLoading
  const isTokensEmpty = inventory.tokens.length === 0

  const LoadingWallet = () => (
    <Box
      paddingTop={getHeaderHeight() ?? 2}
      _web={{
        paddingTop: 4
      }}
    >
      <WalletLoading loadingItemsAmount={numColumns} isDark={isDark} />
    </Box>
  )

  if (!inventory.region || !isAuthed || (inventory.isLoading && isTokensEmpty)) {
    return <LoadingWallet />
  }

  return (
    <Box flexGrow={1}>
      {!isLoading && isTokensEmpty ? (
        <ClaimNFT />
      ) : (
        <Box
          flexGrow={1}
          minHeight={'100%'}
          paddingTop={2}
          _web={{
            paddingTop: 4
          }}
        >
          <WalletList
            isLoading={isLoading}
            isMobile={isMobile}
            region={inventory.region}
            tokens={inventory.tokens}
            numColumns={numColumns}
          />
        </Box>
      )}
    </Box>
  )
})

type WalletLoadingProps = {
  loadingItemsAmount?: number
  containerProps?: React.ComponentProps<typeof Box>
  isDark?: boolean
}
const WalletLoading = ({ loadingItemsAmount = 10, containerProps, isDark }: WalletLoadingProps) => {
  const loadingAmount = loadingItemsAmount > 0 ? loadingItemsAmount : 10
  return (
    <Box
      flexDirection={'row'}
      flexWrap={'wrap'}
      justifyContent={'space-evenly'}
      alignItems={'center'}
      {...containerProps}
    >
      {Array.from({ length: loadingAmount }).map((_, index) => (
        <TokenCardLoading themed={isDark ? 'dark' : 'light'} marginBottom={2} key={index} />
      ))}
    </Box>
  )
}

import React from 'react'
import { Platform, ViewStyle } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { ThreeDFace } from '@vatom/sdk/react'
import { VatomModelType } from '@vatom/vatomNew/plugin'
import { AppRoutes } from '@vatom/wallet-routes'
import { PressableOpacity, Text, theme } from '@vatom/wombo'
import { Box, View } from 'native-base'

import { SpaceViewIcon } from '../../../components/Icons'

const ThreeDDisplay = ({ token, style }: { token: VatomModelType; style: ViewStyle }) => {
  //   const threeDPlacement = token.return()
  const navigation = useNavigation()
  const url = token.threeDInfo.url!
  const navigateToAR = () => {
    navigation.navigate(AppRoutes.TokenAR, { tokenId: token.id })
  }

  return (
    <View
      style={{
        flex: 5 / 6
      }}
    >
      <ThreeDFace
        url={url}
        style={{
          ...style
        }}
      />
      <Box position={'absolute'} alignItems="center" left={0} right={0} bottom={0}>
        <PressableOpacity
          onPress={navigateToAR}
          borderWidth={1}
          borderRadius={3}
          maxWidth={'100%'}
          width={228}
          height={12}
          alignItems={'center'}
          justifyContent={'center'}
          _light={{
            backgroundColor: theme.colors.grayCool[50],
            borderColor: theme.colors.grayCool[200]
          }}
          _dark={{
            backgroundColor: theme.colors.grayDarkMode[900],
            borderColor: theme.colors.grayDarkMode[800]
          }}
          style={{
            ...Platform.select({
              ios: {
                // shadowColor: styles.primaryText ?? '#000',
                shadowColor: '#000',
                shadowOffset: { width: 0, height: 3 },
                shadowOpacity: 0.15,
                shadowRadius: 4
              },
              android: {
                elevation: 3
              },
              web: {
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 14px'
              }
            })
          }}
        >
          <Box flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
            <SpaceViewIcon
              size={6}
              _light={{
                color: theme.colors.textLightMode[600]
              }}
              _dark={{
                color: theme.colors.textDarkMode[300]
              }}
            />
            <Text
              fontSize={15}
              lineHeight={20}
              marginLeft={2}
              _light={{
                color: theme.colors.textLightMode[600]
              }}
              _dark={{
                color: theme.colors.textDarkMode[300]
              }}
              tx="nftDetail.viewSpace"
            />
          </Box>
        </PressableOpacity>
      </Box>
    </View>
  )
}

export default ThreeDDisplay

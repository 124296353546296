import React, { useMemo } from 'react'
import { Platform } from 'react-native'
import { PressableOpacity, Text, theme } from '@vatom/wombo'
import { Button, IPressableProps } from 'native-base'

import { useIsDesktop } from '../../../hooks/useIsDesktop'

export type ButtonNextProps = React.PropsWithChildren &
  IPressableProps & {
    backgroundColor?: string
    textColor?: string
  }

const disabledWebStyles = {
  cursor: 'not-allowed',
  pointerEvents: 'none',
  userSelect: 'none'
}

export const ButtonNext = ({
  children,
  backgroundColor,
  textColor,
  disabled,
  ...rest
}: ButtonNextProps) => {
  const isDesktop = useIsDesktop()
  const isWeb = Platform.OS === 'web'
  const disabledStyles = disabled && isWeb ? disabledWebStyles : {}

  const styles = useMemo(
    () => ({
      light: {
        button: {
          base: {
            backgroundColor: backgroundColor ?? theme.colors.white
          },
          disabled: {
            backgroundColor: theme.colors.disabled,
            borderColor: theme.colors.extraLightText,
            borderWidth: 1
          }
        },
        text: {
          base: {
            color: theme.colors.white
          },
          disabled: {
            color: '#ADB5BD'
          }
        }
      },
      dark: {
        button: {
          base: {
            backgroundColor: backgroundColor ?? theme.colors.grayDarkMode[900]
          },
          disabled: {
            backgroundColor: theme.colors.grayDarkMode[600],
            borderWidth: 0
          }
        },
        text: {
          base: {
            color: theme.colors.white
          },
          disabled: {
            color: '#FFFFFF4D'
          }
        }
      }
    }),
    [backgroundColor]
  )

  return (
    <PressableOpacity
      disabled={disabled}
      height={52}
      alignItems={'center'}
      justifyContent={'center'}
      borderRadius={3}
      _light={!disabled ? styles.light.button.base : styles.light.button.disabled}
      _dark={!disabled ? styles.dark.button.base : styles.dark.button.disabled}
      style={disabledStyles}
      {...rest}
    >
      <Text
        fontSize={16}
        lineHeight={22}
        _light={!disabled ? styles.light.text.base : styles.light.text.disabled}
        _dark={!disabled ? styles.dark.text.base : styles.dark.text.disabled}
        _web={{
          fontSize: isDesktop ? 13 : 16
        }}
      >
        {children}
      </Text>
    </PressableOpacity>
  )
}

export const ButtonBack = ({
  children,
  onPress
}: React.PropsWithChildren & React.ComponentProps<typeof Button>) => {
  return (
    <Button
      m={2}
      minWidth={70}
      maxHeight={10} // 40px
      alignSelf={'flex-start'}
      onPress={onPress}
      _text={{
        fontSize: 13
      }}
      _light={{
        backgroundColor: theme.colors.grayCool[200],
        _text: {
          color: theme.colors.textLightMode[900]
        }
      }}
      _dark={{
        backgroundColor: theme.colors.grayDarkMode[700],
        _text: {
          color: theme.colors.textDarkMode[100]
        }
      }}
    >
      {children}
    </Button>
  )
}

import React, { createContext, useContext } from 'react'
import { observer } from 'mobx-react-lite'

import { host } from '../../../utils/hosts'

import { BusinessContextType } from './types'
import { useWebBusinessProvider } from './useWebBusinessProvider'

export const ContextWebView = createContext<BusinessContextType>({} as BusinessContextType)
export const useWebView = () => useContext(ContextWebView)

export const WebBusinessProvider = observer(({ children }: React.PropsWithChildren) => {
  const {
    onMessage,
    setRef,
    webViewRef,
    sendMessage,
    history,
    isBackButtonSubscribed,
    setIsBackButtonSubscribed
  } = useWebBusinessProvider()

  return (
    <ContextWebView.Provider
      value={{
        setWebviewRef: setRef,
        webViewRef,
        host,
        onMessage,
        sendMessage,
        state: history,
        isBackButtonSubscribed,
        setIsBackButtonSubscribed
      }}
    >
      {children}
    </ContextWebView.Provider>
  )
})

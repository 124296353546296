import React from 'react'
import { Switch, View } from 'react-native'
import { observer } from 'mobx-react-lite'

import { useCommunitiesTheme } from '../../../themes'
import ModalText from '../../modal-components/ModalText'
import { useStoreContext } from '../../MSTContextProvider'
import VotesIcon from '../assets/votes_icon.svg'

import { DecorationData } from './ModalDecorations'
import ModalDecorationWrapper from './ModalDecorationWrapper'

const ModalAllowVote = observer(({ decorationData }: { decorationData: DecorationData }) => {
  const store = useStoreContext()
  const communitiesTheme = useCommunitiesTheme()

  React.useEffect(() => {
    store.addAdditionalInputs(decorationData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const allowVoteInput = store.additionalInputs.find(
    ({ type }: { type: string }) => type === 'allow-vote'
  )

  if (!allowVoteInput) {
    return null
  }

  return (
    <ModalDecorationWrapper>
      <View style={{ flexDirection: 'row' }}>
        <VotesIcon width="20" style={{ marginRight: 12 }} />
        <ModalText text={decorationData.placeholder} />
      </View>

      <Switch
        trackColor={{ false: '#DEE2E6', true: communitiesTheme.accentColor }}
        thumbColor={communitiesTheme.accentColorText}
        onValueChange={value => allowVoteInput.setValue(value)}
        value={allowVoteInput.value as boolean}
        style={{ transform: [{ scaleX: 0.6 }, { scaleY: 0.6 }] }}
      />
    </ModalDecorationWrapper>
  )
})

export default ModalAllowVote

import React, { createContext, useContext, useEffect, useState } from 'react'
import { RootSDKStoreType } from '@vatom/sdk/core'
import { IAnyModelType } from 'mobx-state-tree'

import logger from '../logger'

import { setupRootSdkStore } from './setupRootSdkStore'

/**
 * Create a context we can use to
 * - Provide access to our stores from our root component
 * - Consume stores in our screens (or other components, though it's
 *   preferable to just connect screens)
 */
const RootSDKStoreContext = createContext<RootSDKStoreType>({} as RootSDKStoreType)

/**
 * The provider our root component will use to expose the root store
 */
export const RootSDKStoreProvider = RootSDKStoreContext.Provider

/**
 * A hook that screens can use to gain access to our stores, with
 * `const { someStore, someOtherStore } = useStore()`,
 * or less likely: `const RootSDKStore = useStore()`
 */
export const useSDK = () => useContext(RootSDKStoreContext)

export interface SDKProviderProps {
  children?: React.ReactNode
  plugins?: IAnyModelType[]
}

export let _sdkRootStore: RootSDKStoreType

export const getRootStore = () => _sdkRootStore

export const SDKProvider = ({ children, plugins }: SDKProviderProps) => {
  logger.info('[SDKProvider] plugins', plugins?.length ?? 0)
  const [rootStore, setRootStore] = useState<RootSDKStoreType | undefined>(undefined)

  // Kick off initial async loading actions, like loading fonts and RootStore
  useEffect(() => {
    ;(async () => {
      logger.info('[SDKProvider.useEffect] plugins', plugins?.length ?? 0)
      if (!rootStore) {
        await setupRootSdkStore({ plugins }).then(sdk => {
          _sdkRootStore = sdk
          setRootStore(sdk)
        })
      }
    })()
  }, [plugins, rootStore])

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color.
  // In iOS: application:didFinishLaunchingWithOptions:
  // In Android: https://stackoverflow.com/a/45838109/204044
  // You can replace with your own loading component if you wish.
  if (!rootStore) return null

  return <RootSDKStoreProvider value={rootStore}>{children}</RootSDKStoreProvider>
}

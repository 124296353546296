import React, { useEffect, useState } from 'react'
import { BVatomPluginType, isVatom } from '@vatom/BVatom/plugin'
import { RegionType } from '@vatom/sdk/core'
import { useGetPublicProfile, useIsAuthed, useSDK } from '@vatom/sdk/react'
import { Button, Modal, Text } from 'native-base'

import { useAnalytics } from '../../hooks/useAnalytics'
import { useNavigateHome } from '../../hooks/useNavigateHome'

import { useRedeemMutation } from './redeemMutatio'

type RedeemWebsocketMessage = {
  msg_type: string
  payload: {
    data: {
      cmd: string
      object_id: string
      requestor_id: string
    }
    event_id: string
    rpc: string
    template_variation: string
  }
  user_id: string
}

const ModalReedem = () => {
  const sdk = useSDK()
  const redeemMutation = useRedeemMutation()
  const { navigateHome } = useNavigateHome()
  const { analytics } = useAnalytics()

  const plugin = sdk.dataPool.getPlugin('BVatomPlugin') as BVatomPluginType
  const webSockets = plugin.api.webSockets
  const region = sdk.dataPool.region(RegionType.inventory)
  const isAuthed = useIsAuthed()
  const [requestorId, setRequestorId] = useState<string>()
  const [vatomId, setVatomId] = useState<string>()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const token = region.tokens.find(tk => tk.id === vatomId)
  const vatomToken = token && isVatom(token) ? token : null

  const requestorProfile = useGetPublicProfile(requestorId, {
    onSuccess: () => {
      if (!vatomToken || !requestorId) {
        // throw new Error('Vatom or requestor not found')
        console.info('Vatom or requestor not found')
        return
      }

      if (vatomToken.private.silent_redeem) {
        redeemMutation.mutate(
          { vatomId: vatomToken.id, requestorId },
          {
            onSuccess: () => {
              setVatomId(undefined)
              setRequestorId(undefined)
              navigateHome()
            },
            onError: error => {
              console.error('useGetPublicProfile', error)
            }
          }
        )
      } else {
        setIsModalOpen(true)
      }
    },
    enabled: !!requestorId
  })

  const onWebsocketMessage = (message: RedeemWebsocketMessage) => {
    const { payload } = message

    if (!isAuthed) {
      return
    }

    if (payload.rpc !== 'request_cmd')
      // Check RPC name
      return

    // Get field values
    const vatomId = payload.data.object_id
    const requestorId = payload.data.requestor_id

    if (!vatomId || !requestorId)
      return console.warn(
        'Redeem request RPC arrived, but it was missing either the object_id or the requestor_id.'
      )
    setRequestorId(requestorId)
    setVatomId(vatomId)
  }

  useEffect(() => {
    webSockets.on('message', onWebsocketMessage)
    return () => {
      webSockets.off('message', onWebsocketMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = () => {
    setIsModalOpen(false)
    setTimeout(
      () => {
        setVatomId(undefined)
        setRequestorId(undefined)
      },
      // Wait for the modal to close
      500
    )
  }

  const onRedeem = () => {
    if (!vatomId || !requestorId) {
      console.error('Missing vatomId or requestorId', {
        vatomId,
        requestorId
      })
      return
    }

    redeemMutation.mutate(
      { vatomId, requestorId },
      {
        onSuccess: () => {
          handleClose()
          navigateHome()
        },
        onError() {
          handleClose()
        }
      }
    )
  }

  // We open the modal when we have a requestor profile and a vatom token that is not silent redeem
  const shouldRenderModal =
    !!requestorProfile.data && !!vatomToken && !vatomToken.private.silent_redeem

  if (!shouldRenderModal) {
    return null
  }

  return (
    <Modal useRNModal={true} isOpen={isModalOpen} onClose={handleClose}>
      <Modal.Content>
        <Modal.CloseButton />

        <Modal.Body
          onLayout={() => {
            if (shouldRenderModal && vatomToken) {
              analytics.event(
                'initiateAction',
                {
                  actionUri: 'Redeem',
                  source: 'ObjectModal'
                },
                token
              )
            }
          }}
        >
          <Text
            ellipsizeMode="tail"
            fontSize="17px"
            fontFamily="Inter-Bold"
            textAlign="center"
            noOfLines={1}
            marginBottom="10px"
          >
            {vatomToken.metadata.name}
          </Text>
          <Text textAlign={'center'}>
            {`${requestorProfile.data.name} would like to redeem your Smart NFT`}
          </Text>

          <Button
            disabled={redeemMutation.isLoading}
            onPress={onRedeem}
            alignSelf="center"
            backgroundColor="primary.500"
            variant="outline"
            w="40%"
            marginTop={5}
            opacity={redeemMutation.isLoading ? 0.5 : 1}
          >
            <Text fontFamily="Inter-SemiBold" fontSize="15px" color="white">
              Redeem
            </Text>
          </Button>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  )
}

export default ModalReedem

import WebView from 'react-native-webview'
import { SessionSource, SessionTokenSnapshot, SessionType } from '@vatom/sdk/core'
import { useSDK } from '@vatom/sdk/react'
import { observer } from 'mobx-react-lite'
import { View } from 'native-base'

export const OidcSession = observer(() => {
  const sdk = useSDK()

  const session = sdk.dataPool.sessionStore.getFirstByTypeAndSource(
    SessionType.JWT,
    SessionSource.Vatom
  )

  const sessionToken = session?.value as SessionTokenSnapshot
  const refreshToken = sessionToken?.refreshToken

  if (!refreshToken) return null

  return (
    <View
      style={{
        display: 'none'
      }}
    >
      <WebView
        source={{
          html: `<html> <body onload="document.forms[0].submit();">
                    <form method="post" action="https://id.vatom.com/session">
                      <input type="hidden" name="refreshToken"  value="${refreshToken}" >
                    </form >
                  </body> </html>`
        }}
      />
    </View>
  )
})

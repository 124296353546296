// export * from './AR'
// export * from './ARModal'

import React from 'react'
import { AntDesign } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { Icon, IconButton } from 'native-base'

import { ScreenHeaderWrapper } from '../../components/ScreenHeaderWrapper'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useBusinessSelector, withBusinessSelector } from '../../hooks/useBusinessSelector'
import { AppNavigation, TabRoutes } from '../../navigators'
import { useTabNavConfig } from '../Home/hooks/useTabNavConfig'
import { DrawerSelectorButton } from '../Home/partials/DrawerSelectorButton'

import { AR } from './AR'

const MapAR = observer(props => {
  const navigation = useNavigation<AppNavigation>()

  const { business } = useBusinessSelector()
  const { tabsToRender } = useTabNavConfig()
  const hasARTabBar = tabsToRender.includes(TabRoutes.MapAr)

  const LeftComponent =
    business?.id && hasARTabBar ? (
      <DrawerSelectorButton />
    ) : (
      <IconButton
        borderRadius={'full'}
        opacity="0.5"
        backgroundColor={'black'}
        icon={<Icon color="white" as={AntDesign} size="24px" name="close" />}
        onPressOut={() => {
          navigation.goBack()
        }}
        _hover={{
          bg: 'transparent'
        }}
        _pressed={{
          bg: 'transparent',
          _icon: {
            name: 'close'
          },
          _ios: {
            _icon: {
              size: '2xl'
            }
          }
        }}
        _ios={{
          _icon: {
            size: '2xl'
          }
        }}
        _web={{
          top: 4,
          _icon: {
            size: 'xl'
          }
        }}
      />
    )

  return (
    <ScreenWrapper unsafe>
      <AR />
      <ScreenHeaderWrapper
        containerProps={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          backgroundColor: 'transparent'
        }}
        leftContainerProps={{
          left: 4
        }}
        leftComponent={LeftComponent}
      />
    </ScreenWrapper>
  )
})

export default withBusinessSelector(MapAR)

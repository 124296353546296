import React, { useState } from 'react'
import { Alert, Linking } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getConfig } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { GradientButton, Loader, PressableOpacity, Text, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Checkbox, FormControl, Icon, Input, Pressable, WarningOutlineIcon } from 'native-base'

import { deleteBackup, restoreWallet } from '../../../../modules/web3-wallet'
import BackIcon from '../../assets/back.svg'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { retrieveWalletBackupsKey } from '../RestoreFromCloud/screens/list'
import { useSelectedBackup } from '../RestoreFromCloud/store'

export const DeleteFromCloud = observer(() => {
  const queryClient = useQueryClient()
  const [isAgreed, setIsAgreed] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const { isDark } = useBusinessTheme()
  const navigation = useNavigation()

  const canContinue = isAgreed && password !== ''

  const restoreWalletMutation = useMutation({
    mutationFn: async () => {
      const accnt = useSelectedBackup.getState().acct
      const data = await restoreWallet(accnt, password)
      if (/^[a-zA-Z0-9\s]+$/.test(data)) {
        Alert.alert(
          'Delete Backup',
          'Are you sure you want to delete this iCloud backup? We will not be able to retrieve this once you proceed. Please enter the password you use to delete this.',
          [
            {
              text: 'Cancel',
              onPress: () => console.log('Cancel Pressed'),
              style: 'cancel'
            },
            {
              text: 'OK',
              onPress: async () => {
                useSelectedBackup
                  .getState()
                  .setSelectedBackup(
                    useSelectedBackup.getState().acct,
                    useSelectedBackup.getState().labl
                  )
                await deleteBackup(useSelectedBackup.getState().acct)
                queryClient.invalidateQueries({ queryKey: retrieveWalletBackupsKey })
                navigation.goBack()
              }
            }
          ]
        )
      } else {
        setErrorMsg('Incorrect Password!')
      }
    }
  })

  const togglePasswordVisibility = () => setShowPassword(!showPassword)

  return (
    <Box flex={1} safeArea={true}>
      <Box mb="20px">
        <PressableOpacity
          hitSlop={{ top: 20, left: 20, bottom: 20, right: 20 }}
          accessibilityRole="button"
          onPress={() => navigation.goBack()}
        >
          <BackIcon
            height={40}
            width={40}
            fill={!isDark ? theme.colors.darkText : theme.colors.white}
          />
        </PressableOpacity>
      </Box>
      <Box height="100%" w="100%" flex={1} px="16px">
        <Box flex={1} justifyContent="flex-start" alignItems="center" height="100%">
          <Text
            marginBottom="20px"
            color={!isDark ? theme.colors.darkText : theme.colors.white}
            fontFamily="Inter-Bold"
            fontSize="34px"
            textAlign="left"
            alignSelf="flex-start"
            lineHeight="41px"
            tx="deleteFromCloudPassword.title"
          />

          <Text
            w="100%"
            fontFamily="Inter"
            fontSize="15px"
            textAlign="left"
            mb="20px"
            color={!isDark ? theme.colors.darkText : theme.colors.white}
            tx="deleteFromCloudPassword.description"
          />

          <FormControl isInvalid={errorMsg !== ''} w="100%" maxW="100%" py={2}>
            <Input
              size="lg"
              value={password}
              onChangeText={setPassword}
              placeholder={translate('deleteFromCloudPassword.passwordPlaceholder')}
              type={showPassword ? 'text' : 'password'}
              InputRightElement={
                <Pressable accessibilityRole="button" onPress={togglePasswordVisibility}>
                  <Icon
                    as={<MaterialIcons name={showPassword ? 'visibility' : 'visibility-off'} />}
                    size={5}
                    mr="2"
                    color="muted.400"
                  />
                </Pressable>
              }
            />

            <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
              {errorMsg}
            </FormControl.ErrorMessage>
          </FormControl>
        </Box>

        <Box justifyContent="center" width="100%" pb={20}>
          <Checkbox
            value="agree"
            isChecked={isAgreed}
            onChange={() => setIsAgreed(!isAgreed)}
            mb={10}
          >
            <Text textAlign="left" fontSize="sm" color="gray.500" fontFamily="body">
              {translate('deleteFromCloudPassword.agree')}
              <Text
                color="lightBlue.600"
                underline={true}
                onPress={() => Linking.openURL(getConfig().termsOfUseURL)}
                tx="common.termsOfService"
              />
              {` ${translate('common.and')} `}
              <Text
                color="lightBlue.600"
                underline={true}
                onPress={() => Linking.openURL(getConfig().privacyPolicyURL)}
                tx="loginScreen.privacyPolicy"
              />
            </Text>
          </Checkbox>

          <GradientButton
            disabled={!canContinue || restoreWalletMutation.isLoading}
            onPress={() => restoreWalletMutation.mutate()}
            h="52px"
            w="100%"
            py="15px"
            px="30px"
            alignItems="center"
          >
            {restoreWalletMutation.isLoading ? (
              <Loader size={30} color="white" />
            ) : (
              <Text
                alignSelf="center"
                fontFamily="Inter"
                color="white"
                fontSize={16}
                lineHeight={22}
                tx="common.delete"
              />
            )}
          </GradientButton>
        </Box>
      </Box>
    </Box>
  )
})

export default DeleteFromCloud

import { theme } from '@vatom/wombo'
import { StyledProps, Text } from 'native-base'

import { fieldPropsInterfaceItem } from './Types'

type props = fieldPropsInterfaceItem & StyledProps

const LabelForm = (props: props) => {
  const { field, ...rest } = props

  let text = props.title ?? field?.title ?? field?.title ?? field?.name

  if (!text || text === '') return null

  if (field?.required && !text.includes('*')) text += `*`

  // Quirk: Jingle Ball 2022
  if (field?.name === 'jingleball_content_2') text = ''

  return (
    <Text
      mb="3px"
      fontSize="15px"
      mt="16px"
      _light={{
        color: theme.colors.textLightMode[900]
      }}
      _dark={{
        color: theme.colors.textDarkMode[100]
      }}
      {...rest}
    >
      {text}
    </Text>
  )
}

export default LabelForm

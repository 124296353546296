import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const ProfileIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 16 17" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M8 0.5C12.4062 0.5 16 4.09375 16 8.5C16 12.9375 12.4062 16.5 8 16.5C3.5625 16.5 0 12.9375 0 8.5C0 4.09375 3.5625 0.5 8 0.5ZM8 15.5C9.46875 15.5 10.8438 15.0625 11.9688 14.2812C11.8438 12.7188 10.5625 11.5 9 11.5H7C5.40625 11.5 4.125 12.7188 4 14.2812C5.125 15.0625 6.5 15.5 8 15.5ZM12.8438 13.5312C14.1562 12.25 15 10.4688 15 8.5C15 4.65625 11.8438 1.5 8 1.5C4.125 1.5 1 4.65625 1 8.5C1 10.4688 1.8125 12.25 3.125 13.5312C3.5625 11.8125 5.125 10.5 7 10.5H9C10.8438 10.5 12.4062 11.8125 12.8438 13.5312ZM8 4.5C9.375 4.5 10.5 5.625 10.5 7C10.5 8.40625 9.375 9.5 8 9.5C6.59375 9.5 5.5 8.40625 5.5 7C5.5 5.625 6.59375 4.5 8 4.5ZM8 8.5C8.8125 8.5 9.5 7.84375 9.5 7C9.5 6.1875 8.8125 5.5 8 5.5C7.15625 5.5 6.5 6.1875 6.5 7C6.5 7.84375 7.15625 8.5 8 8.5Z"
    />
  </Icon>
)

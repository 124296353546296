import { Platform } from 'react-native'
import {
  AndroidImportance,
  NotificationContent,
  NotificationTriggerInput,
  scheduleNotificationAsync,
  setNotificationChannelAsync,
  setNotificationHandler
} from 'expo-notifications'

const DEFAULT_TITLE = 'Vatom'

type NotificationData = {
  title?: NotificationContent['title']
  body: NotificationContent['body']
  data?: NotificationContent['data']
  subtitle?: NotificationContent['subtitle']
}

const sendLocalNotification = async (
  { title, body, data, subtitle }: NotificationData,
  trigger?: NotificationTriggerInput
) => {
  if (!body) {
    console.error('sendLocalNotification. body is required')
    return
  }

  await scheduleNotificationAsync({
    content: {
      title: title ?? DEFAULT_TITLE,
      body,
      data,
      subtitle
    },
    trigger: trigger ?? null
  })
}

const initializeNotificationService = async () => {
  if (Platform.OS !== 'web') {
    if (Platform.OS === 'android') {
      await setNotificationChannelAsync('default', {
        name: 'default',
        importance: AndroidImportance.MAX
      })
    }

    setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false
      })
    })
  }
}
export { initializeNotificationService, sendLocalNotification }

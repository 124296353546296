import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size: React.ComponentProps<typeof Icon>['size']
}

export const ETHIcon = ({ size }: IconProps) => (
  <Icon viewBox="0 0 24 24" size={size} fill="currentColor">
    <Path
      d="M11.6225 0L11.4668 0.546928V16.4161L11.6225 16.5767L18.7452 12.2225L11.6225 0Z"
      fill="#343434"
    />
    <Path d="M11.6229 0L4.5 12.2225L11.6229 16.5767V8.87428V0Z" fill="#8C8C8C" />
    <Path
      d="M11.6229 17.9714L11.5352 18.0821V23.7349L11.6229 23.9999L18.75 13.6195L11.6229 17.9714Z"
      fill="#3C3C3B"
    />
    <Path d="M11.6229 23.9999V17.9714L4.5 13.6195L11.6229 23.9999Z" fill="#8C8C8C" />
    <Path d="M11.623 16.5767L18.7458 12.2225L11.623 8.87427V16.5767Z" fill="#141414" />
    <Path d="M4.5 12.2225L11.6229 16.5767V8.87427L4.5 12.2225Z" fill="#393939" />
  </Icon>
)

import React from 'react'
import { SafeAreaView, StyleSheet, View } from 'react-native'
import { observer } from 'mobx-react-lite'

import { withBusinessSelector } from '../../../../../../hooks/useBusinessSelector'
import {
  AppRoutes,
  AppStackScreenProps,
  navigateToConnectScreen,
  RoomRoutes
} from '../../../../../../navigators'
import { addMessageStore } from '../../../../screens/AddMessageModal'
import { useCommunitiesTheme } from '../../../../themes'
import { useCommunitySpace } from '../../../../useCommunitySpace'
import NoSketchesImage from '../../assets/no-sketches-image.svg'
import CloseButton from '../modal-header-components/CloseButton'
import ModalButton from '../modal-header-components/ModalButton'
import ModalTitle from '../modal-header-components/ModalTitle'
import {
  AddButton,
  PlaceholderDescription,
  PlaceholderTitle
} from '../modal-questions-components/components'
import QuestionsList from '../modal-questions-components/QuestionsList'

type SketchesProps = AppStackScreenProps<
  typeof AppRoutes.RoomSketchList | typeof AppRoutes.CommunitiesRoomSketchList
>

const Sketches = observer(({ navigation, route }: SketchesProps) => {
  const { sketches } = addMessageStore
  const communitiesTheme = useCommunitiesTheme()
  const { community } = useCommunitySpace()
  const onPressAddButton = () => {
    navigateToConnectScreen(navigation, RoomRoutes.RoomNewSketch, {
      ...route.params,
      community
    })
  }
  return (
    <View style={styles.container}>
      <SafeAreaView style={[styles.modalContent, { backgroundColor: communitiesTheme.background }]}>
        <View style={styles.header}>
          <CloseButton
            iconType="close"
            style={{
              position: 'absolute',
              left: 8,
              top: 5
            }}
          />
          <ModalTitle title="Sketches" />
          {sketches.length > 0 ? <ModalButton action={onPressAddButton} text="Add" /> : null}
        </View>

        {sketches.length > 0 ? (
          <QuestionsList type="sketch" />
        ) : (
          <View style={styles.content}>
            <NoSketchesImage style={{ marginBottom: 40 }} />
            <PlaceholderTitle text="Add Sketches" />
            <PlaceholderDescription
              text="The purpose of a Vote is to allow users to pick their favorite sketch. Add two or more
              sketches below to create a Vote."
            />
            <AddButton text="Add a Sketch" onPress={onPressAddButton} />
          </View>
        )}
      </SafeAreaView>
    </View>
  )
})

export default withBusinessSelector(Sketches)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContent: {
    width: '100%',
    flex: 1
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    position: 'relative',
    paddingBottom: 10
  },
  content: {
    alignItems: 'center',
    paddingHorizontal: 40,
    marginTop: 60
  }
})

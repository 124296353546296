import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientOptions } from '@tanstack/react-query-persist-client'

import * as storage from '../../utils/storage'

const getItem = (str: string) => {
  const result = storage.storage.getString(str)
  if (result) {
    return result
  }
  return null
}

const setItem = (str: string, value: string) => {
  storage.saveString(str, value)
}

const removeItem = (str: string) => {
  storage.storage.delete(str)
}

export const SDKQueryClientPersister = createSyncStoragePersister({
  storage: {
    getItem,
    setItem,
    removeItem
  }
})

export const SDKQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000, // 1 minute
      cacheTime: 5 * 60 * 1000 // 5 minutes
    }
  }
})

const keysToIgnore = [
  'member-data',
  'message-user-question-votes',
  'message-thread-replies',
  'message-hide',
  'messages',
  'matrix-members',
  'user-power-level',
  'message-reactions'
]

export const SDKPersistOptions: Omit<PersistQueryClientOptions, 'queryClient'> = {
  persister: SDKQueryClientPersister,
  buster: '7',
  dehydrateOptions: {
    shouldDehydrateQuery: query => {
      if (
        query?.meta?.volatile ||
        query.queryKey?.some(key => typeof key === 'string' && keysToIgnore?.includes(key))
      ) {
        return false
      }
      const shouldSave = query.state.status === 'success'

      return shouldSave
    }
  }
}

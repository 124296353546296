import React from 'react'
import { Text } from 'react-native'
import moment from 'moment-timezone'

type EndsInNoteProps = {
  startTime: number
  beforeText?: string
  afterText?: string
  style?: object
}

const StartsInNote = (props: EndsInNoteProps) => {
  const { startTime, beforeText, afterText, style } = props

  const currentDateTimestamp = new Date().getTime()
  const now = moment(currentDateTimestamp)
  const start = moment(startTime)

  const diffMinutes = start.diff(now, 'minutes')
  const diffHours = start.diff(now, 'hours')
  const diffDays = start.diff(now, 'days')

  const daysReference = diffDays === 1 ? 'day' : 'days'
  const hoursReference = diffHours === 1 ? 'hour' : 'hours'
  const minutesReference = diffHours === 1 ? 'minute' : 'minutes'

  const isVisible = currentDateTimestamp < startTime

  const displayEndTime = () => {
    if (diffDays > 0) {
      return `${diffDays} ${daysReference}`
    } else {
      return diffHours > 0 ? `${diffHours} ${hoursReference}` : `${diffMinutes} ${minutesReference}`
    }
  }

  return isVisible ? (
    <Text style={style}>
      {beforeText} {displayEndTime()} {afterText}
    </Text>
  ) : null
}

export default StartsInNote

import { Platform } from 'react-native'
import { Leva } from 'leva'
import { observer } from 'mobx-react-lite'

import { useDebugMode } from './useDebugMode'

export const DebugPanel = observer(() => {
  if (Platform.OS !== 'web') return null

  const showPanel = useDebugMode()

  return (
    <Leva
      // theme={myTheme} // you can pass a custom theme (see the styling section)
      // fill // default = false,  true makes the pane fill the parent dom node it's rendered in
      // flat // default = false,  true removes border radius and shadow
      // oneLineLabels // default = false, alternative layout for labels, with labels and fields on separate rows
      // hideTitleBar // default = false, hides the GUI header
      collapsed // default = false, when true the GUI is collpased
      hidden={!showPanel} // default = false, when true the GUI is hidden
    />
  )
})

import { useRef } from 'react'

import { WebWrapper } from '../../components/WebWrapper'

import RecipientsScreen, { RecipientsProps } from './Recipients'

export default (props: RecipientsProps) => {
  const ref = useRef(null)
  return (
    <WebWrapper justifyContent={'center'}>
      <RecipientsScreen ref={ref} {...props} />
    </WebWrapper>
  )
}

import React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { float } from '@babylonjs/core'
import { useMatrixUser } from '@vatom/sdk/react'
import axios from 'axios'

import { matrixServerUrl } from '../../../constants'
import { useCommunitiesTheme } from '../../../themes'

import VoteCheckIcon from './assets/vote_check_icon_new.svg'

const FillBar = ({ fillBarLength }: { fillBarLength: float }) => {
  const communitiesTheme = useCommunitiesTheme()
  return (
    <>
      <Text
        style={[
          { ...styles.choiceContent, fontWeight: 'bold' },
          { color: communitiesTheme.pollChoice.text }
        ]}
      >
        {Number.isInteger(fillBarLength) ? fillBarLength : fillBarLength.toFixed(2)}%
      </Text>
      <View
        style={[
          { ...styles.choiceFillBar, width: `${fillBarLength}%` },
          { backgroundColor: communitiesTheme.pollChoice.fillBar }
        ]}
      />
    </>
  )
}

const PollElement = (props: any) => {
  const { choice, hideResults, pollVotes, messageId, roomId, questionId, isLocked } = props
  const communitiesTheme = useCommunitiesTheme()
  const { data: matrixUser } = useMatrixUser()

  let fillBarLength = 0
  let choiceVotes = []
  if (pollVotes.length > 0) {
    choiceVotes = pollVotes.filter((pollVote: any) => pollVote.index === choice.index)
    fillBarLength = (choiceVotes.length / pollVotes.length) * 100
  }
  const userVote = choiceVotes.find((pollVote: any) => pollVote.sender === matrixUser?.user_id)

  const isQuestion = typeof questionId === 'number'

  const addVote = async (choice: any) => {
    const eventType = isQuestion ? 'v.room.question.vote' : 'v.room.poll.vote'
    await axios.post(
      `${matrixServerUrl}/_matrix/client/r0/rooms/${roomId}/send/${eventType}?access_token=${matrixUser?.access_token}`,
      {
        'm.relates_to': {
          rel_type: isQuestion ? 'question.vote' : 'poll.vote',
          event_id: messageId
        },
        body: {
          ...(isQuestion ? { questionIndex: questionId } : null),
          index: choice.index
        }
      }
    )
  }

  const removeVote = async (choice: any) => {
    const eventType = isQuestion ? 'question' : 'poll'
    const dateNow = Date.now()
    await axios.put(
      `${matrixServerUrl}/_matrix/client/r0/rooms/${roomId}/redact/${choice.eventId}/${messageId}.${eventType}.${dateNow}?access_token=${matrixUser?.access_token}`,
      {}
    )
  }

  const handleVote = async (choice: any) => {
    const userVote = pollVotes.find((pollVote: any) => pollVote.sender === matrixUser?.user_id)
    if (userVote) {
      await removeVote(userVote)
      if (userVote.index !== choice.index) {
        await addVote(choice)
      }
    } else {
      await addVote(choice)
    }
  }

  const showResults = !hideResults || isLocked
  const showCheck = userVote && hideResults && !isLocked

  return (
    <Pressable
      accessibilityRole="button"
      onPress={async () => (isLocked ? null : await handleVote(choice))}
    >
      <View
        style={{
          ...styles.choiceInput,
          borderColor:
            userVote && !hideResults
              ? communitiesTheme.pollChoice.activeBorder
              : communitiesTheme.pollChoice.border
        }}
      >
        <Text style={[styles.choiceContent, { color: communitiesTheme.pollChoice.text }]}>
          {choice.content ?? choice.value}
        </Text>
        {showCheck ? (
          <VoteCheckIcon
            fill={communitiesTheme.accentColor}
            width={24}
            height={24}
            style={{ right: 12, position: 'absolute', alignSelf: 'center' }}
          />
        ) : null}
        {showResults ? <FillBar fillBarLength={fillBarLength} /> : null}
      </View>
    </Pressable>
  )
}

export default PollElement

const styles = StyleSheet.create({
  choiceInput: {
    borderWidth: 1,
    borderRadius: 4,
    marginBottom: 12,
    paddingVertical: 11,
    overflow: 'hidden',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  choiceFillBar: {
    position: 'absolute',
    height: 44,
    left: 0,
    top: 0
  },
  choiceContent: {
    zIndex: 1,
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: 15,
    lineHeight: 20
  }
})

import React from 'react'
import { StyleSheet, Text } from 'react-native'
import { useQueries } from '@tanstack/react-query'
import { useMatrixUser } from '@vatom/sdk/react'
import { IEvent } from 'matrix-js-sdk'

import { fetchQuestionVote, useMessagePollVotes } from '../../../../queries'
import { useCommunitiesTheme } from '../../../../themes'
import ArrowRightIcon from '../../../modal-components/ArrowRightIcon'
import VoteCheckIcon from '../assets/vote_check_icon_new.svg'

const AnsweredNumber = (props: any) => {
  const { roomID, eventID, questions, isThread } = props
  const { data: matrixUser } = useMatrixUser()
  const userId = matrixUser?.user_id
  const questionsLength = questions?.content?.length ?? 0
  const communitiesTheme = useCommunitiesTheme()

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useMessagePollVotes(
    roomID,
    eventID,
    'question.vote'
  )
  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage()
  }

  const questionVotes = data?.pages.flatMap(group => {
    const userVotes: IEvent[] = group?.chunk?.filter(
      (pollVote: IEvent) => pollVote.sender === userId
    )

    return userVotes?.map(pollVote => ({
      questionIndex: pollVote.content.body.questionIndex
    }))
  })

  const filteredQuestions = questions.content.filter(
    (question: { pollChoices: string | any[] }) => question.pollChoices.length === 0
  )

  const useQueriess = useQueries({
    queries: filteredQuestions.map((question: { id: string }) => {
      return {
        queryKey: ['message-user-question-votes', roomID, eventID, question.id, userId],
        queryFn: () =>
          fetchQuestionVote(roomID, eventID, question.id, userId, matrixUser?.access_token)
      }
    })
  })

  let answered = 0

  if (useQueriess) {
    useQueriess.map(query => {
      const { data } = query

      // @ts-expect-error need to type this properly
      if (data?.chunk.length > 0) {
        answered++
      }
    })
  }

  if (!questionVotes) {
    return null
  }

  return (
    <>
      <Text key="number-answered" style={styles.numberAnswered}>
        {questionVotes.length + answered}/{questionsLength} answered
      </Text>
      {!isThread ? (
        questionVotes.length + answered === questionsLength ? (
          <VoteCheckIcon
            fill={communitiesTheme.accentColor}
            width={20}
            height={20}
            style={{ marginLeft: 12 }}
          />
        ) : (
          <ArrowRightIcon style={{ marginLeft: 12 }} />
        )
      ) : null}
    </>
  )
}

export default AnsweredNumber

const styles = StyleSheet.create({
  numberAnswered: {
    fontSize: 11,
    lineHeight: 15,
    color: '#868E96'
  }
})

import { useCallback, useMemo } from 'react'
import { ScrollView } from 'react-native'
import { StackActions } from '@react-navigation/native'
import { useCommunities, useConfig } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { PressableOpacity, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Text } from 'native-base'

import { BackLightIcon } from '../../../components/Icons'
import { withBusinessSelector } from '../../../hooks/useBusinessSelector'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../../hooks/useIsDesktop'
import { AppRoutes, CommunitiesRoutes, TabRoutes } from '../../../navigators'
import { useDrawerStore } from '../drawerStore'

import { DrawerTabScreenProp, useDrawerContentContext } from './DrawerContent'
import { DrawerItem } from './DrawerItem'
import { ProfileButton } from './ProfileButton'

type CommunitiesListProps = DrawerTabScreenProp<'CommunitiesList'>

const CommunitiesListObs = observer(({ navigation }: CommunitiesListProps) => {
  const isDesktop = useIsDesktop()
  const { isDark } = useBusinessTheme()
  const {
    appNavigation,
    businessIdCommunity: businessId,
    businessIdInNavigation,
    businesses
  } = useDrawerContentContext()
  const { closeDrawer } = useDrawerStore()
  const config = useConfig()

  const business = businesses?.find(b => b.id === businessId)
  const { data: communities } = useCommunities(businessId)
  const filteredCommunities = useMemo(() => {
    if (Array.isArray(communities)) {
      return communities?.filter(c => !c.isDefault)
    }
    return []
  }, [communities])

  const goBack = () => {
    navigation.navigate('BusinessList')
  }

  const onSelectBusiness = useCallback(
    (businessId: string) => {
      closeDrawer()
      appNavigation.dispatch(
        StackActions.replace(AppRoutes.BusinessProxy, { business: businessId })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [businessIdInNavigation]
  )

  const onSelectCommunity = useCallback(
    (communityId: string) => {
      closeDrawer()
      appNavigation.navigate(AppRoutes.BusinessProxy, {
        business: business?.name ?? businessId,
        screen: TabRoutes.Connect,
        params: {
          screen: CommunitiesRoutes.CommunitiesHome,
          params: {
            community: communityId
          }
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [businessId]
  )

  return (
    <>
      <Box
        flexDirection={'column'}
        flex={1}
        bgColor={isDark ? theme.colors.grayCool[900] : theme.colors.white}
      >
        {!config.isBusinessLocked ? (
          <PressableOpacity
            accessibilityRole="button"
            onPress={goBack}
            marginTop={4}
            marginLeft={4}
          >
            <Box flexDirection={'row'} alignItems={'center'} width={'100%'}>
              <BackLightIcon
                size={5}
                _light={{
                  color: theme.colors.grayCool[900]
                }}
                _dark={{
                  color: theme.colors.textDarkMode[300],
                  _web: {
                    color: isDesktop ? theme.colors.grayCool[700] : theme.colors.textDarkMode[300]
                  }
                }}
              />
              <Text
                marginLeft={2}
                fontFamily={'Inter-SemiBold'}
                fontSize={13}
                lineHeight={18}
                _light={{
                  color: theme.colors.grayCool[700]
                }}
                _dark={{
                  color: theme.colors.textDarkMode[300],
                  _web: {
                    color: isDesktop ? theme.colors.grayCool[700] : theme.colors.textDarkMode[300]
                  }
                }}
              >
                Back
              </Text>
            </Box>
          </PressableOpacity>
        ) : null}
        <Box paddingTop={4} flex={1} flexShrink={0}>
          <ScrollView
            contentContainerStyle={{
              flex: 1,
              flexShrink: 0
            }}
          >
            {business && (
              <DrawerItem
                key={`communitiesList-${business.id}`}
                onPress={() => onSelectBusiness(business.name)}
                name={translate('common.home')}
                image={business.logoSrc}
              />
            )}
            {filteredCommunities &&
              filteredCommunities.map(community => (
                <DrawerItem
                  // disabled={Boolean(currentCommunityId && community.id === currentCommunityId)}
                  key={`communitiesList-${community.id}`}
                  onPress={() => onSelectCommunity(community.id)}
                  name={community.name}
                  image={community.icon}
                />
              ))}
          </ScrollView>
        </Box>
      </Box>
      {!isDesktop && <ProfileButton onPress={() => navigation.navigate('ProfileDrawer')} />}
    </>
  )
})

const CommunitiesList = withBusinessSelector(CommunitiesListObs)

export { CommunitiesList }

// {"businessId": "EnV0VWTh5T", "description": null, "icon": "https://icon.com", "id": "WdGg9XLnapZS816PdodsU", "isArchived": false, "isDefault": false, "name": "Community 114"}

import React, { forwardRef, useState } from 'react'
import { Keyboard, Platform, TextInput } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import {
  ContactSources,
  DestinationTypes,
  Filters,
  getAlchemyClient,
  useAddRecentContact,
  useBusiness,
  useGasEstimates,
  useGetContacts,
  useTokenBalanceQuery,
  useWeb3New
} from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { Avatar, Status, Text, theme, Toast } from '@vatom/wombo'
import { TransactionRequest, Utils, Wallet } from 'alchemy-sdk'
import { observer } from 'mobx-react-lite'
import {
  Box,
  Center,
  FlatList,
  HStack,
  KeyboardAvoidingView,
  Pressable,
  Radio,
  VStack
} from 'native-base'

import { getWallet, retrieveAndDecrypt } from '../../../../../modules/web3-wallet'
import {
  HeaderButtonClose,
  ScreenHeader,
  ScreenHeaderButton
} from '../../../components/ScreenHeader'
import { ScreenWrapper } from '../../../components/ScreenWrapper'
import { Title } from '../../../components/Title'
import { useBusinessSelector } from '../../../hooks/useBusinessSelector'
import { useIsDesktop } from '../../../hooks/useIsDesktop'
import { useUserById } from '../../../hooks/useUserById'
import { AppRoutes, AppStackScreenProps, WalletRoutes } from '../../../navigators'
import { useWalletStore } from '../../BlockChainWallets/WalletStore'
import { useCoinTheme } from '../../CoinDetail/hooks/useCoinTheme'
import { CoinAvatar } from '../../CoinDetail/partials'
import { ButtonBack, ButtonNext } from '../../CoinTransfer/partials/Buttons'

import Arrow from './assets/long-arrow.svg'
import { CommentInput } from './components/CommentInput'
import { useTransferState } from './hooks/useTransferState'
import ActionSheetConfirmTransfer from './partials/ActionSheetConfirmTransfer'

export type IConfirmationProps = AppStackScreenProps<typeof AppRoutes.FungibleTokenConfirmation>

export const Confirmation = observer(
  forwardRef(({ route, navigation }: IConfirmationProps, ref) => {
    const [isConfrimOpen, setIsConfirmOpen] = useState(false)
    const [detail, setDetail] = useState('')
    const [selectedAddress, setSelectedAddress] = useState(route.params.toAddress ?? '')

    const insets = useSafeAreaInsets()
    const isDesktop = useIsDesktop()
    const amount = route.params.amount
    const { tokenMetadata, addressBalance } = useTokenBalanceQuery(route.params)
    const gasEstimates = useGasEstimates({
      toAddress: route.params?.toAddress ?? '',
      amount: route.params.amount,
      network: route.params.network
    })
    const isSelfCustodial = useWalletStore(walletState =>
      Boolean(walletState.wallets.find(wallet => wallet.address === route.params.address))
    )
    const filters: Filters = {
      tokenType: undefined,
      sources: [
        ContactSources.userdirectory,
        ContactSources.tokenhistory,
        ContactSources.devicecontacts
      ]
    }
    const contacts = useGetContacts(
      {
        type: DestinationTypes.Id,
        value: ''
      },
      filters
    )
    const contact = contacts.data.find(c => c.id === route.params.toUserId)

    const { businessIdentifier } = useBusinessSelector()
    const { transferState, startTransfer, completeTransfer, failTransfer } = useTransferState()

    const { data: business } = useBusiness({ business: 'nQwtevgfOa' })
    const addRecentContactMutation = useAddRecentContact()

    const inputRef = React.createRef<TextInput>()

    const styles = useCoinTheme({ business })

    const coinImage = tokenMetadata.data?.logo
    const coinName = tokenMetadata.data?.symbol ?? addressBalance.data?.symbol

    const { data: user } = useUserById(route.params.toUserId ?? '')
    const identitiesAddresses = [
      ...(user?.identities.filter(i => i.type === 'eth').map(i => i.value) ?? [])
    ]
    const addressesArray = route.params?.toAddress
      ? [...identitiesAddresses, route.params.toAddress]
      : identitiesAddresses
    const toAddresses = Array.from(new Set(addressesArray))

    const userName = user?.name
    const userImage = user?.picture
    const userImageShowBorder = userImage ? 0 : 1
    const web3 = useWeb3New('eth')

    const onSendFromSelfCustodial = async () => {
      try {
        startTransfer()
        const seeds = await retrieveAndDecrypt(route.params.address ?? '')
        const walletDetails = await getWallet(seeds)

        const alchemy = getAlchemyClient(route.params.network)
        const nonce = await alchemy.core.getTransactionCount(route.params.address, 'latest')

        const network = await alchemy.core.getNetwork()
        const wallet = new Wallet(walletDetails.privateKey, alchemy)

        const transaction: TransactionRequest = {
          to: selectedAddress,
          value: Utils.parseEther(String(route.params.amount)),
          gasLimit: gasEstimates.data?.gasLimit,
          maxPriorityFeePerGas: gasEstimates.data?.maxPriorityFeePerGas,
          maxFeePerGas: gasEstimates.data?.maxFeePerGas,
          nonce: nonce,
          type: 2,
          chainId: network.chainId
        }

        const rawTransaction = await wallet.signTransaction(transaction)
        await alchemy.core.sendTransaction(rawTransaction)

        Toast({
          title: translate('coinsScreen.sendSuccess', { coinName }),
          placement: 'top',
          status: Status.success,
          withMargin: true
        })

        if (contact) {
          addRecentContactMutation.mutate(contact)
        }

        completeTransfer()

        if (businessIdentifier) {
          navigation.navigate(AppRoutes.BusinessProxy, {
            business: businessIdentifier,
            screen: WalletRoutes.Coins
          })
        } else {
          navigation.navigate(AppRoutes.home, {
            screen: WalletRoutes.Coins
          })
        }
      } catch (error) {
        failTransfer()
        completeTransfer()
      }
    }

    const onSend = async () => {
      setIsConfirmOpen(true)
    }

    const onSendFromWatching = async () => {
      try {
        if (!coinName) {
          // throw new Error('Coin transfer failed')
          throw new Error(translate('coinsScreen.coinTransferFailed'))
        }

        Keyboard.dismiss()
        startTransfer()
        const response = await web3.sendFungibleToken(selectedAddress, amount, route.params.address)
        if (response) {
          if (contact) {
            addRecentContactMutation.mutate(contact)
          }

          completeTransfer()
          navigation.navigate(AppRoutes.home, {
            screen: WalletRoutes.Coins
          })
        }
      } catch (error: any) {
        console.log('onSendFungibleToken.error: ', error.message)
        failTransfer()
        // get error message
        const errorMessage = error.message
        Toast({
          title: errorMessage,
          placement: 'top',
          status: Status.error,
          withMargin: true
        })
      }
    }

    const onConnect = async () => {
      if (!web3.isConnected) {
        await web3.open()
        return
      }
    }

    const onClose = () => {
      navigation.goBack()
    }

    const AddressList = (
      <Radio.Group
        value={selectedAddress}
        name="addressGroup"
        onChange={address => {
          setSelectedAddress(address)
        }}
        style={{
          width: '100%',
          height: '100%'
        }}
      >
        <FlatList
          data={toAddresses}
          style={{
            width: '100%'
          }}
          renderItem={({ item, index }) => (
            <Box width="100%" borderBottomWidth="1px" borderBottomColor="light.100" key={item}>
              <Pressable accessibilityLabel={item} accessibilityHint="">
                <Box
                  aria-label="Close"
                  marginY="10px"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text
                    ellipsizeMode="tail"
                    noOfLines={1}
                    fontFamily="Inter-Regular"
                    fontSize="11px"
                    // w="80%"
                  >
                    {item}
                  </Text>

                  <Radio
                    value={item}
                    aria-label="Close"
                    key={`${item}-address`}
                    accessibilityLabel={`${item}`}
                    accessibilityHint=""
                    alignSelf="flex-end"
                    mr="2px"
                  />
                </Box>
              </Pressable>
            </Box>
          )}
          showsVerticalScrollIndicator={false}
          keyExtractor={(item, index) => `identities-${index}`}
        />
      </Radio.Group>
    )

    const SendContent = (
      <Box flex={1} pt={4}>
        <Center>
          <Text
            _light={{
              color: theme.colors.textLightMode[600]
            }}
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
            fontSize={34}
            lineHeight={41}
            fontFamily={'Inter-Regular'}
            fontWeight={'700'}
          >
            {`${amount} ${coinName}`}
          </Text>
          <Text
            mt={2}
            _light={{
              color: theme.colors.textLightMode[300]
            }}
            _dark={{
              color: theme.colors.textDarkMode[300]
            }}
            tx="coinsScreen.sendCoins"
            txOptions={{
              amount,
              coinName: coinName,
              user: userName
            }}
          />
        </Center>
        <Box flexDirection={'row'} justifyContent={'center'} alignItems={'center'} mt={5}>
          <Center>
            <Box>
              <CoinAvatar
                // Coin
                source={coinImage || ''}
                size={100}
                name={coinName || ''}
                containerProps={{ zIndex: 0 }}
              />
              {/* <CoinAvatar
                    // Network
                    source={networkImage}
                    size={32}
                    name={''}
                    containerProps={{ zIndex: 1, position: 'absolute', bottom: 0, right: 0 }}
                  /> */}
            </Box>
          </Center>
          <Center
            width={16}
            borderRadius={999}
            height={16}
            mx={4}
            shadow={1}
            borderWidth={1}
            _light={{
              borderColor: theme.colors.grayCool[200],
              backgroundColor: theme.colors.white
            }}
            _dark={{
              borderColor: theme.colors.grayDarkMode[900],
              backgroundColor: theme.colors.grayDarkMode[700]
            }}
          >
            <Arrow width={24} height={16} fill={theme.colors.lightText} />
          </Center>
          <Avatar size={100} borderWidth={userImageShowBorder} url={userImage} name={userName} />
        </Box>
        {isSelfCustodial ? (
          <Box justifyContent={'center'} alignItems={'center'} mt={5}>
            {/* <Text>Gas Details</Text> */}
            {/* <Text>
              Limit:{' '}
              {Utils.formatUnits(
                gasEstimates.data?.gasLimit ?? 0,
                tokenMetadata?.data?.decimals ?? 18
              )}
            </Text>
            <Text>
              Max Prio Fee:{' '}
              {Utils.formatUnits(
                gasEstimates.data?.maxPriorityFeePerGas ?? 0,
                tokenMetadata?.data?.decimals ?? 18
              )}
            </Text>
            <Text>
              Max Fee:{' '}
              {Utils.formatUnits(
                gasEstimates.data?.maxFeePerGas ?? 0,
                tokenMetadata?.data?.decimals ?? 18
              )}
            </Text> */}
            <Text>
              Estimated Total:{' '}
              {route.params.amount +
                Number(
                  Utils.formatUnits(
                    gasEstimates.data?.estimatedTotal ?? 0,
                    tokenMetadata?.data?.decimals ?? 18
                  )
                )}
            </Text>
          </Box>
        ) : null}

        {/* {user && coin?.isWeb3 ? (
          <UserIdentities user={user} coin={coin} setWeb3address={setWeb3address} />
        ) : null} */}

        <Box mt={8} px={4}>
          {AddressList}
        </Box>

        <Box mt={8} px={4}>
          <CommentInput
            ref={inputRef}
            comment={detail}
            setComment={setDetail}
            autoCompleteType={false}
          />
        </Box>
      </Box>
    )

    const DesktopLayout = (
      <Box
        ref={ref}
        backgroundColor={styles.background}
        borderRadius={10}
        padding={6}
        shadow={'modal'}
        _web={{
          minHeight: '50vh'
        }}
      >
        <ScreenHeader
          headerLeftType="none"
          headerProps={{
            minHeight: 8, // 32px
            paddingX: 0
          }}
          headerRight={() => (
            <ScreenHeaderButton onPress={onClose}>
              <HeaderButtonClose color={styles.textColor} />
            </ScreenHeaderButton>
          )}
        />

        <VStack flex={1} alignItems={'stretch'} width="100%" marginY={8}>
          {SendContent}
        </VStack>

        <HStack
          width={'100%'}
          alignSelf={'flex-end'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <ButtonBack onPress={onClose}>{translate('common.back')}</ButtonBack>
          <Box>
            {!isSelfCustodial ? (
              <ButtonNext
                m={4}
                backgroundColor={styles.primary}
                onPress={onSendFromWatching}
                disabled={transferState.isOnGoing || transferState.isCompleted}
              >
                {translate(web3.isConnected ? 'common.send' : 'common.connect')}
              </ButtonNext>
            ) : (
              <ButtonNext
                m={4}
                backgroundColor={styles.primary}
                onPress={onSendFromSelfCustodial}
                disabled={transferState.isOnGoing || transferState.isCompleted}
              >
                {translate('common.send')}
              </ButtonNext>
            )}
          </Box>
        </HStack>
      </Box>
    )

    if (isDesktop) {
      return DesktopLayout
    }

    return (
      <KeyboardAvoidingView
        paddingBottom={insets.bottom}
        flex={1}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        <ActionSheetConfirmTransfer
          toAddress={selectedAddress}
          amount={amount}
          coinName={coinName}
          isOpen={isConfrimOpen}
          setOpen={setIsConfirmOpen}
          onSend={isSelfCustodial ? onSendFromSelfCustodial : onSendFromWatching}
        />
        <ScreenWrapper
          screenBackgroundColor={styles.background}
          statusBarBackgroundColor={styles.primary}
        >
          <ScreenHeader headerLeftPress={onClose} headerTextColor={styles.textColor}>
            <Title
              preset="h5"
              variant="semibold"
              color={styles.textColor}
              textAlign={'center'}
              tx="common.send"
            />
          </ScreenHeader>

          {SendContent}

          <Box>
            {!isSelfCustodial ? (
              <ButtonNext
                m={4}
                backgroundColor={styles.primary}
                onPress={web3.isConnected ? onSend : onConnect}
                disabled={transferState.isOnGoing || transferState.isCompleted}
              >
                {translate(web3.isConnected ? 'common.send' : 'common.connect')}
              </ButtonNext>
            ) : (
              <ButtonNext
                m={4}
                backgroundColor={styles.primary}
                onPress={onSend}
                disabled={transferState.isOnGoing || transferState.isCompleted}
              >
                {translate('common.send')}
              </ButtonNext>
            )}
          </Box>
        </ScreenWrapper>
      </KeyboardAvoidingView>
    )
  })
)

export default Confirmation

/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * MetadataJsonBase
 * auto generated base class for the model MetadataJsonModel.
 */
export const MetadataJsonModelBase = ModelBase
  .named('MetadataJson')
  .props({
    __typename: types.optional(types.literal("MetadataJson"), "MetadataJson"),
    address: types.union(types.undefined, types.string),
    name: types.union(types.undefined, types.string),
    mintAddress: types.union(types.undefined, types.string),
    image: types.union(types.undefined, types.null, types.string),
    creatorAddress: types.union(types.undefined, types.string),
    creatorTwitterHandle: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class MetadataJsonModelSelector extends QueryBuilder {
  get address() { return this.__attr(`address`) }
  get name() { return this.__attr(`name`) }
  get mintAddress() { return this.__attr(`mintAddress`) }
  get image() { return this.__attr(`image`) }
  get creatorAddress() { return this.__attr(`creatorAddress`) }
  get creatorTwitterHandle() { return this.__attr(`creatorTwitterHandle`) }
}
export function selectFromMetadataJson() {
  return new MetadataJsonModelSelector()
}

export const metadataJsonModelPrimitives = selectFromMetadataJson().address.name.mintAddress.image.creatorAddress.creatorTwitterHandle

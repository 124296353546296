import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { GradientButton, Loader, PressableOpacity, Text, theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Button, Stack } from 'native-base'

import BackIcon from '../../../assets/back.svg'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'
import { useEncryptAndStore } from '../../../hooks/useEncryptAndStore'
import { AppRoutes } from '../../../navigators'
import { useWalletDetails } from '../store'

type ButtonWordProps = {
  nthSelected: number
  onPress: () => void
  word: string
  isSelected: boolean
}

const ButtonWord = (props: ButtonWordProps) => {
  const { isDark } = useBusinessTheme()
  const { nthSelected, onPress, word, isSelected } = props

  const baseColor = isDark ? theme.colors.brand[500] : theme.colors.black
  const selectedColor = theme.colors.white

  const textColor = isSelected ? selectedColor : baseColor

  return (
    <Button
      variant={isSelected ? 'solid' : 'outline'}
      colorScheme="brand"
      borderColor={theme.colors.grayCool[400]}
      h="35px"
      borderRadius="100px"
      minW="52px"
      mb="10px"
      onPress={onPress}
    >
      <Text fontSize={13} lineHeight={13} color={textColor}>
        {word}
      </Text>
      {nthSelected > 0 && (
        <Box
          position="absolute"
          top="-20px"
          right="-20px"
          bgColor={theme.colors.blue[300]}
          borderRadius="20px"
          w="20px"
          h="20px"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <Text fontSize={10} color={theme.colors.black}>
            {nthSelected}
          </Text>
        </Box>
      )}
    </Button>
  )
}

const shuffleArray = (array: string[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export const Confirm = observer(() => {
  const { isDark } = useBusinessTheme()
  const navigation = useNavigation()
  const [selectedWords, setSelectedWords] = useState<string[]>([])
  const seeds = useWalletDetails.getState().seeds
  const [randomizeSeeds] = useState(shuffleArray(seeds.split(' ')))
  const encryptAndStoreMutation = useEncryptAndStore()

  const handleWordPress = (word: string) => {
    if (!selectedWords.includes(word)) {
      setSelectedWords(
        selectedWords.length >= 1 ? [selectedWords[selectedWords.length - 1], word] : [word]
      )
    } else {
      setSelectedWords(selectedWords.filter(w => w !== word))
    }
  }

  const onContinue = async () => {
    const uniqueSelectedWords = [...new Set(selectedWords)]
    try {
      const seedsArray = seeds.split(' ')
      if (
        uniqueSelectedWords[0] === seedsArray[0] &&
        uniqueSelectedWords[1] === seedsArray[seedsArray.length - 1]
      ) {
        await encryptAndStoreMutation.mutateAsync({ seeds })
        useWalletDetails.getState().setWalletDetails('', '')
        navigation.navigate(AppRoutes.BlockChainWallets)
      } else {
        alert('Order of selection is incorrect. Please check your copy and try again')
        setSelectedWords([])
      }
    } catch (error) {
      console.log('Something went wrong oncontinue: ', error)
      alert('Something went wrong confirming your wallet')
    }
  }

  return (
    <Box flex={1} safeArea={true}>
      <Box mb="20px">
        <PressableOpacity
          hitSlop={{
            top: 20,
            left: 20,
            bottom: 20,
            right: 20
          }}
          accessibilityRole="button"
          onPress={() => navigation.goBack()}
        >
          <BackIcon
            height={40}
            width={40}
            fill={!isDark ? theme.colors.darkText : theme.colors.white}
          />
        </PressableOpacity>
      </Box>
      <Box height="100%" w="100%" flex={1} px="16px">
        <Box flex={1} justifyContent="flex-start" alignItems="center" height="100%">
          <Text
            marginBottom="20px"
            color={!isDark ? theme.colors.darkText : theme.colors.white}
            fontFamily="Inter-Bold"
            fontSize="34px"
            textAlign="left"
            alignSelf="flex-start"
            lineHeight="41px"
            tx="newWalletScreen.confirmTitle"
          />

          <Text
            w="100%"
            fontFamily="Inter"
            fontSize="15px"
            textAlign="left"
            mb="20px"
            color={!isDark ? theme.colors.darkText : theme.colors.white}
            tx="newWalletScreen.confirmDescription"
          />

          <Stack direction="row" space={3} flexWrap="wrap">
            {randomizeSeeds.map(seed => (
              <ButtonWord
                key={seed}
                word={seed}
                isSelected={selectedWords.includes(seed)}
                onPress={() => handleWordPress(seed)}
                nthSelected={[1, 12][[...new Set(selectedWords)].indexOf(seed)]}
              />
            ))}
          </Stack>
        </Box>

        <Box justifyContent="center" width="100%" pb={20}>
          <GradientButton
            disabled={selectedWords.length !== 2 || encryptAndStoreMutation.isLoading}
            onPress={onContinue}
            h="52px"
            w="100%"
            py="15px"
            px="30px"
            alignItems="center"
          >
            {encryptAndStoreMutation.isLoading ? (
              <Loader size={30} color="white" />
            ) : (
              <Text
                alignSelf="center"
                fontFamily="Inter"
                color="white"
                fontSize={16}
                lineHeight={22}
                tx="common.continue"
              />
            )}
          </GradientButton>
        </Box>
      </Box>
    </Box>
  )
})

export default Confirm

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef } from 'react'

export const useSendMessage = () => {
  const webviewRef = useRef<HTMLIFrameElement>(null)

  const sendV1Message = useCallback(
    (name: string, data: any) => {
      if (!webviewRef || !webviewRef.current || !webviewRef.current.contentWindow) {
        return
      }

      const msg = {
        source: 'VatomicSDK',
        name: name,
        data: JSON.parse(JSON.stringify(data))
      }

      webviewRef.current.contentWindow.postMessage(msg, '*')
    },
    [webviewRef]
  )

  const sendV2Message = useCallback(
    (id: string, name: string, data: any, isRequest?: boolean) => {
      if (!webviewRef || !webviewRef.current || !webviewRef.current.contentWindow || !data) {
        return
      }

      // console.info('In sendV2Message', false, name, JSON.parse(JSON.stringify(data)))

      const msg = {
        [isRequest ? 'request_id' : 'response_id']: id,
        source: 'BLOCKv SDK',
        name,
        payload: JSON.parse(JSON.stringify(data)),
        version: '2.0.0'
      }

      webviewRef.current.contentWindow.postMessage(msg, '*')
    },
    [webviewRef]
  )

  return {
    sendV1Message,
    sendV2Message,
    webviewRef
  }
}

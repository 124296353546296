import React from 'react'
import { View } from 'react-native'
import { IEvent } from 'matrix-js-sdk'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'

import { useMessage, useMessageInputs } from '../../../queries'
import { useCommunitySpace } from '../../../useCommunitySpace'
import { Input } from '../stores'

import ModalEndTime from './ModalDateTimePicker/ModalEndTime'
import ModalPollLength from './ModalDateTimePicker/ModalPollLength'
import ModalStartTime from './ModalDateTimePicker/ModalStartTime'
import ModalAllowVote from './ModalAllowVote'
import ModalHideResults from './ModalHideResults'
import ModalQuestions from './ModalQuestions'
import ModalResponseAnchors from './ModalResponseAnchors'
import ModalScoreStyle from './ModalScoreStyle'
import ModalSketchCategory from './ModalSketchCategory'
import ModalSketches from './ModalSketches'
import ModalStakeSlider from './ModalStakeSlider'

type PluginDecorationInput = {
  type: string
  placeholder: string
  data?: any
  value: string
  additionalInfo?: string
}

type PluginDecorations = {
  type: string
  elements: PluginDecorationInput[]
}

export type DecorationData = SnapshotOut<typeof Input>

export const DecorationsElements = ({
  elements,
  matrixMessage,
  hasNoMessage,
  messageType
}: {
  elements: PluginDecorationInput[]
  matrixMessage?: IEvent
  hasNoMessage?: boolean
  messageType?: string
}) => {
  let messageInputs: any = null
  if (matrixMessage) {
    messageInputs = JSON.parse(matrixMessage.content.body)
  }

  const specialTypes = ['questions', 'sketches']

  const getDecorationData = (decoration: PluginDecorationInput) => {
    const { type, placeholder, data, value } = decoration

    if (messageInputs) {
      if (specialTypes.includes(type)) {
        const messageDecoration = messageInputs.elements.find(
          ({ type: decorationType }: { type: string }) => decorationType === type
        )

        if (messageDecoration) {
          if (messageDecoration.type === 'questions') {
            return {
              type,
              placeholder,
              additionalInfo: messageDecoration.content,
              value: 0
            }
          }

          if (messageDecoration.type === 'sketches') {
            return {
              type,
              placeholder,
              additionalInfo: messageDecoration.content.map((sketch: any) => {
                const media = typeof sketch.media === 'string' ? JSON.parse(sketch.media) : []
                return {
                  ...sketch,
                  media: media
                }
              }),
              value: 0
            }
          }
        }
      }

      if (type === 'timezone') {
        const timezone = messageInputs['timezone']
        return timezone
      }

      const inputName = decoration.type.replace(/-([a-z])/g, (match: string, letter: string) =>
        letter.toUpperCase()
      )
      const foundInput = messageInputs[inputName]

      return {
        type,
        placeholder,
        ...(data ? { additionalInfo: JSON.stringify(data) } : null),
        value: foundInput
      }
    } else {
      let defaultValue = null
      switch (value) {
        case 'string':
          defaultValue = ''
          break
        case 'boolean':
          defaultValue = false
          break
        case 'number':
          defaultValue = 0
          break
      }
      defaultValue =
        messageType === 'v.room.poll' && type === 'score-style'
          ? 'single-choice-poll'
          : defaultValue
      return {
        type,
        placeholder,
        ...(data ? { additionalInfo: JSON.stringify(data) } : null),
        value: defaultValue
      }
    }
  }

  return (
    elements?.map(decoration => {
      const { type, placeholder } = decoration
      const decorationData = hasNoMessage ? decoration : getDecorationData(decoration)

      switch (type) {
        case 'allow-vote':
          return <ModalAllowVote key={type} decorationData={decorationData} />
        // case 'stake-slider':
        //   return <ModalStakeSlider key={type} placeholder={placeholder} />
        case 'poll-length':
          return <ModalPollLength key={type} decorationData={decorationData} />
        case 'start-time':
          return <ModalStartTime key={type} decorationData={decorationData} />
        case 'end-time':
          return <ModalEndTime key={type} decorationData={decorationData} />
        case 'hide-results':
          return <ModalHideResults key={type} decorationData={decorationData} />
        case 'response-anchors':
          return <ModalResponseAnchors key={type} decorationData={decorationData} />
        case 'score-style':
          return <ModalScoreStyle key={type} decorationData={decorationData} />
        case 'questions':
          return <ModalQuestions key={type} decorationData={decorationData} />
        case 'sketches':
          return <ModalSketches key={type} decorationData={decorationData} />
        case 'sketch-category':
          return <ModalSketchCategory key={type} decorationData={decorationData} />
        default:
          return null
      }
    }) ?? null
  )
}

const ModalDecorations = observer(
  ({
    roomId,
    messageId,
    messageType
  }: {
    roomId: string
    messageId?: string
    messageType: string
  }) => {
    const { businessId } = useCommunitySpace()
    const { data: messagePluginInputs } = useMessageInputs([messageType], businessId)
    const { data: matrixMessage } = useMessage(roomId, messageId)

    const pluginDecorations =
      (messagePluginInputs?.find(
        ({ type }: { type: string }) => type === 'decorations'
      ) as PluginDecorations) ?? null

    const elements = pluginDecorations?.elements ?? []

    return (
      <View>
        {elements.length > 0 ? (
          <DecorationsElements
            elements={elements}
            matrixMessage={matrixMessage}
            messageType={messageType}
          />
        ) : null}
      </View>
    )
  }
)

export default ModalDecorations

import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useQuery } from '@tanstack/react-query'
import { translate } from '@vatom/utils'
import { PressableOpacity, Text, theme } from '@vatom/wombo'
import { BlurView } from 'expo-blur'
import * as Clipboard from 'expo-clipboard'
import { Box, TextArea } from 'native-base'

import { getWallet } from '../../../../modules/web3-wallet'
import BackIcon from '../../assets/back.svg'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { useWalletStore } from '../BlockChainWallets/WalletStore'
import { useWalletDetails } from '../NewWallet/store'

import CopyIcon from './assets/copy.svg'
import HiddenIcon from './assets/hidden.svg'
import ShowIcon from './assets/show.svg'

export const ShowPrivateKey = () => {
  const navigation = useNavigation()
  const { isDark } = useBusinessTheme()
  const [showSeeds, setShowSeeds] = useState(false)
  const selectedAddress = useWalletStore(state => state.selectedAddress)
  const seeds = useWalletDetails(state => state.seeds)

  const wallet = useQuery({
    queryKey: ['get-wallet-details', seeds, selectedAddress?.n],
    queryFn: () => {
      return getWallet(seeds, selectedAddress?.n)
    }
  })

  return (
    <Box flex={1} safeArea={true}>
      <Box mb="20px">
        <PressableOpacity
          hitSlop={{
            top: 20,
            left: 20,
            bottom: 20,
            right: 20
          }}
          accessibilityRole="button"
          onPress={() => navigation.goBack()}
        >
          <BackIcon
            height={40}
            width={40}
            fill={!isDark ? theme.colors.darkText : theme.colors.white}
          />
        </PressableOpacity>
      </Box>
      <Box h="100%" w="100%" flex={1} px="16px" justifyContent="flex-start">
        <Box flex={1} alignItems="center" height="100%" width="100%">
          <Text
            mb="20px"
            color={!isDark ? theme.colors.darkText : theme.colors.white}
            fontFamily="Inter-Bold"
            fontSize="34px"
            textAlign="left"
            lineHeight="41px"
            alignSelf="flex-start"
            tx="showPrivateKey.title"
          />

          <Text
            w="100%"
            fontFamily="Inter"
            fontSize="15px"
            textAlign="left"
            mb="20px"
            color={!isDark ? theme.colors.darkText : theme.colors.white}
            tx="showPrivateKey.description"
          />

          <Box
            borderRadius={10}
            borderWidth={1}
            borderColor={theme.colors.grayCool[200]}
            alignItems="center"
            w="100%"
            flexDirection="row"
            mb="20px"
            h="114px"
            overflow="hidden"
            display="block"
          >
            <TextArea
              placeholder="Text Area Placeholder"
              w="90%"
              h="114px"
              fontSize={16}
              autoCompleteType={undefined}
              color={isDark ? theme.colors.white : theme.colors.black}
              value={wallet.isLoading ? 'Loading...' : wallet.data?.privateKey}
              isDisabled={true}
              borderWidth={0}
            />
            <BlurView
              tint={!isDark ? 'light' : 'dark'}
              intensity={showSeeds ? 0 : 80}
              style={{
                position: 'absolute',
                top: 2,
                bottom: 2,
                left: 2,
                right: 0
              }}
            />

            <PressableOpacity
              alignItems="center"
              w="10%"
              h="100%"
              bgColor={theme.colors.grayCool[200]}
              justifyContent="center"
              onPress={() => {
                setShowSeeds(!showSeeds)
              }}
            >
              {showSeeds ? (
                <HiddenIcon height={16} width={16} />
              ) : (
                <ShowIcon height={16} width={16} />
              )}
            </PressableOpacity>
          </Box>
          <PressableOpacity
            alignSelf="flex-start"
            onPress={() => {
              Clipboard.setStringAsync(seeds)
            }}
          >
            <Text
              alignSelf="center"
              fontFamily="Inter"
              color={!isDark ? theme.colors.darkText : theme.colors.white}
              fontSize={13}
              lineHeight={18}
            >
              <CopyIcon height={16} width={16} /> {translate('showPrivateKey.copy')}
            </Text>
          </PressableOpacity>
        </Box>
      </Box>
    </Box>
  )
}

export default ShowPrivateKey

import { getSnapshot, IAnyModelType, IMSTMap, Instance, SnapshotOut, types } from 'mobx-state-tree'

import { Plugin } from '../BasePlugin'
import { withEventEmitter } from '../EventEmitter'
import { CompositeRegion, CompositeRegionStoreType } from '../Region'
import { SessionStore } from '../SessionInfo/SessionStore'
import { UserStore } from '../User'

export const DataPoolStore = types
  .model('DataPoolStore')
  .props({
    // _id: types.identifier,
    payload: types.optional(types.string, ''),
    sessionStore: types.optional(SessionStore, {}),
    user: types.optional(UserStore, {})
  })
  .extend(withEventEmitter)
  .actions(self => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onWebSocketMessage(ed: any) {
      self.emit('websocket.raw', ed)

      // if the message is a RPC message
      if (ed.msg_type === 'rpc') {
        self.emit('websocket.rpc', ed)
      }
      // if the user only wants state updates
      if (ed.msg_type === 'state_update') {
        self.emit('stateUpdate', ed)
      }

      // if the user only wants inventory updates
      if (ed.msg_type === 'inventory') {
        self.emit('inventory', ed)
      }

      // if the user only wants activity updates
      if (ed.msg_type === 'my_events') {
        self.emit('activity', ed)
      }

      // if the user only wants info updates
      if (ed.msg_type === 'info') {
        self.emit('info', ed)
      }

      if (ed) {
        self.emit('all', ed)
      }
    }
  }))
  .actions(self => ({
    onRegionUpdated(region: CompositeRegionStoreType) {
      self.emit('region.updated', getSnapshot(region))
    }
  }))
  .views(self => ({
    /** Retrieve information about storage etc */
    stats() {
      // Calculate size of local storage
      const sizeBytes = 0
      // @TODO: refactor this:
      // for (const key in localStorage)
      //   if (key.startsWith('sync.')) sizeBytes += localStorage[key].length

      // Done
      return {
        estimatedSize: sizeBytes
      }
    }
  }))

export type DataPoolStoreType = Instance<typeof DataPoolStore>

export interface DataPool extends DataPoolStoreType {
  plugins: IMSTMap<IAnyModelType>
  addPlugin: (plugin: Plugin) => void
  register: (plugin: Plugin) => Promise<void>
  close: (id: string) => void
  isLoading: boolean
  getPlugin: <T extends Plugin>(pluginName: string) => T | null
  region(id: string, descriptor?: string): CompositeRegion
  regions: CompositeRegion[]
  addRegion(region: CompositeRegion): void
  removeRegion(region: CompositeRegion): boolean
  clearCache(): void
  countTokens: number
}

export interface DataPoolStoreSnapshot extends SnapshotOut<typeof DataPoolStore> {
  plugins: Record<string, IAnyModelType>
}

import { IMSTArray, ISimpleType, SnapshotOut, types } from 'mobx-state-tree'

export const Input = types
  .model({
    type: types.optional(types.string, ''),
    placeholder: types.optional(types.string, ''),
    value: types.optional(types.union(types.string, types.number, types.boolean), ''),
    additionalInfo: types.optional(types.string, '')
  })
  .actions(self => ({
    setValue(value: string | number | boolean) {
      self.value = value
    }
  }))

export const PollChoice = types
  .model({
    index: types.number,
    value: types.optional(types.string, '')
  })
  .actions(self => ({
    setValue(value: string) {
      self.value = value
    }
  }))

export const Media = types
  .model({
    type: types.string,
    base64: types.optional(types.string, ''),
    uri: types.string,
    sieInMb: types.optional(types.number, 0),
    thumbnail: types.optional(types.string, ''),
    fileName: types.optional(types.string, '')
  })
  .actions(self => ({
    change(payload: any) {
      Object.assign(self, payload)
    }
  }))

export const SketchMedia = types
  .model({
    isSketch: types.boolean,
    type: types.string,
    base64: types.optional(types.string, ''),
    uri: types.string,
    sieInMb: types.optional(types.number, 0),
    thumbnail: types.optional(types.string, ''),
    fileName: types.optional(types.string, '')
  })
  .actions(self => ({
    change(payload: any) {
      Object.assign(self, payload)
    }
  }))

export const Question = types
  .model({
    id: types.number,
    title: types.optional(types.string, ''),
    text: types.optional(types.string, ''),
    scoreStyle: types.optional(types.string, ''),
    responseAnchor: types.optional(types.string, ''),
    isSelected: types.optional(types.boolean, false),
    pollChoices: types.optional(types.array(PollChoice), [])
  })
  .actions(self => ({
    setData(payload: any) {
      Object.assign(self, payload)
    },
    setIsSelected(payload: boolean) {
      self.isSelected = payload
    }
  }))

export const Sketch = types
  .model({
    id: types.number,
    title: types.optional(types.string, ''),
    text: types.optional(types.string, ''),
    isSelected: types.optional(types.boolean, false),
    media: types.optional(types.array(SketchMedia), []),
    category: types.optional(types.string, '')
  })
  .actions(self => ({
    setData(payload: any) {
      Object.assign(self, payload)
    },
    setIsSelected(payload: boolean) {
      self.isSelected = payload
    }
  }))

export const Timezone = types.model({
  key: types.string,
  offset: types.number
})

export const AddMessageModalStore = types
  .model({
    inputs: types.optional(types.array(Input), []),
    additionalInputs: types.optional(types.array(Input), []),
    pollChoices: types.optional(types.array(PollChoice), []),
    toolbarHeight: types.optional(types.number, 0),
    questions: types.optional(types.array(Question), []),
    sketches: types.optional(types.array(Sketch), []),
    media: types.optional(types.array(Media), []),
    timezone: types.optional(Timezone, {
      key: '',
      offset: 0
    }),
    linkPreviews: types.optional(types.array(types.string), []),
    isMentionsOpen: types.optional(types.boolean, false)
  })
  .actions(self => ({
    setInputs(inputs: any) {
      self.inputs = inputs
    },
    addInput(inputs: any) {
      self.inputs.push(inputs)
    },
    resetInputs() {
      self.inputs = self.inputs.map((input: any) => ({ ...input, value: '' })) as IMSTArray<
        typeof Input
      >
    },
    setAdditionalInputs(additionalInputs: any) {
      self.additionalInputs = additionalInputs
    },
    addAdditionalInputs(additionalInputs: any) {
      self.additionalInputs.push(additionalInputs)
    },
    initPollChoices(payload: any) {
      self.pollChoices = payload
    },
    removeAllPollChoices() {
      self.pollChoices.clear()
    },
    addNewPollChoice() {
      self.pollChoices.push({
        index: self.pollChoices.length + 1
      })
    },
    addPollChoice(payload: SnapshotOut<typeof PollChoice>) {
      self.pollChoices.push(payload)
    },
    removePollChoice(index: number) {
      const filteredPollChoices = self.pollChoices.filter(pollChoice => pollChoice.index !== index)
      self.pollChoices = filteredPollChoices.map((choice, index: number) => ({
        index: index + 1,
        value: choice.value
      })) as IMSTArray<typeof PollChoice>
    },
    setIsMentionsOpen(payload: boolean) {
      self.isMentionsOpen = payload
    },
    setToolbarHeight(toolbarHeight: number) {
      self.toolbarHeight = toolbarHeight
    },
    addQuestion(payload: any) {
      const index = self.questions.findIndex(question => question.id === payload.id)

      if (index !== -1) {
        self.questions[index] = { ...payload }
      } else {
        self.questions.push(payload)
      }
    },
    removeQuestion(questionId: number) {
      self.questions = self.questions.filter(question => question.id !== questionId) as IMSTArray<
        typeof Question
      >
    },
    setQuestions(payload: any) {
      self.questions = payload
    },
    addSketch(payload: any) {
      const index = self.sketches.findIndex(sketch => sketch.id === payload.id)
      if (index !== -1) {
        self.sketches[index] = { ...payload }
      } else {
        self.sketches.push(payload)
      }
    },
    removeSketch(sketchId: number) {
      self.sketches = self.sketches.filter(sketch => sketch.id !== sketchId) as IMSTArray<
        typeof Sketch
      >
    },
    setSketches(payload: any) {
      self.sketches = payload
    },
    reorderQuestions(payload: any) {
      self.questions = payload
    },
    setMedia(media: IMSTArray<typeof Media>) {
      self.media = self.media.concat(media) as IMSTArray<typeof Media>
    },
    removeMedia(uri: string) {
      self.media = self.media.filter(media => media.uri !== uri) as IMSTArray<typeof Media>
    },
    reorderMedia(payload: any) {
      self.media = payload
    },
    resetMedia() {
      self.media.clear()
    },
    setTimezone(timezone: any) {
      self.timezone = timezone
    },
    addLinkPreview(link: string) {
      self.linkPreviews.push(link)
    },
    removeLinkPreview(url: string) {
      self.linkPreviews = self.linkPreviews.filter(link => link !== url) as IMSTArray<
        ISimpleType<string>
      >
    },
    reset() {
      self.inputs.clear()
      self.additionalInputs.clear()
      self.pollChoices.clear()
      self.media.clear()
      self.sketches.clear()
      self.timezone = {
        key: '',
        offset: 0
      }
      self.questions.clear()
    }
  }))

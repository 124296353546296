import { groupBy } from 'lodash'

import { useMatrixUser } from './queries'
import { MatrixData, RoomEvent, SimplifiedRoom } from './types'

const sortRoomsByUserActivity = (rooms: SimplifiedRoom[]) => {
  return rooms.sort((a, b) => b.lastUserActivityTs - a.lastUserActivityTs)
}

export const groupedRoomsByBusiness = (
  matrixData: MatrixData,
  matrixUser: ReturnType<typeof useMatrixUser>
) => {
  const simplifiedRooms = matrixUser.isSuccess
    ? simplifyRooms(matrixData, matrixUser.data?.user_id)
    : []
  if (!simplifiedRooms.length) {
    return {}
  }
  const sortedRoomsByUserActivity = sortRoomsByUserActivity(simplifiedRooms)
  const grouped = groupBy(sortedRoomsByUserActivity, item => item.businessId)

  return grouped
}

export const getBusinessIdsInRoomsSelector = (
  matrixData: MatrixData,
  matrixUser: ReturnType<typeof useMatrixUser>
) => {
  const groupedRooms = groupedRoomsByBusiness(matrixData, matrixUser)
  const businessIds: string[] = Object.keys(groupedRooms)

  return businessIds
}

export const getRoomsByBusinessIdsSelector = (
  matrixData: MatrixData,
  matrixUser: ReturnType<typeof useMatrixUser>,
  businessIds: string[]
) => {
  const simplifiedRooms = matrixUser.isSuccess
    ? simplifyRooms(matrixData, matrixUser.data?.user_id)
    : []
  if (!simplifiedRooms.length) {
    return []
  }
  const sortedRoomsByUserActivity = sortRoomsByUserActivity(simplifiedRooms)
  const rooms = sortedRoomsByUserActivity.filter(room => businessIds.includes(room.businessId))

  return rooms
}

export const getRoomsByBusinessIdSelector = (
  matrixData: MatrixData,
  matrixUser: ReturnType<typeof useMatrixUser>,
  businessId: string
) => {
  const simplifiedRooms = matrixUser.isSuccess
    ? simplifyRooms(matrixData, matrixUser.data?.user_id)
    : []
  if (!simplifiedRooms.length) {
    return []
  }
  const sortedRoomsByUserActivity = sortRoomsByUserActivity(simplifiedRooms)
  const rooms = sortedRoomsByUserActivity.filter(room => room.businessId === businessId)

  return rooms
}

export const getAdminRoomsSelector = (
  matrixData: MatrixData,
  matrixUser: ReturnType<typeof useMatrixUser>
) => {
  const simplifiedRooms = matrixUser.isSuccess
    ? simplifyRooms(matrixData, matrixUser.data?.user_id)
    : []
  if (!simplifiedRooms.length) {
    return []
  }

  return simplifiedRooms.filter(room => {
    if (room.membersDetails && matrixUser.data?.user_id) {
      const isAdmin = room.membersDetails[matrixUser.data.user_id]?.powerLevel ?? 0 >= 90

      return isAdmin
    }

    return false
  })
}

export const getLastVisisitedRoomsSelector = (
  matrixData: MatrixData,
  matrixUser: ReturnType<typeof useMatrixUser>,
  limit = 5
) => {
  const simplifiedRooms = matrixUser.isSuccess
    ? simplifyRooms(matrixData, matrixUser.data?.user_id)
    : []
  if (!simplifiedRooms.length) {
    return []
  }
  const sortedRoomsByUserActivity = sortRoomsByUserActivity(simplifiedRooms)

  if (limit) {
    return sortedRoomsByUserActivity.slice(0, limit)
  }

  return sortedRoomsByUserActivity
}

export const getRoomsByMemberIdSelector = (matrixData: MatrixData, memberId: string) => {
  const simplifiedRooms = simplifyRooms(matrixData, memberId)
  if (!simplifiedRooms.length) {
    return []
  }

  const grouped = simplifiedRooms.filter(room => {
    const memberIds = room.membersDetails ? Object.keys(room.membersDetails) : []
    return memberIds.includes(memberId)
  })

  return grouped
}

const simplifyRooms = (matrixData: MatrixData, memberId: string) => {
  const final: SimplifiedRoom[] = []

  const simplifyEvents = (events: RoomEvent[], matrixRoomId: string) => {
    const simplified: SimplifiedRoom = {
      spaceId: '',
      businessId: '',
      matrixRoomId,
      lastUserActivityTs: 0,
      membersDetails: {}
    }

    events.forEach(roomData => {
      switch (roomData.type) {
        case 'm.room.create':
          if (roomData.content['v.businessId'] && roomData.content['v.spaceId']) {
            simplified.businessId = roomData.content['v.businessId']
            simplified.spaceId = roomData.content['v.spaceId']
          }
          break

        case 'm.room.join_rules':
          simplified.visibility = roomData.content['join_rule']
          break

        case 'm.room.name':
          simplified.displayName = roomData.content['name']
          break

        case 'm.room.avatar':
          simplified.cover = roomData.content['url']
          break

        case 'm.room.member':
          if (roomData.content['membership'] === 'join' && simplified.membersDetails) {
            const name = roomData.content['displayname'] as string
            const senderDetails = simplified.membersDetails[roomData.sender]

            if (senderDetails && senderDetails.name) {
              senderDetails.name = name
            } else {
              simplified.membersDetails[roomData.sender] = {
                name
              }
            }
          }
          break

        case 'm.room.power_levels':
          if (simplified.membersDetails) {
            for (const memberId in roomData.content.users) {
              const memberDetails = simplified.membersDetails[memberId]
              const powerLevel = roomData.content.users[memberId]

              simplified.membersDetails[memberId] = memberDetails
                ? { ...memberDetails, powerLevel }
                : { powerLevel }
            }
          }
          break

        default:
          break
      }

      // This looks bad but the idea is to have all the members details in one place and to update it every time a member do something
      if (simplified.membersDetails) {
        if (
          simplified.membersDetails[roomData.sender] &&
          simplified.membersDetails[roomData.sender].lastActivityTs
        ) {
          simplified.membersDetails[roomData.sender].lastActivityTs = Math.max(
            roomData.origin_server_ts,
            simplified.membersDetails[roomData.sender].lastActivityTs ?? 0
          )
        } else {
          if (simplified.membersDetails && simplified.membersDetails[roomData.sender]) {
            simplified.membersDetails[roomData.sender].lastActivityTs = roomData.origin_server_ts
          } else {
            simplified.membersDetails[roomData.sender] = {
              lastActivityTs: roomData.origin_server_ts
            }
          }
        }
      }

      if (roomData.sender === memberId) {
        simplified.lastUserActivityTs = Math.max(
          roomData.origin_server_ts,
          simplified.lastUserActivityTs
        )
      }
    })

    if (simplified.spaceId !== '' && simplified.displayName !== '') {
      final.push(simplified)
    }
  }

  if (matrixData?.rooms?.join) {
    for (const key in matrixData.rooms.join) {
      const room = matrixData.rooms.join[key]
      if (room.state?.events && room.timeline?.events && !matrixData.rooms.leave?.[key]) {
        simplifyEvents([...room.state.events, ...room.timeline.events], key)
      }
    }
  }

  return final
}

import React from 'react'
import { Keyboard, TouchableWithoutFeedback } from 'react-native'
import { DestinationTypes } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { Box, Center, Image, Text } from 'native-base'

import PrimaryText from '../../ActionSheets/partials/PrimaryText'

import { Sender } from './Senders'
import { useSender } from './SendProvider'

export const NoResult = () => {
  const { token, lastSearchString } = useSender()

  if (lastSearchString.length === 0) return null

  return (
    <Box flexGrow={1}>
      {token.type.includes('vatom') ? <NoResultForVatom /> : <NoResultForNft />}
    </Box>
  )
}

export default NoResult

const NoResultForVatom = () => {
  const { token, destination, isShare, lastSearchString } = useSender()

  const isValid =
    destination &&
    'isValid' in destination &&
    destination?.isValid &&
    (destination.type === DestinationTypes.Email || destination.type === DestinationTypes.Phone)

  return (
    <TouchableWithoutFeedback
      style={{
        flexGrow: 1
      }}
      accessibilityRole="button"
      onPress={() => Keyboard.dismiss()}
    >
      <Box alignItems="center" marginTop={4}>
        <Text marginBottom="26px" fontFamily="Inter-Regular" fontSize="15px" textAlign="center">
          {translate('shareNft.weDidNot')} <Text fontFamily="Inter-Bold">{lastSearchString}</Text>
          {translate('shareNft.inOurRecords')}{' '}
          <Text fontFamily="Inter-Bold">{token?.metadata?.name}</Text>
          {translate('shareNft.nft')}
        </Text>
        <Center marginTop={4}>
          <Sender token={token} destination={destination} invitation isShare={isShare} />
        </Center>
        {!isValid && (
          <Center marginTop={8}>
            <Image
              accessibilityIgnoresInvertColors={true}
              source={require('../assets/no-result.png')}
              height="182px"
              width="170px"
              marginBottom="20px"
              alt="no-result"
            />
          </Center>
        )}
      </Box>
    </TouchableWithoutFeedback>
  )
}

const NoResultForNft = () => {
  const { lastSearchString, token, destination, isShare } = useSender()

  return (
    <TouchableWithoutFeedback
      accessibilityRole="button"
      onPress={() => Keyboard.dismiss()}
      style={{
        flexGrow: 1
      }}
    >
      <Box marginBottom="16px" alignItems="center" flex={1}>
        <Box flex={1} justifyContent="center" marginBottom="30px">
          <PrimaryText text="Transfer to blockchain Wallet?" />
          <Text marginBottom="16px" fontFamily="Inter-Medium" fontSize="15px" textAlign="center">
            {translate('shareNft.willBeMoved')}{' '}
            <Text fontFamily="Inter-Regular">{lastSearchString.toUpperCase()}</Text>{' '}
            {translate('shareNft.sureHasAccess')}
          </Text>
        </Box>
        <Sender token={token} destination={destination} isShare={isShare} />
      </Box>
    </TouchableWithoutFeedback>
  )
}

import React from 'react'

import { useMessage } from '../../../queries'

import ModalImagesSlider from './ModalImagesSlider'

const ModalImagesSliderHolder = ({ roomId, messageId }: { roomId: string; messageId?: string }) => {
  const { data: matrixMessage } = useMessage(roomId, messageId)
  let mediaJSON = ''

  if (matrixMessage) {
    const { media } = matrixMessage.content
    mediaJSON = media ?? ''
  }

  return <ModalImagesSlider mediaJSON={mediaJSON} />
}

export default ModalImagesSliderHolder

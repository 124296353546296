/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Alert, Platform } from 'react-native'
import { BVatomTokenType } from '@vatom/BVatom/plugin'
import { TokenType, ViewMode } from '@vatom/sdk/core'
import { FaceHandler, useSDK } from '@vatom/sdk/react'

import logger from '../../../../logger'
import BridgeV1 from '../BridgeV1'
import BridgeV2 from '../BridgeV2'

import { useSendMessage } from './useSendMessage'

interface IUseWebFace {
  token: BVatomTokenType
  viewMode?: ViewMode
  ref: React.ForwardedRef<FaceHandler>
  onMessage?: (name: string, payload: any) => Promise<void>
}

class CodeError extends Error {
  public code: string
  constructor(message: string | undefined) {
    super(message)
    this.code = ''
  }
}

let countRenders = 0

export const useWebFace = ({ token, viewMode, ref, onMessage }: IUseWebFace) => {
  const { sendV1Message, sendV2Message, webviewRef } = useSendMessage()
  const vatom = token as BVatomTokenType
  const version = useRef<number>(0)
  // const [version, setVersion] = useState<number>()

  // wait for a message from the vatom to determine if children should be observed
  const observeChildren = useRef(false)

  const [pendingRequests, setPendingRequests] = useState<{ [key: string]: any }>({})
  const [face, setFace] = useState(vatom.getFace(viewMode!))

  const sdk = useSDK()

  const bridgeV1 = useMemo<BridgeV1>(
    () => new BridgeV1(vatom.api, sdk.vatomIncApi, vatom),
    [sdk.vatomIncApi, vatom]
  )

  const bridgeV2 = useMemo<BridgeV2>(
    () =>
      new BridgeV2(vatom.api, sdk.vatomIncApi, vatom, {
        ...face,
        properties: { ...face?.properties, config: { ...face?.properties.parsedConfig } }
      }),
    [face, sdk.vatomIncApi, vatom]
  )

  useImperativeHandle(ref, () => ({
    sendRequest: (name: string, data: any) => {
      return sendRequest(name, data)
    }
  }))

  const addPendingRequest = useCallback(
    (responseId: string, request: any) => {
      const requests = pendingRequests
      requests[responseId] = request
      setPendingRequests(requests)
    },
    [pendingRequests]
  )

  const removePendingRequest = useCallback(
    (responseId: string) => {
      // Not sure if this will work
      delete pendingRequests[responseId]
      setPendingRequests(pendingRequests)
    },
    [pendingRequests]
  )

  /** Called when the viewer wants to send a request to the face. Only supported with the V2 bridge. */
  const sendRequest = useCallback(
    (name: string, data: any): Promise<any> => {
      // Send it
      const id = Math.random().toString(36).substr(2)
      sendV2Message(id, name, data, true)

      // Store response promise
      return new Promise((resolve, reject) => {
        addPendingRequest(id, { resolve, reject })
      })
    },
    [addPendingRequest, sendV2Message]
  )

  useEffect(() => {
    const encodeVatom = () => {
      return {
        ...vatom.payload,
        faces: vatom.vatomFaces,
        actions: vatom.vatomActions
      }
    }

    // When the token changes
    if (!vatom || !face) return

    if (version.current === 1) {
      const resources: { [name: string]: string } = {}
      for (const resource of vatom.properties.resources) {
        resources[resource.name] = resource.value.value
      }

      sendV1Message('vatom.updated', {
        vatomInfo: {
          id: vatom.id,
          properties: vatom.properties,
          resources: resources,
          faceProperties: face.properties
        }
      })
    } else {
      sendV2Message('res_1', 'core.vatom.update', { vatom: encodeVatom() }, true)
    }
  }, [face, sendV1Message, sendV2Message, vatom, version, vatom.private])

  const processIncomingBridgeMessage = useCallback(
    async (name: string, payload: any) => {
      // logger.info('processIncomingBridgeMessage', name, payload)

      if (!bridgeV1) return
      if (!bridgeV2) return

      switch (name) {
        case 'vatom.init': {
          // setVersion(1)
          version.current = 1
          return bridgeV1.init()
        }
        case 'vatom.children.get':
          return bridgeV1.getChildren()
        // case "vatom.rpc.call":
        // 	return bridgeV1.rpc();
        case 'vatom.performAction':
          return bridgeV1.performAction(payload)
        case 'user.profile.fetch':
          return bridgeV1.getUser()
        case 'user.avatar.fetch':
          return bridgeV1.getUser()
        case 'vatom.patch':
          return bridgeV1.patchVatom(payload)
        // case "vatom.get":
        // 	return bridgeV1.getVatom(payload);
        case 'core.init':
          // setVersion(2)
          version.current = 2
          return bridgeV2.init()
        case 'core.user.get':
          return bridgeV2.getUserProfile(payload)
        case 'core.user.current.get':
          // @TODO: load this from the state, we already have this??
          return bridgeV2.getCurrentUser(payload)
        // case "core.vatom.get":
        // 	return bridgeV2.getVatom();
        case 'core.vatom.children.get':
          return await bridgeV2.getVatomChildren()
        case 'core.vatom.parent.set':
          return bridgeV2.vatomParentSet(payload)
        case 'core.vatom.children.observe':
          observeChildren.current = true
          // eslint-disable-next-line no-case-declarations
          const res = await bridgeV2.getVatomChildren()

          sendV2Message(
            Math.random().toString(),
            'core.vatom.children.update',
            { id: token?.id, vatoms: res.vatoms },
            true
          )
          return res
        case 'core.action.perform':
          try {
            const response = await bridgeV2.performAction(payload)
            return response
          } catch (error: any) {
            return { error: error?.message }
          }
        case 'core.resource.encode':
          return bridgeV2.encodeResource(payload)
        case 'core.inventory.stats':
          return bridgeV2.inventoryStats(payload)
        default:
          // Unknown event. Pass on to onMessage handler
          if (onMessage) {
            return onMessage(name, payload)
          }
          // No listener, this is an error
          return Promise.reject(new Error('Bridge message not implemented.'))
      }
    },
    [bridgeV1, bridgeV2, onMessage, sendV2Message, token?.id]
  )

  const onIncomingBridgeMessage = useCallback(
    (webViewEvent: any) => {
      try {
        const event = webViewEvent.nativeEvent
        const payload = Platform.OS === 'web' ? webViewEvent.data : JSON.parse(event.data)

        if (!payload.name) {
          return
        }

        logger.info('[WebFace.onIncomingBridgeMessage] payload', payload.name)

        if (payload.start) {
          // logger.info('[WebFace.onIncomingBridgeMessage] start Webview bridge initiated')
          return
        }

        // V1: Check if there's a response ID, if so the web face is expecting a reply with that ID
        let responseID: string | null = null
        if (payload.responseID) {
          responseID = payload.responseID
        }

        // V2: Check if this is a response to one of our requests
        if (payload.response_id && pendingRequests[payload.response_id]) {
          // Complete the promise. Check for error or success
          if (payload.error_message) {
            // Failed
            const err = new CodeError(payload.error_message)
            err.code = payload.error_code || 'unknown_error'
            pendingRequests[payload.response_id].reject(err)
            removePendingRequest(payload.response_id)
            return
          } else {
            // Success
            pendingRequests[payload.response_id].resolve(payload.payload)
            removePendingRequest(payload.response_id)
            return
          }
        }

        // Process it, get response
        Promise.resolve(processIncomingBridgeMessage(payload.name, payload.data || payload.payload))
          .then(resp => {
            // logger.info('processed bridge message', payload.name, payload, resp)
            if (payload.version === '2.0.0') {
              // Done, send response back
              sendV2Message(payload.request_id, payload.name, resp)
            } else {
              sendV1Message(responseID || resp._responseName, resp)
            }
          })
          .catch(err => {
            logger.warn(
              'Error processing bridge message:',
              err,
              'responseID',
              responseID,
              'with payload',
              payload
            )
          })
      } catch (error) {
        logger.error('[WebFace.onIncomingBridgeMessage] error', error)
      }
    },
    [
      pendingRequests,
      processIncomingBridgeMessage,
      removePendingRequest,
      sendV1Message,
      sendV2Message
    ]
  )

  countRenders++
  console.log('useWebFace countRenders', countRenders)

  return {
    face,
    onIncomingBridgeMessage,
    webviewRef
  }
}

import { ComponentProps, useCallback, useMemo, useRef } from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import { isVatom } from '@vatom/BVatom/plugin'
import { useCrateStore, useSDK, useTokenInventory } from '@vatom/sdk/react'
import { Audio, ResizeMode, Video } from 'expo-av'
import { Image } from 'expo-image'
import { observer } from 'mobx-react-lite'
import { Box } from 'native-base'

import { NFTDetailProps } from '../NFTDetail'

export type CrateProps = NFTDetailProps & {
  onClose: () => void
  onMessage: (name: string, payload: any) => Promise<void>
}

export type CrateConfigFace = {
  showConfetti?: boolean
  toastAppearanceTime: number
  video: {
    ref: string
    type: string
  }
  activate_action?: string
  loop?: boolean
}

type VatomOutput<T> = {
  error: null
  output: T
}

export type PreCreatedVatom = {
  icon: string
  name: string
}

export type PreCreatedVatoms = PreCreatedVatom[]

type CrateResponse = VatomOutput<
  VatomOutput<{
    vatoms: PreCreatedVatom[]
  }>
>[]

const viewMode = 'engaged'

export const Crate = observer(({ route, onMessage }: CrateProps) => {
  const ref = useRef<Video>(null)
  const sdk = useSDK()
  const { height } = useWindowDimensions()
  const { tokenId } = route.params
  const { token } = useTokenInventory(tokenId)
  const vatom = token && isVatom(token) ? token : null
  const face = vatom?.getFace(viewMode)
  const Url = face?.properties?.parsedConfig?.video?.ref ?? vatom?.metadata?.animation_url
  const videoUrl = Url || ''
  const coverImage = token?.resources.find(r => r.name === 'CoverImage')
  const videoDidLoad = useRef(false)

  const unlockKey = vatom?.unlockKey(viewMode)

  const keyVatom = useMemo(() => {
    const key = vatom
      ?.getChildren()
      .find(tk => isVatom(tk) && tk?.properties?.template_variation === unlockKey)
    return key
  }, [unlockKey, vatom])

  const enableAudio = async () => {
    await Audio.setAudioModeAsync({
      allowsRecordingIOS: false,
      playsInSilentModeIOS: true,
      staysActiveInBackground: false,
      shouldDuckAndroid: false
    })
  }

  const shouldPlayVideo = useMemo(() => {
    if (!unlockKey) {
      return true
    }
    return keyVatom !== undefined
  }, [keyVatom, unlockKey])

  // If no key and no cover image then render video frame as cover without full screen
  const shouldRenderVideoFrameAsCover = !shouldPlayVideo && !coverImage?.url
  const shouldRenderCoverImage = !shouldPlayVideo && coverImage?.url

  const activate = useCallback(async () => {
    if (!vatom) {
      throw new Error('Vatom required for playing a crate')
    }
    useCrateStore.getState().setisRewardsActive()
    const face_action_name = face?.properties?.parsedConfig.activate_action
    const vatom_action_name = vatom.properties.activate_action
    const actionName = face_action_name || vatom_action_name || 'Activate'
    try {
      const response = await vatom.performAction(actionName, null)

      const res = response?.pre as CrateResponse

      if (res) {
        const vatoms = res.flatMap(r => r.output.output.vatoms)
        useCrateStore.getState().storeCrateNotification({
          tokens: vatoms,
          token: vatom,
          config: face?.properties?.parsedConfig
        })
      }

      console.info(res)
    } catch (error) {
      console.error(error)
    }
  }, [face?.properties?.parsedConfig, vatom])

  const onLoadVideo: ComponentProps<typeof Video>['onLoad'] = status => {
    videoDidLoad.current = true
    console.log('CRATE.onLoadVideo', status)
    activate()

    enableAudio().finally(() => {
      ref.current?.playAsync()
    })
  }

  const onStatusChange: ComponentProps<typeof Video>['onPlaybackStatusUpdate'] = videoStatus => {
    if (!vatom) {
      throw new Error('vatom needed')
    }

    if (!videoStatus.isLoaded) {
      return
    }

    // Safari mobile doesn't fire onLoad callback
    if (!videoDidLoad.current) {
      onLoadVideo(videoStatus)
    }

    if (!videoStatus.durationMillis) {
      return
    }

    const remainingDurationMillis = videoStatus.durationMillis - videoStatus.positionMillis

    if (face?.properties?.parsedConfig.toastAppearanceTime) {
      const targetAppearance =
        face?.properties?.parsedConfig.toastAppearanceTime >= 0
          ? face?.properties?.parsedConfig.toastAppearanceTime * 1000
          : videoStatus.durationMillis -
            Math.abs(face?.properties?.parsedConfig.toastAppearanceTime * 1000)
      if (
        targetAppearance <= videoStatus.positionMillis &&
        !useCrateStore.getState().shouldShowCrateRewards
      ) {
        useCrateStore.getState().openRewardCard()
      }
    } else {
      if (remainingDurationMillis <= 1000 && !useCrateStore.getState().shouldShowCrateRewards) {
        useCrateStore.getState().openRewardCard()
      }
    }

    if (videoStatus.didJustFinish && !useCrateStore.getState().didVideoReproductionEnded) {
      ref.current?.pauseAsync()

      onMessage('viewer.view.close', {})

      vatom.performAction('Delete').catch(error => {
        console.error('Error deleting crate', error)
      })
      useCrateStore.getState().setDidVideoReproductionEnded(true)
    }

    return
  }

  if (!vatom) {
    return null
  }

  if (shouldRenderCoverImage) {
    return (
      <Box
        style={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flex: 1
        }}
      >
        <Image
          style={{
            width: '100%',
            height: '100%',
            resizeMode: 'contain'
          }}
          alt={'token_cover'}
          source={{
            uri: coverImage?.url,
            headers: {
              Accept: '*/*'
            }
          }}
          accessibilityIgnoresInvertColors
        />
      </Box>
    )
  }

  if (shouldRenderVideoFrameAsCover) {
    return (
      <Box
        // @ts-expect-error 900px maxwidth is for web. Anyone who adds a new props here should remove this comment first
        style={{
          maxWidth: Platform.OS === 'web' ? '900px' : '100%',
          // maxWidth: '900px',
          maxHeight: height,
          width: '100%',
          height: '100%'
        }}
      >
        <Video
          ref={ref}
          resizeMode={ResizeMode.CONTAIN}
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            zIndex: 9
          }}
          source={{ uri: videoUrl }}
          //   onLoad={onLoadVideo}
          //   onPlaybackStatusUpdate={onStatusChange}
          useNativeControls={false}
          isLooping={false}
        />
      </Box>
    )
  }

  // Uncomment if needed, seems to look good on web using full screen too (this makes web crate not be full screen)
  //   if (Platform.OS === 'web') {
  //     return (
  //       <Box
  //         // @ts-expect-error 900px maxwidth is for web. Anyone who adds a new props here should remove this comment first
  //         style={{
  //           maxWidth: Platform.OS === 'web' ? '900px' : '100%',
  //           // maxWidth: '900px',
  //           maxHeight: height,
  //           width: '100%',
  //           height: '100%'
  //         }}
  //       >
  //         <Video
  //           ref={ref}
  //           resizeMode={ResizeMode.CONTAIN}
  //           style={{
  //             width: '100%',
  //             height: '100%',
  //             position: 'relative',
  //             zIndex: 9
  //           }}
  //           source={{ uri: videoUrl }}
  //           onLoad={onLoadVideo}
  //           onPlaybackStatusUpdate={onStatusChange}
  //           useNativeControls={false}
  //           isLooping={false}
  //         />
  //       </Box>
  //     )
  //   }

  return (
    <Video
      ref={ref}
      resizeMode={ResizeMode.COVER}
      style={{
        height: height,
        minHeight: height,
        position: 'absolute',
        flex: 1,
        zIndex: 9,
        bottom: 0,
        top: 0,
        minWidth: '100%'
      }}
      source={{ uri: videoUrl }}
      // onLoad={onLoadVideo}
      onPlaybackStatusUpdate={onStatusChange}
      useNativeControls={false}
      isLooping={false}
      onError={error => {
        alert('Something went wrong loading the video')
      }}
    />
  )
})

import React from 'react'
import { Keyboard, StyleProp, TouchableWithoutFeedback, ViewStyle } from 'react-native'

type HideKeyboardProps = React.PropsWithChildren & {
  style?: StyleProp<ViewStyle>
}

function HideKeyboard({ children, style }: HideKeyboardProps) {
  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false} style={style || {}}>
      {children}
    </TouchableWithoutFeedback>
  )
}

export { HideKeyboard }
export type { HideKeyboardProps }

import React from 'react'
import { Text } from '@vatom/wombo'
import { View } from 'native-base'

export const Separator = (props: any) => {
  return (
    <View flexDirection="row" alignItems="center" my={[2, 8]} {...props}>
      <View flex={1} borderColor="gray.900" borderBottomWidth={1} />
      <Text
        mx={4}
        fontSize={[13, 15]}
        fontWeight="bold"
        color="gray.500"
        fontFamily="Inter-Regular"
      >
        or
      </Text>
      <View flex={1} borderColor="gray.900" borderBottomWidth={1} />
    </View>
  )
}

export default Separator

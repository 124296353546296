import React from 'react'
import { Image, Platform, Pressable, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { WebView } from 'react-native-webview'
import { AVPlaybackStatus, Video } from 'expo-av'

import { isM3U8Link } from '../../../../helpers'

export const VideoWebView = ({
  embedURL,
  videoLink,
  mediaLength,
  webViewStyle,
  type,
  videoId
}: {
  embedURL: string
  videoLink: string
  mediaLength: number
  webViewStyle?: object
  type?: string
  videoId?: string
}) => {
  const webViewRef = React.useRef<WebView>(null)

  const playVideo = () => {
    webViewRef?.current?.postMessage('playVideo')
  }

  const isWeb = Platform.OS === 'web'

  const renderSimpleWebView = () => (
    <Pressable
      accessibilityRole="button"
      key={embedURL}
      style={{ ...styles.pressable, marginBottom: mediaLength === 1 ? 0 : 30 }}
    >
      <WebView
        style={{ ...styles.webView, ...webViewStyle }}
        javaScriptEnabled={true}
        source={{ uri: embedURL }}
        // mediaPlaybackRequiresUserAction={true}
        containerStyle={{ backgroundColor: '#000000' }}
        //onShouldStartLoadWithRequest={request => request.url.startsWith(videoLink)}
        scrollEnabled={false}
      />
    </Pressable>
  )

  return type === 'youtube' ? (
    isWeb ? (
      renderSimpleWebView()
    ) : (
      <Pressable
        accessibilityRole="button"
        style={{ ...styles.pressable, marginBottom: mediaLength === 1 ? 0 : 30 }}
      >
        <WebView
          ref={webViewRef}
          style={styles.webView}
          javaScriptEnabled={true}
          source={{
            html: `
            <html >
            <body style="margin: 0; padding: 0">
              <div id="player"></div>
              <script src="https://www.youtube.com/iframe_api"></script>
              <script>
                var player;
                function onYouTubeIframeAPIReady() {
                  player = new YT.Player('player', {
                    height: '100%',
                    width: '100%',
                    videoId: '${videoId}',
                    playerVars: {'controls': 0, 'rel': 0, 'autoplay': 1 },
                  });
                  window.addEventListener('message', function(event) {
                    var command = event.data;
                    if (command === 'playVideo') {
                      player.playVideo();
                    }
                  });
                }
              </script>
            </body>
            </html>
          `
          }}
          containerStyle={{ backgroundColor: '#000000' }}
          //onShouldStartLoadWithRequest={request => request.url.startsWith(videoLink)}
          scrollEnabled={false}
        />
        <TouchableOpacity
          accessibilityRole="button"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0
          }}
          activeOpacity={1}
          onPress={() => playVideo()}
        />
      </Pressable>
    )
  ) : (
    renderSimpleWebView()
  )
}

export const VideoComponent = ({
  videoLink,
  mediaLength
}: {
  videoLink: string
  mediaLength: number
}) => {
  const [isError, setIsError] = React.useState(false)

  const handlePlaybackStatusUpdate = (status: AVPlaybackStatus) => setIsError(!status.isLoaded)
  const isStream = isM3U8Link(videoLink)

  return (
    <Pressable
      accessibilityRole="button"
      style={{ ...styles.pressable, marginBottom: mediaLength === 1 ? 0 : 30 }}
    >
      <Video
        source={{
          uri: videoLink
        }}
        useNativeControls
        style={{ flex: 1 }}
        onPlaybackStatusUpdate={status => handlePlaybackStatusUpdate(status)}
        shouldPlay
      />
      {isError && isStream && (
        <View style={{ justifyContent: 'center' }}>
          <Image
            accessibilityIgnoresInvertColors
            source={require('../assets/static-video.gif')}
            style={{ width: '100%', height: '100%' }}
          />
          <View style={styles.error}>
            <Text style={styles.errorText}>Please check the schedule for Live stream details.</Text>
          </View>
        </View>
      )}
      {!isError && isStream ? (
        <View style={styles.live}>
          <Text style={styles.liveText}>LIVE</Text>
        </View>
      ) : null}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  pressable: {
    borderRadius: 8,
    overflow: 'hidden',
    flex: 1,
    position: 'relative'
  },
  webView: {
    width: '100%',
    height: 215
  },
  live: {
    backgroundColor: '#DD4267',
    position: 'absolute',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 4,
    top: 8,
    right: 8
  },
  liveText: { fontSize: 11, lineHeight: 15, color: '#FFFFFF' },
  error: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.50)',
    borderRadius: 8,
    alignSelf: 'center',
    paddingHorizontal: 12,
    paddingVertical: 4,
    marginHorizontal: 20
  },
  errorText: {
    fontSize: 15,
    lineHeight: 20,
    fontWeight: '700',
    color: '#FFFFFF',
    textAlign: 'center'
  }
})

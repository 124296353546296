import AsyncStorage from '@react-native-async-storage/async-storage'
import { allSettled, isFulfilled, isRejected } from '@vatom/sdk/react'
import { Instance, SnapshotOut, types } from 'mobx-state-tree'

import moralis from './Moralis'
import {
  TEthereumChains,
  TMetadataSolana,
  TNftOwnerChain,
  TSolanaChains,
  TSplNft,
  TSplNftChain,
  TSplNftMetadata
} from './types'
import WebSockets from './WebSockets'

export const ethNetworksChains: TEthereumChains[] = ['eth', 'polygon', 'bsc', 'sepolia']

// export const solNetworksChains: TSolanaChains[] = ['mainnet', 'devnet']
export const solNetworksChains: TSolanaChains[] = ['mainnet']

export const web3chains = [...ethNetworksChains, ...solNetworksChains]

export const Web3Store = types
  .model('Web3Store', {
    address: types.optional(types.string, ''),
    moralisServerUrl: types.optional(types.string, 'https://parser.api.vatominc.com/server'),
    moralisAppId: types.optional(types.string, 'KPwA7E3j_p5Pvme1XRhkF')
  })

  .volatile(self => {
    const { moralisServerUrl, moralisAppId } = self
    moralis.setAsyncStorage(AsyncStorage)
    moralis.start({ serverUrl: moralisServerUrl, appId: moralisAppId })
    const sockets = new WebSockets(moralis)
    return {
      moralis,
      sockets
    }
  })
  .actions(self => ({
    setAddress(address: string) {
      console.log('Web3Store.setAddress: ', address)
      self.address = address
    },
    getChainLogo(chain: TSolanaChains | TEthereumChains) {
      switch (chain) {
        case 'eth':
        case 'sepolia':
          return 'https://w7.pngwing.com/pngs/268/1013/png-transparent-ethereum-eth-hd-logo.png'

        case 'polygon':
          return 'https://logowik.com/content/uploads/images/polygon-matic-icon3725.logowik.com.webp'

        case 'bsc':
          return 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png'

        case 'devnet':
        case 'mainnet':
          return 'https://cryptologos.cc/logos/solana-sol-logo.png'

        default:
          return 'https://w7.pngwing.com/pngs/268/1013/png-transparent-ethereum-eth-hd-logo.png'
      }
    },
    getChainName(chain: TSolanaChains | TEthereumChains) {
      switch (chain) {
        case 'eth':
          return 'Ethereum'
        case 'polygon':
          return 'Polygon'
        case 'bsc':
          return 'Binance'
        case 'devnet':
          return 'Solana testnet'
        case 'mainnet':
          return 'Solana'
        case 'goerli':
          return 'Goerli'
        case 'sepolia':
          return 'Sepolia'
        default:
          return 'Ethereum'
      }
    },
    getTokenName(chain: TSolanaChains | TEthereumChains) {
      switch (chain) {
        case 'eth':
          return 'ETH'
        case 'polygon':
          return 'MATIC'
        case 'bsc':
          return 'BNB'
        case 'mainnet':
          return 'SOL'
        case 'devnet':
          return 'SOL_TESTNET'
        case 'goerli':
          return 'GTH'
        default:
          return 'ETH'
      }
    }
  }))

  .actions(self => ({
    async getNfts(chains?: TEthereumChains[]) {
      const moralis = self.moralis
      await moralis.User.currentAsync()

      if (chains && chains.length > 0) {
        ethNetworksChains.concat(chains)
      }

      const response = await allSettled(
        ethNetworksChains.map(async chain => {
          const options = { address: self.address, chain }
          const moralis_result = await moralis.Web3API.account.getNFTs(options)

          const tokens = moralis_result.result?.map(token => {
            const t = token as TNftOwnerChain
            t.chain = chain
            return t
          })

          return tokens || []
        })
      )

      const fulfilledTokens = response.filter(isFulfilled).map(p => p.value)
      const rejectedReasons = response.filter(isRejected).map(p => p.reason)
      console.error(rejectedReasons)

      const allTokens = fulfilledTokens.reduce(
        (accumulator, currentValue) => accumulator.concat(currentValue),
        []
      )

      return allTokens
    },
    async getSolanaNfts() {
      const moralis = self.moralis
      await moralis.User.currentAsync()
      const SolanaAPI: any = moralis.SolanaAPI

      const tokens_by_chain = await Promise.all(
        solNetworksChains.map(async chain => {
          const options = {
            address: self.address,
            network: chain
          }

          const solanaTokens = await SolanaAPI.account.getNFTs(options)

          const solanaTokensWithChain: TSplNftChain[] = solanaTokens.map((token: TSplNft) => {
            const tk = token as TSplNftChain
            tk.chain = chain
            return tk
          })

          return solanaTokensWithChain
        })
      )

      const allTokens = tokens_by_chain.reduce(
        (accumulator, currentValue) => accumulator.concat(currentValue),
        []
      )

      const tokensPromises = await allSettled(
        allTokens.map(async token => {
          const metaplex: { metaplex: TSplNftMetadata } = await SolanaAPI.nft.getNFTMetadata({
            network: token.chain,
            address: token.mint
          })

          const metadata = await fetch(metaplex.metaplex.metadataUri)
            .then(res => res.json())
            .then(result => result)

          metadata.address = token.associatedTokenAddress
          metadata.mintAddress = token.mint
          metadata.owner = {
            associatedTokenAccountAddress: token.associatedTokenAddress,
            address: self.address
          }

          return metadata as TMetadataSolana
        })
      )

      const fulfilledTokens = tokensPromises.filter(isFulfilled).map(p => p.value)
      // const rejectedReasons = tokensPromises.filter(isRejected).map(p => p.reason)

      return fulfilledTokens
    }
  }))

export interface Web3StoreType extends Instance<typeof Web3Store> {}
export type Web3StoreSnapshot = SnapshotOut<typeof Web3Store>

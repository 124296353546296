import React from 'react'
import { Platform } from 'react-native'
import { LoaderView as Loader } from '@vatom/wombo'
import { Box } from 'native-base'

export const LoaderView = () => {
  if (Platform.OS === 'web') {
    return (
      <Box w="100%" h="200px">
        <Loader blocking={false} />
      </Box>
    )
  } else {
    return <Loader blocking={false} />
  }
}

export default LoaderView

import React, { ReactElement } from 'react'
import {
  FormControl as NBFormControl,
  HStack,
  IFormControlProps,
  WarningOutlineIcon
} from 'native-base'

export interface FormControlProps extends IFormControlProps {
  /**
   * The label text
   */
  label?: string

  /**
   * Feedback presented to user if field erred
   */
  errorMessage?: string

  /**
   * Feedback presented to user if help text provided
   */
  helperText?: string

  /**
   * This component  will render at the right of the label
   */
  rightToLabelComponent?: ReactElement
}

export const FormControl = ({
  helperText,
  label,
  errorMessage,
  children,
  color,
  fontWeight,
  fontFamily,
  fontSize,
  rightToLabelComponent,
  ...props
}: FormControlProps) => {
  return (
    <NBFormControl {...props}>
      <HStack justifyContent="space-between" alignItems="center">
        <NBFormControl.Label _text={{ color, fontWeight, fontFamily, fontSize }} mb={0}>
          {label}
        </NBFormControl.Label>
        {rightToLabelComponent}
      </HStack>
      {children}
      <NBFormControl.HelperText>{helperText}</NBFormControl.HelperText>
      <NBFormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
        {errorMessage}
      </NBFormControl.ErrorMessage>
    </NBFormControl>
  )
}

export default FormControl

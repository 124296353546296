import React from 'react'
import { Platform } from 'react-native'
import { useBackHandler, useBusiness, useTokenBalanceQuery } from '@vatom/sdk/react'
import { Avatar, LoaderView, PressableOpacity, Text, theme } from '@vatom/wombo'
import { Utils } from 'alchemy-sdk'
import { observer } from 'mobx-react-lite'
import { Box, Center, KeyboardAvoidingView } from 'native-base'

import { HideKeyboard } from '../../../components/HideKeyboard'
import { ScreenHeader } from '../../../components/ScreenHeader'
import { ScreenWrapper } from '../../../components/ScreenWrapper'
import { AppRoutes, AppStackScreenProps } from '../../../navigators'
import { useCoinTheme } from '../useCoinTheme'

import { TransactionHistory } from './partials/TransactionHistory'
import { formatName } from './utils/formatString'
import { CoinAvatar } from './partials'

const CoinBalance = ({
  amount,
  name,
  textProps
}: {
  amount: number
  name: string
  textProps?: React.ComponentProps<typeof Text>
}) => (
  <Center mt={4}>
    <Text fontSize={17} lineHeight={24} fontFamily={'Inter-Bold'} fontWeight={'700'} {...textProps}>
      {`${amount} ${formatName(name)}`}
    </Text>
  </Center>
)

export type IDetailsProps = AppStackScreenProps<typeof AppRoutes.FungibleTokenDetail>

const CoinDetail = observer(({ route, navigation }: IDetailsProps) => {
  const { tokenBalance, tokenMetadata, addressBalance, address } = useTokenBalanceQuery(
    route.params
  )

  const { data: business, isLoading } = useBusiness({ businessId: 'nQwtevgfOa' })
  const styles = useCoinTheme({ business })

  const coinImage = tokenMetadata.data?.logo
  const coinName = tokenMetadata.data?.symbol ?? addressBalance.data?.symbol
  const coinBalance =
    Number(
      Utils.formatUnits(tokenBalance.data?.tokenBalance ?? 0, tokenMetadata.data?.decimals ?? 18)
    ) ||
    // @ts-ignore
    Number(Utils.formatUnits(addressBalance.data?.balance.balance ?? 0, 18))

  useBackHandler(() => {
    onClose()
    return true
  })

  const onClose = () => {
    navigation.goBack()
  }

  const onSendCoin = () => {
    navigation.navigate(AppRoutes.FungibleTokenTransfer, route.params)
  }

  if (isLoading) {
    return <LoaderView />
  }

  return (
    <ScreenWrapper
      screenBackgroundColor={styles.background}
      statusBarBackgroundColor={styles.primary}
    >
      <ScreenHeader
        headerLeftPress={onClose}
        headerTextColor={styles.primaryText}
        backdropProps={{
          backgroundColor: styles.primary
        }}
      />

      <HideKeyboard>
        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
          <Box
            px={4}
            position={'relative'}
            zIndex={1}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            {coinImage ? (
              <CoinAvatar
                // Coin
                source={coinImage}
                size={100}
                name={coinImage ?? ''}
                containerProps={{ zIndex: 0 }}
              />
            ) : (
              <Avatar size={100} url={''} badgeColor={undefined} />
            )}
            {/* {networkImage && (
              <CoinAvatar
                // Network
                source={networkImage}
                size={32}
                name={networkImage && networkName ? networkName : ''}
                containerProps={{ zIndex: 1, position: 'absolute', bottom: 0, right: 0 }}
              />
            )} */}
          </Box>
          <CoinBalance
            amount={coinBalance}
            name={coinName || ''}
            textProps={{
              color: styles.textColor
            }}
          />

          <PressableOpacity
            accessibilityRole="button"
            onPress={onSendCoin}
            mt={25}
            py={3}
            mx={4}
            borderWidth={1}
            borderRadius={3}
            _light={{
              borderColor: theme.colors.grayCool[400]
            }}
            _dark={{
              borderColor: theme.colors.grayDarkMode[500]
            }}
          >
            <Center>
              <Text
                color={styles.textColor}
                fontSize={13}
                lineHeight={18}
                fontFamily={'Inter-Regular'}
                fontWeight="500"
                tx="coinsScreen.sendCoin"
                txOptions={{ coinName: coinName ?? '' }}
              />
            </Center>
          </PressableOpacity>
        </KeyboardAvoidingView>
      </HideKeyboard>
      <TransactionHistory businessId={'nQwtevgfOa'} chain={'eth'} address={address} />
    </ScreenWrapper>
  )
})

export default CoinDetail

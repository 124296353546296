import React, { useCallback, useMemo, useState } from 'react'
import { Platform } from 'react-native'
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue
} from 'react-native-reanimated'
import { useNavigation } from '@react-navigation/native'
import { useConfig, useIsAuthed, useSDK, useUser, useUserProfile } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, Center, Text, useColorModeValue } from 'native-base'

import { HeaderButtonBack, ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import VersionInfo from '../../components/VersionInfo'
import { useAuthLogout } from '../../hooks/useAuthLogout'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../hooks/useIsDesktop'
import logger from '../../logger'
import { AppRoutes } from '../../navigators'

import { ProfileMetamask } from './components/ProfileMetamask'
import { ProfileUserBio } from './components/ProfileUserBio'
import { ProfileUserCard } from './components/ProfileUserCard'
import { StatsCard } from './components/StatsCard'
import { IdentityList } from './partials/IdentityList'
import MetamaskProfile from './partials/MetamaskProfile'
import VatomProfile from './partials/VatomProfile'
import { ButtonTransparent, QrCode } from './partials'

const isWeb = Platform.OS === 'web'

export const ProfileUserObs = observer(() => {
  const [walletAddress, setWalletAddress] = useState('')
  const [qrCodeVisible, setQrCodeVisible] = useState<boolean>(false)
  const isDesktop = useIsDesktop()
  const { doLogout } = useAuthLogout()
  const config = useConfig()

  const scrollOffsetY = useSharedValue(0)
  const scrollHandler = useAnimatedScrollHandler(event => {
    scrollOffsetY.value = event.contentOffset.y
  })
  const Max_Header_Height = 100
  const Min_Header_Height = 70
  const scrollDiff = Max_Header_Height - Min_Header_Height // 30

  const animatedHeaderStyles = useAnimatedStyle(() => {
    const height = interpolate(scrollOffsetY.value, [0, scrollDiff], [100, 60], {
      extrapolateRight: Extrapolation.CLAMP,
      extrapolateLeft: Extrapolation.CLAMP
    })
    return {
      height: height
    }
  })
  const animatedTitleStyles = useAnimatedStyle(() => {
    const show = interpolate(scrollOffsetY.value, [130, 200], [0, 1], {
      extrapolateRight: Extrapolation.CLAMP,
      extrapolateLeft: Extrapolation.CLAMP
    })
    return {
      display: show === 0 ? 'none' : 'flex'
    }
  })

  const animatedScrollStyles = useAnimatedStyle(() => {
    const value = interpolate(scrollOffsetY.value, [0, 30], [-50, 0], {
      extrapolateRight: Extrapolation.CLAMP,
      extrapolateLeft: Extrapolation.CLAMP
    })

    return {
      marginTop: value
    }
  })

  const navigation = useNavigation()
  const isLoggedIn = useIsAuthed()
  const sdk = useSDK()
  const user = useUser()
  const { data: userProfile } = useUserProfile(user?.sub ?? '')
  const { isDark } = useBusinessTheme()

  const screenBackgroundColor = useColorModeValue(theme.colors.white, theme.colors.grayCool[900])

  const logoutOrDisconnect = useCallback(async () => {
    logger.info('[ProfileUser.logoutOrDisconnect] isLoggedIn', isLoggedIn)
    doLogout()
  }, [doLogout, isLoggedIn])
  const backgroundColor = sdk.dataPool.sessionStore.isLoggedInWithVatom ? 'white' : 'gray.50'

  const openWalletQrCode = (walletAddress: string) => {
    setWalletAddress(walletAddress)
    setQrCodeVisible(true)
  }

  const openUserQrCode = () => {
    setWalletAddress(user?.sub ?? '')
    setQrCodeVisible(true)
  }

  const onPressEdit = () => {
    if (isWeb) {
      window.open(AppRoutes.editProfile, 'profile')
      return
    }
    navigation.navigate(AppRoutes.editProfile)
  }

  const onPressManage = useCallback(() => {
    if (isWeb) {
      window.open(AppRoutes.manageAccounts, 'profile')
      return
    }
    navigation.navigate(AppRoutes.manageAccounts)
  }, [navigation])

  const brandColor = isDark ? theme.colors.systemColorsDark.orange : theme.colors.brand[500]
  const brandColorLight = isDark ? theme.colors.systemColorsDark.orange : '#FF7A00'
  const defaultBgColor = {
    linearGradient: {
      colors: [brandColor, brandColorLight]
    }
  }
  const backdropProps = !isDesktop
    ? {
        zIndex: 0,
        backgroundColor: config.profileHeaderBackgroundColor ?? defaultBgColor
      }
    : undefined

  const onPressBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate(AppRoutes.home)
    }
  }

  const desktopContent = useMemo(() => {
    if (!isDesktop) {
      return null
    }

    return (
      <>
        <Box
          width={'100%'}
          height={2}
          _dark={{
            backgroundColor: theme.colors.grayCool[900]
          }}
          backgroundColor={theme.colors.grayCool[100]}
        />

        {isLoggedIn ? (
          <VatomProfile
            background={backgroundColor}
            user={user}
            logoutOrDisconnect={logoutOrDisconnect}
            openWalletQrCode={openWalletQrCode}
          />
        ) : (
          <MetamaskProfile
            background={backgroundColor}
            openWalletQrCode={openWalletQrCode}
            logoutOrDisconnect={logoutOrDisconnect}
            user={user}
          />
        )}

        {walletAddress !== '' && (
          <QrCode
            qrCodeVisible={qrCodeVisible}
            setQrCodeVisible={setQrCodeVisible}
            qrLink={`${getHost()}/receive/${user?.sub}`}
          />
        )}

        <ButtonTransparent
          marginTop={12}
          title={translate('common.logout')}
          onPress={logoutOrDisconnect}
        />

        <Box safeAreaBottom paddingY={8}>
          <VersionInfo />
        </Box>
      </>
    )
  }, [
    backgroundColor,
    isDesktop,
    isLoggedIn,
    logoutOrDisconnect,
    qrCodeVisible,
    user,
    walletAddress
  ])

  return (
    <ScreenWrapper
      statusBarBackgroundColor={brandColor}
      statusBarStyle={'light-content'}
      screenBackgroundColor={screenBackgroundColor}
    >
      <Box flexGrow={1}>
        <Animated.View
          style={[{ zIndex: 0, position: 'relative', overflow: 'hidden' }, animatedHeaderStyles]}
        >
          <ScreenHeader
            headerTextColor={theme.colors.white}
            headerProps={{
              zIndex: 0,
              backgroundColor: 'transparent'
            }}
            backdropProps={backdropProps}
            headerLeft={() =>
              !isDesktop && (
                <ScreenHeaderButton onPress={onPressBack}>
                  <HeaderButtonBack color={theme.colors.white} />
                </ScreenHeaderButton>
              )
            }
            headerRight={() => <ScreenHeaderButton />}
          >
            <Animated.View
              style={[
                {
                  alignSelf: 'stretch',
                  alignItems: 'center',
                  justifyContent: 'center'
                },
                animatedTitleStyles
              ]}
            >
              {user?.name && (
                <Title
                  preset="h5"
                  variant="bold"
                  color={theme.colors.white}
                  _web={{
                    color: isDesktop ? theme.colors.textLightMode[900] : theme.colors.white
                  }}
                >
                  {user?.name}
                </Title>
              )}
            </Animated.View>
          </ScreenHeader>
        </Animated.View>
        <Animated.ScrollView
          showsVerticalScrollIndicator={false}
          scrollEventThrottle={16}
          onScroll={scrollHandler}
          style={animatedScrollStyles}
        >
          {isLoggedIn ? (
            <ProfileUserCard
              avatarUrl={user?.picture}
              name={user?.name}
              username={userProfile?.preferred_username}
              onPressQrCode={openUserQrCode}
            />
          ) : (
            <ProfileMetamask />
          )}
          <Center>
            {user?.bio && <ProfileUserBio text={user?.bio} marginTop={4} />}

            {user?.website && (
              <Center marginY={2}>
                <Text color={'#2EA7FF'}>{user?.website}</Text>
              </Center>
            )}
            {config.showUserRelations && (
              <StatsCard
                userId={userProfile?.id ?? ''}
                containerProps={{
                  marginTop: 8
                }}
              />
            )}
          </Center>
          {/*  */}
          {config.showEditProfile && (
            <ButtonTransparent
              title={translate('profileScreen.editAccount')}
              onPress={onPressEdit}
              height={10}
              marginTop={6}
              marginBottom={4}
            />
          )}
          <Box
            width={'100%'}
            height={2}
            _dark={{
              backgroundColor: theme.colors.grayCool[900]
            }}
            backgroundColor={theme.colors.grayCool[100]}
          />

          <IdentityList openQrCode={openWalletQrCode} />
          {config.showManageAccountButton && (
            <ButtonTransparent
              title={translate('profileScreen.manageAccount')}
              onPress={onPressManage}
              height={10}
              marginTop={6}
              marginBottom={4}
            />
          )}

          {/* Legacy content for desktop */}
          {desktopContent}
        </Animated.ScrollView>
      </Box>
    </ScreenWrapper>
  )
})

const getHost = () => {
  if (Platform.OS !== 'web') {
    return 'https://wallet.vatom.com'
  }
  return window.location.origin
}

export const ProfileUser = ProfileUserObs
export default ProfileUser

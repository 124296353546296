import React from 'react'
import { KeyboardTypeOptions, TextInput } from 'react-native'
import { BottomSheetTextInput, TouchableOpacity } from '@gorhom/bottom-sheet'
import { InputField, theme } from '@vatom/wombo'
import { Box, Center, Image, Pressable, useColorModeValue } from 'native-base'

import { CircleIcon } from '../Icons'

import Error from './Error'
import LabelForm from './Label'
import { fieldPropsInterfaceItem } from './Types'

type types = fieldPropsInterfaceItem & { keyboardType?: KeyboardTypeOptions }

export default function StringField(props: types) {
  return (
    <Box>
      <LabelForm {...props} />
      <InputFieldSheet
        placeholder={props.field.placeholder}
        name={props.field.name}
        required={props.field.required}
        pattern={props.field.pattern}
        onChangeText={e => props.onChange(props.field.name, e)}
        value={props.value ?? ''}
        keyboardType={props.keyboardType}
        onFocus={props?.onFocus}
        onBlur={props?.onBlur}
      />

      <Error {...props} />
    </Box>
  )
}

type InputFieldProps = React.ComponentProps<typeof BottomSheetTextInput> & {
  hasDelete?: boolean
  min?: number
  max?: number
  name?: string
  required?: boolean
  pattern?: string
  isDark?: boolean
}

const InputFieldSheet = React.forwardRef((props: InputFieldProps, ref: any) => {
  console.log('LOG: > InputFieldSheet > props:', props)
  const styles = useColorModeValue(
    {
      color: theme.colors.textLightMode[900]
    },
    {
      color: theme.colors.textDarkMode[100]
    }
  )
  return (
    <Box justifyContent="center">
      {/* props.hasDelete &&  */}
      {/* {ShowDelete && inputText.length !== 0 && (
        <TouchableOpacity
          accessibilityRole="button"
          onPress={clearInputField}
          style={{
            position: 'absolute',
            alignSelf: 'flex-end',
            zIndex: 2,
            marginTop: 10,
            marginRight: 4
          }}
        >
          <Center>
            <CircleIcon size={4} fill={'rgb(196, 196, 196)'} />
          </Center>
        </TouchableOpacity>
      )} */}
      <BottomSheetTextInput
        // name={props.field.name}
        // placeholder={props.field.placeholder}
        // required={props.field.required}
        // pattern={props.field.pattern}
        ref={ref}
        value={props.value}
        keyboardType={props.keyboardType}
        clearButtonMode={props.hasDelete ? 'while-editing' : 'never'}
        style={{
          borderWidth: 1,
          borderColor: 'rgb(196, 196, 196)',
          borderRadius: 4,
          fontSize: 16,
          lineHeight: 20,
          padding: 8,
          color: styles.color
        }}
        {...props}
      />
    </Box>
  )
})

import { Path } from 'react-native-svg'
import { Icon } from 'native-base'

type IconProps = {
  size?: React.ComponentProps<typeof Icon>['size']
  fill: React.ComponentProps<typeof Icon>['fill']
} & React.ComponentProps<typeof Icon>

export const SendAudioIcon = ({ size, fill, ...iconProps }: IconProps) => (
  <Icon viewBox="0 0 12 19" size={size} {...iconProps}>
    <Path
      fill={fill}
      d="M11.0625 7C11.3438 7 11.625 7.28125 11.625 7.5625V9.25C11.625 12.168 9.375 14.5586 6.5625 14.8398V17.125H8.8125C9.09375 17.125 9.375 17.4062 9.375 17.6875C9.375 18.0039 9.09375 18.25 8.8125 18.25H3.1875C2.87109 18.25 2.625 18.0039 2.625 17.6875C2.625 17.4062 2.87109 17.125 3.1875 17.125H5.4375V14.8398C2.51953 14.5586 0.375 11.9922 0.375 9.07422V7.5625C0.375 7.28125 0.621094 7 0.9375 7C1.21875 7 1.5 7.28125 1.5 7.5625V9.10938C1.5 11.5 3.29297 13.6094 5.68359 13.75C8.28516 13.9258 10.5 11.8516 10.5 9.25V7.5625C10.5 7.28125 10.7461 7 11.0625 7ZM6 12.625C4.13672 12.625 2.625 11.1133 2.625 9.25V3.625C2.625 1.76172 4.13672 0.25 6 0.25C7.86328 0.25 9.375 1.79688 9.375 3.625V9.25C9.375 11.1133 7.86328 12.625 6 12.625ZM3.75 3.625V9.25C3.75 10.5156 4.73438 11.5 6 11.5C7.23047 11.5 8.25 10.5156 8.25 9.25V3.625C8.25 2.39453 7.23047 1.375 6 1.375C4.73438 1.375 3.75 2.39453 3.75 3.625Z"
    />
  </Icon>
)

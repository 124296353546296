import { useRef } from 'react'
import { WebView as RNWebView, WebViewMessageEvent } from 'react-native-webview'
import { WebView } from '@vatom/sdk/react'
import { getCardView, VatomModelType } from '@vatom/vatomNew/plugin'
import { useVatomWalletSdkStore, VatomWallet } from '@vatom/wallet-sdk'
import { Loader } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { getSnapshot } from 'mobx-state-tree'
import { View } from 'native-base'

import { useViewSDKHandler } from './ViewSDK/useViewSDKHandler'

export const WebDisplay = observer(({ token }: { token: VatomModelType }) => {
  const { messageHandler, webViewRef } = useViewSDKHandler({ token })
  const isEmbedded = useVatomWalletSdkStore(state => state.isEmbedded)

  const view = getCardView(token.viewPlacements)

  if (!view) {
    console.error('LOG: > WebDisplay > !view:', !view)
    return <Loader />
  }

  const handleMessage = (msg: string | WebViewMessageEvent) => {
    messageHandler(msg)

    try {
      if (isEmbedded) {
        const msgObj = typeof msg === 'string' ? JSON.parse(msg) : msg
        const { data } = msgObj

        VatomWallet._shared.sendMsgWithoutResponse('walletsdk:faceMessage', {
          name: data.name,
          payload: data.data,
          token: getSnapshot(token)
        })
      }
    } catch (error) {
      console.error('LOG: > WebDisplay > handleMessage > error:', error)
    }
  }

  const wrapperRef = useRef<HTMLDivElement>()
  return (
    <View
      ref={wrapperRef}
      style={{
        alignItems: 'center',
        width: '100%',
        height: '100%'
      }}
      _web={{
        flexShrink: 'unset'
      }}
    >
      <WebView
        ref={webViewRef as React.Ref<RNWebView>}
        // viewMode={viewMode} // Not in use
        onMessage={handleMessage}
        wrapperRef={wrapperRef}
        // displayUrl={face.properties.display_url}
        displayUrl={view.entrypoint}
      />
    </View>
  )
})

import React from 'react'
import { Image, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
// import DraggableFlatList, { ScaleDecorator } from 'react-native-draggable-flatlist'
import { Video } from 'expo-av'
import { observer } from 'mobx-react-lite'
import { getSnapshot, SnapshotOut } from 'mobx-state-tree'

import CloseIcon from '../../../assets/header-icons/close-icon.svg'
import { mxcToHttp } from '../../../helpers'
import { useMessage } from '../../../queries'
import { useStoreContext } from '../../MSTContextProvider'
import { Media } from '../stores'

// const MediaComponent = observer((props: any) => {
//   const { media, drag, isActive, store } = props

//   let imageLink = ''
//   if (media.base64) {
//     imageLink = media.uri
//   } else {
//     if (media.type === 'image') {
//       const elements = media.uri.split('/')
//       const mediaId = elements[elements.length - 1]
//       imageLink = `https://matrix.vatom.com/_matrix/media/v3/thumbnail/vatom.com/${mediaId}?width=320&height=240`
//     } else {
//       imageLink = media.thumbnail
//     }
//   }

//   return (
//     <ScaleDecorator>
//       <TouchableOpacity
//         style={{ marginRight: 16, paddingVertical: 10 }}
//         onLongPress={drag}
//         disabled={isActive}
//       >
//         <TouchableOpacity style={styles.removeButton} onPress={() => store.removeMedia(media.uri)}>
//           <CloseIcon fill="#FFF" height={10} />
//         </TouchableOpacity>
//         {imageLink ? (
//           <Image
//             source={{
//               uri: imageLink
//             }}
//             style={styles.images}
//           />
//         ) : (
//           <Video
//             source={{
//               uri: media.uri
//             }}
//             shouldPlay={false}
//             style={styles.images}
//           />
//         )}
//       </TouchableOpacity>
//     </ScaleDecorator>
//   )
// })

const MediaComponent = observer(({ media }: { media: SnapshotOut<typeof Media> }) => {
  const store = useStoreContext()

  let imageLink = ''
  if (media.base64) {
    imageLink = media.uri
  } else {
    if (media.type === 'image') {
      imageLink = mxcToHttp(media.uri, 'matrix.api.vatominc.com')
    } else {
      imageLink = media.thumbnail
    }
  }

  return (
    <View style={{ marginRight: 16 }}>
      <TouchableOpacity
        accessibilityRole="button"
        style={styles.removeButton}
        onPress={() => store.removeMedia(media.uri)}
      >
        <CloseIcon fill="#FFF" height={10} width={10} />
      </TouchableOpacity>
      {imageLink ? (
        <Image
          accessibilityIgnoresInvertColors
          source={{
            uri: imageLink
          }}
          style={styles.images}
        />
      ) : (
        <Video
          source={{
            uri: media.uri
          }}
          shouldPlay={false}
          style={styles.images}
        />
      )}
    </View>
  )
})

const ModalImagesSlider = observer(({ mediaJSON }: { mediaJSON: string }) => {
  const store = useStoreContext()

  React.useEffect(() => {
    if (mediaJSON) {
      store.setMedia(JSON.parse(mediaJSON))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const renderItem = (item: any) =>{
  //   const { item: media, drag, isActive } = item
  //   return <MediaComponent media={media} drag={drag} isActive={isActive} store={store} />
  // }

  const media = getSnapshot(store.media)

  return (
    <>
      {/* {media.length > 0 ? (
        <DraggableFlatList
          data={media}
          onDragEnd={({ data }) => store.reorderMedia(data)}
          keyExtractor={item => item.uri.toString()}
          renderItem={item => renderItem(item)}
          horizontal={true}
        />
      ) : null} */}
      {media.length > 0 ? (
        <ScrollView style={{ paddingVertical: 16, flexDirection: 'row' }} horizontal={true}>
          {media?.map((media, index: number) => {
            return <MediaComponent key={index} media={media} />
          })}
        </ScrollView>
      ) : null}
    </>
  )
})

export default ModalImagesSlider

const styles = StyleSheet.create({
  images: {
    width: 64,
    height: 64,
    borderRadius: 8
  },
  removeButton: {
    position: 'absolute',
    top: -10,
    right: -10,
    width: 20,
    height: 20,
    backgroundColor: '#29313A',
    zIndex: 1,
    borderRadius: 40,
    alignItems: 'center',
    justifyContent: 'center'
  }
})

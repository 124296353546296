import { createContext, useCallback, useContext, useRef } from 'react'
import {
  Easing,
  SharedValue,
  useAnimatedScrollHandler,
  useSharedValue
} from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useFocusEffect, useRoute } from '@react-navigation/native'
import { throttle } from 'lodash'

import { defaultHeaderMinHeight } from '../components/ScreenHeaderWrapper'
import { TabBarHeight } from '../screens/Home/components/TabBar'

type LayoutScrollHandler = {
  lastContentOffset: SharedValue<number>
  isScrolling: SharedValue<boolean>
  headerHeight: SharedValue<number>
  footerHeight: SharedValue<number>
  shouldHideElements: SharedValue<boolean>
}

const LayoutScrollHandlerContext = createContext<LayoutScrollHandler | null>(null)
export const LayoutScrollHandlerProvider = ({ children }: React.PropsWithChildren) => {
  const lastContentOffset = useSharedValue(0)
  const isScrolling = useSharedValue(false)
  const headerHeight = useSharedValue(0)
  const footerHeight = useSharedValue(0)
  const shouldHideElements = useSharedValue(false)

  return (
    <LayoutScrollHandlerContext.Provider
      value={{
        lastContentOffset,
        isScrolling,
        headerHeight,
        footerHeight,
        shouldHideElements
      }}
    >
      {children}
    </LayoutScrollHandlerContext.Provider>
  )
}

export const useLayoutScrollHandler = () => {
  const insets = useSafeAreaInsets()
  const duration = 350
  const easing = Easing.inOut(Easing.ease)
  const scrollEventThrottle = 32
  const defaultHeaderHeight = defaultHeaderMinHeight
  const defaultFooterHeight = TabBarHeight + insets.bottom
  const previousRoute = useRef(null as ReturnType<typeof useRoute> | null)

  const { lastContentOffset, isScrolling, headerHeight, footerHeight, shouldHideElements } =
    useContext(LayoutScrollHandlerContext) as LayoutScrollHandler

  const route = useRoute()
  const detectRouteChange = useCallback(() => {
    if (previousRoute.current?.name !== route.name) {
      // Not hide element when route change
      lastContentOffset.value = 0
      shouldHideElements.value = false
    }
    previousRoute.current = route
  }, [lastContentOffset, route, shouldHideElements])
  useFocusEffect(detectRouteChange)

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: event => {
      const offsetY = event.contentOffset.y
      // Scroll Up
      if (lastContentOffset.value > offsetY && isScrolling.value) {
        shouldHideElements.value = false
      }
      // Scroll Down
      else if (lastContentOffset.value < offsetY && isScrolling.value) {
        shouldHideElements.value = true
      }
      lastContentOffset.value = offsetY
    },
    onBeginDrag: e => {
      isScrolling.value = true
    },
    onEndDrag: e => {
      isScrolling.value = false
    }
    // onMomentumBegin: e => {},
    // onMomentumEnd: e => {}
  })

  const onScrollWebView = throttle(({ x, y }: { x: number; y: number }) => {
    const offsetY = y
    if (!offsetY || offsetY < 0) {
      shouldHideElements.value = false
      lastContentOffset.value = 0
      return
    }
    // Scroll Up
    if (lastContentOffset.value > offsetY) {
      shouldHideElements.value = false
    }
    // Scroll Down
    else if (lastContentOffset.value < offsetY) {
      shouldHideElements.value = true
    }
    lastContentOffset.value = offsetY
  }, scrollEventThrottle)

  const setHeaderHeight = (height: number) => {
    headerHeight.value = height
  }

  const getHeaderHeight = () =>
    headerHeight.value !== 0 && headerHeight.value !== defaultHeaderHeight
      ? headerHeight.value
      : defaultHeaderHeight

  const setFooterHeight = (height: number) => {
    footerHeight.value = height
  }

  const getFooterHeight = () =>
    footerHeight.value !== 0 && footerHeight.value !== defaultFooterHeight
      ? footerHeight.value
      : defaultFooterHeight

  return {
    lastContentOffset,
    shouldHideElements,
    scrollHandler,
    onScrollWebView,
    scrollEventThrottle,
    setHeaderHeight,
    getHeaderHeight,
    setFooterHeight,
    getFooterHeight,
    defaultAnimationDuration: duration,
    defaultAnimationEasing: easing
  }
}

import React from 'react'
import { Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { theme } from '@vatom/wombo'
import { observer } from 'mobx-react-lite'
import { Box, ScrollView, View } from 'native-base'

import { HeaderButtonBack, ScreenHeader, ScreenHeaderButton } from '../../components/ScreenHeader'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Title } from '../../components/Title'
import { AppRoutes, TabRoutes } from '../../navigators'

import PeopleSection from './partials/SectionPeople'

// We don't want scrollviews on web they contain in position absolute
const MaybeScrollView = Platform.OS === 'web' ? View : ScrollView

export const InvitePeople = observer(() => {
  const navigation = useNavigation()

  return (
    <ScreenWrapper>
      <ScreenHeader
        headerTextColor={theme.colors.white}
        headerProps={{
          zIndex: 0,
          backgroundColor: 'transparent'
        }}
        headerLeft={() => (
          <ScreenHeaderButton
            onPress={() =>
              navigation.navigate(AppRoutes.home, {
                screen: TabRoutes.People
              })
            }
          >
            <HeaderButtonBack color={theme.colors.black} />
          </ScreenHeaderButton>
        )}
        headerRight={() => <ScreenHeaderButton />}
      >
        <Box
          style={{
            alignSelf: 'stretch',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Title preset="h5" variant="bold" color={theme.colors.black} tx="common.invite" />
        </Box>
      </ScreenHeader>

      <MaybeScrollView>
        <PeopleSection
          containerProps={{
            marginTop: 0
          }}
        />
      </MaybeScrollView>
    </ScreenWrapper>
  )
})

export default InvitePeople

import React from 'react'
import {
  Animated,
  Keyboard,
  Modal,
  SafeAreaView,
  Text,
  TouchableWithoutFeedback,
  View
} from 'react-native'
import { observer } from 'mobx-react-lite'
import { KeyboardAvoidingView } from 'native-base'

import CloseIcon from '../../../assets/header-icons/close-icon.svg'
import { useCommunitiesTheme } from '../../../themes'
import ArrowRightIcon from '../../modal-components/ArrowRightIcon'
import ModalText from '../../modal-components/ModalText'
import {
  closeAnimation,
  ModalAnimatedWrapper,
  ModalGreyBackground,
  ModalHeaderWrapper,
  ModalSaveButton,
  ModalTitle,
  openAnimation
} from '../../modalHelpers'
import { useStoreContext } from '../../MSTContextProvider'
import InfoIcon from '../assets/info_icon.svg'

import { DecorationData } from './ModalDecorations'
import ModalDecorationWrapper from './ModalDecorationWrapper'
import { TextInputArea } from './ModalResponseAnchors'

const ModalSketchCategory = ({ decorationData }: { decorationData: DecorationData }) => {
  const store = useStoreContext()
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')

  React.useEffect(() => {
    store.addAdditionalInputs(decorationData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sketchCategoryInput = store.additionalInputs.find(({ type }) => type === 'sketch-category')

  const fadeAnim = React.useRef(new Animated.Value(0)).current
  const slideAnim = React.useRef(new Animated.Value(1000)).current
  const animationDuration = 300

  const communitiesTheme = useCommunitiesTheme()

  if (!sketchCategoryInput) {
    return null
  }

  const sketchCategoryValue = sketchCategoryInput.value as string

  const openModal = () => {
    setIsModalVisible(true)
    setInputValue(sketchCategoryValue)
    openAnimation(fadeAnim, slideAnim, animationDuration).start()
  }

  const closeModal = () => {
    Keyboard.dismiss()
    closeAnimation(fadeAnim, slideAnim, animationDuration).start(() => {
      setIsModalVisible(false)
    })
  }

  const handleDone = () => {
    sketchCategoryInput.setValue(inputValue)
    closeModal()
  }

  return (
    <>
      <ModalDecorationWrapper handler={() => openModal()}>
        <InfoIcon width="20" style={{ marginRight: 12 }} fill={communitiesTheme.labelIconColor} />
        <View>
          <ModalText text={decorationData.placeholder} />
          {sketchCategoryValue ? (
            <Text style={{ fontSize: 11, lineHeight: 15, color: communitiesTheme.labelTitleColor }}>
              {sketchCategoryValue}
            </Text>
          ) : null}
        </View>

        <ArrowRightIcon style={{ marginLeft: 'auto' }} />
      </ModalDecorationWrapper>

      <SafeAreaView style={{ flex: 1 }}>
        <Modal visible={isModalVisible} transparent={true} animationType="none">
          <ModalGreyBackground fadeAnim={fadeAnim} handler={closeModal} />

          <KeyboardAvoidingView behavior="position">
            <ModalAnimatedWrapper
              slideAnim={slideAnim}
              style={{
                paddingTop: 34,
                paddingBottom: 12
              }}
            >
              <ModalHeaderWrapper style={{ paddingBottom: 12 }}>
                <TouchableWithoutFeedback accessibilityRole="button" onPress={() => closeModal()}>
                  <View>
                    <CloseIcon
                      fill={communitiesTheme.confirmationModal.title}
                      width={20}
                      height={20}
                    />
                  </View>
                </TouchableWithoutFeedback>

                <ModalTitle placeholder={decorationData.placeholder} />

                <ModalSaveButton handler={handleDone} text="Done" />
              </ModalHeaderWrapper>

              <View key="content" style={{ paddingVertical: 16, paddingHorizontal: 16 }}>
                <TextInputArea value={inputValue} setValue={setInputValue} />

                <Text
                  style={{
                    alignSelf: 'flex-end',
                    paddingTop: 4,
                    fontSize: 13,
                    lineHeight: 18,
                    color: '#868E96'
                  }}
                >
                  {inputValue.length}/80
                </Text>
              </View>
            </ModalAnimatedWrapper>
          </KeyboardAvoidingView>
        </Modal>
      </SafeAreaView>
    </>
  )
}

export default observer(ModalSketchCategory)

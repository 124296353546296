import { Text, theme } from '@vatom/wombo'

export const Address = ({ address }: { address: string }) => {
  return (
    <Text
      fontFamily={'Inter-Regular'}
      fontSize={10}
      lineHeight={24}
      _light={{
        color: theme.colors.textLightMode[600]
      }}
      _dark={{
        color: theme.colors.textDarkMode[300]
      }}
      ellipsizeMode="tail"
      noOfLines={1}
      maxWidth={'84%'}
    >
      {address}
    </Text>
  )
}

import React from 'react'
import { Box, HStack, Image, Text } from 'native-base'

const Chip = ({
  children,
  ...rest
}: React.PropsWithChildren<React.ComponentProps<typeof HStack>>) => (
  <HStack space={2} alignItems={'center'} {...rest}>
    {children}
  </HStack>
)

type BusinessCoinsProps = {
  isLoading: boolean
  coins: number
  image: string
  name: string
  color: string
  containerProps?: React.ComponentProps<typeof HStack>
}

const BusinessCoins = ({
  isLoading,
  coins,
  image,
  name,
  color,
  containerProps
}: BusinessCoinsProps) => {
  if (isLoading) {
    return (
      <Chip
        height={8}
        minWidth={100}
        borderWidth={1}
        borderColor={color}
        borderRadius={999}
        paddingX={3}
        {...containerProps}
      >
        <Box
          w={5}
          h={5}
          borderRadius={999}
          overflow={'hidden'}
          backgroundColor={'rgba(255, 255, 255, 0.6)'}
        />
      </Chip>
    )
  }

  return coins ? (
    <Chip
      height={8}
      borderWidth={1}
      borderColor={color}
      borderRadius={999}
      paddingX={3}
      {...containerProps}
    >
      <Image
        w={5}
        h={5}
        borderRadius={999}
        overflow={'hidden'}
        source={{ uri: image }}
        alt={name}
        accessibilityIgnoresInvertColors
      />
      <Text
        color={color}
        fontFamily="Inter-SemiBold"
        fontSize={15}
        lineHeight={20}
      >{`${coins} ${name}`}</Text>
    </Chip>
  ) : null
}

export { BusinessCoins }

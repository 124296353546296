/* eslint-disable unicorn/filename-case */
import { NativeSyntheticEvent } from 'react-native'
import { InfiniteData } from '@tanstack/react-query'
import axios from 'axios'
import { IEvent } from 'matrix-js-sdk'
import moment from 'moment'

import { AppNavigation, AppRoutes, getAllParamsFromRoutes, RoomRoutes } from '../../navigators'

import { matrixServerUrl } from './constants'
import {
  CommunitiesAppRoutes,
  MatrixMessage,
  MatrixReplyType,
  MemberType,
  NativeEventType,
  QueryPage
} from './types'

// export const getEventReputation = async (getterData: GetterData) => {
//   const { roomId, eventId, accessToken } = getterData

//   if (!accessToken) {
//     return []
//   }

//   const messages = await axios
//     .get<EventResponse>(
//       `${matrixServerUrl}/_matrix/client/v1/rooms/${roomId}/relations/${eventId}/v.reputation?access_token=${accessToken}&limit=100`
//     )
//     .then(({ data }) => data.chunk)
//     .catch(() => [])

//   const initialReputation = 0
//   return messages.length > 0
//     ? messages.reduce(
//         (accumulator, currentValue) => accumulator + (currentValue.content.body || 0),
//         initialReputation
//       )
//     : 0
// }

export const getTimeToDisplay = (timestamp: number) => {
  let response = ''
  const currentTime = Date.now()
  const timeDiff = currentTime - timestamp

  const seconds = Math.floor(timeDiff / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const weeks = Math.floor(days / 7)

  if (seconds < 60) {
    response = 'Just now'
  } else if (minutes === 1) {
    response = `${minutes} minute ago`
  } else if (minutes < 60) {
    response = `${minutes} minutes ago`
  } else if (hours === 1) {
    response = `${hours} hour ago`
  } else if (hours < 24) {
    response = `${hours} hours ago`
  } else if (days === 1) {
    response = `${days} day ago`
  } else if (days < 7) {
    response = `${days} days ago`
  } else if (weeks === 1) {
    response = `${weeks} week ago`
  } else {
    response = `${weeks} weeks ago`
  }
  return response
}

export const isCurrentUserJoined = (members?: MemberType[], synapseUserId?: string) => {
  let isJoined = false
  if (!synapseUserId) {
    return isJoined
  }

  if (!members) {
    return false
  }

  const currentUser = members.find(member => member.id === synapseUserId)
  if (currentUser) {
    isJoined = currentUser.isJoined
  }
  return isJoined
}

export const mxcToHttp = (mxcUrl: string, serverName = 'matrix.api.vatominc.com') => {
  return mxcUrl.replace('mxc://', `https://${serverName}/_matrix/media/v3/download/`)
}

// export const getUserReputation = async () => {
//   await axios.get(
//     `${matrixServerUrl}/_matrix/client/v3/rooms/${roomId}/messages?access_token=${accessToken}&filter={"types":["v.room.user.reputation"]}`
//   )
// }

export const formatNumber = (num: number) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M'
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k'
  } else {
    return num
  }
}

export const isJson = (str: string) => {
  let value = typeof str !== 'string' ? JSON.stringify(str) : str
  try {
    value = JSON.parse(value)
  } catch (e) {
    return false
  }
  return typeof value === 'object' && value !== null
}

export const transformedMessageData = (event: IEvent): MatrixMessage => {
  let threadRepliesCount = 0

  if (event.unsigned['m.relations']) {
    if (event.unsigned['m.relations']['m.thread']) {
      threadRepliesCount = event.unsigned['m.relations']['m.thread'].count
    }
  }

  const { body, formatted_body } = event.content

  return {
    eventId: event.event_id,
    eventType: event.type,
    sender: event.sender,
    content: event.type === 'm.room.message' ? formatted_body : body,
    // reputation: messageReputation,
    media: event.content.media ?? null,
    replacing: event.content.replacing ?? null,
    threadRepliesCount: threadRepliesCount,
    timestamp: event.origin_server_ts
  }
}

export const transformedReplyData = (event: IEvent): MatrixReplyType => {
  return {
    eventId: event.event_id,
    sender: event.sender,
    content: event.content.body,
    eventType: event.type,
    media: event.content.media ?? null,
    // ...(stake ? { stake: stake } : {})
    relatesTo: event.content['m.relates_to']?.event_id,
    timestamp: event.origin_server_ts
  }
}

export const getRepliesData = (data: InfiniteData<any> | undefined): MatrixReplyType[] => {
  return (
    data?.pages.flatMap(group => group?.map((reply: IEvent) => transformedReplyData(reply))) ?? []
  )
}

export const getFilters = (activeFilter: string, messages: any, sender?: string) => {
  const messageTypes =
    activeFilter === 'all'
      ? messages.map(({ id }: { id: string }) => `"${id}"`).join(',')
      : `"${activeFilter}"`
  const filters = `&filter={"types":[${messageTypes}]${sender ? `,"senders":["${sender}"]` : ''}}`
  return filters
}

export const isCloseToBottom = (event: NativeSyntheticEvent<unknown>) => {
  const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent as NativeEventType
  const paddingBottom = 500
  return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingBottom
}

export const isYouTubeLink = (url: string) => {
  const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/
  return pattern.test(url)
}

export const extractYoutubeID = (url: string) => {
  const regExpGroup1 = /^https?:\/\/www\.youtube\.com\/watch\?v=([^&\s]+)/
  const regExpGroup2 = /^https?:\/\/youtu\.be\/([^&\s]+)/
  const regExpGroup3 = /^https?:\/\/youtu\.be\/([a-zA-Z0-9_-]+)(\?.+)?$/
  const regExpGroup4 = /^https?:\/\/youtube\.com\/watch\?v=([^&\s]+)/
  const regExpGroup5 = /\/embed\/(.*?)(\?|$)/
  const regExpGroup6 = /\/live\/(.*?)(\?|$)/

  const match1 = url.match(regExpGroup1)
  const match2 = url.match(regExpGroup2)
  const match3 = url.match(regExpGroup3)
  const match4 = url.match(regExpGroup4)
  const match5 = url.match(regExpGroup5)
  const match6 = url.match(regExpGroup6)

  if (match1 && match1[1].length == 11) {
    return match1[1]
  } else if (match2 && match2[1].length == 11) {
    return match2[1]
  } else if (match3 && match3[1].length == 11) {
    return match3[1]
  } else if (match4 && match4[1].length == 11) {
    return match4[1]
  } else if (match5 && match5[1].length == 11) {
    return match5[1]
  } else if (match6 && match6[1].length == 11) {
    return match6[1]
  } else {
    return null
  }
}

export const isVimeoLink = (url: string) => {
  const pattern = /^(https?:\/\/)?(www\.)?(vimeo\.com)\/([a-z]*\/)*([0-9]{6,11})[?]?.*$/
  return pattern.test(url)
}

export const extractVimeoID = (url: string) => {
  const pattern = /^(https?:\/\/)?(www\.)?(vimeo\.com)\/([a-z]*\/)*([0-9]{6,11})[?]?.*$/
  const match = url.match(pattern)
  return match ? match[5] : null
}

export const isLoomLink = (url: string) => {
  const pattern = /^(https?:\/\/)?(www\.)?(loom\.com\/share)\/[a-zA-Z0-9]+(\?.+)?$/
  return pattern.test(url)
}

export const extractLoomID = (url: string) => {
  const match = url.match(/loom\.com\/share\/([a-zA-Z0-9]+)/)
  if (match && match[1]) {
    return match[1]
  } else {
    return null
  }
}

export const isM3U8Link = (url: string) => {
  const pattern = /^https?:\/\/.*\.m3u8(\?.*)?$/
  return pattern.test(url)
}

export const getFilteredPagesData = (oldData: any, event: IEvent) => {
  return oldData?.pages?.map((page: QueryPage) => {
    let filteredChunk = page.chunk.filter(
      event => !event.unsigned || !event.unsigned.redacted_because
    )

    filteredChunk = filteredChunk.filter(msgEvent => msgEvent.event_id !== event.redacts)

    return {
      chunk: filteredChunk
    }
  })
}

export const getUploadedMediaUrl = async (
  blob: ArrayBufferLike,
  mimeType: string,
  accessToken?: string
) => {
  const uploadUrl = await axios
    .post(`${matrixServerUrl}/_matrix/media/v3/upload?access_token=${accessToken}`, blob, {
      headers: {
        'Content-Type': mimeType
      }
    })
    .then(response => response.data.content_uri)
  return uploadUrl
}

export const differenceInHours = (timestamp: number) => {
  const currentDateTimestamp = new Date().getTime()
  const millisecondsPerHour = 1000 * 60 * 60
  const difference = Math.abs(timestamp - currentDateTimestamp)
  const hours = difference / millisecondsPerHour
  return Math.round(hours)
}

export const differenceInDays = (timestamp: number) => {
  const currentDateTimestamp = new Date().getTime()
  const millisecondsPerDay = 1000 * 60 * 60 * 24
  const difference = Math.abs(timestamp - currentDateTimestamp)
  const days = difference / millisecondsPerDay
  return Math.round(days)
}

export const getCombinedDateTimestamp = (date: Date, time: Date) => {
  const date1 = new Date(date)
  const date2 = new Date(time)

  const combinedDate = new Date(
    Date.UTC(
      date1.getUTCFullYear(),
      date1.getUTCMonth(),
      date1.getUTCDate(),
      date2.getUTCHours(),
      date2.getUTCMinutes(),
      date2.getUTCSeconds(),
      date2.getUTCMilliseconds()
    )
  )

  return combinedDate.getTime()
}

export const getAdditionalInfo = (parsedBody: any) => {
  return Object.keys(parsedBody)
    .filter(key => key !== 'elements')
    .reduce((obj: any, key) => {
      obj[key] = parsedBody[key]
      return obj
    }, {})
}

export const addObjectIfUnique = (chunk: IEvent[], newEvent: IEvent) => {
  let newChunk = chunk
  if (!chunk.some(event => event.event_id === newEvent.event_id)) {
    newChunk = [...chunk, newEvent]
  }
  return newChunk
}

export const getTimeDifference = (endTime: number, isAbbreviated?: boolean) => {
  const currentDateTimestamp = new Date().getTime()
  const now = moment(currentDateTimestamp)
  const end = moment(endTime)

  const diffMinutes = end.diff(now, 'minutes')
  const diffHours = end.diff(now, 'hours')
  const diffDays = end.diff(now, 'days')

  const daysReference = diffDays === 1 ? 'day' : 'days'
  const hoursReference =
    diffHours === 1 ? (isAbbreviated ? 'hr' : 'hour') : isAbbreviated ? 'hrs' : 'hours'
  const minutesReference =
    diffHours === 1 ? (isAbbreviated ? 'min' : 'minute') : isAbbreviated ? 'min' : 'minutes'

  if (diffDays > 0) {
    return `${diffDays} ${daysReference}`
  } else {
    return diffHours > 0 ? `${diffHours} ${hoursReference}` : `${diffMinutes} ${minutesReference}`
  }
}

export const allSettled = <T>(promises: Array<Promise<T>>) =>
  Promise.all(
    promises.map(p =>
      p
        .then(value => ({
          status: 'fulfilled' as const,
          value
        }))
        .catch(reason => ({
          status: 'rejected' as const,
          reason
        }))
    )
  )

/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ListingReceiptModel, ListingReceiptModelType } from "./ListingReceiptModel"
import { ListingReceiptModelSelector } from "./ListingReceiptModel.base"
import { TwitterProfileModel, TwitterProfileModelType } from "./TwitterProfileModel"
import { TwitterProfileModelSelector } from "./TwitterProfileModel.base"
import { RootStoreType } from "./index"


/**
 * ListingEventBase
 * auto generated base class for the model ListingEventModel.
 */
export const ListingEventModelBase = ModelBase
  .named('ListingEvent')
  .props({
    __typename: types.optional(types.literal("ListingEvent"), "ListingEvent"),
    createdAt: types.union(types.undefined, types.frozen()),
    walletAddress: types.union(types.undefined, types.string),
    profile: types.union(types.undefined, types.null, types.late((): any => TwitterProfileModel)),
    feedEventId: types.union(types.undefined, types.string),
    lifecycle: types.union(types.undefined, types.string),
    listingReceiptAddress: types.union(types.undefined, types.frozen()),
    listing: types.union(types.undefined, types.null, types.late((): any => ListingReceiptModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ListingEventModelSelector extends QueryBuilder {
  get createdAt() { return this.__attr(`createdAt`) }
  get walletAddress() { return this.__attr(`walletAddress`) }
  get feedEventId() { return this.__attr(`feedEventId`) }
  get lifecycle() { return this.__attr(`lifecycle`) }
  get listingReceiptAddress() { return this.__attr(`listingReceiptAddress`) }
  profile(builder: string | TwitterProfileModelSelector | ((selector: TwitterProfileModelSelector) => TwitterProfileModelSelector) | undefined) { return this.__child(`profile`, TwitterProfileModelSelector, builder) }
  listing(builder: string | ListingReceiptModelSelector | ((selector: ListingReceiptModelSelector) => ListingReceiptModelSelector) | undefined) { return this.__child(`listing`, ListingReceiptModelSelector, builder) }
}
export function selectFromListingEvent() {
  return new ListingEventModelSelector()
}

export const listingEventModelPrimitives = selectFromListingEvent().createdAt.walletAddress.feedEventId.lifecycle.listingReceiptAddress

import React from 'react'
import {
  Animated,
  Dimensions,
  Modal,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View
} from 'react-native'

import CloseIcon from '../../../../assets/header-icons/close-icon.svg'
import { useUserSketchVotes } from '../../../../queries'
import { useCommunitiesTheme } from '../../../../themes'
import { SketchType } from '../../../../types'
import { closeAnimation, openAnimation } from '../../../modalHelpers'
import { useMessageContext } from '../../MessageContext'
import ImageGallery from '../ImageGallery/ImageGallery'
import { WebDisplay } from '../TextParagraph'

import VoteButton from './VoteButton'

type QuestionProps = {
  sketch: SketchType
  roomId: string
  additionalInfo: any
  spaceId: string
}

const Sketch = (props: QuestionProps) => {
  const { messageEventID } = useMessageContext()
  const { sketch, roomId, additionalInfo, spaceId } = props
  const communitiesTheme = useCommunitiesTheme()

  const [webViewHeight, setWebViewHeight] = React.useState(0)
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const fadeAnim = React.useRef(new Animated.Value(0)).current
  const slideAnim = React.useRef(new Animated.Value(1000)).current
  const animationDuration = 300

  const { startTime, endTime } = additionalInfo

  const { data: sketchVotes } = useUserSketchVotes(roomId, messageEventID)

  const hasUserVoted = !!sketchVotes?.chunk?.find(
    (vote: { content: { body: { index: number } } }) => vote.content.body.index === sketch.id
  )

  const isSketchLocked = () => {
    const currentDateTimestamp = new Date().getTime()
    return endTime < currentDateTimestamp || startTime > currentDateTimestamp
  }

  const openModal = () => {
    setIsModalVisible(true)
    openAnimation(fadeAnim, slideAnim, animationDuration).start()
  }

  const closeModal = () => {
    closeAnimation(fadeAnim, slideAnim, animationDuration).start(() => {
      setIsModalVisible(false)
    })
  }

  const media = typeof sketch.media === 'string' ? JSON.parse(sketch.media) : null
  const { height } = Dimensions.get('screen')

  return (
    <>
      <Pressable accessibilityRole="button" onPress={() => openModal()}>
        <View
          style={{
            ...styles.container,
            borderLeftColor: hasUserVoted
              ? communitiesTheme.sketches.borderLeftActive
              : communitiesTheme.sketches.borderLeft,
            backgroundColor: communitiesTheme.sketches.backgroundColor
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            {sketch.category ? (
              <View
                style={{
                  paddingHorizontal: 6,
                  paddingVertical: 2,
                  borderWidth: 1,
                  borderColor: '#ADB5BD',
                  borderRadius: 3,
                  marginBottom: 8
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    fontWeight: '500',
                    lineHeight: 12,
                    color: '#868E96',
                    textTransform: 'uppercase'
                  }}
                >
                  {sketch.category}
                </Text>
              </View>
            ) : null}

            <Text style={styles.questionNumber}>{sketch.id + 1}</Text>
          </View>

          <Text style={{ ...styles.title, color: communitiesTheme.sketches.titleColor }}>
            {sketch.title}
          </Text>
          <View style={{ marginBottom: 16 }}>
            <View style={{ maxHeight: 40, overflow: 'hidden' }}>
              <View
                onLayout={event => {
                  const { height } = event.nativeEvent.layout
                  setWebViewHeight(height)
                }}
              >
                <WebDisplay
                  text={sketch.text}
                  textStyle={{ color: communitiesTheme.sketches.titleColor }}
                  spaceId={spaceId}
                />
              </View>
            </View>

            {webViewHeight > 40 ? (
              <Text
                style={{
                  fontSize: 15,
                  fontWeight: '600',
                  lineHeight: 20,
                  color: communitiesTheme.sketches.titleColor
                }}
              >
                more
              </Text>
            ) : null}
          </View>

          <VoteButton
            roomID={roomId}
            eventID={messageEventID}
            hasUserVoted={hasUserVoted}
            sketchID={sketch.id}
            sketchVotes={sketchVotes}
            isSketchLocked={isSketchLocked()}
          />
        </View>
      </Pressable>

      <Modal visible={isModalVisible} transparent={true} animationType="none">
        <Pressable accessibilityRole="button" onPress={() => closeModal()}>
          <Animated.View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: `rgba(0, 0, 0, 0.5)`,
              opacity: fadeAnim
            }}
          />
        </Pressable>

        <Animated.View
          style={{
            ...styles.animatedContainer,
            transform: [{ translateY: slideAnim }],
            backgroundColor: communitiesTheme.background,
            maxHeight: height - 50
          }}
          pointerEvents="box-none"
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingRight: 16,
              alignItems: 'center',
              paddingBottom: 12
            }}
          >
            <Pressable accessibilityRole="button" onPress={() => closeModal()}>
              <View>
                <CloseIcon fill={communitiesTheme.popUp.title} width={20} height={20} />
              </View>
            </Pressable>
          </View>

          <ScrollView style={{ paddingVertical: 16, paddingRight: 16 }}>
            <Text style={{ ...styles.title, color: communitiesTheme.sketches.titleColor }}>
              {sketch.title}
            </Text>
            <View style={{ marginBottom: 16 }}>
              <WebDisplay
                text={sketch.text}
                textStyle={{ color: communitiesTheme.sketches.titleColor }}
                spaceId={roomId}
              />
            </View>
            {media ? <ImageGallery roomId={roomId} key="media" /> : null}
            <View style={{ height: 30 }} />
          </ScrollView>
        </Animated.View>
      </Modal>
    </>
  )
}

export default Sketch

const styles = StyleSheet.create({
  container: {
    paddingVertical: 20,
    paddingHorizontal: 16,
    backgroundColor: '#F8F9FA',
    marginTop: 8,
    borderRadius: 4,
    borderLeftWidth: 2
  },
  title: {
    fontSize: 16,
    lineHeight: 22,
    fontWeight: '600',
    marginBottom: 4,
    color: '#3F4A55'
  },
  anchor: {
    fontSize: 11,
    lineHeight: 15,
    color: '#868E96',
    marginTop: 8
  },
  questionNumber: {
    fontSize: 13,
    lineHeight: 18,
    fontWeight: '500',
    color: '#868E96',
    marginLeft: 'auto'
  },
  animatedContainer: {
    position: 'absolute',
    bottom: 0,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingTop: 34,
    paddingBottom: 12,
    paddingLeft: 16,
    width: '100%',
    zIndex: 2,
    flex: 1
  }
})

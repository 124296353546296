import React from 'react'
import { FlatList } from 'react-native'
import { Contact, ContactSources, DestinationTypes, useGetContacts } from '@vatom/sdk/react'
import { Avatar, PressableOpacity, Text } from '@vatom/wombo'
import { View } from 'native-base'

type RecentListProps = {
  handleOnPress: (item: Contact) => void
}

export default function RecentList({ handleOnPress }: RecentListProps) {
  const { data: contacts } = useGetContacts(
    {
      type: DestinationTypes.Id,
      value: ''
    },
    {
      tokenType: undefined,
      sources: [ContactSources.recents]
    }
  )

  return contacts?.length !== 0 ? (
    <View marginBottom={4}>
      <Text
        fontFamily="Inter-Bold"
        marginBottom={3}
        fontSize={22}
        fontWeight={700}
        color="black"
        tx="common.recent"
      />
      <FlatList
        data={contacts}
        keyExtractor={(_item, index) => `recent-item-${index}`}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }) => <RecentItem item={item} onPress={handleOnPress} />}
      />
    </View>
  ) : null
}

type ItemProps = {
  item: Contact
  onPress: (item: Contact) => void
}

const RecentItem = (props: ItemProps) => {
  const { onPress, item } = props

  return item ? (
    <PressableOpacity marginRight={3} w={62} onPress={() => onPress(item)}>
      {item.id ? (
        <Avatar size={62} url={item.avatarUrl} name={item.name} />
      ) : (
        <Avatar size={62} name={item.name} />
      )}
      <Text numberOfLines={2} fontSize={11} mt={1} textAlign={'center'}>
        {item.name}
      </Text>
    </PressableOpacity>
  ) : null
}

import { IdentityType } from '@vatom/sdk/core'

import { useSDK } from '../store'

interface IdentityHookOptions {
  allowedTypes?: IdentityType[]
}

export const useIdentities = (options?: IdentityHookOptions) => {
  const sdk = useSDK()
  const identities = sdk.dataPool.user.identities
  const allowedIdentityTypes = options?.allowedTypes ?? []
  const allowedIdentities = identities.filter(identity =>
    allowedIdentityTypes.includes(identity.type)
  )

  return {
    allowedIdentities,
    identities
  }
}

import { CampaignOverview, CampaignRules } from './types'

export const getPickUpPointsForObjectDefinitionId = (
  objectDefinitionId: string,
  rules: CampaignRules
) => {
  const actionId = 'action-id-pickup-v1'
  const addPointsEffectId = 'add-points-v2'
  const defaultPointsBump = {
    points: 0,
    channel: 'total'
  }

  const rule = rules.items.find(rule => {
    return (
      rule.trigger.id === actionId &&
      rule.trigger.config.objectDefinitionId === objectDefinitionId &&
      rule.effects.find(effect => effect.id === addPointsEffectId)
    )
  })
  if (!rule) {
    return defaultPointsBump
  }
  const effect = rule.effects.find(effect => {
    return effect.id === addPointsEffectId
  })
  if (!effect) {
    return defaultPointsBump
  }
  return {
    points: effect.config.points ?? defaultPointsBump.points,
    channel: effect.config.channel ?? defaultPointsBump.channel
  }
}

export const isVatomEphemeral = (objectDefinitionId: string, overview: CampaignOverview) => {
  const trashVatomid = 'trash-vatom'
  return overview.rules.some(
    r =>
      r.trigger.config.objectDefinitionId === objectDefinitionId &&
      r.effects.some(e => e && e.id === trashVatomid)
  )
}

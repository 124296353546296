import React from 'react'
import { StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useMatrixUser } from '@vatom/sdk/react'
import axios from 'axios'

import { matrixServerUrl } from '../../../../constants'
import { useCommunitiesTheme } from '../../../../themes'
import VoteIcon from '../assets/vote-icon.svg'

const VoteButton = (props: any) => {
  const { roomID, eventID, hasUserVoted, sketchID, sketchVotes, isSketchLocked } = props
  const { data: matrixUser } = useMatrixUser()
  const communitiesTheme = useCommunitiesTheme()
  const queryClient = useQueryClient()

  const handleVote = async () => {
    if (isSketchLocked) {
      return
    }
    const userVote = sketchVotes?.chunk[0]
    if (userVote) {
      if (userVote.content.body.index !== sketchID) {
        removeVote.mutate(userVote)
        addVote.mutate()
      }
    } else {
      addVote.mutate()
    }
  }

  const addVote = useMutation({
    mutationFn: () =>
      axios.post(
        `${matrixServerUrl}/_matrix/client/r0/rooms/${roomID}/send/v.room.sketch.vote?access_token=${matrixUser?.access_token}`,
        {
          'm.relates_to': {
            rel_type: matrixUser?.user_id + '.sketch.vote',
            event_id: eventID
          },
          body: {
            index: sketchID
          }
        }
      ),
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ['message-user-sketch-votes', roomID, eventID, matrixUser?.user_id]
      })

      const previousVote = queryClient.getQueryData([
        ['message-user-sketch-votes', roomID, eventID, matrixUser?.user_id]
      ])

      queryClient.setQueryData(
        ['message-user-sketch-votes', roomID, eventID, matrixUser?.user_id],
        (old: any) => ({
          chunk: [
            {
              content: {
                body: {
                  index: sketchID
                },
                'm.relates_to': {
                  event_id: eventID,
                  rel_type: matrixUser?.user_id + '.sketch.vote'
                }
              },
              room_id: roomID,
              sender: matrixUser?.user_id,
              type: 'v.room.sketch.vote',
              unsigned: {
                age: 330
              },
              user_id: matrixUser?.user_id
            }
          ]
        })
      )

      return { previousVote }
    },
    onError: (err, newVote, context) => {
      queryClient.setQueryData(
        ['message-user-sketch-votes', roomID, eventID, matrixUser?.user_id],
        context?.previousVote
      )
    }
  })

  const removeVote = useMutation({
    mutationFn: (choice: any) => {
      const dateNow = Date.now()
      return axios.put(
        `${matrixServerUrl}/_matrix/client/r0/rooms/${roomID}/redact/${choice.event_id}/${eventID}.sketch.${dateNow}?access_token=${matrixUser?.access_token}`,
        {}
      )
    },
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ['message-user-sketch-votes', roomID, eventID, matrixUser?.user_id]
      })

      const previousVote = queryClient.getQueryData([
        ['message-user-sketch-votes', roomID, eventID, matrixUser?.user_id]
      ])

      return { previousVote }
    },
    onError: (err, newVote, context) => {
      queryClient.setQueryData(
        ['message-user-sketch-votes', roomID, eventID, matrixUser?.user_id],
        context?.previousVote
      )
    }
  })

  return (
    <TouchableWithoutFeedback accessibilityRole="button" onPress={async () => await handleVote()}>
      <View
        style={{
          ...styles.button,
          backgroundColor: hasUserVoted ? communitiesTheme.accentColor : 'transparent',
          borderColor: hasUserVoted
            ? communitiesTheme.accentColor
            : communitiesTheme.sketchVoteButton.border
        }}
      >
        <VoteIcon fill={hasUserVoted ? communitiesTheme.accentColorText : '#ADB5BD'} />
        <View
          style={{
            borderLeftWidth: 1,
            borderLeftColor: hasUserVoted ? '#FFFFFF80' : communitiesTheme.sketchVoteButton.line,
            marginLeft: 8,
            paddingLeft: 8
          }}
        >
          <Text
            style={{
              ...styles.buttonText,
              color: hasUserVoted
                ? communitiesTheme.accentColorText
                : communitiesTheme.sketches.titleColor
            }}
          >
            Vote
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

export default VoteButton

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    paddingVertical: 6,
    paddingLeft: 8,
    paddingRight: 12,
    borderWidth: 1,
    borderRadius: 3,
    alignSelf: 'flex-start',
    alignItems: 'center'
  },
  buttonText: { lineHeight: 20, fontSize: 11, fontWeight: '500' }
})

import { Region } from 'react-native-maps'
import { BoundingBoxType } from '@vatom/sdk/core'

/**
 *  This function roughly calculates the meter distance between two coordinates using the Haversine formula.
 *  Adapted from: https://stackoverflow.com/a/1502821
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getDistance(p1: any, p2: any) {
  // Check if points are functions
  if (typeof p1.lat === 'function') p1 = { lat: p1.lat(), lng: p1.lng() }
  if (typeof p2.lat === 'function') p2 = { lat: p2.lat(), lng: p2.lng() }

  const R = 6378137 // Earth’s mean radius in meter
  const dLat = rad(p2.lat - p1.lat)
  const dLong = rad(p2.lng - p1.lng)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c
  return d // returns the distance in meter
}

function rad(x: number) {
  return (x * Math.PI) / 180
}

// Convert react-native-maps Region to Vatom's API boundingBox (top right / north east to bottom left / south west coordinates)
// ref: https://stackoverflow.com/questions/42743984/react-native-maps-get-top-left-corner-and-bottom-right-corner-latitude-and-longi
// more: https://github.com/react-native-maps/react-native-maps/issues/505
export const getBoundingBoxFromRegion = (region: Region): BoundingBoxType => {
  const { latitude, latitudeDelta, longitude, longitudeDelta } = region

  const dx = latitudeDelta * 0.5
  const dy = longitudeDelta * 0.5
  const southwest = { lat: latitude - dx, lng: longitude - dy }
  const northeast = { lat: latitude + dx, lng: longitude + dy }

  return {
    bottom_left: {
      // lat: parseFloat(southwest.lat.toFixed(4)),
      // lon: parseFloat(southwest.lng.toFixed(4))
      lat: southwest.lat,
      lon: southwest.lng
    },
    top_right: {
      // lat: parseFloat(northeast.lat.toFixed(4)),
      // lon: parseFloat(northeast.lng.toFixed(4))
      lat: northeast.lat,
      lon: northeast.lng
    }
  }
}

import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { Linking, Platform } from 'react-native'
import { LatLng } from 'react-native-maps'
import { useIsFocused } from '@react-navigation/native'
import { BVatomTokenType } from '@vatom/BVatom/plugin'
import { TokenType } from '@vatom/sdk/core'
import { useAnalytics, useNetworkUserId, useSDK, useThrottle } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { Button, Status, Text, theme, Toast } from '@vatom/wombo'
import { Box, HStack, Image, Modal } from 'native-base'

import Alert from '../../../components/Alert'
import { useIsDesktop } from '../../../hooks/useIsDesktop'
import logger from '../../../logger'
import { getDistance } from '../../../utils/map'

export interface IPickUpModalData {
  id: string
  title: string
  price?: {
    type: string
    value: number
  }
  image: string
}

export interface IPickUpModal {
  isOpen: boolean
  token?: TokenType
  userLocation?: LatLng
  onPickUp: () => void
  onCancel: () => void
  onPostModal?: () => void
  setOpenModal?: (isOpen: boolean) => void
  buttonProps?: React.ComponentProps<typeof Button>
  /**
   * Makes the content look like its a pickup but it really is a dispenser
   */
  fakePickup?: boolean
  source?: string
  modalProps?: React.ComponentProps<typeof Modal>
  /**
   * There are some cases where we must wait for the response before taking an action (like in the case of a dispenser) or some custom ar game flows
   */
  optimistic?: boolean
}

// Users can only pick up an NFT if they are less than this distance away from it (in meters)
const MaxDistanceForPickup = 500

export const PickUpModal = ({
  token,
  userLocation,
  onPickUp,
  onCancel,
  onPostModal,
  buttonProps,
  fakePickup,
  source,
  modalProps,
  isOpen,
  optimistic,
  setOpenModal
}: IPickUpModal) => {
  const networkUserId = useNetworkUserId()
  const analytics = useAnalytics()
  const sdk = useSDK()
  // only intermediate state meant to be used for the closing animation while the token is undefined
  // The rest of the properties like isForSale, isDispenser, etc. should be derived from the token itself
  const [isPicking, setIsPicking] = useState(false)
  const vatom = token as BVatomTokenType
  const isDesktop = useIsDesktop()
  const isFocused = useIsFocused()
  const { config } = useVatomWalletSdkStore()
  const SDKMaxDistanceForPickup = config.pageConfig?.features?.maxDistanceForPickup

  // @ts-expect-error
  const hoursText = token?.state?.['map-ready-poi-business-v1']?.hoursText
  // @ts-expect-error
  const stockAvailable = token?.state?.['pepsi-store-prizes-v1']?.numPrizesAvailable

  const maxDistance = SDKMaxDistanceForPickup || MaxDistanceForPickup
  const isTakeMeThere = useMemo(() => {
    if (token?.actions && token?.actions?.length === 0) {
      return translate('mapScreen.farAway')
    }
    if (token?.position && userLocation) {
      const latitude = token.position.coordinates?.[1] || 0
      const longitude = token.position.coordinates?.[0] || 0
      const userPos = {
        lat: userLocation.latitude,
        lng: userLocation.longitude
      }
      const vatomPos = { lat: latitude, lng: longitude }
      const distance = getDistance(userPos, vatomPos)
      if (distance > maxDistance) {
        logger.info('distance', distance, userLocation, token.position.coordinates)
        return translate('mapScreen.farAway')
      }
    }
  }, [maxDistance, token?.position, token?.actions, userLocation])

  const isForSale = useMemo(() => {
    if (!vatom) return false
    return (
      !!vatom.commerceInfo &&
      (vatom.commerceInfo.channel === 'direct' || vatom.commerceInfo.channel === 'marketplace') &&
      networkUserId !== vatom.owner
    )
  }, [vatom, networkUserId])

  const isDispenser = useMemo(() => !!token?.actions.find(a => a === 'Dispense'), [token])

  const isLegacyDispenser = useMemo(
    () =>
      vatom?.properties?.template.indexOf('MapDispenser') !== -1 ||
      vatom?.properties?.['is_map_dispenser'] ||
      false,
    [vatom]
  )

  const userOwnsDispenser = useMemo(() => {
    if (!token) return false
    return token.owner === networkUserId
  }, [token, networkUserId])

  useEffect(() => {
    if (!userLocation) return
    // Log analytics for vatom pickup. Based on initialLocation since we only want to send it once
    analytics.event(
      'initiateAction',
      {
        actionUri: 'Pickup',
        source: source ?? 'Map',
        location: {
          longitude: userLocation.longitude,
          latitude: userLocation.latitude
        }
      },
      token
    )
  }, [analytics, userLocation, token, source])

  useEffect(() => {
    if (!isFocused) {
      onCancel()
    }
  }, [isFocused, onCancel])

  const showPickup = useMemo(
    () => !isForSale && (!isDispenser || (isDispenser && userOwnsDispenser)),
    [isForSale, isDispenser, userOwnsDispenser]
  )

  const doPickup = async () => {
    if (token?.type === 'vatom-new') {
      await pickup()
    } else {
      await legacyPickUp()
    }
  }

  const pickup = async () => {
    setOpenModal?.(false)
    setIsPicking(true)
    try {
      await token?.performAction('Pickup')

      Toast({
        title: translate('mapScreen.pickupSuccess', { name: 'object' }),
        placement: 'top',
        status: Status.success
      })
      setIsPicking(false)
      onPickUp()
    } catch (err) {
      console.log('LOG: > doPickup > err:', err)
      setIsPicking(false)
      Alert.showError(err).then(close)
    }
  }

  const legacyPickUp = async () => {
    if (token && !isPicking) {
      setIsPicking(true)
      try {
        if (token) {
          console.log('LOG: > doPickup > token:', token)
          sdk.dataPool.regions.map(region => {
            region?.preemptiveChange(token.id, '/properties/dropped', false)
            region?.regions.forEach(region => {
              region.preemptiveChange(token.id, '/properties/dropped', false)
            })
          })

          const isJoyHack =
            (token as BVatomTokenType).payload['vAtom::vAtomType'].publisher_fqdn === 'mx.joyapp' &&
            (token as BVatomTokenType).getResource('CardImage')

          if (optimistic && !isJoyHack) {
            setOpenModal?.(false)
          }

          await token.performAction('Pickup')
          analytics.event(
            'performAction',
            {
              event: 'performAction',
              actionUri: 'Pickup',
              eventValue: 1
            },
            token
          )

          if (isJoyHack) {
            setIsPicking(false)
            onPostModal?.()

            return
          }

          Toast({
            title: translate('mapScreen.pickupSuccess', { name: 'object' }),
            placement: 'top',
            status: Status.success
          })

          setIsPicking(false)
          setIsMinimized(false)
          onPickUp()
        }
      } catch (err) {
        console.log('LOG: > doPickup > err:', err)
        setIsPicking(false)
        setIsMinimized(false)
        Alert.showError(err).then(close)

        if (token) {
          sdk.dataPool.regions.map(region => {
            region?.preemptiveChange(token.id, '/properties/dropped', true)
            region?.regions.forEach(region => {
              region.preemptiveChange(token.id, '/properties/dropped', true)
            })
          })
        }
      }
    }
  }

  const doDispense = async () => {
    setIsPicking(true)
    const vatom = token as BVatomTokenType
    if (isLegacyDispenser) {
      // Using the old method, pick a random child and call the AcquirePubVariation action on it
      const children = vatom.getChildren()
      const child = children[Math.floor(Math.random() * children.length)]
      if (!child) throw new Error(translate('mapScreen.noDispense'))

      // Call the correct action
      if (child.actions.find(a => a === 'Acquire')) {
        // Acquire it
        await child.performAction('Acquire')

        // Log analytics
        analytics.event(
          'performAction',
          {
            actionUri: 'Acquire',
            location: {
              longitude: child.position?.coordinates[0] || 0,
              latitude: child.position?.coordinates[1] || 0
            },
            source: source ?? 'Map'
          },
          token
        )
        setIsPicking(false)
        setIsMinimized(false)
        onPickUp()
        return 'Acquire'
      } else {
        // Acquire a copy
        await child.performAction('AcquirePubVariation')

        // Log analytics
        analytics.event(
          'performAction',
          {
            actionUri: 'AcquirePubVariation',
            location: {
              longitude: child.position?.coordinates[0] || 0,
              latitude: child.position?.coordinates[1] || 0
            },
            source: source ?? 'Map'
          },
          token
        )
        setIsPicking(false)
        setIsMinimized(false)
        onPickUp()

        return 'AcquirePubVariation'
      }
    } else {
      Toast({
        title: translate('mapScreen.dispensing'),
        placement: 'top',
        status: Status.info
      })

      const result = await vatom.performAction('Dispense')
      // Log analytics
      analytics.event(
        'performAction',
        {
          actionUri: 'Dispense',
          location: {
            longitude: vatom.position?.coordinates[0] || 0,
            latitude: vatom.position?.coordinates[1] || 0
          }
        },
        token
      )
      setIsMinimized(true)
      setIsPicking(false)
      setIsMinimized(false)
      onPickUp()
      return 'Dispense'
    }
  }

  const doBuy = async () => {
    if (token?.commerceInfo?.checkoutUrl) {
      const redirectUrl = Platform.OS === 'web' ? window.location.origin : `com.vatom://`
      openURLWithRedirect(token?.commerceInfo?.checkoutUrl, redirectUrl)
      onPickUp()
    }
  }

  const openURLWithRedirect = (url: string, redirectURL: string) => {
    const fullURL = url + `?redirect_url=${encodeURIComponent(redirectURL)}`
    return Linking.openURL(fullURL)
  }

  const [isMinimized, setIsMinimized] = useState(false)

  // This way the modal will close while having the token prop (so the messaging will be correct while closing)
  const close = () => {
    if (isPicking && isDispenser) {
      setIsMinimized(true)
    } else {
      onCancel()
    }
  }

  const takeMeThere = () => {
    const lat = token?.position?.coordinates?.[1] || 0
    const lng = token?.position?.coordinates?.[0] || 0
    const isAndroid =
      Platform.OS === 'android' || /android/.test(window?.navigator?.userAgent?.toLowerCase())
    const url = isAndroid ? `geo:0,0?q=${lat},${lng}` : `maps:0,0?q=${lat},${lng}`
    return Linking.openURL(url)
  }

  const doCancel = useThrottle(close, 500)

  const height = (isDispenser && userOwnsDispenser) || isTakeMeThere ? 300 : 280

  const isWeb = Platform.OS === 'web'

  const title =
    !isWeb && isForSale ? translate('mapScreen.noTokenPickup') : `${token?.metadata?.name || ''}`

  const isHackImage = useMemo(() => {
    if (token?.displayImage && token?.displayImage?.includes('Cox1qh6ggb/')) {
      return true
    }
    return false
  }, [token])

  const hackImage = useMemo(() => {
    if (!token) {
      return ''
    }
    if (isHackImage) {
      return 'https://resources.vatominc.com/Cox1qh6ggb/6ef0cff0-1156-11ef-a07c-d7fd0079a6fe.png' // custom image
    }
    return token?.displayImage ?? token?.metadata.image
  }, [token, isHackImage])

  return (
    <Modal
      isOpen={isOpen && !isMinimized}
      alignItems="center"
      justifyContent="center"
      animationPreset="slide"
      {...modalProps}
    >
      <Modal.Content
        p={4}
        borderRadius="8px"
        width="320px"
        _light={{
          backgroundColor: theme.colors.white
        }}
        _dark={{
          backgroundColor: theme.colors.grayDarkMode[800]
        }}
      >
        <Box px="16px">
          <Box marginY="16px" height="100px" alignItems="center" justifyContent="center">
            {token?.metadata.image && (
              <Image
                resizeMode="stretch"
                accessibilityIgnoresInvertColors
                source={{ uri: hackImage ?? token.displayImage ?? token.metadata.image }}
                height="100px"
                width="100px"
                alt="modalData.image"
                borderRadius="8px"
              />
            )}
          </Box>
          <Text
            ellipsizeMode="tail"
            fontSize="17px"
            marginBottom="8px"
            fontFamily="Inter-Bold"
            textAlign="center"
            noOfLines={1}
            _dark={{
              color: theme.colors.textDarkMode[100]
            }}
            _light={{
              color: theme.colors.textLightMode[900]
            }}
          >
            {title}
          </Text>
          {token?.metadata?.description && (
            <Text
              _dark={{
                color: theme.colors.textDarkMode[100]
              }}
              _light={{
                color: theme.colors.textLightMode[900]
              }}
              ellipsizeMode="tail"
              fontSize="15px"
              marginBottom="8px"
              fontFamily="Inter-Regular"
              textAlign="center"
              // noOfLines={1}
            >
              {token.metadata.description}
            </Text>
          )}

          {hoursText && (
            <Text
              _dark={{
                color: theme.colors.textDarkMode[100]
              }}
              _light={{
                color: theme.colors.textLightMode[900]
              }}
              ellipsizeMode="tail"
              fontSize="15px"
              marginBottom="12px"
              fontFamily="Inter-Regular"
              textAlign="center"
              // noOfLines={1}
            >
              {hoursText}
            </Text>
          )}
          {stockAvailable && (
            <Text
              _dark={{
                color: theme.colors.textDarkMode[100]
              }}
              _light={{
                color: theme.colors.textLightMode[900]
              }}
              ellipsizeMode="tail"
              fontSize="15px"
              marginBottom="12px"
              fontFamily="Inter-Regular"
              textAlign="center"
              // noOfLines={1}
            >
              Álbumes disponibles: {stockAvailable}
            </Text>
          )}
          {isForSale && (
            <Text
              _dark={{
                color: theme.colors.textDarkMode[100]
              }}
              _light={{
                color: theme.colors.textLightMode[900]
              }}
              textAlign="center"
              fontSize={12}
              tx={isWeb ? 'mapScreen.requiredPayment' : 'mapScreen.seeTokenOwner'}
              txOptions={{
                price: `${token?.commerceInfo?.price || '$0'} ${
                  token?.commerceInfo?.currency?.toUpperCase() || ''
                }`
              }}
            />
          )}
          {isDispenser && userOwnsDispenser && (
            <Text
              _dark={{
                color: theme.colors.textDarkMode[100]
              }}
              _light={{
                color: theme.colors.textLightMode[900]
              }}
              textAlign="center"
              fontSize={12}
              tx="mapScreen.droppedNext"
            />
          )}
          {showPickup ||
            (fakePickup && !isTakeMeThere && (
              <Text
                _dark={{
                  color: theme.colors.textDarkMode[100]
                }}
                _light={{
                  color: theme.colors.textLightMode[900]
                }}
                textAlign="center"
                fontSize={12}
                tx="mapScreen.shouldPickUp"
              />
            ))}
          {!isHackImage && isTakeMeThere && (
            <Text
              _dark={{
                color: theme.colors.textDarkMode[100]
              }}
              _light={{
                color: theme.colors.textLightMode[900]
              }}
              textAlign="center"
              fontSize={11}
              marginBottom={2}
            >
              {isTakeMeThere}
            </Text>
          )}
        </Box>
        {/* <Divider height="1px" backgroundColor="light.300" /> */}
        <HStack height="44px" space={2} justifyContent="center">
          <Button
            _dark={{
              backgroundColor: theme.colors.grayDarkMode[500]
            }}
            _light={{
              backgroundColor: theme.colors.grayCool[200]
            }}
            onPress={doCancel}
            backgroundColor="white"
            variant="outline"
            w="50%"
          >
            <Text
              _light={{
                color: theme.colors.textLightMode[600]
              }}
              _dark={{
                color: 'white'
              }}
              fontFamily="Inter-Regular"
              fontSize="15px"
              tx={
                isPicking && isDispenser
                  ? 'common.minimize'
                  : isTakeMeThere
                  ? 'common.close'
                  : 'common.notNow'
              }
            />
          </Button>
          {isTakeMeThere && (
            <Button
              onPress={takeMeThere}
              backgroundColor="primary.500"
              variant="outline"
              w="50%"
              _light={{
                backgroundColor: theme.colors.systemColorsLight['orange']
              }}
              _dark={{
                backgroundColor: theme.colors.systemColorsDark['orange']
              }}
              {...buttonProps}
            >
              <Text
                fontFamily="Inter-Regular"
                fontSize="15px"
                color="white"
                tx="mapScreen.takeMe"
              />
            </Button>
          )}
          {/* <Divider orientation="vertical" width="1px" backgroundColor="light.300" /> */}
          {isForSale && !isTakeMeThere && (
            <Button
              onPress={doBuy}
              _light={{
                backgroundColor: theme.colors.systemColorsLight['orange']
              }}
              _dark={{
                backgroundColor: theme.colors.systemColorsDark['orange']
              }}
              variant="outline"
              w="50%"
              {...buttonProps}
            >
              <Text
                fontFamily="Inter-SemiBold"
                fontSize="15px"
                color="white"
                tx={isWeb ? 'common.buy' : 'mapScreen.seeDetails'}
              />
            </Button>
          )}
          {isDispenser && !isTakeMeThere && (
            <Button
              onPress={doDispense}
              backgroundColor="primary.500"
              variant="outline"
              w="50%"
              isLoading={isPicking}
              {...buttonProps}
              _light={{
                backgroundColor: theme.colors.systemColorsLight['orange']
              }}
              _dark={{
                backgroundColor: theme.colors.systemColorsDark['orange']
              }}
            >
              <Text
                fontFamily="Inter-SemiBold"
                fontSize="15px"
                color="white"
                tx={fakePickup ? 'common.pickup' : 'common.dispense'}
              />
            </Button>
          )}
          {showPickup && !isTakeMeThere && (
            <Button
              _light={{
                backgroundColor: theme.colors.systemColorsLight['orange']
              }}
              _dark={{
                backgroundColor: theme.colors.systemColorsDark['orange']
              }}
              isLoading={isPicking}
              onPress={doPickup}
              backgroundColor={'primary.500'}
              {...buttonProps}
              variant="outline"
              w="50%"
            >
              <Text fontFamily="Inter-SemiBold" fontSize="15px" color="white" tx="common.pickup" />
            </Button>
          )}
        </HStack>
      </Modal.Content>
    </Modal>
  )
}

export default PickUpModal

import { useMutation } from '@tanstack/react-query'

import { useSDK } from '../../../SDKProvider'
import { getConfig } from '../appConfig'

import { walletApiHost } from './queries'

type AddressToIdentitiesPayload = {
  type: string
  nonce: string
  signature: string
}

type PatchIdentityPayload = {
  name: string
}

type EthNonce = {
  value: string
}

type IdentityParams = {
  type: string
  value: string
}

const fetchEthNonce = async (appId: string, vatomToken: string, address: string) => {
  const res = await fetch(`${walletApiHost}/eth/nonce?address=${address}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${vatomToken}`,
      'App-Id': appId,
      'Content-Type': 'application/json'
    })
  })

  const data = (await res.json()) as EthNonce
  return data
}

const postAddressToIdentities = async (
  appId: string,
  vatomToken: string,
  payload: AddressToIdentitiesPayload
) => {
  const res = await fetch(`${walletApiHost}/identities`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${vatomToken}`,
      'App-Id': appId,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(payload)
  })

  let message = ''

  try {
    const resJson = await res.json()
    message = resJson.message
  } catch (error) {
    //
  }

  const err = 'wallet is already verified'
  if (message.includes(err)) {
    throw new Error(err)
  }

  return res
}

const deleteAddressToIdentities = async (
  appId: string,
  vatomToken: string,
  params: IdentityParams
) => {
  return await fetch(
    `${walletApiHost}/me/identities/${encodeURIComponent(params.type)}/${encodeURIComponent(
      params.value
    )}`,
    {
      method: 'DELETE',
      headers: new Headers({
        Authorization: `Bearer ${vatomToken}`,
        'App-Id': appId,
        'Content-Type': 'application/json'
      })
    }
  )
}

const patchAddressToIdentities = async (
  appId: string,
  vatomToken: string,
  params: IdentityParams,
  payload: PatchIdentityPayload
) => {
  return await fetch(
    `${walletApiHost}/me/identities/${encodeURIComponent(params.type)}/${encodeURIComponent(
      params.value
    )}`,
    {
      method: 'PATCH',
      headers: new Headers({
        Authorization: `Bearer ${vatomToken}`,
        'App-Id': appId,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(payload)
    }
  )
}

export const useAddressToIdentities = () => {
  const sdk = useSDK()
  const accessToken = sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken ?? ''
  const appId = getConfig().appID

  return useMutation({
    mutationFn: async (payload: AddressToIdentitiesPayload) => {
      return await postAddressToIdentities(appId, accessToken, payload)
    }
  })
}

export const useDeleteAddressFromIdentities = () => {
  const sdk = useSDK()
  const accessToken = sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken ?? ''
  const appId = getConfig().appID

  return useMutation({
    mutationFn: async (params: IdentityParams) => {
      await deleteAddressToIdentities(appId, accessToken, params)
      await sdk.dataPool.user.fetchIdentities()

      return
    }
  })
}

export const useFetchEthNonce = () => {
  const sdk = useSDK()
  const accessToken = sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken ?? ''
  const appId = getConfig().appID

  return useMutation({
    mutationFn: async (address: string) => {
      return await fetchEthNonce(appId, accessToken, address)
    }
  })
}

export const usePatchAddressFromIdentities = () => {
  const sdk = useSDK()
  const accessToken = sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken ?? ''
  const appId = getConfig().appID

  return useMutation({
    mutationFn: async ({
      params,
      payload
    }: {
      params: IdentityParams
      payload: PatchIdentityPayload
    }) => {
      return await patchAddressToIdentities(appId, accessToken, params, payload)
    }
  })
}

import React, { useCallback } from 'react'
import { isMobile } from 'react-device-detect'
import { Keyboard, Platform, TouchableWithoutFeedback } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { Text } from '@vatom/wombo'
import { Actionsheet, Box, HStack, Icon, Pressable } from 'native-base'

import { useMaxWidth } from '../../../components/WebWrapper'
import { AppRoutes } from '../../../navigators'
import useSpaceFromNavParams from '../../Space/useSpaceFromNavParams'

type ModalTypes = {
  showModal: boolean
  setShowModal: (x: boolean) => void
  spaceId: string
}

type ActionTypes = {
  label: string
  iconName: string
  color?: string
  onPress: () => void
}

export const PeopleActionsModal = ({ showModal, setShowModal, spaceId }: ModalTypes) => {
  const { space } = useSpaceFromNavParams({ spaceId })
  const maxWidth = useMaxWidth()
  const navigation = useNavigation()

  /** Start a call to the specified space */
  const openSpace = (audioOnly = false) => {
    navigation.navigate(AppRoutes.Metaverse, { spaceId, type: audioOnly ? 'call' : 'metaverse' })
  }

  const actions: ActionTypes[] = [
    {
      label: 'Start Call',
      iconName: 'phone-outline',
      onPress: () => openSpace(true)
    }
  ]

  if (
    (isMobile && space?.compatibility?.mobile) || // mobile browser
    (Platform.OS !== 'web' && space?.compatibility?.mobile) || // native apps
    (!isMobile && Platform.OS === 'web') // desktop browser
  ) {
    actions.push({
      label: 'Visit 3D Space',
      iconName: 'cube-outline',
      onPress: () => openSpace()
    })
  }

  const closeModal = useCallback(() => {
    Keyboard.dismiss()
    setShowModal(false)
  }, [setShowModal])

  return (
    <TouchableWithoutFeedback
      style={{ height: '100%' }}
      onPress={Keyboard.dismiss}
      accessible={false}
    >
      <Actionsheet
        isOpen={showModal}
        onClose={closeModal}
        maxWidth={maxWidth}
        margin={'auto'}
        useRNModal={true}
        // @ts-ignore prop doesn't exit on types
        _backdropFade={{
          entryDelay: 150,
          exitDelay: 10
        }}
      >
        <Actionsheet.Content bgColor="white">
          <Box marginBottom="6px" width="100%" px="16px">
            {actions.map(action => (
              <Pressable
                alignItems="center"
                accessibilityRole="button"
                onPress={() => {
                  action.onPress()
                  setShowModal(false)
                }}
                key={`${action.label}-space-action`}
              >
                <Box
                  flexDir="row"
                  width="100%"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  backgroundColor="white"
                  h="48px"
                >
                  <HStack alignItems="center" h="48px" width="90%">
                    <Icon
                      marginRight="8px"
                      as={MaterialCommunityIcons}
                      name={action.iconName}
                      w="24px"
                      h="24px"
                      color={action.color ?? 'grayCool.700'}
                    />
                    <Text
                      lineHeight={20}
                      letterSpacing={0}
                      fontWeight={400}
                      fontSize={15}
                      color={action.color ?? 'grayCool.700'}
                    >
                      {action.label}
                    </Text>
                  </HStack>
                </Box>
              </Pressable>
            ))}
          </Box>
        </Actionsheet.Content>
      </Actionsheet>
    </TouchableWithoutFeedback>
  )
}

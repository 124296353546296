/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * PricePointBase
 * auto generated base class for the model PricePointModel.
 */
export const PricePointModelBase = ModelBase
  .named('PricePoint')
  .props({
    __typename: types.optional(types.literal("PricePoint"), "PricePoint"),
    price: types.union(types.undefined, types.frozen()),
    date: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PricePointModelSelector extends QueryBuilder {
  get price() { return this.__attr(`price`) }
  get date() { return this.__attr(`date`) }
}
export function selectFromPricePoint() {
  return new PricePointModelSelector()
}

export const pricePointModelPrimitives = selectFromPricePoint().price.date
